import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MergedTrainingExercise } from 'src/app/model/training-exercise';
import { TrackedTrainingExercise, TrackedVideoRecording } from 'src/app/model/training-monitoring.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { TrainingService } from 'src/app/services/training.service';

@Component({
  selector: 'app-video-recording-dialog',
  templateUrl: './video-recording-dialog.component.html',
  styleUrls: ['./video-recording-dialog.component.css']
})
export class VideoRecordingDialogComponent implements OnInit {

  public trackedExercise: TrackedTrainingExercise
  public recording: TrackedVideoRecording
  public exercise: MergedTrainingExercise
  public userUid: string
  public hasChanges: boolean = false

  constructor(public dialogRef: MatDialogRef<VideoRecordingDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {userUid: string, recording: TrackedVideoRecording, trackedExercise: TrackedTrainingExercise, exercise: MergedTrainingExercise}, public firestoreService: FirestoreService, private toastr: ToastrService) {
    this.recording = data.recording
    this.exercise = data.exercise
    this.userUid = data.userUid
    this.trackedExercise = data.trackedExercise
  }

  ngOnInit(): void {
  }

  onVideoClicked() {
    if (this.recording.videoUrl && !this.recording.viewedByCoach) {
      this.recording.viewedByCoach = true
      this.firestoreService.markExerciseRecordingAsViewed(this.userUid, this.trackedExercise).then()
    }
  }

  onEditorValueChanged(value: string) {
    this.recording.feedbackNote = value
    this.recording.feedbackRead = false
    this.hasChanges = true
  }

  async onSendFeedback() {
    try{
      await this.firestoreService.updateExerciseRecordingFeedback(this.userUid, this.trackedExercise)
      var user = this.firestoreService.getClient(this.userUid)
      if (user) {
        this.firestoreService.sendPushNotification('NEW_ASSIGNMENTS', 'Video-Feedback erhalten', 'Du hast Feedback zu einer Videoaufnahme erhalten.', user)
        this.toastr.success("Feedback wurde gesendet!", "" ,  {
          positionClass: 'toast-bottom-center'
        });
        this.hasChanges = false
      }
      else {
        this.toastr.error("Feedback konnte leider nicht gesendet werden.");
      }
      //if (user) this.firestoreService.sendExerciseFeedbackNotification(user, this.trackedExercise.trackedSessionId, this.trackedExercise.id, this.recording.setNumber)
    }
    catch(error){
      console.error(error);
      this.toastr.error("Feedback konnte leider nicht gesendet werden.");
    }
  }

  async onCloseDialog() {
    this.dialogRef.close()
  }
  
  goToLink(url: string) {
    window.open(url, "_blank");
  }
}
