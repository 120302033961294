import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.service';
import { Product } from '../model/product.model';
import { User } from '../model/user.model';
import { ProductPurchase } from '../model/product-purchase.model';
import { firstValueFrom } from 'rxjs';
import { PaymentSettings } from '../model/payment-settings.model';
import { environment } from 'src/environments/environment';
import { Payment } from '../model/payment.model';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  public allPaymentMethods = []

  constructor(private userService: FirestoreService, private translate: TranslateService) {
    this.allPaymentMethods = [
      { name: marker('Sepa-Lastschrift'), value: 'sepa_debit' },
      { name: marker('Banküberweisung'), value: 'bank_transfer' },
      { name: marker('Kreditkarte'), value: 'card' },
    ]

    this.userService.observableUser.subscribe(x => {
      this.user = x
      if (this.user && this.user.licenceHolder) this.loadPaymentData()
    })
  }

  loading: boolean = true
  user: User
  chargesEnabled: boolean = false
  payoutsEnabled: boolean = false
  onboardingCompleted: boolean = false
  paymentSettings: PaymentSettings

  activeProducts: Product[] = []
  allProducts: Product[] = []

  payments: Payment[] = []
  productPurchases: ProductPurchase[] = []

  accountBalance: any = null
  interruptPaymentObserver: boolean = false


  get stripeAccountId(): string {
    return this.user.getStripeAccountId()
  }
  get canUsePayment(): boolean {
    return this.chargesEnabled && this.payoutsEnabled && this.paymentSettings?.isInformationValid() && this.paymentSettings?.areTemplatesValid() && this.stripeAccountId != null
  }

  getLandingPageLink() {
    if (this.user?.licenceHolder?.customLandingPageSlug) return environment.baseUrl + '/profile/' + this.user.licenceHolder.customLandingPageSlug
    return environment.baseUrl + '/profile/' + this.user.licenceHolderUid
  }

  loadPaymentData() {
    this.activeProducts = []
    this.loadPaymentSettings()

    if (this.user.getStripeAccountId()) {

      firstValueFrom(this.userService.isStripeAccountVerified(this.user.getStripeAccountId())).then(result => {
        this.chargesEnabled = result?.chargesEnabled ?? false
        this.payoutsEnabled = result?.payoutsEnabled ?? false
        this.onboardingCompleted = result?.detailsSubmitted ?? false
        this.userService.getProducts(this.user.licenceHolderUid).subscribe(products => {
          this.activeProducts = products.filter(p => !p.deleted)
          this.activeProducts.sort((a, b) => a.name.localeCompare(b.name))
          this.allProducts = products
          this.mergeProductsAndPurchases()
        })
        this.loading = false
      }, err => {})
      firstValueFrom(this.userService.getStripeAccountBalance(this.user.getStripeAccountId())).then(result => {
        this.accountBalance = result
        if (this.accountBalance?.pendingAmount < 0) {
          this.accountBalance.pendingAmount = 0
        }
      }, err => {})

      this.userService.getProductPurchases(this.user.licenceHolderUid).subscribe(subscriptions => {
        this.productPurchases = subscriptions
        this.mergeProductsAndPurchases()
      })

      this.userService.getPayments(this.user.licenceHolderUid).subscribe(payments => {
        if (this.interruptPaymentObserver) return
        this.payments = payments
        this.payments.forEach(payment => {
          this.getClientById(payment.customerUid).then(client => {
            payment.customer = client
          })
          if (payment.productId) payment.product = this.allProducts.find(p => p.id == payment.productId)
        })

        // Correct numbers
        //this.correctInvoiceNumbers()

      })
    } else {
      this.loading = false
    }
  }

  async correctInvoiceNumbers() {
    var payments = this.payments.concat([]).reverse()
    console.log(payments.length)
    this.interruptPaymentObserver = true

    var i = 1
    for (const payment of payments) {
        if (i > 1) break;
        var invoiceNumber = payment.invoiceNumber
        if (!invoiceNumber?.includes('2024') && !invoiceNumber?.includes('2025')) {
            var newInvoiceNumber = 'N' + i.toString() + ',2024,' + (payment.date.getMonth() + 1).toString()
            console.log('Old invoice number:', invoiceNumber, newInvoiceNumber)
            await this.userService.firestore.collection('Payments').doc(payment.id).update({invoiceNumber: newInvoiceNumber, invoiceFileName: newInvoiceNumber + '.pdf' })
            await this.userService.recreateInvoicePdf(payment)

            i++
        }
    }
  }

  async loadPaymentSettings() {
    this.paymentSettings = await this.userService.getPaymentSettings(this.user.licenceHolderUid)
    if (!this.paymentSettings) this.paymentSettings = new PaymentSettings()
    if (this.paymentSettings?.termsAndConditionsPath) {
      try {
        this.paymentSettings.termsAndConditionsUrl = await this.userService.getDownloadUrl("licence_holders/" + this.user.licenceHolderUid + "/settings/payment/" + this.paymentSettings.termsAndConditionsPath)
      } catch (error) {

      }
    }
  }
  async onSavePaymentSettings() {
    await this.userService.savePaymentSettings(this.user.licenceHolderUid, this.paymentSettings)
    this.loadPaymentSettings()
  }

  async mergeProductsAndPurchases() {
    for (let purchase of this.productPurchases) {
      this.getClientById(purchase.customerUid).then(client => {
        purchase.customer = client
      })
      if (purchase.productId) purchase.product = this.allProducts.find(p => p.id == purchase.productId)
      if (purchase.licenceId) purchase.licence = await this.userService.getLicenceById(purchase.licenceId, false)
    }
  }


  getClientById(clientId: string): Promise<User> {
    if (!clientId) return Promise.resolve(null)
    var client = this.userService.getAccessibleClients().filter(c => c.uid == clientId).shift()
    if (!client) {
      return firstValueFrom(this.userService.getUserByUid(clientId))
    }
    return Promise.resolve(client)
  }

  getProductById(productId: string) {
    return this.allProducts.filter(p => p.id == productId).shift()
  }

  getPrintableCurrency(currency: string) {
    if (currency == 'eur' || currency == null) return '€'
    if (currency == 'chf') return 'CHF'
    if (currency == 'usd') return '$'
    if (currency == 'gbp') return '£'
    return currency
  }

}
