<div class="dialog-topbar">
    <h2 class="padding-0 margin-0">Athleten-Einstellungen</h2>
</div>
<div class="dialog-content-wrapper">
    <h3>Kundendaten</h3>
    <div class="row">
        <div class="col-5">Email:</div>
        <div class="col-7">{{userEmail}}</div>
        <div class="col-5">Aktuelles Gewicht:</div>
        <div class="col-7">{{(user.latestBodyWeight ?? user.bodyWeight) | weightConversion: languageService.selectedUnitSystem: true }} {{languageService.getUnit()}}</div>
        <div class="col-5">Startgewicht:</div>
        <div class="col-7">{{(user.firstBodyWeight ?? user.bodyWeight) | weightConversion: languageService.selectedUnitSystem: true }} {{languageService.getUnit()}}</div>
        <div class="col-5">Körpergröße:</div>
        <div class="col-7">{{user.bodyHeight.toFixed(0)}} cm</div>
        <ng-container *ngIf="user.birthDate">
            <div class="col-5">Geburtsdatum:</div>
            <div class="col-7">{{user.birthDate?.asFormatedString()}}</div>
        </ng-container>
        <div class="col-5">Alter:</div>
        <div class="col-7">{{user.age}} Jahre</div>
        <div class="col-5">Geschlecht:</div>
        <div class="col-7">{{user.getPrintableGender()}}</div>
        <div class="col-5">Aktivitätslevel (PAL-Wert):</div>
        <div class="col-7">{{user.getPrintablePhysicalActivityLevel()}}</div>
        <div class="col-5" *ngIf="user?.spikeProvider && user?.spikeUserId">Verbundenes Wearable:</div>
        <div class="col-7" *ngIf="user?.spikeProvider && user?.spikeUserId">{{user?.spikeProvider}}</div>
    </div>

    <div class="spacer-20"></div>
    <div class="row">
        <div class="col-12">
            <h3>Check-Ins</h3>
            <app-checkin-assignment-view [User]="user"></app-checkin-assignment-view>
        </div>
        <div class="spacer-10"></div>
        <div class="col-12">
            <h3>Training</h3>
        </div>
        <div class="col-5">Nächster Wettkampf:</div>
        <div class="col-7">
            <div class="competitiondate-wrapper">
                <input [matDatepicker]="competitionDatePicker" class="visibility-hidden width-0 padding-0" (dateInput)="onCompetitionDateChanged($event.value?.toDate())">
                <mat-datepicker-toggle matSuffix [for]="competitionDatePicker" class="competition-datepicker-toggle"></mat-datepicker-toggle>
                <mat-datepicker #competitionDatePicker></mat-datepicker>
                {{user.nextCompetitionDate?.asFormatedString()}}
                <div *ngIf="user.nextCompetitionDate" class="display-inlineblock link marginleft-10">
                    <i class="bi bi-x-lg" (click)="onCompetitionDateChanged(null)"></i>
                </div>
            </div>
        </div>

        <button class="button-filled small light button-trainingvariables margin-10" (click)="openTrainingVariablesDialog()">Ausdauer-Einstellungen</button>
        <div class="col-12">
            <div class="spacer-20"></div>
        </div>
        <div class="col-5">
            Standard-Parameter RPE/RIR
        </div>
        <div class="col-7">
            <div class="d-inline-block wrapper-selection">
                <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getDefaultExertionParameter() ?? 'Nach Übung'}}</button>
                <div class="dropdown-menu" aria-labelledby="dropdownExertionParameter">
                    <button class="dropdown-item" type="button" (click)="onSelectDefaultExertionParameter(null)">Nach Übung</button>
                    <button class="dropdown-item" type="button" (click)="onSelectDefaultExertionParameter('rpe')">RPE</button>
                    <button class="dropdown-item" type="button" (click)="onSelectDefaultExertionParameter('rir')">RIR</button>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="spacer-10"></div>
            <h3>Sonstige</h3>
        </div>
        <div class="col-5">Ernährungsbereich:</div>
        <div class="col-7">
            <div class="recipesharing-toggle toggle-wrapper margin-4 mobile-marginbottom-10" matTooltip="Aktiviere den Ernährungsbereich in Traindoo für den Athleten.">
                <div class="toggle {{isNutritionEnabled() ? 'on' : ''}}" (click)="onEnableNutritionChanged(!isNutritionEnabled())">
                    <div class="handle"></div>
                </div>
                <div class="text">
                    Ernährung aktivieren <span *ngIf="user.nutritionEnabled == null">(Standardeinstellung)</span>
                    <div class="label" *ngIf="isNutritionEnabled()">Es fallen zusätzliche Kosten an.</div>
                </div>
            </div>
        </div>
        <div class="spacer-20"></div>
    </div>

    <div class="spacer-20"></div>

    <div class="actions">
        <button class="button-filled small light" (click)="onCancelDialog()">Schließen</button>
    </div>
</div>