import { marker } from "@colsen1991/ngx-translate-extract-marker"
import { TranslateService } from "@ngx-translate/core"
import { environment } from "src/environments/environment"

export enum ProductDurationUnit {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year'
}

export enum ProductLicenseType {
  COACHING = 'Coaching',
  NONE = null
}

export class Product {
    id: string
    stripeAccountId: string
    licenceHolderUid: string
    name: string
    subtitle: string
    description: string
    invoiceDescription: string
    thumbnailPath: string
    price: number
    initialSetupFee: number
    chargeInitialSetupFeeOnPurchase: boolean
    currency: string
    vatRate: number
    recurring: boolean
    duration: number
    durationUnit: ProductDurationUnit
    durationMultiplier: number
    autoRenew: boolean
    cancelationPeriod: number
    productPageEnabled: boolean
    showOnLandingPage: boolean
    landingPageOrder: number
    startDate: Date
    licenceType: ProductLicenseType

    onboardingQuestionaireIds: string[]
    assignedMetricIds: string[]
    assignedClientGroups: string[]
    hideNutritionValues: boolean
    hideChat: boolean

    deleted: boolean = false

    // Tmp
    thumbnailUrl: string

    constructor();
    constructor(init: Product);
    constructor(init?: Product) {
        this.id = init && init.id || null
        this.stripeAccountId = init && init.stripeAccountId || null
        this.licenceHolderUid = init && init.licenceHolderUid || null
        this.name = init && init.name || null
        this.subtitle = init && init.subtitle || null
        this.description = init && init.description || null
        this.invoiceDescription = init && init.invoiceDescription || null
        this.thumbnailPath = init && init.thumbnailPath || null
        this.price = init && init.price != null ? init.price : null
        this.initialSetupFee = init && init.initialSetupFee != null ? init.initialSetupFee : null
        this.chargeInitialSetupFeeOnPurchase = init && init.chargeInitialSetupFeeOnPurchase || false
        this.currency = init && init.currency || null
        this.recurring = init && init.recurring || null
        this.durationUnit = init && init.durationUnit || null
        this.durationMultiplier = init && init.durationMultiplier || null
        this.duration = init && init.duration || null
        this.autoRenew = init && init.autoRenew || false
        this.cancelationPeriod = init && init.cancelationPeriod != null ? init.cancelationPeriod : 0
        this.licenceType = init && init.licenceType || null
        this.productPageEnabled = init && init.productPageEnabled || false
        this.showOnLandingPage = init && init.showOnLandingPage || false
        this.landingPageOrder = init && init.landingPageOrder != null ? init.landingPageOrder : null
        if (init?.startDate?.constructor.name == 'Date') {
            this.startDate = new Date(init.startDate)
        } else {
            this.startDate = init && init.startDate ? new Date((init as any).startDate.seconds * 1000) : null
        }
        this.deleted = init && init.deleted || false
        this.onboardingQuestionaireIds = init && init.onboardingQuestionaireIds || []
        this.assignedClientGroups = init && init.assignedClientGroups || []
        this.assignedMetricIds = init && init.assignedMetricIds || []
        this.hideNutritionValues = init && init.hideNutritionValues || false
        this.hideChat = init && init.hideChat || false
        this.vatRate = init && init.vatRate != null ? init.vatRate : null
    }

    hasFixedDuration() {
        return this.durationMultiplier != null
    }
    getPrintableDuration(translate: TranslateService, durationMultiplier: number = this.durationMultiplier) {
        //if (this.licenceType == null) return 'Keine Laufzeit'
        if (this.duration == null) return translate.instant(marker('Unbegrenzte Zeit'))
        return (this.duration * (durationMultiplier ?? 1)).toString() + ' ' + this.getPrintableDurationUnit(translate)
    }
    getPrintableDurationUnit(translate: TranslateService) {
        if (this.durationUnit == 'day') return translate.instant(marker('Tage'))
        if (this.durationUnit == 'week') return translate.instant(marker('Wochen'))
        if (this.durationUnit == 'month') return translate.instant(marker('Monate'))
        if (this.durationUnit == 'year') return translate.instant(marker('Jahre'))
    }

    getPrintablePaymentFrequency(translate: TranslateService) {
        if (this.recurring) {
            if (this.duration == 1) {
                if (this.durationUnit == 'day') return translate.instant('pro Tag')
                if (this.durationUnit == 'week') return translate.instant('pro Woche')
                if (this.durationUnit == 'month') return translate.instant('pro Monat')
                if (this.durationUnit == 'year') return translate.instant('pro Jahr')
            } else {
                return 'pro ' + this.duration.toString() + ' ' + this.getPrintableDurationUnit(translate)
            }
        }
        return translate.instant('einmalig')
    }
    getTotalRuntime() {
        if (!this.durationMultiplier) return this.duration
        return this.duration * this.durationMultiplier
    }
    getPrintableTotalRuntime(translate: TranslateService) {
        return this.getTotalRuntime().toString() + ' ' + this.getPrintableDurationUnit(translate)
    }

    getPrintablePrice() {
        return (this.price / 100).toString().replace('.', ',')
    }
    getPrintablePriceWithCurrency() {
        return this.getPrintablePrice() + ' ' + this.getPrintableCurrency()
    }
    getPrintableInitialSetupFee() {
        if (this.initialSetupFee == null || this.initialSetupFee == 0) return null
        return (this.initialSetupFee / 100).toString().replace('.', ',')
    }
    getPrintableInitialSetupFeeWithCurrency() {
        if (this.initialSetupFee == null) return null
        return this.getPrintableInitialSetupFee() + ' ' + this.getPrintableCurrency()
    }
    getPrintableCurrency() {
        if (this.currency == 'eur' || this.currency == null) return '€'
        if (this.currency == 'chf') return 'CHF'
        if (this.currency == 'usd') return '$'
        if (this.currency == 'gbp') return '£'
        return this.currency
    }

    getFullThumbnailPath(licenceHolderUid: string) {
        return 'licence_holders/' + licenceHolderUid + '/products/' + this.id
    }

    getProductPageLink() {
        return environment.baseUrl + '/product/' + this.id
    }

    getPrintableRuntime(translate: TranslateService) {
        var text = ''
        var now = new Date()
        if (this.startDate) {
            if (this.startDate > now) {
            text += translate.instant('Startet {{startDate}}', { startDate: this.startDate.asFormatedString() })
            } else {
            text += translate.instant('Seit {{startDate}}', { startDate: this.startDate.asFormatedString() })
            }
        } else if (this.licenceType) {
            text += translate.instant('Startet sofort')
        }
        if (this.autoRenew && this.recurring) {
            text += translate.instant(', verlängert sich automatisch um {{duration}} ({{cancelationPeriod}} Tage Kündigungsfrist)', {
            duration: this.getPrintableDuration(translate, 1),
            cancelationPeriod: this.cancelationPeriod
            })
        }

        return text
    }

    asMap() {
        return {
            stripeAccountId: this.stripeAccountId,
            name: this.name,
            subtitle: this.subtitle,
            description: this.description,
            invoiceDescription: this.invoiceDescription,
            thumbnailPath: this.thumbnailPath,
            price: this.price,
            initialSetupFee: this.initialSetupFee,
            chargeInitialSetupFeeOnPurchase: this.chargeInitialSetupFeeOnPurchase,
            currency: this.currency,
            vatRate: this.vatRate,
            recurring: this.recurring,
            licenceHolderUid: this.licenceHolderUid,
            durationUnit: this.durationUnit,
            duration: this.duration,
            durationMultiplier: this.durationMultiplier,
            autoRenew: this.autoRenew,
            cancelationPeriod: this.cancelationPeriod,
            licenceType: this.licenceType,
            productPageEnabled: this.productPageEnabled,
            showOnLandingPage: this.showOnLandingPage,
            landingPageOrder: this.landingPageOrder,
            hideChat: this.hideChat,
            hideNutritionValues: this.hideNutritionValues,
            onboardingQuestionaireIds: this.onboardingQuestionaireIds,
            assignedMetricIds: this.assignedMetricIds,
            assignedClientGroups: this.assignedClientGroups,
            startDate: this.startDate,
            deleted: this.deleted
        }
    }
}
