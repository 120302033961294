<div class="dialog-topbar">
    <div class="icon-button float-right" (click)="onCloseDialog()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        Schließen
    </div>
</div>
<div class="dialog-content-wrapper">
    <div class="container padding-0">
        <h2 class="">Marketing-Kit</h2>
        <h3>Nutze unsere Post-Vorlagen und passe sie beliebig an, um für dein Coaching zu werben.</h3>
        <div class="row">
            <div class="col-12 col-xl-6 preview-wrapper">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <img draggable="false" class="preview" src="assets/images/Marketing-Kit IG-Post App 1.png">
                    </div>
                    <div class="col">
                        Vorlage 1
                        <a href="https://drive.google.com/drive/folders/1B0gnlybjOu5wlarQ5DNKSQW9ozFYh3X4?usp=share_link" target="_blank" class="preview-link">
                            <div class="icon-button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                </svg>
                                Auf Google Drive ansehen
                            </div>
                        </a>
                        <a href="https://www.canva.com/design/DAFYw3u2yZ4/uFIPJxfWiorGE6KcQ5ylhw/view?utm_content=DAFYw3u2yZ4&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview" target="_blank" class="preview-link">
                            <div class="icon-button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                </svg>
                                Auf Canva anpassen
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-6 preview-wrapper">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <img draggable="false" class="preview" src="assets/images/Marketing-Kit IG-Post App 2.png">
                    </div>
                    <div class="col">
                        Vorlage 2
                        <a href="https://drive.google.com/drive/folders/1lO8vcOB1OWEirR_Ga6XBXJGxT1vYnYUT?usp=share_link" target="_blank" class="preview-link">
                            <div class="icon-button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                </svg>
                                Auf Google Drive ansehen
                            </div>
                        </a>
                        <a href="https://www.canva.com/design/DAFdXdpBQvo/q3HPXD4YS_m1DBQHjdwjgQ/view?utm_content=DAFdXdpBQvo&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview" target="_blank" class="preview-link">
                            <div class="icon-button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                </svg>
                                Auf Canva anpassen
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-6 preview-wrapper">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <img draggable="false" class="preview" src="assets/images/Marketing-Kit IG-Post App 3.png">
                    </div>
                    <div class="col">
                        Vorlage 3
                        <a href="https://drive.google.com/drive/folders/1drCI_JJc-N0HvNZsURBgLkUwvcWxP7by?usp=share_link" target="_blank" class="preview-link">
                            <div class="icon-button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                </svg>
                                Auf Google Drive ansehen
                            </div>
                        </a>
                        <a href="https://www.canva.com/design/DAFdfNY6ZTQ/K1kuOkMAiQnU6qJe5nglDg/view?utm_content=DAFdfNY6ZTQ&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview" target="_blank" class="preview-link">
                            <div class="icon-button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                </svg>
                                Auf Canva anpassen
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-6 preview-wrapper">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <img draggable="false" class="preview" src="assets/images/Marketing-Kit IG-Post App 4.png">
                    </div>
                    <div class="col">
                        Vorlage 4
                        <a href="https://drive.google.com/drive/folders/1oAOx3FEjCGtprVBV9GK4K3VWxbbA1Ezo?usp=drive_link" target="_blank" class="preview-link">
                            <div class="icon-button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                </svg>
                                Auf Google Drive ansehen
                            </div>
                        </a>
                        <a href="https://www.canva.com/design/DAGHb8qeNiw/CXmGdwUxpJI2AHM9k08BmA/view?utm_content=DAGHb8qeNiw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview" target="_blank" class="preview-link">
                            <div class="icon-button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                </svg>
                                Auf Canva anpassen
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-6 preview-wrapper">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <img draggable="false" class="preview" src="assets/images/Marketing-Kit IG-Post App 5.png">
                    </div>
                    <div class="col">
                        Vorlage 5
                        <a href="https://drive.google.com/drive/folders/1u_8qIFyMkL7c3vaxuS4PV1mbboTcaj6O?usp=drive_link" target="_blank" class="preview-link">
                            <div class="icon-button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                </svg>
                                Auf Google Drive ansehen
                            </div>
                        </a>
                        <a href="https://www.canva.com/design/DAGHb0QZ4OE/s3DbdG6OCDZekxkv-kBFdA/view?utm_content=DAGHb0QZ4OE&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview" target="_blank" class="preview-link">
                            <div class="icon-button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                </svg>
                                Auf Canva anpassen
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-6 preview-wrapper">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <img draggable="false" class="preview" src="assets/images/Marketing-Kit IG-Post App 6.png">
                    </div>
                    <div class="col">
                        Vorlage 6
                        <a href="https://drive.google.com/drive/folders/1G7nRkJObmaJYRghB82UywOewzdpCPAXc?usp=drive_link" target="_blank" class="preview-link">
                            <div class="icon-button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                </svg>
                                Auf Google Drive ansehen
                            </div>
                        </a>
                        <a href="https://www.canva.com/design/DAGHb4riZ1I/sp5ziF_lYKmILCYJzbOB8A/view?utm_content=DAGHb4riZ1I&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview" target="_blank" class="preview-link">
                            <div class="icon-button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                </svg>
                                Auf Canva anpassen
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-6 preview-wrapper">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <img draggable="false" class="preview" src="assets/images/Marketing-Kit IG-Story App 1.png">
                    </div>
                    <div class="col">
                        Story-Vorlage 1
                        <a href="https://drive.google.com/drive/folders/13XlKZ70X--kIdvFM3HEIywKk7rQ9Tv3O?usp=drive_link" target="_blank" class="preview-link">
                            <div class="icon-button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                </svg>
                                Auf Google Drive ansehen
                            </div>
                        </a>
                        <a href="https://www.canva.com/design/DAGHbjv2rE0/DKwlaqGipA4xBoG3JEnHkA/view?utm_content=DAGHbjv2rE0&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview" target="_blank" class="preview-link">
                            <div class="icon-button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                </svg>
                                Auf Canva anpassen
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="spacer-25"></div>
        <h3>Weitere Grafiken findest du zum Download in unserem Google Drive-Ordner:</h3>
        <a href="https://drive.google.com/drive/folders/1lScvTzQRdVyOp6_mpoPwfsqikJRYybUn?usp=share_link" target="_blank" class="preview-link">
            <div class="icon-button">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                </svg>
                Auf Google Drive ansehen
            </div>
        </a>
        <div class="spacer-25"></div>
        <h3>Außerdem haben wir Videoanleitungen für die App erstellt, die du teilen kannst mit deinen Coachees:</h3>
        <a href="https://loom.com/share/folder/9e820e6b593349d7b364c9fac35f1c48" target="_blank" class="preview-link">
            <div class="icon-button">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                </svg>
                Tutorials aufrufen
            </div>
        </a>
    </div>
</div>