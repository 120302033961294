import { UtilityService } from 'src/app/services/utility.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { Coach } from './../model/coach.model';
import { Token } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { DeliveredNotifications, PushNotifications, PushNotificationToken } from '@capacitor/push-notifications';
import { User } from '../model/user.model';
import { Router } from '@angular/router';
import { timeout } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Network } from '@capacitor/network';
import { TaskManagementService } from './task-management.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  constructor(private router: Router, private utilityService: UtilityService, private toastrService: ToastrService, private taskManagementService: TaskManagementService, private translate: TranslateService) { }

  public initPushNotififcations(coach:Coach, userService:FirestoreService){
    //console.log(coach)
    if (Capacitor.getPlatform() !== 'web') this.registerPushNotifications(coach, userService);
  }


  private registerPushNotifications(coach:Coach, userService:FirestoreService){
    PushNotifications.requestPermissions().then((permission) => {
      if(permission.receive){
        PushNotifications.register()
      }
      else{
        console.log("push permissions denied")
        //no permissions for push granted
      }
    })
    PushNotifications.addListener('registration', token => {
        var currentFcmToken = localStorage.getItem('fcmToken')
        console.log("Current Token: " + currentFcmToken)
        if(currentFcmToken == null || currentFcmToken != token.value || !coach.fcmTokens?.includes(token.value)){
          console.log("register new fcm token.")
          const index = coach.fcmTokens?.indexOf(currentFcmToken);
          if(index > -1){
            coach.fcmTokens?.splice(index, 1)
            localStorage.removeItem('fcmToken');
          }
          localStorage.setItem('fcmToken',token.value)
          if(!coach.fcmTokens.includes(token.value)){
            coach.fcmTokens.push(token.value);
          }
          userService.updateCoachFcmToken(coach.fcmTokens, coach.uid).toPromise();
        }
        console.info('Registration token: ', token.value)});

    PushNotifications.addListener('registrationError', err => {
        console.error('Registration error: ', err.error);
      });
    PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      console.log('Push notification action performed', notification.actionId, notification.inputValue);
      if(notification?.notification?.data?.type == "CHAT_MESSAGE"){
        this.router.navigateByUrl("/chat");
      }
      else if(notification?.notification?.data?.type == "TASK_NOTIFICATION"){
        let taskId = notification?.notification?.data?.refId ?? null;
        this.taskManagementService.showTaskManagementSideBar(taskId);
      }
    });
  }

  public async unregisterPushNotifications(coach:Coach, userService:FirestoreService): Promise<boolean>{
    console.log("unregisterPushNotifications")
    try{
      if(this.utilityService.onNativeMobileApp()) {
        if(!coach){
          console.log("Coach is null")
          return false;
        }
        if(!(await Network.getStatus()).connected) return false;
        
        var fcmToken = localStorage.getItem('fcmToken')
        console.log("Current fcmTOken: " + fcmToken)
        if(fcmToken){
          const index = coach.fcmTokens?.indexOf(fcmToken);
          console.log("INDEX: " + index)
          var logoutTimeout: any;
          if(index > -1){
            coach.fcmTokens?.splice(index, 1)
            var fcmTokens = []
            coach.fcmTokens.forEach(token => {
              fcmTokens.push(token)
            });
            try{
              this.toastrService.info(this.translate.instant("Du wirst in Kürze abgemeldet."), "" ,  {
                positionClass: 'toast-bottom-center'
              });
              logoutTimeout = setTimeout(() => {
                this.toastrService.error(this.translate.instant("Bitte überprüfe die Internetverbindung."), "" ,  {
                positionClass: 'toast-bottom-center'
              });}, 7000);
              var result = (await userService.updateCoachFcmToken(fcmTokens, coach.uid).toPromise()).valueOf()
              clearTimeout(logoutTimeout)
              console.log("now its " + result)
              if(result == true) localStorage.removeItem('fcmToken');
              return result;
              // var result = await userService.updateCoachFcmToken(coach).toPromise().then(x => {
              //   console.log("now its " + x)
              //   return x
              // }).catch(x => {
              //   console.log("now it failed")
              //   return false
              // });
            }
            catch(exception){
              if(logoutTimeout) clearTimeout(logoutTimeout)
              console.log("Exception: " + exception)
              return false;
            }
            // if(!result){
            //   coach.fcmTokens?.push(fcmToken)
            //   console.log("FALSE")
            //   return false;
            // }
          }
        }
        PushNotifications.removeAllListeners();
        PushNotifications.removeAllDeliveredNotifications()
      }
      return true;
    }
    catch(exception){
      console.log(exception);
      return false;
    }
  }

  public RemoveAllDeliveredNotifications(){
    if(Capacitor.getPlatform() !== 'web') {
      PushNotifications.removeAllDeliveredNotifications();
    }
  }

  
}
