<div class="dialog-topbar">
    <h3>{{title}}</h3>
</div>

<div class="dialog-content-wrapper">
    <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{selectedDropdownItem?.name ?? 'Auswählen'}}</button>
    <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
        <div *ngFor="let item of availableDropdownItems">
            <button class="dropdown-item" type="button" (click)="onSelectionChanged(item)">{{item.name}}</button>
        </div>
    </div>
</div>

<mat-dialog-actions>
    <div class="actions">
        <button class="button-filled small light float-right" [disabled]="!canSubmit()" (click)="onSubmit()">{{submitButtonText}}</button>
        <button class="button-filled small light float-right marginright-25" (click)="onCancel()">{{cancelButtonText}}</button>
    </div>
</mat-dialog-actions>