import { Pipe, PipeTransform } from '@angular/core';
import { User } from './model/user.model';
import { DailyCondition } from './model/dailycondition.model';

@Pipe({
  name: 'timezone'
})
export class TimezonePipe implements PipeTransform {

  transform(value: Date, ...args: any[]): Date {
    if(!value) return value;
    let dailyCondition = null;

    if(args[0] instanceof User){
      let user = args[0] as User;
      if(user != null){
        dailyCondition = user.dailyConditions?.find(dc => dc.date.isSameDate(value));
      }
    }
    else if(args[0] instanceof DailyCondition){
      dailyCondition = args[0] as DailyCondition;
    }
    
    if(dailyCondition){
      let timeZoneOffsetInMilliseconds = new Date().getTimezoneOffset() * 60000;
      let millisecondsToAdd = dailyCondition?.timezoneOffset;
      if(millisecondsToAdd != undefined && timeZoneOffsetInMilliseconds != millisecondsToAdd){
        value = new Date(value.getTime() + timeZoneOffsetInMilliseconds + millisecondsToAdd);
      }
    }
    return value;
  }

}
