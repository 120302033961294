import { LicenceHolder } from "./licenceholder.model"

export class CoachInvoice {
    id: string
    invoiceId: string
    stripeCustomerId: string
    invoiceNumber: string
    licenceHolderId: string
    amount: number
    netAmount: number
    currency: string
    status: string
    date: Date
    deleted: boolean
    containsStarterPackage: boolean

    // Tmp:
    licenceHolder: LicenceHolder
    
    constructor();
    constructor(init: CoachInvoice);
    constructor(init?: CoachInvoice) {
        this.id = init && init.id || null
        this.invoiceId = init && init.invoiceId || null
        this.stripeCustomerId = init && init.stripeCustomerId || null
        this.invoiceNumber = init && init.invoiceNumber || null
        this.licenceHolderId = init && init.licenceHolderId || null
        this.amount = init && init.amount || null
        this.netAmount = init && init.netAmount || null
        this.currency = init && init.currency || null
        this.status = init && init.status || null
        this.deleted = init && init.deleted || false
        this.containsStarterPackage = init && init.containsStarterPackage || false
        if (init && (init as any).isStarterPackage == true) {
            this.containsStarterPackage = true
        }
        this.date = init && init.date ? new Date((init as any).date.seconds * 1000) : null
    }

    getPrintablePrice() {
        return (this.amount / 100).toString().replace('.', ',')
    }
    getPrintablePriceWithCurrency() {
        return this.getPrintablePrice() + ' ' + this.getPrintableCurrency()
    }
    getPrintableCurrency() {
        if (this.currency == 'eur' || this.currency == null) return '€'
        return '€'
    }
    getPrintableStatus() {
        switch (this.status) {
            case 'draft':    return 'Entwurf';
            case 'paid': return 'Bezahlt';
            case 'unpaid': return 'Unbezahlt';
            case 'processing': return 'In Verarbeitung';
            case 'pending': return 'In Verarbeitung';
            case 'manual_transfer_initiated': return 'Manuelle Überweisung initiiert';
            case 'refunded': return 'Erstattet';
            case 'voided': return 'Storniert';
            case 'failed': return 'Fehlgeschlagen';
            case 'failed_retrying': return 'Wird erneut versucht';
            case 'initiated': return 'Initiiert';
            case 'open': return 'Offen (keine Zahlungsmethode)';
            default: return 'Unbekannt';
        }
    }

    getClientName() {
        return this.licenceHolder?.name
    }
}