import { Component, Input, NgZone, OnInit } from '@angular/core';
import {MatDialog } from '@angular/material/dialog';
import { Repetition, Repetition2LabelMapping } from 'src/app/model/automatic-push-notification.model';
import { EventTriggerType, EventTriggerType2LabelMapping, Question, Questionaire, TriggerType } from 'src/app/model/questionaires.model';
import { QuestionairesService } from 'src/app/services/questionaires.service';
import { QuestionaireEditorComponent } from '../questionaire-editor/questionaire-editor.component';
import { FirestoreService } from 'src/app/services/firestore.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { User } from 'src/app/model/user.model';
import { QuestionaireSelectionDialogComponent } from '../questionaire-selection-dialog/questionaire-selection-dialog.component';
import { SelectableItem } from 'src/app/metrics-selection-dialog/metrics-selection-dialog.component';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ClientSelectionDialogComponent } from 'src/app/dialogs/client-selection-dialog/client-selection-dialog.component';

@Component({
  selector: 'app-questionaire-overview',
  templateUrl: './questionaire-overview.component.html',
  styleUrls: ['./questionaire-overview.component.css']
})
export class QuestionaireOverviewComponent implements OnInit {

  constructor(public questionaireService: QuestionairesService, public userService: FirestoreService, private dialog: MatDialog, private ngZone: NgZone, private spinner: NgxSpinnerService, public translate: TranslateService) { }

  public environment = environment

  public allQuestionaires: Questionaire[];
  private userQuestionaires: Questionaire[];
  public filteredQuestionaires: Questionaire[];

  public sendToName: string = "";
  public User: User;
  @Input() set user(value: User){
    this.User = value;
    this.filterByUser();
    this.onDeleteSearchInput();
    this.sendToName = this.translate.instant("an") + " " + this.User?.getName();
  }
  async ngOnInit(): Promise<void> {
    await this.loadQuestionaires();
  }

  async loadQuestionaires(): Promise<void>{
    this.allQuestionaires = await this.questionaireService.getQuestionaires();
    this.filterByUser();
    this.onDeleteSearchInput();
  }

  filterByUser(){
    this.ngZone.run( () => {
      this.userQuestionaires = this.allQuestionaires;
      if(this.User != null) {
        this.userQuestionaires = this.userQuestionaires?.filter(x => x.assignedGroupNames.includes('Alle') || x.assignedUids.includes(this.User.uid) || x.assignedGroupNames.find(groupName => this.User.metadataUser.assignedClientGroups.includes(groupName)) != null);
      }
    });
  }

  onCreateQuestionaire(){
    let questionaire = new Questionaire();
    questionaire.licenceHolderUid = this.userService.getLoggedInUser().licenceHolderUid

    const dialogRef = this.dialog.open(QuestionaireEditorComponent, { data: { questionaire: questionaire, user: this.User}, width: '1000px', autoFocus: false})
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if(result.shouldSave && !result.shouldDelete) {
          await this.questionaireService.createQuestionaire(questionaire);
          await this.loadQuestionaires();
        }
      }
    });
  }

  async onAssignQuestionaires(){
    let selectableQuestionaires = this.allQuestionaires.filter(x => !this.userQuestionaires.includes(x)).map(x => new SelectableItem<Questionaire>(x, false));
    const dialogRef = this.dialog.open(QuestionaireSelectionDialogComponent, { data: { selectableQuestionaires: selectableQuestionaires}, width: '1000px', autoFocus: false})
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if(result.shouldTake) {
          for(const questionaire of selectableQuestionaires){
            if(questionaire.IsSelected){
              questionaire.Item.assignedUids.push(this.User.uid);
              await this.questionaireService.updateQuestionaire(questionaire.Item);
              await this.loadQuestionaires();
            }
          }
        }
      }
    });
  }

  onSearchInputChanged(value: string){
    this.filteredQuestionaires = this.userQuestionaires.filter(x => x.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()) || x.internalName.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
  }
  onDeleteSearchInput(){
    this.filteredQuestionaires = this.userQuestionaires;
    (<HTMLInputElement> document.getElementById('search-input')).value = ''
  }

  onQuestionaireSelected(questionaire: Questionaire){
    let tempQuestionaire = questionaire.clone();
    const dialogRef = this.dialog.open(QuestionaireEditorComponent, { data: { questionaire: tempQuestionaire, readOnlyMode: questionaire.public && questionaire.licenceHolderUid != this.userService.getLoggedInUser().licenceHolderUid, user: this.User}, width: '1000px', autoFocus: false});
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if(result.shouldDelete){
          await this.questionaireService.deleteQuestionaire(tempQuestionaire);
          await this.loadQuestionaires();
        }
        else if(result.shouldSave) {
          var nextExecutionDateChanged = result.nextExecutionDateChanged ?? false
          await this.questionaireService.updateQuestionaire(tempQuestionaire, nextExecutionDateChanged)
          await this.loadQuestionaires()
          if (environment.firebaseProjectId == 'traindoo-app') await this.questionaireService.updateAssignedQuestionaireInMetrics(tempQuestionaire)
        }
      }
    });
  }

  duplicateQuestionaire(questionaire: Questionaire){
    let tempQuestionaire = questionaire.clone();
    tempQuestionaire.name = tempQuestionaire.name
    if (questionaire.public) {
      tempQuestionaire.internalName = tempQuestionaire.internalName?.replaceAll('(Standard) ', '').replaceAll(this.translate.instant('(Standard)') + " ", '');
      tempQuestionaire.public = false
    } else {
      tempQuestionaire.internalName = tempQuestionaire.internalName + " " + this.translate.instant("(Kopie)");
    }
    tempQuestionaire.assignedUids = [];
    tempQuestionaire.assignedGroupNames = [];
    tempQuestionaire.licenceHolderUid = this.userService.getLoggedInUser().licenceHolderUid
    const dialogRef = this.dialog.open(QuestionaireEditorComponent, { data: { questionaire: tempQuestionaire, user: this.User}, width: '1000px'});
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if(result.shouldSave) {
          await this.questionaireService.createQuestionaire(tempQuestionaire);
          await this.loadQuestionaires();
        }
      }
    });
  }

  async onSendQuestionaire(questionaire: Questionaire) {
    if (environment.firebaseProjectId == 'traindoo-app') {
      const dialogRef = this.dialog.open(ClientSelectionDialogComponent, {
        data: { title: this.translate.instant('Empfänger auswählen'), cancelButtonText: this.translate.instant('Abbrechen'), submitButtonText: this.translate.instant('questionaireNameParameter versenden', {questionaireNameParameter: questionaire.name}), availableClients: this.userService.getAccessibleClients(), availableClientGroups: this.userService.getClientGroups() },
      });
      dialogRef.afterClosed().subscribe(async result => {
        if (result && result.confirm) {
          var selectedClients = result.selectedClients ?? []
          var selectedClientGroups = result.selectedClientGroups ?? []
          this.spinner.show()
          await this.questionaireService.sendQuestionaire(questionaire, selectedClients, selectedClientGroups);
          this.spinner.hide()
        }
      })
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { message: this.translate.instant(`Möchtest du den Check-In jetzt {{sendToNameParameter}} zum Ausfüllen versenden?`, {sendToNameParameter: this.sendToName}), title: this.translate.instant('Check-in senden') },
      });
      dialogRef.afterClosed().subscribe(async result => {
        if (result) {
          this.spinner.show()
          await this.questionaireService.sendQuestionaire(questionaire, this.User != null ? [this.User] : null, this.User != null ? [] : null);
          this.spinner.hide()
        }
      });
      this.userService.loadAllAssignedQuestionairesForUser
    }
  }

  getTriggerString(questionaire: Questionaire): string{
    if(TriggerType[questionaire.trigger] == TriggerType.event) {
      if(EventTriggerType2LabelMapping[EventTriggerType[questionaire.eventTrigger]]) {
        return this.translate.instant(EventTriggerType2LabelMapping[EventTriggerType[questionaire.eventTrigger]]) || '';
      }
    }
    else if(questionaire.trigger == TriggerType.recurring) {
      if(questionaire.repetition) {
        if(questionaire.repetition != Repetition.everyXDays) return this.translate.instant(Repetition2LabelMapping[Repetition[questionaire.repetition]])
        else if(questionaire.repetitionMultiplier) return this.translate.instant("Alle repetitionMultiplierParameter Tage", {repetitionMultiplierParameter: questionaire.repetitionMultiplier});
      }
    }
    return ''
  }

  getRecipientsString(questionaire: Questionaire): string{
    let returnString = "";
    let maxLength = 30;
    if(questionaire.assignedGroupNames?.length > 0){
      if(questionaire.assignedGroupNames.includes('Alle')){
        return this.translate.instant("Alle");
      }
      for(let group of questionaire.assignedGroupNames){
        if(this.userService.getClientGroups()?.includes(group)){
          if(returnString.length > maxLength) return (returnString + ", ...");
          if(returnString.length > 0)
            returnString += ", " + group;
          else
            returnString += group;
        }
      }
    }
    if(questionaire.assignedUids.length > 0){
      for(let userUid of questionaire.assignedUids){
        if(returnString.length > maxLength) return (returnString + ", ...");
        let userName = this.userService.getClientsOfCoach()?.find(x => x.uid == userUid)?.getName();
        if(userName){
          if(returnString.length > 0)
            returnString += ", " + userName;
          else
            returnString += userName;
        }
      }
    }
    return returnString;
  }

  getRecipientsCount(questionaire: Questionaire): string{
    let clients = this.userService.getClientsOfCoach();
    let assignedClients: User[] = [];
    if(questionaire.assignedGroupNames?.includes('Alle')){
      return "";
    }
    clients.forEach(client => {
      if(questionaire.assignedUids.includes(client.uid)){
        assignedClients.push(client);
        return;
      }
      if(questionaire.assignedGroupNames?.length > 0){
        for(let groupName of questionaire.assignedGroupNames){
          if(client.metadataUser?.assignedClientGroups?.includes(groupName)){
            assignedClients.push(client);
            break;
          }
        }
      }
    });
    return this.translate.instant('(recipientCountParameter Empfänger)', {recipientCountParameter: assignedClients.length});
  }
}
