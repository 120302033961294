import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Moment } from 'moment';
import { TrainingPlan } from 'src/app/model/training-plan.model';
import { TrainingService } from 'src/app/services/training.service';
import { TrainingPlanTemplatesSelectionDialogComponent } from 'src/app/training/training-plan-templates-selection-dialog/training-plan-templates-selection-dialog.component';

@Component({
  selector: 'app-create-periodic-plan-dialog',
  templateUrl: './create-periodic-plan-dialog.component.html',
  styleUrls: ['./create-periodic-plan-dialog.component.css']
})
export class CreatePeriodicPlanDialogComponent {

  name: string
  startDate: Date
  minDate: Date
  template: TrainingPlan = null

  takeWeekFromLastPlan: boolean = false

  constructor(public dialogRef: MatDialogRef<CreatePeriodicPlanDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { name: string, startDate: Date, minDate: Date }, public dialog: MatDialog, public trainingService: TrainingService) {
    this.name = data.name
    this.startDate = data.startDate
    this.minDate = data.minDate
  }


  public today = new Date()
  startDateFilterTrainingPlanConfig = (d: Moment | null): boolean => {
    if (this.minDate && d?.toDate() < this.minDate && !this.minDate.isSameDate(d?.toDate())) return false
    return true
  }

  onStartDateChanged(date: Date) {
    date.setHours(0,0,0,0);
    this.startDate = date
  }

  async onOpenTrainingPlanTemplatesSelection() {
    let trainingPlanTemplates = await this.trainingService.getTrainingPlanTemplates();
    trainingPlanTemplates = trainingPlanTemplates.filter(x => x.isPeriodicPlan && x.sessions?.filter(x => !x.deleted)?.length > 1);
    this.dialog.open(TrainingPlanTemplatesSelectionDialogComponent, {data: { availableTemplates: trainingPlanTemplates, allowOnlySingleSelection: true}, width: '1000px', autoFocus: false}).afterClosed().subscribe(result => {
      if (result?.shouldTake){
        console.log(result)
        if (result.selectedTemplates?.length == 1) {
          this.template = result.selectedTemplates[0]
       }
      }
    })
  }

  onRemoveTrainingPlanTemplate() {
    this.template = null
  }

  onConfirmDialog() {
    this.dialogRef.close({ name: this.name, startDate: this.startDate, template: this.template, takeWeekFromLastPlan: this.takeWeekFromLastPlan });
  }

  onCancelDialog() {
    this.dialogRef.close()
  }
}
