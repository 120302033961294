import { marker } from "@colsen1991/ngx-translate-extract-marker"
import { TranslateService } from "@ngx-translate/core"

export class PaymentSettings {
    termsAndConditionsPath: string
    companyName: string
    address: string
    email: string
    phone: string
    vatIdNo: string
    invoiceHintText: string
    vatRate: number
    imprintUrl: string
    currency: string;
    landingPageEnabled: boolean
    availablePaymentMethods: string[]
    landingPageCalendlyUrl: string
    customAccentColor: string
    customOnAccentTextColor: string
    invoiceNumberFormat: string
    invoiceNumberIncrement: number
    invoiceNumberNext: number
    alternativeImprintAddress: string

    customerPurchaseConfirmationEmailTemplate: EmailTemplate
    customerInvoiceEmailTemplate: EmailTemplate
    customerKickoffEmailTemplate: EmailTemplate
    customerPaymentRequestEmailTemplate: EmailTemplate
    customerCancelationConfirmationEmailTemplate: EmailTemplate
    customerPurchaseExpirationEmailTemplate: EmailTemplate
    customerInvitationEmailTemplate: EmailTemplate
    customerOfferingEmailTemplate: EmailTemplate

    // Tmp
    termsAndConditionsUrl: string

    constructor();
    constructor(init: PaymentSettings);
    constructor(init?: PaymentSettings) {
        this.termsAndConditionsPath = init && init.termsAndConditionsPath || null
        this.address = init && init.address || null
        this.companyName = init && init.companyName || null
        this.email = init && init.email || null
        this.phone = init && init.phone || null
        this.vatIdNo = init && init.vatIdNo || null
        this.invoiceHintText = init && init.invoiceHintText || null
        this.vatRate = init && init.vatRate != null ? init.vatRate : 19
        this.currency = init && init.currency || 'eur';
        this.landingPageEnabled = init && init.landingPageEnabled || false
        this.landingPageCalendlyUrl = init && init.landingPageCalendlyUrl || null
        this.customAccentColor = init && init.customAccentColor || null
        this.customOnAccentTextColor = init && init.customOnAccentTextColor || null
        this.availablePaymentMethods = init && init.availablePaymentMethods || []
        this.imprintUrl = init && init.imprintUrl || null
        this.customerPurchaseConfirmationEmailTemplate = init && init.customerPurchaseConfirmationEmailTemplate ? new EmailTemplate(init.customerPurchaseConfirmationEmailTemplate) : null
        this.customerKickoffEmailTemplate = init && init.customerKickoffEmailTemplate ? new EmailTemplate(init.customerKickoffEmailTemplate) : null
        this.customerInvoiceEmailTemplate = init && init.customerInvoiceEmailTemplate ? new EmailTemplate(init.customerInvoiceEmailTemplate) : null
        this.customerPaymentRequestEmailTemplate = init && init.customerPaymentRequestEmailTemplate ? new EmailTemplate(init.customerPaymentRequestEmailTemplate) : null
        this.customerCancelationConfirmationEmailTemplate = init && init.customerCancelationConfirmationEmailTemplate ? new EmailTemplate(init.customerCancelationConfirmationEmailTemplate) : null
        this.customerPurchaseExpirationEmailTemplate = init && init.customerPurchaseExpirationEmailTemplate ? new EmailTemplate(init.customerPurchaseExpirationEmailTemplate) : null
        this.customerInvitationEmailTemplate = init && init.customerInvitationEmailTemplate ? new EmailTemplate(init.customerInvitationEmailTemplate) : null
        this.customerOfferingEmailTemplate = init && init.customerOfferingEmailTemplate ? new EmailTemplate(init.customerOfferingEmailTemplate) : null
        this.invoiceNumberFormat = init && init.invoiceNumberFormat || null
        this.invoiceNumberIncrement = init && init.invoiceNumberIncrement != null ? init.invoiceNumberIncrement : 1
        this.invoiceNumberNext = init && init.invoiceNumberNext != null ? init.invoiceNumberNext : 1
        this.alternativeImprintAddress = init && init.alternativeImprintAddress || null
    }

    getInvoiceFormatPreview() {
        return this.invoiceNumberFormat.replace('{{Number}}', this.invoiceNumberNext.toString()).replace('{{Year}}', new Date().getFullYear().toString()).replace('{{Month}}', (new Date().getMonth() + 1).toString()).replace('{{Day}}', new Date().getDate().toString())
    }

    isInvoiceNumberFormatValid() {
        if (!this.invoiceNumberFormat) return true
        if (!this.invoiceNumberFormat.includes('{{Number}}')) return false
        if (this.invoiceNumberFormat.includes('/')) return false
        return true
    }
    isEmailValid() {
        return this.email?.length > 0 && this.email.includes('@') && this.email.includes('.')
    }

    isInformationValid() {
        return this.companyName?.length > 0 && this.address?.length > 0 && this.isEmailValid() && this.imprintUrl?.length > 0 && this.availablePaymentMethods?.length > 0
    }

    areTemplatesValid() {
        return this.customerPurchaseConfirmationEmailTemplate && this.customerKickoffEmailTemplate && this.customerInvoiceEmailTemplate && this.customerPaymentRequestEmailTemplate && this.customerCancelationConfirmationEmailTemplate && this.customerPurchaseExpirationEmailTemplate
    }

    asMap() {
        return {
            termsAndConditionsPath: this.termsAndConditionsPath,
            address: this.address,
            companyName: this.companyName,
            email: this.email,
            phone: this.phone,
            vatIdNo: this.vatIdNo,
            invoiceHintText: this.invoiceHintText,
            vatRate: this.vatRate,
            currency: this.currency,
            imprintUrl: this.imprintUrl,
            landingPageEnabled: this.landingPageEnabled,
            landingPageCalendlyUrl: this.landingPageCalendlyUrl?.length > 0 ? this.landingPageCalendlyUrl : null,
            customAccentColor: this.customAccentColor?.length > 0 ? this.customAccentColor : null,
            customOnAccentTextColor: this.customOnAccentTextColor?.length > 0 ? this.customOnAccentTextColor : null,
            availablePaymentMethods: this.availablePaymentMethods,
            invoiceNumberFormat: this.invoiceNumberFormat?.length > 0 ? this.invoiceNumberFormat : null,
            invoiceNumberIncrement: this.invoiceNumberIncrement,
            invoiceNumberNext: this.invoiceNumberNext,
            customerPurchaseConfirmationEmailTemplate: this.customerPurchaseConfirmationEmailTemplate?.asMap() ?? null,
            customerKickoffEmailTemplate: this.customerKickoffEmailTemplate?.asMap() ?? null,
            customerInvoiceEmailTemplate: this.customerInvoiceEmailTemplate?.asMap() ?? null,
            customerPaymentRequestEmailTemplate: this.customerPaymentRequestEmailTemplate?.asMap() ?? null,
            customerCancelationConfirmationEmailTemplate: this.customerCancelationConfirmationEmailTemplate?.asMap() ?? null,
            customerPurchaseExpirationEmailTemplate: this.customerPurchaseExpirationEmailTemplate?.asMap() ?? null,
            customerInvitationEmailTemplate: this.customerInvitationEmailTemplate?.asMap() ?? null,
            customerOfferingEmailTemplate: this.customerOfferingEmailTemplate?.asMap() ?? null,
            alternativeImprintAddress: this.alternativeImprintAddress?.length > 0 ? this.alternativeImprintAddress : null
        }
    }


    getPrintablePaymentMethods(translate: TranslateService) {
        var result = ''
        if (this.availablePaymentMethods.includes('sepa_debit')) result += translate.instant(marker('Sepa-Lastschrift')) + ', '
        if (this.availablePaymentMethods.includes('bank_transfer')) result += translate.instant(marker('Banküberweisung')) + ', '
        if (this.availablePaymentMethods.includes('card')) result += translate.instant(marker('Kreditkarte')) + ', '
        return result.substring(0, result.length - 2)
    }
}

export class EmailTemplate {
    subject: string
    heading: string
    body: string

    constructor();
    constructor(init: EmailTemplate);
    constructor(init?: EmailTemplate) {
        this.subject = init && init.subject || null
        this.heading = init && init.heading || null
        this.body = init && init.body || null
    }

    asMap() {
        return {
            subject: this.subject,
            heading: this.heading,
            body: this.body
        }
    }

    clone() {
        return new EmailTemplate(this)
    }
}
