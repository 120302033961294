import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { NumberToShortWeekDayStringsMapping } from 'src/app/model/task.model';
import { TrainingPlan, TrainingSession, Week } from 'src/app/model/training-plan.model';
import { FirestoreNutritionPlanService } from 'src/app/services/firestore-nutritionplan.service';
import { LanguageService } from 'src/app/services/language.service';
import { TrainingPlanEditorComponent } from '../../training-plan-editor.component';
import { LanguageDictionary } from 'src/app/model/languagedictionary.model';

@Component({
  selector: 'app-sessions-column',
  templateUrl: './sessions-column.component.html',
  styleUrls: ['./sessions-column.component.css']
})
export class SessionsColumnComponent {

  constructor(public languageService: LanguageService, private toastr: ToastrService) { }

  @Input({ required: true }) isBusy: boolean = false;

  @Input({ required: true }) sessionsColumnClosed: boolean;
  @Output() sessionsColumnClosedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get sessionsColumnClosedInternal(): boolean {
    return this.sessionsColumnClosed;
  }
  public set sessionsColumnClosedInternal(value: boolean) {
    this.sessionsColumnClosed = value;
    this.sessionsColumnClosedChange.emit(value);
  }

  @Input({ required: true }) draggingWeek: Week;
  @Output() draggingWeekChange: EventEmitter<Week> = new EventEmitter<Week>();

  public get draggingWeekInternal(): Week {
    return this.draggingWeek;
  }
  public set draggingWeekInternal(value: Week) {
    this.draggingWeek = value;
    this.draggingWeekChange.emit(value);
  }

  @Input({ required: true }) draggingSession: TrainingSession;
  @Output() draggingSessionChange: EventEmitter<TrainingSession> = new EventEmitter<TrainingSession>();

  public get draggingSessionInternal(): TrainingSession {
    return this.draggingSession;
  }
  public set draggingSessionInternal(value: TrainingSession) {
    this.draggingSession = value;
    this.draggingSessionChange.emit(value);
  }


  @Input({ required: true }) focusedBeforeDropzoneWeek: Week;
  @Output() focusedBeforeDropzoneWeekChange: EventEmitter<Week> = new EventEmitter<Week>();

  public get focusedBeforeDropzoneWeekInternal(): Week {
    return this.focusedBeforeDropzoneWeek;
  }
  public set focusedBeforeDropzoneWeekInternal(value: Week) {
    this.focusedBeforeDropzoneWeek = value;
    this.focusedBeforeDropzoneWeekChange.emit(value);
  }
  
  @Input({ required: true }) focusedAfterDropzoneWeek: Week;
  @Output() focusedAfterDropzoneWeekChange: EventEmitter<Week> = new EventEmitter<Week>();

  public get focusedAfterDropzoneWeekInternal(): Week {
    return this.focusedAfterDropzoneWeek;
  }
  public set focusedAfterDropzoneWeekInternal(value: Week) {
    this.focusedAfterDropzoneWeek = value;
    this.focusedAfterDropzoneWeekChange.emit(value);
  }
  
  @Input({ required: true }) focusedWeekDayForSessionDrop: Date;
  @Output() focusedWeekDayForSessionDropChange: EventEmitter<Date> = new EventEmitter<Date>();

  public get focusedWeekDayForSessionDropInternal(): Date {
    return this.focusedWeekDayForSessionDrop;
  }
  public set focusedWeekDayForSessionDropInternal(value: Date) {
    this.focusedWeekDayForSessionDrop = value;
    this.focusedWeekDayForSessionDropChange.emit(value);
  }
  
  @Input({ required: true }) focusedWeekNumberForSessionDrop: number;
  @Output() focusedWeekNumberForSessionDropChange: EventEmitter<number> = new EventEmitter<number>();

  public get focusedWeekNumberForSessionDropInternal(): number {
    return this.focusedWeekNumberForSessionDrop;
  }
  public set focusedWeekNumberForSessionDropInternal(value: number) {
    this.focusedWeekNumberForSessionDrop = value;
    this.focusedWeekNumberForSessionDropChange.emit(value);
  }

  @Input({ required: true }) weekDaysByWeekId: Map<string, Date[]> = new Map<string, Date[]>();
  @Output() weekDaysByWeekIdChange: EventEmitter<Map<string, Date[]>> = new EventEmitter<Map<string, Date[]>>();

  public get weekDaysByWeekIdInternal(): Map<string, Date[]> {
    return this.weekDaysByWeekId;
  }
  public set weekDaysByWeekIdInternal(value: Map<string, Date[]>) {
    this.weekDaysByWeekId = value;
    this.weekDaysByWeekIdChange.emit(value);
  }
  // weekDaysByWeekId: any = {}; // Assuming data structure

  @Input({ required: true }) trainingPlan: TrainingPlan;

  @Input({ required: true }) selectedSession: TrainingSession;
  @Output() selectedSessionChange: EventEmitter<TrainingSession> = new EventEmitter<TrainingSession>();

  public get selectedSessionInternal(): TrainingSession {
    return this.selectedSession;
  }
  public set selectedSessionInternal(value: TrainingSession) {
    this.selectedSession = value;
    this.selectedSessionChange.emit(value);
  }

  @Input({ required: true }) focusedAfterDropzoneSession: TrainingSession;
  @Output() focusedAfterDropzoneSessionChange: EventEmitter<TrainingSession> = new EventEmitter<TrainingSession>();

  public get focusedAfterDropzoneSessionInternal(): TrainingSession {
    return this.focusedAfterDropzoneSession;
  }
  public set focusedAfterDropzoneSessionInternal(value: TrainingSession) {
    this.focusedAfterDropzoneSession = value;
    this.focusedAfterDropzoneSessionChange.emit(value);
  }

  @Input({ required: true }) readOnlyMode: boolean;

  @Input({ required: true }) hasChanges: boolean;
  @Output() hasChangesChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggleSessionsColumn() {
    this.sessionsColumnClosedInternal = !this.sessionsColumnClosed;
  }

  closeSessionsColumn() {
    this.sessionsColumnClosedInternal = true;
  }

  
  isFirstDayOfWeekInThePast(week: Week){
    let weekDays = this.weekDaysByWeekId[week.id];
    if(weekDays){
      let today = new Date();
      today.setHours(0,0,0,0);
      let firstDay = weekDays[0];
      if(firstDay){
        return firstDay < today;
      }
    }
    return false;
  }

  isAnyTrackedSessionInWeek(week: Week){
    return this.trainingPlan.sessions.find(x => x.weekId == week.id && !x.deleted && !x.isRestDay && x.isTracked) != null;
  }
  
  onDragWeekStarted(event, week: Week){
    if(this.isWeekInThePast(week) || this.isAnyTrackedSessionInWeek(week)){
      this.toastr.info('Verschieben vergangener Wochen nicht möglich.', 'Verschieben nicht möglich', {
        positionClass: 'toast-bottom-center'
      });
      return false;
    }
    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.setData('text', 'week')
    setTimeout(() => {
      this.draggingWeekInternal = week;
    }, 100);
    return true;
  }
  
  onDragWeekEnd(){
    this.draggingWeekInternal = null;
    this.focusedAfterDropzoneWeek = null;
    this.focusedBeforeDropzoneWeek = null;
  }

  
  isNextWeekInThePast(week: Week){
    if(this.trainingPlan.isTemplate) return false;
    let weekDays = this.weekDaysByWeekId[week.id];
    if(weekDays){
      let today = new Date();
      today.setHours(0,0,0,0);
      let lastDay = weekDays[0]?.clone();
      if(lastDay){
        lastDay.setDate(lastDay.getDate() + 7)
        return lastDay < today;
      }
    }
    return false;
  }
  
  isWeekInThePast(week: Week){
    if(this.trainingPlan.isTemplate) return false;
    let weekDays = this.weekDaysByWeekId[week.id];
    if(weekDays){
      let today = new Date();
      today.setHours(0,0,0,0);
      let lastDay = weekDays[weekDays.length - 2];
      if(lastDay){
        return lastDay < today;
      }
    }
    return false;
  }

  @Output() addWeek: EventEmitter<void> = new EventEmitter<void>();
  public addWeekInternal(){
    this.addWeek.emit();
  }
  
  @Output() removeWeek: EventEmitter<Week> = new EventEmitter<Week>();
  removeWeekInternal(week: Week){
    this.removeWeek.emit(week);
  }

  @Output() duplicateWeek: EventEmitter<Week> = new EventEmitter<Week>();
  duplicateWeekInternal(week: Week){
    this.duplicateWeek.emit(week);
  }

  @Input() copyWeekFrom: Week = null;

  @Output() copyWeek: EventEmitter<Week> = new EventEmitter<Week>();
  copyWeekInternal(week: Week){
    this.copyWeek.emit(week);
  }

  @Output() pasteWeek: EventEmitter<Week> = new EventEmitter<Week>();
  pasteWeekInternal(week?: Week){
    this.pasteWeek.emit(week);
  }

  @Output() saveWeekAsTemplate: EventEmitter<Week> = new EventEmitter<Week>();
  saveWeekAsTemplateInternal(week: Week){
    this.saveWeekAsTemplate.emit(week);
  }

  @Output() addWeekFromTemplate: EventEmitter<void> = new EventEmitter<void>();
  public addWeekFromTemplateInternal(){
    this.addWeekFromTemplate.emit();
  }

  onHideWeek(week: Week){
    week.hide = true;
  }

  onShowWeek(week: Week){
    week.hide = false;
    this.draggingSessionInternal = null;
    this.draggingWeekInternal = null;
  }

  @Output() dropWeek: EventEmitter<{week: Week, after: boolean}> = new EventEmitter<{week: Week, after: boolean}>();
  onDropWeekInternal(week: Week, after: boolean){
    this.dropWeek.emit({week: week, after: after});
  }

  
  onDragOverWeek($event, week: Week, after: boolean) {
    if(after){
      this.focusedAfterDropzoneWeek = week;
      this.focusedBeforeDropzoneWeek = null;
    }
    else {
      this.focusedBeforeDropzoneWeek = week;
      this.focusedAfterDropzoneWeek = null;
    }
    $event.preventDefault();
  }

  
  onDragLeaveWeek($event, week: Week, after: boolean){
    if(after){
      this.focusedAfterDropzoneWeek = null;
    }
    else {
      this.focusedBeforeDropzoneWeek = null;
    }
    $event.preventDefault();
  }

  @Output() dropOnWeekDayItem: EventEmitter<{weekDay: Date, weekId: string}> = new EventEmitter<{weekDay: Date, weekId: string}>();
  onDropOnWeekDayItemInternal(weekDay: Date, weekId: string) {
    this.dropOnWeekDayItem.emit({weekDay: weekDay, weekId: weekId});
  }

  
  onDragOverWeekDayItem(event, weekDay: Date, weekNumber: number){
    if(this.draggingSessionInternal && !this.isDayInThePast(weekDay) && !this.readOnlyMode){
      this.focusedWeekDayForSessionDrop = weekDay;
      this.focusedWeekNumberForSessionDrop = weekNumber;
      this.focusedAfterDropzoneSessionInternal = null;
      event.dataTransfer.effectAllowed = 'move'
      event.preventDefault();
    }
  }  
  
  onDragEnterWeekDayItem(event, weekDay: Date, weekNumber: number){
    if(this.draggingSessionInternal && !this.isDayInThePast(weekDay) && !this.readOnlyMode){
      this.focusedWeekDayForSessionDrop = weekDay;
      this.focusedWeekNumberForSessionDrop = weekNumber;
      this.focusedAfterDropzoneSession = null;
      event.dataTransfer.effectAllowed = 'move'
      event.preventDefault();
    }
  }

  onDragLeaveWeekDayItem(event, weekDay: Date, weekNumber: number){
    if(this.draggingSessionInternal && !this.isDayInThePast(weekDay) && !this.readOnlyMode){
      this.focusedWeekDayForSessionDrop = null;
      this.focusedWeekNumberForSessionDrop = null;
      this.focusedAfterDropzoneSession = null;
      event.preventDefault();
    }
  }


  
  getWeekDay(weekDay: Date){
    let weekDayNumber = this.trainingPlan.getWeekDayByDate(weekDay);
    return NumberToShortWeekDayStringsMapping[weekDayNumber];
  }

  @Output() addSessionToDay: EventEmitter<{date: Date, weekId: string}> = new EventEmitter<{date: Date, weekId: string}>();
  public addSessionToDayInternal(date: Date, weekId: string){
    this.addSessionToDay.emit({date: date, weekId: weekId});
  }

  @Output() addSessionFromTemplate: EventEmitter<{plannedDay: Date, week: Week}> = new EventEmitter<{plannedDay: Date, week: Week}>();
  public addSessionFromTemplateInternal(plannedDay: Date = null, week: Week = null){
    this.addSessionFromTemplate.emit({plannedDay: plannedDay, week: week});
  }
  onDragOverSession(event, session: TrainingSession){
    if(this.draggingSessionInternal && !this.isDayInThePast(session.plannedDate)){
      this.focusedAfterDropzoneSession = session;
      event.preventDefault();
    }
  }

  
  dropTrainingSession(event: CdkDragDrop<TrainingSession[]>) {
    let sessions = this.trainingPlan.sessions.sort((a,b) => Number(!b.deleted) - Number(!a.deleted))
    let sessionAtNewIndex = sessions[event.currentIndex];
    let sessionAtOldIndex = sessions[event.previousIndex];
    if(this.trainingPlan.isPeriodicPlan && sessionAtNewIndex && sessionAtOldIndex){
      if(sessionAtNewIndex.weekId != sessionAtOldIndex.weekId){
        sessionAtOldIndex.weekId = sessionAtNewIndex.weekId
        sessionAtOldIndex.plannedDate = null
      }
    }

    moveItemInArray(this.trainingPlan.sessions, event.previousIndex, event.currentIndex);
    this.hasChangesChange.emit(true);
  }

  onSessionSelectionChanged(session: TrainingSession){
    this.selectedSessionInternal = session;
  }

  onDragStartSession(event: DragEvent, session: TrainingSession, colorPicker: any){
    if(session.isTracked || this.colorPickerOpened) return false;
    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.setData('text', 'session')
    
    setTimeout(() => {
      this.draggingSessionInternal = session;
    }, 100);
    return true;
  }

  @Output() addSession: EventEmitter<{isRest: boolean, addToNewWeek: boolean, newSession: TrainingSession}> = new EventEmitter<{isRest: boolean, addToNewWeek: boolean, newSession: TrainingSession}>();
  addSessionInternal(isRest:boolean, addToNewWeek: boolean, newSession: TrainingSession = new TrainingSession()){
    this.addSession.emit({isRest: isRest, addToNewWeek: addToNewWeek, newSession: newSession});
  }
  
  
  public colorPickerOpened: boolean = false;
  colorPickerToggleChanged(value: boolean){
    this.colorPickerOpened = value;
    if(!value) {
      this.setCalendarItemsInternal();
    }
  }
  
  onDragEndSession() {
    this.draggingSessionInternal = null;
    this.focusedWeekDayForSessionDrop = null;
    this.focusedWeekNumberForSessionDrop = null;
    this.focusedAfterDropzoneSession = null;
  }


  @Output() dropAfterSession: EventEmitter<TrainingSession> = new EventEmitter<TrainingSession>();
  onDropAfterSessionInternal(session: TrainingSession){
    this.dropAfterSession.emit(session);
  }

  @Output() setCalendarItems: EventEmitter<void> = new EventEmitter<void>();
  setCalendarItemsInternal() {
    this.setCalendarItems.emit();
  }
  
  getWeekDayDate(weekDay: Date){
    return ', ' + weekDay.asShortFormatedString();
  }

  setIndicatorColor(session: TrainingSession, color: string){
    if(session.baseSessionId){
      let baseSession = this.trainingPlan.sessions.find(x => !x.deleted && (x.id == session.baseSessionId || x.baseSessionId == session.baseSessionId));
      if(baseSession){
        baseSession.indicatorColor = color;
        this.setCalendarItemsInternal();
        return;
      }
    }
    else {
      let duplicatedSession = this.trainingPlan.sessions.find(x => !x.deleted && x.baseSessionId == session.id)
      if(duplicatedSession){
        duplicatedSession.indicatorColor = color;
      }
    }
    session.indicatorColor = color;
    this.setCalendarItemsInternal();
  }
  
  getBaseSessionReferenceColor(session: TrainingSession){
    return TrainingPlanEditorComponent.getBaseSessionReferenceColor(session, this.trainingPlan.sessions);
  }

  @Output() openTrackedTrainingSession: EventEmitter<TrainingSession> = new EventEmitter<TrainingSession>();
  onOpenTrackedTrainingSessionInternal(session: TrainingSession){
    this.openTrackedTrainingSession.emit(session);
  }

  
  getSessionColor(sessionId: string){
    return TrainingPlanEditorComponent.getSessionColor(sessionId, this.trainingPlan.sessions);
  }

  hasReferenceSession(session: TrainingSession){
    if(session.baseSessionId) {
      let baseSession = this.trainingPlan.sessions.find(x => !x.deleted && x.id == session.baseSessionId);
      if(baseSession){
        return true;
      }
    }
    else {
      let duplicatedSession = this.trainingPlan.sessions.find(x => !x.deleted && x.baseSessionId == session.id)
      if(duplicatedSession){
        return true;
      }
    }

    return false;
  }
  

  public availableBaseSessions: { session: TrainingSession; color: string; }[] = [];

  getAvailableBaseSessions(session: TrainingSession): { session: TrainingSession; color: string; }[]{
    if(session.baseSessionId){
      let availableSessions = this.trainingPlan.sessions.filter(x => !x.deleted && !x.isRestDay && x.id != session.id && !this.hasReferenceSession(x));
      return availableSessions.map((session, index) => {
        return { session: session, color: this.getSessionColor(session.id) }
      });
    }
    let availableSessions = this.trainingPlan.sessions.filter(x => !x.deleted && !x.baseSessionId && x.id != session.id && x.id != session.baseSessionId);
    return availableSessions.map((session, index) => {
      return { session: session, color: this.getSessionColor(session.id) }
    });
  }
  
  setAvailableBaseSessions(session: TrainingSession){
    this.availableBaseSessions = this.getAvailableBaseSessions(session);
  }

  @Output() duplicateSession: EventEmitter<TrainingSession> = new EventEmitter<TrainingSession>();
  duplicateSessionInternal(session: TrainingSession) {
    this.duplicateSession.emit(session);
  }

  @Output() removeSession: EventEmitter<TrainingSession> = new EventEmitter<TrainingSession>();
  removeSessionInternal(session: TrainingSession){
    this.removeSession.emit(session);
  }

  disconnectFromBaseSession(session: TrainingSession){
    session.baseSessionId = null;
    session.indicatorColor = null;
  }

  @Output() connectToBaseSession: EventEmitter<{session: TrainingSession, baseSession: TrainingSession}> = new EventEmitter<{session: TrainingSession, baseSession: TrainingSession}>();
  connectToBaseSessionInternal(session: TrainingSession, baseSession: TrainingSession){
    this.connectToBaseSession.emit({session: session, baseSession: baseSession});
  }

  
  getWeekRangeString(week: Week){
    if(this.trainingPlan.isTemplate){
      return 'Woche ' + (TrainingPlanEditorComponent.getWeekNumberByWeekId(this.trainingPlan, week.id) + 1);
    }
    let weekDays = this.weekDaysByWeekId[week.id];
    if(weekDays){
      let firstDay = weekDays[0];
      let lastDay = weekDays[weekDays.length - 2];
      return NumberToShortWeekDayStringsMapping[firstDay.getDay()] + ', ' + firstDay.asShortFormatedString() + ' - ' + NumberToShortWeekDayStringsMapping[lastDay.getDay()] + ', ' + lastDay.asShortFormatedString();
    }
    return '';
  }

  canConnectAnyBaseSession(session: TrainingSession){
    return this.trainingPlan.sessions.find(x => !x.deleted && x.id != session.id && !x.isRestDay) != null;
  }

  @Output() saveSessionAsTemplate: EventEmitter<TrainingSession> = new EventEmitter<TrainingSession>();
  saveSessionAsTemplateInternal(session: TrainingSession){
    this.saveSessionAsTemplate.emit(session);
  }

  //duplicated from training-plan-editor.component.ts, maybe join
  isDayInThePast(weekDay: Date){
    if(this.trainingPlan.isTemplate) return false;
    let today = new Date();
    today.setHours(0,0,0,0);
    return weekDay != null && weekDay < today;
  }

}
