import { PalValue } from './../model/user.model';
import { Component, OnDestroy, OnInit, Input, ComponentFactory, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FirestoreService } from '../services/firestore.service';
import { User } from '../model/user.model';
import { UtilityService } from '../services/utility.service';
import { NutritionalSummary } from '../model/nutritionalsummary.model';
import { map, subscribeOn } from 'rxjs/operators';
import { combineLatest, Observable, Subscription, timer } from 'rxjs';
import { ChatService } from '../services/chat.service';
import { ToastrService } from 'ngx-toastr';
import { NutritionService } from '../services/nutrition.service';
import { BodyData } from '../model/bodydata.model';
import { registerPalette } from "devextreme/viz/palette";
import { NutritionStatisticsItem } from '../model/nutritionstatistics.model';
import '../prototypes'
import { DailyCondition } from '../model/dailycondition.model';
import { Metric } from '../model/metric.model';
import { MetricData } from '../model/metricdata.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardComponent } from '../dashboard/dashboard-v1/dashboard.component';
import { NutritionalValuePopoverComponent } from '../nutritional-value-popover/nutritional-value-popover.component';
import { NutritionalValueHolder } from '../model/basenutritionfact.model';
import { NutritionalGoal } from '../model/nutritionalgoal.model';
import { MetricDataImageDialogComponent } from '../metric-data-image-dialog/metric-data-image-dialog.component';
import {MatDialog } from '@angular/material/dialog';
import { ChartExportService } from '../services/chart-export.service';
import { DxDataGridComponent, DxDataGridModule } from "devextreme-angular";
import { Moment } from 'moment';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-body-data',
  templateUrl: './body-data.component.html',
  styleUrls: ['./body-data.component.css']
})
export class BodyDataComponent implements OnInit, OnDestroy {
  @ViewChild(DxDataGridComponent) datagrid: DxDataGridComponent
  private _selectedUserUid: string;

  @Input() set selectedUserUid(value: string) {
    if (this._selectedUserUid != value) {
      this._selectedUserUid = value;
      this.ngOnInit();
    }
  }

  public math = Math
  public componentFactory: ComponentFactory<any>;

  public environment = environment

  public displayQuestionaireMetrics: boolean = true;

  onDisplayQuestionaireMetricsChanged(){
    this.displayQuestionaireMetrics = !this.displayQuestionaireMetrics;
    this.updateBodyStatisticsGraph(this.displayedUser);
  }

  public weekDays = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
  public static weekdays() {
    return this.weekdays
  }
  public defaultBodyDataGraphTypes: DropdownItem[] = [
    // {id: 0, name: "Gewicht", tmp: '', unit: null},
    {id: 1, name: "Umfänge", tmp: '', unit: null, order: 1},
    // {id: 2, name: "KFA", tmp: '', unit: null},
    // {id: 3, name: "BMI", tmp: '', unit: null},
    //{id: 4, name: "Schlaf (Wohlbefinden)", tmp: '', unit: null},
    {id: 5, name: "Wasserzufuhr", tmp: '', unit: null, order: 2},
  ]
  public additionalBodyDataGraphTypes: DropdownItem[] = []

  public bodyDataGraphTimeRanges: DropdownItem[] = [
    {id: 0, name: "Letzte 14 Tage", tmp: '', unit: null, order: 99},
    {id: 1, name: "Letzte 31 Tage", tmp: '', unit: null, order: 99},
    {id: 2, name: "Letzte 90 Tage", tmp: '', unit: null, order: 99},
    {id: 3, name: "Letzte 180 Tage", tmp: '', unit: null, order: 99},
    {id: 4, name: "Letzte 365 Tage", tmp: '', unit: null, order: 99},
    {id: 5, name: "Gesamter Zeitraum", tmp: '', unit: null, order: 99},
    {id: 6, name: "Benutzerdefinierter Zeitraum", tmp: '', unit: null, order: 99},
  ]

  public additionalBodyDataGraphTypesForTable = []

  public selectedBodyDataGraphType: DropdownItem = this.defaultBodyDataGraphTypes[0]
  public selectedBodyDataGraphTypes: DropdownItem[] = []
  public selectedBodyDataGraphTimeRange: DropdownItem = this.bodyDataGraphTimeRanges[1]

  public selectedStartDate: Date = undefined;
  public selectedEndDate: Date = undefined

  onStartDateChanged(startDate: Date) {
    this.selectedStartDate = startDate;
    if(this.selectedStartDate && this.selectedEndDate){
      this.selectedBodyDataGraphTimeRangeChanged();
    }
  }
  onEndDateChanged(endDate: Date) {
    this.selectedEndDate = endDate;
    if(this.selectedStartDate && this.selectedEndDate){
      this.selectedBodyDataGraphTimeRangeChanged();
    }
  }

  public today = new Date()
  startDateFilterBodyData = (d: Moment | null): boolean => {
    if (d?.toDate() > this.today) return false
    return true
  }
  endDateFilterBodyData = (d: Moment | null): boolean => {
    if (d?.toDate() > this.today) return false
    return true
  }

  onBodyDataGraphTypeChanged(selection: DropdownItem) {
    if (this.selectedBodyDataGraphTypes.includes(selection)) {
      this.selectedBodyDataGraphTypes.forEach( (item, index) => {
        if (item == selection) this.selectedBodyDataGraphTypes.splice(index, 1);
      });
    } else {
      this.selectedBodyDataGraphTypes.push(selection)
    }
    this.saveSelectedBodyDataGraphTypesInPortalSettings()
    this.updateBodyStatisticsGraph(this.displayedUser)
  }

  saveSelectedBodyDataGraphTypesInPortalSettings(){
      var currentUser = this.userService.getLoggedInUser();
      if(currentUser?.isCoach){
        // saves new selectedBodyDataGraphTypes
        this.selectedBodyDataGraphTypes.map(x => x.name).forEach(typeName => {
          if(!currentUser.portalSettingsCoach?.selectedBodyDataGraphTypNames?.includes(typeName)){
            currentUser.portalSettingsCoach.selectedBodyDataGraphTypNames.push(typeName)
          }
        });
        currentUser.portalSettingsCoach?.selectedBodyDataGraphTypNames?.forEach( (typeName, index) => {
          // removes all deselected bodyDataGraphTypes
          if(!this.selectedBodyDataGraphTypes.map(x => x.name).includes(typeName)){
            if(this.additionalBodyDataGraphTypes.map(x => x.name).includes(typeName) || this.defaultBodyDataGraphTypes.map(x => x.name).includes(typeName)){
              currentUser.portalSettingsCoach.selectedBodyDataGraphTypNames.splice(index, 1);
            }
          }
        });
        // currentUser.portalSettingsCoach.selectedBodyDataGraphTypNames = this.selectedBodyDataGraphTypes.map(x => x.name) //would simply store all SelectedBodyDataGraphTypes, but causes types that the current user has not selected to be removed as well
        this.userService.updatePortalSettingsForCoach(currentUser)
      }
  }
  loadPreviousSelectedBodyDataGraphTypes(){
    var currentUser = this.userService.getLoggedInUser();
    var previousSelectedBodyDataGraphTypNames = []
    if(currentUser.isCoach){
      previousSelectedBodyDataGraphTypNames = currentUser.portalSettingsCoach.selectedBodyDataGraphTypNames
    }
    if(previousSelectedBodyDataGraphTypNames.length == 0) {
      // if(this.selectedBodyDataGraphTypes?.length == 0) this.selectedBodyDataGraphTypes.push(this.defaultBodyDataGraphTypes[0])
      return
    }
    this.selectedBodyDataGraphTypes = []
    this.additionalBodyDataGraphTypes.forEach(type => {
      if(previousSelectedBodyDataGraphTypNames.includes(type.name)){
        if (!this.selectedBodyDataGraphTypes.map(x => x.name).includes(type.name)) {
          this.selectedBodyDataGraphTypes.push(type)
        }
      }
    });
    this.defaultBodyDataGraphTypes.forEach(type => {
      if(previousSelectedBodyDataGraphTypNames.includes(type.name)){
        if (!this.selectedBodyDataGraphTypes.map(x => x.name).includes(type.name)) {
          this.selectedBodyDataGraphTypes.push(type)
        }
      }
    });
    if(this.selectedBodyDataGraphTypes.length == 0){
      let bodyWeightType = this.additionalBodyDataGraphTypes.find(x => x.tmp == 'metricbodyWeight')
      if(bodyWeightType != null){
        this.selectedBodyDataGraphTypes.push(bodyWeightType)
      }
    }
  }

  onBodyDataGraphTimeRangeChanged(selection: DropdownItem) {
    this.selectedBodyDataGraphTimeRange = selection;
    this.selectedBodyDataGraphTimeRangeChanged();
  }

  selectedBodyDataGraphTimeRangeChanged() {
    this.loadStatisticsDataForUser(this.displayedUser)
    this.updateBodyStatisticsGraph(this.displayedUser)
    this.updateVisibleImages(this.displayedUser)
  }

  // Logged-in User
  public user: User;
  // Displayed/Selected User
  public displayedUser: User;

  public currentDate = new Date();

  public isUser: boolean;

  public isCustomer = false;
  public navBarTriggered;

  private subscriptionBodyData: Subscription;

  allStatisticsGraphItems: any[] = []
  tableStatisticsGraphItems: any[] = []
  displayedStatisticsGraphItems: any[] = []
  progressStatisticsGraphData: any[] = []
  statisticsGraphAxis: any[] = []
  displayedMetricInGraph: Metric = null
  displayedMetricsInGraph: Metric[] = []
  progressStatisticsGraphStrips: any[] = []
  showDataTable: boolean = false
  canShowDataTable: boolean = false

  aggregateChartData: boolean = false


  nutritionStatisticsGraphData: NutritionStatisticsItem[] = []
  weeklyReportCalorieIntake: number = 0
  weeklyReportCalorieGoal: number = 0
  weeklyReportAbsoluteDeviation: number = 0
  weeklyReportDeviationPercentage: number = 0

  onShowDataTableChanged(value: boolean) {
    this.showDataTable = value
  }

  onAggregateChartDataChanged(value: boolean){
    this.aggregateChartData = value
  }
  
  customizeBodyDataGraphYAxisText = (arg: any) => {
    if (this.selectedBodyDataGraphType.id == 0) {
      return arg.valueText + " kg";
    } else if (this.selectedBodyDataGraphType.id == 1) {
      return arg.valueText + " cm";
    } else if (this.selectedBodyDataGraphType.id == 2) {
      return arg.valueText + "%";
    } else if (this.selectedBodyDataGraphType.id == 4) {
      var sleepDuration = parseInt(arg.valueText)
      return Math.floor(sleepDuration / 60) + ':' + ((sleepDuration % 60) < 10 ? '0' : '') + (sleepDuration % 60) + ' h';
    } else if (this.selectedBodyDataGraphType.id == 5) {
      return arg.valueText + " l";
    } else if (this.selectedBodyDataGraphType.id == 6 && this.displayedMetricInGraph != null) {
      if (this.displayedMetricInGraph.isMetricTypeNumber()) {
        return arg.valueText + " " + (this.displayedMetricInGraph.unit ?? '');
      } else if (this.displayedMetricInGraph.isMetricTypeSelection()) {
        var value = parseInt(arg.valueText)
        return this.displayedMetricInGraph.getSelectableValues()[value]
      } else if (this.displayedMetricInGraph.isMetricTypeYesNo()) {
        var value = parseInt(arg.valueText)
        return value == 0 ? 'Nein' : 'Ja'
      } else if (this.displayedMetricInGraph.isMetricTypeDuration()) {
        var duration = parseInt(arg.valueText)
        return Math.floor(duration / 60) + ':' + ((duration % 60) < 10 ? '0' : '') + (duration % 60) + ' h';
      } else {
        return arg.valueText
      }
    } else {
      return arg.valueText
    }
  }
  customizeBodyDataGraphLeftYAxis = (arg: any) => { return arg.valueText }
  customizeBodyDataGraphRightYAxis = (arg: any) => { return arg.valueText }
  customizedAxisUnits: string[] = []
  customizeBodyDataGraphTooltip = (arg: any) => {
    var name = arg.point?.series?.name;
    var text = arg.point?.data?.date.getPrintableWeekday().substr(0, 2) + ', ' + arg.point?.data?.date.asFormatedString() + '\n'
    this.displayedStatisticsGraphItems.forEach(item => {
      if (arg.point?.data[item.valueField] != undefined && arg.point?.data[item.valueField] != null) {
        if (item.name == name){
          text = text + "<b>" + item.name + ': ' + arg.point?.data[item.valueField + 'Rendered'] + ' ' + (item.unit || '') + "</b>" + '\n'
        }
        else {
          text = text + item.name + ': ' + arg.point?.data[item.valueField + 'Rendered'] + ' ' + (item.unit || '') + '\n'
        }
      }
    })
    return { text: text}
  }
  getPrintableSleepDuration(sleepDuration:number) {
    return Math.floor(sleepDuration / 60) + ':' + ((sleepDuration % 60) < 10 ? '0' : '') + (sleepDuration % 60).toFixed(2)
  }
  customizeGroupSummaryTextAverage (e) {
    return "∅: " + Math.round((e.value + Number.EPSILON) * 100) / 100
  };
  customizeGroupSummaryTextSum (e) {
    return "Σ: " + Math.round((e.value + Number.EPSILON) * 100) / 100
  };
  progressGraphTickIntervall = undefined
  progressGraphVisualRange = [null, null]

  constructor(public nutritionService: NutritionService, public chatService: ChatService, private authService: AuthService, private router: Router, public userService: FirestoreService, private route: ActivatedRoute, public utilityService: UtilityService, private toastr: ToastrService, private spinner: NgxSpinnerService, private componentFactoryResolver: ComponentFactoryResolver, private dialog: MatDialog, public chartExportService: ChartExportService) {
    this.calculateCustomSummaryRow = this.calculateCustomSummaryRow.bind(this);
  }

  ngOnInit(): void {
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(NutritionalValuePopoverComponent);

    var bodyDataGraphColorPalette = {
      simpleSet: ['var(--accentColor)', '#4ACCE6', '#4A79E6', '#934AE6', '#E64ADC', '#E64A6E', '#E67D4A', '#E6BC4A', '#D6E64A', '#9CE64A', '#4AE693'],
      indicatingSet: ['var(--accentColor)', '#4AE6E6', '#4AD6E6'],
      gradientSet: ['var(--accentColor)', '#4AE6E6']
    };
    registerPalette('nutrilizePalette', bodyDataGraphColorPalette);
    
    if (this._selectedUserUid === undefined){

      this.authService.getAuthState().subscribe(user => {
        if (user) {
          this.authService.setUser(user)
          this.userService.onLoggedIn(user.uid)
          this.userService.initUser(this.authService.user.uid)
          this._selectedUserUid = user.uid
          this.init();
        }
      });
    } else {
      this.init();
    }
    if (!this.authService.isLoggedIn()) {
      this.router.navigate['login'];
    }
  }

  private async init(){
    this.user = this.userService.getLoggedInUser();
    this.isUser = this.user.isUser;
    this.selectedBodyDataGraphTimeRange = this.bodyDataGraphTimeRanges[1]

    this.userService.getAccessibleClients().forEach(c => {
      if (c.uid == this._selectedUserUid) {
        this.displayedUser = c
      }
    });
    if (this.user.uid != this._selectedUserUid) {
      this.isCustomer = true;
    } else if (!this.displayedUser?.uid) {
      this.displayedUser = this.user
    }
    
    this.prepareDefaultDataGraphTypes(this.displayedUser)

    this.userService.loadAssignedMetricsForUser(this.displayedUser)
    if(this.defaultBodyDataGraphTypes.length > 0){
      this.selectedBodyDataGraphTypes = [this.defaultBodyDataGraphTypes[0]]
    }
    
    if (!this.displayedUser.bodyDataMigrated && (!this.displayedUser.bodyData || this.displayedUser.bodyData.length == 0)) {
      this.displayedUser.currentlyLoadingBodyDataStatistics = true;
      this.loadBodyDatasForUser(this.displayedUser)
    } else {
      this.updateBodyStatisticsGraph(this.displayedUser)
    }
    if (!this.displayedUser.dailyConditions || this.displayedUser.dailyConditions.length == 0) {
      await this.loadDailyConditionsForUser(this.displayedUser)
    } else {
      this.prepareAdditionalDataGraphTypes(this.displayedUser)
      this.updateBodyStatisticsGraph(this.displayedUser)
      this.prepareImageMetricTypes(this.displayedUser)
    }

    this.userService.observableMetrics.subscribe(async metrics => {
      if ((!this.displayedUser.dailyConditions || this.displayedUser.dailyConditions.length == 0) && this.subscriptionDailyCondition == null) await this.loadDailyConditionsForUser(this.displayedUser)
    })
  }
  
  public nutritionalValueHolder: NutritionalValueHolder
  onNutritionalValuesFocused(nutritionalValueHolder: NutritionalValueHolder) {
    this.nutritionalValueHolder = nutritionalValueHolder
  }

  ngOnDestroy(): void {
    if (this.subscriptionBodyData) this.subscriptionBodyData.unsubscribe()
  }

  customAggregationFunction (aggregationInfo: any, series: any) {
    if (!aggregationInfo.data.length) {
      return;
    }
    const val = aggregationInfo.data.map((item) => item.bmi);
    const maxVal = Math.max.apply(null, val);
    const minVal = Math.min.apply(null, val);

    return {
      date: new Date((aggregationInfo.intervalStart.valueOf() + aggregationInfo.intervalEnd.valueOf()) / 2),
      maxVal,
      minVal,
    };
  };

  getSelectedBodyDataGraphTypesString() {
    if (this.selectedBodyDataGraphTypes.length == 0) {
      return "Metrik auswählen"
    } else if (this.selectedBodyDataGraphTypes.length == 1) {
      return this.selectedBodyDataGraphTypes[0]?.name
    } else {
      var text = ""
      this.selectedBodyDataGraphTypes.forEach(type => {
        text += type?.name + ", "
      })
      text = text.substr(0, text.length - 2)
      return text
    }
  }

  loadBodyDatasForUser(user: User) {
    if(user.bodyDataMigrated) {
      return;
    }
    this.subscriptionBodyData = this.userService.getAllBodyDataForUser(user).subscribe(documents => {
      var bodyData = []
      var averageWeightMap = new Map()
      var averageWeightCountMap = new Map()
      documents.forEach(document => {
        var b = new BodyData(document as BodyData)
        b.date = new Date((document as any).date.seconds*1000)
        if (b.bodyWeight && b.bodyWeight > 0 && user.bodyHeight) b.bmi = Math.round(b.bodyWeight / Math.pow(user.bodyHeight / 100, 2) * 100) / 100
        if (b.bodyWeight && b.bodyWeight > 0) {
          var averageWeight = averageWeightMap.get(b.date.getWeekNumber())
          var averageWeightCount = averageWeightCountMap.get(b.date.getWeekNumber())
          if (averageWeight) {
            averageWeight = averageWeight + b.bodyWeight
            averageWeightCount++
          } else {
            averageWeight = b.bodyWeight
            averageWeightCount = 1
          }
          averageWeightMap.set(b.date.getWeekNumber(), averageWeight)
          averageWeightCountMap.set(b.date.getWeekNumber(), averageWeightCount)
        }
        bodyData.push(b)
      })
      bodyData.sort((b1, b2) => b1.date.getTime() - b2.date.getTime())
      
      var nextBodyDataDate = null
      var newBodyDatas = []
      bodyData.forEach(b => {
        if (nextBodyDataDate == null) {
          nextBodyDataDate = b.date.clone().addDays(1)
        } else {
          if (b.date.isSameDate(nextBodyDataDate)) {
            nextBodyDataDate = b.date.clone().addDays(1)
          } else {
            for (var newDate = nextBodyDataDate; newDate.getTime() < b.date.getTime(); newDate = newDate.clone().addDays(1)) {
              var newBodyData = new BodyData()
              newBodyData.date = new Date(newDate.getTime())
              newBodyDatas.push(newBodyData)
            }
            nextBodyDataDate = b.date.clone().addDays(1)
          }
        }
      })
      if (newBodyData != null && nextBodyDataDate.getTime() < Date.now()) {
        for (var newDate = nextBodyDataDate; newDate.getTime() < Date.now(); newDate = newDate.clone().addDays(1)) {
          var newBodyData = new BodyData()
          newBodyData.date = new Date(newDate.getTime())
          newBodyDatas.push(newBodyData)
        }
      }
      bodyData = bodyData.concat(newBodyDatas).sort((b1, b2) => b1.date.getTime() - b2.date.getTime())
      bodyData.forEach(b => {
        var weekNumber = b.date.getWeekNumber()
        var averageWeight = averageWeightMap.get(weekNumber) / averageWeightCountMap.get(weekNumber)
        b.bodyWeightWeeklyAverage = Math.round(averageWeight * 100) / 100
      })
      user.bodyData = bodyData;
      this.updateBodyStatisticsGraph(user)
      user.currentlyLoadingBodyDataStatistics = false;
    })
  }

  prepareDefaultDataGraphTypes(user: User){
    this.defaultBodyDataGraphTypes = []
    if(!user.bodyDataMigrated) {
      this.defaultBodyDataGraphTypes.push({id: 0, name: "Gewicht", tmp: '', unit: null, order: 1})
      this.defaultBodyDataGraphTypes.push({id: 1, name: "Umfänge", tmp: '', unit: null, order: 2})
      this.defaultBodyDataGraphTypes.push({id: 2, name: "KFA", tmp: '', unit: null, order: 3})
      this.defaultBodyDataGraphTypes.push({id: 3, name: "BMI", tmp: '', unit: null, order: 4})
      this.defaultBodyDataGraphTypes.push({id: 5, name: "Wasserzufuhr", tmp: '', unit: null, order: 5})
    }
    this.selectedBodyDataGraphTypes = this.selectedBodyDataGraphTypes?.filter(x => this.defaultBodyDataGraphTypes.find(d => d.name == x.name) != null || this.additionalBodyDataGraphTypes.find(d => d.name == x.name) != null)
  }

  isMetricPossibleOnChart(metric: Metric){
    return metric.isMetricTypeNumber() || metric.isMetricTypeDuration() || metric.isMetricTypeSelection() || metric.isMetricTypeYesNo() || metric.isMetricTypeToDo();
  }

  isMetricPossibleOnTable(metric: Metric){
    return this.isMetricPossibleOnChart(metric) || metric.isMetricTypeText() || metric.isMetricTypeMultiselect();
  }

  async prepareAdditionalDataGraphTypes(user: User) {
    var handledMetricIds: string[] = []
    var additionalBodyDataGraphTypes = []
    this.additionalBodyDataGraphTypesForTable = []
    if (user.bodyDataMigrated){
      additionalBodyDataGraphTypes.push({id: 1, name: "Umfänge", tmp: '', unit: null, order: 3})
      additionalBodyDataGraphTypes.push({id: 3, name: "BMI", tmp: '', unit: null, order: 4})
      additionalBodyDataGraphTypes.push({id: 5, name: "Wasserzufuhr", tmp: '', unit: null, order: 5})
    }
    for (let dailyCondition of user.dailyConditions) {
      for (let data of dailyCondition.getMetricDataListWithQuestionaires()) {
        if (!Metric.circumferenceMetricIds.includes(data.metricId) && !handledMetricIds.includes(data.metricId) && additionalBodyDataGraphTypes.filter(function(e) { return e.tmp === 'metric' + data.metricId; }).length == 0) {
          var metric = this.userService.getMetricByMetricId(data.metricId)
          if (!metric) metric = await this.userService.fetchMetricByMetricId(data.metricId)
          var order = 7
          if (metric?.metricId == 'bodyWeight') {
            order = 1
          } else if (metric?.metricId == 'bodyFat') {
            order = 2
          }
          if (metric != null && (this.isMetricPossibleOnChart(metric))) {
            additionalBodyDataGraphTypes.push({id: 6, name: metric.nameDe ?? 'Metrik ' + data.metricId, tmp: 'metric' + data.metricId, unit: metric.unit, order: order})
          }
          else if(metric != null && this.isMetricPossibleOnTable(metric)) {
            this.additionalBodyDataGraphTypesForTable.push({valueField: 'metric' + data.metricId, name: metric.nameDe ?? 'Metrik ' + data.metricId + (metric.unit != null ? ' (' + metric.unit + ')' : ''), summaryType: ""})
          }

          handledMetricIds.push(data.metricId)
        }
      }
    }
    this.selectedBodyDataGraphTypes = this.selectedBodyDataGraphTypes.filter(x => this.defaultBodyDataGraphTypes.find(d => d.name == x.name) != null || this.additionalBodyDataGraphTypes.find(d => d.name == x.name) != null)
    this.additionalBodyDataGraphTypes = additionalBodyDataGraphTypes;
    this.additionalBodyDataGraphTypes.sort((a, b) => {
      if (a.order == b.order) {
        if (Metric.bodyDataMetricIds.includes(a.tmp.replaceAll('metric', ''))) {
          if (Metric.bodyDataMetricIds.includes(b.tmp.replaceAll('metric', ''))) {
            return a.name.localeCompare(b.name);
          } else {
            return -1;
          }
        } else if (Metric.bodyDataMetricIds.includes(b.tmp.replaceAll('metric', ''))) {
          return 1
        }
        return a.name.localeCompare(b.name);
      }
      return a.order - b.order
    });
    this.loadPreviousSelectedBodyDataGraphTypes()
  }

  private subscriptionDailyCondition: Subscription
  async loadDailyConditionsForUser(user: User) {
    if (this.subscriptionDailyCondition) {
      this.subscriptionDailyCondition.unsubscribe()
      this.subscriptionDailyCondition = null
    }
    this.subscriptionDailyCondition = this.userService.getDailyConditionsForUser(user).subscribe(async dailyConditions => {
      // var dailyConditions = []
      // documents.forEach(document => {
      //   var d = new DailyCondition(document as DailyCondition)
      //   dailyConditions.push(d)
      // })
      
      user.dailyConditions = dailyConditions.sort((a, b) => b.date.getTime() - a.date.getTime());
      this.prepareAdditionalDataGraphTypes(user)
      await this.loadStatisticsDataForUser(user)
      this.prepareImageMetricTypes(user)
    })
  }

  async loadStatisticsDataForUser(user: User) {

    // verhindert das Laden von weiteren Nutritiondaten.
    // if (user.statisticsUpdated.value) return


    var timeRange: number
    if (this.selectedBodyDataGraphTimeRange.id == 0) {
      timeRange = 31;
    } else if (this.selectedBodyDataGraphTimeRange.id == 1) {
      timeRange = 31;
    } else if (this.selectedBodyDataGraphTimeRange.id == 2) {
      timeRange = 90;
    } else if (this.selectedBodyDataGraphTimeRange.id == 3) {
      timeRange = 180;
    } else if (this.selectedBodyDataGraphTimeRange.id == 4) {
      timeRange = 365;
    } else if (this.selectedBodyDataGraphTimeRange.id == 5){
      timeRange = this.getFullTimeRangeNumber(user);
    } else if (this.selectedBodyDataGraphTimeRange.id == 6){
      if(this.selectedStartDate && this.selectedEndDate && this.selectedEndDate > this.selectedStartDate) {
        this.selectedEndDate.setHours(0,0,0,0)
        this.selectedStartDate.setHours(0,0,0,0)
        timeRange = (this.selectedEndDate.getTime() - this.selectedStartDate?.getTime()) / (1000 * 3600 * 24)
      }
      else {
        return;
      }
    }


    if (user.currentlyLoadingNutritionStatistics) return
    
    var startDate = new Date();
    var endDate = new Date()
    
    if(this.selectedBodyDataGraphTimeRange.id == 6) {
      startDate = this.selectedStartDate;
      endDate = this.selectedEndDate
    }
    else {
      startDate.setDate(startDate.getDate() - timeRange);
      this.selectedStartDate = startDate
      this.selectedEndDate = endDate
      if (timeRange <= user.statistics.length){
         return
      }
    }
    
    if(timeRange <= 180){
      user.currentlyLoadingNutritionStatistics = true
      startDate.setHours(0,0,0,0);
      
      var statistics = await this.userService.loadNutritionStatisticsForDateRange(user, startDate, endDate)
      
      user.statistics = statistics
      user.statisticsUpdated.next(true)
      user.currentlyLoadingNutritionStatistics = false
      // this.updateNutritionStatisticsGraph(user)
      this.updateBodyStatisticsGraph(user)
    }

  }

  public progressStatisticsGroupCount = new Map()
  public selectedProgressStatisticsRowDates = []

  calculateCustomSummaryRow(options) {
    if (options.summaryProcess === "start") {
      options.totalValue = 0;
      this.progressStatisticsGroupCount.set(options.name, 0)
    } else if (options.summaryProcess === "calculate") {
      if (!this.selectedProgressStatisticsRowDates.includes(options.value["date"]) && options.value[options.name] != null) {
        options.totalValue = options.totalValue + options.value[options.name]
        this.progressStatisticsGroupCount.set(options.name,  this.progressStatisticsGroupCount.get(options.name) + 1)
      }
    } else if (options.summaryProcess === "finalize") {
      var summaryType = 'avg'
      var item = this.allStatisticsGraphItems.filter(x => x.valueField == options.name.replace('Rendered', '')).shift()
      if (item) {
        summaryType = item.summaryType
      }
      if (summaryType == 'avg' && this.progressStatisticsGroupCount.get(options.name)) {
        options.totalValue = options.totalValue / this.progressStatisticsGroupCount.get(options.name)
      }
    }
  }
  onSelectionChanged(e) {
    e.currentSelectedRowKeys.forEach(element => {
      if (!this.selectedProgressStatisticsRowDates.includes(element["date"])) this.selectedProgressStatisticsRowDates.push(element["date"])
    });
    e.currentDeselectedRowKeys.forEach(element => {
      this.selectedProgressStatisticsRowDates.forEach( (item, index) => {
        if (item == element["date"]) this.selectedProgressStatisticsRowDates.splice(index, 1);
      });
    });
    e.component.refresh(true);
  }


  private getFullTimeRangeNumber(user:User):number{
    let firstBodyDataDate = user.getFirstBodyDataDate() || new Date();
    let firstDailyConditionDate = user.getFirstDailyConditionDate() || new Date();
    var firstDate = firstBodyDataDate > firstDailyConditionDate ? firstDailyConditionDate : firstBodyDataDate;
    firstDate?.setHours(0,0,0,0)
    var now = new Date();
    now.setHours(0,0,0,0);
    return (now.getTime() - firstDate?.getTime()) / (1000 * 3600 * 24);
  }
  

  async updateBodyStatisticsGraph(user: User) {
    this.progressStatisticsGraphStrips = []
    var timeRange: number;
    if (this.selectedBodyDataGraphTimeRange.id == 0) {
      timeRange = 14;
    } else if (this.selectedBodyDataGraphTimeRange.id == 1) {
      timeRange = 31;
    } else if (this.selectedBodyDataGraphTimeRange.id == 2) {
      timeRange = 90;
    } else if (this.selectedBodyDataGraphTimeRange.id == 3) {
      timeRange = 180;
    } else if (this.selectedBodyDataGraphTimeRange.id == 4) {
      timeRange = 365;
    } else if (this.selectedBodyDataGraphTimeRange.id == 5){
      timeRange = this.getFullTimeRangeNumber(user);
    } else if (this.selectedBodyDataGraphTimeRange.id == 6){
      if(this.selectedStartDate && this.selectedEndDate && this.selectedEndDate > this.selectedStartDate) {
        this.selectedEndDate.setHours(0,0,0,0)
        this.selectedStartDate.setHours(0,0,0,0)
        timeRange = (this.selectedEndDate.getTime() - this.selectedStartDate?.getTime()) / (1000 * 3600 * 24)
      }
      else {
        return;
      }
    }
    this.canShowDataTable = (timeRange <= 180 || !this.utilityService.onSmallDisplay())
    
    this.allStatisticsGraphItems = [
      {valueField: "bodyWeight", name: "Gewicht (kg)", summaryType: "avg"}, 
      {valueField: "bodyWeightWeeklyAverage", name: "∅-Gewicht (kg)", style: "avg"},
      {valueField: "calories", name: "Kalorien (kcal)", summaryType: "avg"},
      {valueField: "carbohydrates", name: "Kohlenhydrate (g)", summaryType: "avg"},
      {valueField: "protein", name: "Eiweiß (kcal)", summaryType: "avg"},
      {valueField: "fat", name: "Fett (g)", summaryType: "avg"},
      {valueField: "sugar", name: "Zucker (g)", summaryType: "avg"},
      {valueField: "saturatedFat", name: "Gesättigte Fetts. (g)", summaryType: "avg"},
      {valueField: "fibre", name: "Ballaststoffe (g)", summaryType: "avg"},
      {valueField: "salt", name: "Salz (g)", summaryType: "avg"},
      {valueField: "activitiesDuration", name: "Aktivitätsdauer (min)", summaryType: "avg"},
      {valueField: "activitiesCalories", name: "Verbrannte Kalorien (kcal)", summaryType: "sum"},
      {valueField: "hipCircumference", name: "Hüftumfang (cm)", summaryType: "avg"},
      {valueField: "waistCircumference", name: "Taillenumfang (cm)", summaryType: "avg"},
      {valueField: "chestCircumference", name: "Brustumfang (cm)", summaryType: "avg"},
      {valueField: "leftArmCircumference", name: "Armumfang links (cm)", summaryType: "avg"},
      {valueField: "rightArmCircumference", name: "Armumfang rechts (cm)", summaryType: "avg"},
      {valueField: "leftThighCircumference", name: "Beinumfang links (cm)", summaryType: "avg"},
      {valueField: "rightThighCircumference", name: "Beinumfang rechts (cm)", summaryType: "avg"},
      {valueField: "leftCalfCircumference", name: "Wadenumfang links (cm)", summaryType: "avg"},
      {valueField: "rightCalfCircumference", name: "Wadenumfang rechts (cm)", summaryType: "avg"},
      {valueField: "bellyCircumference", name: "Bauchumfang (cm)", summaryType: "avg"},
      {valueField: "seatCircumference", name: "Gesäßumfang (cm)", summaryType: "avg"},
      {valueField: "bodyFatPercentage", name: "KFA (%)", summaryType: "avg"},
      {valueField: "bmi", name: "BMI", summaryType: "avg"},
      {valueField: "sleepDuration", name: "Schlaf (h)", summaryType: "avg"},
      {valueField: "waterIntake", name: "Wasserzufuhr (l)", summaryType: "avg"}
    ]
    this.additionalBodyDataGraphTypes.forEach(item => {
      if (item.tmp == 'metricbodyWeight') {
        this.allStatisticsGraphItems = [{valueField: item.tmp, name: item.name + (item.unit != null ? ' (' + item.unit + ')' : ''), summaryType: "avg"}].concat(this.allStatisticsGraphItems)
      } else {
        this.allStatisticsGraphItems.push({valueField: item.tmp, name: item.name + (item.unit != null ? ' (' + item.unit + ')' : ''), summaryType: "avg"})
      }
    })

    var startDate = new Date();
    if(this.selectedBodyDataGraphTimeRange.id == 6) {
      startDate = this.selectedStartDate;
      // endDate = this.selectedEndDate
    }
    else {
      startDate.setDate(startDate.getDate() - timeRange);
      this.selectedStartDate = startDate
      this.selectedEndDate = new Date()
    }

    
    startDate.setHours(0,0,0,0);
    
    var dailyCondition: DailyCondition;

    var statisticsItems: any[];
    var statisticsItem: any;
    statisticsItems = []
    this.displayedMetricInGraph = null

    this.displayedStatisticsGraphItems = []
    this.displayedMetricsInGraph = []
    this.statisticsGraphAxis = []

    this.customizeBodyDataGraphLeftYAxis = (arg: any) => {
      return arg.valueText;
    }
    this.customizeBodyDataGraphRightYAxis = (arg: any) => {
      return arg.valueText;
    }

    var selectedTodoMetricIds: string[] = []

    var typeCount = 0
    for (let selectedBodyDataGraphType of this.selectedBodyDataGraphTypes) {
      
      if (selectedBodyDataGraphType.id >= 0 && selectedBodyDataGraphType.id <= 3) {
        if (selectedBodyDataGraphType.name == "Gewicht") {
          // this.displayedStatisticsGraphItems.push({valueField: "bodyWeight", name: "Gewicht", axis: "weightAxis", unit: "kg"})

          this.displayedStatisticsGraphItems.push({valueField: "bodyWeight", name: "Gewicht", axis: "weightAxis", unit: "kg"}, {valueField: "bodyWeightWeeklyAverage", name: "∅-Gewicht", axis: "weightAxis", unit: "kg", style: "average"})
          
          this.statisticsGraphAxis.push({name: "weightAxis", visualRange: [null, null]})
          if (typeCount == 0) {
            this.customizeBodyDataGraphLeftYAxis = (arg: any) => {
              return arg.valueText + " kg";
            }
          } else if (typeCount == 1) {
            this.customizeBodyDataGraphRightYAxis = (arg: any) => {
              return arg.valueText + " kg";
            }
          }
        } else if (selectedBodyDataGraphType.name == "Umfänge") {

          if(!user.bodyDataMigrated) {
            this.displayedStatisticsGraphItems.push(
            {valueField: "hipCircumference", name: "Hüftumfang", axis: "circumferenceAxis", unit: "cm"},
            {valueField: "waistCircumference", name: "Taillenumfang", axis: "circumferenceAxis", unit: "cm"},
            {valueField: "chestCircumference", name: "Brustumfang", axis: "circumferenceAxis", unit: "cm"},
            {valueField: "leftArmCircumference", name: "Armumfang links", axis: "circumferenceAxis", unit: "cm"},
            {valueField: "rightArmCircumference", name: "Armumfang rechts", axis: "circumferenceAxis", unit: "cm"},
            {valueField: "leftThighCircumference", name: "Beinumfang links", axis: "circumferenceAxis", unit: "cm"},
            {valueField: "rightThighCircumference", name: "Beinumfang rechts", axis: "circumferenceAxis", unit: "cm"},
            {valueField: "leftCalfCircumference", name: "Wadenumfang links", axis: "circumferenceAxis", unit: "cm"},
            {valueField: "rightCalfCircumference", name: "Wadenumfang rechts", axis: "circumferenceAxis", unit: "cm"},
            {valueField: "bellyCircumference", name: "Bauchumfang", axis: "circumferenceAxis", unit: "cm"},
            {valueField: "seatCircumference", name: "Gesäßumfang", axis: "circumferenceAxis", unit: "cm"}
            )
          }
          else {
            Metric.circumferenceMetricIds.forEach(circumferenceMetricId  => {
              this.displayedStatisticsGraphItems.push({valueField: 'metric' + circumferenceMetricId, name: Metric.circumferenceMetricIdToNameMapping.get(circumferenceMetricId), axis: "circumferenceAxis", unit: "cm"})
            });
          }

            this.statisticsGraphAxis.push({name: "circumferenceAxis", visualRange: [null, null]})
            if (typeCount == 0) {
              this.customizeBodyDataGraphLeftYAxis = (arg: any) => {
                return arg.valueText + " cm";
              }
            } else if (typeCount == 1) {
              this.customizeBodyDataGraphRightYAxis = (arg: any) => {
                return arg.valueText + " cm";
              }
            }
        } else if (selectedBodyDataGraphType.name == "KFA") {

          this.displayedStatisticsGraphItems.push({valueField: "bodyFatPercentage", name: "KFA", unit: "%", axis: "percentageAxis"})
          this.statisticsGraphAxis.push({name: "percentageAxis", visualRange: [null, null]})
          if (typeCount == 0) {
            this.customizeBodyDataGraphLeftYAxis = (arg: any) => {
              return arg.valueText + " %";
            }
          } else if (typeCount == 1) {
            this.customizeBodyDataGraphRightYAxis = (arg: any) => {
              return arg.valueText + " %";
            }
          }
        } else if (selectedBodyDataGraphType.name == "BMI") {
          this.displayedStatisticsGraphItems.push({valueField: "bmi", name: "BMI", axis: "bmiAxis"})
          this.statisticsGraphAxis.push({name: "bmiAxis", visualRange: [null, null]})
        }
      } else {
        if (selectedBodyDataGraphType.name == "Wasserzufuhr") {
          this.displayedStatisticsGraphItems.push({valueField: "waterIntake", name: "Wasserzufuhr", unit: "l", axis: "waterIntakeAxis"})
          this.statisticsGraphAxis.push({name: "waterIntakeAxis", visualRange: [0, null]})
          if (typeCount == 0) {
            this.customizeBodyDataGraphLeftYAxis = (arg: any) => {
              return arg.valueText + " l";
            }
          } else if (typeCount == 1) {
            this.customizeBodyDataGraphRightYAxis = (arg: any) => {
              return arg.valueText + " l";
            }
          }
        } else {
          var metric = this.userService.getMetricByMetricId(selectedBodyDataGraphType.tmp.replace('metric', ''))
          if (!metric) metric = await this.userService.fetchMetricByMetricId(selectedBodyDataGraphType.tmp.replace('metric', ''))
          if (metric.metricId == 'bodyWeight' || metric.metricId == 'NUT_bodyWeight') {
            this.displayedStatisticsGraphItems.push({valueField: "bodyWeightWeeklyAverage", name: "∅-Körpergewicht", axis: "kgAxis", unit: "kg", style: "average"})
          }
          var axisName = (metric.unit ? metric.unit : metric.id) + "Axis"
          var existingAxis = this.statisticsGraphAxis.filter(x => x.name == axisName).shift() ?? null
          this.displayedStatisticsGraphItems.push({valueField: selectedBodyDataGraphType.tmp, name: selectedBodyDataGraphType.name, unit: metric.unit, axis: axisName})
          this.displayedMetricsInGraph.push(metric)
          var axisRange = [null, null]
          var tickInterval = undefined
          if (metric?.isMetricTypeToDo()) {
            if(!selectedTodoMetricIds.includes(metric.metricId)) {
              selectedTodoMetricIds.push(metric.metricId)
            }
            axisRange = [0, 1]
            tickInterval = 1
            if (typeCount == 0) {
              this.customizeBodyDataGraphLeftYAxis = (arg: any) => {
                var value = parseInt(arg.valueText)
                return value == 0 ? 'Nicht erledigt' : 'Erledigt'
              }
            } else if (typeCount == 1) {
              this.customizeBodyDataGraphRightYAxis = (arg: any) => {
                var value = parseInt(arg.valueText)
                return value == 0 ? 'Nicht erledigt' : 'Erledigt'
              }
            }
          } else if (metric?.isMetricTypeYesNo()) {
            axisRange = [0, 1]
            tickInterval = 1
            if (typeCount == 0) {
              this.customizeBodyDataGraphLeftYAxis = (arg: any) => {
                var value = parseInt(arg.valueText)
                return value == 0 ? 'Nein' : 'Ja'
              }
            } else if (typeCount == 1) {
              this.customizeBodyDataGraphRightYAxis = (arg: any) => {
                var value = parseInt(arg.valueText)
                return value == 0 ? 'Nein' : 'Ja'
              }
            }
          } else if (metric?.isMetricTypeSelection()) {
            axisRange = [0, metric.getSelectableValues().length - 1]
            tickInterval = 1
            if (typeCount == 0) {
              this.customizeBodyDataGraphLeftYAxis = (arg: any) => {
                var value = parseInt(arg.valueText)
                return metric.getSelectableValues()[value]
              }
            } else if (typeCount == 1) {
              this.customizeBodyDataGraphRightYAxis = (arg: any) => {
                var value = parseInt(arg.valueText)
                return metric.getSelectableValues()[value]
              }
            }
          } else if (metric?.isMetricTypeMultiselect()) {
            axisRange = [0, metric.getSelectableValues().length - 1]
            tickInterval = 1
            if (typeCount == 0) {
              this.customizeBodyDataGraphLeftYAxis = (arg: any) => {
                var value = parseInt(arg.valueText)
                return metric.getSelectableValues()[value]
              }
            } else if (typeCount == 1) {
              this.customizeBodyDataGraphRightYAxis = (arg: any) => {
                var value = parseInt(arg.valueText)
                return metric.getSelectableValues()[value]
              }
            }
          } else if (metric?.isMetricTypeNumber()) {
            axisRange = [metric.minValue != null ? metric.minValue : null, metric.maxValue != null ? metric.maxValue : null]
            var unit = metric.unit ?? ''
            if (existingAxis) {

            } else {
              if (typeCount == 0) {
                this.customizedAxisUnits[0] = unit
                this.customizeBodyDataGraphLeftYAxis = (arg: any) => {
                  return arg.valueText + " " + this.customizedAxisUnits[0];
                }
              } else if (typeCount == 1) {
                this.customizedAxisUnits[1] = unit
                this.customizeBodyDataGraphRightYAxis = (arg: any) => {
                  return arg.valueText + " " + this.customizedAxisUnits[1];
                }
              }
            }
          } else if (metric?.isMetricTypeDuration()) {
            if (typeCount == 0) {
              this.customizeBodyDataGraphLeftYAxis = (arg: any) => {
                var duration = parseInt(arg.valueText)
                return Math.floor(duration / 60) + ':' + ((duration % 60) < 10 ? '0' : '') + (duration % 60) + ' h';
              }
            } else if (typeCount == 1) {
              this.customizeBodyDataGraphRightYAxis = (arg: any) => {
                var duration = parseInt(arg.valueText)
                return Math.floor(duration / 60) + ':' + ((duration % 60) < 10 ? '0' : '') + (duration % 60) + ' h';
              }
            }
          }
          if (!existingAxis) {
            this.statisticsGraphAxis.push({name: axisName, visualRange: axisRange, tickInterval: tickInterval, unit: metric.unit})
          }
        }
      }
      typeCount++
    }

    
    this.tableStatisticsGraphItems = []
    var dataForValueFieldAvailable = new Map()
    dataForValueFieldAvailable.set("waterIntake", true)

    var averageWeightMap = new Map()
    var averageWeightCountMap = new Map()

    for (var i = 0; i <= timeRange; i++) {
      var date = new Date(startDate.getTime())
      date.setDate(date.getDate() + i)
      statisticsItem = {
        date: date,
        dateRendered: date.asShortFormatedString(),
        weekNumber: date.getYearOfWeekOfYearNumber() + '/' + (date.getWeekOfYearNumber() < 10 ? '0' : '') + date.getWeekOfYearNumber()
      }

      if(selectedTodoMetricIds.length > 0){
        selectedTodoMetricIds.forEach(metricId => {
          statisticsItem["metric" + metricId] = 0;
          statisticsItem["metric" + metricId + "Rendered"] = "Nicht erledigt";
        });
      }

      if(!user.bodyDataMigrated) {
        var b = user.getBodyDataItemForDate(date)
        if (b && !b.migrated) {
          if(b.bodyWeight != null) {
            dataForValueFieldAvailable.set("bodyWeight", true)
          }
          
          statisticsItem["bodyWeight"] = b.bodyWeight
          statisticsItem["bodyWeightRendered"] = statisticsItem["bodyWeight"]
          statisticsItem["bodyWeightWeeklyAverage"] = b.bodyWeightWeeklyAverage
          statisticsItem["bodyWeightWeeklyAverageRendered"] = statisticsItem["bodyWeightWeeklyAverage"]
          
          statisticsItem["hipCircumference"] = b.hipCircumference
          statisticsItem["hipCircumferenceRendered"] = statisticsItem["hipCircumference"]
          if (b.hipCircumference != null) dataForValueFieldAvailable.set("hipCircumference", true)
          statisticsItem["waistCircumference"] = b.waistCircumference
          statisticsItem["waistCircumferenceRendered"] = statisticsItem["waistCircumference"]
          if (b.waistCircumference != null) dataForValueFieldAvailable.set("waistCircumference", true)
          statisticsItem["chestCircumference"] = b.chestCircumference
          statisticsItem["chestCircumferenceRendered"] = statisticsItem["chestCircumference"]
          if (b.chestCircumference != null) dataForValueFieldAvailable.set("chestCircumference", true)
          statisticsItem["leftArmCircumference"] = b.leftArmCircumference
          statisticsItem["leftArmCircumferenceRendered"] = statisticsItem["leftArmCircumference"]
          if (b.leftArmCircumference != null) dataForValueFieldAvailable.set("leftArmCircumference", true)
          statisticsItem["rightArmCircumference"] = b.rightArmCircumference
          statisticsItem["rightArmCircumferenceRendered"] = statisticsItem["rightArmCircumference"]
          if (b.rightArmCircumference != null) dataForValueFieldAvailable.set("rightArmCircumference", true)
          statisticsItem["leftThighCircumference"] = b.leftThighCircumference
          statisticsItem["leftThighCircumferenceRendered"] = statisticsItem["leftThighCircumference"]
          if (b.leftThighCircumference != null) dataForValueFieldAvailable.set("leftThighCircumference", true)
          statisticsItem["rightThighCircumference"] = b.rightThighCircumference
          statisticsItem["rightThighCircumferenceRendered"] = statisticsItem["rightThighCircumference"]
          if (b.rightThighCircumference != null) dataForValueFieldAvailable.set("rightThighCircumference", true)
  
          statisticsItem["leftCalfCircumference"] = b.leftCalfCircumference
          statisticsItem["leftCalfCircumferenceRendered"] = statisticsItem["leftCalfCircumference"]
          if (b.leftCalfCircumference != null) dataForValueFieldAvailable.set("leftCalfCircumference", true)
  
          statisticsItem["rightCalfCircumference"] = b.rightCalfCircumference
          statisticsItem["rightCalfCircumferenceRendered"] = statisticsItem["rightCalfCircumference"]
          if (b.rightCalfCircumference != null) dataForValueFieldAvailable.set("rightCalfCircumference", true)
  
          statisticsItem["bellyCircumference"] = b.bellyCircumference
          statisticsItem["bellyCircumferenceRendered"] = statisticsItem["bellyCircumference"]
          if (b.bellyCircumference != null) dataForValueFieldAvailable.set("bellyCircumference", true)
  
          statisticsItem["seatCircumference"] = b.seatCircumference
          statisticsItem["seatCircumferenceRendered"] = statisticsItem["seatCircumference"]
          if (b.seatCircumference != null) dataForValueFieldAvailable.set("seatCircumference", true)
  
          
          statisticsItem["bodyFatPercentage"] = b.bodyFatPercentage
          statisticsItem["bodyFatPercentageRendered"] = statisticsItem["bodyFatPercentage"]
          if (b.bodyFatPercentage != null) dataForValueFieldAvailable.set("bodyFatPercentage", true)
          statisticsItem["bmi"] = b.bmi
          statisticsItem["bmiRendered"] = statisticsItem["bmi"]
          if (b.bmi != null) dataForValueFieldAvailable.set("bmi", true)
        }
      }

      // Collect DailyCondition and MetricData.
      dailyCondition = user.getDailyConditionForDate(date)
      if (dailyCondition) {
        if (dailyCondition.menstruating || dailyCondition.getMetricDataByMetricId(Metric.metricIdMenstruation())?.value == true) {
          var strip = {
            startValue: new Date(date.getTime() - 1000 * 60 * 60 * 12),
            endValue: new Date(date.getTime() + 1000 * 60 * 60 * 12)
          }
          this.progressStatisticsGraphStrips.push(strip)
        }
        statisticsItem["sleepDuration"] = dailyCondition.sleepDuration
        statisticsItem["sleepDurationRendered"] = dailyCondition.getPrintableSleepDuration()
        if (dailyCondition.sleepDuration != null) dataForValueFieldAvailable.set("sleepDuration", true)
        statisticsItem["waterIntake"] = Math.round((dailyCondition.waterIntake + Number.EPSILON) * 100) / 100
        statisticsItem["waterIntakeRendered"] = statisticsItem["waterIntake"]
        let availableMetrics = dailyCondition.getAllAvailableMetrics();
        availableMetrics.forEach(metric => {
          let averageMetricData = dailyCondition.getAverageMetricDataWithQuestionairesByMetricId(metric.metricId, this.displayQuestionaireMetrics);
          
          if (averageMetricData == null) return

          if (metric.metricId == "bodyFat") {
            statisticsItem["metric" + metric.metricId] = (averageMetricData.value * 100) ?? undefined
          } else {
            statisticsItem["metric" + metric.metricId] = averageMetricData.value ?? undefined
          }
          statisticsItem["metric" + metric.metricId + "Rendered"] = averageMetricData.getPrintableValue() ?? undefined
          if (averageMetricData.value != null) dataForValueFieldAvailable.set("metric" + averageMetricData.metricId, true)

          if (metric.metricId == 'bodyWeight' || metric.metricId == 'NUT_bodyWeight') {
            //let weight = statisticsItem[metric.metricId]
            if(averageMetricData?.value) {
              let bmi = Math.round(averageMetricData.value / Math.pow(user.bodyHeight / 100, 2) * 100) / 100;
              if(bmi) {
                statisticsItem["bmi"] = Math.round(averageMetricData.value / Math.pow(user.bodyHeight / 100, 2) * 100) / 100
                statisticsItem["bmiRendered"] = statisticsItem["bmi"]
                dataForValueFieldAvailable.set("bmi", true)
              }
              var averageWeight = averageWeightMap.get(statisticsItem.weekNumber)
              var averageWeightCount = averageWeightCountMap.get(statisticsItem.weekNumber)
              if (averageWeight) {
                averageWeight = averageWeight + averageMetricData.value
                averageWeightCount++
              } else {
                averageWeight = averageMetricData.value
                averageWeightCount = 1
              }
              averageWeightMap.set(statisticsItem.weekNumber, averageWeight)
              averageWeightCountMap.set(statisticsItem.weekNumber, averageWeightCount)
            }
          }
        })
      }

      // Collect nutritional data.
      var nutritionStatistic = user.getNutritionStatisticsItemForDate(date)
      if (nutritionStatistic) {
        statisticsItem["calories"] = nutritionStatistic.nutritionalSummary.calories.roundToPlaces(1)
        statisticsItem["caloriesRendered"] = statisticsItem["calories"]
        dataForValueFieldAvailable.set("calories", true)
        statisticsItem["carbohydrates"] = nutritionStatistic.nutritionalSummary.carbohydrates.roundToPlaces(1)
        statisticsItem["carbohydratesRendered"] = statisticsItem["carbohydrates"]
        dataForValueFieldAvailable.set("carbohydrates", true)
        statisticsItem["protein"] = nutritionStatistic.nutritionalSummary.protein.roundToPlaces(1)
        statisticsItem["proteinRendered"] = statisticsItem["protein"]
        dataForValueFieldAvailable.set("protein", true)
        statisticsItem["fat"] = nutritionStatistic.nutritionalSummary.fat.roundToPlaces(1)
        statisticsItem["fatRendered"] = statisticsItem["fat"]
        dataForValueFieldAvailable.set("fat", true)

        statisticsItem["sugar"] = nutritionStatistic.nutritionalSummary.sugar.roundToPlaces(1)
        statisticsItem["sugarRendered"] = statisticsItem["sugar"]
        dataForValueFieldAvailable.set("sugar", true)
        statisticsItem["saturatedFat"] = nutritionStatistic.nutritionalSummary.saturatedFat.roundToPlaces(1)
        statisticsItem["saturatedFatRendered"] = statisticsItem["saturatedFat"]
        dataForValueFieldAvailable.set("saturatedFat", true)
        statisticsItem["fibre"] = nutritionStatistic.nutritionalSummary.fibre.roundToPlaces(1)
        statisticsItem["fibreRendered"] = statisticsItem["fibre"]
        dataForValueFieldAvailable.set("fibre", true)
        statisticsItem["salt"] = nutritionStatistic.nutritionalSummary.salt.roundToPlaces(1)
        statisticsItem["saltRendered"] = statisticsItem["salt"]
        dataForValueFieldAvailable.set("salt", true)

        if (nutritionStatistic.activities) {
          var duration = 0
          var calories = 0
          nutritionStatistic.activities.forEach(a => {
            if (a.activityFactId != 'acf71') duration += (a.duration || 0)
            calories += a.caloriesBurned
          })
          try{
            statisticsItem["activitiesDuration"] = duration.roundToPlaces(1)
            statisticsItem["activitiesDurationRendered"] = statisticsItem["activitiesDuration"]
            dataForValueFieldAvailable.set("activitiesDuration", true)
            statisticsItem["activitiesCalories"] = calories.roundToPlaces(1)
            statisticsItem["activitiesCaloriesRendered"] = statisticsItem["activitiesCalories"]
            dataForValueFieldAvailable.set("activitiesCalories", true)
          }
          catch(ex){
            console.error(ex)
          }
        }
      }

      statisticsItems.push(statisticsItem)
    }
    statisticsItems.forEach(items => {
      if (items['metricbodyWeight'] != null || items['metricNUT_bodyWeight'] != null){
        var weekNumber = items.weekNumber;
        var averageWeight = averageWeightMap.get(weekNumber) / averageWeightCountMap.get(weekNumber)
        let weeklyAverage = Math.round(averageWeight * 100) / 100
        items["bodyWeightWeeklyAverage"] = weeklyAverage
        items["bodyWeightWeeklyAverageRendered"] = weeklyAverage

      }
    })
    this.isTableDateColumnVisible = this.isTableColumnVisible("date")
    this.allStatisticsGraphItems.forEach(item => {
      if (dataForValueFieldAvailable.get(item.valueField) == true)
      { 
        item.visible = this.isTableColumnVisible(item.valueField);
        this.tableStatisticsGraphItems.push(item)
      }
    })
    this.additionalBodyDataGraphTypesForTable.forEach(item => {
      if (dataForValueFieldAvailable.get(item.valueField) == true)
      { 
        item.visible = this.isTableColumnVisible(item.valueField);
        this.tableStatisticsGraphItems.push(item)
      }
    });
    Metric.circumferenceMetricIds.forEach(metricId => {
      let item = {valueField: "metric" + metricId, name: Metric.circumferenceMetricIdToNameMapping.get(metricId), summaryType: "avg", visible: true}
      if (dataForValueFieldAvailable.get(item.valueField) == true && this.tableStatisticsGraphItems.find(x => x.valueField == item.valueField) == null){
        item.visible = this.isTableColumnVisible(item.valueField);
        this.tableStatisticsGraphItems.push(item)
      }
    });

    if (this.displayedMetricInGraph != null && this.displayedMetricInGraph.isMetricTypeYesNo()) {
      this.progressGraphVisualRange = [0, 1]
    } else if (this.displayedMetricInGraph != null && this.displayedMetricInGraph.isMetricTypeSelection()) {
      this.progressGraphVisualRange = [0, this.displayedMetricInGraph.getSelectableValues().length - 1]
    } else if (this.displayedMetricInGraph != null && this.displayedMetricInGraph.isMetricTypeNumber()) {
      this.progressGraphVisualRange = [this.displayedMetricInGraph.minValue != null ? this.displayedMetricInGraph.minValue : null, this.displayedMetricInGraph.maxValue != null ? this.displayedMetricInGraph.maxValue : null]
    } else {
      this.progressGraphVisualRange = [null, null]
    }
    if (this.displayedMetricInGraph != null && (this.displayedMetricInGraph.isMetricTypeSelection() || this.displayedMetricInGraph.isMetricTypeYesNo())) {
      this.progressGraphTickIntervall = 1
    } else {
      this.progressGraphTickIntervall = undefined
    }

    this.progressStatisticsGraphData = statisticsItems
    
  }

  legendClickHandler(e) {
    const series = e.target;
    if (series.isVisible()) {
      series.hide();
    } else {
      series.show();
    }
  }

  loadStatisticForUserAndDate(user: User, date: Date): Observable<NutritionStatisticsItem> {
    // Fetch Meals and NutritionalGoal.
    return combineLatest(
      this.userService.getMealsWithFoodsByDate(user, date, false, false),
      this.userService.getNutritionalGoalIncludingActivitiesByDate(user, date)
    ).pipe(
      map(([meals, nutritionalGoal]) => {
        var statistic = new NutritionStatisticsItem()
        statistic.date = date
        var nutritionalSummary = new NutritionalSummary();
        statistic.nutritionalSummary = nutritionalSummary;
        statistic.nutritionalGoal = nutritionalGoal

        statistic.carbohydrates = 0
        statistic.protein = 0
        statistic.fat = 0
        statistic.carbohydratesGoal = nutritionalGoal.carbohydrates
        statistic.proteinGoal = nutritionalGoal.protein
        statistic.fatGoal = nutritionalGoal.fat
        statistic.meals = meals

        statistic.sugar = 0
        statistic.saturatedFat = 0
        statistic.fibre = 0
        statistic.salt = 0

        if (nutritionalSummary.calories >= nutritionalGoal.calories) {
          statistic.calories = 0
          statistic.calorieGoal = nutritionalGoal.calories
          statistic.calorieDeficit = 0;
          statistic.calorieSurplus = nutritionalSummary.calories - nutritionalGoal.calories
        } else {
          statistic.calories = nutritionalSummary.calories
          statistic.calorieGoal = 0
          statistic.calorieSurplus = 0
          statistic.calorieDeficit = nutritionalGoal.calories - nutritionalSummary.calories
        }
        
        meals.forEach(meal => {
          nutritionalSummary.carbohydrates = nutritionalSummary.carbohydrates + meal.getCarbohydrates()
          nutritionalSummary.protein = nutritionalSummary.protein + meal.getProtein()
          nutritionalSummary.fat = nutritionalSummary.fat + meal.getFat()
          nutritionalSummary.calories = nutritionalSummary.calories + meal.getCalories()

          nutritionalSummary.sugar = nutritionalSummary.sugar + meal.getSugar();
          nutritionalSummary.saturatedFat = nutritionalSummary.saturatedFat + meal.getSaturatedFat();
          nutritionalSummary.fibre = nutritionalSummary.fibre + meal.getFibre();
          nutritionalSummary.salt = nutritionalSummary.salt + meal.getSalt();

          if (nutritionalSummary.calories >= nutritionalGoal.calories) {
            statistic.calories = 0
            statistic.calorieGoal = nutritionalGoal.calories
            statistic.calorieDeficit = 0;
            statistic.calorieSurplus = nutritionalSummary.calories - nutritionalGoal.calories
          } else {
            statistic.calories = nutritionalSummary.calories
            statistic.calorieGoal = 0
            statistic.calorieSurplus = 0
            statistic.calorieDeficit = nutritionalGoal.calories - nutritionalSummary.calories
          }
          statistic.carbohydrates = nutritionalSummary.carbohydrates
          statistic.protein = nutritionalSummary.protein
          statistic.fat = nutritionalSummary.fat

          statistic.sugar = nutritionalSummary.sugar
          statistic.saturatedFat = nutritionalSummary.saturatedFat
          statistic.fibre = nutritionalSummary.fibre
          statistic.salt = nutritionalSummary.salt
        })
        
        return statistic
      })
    );
  }

  onOpenMetricDataImage(metricData: MetricData) {
    const dialogRef = this.dialog.open(MetricDataImageDialogComponent, { data: { imageURL: metricData.mediaLink}});
  }

  /*Bild Metriken anzeigen */
  showImageMetrics: boolean = false
  canShowImageMetrics:boolean = false

  showVideoMetrics: boolean = false
  canShowVideoMetrics:boolean = false

  onShowImageMetricsChanged(value: boolean){
    this.showImageMetrics = value
    // this.prepareImageMetricTypes(this.displayedUser)
    this.updateVisibleImages(this.displayedUser)
  }
  onShowVideoMetricsChanged(value: boolean){
    this.showVideoMetrics = value
    this.updateVisibleImages(this.displayedUser)
  }


  visibleImages:any = []
  visibleVideos: any = []
  async updateVisibleImages(user: User){
    this.visibleImages = []
    this.visibleVideos = []
    if (!this.selectedImageMetricType) return
    var timeRange: number;
    if (this.selectedBodyDataGraphTimeRange.id == 0) {
      timeRange = 14;
    } else if (this.selectedBodyDataGraphTimeRange.id == 1) {
      timeRange = 31;
    } else if (this.selectedBodyDataGraphTimeRange.id == 2) {
      timeRange = 90;
    } else if (this.selectedBodyDataGraphTimeRange.id == 3) {
      timeRange = 180;
    } else if (this.selectedBodyDataGraphTimeRange.id == 4) {
      timeRange = 365;
    } else if (this.selectedBodyDataGraphTimeRange.id == 5){
      timeRange = this.getFullTimeRangeNumber(user);
    } else if (this.selectedBodyDataGraphTimeRange.id == 6){
      if(this.selectedStartDate && this.selectedEndDate && this.selectedEndDate > this.selectedStartDate) {
        this.selectedEndDate.setHours(0,0,0,0)
        this.selectedStartDate.setHours(0,0,0,0)
        timeRange = (this.selectedEndDate.getTime() - this.selectedStartDate?.getTime()) / (1000 * 3600 * 24)
      }
      else {
        return;
      }
    }

    var startDate = new Date();

    if(this.selectedBodyDataGraphTimeRange.id == 6) {
      startDate = this.selectedStartDate;
    }
    else {
      startDate.setDate(startDate.getDate() - timeRange);
      startDate.setHours(0,0,0,0);
    }
    var dailyCondition: DailyCondition;

    var statisticsItems: any[];
    var statisticsItem: any;
    statisticsItems = []

    for (var i = 0; i <= timeRange; i++) {
      var date = new Date(startDate.getTime())
      date.setDate(date.getDate() + i)
      dailyCondition = user.getDailyConditionForDate(date)
      if (dailyCondition) {
        var metricDatas = dailyCondition.getMetricDataListWithQuestionaires()
        for (var metricData of metricDatas) {
          if (metricData.metric !== null) {
            if (metricData.metric.isMetricTypeImage() && metricData.metric.metricId === this.selectedImageMetricType.tmp.replace('metric', '')){
              if (!metricData.mediaLink) {
                metricData.mediaLink = await this.userService.getMetricDataMediaLink(metricData, user.uid)
              }
              if (metricData.mediaLink) {
                statisticsItem = {
                  date: date,
                  dateRendered: date.asShortFormatedString(),
                  weekNumber: date.getWeekOfYearNumber(),
                  imageURL: metricData.mediaLink ?? undefined
                }
                this.visibleImages.push(statisticsItem)
              }
            } else if (metricData.metric.isMetricTypeVideo() && metricData.metric.metricId === this.selectedVideoMetricType.tmp.replace('metric', '')){
              if (!metricData.mediaLink) {
                metricData.mediaLink = await this.userService.getMetricDataMediaLink(metricData, user.uid)
              }
              if (metricData.mediaLink) {
                statisticsItem = {
                  date: date,
                  dateRendered: date.asShortFormatedString(),
                  weekNumber: date.getWeekOfYearNumber(),
                  videoURL: metricData.mediaLink ?? undefined
                }
                this.visibleVideos.push(statisticsItem)
              }
            }
          }
        }
      }
    }
  }

  onImageMetricTypeChanged(selection: DropdownItem) {
    this.selectedImageMetricType = selection
    this.updateVisibleImages(this.displayedUser)
    this.selectedCompareImages = []
  }
  onVideoMetricTypeChanged(selection: DropdownItem) {
    this.selectedVideoMetricType = selection
    this.updateVisibleImages(this.displayedUser)
  }


  onImageSelected(image: any) {
    if(this.selectedCompareImages.includes(image)){
      this.selectedCompareImages = this.selectedCompareImages.filter(item => item.imageURL != image.imageURL)
    } else{
      this.selectedCompareImages.push(image)
    }
  }

  onOpenCompareImageDialog() {
    if (this.selectedCompareImages?.length <= 0) return
    const dialogRef = this.dialog.open(MetricDataImageDialogComponent, { data: { compareImages: this.selectedCompareImages}});
  }
  
  onOpenMetricTypeImage(imageLink: string) {
    const dialogRef = this.dialog.open(MetricDataImageDialogComponent, { data: { imageURL: imageLink}});
  }

  imageMetricTypes:DropdownItem[] = []
  selectedImageMetricType: DropdownItem
  selectedCompareImages: any[] = []

  videoMetricTypes:DropdownItem[] = []
  selectedVideoMetricType: DropdownItem

  prepareImageMetricTypes(user: User) {
    var handledMetricIds: string[] = []
    this.imageMetricTypes = []
    this.videoMetricTypes = []
    this.selectedCompareImages = []
    user.dailyConditions.forEach(dailyCondition => {
      dailyCondition.getMetricDataListWithQuestionaires()?.forEach(data => {
        if (!handledMetricIds.includes(data.metricId) && this.imageMetricTypes.filter(function(e) { return e.tmp === 'metric' + data.metricId; }).length == 0) {
          var metric = this.userService.getMetricByMetricId(data.metricId)
          if (metric != null && metric.isMetricTypeImage()){ 
            this.imageMetricTypes.push({id: 6, name: metric.nameDe ?? 'Metrik ' + data.metricId, tmp: 'metric' + data.metricId, unit: metric.unit, order: 99})
          }
          if (metric != null && metric.isMetricTypeVideo()){ 
            this.videoMetricTypes.push({id: 6, name: metric.nameDe ?? 'Metrik ' + data.metricId, tmp: 'metric' + data.metricId, unit: metric.unit, order: 99})
          }
          handledMetricIds.push(data.metricId)
        }
      })
    })
    if(this.imageMetricTypes.length > 0){
      this.selectedImageMetricType = this.imageMetricTypes[0]
      this.canShowImageMetrics = true
    }
    else{
      this.selectedImageMetricType = undefined
      this.canShowImageMetrics = false
    }

    if(this.videoMetricTypes.length > 0){
      this.selectedVideoMetricType = this.videoMetricTypes[0]
      this.canShowVideoMetrics = true
    }
    else{
      this.selectedVideoMetricType = undefined
      this.canShowVideoMetrics = false
    }
    this.updateVisibleImages(this.displayedUser)
  }

  logout() {
    this.userService.logout();
    this.authService.logout();
  }

  getNutritionalSummary() {
    return this.user.nutritionalSummary;
  }

  createRange(number){
    var items: number[] = [];
    for(var i = 0; i < number; i++){
       items.push(i);
    }
    return items;
  }  

  
  
  public isTableDateColumnVisible: boolean = true
  isTableColumnVisible(columnName: string){
    let loggedInUser = this.userService.getLoggedInUser();
    if(loggedInUser?.isCoach){
      return loggedInUser?.portalSettingsCoach?.hiddenBodyDataTableColumns?.includes(columnName) == false
    }
    return true
  }

  tableColumnVisibilityChanged(visible, columnName){
    let loggedInUser = this.userService.getLoggedInUser();
    if(loggedInUser.isCoach){
      if(visible == false){
            let loggedInUser = this.userService.getLoggedInUser();
            loggedInUser.portalSettingsCoach.hiddenBodyDataTableColumns.push(columnName)
      }
      else{
        loggedInUser.portalSettingsCoach.hiddenBodyDataTableColumns = loggedInUser.portalSettingsCoach.hiddenBodyDataTableColumns.filter(x => x != columnName)
      }
      this.userService.updatePortalSettingsForCoach(loggedInUser)
    }
  }


}

class DropdownItem {
  id: number;
  tmp: string;
  name: string;
  unit: string;
  order: number;
}

class MetricType {
  type: string;
  description: string;
}