import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { CustomerPaymentSettings, PaymentMethod } from 'src/app/model/customer-payment-settings.model';
import { PaymentMethodOption, PaymentSettings } from 'src/app/model/payment-settings.model';
import { Payment } from 'src/app/model/payment.model';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-invoice-history-dialog',
  templateUrl: './invoice-history-dialog.component.html',
  styleUrls: ['./invoice-history-dialog.component.css']
})
export class InvoiceHistoryDialogComponent {

  get user(): User {
    return this.userService.getLoggedInUser()
  }

  paymentSettings: PaymentSettings
  stripeAccountId: string
  payments: Payment[]
  customerPaymentSettings: CustomerPaymentSettings
  choosePaymentMethod: boolean = false

  constructor(public dialogRef: MatDialogRef<InvoiceHistoryDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { paymentSettings: PaymentSettings, stripeAccountId: string }, public userService: FirestoreService, private spinner: NgxSpinnerService, private dialog: MatDialog, private paymentService: PaymentService, private authService: AuthService, public translate: TranslateService) {
    this.paymentSettings = data.paymentSettings
    this.stripeAccountId = data.stripeAccountId

    authService.getAuthState().subscribe(async user => {
      if (user) {
        this.loadPayments()
        await this.loadCustomerPaymentSettings()
        this.setupPaymentMethods()
      } else {
        this.payments = null
      }
    });
  }

  async loadCustomerPaymentSettings() {
    var customerPaymentSettings = await this.userService.getCustomerPaymentSettings(this.user.uid)
    for (var method of customerPaymentSettings.defaultPaymentMethods) {
      method.paymentSettings = await this.userService.getPaymentSettings(method.licenceHolderUid)
      method.licenceHolderName = (await firstValueFrom(this.userService.getLicenceHolderByUid(method.licenceHolderUid)))?.name
    }
    this.customerPaymentSettings = customerPaymentSettings
  }
  loadPayments() {
    this.spinner.show()
    firstValueFrom(this.userService.getPaymentsOfUser(this.user.uid)).then(payments => {
      this.payments = payments
      this.spinner.hide()
    })
  }
  onOpenInvoice(payment: Payment) {
    this.spinner.show()
    this.userService.getDownloadUrl('payments/' + payment.id + '/' + (payment.invoiceFileName ?? (this.translate.instant('Rechnung') + '_' + payment.id + '.pdf'))).then(url => {
      this.spinner.hide()
      window.open(url, "_blank");
    })
  }

  public changingDefaultPaymentMethod: PaymentMethod
  public collectBillingAddress = false
  public address: any = {}

  selectedPaymentMethod: PaymentMethodOption
  paymentMethods: PaymentMethodOption[] = []

  async setupPaymentMethods() {
    var availablePaymentMethods = this.paymentSettings?.availablePaymentMethods
    this.paymentMethods = await this.paymentService.getAvailablePaymentMethodOptions(availablePaymentMethods, this.stripeAccountId, false)
  }

  getPaymentMethods() {
    return this.paymentMethods
  }

  onChangeDefaultPaymentMethod(paymentMethod: PaymentMethod) {
    this.changingDefaultPaymentMethod = paymentMethod
    this.choosePaymentMethod = true
    this.collectBillingAddress = false
  }

  async onConfirmPaymentMethod(paymentMethod: PaymentMethodOption) {
    if (this.selectedPaymentMethod?.value == 'bank_transfer') {
      this.spinner.show()
      console.log('Loading stripe connect customer data...')
      var res = await firstValueFrom(this.userService.getStripeConnectCustomerData(this.user.uid, this.paymentService.stripeAccountId))
      console.log(res)
      if (res.success == true) {
        this.address = res.data.address
        this.address.name = res.data.name
      }
      this.choosePaymentMethod = false
      this.collectBillingAddress = true
      this.spinner.hide()
    } else {
      this.spinner.show()
      firstValueFrom(this.userService.createStripeConnectPaymentMethodUpdateSession(this.customerPaymentSettings.defaultPaymentMethods[0].licenceHolderUid, this.user.uid, null, this.selectedPaymentMethod?.value ?? null)).then(result => {
        console.log(result)
        this.spinner.hide()
        if (result.redirectUrl) {
          document.location.href = result.redirectUrl
        } else if (result.error) {
          console.log(result.error)
        }
      }, err => {
        console.log(err)
      })
    }
  }
  isAddressValid() {
    return this.address.name && this.address.street && this.address.postalCode && this.address.city && this.address.country
  }
  async onConfirmBillingAddress() {
    this.spinner.show()
    var res = await firstValueFrom(this.userService.updateStripeConnectCustomer(this.address, this.user.uid, this.paymentService.stripeAccountId))
    console.log(res)
    var res = await firstValueFrom(this.userService.updateDefaultPaymentMethod(this.user.uid, this.changingDefaultPaymentMethod.licenceHolderUid, null, 'bank_transfer'))
    this.collectBillingAddress = false
    this.loadCustomerPaymentSettings()
    this.spinner.hide()
  }

  onPayInvoice(payment: Payment) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: this.translate.instant('Bist du sicher, dass du diese Rechnung mit deiner Standard-Zahlungsmethode bezahlen möchtest?'), title: this.translate.instant('Rechnung bezahlen'), positiveButton: this.translate.instant('Ja'), negativeButton: this.translate.instant('Nein') },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) {
        this.spinner.show()
        var res = await this.userService.reChargePayment(payment, this.paymentService.stripeAccountId)
        console.log(res)
        this.spinner.hide()
        this.onCloseDialog()
      }
    })
  }
  onOpenBankTransferInstructions(payment: Payment) {
    var url = payment.bankTransferInstructionsUrl
    if (url) {
      window.open(url, "_blank");
    }
  }

  onCloseDialog() {
    this.closeDialog()
  }

  closeDialog() {
    this.dialogRef.close(null);
  }
}
