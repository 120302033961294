<div class="container">
    <div class="col-12" *ngFor="let zoneGroup of filteredCardioZoneGroups">
        <ng-container *ngIf="!zoneGroup.deleted">
            <h2 *ngIf="zoneGroup?.isDefaultZoneGroup; else inputHeader">
                <span translate>Standard</span>
                <div class="spacer-10"></div>
            </h2>
            <ng-template #inputHeader>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <input id="input-variable-name" [placeholder]="'Name der Zonengruppe' | translate" maxlength="20" required class="input small full-width {{zoneGroup.isGroupNameInvalid() ? 'invalid' : ''}}" [value]="zoneGroup.name" (input)="setZoneGroupName(zoneGroup, $event.target.value)" type="text">
                    </div>
                    <div class="col-12 col-md-6">
                        <button class="button-filled light small dropdown-toggle margin-inbetween {{zoneGroup.isExerciseAssignementInvalid() ? 'invalid' : ''}}" [matMenuTriggerFor]="exercisesMenu" type="button" aria-haspopup="true" aria-expanded="false">{{getCardioZoneGroupExerciseNames(zoneGroup)}}</button>
                        <mat-menu #exercisesMenu="matMenu">
                            <div class="available-variables-area">
                                <ng-container *ngFor="let exerciseId of zoneGroup.exerciseIds">
                                    <button mat-menu-item (click)="removeExercise(zoneGroup, exerciseId)">
                                        {{trainingService.getExerciseById(exerciseId)?.name?.GetValue(translate.currentLang)}}
                                        <i class="bi bi-x-lg"></i>
                                    </button>
                                </ng-container>
                                <mat-divider></mat-divider>
                                <button mat-menu-item (click)="addExercise(zoneGroup)">
                                    {{'Übungen auswählen' | translate}}
                                </button>
                            </div>
                        </mat-menu>
                    </div>
                </div>
            </ng-template>
            <div class="spacer-10"></div>
            <table *ngIf="getCardioZones(zoneGroup)?.length > 0" class="col-12 sets-table">
                <thead class="variable-header">
                    <tr>
                        <th translate>
                            Zone
                        </th>
                        <th>
                            <span class="{{zoneGroup.heartRateAvailable ? '' : 'disabled'}}" translate>
                                Herzfrequenz
                            </span>
                            <i class="bi bi-arrow-repeat link" (click)="onUpdateHeartRateZoneValues(zoneGroup)" [matTooltip]="'Herzfrequenz-Werte anhand des Maximalwerts neu berechnen' | translate"></i>
                            <i class="bi bi-eye-slash link" (click)="onToggleHeartRate(zoneGroup)" [matTooltip]="'Herzfrequenz deaktivieren' | translate"></i>
                        </th>
                        <th>
                            <span class="{{zoneGroup.paceAvailable ? '' : 'disabled'}}" translate>
                                Pace
                            </span>
                            <i class="bi bi-arrow-repeat link" (click)="onUpdatePaceZoneValues(zoneGroup)" [matTooltip]="'Pace-Werte anhand des Maximalwerts neu berechnen' | translate"></i>
                            <i class="bi bi-eye-slash link" (click)="onTogglePace(zoneGroup)" [matTooltip]="'Pace deaktivieren' | translate"></i>
                        </th>
                        <th>
                            <span class="{{zoneGroup.pace500Available ? '' : 'disabled'}}" translate>
                                Pace 500m
                            </span>
                            <i class="bi bi-arrow-repeat link" (click)="onUpdatePace500ZoneValues(zoneGroup)" [matTooltip]="'Pace500-Werte anhand des Maximalwerts neu berechnen' | translate"></i>
                            <i class="bi bi-eye-slash link" (click)="onTogglePace500(zoneGroup)" [matTooltip]="'Pace500 deaktivieren' | translate"></i>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let zone of getCardioZones(zoneGroup)">
                        <tr>
                            <td>
                                {{zone.name}}
                                <!-- <input disabled id="input-variable-name" placeholder="Zone 1" maxlength="10" required class="input small full-width {{isZoneNameInvalid(zone, zoneGroup) ? 'invalid' : ''}}" [value]="zone.name" (input)="setName(zone, $event.target.value)" placeholder="Name" type="text"> -->
                            </td>
                            <td *ngIf="dialogType == TrainingVariableEditorDialogType.user || dialogType == TrainingVariableEditorDialogType.plan">
                                <input min="0" [disabled]="!zoneGroup.heartRateAvailable" class="input small full-width {{zoneGroup.isHeartRateValueInvalid(zone) ? 'invalid' : ''}}" (input)="setHeartRate(zone, $event.target.value)" type="text" [pattern]="rangePattern" [value]="getHeartRate(zone)" [placeholder]="zoneGroup.getCardioZoneHeartRatePlaceholder(zone)">
                            </td>
                            <td *ngIf="dialogType == TrainingVariableEditorDialogType.user || dialogType == TrainingVariableEditorDialogType.plan">
                                <input min="0" [disabled]="!zoneGroup.paceAvailable" class="input small full-width {{zoneGroup.isPaceValueInvalid(zone) ? 'invalid' : ''}}" (input)="setPace(zone, $event.target.value, false)" type="text" [pattern]="paceRangePattern" [value]="getPace(zone, false)" [placeholder]="zoneGroup.getCardioZonePacePlaceholder(zone)">
                            </td>
                            <td *ngIf="dialogType == TrainingVariableEditorDialogType.user || dialogType == TrainingVariableEditorDialogType.plan">
                                <input min="0" [disabled]="!zoneGroup.pace500Available" class="input small full-width {{zoneGroup.isPace500ValueInvalid(zone) ? 'invalid' : ''}}" (input)="setPace(zone, $event.target.value, true)" type="text" [pattern]="paceRangePattern" [value]="getPace(zone, true)">
                            </td>
                        </tr>
                        <ng-container *ngIf="zone.isMaxCardioZone" >
                            <tr class="spacer">
                            </tr>
                        </ng-container>
                    </ng-container>
                </tbody>
            </table>
            <div class="spacer-20"></div>
        </ng-container>
    </div>

    <div class="spacer-10"></div>
    <div class="row icon-button" [matMenuTriggerFor]="menu" translate>
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
        </svg>
        Neue Gruppe anlegen
    </div>
    <mat-menu #menu="matMenu">
        <div class="available-variables-area">
            <button mat-menu-item (click)="addCardioZoneGroup()" translate>
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
                <span translate>Leere Gruppe erstellen</span>
            </button>
            <button *ngIf="filteredCardioZoneGroups?.length > 0" mat-menu-item [matMenuTriggerFor]="subMenu" [innerHTML]="'Bestehende Gruppe kopieren' | translate">
            </button>
        </div>
    </mat-menu>
    <mat-menu #subMenu="matMenu">
        <ng-container *ngFor="let zoneGroup of filteredCardioZoneGroups">
            <button mat-menu-item (click)="copyZoneGroup(zoneGroup)">
                <ng-container *ngIf="zoneGroup.isDefaultZoneGroup; else displayName">
                    Standard
                </ng-container>
                <ng-template #displayName>
                    {{zoneGroup.name}}
                </ng-template>
            </button>
        </ng-container>
    </mat-menu>
</div>

<div id="exercises-table-dialog" class="dialog-wrapper show" *ngIf="exerciseDialogVisible">
    <div class="dialog layout-box">
        <div class="dialog-topbar">
            <div class="icon-button float-right" (click)="onCancelExercisesSelection()" translate>
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                Schließen
            </div>
        </div>
        <div class="dialog-content-wrapper">
            <div class="row justify-content-center">
                <app-exercises-table [TrainingExercises]="trainingService.MergedTrainingExercises" [(selectedTrainingExercises)]="selectedTrainingExercises" (trainingExerciseSelected)="onTrainingExerciseSelectionChanged($event)" [multiselect]="true"></app-exercises-table>
            </div>
        </div>        
        <div class="dialog-bottombar" mat-dialog-actions>
            <div cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="selectedTrainingExercisesDrop($event)" class="selected-exercises-wrapper">
                <div cdkDrag cdkDragLockAxis="x" class="selected-exercise" *ngFor="let exercise of selectedTrainingExercises">
                    <div class="remove-exercise-button link" (click)="removeSelectedExercise(exercise)">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                    <div class="thumbnail-image">
                        <img draggable="false" *ngIf="exercise.thumbnailDownloadURL" loading="lazy"
                            src="{{exercise.thumbnailDownloadURL}}">
                        <div *ngIf="!exercise.thumbnailDownloadURL">{{
                            (trainingService.getExerciseThumbnailUrl(exercise) | async) }}</div>
                    </div>
                </div>
            </div>
            <button *ngIf="selectedTrainingExercises?.length > 0" class="button-filled small select-exercises"
                (click)="onTakeExercisesSelection()" translate>Auswahl übernehmen</button>
        </div>
    </div>
</div>