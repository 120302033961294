import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MergedTrainingExercise } from 'src/app/model/training-exercise';
import { TrackedTrainingExercise, TrackedVideoRecording } from 'src/app/model/training-monitoring.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { TrainingService } from 'src/app/services/training.service';

@Component({
  selector: 'app-video-recording-dialog',
  templateUrl: './video-recording-dialog.component.html',
  styleUrls: ['./video-recording-dialog.component.css']
})
export class VideoRecordingDialogComponent implements OnInit, OnDestroy {

  public trackedExercise: TrackedTrainingExercise
  public recording: TrackedVideoRecording
  public exercise: MergedTrainingExercise
  public userUid: string
  public hasChanges: boolean = false
  public isEmojiPickerVisible: boolean = false;

  public editMode: boolean = false

  constructor(public dialogRef: MatDialogRef<VideoRecordingDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {userUid: string, recording: TrackedVideoRecording, trackedExercise: TrackedTrainingExercise, exercise: MergedTrainingExercise, editMode: boolean}, public firestoreService: FirestoreService, private toastr: ToastrService, public translate: TranslateService) {
    this.recording = data.recording
    this.exercise = data.exercise
    this.userUid = data.userUid
    this.trackedExercise = data.trackedExercise
    this.editMode = data.editMode ?? false
  }

  ngOnDestroy(): void {
    if (this.recording?.newVideoFile && this.recording?.videoUrl) {
      try {
        URL.revokeObjectURL(this.recording.videoUrl)
      }
      catch(ex) {
        console.error(ex)
      }
    }
  }

  ngOnInit(): void {  
    if((this.recording?.newVideoFile || this.recording?.newVideoBlob)) {
      try {
        if(this.recording.newVideoBlob) {
          this.recording.videoUrl = URL.createObjectURL(this.recording.newVideoBlob);
        }
        else {
          this.recording.videoUrl = URL.createObjectURL(this.recording.newVideoFile);
        }
      }
      catch(ex) {
        console.error(ex)
      }
    }
  }

  onVideoClicked() {
    if (this.recording.videoUrl && !this.recording.viewedByCoach) {
      this.recording.viewedByCoach = true
      this.firestoreService.markExerciseRecordingAsViewed(this.userUid, this.trackedExercise).then()
    }
  }

  onCoacheeNoteChanged(value: string) {
    this.recording.note = value
    this.hasChanges = true
  }

  onEditorValueChanged(value: string) {
    this.recording.feedbackNote = value
    this.recording.feedbackRead = false
    this.hasChanges = true
  }

  async onSendFeedback() {
    try {
      await this.firestoreService.updateExerciseRecordingFeedback(this.userUid, this.trackedExercise)
      var user = this.firestoreService.getClient(this.userUid)
      if (user) {
        this.firestoreService.createUserNotificationEntry(user, 'Trainingsvideo-Feedback erhalten', 'Dein Coach hat dir Feedback zur deinem Video der Übung ' + (this.exercise?.name?.GetValue('de') ?? '') + ' gesendet.', 'newTrainingVideoFeedback', this.trackedExercise.id, this.recording.setNumber?.toString())
        this.firestoreService.sendPushNotification('NEW_ASSIGNMENTS', 'Video-Feedback erhalten', 'Du hast Feedback zu einer Videoaufnahme erhalten.', user)
        this.toastr.success(this.translate.instant("Feedback wurde gesendet!"), "" ,  {
          positionClass: 'toast-bottom-center'
        });
        this.hasChanges = false
      }
      else {
        this.toastr.error(this.translate.instant("Feedback konnte leider nicht gesendet werden."));
      }
      //if (user) this.firestoreService.sendExerciseFeedbackNotification(user, this.trackedExercise.trackedSessionId, this.trackedExercise.id, this.recording.setNumber)
    }
    catch(error){
      console.error(error);
      this.toastr.error(this.translate.instant("Feedback konnte leider nicht gesendet werden."));
    }
  }

  async onCloseDialog() {
    this.dialogRef.close()
  }
  
  goToLink(url: string) {
    window.open(url, "_blank");
  }
  openVideoDialog(){
    if(!this.editMode) return;
    (document.querySelector('#video-upload') as HTMLElement).click()
  }
  
  async onUploadVideoSelected(event) {
    if (event.target?.files?.length > 0) {
      let file = event.target.files[0];
      this.recording.newVideoBlob = null;
      this.recording.newVideoFile = file;
      this.recording.videoUrl = URL.createObjectURL(file);
      this.recording.urlsLoaded = true;
      this.hasChanges = true;
    }
  }

  onSaveChanges() {
    this.dialogRef.close({recording: this.recording, shouldTake: true});
  }

  onDeleteVideo() {
    this.recording.videoUrl = null
    this.recording.videoPath = null
    this.recording.newVideoBlob = null
    this.recording.newVideoFile = null
    this.hasChanges = true
  }

  
  handleEmojiSelection(event) {
    if(!this.recording.feedbackNote) {
      this.recording.feedbackNote = "";
    }
    this.recording.feedbackNote += event.emoji.native;
    this.isEmojiPickerVisible = false;
    this.hasChanges = true;
  }
}
