import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-client-selection-dialog',
  templateUrl: './client-selection-dialog.component.html',
  styleUrls: ['./client-selection-dialog.component.css']
})
export class ClientSelectionDialogComponent {

  public title: string = "";
  public availableClients: User[]
  public availableClientGroups: string[]
  public selectedClients: User[] = []
  public selectedClientGroups: string[] = []
  
  public cancelButtonText: string = "Abbrechen"
  public submitButtonText: string = "Ok"


  constructor(public dialogRef: MatDialogRef<ClientSelectionDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { title: string, availableClients: User[], availableClientGroups: string[], cancelButtonText: string, submitButtonText: string }, public userService: FirestoreService) {
    this.title = data.title;
    this.availableClients = data.availableClients || []
    this.availableClientGroups = data.availableClientGroups || []
    if (data.cancelButtonText) this.cancelButtonText = data.cancelButtonText
    if (data.submitButtonText) this.submitButtonText = data.submitButtonText;
  }

  areAllUsersTargeted() {
    return this.selectedClientGroups?.includes('Alle')
  }

  
  onTargetSelectionChangedUser(user: User){
    if (!this.canAccessUser(user)) return
    if (this.selectedClients.includes(user)) {
      this.selectedClients.forEach( (item, index) => {
        if (item == user) this.selectedClients.splice(index, 1)
      })
    } else {
      this.selectedClients.push(user)
    }
  }

  onTargetSelectionChangedGroup(group: string){
    if (!this.canAccessGroup(group)) return
    if (this.selectedClientGroups.includes(group)) {
      this.selectedClientGroups.forEach( (item, index) => {
        if (item == group) this.selectedClientGroups.splice(index, 1)
      })
    } else {
      this.selectedClientGroups.push(group)
    }
  }

  canAccessGroup(group: string) {
    return this.userService.getLoggedInUser().coach.canAccessClientGroup(group)
  }
  canAccessUser(user: User) {
    return this.userService.getLoggedInUser().coach.canAccessUser(user)
  }


  getPrintableRecipients(): string{
    var checkedElements = '';
    this.selectedClientGroups.forEach(element => {
      if(checkedElements.length > 0){
        checkedElements = checkedElements.concat(', ')
      }
      checkedElements = checkedElements.concat(element);
    })
    this.selectedClients.forEach(element => {
      if(checkedElements.length > 0){
        checkedElements = checkedElements.concat(', ')
      }
      checkedElements = checkedElements.concat(element.name);
    })
    if (checkedElements.length == 0) checkedElements = 'Auswählen'
    return checkedElements;
}

  onCancel(): void {
    this.dialogRef.close({confirm: false});
  }

  onSubmit(): void {
    this.dialogRef.close({ confirm: true, selectedClients: this.selectedClients, selectedClientGroups: this.selectedClientGroups });
  }
}
