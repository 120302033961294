import { marker } from "@colsen1991/ngx-translate-extract-marker"
import { TranslateService } from "@ngx-translate/core"

export class Tag {

    public static IDENTIFIER_SWEET = 'SWEET'
    public static IDENTIFIER_SAVOURY = 'SAVOURY'
    public static IDENTIFIER_LOW_CARB = 'LOW_CARB'
    public static IDENTIFIER_LOW_CALORIE = 'LOW_CALORIE'
    public static IDENTIFIER_HIGH_PROTEIN = 'HIGH_PROTEIN'
    public static IDENTIFIER_ONE_POT = 'ONE_POT'
    public static IDENTIFIER_VEGETARIAN = 'VEGETARIAN'
    public static IDENTIFIER_VEGAN = 'VEGAN'
    public static IDENTIFIER_BREAKFAST = 'BREAKFAST'
    public static IDENTIFIER_CUSTOM = 'CUSTOM'

    public rawTag: string;
    public identifier: string;
    public printableIdentifier: string;
    public isExcluding: boolean = false

    
    constructor(identifier: string, printableIdentifier: string, rawTag: string) {
        this.identifier = identifier
        this.printableIdentifier = printableIdentifier
        this.rawTag = rawTag
    }

    setExcluding() {
        this.isExcluding = true
        if (!this.rawTag.startsWith('!')) this.rawTag = '!' + this.rawTag
    }

    getPrintableName(translate: TranslateService) {
        if(this.identifier.startsWith(Tag.IDENTIFIER_CUSTOM)) return '#' + this.printableIdentifier
        return '#' + translate.instant(this.printableIdentifier)
    }

    clone(): Tag {
        var tag = new Tag(this.identifier, this.printableIdentifier, this.rawTag)
        tag.isExcluding = this.isExcluding
        return tag
    }

    static tagsFromString(string: string): Tag[] {
        if (!string || string.length == 0) return []
        var tags = []
        var rawTags = string.split(';')
        rawTags.forEach(rawTag => {
            var tag = this.tagFromString(rawTag)
            if (tag) tags.push(tag)
        })
        return tags.sort((a, b) => a.printableIdentifier?.localeCompare(b?.printableIdentifier));
    }

    static tagFromString(rawTag: string): Tag {
        try {
            var tag = null
            var isExcluding = rawTag.startsWith('!')
            if (isExcluding) rawTag = rawTag.substring(1)
            if (rawTag == Tag.IDENTIFIER_SWEET) {
                tag = new Tag(Tag.IDENTIFIER_SWEET, marker('Süß'), rawTag)
            } else if (rawTag == Tag.IDENTIFIER_SAVOURY) {
                tag = new Tag(Tag.IDENTIFIER_SAVOURY, marker('Herzhaft'), rawTag)
            } else if (rawTag == Tag.IDENTIFIER_LOW_CALORIE) {
                tag = new Tag(Tag.IDENTIFIER_LOW_CALORIE, marker('Kalorienarm'), rawTag)
            } else if (rawTag == Tag.IDENTIFIER_LOW_CARB) {
                tag = new Tag(Tag.IDENTIFIER_LOW_CARB, marker('Low-Carb'), rawTag)
            } else if (rawTag == Tag.IDENTIFIER_HIGH_PROTEIN) {
                tag = new Tag(Tag.IDENTIFIER_HIGH_PROTEIN, marker('High-Protein'), rawTag)
            } else if (rawTag == Tag.IDENTIFIER_ONE_POT) {
                tag = new Tag(Tag.IDENTIFIER_ONE_POT, marker('One Pot'), rawTag)
            } else if (rawTag == Tag.IDENTIFIER_VEGETARIAN) {
                tag = new Tag(Tag.IDENTIFIER_VEGETARIAN, marker('Vegetarisch'), rawTag)
            } else if (rawTag == Tag.IDENTIFIER_VEGAN) {
                tag = new Tag(Tag.IDENTIFIER_VEGAN, marker('Vegan'), rawTag)
            } else if (rawTag == Tag.IDENTIFIER_BREAKFAST) {
                tag = new Tag(Tag.IDENTIFIER_BREAKFAST, marker('Frühstück'), rawTag)
            } else if (rawTag.startsWith(Tag.IDENTIFIER_CUSTOM)) {
                tag = new Tag(null, null, null)
                tag.identifier = Tag.IDENTIFIER_CUSTOM
                tag.printableIdentifier = rawTag.replace('CUSTOM\'', '').replace('\'', '')
                tag.rawTag = rawTag
            }
            if (isExcluding && tag) tag.setExcluding()
            return tag
        } catch (error) {}
        return null
    }

    static getAllTags(): Tag[] {
        var tags = []
        tags.push(new Tag(Tag.IDENTIFIER_SAVOURY, marker('Herzhaft'), Tag.IDENTIFIER_SAVOURY))
        tags.push(new Tag(Tag.IDENTIFIER_HIGH_PROTEIN, marker('High-Protein'), Tag.IDENTIFIER_HIGH_PROTEIN))
        tags.push(new Tag(Tag.IDENTIFIER_LOW_CALORIE, marker('Kalorienarm'), Tag.IDENTIFIER_LOW_CALORIE))
        tags.push(new Tag(Tag.IDENTIFIER_LOW_CARB, marker('Low-Carb'), Tag.IDENTIFIER_LOW_CARB))
        tags.push(new Tag(Tag.IDENTIFIER_ONE_POT, marker('One Pot'), Tag.IDENTIFIER_ONE_POT))
        tags.push(new Tag(Tag.IDENTIFIER_SWEET, marker('Süß'), Tag.IDENTIFIER_SWEET))
        tags.push(new Tag(Tag.IDENTIFIER_VEGAN, marker('Vegan'), Tag.IDENTIFIER_VEGAN))
        tags.push(new Tag(Tag.IDENTIFIER_VEGETARIAN, marker('Vegetarisch'), Tag.IDENTIFIER_VEGETARIAN))
        return tags
    }
}