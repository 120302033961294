<div class="toggle-wrapper display-inlineblock marginright-25">
    <div class="toggle {{showDataTable ? 'on' : ''}}" (click)="onShowDataTableChanged(!showDataTable)">
        <div class="handle"></div>
    </div>
    <div class="text">
        Daten tabellarisch anzeigen
    </div>
</div>
<div class="toggle-wrapper display-inlineblock marginright-25">
    <div class="toggle {{showVideoRecordings ? 'on' : ''}}" (click)="onShowVideoRecordingsChanged(!showVideoRecordings)">
        <div class="handle"></div>
    </div>
    <div class="text">
        Videoaufnahmen anzeigen
    </div>
</div>

<dx-data-grid *ngIf="showDataTable"
    id="progressStatisticsTable"
    [dataSource]="tableData"
    [allowColumnReordering]="true"
    [allowColumnResizing]="false"
    [showBorders]="true"
    [showRowLines]="true"
    [columnAutoWidth]="true"
    [columnMinWidth]="100"
    [selectedRowKeys]="[]" (onExporting)="chartExportService.onExporting($event)">

    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
    <dxo-selection mode="multiple" [allowSelectAll]="false"></dxo-selection>

    <dxi-sort-by-group-summary-info summaryItem="groupOrder"></dxi-sort-by-group-summary-info>
    <dxi-column dataField="setRendered" caption="Übung/Satz" [width]="60" [fixed]="true"></dxi-column>
    <dxi-column dataField="headingRendered" caption="Einheit" [groupIndex]="0"></dxi-column>
    <dxi-column
        name="groupOrder"
        [visible]="false"
        [showInColumnChooser]="false"
        [calculateCellValue]="calculateCellValue">
    </dxi-column>

    <ng-container *ngFor="let graphItem of graphParams">
        <dxi-column *ngIf="graphItem.subGraphParams" caption="{{graphItem.name}}">
            <ng-container *ngFor="let subGraphItem of graphItem.subGraphParams">
                <dxi-column dataField="{{subGraphItem.valueField + 'Rendered'}}" caption="{{subGraphItem.name}}" [showEditorAlways]="false" [width]="40"></dxi-column>
            </ng-container>
        </dxi-column>
        <dxi-column *ngIf="!graphItem.subGraphParams" dataField="{{graphItem.valueField + 'Rendered'}}" caption="{{graphItem.name}}" [showEditorAlways]="false" [width]="40"></dxi-column>
    </ng-container>

    <dxo-summary>
        <dxi-group-item
            column="groupOrder"
            summaryType="min"
            [alignByColumn]="true">
        </dxi-group-item>
    </dxo-summary>

    <!--dxo-summary [calculateCustomSummary]="calculateCustomSummaryRow">
        <ng-container *ngFor="let graphItem of tableStatisticsGraphItems">
            <dxi-group-item *ngIf="graphItem.summaryType != null" showInColumn="{{graphItem.valueField + 'Rendered'}}" summaryType="custom" name="{{graphItem.valueField + 'Rendered'}}" [customizeText]="customizeGroupSummaryText" [alignByColumn]="true"></dxi-group-item>
        </ng-container>
    </dxo-summary-->
    <dxo-export [enabled]="true"></dxo-export>

</dx-data-grid>

<div *ngIf="showVideoRecordings" class="videorecordings-wrapper">
    <div *ngIf="!videoRecordingSessions">
        <div class="spacer-10"></div>
        Wähle im Graph eine einzelne Übung aus, um die Videoaufnahmen einzusehen.
    </div>
    <div *ngIf="videoRecordingSessions?.length == 0">
        <div class="spacer-10"></div>
        Im ausgewählten Zeitraum ist für die ausgewählte Übung keine Aufnahme vorhanden.
    </div>
    <div *ngIf="videoRecordingSessions?.length > 0">
        <div class="spacer-25"></div>
        <div class="row" *ngFor="let exerciseId of getExercisesForRecordingSessions(videoRecordingSessions)">
            <div class="col-12">
                {{getMergedExerciseById(exerciseId).name.GetValue(languageService.selectedLanguageCode)}}
                <div class="label">
                    {{getMergedExerciseById(exerciseId).subName.GetValue(languageService.selectedLanguageCode)}}
                </div>
            </div>
            <div class="col-12">
                <div class="horizontal-image-scroll-wrapper">
                    <div *ngFor="let session of getSessionsForExercise(exerciseId, videoRecordingSessions)" class="display-inlineblock layout-box">
                        <div class="row">
                            {{(getPlannedSession(session)?.name || 'Einheit') + ' am ' + session.startDate.asFormatedString()}}
                        </div>
                        <div *ngFor="let recording of getRecordingsForExercise(exerciseId, session)" class="display-inlineblock mr-3">
                            <div class="label">{{recording.setNumber + 1}}. Satz: </div>
    
                            <div *ngIf="recording.urlsLoaded && recording.thumbnailUrl && recording.videoUrl" class="layout-box video-box link" (click)="onShowTrackedVideoRecording(recording, session, exerciseId)" [ngStyle]="{'background-image':'url('+ recording.thumbnailUrl +')'}"></div>
                            <div *ngIf="recording.urlsLoaded && !recording.thumbnailUrl && recording.videoUrl" class="layout-box video-box link" (click)="onShowTrackedVideoRecording(recording, session, exerciseId)">
                                <video src="{{recording.videoUrl}}"></video>
                            </div>
    
                            <div *ngIf="recording.urlsLoaded && !recording.videoUrl" class="layout-box video-box padding-10">
                                <div class="label margin-10">Das Video wurde noch nicht vollständig hochgeladen.</div>
                            </div>
                            <div *ngIf="!recording.urlsLoaded" class="layout-box video-box padding-10">
                                <div class="label margin-10">Video wird geladen.</div>
                                {{(firestoreService.loadTrackedVideoRecordingUrls(recording, userUid) | async)?.videoUrl}}
                            </div>
    
                        </div>
                    </div>
                </div>
            </div>
            <div class="spacer-25"></div>
        </div>
        <!-- <ng-container *ngFor="let session of videoRecordingSessions">
            <div class="videorecording-series">
                {{(getPlannedSession(session)?.name || 'Einheit') + ' am ' + session.startDate.asFormatedString()}}
                <div class="horizontal-image-scroll-wrapper">
                    <div *ngFor="let recording of getRecordingsForSession(session)" class="display-inlineblock">
                        <div class="label">{{recording.setNumber + 1}}. Satz: </div>

                        <div *ngIf="recording.urlsLoaded && recording.thumbnailUrl && recording.videoUrl" class="layout-box video-box link" (click)="onShowTrackedVideoRecording(recording, session)" [ngStyle]="{'background-image':'url('+ recording.thumbnailUrl +')'}"></div>
                        <div *ngIf="recording.urlsLoaded && !recording.thumbnailUrl && recording.videoUrl" class="layout-box video-box link" (click)="onShowTrackedVideoRecording(recording, session)">
                            <video src="{{recording.videoUrl}}"></video>
                        </div>

                        <div *ngIf="recording.urlsLoaded && !recording.videoUrl" class="layout-box video-box padding-10">
                            <div class="label margin-10">Das Video wurde noch nicht vollständig hochgeladen.</div>
                        </div>
                        <div *ngIf="!recording.urlsLoaded" class="layout-box video-box padding-10">
                            <div class="label margin-10">Video wird geladen.</div>
                            {{(firestoreService.loadTrackedVideoRecordingUrls(recording, userUid) | async)?.videoUrl}}
                        </div>

                    </div>
                </div>
                <div class="spacer-25"></div>
            </div>
        </ng-container> -->
    </div>
</div>