import { ClientComponent } from '../client/client-v1/client-v1.component';
import { NutritionalGoal } from './nutritionalgoal.model';
import { CalendarDay, DayItem, NutritionPlanComponent } from '../nutrition-plan/nutrition-plan.component';
import { PlannedMealV2 } from './plannedmealv2.model';
import { NutritionalGoalV2 } from './nutritionalgoalv2.model';
import { CycleConfig, Situation } from './nutritionconfig.model';
import { NutritionPlanV2 } from './nutritionplanv2.model';
import { User } from './user.model';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { StartEndDateStringBase } from './base/date-string-base';

export class NutritionPlanConfig extends StartEndDateStringBase {

    id: string
    nutritionPlans: NutritionPlanV2[]
    isActive: boolean

    coachUid: string
    licenceHolderUid: string

    isTemplate: boolean
    startDayNumber: number
    endDayNumber: number

    expiringNutritionPlanHintDismissed: boolean

    // Tmp:
    selectedNutritionPlan: NutritionPlanV2
    startDateNumber: number
    colorScheme: string
    //initialCycleConfig: CycleConfig

    constructor();
    constructor(init: NutritionPlanConfig);
    constructor(init?: NutritionPlanConfig) {
        super(init);
        this.id = init && init.id || null
        this.nutritionPlans = init && init.nutritionPlans || []
        this.selectedNutritionPlan = init && init.selectedNutritionPlan || null
        this.isTemplate = init && init.isTemplate || false
        this.isActive = init && init.isActive || false
        this.startDayNumber = init && init.startDayNumber || null
        this.endDayNumber = init && init.endDayNumber || null
        this.expiringNutritionPlanHintDismissed = init && init.expiringNutritionPlanHintDismissed || false
        this.coachUid = init && init.coachUid || null
        this.licenceHolderUid = init && init.licenceHolderUid || null
    }

    getNutritionPlans() {
        return this.nutritionPlans.filter(plan => !plan.deleted)
    }

    setSelectedNutritionPlanById(id: String) {
        // this.nutritionPlans.forEach(plan => {
        //     if (id == plan.id) this.selectedNutritionPlan = plan
        // })
        this.selectedNutritionPlan = this.nutritionPlans?.find(plan => plan.id == id)
    }

    getName(translate: TranslateService) {
        var plans = this.nutritionPlans.filter(plan => !plan.deleted)
        if (plans.length == 0) return translate.instant(marker("Plan"))
        var name = plans[0].getName(translate)
        for (var i = 1; i < plans.length; i++) {
            name = name + ' + ' + (plans[i].getName(translate))
        }
        return name
    }

    getPrintableDateRange(translate: TranslateService) {
        return this.startDate.asFormatedString() + ' ' + (this.endDate ? ' ' + translate.instant(marker('bis')) + ' ' + this.endDate.asFormatedString() : ' ' + translate.instant(marker('bis unbegrenzt')))
    }
    getPrintableDuration(translate: TranslateService) {
        if (this.endDate) {
            return (NutritionPlanComponent.dateDiffInDays(this.startDate, this.endDate) + 1) + ' ' + translate.instant(marker('Tage'))
        }
        return translate.instant(marker('unbegrenzt'))
    }

    isCurrentlyActive() {
        return this.isActiveAtDate(new Date())
    }
    isActiveAtDate(date: Date) {
        return (this.startDate < date || this.startDate.isSameDate(date)) && (!this.endDate || this.endDate.isSameDate(date) || this.endDate > date)
    }

    isOlderThanDate(date: Date) {
        return this.endDate && (this.endDate < date && !this.endDate.isSameDate(date))
    }
    isOlderThanToday() {
        return this.isOlderThanDate(new Date())
    }
    isInFuture(): boolean {
      let today = new Date()
      return this.startDate > today || this.startDate.isSameDate(today)
    }

    collidesWith(planConfigs: NutritionPlanConfig[]) {
        var colliding = false
        for (var config of planConfigs) {
          if (config.id == this.id) continue
          if (this.intersectsWith(config)) {
            colliding = true
          }
        }
        return colliding
    }
    intersectsWith(c: NutritionPlanConfig): boolean {
        var first = this.startDate.isSameOrBeforeDate(c.startDate) ? this : c
        var second = this.startDate.isSameOrBeforeDate(c.startDate) ? c : this
        return second.startsInside(first) || first.endsInside(second) || second.endsInside(first) || second.isInside(first)
    }
    startsInside(c: NutritionPlanConfig): boolean {
        if (this.startDate.isSameOrAfterDate(c.startDate) && ((c.endDate && this.startDate.isSameOrBeforeDate(c.endDate)) || !c.endDate)) return true
        return false
    }
    endsInside(c: NutritionPlanConfig): boolean {
        if (this.endDate && ((!c.endDate && this.startsInside(c)) || (c.endDate && (this.endDate.isSameOrBeforeDate(c.endDate)) && this.endDate.isSameOrAfterDate(c.startDate)))) return true
        return false
    }
    isInside(c: NutritionPlanConfig) {
        return this.startDate.isSameOrAfterDate(c.startDate) && this.endDate && ((c.endDate && this.endDate.isSameOrBeforeDate(c.endDate)) || (!c.endDate))
    }
    
    clone(): NutritionPlanConfig {
        var config = new NutritionPlanConfig(this)
        config.nutritionPlans = []
        this.nutritionPlans.forEach(e => {
            config.nutritionPlans.push(e.clone())
        })
        var selectedIndex = this.nutritionPlans.indexOf(this.selectedNutritionPlan)
        if (selectedIndex >= 0) config.selectedNutritionPlan = config.nutritionPlans[selectedIndex]
        return config
    }

    canAccess(user: User) {
        if (!this.licenceHolderUid) return true
        return this.licenceHolderUid == user?.licenceHolderUid
    }

    static defaultConfig(startDate: Date, endDate: Date, configOrder: number) {
        var config = new NutritionPlanConfig()
        config.startDate = startDate
        config.endDate = endDate
        var plan = NutritionPlanV2.defaultNutritionPlan(0)
        config.nutritionPlans.push(plan)
        config.selectedNutritionPlan = plan
        return config
    }

}