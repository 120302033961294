import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { EventTriggerType } from 'src/app/model/questionaires.model';
import { TrackedTrainingSession } from 'src/app/model/training-monitoring.model';
import { User } from 'src/app/model/user.model';
import { TrainingTrackingService } from 'src/app/services/training-tracking.service';
import { TrainingService } from 'src/app/services/training.service';
import { UtilityService } from 'src/app/services/utility.service';
import { TrainingSessionContainer } from 'src/app/training-monitoring/training-history-dialog/training-history-dialog.component';
import { TrackingSessionComponent } from '../tracking-session.component';

@Component({
  selector: 'app-tracking-session-dialog',
  templateUrl: './tracking-session-dialog.component.html',
  styleUrls: ['./tracking-session-dialog.component.css']
})
export class TrackingSessionDialogComponent {

  public trainingSessionContainer: TrainingSessionContainer;
  public user: User;
  public hasChanges: boolean = false;
  public autoCreateActivity: boolean = true;

  public spinnerText: string = "";

  constructor(public dialogRef: MatDialogRef<TrackingSessionDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { trainingSessionContainer: TrainingSessionContainer, user: User, hasInitialChanges: boolean }, private dialog: MatDialog, private trainingTrackingService: TrainingTrackingService, private toastr: ToastrService, private translate: TranslateService, private spinner: NgxSpinnerService, public utilityService: UtilityService) {
    this.trainingSessionContainer = data.trainingSessionContainer;
    this.user = data.user;
    this.trainingSessionContainer.trackedTrainingSession.tempUserUid = this.user?.uid;
    this.hasChanges = data.hasInitialChanges;
    this.dialogRef.disableClose = true;

    if (!this.trainingSessionContainer.trackedTrainingSession?.startDate) {
      this.trainingSessionContainer.trackedTrainingSession.startDate = new Date();
    }
    if (this.trainingSessionContainer.trackedTrainingSession?.id) {
      this.trainingSessionContainer.trackedTrainingSession.continued = true;
    }
  }

  async onSaveTrackedSession() {

    if (!this.trainingSessionContainer.trackedTrainingSession.startDate) {
      this.toastr.error(this.translate.instant("Das Startdatum darf nicht leer sein."), this.translate.instant("Speichern nicht möglich"), {
        positionClass: 'toast-bottom-center'
      });
      return;
    }
    if (this.trainingSessionContainer.trackedTrainingSession.endDate && this.trainingSessionContainer.trackedTrainingSession.endDate <= this.trainingSessionContainer.trackedTrainingSession.startDate) {
      this.toastr.error(this.translate.instant("Das Enddatum muss nach dem Startdatum liegen."), this.translate.instant("Speichern nicht möglich"), {
        positionClass: 'toast-bottom-center'
      });
      return;
    }
    if (this.trainingSessionContainer.trackedTrainingSession.trackedTrainingExercises.find(x => x.trackedSets.find(s => !s.isTracked))) {
      let message = this.translate.instant("Es wurden nicht alle Sätze getrackt. Möchtest du trotzdem speichern?");
      let hintResult = await firstValueFrom(this.dialog.open(ConfirmationDialogComponent, { data: { message: message, title: this.translate.instant("Hinweis") } }).afterClosed());

      if (!hintResult) {
        return;
      }

    }

    this.spinner.show("trackingUpdateSpinner");
    let isNewTrackedSession = !this.trainingSessionContainer.trackedTrainingSession.id;
    let result = await this.trainingTrackingService.createOrUpdateTrackedTrainingSession(this.trainingSessionContainer.trackedTrainingSession, this.user, this.updateSpinnerText.bind(this), this.autoCreateActivity);
    if (isNewTrackedSession) {
      var assignedQuestionaires = await this.trainingTrackingService.createQuestionaires(this.trainingSessionContainer.trackedTrainingSession, this.user);
    }
    await this.trainingTrackingService.removeCachedTrainingData();
    if (result) {
      this.dialogRef.close({ shouldTake: true, trainingSessionContainer: this.trainingSessionContainer, assignedQuestionaires: assignedQuestionaires });
    }
    else {
      console.error("Error saving tracked training session");
      this.toastr.error(this.translate.instant("Speichern fehlgeschlagen"), this.translate.instant("Fehler"));
    }
    this.spinner.hide();

  }

  updateSpinnerText(text: string) {
    this.spinnerText = text;
  }

  async onCancelTracking() {
    if (this.hasChanges) {
      let message = this.translate.instant("Möchtest du die Einheit vor dem Schließen speichern?");
      let hintResult = await firstValueFrom(this.dialog.open(ConfirmationDialogComponent, { data: { message: message, title: this.translate.instant("Einheit speichern"), positiveButton: this.translate.instant('Ja'), negativeButton: this.translate.instant('Nein') } }).afterClosed());
      if (hintResult == true) {
        await this.onSaveTrackedSession();
      }
      else if(hintResult == false) {
        try {
          this.spinner.show();
          await this.trainingTrackingService.removeCachedTrainingData();
        }
        catch (error) {
          console.error(error);
        }
        finally {
          this.spinner.hide();
        }
        this.dialogRef.close({ shouldTake: false });
      }
    }
    else {
      this.dialogRef.close({ shouldTake: false });
    }
  }
}
