<div class="row {{selectionMode ? 'selection-mode' : 'full-mode'}}">
    <div class="col-12">
        <div class="{{selectionMode ? '' : 'layout-box adjust-mobile'}}">
            <h3 class="display-inlineblock">Planvorlagen</h3>
            <ng-container *ngIf="!selectionMode">
                <button class="button-filled small create-Exercise marginleft-25" (click)="onCreateTemplate()">{{utilityService.onSmallDisplay() ? 'Erstellen' : 'Vorlage erstellen'}}</button>
                <div *ngIf="trainingService.CanEnterTrainingTemplateAdministratorEditMode" class="toggle-wrapper display-inlineblock marginleft-25 mt-1">
                    <div class="toggle {{trainingService.TrainingTemplateAdministratorEditModeActivated ? 'on' : ''}}" (click)="onAdministratorModeChanged(!trainingService.TrainingTemplateAdministratorEditModeActivated)">
                        <div class="handle"></div>
                    </div>
                    <div class="text">
                        Administrator Modus
                    </div>
                </div>
                <div class="display-inlineblock marginleft-10 float-right" *ngIf="environment.firebaseProjectId != 'traindoo-app'">
                    <div class="icon-button" mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-gear padding-4" viewBox="0 0 16 16">
                            <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0"/>
                            <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z"/>
                        </svg>
                        <span class="only-desktop">Einstellungen</span>
                    </div>
                    <mat-menu #menu="matMenu" class="padding-15">
                        Trainingseinstellungen:
                        <div class="spacer-10"></div>
                        <div class="recipesharing-toggle toggle-wrapper margin-4 mobile-marginbottom-10" matTooltip="Durch diese Einstellung wird noch nichts automatisch geteilt. Du musst jede Vorlage individuell teilen und Nutzern zuweisen.">
                            <div class="toggle {{templateSharingEnabled ? 'on' : ''}}" (click)="onTemplateSharingEnabledChanged(!templateSharingEnabled)">
                                <div class="handle"></div>
                            </div>
                            <div class="text">
                                Vorlagen teilen
                            </div>
                        </div>
                        <div class="spacer-10"></div>
                        <div class="recipesharing-toggle toggle-wrapper margin-4 mobile-marginbottom-10" matTooltip="Aktiviere, dass Coachees sich selbstständig Trainingspläne in der App erstellen können. Du kannst diese Einstellung bei jedem Coachee individuell überschreiben.">
                            <div class="toggle {{isCoacheeTrainingPlanCreationEnabled ? 'on' : ''}}" (click)="onCoacheeTrainingPlanCreationEnabledChanged(!isCoacheeTrainingPlanCreationEnabled)">
                                <div class="handle"></div>
                            </div>
                            <div class="text">
                                Planerstellung durch Coachee zulassen
                            </div>
                        </div>

                    </mat-menu>
                </div>

                
                <div class="spacer-10">
                    <div class="icon-button small" (click)="openTrainingVariablesDialog()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-bullseye" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                            <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10m0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12"/>
                            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8"/>
                            <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                        </svg>
                        Trainingsvariablen bearbeiten
                    </div>
                </div>
                <div class="spacer-25"></div>
            </ng-container>
            <div *ngIf="environment.firebaseProjectId != 'traindoo-app'" class="tab-selection textalign-center tab-button-group adjust-mobile padding-0">
                <button class="button tab-button" (click)="onSelectTemplateTab('OWN')">
                    Eigene Vorlagen
                    <div *ngIf="selectedTemplateTab == 'OWN'" class="underline"></div>
                </button>
                <button class="button tab-button" (click)="onSelectTemplateTab('NUTRILIZE')">
                    {{environment.isWhitelabel ? 'Basis-Vorlagen' : 'nutrilize Vorlagen'}}
                    <div *ngIf="selectedTemplateTab == 'NUTRILIZE'" class="underline"></div>
                </button>
            </div>
            <div class="spacer-25"></div>
            <div class="template-folder-tags">
                <div class="folder-scroll-container">
                    <div style="width: 105px; min-width: 105px;" class="template-folder-box link {{!selectedTrainingPlanTemplateFolder ? ' active' : ''}}" (click)="onShowAllTrainingPlanTemplates()">
                        <div class="all-templates-tab">
                            <strong class="folder-name">
                                Alle Vorlagen
                            </strong>
                        </div>
                    </div>
                    <div *ngIf="!selectionMode" class="create-template-folder-box link" (click)="onCreateTrainingPlanTemplateFolder()">
                        <div class="create-template-folder-button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-folder-plus" viewBox="0 0 16 16">
                                <path d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z"/>
                                <path d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"/>
                            </svg>
                        </div>
                    </div>
                    <ng-container *ngFor="let folder of availableTrainingPlanTemplateFolders">
                        <div *ngIf="(selectedTemplateTab === 'OWN' && folder.licenceHolderUid !== 'nutrilize') || (selectedTemplateTab === 'NUTRILIZE' && folder.licenceHolderUid === 'nutrilize')" class="template-folder-box link {{selectedTrainingPlanTemplateFolder == folder ? ' active' : ''}}" (click)="onSelectTrainingPlanTemplateFolder(folder)" (dragover)="onDragOverFolderDropzone($event)" (dragend)="onDragEndFolderTemplate($event, null)" (drop)="onDropOnFolderDropzone($event, folder)">
                            <div class="folder-info-container">
                                <strong class="folder-name">
                                    {{folder.name?.GetValue(languageService.selectedLanguageCode)}}
                                </strong>
                                <div>
                                    {{getNumberOfTemplatesInFolder(folder)}} Vorlagen
                                </div>
                                <div class="label shared-with-column" *ngIf="templateSharingEnabled && folder.licenceHolderUid == trainingService.TemplateUserUid && getSharedWith(folder)">
                                    <div>
                                        Geteilt mit:
                                    </div>
                                    <div class="shared-with-list">
                                        {{getSharedWith(folder)}}
                                    </div>
                                </div>
                                <div *ngIf="!selectionMode" class="small-round-button copy-folder-button link" (click)="onCopySelectedTemplateFolder(folder);$event.stopPropagation()" tooltip="Ordner duplizieren">
                                    <svg width="16" height="16" viewBox="0 0 16 16" class="bi bi-files" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"/>
                                        <path d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z"/>
                                    </svg>
                                </div>
                                <div *ngIf="!selectionMode && folder.licenceHolderUid === trainingService.TemplateUserUid" class="small-round-button edit-folder-button link" (click)="onEditSelectedTemplateFolder(folder);$event.stopPropagation()" tooltip="Ordner bearbeiten">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
                                        <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                                    </svg>
                                </div>
                            </div>
                            <img *ngIf="folder.imageDownloadURL" draggable="false" class="folder-thumbnail-image" [src]="folder.imageDownloadURL">
                        </div>
                    </ng-container>
                </div>
                <!-- <div class="chip link" (click)="onCreateTrainingPlanTemplateFolder()">
                    <div class="display-inlineblock">
                        <svg width="1.4em" height="1.4em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                    </div>
                    Vorlagen-Ordner erstellen
                </div> -->
            </div>
            <div class="spacer-25"></div>
            <div class="row">
                <div class="col-lg-6 col-sm-12" *ngIf="!selectedTrainingPlanTemplateFolder">
                    <!-- <label for="dropdownRepetion">Filter</label> -->
                    <div class="d-inline-block wrapper-repetition-selection">
                        <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{selectedTemplateFilter}}</button>
                        <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
                            <div *ngFor="let filter of availableTemplateFilters">
                                <button class="dropdown-item" type="button" (click)="onTemplateFilterChanged(filter)">{{filter}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="spacer-10"></div>
                    <input id="templatesearch-input" class="input wide" (input)="onTemplateSearchInputChanged($event.target.value)" placeholder="Vorlagen durchsuchen" type="text">
                    <div class="icon-button deletesearch-icon delete-recipesearch" (click)="onDeleteTemplateSearchInput()">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                    <div *ngIf="!selectionMode && selectedTrainingPlanTemplateFolder != null && selectedTrainingPlanTemplateFolder.licenceHolderUid == trainingService.TemplateUserUid" class="icon-button display-inlineblock marginleft-25" (click)="onAddTrainingPlanTemplates()">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                        Vorlagen zum Ordner hinzufügen
                    </div>
                    <div class="spacer-25"></div>
                </div>
            </div>
            <div *ngIf="trainingService.trainingPlanTemplates?.length > 0" class="row">
                <div *ngFor="let template of filteredTrainingPlanTemplates" class="col-12">
                    <div *ngIf="(selectedTemplateTab === 'OWN' && template.licenceHolderUid !== 'nutrilize') || (selectedTemplateTab === 'NUTRILIZE' && template.licenceHolderUid === 'nutrilize')" class="layout-box sub-box template-item template-box link " (click)="onOpenTemplate(template)" [draggable]="true" (dragstart)="onDragStartTemplate($event, template)" (dragend)="onDragEndTemplate($event, template)">
                        <div *ngIf="template.imageDownloadURL" draggable="false" class="template-thumbnail-image" [ngStyle]="getBackgroundImage(template)"></div>
                        <div *ngIf="!selectionMode" class="template-buttons" [ngStyle]="template.imageDownloadURL ? { 'color': 'white' } : {}">
                            <div class="icon-button float-right duplicate-template-button" (click)="copyTrainingTemplate(template);$event.stopPropagation()">
                                <svg _ngcontent-vjg-c53="" class="padding-4 bi bi-files" fill="currentColor" height="1em" viewBox="0 0 16 16" width="1em" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-vjg-c53="" d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z" fill-rule="evenodd"></path><path _ngcontent-vjg-c53="" d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z"></path></svg>
                                <ng-container *ngIf="!utilityService.onSmallDisplay()">Duplizieren</ng-container>
                            </div>
                            <div class="icon-button float-right" (click)="deleteTrainingTemplate(template);$event.stopPropagation()" *ngIf="template.licenceHolderUid === trainingService.TemplateUserUid">
                                <svg _ngcontent-btw-c42="" class="padding-4 bi bi-trash" fill="currentColor" height="1em" viewBox="0 0 16 16" width="1em" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-btw-c42="" d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path><path _ngcontent-btw-c42="" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" fill-rule="evenodd"></path></svg>
                                <ng-container *ngIf="!utilityService.onSmallDisplay()">Löschen</ng-container>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <div class="row">
                                    <div class="col">
                                        <div class="{{template.imageDownloadURL ? 'background-image-text' : ''}}">{{template.nameTranslation.GetValue(languageService.selectedLanguageCode)}}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-auto">
                                        <div class="label {{template.imageDownloadURL ? 'background-image-text' : ''}}">{{template.subNameTranslation.GetValue(languageService.selectedLanguageCode)}}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-auto">
                                        <div *ngIf="getPrintableTemplateFolderNames(template)" class="label template-folder-names {{template.imageDownloadURL ? 'background-image-text' : ''}}">
                                            <div class="spacer-10"></div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-folder" viewBox="0 0 16 16">
                                                <path d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z"/>
                                            </svg>
                                            {{getPrintableTemplateFolderNames(template)}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner name="templates-spinner" bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"><div *ngIf="spinnerText" class="spinner-overlaytext" [innerHTML]="spinnerText"></div></ngx-spinner>