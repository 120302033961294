import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Label2RepetitionMapping, Repetition, Repetition2LabelMapping } from './../../model/automatic-push-notification.model';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DropdownItem } from 'src/app/model/automatic-push-notification.model';
import { Coach } from 'src/app/model/coach.model';
import { EventTriggerType, EventTriggerType2LabelMapping, Label2EventTriggerTypeMapping, Label2TriggerTypeMapping, Question, Questionaire, TriggerType, TriggerType2LabelMapping } from 'src/app/model/questionaires.model';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UtilityService } from 'src/app/services/utility.service';
import { MetricsSelectionDialogComponent } from 'src/app/metrics-selection-dialog/metrics-selection-dialog.component';
import { Metric } from 'src/app/model/metric.model';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { QuestionaireInfoslideDialogComponent } from '../questionaire-infoslide-dialog/questionaire-infoslide-dialog.component';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-questionaire-editor',
  templateUrl: './questionaire-editor.component.html',
  styleUrls: ['./questionaire-editor.component.css']
})
export class QuestionaireEditorComponent implements OnInit {

  public environment = environment
  
  public questionaire: Questionaire;
  private user: User;
  public selectableGroupNames: DropdownItem[] = [];
  public selectableClients: DropdownItem[] = [];

  public TriggerType2LabelMapping = TriggerType2LabelMapping;
  public Label2TriggerTypeMapping = Label2TriggerTypeMapping;
  public triggerLabels = Object.values(TriggerType2LabelMapping).filter(value => typeof value === 'string');
  
  public EventTriggerType2LabelMapping = EventTriggerType2LabelMapping;
  public Label2EventTriggerTypeMapping = Label2EventTriggerTypeMapping;
  public eventTriggerLabels = Object.values(EventTriggerType2LabelMapping).filter(value => typeof value === 'string');

  public Label2RepetitionMapping = Label2RepetitionMapping;
  public Repetition2LabelMapping = Repetition2LabelMapping;
  public repetitionLabels = Object.values(Repetition2LabelMapping).filter(value => typeof value === 'string');
  public TriggerType = TriggerType;

  public hoveredQuestion: Question;
  
  private hasChanges: boolean = false
  private nextExecutionDateChanged: boolean = false

  public readOnlyMode: boolean = false

  constructor(public dialogRef: MatDialogRef<QuestionaireEditorComponent>, @Inject(MAT_DIALOG_DATA) private data: {questionaire: Questionaire, readOnlyMode: boolean, user: User }, public utilityService: UtilityService, public userService: FirestoreService, private dialog: MatDialog, private toastr: ToastrService, public translate: TranslateService) { 
    this.questionaire = data.questionaire;
    this.user = data.user;
    this.questionaire.questions.forEach(questionaireItem => {
      if (questionaireItem.imagePath && !questionaireItem.imageURL){
        userService.getDownloadUrl(questionaireItem.imagePath).then((link) => {
          questionaireItem.imageURL = link
        })
      }
      if (questionaireItem.videoPath && !questionaireItem.videoURL){
        userService.getDownloadUrl(questionaireItem.videoPath).then((link) => {
          questionaireItem.videoURL = link
        })
      }
    })
    if (data.readOnlyMode != null) this.readOnlyMode = data.readOnlyMode
    this.setDropdownItems();
  }

  ngOnInit(): void {

  }

  getSelectableClients() {
    return this.userService.getAccessibleClients()
  }

  getSelectableClientGroups() {
    return this.userService.getClientGroups()
  }

  onTypeChanged(type: string) {
    this.questionaire.type = type;
    this.hasChanges = true
    if (type == 'training_feedback') {
      this.questionaire.trigger = TriggerType.event.toString()
      this.questionaire.eventTrigger = EventTriggerType.after_training.toString()
    } else {
      this.questionaire.trigger = TriggerType.manually.toString()
      this.questionaire.eventTrigger = null
    }
  }

  setDropdownItems(){
    this.selectableClients = []
    this.selectableGroupNames = [new DropdownItem('', 'Alle', this.questionaire.assignedGroupNames?.includes('Alle'))]
    this.userService.getAccessibleClients().forEach(client => {
      this.selectableClients.push(new DropdownItem(client.uid, client.getName(), this.questionaire.assignedUids?.includes(client.uid)))
    });
    this.userService.getClientGroups().forEach(group => {
      this.selectableGroupNames.push(new DropdownItem('', group, this.questionaire.assignedGroupNames?.includes(group)))
    });
  }

  async onQuestionClick(question:Question){
    if(question.isInfoSlide){
      let result = await this.editInfoSlide(question);
      if(result){
        this.hasChanges = true;
      }
    }
  }

  doBeforeUnload(){
    // Show confirmation dialog before closing tab/browser.
    if (this.hasChanges) return false;
    return true;
  }

  saveQuestionaire(){
    if(!this.isNextExecutionDateValid() || this.questionaire.name?.length == 0 || !this.isRepetitionMultiplierValid()){
      this.toastr.error(this.translate.instant("Überprüfe deine Eingaben!"), "" ,  {
        positionClass: 'toast-bottom-center'
      });
      return;
    }
    if (this.questionaire.questions.length == 0) {
      this.toastr.error(this.translate.instant("Füge mindestens eine Frage hinzu."), "" ,  {
        positionClass: 'toast-bottom-center'
      });
      return;
    }
    this.onCloseDialog(true, false);
  }

  cancelEditQuestionaire(){
    if (!this.hasChanges) {
      this.onCloseDialog(false, false)
      return
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: this.translate.instant('Möchtest du den Check-In vor dem Schließen speichern?'), title: this.translate.instant('Check-In speichern'), positiveButton: this.translate.instant('Ja'), negativeButton: this.translate.instant('Nein') },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.saveQuestionaire()
      } else {
        this.onCloseDialog(false, false)
      }
    })
  }

  deleteQuestionaire()
  {
    if(this.user && this.questionaire?.assignedUids?.includes(this.user?.uid)){
      let dialogMessage = this.translate.instant("Soll der gesamte Check-in oder nur die Zuweisung zum ausgewählten Kunden gelöscht werden?<br>Wenn du den gesamten Check-In löschst, wird er auch bei anderen Kunden entfernt.");
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { message: dialogMessage, title: this.translate.instant('Check-In löschen'), positiveButton: this.translate.instant('Gesamten Check-In löschen'), negativeButton: this.translate.instant('Zuweisung entfernen') },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.onCloseDialog(false, true);
        } else if (result === false) {
          this.questionaire.assignedUids = this.questionaire.assignedUids.filter(x => x != this.user.uid);
          this.onCloseDialog(true, false);
        }
      });
    }
    else {
      let dialogMessage = this.translate.instant("Möchtest du diesen Check-In wirklich löschen? Er wird bei allen zugewiesenen Nutzer:innen entfernt.<br>Um den Erhalt bei einzelnen Coachees zu ändern, passe die Empfänger an.");
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { message: dialogMessage, title: 'Check-In löschen', positiveButton: this.translate.instant('Bestätigen'), negativeButton: this.translate.instant('Abbrechen') },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.onCloseDialog(false, true);
        }
      });
    }
  }


  onCloseDialog(shouldSave: boolean, shouldDelete: boolean){
    this.dialogRef.close({shouldSave: shouldSave, shouldDelete: shouldDelete, nextExecutionDateChanged: this.nextExecutionDateChanged});
  }

  getPrintableRecipients(): string{
      var checkedElements = '';
      if(this.questionaire.assignedGroupNames != null){
        if (this.questionaire.assignedGroupNames.includes('Alle')) return this.translate.instant('Alle')
        this.questionaire.assignedGroupNames.forEach(name => {
          if(this.getSelectableClientGroups()?.includes(name)) {
            if(checkedElements.length > 0){
              checkedElements = checkedElements.concat(', ')
            }
            checkedElements = checkedElements.concat(name);
          }
        });
      }
      if (this.selectableClients != null){
        this.selectableClients.forEach(element => {
          if(this.questionaire.assignedUids.includes(element.uid)){
            if(checkedElements.length > 0){
              checkedElements = checkedElements.concat(', ')
            }
            checkedElements = checkedElements.concat(element.name);
          }
        });
      }
      return checkedElements;
  }


  areAllUsersTargeted() {
    return this.questionaire.assignedGroupNames?.includes('Alle')
  }

  
  onTargetSelectionChangedUser(user: User){
    if (!this.canAccessUser(user)) return
    if (this.questionaire.assignedUids.includes(user.uid)) {
      this.questionaire.assignedUids.forEach( (item, index) => {
        if (item == user.uid) this.questionaire.assignedUids.splice(index, 1)
      })
    } else {
      this.questionaire.assignedUids.push(user.uid)
    }
    this.hasChanges = true
  }

  onTargetSelectionChangedGroup(group: string){
    if (!this.canAccessGroup(group)) return
    if (this.questionaire.assignedGroupNames.includes(group)) {
      this.questionaire.assignedGroupNames.forEach( (item, index) => {
        if (item == group) this.questionaire.assignedGroupNames.splice(index, 1)
      })
    } else {
      this.questionaire.assignedGroupNames.push(group)
    }
    this.hasChanges = true
  }

  onEventTriggerSelectionChanged(eventTrigger: string) {
    this.questionaire.eventTrigger = this.Label2EventTriggerTypeMapping[eventTrigger].toString();
    this.hasChanges = true
  }

  canAccessGroup(group: string) {
    return this.userService.getLoggedInUser().coach.canAccessClientGroup(group)
  }
  canAccessUser(user: User) {
    return this.userService.getLoggedInUser().coach.canAccessUser(user)
  }

  onRepetitionMultiplierChanged(multiplier: string){
    this.questionaire.repetitionMultiplier = parseInt(multiplier, 10);
    this.hasChanges = true
  }

  onRepetitionSelectionChanged(rep: string){
    this.questionaire.repetition = this.Label2RepetitionMapping[rep].toString();
    this.hasChanges = true
  }

  isRepetitionMultiplierValid(){
    return this.questionaire.trigger != TriggerType.recurring.toString() || this.questionaire?.repetition !==  Repetition.everyXDays || (this.questionaire?.repetitionMultiplier >= 1 && this.questionaire?.repetitionMultiplier <= 3000);
  }

  onTriggerChanged(type: string) {
    this.questionaire.trigger = this.Label2TriggerTypeMapping[type].toString();
    if(this.questionaire.trigger == TriggerType.recurring.toString()){
      let newDate = new Date();
      newDate.addDays(1);
      newDate.setHours(12,0,0,0);
      this.questionaire.nextExecutionDate = newDate;
    }
    else {
      this.questionaire.nextExecutionDate = null;
    }
    this.hasChanges = true
    this.nextExecutionDateChanged = true;
  }

  isNextExecutionDateValid() {
    if(this.questionaire.trigger != TriggerType.recurring.toString()) return true;
    var now = new Date()
    now.setMilliseconds(0)
    now.setSeconds(0)
    return this.questionaire?.nextExecutionDate >= now
  }

  onNewExecutionDateChanged(date: string){
    this.questionaire.nextExecutionDate = this.parseDateString(date)
    this.hasChanges = true
    this.nextExecutionDateChanged = true
  }

  onRemoveAssignedQustion(question: Question) {
    if(!question.isInfoSlide) {
      this.questionaire.questions.forEach( (item, index) => {
        if (item.metricId == question.metric.metricId) this.questionaire.questions.splice(index, 1);
      });
    }
    else {
      this.questionaire.questions = this.questionaire.questions.filter(x => x != question);
    }
    this.hasChanges = true
  }

  dropAssignedMetric(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.questionaire.questions, event.previousIndex, event.currentIndex);
    this.hasChanges = true
  }

  addQuestions(){
    const dialogRef = this.dialog.open(MetricsSelectionDialogComponent, { data: { notAvailableMetricIds: this.questionaire.questions.map(x => x.metricId), canShowMetricSets: true}, width: '1000px'})
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if(result.shouldTake) {
          result.metrics.forEach(metric => {
            if(!this.questionaire.questions.find(x => x.metricId == metric.metricId)){
              this.questionaire.questions.push(new Question(metric.metricId, this.questionaire.questions.length, metric, null, null, null, false, null));
            }
          });
        }
      }
      this.synchronizeMetrics();
    });
    this.hasChanges = true
  }

  
  synchronizeMetrics() {
    this.questionaire.questions.forEach(question => {
      if(question.metric) {
        let globalMetric = this.userService.metrics.find(x => x.metricId == question.metricId);
        if(globalMetric) {
          if (globalMetric) {
            let metric = globalMetric?.clone() ?? question.metric.clone();
            this.userService.setUserMetricValues(metric, question.metric, question.metric.assignmentDate, question.metric.id);
            question.metric = metric;
          }
        }
      }
    });
  }

  onReuiredChanged(question: Question){
    question.required = !question.required;
  }

  async addInfoSlide(){
    // const dialogRef = this.dialog.open(QuestionaireInfoslideDialogComponent, { data: { question: question}, width: '1000px'})
    // dialogRef.afterClosed().subscribe(async result => {
    //   if (result) {
    //     if(result.shouldTake) {
    //       let clonedQuestion = new Question(null, this.questionaire.questions.length, null, question.groupHeading, question.content, question.imagePath, question.required);
    //       clonedQuestion.updatedImage = question.updatedImage;
    //       this.questionaire.questions.push(clonedQuestion);
    //     }
    //   }
    // });
    let question = new Question(null, this.questionaire.questions.length, null, null, null, null, false, null);
    if(await this.editInfoSlide(question)){
      this.questionaire.questions.push(question);
      this.hasChanges = true
    }
    // let clonedQuestion = new Question(null, this.questionaire.questions.length, null, question.groupHeading, question.content, question.imagePath, question.required);
    // clonedQuestion.updatedImage = question.updatedImage;
  }

  async editInfoSlide(question: Question): Promise<boolean>{
    const dialogRef = this.dialog.open(QuestionaireInfoslideDialogComponent, { data: { question: question}, width: '1000px'})
    let result = await dialogRef.afterClosed().toPromise();
    return result?.shouldTake ? true : false;
    // if(result?.shouldTake) {
    //   let clonedQuestion = new Question(null, this.questionaire.questions.length, null, question.groupHeading, question.content, question.imagePath, question.required);
    //   clonedQuestion.updatedImage = question.updatedImage;
    //   this.questionaire.questions.push(clonedQuestion);
    // }
  }

  onAddGroupHeading(question: Question) {
    question.groupHeading = "";
  }

  onDeleteGroupHeading(question: Question) {
    question.groupHeading = null;
  }

  mouseEnterQuestion(question: Question){
    this.hoveredQuestion = question;
  }
  

  public toDateString(date: Date): string {
    if(date == null) return null;
    return (date.getFullYear().toString() + '-' 
       + ("0" + (date.getMonth() + 1)).slice(-2) + '-' 
       + ("0" + (date.getDate())).slice(-2))
       + 'T' + date.toTimeString().slice(0,5);
  }

  private parseDateString(date:string): Date {
    if(date == null) return null;
    date = date?.replace('T','-');
    var parts = date?.split('-');
    var timeParts = parts[3]?.split(':');
    return new Date(+parts[0], +parts[1]-1, +parts[2], +timeParts[0], +timeParts[1]);
 }
}
