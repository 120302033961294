import { Licence } from "./lid.model";
import { LogItem } from "./payment.model";
import {Product, ProductDurationUnit, ProductLicenseType} from "./product.model";
import { User } from "./user.model";
import * as moment from "moment";

export enum ProductPurchaseStatus {
  DRAFT = 'draft',
  REQUEST = 'request',
  UNCOMPLETED = 'uncompleted',
  PURCHASED = 'purchased',
  // for subscriptions: (active, expired, canceled)
  ACTIVE = 'active',
  EXPIRED = 'expired',
  CANCELED = 'canceled'
}


export class ProductPurchase {
    id: string;
    customerUid: string;
    licenceHolderUid: string;
    provider: string;
    stripeCustomerId: string;
    stripeAccountId: string;

    nextPaymentDate: Date
    currentPaymentDateShift: number // Number of days the payment date was shifted to the future
    startDate: Date // Start date of the subscription, null if starts directly after purchase
    endDate: Date // End date of the subscription if already predictable (fixed runtime or auto-renew canceled)
    recurring: boolean // One-time purchase or recurring subscription
    duration: number // Duration and payment interval of the subscription
    durationUnit: ProductDurationUnit
    durationMultiplier: number // Total runtime (1 or null if not recurring -> runtime is one interval, null for forever or fixed number if recurring)
    autoRenew: boolean // Subscription is automatically renewed after runtime for one duration interval
    price: number
    initialSetupFee: number
    currency: string;
    vatRate: number
    productName: string
    availablePaymentMethods: string[]

    status: ProductPurchaseStatus
    licenceId: string
    licenceType: ProductLicenseType
    termsAccepted: boolean
    cancelationPeriod: number

    creationDate: Date;
    productId: string;
    deleted: boolean

    logs: LogItem[]

    customer: User
    product: Product
    licence: Licence

    constructor();
    constructor(init: ProductPurchase);
    constructor(init?: ProductPurchase) {
        this.id = init && init.id || null
        this.creationDate = init && init.creationDate ? new Date((init as any).creationDate.seconds * 1000) : null
        this.provider = init && init.provider || null
        this.stripeCustomerId = init && init.stripeCustomerId || null
        this.stripeAccountId = init && init.stripeAccountId || null
        this.productId = init && init.productId || null
        this.productName = init && init.productName || null
        this.durationUnit = init && init.durationUnit || null
        this.duration = init && init.duration || null
        this.durationMultiplier = init && init.durationMultiplier || null
        this.price = init && init.price != null ? init.price : null
        this.initialSetupFee = init && init.initialSetupFee != null ? init.initialSetupFee : null
        this.currency = init && init.currency || 'eur';
        this.vatRate = init && init.vatRate != null ? init.vatRate : null
        this.customerUid = init && init.customerUid || null
        this.licenceHolderUid = init && init.licenceHolderUid || null
        this.status = init && init.status || null
        this.startDate = init && init.startDate ? new Date((init as any).startDate.seconds * 1000) : null
        this.endDate = init && init.endDate ? new Date((init as any).endDate.seconds * 1000) : null
        this.nextPaymentDate = init && init.nextPaymentDate ? new Date((init as any).nextPaymentDate.seconds * 1000) : null
        this.licenceId = init && init.licenceId || null
        this.licenceType = init && init.licenceType || null
        this.termsAccepted = init && init.termsAccepted || false
        this.recurring = init && init.recurring || false
        this.autoRenew = init && init.autoRenew || false
        this.cancelationPeriod = init && init.cancelationPeriod != null ? init.cancelationPeriod : 0
        this.deleted = init && init.deleted || false
        this.logs = init && init.logs?.map(x => new LogItem(x)) || []
        this.availablePaymentMethods = init && init.availablePaymentMethods || null
        this.currentPaymentDateShift = init && init.currentPaymentDateShift || 0
    }

    getProductName() {
        return this.productName ?? this.product?.name
    }
    getPrintableStartDate() {
        if (this.startDate) {
            return this.startDate.asFormatedString()
        }
        if (!this.licenceType && !this.licenceId) return null
        if (this.status == 'canceled') return null
        return 'Bei Kaufabschluss'
    }
    getPrintableEndDate() {
        if (this.endDate) {
            return this.endDate.asFormatedString()
        }
        if (!this.licenceType && !this.licenceId) return null
        if (this.status == 'canceled') return null
        if (this.autoRenew) return 'Verlängert automatisch'
        return 'Nicht definiert'
    }

    getPrintableStatus() {
        switch (this.status) {
            case 'draft':    return 'Entwurf';
            case 'uncompleted': return 'Ausstehend';
            case 'purchased':  return 'Kauf abgeschlossen';
            case 'active':   return 'Aktiv';
            case 'expired':  return 'Abgelaufen';
            case 'canceled': return 'Gekündigt';
            default:         return this.status;
        }
    }
    getPrintableDuration(durationMultiplier: number = this.durationMultiplier) {
        //if (this.licenceType == null) return 'Keine Laufzeit'
        if (this.duration == null) return 'Unbegrenzte Zeit'
        return (this.duration * (durationMultiplier ?? 1)).toString() + ' ' + this.getPrintableDurationUnit()
    }
    getPrintableDurationUnit() {
        if (this.durationUnit == 'day') return 'Tag(e)'
        if (this.durationUnit == 'week') return 'Woche(n)'
        if (this.durationUnit == 'month') return 'Monat(e)'
        if (this.durationUnit == 'year') return 'Jahr(e)'
    }
    getPrintablePaymentFrequency() {
        if (this.recurring) {
            if (this.duration == 1) {
                if (this.durationUnit == 'day') return 'pro Tag'
                if (this.durationUnit == 'week') return 'pro Woche'
                if (this.durationUnit == 'month') return 'pro Monat'
                if (this.durationUnit == 'year') return 'pro Jahr'
            } else {
                return 'pro ' + this.duration.toString() + ' ' + this.getPrintableDurationUnit()
            }
        }
        return 'einmalig'
    }
    getPrintablePrice() {
        return (this.price / 100).toString().replace('.', ',')
    }
    getPrintablePriceWithCurrency() {
        return this.getPrintablePrice() + ' ' + this.getPrintableCurrency()
    }
    getPrintableCurrency() {
        if (this.currency == 'eur' || this.currency == null) return '€'
        if (this.currency == 'chf') return 'CHF'
        if (this.currency == 'usd') return '$'
        if (this.currency == 'gbp') return '£'
        return this.currency
    }
    getPrintableInitialSetupFee() {
        if (this.initialSetupFee == null || this.initialSetupFee == 0) return null
        return (this.initialSetupFee / 100).toString().replace('.', ',')
    }
    getPrintableInitialSetupFeeWithCurrency() {
        if (this.initialSetupFee == null) return null
        return this.getPrintableInitialSetupFee() + ' ' + this.getPrintableCurrency()
    }
    getPrintableCancelationPolicy() {
        if (!this.autoRenew || !this.duration) return ''
        if (!this.recurring) return 'Monatlich kündbar'
        return 'Kündigungsfrist: ' + this.cancelationPeriod + ' Tage vor Verlängerung'
    }
    getPrintableRenewalInformation() {
        if (this.autoRenew && this.getNextRenewalDate() != null) {
            return 'Verlängert sich automatisch am ' + this.getNextRenewalDate().asFormatedString() + ' um ' + (this.getPrintableDuration(1))
        }
        return ''
    }
    getPrintableRuntime() {
        var text = ''
        var now = new Date()
        if (this.startDate) {
            if (this.startDate > now) {
                text += 'Startet ' + this.startDate.asFormatedString()
            } else {
                text += 'Seit ' + this.startDate.asFormatedString()
            }
        } else if (this.licenceType || this.licenceId) {
            text += 'Startet sofort'
        }
        if (this.endDate) {
            text += 'bis ' + this.endDate.asFormatedString()
        } else {
            if (this.autoRenew && this.recurring) {
                text += ', verlängert sich automatisch um ' + this.getPrintableDuration(1) + ' (' +this.cancelationPeriod + ' Tage Kündigungsfrist)'
            }
        }
        return text
    }

    canBeCanceled() {
        if (this.status == 'active') {
            if (this.autoRenew && this.getNextRenewalDate()) {
                return true
            }
        }
        return false
    }

    getProjectedEndDate(renewalDurationMultiplier: number) {
        if (this.endDate) {
            const endMoment = moment(this.endDate.getTime());
            endMoment.add(this.duration * renewalDurationMultiplier, this.durationUnit);
            return endMoment.toDate();
        }
        return null
    }

    getNextCancelationDate() {
      if (this.startDate && this.duration && this.duration > 0) {
        const durationMultiplier = this.durationMultiplier ?? 1;
        const endMoment = moment(this.startDate);
        endMoment.add(this.duration * durationMultiplier, this.durationUnit);

        const earliestEndMoment = moment();
        earliestEndMoment.add(this.cancelationPeriod, "day");

        while (endMoment.isBefore(earliestEndMoment)) {
          endMoment.add(this.duration, this.durationUnit);
        }
        return endMoment.toDate();
      }
      return null
    }

    getEndDate() {
      if (this.endDate) {
        return this.endDate.clone();
      }
      if (this.startDate) {
        const endMoment = moment(this.startDate);
        const multiplier = this.durationMultiplier != null ? this.durationMultiplier : 1000;
        endMoment.add(this.duration * multiplier, this.durationUnit);
        return endMoment.toDate();
      }
      return null
    }

    getEndDateTillEndOfMonth() {
      if (this.endDate) {
        return this.endDate.clone();
      }
      if (this.startDate) {
        const endMoment = moment(this.startDate);
        const multiplier = this.durationMultiplier ?? 1;
        switch (this.durationUnit) {
          case ProductDurationUnit.DAY:
          case ProductDurationUnit.WEEK:
            endMoment.add(this.duration * multiplier, this.durationUnit);
            break;
          default:
            return moment().endOf("month").toDate();
        }
        return endMoment.toDate();
      }
      return null
    }

    getNextRenewalDate() {
        if (!this.autoRenew) return null;
        if (!this.startDate) return null;
        if (this.duration === null || this.duration < 1) return null;

        const durationMultiplier = this.durationMultiplier ?? 1;
        const endMoment = moment(this.startDate);

        endMoment.add(this.duration * durationMultiplier, this.durationUnit);

        while (endMoment.isBefore(new Date())) {
            endMoment.add(this.duration, this.durationUnit);
        }

        return endMoment.toDate();
    }

    asMap() {
        return {
            creationDate: this.creationDate,
            provider: this.provider,
            stripeCustomerId: this.stripeCustomerId,
            stripeAccountId: this.stripeAccountId,
            productId: this.productId,
            durationUnit: this.durationUnit,
            duration: this.duration,
            durationMultiplier: this.durationMultiplier,
            price: this.price,
            initialSetupFee: this.initialSetupFee,
            currency: this.currency,
            vatRate: this.vatRate,
            recurring: this.recurring,
            customerUid: this.customerUid,
            licenceHolderUid: this.licenceHolderUid,
            status: this.status,
            startDate: this.startDate,
            licenceId: this.licenceId,
            licenceType: this.licenceType,
            termsAccepted: this.termsAccepted,
            autoRenew: this.autoRenew,
            cancelationPeriod: this.cancelationPeriod,
            availablePaymentMethods: this.availablePaymentMethods,
            currentPaymentDateShift: this.currentPaymentDateShift,
            deleted: this.deleted,
        }
    }

}
