<div class="dialog-topbar">
    <h3 translate>Neuen Plan erstellen</h3>
</div>

<div class="dialog-content-wrapper">
    <label for="input-plan-name" translate>Planname</label>
    <input id="input-plan-name" maxlength="35" required class="input plannedmeal-name form-control wide-input" [(ngModel)]="name" [placeholder]="'Name' | translate" type="text">
    <div class="spacer-25"></div>
    <span translate>Achtung: Die Wahl des Startdatums bestimmt den Wochentag, an dem jede Trainingswoche des Athleten startet.</span>
    <div class="">
        <span translate>Startdatum: </span>
        <span class="marginleft-10">{{startDate?.asFormatedString()}}</span>
        <input [matDatepicker]="startDatePicker" [value]="startDate" [matDatepickerFilter]="startDateFilterTrainingPlanConfig" class="visibility-hidden width-0 padding-0" (dateInput)="onStartDateChanged($event.value?.toDate())">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker ></mat-datepicker><br>
    </div>
    <ng-container *ngIf="!template && currentPlan">
        <div class="spacer-25"></div>
        <div translate>Wochen von letztem Zyklus kopieren:</div>
        <div class="spacer-10"></div>
        <app-selection-dropdown [isMultiSelect]="true" [hasSearchField]="true" [selectableGroups]="selectableWeekGroups"></app-selection-dropdown>
    </ng-container>
    <ng-container *ngIf="!anyWeekSelected()">
        <div class="spacer-25"></div>
        <span translate>Vorlage (optional):</span> <ng-container *ngIf="template">{{template.nameTranslation?.GetValue(translate.currentLang)}}</ng-container><br>
        <div class="spacer-10"></div>
        <button *ngIf="template" class="button-filled small light" (click)="onRemoveTrainingPlanTemplate()" translate>Vorlage entfernen</button>
        <button *ngIf="!template" class="button-filled small light" (click)="onOpenTrainingPlanTemplatesSelection()" translate>Vorlage auswählen</button>
    </ng-container>

    <div class="spacer-25"></div>
    <div class="actions row">
        <div class="col">
            <button class="button-filled small light" (click)="onCancelDialog()" translate>Schließen</button>
        </div>
        <div class="col">
            <button class="button-filled small float-right" (click)="onConfirmDialog()" translate>Erstellen</button>
        </div>
    </div>
</div>