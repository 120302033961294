<table class="exercise-table" (click)="onTableClick()">
    <thead>
        <tr cdkDropList (cdkDropListDropped)="setParameterDropped($event)" cdkDropListOrientation="horizontal">
            <th></th>
            <th>#</th>
            <th *ngIf="showVideoIcon" class="video-column-header">
                
            </th>
            <th cdkDrag
                *ngFor="let parameter of selectedSetParameters">
                <div class="parameter-container {{parameter}}">
                    <ng-container
                        *ngIf="parameter == setParameter.note">
                        <div class="label" translate>
                            {{setParameter2SubHeadingMapping[parameter]}}
                        </div>
                        <svg width="1em" height="1em"
                            viewBox="0 0 16 16"
                            class="bi bi-trash float-right"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            (click)="onRemoveParameterFromExerciseTableClick(parameter); $event.stopPropagation()">
                            <path
                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                            <path fill-rule="evenodd"
                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                        </svg>
                        <input *ngIf="!trackingMode; else trackingNoteHeader" [disabled]="readOnlyMode"
                            maxlength="10"
                            class="input small form-control white-input input-noteunit"
                            [ngModel]="noteUnit"
                            (input)="noteUnitChange?.emit($event.target.value)"
                            type="text" (click)="$event.stopPropagation()">
                        <ng-template #trackingNoteHeader>
                            <div class="label" translate *ngIf="noteUnit">
                                {{noteUnit}}
                            </div>
                        </ng-template>
                    </ng-container>
                    <ng-container
                        *ngIf="parameter != setParameter.note">
                        <div class="label" translate>
                            {{setParameter2SubHeadingMapping[parameter]}}
                        </div>
                        <div>
                            {{setParameter2LabelUnitMapping[parameter] | unitConversion: languageService.selectedUnitSystem | translate}}
                        </div>
                        <div
                            *ngIf="!setParameter2LabelMapping[parameter]">
                            -</div>
                        <svg width="1em" height="1em"
                            viewBox="0 0 16 16"
                            class="bi bi-trash"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            (click)="onRemoveParameterFromExerciseTableClick(parameter);$event.stopPropagation()">
                            <path
                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                            <path fill-rule="evenodd"
                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                        </svg>
                    </ng-container>
                </div>
            </th>
            <th *ngIf="trackingMode && environment.firebaseProjectId == 'traindoo-app'" class="video-column">
                <div class="label" translate>1RM</div>
                <div class="text-center">&nbsp;</div>
            </th>
            <th *ngIf="isPeriodicPlan && environment.firebaseProjectId == 'traindoo-app'" class="setparameter-btn-group">
            </th>
            <th *ngIf="isPeriodicPlan && environment.firebaseProjectId == 'traindoo-app'" class="setparameter-btn-group">
            </th>
            <div class="setparameter-btn-group">
                <div
                    class="d-inline-block wrapper-setparameter-selection">
                    <button type="button"
                        class="icon-button"
                        id="dropdownSetParameters"
                        [matMenuTriggerFor]="setParameterDropdownMenu" (click)="$event.stopPropagation()">
                        <svg width="1em" height="1em"
                            viewBox="0 0 16 16"
                            class="bi bi-plus"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                    </button>
                    <mat-menu #setParameterDropdownMenu="matMenu">
                        <div class="setparameter-menu">
                            <ng-container *ngFor="let parameter of getSelectableSetParameters()">
                                <button mat-menu-item class="dropdown-item white"
                                    type="button"
                                    (click)="onParameterSetSelected(parameter)">{{setParameter2LabelMapping[parameter] | translate}}
                                    {{setParameter2UnitMapping[parameter] | translate}}</button>
                            </ng-container>
                        </div>
                    </mat-menu>
                </div>
            </div>
            <th *ngIf="trackingMode"></th>
        </tr>
    </thead>
    <tbody cdkDropList (cdkDropListDropped)="setDropped($event)">
        <tr #tableRows cdkDrag cdkDragLockAxis="y" [cdkDragDisabled]="readOnlyMode" *ngFor="let set of sets; let i = index"
            class="{{isPartOfSuperSet && set.reps == 0 ? 'hidden-set' : ''}}">
            <td cdkDragHandle>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-grip-vertical" viewBox="0 0 16 16">
                    <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                </svg>
            </td>
            <td [matMenuTriggerFor]="readOnlyMode ? null : menu"
                class="{{isPartOfDropset(set, sets, i) ? 'dropset-line' : ''}}" (click)="$event.stopPropagation()">
                <ng-container
                    *ngIf="set.isWarmupSet || set.isDropset; else defaultSet">
                    <div [tooltip]="'`W` kennzeichnet einen Warmup-Satz. Dieser Satz fließt nicht in deine Statistik mit ein.' | translate"
                        class="setmode-indicator"
                        *ngIf="set.isWarmupSet">
                        W
                    </div>
                    <div [tooltip]="'`D` kennzeichnet einen Dropset.' | translate"
                        class="setmode-indicator"
                        *ngIf="set.isDropset">
                        D
                    </div>
                </ng-container>
                <ng-template #defaultSet>
                    <span [tooltip]="'Satz-Typ wählen' | translate">
                        {{getRealSetIndex(sets, set) + 1}}
                    </span>
                </ng-template>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item
                        (click)="setSetMode(set, 'default', i)" translate>Normaler
                        Satz</button>
                    <button
                        *ngIf="canSelectWarmupSet(set)"
                        mat-menu-item
                        (click)="setSetMode(set, 'warmup', i)" translate>Warmup-Satz</button>
                    <button
                        *ngIf="!isPartOfSuperSet && !set.isDropset && i > 0"
                        mat-menu-item
                        (click)="setSetMode(set, 'dropset', i)" translate>Drop-Set</button>
                </mat-menu>
            </td>
            <td *ngIf="showVideoIcon">
                <div class="icon-button video-icon {{hasVideoRecording(i) ? 'video-filled' : ''}}" (click)="onVideoIconClicked?.emit(set)" [tooltip]="hasVideoRecording(i) ? 'Video austauschen' : 'Video hochladen' | translate">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera-video" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"/>
                    </svg>
                </div>
            </td>
            <td #setElements [id]="'set-input-' + i" class="{{isTransparentSet(set) ? 'transparent-set' : ''}}"
                *ngFor="let parameter of selectedSetParameters">
                <input
                    #numberInput
                    [disabled]="readOnlyMode"
                    *ngIf="isDefaultInputType(parameter, set, sets, i)"
                    min="0"
                    class="input small form-control white-input"
                    [step]="setParameter2ValueStepMapping[parameter]"
                    [placeholder]="getPlaceholder(i, parameter)"
                    [(ngModel)]="set[parameter]" type="number" (input)="setChanged()"
                    (keyup.enter)="addSet(set, numberInput)" (click)="onTableClick(); $event.stopPropagation()">
                <input
                    #tutInput
                    [disabled]="readOnlyMode"
                    *ngIf="parameter == setParameter.timeUnderTension"
                    class="input small form-control white-input masked-input"
                    [(ngModel)]="set[parameter]"
                    (input)="tutChange(set)" type="text"
                    [pattern]="tutPattern"
                    [placeholder]="getPlaceholder(i, parameter)"
                    (focusout)="checkTUTINput(set)" (input)="setChanged()"
                    (keyup.enter)="addSet(set, tutInput)" (click)="onTableClick(); $event.stopPropagation()">
                <input
                    #rirInput
                    [disabled]="readOnlyMode"
                    *ngIf="parameter == setParameter.rir"
                    min="0"
                    class="input small form-control white-input"
                    (input)="setSetRir(set, $event.target.value)"
                    type="text"
                    [pattern]="getRegexForSetParameter(parameter)"
                    [placeholder]="getPlaceholder(i, parameter)"
                    [value]="getSetRir(set)" (input)="setChanged()"
                    (keyup.enter)="addSet(set, rirInput)" (click)="onTableClick(); $event.stopPropagation()">
                <input
                    #rpeInput
                    [disabled]="readOnlyMode"
                    *ngIf="parameter == setParameter.rpe"
                    min="0"
                    class="input small form-control white-input"
                    (input)="setSetRpe(set, $event.target.value)"
                    type="text"
                    [pattern]="getRegexForSetParameter(parameter)"
                    [placeholder]="getPlaceholder(i, parameter)"
                    [value]="getSetRpe(set)" (input)="setChanged()"
                    (keyup.enter)="addSet(set, rpeInput)" (click)="onTableClick(); $event.stopPropagation()">
                <input
                    #rpeInput
                    [disabled]="readOnlyMode"
                    *ngIf="trackingMode && parameter == setParameter.heartRate"
                    min="0"
                    class="input small form-control white-input"
                    (input)="setSetHeartRate(set, $event.target.value)"
                    type="text"
                    [pattern]="getRegexForSetParameter(parameter)"
                    [placeholder]="getPlaceholder(i, parameter)"
                    [value]="getSetHeartRate(set)" (input)="setChanged()"
                    (keyup.enter)="addSet(set, rpeInput)" (click)="onTableClick(); $event.stopPropagation()">
                <ng-container *ngIf="parameter == setParameter.note">
                    <input
                    #noteInput
                    [disabled]="readOnlyMode || trackingMode"
                    *ngIf="!trackingMode; else trackingNotePreview;"
                    class="input medium form-control white-input"
                    [(ngModel)]="set[parameter]"
                    (input)="noteChanged(set, $event.target.value)"
                    [placeholder]="getPlaceholder(i, parameter)"
                    type="text" (input)="setChanged()"
                    (keyup.enter)="addSet(set, noteInput)" (click)="onTableClick(); $event.stopPropagation()">
                    <ng-template #trackingNotePreview>
                        <div class="label note-preview" [tooltip]="set[parameter]">
                            {{set[parameter]}}
                        </div>
                    </ng-template>
                </ng-container>
                <input
                    #timeinput
                    [disabled]="readOnlyMode"
                    [pattern]="getRegexForSetParameter(parameter)"
                    *ngIf="isTimeInputType(parameter)"
                    class="input medium form-control white-input {{hasValueRange(timeinput?.value) ? 'range-input' : ''}}"
                    (focusout)="setTime(set, parameter, $event.target.value)"
                    [value]="getTime(set, parameter)" type="text"
                    [placeholder]="getPlaceholder(i, parameter)" (input)="setChanged()"
                    (keyup.enter)="addSet(set, timeinput)" (click)="onTableClick(); $event.stopPropagation()">

                    <ng-container *ngIf="formulaTemplate" [ngTemplateOutlet]="formulaTemplate" [ngTemplateOutletContext]="{set:set, exercise:exercise, parameter:parameter, i:i}"></ng-container>
            </td>
            <th *ngIf="trackingMode && environment.firebaseProjectId == 'traindoo-app'" class="video-column">
                {{set?.getE1RM() | weightConversion: languageService.selectedUnitSystem: true }}
            </th>
            <td *ngIf="!trackingMode && environment.firebaseProjectId == 'traindoo-app'">
                <div *ngIf="readOnlyMode || !trainingService.rpeTableCopyResult" class="icon-button display-inlineblock marginleft-10 button-exerciserecording" (click)="onOpenRpeDialog(exercise, set);$event.stopPropagation()">
                    <i class="bi bi-table fontsize-18"></i>
                </div>
                <div *ngIf="trainingService.rpeTableCopyResult && !readOnlyMode" class="icon-button display-inlineblock marginleft-10 button-exerciserecording" (click)="onPasteRpeTableCopyResult(exercise, set);$event.stopPropagation()" [tooltip]="'Werte einfügen' | translate">
                    <i class="bi bi-clipboard fontsize-18"></i>
                </div>
            </td>
            <td *ngIf="isPeriodicPlan && environment.firebaseProjectId == 'traindoo-app'">
                <div class="icon-button display-inlineblock marginleft-10 button-exerciserecording {{set.videoRecordingRequested ? 'active bi-highlight' : ''}}" (click)="toggleExerciseRecordingRequestedForSet(set);$event.stopPropagation()">
                    <i class="bi bi-camera-video"></i>
                </div>
            </td>
            <td class="remove-set">
                <i class="bi bi-x-lg fontsize-20" (click)="removeSet(set);$event.stopPropagation()"></i>
            </td>
            <td *ngIf="trackingMode">
                <div  class="{{set.isTracked ? 'tracked-set-indicator' : canTrackSet(set) ? 'current-set-indicator' : isFutureSet(set) ? 'future-set-indicator' : ''}}">
                    <i class="bi bi-check2 fontsize-20 {{set.isTracked ? 'tracked-check' : ''}}" (click)="setTracked(set);$event.stopPropagation()"></i>
                </div>
            </td>
        </tr>
        <tr>
            <td>
                <div class="icon-button display-inlineblock"
                    (click)="addSet();$event.stopPropagation()" [tooltip]="'&crarr;' + ('Enter' | translate)">
                    <svg width="1em" height="1em"
                        viewBox="0 0 16 16" class="bi bi-plus"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                </div>
            </td>
            <td colspan="2">
                <div class=""
                    *ngIf="hasTimeParameter()"
                    (click)="onAutoRunChanged();$event.stopPropagation()"
                    [tooltip]="'Aktivieren, wenn die App nach einem Satz oder einer Pause automatisch zum nächsten Satz springen soll.' | translate">
                    <input
                        [disabled]="readOnlyMode"
                        class="input checkbox" type="checkbox"
                        [checked]="autoRun">
                    <span class="label">&nbsp;&nbsp;<span translate>Auto-Durchlauf</span></span>
                </div>
            </td>
        </tr>
    </tbody>
</table>