import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { FirestoreService } from 'src/app/services/firestore.service';
import { LicenceHolder } from 'src/app/model/licenceholder.model';
import { Coach } from 'src/app/model/coach.model';
import { FirestoreNutritionPlanService } from 'src/app/services/firestore-nutritionplan.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  public step = 0
  public loading = false

  public licenceHolderName = ''
  public coachName = ''

  public createAccount = true
  public mail = '';
  public mailValidation = '';
  public password = '';

  public termsAccepted = false

  public environment = environment

  constructor(private  authService:  AuthService, private router: Router, public userService: FirestoreService, private toastr: ToastrService) { }

  ngOnInit(): void {
    if (environment.firebaseProjectId != 'traindoo-app') {
      this.router.navigate(['../dashboard'])
    }
  }

  onContinue() {
    if (this.step == 0) {
      if (this.licenceHolderName?.length < 3) {
        this.toastr.error('Bitte gib einen Namen ein.', '',  {
            positionClass: 'toast-bottom-center'
        });
        return
      }
      if (this.coachName?.length < 2) {
        this.toastr.error('Bitte gib einen Namen ein.', '',  {
            positionClass: 'toast-bottom-center'
        });
        return
      }
      this.step = 1
    } else if (this.step == 1) {
      if (this.termsAccepted) {
        this.step = 2
      }
    }
  }

  register() {
    var email = this.mail?.replaceAll(' ', '').replace(/[\u200B-\u200D\uFEFF]/g, '') ?? ''
    var emailValidated = this.mailValidation?.replaceAll(' ', '').replace(/[\u200B-\u200D\uFEFF]/g, '') ?? ''
    if (email.length == 0) {
      this.toastr.error('Bitte gib eine Email-Adresse ein.', '',  {
          positionClass: 'toast-bottom-center'
      });
      return
    }
    if (emailValidated.length == 0) {
      this.toastr.error('Bitte bestätige deine Email-Adresse.', '',  {
          positionClass: 'toast-bottom-center'
      });
      return
    }
    if (email != emailValidated) {
      this.toastr.error('Stelle sicher, dass du in beide Feldern die richtige Email-Adresse eingegeben hast.', '',  {
          positionClass: 'toast-bottom-center'
      });
      return
    }

    this.loading = true

    this.authService.createAndLoginUser(email, this.password).then(async (result) => {
      console.log(result)
      var uid = result.user.uid
      var licenceHolder = new LicenceHolder()
      licenceHolder.uid = uid
      licenceHolder.name = this.licenceHolderName
      licenceHolder.onTrial = true
      licenceHolder.trialEndDate = new Date()
      licenceHolder.trialEndDate.setDate(licenceHolder.trialEndDate.getDate() + 14)
      await this.userService.updateLicenceHolder(licenceHolder)
      var coach = new Coach()
      coach.uid = uid
      coach.licenceHolderUid = uid
      coach.name = this.coachName
      coach.hasRecipeDatabaseAccess = true
      coach.trainingEnabled = true
      coach.trainerCode = FirestoreNutritionPlanService.generateUniqueString().toUpperCase()
      await this.userService.updateCoach(coach)
      this.loading = false
      this.step = 3
    }).catch((error) => {
      console.log('Error')
      console.log(error)
      this.loading = false
      if (error.code == 'auth/email-already-in-use') {
        this.createAccount = false
        this.password = ''
      }
    });
  }

  login() {
    var email = this.mail?.replaceAll(' ', '').replace(/[\u200B-\u200D\uFEFF]/g, '') ?? ''
    if (email.length == 0) {
      this.toastr.error('Bitte gib eine Email-Adresse ein.', '',  {
          positionClass: 'toast-bottom-center'
      });
      return
    }

    this.loading = true

    this.authService.login(email, this.password).then(async (result) => {
      console.log(result)
      var uid = result.user.uid
      var licenceHolder = new LicenceHolder()
      licenceHolder.uid = uid
      licenceHolder.name = this.licenceHolderName
      licenceHolder.onTrial = true
      licenceHolder.trialEndDate = new Date()
      licenceHolder.trialEndDate.setDate(licenceHolder.trialEndDate.getDate() + 14)
      await this.userService.updateLicenceHolder(licenceHolder)
      var coach = new Coach()
      coach.uid = uid
      coach.licenceHolderUid = uid
      coach.name = this.coachName
      coach.hasRecipeDatabaseAccess = true
      coach.trainingEnabled = true
      coach.trainerCode = FirestoreNutritionPlanService.generateUniqueString().toUpperCase()
      await this.userService.updateCoach(coach)
      this.loading = false
      this.step = 3
    }).catch((error) => {
      this.loading = false
      console.log('Error')
      console.log(error)
    });

  }

  goToDashboard() {
    this.router.navigate(['../dashboard'])
      .then(() => {
        window.location.reload();
      });
  }
}
