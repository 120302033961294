import { Component, Input, NgZone, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { User } from '../model/user.model';
import { FirestoreService } from '../services/firestore.service';
import { Chatmessage } from '../model/chatmessage.model';
import { BroadcastChat, Chat } from '../model/chat.model';
import { UtilityService } from '../services/utility.service';
import { ChatService } from '../services/chat.service';
import { Capacitor } from '@capacitor/core';
import { FirestoreNutritionPlanService } from '../services/firestore-nutritionplan.service';
import { DropdownItem } from '../model/automatic-push-notification.model';
import { ChatOverviewBase } from './chat-overview-base';
import { CdkDragRelease, CdkDragStart } from '@angular/cdk/drag-drop';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
  animations : [
    trigger('panelState', [
      state('closed', style({ height: '0px', overflow: 'hidden' })),
      state('open', style({ height: ('{{pixelHeight}}') }), {params: {pixelHeight: '*'}}),
      transition('closed <=> open', animate('300ms ease-in-out')),
    ]),
  ],
})
export class ChatComponent extends ChatOverviewBase implements OnInit {

  public folded = 'closed';
  public message = "";
  public platform = Capacitor.getPlatform()
  
  public clientSearchInput: string;

  public dragStartDelay = 50;

  public draggingSingleChat: boolean = false;
  
  constructor(public userService: FirestoreService, public authService: AuthService, public utilityService: UtilityService, public chatService: ChatService, private route: ActivatedRoute, ngZone: NgZone, translate: TranslateService) {
    super(userService, ngZone, translate);
   }

  ngOnInit(): void {
    this.init();
    this.utilityService.currentEvent.subscribe((e) => {
      if(e == 'WINDOW_RESIZE'){
        this.resetDrag();
      }
    });
  }

  toggleFold(){
    this.folded = this.folded === 'open' ? 'closed' : 'open';
  }

  openBraodcastChat() {
    let chat = new BroadcastChat();
    this.chatService.openChat(chat);
  }

  onClientSearchInputChanged(text: string) {
    this.clientSearchInput = text
    
  }

  toggleFilterDropdownMenu(event: any) {
    let dropdownMenu = document.getElementById('filterDropdownMenu');
    dropdownMenu.classList.toggle('show');
    

    event.stopPropagation();
  }

  private dragRelease: CdkDragRelease = null
  resetDrag() {
    if(this.dragRelease != null){
      this.dragRelease.source?.reset();
    }
  }

  dragStarted(event: CdkDragStart){
    this.draggingSingleChat = true;
  }

  dragEnd(){
    setTimeout(() => {
      this.draggingSingleChat = false;
    }, 200);
  }

  dragReleased(event: CdkDragRelease){
    this.dragRelease = event;
    setTimeout(() => {
      this.draggingSingleChat = false;
    }, 200);
  }

  dragDropped(){
    setTimeout(() => {
      this.draggingSingleChat = false;
    }, 200);
  }
  


  dateFormatter(date:Date):string{
    if(date.isToday()){
      return date.asTimeFormatedString()
    }
    else{
      return date.asFormatedString()
    }
  }
}