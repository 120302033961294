import { Component } from '@angular/core';
import {Payment} from "../../../../model/payment.model";
import {PaymentAnalyticsService} from "../../../../services/payment-analytics.service";
import * as moment from "moment";

@Component({
  selector: 'app-kpi-monthly-rev',
  templateUrl: './kpi-monthly-rev.component.html',
  styleUrls: ['./kpi-monthly-rev.component.css']
})
export class KpiMonthlyRevComponent {
  public result: string = null;
  public info: string = null;
  public growthRate: number = 0;

  constructor(private paymentAnalyticsService: PaymentAnalyticsService) {
    this.init();
  }

  private init() {
    const startMoment = moment().startOf('month');
    const endMoment = moment().endOf('day');
    const revenues = this.paymentAnalyticsService.getPaidProcessingAndFuturePayments().filter(payment => {
      const paidMoment = moment(payment.getDate());
      return paidMoment.isBetween(startMoment, endMoment, "day", "[]");
    });

    const rev = revenues.reduce((previousValue, currentValue) => previousValue + currentValue.amount/100, 0);
    this.result = `${this.paymentAnalyticsService.transformNumberToMoney(rev)}`;


    const lastMonthStartMoment = moment().subtract(1, 'month').startOf('month');
    const lastMonthEndMoment = moment().subtract(1, 'month').endOf('day');
    const lastMonthsRevenues = this.paymentAnalyticsService.getPaidProcessingAndFuturePayments().filter(payment => {
      const paidMoment = moment(payment.getDate());
      return paidMoment.isBetween(lastMonthStartMoment, lastMonthEndMoment, "day", "[]");
    });

    const lastMonthsRev = lastMonthsRevenues.reduce((previousValue, currentValue) => previousValue + currentValue.amount/100, 0);

    this.growthRate = (rev/lastMonthsRev) - 1;
    if(isNaN(this.growthRate)) {
      this.growthRate = Infinity;
    }
    this.info = `Veränderung im Vergleich zu ${this.paymentAnalyticsService.transformNumberToMoney(lastMonthsRev)} vom ${lastMonthStartMoment.format('DD.MM.YY')} bis zum ${lastMonthEndMoment.format('DD.MM.YY')}: ${(this.growthRate * 100).toFixed(0)}%`;
  }

  protected readonly Infinity = Infinity;
}
