<div>
    <h3 class="display-inlineblock" translate>Athleten-Notiz</h3>
    <div class="icon-button float-right" (click)="onShowAllNotes()" translate>
        Alle ansehen
    </div>
    <div *ngIf="loading" class="loading-animation">
        <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
    </div>
    <app-md-editor-legacy *ngIf="!loading" class=""
        [inputValue]="editableNote?.content"
        [placeHolder]="editorPlaceholder"
        (valueChanged)="onEditorValueChanged($event)"
        (focusChanged)="onFocusChanged($event)">
    </app-md-editor-legacy>
</div>