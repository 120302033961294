import { Component, OnInit } from '@angular/core';
import {MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CreateCoachDialogComponent } from '../create-coach-dialog/create-coach-dialog.component';
import { CreateLicenceHolderDialogComponent } from '../create-licenceholder-dialog/create-licenceholder-dialog.component';
import { Coach } from '../model/coach.model';
import { LicenceHolder } from '../model/licenceholder.model';
import { User } from '../model/user.model';
import { FirestoreService } from '../services/firestore.service';
import { NavbarService } from '../services/navbar.service';
import { InputFieldDialogComponent } from '../inputfield-dialog/inputfield-dialog.component';
import * as FileSaver from 'file-saver';
import { UpdateEmailDialogComponent } from '../dialogs/update-email-dialog/update-email-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-team-management',
  templateUrl: './team-management.component.html',
  styleUrls: ['./team-management.component.css']
})
export class TeamManagementComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router, public userService: FirestoreService, public navService: NavbarService, private toastr: ToastrService, public dialog: MatDialog, private spinner: NgxSpinnerService, private translate: TranslateService) { }

  public environment = environment

  public selectedLicenceHolder: LicenceHolder
  public coachesOfLicenceHolder: Coach[] = []
  public licenceHolders: LicenceHolder[]
  public selectedCoach: Coach
  public selectedLicenceHolderEmail: string
  public selectedCoachEmail: string

  private subscriptionLicenceHolders: Subscription
  private subscriptionCoaches: Subscription

  ngOnInit(): void {
    this.selectedLicenceHolder = this.userService.getLoggedInUser().licenceHolder
    if (!this.selectedLicenceHolder || !this.selectedLicenceHolder.uid) return

    this.loadCoaches()

    if (this.isAdmin()) {
      this.subscriptionLicenceHolders = this.userService.getAllLicenceHolders().subscribe((licenceHolders => {
        this.licenceHolders = licenceHolders
        licenceHolders.sort((a, b) => a.name.localeCompare(b.name))
      }))
    }
  }

  getSelectableClients() {
    return this.userService.getAllClientsOfLicenceHolder()
  }
  getSelectableClientGroups() {
    return this.userService.getClientGroups()
  }

  loadCoaches() {
    if (this.subscriptionCoaches) this.subscriptionCoaches.unsubscribe()
    this.subscriptionCoaches = this.userService.getAllCoachesByLicenceHolderUid(this.selectedLicenceHolder.uid).subscribe(coaches => {
      this.coachesOfLicenceHolder = coaches
    })
  }

  ngOnDestroy(): void {
    if (this.subscriptionCoaches) this.subscriptionCoaches.unsubscribe()
    if (this.subscriptionLicenceHolders) this.subscriptionLicenceHolders.unsubscribe()
  }

  onCreateCoach() {
    const dialogRef = this.dialog.open(CreateCoachDialogComponent, {
      data: {licenceHolders: this.licenceHolders, selectedLicenceHolder: this.selectedLicenceHolder}, width: '1000px', autoFocus: false, restoreFocus: false
    })
    dialogRef.afterClosed().subscribe(async result => {
      if (result) { }
    });
  }
  onSelectCoach(coach: Coach) {
    this.selectedCoach = coach
    if (this.selectedCoach.uid == this.selectedLicenceHolder.uid) {
      this.selectedCoachEmail = this.selectedLicenceHolderEmail
    } else {
      this.userService.getEmailForUser(coach.uid).then(email => {
        this.selectedCoachEmail = email
      })
    }
  }
  async onSaveCoach() {
    this.spinner.show()
    await this.userService.updateCoach(this.selectedCoach)
    this.selectedCoach = null
    this.spinner.hide()
  }
  onDeactivateCoach() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: this.translate.instant('Möchtest du diesen Coachzugang wirklich deaktivieren?'), title: this.translate.instant('Coach deaktivieren') },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        await this.userService.updateCoachAttribute(this.selectedCoach, 'disabled', true)
      }
    })
  }

  onHasLicenceHolderRightsToggled(coach: Coach, checked: boolean) {
    if (coach.isLicenceHolder()) return
    coach.hasLicenceHolderRights = checked
    this.userService.updateAdminRights(coach)
  }

  onCoachCanAccessAllUsersChanged() {
    if (this.selectedCoach.hasLicenceHolderRights) return
    this.selectedCoach.canAccessAllUsers = !this.selectedCoach.canAccessAllUsers
  }

  onCoachUserAccessChanged(user: User) {
    if (this.selectedCoach.canAccessAllUsers) return
    if (this.selectedCoach.accessibleUserUids.includes(user.uid)) {
      this.selectedCoach.accessibleUserUids.forEach( (item, index) => {
        if (item == user.uid) this.selectedCoach.accessibleUserUids.splice(index, 1)
      })
    } else {
      this.selectedCoach.accessibleUserUids.push(user.uid)
    }
  }

  onCoachUserAccessGroupsChanged(group: string) {
    if (this.selectedCoach.canAccessAllUsers) return
    if (this.selectedCoach.accessibleGroupNames.includes(group)) {
      this.selectedCoach.accessibleGroupNames.forEach( (item, index) => {
        if (item == group) this.selectedCoach.accessibleGroupNames.splice(index, 1)
      })
    } else {
      this.selectedCoach.accessibleGroupNames.push(group)
    }
  }

  onCoachCoachesAccessChanged(coach: Coach) {
    if (this.selectedCoach.accessibleCoachUids.includes(coach.uid)) {
      this.selectedCoach.accessibleCoachUids.forEach( (item, index) => {
        if (item == coach.uid) this.selectedCoach.accessibleCoachUids.splice(index, 1)
      })
    } else {
      this.selectedCoach.accessibleCoachUids.push(coach.uid)
    }
  }

  onCanAssignCoachesAndLicencesToggled(coach: Coach, checked: boolean) {
    coach._canAssignCoachesAndLicences = checked
    this.onSaveCoach()
  }

  onOpenTokenCreation() {
    const dialogRef = this.dialog.open(InputFieldDialogComponent, {
      data: { message: 'Email-Adresse oder UID angeben.', title: 'Token erstellen' },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result && result.input) {
        var input = result.input?.replaceAll(' ', '');
        if (input.includes('@')) {
          let uid = (await firstValueFrom(this.userService.getFirebaseUserUid(input)))?.uid
          if (uid) {
            this.onCreateCustomToken(uid)
          }
        } else {
          let uid = input
          this.onCreateCustomToken(uid)
        }
      }
    })
  }

  onCreateLicenceHolder() {
    const dialogRef = this.dialog.open(CreateLicenceHolderDialogComponent, {
      data: {}, width: '1000px', autoFocus: false, restoreFocus: false
    })
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.uid) {
          if (this.licenceHolders.find(x => x.uid == result.uid)) {
            this.selectedLicenceHolder = this.licenceHolders.find(x => x.uid == result.uid)
          }
        }
      }
    });
  }
  async onSaveLicenceHolder() {
    this.spinner.show()
    await this.userService.updateLicenceHolder(this.selectedLicenceHolder)
    this.spinner.hide()
  }

  onSelectLicenceHolder(licenceHolder: LicenceHolder) {
    this.selectedLicenceHolder = licenceHolder
    this.selectedCoach = null
    this.selectedLicenceHolderEmail = null
    this.selectedCoachEmail = null
    this.userService.getEmailForUser(licenceHolder.uid).then(email => {
      this.selectedLicenceHolderEmail = email
    })
    this.loadCoaches()
  }

  onIsOnTrialToggled(licenceHolder: LicenceHolder, checked: boolean) {
    licenceHolder.onTrial = checked
    this.userService.updateOnTrial(licenceHolder)
  }

  private token: string

  onCreateCustomToken(uid: string) {
    this.userService.createCustomToken(uid).then(async result => {
      this.token = result.token
      this.toastr.success(this.translate.instant('Token erstellt.'), "",  {
        positionClass: 'toast-bottom-center'
      });
    })
  }

  onRedeemToken() {
    if (this.token) {
      this.authService.logout()
      this.authService.loginWithToken(this.token)
      return
    }
    const dialogRef = this.dialog.open(InputFieldDialogComponent, {
      data: { message: '', title: this.translate.instant('Token eingeben') },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result && result.input) {
        var id = result.input?.replaceAll(' ', '');
        await this.authService.logout()
        this.authService.loginWithToken(id)
      }
    });
  }
  
  async onDownloadLicenceOverview(licenceHolder: LicenceHolder) {
    this.spinner.show()
    var licences = await this.userService.getLicencesByLicenceHolderUid(licenceHolder.uid)
    var data: any[] = []
    data.push([this.translate.instant("Id"), this.translate.instant("Aktiviert am"), this.translate.instant("Deaktiviert am"), this.translate.instant("Trainerlizenz")])
    for (var licence of licences) {
      data.push([licence.lid, licence.redeemDate, licence.deactivationDate, (licence.isTrialLicence ? 'y': 'n')])
    }

    const replacer = (key, value) => value === null ? '' : value;
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], {type: 'text/csv' })
    FileSaver.saveAs(blob, 'Lizenzen.csv')
    this.spinner.hide()
  }

  onUpdateEmail() {
    const dialogRef = this.dialog.open(UpdateEmailDialogComponent, {});
  }

  isAdmin() {
    return this.authService.isAdmin()
  }
}
