import { NgZone } from "@angular/core";
import { DropdownItem } from "../model/automatic-push-notification.model";
import { User } from "../model/user.model";
import { FirestoreService } from "../services/firestore.service";
import { TranslateService } from "@ngx-translate/core";

export class ChatOverviewBase {

    public selectableCoachGroups: DropdownItem[] = [];
    public selectableGroupNames: DropdownItem[] = [];

    constructor(public userService: FirestoreService, private ngZone: NgZone, private translate: TranslateService) {
    }
    public init() {
        try {
            this.selectableCoachGroups = []
            this.selectableGroupNames = []

            let user = this.userService.getLoggedInUser();
            if(user){
                this.observeCoaches(user);
                this.userService.observableClientGroups.subscribe(clientGroups => this.refreshSelectableClientsGroups(clientGroups))
            }
            else {
                let userSubsciption = this.userService.observableUser.subscribe(user => {
                    if(user){
                        userSubsciption.unsubscribe();
                        this.observeCoaches(user);
                        this.userService.observableClientGroups.subscribe(clientGroups => this.refreshSelectableClientsGroups(clientGroups))
                    }
                });
            }


        }
        catch (ex) {
            console.error(ex);
        }
    }

    observeCoaches(loggedInUser: User){
        this.userService.getAllCoachesByLicenceHolderUid(loggedInUser?.licenceHolderUid).subscribe(coaches => {
            let selectedChatFilterCoachUids = loggedInUser.portalSettingsCoach?.selectedChatFilterCoachUids;
            this.selectableCoachGroups = [];
            coaches.forEach(coach => {
                if (loggedInUser.coach?.canAccessCoachClients(coach)) this.selectableCoachGroups.push(new DropdownItem(coach.uid, coach.name, selectedChatFilterCoachUids?.includes(coach.uid) || selectedChatFilterCoachUids?.length <= 0));
            });
            this.setAllFiltersIfAllSelected();
        });
    }

    refreshSelectableClientsGroups(clientGroups: string[]) {
        this.selectableGroupNames = [];
        let selectedChatFilterGroupNames = this.userService.getLoggedInUser().portalSettingsCoach?.selectedChatFilterGroupNames;
        this.selectableGroupNames.push(new DropdownItem('all', this.translate.instant('Alle'), true));
        clientGroups.forEach(group => {
            if (this.userService.getLoggedInUser().coach?.canAccessClientGroup(group)) this.selectableGroupNames.push(new DropdownItem(group, group, selectedChatFilterGroupNames?.includes(group) || selectedChatFilterGroupNames?.length <= 0));
        });
        this.setAllFiltersIfAllSelected();
    }

    setAllFiltersIfAllSelected() {
        if (this.selectableGroupNames?.filter(x => x.checked)?.length !== this.selectableGroupNames?.length || this.selectableCoachGroups?.filter(x => x.checked)?.length !== this.selectableCoachGroups?.length) {
            if (this.selectableGroupNames?.length > 0) this.selectableGroupNames[0].checked = false;
        }
        else {
            if(this.selectableGroupNames.length > 0){
                this.selectableGroupNames.forEach(groupName => {
                    groupName.checked = true;
                });
                this.selectableGroupNames[0].checked = true;
            }
            this.selectableCoachGroups.forEach(coachGroup => {
                coachGroup.checked = true;
            });
        }
    }

    getSelectedCoachUids() {
        return this.selectableCoachGroups.filter(x => x.checked).map(x => x.uid);
    }
    getSelectedGroupNames() {
        return this.selectableGroupNames.filter(x => x.checked).map(x => x.name);
    }

    onFilterSelectionChangedCoachGroup(clientGroup: DropdownItem, checked: boolean) {
        clientGroup.checked = checked;
        if (this.selectableGroupNames?.filter(x => x.checked)?.length !== this.selectableGroupNames?.length || this.selectableCoachGroups?.filter(x => x.checked)?.length !== this.selectableCoachGroups?.length) {
            if (this.selectableGroupNames?.length > 0) this.selectableGroupNames[0].checked = false;
        }
        else {
            if (this.selectableGroupNames?.length > 0) this.selectableGroupNames[0].checked = true;
        }

        this.setAllFiltersIfAllSelected();
        this.updateFilterPortalSettings();
    }

    onFilterSelectionChangedGroup(clientGroup: DropdownItem, checked: boolean) {
        clientGroup.checked = checked;
        if (clientGroup.uid === "all") {
            this.selectableGroupNames.forEach(group => {
                group.checked = checked;
            })
            this.selectableCoachGroups.forEach(group => {
                group.checked = checked;
            })
        }
        this.setAllFiltersIfAllSelected();
        this.updateFilterPortalSettings();
    }

    updateFilterPortalSettings(){
        this.userService.getLoggedInUser().portalSettingsCoach.selectedChatFilterGroupNames = this.getSelectedGroupNames();
        this.userService.getLoggedInUser().portalSettingsCoach.selectedChatFilterCoachUids = this.getSelectedCoachUids();
        this.userService.updatePortalSettingsForCoach(this.userService.getLoggedInUser());
    }

    getPrintableFilteredClients(): string {
        var checkedElements = '';
        let maxLength = 7;
        if (this.selectableGroupNames?.filter(x => x.checked)?.map(x => x.uid)?.includes("all")) {
            return this.translate.instant("Alle");
        }
        else {
            this.selectableGroupNames.filter(x => x.checked).forEach(group => {
                if (checkedElements.length > 0) {
                    checkedElements = checkedElements.concat(', ')
                }
                checkedElements = checkedElements.concat(group.name);
            });
            this.selectableCoachGroups.filter(x => x.checked).forEach(group => {
                if (checkedElements.length > 0) {
                    checkedElements = checkedElements.concat(', ')
                }
                checkedElements = checkedElements.concat(group.name);
            });
        }
        return checkedElements.length > maxLength ? checkedElements.substring(0, maxLength).concat('...') : checkedElements;
    }
}
