import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore, DocumentData } from '@angular/fire/compat/firestore';
import { AuthService } from '../auth/auth.service';
import { Ingredient } from '../model/ingredient.model';
import { Recipe } from '../model/recipe.model';
import { FirestoreService } from './firestore.service';
import { map } from 'rxjs/operators';
import { Observable, combineLatest, of, merge, Observer, firstValueFrom } from 'rxjs';
import { User } from '../model/user.model';
import { NutritionPlan } from '../model/nutritionplan.model';
import { Day } from '../model/day.model';
import { PlannedMeal } from '../model/plannedmeal.model';
import { Meal } from '../model/meal.model';
import { NutritionalSummary } from '../model/nutritionalsummary.model';
import { Tag } from '../model/tag.model';
import { NutritionalGoal } from '../model/nutritionalgoal.model';
import { PlannedFood } from '../model/plannedfood.model';
import { BaseNutritionFact } from '../model/basenutritionfact.model';
import { Food } from '../model/food.model';
import { CommonFirebase, IndividualFirebase, FirebaseProject } from '../app.module';
import { PlannedMealV2 } from '../model/plannedmealv2.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NutritionService {

  get firestore(): AngularFirestore {
    return this.mainFirebase.firestore
  }
  get fireStorage(): AngularFireStorage {
    return this.mainFirebase.storage
  }

  public recipes: Recipe[]
  public recipesLoaded = false
  public clientRecipes: Recipe[]
  public databaseRecipes: Recipe[]
  public baseRecipes: Recipe[]
  public customTags: Tag[] = []
  public nutritionPlanTemplates: NutritionPlan[] = null

  public dummyNutritionFacts: BaseNutritionFact[]

  constructor(private mainFirebase: IndividualFirebase, private commonFirebase: CommonFirebase, private userService: FirestoreService, private toastr: ToastrService, private translate: TranslateService) {
    this.recipes = []
    this.clientRecipes = []
    this.databaseRecipes = []
    this.baseRecipes = []
  }
  
  // RECIPES

  getClientRecipesByUid(uid: string, user: User = null): Observable<Recipe[]> {
    return new Observable((observer) => {
      // Fetch Recipes.
      this.firestore.collection<any>('MealTemplates/').ref.where('creatorUid', '==', uid).where("shared", '==', true).where("assignedUsers", 'array-contains-any', ["all", user.uid]).get().then(async snapshot => {
        var recipes = []
        for (let document of snapshot.docs) {
          var recipe = new Recipe(document.data() as Recipe)
          recipe.id = document.id
          recipe.creationDate = new Date(document.data().creationDate?.seconds ? document.data().creationDate?.seconds * 1000 : document.data().creationDate)
          if (!recipe.deleted) {
            BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
              if (document.data()[nutritionalValue] != null && document.data()[nutritionalValue] != undefined && document.data()[nutritionalValue].toString().length > 0) {
                recipe.setNutritionalValue(nutritionalValue, parseFloat(document.data()[nutritionalValue]))
              } else {
                recipe.setNutritionalValue(nutritionalValue, null)
              }
            })
            recipes.push(recipe)
            //this.loadRecipeImage(recipe)
            if (!recipe.calories && user != null) {
              recipe.ingredients = await this.getRecipeIngredients(recipe)
              recipe.recalculateNutritionalValues()
              recipe.loaded = true
              var data = {}
              BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
                if (recipe.getNutritionalValue(nutritionalValue) != null && recipe.getNutritionalValue(nutritionalValue) != undefined) {
                  data[nutritionalValue] = recipe.getNutritionalValue(nutritionalValue)
                }
              })
              /*this.firestore.collection('MealTemplates/').doc(recipe.id).set(data, {merge: true})
              console.log("## Migrated Recipe")*/
            }
          }
        };
        observer.next(recipes);
        observer.complete();
      });
    });
  }

  async getBaseRecipe(id: string, loadNutritionFacts: boolean = true): Promise<Recipe> {
    var r = this.recipes.find(r => r.id == id)
    if (r) return r
    r = this.databaseRecipes.find(r => r.id == id)
    if (r) return r
    r = this.baseRecipes.find(r => r.id == id)
    if (r) return r
    r = await this.getRecipeById(id, false, loadNutritionFacts)
    if (r) {
      this.baseRecipes.push(r)
      return r
    }
    return null
  }

  loadRecipes(): Observable<boolean> {
    return new Observable((observer) => {
      this.getRecipesByUid(this.userService.getLoggedInUser().licenceHolderUid || this.userService.getLoggedInUser().uid, this.userService.getLoggedInUser(), false).toPromise().then(async r => {
        
        await this.loadRecipeDatabase();

        var recipes = r
        for (let recipe of recipes) {
          if (recipe.modelVersion == 2 && recipe.baseMealTemplateId) {
            var baseRecipe = await this.getBaseRecipe(recipe.baseMealTemplateId)
            if (baseRecipe) recipe.merge(baseRecipe)
          }
          recipe.getComputedTags().forEach(tag => {
            if (tag.identifier == Tag.IDENTIFIER_CUSTOM) {
              var hasTag = false
              this.customTags.forEach(t => {
                  if (t.printableIdentifier?.toLowerCase() == tag.printableIdentifier?.toLowerCase()) hasTag = true
              })
              if (!hasTag) this.customTags.push(tag)
            }
          })
        }
        recipes.sort((a,b) => a.getName().localeCompare(b.getName()));
        this.customTags.sort((a,b) => a?.getPrintableName(this.translate)?.localeCompare(b?.getPrintableName(this.translate)))
        this.recipes = recipes
        this.recipesLoaded = true
        observer.next(true)
        observer.complete()
      })
    })
  }

  loadClientRecipes(): Observable<boolean>{
    if(!this.userService.getLoggedInUser().connectedLicenceHolderUid) return of(false)
    return new Observable((observer) => {
      this.getClientRecipesByUid(this.userService.getLoggedInUser().connectedLicenceHolderUid, this.userService.getLoggedInUser()).toPromise().then(async recipes => {
        
        await this.loadRecipeDatabase();

        for (let recipe of recipes) {
          if (recipe.modelVersion == 2 && recipe.baseMealTemplateId) {
            var baseRecipe = await this.getBaseRecipe(recipe.baseMealTemplateId)
            if (baseRecipe) recipe.merge(baseRecipe)
          }
          recipe.getComputedTags().forEach(tag => {
            if (tag.identifier == Tag.IDENTIFIER_CUSTOM) {
              var hasTag = false
              this.customTags.forEach(t => {
                  if (t.printableIdentifier?.toLowerCase() == tag.printableIdentifier?.toLowerCase()) hasTag = true
              })
              if (!hasTag) this.customTags.push(tag)
            }
          })
        }

        recipes.sort((a,b) => a.getName().localeCompare(b.getName()));

        this.clientRecipes = recipes
        this.clientRecipes.forEach(recipe => {
          recipe.getComputedTags().forEach(tag => {
            if (tag.identifier == Tag.IDENTIFIER_CUSTOM) {
              var hasTag = false
              this.customTags.forEach(t => {
                  if (t.printableIdentifier?.toLowerCase() == tag.printableIdentifier?.toLowerCase()) hasTag = true
              })
              if (!hasTag) this.customTags.push(tag)
            }
          })
        })
        
        observer.next(true)
        observer.complete()
      }).catch(ex => {
        console.log(ex)
        observer.next(false)
        observer.complete()
      })
    })
  }

  async loadRecipeDatabase() {
    var recipes = await firstValueFrom(this.getRecipesByUid('d6EQi9raVsfZ3yNLuwsgoIZV68n1', null, true))
    for (let recipe of recipes) {
      if (recipe.modelVersion == 2 && recipe.baseMealTemplateId) {
        if (this.recipes?.find(r => r.id == recipe.baseMealTemplateId) != null) {
          recipe.merge(this.recipes.find(r => r.id == recipe.baseMealTemplateId))
        } else {
          var baseRecipe = await this.getBaseRecipe(recipe.baseMealTemplateId)
          if (baseRecipe) recipe.merge(baseRecipe)
        }
      }
    }
    recipes.sort((a,b) => a.getName().localeCompare(b.getName()));
    recipes.forEach(recipe => {
      if (!recipe.thumbnailURL) this.loadRecipeImage(recipe)
    })
    this.databaseRecipes = recipes
    if (this.baseRecipes) {
      this.baseRecipes = this.baseRecipes.concat(recipes)
    } else {
      this.baseRecipes = recipes
    }
  }

  async loadDummyNutritionFacts() {
    if (this.dummyNutritionFacts) return
    this.dummyNutritionFacts = []
    var snapshot = await this.firestore.collection<any>('LicenceHolders/').doc(this.userService.getLoggedInUser().licenceHolderUid).collection('DummyNutritionFacts').get().toPromise()
    snapshot.forEach(document => {
      var nutritionFact = new BaseNutritionFact(document.data() as BaseNutritionFact)
      nutritionFact.id = document.id
      nutritionFact.carbohydrates = document.data().carbohydrates ?? null
      nutritionFact.protein = document.data().protein ?? null
      nutritionFact.fat = document.data().fat ?? null
      nutritionFact.calories = document.data().calories ?? null
      this.dummyNutritionFacts.push(nutritionFact)
    })
  }

  async saveDummyNutritionFact(nutritionFact: BaseNutritionFact) {
    var data = {
      nameDe: nutritionFact.getName(),
      calories: nutritionFact.calories,
      protein: nutritionFact.protein,
      fat: nutritionFact.fat,
      carbohydrates: nutritionFact.carbohydrates,
    }
    if (nutritionFact.id) {
      await this.firestore.collection('LicenceHolders/').doc(this.userService.getLoggedInUser().licenceHolderUid).collection('DummyNutritionFacts').doc(nutritionFact.id).set(data)
      var index = this.dummyNutritionFacts.findIndex(f => f.id == nutritionFact.id)
      if (index != -1) {
        this.dummyNutritionFacts[index] = nutritionFact
      } else {
        this.dummyNutritionFacts.push(nutritionFact)
      }
    } else {
      var res = await this.firestore.collection('LicenceHolders/').doc(this.userService.getLoggedInUser().licenceHolderUid).collection('DummyNutritionFacts').add(data)
      nutritionFact.id = res.id
      this.dummyNutritionFacts.push(nutritionFact)
    }
  }

  async deleteDummyNutritionFact(nutritionFact: BaseNutritionFact) {
    await this.firestore.collection('LicenceHolders/').doc(this.userService.getLoggedInUser().licenceHolderUid).collection('DummyNutritionFacts').doc(nutritionFact.id).delete()
    this.dummyNutritionFacts = this.dummyNutritionFacts.filter(f => f.id != nutritionFact.id)
  }

  private recipeThumbnailPromiseMap = new Map<string, Promise<string>>()
  
  getRecipeThumbnailUrl(recipe: Recipe) {
    if (!recipe) return null
    if (recipe.thumbnailUrl) {
      recipe.thumbnailURL = recipe.thumbnailUrl
      return new Promise<string>((resolve, reject) => resolve(recipe.thumbnailUrl))
    }
    if (recipe.thumbnailURL) return new Promise<string>((resolve, reject) => resolve(recipe.thumbnailURL))
    if (this.recipeThumbnailPromiseMap.has(recipe.id)) {
      return this.recipeThumbnailPromiseMap.get(recipe.id).then((link) => {
        recipe.thumbnailURL = link;
        return link
      })
    }
    var promise: Promise<string> = new Promise((resolve, reject) => {
      firstValueFrom((recipe.isThumbnailPathFromCommonFirebase() ? this.commonFirebase : this.mainFirebase).storage.ref(recipe.getThumbnailPath() || 'meal_templates/' + recipe.id + '/thumbnail.jpg').getDownloadURL()).then((link) => {
        recipe.thumbnailURL = link?.split('&token=')[0]
        if (recipe.thumbnailURL && !recipe.baseMealTemplateId) {
          if (!recipe.getThumbnailPath()) {
            this.firestore.collection('MealTemplates/').doc(recipe.id).update({thumbnailUrl: recipe.thumbnailURL, thumbnailPath: 'meal_templates/' + recipe.id + '/thumbnail.jpg'}).then(res => {console.log('updated')})
          } else {
            this.firestore.collection('MealTemplates/').doc(recipe.id).update({thumbnailUrl: recipe.thumbnailURL}).then(res => {console.log('updated')})
          }
        }
        if (recipe.baseMealTemplateId == null && recipe.thumbnailPath == null) recipe.thumbnailPath = recipe.getThumbnailPath() || 'meal_templates/' + recipe.id + '/thumbnail.jpg'
        resolve(link)
      }).catch(ex => console.log(ex));
    });
    this.recipeThumbnailPromiseMap.set(recipe.id, promise)
    return promise
  }
  clearRecipeThumbnailUrl(recipe: Recipe) {
    this.recipeThumbnailPromiseMap.delete(recipe.id)
    recipe.thumbnailURL = null
  }

  private plannedMealThumbnailPromiseMap = new Map<string, Promise<string>>()

  getPlannedMealThumbnailUrl(meal: PlannedMealV2) {
    if (!meal || meal.getThumbnailPath()?.length == 0) return null
    if (true) {
      if (meal.imageURL) return new Promise<string>((resolve, reject) => resolve(meal.imageURL))
      if (this.plannedMealThumbnailPromiseMap.has(meal.getThumbnailPath())) {
        return this.plannedMealThumbnailPromiseMap.get(meal.getThumbnailPath()).then((link) => {
          meal.imageURL = link;
          return link
        })
      }
      var promise: Promise<string> = new Promise((resolve, reject) => {
        firstValueFrom((meal.isThumbnailPathFromCommonFirebase() ? this.commonFirebase : this.mainFirebase).storage.ref(meal.getThumbnailPath()).getDownloadURL()).then((link) => {
          meal.imageURL = link;
          resolve(link)
        }).catch(ex => console.log(ex));
      });
      this.plannedMealThumbnailPromiseMap.set(meal.getThumbnailPath(), promise)
      return promise;
    }
    return null
  }
  
  getRecipesByUid(uid: string, coach: User = null, fromCommonFirebase: boolean = false): Observable<Recipe[]> {
    return new Observable((observer) => {
      // Fetch Recipes.
      (fromCommonFirebase ? this.commonFirebase : this.mainFirebase).firestore.collection<any>('MealTemplates/').ref.where('creatorUid', '==', uid).get().then(async snapshot => {
        var recipes = []
        for (let document of snapshot.docs) {
          var recipe = new Recipe(document.data() as Recipe)
          recipe.id = fromCommonFirebase && (this.mainFirebase.name != this.commonFirebase.name) ? 'NUT_' + document.id : document.id
          recipe.fromCommonFirebase = fromCommonFirebase
          if (!recipe.deleted) {
            BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
              if (document.data()[nutritionalValue] != null && document.data()[nutritionalValue] != undefined && document.data()[nutritionalValue].toString().length > 0) {
                recipe.setNutritionalValue(nutritionalValue, parseFloat(document.data()[nutritionalValue]))
              } else {
                recipe.setNutritionalValue(nutritionalValue, null)
              }
            })
            recipes.push(recipe)
          }
        };
        observer.next(recipes);
        observer.complete();
      });
    });
  }

  async getRecipeById(id: string, fromCommonFirebase: boolean = false, loadNutritionFacts: boolean = true): Promise<Recipe> {
    var snapshot = await (fromCommonFirebase ? this.commonFirebase : this.mainFirebase).firestore.collection<any>('MealTemplates/').doc(id).ref.get()
    if (!snapshot.exists) return null
    var recipe = new Recipe(snapshot.data() as Recipe)
    recipe.id = snapshot.id
    recipe.fromCommonFirebase = fromCommonFirebase
    if (!recipe.deleted) {
      if (recipe.modelVersion < 2 && !recipe.baseMealTemplateId) {
        await this.loadFullRecipe(recipe, false, loadNutritionFacts)
      }
      BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
        if (snapshot.data()[nutritionalValue] != null && snapshot.data()[nutritionalValue] != undefined && snapshot.data()[nutritionalValue].toString().length > 0) {
          recipe.setNutritionalValue(nutritionalValue, parseFloat(snapshot.data()[nutritionalValue]))
        } else {
          recipe.setNutritionalValue(nutritionalValue, null)
        }
      })
    }
    return recipe
  }

  async loadBaseRecipeForPlannedMeal(meal: PlannedMealV2, loadNutritionFacts: boolean = true) {
    if (meal.baseMealTemplateId) {
      if (!meal.baseRecipe) {
        var baseRecipe = await this.getBaseRecipe(meal.baseMealTemplateId)
        if (baseRecipe) meal.baseRecipe = baseRecipe
      }
      if (meal.baseRecipe) {
        if (!meal.baseRecipe.loaded || !meal.baseRecipe.nutritionFactsLoaded && loadNutritionFacts) {
          await this.loadFullRecipe(meal.baseRecipe, false, loadNutritionFacts)
          //if (!meal.baseRecipe.calories) meal.baseRecipe.recalculateNutritionalValues()
          meal.baseRecipe.loaded = true
          meal.recalculateBaseRecipeFoods()
        } else {
          meal.recalculateBaseRecipeFoods()
        }
      }
      if (loadNutritionFacts) {
        meal.recalculateBaseRecipeFoods()
      } else {
        meal.recalculateNutritionalValues()
      }
    }
    
  }

  async loadNutritionFactsForPlannedMeal(plannedMeal: PlannedMeal): Promise<boolean> {
    for (let food of plannedMeal.foods) {
      if (!food.nutritionFact) {
        food.nutritionFact = await this.userService.getBaseNutritionFactById(food.nutritionFactId).toPromise()
        food.recalculateNutritionalValues()
      }
    }
    return Promise.resolve(true)
  }

  async loadFullRecipe(recipe: Recipe, isDatabaseRecipe: boolean = false, loadNutritionFacts: boolean = true) {
    if (recipe.modelVersion == 2) {
      if (recipe.baseMealTemplateId && recipe.baseRecipe == null) {
        var baseRecipe = await this.getBaseRecipe(recipe.baseMealTemplateId, loadNutritionFacts)
        if (baseRecipe) recipe.merge(baseRecipe)
      }
      if (recipe.baseRecipe?.modelVersion < 2 && !recipe.baseRecipe?.baseMealTemplateId) {
        await this.loadFullRecipe(recipe.baseRecipe, false, loadNutritionFacts)
      }
      if (recipe.baseRecipe && !recipe.baseRecipe.loaded && loadNutritionFacts) {
        for (var ingredient of recipe.baseRecipe.ingredients) {
          if (ingredient.nutritionFactId) {
            ingredient.nutritionFact = await this.userService.getBaseNutritionFactById(ingredient.nutritionFactId).toPromise()
          }
        }
      }
      if (recipe.ingredients) {
        for (var ingredient of recipe.ingredients) {
          if (ingredient.nutritionFactId && loadNutritionFacts) {
            ingredient.nutritionFact = await this.userService.getBaseNutritionFactById(ingredient.nutritionFactId).toPromise()
          }
        }
        if (loadNutritionFacts) recipe.nutritionFactsLoaded = true
      }
    } else {
      recipe.ingredients = await this.getRecipeIngredients(recipe, false, loadNutritionFacts)
    }
  }

  async getRecipeIngredients(recipe: Recipe, isDatabaseRecipe: boolean = false, loadNutritionFacts: boolean = true): Promise<Ingredient[]> {
    /*if (recipe.modelVersion == 2) {
      console.log('Model version 2')
      if (recipe.baseMealTemplateId && recipe.baseRecipe == null) {
        recipe.merge(await this.getRecipeById(recipe.baseMealTemplateId))
        if (recipe.baseRecipe?.modelVersion < 2 && !recipe.baseRecipe?.baseMealTemplateId) {
          recipe.baseRecipe.ingredients = await this.getRecipeIngredients(recipe.baseRecipe, false, loadNutritionFacts)
        }
      }
      if (recipe.baseRecipe && !recipe.baseRecipe.loaded && loadNutritionFacts) {
        for (var ingredient of recipe.baseRecipe.ingredients) {
          if (ingredient.nutritionFactId) {
            console.log('Load nutrition fact')
            ingredient.nutritionFact = await this.userService.getBaseNutritionFactById(ingredient.nutritionFactId).toPromise()
          }
        }
      }
      if (recipe.ingredients) {
        for (var ingredient of recipe.ingredients) {
          if (ingredient.nutritionFactId && loadNutritionFacts) {
            console.log('Load nutrition fact')
            ingredient.nutritionFact = await this.userService.getBaseNutritionFactById(ingredient.nutritionFactId).toPromise()
          }
        }
        return recipe.ingredients
      }
      return null
    }*/
    // Fetch Ingredients for Recipe.
    var snapshot = await (isDatabaseRecipe ? this.commonFirebase.firestore : this.mainFirebase.firestore).collection<any>('MealTemplates/' + recipe.id + "/Foods").ref.get()
    var ingredients = []
    for (let document of snapshot.docs) {
      var ingredient = new Ingredient(document.data() as Ingredient)
      ingredient.id = document.id
      ingredient.nutritionFactId = document.data().baseNutritionFactId
      if (ingredient.nutritionFactId && loadNutritionFacts) {
        ingredient.nutritionFact = await this.userService.getBaseNutritionFactById(ingredient.nutritionFactId).toPromise()
      }
      if (document.data().isDummy != null && document.data().isDummy == true) {
        ingredient.isDummy = true;
        ingredient.nutritionalSummary = new NutritionalSummary()
        ingredient.nutritionalSummary.carbohydrates = document.data().carbohydrates || 0
        ingredient.nutritionalSummary.protein = document.data().protein || 0
        ingredient.nutritionalSummary.fat = document.data().fat || 0
        ingredient.nutritionalSummary.calories = document.data().calories || 0
      }
      ingredients.push(ingredient)
    }
    if (loadNutritionFacts) recipe.nutritionFactsLoaded = true
    ingredients = ingredients.sort((a, b) => a.position - b.position)
    return ingredients
  }

  insertRecipe(recipe: Recipe, coach: User, updateRecipesList: boolean = true, overwriteCreatorUid: string = null): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!recipe.creationDate) recipe.creationDate = new Date()
      var data = recipe.toMap(overwriteCreatorUid || coach.licenceHolderUid || coach.uid)
      this.firestore.collection('MealTemplates/').add(data).then(res => {
        var recipeId = res.id
        var path = recipe.updatedImage ? 'meal_templates/' + recipeId + '/' + (new Date()).getTime() + '.jpg' : recipe.thumbnailPath ?? null
        recipe.thumbnailPath = path
        this.firestore.collection('MealTemplates/').doc(recipeId).update({id: recipeId, thumbnailPath: path}).then(async res => {
          recipe.id = recipeId
          if (recipe.updatedImage) this.uploadRecipeImage(recipe.updatedImage, recipe, recipe.thumbnailPath ?? 'meal_templates/' + recipe.id + '/thumbnail.jpg').then()
          if (recipe.ingredients) {
            for (var i = 0; i < recipe.ingredients.length; i++) {
              recipe.ingredients[i].position = i
              await this.insertIngredient(recipe.ingredients[i], recipeId, coach)
            }
          }
          if (updateRecipesList) {
            if (this.recipes?.length > 0) {
              this.recipes.push(recipe)
              this.recipes.sort((a,b) => a.getName().localeCompare(b.getName()));
            } else {
              this.loadRecipes().toPromise().then()
            }
          }
          resolve()
        })
      })
    })
  }
  async updateRecipe(recipe: Recipe, coach: User, updateFoods: boolean = true): Promise<void> {
    if (!(recipe.creationDate instanceof Date && !isNaN(recipe.creationDate.getTime()))) {
      recipe.creationDate = new Date()
    }
    var data = recipe.toMap(coach.licenceHolderUid || coach.uid)
    if (recipe.updatedImage) {
      var path = 'meal_templates/' + recipe.id + '/' + (new Date()).getTime() + '.jpg'
      recipe.thumbnailPath = path
      data['thumbnailPath'] = path
    }

    await this.firestore.collection('MealTemplates/').doc(recipe.id).update(data)

    if (updateFoods && recipe.ingredients) {
      var snapshot = await this.firestore.collection<any>('MealTemplates/' + recipe.id + "/Foods").ref.get()
      for (let document of snapshot.docs) {
        await this.firestore.collection('MealTemplates/' + recipe.id + '/Foods').doc(document.id).delete()
      }
      for (var i = 0; i < recipe.ingredients.length; i++) {
        recipe.ingredients[i].position = i
        await this.insertIngredient(recipe.ingredients[i], recipe.id, coach)
      }
    }
    this.recipes.forEach( (item, index) => {
      if (item.id === recipe.id) this.recipes.splice(index, 1, recipe);
    });
    if (recipe.updatedImage) await this.uploadRecipeImage(recipe.updatedImage, recipe, recipe.thumbnailPath ?? 'meal_templates/' + recipe.id + "/thumbnail.jpg")
  }

  async updateRecipeSharingSettings(recipe: Recipe, coach: User): Promise<void> {
    var data = {
      shared: recipe.shared, 
      assignedUsers: recipe.assignedUsers, 
      assignedGroupNames: recipe.assignedGroupNames,
      timestamp: new Date()
    }
    await this.firestore.collection('MealTemplates/').doc(recipe.id).update(data)

    this.recipes.forEach( (item, index) => {
      if (item.id === recipe.id) this.recipes.splice(index, 1, recipe);
    });
  }


  async deleteRecipe(recipe: Recipe, coach: User, updateRecipesList: boolean = true): Promise<void> {
    recipe.deleted = true
    var res = await this.firestore.collection('MealTemplates/').doc(recipe.id).update({deleted: true, shared: false, timestamp: new Date()})
    if (updateRecipesList) {
      this.recipes.forEach( (item, index) => {
        if (item.id === recipe.id) this.recipes.splice(index, 1);
      });
    }
  }
  
  insertIngredient(ingredient: Ingredient, recipeId: string, coach: User): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!ingredient.isDummy) {
        this.firestore.collection('MealTemplates/' + recipeId + '/Foods').add({baseNutritionFactId: ingredient.nutritionFactId, weight: ingredient.weight, servingSize: ingredient.servingSize || null, isDummy: false, position: ingredient.position, groupHeading: ingredient.groupHeading, allowWeightAdjustment: ingredient.allowWeightAdjustment }).then(res => {
          var ingredientId = res.id
          this.firestore.collection('MealTemplates/' + recipeId + '/Foods').doc(ingredientId).update({id: ingredientId}).then(res => {
            ingredient.id = ingredientId
            resolve()
          });
        });
      } else {
        this.firestore.collection('MealTemplates/' + recipeId + '/Foods').add({baseNutritionFactId: null, weight: ingredient.weight || null, name: ingredient.name || null, servingSize: ingredient.servingSize || null, isDummy: true, carbohydrates: ingredient.nutritionalSummary.carbohydrates || null, protein: ingredient.nutritionalSummary.protein || null, fat: ingredient.nutritionalSummary.fat || null, calories: ingredient.nutritionalSummary.calories || null, position: ingredient.position, groupHeading: ingredient.groupHeading, allowWeightAdjustment: ingredient.allowWeightAdjustment }).then(res => {
          var ingredientId = res.id
          this.firestore.collection('MealTemplates/' + recipeId + '/Foods').doc(ingredientId).update({id: ingredientId}).then(res => {
            ingredient.id = ingredientId
            resolve()
          });
        });
      }
    })
  }
  deleteIngredient(ingredient: Ingredient, recipeId: string, coach: User) {
    return this.firestore.collection('MealTemplates/' + recipeId + '/Foods').doc(ingredient.id).delete()
  }
  updateIngredient(ingredient: Ingredient, recipeId: string, coach: User) {
    if (ingredient.id && !ingredient.id.startsWith('tmp')) {
      if (!ingredient.isDummy) {
        return this.firestore.collection('MealTemplates/' + recipeId + '/Foods').doc(ingredient.id).set({baseNutritionFactId: ingredient.nutritionFactId, weight: ingredient.weight, servingSize: ingredient.servingSize || null, name: null, isDummy: false, position: ingredient.position, groupHeading: ingredient.groupHeading, allowWeightAdjustment: ingredient.allowWeightAdjustment}, {merge: true})
      } else {
        return this.firestore.collection('MealTemplates/' + recipeId + '/Foods').doc(ingredient.id).set({baseNutritionFactId: null, weight: ingredient.weight || null, name: ingredient.name || null, servingSize: ingredient.servingSize || null, isDummy: true, carbohydrates: ingredient.nutritionalSummary.carbohydrates || null, protein: ingredient.nutritionalSummary.protein || null, fat: ingredient.nutritionalSummary.fat || null, calories: ingredient.nutritionalSummary.calories || null, position: ingredient.position, groupHeading: ingredient.groupHeading, allowWeightAdjustment: ingredient.allowWeightAdjustment}, {merge: true})
      }
    } else {
      ingredient.id = null
      return this.insertIngredient(ingredient, recipeId, coach).then()
    }
  }
  uploadRecipeImage(image: File, recipe: Recipe, path: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.fireStorage.upload(path, image).then(res => {
        res.ref.getDownloadURL().then(url => {
          recipe.thumbnailURL = url
          recipe.updatedImage = null
          resolve()
        })
      })
    })
  }
  loadRecipeImage(recipe: Recipe) {
    firstValueFrom((recipe.fromCommonFirebase ? this.commonFirebase : this.mainFirebase).storage.ref(recipe.getThumbnailPath() ?? "meal_templates/" + recipe.id + "/thumbnail.jpg").getDownloadURL()).then(res => {
      recipe.thumbnailURL = res
    }, error => {})
  }
  deleteRecipeImage(recipe: Recipe) {
    this.fireStorage.ref("meal_templates/" + recipe.id + "/thumbnail.jpg").delete()
  }

  // NUTRITION PLANS

  retrieveNutritionPlansForUser(user: User): Observable<boolean> {
    return new Observable((observer) => {
      user.nutritionPlans = []
      this.firestore.collection<any>('Users/' + user.uid + "/Shared/" + user.getLid() + "/NutritionPlans").ref.get().then(documents => {
        documents.forEach(document => {
          var deleted = document.data().deleted || false;
          if (!deleted) {
            var nutritionPlan = new NutritionPlan(document.data() as NutritionPlan)
            var date = new Date();
            date.setTime(document.data().startDate.seconds * 1000)
            nutritionPlan.startDate = date
            if (document.data().carbohydrates && document.data().protein && document.data().fat && document.data().calories) {
              nutritionPlan.referenceNutritionalGoal = new NutritionalGoal()
              nutritionPlan.referenceNutritionalGoal.carbohydrates = document.data().carbohydrates
              nutritionPlan.referenceNutritionalGoal.protein = document.data().protein
              nutritionPlan.referenceNutritionalGoal.fat = document.data().fat
              nutritionPlan.referenceNutritionalGoal.calories = document.data().calories
            } else {
              nutritionPlan.referenceNutritionalGoal = null
            }
            user.nutritionPlans.push(nutritionPlan)
          }
        });
        user.nutritionPlans.sort((a,b) => b.startDate.getTime() - a.startDate.getTime());
        observer.next(true)
        observer.complete()
      });
    })
  }

  retrieveNutritionPlanContent(nutritionPlan: NutritionPlan, user: User): Observable<boolean> {
    return new Observable((observer) => {
      nutritionPlan.days = []
      for (var dayNumber = 0; dayNumber < nutritionPlan.duration; dayNumber++) {
        var day = new Day()
        day.setDateAndNumber(nutritionPlan.startDate, dayNumber)
        day.initMeals(nutritionPlan.mealsPerDay)
        nutritionPlan.days.push(day)
      }
      this.firestore.collection<any>('Users/' + user.uid + '/Shared/' + user.getLid() + '/NutritionPlans/' + nutritionPlan.id + '/PlannedMeals/').ref.get().then(async documents => {
        for (let document of documents.docs) {
          if (document.data().deleted == null || !document.data().deleted) {
            var meal = new PlannedMeal(document.data() as PlannedMeal)
            meal.id = document.id
            meal.dayNumber = document.data().dayNumber
            meal.number = document.data().number
            if (meal.mealTemplateId) this.loadRecipeImageForMeal(meal)
            nutritionPlan.days[meal.dayNumber].plannedMeals.splice(meal.number, 1, meal)
            meal.foods = await this.retrieveNutritionPlanMeal(meal, day, nutritionPlan, user).toPromise()
          }
        }
        observer.next(true)
        observer.complete()
      })
    })
  }
  
  retrieveNutritionPlanMeal(meal: PlannedMeal, day: Day, nutritionPlan: NutritionPlan, user: User): Observable<PlannedFood[]> {
    return new Observable((observer) => {
      this.firestore.collection<any>('Users/' + user.uid + '/Shared/' + user.getLid() + '/NutritionPlans/' + nutritionPlan.id + '/PlannedMeals/' + meal.id + '/Foods/').ref.get().then(async documents => {
        var foods = []
        for (let document of documents.docs) {
          var food = new PlannedFood(document.data() as PlannedFood)
          food.nutritionFactId = document.data().baseNutritionFactId
          food.id = document.id
          food.weight = document.data().weight
          if (document.data().isDummy != null && document.data().isDummy == true) {
            food.isDummy = true;
          }
          foods.push(food)
          BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
            if (document.data()[nutritionalValue] != null && document.data()[nutritionalValue] != undefined && document.data()[nutritionalValue].toString().length > 0) {
              food.setNutritionalValue(nutritionalValue, parseFloat(document.data()[nutritionalValue]))
            } else {
              food.setNutritionalValue(nutritionalValue, null)
            }
          })
          if (food.nutritionFactId && (food.calories == null || food.calories == 0) && !food.isDummy) {
            food.nutritionFact = await this.userService.getBaseNutritionFactById(food.nutritionFactId).toPromise()
            food.recalculateNutritionalValues()
            var data = {name: food.nutritionFact?.nameDe}
            BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
              if (food.getNutritionalValue(nutritionalValue) != null && food.getNutritionalValue(nutritionalValue) != undefined) {
                data[nutritionalValue] = food.getNutritionalValue(nutritionalValue)
              }
            })
            this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/NutritionPlans/' + nutritionPlan.id + '/PlannedMeals/' + meal.id + '/Foods/').doc(food.id).set(data, {merge: true})
          }
        }
        observer.next(foods)
        observer.complete()
      })
    })
  }

  // NUTRITION PLAN TEMPLATES

  loadNutritionPlanTemplates() {
    this.nutritionPlanTemplates = []
    this.firestore.collection<any>('NutritionPlanTemplates/').ref.where('creatorUid', '==', this.userService.getLoggedInUser().licenceHolderUid || this.userService.getLoggedInUser().uid).get().then(documents => {
      documents.forEach(document => {
        var deleted = document.data().deleted || false;
        if (!deleted) {
          var nutritionPlan = new NutritionPlan(document.data() as NutritionPlan)
          nutritionPlan.isTemplate = true
          if (document.data().carbohydrates && document.data().protein && document.data().fat && document.data().calories) {
            nutritionPlan.referenceNutritionalGoal = new NutritionalGoal()
            nutritionPlan.referenceNutritionalGoal.carbohydrates = document.data().carbohydrates
            nutritionPlan.referenceNutritionalGoal.protein = document.data().protein
            nutritionPlan.referenceNutritionalGoal.fat = document.data().fat
            nutritionPlan.referenceNutritionalGoal.calories = document.data().calories
          } else {
            nutritionPlan.referenceNutritionalGoal = null
          }
          this.nutritionPlanTemplates.push(nutritionPlan)
          //if (loadContent) this.loadNutritionPlanTemplateContent(nutritionPlan)
        }
        this.nutritionPlanTemplates.sort((a,b) => a.name.localeCompare(b.name));
      });
    });
  }
  loadNutritionPlanTemplateContent(nutritionPlan: NutritionPlan): Observable<boolean> {
    return new Observable((observer) => {
      nutritionPlan.days = []
      for (var dayNumber = 0; dayNumber < nutritionPlan.duration; dayNumber++) {
        var day = new Day()
        day.setDateAndNumber(nutritionPlan.startDate, dayNumber)
        day.initMeals(nutritionPlan.mealsPerDay)
        nutritionPlan.days.push(day)
      }
      this.firestore.collection<any>('NutritionPlanTemplates/' + nutritionPlan.id + '/PlannedMeals/').ref.get().then(async documents => {
        for (let document of documents.docs) {
          if (document.data().deleted == null || !document.data().deleted) {
            var meal = new PlannedMeal(document.data() as PlannedMeal)
            meal.id = document.id
            meal.dayNumber = document.data().dayNumber
            meal.number = document.data().number
            if (meal.mealTemplateId) this.loadRecipeImageForMeal(meal)
            nutritionPlan.days[meal.dayNumber].plannedMeals.splice(meal.number, 1, meal)
            meal.foods = await this.loadNutritionPlanTemplateMeal(meal, nutritionPlan).toPromise()
          }
        }
        observer.next(true)
        observer.complete()
      })
    })
  }

  loadNutritionPlanTemplateMeal(meal: PlannedMeal, nutritionPlan: NutritionPlan): Observable<PlannedFood[]> {
    return new Observable((observer) => {
      this.firestore.collection<any>('NutritionPlanTemplates/' + nutritionPlan.id + '/PlannedMeals/' + meal.id + '/Foods/').ref.get().then(async documents => {
        var foods = []
        for (let document of documents.docs) {
          var food = new PlannedFood(document.data() as PlannedFood)
          food.nutritionFactId = document.data().baseNutritionFactId
          food.id = document.id
          food.weight = document.data().weight
          if (document.data().isDummy != null && document.data().isDummy == true) {
            food.isDummy = true;
          }
          foods.push(food)
          BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
            if (document.data()[nutritionalValue] != null && document.data()[nutritionalValue] != undefined && document.data()[nutritionalValue].toString().length > 0) {
              food.setNutritionalValue(nutritionalValue, parseFloat(document.data()[nutritionalValue]))
            } else {
              food.setNutritionalValue(nutritionalValue, null)
            }
          })
          if (food.nutritionFactId && (food.calories == null || food.calories == 0) && !food.isDummy) {
            food.nutritionFact = await this.userService.getBaseNutritionFactById(food.nutritionFactId).toPromise()
            food.recalculateNutritionalValues()
            var data = {name: food.nutritionFact?.nameDe}
            BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
              if (food.getNutritionalValue(nutritionalValue) != null && food.getNutritionalValue(nutritionalValue) != undefined) {
                data[nutritionalValue] = food.getNutritionalValue(nutritionalValue)
              }
            })
            this.firestore.collection('NutritionPlanTemplates/' + nutritionPlan.id + '/PlannedMeals/' + meal.id + '/Foods/').doc(food.id).set(data, {merge: true})
          }
        }
        observer.next(foods)
        observer.complete()
      })
    })
  }

  insertNutritionPlan(nutritionPlan: NutritionPlan, user: User, setupOnly: boolean): Observable<boolean> {
    return new Observable((observer) => {
      this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/NutritionPlans').add({name: nutritionPlan.name, duration: nutritionPlan.duration, startDate: nutritionPlan.startDate, mealsPerDay: nutritionPlan.mealsPerDay, customMealTypes: nutritionPlan.customMealTypes, distributed: false, applied: false, carbohydrates: nutritionPlan.referenceNutritionalGoal?.carbohydrates || null, protein: nutritionPlan.referenceNutritionalGoal?.protein || null, fat: nutritionPlan.referenceNutritionalGoal?.fat || null, calories: nutritionPlan.referenceNutritionalGoal?.calories || null}).then(res => {
        var id = res.id
        this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/NutritionPlans').doc(id).update({id: id}).then(async res => {
          nutritionPlan.id = id
          user.nutritionPlans.push(nutritionPlan)
          user.nutritionPlans.sort((a,b) => b.startDate.getTime() - a.startDate.getTime());
          if (!setupOnly) {
            var promises = []
            nutritionPlan.days.forEach(day => {
              day.plannedMeals.forEach(meal => {
                if (meal != null) {
                  meal.id = null
                  var promise = this.insertNutritionPlanMeal(meal, nutritionPlan, user).toPromise()
                  promises.push(promise)
                }
              })
            })
            await Promise.all(promises)
          }
          observer.next(true)
          observer.complete()
        })
      })
    })
  }
  insertNutritionPlanSetup(nutritionPlan: NutritionPlan, user: User) {
    this.insertNutritionPlan(nutritionPlan, user, true).toPromise().then()
  }
  updateNutritionPlanSetup(nutritionPlan: NutritionPlan, user: User) {
    if (nutritionPlan.id) {
      this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/NutritionPlans').doc(nutritionPlan.id).set({name: nutritionPlan.name, duration: nutritionPlan.duration, startDate: nutritionPlan.startDate, mealsPerDay: nutritionPlan.mealsPerDay, customMealTypes: nutritionPlan.customMealTypes, carbohydrates: nutritionPlan.referenceNutritionalGoal?.carbohydrates || null, protein: nutritionPlan.referenceNutritionalGoal?.protein || null, fat: nutritionPlan.referenceNutritionalGoal?.fat || null, calories: nutritionPlan.referenceNutritionalGoal?.calories || null, expiringNutritionPlanHintDismissed: nutritionPlan.expiringNutritionPlanHintDismissed}, {merge: true}).then(res => {
        user.nutritionPlans.forEach( (item, index) => {
          if (item.id === nutritionPlan.id) user.nutritionPlans.splice(index, 1, nutritionPlan);
        });
      });
    } else {
      this.insertNutritionPlanSetup(nutritionPlan, user)
    }
  }
  distributeNutritionPlan(nutritionPlan: NutritionPlan, user: User) {
    if (nutritionPlan.id) {
      this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/NutritionPlans').doc(nutritionPlan.id).set({distributed: true, applied: false}, {merge: true}).then(res => {
        nutritionPlan.distributed = true
        this.userService.sendPushNotification('NEW_ASSIGNMENTS', 'Du hast einen neuen Ernährungsplan erhalten!', 'Tippe, um ihn anzunehmen.', user)
        this.userService.createUserNotificationEntry(user, 'Neuer Ernährungsplan erhalten', 'Dein Coach hat dir einen neuen Ernährungsplan ab dem ' + nutritionPlan.startDate.asFormatedString() + ' erstellt.', 'newNutritionPlan', nutritionPlan.id, null)
      });
    }
  }
  deleteNutritionPlan(nutritionPlan: NutritionPlan, user: User) {
    this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/NutritionPlans').doc(nutritionPlan.id).update({deleted: true}).then(res => {
      user.nutritionPlans.forEach( (item, index) => {
        if (item.id === nutritionPlan.id) user.nutritionPlans.splice(index, 1);
      });
    })
  }
  insertNutritionPlanMeal(meal: PlannedMeal, nutritionPlan: NutritionPlan, user: User): Observable<boolean> {
    return new Observable((observer) => {
      this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/NutritionPlans/' + nutritionPlan.id + '/PlannedMeals/').add({dayNumber: meal.dayNumber, number: meal.number, name: meal.name, mealTemplateId: meal.mealTemplateId, comment: meal.comment, instructions: meal.instructions, note: meal.note, applied: false, handled: false}).then(async res => {
        meal.id = res.id
        await this.insertNutritionPlanMealContent(meal, nutritionPlan, user)
        observer.next(true)
        observer.complete()
      })
    })
  }
  updateNutritionPlanMeal(meal: PlannedMeal, nutritionPlan: NutritionPlan, user: User): Observable<boolean> {
    return new Observable((observer) => {
      this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/NutritionPlans/' + nutritionPlan.id + '/PlannedMeals/').doc(meal.id).set({dayNumber: meal.dayNumber, number: meal.number, name: meal.name, mealTemplateId: meal.mealTemplateId, comment: meal.comment, instructions: meal.instructions, note: meal.note, applied: false}, {merge: true}).then(res => {
        this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/NutritionPlans/' + nutritionPlan.id + '/PlannedMeals/' + meal.id + '/Foods/').ref.get().then(async documents => {
          documents.forEach(document => {
            var contains = false
            meal.foods.forEach(food => {
              if (food.id == document.id) contains = true
            })
            if (!contains) document.ref.delete()
          })
          await this.insertNutritionPlanMealContent(meal, nutritionPlan, user)
        })
        observer.next(true)
        observer.complete()
      })
    })
  }
  
  deleteNutritionPlanMeal(meal: PlannedMeal, nutritionPlan: NutritionPlan, user: User) {
    this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/NutritionPlans/' + nutritionPlan.id + '/PlannedMeals/').doc(meal.id).update({deleted: true, applied: false})
  }
  async insertNutritionPlanMealContent(meal: PlannedMeal, nutritionPlan: NutritionPlan, user: User): Promise<boolean> {
    for (let food of meal.foods) {
      food.recalculateNutritionalValues()
      var data = {
        name: food.getName(this.translate.currentLang),
        weight: food.weight,
        servingSize: food.servingSize,
        unit: food.unit,
        isDummy: food.isDummy
      }
      BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
        if (food.getNutritionalValue(nutritionalValue) != null && food.getNutritionalValue(nutritionalValue) != undefined) {
          data[nutritionalValue] = food.getNutritionalValue(nutritionalValue)
        }
      })
      if (food.id) {
        if (!food.isDummy) {
          data['baseNutritionFactId'] = food.nutritionFactId
          await this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/NutritionPlans/' + nutritionPlan.id + '/PlannedMeals/' + meal.id + '/Foods/').doc(food.id).set(data)
        } else {
          data['baseNutritionFactId'] = null
          data['name'] = food.name
          await this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/NutritionPlans/' + nutritionPlan.id + '/PlannedMeals/' + meal.id + '/Foods/').doc(food.id).set(data)
        }
      } else {
        if (!food.isDummy) {
          data['baseNutritionFactId'] = food.nutritionFactId
          await this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/NutritionPlans/' + nutritionPlan.id + '/PlannedMeals/' + meal.id + '/Foods/').add(data).then(res => {
            food.id = res.id
          })
        } else {
          data['baseNutritionFactId'] = null
          data['name'] = food.name
          await this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/NutritionPlans/' + nutritionPlan.id + '/PlannedMeals/' + meal.id + '/Foods/').add(data).then(res => {
            food.id = res.id
          })
        }
      }
    }
    return Promise.resolve(true)
  }

  async insertNutritionPlanTemplateMealContent(meal: PlannedMeal, nutritionPlan: NutritionPlan): Promise<boolean> {
    for (let food of meal.foods) {
      food.recalculateNutritionalValues()
      var data = {
        name: food.getName(this.translate.currentLang),
        weight: food.weight,
        servingSize: food.servingSize,
        unit: food.unit,
        isDummy: food.isDummy
      }
      BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
        if (food.getNutritionalValue(nutritionalValue) != null && food.getNutritionalValue(nutritionalValue) != undefined) {
          data[nutritionalValue] = food.getNutritionalValue(nutritionalValue)
        }
      })
      if (food.id) {
        if (!food.isDummy) {
          data['baseNutritionFactId'] = food.nutritionFactId
          await this.firestore.collection('NutritionPlanTemplates/' + nutritionPlan.id + '/PlannedMeals/' + meal.id + '/Foods/').doc(food.id).set(data)
        } else {
          data['baseNutritionFactId'] = null
          data['name'] = food.name
          await this.firestore.collection('NutritionPlanTemplates/' + nutritionPlan.id + '/PlannedMeals/' + meal.id + '/Foods/').doc(food.id).set(data)
        }
      } else {
        if (!food.isDummy) {
          data['baseNutritionFactId'] = food.nutritionFactId
          await this.firestore.collection('NutritionPlanTemplates/' + nutritionPlan.id + '/PlannedMeals/' + meal.id + '/Foods/').add(data).then(res => {
            food.id = res.id
          })
        } else {
          data['baseNutritionFactId'] = null
          data['name'] = food.name
          await this.firestore.collection('NutritionPlanTemplates/' + nutritionPlan.id + '/PlannedMeals/' + meal.id + '/Foods/').add(data).then(res => {
            food.id = res.id
          })
        }
      }
    }
    return Promise.resolve(true)
  }

  deleteNutritionPlanDay(day: Day, nutritionPlan: NutritionPlan, user: User) {
    this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/NutritionPlans/' + nutritionPlan.id + '/PlannedMeals/').ref.get().then(documents => {
      documents.forEach(document => document.ref.delete())
    })
  }

  updateNutritionPlanTemplateSetup(nutritionPlan: NutritionPlan) {
    if (nutritionPlan.id) {
      this.firestore.collection('NutritionPlanTemplates/').doc(nutritionPlan.id).set({name: nutritionPlan.name, duration: nutritionPlan.duration, mealsPerDay: nutritionPlan.mealsPerDay, customMealTypes: nutritionPlan.customMealTypes, carbohydrates: nutritionPlan.referenceNutritionalGoal?.carbohydrates || null, protein: nutritionPlan.referenceNutritionalGoal?.protein || null, fat: nutritionPlan.referenceNutritionalGoal?.fat || null, calories: nutritionPlan.referenceNutritionalGoal?.calories || null}, {merge: true}).then(res => {
      });
    }
  }
  insertNutritionPlanTemplate(nutritionPlan: NutritionPlan, setupOnly: boolean): Observable<boolean> {
    return new Observable((observer) => {
      this.firestore.collection('NutritionPlanTemplates/').add({name: nutritionPlan.name, isTemplate: nutritionPlan.isTemplate, creatorUid: this.userService.getLoggedInUser().licenceHolderUid || this.userService.getLoggedInUser().uid, duration: nutritionPlan.duration, mealsPerDay: nutritionPlan.mealsPerDay, customMealTypes: nutritionPlan.customMealTypes, carbohydrates: nutritionPlan.referenceNutritionalGoal?.carbohydrates || null, protein: nutritionPlan.referenceNutritionalGoal?.protein || null, fat: nutritionPlan.referenceNutritionalGoal?.fat || null, calories: nutritionPlan.referenceNutritionalGoal?.calories || null}).then(res => {
        var id = res.id
        this.firestore.collection('NutritionPlanTemplates/').doc(id).update({id: id}).then(async res => {
          nutritionPlan.id = id
          if (this.nutritionPlanTemplates) {
            this.nutritionPlanTemplates.push(nutritionPlan)
            this.nutritionPlanTemplates.sort((a, b) => a.name.localeCompare(b.name))
          }
          if (!setupOnly) {
            var promises = []
            nutritionPlan.days.forEach(day => {
              day.plannedMeals.forEach(meal => {
                if (meal != null) {
                  meal.id = null
                  var promise = this.insertNutritionPlanTemplateMeal(meal, nutritionPlan).toPromise()
                  promises.push(promise)
                }
              })
            })
            await Promise.all(promises)
          }
          observer.next(true)
          observer.complete()
        });
      });
    })
  }
  insertNutritionPlanTemplateMeal(meal: PlannedMeal, nutritionPlan: NutritionPlan): Observable<boolean> {
    return new Observable((observer) => {
      this.firestore.collection('NutritionPlanTemplates/' + nutritionPlan.id + '/PlannedMeals/').add({dayNumber: meal.dayNumber, number: meal.number, name: meal.name, mealTemplateId: meal.mealTemplateId, comment: meal.comment, instructions: meal.instructions, note: meal.note}).then(async res => {
        meal.id = res.id
        await this.insertNutritionPlanTemplateMealContent(meal, nutritionPlan)
        observer.next(true)
        observer.complete()
      })
    })
  }
  updateNutritionPlanTemplateMeal(meal: PlannedMeal, nutritionPlan: NutritionPlan): Observable<boolean> {
    return new Observable((observer) => {
      this.firestore.collection('NutritionPlanTemplates/' + nutritionPlan.id + '/PlannedMeals/').doc(meal.id).set({dayNumber: meal.dayNumber, number: meal.number, name: meal.name, mealTemplateId: meal.mealTemplateId, comment: meal.comment, instructions: meal.instructions, note: meal.note}, {merge: true}).then(res => {
        this.firestore.collection('NutritionPlanTemplates/' + nutritionPlan.id + '/PlannedMeals/' + meal.id + '/Foods/').ref.get().then(async documents => {
          documents.forEach(document => {
            var contains = false
            meal.foods.forEach(food => {
              if (food.id == document.id) contains = true
            })
            if (!contains) document.ref.delete()
          })
          await this.insertNutritionPlanTemplateMealContent(meal, nutritionPlan)
        })
        observer.next(true)
        observer.complete()
      })
    })
  }
  deleteNutritionPlanTemplateMeal(meal: PlannedMeal, nutritionPlan: NutritionPlan) {
    this.firestore.collection('NutritionPlanTemplates/' + nutritionPlan.id + '/PlannedMeals/').doc(meal.id).update({deleted: true})
  }
  deleteNutritionPlanTemplate(nutritionPlan: NutritionPlan) {
    this.firestore.collection('NutritionPlanTemplates/').doc(nutritionPlan.id).update({deleted: true}).then(res => {
      this.nutritionPlanTemplates.forEach( (item, index) => {
        if (item.id === nutritionPlan.id) this.nutritionPlanTemplates.splice(index, 1);
      });
    })
  }

  loadRecipeImageForMeal(meal: PlannedMeal) {
    this.fireStorage.ref("meal_templates/" + meal.mealTemplateId + "/thumbnail.jpg").getDownloadURL().subscribe(res => {
      meal.imageURL = res
    }, error => {})
  }

  async removeTagFromAllRecipes(tag: Tag){
    for(let recipe of this.recipes){
      if(recipe.matchesTag(tag)){
        recipe.removeTag(tag)
        await this.updateRecipe(recipe, this.userService.getLoggedInUser());
      }
    }
    this.customTags = this.customTags.filter(t => t.printableIdentifier != tag.printableIdentifier)
  }

}
