import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-affiliate-dialog',
  templateUrl: './affiliate-dialog.component.html',
  styleUrls: ['./affiliate-dialog.component.css']
})
export class AffiliateDialogComponent {

  public link: string

  constructor(public dialogRef: MatDialogRef<AffiliateDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { link: string }, public dialog: MatDialog, private toastr: ToastrService, private translate: TranslateService)
  {
    this.link = data.link
  }

  onLinkCopied() {
    this.toastr.success(this.translate.instant("Link in Zwischenablage kopiert"), "",  {
      positionClass: 'toast-bottom-center'
    })
  }

  onClose() {
    this.dialogRef.close();
  }
}
