import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.css']
})
export class SearchFieldComponent {

  @Input() additionalClasses: string = ''
  @Output() inputChanged = new EventEmitter<string>()
  public searchInput: string

  onSearchInputChanged(text: string) {
    this.searchInput = text
    this.inputChanged.emit(this.searchInput)
  }
  onDeleteSearchInput() {
    (<HTMLInputElement> document.getElementById('search-input')).value = '';
    this.onSearchInputChanged(null)
  }
}
