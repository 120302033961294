import { time } from 'console';
import { DailyCondition } from 'src/app/model/dailycondition.model';
import { TimezonePipe } from '../timezone.pipe';
import { User } from './user.model';
export class Note {
    id: string
    date: Date;
    content: string;
    attachedFileName: string;
    attachmentUrl: string;
    attachment: File
    isMainNote: boolean
    title: string
    voiceRecordingUrl: string;
    voiceRecordingFileName: string;

    tempVoiceRecordingFile: File = null;

    constructor();
    constructor(init: Note);
    constructor(init?: Note) {
        this.id = init && init.id || null
        this.date = init && init.date || null
        this.content = init && init.content || null
        this.attachedFileName = init && init.attachedFileName || null
        this.attachmentUrl = init && init.attachmentUrl || null
        this.isMainNote = init && init.isMainNote || false
        this.title = init && init.title || null
        this.voiceRecordingUrl = init && init.voiceRecordingUrl || null
        this.voiceRecordingFileName = init && init.voiceRecordingFileName || null
    }
    
    hasImageAttachment() {
        return this.attachedFileName != null && (this.attachedFileName.endsWith(".png") || this.attachedFileName.endsWith(".jpg"))
    }
    hasAttachment() {
        return this.attachedFileName != null
    }

    timezonePipe: TimezonePipe = new TimezonePipe();

    getPrintableDate(user: User) {
        let date = this.timezonePipe.transform(this.date, user);
        return (date.getDate() < 10 ? '0' : '') + date.getDate() + '.' + (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1) + '.' + date.getFullYear() + ', ' + (date.getHours() < 10 ? '0' : '') + date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
    }

    clone(): Note {
        var note = new Note()
        note.id = this.id
        note.date = this.date
        note.content = this.content
        note.attachedFileName = this.attachedFileName
        note.attachmentUrl = this.attachmentUrl
        note.attachment = this.attachment
        note.isMainNote = this.isMainNote
        note.title = this.title
        note.voiceRecordingUrl = this.voiceRecordingUrl
        note.voiceRecordingFileName = this.voiceRecordingFileName
        return note
    }

    getFirebaseMap() {
        return {
            date: this.date,
            content: this.content,
            attachedFileName: this.attachedFileName,
            voiceRecordingFileName: this.voiceRecordingFileName,
            title: this.title,
        }
    }
}