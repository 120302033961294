export interface CommunityModel {
  id: string;
  allowPostings: boolean;
  allowRecipeSharing: boolean;
  allowTrainingSharing: boolean;
  allowVoiceMessagesInPostings: boolean;
  allowFileSharing: boolean;
  coachUids: string[];
  description: string;
  imagePath: string;
  licenceHolderUid: string;
  name: string;
  timestamp: Date;
  selectedUserUids: string[];
  selectedGroupIds: string[];
  selectedGroupNames: string[];
  hideMemberList: boolean;
  deleted: boolean;
}

export class Community implements CommunityModel {
  public id: string
  public allowPostings: boolean;
  public allowRecipeSharing: boolean;
  public allowTrainingSharing: boolean;
  public allowVoiceMessagesInPostings: boolean;
  public allowFileSharing: boolean;
  public coachUids: string[];
  public description: string;
  public imagePath: string;
  public licenceHolderUid: string;
  public name: string;
  public timestamp: Date;
  public selectedUserUids: string[];
  public selectedGroupIds: string[];
  public selectedGroupNames: string[];
  public hideMemberList: boolean;
  public deleted: boolean;

  constructor();
  constructor(init: Community);
  constructor(init: CommunityModel);
  constructor(init?: Community | CommunityModel) {
    this.id = init?.id || '';
    this.allowPostings = init ? init.allowPostings : true;
    this.allowRecipeSharing = init ? init.allowRecipeSharing : true;
    this.allowTrainingSharing = init ? init.allowTrainingSharing : true;
    this.allowVoiceMessagesInPostings = init ? init.allowVoiceMessagesInPostings : true;
    this.allowFileSharing = init ? init.allowFileSharing : false;
    this.coachUids = init?.coachUids || [];
    this.description = init?.description || '';
    this.imagePath = init?.imagePath || null;
    this.licenceHolderUid = init?.licenceHolderUid || '';
    this.name = init?.name || '';
    this.timestamp = init?.timestamp || new Date();
    this.selectedUserUids = init?.selectedUserUids || [];
    // TODO fix when groups are ready: this.selectedGroupIds = init?.selectedGroupIds
    this.selectedGroupIds = init?.selectedGroupNames || [];
    this.selectedGroupNames = init?.selectedGroupNames || [];
    this.hideMemberList = init && init.hideMemberList || false;
    this.deleted = !!init?.deleted;
  }

  public toFirestore() {
    return {
      allowPostings: this.allowPostings,
      allowRecipeSharing: this.allowRecipeSharing,
      allowTrainingSharing: this.allowTrainingSharing,
      allowVoiceMessagesInPostings: this.allowVoiceMessagesInPostings,
      allowFileSharing: this.allowFileSharing,
      coachUids: this.coachUids,
      description: this.description,
      imagePath: this.imagePath,
      licenceHolderUid: this.licenceHolderUid,
      name: this.name,
      timestamp: new Date(), // ist ein modified date
      selectedUserUids: this.selectedUserUids,
      // TODO fix when groups are ready: selectedGroupNames: this.selectedGroupNames
      // selectedGroupIds: this.selectedGroupIds,
      selectedGroupNames: this.selectedGroupIds,
      hideMemberList: this.hideMemberList,
      deleted: this.deleted,
    }
  }
}
