import {Component, Input} from '@angular/core';
import {environment} from "../../../environments/environment";
import {MetricData} from "../../model/metricdata.model";

export enum GoalVisualizationOrigin {
  DIARY_WEEKLY = "diary_weekly",
  DIARY_DAILY = "diary_daily",
  QUESTIONAIRE = "questionaire",
}

export type GoalVisualizationConfig = {
  currentValue: number;
  minGoalValue: number;
  maxGoalValue: number;
  maxValue: number;
  info: string;
  metricData?: MetricData;
}


const mainColor: string = "var(--accentColor)";
const mainColorDark: string = "var(--accentColorDark)";
const bgColor: string = "var(--darkestBackgroundColor)";
const bgColorDark: string = "var(--darkestBackgroundColor)";
const rangeColor: string = "#cff785";

@Component({
  selector: 'app-goal-visualization',
  templateUrl: './goal-visualization.component.html',
  styleUrls: ['./goal-visualization.component.css']
})
export class GoalVisualizationComponent {
  get config(): GoalVisualizationConfig {
    return this._config;
  }

  @Input()
  set config(value: GoalVisualizationConfig) {
    this._config = value;
    this.mainColor = mainColor;
    this.bgColor = bgColor;
    this.rangeColor = rangeColor;

    if (value) {
      this.currentValue = value.currentValue;
      this.minimumRangeValue = value.minGoalValue;
      this.maximumRangeValue = value.maxGoalValue;
      this.maximumValue = value.maxValue;
      this.info = value.info;

      if (this.currentValue <= this.maximumRangeValue && this.currentValue >= this.minimumRangeValue) {
        this.mainColor = rangeColor;
        this.rangeColor = null;
      }

      if (this.currentValue > this.maximumValue) {
        this.mainColor = mainColorDark;
        this.bgColor = mainColor;
        this.currentValue = this.currentValue - this.maximumValue;
      }
    }
  }

  @Input()
  hideInfo: boolean;

  protected readonly math = Math;
  protected currentValue: number = 0;
  protected minimumRangeValue: number = 0;
  protected maximumRangeValue: number = 0;
  protected maximumValue: number = 0;
  protected colorCalorieGraph = environment.colorCalorieGraph;
  protected info: string = "";

  protected mainColor: string = mainColor;
  protected bgColor: string = bgColor;
  protected rangeColor: string = rangeColor;

  private _config: GoalVisualizationConfig;

  constructor() {

  }


  protected readonly Math = Math;
}

