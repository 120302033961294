import { UtilityService } from './../services/utility.service';
import { bindNodeCallback, Subscription, TimeInterval } from 'rxjs';
import { NavbarService } from 'src/app/services/navbar.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, ViewChild, OnDestroy, Output } from '@angular/core';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';
import { BroadcastChat, Chat } from '../model/chat.model';
import { Chatmessage } from '../model/chatmessage.model';
import { ChatService } from '../services/chat.service';
import { FirestoreService } from '../services/firestore.service';
// import { CachedImageComponent } from '../cached-image/cached-image.component';
import { ActivatedRoute, NavigationStart } from '@angular/router';
import { FirestoreNutritionPlanService } from '../services/firestore-nutritionplan.service';
import {MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AudioRecorder } from '../audio-recorder';
import { KeepAwake } from '@capacitor-community/keep-awake';

import lame from 'lamejs';
import { User } from '../model/user.model';
import { Coach } from '../model/coach.model';
import { FileSharingDialogComponent } from '../dialogs/file-sharing-dialog/file-sharing-dialog.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MetricDataImageDialogComponent } from '../metric-data-image-dialog/metric-data-image-dialog.component';
import { LoggingService } from '../services/logging.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'single-chat',
  templateUrl: './single-chat.component.html',
  styleUrls: ['./single-chat.component.css'],
  animations : [
    trigger('panelState', [
      state('true', style({ height: '0px', overflow: 'hidden' })),
      state('false', style({ height: ('{{pixelHeight}}') }), {params: {pixelHeight: '*'}}),
      transition('true <=> false', animate('300ms ease-in-out')),
    ]),
  ],
})
export class SingleChatComponent implements OnInit, OnDestroy {

  @Input() chat: Chat;
  @Input() inputDisabled: boolean = false;


  get message(): string {
    return this.chat?.tempMessage || ""
  }
  set message(value: string){
      if(this.chat){
          this.chat.tempMessage = value
      }
  }

  get selectedFiles(): File[] {
    return this.chat?.tempSelectedFiles;
  }
  set selectedFiles(value: File[]){
      if(this.chat){
          this.chat.tempSelectedFiles = value
      }
  }

  get voiceRecordingFile(): File {
    return this.chat?.tempVoiceRecordingFile
  }
  set voiceRecordingFile(value: File){
      if(this.chat){
          this.chat.tempVoiceRecordingFile = value
      }
  }
  
  public uid: string;

  public id: number;

  private innerChat: HTMLElement;

  private limit = 20;

  private subscriptionMessages0: Subscription;

  private scrolling = false;

  public audioRecorder: AudioRecorder;
  
  public isBusy: boolean = false;

  public get messages() {
    return this.chat.messages
  }


  public get isFolded(): boolean {
    return this.canFold() && this.chat.tempIsFolded;
  }
  public set isFolded(value: boolean) {
    this.chat.tempIsFolded = value;
  }

  public canFold(): boolean {
    return !this.utilityService.onMobile();
  }
  
  private subscriptionCoaches: Subscription
  public selectableCoaches: Coach[] = []

  constructor(public userService: FirestoreService, private authService: AuthService, public chatService: ChatService, private imageCompressor: NgxImageCompressService, public dialog: MatDialog, private toastrService: ToastrService, public utilityService: UtilityService, private toastr: ToastrService, loggingService: LoggingService, public translate: TranslateService) 
  {
    this.audioRecorder = new AudioRecorder(600, toastrService, utilityService, loggingService, translate);
  }

  ngOnInit(): void {
    this.uid = this.authService.user.uid;
    this.id = Math.random() * 1000;    
    this.loadCoaches()

    this.audioRecorder?.reachedMaxSeconds?.subscribe(async (seconds) => {
      if(seconds > 0){
        await this.stopAudioRecorder()
        this.chatService.showToastMessage(this.translate.instant('Maximale Aufnahmelänge erreicht.'))
      }
    })
    /*if(!this.loading && this.messages.length < this.limit){
      this.loadNextMessages();
    }*/
   
    this.loadNextMessages()

    this.userService.loadLicenceSettingsForUser(this.user)

    this.userService.observableClients.subscribe(clients => {
      if(this.user){
        this.userService.loadLicenceSettingsForUser(this.user)
      }
    })
  }

  async ngOnDestroy(): Promise<void>{
    this.audioRecorder?.Cancel()
    
    if(this.utilityService.onNativeMobileApp() && await KeepAwake.isSupported()) {
      await KeepAwake.allowSleep();
    }

    this.subscriptionMessages0?.unsubscribe();
  }

  get user(): User {
    return this.userService.getClient(this.chat.chatPartner.uid) ?? this.chat.chatPartner
  }

  showHideChatSpinner: boolean = false
  isHideChatEnabled() {
    return this.chat.chatPartner.hideChat ?? this.userService.getClient(this.chat.chatPartner.uid).hideChat
  }
  async onHideChatChanged(value: boolean) {
    this.showHideChatSpinner = true
    this.user.hideChat = value
    await this.userService.updateLicenceSettingsChat(this.user)
    this.userService.loadLicenceSettingsForUser(this.userService.getClient(this.chat.chatPartner.uid))
    this.delay(500).then(() => {
      this.showHideChatSpinner = false
    })
  }

  showEnableVoiceMessageSpinner: boolean = false
  isVoiceMessageEnabled() {
    if (this.user.coacheeVoiceMessageEnabled != null) return this.user.coacheeVoiceMessageEnabled
    return this.userService.getLoggedInUser()?.coach?.coacheeVoiceMessageEnabled
  }
  async onEnableVoiceMessageChanged(value: boolean) {
    this.showEnableVoiceMessageSpinner = true
    this.user.coacheeVoiceMessageEnabled = value
    await this.userService.updateLicenceSettingsChat(this.user)
    this.userService.loadLicenceSettingsForUser(this.userService.getClient(this.chat.chatPartner.uid))
    this.delay(500).then(() => {
      this.showEnableVoiceMessageSpinner = false
    })
  }

  getBroadcastChat(chat: Chat){
    return chat as BroadcastChat;
  }

  async scrollToBottom(delay: number) {
    await this.delay(delay);
    this.innerChat = document.getElementById(this.id + "-innerchat");
    this.innerChat.scrollTop = this.innerChat.scrollHeight;
  }

  async sendMessage() {
    if(this.inputDisabled) return;
    this.isBusy = true;
    if (this.message.replace(/\s/g, '').length > 0 || this.voiceRecordingFile || this.selectedFiles?.length > 0) {
      if (this.message.endsWith('\n')) this.message = this.message.substr(0, this.message.length - 1)
      if(this.chat.isBroadcastChat) {
        let broadcastChat = this.chat as BroadcastChat;
        let sentChats: Chat[] = []
        for (let index = 0; index < this.chatService.getChats().length; index++) {
          const chat = this.chatService.getChats()[index];
          if(!sentChats.includes(chat) && broadcastChat.selectedClientGroups.includes('Alle') || broadcastChat.selectedChats.includes(chat) || broadcastChat.selectedClientGroups.filter(x => chat.chatPartner.metadataUser?.assignedClientGroups.includes(x)).length > 0 || broadcastChat.selectedCoaches.filter(x => x.uid == chat.chatPartner.coachUid).length > 0) {
            await this.sendChatMessage(chat)
            sentChats.push(chat)
          }
        }
        for (let chat of broadcastChat.selectedChats) {
          if(!sentChats.includes(chat)) {
            await this.sendChatMessage(chat)
            sentChats.push(chat)
          }
        }
        if(sentChats.length === 0) {
          this.chatService.showToastMessage(this.translate.instant("Bitte wähle mindestens einen Empfänger."))
        }
        else {
          this.chatService.onCloseChat(this.chat)
        }
      }
      else {
        await this.sendChatMessage(this.chat)
      }
      this.message = ""
      this.selectedFiles = []
      this.voiceRecordingFile = null
      this.scrollToBottom(500);
      this.manuallyScrolled = false;
    }
    this.isBusy = false;
  }

  private async sendChatMessage(chat: Chat){
    if(this.inputDisabled) return;
    var message = new Chatmessage()
    message.message = this.replaceAll(this.message, '{{Name}}', chat?.chatPartner?.getFirstName())
    message.chatId = chat.chatId
    if (this.voiceRecordingFile) {
      message.attachedFileName = this.voiceRecordingFile.name
      message.attachment = this.voiceRecordingFile
    } else if (this.selectedFiles?.length > 0) {
      let filenameExtension = this.selectedFiles[this.selectedFiles.length - 1].name.split('.').pop();
      if(filenameExtension){
        message.attachedFileName = this.selectedFiles[this.selectedFiles.length - 1].name.replace('.' + filenameExtension, '_' + FirestoreNutritionPlanService.generateUniqueString() + '.' + filenameExtension.toLowerCase())
        message.attachment = this.selectedFiles[this.selectedFiles.length - 1]
      }
      if (this.selectedFiles.length > 1) {
        for(let i = 0; i < this.selectedFiles.length - 1; i++) {
          let newMessage = new Chatmessage()
          newMessage.message = ''
          newMessage.chatId = chat.chatId
          let filenameExtension = this.selectedFiles[i].name.split('.').pop();
          if(filenameExtension){
            newMessage.attachedFileName = this.selectedFiles[i].name.replace('.' + filenameExtension, '_' + FirestoreNutritionPlanService.generateUniqueString() + '.' + filenameExtension.toLowerCase())
            newMessage.attachment = this.selectedFiles[i]
          }
          await this.userService.sendChatMessage(newMessage, this.userService.getLoggedInUser(), chat.chatPartner);
        }
      }
    }
    await this.userService.sendChatMessage(message, this.userService.getLoggedInUser(), chat.chatPartner);
  }

  private replaceAll(inputString: string, find: string, replaceString: string): string {
    return inputString.replace(new RegExp(find, 'g'), replaceString);
  }

  private manuallyScrolled: boolean = false;
  public loading: boolean = false;
  async onScroll(event) {
    this.manuallyScrolled = true;
    if(!this.loading && (event.target.offsetHeight + event.target.offsetTop + Math.abs(event.target.scrollTop)) >= event.target.scrollHeight){
      this.limit += 10;
      this.scrolling = true;
      this.loadNextMessages();
      // await this.delay(200);
    }
  }

  loadNextMessages() {
    if (this.chat.isBroadcastChat) return
    if (this.loading || !this.chat.hasMoreMessages) return
    
    console.log("loadNextMessages", this.chat.chatId, this.chat.getLatestMessage()?.time)

    this.loading = true
    this.userService.getChatMessagesAfter(this.chat.chatId, this.messages.length > 0 ? this.messages[this.messages.length - 1].time : null, 20)?.then(messages => {
      if (messages.length == 0) {
        this.chat.hasMoreMessages = false
        this.loading = false
        return
      }
      messages.forEach(message => {
          message.chatId = this.chat.chatId
          message.time = new Date((message as any).time.seconds * 1000)
          message.isFromPartner = this.chat.chatPartner.uid == message.uid
      });
      this.userService.loadChatMessageContents(messages, this.chat.chatPartner)
      this.chat.messages.push(...messages)
      
      this.loading = false
    })
  }

  onOpenReferencedSharedFile(message: Chatmessage) {
    const dialogRef = this.dialog.open(FileSharingDialogComponent, {width: '1000px', panelClass: 'file-manager', data: { user: this.chat.chatPartner, referencedSharedFileId: message.sharedFileId }});
    if(!this.audioRecorder?.IsRecording()) {
      if(this.canFold()) {
        this.isFolded = true;
      }
      else {
        this.closeChat();
      }
    }
  }


  async toggleAudioRecording(e){
    if(this.inputDisabled) return;
    e.preventDefault();
    if(this.audioRecorder.IsRecording()){
      await this.stopAudioRecorder()
      await this.sendMessage()

      if(this.utilityService.onNativeMobileApp() && await KeepAwake.isSupported()) {
        await KeepAwake.allowSleep()
      }
    }
    else{
      if(this.utilityService.onNativeMobileApp() && await KeepAwake.isSupported()) {
        await KeepAwake.keepAwake();
      }
      await this.audioRecorder.Start()
    }
  }

  
  private async stopAudioRecorder(){
    if(this.inputDisabled) return;
    if(this.audioRecorder.elapsedSeconds > 0){
      this.isBusy = true
      try{
        this.voiceRecordingFile = await this.audioRecorder.Stop()
      }
      catch(e) {
        console.log(e)
        this.chatService.showToastMessage(this.translate.instant("Es ist ein unbekannter Fehler aufgetreten."))
      }
      this.isBusy = false
    }
    else {
      this.audioRecorder.Cancel()
    }
    
    if(this.utilityService.onNativeMobileApp() && await KeepAwake.isSupported()) {
      await KeepAwake.allowSleep();
    }
  }

  async cancelAudioRecording(){
    if(this.inputDisabled) return;
    this.audioRecorder.Cancel()
    this.voiceRecordingFile = null

    
    if(this.utilityService.onNativeMobileApp() && await KeepAwake.isSupported()) {
      await KeepAwake.allowSleep();
    }
  }

  private timeoutHandler: NodeJS.Timeout = null;
  private pressAndHold: boolean = false;

  public async touchendAudioRecording(e) {
    if(this.inputDisabled) return;
    // e.preventDefault();
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
      this.timeoutHandler = null;
    }
    if(this.audioRecorder.IsRecording()){
      await this.stopAudioRecorder();
      if(!this.pressAndHold) await this.sendMessage()
    }
    else {
      if(this.utilityService.onNativeMobileApp()) {
        if(await KeepAwake.isSupported()) {
          await KeepAwake.keepAwake();

        }
      }
      await this.audioRecorder.Start()
    }
  }


  public touchstartAudioRecording(e) {
    if(this.inputDisabled) return;
    // e.preventDefault();
    this.pressAndHold = false
    this.timeoutHandler = setTimeout(async () => {
      this.pressAndHold = true;
      clearTimeout(this.timeoutHandler);
      this.timeoutHandler = null;
      await this.audioRecorder.Start()
      
      if(this.utilityService.onNativeMobileApp()) {
        if(await KeepAwake.isSupported()) {
          await KeepAwake.keepAwake();
        }
      }
    }, 250);
  }

  closeChat() {
    if(this.inputDisabled) return;
    this.audioRecorder.Cancel()
    this.chatService.onCloseChat(this.chat)
  }

  foldChat() {
    if(this.inputDisabled) return;
    if(this.canFold()){
      this.isFolded = !this.isFolded;
    }
    else {
      this.closeChat();
    }
  }

  onClientRoute() {
    if(this.inputDisabled) return;
    if(!this.canFold()){
      this.closeChat();
    }
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  onSelectAttachment() {
    document.getElementById('input-chat-attachment-' + this.chat?.chatId).click()
  }

  onRemoveSelectedFiles() {
    this.selectedFiles = [];
  }

  getAttachementString(selectedFiles: File[]){
    if(selectedFiles.length == 1){
      return selectedFiles[0].name
    }
    else if(selectedFiles.length > 1){
      return selectedFiles.length + ' ' + this.translate.instant('Dateien ausgewählt')
    }
    return ''
  }

  getAttachedFileTooltip(selectedFiles: File[]){
    let tooltip: string = "";
    selectedFiles?.forEach(file => {
      tooltip += file.name + '\n'
    });
    return tooltip;
  }
  
  onAttachmentSelected(e) {
    let selectedFiles = e.target.files;
    this.selectedFiles = [];
    for (let index = 0; index < selectedFiles.length; index++) {
      let file = selectedFiles[index];
      if (selectedFiles[index].isImage()) {
        let image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = async () => {
          var maxDimension = Math.max(image.width, image.height)
          var scaleRatio = Math.min(100, 1080 / maxDimension * 100)
          let compressedFile = await this.imageCompressor.compressFile(image.src, DOC_ORIENTATION.Up, scaleRatio, 60);
          this.selectedFiles.push(this.dataURLtoFile(compressedFile, file.name));

          // this.imageCompressor.compressFile(image.src, DOC_ORIENTATION.Up, scaleRatio, 60).then((compressedFile) => {
          //   this.selectedFiles.push(this.dataURLtoFile(compressedFile, file.name));
          // })
        }
      }
      else if(file.name.toLowerCase().endsWith('.pdf')){
        this.selectedFiles.push(file);
      }
      else {
        this.chatService.showToastMessage(this.translate.instant("Es werden nur Bild- und PDF-Dateien unterstützt."))
      }
    }
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }

  onOpenAttachedImage(imageURL: string) {
    const dialogRef = this.dialog.open(MetricDataImageDialogComponent, { data: { imageURL: imageURL}, 
      panelClass: 'chat-cdk-overlay-container'});
  }

  public isEmojiPickerVisible: boolean = false;
  handleEmojiSelection(event) {
    this.message += event.emoji.native;
    this.isEmojiPickerVisible = false;
  }


  async onDeleteMessage(message: Chatmessage) {
    if(this.inputDisabled) return;
    message.audioPlayerRef?.pause()
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: this.translate.instant('Möchtest du diese Nachricht wirklich für alle löschen?'), title: this.translate.instant('Nachricht löschen') },
      panelClass: 'chat-cdk-overlay-container'
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        await this.userService.deleteMessage(message)
      }
    })
  }

  async onCopyMessage(message: Chatmessage){
    if(this.inputDisabled) return;
    await navigator.clipboard.writeText(message.message)
    this.toastr.success(this.translate.instant('Nachricht in Zwischenablage kopiert.'), "",  {
      positionClass: 'toast-bottom-center'
    });
  }


  loadCoaches() {
    if (this.subscriptionCoaches) this.subscriptionCoaches.unsubscribe()
    this.subscriptionCoaches = this.userService.getAllCoachesByLicenceHolderUid(this.userService.getLoggedInUser().coach.licenceHolderUid).subscribe(coaches => {
      this.selectableCoaches = coaches
    })
  }

  addChatToBroadcast(chat: Chat){
    if(this.chat.isBroadcastChat) {
      let broadcastChat = this.chat as BroadcastChat;
      if(broadcastChat?.selectedChats?.includes(chat)) {
        let index = broadcastChat.selectedChats.indexOf(chat)
        broadcastChat.selectedChats.splice(index, 1); 
      }
      else{
        broadcastChat.selectedChats.push(chat)
      }
    }
  }

  addClientGroupToBroadcast(clientGroup: string) {
    if(this.chat.isBroadcastChat) {
      let broadcastChat = this.chat as BroadcastChat;
      if(broadcastChat?.selectedClientGroups?.includes(clientGroup)) {
        let index = broadcastChat.selectedClientGroups.indexOf(clientGroup)
        broadcastChat.selectedClientGroups.splice(index, 1); 
      }
      else{
        broadcastChat.selectedClientGroups.push(clientGroup)
      }
    }
  }
  addCoachToBroadcast(coach: Coach) {
    if(this.chat.isBroadcastChat) {
      let broadcastChat = this.chat as BroadcastChat;
      if(broadcastChat?.selectedCoaches?.includes(coach)) {
        let index = broadcastChat.selectedCoaches.indexOf(coach)
        broadcastChat.selectedCoaches.splice(index, 1); 
      }
      else{
        broadcastChat.selectedCoaches.push(coach)
      }
    }
  }

  getClientsForSelectedGroup(clientGroup: string): User[] {
    return this.userService.getAccessibleClients().filter(client => client.metadataUser?.assignedClientGroups.includes(clientGroup))
  }

  getPrintableBroadcastTarget(): string {
    var checkedElements = '';
    // if(notification == null){
    //   return ''
    // }
    let broadcastChat = this.getBroadcastChat(this.chat);
    if(broadcastChat.selectedCoaches != null){
      broadcastChat.selectedCoaches.forEach(coach => {
        if (coach) {
          if(checkedElements.length > 0){
            checkedElements = checkedElements.concat(', ')
          }
            checkedElements = checkedElements.concat(this.translate.instant("{{coachNameParameter}}'s Kunden", { coachNameParameter: coach.name }));
        }
      });
    }
    if(broadcastChat.selectedClientGroups != null){
      if (broadcastChat.selectedClientGroups?.includes('Alle')) return 'Alle'
      broadcastChat.selectedClientGroups.forEach(name => {
        if(checkedElements.length > 0){
          checkedElements = checkedElements.concat(', ')
        }
        checkedElements = checkedElements.concat(name);
      });
    }
    if (broadcastChat.selectedChats != null){
      broadcastChat.selectedChats.forEach(element => {
        if(checkedElements.length > 0){
          checkedElements = checkedElements.concat(', ')
        }
        checkedElements = checkedElements.concat(element.chatPartner.getName());
      });
    }
    return checkedElements;
  }

  areAllUsersTargeted(): boolean {
    return this.getBroadcastChat(this.chat).selectedClientGroups?.includes('Alle')
  }
  canAccessGroup(group: string) {
    return this.userService.getLoggedInUser().coach.canAccessClientGroup(group)
  }

  public MessagePlaceHolder = MessagePlaceHolder;

  placeHolderSelected(placeHolder: MessagePlaceHolder) {
    if(placeHolder == MessagePlaceHolder.Name) {
      this.message += "{{Name}}"
    }
  }
}

export enum MessagePlaceHolder {
  Name,
}