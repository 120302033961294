import { LanguageDictionary } from './languagedictionary.model';
import { SetParameter, SetParameter2LanguageDictionary } from './training-plan.model';

export class MuscleInformation<Type> extends LanguageDictionary<Type>{

    constructor(latin?: string, de?: string, en?: string, originObject?:Type, es?: string, fr?: string) {
        super(de, en, originObject, es, fr);
        this.latin = latin
    }

    latin: string = '';
    
    clone():MuscleInformation<Type> {
        return new MuscleInformation(this.latin, this.de, this.en, this.originObject, this.es, this.fr)
    }
}

export const ExerciseTypeTranslation: Map<string, LanguageDictionary<string>> = new Map<string, LanguageDictionary<string>>([
    ["compound", new LanguageDictionary<string>("Mehrgelenksübung", "Compound exercise", "compound", "ejercicio compuesto", "exercice composé")],
    ["isolation", new LanguageDictionary<string>("Isolationsübung", "Isolation exercise", "isolation", "ejercicio de aislamiento", "exercice d'isolement")],
    ["bodyweight", new LanguageDictionary<string>("Körpergewicht", "Bodyweight", "bodyweight", "peso corporal", "poids du corps")],
    ["mobility", new LanguageDictionary<string>("Mobility", "Mobility", "mobility", "movilidad", "mobilité")],
    ["warmup", new LanguageDictionary<string>("Warm-up", "Warm up", "warmup", "calentamiento", "échauffement")],
    ["endurance", new LanguageDictionary<string>("Ausdauer", "Endurance", "endurance", "resistencia", "endurance")],
    ["coordination", new LanguageDictionary<string>("Koordination", "Coordination", "coordination", "coordinación", "coordination")],
    ["fascia", new LanguageDictionary<string>("Faszientraining", "Fascia training", "fascia", "entrenamiento de fascia", "entraînement de fascia")],
    ["boxing", new LanguageDictionary<string>("Boxen", "Boxing", "boxing", "boxeo", "boxe")],
]);

export const MovementTypeMap: Map<string, LanguageDictionary<string>> = new Map<string, LanguageDictionary<string>>([
    ["squat", new LanguageDictionary<string>("Squat", "Squat", "squat", "sentadilla", "squat")],
    ["lunge", new LanguageDictionary<string>("Lunge", "Lunge", "lunge", "zancada", "fente")],
    ["press", new LanguageDictionary<string>("Press", "Press", "press", "presión", "presse")],
    ["row", new LanguageDictionary<string>("Row", "Row", "row", "remo", "rame")],
    ["bend", new LanguageDictionary<string>("Bend", "Bend", "bend", "flexión", "flexion")],
    ["core", new LanguageDictionary<string>("Core", "Core", "core", "núcleo", "noyau")],
    ["push", new LanguageDictionary<string>("Push", "Push", "push", "empujar", "pousser")],
    ["pull", new LanguageDictionary<string>("Pull", "Pull", "pull", "tirar", "tirer")],
    ["mobility", new LanguageDictionary<string>("Mobility", "Mobility", "mobility", "movilidad", "mobilité")],
]);

export const EquipmentTranslation: Map<string, LanguageDictionary<string>> = new Map<string, LanguageDictionary<string>>([
    ["barbell", new LanguageDictionary<string>("Langhantel", "Barbell", "barbell", "barra olímpica", "barre")],
    ["dumbbell", new LanguageDictionary<string>("Kurzhantel", "Dumbbell", "dumbbell", "mancuerna", "haltère")],
    ["multipress", new LanguageDictionary<string>("Multipresse", "Smith machine", "multipress", "máquina Smith", "machine Smith")],
    ["cable", new LanguageDictionary<string>("Kabelzug", "Cable", "cable", "polea", "câble")],
    ["machine", new LanguageDictionary<string>("Maschine", "Machine", "machine", "máquina", "machine")],
    ["kettlebell", new LanguageDictionary<string>("Kettlebell", "Kettlebell", "kettlebell", "pesa rusa", "kettlebell")],
    ["band", new LanguageDictionary<string>("Band", "Resistance band", "band", "banda elástica", "élastique")],
    ["plate", new LanguageDictionary<string>("Gewichtsscheibe", "Plate", "plate", "disco de pesas", "disque")],
    ["ez_bar", new LanguageDictionary<string>("SZ-Stange", "EZ bar", "ez_bar", "barra EZ", "barre EZ")],
    ["bench", new LanguageDictionary<string>("Bank", "Bench", "bench", "banco", "banc")],
    ["wrist_roller", new LanguageDictionary<string>("Wrist Roller", "Wrist roller", "wrist_roller", "rodillo de muñeca", "roue à poignet")],
    ["ball", new LanguageDictionary<string>("Medizinball", "Ball", "ball", "balón medicinal", "balle")],
    ["rings", new LanguageDictionary<string>("Ringe", "Rings", "rings", "anillas", "anneaux")],
    ["box", new LanguageDictionary<string>("Box", "Box", "box", "caja", "boîte")],
    ["trap_bar", new LanguageDictionary<string>("Trap Bar", "Trap bar", "trap_bar", "barra hexagonal", "barre trapèze")],
    ["pvc", new LanguageDictionary<string>("PVC-Stab", "PVC", "pvc", "barra de PVC", "barre en PVC")],
    ["trx", new LanguageDictionary<string>("TRX", "TRX", "trx", "TRX", "TRX")],
    ["sled", new LanguageDictionary<string>("Schlitten", "Sled", "sled", "trineo", "traîneau")],
    ["battle_rope", new LanguageDictionary<string>("Battle Ropes", "Battle ropes", "battle_rope", "cuerdas de combate", "cordes de bataille")],
    ["pullup_bar", new LanguageDictionary<string>("Klimmzugstange", "Pull-up bar", "pullup_bar", "barra de dominadas", "barre de traction")],
    ["dip_bars", new LanguageDictionary<string>("Dip Barren", "Dip bars", "dip_bars", "barras paralelas", "barres parallèles")],
    ["step_board", new LanguageDictionary<string>("Stepboard", "Step board", "step_board", "plataforma de step", "plaque de step")],
    ["fascia_ball", new LanguageDictionary<string>("Faszienball", "Fascia ball", "fascia_ball", "pelota de fascia", "balle de fascia")],
    ["fascia_roll", new LanguageDictionary<string>("Faszienrolle", "Fascia roll", "fascia_roll", "rodillo de fascia", "rouleau de fascia")],
    ["gymnasic_ball", new LanguageDictionary<string>("Gymnastikball", "Gymnastic ball", "gymnasic_ball", "pelota de gimnasia", "balle de gymnastique")],
    ["bosu_ball", new LanguageDictionary<string>("Bosu-Ball", "Bosu ball", "bosu_ball", "Bosu ball", "Bosu ball")],
    ["other", new LanguageDictionary<string>("Sonstiges", "Other", "other", "otro", "autre")],
]);

export const MuscleTranslation: Map<string, MuscleInformation<string>> = new Map<string, MuscleInformation<string>>([
    ["adductor", new MuscleInformation<string>("", "Adduktor", "Adductor", "adductor", "Aductor", "Adducteur")],
    ["abductors", new MuscleInformation<string>("", "Abduktor", "Abductors", "abductors", "Abductores", "Abducteurs")],
    ["chest_focusupperpart", new MuscleInformation<string>("Musculus pectoralis major", "Großer Brustmuskel (Fokus oberer Teil)", "Chest (Focus - upper part)", "chest_focusupperpart", "Pecho (Foco - parte superior)", "Poitrine (Focaliser - partie supérieure)")],
    ["chest_focuslowerpart", new MuscleInformation<string>("Musculus pectoralis major", "Großer Brustmuskel (Fokus unterer Teil)", "Chest (Focus - lower part)", "chest_focuslowerpart", "Pecho (Foco - parte inferior)", "Poitrine (Focaliser - partie inférieure)")],
    ["chest_focusmiddlepart", new MuscleInformation<string>("Musculus pectoralis major", "Großer Brustmuskel (Fokus mittlerer Teil)", "Chest (Focus - middle part)", "chest_focusmiddlepart", "Pecho (Foco - parte intermedia)", "Poitrine (Focaliser - partie intermédiaire)")],
    ["chest_large", new MuscleInformation<string>("Musculus pectoralis major", "Großer Brustmuskel", "Large Chest muscle", "chest_large", "Pecho grande", "Grand muscle de la poitrine")],
    ["chest_small", new MuscleInformation<string>("Musculus pectoralis minor", "Kleiner Brustmuskel", "Small Chest muscle", "chest_small", "Pecho pequeño", "Petit muscle de la poitrine")],
    ["shoulder", new MuscleInformation<string>("Musculus deltoideus", "Schulter", "Shoulder", "shoulder", "Hombro", "Épaule")],
    ["shoulder_focusfrontpart", new MuscleInformation<string>("Musculus deltoideus", "Schulter (Fokus vorderer Teil)", "Shoulder (Focus - front part)", "shoulder_focusfrontpart", "Hombro (Foco - parte frontal)", "Épaule (Focaliser - partie avant)")],
    ["shoulder_focussidepart", new MuscleInformation<string>("Musculus deltoideus", "Schulter (Fokus seitlicher Teil)", "Shoulder (Focus - side part)", "shoulder_focussidepart", "Hombro (Foco - parte lateral)", "Épaule (Focaliser - partie latérale)")],
    ["shoulder_focusbackpart", new MuscleInformation<string>("Musculus deltoideus", "Schulter (Fokus hinterer Teil)", "Shoulder (Focus - back part)", "shoulder_focusbackpart", "Hombro (Foco - parte trasera)", "Épaule (Focaliser - partie arrière)")],
    ["biceps", new MuscleInformation<string>("Musculus biceps brachii", "Bizeps", "Biceps", "biceps", "Bíceps", "Biceps")],
    ["deltoid", new MuscleInformation<string>("Musculus deltoideus", "Deltamuskel", "Deltoid", "deltoid", "Deltoides", "Deltoïde")],
    ["handflexor_ulnaris", new MuscleInformation<string>("Musculus flexor carpi ulnaris", "Ellenseitiger Handbeuger", "Hand flexor", "handflexor_ulnaris", "Flexor ulnar de la muñeca", "Fléchisseur du poignet (ulnaire)")],
    ["handflexor_radialis", new MuscleInformation<string>("Musculus flexor carpi radialis", "Speichenseitiger Handbeuger", "Hand flexor", "handflexor_radialis", "Flexor radial de la muñeca", "Fléchisseur du poignet (radial)")],
    ["abdominalmuscles_oblique", new MuscleInformation<string>("Musculus obliquus externus abdominis", "Schräge Bauchmuskulatur", "Oblique abdominal muscles", "abdominalmuscles_oblique", "Músculos abdominales oblicuos", "Muscles abdominaux obliques")],
    ["abdominalmuscles_straight", new MuscleInformation<string>("Musculus rectus abdominis", "Gerade Bauchmuskulatur", "Straight abdominal muscles", "abdominalmuscles_straight", "Músculos abdominales rectos", "Muscles abdominaux droits")],
    ["glutes", new MuscleInformation<string>("Musculus gluteus maximus", "Gluteus", "Glutes", "glutes", "Glúteos", "Fessiers")],
    ["hipflexor", new MuscleInformation<string>("", "Hüftbeuger", "Hip flexor", "hipflexor", "Flexor de la cadera", "Fléchisseur de la hanche")],
    ["triceps", new MuscleInformation<string>("Musculus triceps brachii", "Trizeps", "Triceps", "triceps", "Tríceps", "Triceps")],
    ["quadriceps", new MuscleInformation<string>("Musculus quadriceps femoris", "Beinstrecker", "Quadriceps", "quadriceps", "Cuádriceps", "Quadriceps")],
    ["calves", new MuscleInformation<string>("Musculus gastrocnemius", "Wade (Zwillingswadenmuskel)", "Calves", "calves", "Pantorrillas", "Mollets")],
    ["forearm", new MuscleInformation<string>("Antebrachium", "Unterarm", "Forearm", "forearm", "Antebrazo", "Avant-bras")],
    ["brachioradialis", new MuscleInformation<string>("Musculus brachioradialis", "Oberarmspeichenmuskel", "Brachioradialis", "brachioradialis", "Braquiorradial", "Brachioradial")],
    ["armflexor", new MuscleInformation<string>("", "Oberarmmuskel (Armbeuger)", "Arm flexor", "armflexor", "Flexor del brazo", "Fléchisseur du bras")],
    ["hamstrings", new MuscleInformation<string>("Musculus biceps femoris", "Beinbeuger", "Hamstrings", "hamstrings", "Isquiotibiales", "Ischio-jambiers")],
    ["longcalvemuscle", new MuscleInformation<string>("", "Langer Wademuskel", "Long Calve Muscle", "longcalvemuscle", "Músculo largo del gemelo", "Muscle long du mollet")],
    ["latissimus", new MuscleInformation<string>("Musculus latissimus dorsi", "Latissimus", "Latissimus", "latissimus", "Dorsal ancho", "Grand dorsal")],
    ["backextensor", new MuscleInformation<string>("Musculus erector spinae", "Rückenstrecker", "Back Extensor", "backextensor", "Extensor de la espalda", "Extenseur du dos")],
    ["trapezius", new MuscleInformation<string>("Musculus trapezius", "Trapez", "Trapezius", "trapezius", "Trapecio", "Trapèze")],
    ["upperback", new MuscleInformation<string>("", "Oberer Rücken", "Upper Back", "upperback", "Parte superior de la espalda", "Haut du dos")],
    ["infraspinatus", new MuscleInformation<string>("Musculus infraspinatus", "Untergrätenmuskel", "Infraspinatus", "infraspinatus", "Infraespinoso", "Infra-épineux")],
    ["rhomboids", new MuscleInformation<string>("Musculus rhomboideus major", "Rhomboiden", "Rhomboids", "rhomboids", "Romboides", "Rhomboïdes")],
    ["anteriorserratusmuscle", new MuscleInformation<string>("Musculus serratus anterior", "Vorderer Sägemuskel", "Anterior serratus muscle", "anteriorserratusmuscle", "Serrato anterior", "Serratus antérieur")],
    ["musculuspronatorteres", new MuscleInformation<string>("Musculus pronator teres", "Runder Einwärtsdreher", "Musculus pronator teres", "musculuspronatorteres", "Pronador redondo", "Pronateur rond")],
    ["musculussoleus", new MuscleInformation<string>("Musculus soleus", "Schollenmuskel", "Musculus soleus", "musculussoleus", "Sóleo", "Soleus")],
]);


export const MuscleGroupTranslation: Map<string, MuscleInformation<string>> = new Map<string, MuscleInformation<string>>([
    // [.none, new MuscleInformation<MuscleGroupEnum>("", "", "", MuscleGroupEnum.none)],
    ["chest", new MuscleInformation<string>("", "Brust", "Chest", "chest", "pecho", "poitrine")],
    ["back", new MuscleInformation<string>("", "Rücken", "Back", "back", "espalda", "dos")],
    ["core", new MuscleInformation<string>("", "Bauch", "Core", "core", "abdomen", "abdomen")],
    ["delts", new MuscleInformation<string>("", "Schulter", "Delts", "delts", "hombros", "épaules")],
    ["quads", new MuscleInformation<string>("", "Beine", "Legs", "quads", "cuádriceps", "quadriceps")],
    ["calves", new MuscleInformation<string>("", "Unterschenkel", "Calves", "calves", "pantorrillas", "mollets")],
    ["arms", new MuscleInformation<string>("", "Arme", "Arms", "arms", "brazos", "bras")],
]);


export interface ITrainingExercise {
    id: string;
    deleted: boolean;
    creatorUid: string;
    name: LanguageDictionary<any>
    subName: LanguageDictionary<any>
    thumbnailPath: string;
    thumbnailDownloadURL: string;
    thumbnailUrl: string
    instructions: LanguageDictionary<any>;
    isInvertedWeight: boolean;
    mainMuscles: MuscleInformation<string>[]; //in db ref to muscle
    subMuscles: MuscleInformation<string>[]; 
    exerciseType: LanguageDictionary<string>[];
    movement: LanguageDictionary<string>[];
    equipment: LanguageDictionary<string>[];
    exercisePreviewPath: string;
    instructionVideoPath: string;
    defaultSetParameters: LanguageDictionary<SetParameter>[];
    isBasicExercise: boolean;
    timestamp: Date;
    clone(): ITrainingExercise;
}

export interface ITrainingExerciseOverwrite {
    id: string;
    deleted: boolean;
    creatorUid: string;
    sourceExerciseId: string;
    customVideoUrl: string; // Input from coach
    customInstructionVideoPath: string; // Input from coach)
    customCategories: LanguageDictionary<string>[]
    customNotes: String; // (Markdown)
    customPauseDuration?: number;
    customInstructions: LanguageDictionary<any>;
    customImagePaths: string[];

    customThumbnailPath: string;
    customThumbnailUrl: string
    customThumbnailDownloadURL: string;

    customExercisePreviewPath: string;

    customName: LanguageDictionary<any>;
    customSubName: LanguageDictionary<any>;

    customIsInvertedWeight: boolean;
    customMuscleGroups: MuscleInformation<string>[];
    customMainMuscles: MuscleInformation<string>[];
    customSubMuscles: MuscleInformation<string>[];
    customExerciseType: LanguageDictionary<string>[];
    customMovement: LanguageDictionary<string>[];
    customEquipment: LanguageDictionary<string>[];

    customIsBasicExercise: boolean;
    

    hasValue(): boolean;
    clone(): ITrainingExerciseOverwrite;
}

export class TrainingExerciseOverwrite implements ITrainingExerciseOverwrite{
    id: string;
    deleted: boolean;
    creatorUid: string;
    sourceExerciseId: string;
    customVideoUrl: string;
    customInstructionVideoPath: string;
    customCategories: LanguageDictionary<string>[];
    customNotes: string;
    customPauseDuration?: number;
    customInstructions: LanguageDictionary<any>;
    customImagePaths: string[];

    customThumbnailPath: string;
    customThumbnailUrl: string
    customThumbnailDownloadURL: string;

    customExercisePreviewPath: string;

    customName: LanguageDictionary<any>;
    customSubName: LanguageDictionary<any>;

    customIsInvertedWeight: boolean;
    customMuscleGroups: MuscleInformation<string>[];
    customMainMuscles: MuscleInformation<string>[];
    customSubMuscles: MuscleInformation<string>[];
    customExerciseType: LanguageDictionary<string>[];
    customMovement: LanguageDictionary<string>[];
    customEquipment: LanguageDictionary<string>[];

    hiddenExercise: boolean = false;

    customIsBasicExercise: boolean = false;
    customDefaultSetParameters: LanguageDictionary<SetParameter>[] = [];

    timestamp: Date;
    

    constructor();
    constructor(init: TrainingExerciseOverwrite);
    constructor(init?: TrainingExerciseOverwrite) {
        this.id = init && init.id || undefined
        this.deleted = init && init.deleted || false
        this.creatorUid = init && init.creatorUid || ""
        this.sourceExerciseId = init && init.sourceExerciseId || ""
        this.customVideoUrl = init && init.customVideoUrl || ""
        this.customInstructionVideoPath = init && init.customInstructionVideoPath || ""
        this.customCategories = init && init.customCategories || []
        this.customNotes = init && init.customNotes || ""
        this.customPauseDuration = init && init.customPauseDuration || null
        
        this.customInstructions = init && init.customInstructions != null ? new LanguageDictionary(init.customInstructions.de, init.customInstructions.en, init.customInstructions.originObject, init.customInstructions.es, init.customInstructions.fr) : new LanguageDictionary()
        this.customThumbnailPath = init && init.customThumbnailPath || null
        this.customThumbnailDownloadURL = init && init.customThumbnailDownloadURL || null
        this.customExercisePreviewPath = init && init.customExercisePreviewPath || null
        this.customThumbnailUrl = init && init.customThumbnailUrl || null
        this.customImagePaths = init && init.customImagePaths || []

        this.customName = init && init.customName != null ? new LanguageDictionary(init.customName.de, init.customName.en, init.customName.originObject, init.customName.es, init.customName.fr) : new LanguageDictionary()
        this.customSubName = init && init.customSubName != null ? new LanguageDictionary(init.customSubName.de, init.customSubName.en, init.customSubName.originObject, init.customName.fr, init.customSubName.fr) : new LanguageDictionary()

        this.customIsInvertedWeight = init && init.customIsInvertedWeight != null ? init.customIsInvertedWeight : null
        this.customMuscleGroups = init && init.customMuscleGroups?.map(x => new MuscleInformation(x.latin, x.de, x.en, x.originObject, x.es, x.fr)) || []
        this.customMainMuscles = init && init.customMainMuscles?.map(x => new MuscleInformation(x.latin, x.de, x.en, x.originObject, x.es, x.fr)) || []
        this.customSubMuscles = init && init.customSubMuscles?.map(x => new MuscleInformation(x.latin, x.de, x.en, x.originObject, x.es, x.fr)) || []
        this.customExerciseType = init && init.customExerciseType?.map(x => new LanguageDictionary(x.de, x.en, x.originObject, x.es, x.fr)) || []
        this.customMovement = init && init.customMovement?.map(x => new LanguageDictionary(x.de, x.en, x.originObject, x.es, x.fr)) || []
        this.customEquipment = init && init.customEquipment?.map(x => new LanguageDictionary(x.de, x.en, x.originObject, x.es, x.fr)) || []

        this.hiddenExercise = init && init.hiddenExercise || false
        this.customIsBasicExercise = init && init.customIsBasicExercise != null ? init.customIsBasicExercise : null

        this.customDefaultSetParameters = init && init.customDefaultSetParameters?.map(x => new LanguageDictionary(x.de, x.en, x.originObject, x.es, x.fr)) || []
        this.timestamp = init && init.timestamp || null
    }

    // check if any proprtys are set and have a value
    hasValue():boolean{
        if(this.id?.length > 0 || this.customVideoUrl?.length > 0 || this.customInstructionVideoPath?.length > 0 || this.customCategories?.length > 0 || this.customNotes?.length > 0 || this.customPauseDuration != null || this.customInstructions?.hasAnyValue() || this.customThumbnailPath?.length > 0 || this.customExercisePreviewPath?.length > 0 || this.customName?.hasAnyValue() || this.customSubName?.hasAnyValue() || this.customIsInvertedWeight != null || this.customMuscleGroups?.length > 0 || this.customMainMuscles?.length > 0 || this.customSubMuscles?.length > 0 || this.customExerciseType?.length > 0 || this.customMovement?.length > 0 || this.customEquipment?.length > 0 || this.deleted || this.hiddenExercise || this.customDefaultSetParameters?.length > 0) {
            return true
        }
        return false
    }

    clone():TrainingExerciseOverwrite{
        var clonedOverwrite = new TrainingExerciseOverwrite(this)
        clonedOverwrite.customCategories = this.customCategories?.map(x => new LanguageDictionary(x?.de, x?.en, x?.originObject, x?.es, x?.fr))
        return clonedOverwrite
    }
}

export class MergedTrainingExercise implements ITrainingExercise, ITrainingExerciseOverwrite{

    constructor(trainingExercise?:TrainingExercise, trainingExerciseOverwrite?:TrainingExerciseOverwrite) {
        this.trainingExercise = trainingExercise || new TrainingExercise()
        this.trainingExerciseOverwrite = trainingExerciseOverwrite || new TrainingExerciseOverwrite()
    }
    
    public trainingExercise: TrainingExercise;
    public trainingExerciseOverwrite: TrainingExerciseOverwrite;

    get isFromCommonFirebaseProject(): boolean {
        return this.trainingExercise.isFromCommonFirebaseProject
    }
    
    public isDirty: boolean = false;

    //Temp
    public editDisabled: boolean = false;

    //überprüfe ob irgendein Property in TrainingExerciseOverwrite gesetzt ist
    hasValue():boolean{
        if(this.id || this.creatorUid || this.sourceExerciseId || this.trainingExerciseOverwrite.hasValue()) return true
        return false
    }

    clone():MergedTrainingExercise{
        return new MergedTrainingExercise(this.trainingExercise?.clone(), this.trainingExerciseOverwrite?.clone())
    }

    matchesName(words: string[], selectedLanguage: string): boolean {
        if (words && words.length == 0) return true
        if (words.length == 1) return this.name.GetValue(selectedLanguage)?.toLowerCase()?.includes(words[0]) || this.name.GetValue('en')?.toLowerCase()?.includes(words[0]) || this.subName.GetValue(selectedLanguage)?.toLowerCase()?.includes(words[0]);
        var match = true
        words.forEach(word => {
            if (!(this.name.GetValue(selectedLanguage)?.toLowerCase()?.includes(word) || this.name.GetValue('en')?.toLowerCase()?.includes(word) || this.subName.GetValue(selectedLanguage)?.toLowerCase()?.includes(word))) match = false
        })
        return match
    }

    matchesSearchInput(words: string[], selectedLanguage: string): boolean {
        if (words && words.length == 0) return true
        if (words.length == 1) return this.name.GetValue(selectedLanguage)?.toLowerCase()?.includes(words[0]) || this.name.GetValue('en')?.toLowerCase()?.includes(words[0]) || this.subName.GetValue(selectedLanguage)?.toLowerCase()?.includes(words[0]) || (this.customCategories.filter(x => x.originObject?.toString()?.toLowerCase()?.includes(words[0]))?.length > 0) || (this.muscleGroups.filter(x => x.GetValue(selectedLanguage)?.toLowerCase()?.includes(words[0]))?.length > 0) || (this.exerciseType.filter(x => x.GetValue(selectedLanguage)?.toLowerCase()?.includes(words[0]))?.length > 0) || (this.movement.filter(x => x.GetValue(selectedLanguage)?.toLowerCase()?.includes(words[0]))?.length > 0) || (this.mainMuscles.filter(x => x.GetValue(selectedLanguage)?.toLowerCase()?.includes(words[0]))?.length > 0)
        var match = true
        words.forEach(word => {
            if (!(this.name.GetValue(selectedLanguage)?.toLowerCase()?.includes(word) || this.name.GetValue('en')?.toLowerCase()?.includes(word) || this.subName.GetValue(selectedLanguage)?.toLowerCase()?.includes(word) || (this.customCategories.filter(x => x.originObject?.toString()?.toLowerCase()?.includes(word))?.length > 0) || (this.muscleGroups.filter(x => x.GetValue(selectedLanguage)?.toLowerCase()?.includes(word))?.length > 0) || (this.exerciseType.filter(x => x.GetValue(selectedLanguage)?.toLowerCase()?.includes(word))?.length > 0) || (this.movement.filter(x => x.GetValue(selectedLanguage)?.toLowerCase()?.includes(word))?.length > 0) || (this.mainMuscles.filter(x => x.GetValue(selectedLanguage)?.toLowerCase()?.includes(word))?.length > 0) )) match = false
        })
        return match
    }

    getDefaultPauseDuration() {
        if (this.exerciseType.find(x => x.originObject == "compound")) return 120
        return 60
    }

    getPrintableDefaultSetParameters(languageCode: string): string {
        var setParameters = this.defaultSetParameters.map(x => x.GetValue(languageCode))
        return setParameters.join(", ")
    }

    get timestamp(): Date {
        if(this.trainingExerciseOverwrite?.timestamp) return this.trainingExerciseOverwrite.timestamp;
        return this.trainingExercise.timestamp;
    }

    get sourceExerciseId(): string {
        return this.trainingExerciseOverwrite.sourceExerciseId || this.trainingExercise.id
    }
    set sourceExerciseId(value: string){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.sourceExerciseId = this.sourceExerciseId
        }
        this.isDirty = true;
    }

    get customVideoUrl(): string {
        return this.trainingExerciseOverwrite.customVideoUrl || ""
    }
    set customVideoUrl(value: string){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customVideoUrl = value
        }
        this.isDirty = true;
    }

    get customInstructionVideoPath(): string {
        return this.trainingExerciseOverwrite.customInstructionVideoPath || ""
    }
    set customInstructionVideoPath(value: string){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customInstructionVideoPath = value
        }
        this.isDirty = true;
    }
    get customImagePaths(): string[] {
        return this.trainingExerciseOverwrite.customImagePaths || null
    }
    set customImagePaths(value: string[]) {
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customImagePaths = value
        }
        this.isDirty = true;
    }

    get customCategories(): LanguageDictionary<string>[] {
        return this.trainingExerciseOverwrite.customCategories || []
    }
    set customCategories(value: LanguageDictionary<string>[]){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customCategories = value
        }
        this.isDirty = true;
    }

    get customNotes(): string {
        return this.trainingExerciseOverwrite.customNotes || ""
    }
    set customNotes(value: string){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customNotes = value
        }
        this.isDirty = true;
    }

    get customPauseDuration(): number {
        return this.trainingExerciseOverwrite.customPauseDuration
    }
    set customPauseDuration(value: number) {
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customPauseDuration = value
        }
        this.isDirty = true;
    }

    get customThumbnailPath(): string {
        return this.trainingExerciseOverwrite.customThumbnailPath || ""
    }
    set customThumbnailPath(value: string){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customThumbnailPath = value
        }
        this.isDirty = true;
    }

    get customThumbnailUrl(): string {
        return this.trainingExerciseOverwrite.customThumbnailUrl || null
    }
    set customThumbnailUrl(value: string){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customThumbnailUrl = value
        }
        this.isDirty = true;
    }

    get customThumbnailDownloadURL(): string {
        return this.customThumbnailUrl
        return this.trainingExerciseOverwrite.customThumbnailDownloadURL || ""
    }
    set customThumbnailDownloadURL(value: string){
        this.customThumbnailUrl = value
        return
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customThumbnailDownloadURL = value
        }
        this.isDirty = true;
    }

    get customExercisePreviewPath(): string {
        return this.trainingExerciseOverwrite.customExercisePreviewPath || ""
    }
    set customExercisePreviewPath(value: string){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customExercisePreviewPath = value
        }
        this.isDirty = true;
    }

    get customName(): LanguageDictionary<any> {
        return this.trainingExerciseOverwrite.customName || new LanguageDictionary()
    }
    set customName(value: LanguageDictionary<any>){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customName = value
        }
        this.isDirty = true;
    }

    get customSubName(): LanguageDictionary<any> {
        return this.trainingExerciseOverwrite.customSubName || new LanguageDictionary()
    }
    set customSubName(value: LanguageDictionary<any>){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customSubName = value
        }
        this.isDirty = true;
    }

    get customMuscleGroups(): MuscleInformation<string>[] {
        return this.trainingExerciseOverwrite.customMuscleGroups || []
    }
    set customMuscleGroups(value: MuscleInformation<string>[]){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customMuscleGroups = value
        }
        this.isDirty = true;
    }

    get customMainMuscles(): MuscleInformation<string>[] {
        return this.trainingExerciseOverwrite.customMainMuscles || []
    }
    set customMainMuscles(value: MuscleInformation<string>[]){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customMainMuscles = value
        }
        this.isDirty = true;
    }

    get customSubMuscles(): MuscleInformation<string>[] {
        return this.trainingExerciseOverwrite.customSubMuscles || []
    }
    set customSubMuscles(value: MuscleInformation<string>[]){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customSubMuscles = value
        }
        this.isDirty = true;
    }

    get customExerciseType(): LanguageDictionary<string>[] {
        return this.trainingExerciseOverwrite.customExerciseType || []
    }

    set customExerciseType(value: LanguageDictionary<string>[]){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customExerciseType = value
        }
        this.isDirty = true;
    }

    get customMovement(): LanguageDictionary<string>[] {
        return this.trainingExerciseOverwrite.customMovement || []
    }
    set customMovement(value: LanguageDictionary<string>[]){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customMovement = value
        }
        this.isDirty = true;
    }

    get customEquipment(): LanguageDictionary<string>[] {
        return this.trainingExerciseOverwrite.customEquipment || []
    }
    set customEquipment(value: LanguageDictionary<string>[]){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customEquipment = value
        }
        this.isDirty = true;
    }
    
    get customIsInvertedWeight(): boolean {
        return this.trainingExerciseOverwrite.customIsInvertedWeight || null
    }
    set customIsInvertedWeight(value: boolean){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customIsInvertedWeight = value
        }
        this.isDirty = true;
    }

    get customIsBasicExercise(): boolean {
        return this.trainingExerciseOverwrite.customIsBasicExercise || null
    }
    set customIsBasicExercise(value: boolean){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customIsBasicExercise = value
        }
        this.isDirty = true;
    }



    get hiddenExercise(): boolean {
        return this.trainingExerciseOverwrite.hiddenExercise || false
    }
    set hiddenExercise(value: boolean){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.hiddenExercise = value
        }
        this.isDirty = true;
    }
    
    get id(): string {
        return this.trainingExerciseOverwrite.id || ""
    }

    
    // id: string;

    get creatorUid(): string {
        return this.trainingExercise.creatorUid || ""
    }
    set creatorUid(value: string){
        if(this.trainingExercise){
            this.trainingExercise.creatorUid = value
        }
        this.isDirty = true;
    }

    get name(): LanguageDictionary<any> {
        if(this.trainingExerciseOverwrite?.customName?.hasAnyValue()){
            return this.trainingExerciseOverwrite?.customName;
        }
        return this.trainingExercise.name || new LanguageDictionary()
    }
    set name(value: LanguageDictionary<any>){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customName = value
        }
        if(this.trainingExercise){
            this.trainingExercise.name = value
        }
        this.isDirty = true;
    }

    get subName(): LanguageDictionary<any> {
        if(this.trainingExerciseOverwrite?.customSubName?.hasAnyValue()){
            return this.trainingExerciseOverwrite?.customSubName;
        }
        return this.trainingExercise.subName || new LanguageDictionary()
    }
    set subName(value: LanguageDictionary<any>){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customSubName = value
        }
        if(this.trainingExercise){
            this.trainingExercise.subName = value
        }
        this.isDirty = true;
    }

    get thumbnailPath(): string {
        if(this.customThumbnailPath?.length > 0) return this.customThumbnailPath
        else return this.trainingExercise.thumbnailPath || ""
    }
    set thumbnailPath(value: string){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customThumbnailPath = value
        }
        if(this.trainingExercise){
            this.trainingExercise.thumbnailPath = value
        }
        this.isDirty = true;
    }
    get thumbnailUrl(): string {
        if (this.trainingExerciseOverwrite?.customThumbnailUrl?.length > 0) return this.trainingExerciseOverwrite?.customThumbnailUrl
        if (this.trainingExerciseOverwrite?.customThumbnailPath?.length > 0 && (this.trainingExerciseOverwrite.customThumbnailUrl?.length ?? 0) == 0) return null
        else return this.trainingExercise.thumbnailUrl || null
    }
    set thumbnailUrl(value: string){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customThumbnailUrl = value
        }
        if(this.trainingExercise){
            this.trainingExercise.thumbnailUrl = value
        }
        this.isDirty = true;
    }

    get thumbnailDownloadURL(): string {
        if (this.trainingExerciseOverwrite?.customThumbnailUrl?.length > 0) return this.trainingExerciseOverwrite?.customThumbnailUrl
        if (this.trainingExerciseOverwrite?.customThumbnailPath?.length > 0 && (this.trainingExerciseOverwrite.customThumbnailUrl?.length ?? 0) == 0) return null
        else return this.trainingExercise.thumbnailUrl || null
        return
        if(this.trainingExerciseOverwrite?.customThumbnailDownloadURL?.length > 0) return this.trainingExerciseOverwrite?.customThumbnailDownloadURL
        else return this.trainingExercise.thumbnailDownloadURL || ""
    }
    set thumbnailDownloadURL(value: string){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customThumbnailDownloadURL = value
        }
        if(this.trainingExercise){
            this.trainingExercise.thumbnailDownloadURL = value
        }
        this.isDirty = true;
    }

    get instructions(): LanguageDictionary<any> {
        if(this.trainingExerciseOverwrite?.customInstructions?.hasAnyValue()){
            return this.trainingExerciseOverwrite?.customInstructions;
        }
        return this.trainingExercise.instructions || new LanguageDictionary()
    }
    set instructions(value: LanguageDictionary<any>){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customInstructions = value
        }
        if(this.trainingExercise){
            this.trainingExercise.instructions = value
        }
        this.isDirty = true;
    }
    
    get customInstructions(): LanguageDictionary<any> {
        return this.trainingExerciseOverwrite.customInstructions || new LanguageDictionary()
    }
    set customInstructions(value: LanguageDictionary<any>){
        if(this.trainingExercise){
            this.trainingExerciseOverwrite.customInstructions = value
        }
        this.isDirty = true;
    }


    get isInvertedWeight(): boolean {
        return (this.trainingExerciseOverwrite?.customIsInvertedWeight != null) ? this.trainingExerciseOverwrite?.customIsInvertedWeight : this.trainingExercise.isInvertedWeight || false
    }
    set isInvertedWeight(value: boolean){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customIsInvertedWeight = value
        }
        if(this.trainingExercise){
            this.trainingExercise.isInvertedWeight = value
        }
        this.isDirty = true;
    }

    get muscleGroups(): MuscleInformation<string>[] {
        if(this.trainingExerciseOverwrite?.customMuscleGroups?.length > 0) return this.trainingExerciseOverwrite.customMuscleGroups
        return this.trainingExercise.muscleGroups || []
    }
    set muscleGroups(value: MuscleInformation<string>[]){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customMuscleGroups = value
        }
        if(this.trainingExercise){
            this.trainingExercise.muscleGroups = value
        }
        this.isDirty = true;
    }


    get mainMuscles(): MuscleInformation<string>[] {
        if(this.trainingExerciseOverwrite?.customMainMuscles?.length > 0) return this.trainingExerciseOverwrite.customMainMuscles
        return this.trainingExercise.mainMuscles || []
    }
    set mainMuscles(value: MuscleInformation<string>[]){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customMainMuscles = value
        }
        if(this.trainingExercise){
            this.trainingExercise.mainMuscles = value
        }
        this.isDirty = true;
    }

    get subMuscles(): MuscleInformation<string>[] {
        if(this.trainingExerciseOverwrite?.customSubMuscles?.length > 0) return this.trainingExerciseOverwrite.customSubMuscles
        return this.trainingExercise.subMuscles || []
    }
    set subMuscles(value: MuscleInformation<string>[]){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customSubMuscles = value
        }
        if(this.trainingExercise){
            this.trainingExercise.subMuscles = value
        }
        this.isDirty = true;
    }

    get exerciseType(): LanguageDictionary<string>[] {
        if(this.trainingExerciseOverwrite?.customExerciseType?.length > 0) return this.trainingExerciseOverwrite.customExerciseType
        return this.trainingExercise.exerciseType || []
    }
    set exerciseType(value: LanguageDictionary<string>[]){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customExerciseType = value
        }
        if(this.trainingExercise){
            this.trainingExercise.exerciseType = value
        }
        this.isDirty = true;
    }

    get movement(): LanguageDictionary<string>[] {
        if(this.trainingExerciseOverwrite?.customMovement?.length > 0) return this.trainingExerciseOverwrite.customMovement
        return this.trainingExercise.movement || []
    }
    set movement(value: LanguageDictionary<string>[]){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customMovement = value
        }
        if(this.trainingExercise){
            this.trainingExercise.movement = value
        }
        this.isDirty = true;
    }
    

    get equipment(): LanguageDictionary<string>[] {
        if(this.trainingExerciseOverwrite?.customEquipment?.length > 0) return this.trainingExerciseOverwrite.customEquipment
        return this.trainingExercise.equipment || []
    }
    set equipment(value: LanguageDictionary<string>[]){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customEquipment = value
        }
        if(this.trainingExercise){
            this.trainingExercise.equipment = value
        }
        this.isDirty = true;
    }

    get exercisePreviewPath(): string {
        if(this.trainingExerciseOverwrite?.customExercisePreviewPath?.length > 0) return this.trainingExerciseOverwrite.customExercisePreviewPath
        return this.trainingExercise.exercisePreviewPath || ""
    }
    set exercisePreviewPath(value: string){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customExercisePreviewPath = value
        }
        if(this.trainingExercise){
            this.trainingExercise.exercisePreviewPath = value
        }
        this.isDirty = true;
    }

    get instructionVideoPath(): string {
        if(this.trainingExerciseOverwrite.customInstructionVideoPath?.length > 0) return this.trainingExerciseOverwrite.customInstructionVideoPath
        else return this.trainingExercise.instructionVideoPath || ""
    }
    set instructionVideoPath(value: string){
        if(this.trainingExercise){
            this.trainingExercise.instructionVideoPath = value
        }
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customInstructionVideoPath = value
        }
        this.isDirty = true;
    }

    get pauseDuration(): number {
        if(this.trainingExerciseOverwrite?.customPauseDuration){
            return this.trainingExerciseOverwrite.customPauseDuration;
        }
    }
    set pauseDuration(value: number) {
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customPauseDuration = value;
        }
        this.isDirty = true;
    }

    get deleted(): boolean {
        if(this.trainingExercise?.deleted) return this.trainingExercise.deleted
        if(this.trainingExerciseOverwrite?.deleted != null) return this.trainingExerciseOverwrite?.deleted
        return false
        // if(this.trainingExerciseOverwrite?.deleted != null) return this.trainingExerciseOverwrite?.deleted
        // return (this.trainingExerciseOverwrite?.deleted != null) ? this.trainingExerciseOverwrite?.deleted : this.trainingExercise.deleted || false
        // return this.trainingExercise.deleted;
    }
    set deleted(value: boolean) {
        if(this.trainingExercise){
            this.trainingExercise.deleted = value;
        }
        // if(this.trainingExercise){
        //     this.trainingExercise.deleted = value;
        // }
        this.isDirty = true;
    }
    
    get isBasicExercise(): boolean {

        return (this.trainingExerciseOverwrite?.customIsBasicExercise != null) ? this.trainingExerciseOverwrite?.customIsBasicExercise : this.trainingExercise.isBasicExercise || false
    }
    set isBasicExercise(value: boolean) {
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customIsBasicExercise = value
        }
        if(this.trainingExercise){
            this.trainingExercise.isBasicExercise = value;
        }
        this.isDirty = true;
    }

    get defaultSetParameters(): LanguageDictionary<SetParameter>[]{
        if(this.trainingExerciseOverwrite?.customDefaultSetParameters?.length > 0) return this.trainingExerciseOverwrite.customDefaultSetParameters
        return this.trainingExercise.defaultSetParameters;
        // return this.trainingExercise.defaultSetParameters;
    }
    set defaultSetParameters(value: LanguageDictionary<SetParameter>[]) {
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customDefaultSetParameters = value;
        }
        if(this.trainingExercise){
            this.trainingExercise.defaultSetParameters = value;
        }        
        this.isDirty = true;
    }

    get customDefaultSetParameters(): LanguageDictionary<SetParameter>[] {
        return this.trainingExerciseOverwrite.customDefaultSetParameters || []
    }
    set customDefaultSetParameters(value: LanguageDictionary<SetParameter>[]){
        if(this.trainingExerciseOverwrite){
            this.trainingExerciseOverwrite.customDefaultSetParameters = value
        }
        this.isDirty = true;
    }

}

export class TrainingExercise implements ITrainingExercise {
    id: string;
    deleted: boolean;
    creatorUid: string;
    name: LanguageDictionary<any>;
    subName: LanguageDictionary<any>;
    thumbnailPath: string;
    thumbnailUrl: string
    thumbnailDownloadURL: string;
    instructions: LanguageDictionary<any>;
    isInvertedWeight: boolean;
    muscleGroups: MuscleInformation<string>[];
    mainMuscles: MuscleInformation<string>[];
    subMuscles: MuscleInformation<string>[]; 
    exerciseType: LanguageDictionary<string>[];
    movement: LanguageDictionary<string>[];
    equipment: LanguageDictionary<string>[];
    exercisePreviewPath: string;
    instructionVideoPath: string;
    instructionVideoDownloadURL: string;
    defaultSetParameters: LanguageDictionary<SetParameter>[];
    isBasicExercise: boolean;

    timestamp: Date;

    public isFromCommonFirebaseProject: boolean = false

    constructor();
    constructor(init: TrainingExercise);
    constructor(init?: TrainingExercise) {
        this.id = init && init.id || undefined
        this.deleted = init && init.deleted || false
        this.creatorUid = init && init.creatorUid || ""
        this.name = init && new LanguageDictionary(init.name.de, init.name.en, init.name.originObject, init.name.es, init.name.fr) || new LanguageDictionary()
        this.subName = init && new LanguageDictionary(init.subName.de, init.subName.en, init.subName.originObject, init.subName.es, init.subName.fr) || new LanguageDictionary()
        this.thumbnailDownloadURL = init && init.thumbnailDownloadURL || ""
        this.instructions = init && new LanguageDictionary(init.instructions.de, init.instructions.en, init.instructions.originObject, init.instructions.es, init.instructions.fr) || new LanguageDictionary()
        this.isInvertedWeight = init && init.isInvertedWeight || false
        this.muscleGroups = init && init.muscleGroups?.map(x => new MuscleInformation(x.latin, x.de, x.en, x.originObject, x.es, x.fr)) || []
        this.mainMuscles = init && init.mainMuscles?.map(x => new MuscleInformation(x.latin, x.de, x.en, x.originObject, x.es, x.fr)) || []
        this.subMuscles = init && init.subMuscles?.map(x => new MuscleInformation(x.latin, x.de, x.en, x.originObject, x.es, x.fr)) || []
        this.exerciseType = init && init.exerciseType?.map(x => new LanguageDictionary(x.de, x.en, x.originObject, x.es, x.fr)) || []
        this.movement = init && init.movement?.map(x => new LanguageDictionary(x.de, x.en, x.originObject, x.es, x.fr)) || []
        this.equipment = init && init.equipment?.map(x => new LanguageDictionary(x.de, x.en, x.originObject, x.es, x.fr)) || []
        this.thumbnailPath = init && init.thumbnailPath || ""
        this.thumbnailUrl = init && init.thumbnailUrl || null
        this.exercisePreviewPath = init && init.exercisePreviewPath || ""
        this.instructionVideoPath = init && init.instructionVideoPath || ""
        this.isBasicExercise = init && init.isBasicExercise || false
        this.defaultSetParameters = init && init.defaultSetParameters?.map(x => new LanguageDictionary(x.de, x.en, x.originObject, x.es, x.fr)) || [SetParameter2LanguageDictionary[SetParameter.weight], SetParameter2LanguageDictionary[SetParameter.reps]]

        this.timestamp = init && init.timestamp || new Date()
    }


    clone(): TrainingExercise {
        var exercise = new TrainingExercise(this)
        exercise.name = new LanguageDictionary(this.name?.de, this.name?.en, this.name?.originObject, this.name?.es, this.name?.fr)
        exercise.subName = new LanguageDictionary(this.subName?.de, this.subName?.en, this.subName?.originObject, this.subName?.es, this.subName?.fr)
        exercise.instructions = new LanguageDictionary(this.instructions?.de, this.instructions?.en, this.instructions?.originObject, this.instructions?.es, this.instructions?.fr)
        exercise.muscleGroups = this.muscleGroups?.map(x => new MuscleInformation(x?.latin, x?.de, x?.en, x?.originObject, x?.es, x?.fr))
        exercise.mainMuscles = this.mainMuscles?.map(x => new MuscleInformation(x?.latin, x?.de, x?.en, x?.originObject, x?.es, x?.fr))
        exercise.subMuscles = this.subMuscles?.map(x => new MuscleInformation(x?.latin, x?.de, x?.en, x?.originObject, x?.es, x?.fr))
        exercise.movement = this.movement?.map(x => new LanguageDictionary(x?.de, x?.en, x?.originObject, x?.es, x?.fr)) 
        exercise.equipment = this.equipment?.map(x => new LanguageDictionary(x?.de, x?.en, x?.originObject, x?.es, x?.fr))
        exercise.defaultSetParameters = this.defaultSetParameters?.map(x => new LanguageDictionary(x?.de, x?.en, x?.originObject, x?.es, x?.fr))
        exercise.isFromCommonFirebaseProject = this.isFromCommonFirebaseProject
        return exercise
    }

}