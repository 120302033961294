<div *ngIf="!isLoading && isLandingPageEnabled()" class="container">
    <div class="row">
        <div class="col-12 col-lg-4"></div>
        <div class="col-12 col-lg-4">
            <div class="branding-wrapper">
                <h3 translate>Willkommen!</h3>
                <img draggable="false" *ngIf="brandingImageUrl" class="branding-image" src="{{brandingImageUrl}}" alt="">
            </div>
        </div>
        <div class="col-12 col-lg-4">
            <div *ngIf="user && userSelected">
                <div class="user-selection">
                    <div class="profile-container">
                        <div class="profile-picture">
                            <button class="profile-button" [title]="'Profilbild hochladen' | translate">
                                <img draggable="false" src="{{user.profilePictureUrl}}">
                            </button>
                        </div>
                        <h3 class="name">{{user?.getName()}}</h3>
                    </div>
                    Nicht du?<br>
                    <div class="spacer-10"></div>
                    <button class="button-filled small white" id="logout-button" (click)="onLogout()" translate>Abmelden</button>
                </div>
                <div class="spacer-25"></div>
            </div>
        </div>

        <div class="col-12">
            
            <div class="row">
                <div *ngIf="products == null" class="col textalign-center">
                    <div class="spacer-50"></div>
                    <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
                    <div class="spacer-50"></div>
                </div>
                <div *ngIf="products != null && products.length == 0" class="col textalign-center">
                    <div class="spacer-50"></div>
                    <h3 translate>Keine Produkte gefunden.</h3>
                    <div class="spacer-50"></div>
                </div>
                <div class="col" *ngFor="let product of products">
                    <app-checkout-view [setProduct]="product" [setPaymentSettings]="paymentSettings" [setIsPreview]="true"></app-checkout-view>
                </div>
            </div>
        </div>

        <div *ngIf="paymentSettings?.landingPageCalendlyUrl" class="col-12 textalign-center">
            <div class="spacer-50"></div>
            <h2 translate>Buche dir ein Meeting:</h2>
            <app-calendly-widget [calendlyUrl]="paymentSettings?.landingPageCalendlyUrl"></app-calendly-widget>
        </div>
        
        <div class="checkout-footer col-12">
            <div class="label" translate>Anbieter:</div>
            <ng-container *ngIf="paymentSettings?.alternativeImprintAddress">
                <div class="whitespace-prewrap">{{paymentSettings?.alternativeImprintAddress}}</div>
                <div class="spacer-25"></div>
            </ng-container>
            <ng-container *ngIf="!paymentSettings?.alternativeImprintAddress">
                <div class="whitespace-prewrap">{{paymentSettings?.companyName}}</div>
                <div class="spacer-10"></div>
                <div class="whitespace-prewrap">{{paymentSettings?.address}}</div>
                <div class="spacer-10"></div>
                <div class="whitespace-prewrap">{{paymentSettings?.phone}}</div>
                <div class="whitespace-prewrap">{{paymentSettings?.email}}</div>
                <div class="spacer-10"></div>
                <div *ngIf="paymentSettings?.vatIdNo" class="whitespace-prewrap"><span translate>USt-IdNr:</span> {{paymentSettings?.vatIdNo}}</div>
                <div class="spacer-25"></div>
            </ng-container>
        </div>
    </div>
    
</div>
<div *ngIf="!isLoading && !isLandingPageEnabled()" class="textalign-center">
    <div class="row">
        <div class="col-12">
            <div class="spacer-50"></div>
            <h3 translate>Kein Profil gefunden.</h3>
        </div>
    </div>
</div>
<div *ngIf="isLoading" class="textalign-center">
    <div class="row">
        <div class="col-12">
            <div class="spacer-50"></div>
            <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
        </div>
    </div>
</div>