<div cdkDropList (cdkDropListDropped)="dropTrainingExercise($event)" *ngIf="selectedSession" class="session-edit-column padding-0 ignore-disabled {{selectedSession.isTracked ? 'disabled': ''}}" (click)="closeSessionsColumn?.emit">
    <div class="sticky-column">
        <div *ngIf="headerAreaEnabled" class="spacer-25"></div>
        <div *ngIf="headerAreaEnabled && !readOnlyMode && !selectedSession?.isTracked" class="row">
            <div class="col-12 col-sm-8">
                <h2 class="">Einheit bearbeiten</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12 ignore-disabled" *ngIf="trainingSessionContainerList.length > 0 && !trainingPlan.isTemplate && trainingPlan.isPeriodicPlan && selectedSession?.isTracked && environment.firebaseProjectId != 'traindoo-app'">
                <ng-container *ngIf="!loadingSessionHistory">
                    <div class="bold">Absolviertes Training:</div>
                    <div class="spacer-10"></div>
                    <div class="ignore-disabled">
                        <app-tracked-training-session [user]="user" [questionaireResults]="questionaireResults"
                            [TrainingSessionContainerList]="trainingSessionContainerList"></app-tracked-training-session>
                    </div>
                </ng-container>
                <div *ngIf="loadingSessionHistory" class="col-8">
                    <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
                </div>
            </div>
        </div>
        <div *ngIf="headerAreaEnabled" class="row">
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="" *ngIf="!selectedSession?.isRestDay">
                    <label for="input-session-name" class="label">Geschätzte Dauer (in Minuten):</label>
                    <div>
                        <input [disabled]="readOnlyMode || selectedSession?.isTracked" min="0"
                            class="input small form-control number-input display-inlineblock"
                            [ngModel]="getEstimatedDurationInMinutes(selectedSession)"
                            (input)="setEstimateedDurationInMinutes(selectedSession, $event.target.value)"
                            (click)="$event.stopPropagation()" type="number">
                        <div class="d-inline-block link" (click)="refreshEstimatedDurationInMinutes(selectedSession)"
                            tooltip="Geschätzte Dauer neu berechnen">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                    d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                <path
                                    d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                            </svg>
                        </div>
                    </div>
                    <div class="spacer-10"></div>
                </div>
                <div class="">
                    <label for="input-session-name" class="label">Einheitenname</label>
                    <input [disabled]="readOnlyMode || selectedSession?.isTracked" id="input-session-name"
                        maxlength="35" required class="input plannedmeal-name form-control wide-input"
                        [(ngModel)]="selectedSession?.nameTranslation[languageService.selectedLanguageCode]"
                        placeholder="{{selectedSession?.getSessionNameTranslationWithFallback(languageService.selectedLanguageCode) ?? 'Name'}}" type="text">
                </div>
            </div>
        </div>
        <div class="spacer-25"></div>
        <div *ngIf="headerAreaEnabled" class="spacer-25"></div>
        <div class="row" *ngIf="selectedSession?.isRestDay">

        </div>
        <div class="row limited-exercise-row" *ngIf="!selectedSession?.isRestDay">
            <div class="col">
                <div class="row">
                    <div class="col">
                        <h3 class="display-inlineblock">Übungen</h3>
                        <div *ngIf="!readOnlyMode  && !selectedSession?.isTracked"
                            class="icon-button display-inlineblock marginleft-25 marginright-25"
                            (click)="addPlannedTrainingExercise()">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                            <span class="only-desktop">Übungen hinzufügen</span>
                            <span class="only-mobile">Hinzufügen</span>
                        </div>
                        <div *ngIf="!readOnlyMode  && !selectedSession?.isTracked"
                            class="icon-button display-inlineblock"
                            (click)="addExerciseGroupFromTemplate(selectedSession)">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                            <span class="only-desktop">Übungen aus Vorlage hinzufügen</span>
                            <span class="only-mobile">Aus Vorlage</span>
                        </div>
                    </div>
                    <div *ngIf="!trainingPlan.isTemplate" class="col-12 history-column">
                        <div class="row">
                            <div class="col-auto" *ngIf="!historyAlwaysOpen">
                                <h3 class="link ignore-disabled"
                                    (click)="trainingPlanHistoryCollapsed = !trainingPlanHistoryCollapsed">
                                    <ng-container *ngIf="!trainingPlanHistoryCollapsed; else historyCollapsed">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                            fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </ng-container>
                                    <ng-template #historyCollapsed>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                            fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                            <path
                                                d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg>
                                    </ng-template>
                                    Historie
                                </h3>
                            </div>
                            <div class="col-auto" *ngIf="historyAlwaysOpen">
                                <h3 class="ignore-disabled">
                                    Historie
                                </h3>
                            </div>
                            <div *ngIf="historyAlwaysOpen || !trainingPlanHistoryCollapsed"
                                class="col date-selection history-date-selection ignore-disabled">
                                <button *ngIf="!jumpToPreviousHistorySessionDisabled" class="button change-date p-0" (click)="jumpToPreviousHistorySession()">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-left"
                                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                    </svg>
                                </button>

                                <input [matDatepicker]="diaryDatePicker" class="visibility-hidden width-0 padding-0"
                                    (dateInput)="onHistoryDateSelected($event.value?.toDate())"  [value]="selectedHistoryDate">
                                <mat-datepicker-toggle matSuffix [for]="diaryDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #diaryDatePicker></mat-datepicker>
                                <div class="date-display">
                                    {{NumberToShortWeekDayStringsMapping[selectedHistoryDate?.getDay()]?.substr(0,
                                    2)}}., {{selectedHistoryDate?.getDate()}}.{{selectedHistoryDate?.getMonth() +
                                    1}}.{{selectedHistoryDate?.getFullYear()}}</div>
                                <button *ngIf="!jumpToNextHistorySessionDisabled" class="button change-date p-0" (click)="jumpToNextHistorySession()">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right"
                                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </button>
                            </div>
                            <div class="spacer-10"></div>
                        </div>
                        <ng-container *ngIf="(historyAlwaysOpen || !trainingPlanHistoryCollapsed) && selectedHistoryTrackedSession">
                            <div *ngIf="loadingSessionHistory" class="row">
                                <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
                            </div>
                            <div class="row justify-content-between">
                                <div class="col-auto">
                                    <h3 class="mb-0">
                                        {{selectedHistoryTrackedSession.sessionName}}
                                    </h3>
                                    <div class="label">
                                        <ng-container *ngIf="selectedHistoryTrackedSession?.plannedWeek">
                                            W{{selectedHistoryTrackedSession?.plannedWeekIndex + 1}}
                                            <ng-container *ngIf="selectedHistoryTrackedSession?.plannedWeek?.name?.GetValue(languageService.selectedLanguageCode) && selectedHistoryTrackedSession?.plannedWeek?.name?.GetValue(languageService.selectedLanguageCode) !== ('W' + (selectedHistoryTrackedSession?.plannedWeekIndex + 1))">
                                                ({{selectedHistoryTrackedSession?.plannedWeek?.name?.GetValue(languageService.selectedLanguageCode) ?? ''}})
                                            </ng-container>
                                        </ng-container>
                                        <!-- {{selectedHistoryTrackedSession?.endDate | date:'dd.MM.yyyy'}} -->
                                    </div>
                                    <div class="label">
                                        <ng-container *ngIf="selectedHistoryTrackedSession?.startDate?.isSameDate(selectedHistoryTrackedSession?.endDate); else differentDates;">
                                            {{(selectedHistoryTrackedSession?.startDate | timezone:user)?.asDateTimeFormatedString()}} - {{(selectedHistoryTrackedSession?.endDate | timezone:user)?.asTimeFormatedString()}}
                                        </ng-container>
                                        <ng-template #differentDates>
                                            {{(selectedHistoryTrackedSession?.startDate | timezone:user)?.asDateTimeFormatedString()}} - {{(selectedHistoryTrackedSession?.endDate | timezone:user)?.asDateTimeFormatedString()}}
                                        </ng-template>
                                    </div>
                                    <div class="label" *ngIf="selectedHistoryTrackedSession?.getPrintableTrainingDuration()">
                                        Dauer: {{selectedHistoryTrackedSession?.getPrintableTrainingDuration()}}h
                                    </div>
                                </div>
                                <div *ngIf="selectedQuestionaire" class="col-auto">
                                    <div class="layout-box sub-box link training-feedback-box" tooltip="Trainingsfeedback" (click)="openQuestionaireDialog(selectedQuestionaire)">
                                        <div class="icon">
                                            <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.5 13H11.5M5.5 10H11.5M11.5002 2.5H15.25C15.4489 2.5 15.6397 2.57902 15.7803 2.71967C15.921 2.86032 16 3.05109 16 3.25V19C16 19.1989 15.921 19.3897 15.7803 19.5303C15.6397 19.671 15.4489 19.75 15.25 19.75H1.75C1.55109 19.75 1.36032 19.671 1.21967 19.5303C1.07902 19.3897 1 19.1989 1 19V3.25C1 3.05109 1.07902 2.86032 1.21967 2.71967C1.36032 2.57902 1.55109 2.5 1.75 2.5H5.4998M4.75 5.5V4.75C4.75 3.75544 5.14509 2.80161 5.84835 2.09835C6.55161 1.39509 7.50544 1 8.5 1C9.49456 1 10.4484 1.39509 11.1517 2.09835C11.8549 2.80161 12.25 3.75544 12.25 4.75V5.5H4.75Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>                                                          
                                        </div>
                                        <div class="label display-inlineblock marginleft-10">Trainingsfeedback</div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div *ngIf="copyExercise && !readOnlyMode && !selectedSession?.isTracked"
                    (click)="onPasteExercise(selectedSession);$event.stopPropagation()">
                    <div class="icon-button" tooltip="Einfügen">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-download" viewBox="0 0 16 16">
                            <path
                                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                            <path
                                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                        Einfügen
                    </div>
                </div>
                <div *ngIf="copyExercisesListInternal && !readOnlyMode && !selectedSession?.isTracked"
                    (click)="onPasteGroupHeading(selectedSession);$event.stopPropagation()">
                    <div class="icon-button" tooltip="Einfügen">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                            fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                            <path
                                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                            <path
                                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                        Gruppe einfügen
                    </div>
                </div>
                <div>
                    <div class="{{superSet.exercises.length > 1 ? 'super-set-row' : ''}} {{draggingGroup ? 'p-0' : ''}}"
                        *ngFor="let superSet of selectedSession.superSets">
                        <div *ngIf="!draggingGroup && superSet.exercises.length > 1">
                            <div class="spacer-20"></div>
                            <div class="col-6">
                                <div class="row">
                                    <h3>Supersatz:</h3>
                                </div>
                                <div class="row">
                                    <label for="input-session-name" class="label">Name</label>
                                    <input [disabled]="readOnlyMode || selectedSession?.isTracked"
                                        id="input-session-name" maxlength="35"
                                        class="input plannedmeal-name form-control wide-input"
                                        [(ngModel)]="superSet.exercises[0].superSetConfig.nameTranslation[languageService.selectedLanguageCode]"
                                        placeholder="Supersatz" type="text">
                                </div>
                                <div class="spacer-10"></div>
                                <div class="row">
                                    <div class="display-inlineblock link"
                                        tooltip="Lege fest, wie viele Runden des Satzes absolviert werden sollen. AMRAP: So viele Runden wie möglich innerhalb einer festgelegten Zeit. Feste Anzahl: Die Runden ergeben sich durch die Anzahl der geplanten Sätze je Übung."
                                        placement="top">
                                        <div class="label display-inlineblock marginright-10" style="width: auto">
                                            Rundenanzahl:</div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                            <path
                                                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path
                                                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                        </svg>
                                    </div>
                                    <div class="wrapper-rounds-number-selection">
                                        <button [disabled]="readOnlyMode || selectedSession?.isTracked"
                                            class="button-filled light small dropdown-toggle margin-inbetween"
                                            type="button" id="dropdownCoaches" data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false">{{numberOfRounds2LabelMapping[superSet.exercises[0].superSetConfig.numberOfRounds]}}</button>
                                            <a *ngIf="!environment.isWhitelabel && superSet.exercises[0].superSetConfig.numberOfRounds == 'PER_SETS'"
                                            class="icon-button display-inlineblock"
                                            tooltip="Videotutorial zu Supersätzen ansehen"
                                            (click)="goToLink('https://www.loom.com/share/9d6cdd5689db4039af857f71a1573aca')">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                                viewBox="0 0 16 16" fill="currentColor"
                                                class="bi bi-play-btn padding-4">
                                                <path
                                                    d="M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                                                <path
                                                    d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                            </svg>
                                        </a>
                                        <a *ngIf="!environment.isWhitelabel && superSet.exercises[0].superSetConfig.numberOfRounds == 'AMRAP'"
                                            class="icon-button display-inlineblock"
                                            tooltip="Videotutorial zu AMRAPs ansehen"
                                            (click)="goToLink('https://www.loom.com/share/ec3df8f24d9b4133a016b22ab97774cb')">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                                viewBox="0 0 16 16" fill="currentColor"
                                                class="bi bi-play-btn padding-4">
                                                <path
                                                    d="M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                                                <path
                                                    d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                            </svg>
                                        </a>
                                        <div class="dropdown-menu" aria-labelledby="dropdownCoaches">
                                            <div *ngFor="let numberOfRounds of numberOfRoundsLabels">
                                                <button [disabled]="readOnlyMode || selectedSession?.isTracked"
                                                    class="dropdown-item" type="button"
                                                    (click)="onSetNumberOfRounds(superSet, numberOfRounds)">{{numberOfRounds}}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2"
                                    *ngIf="superSet.exercises[0].superSetConfig.numberOfRounds === NumberOfRounds.AMRAP">
                                    <div class="label w-100">Verfügbare Zeit:</div>
                                    <div>
                                        <app-time-input-control [backgroundColor]="utilityService.getCSSVariableValue('--lightBackgroundColor')"
                                            [(fullSeconds)]="superSet.exercises[0].superSetConfig.totalAvailableTime"></app-time-input-control>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col" *ngIf="!selectedSession.deleted">
                                <div class="arrow-box" *ngIf="!draggingGroup && superSet.exercises.length > 1"></div>
                                <ng-container *ngFor="let exercise of superSet.exercises; let exerciseIndex = index">
                                    <div class="row">
                                        <div class="col" (mouseover)="mouseEnterExercise(exercise)"
                                            (touchstart)="mouseEnterExercise(exercise)">
                                            <div class="col-12 col-md-9 exercise-header {{draggingGroup ? 'ml-0' : ''}}"
                                                *ngIf="!selectedSession?.deleted && !exercise?.deleted && (exercise.groupHeadingTranslation != null || !draggingGroup)">
                                                <div *ngIf="!draggingGroup" class="row {{hoveredExercise === exercise ? 'visible' : 'hidden'}}">
                                                    <div *ngIf="environment.firebaseProjectId == 'traindoo-app'" class="col-auto add-heading-marker-wrapper link"
                                                        (click)="addPlacholderTrainingExercise(exercise)">
                                                        <div class="add-heading-marker">+ Übung hinzufügen</div>
                                                    </div>
                                                    <div class="col-auto add-heading-marker-wrapper link"
                                                        (click)="addPlannedTrainingExercise(exercise)">
                                                        <div *ngIf="environment.firebaseProjectId != 'traindoo-app'" class="add-heading-marker">+ Übungen hinzufügen</div>
                                                        <div *ngIf="environment.firebaseProjectId == 'traindoo-app'" class="add-heading-marker">+ Übungsauswahl</div>
                                                    </div>
                                                    <div *ngIf="exercise.groupHeadingTranslation == null"
                                                        class="col-auto add-heading-marker-wrapper link"
                                                        (click)="onAddGroupHeading(exercise)">
                                                        <div class="add-heading-marker">+ Überschrift hinzufügen</div>
                                                    </div>
                                                </div>
                                                <div class="group-heading-drag"
                                                    *ngIf="exercise.groupHeadingTranslation != null"
                                                    (dragstart)="onDragStartGroup($event, selectedSession, exercise.groupHeadingTranslation)"
                                                    (dragenter)="$event.preventDefault();"
                                                    (drop)="onDropGroupAfterGroup(selectedSession, exercise.groupHeadingTranslation);$event.stopPropagation()"
                                                    (dragend)="onDragEndGroup()" (dragover)="$event.preventDefault()">
                                                    <div (mousedown)="onMouseDownDragHandle($event)"
                                                        (mouseup)="onMouseUpDragHandle($event)"
                                                        class="drag-handle drag-item link">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22"
                                                            fill="currentColor" class="bi bi-grip-vertical"
                                                            viewBox="0 0 16 16">
                                                            <path
                                                                d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                        </svg>
                                                    </div>
                                                    <input
                                                        [(ngModel)]="exercise.groupHeadingTranslation[languageService.selectedLanguageCode]"
                                                        type="text" class="input small groupheading-input drag-item">
                                                    <div class="icon-button marginleft-25 drag-item"
                                                        [matMenuTriggerFor]="groupHeadingMenu">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            fill="currentColor" class="bi bi-three-dots-vertical"
                                                            viewBox="0 0 16 16">
                                                            <path
                                                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                        </svg>
                                                    </div>
                                                    <div class="icon-button marginleft-20 drag-item"
                                                        (click)="onDeleteGroupHeading(exercise)">
                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x"
                                                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd"
                                                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                        </svg>
                                                    </div>
                                                    <mat-menu #groupHeadingMenu="matMenu">
                                                        <button mat-menu-item
                                                            (click)="onSaveGroupAsTemplate(selectedSession, exercise.groupHeadingTranslation)">
                                                            Als Vorlage speichern
                                                        </button>
                                                        <button mat-menu-item
                                                            (click)="onCopyGroupHeading(selectedSession, exercise.groupHeadingTranslation)">
                                                            Kopieren
                                                        </button>
                                                    </mat-menu>
                                                </div>
                                            </div>
                                            <div class="arrow-down"
                                                *ngIf="!draggingGroup && superSet.exercises.length > 1 && exerciseIndex === 0">
                                            </div>
                                            <div cdkDrag [cdkDragDisabled]="readOnlyMode"
                                                class="layout-box sub-box exercise-box link ng-star-inserted exercise-container"
                                                *ngIf="!draggingGroup && !selectedSession?.deleted && !exercise?.deleted">
                                                <div class="drag-handle exercise-drag-handle" cdkDragHandle>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22"
                                                        fill="currentColor" class="bi bi-grip-vertical" viewBox="0 0 16 16">
                                                        <path
                                                            d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                    </svg>
                                                </div>
                                                <div *ngIf="!trainingExercisesLoaded()">
                                                    <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
                                                </div>
                                                <!-- <div *ngIf="isExerciseExpanded(exercise)" class="spacer-25"></div> -->
                                                <div (click)="toggleExpandedExercise(exercise, true)" class="expanded-exercise-container">
                                                    <div class="row">
                                                        <div class="thumbnail-image">
                                                            <ng-container *ngIf="exercise.exerciseId; else placeholderExerciseIcon;">
                                                                <img draggable="false"
                                                                    *ngIf="getExerciseById(exercise.exerciseId)?.thumbnailDownloadURL"
                                                                    loading="lazy"
                                                                    (error)="utilityService.onImageErrorHide($event)"
                                                                    src="{{getExerciseById(exercise.exerciseId)?.thumbnailDownloadURL}}">
                                                                <div
                                                                    *ngIf="!getExerciseById(exercise.exerciseId)?.thumbnailDownloadURL">
                                                                    <img loading="lazy" draggable="false"
                                                                        (error)="utilityService.onImageErrorHide($event)"
                                                                        src="{{ (trainingService.getExerciseThumbnailUrl(getExerciseById(exercise.exerciseId)) | async) }}">
                                                                </div>
                                                            </ng-container>
                                                            <ng-template #placeholderExerciseIcon>
                                                                <div class="placeholder-icon" (click)="onReplaceExercise(selectedSession, exercise, superSet);$event.stopPropagation()" matTooltip="Übung auswählen">
                                                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <g clip-path="url(#clip0_72_4122)">
                                                                        <path d="M22 12.05H23.5M9.25 12.05H15.25M1 12.05H2.5M19 7.55005H21.25C21.4489 7.55005 21.6397 7.62907 21.7803 7.76972C21.921 7.91037 22 8.10114 22 8.30005V15.8C22 15.999 21.921 16.1897 21.7803 16.3304C21.6397 16.471 21.4489 16.55 21.25 16.55H19M5.5 16.55H3.25C3.05109 16.55 2.86032 16.471 2.71967 16.3304C2.57902 16.1897 2.5 15.999 2.5 15.8V8.30005C2.5 8.10114 2.57902 7.91037 2.71967 7.76972C2.86032 7.62907 3.05109 7.55005 3.25 7.55005H5.5M16 5.30005H18.25C18.6642 5.30005 19 5.63584 19 6.05005V18.05C19 18.4643 18.6642 18.8 18.25 18.8H16C15.5858 18.8 15.25 18.4643 15.25 18.05V6.05005C15.25 5.63584 15.5858 5.30005 16 5.30005ZM6.25 5.30005H8.5C8.91421 5.30005 9.25 5.63584 9.25 6.05005V18.05C9.25 18.4643 8.91421 18.8 8.5 18.8H6.25C5.83579 18.8 5.5 18.4643 5.5 18.05V6.05005C5.5 5.63584 5.83579 5.30005 6.25 5.30005Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </g>
                                                                        <defs>
                                                                        <clipPath id="clip0_72_4122">
                                                                        <rect width="24" height="24" fill="white" transform="translate(0 0.300049)"/>
                                                                        </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                        <div class="exercise-name-col col-auto col-lg-4 col-xxxl-2">
                                                            <div class="row">
                                                                <div class="col mr-4">
                                                                    <div class="row">
                                                                        <b *ngIf="exercise.exerciseId">{{getExerciseById(exercise.exerciseId)?.name?.GetValue(languageService.selectedLanguageCode)}}</b>
                                                                        <div class="d-flex" *ngIf="!exercise.exerciseId">
                                                                            <input placeholder="Übung auswählen..." class="input small form-control white-input wide-input placeholder-search-input" type="text" (focus)="onSetSearchFocus(exercise)" (keyup.enter)="selectFirstSearchedExercise(exercise)" (input)="onExerciseSearchInputChanged(exercise, $event.target.value)" [(ngModel)]="exerciseSearchText">
                                                                            <div *ngIf="focusedSearchPlannedExercise == exercise" class="icon-button float-right deletesearch-icon" (click)="onSetSearchFocus(null)">
                                                                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                        <div *ngIf="focusedSearchPlannedExercise == exercise" class="col-12 searched-exercises layout-box margin-0">
                                                                            <div class="mb-2" *ngFor="let filteredExercise of filteredMergedExercises">
                                                                                <div class="link" (click)="selectSearchExercise(exercise, filteredExercise); $event.stopPropagation();">
                                                                                    <div class="">
                                                                                        {{filteredExercise.name.GetValue(languageService.selectedLanguageCode)}}
                                                                                    </div>
                                                                                    <div class="label">
                                                                                        {{filteredExercise.subName.GetValue(languageService.selectedLanguageCode)}}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        {{getExerciseById(exercise.exerciseId)?.subName?.GetValue(languageService.selectedLanguageCode)}}
                                                                    </div>
                                                                    <div *ngIf="user && isExerciseExpanded(exercise) && exercise.exerciseId"
                                                                        class="row">
                                                                        <div class="spacer-10"></div>
                                                                        <div class="icon-button ignore-disabled"
                                                                            (click)="onShowExerciseHistory(exercise);$event.stopPropagation()">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                                height="16" fill="currentColor"
                                                                                class="bi bi-graph-up" viewBox="0 0 16 16">
                                                                                <path fill-rule="evenodd"
                                                                                    d="M0 0h1v15h15v1H0V0Zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07Z" />
                                                                            </svg>
                                                                            Historie anzeigen
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="pause-wrapper-column">
                                                                    <div class="pause-wrapper">
                                                                        <label for="pause-duration-input"
                                                                            class="label">Pause</label>
                                                                        <div class="light small d-inline-block">
                                                                            <input
                                                                                [disabled]="readOnlyMode || selectedSession?.isTracked"
                                                                                min="0" max="600"
                                                                                class="input small form-control white-input number-input display-inlineblock pause-input"
                                                                                [(ngModel)]="exercise.pauseDuration"
                                                                                [placeholder]="getExerciseById(exercise.exerciseId)?.getDefaultPauseDuration() ?? 0"
                                                                                (click)="$event.stopPropagation()"
                                                                                type="number">
                                                                            <div class="display-inlineblock paddingleft-10 only-desktop"> s
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="isExerciseExpanded(exercise)" class="col exercise-table-wrapper">
                                                            <table (click)="$event.stopPropagation()" class="exercise-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th></th>
                                                                        <th>#</th>
                                                                        <th
                                                                            *ngFor="let parameter of exercise.setParameters">
                                                                            <div class="parameter-container {{parameter}}">
                                                                                <ng-container
                                                                                    *ngIf="parameter == setParameter.note">
                                                                                    <div class="label">
                                                                                        {{setParameter2SubHeadingMapping[parameter]}}
                                                                                    </div>
                                                                                    <svg width="1em" height="1em"
                                                                                        viewBox="0 0 16 16"
                                                                                        class="bi bi-trash float-right"
                                                                                        fill="currentColor"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        (click)="onRemoveParameterFromExerciseTableClick(parameter, exercise); $event.stopPropagation()">
                                                                                        <path
                                                                                            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                                        <path fill-rule="evenodd"
                                                                                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                                                    </svg>
                                                                                    <input [disabled]="readOnlyMode"
                                                                                        maxlength="10"
                                                                                        class="input small form-control white-input input-noteunit"
                                                                                        [(ngModel)]="exercise.noteUnit"
                                                                                        type="text" (click)="$event.stopPropagation()">
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="parameter != setParameter.note">
                                                                                    <div class="label">
                                                                                        {{setParameter2SubHeadingMapping[parameter]}}
                                                                                    </div>
                                                                                    <div>
                                                                                        {{setParameter2LabelUnitMapping[parameter] | unitConversion: languageService.selectedUnitSystem}}
                                                                                    </div>
                                                                                    <div
                                                                                        *ngIf="!setParameter2LabelMapping[parameter]">
                                                                                        -</div>
                                                                                    <svg width="1em" height="1em"
                                                                                        viewBox="0 0 16 16"
                                                                                        class="bi bi-trash"
                                                                                        fill="currentColor"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        (click)="onRemoveParameterFromExerciseTableClick(parameter, exercise);$event.stopPropagation()">
                                                                                        <path
                                                                                            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                                        <path fill-rule="evenodd"
                                                                                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                                                    </svg>
                                                                                </ng-container>
                                                                            </div>
                                                                        </th>
                                                                        <th *ngIf="trainingPlan.isPeriodicPlan && environment.firebaseProjectId == 'traindoo-app'" class="setparameter-btn-group">
                                                                        </th>
                                                                        <th *ngIf="trainingPlan.isPeriodicPlan && environment.firebaseProjectId == 'traindoo-app'" class="setparameter-btn-group">
                                                                        </th>
                                                                        <div class="setparameter-btn-group">
                                                                            <div
                                                                                class="d-inline-block wrapper-setparameter-selection">
                                                                                <button type="button"
                                                                                    class="icon-button"
                                                                                    id="dropdownSetParameters"
                                                                                    [matMenuTriggerFor]="setParameterDropdownMenu" (click)="$event.stopPropagation()">
                                                                                    <svg width="1em" height="1em"
                                                                                        viewBox="0 0 16 16"
                                                                                        class="bi bi-plus"
                                                                                        fill="currentColor"
                                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                                        <path fill-rule="evenodd"
                                                                                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                                                    </svg>
                                                                                </button>
                                                                                <mat-menu #setParameterDropdownMenu="matMenu">
                                                                                    <div class="setparameter-menu">
                                                                                        <ng-container *ngFor="let parameter of getSelectableSetParameters(exercise)">
                                                                                            <button mat-menu-item class="dropdown-item white"
                                                                                                type="button"
                                                                                                (click)="onParameterSetSelected(parameter, exercise)">{{setParameter2LabelMapping[parameter]}}
                                                                                                {{setParameter2UnitMapping[parameter]}}</button>
                                                                                        </ng-container>
                                                                                    </div>
                                                                                </mat-menu>
                                                                            </div>
                                                                        </div>
                                                                    </tr>
                                                                </thead>
                                                                <tbody cdkDropList (cdkDropListDropped)="setDropped(exercise, $event)">
                                                                    <tr cdkDrag cdkDragLockAxis="y" *ngFor="let set of exercise.sets; let i = index"
                                                                        class="{{superSet.exercises.length > 1 && set.reps == 0 ? 'hidden-set' : ''}}">
                                                                        <td cdkDragHandle>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-grip-vertical" viewBox="0 0 16 16">
                                                                                <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                                            </svg>
                                                                        </td>
                                                                        <td [matMenuTriggerFor]="menu"
                                                                            class="{{isPartOfDropset(set, exercise?.sets, i) ? 'dropset-line' : ''}}" (click)="$event.stopPropagation()">
                                                                            <ng-container
                                                                                *ngIf="set.isWarmupSet || set.isDropset; else defaultSet">
                                                                                <div tooltip="'W' kennzeichnet einen Warmup-Satz. Dieser Satz fließt nicht in deine Statistik mit ein."
                                                                                    class="setmode-indicator"
                                                                                    *ngIf="set.isWarmupSet">
                                                                                    W
                                                                                </div>
                                                                                <div tooltip="'D' kennzeichnet einen Dropset."
                                                                                    class="setmode-indicator"
                                                                                    *ngIf="set.isDropset">
                                                                                    D
                                                                                </div>
                                                                            </ng-container>
                                                                            <ng-template #defaultSet>
                                                                                <span tooltip="Satz-Typ wählen">
                                                                                    {{exercise.getRealSetIndex(set) + 1}}
                                                                                </span>
                                                                            </ng-template>
                                                                            <mat-menu #menu="matMenu">
                                                                                <button mat-menu-item
                                                                                    (click)="setSetMode(exercise, set, 'default', i)">Normaler
                                                                                    Satz</button>
                                                                                <button
                                                                                    *ngIf="canSelectWarmupSet(exercise, set)"
                                                                                    mat-menu-item
                                                                                    (click)="setSetMode(exercise, set, 'warmup', i)">Warmup-Satz</button>
                                                                                <button
                                                                                    *ngIf="superSet.exercises?.length <= 1 && !set.isDropset && i > 0"
                                                                                    mat-menu-item
                                                                                    (click)="setSetMode(exercise, set, 'dropset', i)">Drop-Set</button>
                                                                            </mat-menu>
                                                                        </td>
                                                                        <td
                                                                            *ngFor="let parameter of exercise.setParameters">
                                                                            <input
                                                                                #numberInput
                                                                                [disabled]="readOnlyMode || selectedSession?.isTracked"
                                                                                *ngIf="(parameter != setParameter.pauseDuration || !isFirstPartOfDropset(set, exercise?.sets, i)) && parameter != setParameter.timeUnderTension && parameter != setParameter.reps && parameter != setParameter.rir && parameter != setParameter.rpe && parameter != setParameter.time && parameter != setParameter.pace && parameter != setParameter.weight && parameter != setParameter.heartRate && parameter != setParameter.note && !hasSetParameterFormulaInput(parameter)"
                                                                                min="0"
                                                                                class="input small form-control white-input"
                                                                                [step]="setParameter2ValueStepMapping[parameter]"
                                                                                [(ngModel)]="set[parameter]" type="number"
                                                                                (keyup.enter)="addSet(exercise, set, numberInput)" (click)="$event.stopPropagation()">
                                                                            <input
                                                                                #tutInput
                                                                                [disabled]="readOnlyMode || selectedSession?.isTracked"
                                                                                *ngIf="parameter == setParameter.timeUnderTension"
                                                                                class="input small form-control white-input masked-input"
                                                                                [(ngModel)]="set[parameter]"
                                                                                (input)="tutChange(set)" type="text"
                                                                                [pattern]="trainingPlanEditorHelper.tutPattern"
                                                                                placeholder="X-X-X-X"
                                                                                (focusout)="trainingPlanEditorHelper.checkTUTINput(set)"
                                                                                (keyup.enter)="addSet(exercise, set, tutInput)" (click)="$event.stopPropagation()">
                                                                            <input
                                                                                #rirInput
                                                                                [disabled]="readOnlyMode || selectedSession?.isTracked"
                                                                                *ngIf="parameter == setParameter.rir"
                                                                                min="0"
                                                                                class="input small form-control white-input"
                                                                                (input)="setSetRir(set, $event.target.value)"
                                                                                type="text"
                                                                                [pattern]="getRegexForSetParameter(parameter)"
                                                                                [value]="getSetRir(set)"
                                                                                (keyup.enter)="addSet(exercise, set, rirInput)" (click)="$event.stopPropagation()">
                                                                            <input
                                                                                #rpeInput
                                                                                [disabled]="readOnlyMode || selectedSession?.isTracked"
                                                                                *ngIf="parameter == setParameter.rpe"
                                                                                min="0"
                                                                                class="input small form-control white-input"
                                                                                (input)="setSetRpe(set, $event.target.value)"
                                                                                type="text"
                                                                                [pattern]="getRegexForSetParameter(parameter)"
                                                                                [value]="getSetRpe(set)"
                                                                                (keyup.enter)="addSet(exercise, set, rpeInput)" (click)="$event.stopPropagation()">
                                                                            <input
                                                                                #noteInput
                                                                                [disabled]="readOnlyMode || selectedSession?.isTracked"
                                                                                *ngIf="parameter == setParameter.note"
                                                                                class="input medium form-control white-input"
                                                                                [(ngModel)]="set[parameter]"
                                                                                (input)="noteChanged(set, $event.target.value)"
                                                                                type="text"
                                                                                (keyup.enter)="addSet(exercise, set, noteInput)" (click)="$event.stopPropagation()">
                                                                            <input
                                                                                #timeinput
                                                                                [disabled]="readOnlyMode || selectedSession?.isTracked"
                                                                                [pattern]="getRegexForSetParameter(parameter)"
                                                                                *ngIf="parameter == setParameter.time"
                                                                                class="input medium form-control white-input {{hasValueRange(timeinput?.value) ? 'range-input' : ''}}"
                                                                                (focusout)="setTime(set, $event.target.value)"
                                                                                [value]="getTime(set)" type="text"
                                                                                placeholder="hh:mm:ss(-hh:mm:ss)"
                                                                                (keyup.enter)="addSet(exercise, set, timeinput)" (click)="$event.stopPropagation()">
    
                                                                            <div tooltip="{{getCalculatedFormulaValue(set, exercise, parameter, setParameter2LabelUnitMapping[parameter])}}"
                                                                                *ngIf="hasSetParameterFormulaInput(parameter)"
                                                                                class="progression-preview {{isVariableInputValid(exercise.sets, set, parameter) ? 'valid' : 'invalid'}}">
                                                                                <ng-container
                                                                                    *ngIf="isFormulaValueAvailable(set, parameter); else noformulatemplate">
                                                                                    <div class="formula-preview"
                                                                                        (click)="formulInputTriggerDiv.click(); $event.stopPropagation();">
                                                                                        <div class="value-preview">
                                                                                            {{getValueByFormula(set,
                                                                                            parameter)}}%</div>
                                                                                        <div
                                                                                            class="variable-preview small-text">
                                                                                            {{getVariableNameByFormula(exercise,
                                                                                            set, parameter)}}</div>
                                                                                    </div>
                                                                                    <div class="unit-preview small-text"
                                                                                        (click)="switchFormulaInput(set, parameter, menuTrigger);$event.stopPropagation()">
                                                                                        fx
                                                                                    </div>
                                                                                </ng-container>
                                                                                <ng-template #noformulatemplate>
                                                                                    <div class="formula-preview">
                                                                                        <input #noformulainput
                                                                                            (click)="$event.stopPropagation()"
                                                                                            [placeholder]="getPlaceholderForSetParameter(parameter)"
                                                                                            [disabled]="readOnlyMode || selectedSession?.isTracked"
                                                                                            class="input small form-control white-input no-formula-input {{hasValueRange(noformulainput.value) ? 'range-input' : ''}}"
                                                                                            [step]="setParameter2ValueStepMapping[parameter]"
                                                                                            (focusout)="setSetValue(set, parameter, $event.target.value, i, exercise)"
                                                                                            [value]="getSetValue(set, parameter, exercise)"
                                                                                            type="text"
                                                                                            [pattern]="getRegexForSetParameter(parameter)"
                                                                                            (keyup.enter)="addSet(exercise, set, noformulainput)"
                                                                                            oninput="this.value = this.value.toUpperCase()">
                                                                                    </div>
                                                                                    <div *ngIf="parameter != setParameter.pace; else paceLabel"
                                                                                        class="unit-preview small-text"
                                                                                        (click)="switchFormulaInput(set, parameter, menuTrigger);$event.stopPropagation()">
                                                                                        <ng-container
                                                                                            *ngIf="parameter == setParameter.pace500; else defaultFormulaSwitch">
                                                                                            <div class="unit-preview small-text"
                                                                                                (click)="switchFormulaInput(set, parameter, menuTrigger);$event.stopPropagation()">
                                                                                                <div>
                                                                                                    <div>min</div>
                                                                                                    <mat-divider></mat-divider>
                                                                                                    <div>500m</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </ng-container>
                                                                                        <ng-template #defaultFormulaSwitch>
                                                                                            {{setParameter2LabelUnitMapping[parameter] | unitConversion: languageService.selectedUnitSystem}}
                                                                                        </ng-template>
                                                                                    </div>
                                                                                    <ng-template #paceLabel>
                                                                                        <div class="unit-preview small-text"
                                                                                            (click)="switchFormulaInput(set, parameter, menuTrigger);$event.stopPropagation()">
                                                                                            <div>
                                                                                                <div>min</div>
                                                                                                <mat-divider></mat-divider>
                                                                                                <div>km</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </ng-template>
                                                                                </ng-template>
                                                                            </div>
                                                                            <div #formulInputTriggerDiv
                                                                                #menuTrigger="matMenuTrigger"
                                                                                [matMenuTriggerFor]="formulaInputMenu"
                                                                                (menuOpened)="setVariableSuggestions(exercise.sets, '', parameter, i); percentageinput.focus()" (click)="$event.stopPropagation()">
                                                                            </div>
                                                                            <mat-menu #formulaInputMenu="matMenu">
                                                                                <div class="formula-input">
                                                                                    <input placeholder="50%"
                                                                                        #percentageinput
                                                                                        (click)="$event.stopPropagation()"
                                                                                        (keydown.tab)="$event.stopPropagation()"
                                                                                        (focusout)="$event.stopPropagation()"
                                                                                        [disabled]="readOnlyMode || selectedSession?.isTracked"
                                                                                        min="1" max="999"
                                                                                        class="input small form-control d-inline-block textalign-center percentage-input"
                                                                                        [placeholder]="getPercentageBySet(set, parameter)"
                                                                                        type="number" step=".01"
                                                                                        (keyup.enter)="submitFormulaInput(set, percentageinput, variableinput, parameter, menuTrigger, i, exercise)"
                                                                                        (keydown)="onPercentageInputKeyDown($event, variableinput)">
                                                                                    %
                                                                                    <input placeholder="Var" #variableinput
                                                                                        (click)="$event.stopPropagation()"
                                                                                        (keydown.tab)="$event.stopPropagation()"
                                                                                        (focusout)="$event.stopPropagation()"
                                                                                        [disabled]="readOnlyMode || selectedSession?.isTracked"
                                                                                        class="input small form-control masked-input d-inline-block variable-input"
                                                                                        [placeholder]="getVariableNameBySet(set, parameter, false)"
                                                                                        type="text"
                                                                                        (keyup.enter)="submitFormulaInput(set, percentageinput, variableinput, parameter, menuTrigger, i, exercise)"
                                                                                        (input)="setVariableSuggestions(exercise.sets, $event.target.value, parameter, i, variableSuggestionsMenuTrigger, rmSuggestionsMenuTrigger, setRefSuggestionsMenuTrigger, cardioZoneSuggestionsMenuTrigger)"
                                                                                        oninput="this.value = this.value.toUpperCase()">
                                                                                    <button
                                                                                        class="btn-link button-submitinput"
                                                                                        (click)="submitFormulaInput(set, percentageinput, variableinput, parameter, menuTrigger, i, exercise);$event.stopPropagation()">
                                                                                        <svg width="1em" height="1em"
                                                                                            viewBox="0 0 16 16"
                                                                                            class="bi bi-check2"
                                                                                            fill="currentColor"
                                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                                            <path fill-rule="evenodd"
                                                                                                d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                                        </svg>
                                                                                    </button>
                                                                                </div>
                                                                                <div class="spacer-10"></div>
                                                                                <button
                                                                                    #rmSuggestionsMenuTrigger="matMenuTrigger"
                                                                                    class="suggestions-mat-menu-trigger {{environment.firebaseProjectId != 'traindoo-app' && rmSuggestions?.length > 0 ? '' : 'hide'}}"
                                                                                    mat-menu-item
                                                                                    [matMenuTriggerFor]="rmSuggestionsMenu">x-Rep-Max
                                                                                    auswählen</button>
                                                                                <button
                                                                                    #setRefSuggestionsMenuTrigger="matMenuTrigger"
                                                                                    class="suggestions-mat-menu-trigger {{setRefSuggestions?.length > 0 ? '' : 'hide'}}"
                                                                                    mat-menu-item
                                                                                    [matMenuTriggerFor]="setRefSuggestionsMenu">Referenzsatz
                                                                                    auswählen</button>
                                                                                <button
                                                                                    #variableSuggestionsMenuTrigger="matMenuTrigger"
                                                                                    class="suggestions-mat-menu-trigger {{environment.firebaseProjectId != 'traindoo-app' && filteredVariableSuggestions?.length > 0 ? '' : 'hide'}}"
                                                                                    mat-menu-item
                                                                                    [matMenuTriggerFor]="variableSuggestionsMenu">Variable auswählen</button>
                                                                                <button
                                                                                    #cardioZoneSuggestionsMenuTrigger="matMenuTrigger"
                                                                                    class="suggestions-mat-menu-trigger {{cardioZoneSuggestions?.length > 0 ? '' : 'hide'}}"
                                                                                    mat-menu-item
                                                                                    [matMenuTriggerFor]="cardioZoneSuggestionsMenu">Belastungszone auswählen</button>
                                                                                <button
                                                                                    #oneRmSuggestionsMenuTrigger="matMenuTrigger"
                                                                                    class="suggestions-mat-menu-trigger {{environment.firebaseProjectId == 'traindoo-app' && parameter == setParameter.weight ? '' : 'hide'}}"
                                                                                    mat-menu-item
                                                                                    [matMenuTriggerFor]="oneRmSuggestionsMenu">1RM auswählen</button>
                                                                                <button
                                                                                    #oneRmGoalSuggestionsMenuTrigger="matMenuTrigger"
                                                                                    class="suggestions-mat-menu-trigger {{environment.firebaseProjectId == 'traindoo-app' && parameter == setParameter.weight ? '' : 'hide'}}"
                                                                                    mat-menu-item
                                                                                    [matMenuTriggerFor]="oneRmGoalSuggestionsMenu">Zyklusziel auswählen</button>
                                                                            </mat-menu>
                                                                            <mat-menu #variableSuggestionsMenu="matMenu">
                                                                                <ng-container
                                                                                    *ngFor="let variable of filteredVariableSuggestions">
                                                                                    <button class="mat-mdc-menu-item"
                                                                                        (click)="setSelectedVariable(variable, set, percentageinput, variableinput, parameter, i, exercise);$event.stopPropagation()">{{variable.name}}</button>
                                                                                </ng-container>
                                                                            </mat-menu>
                                                                            <mat-menu #rmSuggestionsMenu="matMenu">
                                                                                <ng-container
                                                                                    *ngFor="let rmSuggestion of rmSuggestions">
                                                                                    <button class="mat-mdc-menu-item"
                                                                                        (click)="setSelectedRM(rmSuggestion, set, percentageinput, getPercentageBySet(set, parameter), variableinput, parameter, i, exercise);$event.stopPropagation()">{{rmSuggestion}}</button>
                                                                                </ng-container>
                                                                            </mat-menu>
                                                                            <mat-menu #oneRmSuggestionsMenu="matMenu">
                                                                                <button class="mat-mdc-menu-item" (click)="setSelectedRM('1RM', set, percentageinput, getPercentageBySet(set, parameter), variableinput, parameter, i, exercise);$event.stopPropagation()">Aus letzten Einheiten berechnen</button>
                                                                                <button class="mat-mdc-menu-item" *ngIf="exercise.getRealSetIndex(set) > 0" (click)="setSelectedRM('1RM_TOPSET', set, percentageinput, getPercentageBySet(set, parameter), variableinput, parameter, i, exercise);$event.stopPropagation()">Aus aktuellem Topset berechnen</button>
                                                                                <ng-container *ngFor="let variable of oneRmTrainingVariables">
                                                                                    <button class="mat-mdc-menu-item" (click)="setSelectedVariable(variable, set, percentageinput, variableinput, parameter, i, exercise);$event.stopPropagation()">{{variable.name}}</button>
                                                                                </ng-container>
                                                                            </mat-menu>
                                                                            <mat-menu #oneRmGoalSuggestionsMenu="matMenu">
                                                                                <ng-container *ngFor="let variable of oneRmGoalTrainingVariables">
                                                                                    <button class="mat-mdc-menu-item" (click)="setSelectedVariable(variable, set, percentageinput, variableinput, parameter, i, exercise);$event.stopPropagation()">{{variable.name}}</button>
                                                                                </ng-container>
                                                                            </mat-menu>
                                                                            <mat-menu #setRefSuggestionsMenu="matMenu">
                                                                                <ng-container
                                                                                    *ngFor="let setRefSuggestion of setRefSuggestions">
                                                                                    <button class="mat-mdc-menu-item"
                                                                                        (click)="setSelectedRM(setRefSuggestion.setIndex, set, percentageinput, getPercentageBySet(set, parameter), variableinput, parameter, i, exercise);$event.stopPropagation()">{{setRefSuggestion.name}}</button>
                                                                                </ng-container>
                                                                            </mat-menu>
                                                                            <mat-menu #cardioZoneSuggestionsMenu="matMenu">
                                                                                <ng-container
                                                                                    *ngFor="let cardioZone of cardioZoneSuggestions">
                                                                                    <button class="mat-mdc-menu-item"
                                                                                        (click)="setSelectedCardioZone(cardioZone, set, percentageinput, variableinput, parameter, i, exercise);$event.stopPropagation()">{{cardioZone.name}}</button>
                                                                                </ng-container>
                                                                            </mat-menu>
                                                                        </td>
                                                                        <td *ngIf="environment.firebaseProjectId == 'traindoo-app'">
                                                                            <div *ngIf="selectedSession?.isTracked || !trainingService.rpeTableCopyResult" class="icon-button display-inlineblock marginleft-10 button-exerciserecording" (click)="onOpenRpeDialog(exercise, set);$event.stopPropagation()">
                                                                                <i class="bi bi-table fontsize-18"></i>
                                                                            </div>
                                                                            <div *ngIf="trainingService.rpeTableCopyResult && !readOnlyMode && !selectedSession?.isTracked" class="icon-button display-inlineblock marginleft-10 button-exerciserecording" (click)="onPasteRpeTableCopyResult(exercise, set);$event.stopPropagation()" tooltip="Werte einfügen">
                                                                                <i class="bi bi-clipboard fontsize-18"></i>
                                                                            </div>
                                                                        </td>
                                                                        <td *ngIf="trainingPlan.isPeriodicPlan && environment.firebaseProjectId == 'traindoo-app'">
                                                                            <div class="icon-button display-inlineblock marginleft-10 button-exerciserecording {{set.videoRecordingRequested ? 'active bi-highlight' : ''}}" (click)="toggleExerciseRecordingRequestedForSet(exercise, set);$event.stopPropagation()">
                                                                                <i class="bi bi-camera-video"></i>
                                                                            </div>
                                                                        </td>
                                                                        <td class="remove-set">
                                                                            <i class="bi bi-x-lg fontsize-20" (click)="removeSet(exercise, set);$event.stopPropagation()"></i>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <div class="icon-button display-inlineblock"
                                                                                (click)="addSet(exercise);$event.stopPropagation()" tooltip="&crarr; Enter">
                                                                                <svg width="1em" height="1em"
                                                                                    viewBox="0 0 16 16" class="bi bi-plus"
                                                                                    fill="currentColor"
                                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd"
                                                                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                                                </svg>
                                                                            </div>
                                                                        </td>
                                                                        <td colspan="2">
                                                                            <div class=""
                                                                                *ngIf="isExerciseExpanded(exercise) && exercise.setParameters.includes('time')"
                                                                                (click)="onAutoRunChanged(exercise);$event.stopPropagation()"
                                                                                tooltip="Aktivieren, wenn die App nach einem Satz oder einer Pause automatisch zum nächsten Satz springen soll.">
                                                                                <input
                                                                                    [disabled]="readOnlyMode || selectedSession?.isTracked"
                                                                                    class="input checkbox" type="checkbox"
                                                                                    [checked]="exercise.autoRun">
                                                                                <span class="label">&nbsp;&nbsp;Auto-Durchlauf</span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div *ngIf="isExerciseExpanded(exercise)" class="{{environment.firebaseProjectId != 'traindoo-app' ? 'col' : 'col-12'}}">
                                                            <div class="col-12" *ngIf="superSet.exercises.length === 1 && isExerciseExpanded(exercise) && environment.firebaseProjectId != 'traindoo-app'">
                                                                <div class="row">
                                                                    <div class="col">
                                                                        <div class="spacer-10"></div>
                                                                        <div class="videorequest-header">
                                                                            <button
                                                                                [disabled]="readOnlyMode || selectedSession?.isTracked"
                                                                                class="tab-button videorequest-button"
                                                                                tooltip="Videoaufnahme von Ausführung der Übung anfordern"
                                                                                [ngClass]="{'active': exercise.videoRecordingRequest.active}"
                                                                                (click)="toggleVideoRecordingRequest(exercise);$event.stopPropagation()">
                                                                                <i class="bi bi-camera-video"></i>
                                                                            </button>
                                                                            <div class="header-text">
                                                                                <div class="label">Videoaufnahme anfordern</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class=""
                                                                            *ngIf="exercise.videoRecordingRequest.active">
                                                                            <div class="spacer-10"></div>
                                                                            <div
                                                                                class="d-inline-block wrapper-frequency-selection">
                                                                                <button [matMenuTriggerFor]="frequencyLabelsDropdownMenu"
                                                                                    class="button-filled white small dropdown-toggle margin-inbetween"
                                                                                    type="button" id="dropdownFrequency" (click)="$event.stopPropagation()">{{frequency2LabelMapping[exercise.videoRecordingRequest.frequency]}}</button>
                                                                                <mat-menu #frequencyLabelsDropdownMenu="matMenu">
                                                                                    <ng-container *ngFor="let frequency of frequencyLabels">
                                                                                        <button mat-menu-item
                                                                                            [disabled]="readOnlyMode || selectedSession?.isTracked"
                                                                                            class="dropdown-item white"
                                                                                            type="button"
                                                                                            (click)="onFrequencySelectionChanged(frequency, exercise)">{{frequency}}</button>
                                                                                    </ng-container>
                                                                                </mat-menu>
                                                                            </div>
                                                                            <div class="spacer-10"></div>
                                                                            <div class="col-12">
                                                                                <div class="label">Aufnahme-Hinweis</div>
                                                                                <app-md-editor-legacy
                                                                                    [readOnlyMode]="readOnlyMode || selectedSession?.isTracked"
                                                                                    id="editor-note"
                                                                                    class="single-line mdeditor-whitefield"
                                                                                    [placeHolder]="videoRequestNotePlaceholder"
                                                                                    (valueChanged)="onEditorValueVideoRequestNoteChanged($event, exercise)"
                                                                                    [inputValue]="exercise.videoRecordingRequest.hint" (click)="$event.stopPropagation()">
                                                                                </app-md-editor-legacy>
                                                                            </div>
                                                                            <div class="spacer-10"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="!exercise.videoRecordingRequest.active" class="col-12 hint-column">
                                                                <ng-container *ngTemplateOutlet="hintEditor"></ng-container>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="isExerciseExpanded(exercise) && exercise.videoRecordingRequest.active" class="col-12 hint-column">
                                                            <div class="col">
                                                                <ng-container *ngTemplateOutlet="hintEditor"></ng-container>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ng-template #hintEditor>
                                                        <div class="">
                                                            <div class="label d-xl-none">Hinweis</div>
                                                            <app-md-editor-legacy
                                                                [readOnlyMode]="readOnlyMode || selectedSession?.isTracked"
                                                                id="editor-note" class="mdeditor-whitefield"
                                                                [placeHolder]="notePlaceholder"
                                                                (valueChanged)="onEditorValueNoteChanged($event, exercise)"
                                                                [inputValue]="exercise.note" (click)="$event.stopPropagation()">
                                                            </app-md-editor-legacy>
                                                        </div>
                                                    </ng-template>
                                                    <div *ngIf="isExerciseExpanded(exercise) && environment.firebaseProjectId != 'traindoo-app'">
                                                        <div class="spacer-10"></div>
                                                        <div class="icon-button display-inlineblock"
                                                            (click)="toggleExpandedExercise(exercise, true);$event.stopPropagation()">
                                                            Schließen
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div *ngIf="exercise.exerciseId" class="ignore-disabled exercise-info-button"
                                                    (click)="onShowExerciseInfo(exercise);$event.stopPropagation()"
                                                    tooltip="Übungsdetails anzeigen">
                                                    <div class="icon-button">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            fill="currentColor" class="bi bi-info-circle"
                                                            viewBox="0 0 16 16">
                                                            <path
                                                                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            <path
                                                                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div *ngIf="superSet.exercises?.length <= 1"
                                                    class="alternative-exercise-container d-flex justify-content-center"
                                                    (click)="$event.stopPropagation()" [matMenuTriggerFor]="alternativeMenu"
                                                    tooltip="{{exercise.alternativeExerciseId ? 'Ausgewählte Alternativübung: ' + getExerciseById(exercise.alternativeExerciseId)?.name?.GetValue(languageService.selectedLanguageCode) : 'Alternativübung auswählen'}}">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="16"
                                                        fill="currentColor" class="bi bi-shuffle" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd"
                                                            d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.624 9.624 0 0 0 7.556 8a9.624 9.624 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.595 10.595 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.624 9.624 0 0 0 6.444 8a9.624 9.624 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5z" />
                                                        <path
                                                            d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192zm0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192z" />
                                                    </svg>
                                                    <div class="alternative-exercise-thumbnail"
                                                        *ngIf="exercise.alternativeExerciseId">
                                                        <img draggable="false"
                                                            *ngIf="getExerciseById(exercise.alternativeExerciseId)?.thumbnailDownloadURL"
                                                            (error)="utilityService.onImageErrorHide($event)" loading="lazy"
                                                            src="{{getExerciseById(exercise.alternativeExerciseId)?.thumbnailDownloadURL}}">
                                                        <div
                                                            *ngIf="!getExerciseById(exercise.alternativeExerciseId)?.thumbnailDownloadURL">
                                                            <img loading="lazy" draggable="false"
                                                                (error)="utilityService.onImageErrorHide($event)"
                                                                src="{{ (trainingService.getExerciseThumbnailUrl(getExerciseById(exercise.alternativeExerciseId)) | async) }}">
                                                        </div>
                                                    </div>
                                                </div>
                                                <mat-menu #alternativeMenu="matMenu">
                                                    <button mat-menu-item *ngIf="exercise.alternativeExerciseId"
                                                        (click)="onRemoveAlternativeExercise(exercise)">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="16"
                                                            fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                            <path
                                                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                                            <path
                                                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                                        </svg>
                                                        Alternativübung entfernen
                                                    </button>
                                                    <button mat-menu-item (click)="onAddAlternativeExercise(exercise)">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="16"
                                                            fill="currentColor" class="bi bi-shuffle" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd"
                                                                d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.624 9.624 0 0 0 7.556 8a9.624 9.624 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.595 10.595 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.624 9.624 0 0 0 6.444 8a9.624 9.624 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5z" />
                                                            <path
                                                                d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192zm0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192z" />
                                                        </svg>
                                                        <ng-container
                                                            *ngIf="!exercise.alternativeExerciseId; else replaceAlternative;">Alternativübung
                                                            auswählen</ng-container>
                                                        <ng-template #replaceAlternative>Alternativübung
                                                            austauschen</ng-template>
                                                    </button>
                                                </mat-menu>
                                                <div *ngIf="!readOnlyMode && !selectedSession?.isTracked" class="bottom-action-buttons">
                                                    <div class="float-right marginleft-10"
                                                        (click)="onRemoveExercise(selectedSession, exercise, superSet);$event.stopPropagation()"
                                                        tooltip="Übung entfernen">
                                                        <div class="icon-button">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                                <path
                                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                                                <path
                                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div class="float-right marginleft-10"
                                                        (click)="onCopyExercise(selectedSession, exercise, superSet);$event.stopPropagation()"
                                                        tooltip="Übung in diese Einheit oder eine andere Einheit kopieren.">
                                                        <div class="icon-button">
                                                            <svg width="16" height="16" viewBox="0 0 16 16"
                                                                class="bi bi-files" fill="currentColor"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd"
                                                                    d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z" />
                                                                <path
                                                                    d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div class="float-right"
                                                        (click)="onReplaceExercise(selectedSession, exercise, superSet);$event.stopPropagation()"
                                                        tooltip="Übung ersetzen">
                                                        <div class="icon-button">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                fill="currentColor" class="bi bi-arrow-repeat"
                                                                viewBox="0 0 16 16">
                                                                <path
                                                                    d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                                                                <path fill-rule="evenodd"
                                                                    d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button (mouseover)="mouseEnterExercise(exercise)"
                                                (touchstart)="mouseEnterExercise(exercise)"
                                                *ngIf="!readOnlyMode && !draggingGroup && !selectedSession?.isTracked && !selectedSession?.deleted && !exercise?.deleted && (!superSet.isLast || exerciseIndex < superSet.exercises.length - 1)"
                                                (click)="onSetSuperSetConnection(exercise, superSet)"
                                                class="tab-button super-set-connect-button {{exerciseIndex < superSet.exercises.length - 1 ? 'active' : 'not-connected'}} {{hoveredExercise === exercise ? 'visible' : 'hidden'}}">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                    fill="currentColor" class="bi bi-link" viewBox="0 0 16 16"
                                                    transform="rotate(90)">
                                                    <path
                                                        d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                                                    <path
                                                        d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div *ngIf="!draggingGroup && !trainingPlan.isTemplate && exercise.exerciseId && (!trainingPlanHistoryCollapsed || historyAlwaysOpen)"
                                            class="col-12 ignore-disabled history-column {{exercise.groupHeadingTranslation != null ? 'exercise-history-with-heading' : ''}}">
                                            <div class="spacer-25"></div>
                                            <app-tracked-exercise-table [plannedExercise]="exercise"
                                                [externalSelectedSession]="selectedHistoryTrackedSession"
                                                [user]="user"></app-tracked-exercise-table>
                                            <div class="spacer-20"></div>
                                        </div>
                                    </div>
                                    <div *ngIf="copyExercise && !readOnlyMode && !selectedSession?.isTracked"
                                        (click)="onPasteExercise(selectedSession, exercise);$event.stopPropagation()" class="paste-button">
                                        <div class="icon-button" tooltip="Einfügen">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                                <path
                                                    d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                <path
                                                    d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                            </svg>
                                            Einfügen
                                        </div>
                                    </div>
                                    <div *ngIf="copyExercisesListInternal && !readOnlyMode && !selectedSession?.isTracked"
                                        (click)="onPasteGroupHeading(selectedSession, exercise);$event.stopPropagation()" class="paste-button">
                                        <div class="icon-button" tooltip="Einfügen">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                                <path
                                                    d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                <path
                                                    d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                            </svg>
                                            Gruppe einfügen
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="notAvailableExercises?.length > 0">
                    <div class="spacer-25"></div>
                    <mat-divider></mat-divider>
                    <div class="row" *ngFor="let dummyExercise of notAvailableExercises">
                        <div class="spacer-25"></div>
                        <div class="col">
                        </div>
                        <div *ngIf="!draggingGroup && !trainingPlan.isTemplate && (!trainingPlanHistoryCollapsed || historyAlwaysOpen)" class="col-12 ignore-disabled history-column">
                            <app-tracked-exercise-table [navigationDisabled]="true" [plannedExercise]="dummyExercise"
                                [externalSelectedSession]="selectedHistoryTrackedSession"
                                [user]="user"></app-tracked-exercise-table>
                        </div>
                    </div>
                </ng-container>
                <div *ngIf="!readOnlyMode && !selectedSession?.isTracked && selectedSession?.exercises?.length > 0">
                    <div class="spacer-25"></div>
                    <div *ngIf="environment.firebaseProjectId != 'traindoo-app'; else traindooAddButtons" class="">
                        <div class="icon-button display-inlineblock marginleft-10 marginright-10"
                            (click)="addPlannedTrainingExercise()">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                            Übungen hinzufügen
                        </div>
                        <div class="icon-button display-inlineblock marginleft-10"
                            (click)="addExerciseGroupFromTemplate(selectedSession)">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                            <span class="only-desktop">Übungen aus Vorlage hinzufügen</span>
                            <span class="only-mobile">Aus Vorlage</span>
                        </div>
                    </div>
                    <ng-template #traindooAddButtons>
                        <div class="">
                            <div class="icon-button display-inlineblock marginleft-10 marginright-10"
                                (click)="addPlacholderTrainingExercise()">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>
                                <span class="only-desktop">Übung hinzufügen</span>
                                <span class="only-mobile">Hinzufügen</span>
                            </div>
                            <div class="icon-button display-inlineblock marginleft-10 marginright-10"
                                (click)="addPlannedTrainingExercise()">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>
                                Übungsauswahl
                            </div>
                            <div class="icon-button display-inlineblock marginleft-10"
                                (click)="addExerciseGroupFromTemplate(selectedSession)">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>
                                <span class="only-desktop">Übungen aus Vorlage hinzufügen</span>
                                <span class="only-mobile">Aus Vorlage</span>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="spacer-50"></div>
    </div>
</div>

<div id="exercises-table-dialog" class="dialog-wrapper show" *ngIf="showExerciseDialog">
    <div class="dialog layout-box">
        <div class="dialog-topbar">
            <button class="button-filled small create-Exercise" (click)="onCreateExercise()">Übung erstellen</button>
            <div class="icon-button float-right" (click)="onCancelSelection()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
                Schließen
            </div>
        </div>
        <div class="dialog-content-wrapper">
            <div class="row justify-content-center">
                <app-exercises-table [TrainingExercises]="getTrainingExercises()"
                    (multipleTrainingExercisesSelected)="onTrainingExerciseMultiSelectionChanged($event)"
                    (trainingExerciseSelected)="onTrainingExerciseSelectionChanged($event)"
                    [multiselect]="!replacingExercise && !alternativeExercise"></app-exercises-table>
            </div>
        </div>
        <div class="dialog-bottombar" mat-dialog-actions>
            <div cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="selectedTrainingExercisesDrop($event)" class="selected-exercises-wrapper">
                <div cdkDrag cdkDragLockAxis="x" class="selected-exercise" *ngFor="let exercise of selectedTrainingExercises">
                    <div class="remove-exercise-button link" (click)="removeSelectedExercise(exercise)">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                    <div class="thumbnail-image">
                        <img draggable="false" *ngIf="exercise.thumbnailDownloadURL" loading="lazy"
                            src="{{exercise.thumbnailDownloadURL}}">
                        <div *ngIf="!exercise.thumbnailDownloadURL">{{
                            (trainingService.getExerciseThumbnailUrl(exercise) | async) }}</div>
                    </div>
                </div>
            </div>
            <button *ngIf="selectedTrainingExercises?.length > 0" class="button-filled small select-exercises"
                (click)="onTakeSelection(selectedTrainingExercises, positionExercise)">Auswahl übernehmen</button>
        </div>
    </div>
</div>