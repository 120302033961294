import { PLATFORM_ID, NgZone, LOCALE_ID, Inject } from '@angular/core';
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { TrainingService } from './services/training.service';
import { FcmService } from './services/fcm.service';
import { state } from '@angular/animations';
import { Component, OnInit, Sanitizer } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { ChatService } from './services/chat.service';
import { FirestoreService } from './services/firestore.service';
import { NavbarService } from './services/navbar.service';
import { NutritionService } from './services/nutrition.service';
import { UtilityService } from './services/utility.service';
import { Capacitor } from '@capacitor/core';
import { App as CapacitorApp, URLOpenListenerEvent } from '@capacitor/app';
import { Network } from '@capacitor/network';
// import * as firebase from 'firebase';
import { FirestoreNutritionPlanService } from './services/firestore-nutritionplan.service';
import { environment } from 'src/environments/environment';
import { environment as environmentGinoSingh } from 'src/environments/environment.ginosingh';
import { environment as environmentAestheticsAdvisor } from 'src/environments/environment.aestheticsadvisor';
import { environment as environmentTheNewyou } from 'src/environments/environment.thenewyou';
import { environment as environmentDerErsteMuskel } from 'src/environments/environment.dererstemuskel';
import { environment as environmentTobiasKurz } from 'src/environments/environment.tobiaskurz';
import { environment as environmentRecomat } from 'src/environments/environment.recomat';
import { environment as environmentTraindoo } from 'src/environments/environment.traindoo';
import { environment as environmentFitMitJasmin } from 'src/environments/environment.fitmitjasmin';
import { environment as environmentLukeFit } from 'src/environments/environment.lukefit';
import { environment as environmentGrow } from 'src/environments/environment.grow';
import { environment as environmentVencha } from 'src/environments/environment.venchacoaching';
import { MatDialog } from '@angular/material/dialog';

import { MarketingKitComponent } from './dialogs/marketing-kit/marketing-kit.component';
import { PaymentService } from './services/payment.service';
import { IndividualFirebase } from './app.module';
import { QuestionairesService } from './services/questionaires.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'nutrilize-web-app';

  public environment = environment

  public loggedIn: boolean;
  public chats = [];
  public platform = Capacitor.getPlatform()

  public currentUserUid: string = null;

  public tutorialVideos =
  [
    {name: "Erste Schritte und Lizenzverwaltung", url: this.domSanitizer.bypassSecurityTrustResourceUrl("https://www.loom.com/embed/44fd491900f74f6eb98f46545aa901ee")},
    {name: "Monitoring und Statistiken", url: this.domSanitizer.bypassSecurityTrustResourceUrl("https://www.loom.com/embed/0fbbe12d29424936b87c74ba4e5bb9ed")},
    {name: "Ernährungsprotokoll und Tagebuch", url: this.domSanitizer.bypassSecurityTrustResourceUrl("https://www.loom.com/embed/41f9b1e726744519b2b03246aa83f29d")},
    {name: "Metriken und Check-Ins", url: this.domSanitizer.bypassSecurityTrustResourceUrl("https://www.loom.com/embed/33676bec3dc74b2f938f675390f70a61")},
    {name: "Nährwertziele", url: this.domSanitizer.bypassSecurityTrustResourceUrl("https://www.loom.com/embed/abb4ba800a16418aa629b011c7a2a6ab")},
    {name: "Tages- und situationsabhängige Nährwertziele", url: this.domSanitizer.bypassSecurityTrustResourceUrl("https://www.loom.com/embed/9a0a3210ba7144fc91b814d44082a142")},
    {name: "Ernährungspläne", url: this.domSanitizer.bypassSecurityTrustResourceUrl("https://www.loom.com/embed/2e3a97d266094230be44bb5c2d8dfb5f")},
    {name: "Wiederholende Ernährungspläne", url: this.domSanitizer.bypassSecurityTrustResourceUrl("https://www.loom.com/embed/8e5915a2f42844f2b1dd12943ffed1d2")},
    {name: "Alternative Ernährungspläne", url: this.domSanitizer.bypassSecurityTrustResourceUrl("https://www.loom.com/embed/b11c1bdfdafb4b93b215e591e1354def")},
    {name: "Rezepte und Rezept-Pool", url: this.domSanitizer.bypassSecurityTrustResourceUrl("https://www.loom.com/embed/737cdd92d8f84546a1637fef7518493f")},
    {name: "Übungsdatenbank", url: this.domSanitizer.bypassSecurityTrustResourceUrl("https://www.loom.com/embed/b4eb995261af438199fab5bb049252a9"), subtitle: 'Das Trainingsmodul muss einmalig im Menü unter Account aktiviert werden.'},
    {name: "Trainingspläne", url: this.domSanitizer.bypassSecurityTrustResourceUrl("https://www.loom.com/embed/08c51099467146bf8a6a628ed2250de7")},
    {name: "Training Supersätze", url: this.domSanitizer.bypassSecurityTrustResourceUrl("https://www.loom.com/embed/9d6cdd5689db4039af857f71a1573aca")},
    {name: "Training AMRAPs", url: this.domSanitizer.bypassSecurityTrustResourceUrl("https://www.loom.com/embed/ec3df8f24d9b4133a016b22ab97774cb")},

  ]

  constructor(private mainFirebase: IndividualFirebase, public chatService: ChatService, public authService: AuthService, private paymentService: PaymentService, public router: Router, private activatedRoute: ActivatedRoute, public userService: FirestoreService, private trainingService: TrainingService, public utilityService: UtilityService, public navService: NavbarService, private nutritionService: NutritionService, private nutritionPlanService: FirestoreNutritionPlanService, private questionaireService: QuestionairesService, private fcmService: FcmService, private toastrService: ToastrService, private domSanitizer:DomSanitizer, public dialog: MatDialog, private ngZone: NgZone, @Inject(DOCUMENT) private document: Document)
  {
    var appId = localStorage.getItem('firebaseProjectId') ?? window.location.href.split('?appId=')[1]
    var isDarkMode = localStorage.getItem('darkMode') == 'true'

    if (appId != 'nutrilize-b4788') {
      if (appId == 'aesthetics-advisor') {
        environment.overwrite(environmentAestheticsAdvisor)
        AuthService.firebaseProjectIndividual = environmentAestheticsAdvisor.firebaseProjectIndividual
        document.documentElement.style.setProperty('--accentColor', '#E0C27D');
        document.documentElement.style.setProperty('--accentColorLight', '#ecdaaf');
        document.documentElement.style.setProperty('--accentColorDark', '#b69853');
        document.documentElement.style.setProperty('--accentColorHover', '#E0C27D');
      } else if (appId == 'gino-singh') {
        environment.overwrite(environmentGinoSingh)
        AuthService.firebaseProjectIndividual = environmentGinoSingh.firebaseProjectIndividual
        if (!isDarkMode) {
          document.documentElement.style.setProperty('--accentColor', '#333333');
          document.documentElement.style.setProperty('--accentColorLight', '#666666');
          document.documentElement.style.setProperty('--accentColorDark', '#000000');
          document.documentElement.style.setProperty('--accentColorHover', '#22222240');
          document.documentElement.style.setProperty('--textColor', '#000000');
          document.documentElement.style.setProperty('--textOnAccentColor', '#FFFFFF');
          document.documentElement.style.setProperty('--lightBackgroundColor', '#F6F6F6');
          document.documentElement.style.setProperty('--darkerBackgroundColor', 'rgb(230, 230, 230)');
          document.documentElement.style.setProperty('--darkestBackgroundColor', 'rgb(215, 215, 215)');
        } else {
          document.documentElement.style.colorScheme = 'dark';
          document.documentElement.style.setProperty('--accentColor', '#DDDDDD');
          document.documentElement.style.setProperty('--accentColorLight', '#FFFFFF');
          document.documentElement.style.setProperty('--accentColorDark', '#AAA');
          document.documentElement.style.setProperty('--accentColorHover', '#DDDDDD40');
          document.documentElement.style.setProperty('--textColor', '#FFFFFF');
          document.documentElement.style.setProperty('--textColorLight', '#CCCCCC');
          document.documentElement.style.setProperty('--textOnAccentColor', '#000000');
          document.documentElement.style.setProperty('--boxBackgroundColor', 'rgb(45, 45, 45)');
          document.documentElement.style.setProperty('--lightBackgroundColor', 'rgb(60, 60, 60)');
          document.documentElement.style.setProperty('--darkerBackgroundColor', 'rgb(75, 75, 75)');
          document.documentElement.style.setProperty('--darkestBackgroundColor', 'rgb(75, 75, 75)');
        }
      } else if (appId == 'the-newyou') {
        environment.overwrite(environmentTheNewyou)
        AuthService.firebaseProjectIndividual = environmentTheNewyou.firebaseProjectIndividual
        document.documentElement.style.setProperty('--accentColor', '#93A58B');
        document.documentElement.style.setProperty('--accentColorLight', '#bbcdb4');
        document.documentElement.style.setProperty('--accentColorDark', '#708468');
        document.documentElement.style.setProperty('--accentColorHover', '#455a3b77');
        document.documentElement.style.setProperty('--textColor', '#212529');
        document.documentElement.style.setProperty('--textOnAccentColor', '#FFFFFF');
      } else if (appId == 'der-erste-muskel') {
        environment.overwrite(environmentDerErsteMuskel)
        AuthService.firebaseProjectIndividual = environmentDerErsteMuskel.firebaseProjectIndividual
        document.documentElement.style.setProperty('--accentColor', '#AA0106');
        document.documentElement.style.setProperty('--accentColorLight', '#fe8f92');
        document.documentElement.style.setProperty('--accentColorDark', '#760105');
        document.documentElement.style.setProperty('--accentColorHover', '#aa010755');
        document.documentElement.style.setProperty('--textColor', '#212529');
        document.documentElement.style.setProperty('--textOnAccentColor', '#FFFFFF');
      } else if (appId == 'tobias-kurz') {
        environment.overwrite(environmentTobiasKurz)
        AuthService.firebaseProjectIndividual = environmentTobiasKurz.firebaseProjectIndividual
        document.documentElement.style.setProperty('--accentColor', '#AA0106');
        document.documentElement.style.setProperty('--accentColorLight', '#fe8f92');
        document.documentElement.style.setProperty('--accentColorDark', '#760105');
        document.documentElement.style.setProperty('--accentColorHover', '#aa010755');
        document.documentElement.style.setProperty('--textColor', '#212529');
        document.documentElement.style.setProperty('--textOnAccentColor', '#FFFFFF');
      } else if (appId == 'recomat-app') {
        environment.overwrite(environmentRecomat)
        AuthService.firebaseProjectIndividual = environmentRecomat.firebaseProjectIndividual
        document.documentElement.style.setProperty('--accentColor', '#09acc9');
        document.documentElement.style.setProperty('--accentColorLight', '#4fd1e8');
        document.documentElement.style.setProperty('--accentColorDark', '#0492ab');
        document.documentElement.style.setProperty('--accentColorHover', '#09acc96f');
        document.documentElement.style.setProperty('--textColor', '#212529');
        document.documentElement.style.setProperty('--textOnAccentColor', '#212529');
      } else if (appId == 'fit-mit-jasmin') {
        environment.overwrite(environmentFitMitJasmin)
        AuthService.firebaseProjectIndividual = environmentFitMitJasmin.firebaseProjectIndividual
        if (!isDarkMode) {
          document.documentElement.style.setProperty('--accentColor', '#D1698B');
          document.documentElement.style.setProperty('--accentColorLight', '#E99CB5');
          document.documentElement.style.setProperty('--accentColorDark', '#C4416C');
          document.documentElement.style.setProperty('--accentColorHover', '#d1698b7c');
          document.documentElement.style.setProperty('--textColor', '#212529');
          document.documentElement.style.setProperty('--textColorLight', 'grey');
          document.documentElement.style.setProperty('--textOnAccentColor', '#FFFFFF');
          document.documentElement.style.setProperty('--boxBackgroundColor', '#FFFFFF');
          document.documentElement.style.setProperty('--lightBackgroundColor', '#FAFAFA');
          document.documentElement.style.setProperty('--darkerBackgroundColor', '#ffe5ef');
          document.documentElement.style.setProperty('--darkestBackgroundColor', '#E6EAEE');
        } else {
          document.documentElement.style.colorScheme = 'dark';
          document.documentElement.style.setProperty('--accentColor', '#853055');
          document.documentElement.style.setProperty('--accentColorLight', '#A15978');
          document.documentElement.style.setProperty('--accentColorDark', '#570227');
          document.documentElement.style.setProperty('--accentColorHover', '#8530557c');
          document.documentElement.style.setProperty('--textColor', '#FFFFFF');
          document.documentElement.style.setProperty('--textColorLight', '#CCCCCC');
          document.documentElement.style.setProperty('--textOnAccentColor', '#FFFFFF');
          document.documentElement.style.setProperty('--boxBackgroundColor', 'rgb(45, 45, 45)');
          document.documentElement.style.setProperty('--lightBackgroundColor', 'rgb(60, 60, 60)');
          document.documentElement.style.setProperty('--darkerBackgroundColor', 'rgb(75, 75, 75)');
          document.documentElement.style.setProperty('--darkestBackgroundColor', 'rgb(75, 75, 75)');
        }
      } else if (appId == 'luke-fit') {
        environment.overwrite(environmentLukeFit)
        AuthService.firebaseProjectIndividual = environmentLukeFit.firebaseProjectIndividual
        document.documentElement.style.setProperty('--accentColor', '#F9E22B');
        document.documentElement.style.setProperty('--accentColorLight', '#ffed62');
        document.documentElement.style.setProperty('--accentColorDark', '#d6bf10');
        document.documentElement.style.setProperty('--accentColorHover', 'rgba(214, 191, 16, 0.525)');
        document.documentElement.style.setProperty('--textColor', '#212529');
        document.documentElement.style.setProperty('--textOnAccentColor', '#212529');
        document.documentElement.style.setProperty('--textColorLight', 'grey');
      } else if (appId == 'grow-fitness-app') {
        environment.overwrite(environmentGrow)
        AuthService.firebaseProjectIndividual = environmentGrow.firebaseProjectIndividual
        if (!isDarkMode) {
          document.documentElement.style.setProperty('--accentColor', '#D7003C');
          document.documentElement.style.setProperty('--accentColorLight', '#ff336d');
          document.documentElement.style.setProperty('--accentColorDark', '#af0032');
          document.documentElement.style.setProperty('--accentColorHover', 'rgba(214, 191, 16, 0.525)');
          document.documentElement.style.setProperty('--textColor', '#212529');
          document.documentElement.style.setProperty('--textOnAccentColor', '#ffffff');
          document.documentElement.style.setProperty('--textColorLight', 'grey');
        } else {
          document.documentElement.style.colorScheme = 'dark';
          document.documentElement.style.setProperty('--accentColor', '#D7003C');
          document.documentElement.style.setProperty('--accentColorLight', '#ff336d');
          document.documentElement.style.setProperty('--accentColorDark', '#af0032');
          document.documentElement.style.setProperty('--accentColorHover', 'rgba(214, 191, 16, 0.525)');
          document.documentElement.style.setProperty('--textColor', '#FFFFFF');
          document.documentElement.style.setProperty('--textColorLight', '#CCCCCC');
          document.documentElement.style.setProperty('--textOnAccentColor', '#FFFFFF');
          document.documentElement.style.setProperty('--boxBackgroundColor', 'rgb(45, 45, 45)');
          document.documentElement.style.setProperty('--lightBackgroundColor', 'rgb(60, 60, 60)');
          document.documentElement.style.setProperty('--darkerBackgroundColor', 'rgb(75, 75, 75)');
          document.documentElement.style.setProperty('--darkestBackgroundColor', 'rgb(75, 75, 75)');
        }
      } else if (appId == 'vencha-coaching') {
        environment.overwrite(environmentVencha)
        AuthService.firebaseProjectIndividual = environmentVencha.firebaseProjectIndividual
        if (!isDarkMode) {
          document.documentElement.style.setProperty('--accentColor', '#B9A174');
          document.documentElement.style.setProperty('--accentColorLight', '#ddc08c');
          document.documentElement.style.setProperty('--accentColorDark', '#967b49');
          document.documentElement.style.setProperty('--accentColorHover', '#b9a1747e');
          document.documentElement.style.setProperty('--textColor', '#212529');
          document.documentElement.style.setProperty('--textOnAccentColor', '#ffffff');
          document.documentElement.style.setProperty('--textColorLight', 'grey');
        } else {
          document.documentElement.style.colorScheme = 'dark';
          document.documentElement.style.setProperty('--accentColor', '#B9A174');
          document.documentElement.style.setProperty('--accentColorLight', '#ddc08c');
          document.documentElement.style.setProperty('--accentColorDark', '#967b49');
          document.documentElement.style.setProperty('--accentColorHover', '#b9a1747e');
          document.documentElement.style.setProperty('--textColor', '#FFFFFF');
          document.documentElement.style.setProperty('--textColorLight', '#CCCCCC');
          document.documentElement.style.setProperty('--textOnAccentColor', '#FFFFFF');
          document.documentElement.style.setProperty('--boxBackgroundColor', 'rgb(45, 45, 45)');
          document.documentElement.style.setProperty('--lightBackgroundColor', 'rgb(60, 60, 60)');
          document.documentElement.style.setProperty('--darkerBackgroundColor', 'rgb(75, 75, 75)');
          document.documentElement.style.setProperty('--darkestBackgroundColor', 'rgb(75, 75, 75)');
        }
      } else if (appId == 'traindoo-app') {
        environment.overwrite(environmentTraindoo)
        AuthService.firebaseProjectIndividual = environmentTraindoo.firebaseProjectIndividual
        document.documentElement.style.setProperty('--fontFamily', '"DM Sans", sans-serif');
        if (!isDarkMode) {
          document.documentElement.style.setProperty('--accentColor', '#0066C4');
          document.documentElement.style.setProperty('--accentColorLight', '#0099FF');
          document.documentElement.style.setProperty('--accentColorDark', '#054d91');
          document.documentElement.style.setProperty('--accentColorHover', '#0065c47c');
          document.documentElement.style.setProperty('--textColor', '#212529');
          document.documentElement.style.setProperty('--textColorLight', 'grey');
          document.documentElement.style.setProperty('--textOnAccentColor', '#FFFFFF');
          document.documentElement.style.setProperty('--lightBackgroundColor', '#FAFAFA');
          document.documentElement.style.setProperty('--darkerBackgroundColor', '#EEF2F6');
          document.documentElement.style.setProperty('--darkestBackgroundColor', '#E6EAEE');
        } else {
          document.documentElement.style.colorScheme = 'dark';
          document.documentElement.style.setProperty('--accentColor', '#0066C4');
          document.documentElement.style.setProperty('--accentColorLight', '#0099FF');
          document.documentElement.style.setProperty('--accentColorDark', '#054d91');
          document.documentElement.style.setProperty('--accentColorHover', '#0065c47c');
          document.documentElement.style.setProperty('--textColor', '#FFFFFF');
          document.documentElement.style.setProperty('--textColorLight', '#CCCCCC');
          document.documentElement.style.setProperty('--textOnAccentColor', '#FFFFFF');
          document.documentElement.style.setProperty('--boxBackgroundColor', '#3E4A54');
          document.documentElement.style.setProperty('--lightBackgroundColor', '#29353E');
          document.documentElement.style.setProperty('--darkerBackgroundColor', '#525D66');
          document.documentElement.style.setProperty('--darkestBackgroundColor', '#677077');
        }
      }
    } else {
      if (!isDarkMode) {
        document.documentElement.style.setProperty('--accentColor', '#4AE6D7');
        document.documentElement.style.setProperty('--accentColorLight', '#B3F8F1');
        document.documentElement.style.setProperty('--accentColorDark', '#4CCCC0');
        document.documentElement.style.setProperty('--accentColorHover', 'rgba(74, 230, 215, 0.5)');
        document.documentElement.style.setProperty('--textColor', '#212529');
        document.documentElement.style.setProperty('--textColorLight', 'grey');
        document.documentElement.style.setProperty('--textOnAccentColor', '#212529');
        document.documentElement.style.setProperty('--boxBackgroundColor', 'var(--colorWhite)');
        document.documentElement.style.setProperty('--lightBackgroundColor', 'var(--colorLightGrey)');
        document.documentElement.style.setProperty('--darkerBackgroundColor', '#EBEEF6');
        document.documentElement.style.setProperty('--darkestBackgroundColor', '#e0e3ec');
      } else {
        document.documentElement.style.colorScheme = 'dark';
        document.documentElement.style.setProperty('--accentColor', '#4AE6D7');
        document.documentElement.style.setProperty('--accentColorLight', '#B3F8F1');
        document.documentElement.style.setProperty('--accentColorDark', '#4CCCC0');
        document.documentElement.style.setProperty('--accentColorHover', 'rgba(74, 230, 215, 0.5)');
        document.documentElement.style.setProperty('--textColor', '#FFFFFF');
        document.documentElement.style.setProperty('--textColorLight', '#CCCCCC');
        document.documentElement.style.setProperty('--textOnAccentColor', '#212529');
        document.documentElement.style.setProperty('--boxBackgroundColor', 'rgb(60, 60, 60)');
        document.documentElement.style.setProperty('--lightBackgroundColor', '#000');
        document.documentElement.style.setProperty('--darkerBackgroundColor', 'rgb(45, 45, 45)');
        document.documentElement.style.setProperty('--darkestBackgroundColor', '#606060');
      }
    }
    document.documentElement.style.setProperty('--nutrientsColorCalories', environment.colorCalorieGraph);
    document.documentElement.style.setProperty('--nutrientsColorCarbohydrates', environment.colorCarbohydratesGraph);
    document.documentElement.style.setProperty('--nutrientsColorProtein', environment.colorProteinGraph);
    document.documentElement.style.setProperty('--nutrientsColorFat', environment.colorFatGraph);

    const app = initializeApp(AuthService.firebaseProjectIndividual, 'individualFirebase');
    this.mainFirebase.initialize(app, AuthService.firebaseProjectIndividual.projectId, PLATFORM_ID, ngZone)
  }

  isInPublicView() {
    var url = window.location.href
    return url.includes('/login') || url.includes('/get-started') || url.includes('/forgot-password') || url.includes('/body-data') || url.includes('/checkout') || url.includes('/product') || url.includes('/profile') || url.includes('/invoice')
  }

  ngOnInit(): void {
    CapacitorApp.addListener('backButton', ({canGoBack}) => {
      if(!canGoBack){
        CapacitorApp.exitApp();
      } else {
        window.history.back();
      }
    });

    Network.getStatus().then(x => {
      if(x.connected == false){
        this.toastrService.error("Aktuell besteht keine Internetverbindung!", "Offline" ,  {
          positionClass: 'toast-bottom-center'
        });
      }
    });

    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status.connectionType);
      if (status.connected == false) {
        this.toastrService.error("Aktuell besteht keine Internetverbindung!", "Offline" ,  {
          positionClass: 'toast-bottom-center'
        });
      }
    });

    this.utilityService.windowWidth = window.innerWidth;

    this.loggedIn = this.authService.isLoggedIn();
    this.authService.redirectUrl = this.router.url

    if (this.isInPublicView()) document.querySelector("#app-loading").classList.add('display-none')

    this.authService.getAuthState().subscribe(user => {
      if (user) {
        localStorage.setItem('firebaseProjectId', this.mainFirebase.name)

        this.authService.setUser(user)
        this.userService.onLoggedIn(user.uid)
        this.userService.setNutritionService(this.nutritionService)
        this.userService.setNutritionPlanService(this.nutritionPlanService)
        //this.chatService.loadChats()
        if (this.router.url.includes('/body-data')) {
          this.router.navigate(['body-data']);
        } else if (this.router.url.includes('/subscription-success')) {
          this.router.navigate(['subscription-success']);
        } else if (this.router.url.includes('/chat') && this.utilityService?.onNativeMobileApp()) {
          this.router.navigate(['chat']);
        } else if (this.router.url.includes('/checkout')) {
          var url = this.router.url.split('?')[0] ?? this.router.url
          var token = this.router.url.split('?token=')[1]
          this.router.navigate([url], { queryParams: { token: token } });
        } else if (this.router.url.includes('/product')) {
          this.router.navigate([this.router.url]);
        } else if (this.router.url.includes('/profile')) {
          this.router.navigate([this.router.url]);
        } else if (this.router.url.includes('/invoice')) {
          this.router.navigate([this.router.url]);
        } else if (this.router.url.includes('/partners')) {
          this.router.navigate([this.router.url]);
        } else if (this.router.url.includes('/client')) {
          this.router.navigateByUrl(this.router.url)
          //this.router.navigate([this.router.url]);
        } else if (this.router.url.includes('/get-started')) {
          this.router.navigate([this.router.url]);
        } else if (this.router.url.includes('/analytics')) {
          this.router.navigate([this.router.url]);
        } else {
          this.router.navigate(['../dashboard'])
        }
        document.querySelector("#app-loading").classList.add('display-none');

      } else {
        this.nutritionService.recipes = []
        // this.router.navigate(['login']);
        if (this.router.url.includes('body-data')){
          this.router.navigate(['login/body-data']);
        } else if(this.router.url.includes('chat') && this.utilityService?.onNativeMobileApp()){
          this.router.navigate(['login/chat']);
        } else if (this.router.url.includes('/checkout')) {
          this.router.navigate([this.router.url]);
        } else if (this.router.url.includes('/product')) {
          this.router.navigate([this.router.url]);
        } else if (this.router.url.includes('/profile')) {
          this.router.navigate([this.router.url]);
        } else if (this.router.url.includes('/invoice')) {
          this.router.navigate([this.router.url]);
        } else if (this.router.url.includes('/get-started')) {
          this.router.navigate([this.router.url]);
        } else {
          this.router.navigate(['login']);
        }
        document.querySelector("#app-loading").classList.add('display-none')
      }
    });

    if(this.utilityService.onNativeMobileApp()){
      var style = document.createElement('style');
      style.innerHTML = `
      body {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      `;
      document.head.appendChild(style);
    }
  }

  async logout() {
    document.getElementById('logout-button').setAttribute("disabled", "true");
    if(!this.utilityService.onNativeMobileApp() || !this.userService.getLoggedInUser().isCoach || (await this.fcmService.unregisterPushNotifications(this.userService.getLoggedInUser()?.coach, this.userService) == true)){
      this.nutritionService.recipes = []
      this.nutritionService.customTags = []
      this.userService.logout();
      this.authService.logout();
      this.chatService.logout();
      this.trainingService.logout();
      this.questionaireService.questionaires = []

      this.router.navigate(['login']);
    } else{
      this.toastrService.error("Logout fehlgeschlagen. Bitte überprüfe die Internetverbindung.", "" ,  {
        positionClass: 'toast-bottom-center'
      });
      document.getElementById('logout-button').removeAttribute("disabled");
    }
  }

  logoutBodyData(){
    this.nutritionService.recipes = []
    this.userService.logout();
    this.authService.logoutToPage('body-data');
    this.chatService.logout();
  }

  onResize(event) {
    this.utilityService.windowWidth = event.target.innerWidth;
    this.utilityService.publishEvent('WINDOW_RESIZE')
  }

  public showHelpDialog = false
  public visibleVideoNumber = null
  onShowVideo(number: number) {
    if (this.visibleVideoNumber == number) {
      this.visibleVideoNumber = null
    } else {
      this.visibleVideoNumber = number
    }
  }
  toggleHelpDialog(){
    (window as any).Intercom('showArticle', 8975896);
    return
    this.showHelpDialog = !this.showHelpDialog
    return false;
  }

  openMarketingKit() {
    const dialogRef = this.dialog.open(MarketingKitComponent, { data: { }, width: '1000px'})
  }
}
