<div id="notes-wrapper" class="notes-wrapper">
    <input id="search-input" class="input wide" [(ngModel)]="searchText" placeholder="Notizen durchsuchen"
                type="text">
    <div class="icon-button deletesearch-icon delete-recipesearch"
        (click)="onDeleteSearchInput(); $event.stopPropagation()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
    </div>
    <ng-container *ngFor="let note of filteredNotes">
        <div class="layout-box note-box">
            <div class="date">{{note.getPrintableDate(user?.dailyCondition)}}</div>
            <div class="title h3">{{note.title}}</div>
            <div class="icon-button edit-note" (click)="onEditNote(note)">
                Bearbeiten
            </div>
            <div  [innerHtml]="compileMarkdown(note.content)"></div>
            <div *ngIf="note.hasAttachment() && note.hasImageAttachment()" class="image-wrapper">
                <img draggable="false" src="{{note.attachmentUrl}}" alt="">
            </div>
            <div *ngIf="note.voiceRecordingUrl" class="audio-wrapper">
                <div class="spacer-10"></div>
                <app-audio-player [src]="note.voiceRecordingUrl"></app-audio-player>
            </div>
            <div *ngIf="note.hasAttachment() && !note.hasImageAttachment()" class="file-wrapper">
                <a class="attached-file" href="{{note.attachmentUrl}}" target="_blank">{{note.attachedFileName}}</a>
            </div>
        </div>
    </ng-container>
</div>
<div class="newnote-wrapper">    
    <mat-divider></mat-divider>
    <div class="spacer-10"></div>
    <div *ngIf="editableNote" class="">
        <input class="input wide" placeholder="Überschrift" [(ngModel)]="editableNote.title">
    </div>
    <div class="spacer-10"></div>
    <app-md-editor class=""
        [placeHolder]="editorPlaceholder"
        (valueChanged)="onEditorValueChanged($event)" [inputValue]="this.editableNote?.content">
    </app-md-editor>
    <div *ngIf="editableNote?.attachedFileName" class="file-wrapper selected-attachment">
        <div class="attached-file" target="_blank">
            {{editableNote?.attachedFileName}}
            <div class="icon-button" (click)="onRemoveSelectedFile()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>
        </div>
    </div>
    <div *ngIf="editableNote?.voiceRecordingFileName" class="file-wrapper selected-attachment audio-attachement">
        <div class="attached-file" target="_blank">
            {{editableNote?.voiceRecordingFileName}}
            <div class="icon-button" (click)="onRemoveVoiceRecording()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>
        </div>
    </div>
    <button *ngIf="!audioRecorder.IsRecording()" class="add-attachment" mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
        </svg>
    </button>
    <mat-menu #menu="matMenu" class="chat-cdk-overlay-container">
        <button mat-menu-item (click)="onSelectAttachment()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16">
                <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"/>
            </svg>
            Anhang hinzufügen
            <input id="input-note-attachment" type="file" style="display: none;" accept=".jpg, .png, .pdf, .jpeg" (change)="onFileSelected($event)">
        </button>
        <button mat-menu-item (click)="startAudioRecording()">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-mic" viewBox="0 0 16 16">
                <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"/>
                <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z"/>
            </svg>
            Sprachaufnahme starten
        </button>
    </mat-menu>
    <div class="voice-recording-area" *ngIf="audioRecorder.IsRecording()">
        <button (click)="stopAudioRecorder()" class="button-savenote stop-recording" matTooltip="Aufnahme beenden">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-stop blink-red" viewBox="0 0 16 16">
                <path d="M3.5 5A1.5 1.5 0 0 1 5 3.5h6A1.5 1.5 0 0 1 12.5 5v6a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 11zM5 4.5a.5.5 0 0 0-.5.5v6a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 .5-.5V5a.5.5 0 0 0-.5-.5z"/>
            </svg>
        </button>
        <button (click)="cancelAudioRecording()" class="button-savenote" matTooltip="Aufnahme abbrechen">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"  fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
            </svg>
        </button>
    </div>
    <button *ngIf="!audioRecorder.IsRecording() && (editableNote.tempVoiceRecordingFile || editableNote?.content?.length > 0 || editableNote?.attachedFileName || editableNote?.id)" class="button-savenote" (click)="onSaveNote()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
    </button>
</div>