<div class="page-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-9 col-lg-9 col-xl-8 col-xxl-4 order-1 order-xxl-1 profile">
                <h2 class="" translate>Einstellungen</h2>
            </div>
            <div class="col-12 col-xl-12 col-xxl-4 order-3 order-xxl-2">
                <div class="div textalign-center">
                    <div class="spacer-10"></div>
                    <button class="tab-button {{showCustomInAppBrandingSettings ? 'active' : ''}}" (click)="swapTab('inAppBrandingSettings')">
                        <span translate>Personalisierung</span>
                        <div class="underline"></div>
                    </button>
                    <button *ngIf="(userService.getLoggedInUser()?.isLicenceHolder || userService.getLoggedInUser()?.coach?.hasLicenceHolderRights) && !userService.getLoggedInUser()?.disabled && !environment.isWhitelabel || isAdmin()" class="tab-button {{showAccountSettings ? 'active' : ''}}" (click)="swapTab('accountSettings')">
                        <span translate>Account</span>
                        <div class="underline"></div>
                    </button>
                    <button *ngIf="userService.getLoggedInUser()?.coach?.hasLicenceHolderRights" class="tab-button {{showTeamManagement ? 'active' : ''}}" (click)="swapTab('teamManagement')">
                        <span translate>Trainerverwaltung</span>
                        <div class="underline"></div>
                    </button>
                </div>
            </div>
        </div>

        <div *ngIf="showAccountSettings">
            <app-account></app-account>
        </div>
        <div *ngIf="showCustomInAppBrandingSettings">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="layout-box">

                        <h3 class="display-inlineblock">Coaching Zone</h3>
                        <div class="spacer-10"></div>
                        <div class="language-switch">
                            <app-global-language-selector></app-global-language-selector>
                            <label class="small-text bold" translate>Anzeigesprache wählen</label>
                        </div>
                        <div class="spacer-10"></div>
                        <div class=" toggle-wrapper mobile-marginbottom-10 display-block">
                            <div class="toggle {{isDarkModeEnabled ? 'on' : ''}}" (click)="onDarkModeEnabledChanged(!isDarkModeEnabled)">
                                <div class="handle"></div>
                            </div>
                            <div class="text" translate>
                                Darkmode aktivieren
                            </div>
                        </div>
                        <div class="spacer-10"></div>
                        <div class="label display-block" *ngIf="darkModeSettingChanged">
                            <span *ngIf="!utilityService.onNativeMobileApp()" translate>Lade die Seite neu, um die Änderungen zu übernehmen.</span>
                            <span *ngIf="utilityService.onNativeMobileApp()" translate>Schließe die App komplett und öffne sie erneut, um die Änderungen zu übernehmen.</span>
                        </div>
                        <div class="spacer-25"></div>

                        <h3 class="display-inlineblock" translate>App-Personalisierung</h3>
                        <br>
                        <div class="spacer-10"></div>
                        <div class="recipesharing-toggle toggle-wrapper">
                            <div class="toggle {{coach?.coacheeVoiceMessageEnabled ? 'on' : ''}}" (click)="onVoiceMessagesEnabledChanged()">
                                <div class="handle"></div>
                            </div>
                            <div class="text" translate>
                                Versand von Sprachnachrichten durch Coachees erlauben
                            </div>
                        </div>
                        <br>
                        <div class="spacer-10"></div>
                        <div class="recipesharing-toggle toggle-wrapper">
                            <div class="toggle {{!coach?.hideChatMessageSender ? 'on' : ''}}" (click)="onHideChatMessageSender()">
                                <div class="handle"></div>
                            </div>
                            <div class="text">
                                <span translate>Absender von Chatnachrichten einzeln anzeigen</span>
                                <div class="icon-button marginleft-10" [tooltip]="'Wenn mehrere Coaches des Teams im Chat mit einem Coachee kommunizieren, können diese entsprechend angezeigt werden. Andernfalls ist überall der zugewiesene Coach als Absender zu sehen.' | translate">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="spacer-10"></div>
                        <div class="branding-toggle toggle-wrapper">
                            <div class="toggle {{customInAppBrandingEnabled ? 'on' : ''}}" (click)="onCustomBrandingEnabledChanged(!customInAppBrandingEnabled)">
                                <div class="handle"></div>
                            </div>
                            <div class="text" translate>
                                Eigenes In-App-Branding aktivieren
                            </div>
                        </div>
                        <div class="spacer-25"></div>
                        <div class="icon-button" *ngIf="hasBrandingChanges" (click)="onSaveChanges()" translate>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                            </svg>
                            Speichern
                        </div>
                        <br>
                        <div *ngIf="customInAppBrandingEnabled">
                            <div class="phone-mockup-wrapper">
                                <img draggable="false" class="phone-mockup" src="assets/images/nutrilize_Loading-Screen_light.png">
                                <div class="branding-imagewrapper link {{logoUrl ? '' : 'invalid'}}" (click)="onUploadLogoImage()">
                                    <div *ngIf="!logoUrl" class="logo-image padding-10"><span class="label" translate>Bild für Ladebildschirm hochladen (.png mit transparentem Hintergrund)</span></div>
                                    <div *ngIf="logoUrl" class="logo-image" [ngStyle]="{'background-image':'url('+ logoUrl +')'}"></div>
                                    <input id="input-logoimage" type="file" style="display: none;" accept=".png" (change)="onLogoImageUploaded($event)">
                                </div>
                            </div>
                            <div class="phone-mockup-wrapper">
                                <img draggable="false" class="phone-mockup" src="assets/images/nutrilize_Loading-Screen_dark.png">
                                <div class="branding-imagewrapper dark link {{logoLightUrl ? '' : 'invalid'}}" (click)="onUploadLogoLightImage()">
                                    <div *ngIf="!logoLightUrl" class="logo-image padding-10"><span class="label" translate>Bild für Ladebildschirm im Dark-Mode hochladen (.png mit transparentem Hintergrund in heller Ausführung)</span></div>
                                    <div *ngIf="logoLightUrl" class="logo-image" [ngStyle]="{'background-image':'url('+ logoLightUrl +')'}"></div>
                                    <input id="input-logolightimage" type="file" style="display: none;" accept=".png" (change)="onLogoLightImageUploaded($event)">
                                </div>
                            </div>
                            <div class="phone-mockup-wrapper">
                                <img draggable="false" class="phone-mockup" src="assets/images/nutrilize_Plain-Screen.png">
                                <div class="welcomedialog">
                                    <img draggable="false" *ngIf="!isDarkModeEnabled" class="welcomedialog-image" [src]="logoUrl | safe">
                                    <img draggable="false" *ngIf="isDarkModeEnabled" class="welcomedialog-image" [src]="logoLightUrl | safe">
                                    <div class="welcomedialog-heading">{{welcomeDialogHeading || 'Überschrift unten anpassen'}}</div>
                                    <div class="welcomedialog-content">{{welcomeDialogContent || 'Nachricht unten anpassen'}}</div>
                                    <div class="welcomedialog-close" translate>Schließen</div>
                                </div>
                            </div>
                            <br>
                            <br>
                            <span translate>Willkommensdialog Überschrift:</span><br>
                            <input [ngModel]="welcomeDialogHeading" (input)="onHeadingChanged($event.target.value)" class="input welcome-heading {{welcomeDialogHeading?.length < 55 ? '' : 'invalid'}}" type="text" [placeholder]="'Willkommen im Coaching!' | translate"/>
                            <br><br>
                            <span translate>Willkommensdialog Nachricht:</span><br>
                            <textarea [ngModel]="welcomeDialogContent" (input)="onContentChanged($event.target.value)" class="input welcome-message {{welcomeDialogContent?.length < 350 ? '' : 'invalid'}}" type="text" rows="5" [placeholder]="'Den ersten Schritt hast du bereits geschafft: Du hast dich mit mir verbunden und wir können hier ab sofort zusammenarbeiten! ...' | translate"></textarea>

                        </div>
                        <div class="spacer-25"></div>
                    </div>
                </div>
                <!--div class="col-12 col-lg-6">
                    <div class="layout-box">
                        <h3 class="display-inlineblock">Benachrichtigungen</h3>
                        <div class="spacer-10"></div>
                        <app-coach-notifications></app-coach-notifications>

                    </div>
                </div-->
            </div>
        </div>
        <div *ngIf="showTeamManagement">
            <app-team-management></app-team-management>
        </div>
    </div>
</div>
