import { marker } from "@colsen1991/ngx-translate-extract-marker"
import { TranslateService } from "@ngx-translate/core"

export class Serving {

    public IDENTIFIER_PACKAGE = 'PACKAGE'
    public IDENTIFIER_SERVING = 'SERVING'
    public IDENTIFIER_SMALL = 'SMALL'
    public IDENTIFIER_MEDIUM = 'MEDIUM'
    public IDENTIFIER_LARGE = 'LARGE'
    public IDENTIFIER_SLICE = 'SLICE'
    public IDENTIFIER_PIECE = 'PIECE'
    public IDENTIFIER_BOTTLE = 'BOTTLE'
    public IDENTIFIER_TEASPOON = 'TEASPOON'
    public IDENTIFIER_TABLESPOON = 'TABLESPOON'
    public IDENTIFIER_CUP = 'CUP'
    public IDENTIFIER_BAR = 'BAR'
    public IDENTIFIER_CUSTOM = 'CUSTOM'
    public IDENTIFIER_PIECES = 'PIECES'

    public amount: number
    public rawServing: string
    public identifier: string
    public printableIdentifier: string
    public unit: string

    getPrintableName(translate: TranslateService) {
        return (this.printableIdentifier?.length > 0 ? translate.instant(this.printableIdentifier) : '') + ': ' + this.amount + (this.unit || 'g')
    }

    static servingsFromString(string: string): Serving[] {
        if (!string || string.length == 0) return []
        var servings = []
        var rawServings = string.split(';')
        rawServings.forEach(rawServing => {
            var serving = this.servingFromString(rawServing)
            if (serving) servings.push(serving)
        })
        return servings
    }

    static servingFromString(rawServing: string): Serving {
        try {
            var serving = new Serving()
            serving.rawServing = rawServing
            var data = rawServing.split(':')
            var weight = 0;
            if (data[1].indexOf('kg') != -1) {
                weight = parseFloat(data[1].replace('kg', '')) * 1000;
                serving.unit = 'g'
            } else if (data[1].indexOf('g') != -1) {
                weight = parseFloat(data[1].replace('g', ''))
                serving.unit = 'g'
            } else if (data[1].indexOf('ml') != -1) {
                weight = parseFloat(data[1].replace('ml', ''))
                serving.unit = 'ml'
            } else if (data[1].indexOf('l') != -1) {
                weight = parseFloat(data[1].replace('l', '')) * 1000;
                serving.unit = 'ml'
            }
            serving.amount = weight
            if (data[0] == serving.IDENTIFIER_PACKAGE) {
                serving.identifier = serving.IDENTIFIER_PACKAGE
                serving.printableIdentifier = marker('Packung')
                return serving
            } else if (data[0] == serving.IDENTIFIER_SERVING) {
                serving.identifier = serving.IDENTIFIER_SERVING
                serving.printableIdentifier = marker('Portion')
                return serving
            } else if (data[0] == serving.IDENTIFIER_SMALL) {
                serving.identifier = serving.IDENTIFIER_SMALL
                serving.printableIdentifier = marker('Klein')
                return serving
            } else if (data[0] == serving.IDENTIFIER_MEDIUM) {
                serving.identifier = serving.IDENTIFIER_MEDIUM
                serving.printableIdentifier = marker('Mittel')
                return serving
            } else if (data[0] == serving.IDENTIFIER_LARGE) {
                serving.identifier = serving.IDENTIFIER_LARGE
                serving.printableIdentifier = marker('Groß')
                return serving
            } else if (data[0] == serving.IDENTIFIER_SLICE) {
                serving.identifier = serving.IDENTIFIER_SLICE
                serving.printableIdentifier = marker('Scheibe')
                return serving
            } else if (data[0] == serving.IDENTIFIER_PIECE) {
                serving.identifier = serving.IDENTIFIER_PIECE
                serving.printableIdentifier = marker('Stück')
                return serving
            } else if (data[0] == serving.IDENTIFIER_BOTTLE) {
                serving.identifier = serving.IDENTIFIER_BOTTLE
                serving.printableIdentifier = marker('Flasche')
                return serving
            } else if (data[0] == serving.IDENTIFIER_TEASPOON) {
                serving.identifier = serving.IDENTIFIER_TEASPOON
                serving.printableIdentifier = marker('Teelöffel')
                return serving
            } else if (data[0] == serving.IDENTIFIER_TABLESPOON) {
                serving.identifier = serving.IDENTIFIER_TABLESPOON
                serving.printableIdentifier = marker('Esslöffel')
                return serving
            } else if (data[0] == serving.IDENTIFIER_CUP) {
                serving.identifier = serving.IDENTIFIER_CUP
                serving.printableIdentifier = marker('Tasse')
                return serving
            } else if (data[0] == serving.IDENTIFIER_BAR) {
                serving.identifier = serving.IDENTIFIER_BAR
                serving.printableIdentifier = marker('Riegel')
                return serving
            } else if (data[0].startsWith(serving.IDENTIFIER_CUSTOM)) {
                serving.identifier = serving.IDENTIFIER_CUSTOM
                serving.printableIdentifier = data[0].replace('CUSTOM\'', '').replace('\'', '')
                return serving
            } else if (data[0].startsWith(serving.IDENTIFIER_PIECES)) {
                serving.identifier = serving.IDENTIFIER_PIECES
                serving.printableIdentifier = data[0].replace('PIECES_', '').replace('\'', '') + ' Stück(e)'
                return serving
            }
        } catch (error) {}
        return null
    }
}