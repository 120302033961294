import { TranslateService } from "@ngx-translate/core"
import { Product } from "./product.model"
import { User } from "./user.model"
import { marker } from "@colsen1991/ngx-translate-extract-marker"

export enum PaymentStatus {
  DRAFT = "draft",
  PAID = "paid",
  UNPAID = "unpaid",
  PROCESSING = "processing",
  REFUNDED = "refunded",
  VOIDED = "voided",
  FAILED = "failed",
  DISPUTED = "disputed",
  INITIATED = "initiated",
  FORECAST = "forecast",
}

export class Payment {
    id: string;
    stripeInvoiceId: string;
    stripeCustomerId: string;
    stripeAccountId: string;
    customerUid: string;
    productId: string;
    productPurchaseId: string;
    currency: string;
    amount: number;
    status: PaymentStatus;
    date: Date;
    invoiceNumber: string;
    invoiceFileName: string;
    cancellationInvoiceNumber: string;
    cancellationInvoiceFileName: string;
    paymentMethod: string;
    logs: LogItem[];
    payoutDate: Date;
    reversePayoutDate: Date;
    stripePaymentIntentId: string;
    bankTransferInstructionsUrl: string;

    customer: User;
    product: Product;

    constructor();
    constructor(init: Payment);
    constructor(init?: Payment) {
        this.id = init && init.id || null
        this.stripeInvoiceId = init && init.stripeInvoiceId || null
        this.stripeCustomerId = init && init.stripeCustomerId || null
        this.stripeAccountId = init && init.stripeAccountId || null
        this.customerUid = init && init.customerUid || null
        this.productId = init && init.productId || null
        this.productPurchaseId = init && init.productPurchaseId || null
        this.currency = init && init.currency || 'eur'
        this.amount = init && init.amount || null
        this.status = init && init.status || null
        this.date = init && init.date ? new Date((init as any).date.seconds * 1000) : null
        this.invoiceNumber = init && init.invoiceNumber || null
        this.invoiceFileName = init && init.invoiceFileName || null
        this.cancellationInvoiceNumber = init && init.cancellationInvoiceNumber || null
        this.cancellationInvoiceFileName = init && init.cancellationInvoiceFileName || null
        this.paymentMethod = init && init.paymentMethod || null
        this.logs = init && init.logs?.map(x => new LogItem(x)) || []
        this.stripePaymentIntentId = init && init.stripePaymentIntentId || null
        this.payoutDate = init && init.payoutDate ? new Date((init as any).payoutDate.seconds * 1000) : null
        this.reversePayoutDate = init && init.reversePayoutDate ? new Date((init as any).reversePayoutDate.seconds * 1000) : null
        this.bankTransferInstructionsUrl = init && init.bankTransferInstructionsUrl || null
    }

    getDate() {
        // Subtract timezone offset to get the correct date
        var offset = new Date().getTimezoneOffset();
        if (offset == null) return this.date
        var offsetHours = offset / 60
        var d = this.date.clone()
        d.setHours(d.getHours() + offsetHours)
        return d
    }

    getPrintablePrice() {
        return (this.amount / 100).toString().replace('.', ',')
    }
    getPrintablePriceWithCurrency() {
        return this.getPrintablePrice() + ' ' + this.getPrintableCurrency()
    }
    getPrintableCurrency() {
        if (this.currency == 'eur' || this.currency == null) return '€'
        if (this.currency == 'chf') return 'CHF'
        if (this.currency == 'usd') return '$'
        if (this.currency == 'gbp') return '£'
        return this.currency
    }
    getPrintableStatus(translate: TranslateService) {
        switch (this.status) {
            case PaymentStatus.DRAFT: return translate.instant(marker('Entwurf'));
            case PaymentStatus.PAID: return translate.instant(marker('Bezahlt'));
            case PaymentStatus.UNPAID: return translate.instant(marker('Unbezahlt'));
            case PaymentStatus.PROCESSING: return translate.instant(marker('In Verarbeitung'));
            case PaymentStatus.REFUNDED: return translate.instant(marker('Erstattet'));
            case PaymentStatus.VOIDED: return translate.instant(marker('Storniert'));
            case PaymentStatus.FAILED: return translate.instant(marker('Fehlgeschlagen'));
            case PaymentStatus.INITIATED: return translate.instant(marker('Initiiert'));
            default: return translate.instant(marker('Unbekannt'));
        }
    }
    getPrintableStatusInfo(translate: TranslateService) {
        switch (this.status) {
            case PaymentStatus.DRAFT: return translate.instant(marker('Entwurf'));
            case PaymentStatus.PAID: return translate.instant(marker('Der Rechnungsbetrag ist eingegangen.'));
            case PaymentStatus.UNPAID: return translate.instant(marker('Unbezahlt'));
            case PaymentStatus.PROCESSING: return translate.instant(marker('Die Zahlung wird verarbeitet. Dies kann 7-14 Werktage dauern.'));
            case PaymentStatus.REFUNDED: return translate.instant(marker('Der Rechnungsbetrag wurde erstattet.'));
            case PaymentStatus.VOIDED: return translate.instant(marker('Die Rechnung wurde storniert.'));
            case PaymentStatus.FAILED: return translate.instant(marker('Die Zahlung ist fehlgeschlagen und die Rechnung noch offen.'));
            case PaymentStatus.INITIATED: return translate.instant(marker('Der Kunde hat die Überweisung noch nicht durchgeführt.'));
            default: return translate.instant(marker('Unbekannt'));
        }

    }
    getPrintablePaymentMethod(translate: TranslateService) {
        if (!this.paymentMethod) return ''
        switch (this.paymentMethod) {
            case 'paypal':    return translate.instant(marker('PayPal'));
            case 'card':    return translate.instant(marker('Kreditkarte'));
            case 'sepa_debit':    return translate.instant(marker('SEPA-Lastschrift'));
            case 'bank_transfer':   return translate.instant(marker('Banküberweisung'));
            case 'klarna':   return translate.instant(marker('Klarna'));
            default: return this.paymentMethod;
        }
    }

    asMap() {
        return {
            stripeInvoiceId: this.stripeInvoiceId,
            stripeCustomerId: this.stripeCustomerId,
            stripeAccountId: this.stripeAccountId,
            customerUid: this.customerUid,
            productId: this.productId,
            productPurchaseId: this.productPurchaseId,
            currency: this.currency,
            amount: this.amount,
            status: this.status,
            date: this.date,
            invoiceNumber: this.invoiceNumber,
            invoiceFileName: this.invoiceFileName,
            cancellationInvoiceNumber: this.cancellationInvoiceNumber,
            cancellationInvoiceFileName: this.cancellationInvoiceFileName,
            paymentMethod: this.paymentMethod,
            payoutDate: this.payoutDate,
            stripePaymentIntentId: this.stripePaymentIntentId,
        }
    }
}

export class LogItem {
    date: Date
    event: string
    error: string
    message: string
    cardDeclineCode: string

    constructor();
    constructor(init: LogItem);
    constructor(init?: LogItem) {
        this.date = init && init.date ? new Date((init as any).date.seconds * 1000) : null
        this.event = init && init.event || null
        this.error = init && init.error || null
        this.message = init && init.message || null
        this.cardDeclineCode = init && init.cardDeclineCode || null
    }

    getPrintableErrorDescription(translate: TranslateService) {
        if (!this.error && !this.message) return null
        var text = this.getPrintableError(translate)
        if (this.getPrintableCardDeclineCode(translate)) text += ' (' + this.getPrintableCardDeclineCode(translate) + ')'
        if (this.message) text += ': ' + this.message
        return text
    }

    getPrintableError(translate: TranslateService) {
        if (this.error == 'card_declined') return translate.instant(marker('Karte abgelehnt'))
        if (this.error == 'expired_card') return translate.instant(marker('Karte abgelaufen'))
        if (this.error == 'sepa_generic_decline') return translate.instant(marker('Zahlung von der ausstellenden Bank abgewiesen'))
        return this.error ?? ''
    }

    getPrintableCardDeclineCode(translate: TranslateService) {
        if (this.cardDeclineCode == 'do_not_honor') return translate.instant(marker('Karte nicht akzeptiert'))
        if (this.cardDeclineCode == 'expired_card') return translate.instant(marker('Karte abgelaufen'))
        if (this.cardDeclineCode == 'incorrect_cvc') return translate.instant(marker('Falsche CVC'))
        if (this.cardDeclineCode == 'incorrect_number') return translate.instant(marker('Falsche Kartennummer'))
        if (this.cardDeclineCode == 'incorrect_pin') return translate.instant(marker('Falsche PIN'))
        if (this.cardDeclineCode == 'insufficient_funds') return translate.instant(marker('Nicht genügend Kontodeckung'))
        if (this.cardDeclineCode == 'lost_card') return translate.instant(marker('Karte verloren'))
        return null
    }

    getPrintableEvent(translate: TranslateService) {
        if (!this.event) return ''
        switch (this.event) {
            case 'invoice_created': return translate.instant(marker('Rechnung erstellt'))
            case 'invoice_finalized': return translate.instant(marker('Rechnung finalisiert'))
            case 'invoice_refunded': return translate.instant(marker('Rechnung erstattet'))
            case 'payment_created': return translate.instant(marker('Rechnung erstellt'))
            case 'invoice_payment_failed': return translate.instant(marker('Zahlung fehlgeschlagen'))
            case 'payment_failed': return translate.instant(marker('Zahlung fehlgeschlagen'))
            case 'invoice_paid': return translate.instant(marker('Zahlung erfolgreich'))
            case 'checkout_completed': return translate.instant(marker('Kauf abgeschlossen'))
            case 'licence_activated': return translate.instant(marker('Lizenz aktiviert'))
            case 'licence_manually_activated': return translate.instant(marker('Lizenz manuell aktiviert'))
            case 'invoice_voided': return translate.instant(marker('Zahlung storniert'))
            case 'renewal_canceled': return translate.instant(marker('Verlängerung gekündigt'))
            case 'renewal_enabled': return translate.instant(marker('Automatische Verlängerung aktiviert'))
            case 'purchase_canceled': return translate.instant(marker('Kauf gekündigt'))
            case 'payment_processing': return translate.instant(marker('Zahlung in Verarbeitung'))
            case 'runtime_started': return translate.instant(marker('Laufzeit gestartet'))
            case 'runtime_ended': return translate.instant(marker('Laufzeit beendet'))
            case 'recharge_payment': return translate.instant(marker('Zahlung wiederholen'))
            case 'runtime_manually_extended': return translate.instant(marker('Laufzeit manuell verlängert'))
            case 'paymentdate_changed': return translate.instant(marker('Abrechnungsdatum geändert'))
            case 'mark_as_paid': return translate.instant(marker('Rechnung als bezahlt markiert'))
            case 'skip_next_payment': return translate.instant(marker('Nächste Zahlung ausgesetzt'))
            case 'refund_failed': return translate.instant(marker('Erstattung fehlgeschlagen'))
            case 'purchase_paused': return translate.instant(marker('Kauf pausiert'))
            case 'purchase_resumed': return translate.instant(marker('Kauf fortgesetzt'))
            case 'licence_paused': return translate.instant(marker('Lizenz pausiert'))
        }
        return this.event
    }
}
