import { CycleConfig } from './../model/nutritionconfig.model';
import { Component, OnInit, Input, Output } from '@angular/core';
import { BaseNutritionFact, NutritionalValueHolder } from '../model/basenutritionfact.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-nutritional-value-popover',
  templateUrl: './nutritional-value-popover.component.html',
  styleUrls: ['./nutritional-value-popover.component.css']
})
export class NutritionalValuePopoverComponent implements OnInit {
  @Input() nutritionalValueHolder: NutritionalValueHolder
  @Input() cycleConfig: CycleConfig

  public BaseNutritionFact = BaseNutritionFact
  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
  }

}