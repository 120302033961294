<div *ngIf="!calendarCollapsed; else collapedCalendar;" class="layout-box calendar-layout-box {{isBusy ? 'busy' : ''}}">
    <div class="row">
        <div class="col-12 calendar-box">
            <div class="date-selection">
                <button class="button change-date {{canJumpMonthBackward() ? '' : 'opaque'}} p-0" (click)="jumpMonthBackward()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                    </svg>
                </button>
                <span class="link" tooltip="{{canJumpToCurrentMonth() ? ('Springe zum aktuellen Monat' | translate) : ''}}" (click)="jumpToCurrentMonth()">{{getDisplayedMonth()}}</span>
                <button class="button change-date {{canJumpMonthForward() ? '' : 'opaque'}} p-0" (click)="jumpMonthForward()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </button>
            </div>
            <div *ngIf="!showRelativeMonths" class="row">
                <div class="weekday-item" translate>{{NumberToShortWeekDayStringsMapping[1]}}</div>
                <div class="weekday-item" translate>{{NumberToShortWeekDayStringsMapping[2]}}</div>
                <div class="weekday-item" translate>{{NumberToShortWeekDayStringsMapping[3]}}</div>
                <div class="weekday-item" translate>{{NumberToShortWeekDayStringsMapping[4]}}</div>
                <div class="weekday-item" translate>{{NumberToShortWeekDayStringsMapping[5]}}</div>
                <div class="weekday-item" translate>{{NumberToShortWeekDayStringsMapping[6]}}</div>
                <div class="weekday-item" translate>{{NumberToShortWeekDayStringsMapping[0]}}</div>
            </div>
            <div class="row" *ngFor="let week of weeks">
                <div class="day-item {{(!draggingEnabled && calendarDay?.disabled) || (draggingEnabled && calendarDay?.disabledDrop) ? 'disabled' : ''}}" *ngFor="let calendarDay of singleCalendarDaysByWeekNumber.get(week)"  (drop)="onDropOnDay($event, calendarDay);$event.stopPropagation()" (dragover)="onDragOverDay($event, calendarDay);$event.stopPropagation()">
                    <ng-container *ngIf="calendarDay; else hiddenCalendarDay;">
                        <button class="calendar-button p-0" [matTooltip]="getCalendarDayTooltip(calendarDay)" matTooltipClass="day-item-tooltip" [ngClass]="{'active': selectedCalendarDay?.date === calendarDay?.date, 'dragging-enabled': draggingEnabled, 'is-today': calendarDay?.date?.isSameDate(today)}" type="button" (click)="onSelectedCalendarDayChanged(calendarDay, dayItemMenuTrigger)">{{calendarDay.date.getDate()}}</button>
                        <div class="day-indicator">
                            <ng-container *ngFor="let calendarItem of calendarDay.getCalendarItems(5)">
                                <div class="calendaritem-indicator" [style.background-color]="calendarItem.color" [matTooltip]="calendarItem.title"></div>
                            </ng-container>
                        </div>
                        <button  #dayItemMenuTrigger="matMenuTrigger" mat-button [matMenuTriggerFor]="dayItemMenu" class="hidden-menu-trigger"></button>
                        <mat-menu #dayItemMenu="matMenu">
                            <ng-container *ngFor="let item of calendarDay.calendarItems">
                                <button mat-menu-item (click)="onSelectedCalendarItemChanged(item)">{{item.title}}</button>
                            </ng-container>
                        </mat-menu>
                    </ng-container>
                    <ng-template #hiddenCalendarDay>
                        <div class="calendar-button hidden"></div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isCollapsible" class="collapse-calendar-button link" (click)="toggleCollapsedCalendar()">
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-arrows-angle-contract" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M.172 15.828a.5.5 0 0 0 .707 0l4.096-4.096V14.5a.5.5 0 1 0 1 0v-3.975a.5.5 0 0 0-.5-.5H1.5a.5.5 0 0 0 0 1h2.768L.172 15.121a.5.5 0 0 0 0 .707M15.828.172a.5.5 0 0 0-.707 0l-4.096 4.096V1.5a.5.5 0 1 0-1 0v3.975a.5.5 0 0 0 .5.5H14.5a.5.5 0 0 0 0-1h-2.768L15.828.879a.5.5 0 0 0 0-.707"></path>
        </svg>
    </div>
</div>
<ng-template #collapedCalendar>
    <div class="collapsed-calendar-button link" (click)="toggleCollapsedCalendar()">
        <i class="bi bi-calendar-event"></i>
    </div>
</ng-template>
<div class="spinner-wrapper" *ngIf="isBusy">
    <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
</div>
