import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { LicenceMonitoringItem } from '../model/licence-monitoring-item';
import { FirestoreService } from '../services/firestore.service';
import { UtilityService } from '../services/utility.service';
import '../prototypes'
import { items } from 'fusioncharts';
import { Coach } from '../model/coach.model';
import { DxChartComponent } from 'devextreme-angular';
import { map } from 'rxjs/operators';
import { LicenceHolder } from '../model/licenceholder.model';
import { ChartExportService } from '../services/chart-export.service';
import * as FileSaver from 'file-saver';
import { combineLatest, forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-licence-monitoring',
  templateUrl: './licence-monitoring.component.html',
  styleUrls: ['./licence-monitoring.component.css']
})
export class LicenceMonitoringComponent implements OnInit {
  @ViewChild(DxChartComponent, { static: false }) chart: DxChartComponent;

  coachingLicenceMonitoringItems: LicenceMonitoringItem[] = []
  premiumLicenceMonitoringItems: LicenceMonitoringItem[] = []
  licenceHolders: LicenceHolder[] = []
  monitoringGraphData: any[] = []
  monitoringGraphItems: any[] = []
  monitoringTableData: any[] = []
  
  public graphRangeStartDate: Date = null
  public graphRangeEndDate: Date = null

  legendClickHandler (e) {
    const series = e.target;
    if (this.visibleSeries.includes(series.name)) {
      this.visibleSeries.forEach( (item, index) => {
        if (item === series.name) this.visibleSeries.splice(index, 1)
      })
    } else {
      this.visibleSeries.push(series.name)
    }
  };

  customizeTooltip = (arg: any) => {
    var average = 0
    var valueField = this.monitoringGraphItems.filter(function(e) { return e.name === arg.seriesName; })[0].valueField
    this.chart.instance.getSeriesByName(arg.seriesName).getAllPoints().forEach(point => {
      average += point.data[valueField]
    })
    var range = Math.floor((this.graphRangeEndDate.getTime() - this.graphRangeStartDate.getTime()) / (1000*60*60*24)) + 1
    if (range > 0) {
      average = average / range
    } else {
      average = -1
    }
    var trial = arg.point?.data['trial' + valueField]
    return { text: arg.point?.data?.date.asFormatedString() + '\n' + arg.seriesName + ': ' + arg.valueText + (trial ? ' (-' + trial + ')' : '') + '\n' + this.translate.instant('Durchschnitt:') + ' ' + average.toFixed(2) }
  }
  getAllSeries() {
    return this.chart.instance.getAllSeries();
  }
  hideAllGraphLines() {
    this.visibleSeries = []
    this.updateGraphData()
  }
  showAllGraphLines() {
    this.visibleSeries = ['Gesamt Coaching', 'Gesamt Premium']
    this.licenceHolders.forEach(licenceHolder => {
      this.visibleSeries.push(licenceHolder.name)
    })
    this.updateGraphData()
  }

  constructor(public authService: AuthService, private router: Router, public userService: FirestoreService, public utilityService: UtilityService, public chartExportService: ChartExportService, private translate: TranslateService) { }

  ngOnInit(): void {
    if (!this.authService.isAdmin()) return;

    this.graphRangeEndDate = new Date()
    this.graphRangeEndDate.setHours(23,59,59,0)
    this.graphRangeStartDate = new Date()
    this.graphRangeStartDate.setHours(0,0,0,0)
    this.graphRangeStartDate.setTime(this.graphRangeStartDate.getTime() - (1000*60*60*24) * 31);

    var subscriptionCoaches = this.userService.getAllLicenceHolders().subscribe((coaches => {
      this.licenceHolders = coaches
      this.updateGraphData()
      var promises: Promise<any>[] = []
      this.licenceHolders.forEach(coach => {
        if (coach.stripeCustomerId) promises.push(this.userService.loadCoachSubscription(coach))
      })
      Promise.all(promises).then(value => this.updateGraphData())
      if (subscriptionCoaches) subscriptionCoaches.unsubscribe()
    }))
    this.loadMonitoringItems()
  }

  onGraphRangeStartDateChanged(date: Date) {
    this.graphRangeStartDate = date
    this.graphRangeStartDate.setHours(0,0,0,0)
    this.updateGraphData()
  }
  onGraphRangeEndDateChanged(date: Date) {
    this.graphRangeEndDate = date
    this.graphRangeEndDate.setHours(0,0,0,0)
    this.updateGraphData()
  }

  loadMonitoringItems() {
    combineLatest([this.userService.getAllCoachingLicenceMonitoringItems(), this.userService.getAllLicenceMonitoringItemsByType('Premium')]).subscribe(([coachingLicenceMonitoringItems, premiumLicenceMonitoringItems]) => {
      console.log(coachingLicenceMonitoringItems?.length + ' ' + premiumLicenceMonitoringItems?.length)
      coachingLicenceMonitoringItems.sort((a, b) => a.date.getTime() - b.date.getTime())
      this.coachingLicenceMonitoringItems = coachingLicenceMonitoringItems
      
      premiumLicenceMonitoringItems.sort((a, b) => a.date.getTime() - b.date.getTime())
      this.premiumLicenceMonitoringItems = premiumLicenceMonitoringItems
      this.updateGraphData()

      if (this.coachingLicenceMonitoringItems?.length == 0 || !this.coachingLicenceMonitoringItems[this.coachingLicenceMonitoringItems.length - 1].date.isToday()) {
        this.createMonitoringItem()
      }
    })
  }
  
  visibleSeries: string[] = ['Gesamt Coaching', 'Gesamt Premium']

  updateGraphData() {
    var newGraphData = []
    var newGraphItems = [{valueField: 'totalCoaching', name: 'Gesamt Coaching'}, {valueField: 'totalPremium', name: 'Gesamt Premium'}]
    var tableData = []
    this.coachingLicenceMonitoringItems.forEach((item, index) => {
      if (item.date.getTime() >= this.graphRangeStartDate.getTime() && item.date.getTime() <= this.graphRangeEndDate.getTime()) {
        
        var dataItem: any = {
          date: item.date,
        }
        var totalCount = 0
        this.licenceHolders.forEach(licenceHolder => {
          var activeLicences = item.activeLicencesPerCoach.get(licenceHolder.uid) ?? 0
          var trialLicences = item.trialLicencesPerCoach.get(licenceHolder.uid) ?? 0
          var sum = activeLicences + trialLicences
          dataItem[licenceHolder.uid] = sum
          dataItem['trial' + licenceHolder.uid] = trialLicences
          totalCount += sum
          if (sum > 0 && newGraphItems.filter(function(e) { return e.valueField === licenceHolder.uid; }).length == 0) newGraphItems.push({valueField: licenceHolder.uid, name: licenceHolder.name || licenceHolder.uid})
          if (index == this.coachingLicenceMonitoringItems.length - 1) {
            tableData.push({ name: licenceHolder.name, totalCoaching: sum, trial: trialLicences })
          }
        })
        dataItem["totalCoaching"] = totalCount
        newGraphData.push(dataItem)
      }
    })
    this.premiumLicenceMonitoringItems.forEach((item, index) => {
      var dataItem = newGraphData.filter(function(e) { return e.date.getTime() === item.date.getTime(); })[0]
      if (dataItem) {
        var totalCount = 0
        item.activeLicencesPerCoach.forEach((value, key) => {
          dataItem[key] = value
          totalCount += value
        })
        dataItem["totalPremium"] = totalCount
      }
    })
    this.monitoringTableData = tableData
    this.monitoringGraphData = newGraphData
    this.monitoringGraphItems = newGraphItems
    this.chart.instance.render()
  }

  onExport() {
    var map = new Map<string, any>()
    
    map.set('Day', ['Day'])
    this.licenceHolders.forEach(licenceHolder => {
      if (licenceHolder.subscription) map.set(licenceHolder.uid, [licenceHolder.uid])
    })
    
    this.monitoringGraphData.forEach(item => {
      var count = 0
      map.forEach((value, key) => {
        if (!map.get(key)) {
          map.set(key, map.get('Day').map(() => 0))
        }
        if (key != 'Day') {
          map.set(key, map.get(key).concat(item[key] ?? 0))
        } else {
          map.set(key, map.get(key).concat(item.date.asFormatedString()))
        }
        count += item[key] ?? 0
      })
    })

    var data = []
    
    map.forEach((value, key) => {
      data.push(value)
    })

    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], {type: 'text/csv' })
    FileSaver.saveAs(blob, 'export.csv')
  }

  createStripeUsageRecord() {
    var endDate = new Date()
    endDate.setHours(23,59,59,0)
    var startDate = new Date(endDate.getTime())
    startDate.setHours(0,0,0,0)
    startDate.setMonth(endDate.getMonth() - 1)
    startDate.setDate(startDate.getDate() + 1)
    console.log('## Monitoring: ' + startDate.toDateString() + ' - ' + endDate.toDateString())

    // Sum up usage of each MonitoringItem for every LicenceHolder.
    this.licenceHolders.forEach(licenceHolder => {
      if (licenceHolder.subscription) {
        var summedUsage = 0
        var individualStartDate = new Date(startDate.getTime())
        var subscriptionStartDate = licenceHolder.subscription.creationDate
        if (licenceHolder.subscription.overwriteSubscriptionStartDate) {
          subscriptionStartDate = licenceHolder.subscription.overwriteSubscriptionStartDate
          subscriptionStartDate.setHours(0,0,0,0)
        }
        this.coachingLicenceMonitoringItems.forEach(item => {
          if (item.date >= individualStartDate && item.date <= endDate && item.date >= subscriptionStartDate) {
            if (item.activeLicencesPerCoach.has(licenceHolder.uid)) {
              summedUsage += item.activeLicencesPerCoach.get(licenceHolder.uid)
            }
          }
        })
        if (licenceHolder.overwriteCoachingLicenceCount != null) summedUsage = licenceHolder.overwriteCoachingLicenceCount
        var timeRange = Math.round((endDate.getTime() - individualStartDate.getTime()) / (1000 * 60 * 60 * 24))
        var average = summedUsage / timeRange
        var rounded = Math.ceil(average - 0.25)
        console.log('# ' + licenceHolder.name + ' ' + timeRange + 'T: ' + average + ' -> ' + rounded)
        if (licenceHolder.stripeCustomerId && licenceHolder.subscription) {
            this.userService.createStripeUsageRecord(licenceHolder.subscription.stripeSubscriptionItemId, rounded).subscribe(result => {})
        }
      }
    })
  }

  createMonitoringItem() {
    this.userService.getActiveLicencesByType('Coaching').then((licences) => {
      console.log('## Coaching: ' + licences.length)
      var activeLicencesPerHolder = new Map<string, number>()
      var trialLicencesPerHolder = new Map<string, number>()
      var nutritionActivationsPerHolder = new Map<string, number>()
      licences.forEach((licence) => {
        if (licence.isTrialLicence) {
          if (trialLicencesPerHolder.has(licence.licenceHolderUid)) {
            trialLicencesPerHolder.set(licence.licenceHolderUid, trialLicencesPerHolder.get(licence.licenceHolderUid ) + 1)
          } else {
            trialLicencesPerHolder.set(licence.licenceHolderUid, 1)
          }
        } else {
          if (activeLicencesPerHolder.has(licence.licenceHolderUid)) {
            activeLicencesPerHolder.set(licence.licenceHolderUid, activeLicencesPerHolder.get(licence.licenceHolderUid ) + 1)
          } else {
            activeLicencesPerHolder.set(licence.licenceHolderUid, 1)
          }
        }
        if (licence.monitoring && licence.monitoring.nutritionEnabled) {
          if (nutritionActivationsPerHolder.has(licence.licenceHolderUid)) {
            nutritionActivationsPerHolder.set(licence.licenceHolderUid, nutritionActivationsPerHolder.get(licence.licenceHolderUid ) + 1)
          } else {
            nutritionActivationsPerHolder.set(licence.licenceHolderUid, 1)
          }
        }
      })
      var monitoringItem = new LicenceMonitoringItem()
      monitoringItem.activeLicencesPerCoach = activeLicencesPerHolder
      monitoringItem.trialLicencesPerCoach = trialLicencesPerHolder
      monitoringItem.nutritionActivationsPerCoach = nutritionActivationsPerHolder
      var date = new Date()
      date.setUTCHours(0, 0, 0, 0)
      monitoringItem.date = date
      if (this.coachingLicenceMonitoringItems?.length != 0 && this.coachingLicenceMonitoringItems[this.coachingLicenceMonitoringItems.length - 1].date.isToday()) {
        var oldItem = this.coachingLicenceMonitoringItems.splice(this.coachingLicenceMonitoringItems.length - 1, 1, monitoringItem)[0]
        monitoringItem.id = oldItem.id
        this.userService.insertCoachingLicenceMonitoringItem(monitoringItem)
      } else {
        this.coachingLicenceMonitoringItems.push(monitoringItem)
        this.userService.insertCoachingLicenceMonitoringItem(monitoringItem)
      }
      this.updateGraphData()
    })

    this.userService.getActiveLicencesByType('Premium').then((licences) => {
      console.log('## Premium: ' + licences.length)
      var activeLicencesPerProductType = new Map<string, number>()
      licences.forEach((licence) => {
        if (activeLicencesPerProductType.has(licence.productId)) {
          activeLicencesPerProductType.set(licence.productId, activeLicencesPerProductType.get(licence.productId ) + 1)
        } else {
          activeLicencesPerProductType.set(licence.productId, 1)
        }
        
      })
      var monitoringItem = new LicenceMonitoringItem()
      monitoringItem.activeLicencesPerCoach = activeLicencesPerProductType
      monitoringItem.trialLicencesPerCoach = null
      var date = new Date()
      date.setUTCHours(0, 0, 0, 0)
      monitoringItem.date = date
      if (this.premiumLicenceMonitoringItems?.length != 0 && this.premiumLicenceMonitoringItems[this.premiumLicenceMonitoringItems.length - 1].date.isToday()) {
        var oldItem = this.premiumLicenceMonitoringItems.splice(this.premiumLicenceMonitoringItems.length - 1, 1, monitoringItem)[0]
        monitoringItem.id = oldItem.id
        this.userService.insertLicenceMonitoringItem(monitoringItem, 'Premium')
      } else {
        this.premiumLicenceMonitoringItems.push(monitoringItem)
        this.userService.insertLicenceMonitoringItem(monitoringItem, 'Premium')
      }
    })
  }
}