import {Component} from '@angular/core';
import {PaymentService} from "../../../../services/payment.service";
import {FirestoreService} from "../../../../services/firestore.service";
import * as moment from "moment";
import {PaymentAnalyticsService} from "../../../../services/payment-analytics.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-kpi-customer-retention',
  templateUrl: './kpi-customer-retention.component.html',
  styleUrls: ['./kpi-customer-retention.component.css']
})
export class KpiCustomerRetentionComponent {

  private averageCoachingTimeInMonths: number = 0;
  private coachingTimeInMonthsByCustomer: Map<string, number>;

  constructor(private userService: FirestoreService, private paymentService: PaymentService, private dashboardAnalyticsService: PaymentAnalyticsService, private translate: TranslateService) {
    this.calculateCR();
  }

  getPrintableCustomerRetention() {
    if (!this.paymentService.accountBalance) return null
    return this.averageCoachingTimeInMonths.toFixed(1) + ' ' + this.translate.instant('Monate');
  }

  private calculateCR() {
    const customerPurchases = this.dashboardAnalyticsService.getCoachingPurchases();
    this.coachingTimeInMonthsByCustomer = new Map<string, number>();

    for (let purchase of customerPurchases) {
      const startMoment = moment(purchase.startDate);
      const endMoment = moment(purchase.getEndDateTillEndOfMonth());
      let monthsDifference = endMoment.diff(startMoment, "months", true);
      const id = purchase.customerUid;

      if (this.coachingTimeInMonthsByCustomer.has(id)) {
        let duration = this.coachingTimeInMonthsByCustomer.get(id);
        monthsDifference += duration;
      }

      this.coachingTimeInMonthsByCustomer.set(id, monthsDifference);
    }

    const purchases = Array.from(this.coachingTimeInMonthsByCustomer.values());
    this.averageCoachingTimeInMonths = purchases.reduce((acc, cur) => acc + cur, 0) / (purchases.length || 1);
  }

  getPrintableCRInfo() {
    const purchases = Array.from(this.coachingTimeInMonthsByCustomer.values());
    const customers = purchases.length;
    const amount = purchases.reduce((acc, cur) => acc + cur, 0);

    return this.translate.instant('Coaching-Monate insgesamt: amountParameter, bei customerCountParameter Kunden', {
      amountParameter: amount.toFixed(1),
      customerCountParameter: customers
    });
  }
}
