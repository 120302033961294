import { Component, Input } from '@angular/core';
import { PaymentSettings } from 'src/app/model/payment-settings.model';

@Component({
  selector: 'app-payment-imprint',
  templateUrl: './payment-imprint.component.html',
  styleUrls: ['./payment-imprint.component.css']
})
export class PaymentImprintComponent {

  @Input() paymentSettings: PaymentSettings
  expanded: boolean = false

  toggleExpanded() {
    this.expanded = !this.expanded
  }
}
