<div class="page-content">
    <div class="container-fluid">
        <h2 class="">Dein Profil</h2>
        <div class="row align-items-left"style="margin-top: 2%;">
            <div class="col-sm">
                <span translate>Name:</span> {{user.name}}
            </div>
        </div>
        <div class="row align-items-left" style="margin-bottom: 2%;">
            <div class="col-sm">
                <span translate>Account-Email:</span> {{authServiceUser.email}}
            </div>
        </div>
        <div class="row align-items-left">
            <div class="col-sm">
                <span translate>Körpergröße:</span> {{user.bodyHeight}}
            </div>
        </div>
        <div class="row align-items-left">
            <div class="col-sm">
                <span translate>Körpergewicht:</span> {{user.bodyWeight}}
            </div>
        </div>
        <div class="row align-items-left">
            <div class="col-sm">
                <span translate>Alter:</span> {{user.age}}
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col">
                <chat>
                </chat>
            </div>
        </div>
    </div>
</div>
