import { marker } from "@colsen1991/ngx-translate-extract-marker";
import { FirestoreNutritionPlanService } from "../services/firestore-nutritionplan.service";
import { Repetition } from "./automatic-push-notification.model";
import { LanguageDictionary } from "./languagedictionary.model";

export class Task {
    id: string;
    title: string;
    description: string;
    dueDate: Date;
    assignedCoachUid: string;
    licenceHolderUid: string;
    deleted: boolean;
    done: boolean;
    connectedClientUid: string;
    connectedClientGroup: string;
    subTasks: SubTask[];
    priority: string;
    repetition: string;
    repetitionMultiplier: number;
    repetitionWeekDays: WeekDays;
    timestamp: Date;
    active: boolean;
    refTaskId: string;
    tags: LanguageDictionary<string>[]

    isSelected: boolean = false;

    constructor();
    constructor(init: Task);
    constructor(init?: Task){
        this.id = init && init.id || null;
        this.title = init && init.title || '';
        this.description = init && init.description || '';
        this.dueDate = init && init.dueDate || null;
        this.assignedCoachUid = init && init.assignedCoachUid || '';
        this.licenceHolderUid = init && init.licenceHolderUid || '';
        this.deleted = init && init.deleted || false;
        this.done = init && init.done || false;
        this.connectedClientUid = init && init.connectedClientUid || '';
        this.subTasks = init && init.subTasks?.map(x => new SubTask(x.id, x.title, x.done, x.position, x.deleted, x.timestamp)) || [];
        this.priority = init && init.priority || Priority.low.toString();
        this.repetition = init && init.repetition || Repetition.once.toString()
        this.repetitionMultiplier = init && init.repetitionMultiplier || 1
        this.repetitionWeekDays = init && new WeekDays(init.repetitionWeekDays?.monday, init.repetitionWeekDays?.tuesday, init.repetitionWeekDays?.wednesday, init.repetitionWeekDays?.thursday, init.repetitionWeekDays?.friday, init.repetitionWeekDays?.saturday, init.repetitionWeekDays?.sunday) || new WeekDays();
        this.timestamp = init && init.timestamp || new Date();
        this.active = init?.active === false ? init.active : true;
        this.refTaskId = init && init.refTaskId || this.id;
        this.tags = init && init.tags || [];
        this.connectedClientGroup = init && init.connectedClientGroup || null;

        try{
            this.subTasks?.sort((a, b) => a.position - b.position);
        }
        catch(ex){
            console.error(ex);
        }
    }

    asFirebaseMap(): any {
        for(let index = 0; index < this.subTasks.length; index++){
            this.subTasks[index].position = index;
        }
        return {
            title: this.title,
            description: this.description,
            dueDate: this.dueDate,
            assignedCoachUid: this.assignedCoachUid,
            licenceHolderUid: this.licenceHolderUid,
            deleted: this.deleted,
            done: this.done,
            connectedClientUid: this.connectedClientUid,
            priority: this.priority,
            repetition: this.repetition,
            repetitionWeekDays: this.repetitionWeekDays.asFirebaseMap(),
            repetitionMultiplier: this.repetitionMultiplier,
            subTasks: this.subTasks?.map(subTask => subTask.asFirebaseMap()) || [],
            timestamp: new Date(),
            active: this.active,
            refTaskId: this.refTaskId,
            tags: this.tags.map(x => x.originObject?.toString() || ""),
            connectedClientGroup: this.connectedClientGroup
        }
    }

    clone(): Task {
        let clonedTask = new Task(this);
        clonedTask.repetitionWeekDays = new WeekDays(clonedTask.repetitionWeekDays.monday, clonedTask.repetitionWeekDays.tuesday, clonedTask.repetitionWeekDays.wednesday, clonedTask.repetitionWeekDays.thursday, clonedTask.repetitionWeekDays.friday, clonedTask.repetitionWeekDays.saturday, clonedTask.repetitionWeekDays.sunday);
        
        clonedTask.tags = this.tags?.map(x => new LanguageDictionary(x?.de, x?.en, x?.originObject))
        return clonedTask;
    }
}

export class SubTask {
    id: string;
    done: boolean;
    position: number;
    deleted: boolean;
    timestamp: Date;

    private _title: string;
    public get title() : string {
        return this._title;
    }
    public set title(v : string) {
        this._title = v;
        this.hasChanges = true;
    }
    
    

    focus: boolean = false;
    hasChanges: boolean = false;
    
    constructor();
    constructor(id: string, title: string, done: boolean, position: number, deleted: boolean, timestamp: Date);
    constructor(id?: string, title?: string, done?: boolean, position?: number, deleted?: boolean, timestamp?: Date){
        this.id = id || null;
        this.title = title || '';
        this.done = done || false;
        this.position = position || null;
        this.deleted = deleted || false;
        this.timestamp = timestamp || new Date();

        this.hasChanges = false;
    }

    asFirebaseMap(): any {
        return {
            id: this.id || FirestoreNutritionPlanService.generateUniqueString(),
            title: this.title,
            done: this.done,
            position: this.position,
            deleted: this.deleted,
            timestamp: new Date()
        }
    }

    clone(): SubTask {
        return new SubTask(this.id, this.title, this.done, this.position, this.deleted, this.timestamp);
    }
}

export class WeekDays{
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;

    constructor();
    constructor(monday: boolean, tuesday: boolean, wednesday: boolean, thursday: boolean, friday: boolean, saturday: boolean, sunday: boolean);
    constructor(monday?: boolean, tuesday?: boolean, wednesday?: boolean, thursday?: boolean, friday?: boolean, saturday?: boolean, sunday?: boolean){
        this.monday = monday || false;
        this.tuesday = tuesday || false;
        this.wednesday = wednesday || false;
        this.thursday = thursday || false;
        this.friday = friday || false;
        this.saturday = saturday || false;
        this.sunday = sunday || false;
    }

    isDayNumberSelected(dayNumber: number){
        switch(dayNumber){
            case 0: return this.sunday;
            case 1: return this.monday;
            case 2: return this.tuesday;
            case 3: return this.wednesday;
            case 4: return this.thursday;
            case 5: return this.friday;
            case 6: return this.saturday;
            default: false;
        }
    }

    onSelectDayNumber(dayNumber: number, selected: boolean){
        switch(dayNumber){
            case 0: this.sunday = selected; break;
            case 1: this.monday = selected; break;
            case 2: this.tuesday = selected; break;
            case 3: this.wednesday = selected; break;
            case 4: this.thursday = selected; break;
            case 5: this.friday = selected; break;
            case 6: this.saturday = selected; break;
        }
    }

    getSelectedDayNumbers(): number[]{
        let selectedDayNumbers: number[] = [];
        if(this.sunday) selectedDayNumbers.push(0);
        if(this.monday) selectedDayNumbers.push(1);
        if(this.tuesday) selectedDayNumbers.push(2);
        if(this.wednesday) selectedDayNumbers.push(3);
        if(this.thursday) selectedDayNumbers.push(4);
        if(this.friday) selectedDayNumbers.push(5);
        if(this.saturday) selectedDayNumbers.push(6);
        return selectedDayNumbers;
    }

    onSwitchDayNumber(dayNumber: number){
        this.onSelectDayNumber(dayNumber, !this.isDayNumberSelected(dayNumber));
    }

    asFirebaseMap(): any {
        return {
            monday: this.monday,
            tuesday: this.tuesday,
            wednesday: this.wednesday,
            thursday: this.thursday,
            friday: this.friday,
            saturday: this.saturday,
            sunday: this.sunday
        }
    }
}

export enum Priority {
    low = 'low',
    medium = 'medium',
    high = 'high'
}

export const Priority2LabelMapping: Record<Priority, string> = {
    [Priority.low]: marker("Niedrig"),
    [Priority.medium]: marker("Mittel"),
    [Priority.high]: marker("Hoch")
};
  
export const Label2PriorityMapping: Record<string, Priority> = {
    "Niedrig": Priority.low,
    "Mittel": Priority.medium,
    "Hoch": Priority.high
};

export const ShortWeekDayStringsToNumberMapping: Record<string, number> = {
    "Mo": 1,
    "Di": 2,
    "Mi": 3,
    "Do": 4,
    "Fr": 5,
    "Sa": 6,
    "So": 0,
};

export const NumberToShortWeekDayStringsMapping: Record<number, string> = {
    1: "Mo",
    2: "Di",
    3: "Mi",
    4: "Do",
    5: "Fr",
    6: "Sa",
    0: "So",
};

export const ShortWeekDayStrings: string[] = [
    marker("Mo"),
    marker("Di"),
    marker("Mi"),
    marker("Do"),
    marker("Fr"),
    marker("Sa"),
    marker("So"),
];

export const NumberToWeekDayLetterMapping: Record<number, string> = {
    1: marker("BuchstabeMontag"),
    2: marker("BuchstabeDienstag"),
    3: marker("BuchstabeMittwoch"),
    4: marker("BuchstabeDonnerstag"),
    5: marker("BuchstabeFreitag"),
    6: marker("BuchstabeSamstag"),
    0: marker("BuchstabeSonntag"),
};