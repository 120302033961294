import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { Chat } from 'src/app/model/chat.model';
import { Licence } from 'src/app/model/lid.model';
import { User } from 'src/app/model/user.model';
import { ChatService } from 'src/app/services/chat.service';
import { FcmService } from 'src/app/services/fcm.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-base',
  templateUrl: './dashboard-base.component.html',
  styleUrls: ['./dashboard-base.component.css']
})
export class DashboardBaseComponent {

  public environment = environment

  constructor(public chatService: ChatService, public authService: AuthService, public router: Router, public userService: FirestoreService, public utilityService: UtilityService, public navService: NavbarService, public dialog: MatDialog, public fcmService: FcmService) {
  }


  public showEventLogView: boolean = true
  public showUnreadMessagesView: boolean = false
  public showExpiringLicensesView: boolean = false
  public showExpiringNutritionPlansView: boolean = false
  public showTasksView: boolean = false

  public eventLogTab = "tab-button active";
  public unreadMessagesTab = "tab-button";
  public expiringLicencesTab = "tab-button";
  public getExpiringNutritionPlansTab = "tab-button";
  public tasksViewTab = "tab-button";

  private profilePic: File;
  private profilePicImage = new Image();

  onDeactivateLicence(licence: Licence) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du die Lizenz wirklich deaktivieren? ' + licence.user?.name + ' kann danach nicht mehr in der Coaching Zone von dir betreut werden.', title: 'Lizenz deaktivieren' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.deactivateLicence(licence.lid);
      }
    });
  }


  resetTabs() {
    this.showEventLogView = false
    this.showUnreadMessagesView = false
    this.showExpiringLicensesView = false
    this.showExpiringNutritionPlansView = false
    this.showTasksView = false;
    this.eventLogTab = "tab-button";
    this.unreadMessagesTab = "tab-button";
    this.expiringLicencesTab = "tab-button";
    this.getExpiringNutritionPlansTab = "tab-button";
    this.tasksViewTab = "tab-button";
  }

  swapTab(tab: string) {
    this.resetTabs();
    switch(tab) {
      case "eventLogView":
        this.showEventLogView = true
        this.eventLogTab = "tab-button active";
        break;
      case "unreadMessagesView":
        this.showUnreadMessagesView = true;
        this.unreadMessagesTab = "tab-button active";
        break;
      case "expiringLicensesView":
        this.showExpiringLicensesView = true;
        this.expiringLicencesTab = "tab-button active";
        break;
      case "expiringNutritionPlansView":
        this.showExpiringNutritionPlansView = true;
        this.getExpiringNutritionPlansTab = "tab-button active";
        break;
      case "tasksView":
        this.showTasksView = true;
        this.tasksViewTab = "tab-button active";
        break;
      default:
        break;
    }
  }

  expiringPlansThreshold: number = 2
  getExpiringPlanClients(): User[] {
    var maxDate = new Date()
    maxDate.addDays(this.expiringPlansThreshold)
    return this.userService.getAccessibleClients().filter(x => x.hasExpiringNutritionPlan(maxDate) || x.hasExpiringTrainingPlan(maxDate))
  }
  getExpiringPlansThreshold() {
    return this.expiringPlansThreshold
  }
  getNumberOfExpiringPlans() {
    var maxDate = new Date()
    maxDate.addDays(this.expiringPlansThreshold)
    return this.userService.getAccessibleClients().reduce((value, current) => value + (current.hasExpiringNutritionPlan(maxDate) && current.hasExpiringTrainingPlan(maxDate) ? 2 : ( current.hasExpiringNutritionPlan(maxDate) || current.hasExpiringTrainingPlan(maxDate) ? 1 : 0 ) ), 0)
  }
  updateExpiringPlansThreshold(value: number) {
    this.expiringPlansThreshold = value
  }

  onHideExpiringNutritionPlanHint(user: User) {
    this.userService.dismissExpiringNutritionPlanHint(user)
  }
  onHideExpiringTrainingPlanHint(user: User) {
    this.userService.dismissExpiringTrainingPlanHint(user)
  }

  onOpenChat(chat: Chat) {
    this.chatService.openChat(chat);
  }

  openAccountSettings() {
    this.router.navigateByUrl('settings/?tab=account')
  }

  logout() {
    this.userService.logout();
    this.authService.logout();
  }

  getPendingLicences() {
    return this.userService.getAccessiblePendingLicences()
  }

  openProfilePicDialog() {
    document.getElementById('input-profilepicture').click()
  }

  uploadProfilePic(e) {
    this.profilePic = e.target.files[0];
    this.profilePicImage.src = URL.createObjectURL(this.profilePic);
    this.profilePicImage.onload = () => {
      var width = this.profilePicImage.width;
      var height = this.profilePicImage.height;

      var p = new Image();
      p.width = 250;
      var i = width / 250;
      p.height = height / i;
      p.src = this.profilePicImage.src;
      
      var x = 0;
      var y = 0;
      var min = height;
      if (width > height) {
        x = (width - height) / 2;
      } else {
        y = (height - width) / 2;
        min = width;
      }
      const elem = document.createElement('canvas');
      elem.width = 250;
      elem.height = 250;
      const ctx = elem.getContext('2d');
      ctx.drawImage(p, x, y, min, min, 0, 0, 250, 250);
      ctx.canvas.toBlob((blob) => {
          const file = new File([blob], "profile_picture.jpg", {
              type: 'image/jpeg',
              lastModified: Date.now()
          });
          this.userService.uploadProfileImage(file);
      }, 'image/jpeg', 1);
    }
    //this.userService.uploadProfileImage(this.profilePic);
  }

  isAdmin() {
    return this.authService.isAdmin()
  }

  refreshCapacitorApp(){
    window.location.reload();
  }
}
