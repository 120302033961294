import { Component, OnInit } from '@angular/core';

import { AuthService } from  '../../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UtilityService } from 'src/app/services/utility.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public environment = environment

  public mail = '';
  public password = '';

  constructor(public  authService:  AuthService, private userService: FirestoreService, private router: Router, private route: ActivatedRoute, public utilityService: UtilityService, private navService: NavbarService, private translate: TranslateService) { }

  ngOnInit(): void {
  }

  login() {
    var email = this.mail?.replaceAll(' ', '').replace(/[\u200B-\u200D\uFEFF]/g, '')
    this.authService.login(email, this.password).then((result) => {      
      // Handled in AppComponent in AuthService.AuthState subscription.
    }).catch((error) => {
      console.log(error)
      if (error?.code == 'auth/wrong-password') {
        window.alert(this.translate.instant('Passwort falsch. Bitte korrigiere deine Email-Adresse oder dein Passwort.'))
      } else if (error?.code == 'auth/invalid-email') {
        window.alert(this.translate.instant('Die Email-Adresse ist falsch formatiert. Bitte korrigiere sie.'))
      } else if (error?.code == 'auth/user-not-found') {
        window.alert(this.translate.instant('Unter dieser Email-Adresse konnte kein Account gefunden werden. Bitte probiere es mit einer anderen Email-Adresse.'))
      } else {
        window.alert(error.message);
      }
    });
  }

  goToWebsite() {
    window.open('https://nutrilize.app', "_blank");
  }
}
