// noinspection JSUnusedAssignment

import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {User} from "../model/user.model";
import {GoalPlan, GoalPlanConfig, GoalPlanMap, GoalPlanType} from "../model/goal-plan.model";
import {MetricGoalDialogComponent} from "../body-data/metric-goal-dialog/metric-goal-dialog.component";
import {GoalPlanService} from "../services/goal-plan.service";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {FirestoreService} from "../services/firestore.service";
import {Router} from "@angular/router";
import {AuthService} from "../auth/auth.service";
import {Subscription} from "rxjs";
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-goal-management',
  templateUrl: './goal-management.component.html',
  styleUrls: ['./goal-management.component.css']
})
export class GoalManagementComponent implements OnInit, OnDestroy {
  private _user: User;

  @Input()
  protected set user(user: User) {
    this._user = user
    if (user) {
      this.init();
    }
  }

  protected get user(): User {
    return this._user;
  }

  protected math = Math;
  protected environment = environment;
  protected displayMetricGoals: boolean;
  protected planMap: GoalPlanMap = null;
  protected today = new Date();
  protected progressPlans: GoalPlan[] = [];
  protected weeklyPlans: GoalPlan[] = [];
  protected dailyPlans: GoalPlan[] = [];

  private subscriptions: Subscription[] = [];


  constructor(private authService: AuthService, private router: Router, private userService: FirestoreService, private dialog: MatDialog, public translate: TranslateService, private goalPlanService: GoalPlanService, private changeDetection: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate['login'];
    }
  }

  private init() {
    this.subscriptions.push(this._user.observableGoalPlans.subscribe((planMap) => {
      if (!planMap) return;

      this.planMap = planMap;
      this.reevaluatePlans();
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      this.subscriptions.forEach(sub => sub.unsubscribe());
      this.subscriptions = [];
    }
  }

  private reevaluatePlans(): void {
    this.progressPlans = Array.from(this.planMap.values()).filter(plan => plan.type === GoalPlanType.PROGRESS && !plan.deleted).sort((a,b)=>a.name.localeCompare(b.name));
    this.weeklyPlans = Array.from(this.planMap.values()).filter(plan => plan.type === GoalPlanType.WEEKLY && !plan.deleted).sort((a,b)=>a.name.localeCompare(b.name));
    this.dailyPlans = Array.from(this.planMap.values()).filter(plan => plan.type === GoalPlanType.DAILY && !plan.deleted).sort((a,b)=>a.name.localeCompare(b.name));
    this.changeDetection.detectChanges();
  }

  protected onAddMetricGoal() {
    if (!this.userService.getLoggedInUser().isCoach) return

    let metric = null;

    const data: GoalPlanConfig = {
      metric: metric,
      type: GoalPlanType.DAILY,
      isNew: true,
      user: this._user
    };

    const dialogRef = this.dialog.open(MetricGoalDialogComponent, {data: data, width: '850px'});
    dialogRef.afterClosed().subscribe((result: GoalPlan) => {
      if (result) {
        if(this.planMap == null) {
          this.planMap = new Map<string, GoalPlan>();
        }
        this.planMap.set(result.id, result);
        let promise = null;
        if (this.planMap.size === 1) {
          promise = this.goalPlanService.addFirstGoalPlan(this._user, result);
        } else {
          promise = this.goalPlanService.updateGoalPlans(this._user, result);
        }

        if (this.displayMetricGoals) {
          promise.then(() => {

          });
        }
      }
    });
  }

  protected onUpdateMetricGoal(goalPlan: GoalPlan) {
    if (!this.userService.getLoggedInUser().isCoach) return
    
    const metric = this.userService.getMetricByMetricId(goalPlan.metric.metricId);

    const data: GoalPlanConfig = {
      metric: metric,
      type: goalPlan.type,
      isNew: false,
      goalPlan: goalPlan,
      user: this._user
    };
    const dialogRef = this.dialog.open(MetricGoalDialogComponent, {data: data, width: '850px'});
    dialogRef.afterClosed().subscribe((result: GoalPlan) => {
      if (result) {
        if (result.deleted) {
          this.planMap.delete(result.id);
        }
        const promise = this.goalPlanService.updateGoalPlans(this._user, result);

        if (this.displayMetricGoals) {
          promise.then(() => {

          });
        }
      }
    });
  }


  protected onShowPreviousWeek() {
    var d = new Date(this.today)
    d.addDays(-7)
    this.showWeekForDate(d)
  }

  protected onShowNextWeek() {
    var d = new Date(this.today)
    d.addDays(7)
    this.showWeekForDate(d)
  }

  protected showWeekForDate(date: Date) {
    this.today = date;
    this.today.setHours(0);
    this.today.setMinutes(0);
    this.today.setSeconds(0);
    this.today.setMilliseconds(0);

    this.reevaluatePlans();
  }
}
