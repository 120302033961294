<div class="h-100 layout-box sub-box">
  <h3 class="display-inlineblock" translate>Umsatz MTD</h3>
  <div class="">
    <div class="label" translate>
      Aktueller Monatsumsatz
    </div>
    <div class="spacer-5"></div>
    <div>
      {{ result }}
      <ng-container *ngIf="growthRate != Infinity">
        <span *ngIf="growthRate > 0" class="text-nowrap">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green"
               class="bi bi-graph-up-arrow marginright-5" viewBox="0 0 16 16">
            <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M10 3.5C10 3.36739 10.0527 3.24021 10.1465 3.14645C10.2402 3.05268 10.3674 3 10.5 3H14.5C14.6326 3 14.7598 3.05268 14.8536 3.14645C14.9473 3.24021 15 3.36739 15 3.5V7.5C15 7.63261 14.9473 7.75979 14.8536 7.85355C14.7598 7.94732 14.6326 8 14.5 8C14.3674 8 14.2402 7.94732 14.1465 7.85355C14.0527 7.75979 14 7.63261 14 7.5V4.9L10.387 9.317C10.3428 9.37099 10.2877 9.41511 10.2254 9.44655C10.1631 9.47798 10.0949 9.49602 10.0252 9.4995C9.95551 9.50299 9.88585 9.49184 9.82071 9.46678C9.75557 9.44172 9.69641 9.4033 9.64701 9.354L7.06001 6.767L3.40401 11.794C3.32403 11.8957 3.20775 11.9624 3.0796 11.9802C2.95144 11.9979 2.82139 11.9653 2.71678 11.8892C2.61217 11.8131 2.54114 11.6994 2.51862 11.572C2.4961 11.4446 2.52384 11.3134 2.59601 11.206L6.59601 5.706C6.63847 5.64751 6.6931 5.59893 6.75614 5.56359C6.81918 5.52825 6.88914 5.50699 6.96118 5.50129C7.03323 5.49559 7.10565 5.50557 7.17347 5.53055C7.24129 5.55554 7.30288 5.59492 7.35401 5.646L9.96301 8.256L13.445 4H10.5C10.3674 4 10.2402 3.94732 10.1465 3.85355C10.0527 3.75979 10 3.63261 10 3.5Z"/>
          </svg>
          <p class="text-success display-inlineblock">
            {{ (growthRate * 100).toFixed(0) + '%' }}
          </p>
        </span>
        <span *ngIf="growthRate === 0" class="text-nowrap">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-arrow-right marginright-5" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
          </svg>
          <p class="display-inlineblock">
            {{ (growthRate * 100).toFixed(0) + '%' }}
          </p>
        </span>
        <span *ngIf="growthRate < 0" class="text-nowrap">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red"
               class="bi bi-graph-down-arrow marginright-5" viewBox="0 0 16 16">
            <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M10 11.5001C10 11.6327 10.0527 11.7598 10.1465 11.8536C10.2402 11.9474 10.3674 12.0001 10.5 12.0001H14.5C14.6326 12.0001 14.7598 11.9474 14.8536 11.8536C14.9473 11.7598 15 11.6327 15 11.5001V7.50005C15 7.36745 14.9473 7.24027 14.8536 7.1465C14.7598 7.05273 14.6326 7.00005 14.5 7.00005C14.3674 7.00005 14.2402 7.05273 14.1465 7.1465C14.0527 7.24027 14 7.36745 14 7.50005V10.1001L10.387 5.68305C10.3428 5.62907 10.2877 5.58494 10.2254 5.55351C10.1631 5.52208 10.0949 5.50404 10.0252 5.50055C9.95551 5.49707 9.88585 5.50821 9.82071 5.53327C9.75557 5.55833 9.69641 5.59675 9.64701 5.64605L7.06001 8.23305L3.40401 3.20606C3.32403 3.10436 3.20775 3.03762 3.0796 3.01987C2.95144 3.00212 2.82139 3.03473 2.71678 3.11086C2.61217 3.18698 2.54114 3.3007 2.51862 3.4281C2.4961 3.5555 2.52384 3.68668 2.59601 3.79405L6.59601 9.29405C6.63847 9.35254 6.6931 9.40113 6.75614 9.43647C6.81918 9.47181 6.88914 9.49306 6.96118 9.49876C7.03323 9.50447 7.10565 9.49448 7.17347 9.4695C7.24129 9.44452 7.30288 9.40513 7.35401 9.35405L9.96301 6.74405L13.445 11.0001H10.5C10.3674 11.0001 10.2402 11.0527 10.1465 11.1465C10.0527 11.2403 10 11.3674 10 11.5001Z"/>
          </svg>
          <p class="text-danger display-inlineblock">
            {{ (growthRate * 100).toFixed(0) + '%' }}
          </p>
        </span>
        <a class="icon-button marginleft-10"
           [tooltip]=info>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-info-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path
              d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
          </svg>
        </a>
      </ng-container>
    </div>
  </div>
</div>
