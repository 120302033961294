import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { CardioZone, CardioZoneGroup, MinMaxPercentages } from 'src/app/model/cardio-zone-group.model';
import { SetParameter } from 'src/app/model/training-plan.model';

@Component({
  selector: 'app-global-cardio-zone-editor',
  templateUrl: './global-cardio-zone-editor.component.html',
  styleUrls: ['./global-cardio-zone-editor.component.css']
})
export class GlobalCardioZoneEditorComponent {
  @Input() zones: CardioZone[] = [];

  constructor(private dialog: MatDialog) { }

  public namePattern: string = CardioZone.namePattern;
  public minMaxPercentagePattern: string = CardioZone.minMaxPercentagePattern;
  public SetParameter = SetParameter;

  addCardioZone(){
    let zone = new CardioZone();
    zone.name = "Zone " + this.zones.length;
    this.zones.push(zone);
  }

  setName(value: string, index: number){
    this.zones[index].name = value;
  }

  removeCardioZone(zone: CardioZone){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Stelle sicher, dass diese Belastungszone in keinem Trainingsplan verwendet wird. <br>Bist du sicher, dass du diese Belastungszone löschen möchtest?', title: 'Belastungszone löschen', positiveButton: 'Ja', negativeButton: 'Nein' },

    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) {
        this.zones.splice(this.zones.indexOf(zone), 1);
      }
    })
  }

  getCardioZonesHintText(){
    return " In diesem Fenster kannst du die Anzahl und Namen der unterschiedlichen Belastungszonen festlegen, die dann für jeden Coachee übernommen werden. Die Definition der Zonenwerte bpm und Pace kannst du dann in den Trainingsvariablen des Coachees direkt vornehmen.";
  }

  getIndicatorColor(zone: CardioZone){
    return CardioZoneGroup.getColorByCardioZone(this.zones, zone);
  }

  setIndicatorColor(zone: CardioZone, color: string){
    zone.color = color;
  }

  getMinMaxPercentageString(zone: CardioZone, setParameter: SetParameter){
    if(setParameter == SetParameter.pace && zone.minMaxPercentagesPace){
      return (zone.minMaxPercentagesPace.min * 100)?.roundToInt() + "-" + (zone.minMaxPercentagesPace.max * 100)?.roundToInt();
    }
    else if (setParameter == SetParameter.pace500 && zone.minMaxPercentagesPace500){
      return (zone.minMaxPercentagesPace500.min * 100)?.roundToInt() + "-" + (zone.minMaxPercentagesPace500.max * 100)?.roundToInt();
    }
    else if (setParameter == SetParameter.heartRate && zone.minMaxPercentagesPaceHeartRate){
      return (zone.minMaxPercentagesPaceHeartRate.min * 100)?.roundToInt() + "-" + (zone.minMaxPercentagesPaceHeartRate.max * 100)?.roundToInt();
    }
    else {
      let percentages = CardioZoneGroup.getDefaultCardioZonePercentages(this.zones, zone, setParameter)
      if(percentages){
        return (percentages.min * 100)?.roundToInt() + "-" + (percentages.max * 100)?.roundToInt();
      }
    }
    return "";
  }

  setPercentages(value: string, zone: CardioZone, setParameter: SetParameter) {
    value = value.replace("%", "");
    let percentages = value.split("-");
    if(percentages.length == 2){
      let min = parseInt(percentages[0]);
      let max = parseInt(percentages[1]);

      if(min >= 0 && max >= 0){
        min = min / 100;
        max = max / 100;

        if(setParameter == SetParameter.pace){
          zone.minMaxPercentagesPace = new MinMaxPercentages(min, max);
        }
        else if(setParameter == SetParameter.pace500){
          zone.minMaxPercentagesPace500 = new MinMaxPercentages(min, max);
        }
        else if(setParameter == SetParameter.heartRate){
          zone.minMaxPercentagesPaceHeartRate = new MinMaxPercentages(min, max);
        }
      }
    }
  }

}
