import { Component, Inject, OnInit } from '@angular/core';
import { Metric } from '../model/metric.model';
import { MetricType } from '../metrics-assignment/metrics-assignment.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FirestoreService } from '../services/firestore.service';
import { User } from '../model/user.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { LanguageService } from '../services/language.service';
import { LanguageDictionary } from '../model/languagedictionary.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-custom-metric-editor-dialog',
  templateUrl: './custom-metric-editor-dialog.component.html',
  styleUrls: ['./custom-metric-editor-dialog.component.css']
})
export class CustomMetricEditorDialogComponent implements OnInit {

  public customMetric: Metric;
  isNewMetric() {
    return this.customMetric.id == null
  }
  public coach: User;

  public showCustomMetricMinValueError = false;
  public showCustomMetricMaxValueError = false;
  public showCustomMetricTargetValueError = false;
  public customMetricForDeletion: Metric = null
  public customMetricSelectableValues: string[] = []
  public customMetricSelectableValuesEn: string[] = []

  constructor(public dialogRef: MatDialogRef<CustomMetricEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {customMetric: Metric, coach: User}, private userService: FirestoreService, private spinner: NgxSpinnerService, public languageService: LanguageService, private translate: TranslateService) {
    this.customMetric = data.customMetric;
    this.coach = data.coach;
    this.customMetricSelectableValues = this.customMetric.getSelectableValues('de') || [""]
    this.customMetricSelectableValuesEn = this.customMetric.getSelectableValues('en') || [""]
    this.showCustomMetricMinValueError = false
    this.showCustomMetricMaxValueError = false
    this.showCustomMetricTargetValueError = false
  }

  ngOnInit(): void {
  }

  onCloseCustomMetricDialog(){
    this.onCloseDialog();
  }

  getSelectableValues(language: string): string[] {
    if (language == 'en') return this.customMetricSelectableValuesEn
    return this.customMetricSelectableValues
  }

  public customMetricTypes: MetricType[] = [
    {type: 'NUMBER', description: this.translate.instant('Zahl')},
    {type: 'YES_NO', description: this.translate.instant('Ja/Nein')},
    {type: 'SELECTION', description: this.translate.instant('Einfach-Auswahl mit vorgegebenen Werten')},
    {type: 'TEXT', description: this.translate.instant('Text')},
    {type: 'DURATION', description: this.translate.instant('Dauer (h:min:s)')},
    {type: 'DURATION_SECONDS', description: 'Dauer (h:min:s)'},
    {type: 'IMAGE', description: this.translate.instant('Bild')},
    {type: 'VIDEO', description: this.translate.instant('Video')},
    {type: 'MULTISELECT', description: this.translate.instant('Mehrfach-Auswahl mit vorgegebenen Werten')},
    {type: 'TODO', description: this.translate.instant('ToDo')},
  ]
  getDescriptionForMetricType(type: string) {
    var description = ''
    this.customMetricTypes.forEach(metricType => {
      if (metricType.type == type) description = metricType.description
    })
    return description
  }
  onCustomMetricUnitChanged(text: string, language: string) {
    if (text?.length == 0) text = null
    this.customMetric.unitTranslation.SetValue(language, text)
  }
  onCustomMetricValueExplanationChanged(text: string) {
    if (text?.length == 0) text = null
    this.customMetric.valueExplanationTranslation[this.languageService.selectedLanguageCode] = text
  }
  onCustomMetricTypeChanged(text: string) {
    this.customMetric.metricType = text
    if (this.customMetric.isMetricTypeNumber()) this.customMetric.dataType = 'INTEGER'
    if (this.customMetric.isMetricTypeText()) this.customMetric.dataType = 'STRING'
    if (this.customMetric.isMetricTypeSelection()) this.customMetric.dataType = 'INTEGER'
    if (this.customMetric.isMetricTypeYesNo()) this.customMetric.dataType = 'BOOLEAN'
    if (this.customMetric.isMetricTypeDuration()) this.customMetric.dataType = 'INTEGER'
    if (this.customMetric.isMetricTypeImage()) this.customMetric.dataType = 'STRING'
    if (this.customMetric.isMetricTypeVideo()) this.customMetric.dataType = 'STRING'
    if (this.customMetric.isMetricTypeMultiselect()) this.customMetric.dataType = 'STRING'
    if (this.customMetric.isMetricTypeToDo()) this.customMetric.dataType = 'BOOLEAN'
    this.customMetric.targetValue = null
  }
  onCustomMetricDataTypeChanged(text: string) {
    this.customMetric.dataType = text
    this.customMetric.minValue = null
    this.customMetric.maxValue = null
    this.customMetric.targetValue = null
    this.showCustomMetricMinValueError = false
    this.showCustomMetricMaxValueError = false
    this.showCustomMetricTargetValueError = false
    this.customMetricSelectableValues = [""]
    this.customMetricSelectableValuesEn = [""]
    
  }
  onCustomMetricMinValueChanged(text: string) {
    if (text == '-') {
      this.showCustomMetricMinValueError = true
      return
    }
    if (text == null || text.length == 0) {
      this.customMetric.minValue = null
      this.showCustomMetricMinValueError = false
      return
    }
    if (this.customMetric.isDataTypeDouble()) {
      if (text) text = text.replace(',', '.')
      var regex = new RegExp("^[0-9,.-]+$");
      if (text?.length > 0 && regex.test(text)) {
        var number = parseFloat(text)
        this.customMetric.minValue = number
        this.showCustomMetricMinValueError = false
      } else {
        this.showCustomMetricMinValueError = true
      }
    }
    if (this.customMetric.isDataTypeInteger()) {
      var regex = new RegExp("^[0-9-]+$");
      if (text?.length > 0 && regex.test(text))  {
        var number = parseInt(text);
        this.customMetric.minValue = number
        this.showCustomMetricMinValueError = false
      } else {
        this.showCustomMetricMinValueError = true
      }
    }
  }
  onCustomMetricMaxValueChanged(text: string) {
    if (text == '-') {
      this.showCustomMetricMaxValueError = true
      return
    }
    if (text == null || text.length == 0) {
      this.customMetric.maxValue = null
      this.showCustomMetricMaxValueError = false
      return
    }
    if (this.customMetric.isDataTypeDouble()) {
      if (text) text = text.replace(',', '.')
      var regex = new RegExp("^[0-9,.-]+$");
      if (text?.length > 0 && regex.test(text)) {
        var number = parseFloat(text)
        this.customMetric.maxValue = number
        this.showCustomMetricMaxValueError = false
      } else {
        this.showCustomMetricMaxValueError = true
      }
    }
    if (this.customMetric.isDataTypeInteger()) {
      var regex = new RegExp("^[0-9-]+$");
      if (text?.length > 0 && regex.test(text))  {
        var number = parseInt(text);
        this.customMetric.maxValue = number
        this.showCustomMetricMaxValueError = false
      } else {
        this.showCustomMetricMaxValueError = true
      }
    }
  }

  onMetricTargetValueChanged(text: string, metric: Metric) {
    metric.targetValue = null
    metric.hasTargetValueError = false
    if (text == '-') {
      metric.hasTargetValueError = true
      return
    }
    if (text == null || text.length == 0) {
      metric.targetValue = null
      metric.hasTargetValueError = false
      return
    }
    if (metric.isMetricTypeDuration()) {
      if (!text.includes(':') || text.endsWith(':')) {
        metric.hasTargetValueError = true
        return
      }
      var regex = new RegExp("^[0-9,:]+$");
      var values = text?.split(':')
      if (text?.length > 0 && regex.test(text) && values?.length == 2)  {
        var hours = parseInt(values[0]);
        var minutes = parseInt(values[1]);
        metric.targetValue = 60 * hours + minutes
        metric.hasTargetValueError = false
      } else {
        metric.hasTargetValueError = true
      }
    } else if (metric.isDataTypeDouble()) {
      if (text) text = text.replace(',', '.')
      var regex = new RegExp("^[0-9,.-]+$");
      if (text?.length > 0 && regex.test(text)) {
        var number = parseFloat(text)
        metric.targetValue = number
        metric.hasTargetValueError = false
      } else {
        metric.hasTargetValueError = true
      }
    } else if (metric.isDataTypeInteger()) {
      var regex = new RegExp("^[0-9-]+$");
      if (text?.length > 0 && regex.test(text))  {
        var number = parseInt(text);
        metric.targetValue = number
        metric.hasTargetValueError = false
      } else {
        metric.hasTargetValueError = true
      }
    }
  }
  onMetricSelectableValueChanged(text: string, index: number) {
    text = text.replace(";", "")
    this.getSelectableValues(this.languageService.selectedLanguageCode)[index] = text
  }
  onAddNewSelectableValue() {
    this.customMetricSelectableValues.push("")
    this.customMetricSelectableValuesEn.push("")
  }
  onDeleteSelectableValue(index: number) {
    this.customMetricSelectableValues.splice(index, 1)
    this.customMetricSelectableValuesEn.splice(index, 1)
  }

  onSaveCustomMetric() {
    if (this.customMetric.isMetricTypeSelection() || this.customMetric.isMetricTypeMultiselect()) {
      if (!this.customMetric.selectableValuesTranslation) this.customMetric.selectableValuesTranslation = new LanguageDictionary()
      
      if (this.customMetricSelectableValues?.filter(x => x?.length > 0)?.length > 0) {
        var values = ''
        this.customMetricSelectableValues.forEach(value => {
          values = values + value + ";"
        })
        if (values.endsWith(";")) values = values.substr(0, values.length - 1)
        this.customMetric.selectableValues = values
        this.customMetric.selectableValuesTranslation['de'] = values
      }

      if (this.customMetricSelectableValuesEn?.filter(x => x?.length > 0)?.length > 0) {
        var values = ''
        this.customMetricSelectableValuesEn.forEach(value => {
          values = values + value + ";"
        })
        if (values.endsWith(";")) values = values.substr(0, values.length - 1)
        if(this.customMetric.selectableValues?.length == 0) this.customMetric.selectableValues = values
        this.customMetric.selectableValuesTranslation['en'] = values
      }
    }

    if (this.customMetric.id) {
      try {
        if (!this.customMetric.questionText && this.customMetric.questionTextTranslation) this.customMetric.questionText = this.customMetric.questionTextTranslation.GetValue('de') ?? null
        this.userService.updateCustomMetric(this.customMetric);
      } catch (error) {
        console.error(error);
      }
    } else {
      this.userService.insertNewCustomMetric(this.coach, this.customMetric)
    }
    
    this.onCloseDialog();
  }

  onCloseDialog(){
    this.dialogRef.close();
  }

  isCustomMetricValid(): boolean {
    var valid = true
    if (this.customMetric.nameTranslation[this.languageService.selectedLanguageCode] == null || this.customMetric.nameTranslation[this.languageService.selectedLanguageCode].length == 0) valid = false
    if (this.customMetric.isMetricTypeNumber()) {
      if (this.showCustomMetricMinValueError || this.showCustomMetricMaxValueError || this.showCustomMetricTargetValueError) valid = false
    }
    if (this.customMetric.isMetricTypeSelection() || this.customMetric.isMetricTypeMultiselect()) {
      if (this.customMetricSelectableValues.length == 0) valid = false
      this.customMetricSelectableValues.forEach(value => {
        if (value == null || value.length == 0) valid = false
      })
    }
    if (this.customMetric.hasTargetValueError) valid = false
    return valid
  }
  
  createRange(number){
    var items: number[] = [];
    for(var i = 0; i < number; i++){
       items.push(i);
    }
    return items;
  }
}
