<div *ngIf="authService?.isAdmin()" class="page-content">
    <div class="usingdatabase-toggle toggle-wrapper">
        <div class="toggle {{databaseAdminInUse ? 'on' : ''}}" (click)="onDatabaseAdminInUseChanged(!databaseAdminInUse)">
            <div class="handle"></div>
        </div>
        <div class="text" translate>
            NF-Vorschläge in Bearbeitung
        </div>
    </div>
    <div class="tab-selection">
        <button class="button tab-button" (click)="onSelectTab('NUTRITIONFACTS')" translate>
            Nährwertdaten
            <div *ngIf="selectedTab == 'NUTRITIONFACTS'" class="underline"></div>
        </button>
        <button class="button tab-button" (click)="onSelectTab('SERVINGS')" translate>
            Portionsvorschläge
            <div *ngIf="selectedTab == 'SERVINGS'" class="underline"></div>
        </button>
    </div>
    <div *ngIf="selectedTab == 'SERVINGS'" class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="icon-button" (click)="loadNewServingInquiry()" translate>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                    </svg>
                    Neuen Portionsvorschlag laden
                </div>
            </div>
            <div class="col-12"><div class="spacer-25"></div></div>
            <div *ngIf="servingInquiry" class="col-6">
                <div class="layout-box">
                    <div class="row">
                        <div class="col-12">
                            <div class="icon-button" (click)="updateBaseNutritionFactAndDeleteServingInquiry()" translate>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hdd" viewBox="0 0 16 16">
                                    <path d="M4.5 11a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM3 10.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                    <path d="M16 11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V9.51c0-.418.105-.83.305-1.197l2.472-4.531A1.5 1.5 0 0 1 4.094 3h7.812a1.5 1.5 0 0 1 1.317.782l2.472 4.53c.2.368.305.78.305 1.198V11zM3.655 4.26L1.592 8.043C1.724 8.014 1.86 8 2 8h12c.14 0 .276.014.408.042L12.345 4.26a.5.5 0 0 0-.439-.26H4.094a.5.5 0 0 0-.44.26zM1 10v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1z"/>
                                </svg>
                                Updaten und Portionsvorschlag löschen
                            </div>
                            <div class="spacer-25"></div>
                        </div>
                        <div class="col-12">
                            id: {{baseNutritionFact?.id}}
                        </div>
                        <div class="col-12">
                            nameDe: {{baseNutritionFact?.nameDe}}
                        </div>
                        <div class="col-12">
                            servingSizes: <input class="input small wide" [ngModel]="baseNutritionFact?.servingSizes" (input)="onBaseNutritionFactServingSizesChanged($event.target.value)" type="text" />
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="servingInquiry" class="col-6">
                <div class="layout-box">
                    <div class="row">
                        <div class="col-12">
                            <div class="icon-button" (click)="deleteServingInquiry()" translate>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                </svg>
                                Löschen und neu laden
                            </div>
                            <div class="spacer-25"></div>
                        </div>
                        <div class="col-12">
                            <input class="input small wide" [ngModel]="servingInquiry?.servingSize" (input)="onServingInquiryChanged($event.target.value)" type="text" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [style.display]="selectedTab == 'NUTRITIONFACTS' ? 'block' : 'none'" class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="icon-button" (click)="loadNewBaseNutritionFactInquiry()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                    </svg>
                    <span translate>NutritionFact-Vorschlag laden</span> (~{{numberOfInquiries}})
                </div>
                <div class="icon-button marginleft-25" (click)="loadNewBaseNutritionFactInquiryOFF()" translate>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                    </svg>
                    OpenFoodFact laden
                </div>
                <div class="icon-button marginleft-25" (click)="loadBaseNutritionFactWithMistake()" translate>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                    </svg>
                    Fehlerhaften NF laden
                </div>
            </div>
            <div class="col-12"><div class="spacer-25"></div></div>
            <div  [style.display]="baseNutritionFact ? 'block' : 'none'" class="col-6 nutritionfact-data">
                <div class="layout-box">
                    <div class="row">
                        <div class="col-12">
                            <div class="icon-button" *ngIf="baseNutritionFact?.id" (click)="saveBaseNutritionFact()" translate>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hdd" viewBox="0 0 16 16">
                                    <path d="M4.5 11a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM3 10.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                    <path d="M16 11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V9.51c0-.418.105-.83.305-1.197l2.472-4.531A1.5 1.5 0 0 1 4.094 3h7.812a1.5 1.5 0 0 1 1.317.782l2.472 4.53c.2.368.305.78.305 1.198V11zM3.655 4.26L1.592 8.043C1.724 8.014 1.86 8 2 8h12c.14 0 .276.014.408.042L12.345 4.26a.5.5 0 0 0-.439-.26H4.094a.5.5 0 0 0-.44.26zM1 10v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1z"/>
                                </svg>
                                NutritionFact speichern
                            </div>
                            <div class="icon-button" *ngIf="baseNutritionFactSaved">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                                </svg>
                            </div>
                            <div class="icon-button marginleft-25" *ngIf="baseNutritionFact" (click)="clearBaseNutritionFact()" translate>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eraser" viewBox="0 0 16 16">
                                    <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm2.121.707a1 1 0 0 0-1.414 0L4.16 7.547l5.293 5.293 4.633-4.633a1 1 0 0 0 0-1.414l-3.879-3.879zM8.746 13.547L3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z"/>
                                </svg>
                                Leeren
                            </div>
                            <div class="icon-button marginleft-25" *ngIf="baseNutritionFact" (click)="onDeleteBaseNutritionFact()" translate>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                </svg>
                                Löschen
                            </div>
                            <div id="delete-basenutritionfact-dialog" class="dialog-wrapper">
                                <div class="dialog layout-box">
                                    <h3 translate>NutritionFact löschen</h3>
                                    <div class="content">
                                        <p translate>
                                            Nicht löschen, wenn der NutritionFact schon in Verwendung ist!
                                        </p>
                                    </div>
                                    <div class="actions">
                                        <button class="button-filled small light" (click)="onCancelBaseNutritionFactDeletion()" translate>Abbrechen</button>
                                        <button class="button-filled small light" (click)="onConfirmBaseNutritionFactDeletion()" translate>Löschen</button>
                                    </div>
                                </div>
                            </div>
                            <div class="spacer-25"></div>
                        </div>
                        <div class="col-12">
                            <div id="nutritionfact-search">
                                <div id="nutritionfact-search-box" class="search-panel">
                                    <div class="search-panel__results">
                                        <div id="searchbox" (click)="onNutritionFactSearchClicked()"></div>
                                        <div class="icon-button float-right deletesearch-icon delete-nutritionfactsearch" (click)="onDeleteNutritionFactSearch()">
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                        </div>
                                        <div class="ais-Hits">
                                            <ul class="ais-Hits-list" *ngIf="state?.hits?.length > 0 && nutritionFactSearchFocused">
                                                <ng-container *ngFor="let hit of state.hits">
                                                    <li class="ais-Hits-item link" (click)="onNutritionFactSelected(hit)">
                                                        {{hit.nameDe}} <span *ngIf="hit.brands">({{hit.brands}})</span>
                                                        <div class="nutritional-values-small">
                                                            {{hit.carbohydrates?.toFixed(2)}}{{'g K' | translate}}/ {{hit.protein?.toFixed(2)}}{{'g E' | translate}}/ {{hit.fat?.toFixed(2)}}{{'g F' | translate}}/ {{hit.calories?.toFixed(2) || ((hit.carbohydrates + hit.protein) * 4.1 + hit.fat * 9.3).toFixed(0)}} {{'kcal' | translate}}
                                                        </div>
                                                    </li>
                                                </ng-container>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div id="pagination" [style.display]="nutritionFactSearchFocused ? 'block' : 'none'"></div>
                            </div>
                            <div class="spacer-25"></div>
                        </div>

                        <div class="col-12 nutritionfact-dataentry {{(baseNutritionFact?.barcode && ('brc' + baseNutritionFact.barcode) != baseNutritionFact.id) || (!baseNutritionFact?.barcode && baseNutritionFact?.id?.startsWith('brc')) ? 'highlight' : ''}}">
                            id: <input class="input small wide" [ngModel]="baseNutritionFact?.id" (input)="idOfBaseNutritionFactChanged($event.target.value)" type="text" />
                            <div class="icon-button" (click)="generateBrandedBnfId()" translate>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hash" viewBox="0 0 16 16">
                                    <path d="M8.39 12.648a1.32 1.32 0 0 0-.015.18c0 .305.21.508.5.508.266 0 .492-.172.555-.477l.554-2.703h1.204c.421 0 .617-.234.617-.547 0-.312-.188-.53-.617-.53h-.985l.516-2.524h1.265c.43 0 .618-.227.618-.547 0-.313-.188-.524-.618-.524h-1.046l.476-2.304a1.06 1.06 0 0 0 .016-.164.51.51 0 0 0-.516-.516.54.54 0 0 0-.539.43l-.523 2.554H7.617l.477-2.304c.008-.04.015-.118.015-.164a.512.512 0 0 0-.523-.516.539.539 0 0 0-.531.43L6.53 5.484H5.414c-.43 0-.617.22-.617.532 0 .312.187.539.617.539h.906l-.515 2.523H4.609c-.421 0-.609.219-.609.531 0 .313.188.547.61.547h.976l-.516 2.492c-.008.04-.015.125-.015.18 0 .305.21.508.5.508.265 0 .492-.172.554-.477l.555-2.703h2.242l-.515 2.492zm-1-6.109h2.266l-.515 2.563H6.859l.532-2.563z"/>
                                  </svg>
                                Generieren für Markenlebensmittel
                            </div>
                            <div class="icon-button marginleft-25" (click)="generateCommonBnfId()" translate>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hash" viewBox="0 0 16 16">
                                    <path d="M8.39 12.648a1.32 1.32 0 0 0-.015.18c0 .305.21.508.5.508.266 0 .492-.172.555-.477l.554-2.703h1.204c.421 0 .617-.234.617-.547 0-.312-.188-.53-.617-.53h-.985l.516-2.524h1.265c.43 0 .618-.227.618-.547 0-.313-.188-.524-.618-.524h-1.046l.476-2.304a1.06 1.06 0 0 0 .016-.164.51.51 0 0 0-.516-.516.54.54 0 0 0-.539.43l-.523 2.554H7.617l.477-2.304c.008-.04.015-.118.015-.164a.512.512 0 0 0-.523-.516.539.539 0 0 0-.531.43L6.53 5.484H5.414c-.43 0-.617.22-.617.532 0 .312.187.539.617.539h.906l-.515 2.523H4.609c-.421 0-.609.219-.609.531 0 .313.188.547.61.547h.976l-.516 2.492c-.008.04-.015.125-.015.18 0 .305.21.508.5.508.265 0 .492-.172.554-.477l.555-2.703h2.242l-.515 2.492zm-1-6.109h2.266l-.515 2.563H6.859l.532-2.563z"/>
                                  </svg>
                                Generieren für Allgemein
                            </div>
                        </div>
                        <div class="col-12 nutritionfact-dataentry">
                            barcode: <input class="input small wide" [ngModel]="baseNutritionFact?.barcode" (input)="barcodeOfBaseNutritionFactChanged($event.target.value)" type="text" />
                            <div *ngIf="baseNutritionFact?.barcode" class="icon-button marginleft-10">
                                <a href="https://fddb.info/db/de/suche/?udd=0&cat=site-de&search={{baseNutritionFact?.barcode}}" target="_blank">FDDB</a>
                            </div>
                            <div *ngIf="baseNutritionFact?.barcode" class="icon-button marginleft-10">
                                <a href="https://de.openfoodfacts.org/cgi/search.pl?search_terms={{baseNutritionFact?.barcode}}&search_simple=1&action=process" target="_blank">OFF</a>
                            </div>
                            <div *ngIf="baseNutritionFact?.barcode" class="icon-button marginleft-10">
                                <a href="https://www.google.com/search?q=ean+{{baseNutritionFact?.barcode}}" target="_blank">Google</a>
                            </div>
                            <div *ngIf="baseNutritionFact?.barcode" class="icon-button marginleft-10">
                                <a (click)="onImportOFFFact()" target="_blank">OFF Import</a>
                            </div>
                        </div>
                        <div class="col-12 nutritionfact-dataentry">
                            nameDe: <input class="input small wide" [ngModel]="baseNutritionFact?.nameDe" (input)="nameDeOfBaseNutritionFactChanged($event.target.value)" type="text" />
                        </div>
                        <div class="col-12 nutritionfact-dataentry">
                            nameEn: <input class="input small wide" [ngModel]="baseNutritionFact?.nameEn" (input)="nameEnOfBaseNutritionFactChanged($event.target.value)" type="text" />
                        </div>
                        <div class="col-12 nutritionfact-dataentry">
                            nameEs: <input class="input small wide" [ngModel]="baseNutritionFact?.nameEs" (input)="nameEsOfBaseNutritionFactChanged($event.target.value)" type="text" />
                        </div>
                        <div class="col-12 nutritionfact-dataentry">
                            nameFr: <input class="input small wide" [ngModel]="baseNutritionFact?.nameFr" (input)="nameFrOfBaseNutritionFactChanged($event.target.value)" type="text" />
                        </div>
                        <div class="col-12 nutritionfact-dataentry">
                            brands: <input class="input small wide" [ngModel]="baseNutritionFact?.brands" (input)="brandsOfBaseNutritionFactChanged($event.target.value)" type="text" />
                        </div>
                        <div class="col-12 nutritionfact-dataentry">
                            synonymsDe: <input class="input small wide" [ngModel]="tmpSynonymsDe" (input)="synonymsDeOfBaseNutritionFactChanged($event.target.value)" type="text" /> <span class="label">Synonyme mit ';' trennen</span>
                        </div>
                        <div class="col-12 nutritionfact-dataentry">
                            servingSizes: <input class="input small wide" [ngModel]="baseNutritionFact?.servingSizes" (input)="servingSizesOfBaseNutritionFactChanged($event.target.value)" type="text" />
                            <div class="icon-button marginleft-10" (click)="onAddServingToBaseNutritionFact('PACKAGE:')" translate>
                                Packung
                            </div>
                            <div class="icon-button marginleft-10" (click)="onAddServingToBaseNutritionFact('SERVING:')" translate>
                                Portion
                            </div>
                            <div class="icon-button marginleft-10" (click)="onAddServingToBaseNutritionFact('PIECES_1:')" translate>
                                x Stück
                            </div>
                            <div class="icon-button marginleft-10" (click)="onAddServingToBaseNutritionFact('TABLESPOON:')" translate>
                                EL
                            </div>
                            <div class="icon-button marginleft-10" (click)="onAddServingToBaseNutritionFact('TEASPOON:')" translate>
                                TL
                            </div>
                            <div class="icon-button marginleft-10" (click)="onAddServingToBaseNutritionFact('CUP:')" translate>
                                Tasse
                            </div>
                            <div class="icon-button marginleft-10" (click)="onAddServingToBaseNutritionFact('CUSTOM\'Bezeichnung\':')" translate>
                                Custom
                            </div>
                        </div>
                        <div class="col-12 nutritionfact-dataentry">
                            unit: <input class="input small wide" [ngModel]="baseNutritionFact?.unit" (input)="unitOfBaseNutritionFactChanged($event.target.value)" type="text" />
                        </div>
                        <div class="col-12 nutritionfact-dataentry">
                            region: <input class="input small wide" [ngModel]="baseNutritionFact?.region" (input)="regionOfBaseNutritionFactChanged($event.target.value)" type="text" />
                        </div>
                        <div class="col-12 nutritionfact-dataentry">
                            valid: <input class="input checkbox" type="checkbox" [checked]="baseNutritionFact?.valid" (change)="validOfBaseNutritionFactChanged($event.target.checked)">
                        </div>

                        <ng-container *ngFor="let nutritionalValue of getNutritionalValues()">
                            <div class="col-12 nutritionfact-dataentry {{(nutritionalValue == 'carbohydrates' || nutritionalValue == 'protein' || nutritionalValue == 'fat' || nutritionalValue == 'calories') && baseNutritionFact?.getNutritionalValue(nutritionalValue) == null ? 'highlight' : ''}} {{baseNutritionFact?.getNutritionalValue(nutritionalValue) != baseNutritionFactInquiry?.getNutritionalValue(nutritionalValue) && baseNutritionFactInquiry?.getNutritionalValue(nutritionalValue) != null ? 'highlight' : ''}}">
                                {{nutritionalValue}}: <input class="input small wide" [ngModel]="baseNutritionFact?.getNutritionalValue(nutritionalValue)" (input)="valueOfBaseNutritionFactChanged(nutritionalValue, $event.target.value)" type="text" />
                            </div>
                        </ng-container>
                    
                        <div class="col-12 nutritionfact-dataentry">
                            nutriScore: <input class="input small wide" [ngModel]="baseNutritionFact?.nutriScore" (input)="nutriScoreOfBaseNutritionFactChanged($event.target.value)" type="text" />
                        </div>
                        <div class="col-12 nutritionfact-dataentry">
                            nutriScoreGrade: <input class="input small wide" [ngModel]="baseNutritionFact?.nutriScoreGrade" (input)="nutriScoreGradeOfBaseNutritionFactChanged($event.target.value)" type="text" />
                        </div>
                        <div class="col-12 nutritionfact-dataentry">
                            offCategory1: <input class="input small wide" [ngModel]="baseNutritionFact?.offCategory1" (input)="offCategory1OfBaseNutritionFactChanged($event.target.value)" type="text" />
                        </div>
                        <div class="col-12 nutritionfact-dataentry">
                            offCategory2: <input class="input small wide" [ngModel]="baseNutritionFact?.offCategory2" (input)="offCategory2OfBaseNutritionFactChanged($event.target.value)" type="text" />
                        </div>
                        <div class="col-12 nutritionfact-dataentry">
                            imageUrl: <input class="input small wide" [ngModel]="baseNutritionFact?.imageUrl" (input)="imageUrlOfBaseNutritionFactChanged($event.target.value)" type="text" />
                        </div>


                    </div>
                </div>
            </div>
            <div *ngIf="baseNutritionFactInquiry" class="col-6 nutritionfact-data">
                <div class="layout-box">
                    <div class="row">
                        <div class="col-12">
                            <div class="icon-button" (click)="createNutritionFactFromInquiry()" translate>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                                  </svg>
                                NutritionFact erstellen
                            </div>
                            <div class="icon-button marginleft-25" (click)="deleteBaseNutritionFactInquiry()" translate>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                </svg>
                                Löschen und neu laden
                            </div>
                            <div class="spacer-25"></div>
                        </div>
                        <div class="col-12 nutritionfact-dataentry">
                            id: {{baseNutritionFactInquiry?.id}}
                        </div>
                        <div class="col-12 nutritionfact-dataentry">
                            baseNutritionFactId: {{baseNutritionFactInquiry?.baseNutritionFactId}}
                        </div>
                        
                        <div class="col-12 nutritionfact-dataentry {{baseNutritionFact?.barcode != baseNutritionFactInquiry?.barcode ? 'highlight' : ''}}">
                            barcode: <input class="input small wide" [ngModel]="baseNutritionFactInquiry?.barcode" (input)="barcodeOfBaseNutritionFactInquiryChanged($event.target.value)" type="text" />
                        </div>
                        <div class="col-12 nutritionfact-dataentry {{baseNutritionFact?.nameDe != baseNutritionFactInquiry?.nameDe ? 'highlight' : ''}}">
                            nameDe: <input class="input small wide" [ngModel]="baseNutritionFactInquiry?.nameDe" (input)="nameDeOfBaseNutritionFactInquiryChanged($event.target.value)" type="text" />
                        </div>
                        <div class="col-12 nutritionfact-dataentry {{baseNutritionFact?.nameEn != baseNutritionFactInquiry?.nameEn ? 'highlight' : ''}}">
                            nameEn: <input class="input small wide" [ngModel]="baseNutritionFactInquiry?.nameEn" (input)="nameEnOfBaseNutritionFactInquiryChanged($event.target.value)" type="text" />
                        </div>
                        <div class="col-12 nutritionfact-dataentry {{baseNutritionFact?.brands != baseNutritionFactInquiry?.brands ? 'highlight' : ''}}">
                            brands: <input class="input small wide" [ngModel]="baseNutritionFactInquiry?.brands" (input)="brandsOfBaseNutritionFactInquiryChanged($event.target.value)" type="text" />
                        </div>
                        <div class="col-12 nutritionfact-dataentry {{baseNutritionFact?.servingSizes != baseNutritionFactInquiry?.servingSizes ? 'highlight' : ''}}">
                            servingSizes: <input class="input small wide" [ngModel]="baseNutritionFactInquiry?.servingSizes" (input)="servingSizesOfBaseNutritionFactInquiryChanged($event.target.value)" type="text" />
                        </div>
                        <div class="col-12 nutritionfact-dataentry {{baseNutritionFact?.unit != baseNutritionFactInquiry?.unit ? 'highlight' : ''}}">
                            unit: <input class="input small wide" [ngModel]="baseNutritionFactInquiry?.unit" (input)="unitOfBaseNutritionFactInquiryChanged($event.target.value)" type="text" />
                        </div>
                        <div class="col-12 nutritionfact-dataentry {{baseNutritionFact?.region != baseNutritionFactInquiry?.region ? 'highlight' : ''}}">
                            region: <input class="input small wide" [ngModel]="baseNutritionFactInquiry?.region" (input)="regionOfBaseNutritionFactInquiryChanged($event.target.value)" type="text" />
                        </div>
                        <div class="col-12 nutritionfact-dataentry">
                            valid: <input class="input checkbox" type="checkbox" [checked]="baseNutritionFactInquiry?.valid" (change)="validOfBaseNutritionFactInquiryChanged($event.target.checked)">
                        </div>

                        <ng-container *ngFor="let nutritionalValue of getNutritionalValues()">
                            <div class="col-12 nutritionfact-dataentry {{(nutritionalValue == 'carbohydrates' || nutritionalValue == 'protein' || nutritionalValue == 'fat' || nutritionalValue == 'calories') && baseNutritionFactInquiry?.getNutritionalValue(nutritionalValue) == null ? 'highlight' : ''}}">
                                {{nutritionalValue}}: <input class="input small wide" [ngModel]="baseNutritionFactInquiry?.getNutritionalValue(nutritionalValue)" (input)="valueOfBaseNutritionFactInquiryChanged(nutritionalValue, $event.target.value)" type="text" />
                            </div>
                        </ng-container>
                    
                        <div class="col-12 nutritionfact-dataentry {{baseNutritionFact?.nutriScore != baseNutritionFactInquiry?.nutriScore ? 'highlight' : ''}}">
                            nutriScore: <input class="input small wide" [ngModel]="baseNutritionFactInquiry?.nutriScore" (input)="nutriScoreOfBaseNutritionFactInquiryChanged($event.target.value)" type="text" />
                        </div>
                        <div class="col-12 nutritionfact-dataentry {{baseNutritionFact?.nutriScoreGrade != baseNutritionFactInquiry?.nutriScoreGrade ? 'highlight' : ''}}">
                            nutriScoreGrade: <input class="input small wide" [ngModel]="baseNutritionFactInquiry?.nutriScoreGrade" (input)="nutriScoreGradeOfBaseNutritionFactInquiryChanged($event.target.value)" type="text" />
                        </div>
                        <div class="col-12 nutritionfact-dataentry {{baseNutritionFact?.offCategory1 != baseNutritionFactInquiry?.offCategory1 ? 'highlight' : ''}}">
                            offCategory1: <input class="input small wide" [ngModel]="baseNutritionFactInquiry?.offCategory1" (input)="offCategory1OfBaseNutritionFactInquiryChanged($event.target.value)" type="text" />
                        </div>
                        <div class="col-12 nutritionfact-dataentry {{baseNutritionFact?.offCategory2 != baseNutritionFactInquiry?.offCategory2 ? 'highlight' : ''}}">
                            offCategory2: <input class="input small wide" [ngModel]="baseNutritionFactInquiry?.offCategory2" (input)="offCategory2OfBaseNutritionFactInquiryChanged($event.target.value)" type="text" />
                        </div>
                        <div class="col-12 nutritionfact-dataentry {{baseNutritionFact?.imageUrl != baseNutritionFactInquiry?.imageUrl ? 'highlight' : ''}}">
                            imageUrl: <input class="input small wide" [ngModel]="baseNutritionFactInquiry?.imageUrl" (input)="imageUrlOfBaseNutritionFactInquiryChanged($event.target.value)" type="text" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>