<div class="page-content">
    <div class="container-fluid">
        <div class="" *ngIf="environment.firebaseProjectId == 'traindoo-app' && utilityService.onMobile()">
            <app-library-mobile-navigation></app-library-mobile-navigation>
        </div>
        <h2 class="" translate>Wissen</h2>
        <div class="layout-box padding-0">
            <app-file-sharing [User]="null"></app-file-sharing>
        </div>
    </div>
</div>

<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>