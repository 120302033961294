import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Licence } from '../model/lid.model';
import { User } from '../model/user.model';
import { ChatService } from '../services/chat.service';
import { FirestoreService } from '../services/firestore.service';
import { NavbarService } from '../services/navbar.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Coach } from '../model/coach.model';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import {MatDialog } from '@angular/material/dialog';
import { QuestionairesService } from '../services/questionaires.service';
import { Questionaire } from '../model/questionaires.model';
import { LicenceDialogComponent } from '../dialogs/licence-dialog/licence-dialog.component';
import { PaymentService } from '../services/payment.service';
import { ProductPurchase } from '../model/product-purchase.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-licence-management',
  templateUrl: './licence-management.component.html',
  styleUrls: ['./licence-management.component.css']
})
export class LicenceManagementComponent implements OnInit {

  public environment = environment

  public coachesOfLicenceHolder: Coach[] = []
  public clients: User[];
  public filteredClients: User[];
  public clientSearchInput: string
  public chats = [];
  private lidForDeactivation: string;
  public usedTrialLicences: number = 0
  public canCheckAsTrialLicence: boolean = false
  public availableQuestionaires: Questionaire[] = []

  public static TAB_OPEN = 'open'
  public get tabOpen() {
    return LicenceManagementComponent.TAB_OPEN
  }
  public static TAB_PLANNED = 'planned'
  public get tabPlanned() {
    return LicenceManagementComponent.TAB_PLANNED
  }
  public currentTab = LicenceManagementComponent.TAB_OPEN

  get user(): User {
    return this.userService.getLoggedInUser()
  }

  constructor(public chatService: ChatService, private authService: AuthService, private router: Router, public userService: FirestoreService, private paymentService: PaymentService, public navService: NavbarService, private toastr: ToastrService, public questionaireService: QuestionairesService, public dialog: MatDialog, private spinner: NgxSpinnerService, public translate: TranslateService) { }


  private clientsSubscription: Subscription = null;
  ngOnInit(): void {
    this.clients = [];
    this.clients = this.userService.getAllClientsOfLicenceHolder();
    this.filteredClients = this.clients?.filter(client => this.user.coach?.canAccessUser(client))
    if (this.clientsSubscription) this.clientsSubscription.unsubscribe();
    this.clientsSubscription = this.userService.getObservableClients().subscribe(clients => {
      this.clients = clients.all?.filter(client => this.user.coach?.canAccessUser(client))
      this.onClientSearchInputChanged(this.clientSearchInput)
      this.refreshUsedTrialLicences()
    })
    var subscriptionCoaches = this.userService.getAllCoachesByLicenceHolderUid(this.userService.getLoggedInUser().licenceHolderUid).subscribe(coaches => {
      this.coachesOfLicenceHolder = coaches
      subscriptionCoaches.unsubscribe()
    })
    if (!this.authService.isLoggedIn) {
      this.router.navigate['login'];
    }
    this.refreshUsedTrialLicences()
    this.questionaireService.getQuestionaires().then(questionaires => {
      this.availableQuestionaires = questionaires
    })
  }

  onChangeTab(tab: string) {
    this.currentTab = tab
  }

  getQuestionaireById(id: string) {
    return this.availableQuestionaires.filter(q => q.id == id).shift()
  }

  getCoachForUser(user: User) {
    return this.coachesOfLicenceHolder.filter(c => c.uid == user.coachUid).shift() || null
  }
  getCoachByUid(uid: string) {
    if (!uid) return null
    return this.coachesOfLicenceHolder.filter(c => c.uid == uid).shift() || null
  }

  getPendingLicences() {
    return this.userService.getAccessiblePendingLicences()
  }
  getOpenPendingLicences() {
    return this.userService.getAccessiblePendingLicences().filter(licence => !licence.plannedActivationDate)
  }
  getPlannedPendingLicences() {
    return this.userService.getAccessiblePendingLicences().filter(licence => licence.plannedActivationDate)
  }
  onAssignNewCoach(user: User, coach: Coach) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: this.translate.instant('Möchtest du den zugewiesenen Coach wirklich ändern?'), title: this.translate.instant('Neuen Coach zuweisen') },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) {
        await this.userService.updateAssignedCoach(user, coach.uid)
      }
    })
  }
  onActivatePendingLicence(licence: Licence) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: this.translate.instant('Möchtest du diese Lizenz sofort aktivieren? Der Kauf und dessen Startdatum werden dadurch nicht verändert.<br>Wenn du das Abonnement schon früher starten lassen möchtest, kannst du unter Zahlung -> Käufe den Kauf auswählen und das Startdatum anpassen.'), title: this.translate.instant('Lizenz aktivieren') },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) {
        this.spinner.show()
        this.userService.activateLicenceForProductPurchase(licence.lid, licence.productPurchaseId).then((res) => {
          this.spinner.hide()
          if (res?.success == true) {
            this.toastr.success(this.translate.instant('Lizenz wurde aktiviert und Einladung per Email versendet.'))
          } else {
            this.toastr.error(this.translate.instant('Lizenz konnte nicht aktiviert werden.'))
          }
        })
      }
    })
  }

  onSetCoachReference(user: User, coach: Coach) {
    user.coachReferenceUid = coach?.uid ?? null
    this.userService.updateUserCoachReferenceUid(user)
  }
  isCommunityEnabled() {
    return this.userService.getLoggedInUser().licenceHolder?.communityEnabled
  }

  refreshUsedTrialLicences() {
    var i = 0
    this.clients.forEach(user => {
      if (user.licence?.isTrialLicence) i++
    })
    this.usedTrialLicences = i
    this.canCheckAsTrialLicence = this.usedTrialLicences < this.userService.getLoggedInUser().licenceHolder?.availableTrialLicences
  }

  onConnectClient() {
    this.onShowLicence(null, true)
  }

  async onDeletePendingLicence(licence: Licence) {
    var subscription: ProductPurchase
    if (licence.productPurchaseId) {
      subscription = await this.paymentService.productPurchases.filter(s => s.id == licence.productPurchaseId).shift()
    }
    var message = this.translate.instant('Möchtest du diese Einladung wirklich löschen?')
    if (subscription) {
      if (subscription.status == 'uncompleted') {
        message += this.translate.instant('<br><br><b>Achtung:</b> Die Lizenz ist mit einem Abonnement verknüpft.<br>Wenn du diese löscht, kann das Abonnement nicht mehr abgeschlossen werden.')
      } else if (subscription.status == 'active' || subscription.status == 'purchased') {
        return
      }
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: message, title: this.translate.instant('Einladung löschen') },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result == true) {
        if (subscription) {
          await this.userService.deleteProductPurchase(subscription)
        }
        this.userService.deleteLicence(licence)
      }
    })
  }

  logout() {
    this.authService.logout();
    this.userService.logout();
  }

  onLicenceIssuedChanged(licence: Licence, event: any){
    licence.issued = event.currentTarget.checked;
    this.userService.updateLicenceIssued(licence);
  }

  onExpirationDateChanged(licence: Licence, date: Date) {
    licence.expirationDate = date
    this.userService.updateLicenceExpirationDate(licence)
  }

  onTrialLicenceToggled(licence: Licence, checked: boolean) {
    licence.isTrialLicence = checked
    this.userService.updateLicenceIsTrialLicence(licence)
    this.refreshUsedTrialLicences()
  }

  onDeactivateLicence(lid: string) {
    this.lidForDeactivation = lid;
    this.toggleDeactivationDialog()
  }

  onShowLicence(licence: Licence, newLicence: boolean = false) {
    const dialogRef = this.dialog.open(LicenceDialogComponent, { data: { user: this.user, licence: licence, coachesOfLicenceHolder: this.coachesOfLicenceHolder, availableQuestionaires: this.availableQuestionaires, createNew: newLicence }, width: '1000px'})
  }

  confirmDeactivation() {
    this.userService.deactivateLicence(this.lidForDeactivation);
    this.toggleDeactivationDialog()
  }

  cancelDeactivation() {
    this.lidForDeactivation = undefined
    this.toggleDeactivationDialog()
  }

  onClientSearchInputChanged(text: string) {
    this.clientSearchInput = text
    if (!text || text.length == 0) {
      this.filteredClients = this.clients
    } else {
      this.filteredClients = []
      this.clients.forEach(client => {
        if (client.getName().toLowerCase().includes(text.toLowerCase())) {
          this.filteredClients.push(client)
        }
      })
    }
  }
  onDeleteClientSearchInput() {
    (<HTMLInputElement> document.getElementById('clientsearch-input')).value = ''
    this.filteredClients = this.clients
    this.clientSearchInput = null
  }

  toggleDeactivationDialog() {
   document.getElementById('deactivate-licence-dialog').classList.toggle('show');
   return false;
  }

  async onExportClients() {
  this.spinner.show()
    var data: any[] = []
    for (let i = 0; i < this.filteredClients.length; i++) {
      var client = this.filteredClients[i]
      var email = await this.userService.getEmailForUser(client.uid)
      data.push([client.getName(), email])
    }
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], {type: 'text/csv' })
    FileSaver.saveAs(blob, this.translate.instant('Kunden') + '.csv')
    this.spinner.hide()
  }

  isAdmin() {
    return this.authService.isAdmin()
  }

}
