<ng-container *ngIf="!editMode; else editModeTemplate">
    <ng-container *ngIf="metricData.value != null">
        <div class="marginleft-5 label" *ngIf="isQuestionaireMetricData">
            {{metricData.metric.questionTextTranslation?.GetValue(languageService.selectedLanguageCode) ?? metricData.metric.questionText}}
        </div>
        <div class="icon" [innerHTML]="EventLogService.getEventItemMetricIcon(metricData.metric) | sanitizedHtml">
        </div>
        <div class="d-inline-block marginleft-5 align-top">{{metricData?.metric?.nameDe}}:</div>
        <div *ngIf="metricData?.metric?.isMetricTypeImage()" class="marginleft-5">
            <div class="layout-box image-box link" (click)="onOpenMetricDataImage(metricData)" [ngStyle]="{'background-image':'url('+metricData?.mediaLink+')'}"></div>
        </div>
        
        <div *ngIf="metricData?.metric?.isMetricTypeVideo()" class="d-flex marginleft-5 align-top">
            <div class="layout-box video-box">
                <video controls playsinline src="{{metricData?.mediaLink}}" preload="metadata"></video>
            </div>
        </div>
        
        <div *ngIf="metricData?.metric?.isMetricTypeMultiselect()" class="marginleft-5 align-top">
            <div *ngFor="let selectableValue of metricData?.metric?.getSelectableValues(); let i = index;" class="tab-button d-inline-block {{metricData?.isIndexSelected(i) ? 'active' : ''}}">
                <div>{{selectableValue}}</div>
            </div>
        </div>
        
        <div *ngIf="metricData?.metric?.isMetricTypeToDo()" class="display-inlineblock marginleft-5 align-top">
            <div *ngIf="metricData?.value" class="d-inline-block ml-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#4AE6D7" class="bi bi-check2-circle" viewBox="0 0 16 16">
                <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                </svg>
            </div>
            <div *ngIf="!metricData?.value" class="d-inline-block ml-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                </svg>
            </div>
        </div>
        
        
        <div *ngIf="!metricData?.metric?.isMetricTypeImage() && !metricData?.metric?.isMetricTypeVideo() && !metricData?.metric?.isMetricTypeMultiselect() && !metricData?.metric?.isMetricTypeToDo()" class="display-inlineblock marginleft-10">{{metricData?.getPrintableValue()}} {{metricData?.metric?.unit}}</div>
    </ng-container>
</ng-container>
<ng-template #editModeTemplate>
    <div class="marginleft-5 label" *ngIf="isQuestionaireMetricData">
        {{metricData.metric.questionTextTranslation?.GetValue(languageService.selectedLanguageCode) ?? metricData.metric.questionText}}
    </div>
    <div class="icon" [innerHTML]="EventLogService.getEventItemMetricIcon(metricData.metric) | sanitizedHtml">
    </div>
    <div class="d-inline-block marginleft-5">{{metricData?.metric?.nameDe}}:</div>
    <div *ngIf="metricData?.metric?.isMetricTypeImage()" class="marginleft-5">
        <div class="layout-box image-box link" (click)="fileUpload.click()" [ngStyle]="{'background-image':'url('+metricData?.mediaLink+')'}"></div>
        <input id="thumbnail-input" type="file" style="display: none;" accept=".jpg, .png, .jpeg" #fileUpload (change)="uploadMetricDataImage($event, metricData)">
    </div>
    
    <div *ngIf="metricData?.metric?.isMetricTypeVideo()" class="row marginleft-5 align-top">
        <div class="col-auto">
            <div class="row layout-box video-box">
                <video controls playsinline [src]="metricData.mediaLink" preload="metadata"></video>
            </div>
            <div class="row label link" (click)="videoUpload.click()">
                Bearbeiten
                <input id="video-input" type="file" style="display: none;" accept=".mp4, .x-m4v, .mov" #videoUpload (change)="uploadMetricDataVideo($event, metricData)">
            </div>
        </div>
    </div>
    <div *ngIf="metricData?.metric?.isMetricTypeSelection()" class="marginleft-5 align-top d-inline-block">
        <div class="d-inline-block wrapper-statisticstype-selection">
            <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownStatisticsGraphType" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{metricData?.getPrintableValue()}}</button>
            <div class="dropdown-menu" aria-labelledby="dropdownStatisticsGraphType">
                <div *ngFor="let selectableValue of metricData?.metric?.getSelectableValues(); let i = index;">
                    <button class="dropdown-item" type="button" (click)="onMetricSelectionChanged(metricData, i)">{{selectableValue}}</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="metricData?.metric?.isMetricTypeMultiselect()" class="marginleft-5 align-top">
        <div (click)="multiSelectionChanged(metricData, i)" *ngFor="let selectableValue of metricData?.metric?.getSelectableValues(); let i = index;" class="link tab-button d-inline-block {{metricData?.isIndexSelected(i) ? 'active' : ''}}">
            <div>{{selectableValue}}</div>
        </div>
    </div>
    
    <div *ngIf="metricData?.metric?.isMetricTypeToDo()" class="display-inlineblock marginleft-5 align-top">
        <input class="input checkbox margin-vertical-auto h-100" type="checkbox" [(ngModel)]="metricData.value" >
    </div>
    <div *ngIf="metricData?.metric?.isMetricTypeYesNo()" class="display-inlineblock marginleft-5 align-top">
        <div class="icon-button">
            Nein
            <div class="toggle-wrapper multi-text-toggle display-inlineblock">
                <div class="toggle {{metricData.value ? 'on' : ''}}" (click)="switchYesNo()">
                    <div class="handle"></div>
                </div>
            </div>
            Ja
        </div>
    </div>
    <div *ngIf="metricData?.metric?.isMetricTypeDuration()" class="display-inlineblock marginleft-10">
        <app-time-input-control class="d-inline-block" [backgroundColor]="utilityService.getCSSVariableValue('--lightBackgroundColor')" [(fullMinutes)]="metricData.value" ></app-time-input-control>
    </div>
    <div *ngIf="metricData?.metric?.isMetricTypeText()">
        <app-md-editor class="multi-line"
                            (valueChanged)="metricData?.setPrintableValue($event)"
                            [inputValue]="metricData.value"
                            [placeHolder]="''">
                        </app-md-editor>
                        
    </div>
    <div class="d-inline-block" *ngIf="metricData?.metric?.isMetricTypeNumber()">
        <input class="metric-input {{metricData?.isValueValid() ? '' : 'ng-invalid'}}" type="text" pattern="{{metricData?.metric.dataType == 'INTEGER' ? '-?[0-9]+?' : '-?[0-9]+([,\.][0-9]+)?'}}" [max]="metricData?.metric?.maxValue" [min]="metricData?.metric?.minValue" [ngModel]="metricData?.getPrintableValue()" (input)="metricData?.setPrintableValue($event.target.value)">
        {{metricData?.metric?.unit}}
    </div>
    <div class="d-inline-block" *ngIf="!metricData?.metric?.isMetricTypeNumber() && !metricData?.metric?.isMetricTypeYesNo() && !metricData?.metric?.isMetricTypeImage() && !metricData?.metric?.isMetricTypeText() && !metricData?.metric?.isMetricTypeDuration() && !metricData?.metric?.isMetricTypeVideo() && !metricData?.metric?.isMetricTypeMultiselect() && !metricData?.metric?.isMetricTypeToDo() && !metricData?.metric?.isMetricTypeSelection()" class="display-inlineblock marginleft-10">
        <input class="metric-input" type="text" [ngModel]="metricData?.getPrintableValue()" (input)="metricData?.setPrintableValue($event.target.value)">
        <!-- {{metricData?.getPrintableValue()}}  -->
        {{metricData?.metric?.unit}}
    </div>
    <div class="icon-button float-right marginleft-2 d-inlin" (click)="onRemoveMetricData();$event.stopPropagation()" tooltip="{{metricData?.metric?.nameTranslation.GetValue(languageService.selectedLanguageCode)}} entfernen">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="padding-6 bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
    </div>
</ng-template>
<ngx-spinner name="image-editor-spinner" bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>