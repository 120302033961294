import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProfileSettingsDialogComponent } from 'src/app/dialogs/profile-settings-dialog/profile-settings-dialog.component';
import { TrainingPlan } from 'src/app/model/training-plan.model';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UtilityService } from 'src/app/services/utility.service';
import { RpeTableDialogComponent } from 'src/app/training/rpe-table-dialog/rpe-table-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-v2',
  templateUrl: './client-v2.component.html',
  styleUrls: ['./client-v2.component.css']
})
export class ClientV2Component {

  public environment = environment
  
  get user() {
    return this.userService.getLoggedInUser()
  }

  public displayedUser: User
  public loaded = false
  public selectedTrainingPlan: TrainingPlan

  @Input() set setDisplayedUser(value: User) {
    this.displayedUser = value
    if (this.displayedUser) this.loadData()
  }

  async loadData() {
    await this.userService.loadLicenceSettingsForUser(this.displayedUser)
    await this.userService.loadTrainingPlans(this.displayedUser, this.user)
    await this.userService.loadAssignedMetricsForUser(this.displayedUser)
    if (!this.displayedUser.dailyConditions || this.displayedUser.dailyConditions.length == 0) {
      await this.loadDailyConditionsForUser(this.displayedUser)
    }
    this.loaded = true
  }

  constructor(public userService: FirestoreService, public utilityService: UtilityService, private router: Router, public dialog: MatDialog) {
    console.log(router.url)
  }

  private subscriptionDailyCondition: Subscription

  async loadDailyConditionsForUser(user: User) {
    if (this.subscriptionDailyCondition) {
      this.subscriptionDailyCondition.unsubscribe()
      this.subscriptionDailyCondition = null
    }
    this.subscriptionDailyCondition = this.userService.getDailyConditionsForUser(user).subscribe(async dailyConditions => {
      user.dailyConditions = dailyConditions.sort((a, b) => b.date.getTime() - a.date.getTime());
    })
  }

  isClientsAnalyticsMenuItemActive() {
    return this.router.url.includes('analytics')
  }
  isClientsTrainingMenuItemActive() {
    return this.router.url.includes('training') || !this.router.url.includes('?page=')
  }
  isClientsNutritionMenuItemActive() {
    return this.router.url.includes('nutrition')
  }

  public nutritionPlanOpened: boolean = false
  onNutritionPlanOpened(value: boolean) {
    this.nutritionPlanOpened = value
  }
  
  isNutritionEnabled() {
    if (this.displayedUser.nutritionEnabled != null) return this.displayedUser.nutritionEnabled
    return this.userService.getLoggedInUser().isNutritionEnabled()
  }

  openUserProfile() {
    var dialog = this.dialog.open(ProfileSettingsDialogComponent, { data: { user: this.displayedUser }, width: '600px' });
  }

  onSelectedTrainingPlanChanged(trainingPlan: TrainingPlan) {
    this.selectedTrainingPlan = trainingPlan
  }

}
