import { Component, Input } from '@angular/core';
import { user } from '@angular/fire/auth';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-client-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent {

  constructor(public userService: FirestoreService) { }

  public user: User
  @Input() set User(value: User){
    this.user = value
    this.init()
  }

  public totalElements = 6

  entryConfigs: any[] = [] // itemId: string, position: number, size: string

  init() {
    var coach = this.userService.getLoggedInUser().coach
    this.entryConfigs = coach.portalSettingsCoach.clientAnalyticsPageConfigs

    if (this.entryConfigs == null || this.entryConfigs.length == 0) {
      var config: any = {}
      config.itemId = 'checkin-table-view'
      config.position = 0
      config.size = 'two-thirds'
      this.entryConfigs.push(config)

      config = {}
      config.itemId = 'training-history'
      config.position = 1
      config.size = 'one-third'
      this.entryConfigs.push(config)

      config = {}
      config.itemId = 'body-data'
      config.position = 2
      config.size = 'two-thirds'
      this.entryConfigs.push(config)

      config = {}
      config.itemId = 'completed-questionaires-overview'
      config.position = 3
      config.size = 'one-third'
      this.entryConfigs.push(config)

      config = {}
      config.itemId = 'training-graph'
      config.position = 4
      config.size = 'full'
      this.entryConfigs.push(config)

      config = {}
      config.itemId = 'nutrition-graph'
      config.position = 5
      config.size = 'full'
      this.entryConfigs.push(config)
    }
  }

  isConfigEnabled(itemId: string) {
    return this.entryConfigs.filter(x => x.itemId == itemId).length > 0
  }

  getColSize(config: any) {
    if (config.size == 'full') return 'col-12'
    if (config.size == 'half') return 'col-lg-6'
    if (config.size == 'one-third') return 'col-lg-4'
    if (config.size == 'two-thirds') return 'col-lg-8'
    return 'col-lg-12'
  }

  isNutritionEnabled() {
    if (this.user.nutritionEnabled != null) return this.user.nutritionEnabled
    return this.userService.getLoggedInUser().isNutritionEnabled()
  }

  onAddConfigEntry(itemId: string) {
    var config: any = {}
    config.itemId = itemId
    config.position = this.entryConfigs.length
    config.size = 'half'
    this.entryConfigs.push(config)
    this.userService.getLoggedInUser().coach.portalSettingsCoach.clientAnalyticsPageConfigs = this.entryConfigs
    this.userService.updatePortalSettingsForCoach(this.userService.getLoggedInUser())
  }

  async onRemoveConfig(config: any) {
    this.entryConfigs = this.entryConfigs.filter(x => x != config)
    this.userService.getLoggedInUser().coach.portalSettingsCoach.clientAnalyticsPageConfigs = this.entryConfigs
    await this.userService.updatePortalSettingsForCoach(this.userService.getLoggedInUser())
  }

  async onChangeConfigSize(config: any, size: string) {
    config.size = size
    this.userService.getLoggedInUser().coach.portalSettingsCoach.clientAnalyticsPageConfigs = this.entryConfigs
    await this.userService.updatePortalSettingsForCoach(this.userService.getLoggedInUser())
  }

  async onMoveConfigUp(config: any) {
    var index = this.entryConfigs.indexOf(config)
    if (index > 0) {
      this.entryConfigs[index] = this.entryConfigs[index - 1]
      this.entryConfigs[index - 1] = config
    }
    this.userService.getLoggedInUser().coach.portalSettingsCoach.clientAnalyticsPageConfigs = this.entryConfigs
    await this.userService.updatePortalSettingsForCoach(this.userService.getLoggedInUser())
  }
  async onMoveConfigDown(config: any) {
    var index = this.entryConfigs.indexOf(config)
    if (index < this.entryConfigs.length - 1) {
      this.entryConfigs[index] = this.entryConfigs[index + 1]
      this.entryConfigs[index + 1] = config
    }
    this.userService.getLoggedInUser().coach.portalSettingsCoach.clientAnalyticsPageConfigs = this.entryConfigs
    await this.userService.updatePortalSettingsForCoach(this.userService.getLoggedInUser())
  }

}

