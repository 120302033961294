import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MergedTrainingExercise } from 'src/app/model/training-exercise';
import { ExtendedTrackedTrainingExercise, TrackedSuperSet, TrackedTrainingExercise, TrackedTrainingSession, TrackedTrainingSet, TrackedVideoRecording } from 'src/app/model/training-monitoring.model';
import { BaseTrainingSet, PlannedTrainingExercise, SetParameter, SuperSet, TrainingPlan, TrainingSet } from 'src/app/model/training-plan.model';
import { SingleExerciseComponent } from '../../single-exercise/single-exercise.component';
import { TrainingService } from 'src/app/services/training.service';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { LanguageService } from 'src/app/services/language.service';
import { UtilityService } from 'src/app/services/utility.service';
import { User } from 'src/app/model/user.model';
import { TrainingSessionContainer } from 'src/app/training-monitoring/training-history-dialog/training-history-dialog.component';
import { TrainingSessionEditorComponent } from '../../training-plan-editor/components/training-session-editor/training-session-editor.component';
import { ExerciseHistoryDialogComponent } from 'src/app/training-monitoring/exercise-history-dialog/exercise-history-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { TrainingPlanEditorHelper } from '../../training-plan-editor/utilities/training-plan-editor-helper';
import { ToastrService } from 'ngx-toastr';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { VideoRecordingDialogComponent } from 'src/app/training-monitoring/video-recording-dialog/video-recording-dialog.component';
import { firstValueFrom } from 'rxjs';
import { FirestoreNutritionPlanService } from 'src/app/services/firestore-nutritionplan.service';
import { TimerComponent } from 'src/app/utilities/timer/timer.component';
import { KeepAwake } from '@capacitor-community/keep-awake';
import { TrainingPlanEditorComponent } from '../../training-plan-editor/training-plan-editor.component';
import { LanguageDictionary } from 'src/app/model/languagedictionary.model';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { TrainingTrackingService } from 'src/app/services/training-tracking.service';

@Component({
  selector: 'app-tracking-session',
  templateUrl: './tracking-session.component.html',
  styleUrls: ['./tracking-session.component.css', '../../training-plan-editor/components/training-session-editor/training-session-editor.component.css']
})
export class TrackingSessionComponent implements AfterViewInit {

  @ViewChild('timerComponent') timerComponent: TimerComponent;

  public spinnerText: string = "";

  _trainingSessionContainer: TrainingSessionContainer;


  @Input() set trainingSessionContainer(v: TrainingSessionContainer) {
    this._trainingSessionContainer = v;
    this.init();
  }

  get trainingSessionContainer(): TrainingSessionContainer {
    return this._trainingSessionContainer;
  }


  @Input() user: User;
  @Input() readOnlyMode: boolean = false;
  @Input() initialFocusExercise: TrackedTrainingExercise = null;

  public get hasChangesInternal(): boolean {
    return this.hasChanges;
  }
  public set hasChangesInternal(value: boolean) {
    this.hasChanges = value;
    this.hasChangesChange.emit(value);
    this.cacheTrackingSession();
  }

  private cacheTrackingSession() {
    try {
      if (this.trainingSessionContainer?.trackedTrainingSession) {
        let json = this.trainingSessionContainer.trackedTrainingSession.getJson();
        Filesystem.writeFile({
          path: TrainingTrackingService.CACHE_FOLDER + '/' + TrainingTrackingService.FILE_NAME,
          data: json,
          directory: Directory.Cache,
          recursive: true,
          encoding: Encoding.UTF8
        })
      }
    }
    catch (ex) {
      console.error(ex);
    }
  }

  private async cacheFile(fileName: string, file: File, directoryName: string) {
    try {
      let blob = file.slice(0, file.size, file.type);
      const base64data: string = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
        reader.readAsDataURL(blob);
      });
      await Filesystem.writeFile({
        path: `${directoryName}/${fileName}`,
        data: base64data,
        directory: Directory.Cache,
        recursive: true,
      });
      console.log('Datei gespeichert:', fileName);
    } catch (ex) {
      console.error('Fehler beim Speichern der Video-Datei:', ex);
    }
  }

  private async cacheBlob(fileName: string, blob: Blob, directoryName: string) {
    try {
      await Filesystem.writeFile({
        path: `${directoryName}/${fileName}`,
        data: blob,
        directory: Directory.Cache,
        recursive: true,
      });

      console.log('Datei gespeichert:', fileName);
    } catch (ex) {
      console.error('Fehler beim Speichern der Datei:', ex);
    }
  }

  public onTrainingSetsChange(event: any) {
    this.trainingSessionContainer.initSuperSets();
  }

  @Input() hasChanges: boolean = false;
  @Output() hasChangesChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() autoCreateActivity: boolean = true;
  @Output() autoCreateActivityChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public hoveredExercise: TrackedTrainingExercise;

  public autoStartPause: boolean = false;
  public imageAreaExpanded: boolean = false;

  //set to true to enable local caching of images and videos
  private localCache: boolean = false;

  constructor(public trainingService: TrainingService, private dialog: MatDialog, private spinner: NgxSpinnerService, public languageService: LanguageService, public utilityService: UtilityService, public translate: TranslateService, private toastr: ToastrService, private trainingTrackingService: TrainingTrackingService) {

  }

  async ngAfterViewInit(): Promise<void> {
    await this.init();
  }

  async ngOnDestroy(): Promise<void> {
    if (this.utilityService.onNativeMobileApp()) {
      if (await KeepAwake.isSupported()) {
        await KeepAwake.allowSleep();
      }
    }
  }

  private async init() {
    if (this.utilityService.onNativeMobileApp()) {
      if (await KeepAwake.isSupported()) {
        await KeepAwake.keepAwake();
      }
    }
    var check = this.initialFocusExercise;
    if(this.initialFocusExercise) {
      this.focusedTable(this.initialFocusExercise, true);
    }
    else {
      for (let exercise of this.trainingSessionContainer?.trackedTrainingSession?.trackedTrainingExercises) {
        let firstNotTrackedSetIndex = exercise.trackedSets.findIndex(x => !x.isTracked);
        if (firstNotTrackedSetIndex > -1) {
          let firstNotTrackedSet = exercise.trackedSets[firstNotTrackedSetIndex];
          this.setFirstFocusedSet(firstNotTrackedSet, exercise, this.getPlannedSetByIndex(exercise, firstNotTrackedSetIndex), true);
          return;
        }
      }
    }
  }

  showSpinner() {
    this.spinnerText = "";
    this.spinner.show("trackingSpinner");
  }

  setSpinnerText(text: string) {
    this.spinnerText = text;
  }

  hideSpinner() {
    this.spinnerText = "";
    this.spinner.hide("trackingSpinner");
  }

  trainingExercisesLoaded(): boolean {
    return this.trainingService?.MergedTrainingExercises?.length > 0
  }

  onToggleAutoStartPause() {
    this.autoStartPause = !this.autoStartPause;
  }

  onStartDateChanged(value: string) {
    if (value) {
      this.trainingSessionContainer.trackedTrainingSession.startDate = new Date(value) ?? null;
    }
    else {
      this.trainingSessionContainer.trackedTrainingSession.startDate = null;
    }
    this.hasChangesInternal = true;
  }

  onEndDateChanged(value: string) {
    if (value) {
      this.trainingSessionContainer.trackedTrainingSession.endDate = new Date(value) ?? null;
    }
    else {
      this.trainingSessionContainer.trackedTrainingSession.endDate = null;
    }
    this.hasChangesInternal = true;
  }

  public toDateString(date: Date): string {
    if (date == null) return null;
    return (date.getFullYear().toString() + '-'
      + ("0" + (date.getMonth() + 1)).slice(-2) + '-'
      + ("0" + (date.getDate())).slice(-2))
      + 'T' + date.toTimeString().slice(0, 5);
  }

  isEndDateValid(): boolean {
    return !this.trainingSessionContainer.trackedTrainingSession.endDate || this.trainingSessionContainer.trackedTrainingSession.endDate > this.trainingSessionContainer.trackedTrainingSession.startDate;
  }

  isStartDateValid(): boolean {
    return this.trainingSessionContainer.trackedTrainingSession.startDate != null;
  }


  onShowExerciseInfo(exercise: PlannedTrainingExercise) {
    let mergedExercise = this.getExerciseById(exercise.exerciseId);
    if (mergedExercise) {
      let dialogRef = this.dialog.open(SingleExerciseComponent, { data: { selectedExercise: mergedExercise }, width: '1000px' });
      dialogRef.afterClosed().subscribe(async result => {
        if (result) {
          if (result.shouldSave) {
            this.spinner.show()
            var exercise = result.exercise as MergedTrainingExercise
            if (exercise) {
              if (result.shouldDelete) {
                if (exercise.creatorUid == this.trainingService.UserUid) {
                  exercise.trainingExercise.deleted = true;
                } else {
                  exercise.trainingExerciseOverwrite.deleted = true;
                  exercise.trainingExerciseOverwrite.hiddenExercise = true;
                }
              }
              this.trainingService.saveOrUpdateMergedTrainingExercise(exercise, result.newThumbnail, result.newVideo, result.newExercisePreview, result.deletedVideo, result.customImages)
            }
            this.spinner.hide()
          }
        }
      })
    }
  }


  getExerciseById(exerciseId: string): MergedTrainingExercise {
    return this.trainingService.MergedTrainingExercises.find(x => x.sourceExerciseId == exerciseId) || this.trainingService.foreignTrainingExercises.find(x => x.sourceExerciseId == exerciseId) || null
  }

  getExerciseByTrackedExercise(trackedExercise: TrackedTrainingExercise): MergedTrainingExercise {
    return this.getExerciseById(trackedExercise.exerciseId);
  }


  onShowExerciseHistory(exercise: TrackedTrainingExercise) {
    let plannedExercise = this.getPlannedExerciseById(exercise.plannedExerciseId);
    if(!plannedExercise) {
      let mergedExercise = this.getExerciseById(exercise.exerciseId);
      plannedExercise = new PlannedTrainingExercise();
      plannedExercise.id = FirestoreNutritionPlanService.generateUniqueString(24);
      plannedExercise.exerciseId = mergedExercise.sourceExerciseId;
    }
    const dialogRef = this.dialog.open(ExerciseHistoryDialogComponent, { data: { user: this.user, exercise: plannedExercise }, width: '750px' })
  }

  getPlannedExerciseById(exerciseId: string): PlannedTrainingExercise {
    return this.trainingSessionContainer?.plannedTrainingSession?.exercises?.find(x => x.id == exerciseId);
  }

  async onUploadVideo(set: BaseTrainingSet, trackedExercise: TrackedTrainingExercise) {
    let setIndex = trackedExercise.trackedSets.indexOf(set as TrackedTrainingSet);

    if (setIndex > -1) {
      let recording = (trackedExercise.getSetVideoRecording(setIndex) ?? new TrackedVideoRecording(setIndex, new Date(), null, null, "", null, false, null, null, false)).clone();
      let dialogResult = await firstValueFrom(this.dialog.open(VideoRecordingDialogComponent, { data: { recording: recording, trackedExercise: trackedExercise, userUid: this.user.uid, exercise: this.getExerciseByTrackedExercise(trackedExercise), editMode: true }, width: '1000px' })?.afterClosed());
      if (dialogResult?.shouldTake) {
        try {
          this.showSpinner();
          console.log("after dialog closed");
          if (trackedExercise.recordings.find(x => x.setNumber == dialogResult?.recording.setNumber)) {
            trackedExercise.recordings = trackedExercise.recordings.map(x => x.setNumber == recording.setNumber ? recording : x);
          }
          else {
            trackedExercise.recordings.push(recording);
          }
          if (recording.newVideoFile) {
            if (this.localCache) {
              console.log("started caching video");
              await this.cacheFile((trackedExercise?.id + '_' + (recording.setNumber?.toString() ?? "0")), recording?.newVideoFile, TrainingTrackingService.CACHE_FOLDER + '/' + TrainingTrackingService.SUB_VIDEOS_FOLDER);
              console.log("finished caching video");
            }
            else {
              try {
                this.setSpinnerText(this.translate.instant("Video wird gespeichert..."));
                let result = await this.trainingTrackingService.uploadVideo(recording, this.user);
                recording.newVideoFile = null;
                result.ref.getDownloadURL().then(url => {
                  recording.videoUrl = url;
                });
              }
              catch (ex) {
                this.toastr.error(this.translate.instant("Fehler beim Speichern des Videos"), this.translate.instant("Fehler"));
                console.error(ex);
              }
            }
          }
        }
        catch (ex) {
          console.error(ex);
        }
        finally {
          this.hideSpinner();
        }
        this.hasChangesInternal = true;
      }
    }
    else {
      console.error("Set not found in exercise");
    }
  }


  onRemoveExercise(exercise) {
    this.trainingSessionContainer.trackedTrainingSession.trackedTrainingExercises = this.trainingSessionContainer.trackedTrainingSession.trackedTrainingExercises.filter(x => x != exercise);
    this.trainingSessionContainer.initSuperSets();
  }

  mouseEnterExercise(exercise: TrackedTrainingExercise) {
    this.hoveredExercise = exercise;
  }

  onSetSuperSetConnection(exercise: TrackedTrainingExercise) {
    if (exercise.nextSupersetExerciseId) {
      exercise.nextSupersetExerciseId = null;
      this.trainingSessionContainer.initSuperSets();
    }
    else {
      let exerciseIndex = this.trainingSessionContainer.trackedTrainingSession.trackedTrainingExercises.indexOf(exercise);
      if (exerciseIndex >= 0 && this.trainingSessionContainer.trackedTrainingSession.trackedTrainingExercises.length > exerciseIndex + 1) {
        let nextExercise = this.trainingSessionContainer.trackedTrainingSession.trackedTrainingExercises[exerciseIndex + 1];


        exercise.nextSupersetExerciseId = nextExercise.id;
        this.trainingSessionContainer.initSuperSets();
      }
    }
  }

  private replaceTrackedTrainingExercise: TrackedTrainingExercise;
  public plannedAlternativeExercises: PlannedTrainingExercise[];
  public plannedMergedExercises: MergedTrainingExercise[];
  onReplaceExercise(exercise: TrackedTrainingExercise) {
    this.showExerciseDialog = true;
    this.replaceTrackedTrainingExercise = exercise;
    this.plannedAlternativeExercises = this.getPlannedExerciseById(exercise?.plannedExerciseId)?.alternativePlannedExercises;
    this.plannedMergedExercises = this.plannedAlternativeExercises?.map(x => this.getExerciseById(x.exerciseId)).filter(x => x != null);
  }

  public showExerciseDialog: boolean = false;

  onAddExercise() {
    this.showExerciseDialog = true;
  }

  replaceTrainingExerciseBySeleection(replaceExercise: TrackedTrainingExercise, mergedExercise: MergedTrainingExercise) {
    let plannedTrainingExercise = this.getPlannedExerciseById(replaceExercise.plannedExerciseId);
    if (plannedTrainingExercise?.alternativePlannedExercises?.length > 0 && !replaceExercise?.trackedSets?.find(x => x.isTracked)) {
      let alternativePlannedExercise = plannedTrainingExercise.alternativePlannedExercises.find(x => x.exerciseId == mergedExercise.sourceExerciseId);
      if (alternativePlannedExercise?.sets?.length > 0 && alternativePlannedExercise?.setParameters?.length > 0) {
        replaceExercise.setParameters = alternativePlannedExercise.setParameters?.map(x => x);
        replaceExercise.trackedSets = [];
        for (let index = 0; index < alternativePlannedExercise?.sets?.length; index++) {
          const plannedSet = alternativePlannedExercise.sets[index];
          let trackedSet = TrackedTrainingSet.FromPlannedTrainingSet(plannedSet, alternativePlannedExercise, index);
          replaceExercise.trackedSets.push(trackedSet);
        }
      }
    }
    if(plannedTrainingExercise) {
      if(plannedTrainingExercise.exerciseId == mergedExercise.sourceExerciseId) {
        replaceExercise.replacedExerciseId = null;
      }
      else {
        replaceExercise.replacedExerciseId = plannedTrainingExercise.exerciseId;
      }
      replaceExercise.exerciseId = mergedExercise.sourceExerciseId;
    }
    else {
      replaceExercise.replacedExerciseId = null;
      replaceExercise.exerciseId = mergedExercise.sourceExerciseId;
    }

  }

  onTrainingExerciseSelectionChanged(exercise: MergedTrainingExercise) {
    if (this.replaceTrackedTrainingExercise) {
      this.replaceTrainingExerciseBySeleection(this.replaceTrackedTrainingExercise, exercise);
    }
    else {
      let newTrackedExercise = new TrackedTrainingExercise();
      newTrackedExercise.id = FirestoreNutritionPlanService.generateUniqueString(24);
      newTrackedExercise.exerciseId = exercise.sourceExerciseId;
      newTrackedExercise.setParameters = exercise.defaultSetParameters?.map(x => x.originObject) || [];
      let newTrackedSet = new TrackedTrainingSet();
      newTrackedSet.setNumber = 0;
      newTrackedExercise.trackedSets.push(newTrackedSet);
      this.trainingSessionContainer.trackedTrainingSession.trackedTrainingExercises.push(newTrackedExercise);
      this.trainingSessionContainer.initSuperSets();

      if (this.trainingSessionContainer?.trackedTrainingSession?.trackedTrainingExercises?.length == 1) {
        this.focusedTable(newTrackedExercise);
      }
    }

    this.replaceTrackedTrainingExercise = null;
    this.showExerciseDialog = false;
  }

  onCancelSelection() {
    this.replaceTrackedTrainingExercise = null;
    this.showExerciseDialog = false;
  }

  getAvailableSetParameters(trackedTrainingExercise: TrackedTrainingExercise): SetParameter[] {
    return trackedTrainingExercise.setParameters;
  }

  onSetParametersChanged(event, trackedTrainingExercise: TrackedTrainingExercise) {
    trackedTrainingExercise.setParameters = event?.map(x => SetParameter[x]) ?? [];
  }

  private numberPattern = "^([0-9]+([,.][0-9]+)?)$";
  private integerPattern = "^([0-9]+)$";
  public getRegexForSetParameter(setParameter: SetParameter): string {
    if (setParameter == SetParameter.weight) {
      // Regex allow everything for traindoo-app
      // if (environment.firebaseProjectId == 'traindoo-app') return ".*";
      return this.numberPattern;
    }
    else if (setParameter == SetParameter.pace || setParameter == SetParameter.pace500) {
      return "^(([1-9][0-9]?[0-9]?)%([a-zA-Z0-9_]+))|((60|[0-5][0-9]):[0-5][0-9])(-(60|[0-5][0-9]):[0-5][0-9])?$";
    }
    else if (setParameter == SetParameter.heartRate) {
      return "^(\\d+(-\\d+)?)$"
    }
    else if (setParameter == SetParameter.rpe || setParameter == SetParameter.rir) {
      return this.numberPattern;
    }
    else if (setParameter == SetParameter.reps) {
      return this.integerPattern;
    }
    else if (setParameter == SetParameter.time) {
      return "^(([0-9][0-9]:)?([0-5][0-9]):[0-5][0-9])?$";
    }
    // else if (setParameter == SetParameter.rpe) {
    //   return this.rpePattern;
    // }
    // else if (setParameter == SetParameter.rir) {
    //   return this.rirPattern;
    // }
    // else if (setParameter == SetParameter.reps) {
    //   // Regex allow everything for traindoo-app
    //   if (environment.firebaseProjectId == 'traindoo-app') return ".*";
    //   return this.repsPatter;
    // }
    else {
      return "";
    }
  }


  getTime(set: TrackedTrainingSet, setParameter: SetParameter): string {
    let setPropertyString = setParameter.toString();
    let minProperty = 'min' + setPropertyString.charAt(0).toUpperCase() + setPropertyString.slice(1);
    let maxProperty = 'max' + setPropertyString.charAt(0).toUpperCase() + setPropertyString.slice(1);

    if (setParameter == SetParameter.time) {
      return TrackingSessionComponent.getSecondsAsTimeWithHours(set[setPropertyString]);
    }
    else {
      if (set[setPropertyString]) {
        return TrackingSessionComponent.getSecondsAsShortDurationString(set[setPropertyString]);
      }
      else if (set[minProperty] && set[maxProperty]) {
        return TrackingSessionComponent.getSecondsAsShortDurationString(set[minProperty]) + "-" + TrackingSessionComponent.getSecondsAsShortDurationString(set[maxProperty]);
      }
    }
    return null;
  }

  static getSecondsAsShortDurationString(inputSeconds: number): string {
    if (inputSeconds == undefined) return "";
    if (inputSeconds < 0) inputSeconds = 0
    return inputSeconds.asShortDurationString();
  }

  static getSecondsAsTimeWithHours(inputSeconds: number): string {
    if (inputSeconds == undefined) return "";
    if (inputSeconds < 0) inputSeconds = 0
    return inputSeconds.asDurationString();
  }

  setTime(set: TrackedTrainingSet, setParameter: SetParameter, value: string) {
    if (value?.includes('-') && setParameter != SetParameter.time) {
      let splitted = value.split('-');
      if (splitted.length == 2) {
        let timeMin = TrainingSessionEditorComponent.timeStringWithHoursToSeconds(splitted[0]);
        let timeMax = TrainingSessionEditorComponent.timeStringWithHoursToSeconds(splitted[1]);
        if (timeMin && timeMax) {
          let minParameter = "min" + setParameter.toString().charAt(0).toUpperCase() + setParameter.toString().slice(1);
          let maxParameter = "max" + setParameter.toString().charAt(0).toUpperCase() + setParameter.toString().slice(1);
          set[minParameter] = timeMin;
          set[maxParameter] = timeMax;
          set[setParameter] = null;
          this.hasChangesInternal = true
          return;
        }
      }
    }
    let setPropertyString = setParameter.toString();
    set[setPropertyString] = undefined;
    if (value?.length > 0) {
      let time = TrainingSessionEditorComponent.timeStringWithHoursToSeconds(value)
      if (time) {
        set[setPropertyString] = time
        if (setParameter == SetParameter.pace || setParameter == SetParameter.pace500) {
          let minParameter = "min" + setParameter.toString().charAt(0).toUpperCase() + setParameter.toString().slice(1);
          let maxParameter = "max" + setParameter.toString().charAt(0).toUpperCase() + setParameter.toString().slice(1);
          set[minParameter] = null;
          set[maxParameter] = null;
        }
      }
    }
    this.hasChangesInternal = true
  }

  setSetRir(set: TrackedTrainingSet, value: string) {
    set.rir = undefined;
    if (value?.length > 0) {
      value = value.replace(",", ".");
      let rir = parseFloat(value)
      if (rir) {
        set.rir = rir
      }
    }
    this.hasChangesInternal = true
  }

  setSetRpe(set: TrackedTrainingSet, value: string) {
    set.rpe = undefined;
    if (value?.length > 0) {
      value = value.replace(",", ".");
      let rpe = parseFloat(value)
      if (rpe) {
        set.rpe = rpe
      }
    }
    this.hasChangesInternal = true
  }

  setSetReps(set: TrackedTrainingSet, value: string) {
    set.reps = undefined;
    if (value?.length > 0) {
      let reps = parseInt(value)
      if (reps) {
        set.reps = reps
      }
    }
    this.hasChangesInternal = true
  }

  setSetHeartRate(set: TrackedTrainingSet, value: string) {
    if (value?.includes('-')) {
      let splitted = value.split('-');
      if (splitted.length == 2) {
        let heartRateMin = parseInt(splitted[0]);
        let heartRateMax = parseInt(splitted[1]);
        if (heartRateMin && heartRateMax) {
          set.minHeartRate = heartRateMin;
          set.maxHeartRate = heartRateMax;
          set.heartRate = null;
        }
      }
    }
    else {
      let heartRate = parseInt(value);
      if (heartRate) {
        set.heartRate = heartRate;
        set.minHeartRate = null;
        set.maxHeartRate = null;
      }
    }

    this.hasChangesInternal = true
  }

  getSetHeartRate(set: TrackedTrainingSet): string {
    if (set.heartRate) {
      return set.heartRate.toString();
    }
    else if (set.minHeartRate && set.maxHeartRate) {
      return set.minHeartRate + "-" + set.maxHeartRate;
    }
    return "";
  }

  getSetRir(set: TrackedTrainingSet): string {
    return set.rir?.toString() || "";
  }

  getSetRpe(set: TrackedTrainingSet): string {
    return set.rpe?.toString() || "";
  }

  getSetReps(set: TrackedTrainingSet): string {
    return set.reps?.toString() || "";
  }

  public tutPattern = TrainingPlanEditorHelper.tutPattern;

  checkTUTInput(set: BaseTrainingSet) {
    TrainingPlanEditorHelper.checkTUTINput(set, this.toastr, this.translate);
  }

  tutChange(set: BaseTrainingSet) {
    TrainingSessionEditorComponent.tutChange(set);
  }

  noteChanged(set: TrackedTrainingSet, value: string) {
    set.coacheeNote = value;
  }

  onSelectAlternativeExercise(exercise: TrackedTrainingExercise) {
    // let plannedExercise = this.getPlannedExerciseById(exercise.plannedExerciseId);
    // if(plannedExercise) {
    //   if(exercise.replacedExerciseId == exercise.exerciseId) {
    //     exercise.replacedExerciseId = null;
    //   }
    //   else {
    //     exercise.replacedExerciseId = exercise.exerciseId;
    //   }
    // }
    // let alternativeExerciseId = this.getAlternativeExerciseId(exercise);
    // exercise.exerciseId = alternativeExerciseId;
    let mergedExercise = this.getExerciseById(exercise.replacedExerciseId);
    this.replaceTrainingExerciseBySeleection(exercise, mergedExercise);
  }

  dropTrainingExercise(event: CdkDragDrop<string[]>) {
    let movedExercise = this.trainingSessionContainer.trackedTrainingSession.trackedTrainingExercises[event.previousIndex];
    if (movedExercise.nextSupersetExerciseId) {
      movedExercise.nextSupersetExerciseId = null;
    }
    else {
      let foreignSuperSetExercise = this.trainingSessionContainer.trackedTrainingSession.trackedTrainingExercises.find(x => x.nextSupersetExerciseId == movedExercise.id);
      if (foreignSuperSetExercise) {
        foreignSuperSetExercise.nextSupersetExerciseId = null;
      }
    }
    moveItemInArray(this.trainingSessionContainer.trackedTrainingSession.trackedTrainingExercises, event.previousIndex, event.currentIndex);

    this.trainingSessionContainer.initSuperSets();
    this.hasChangesInternal = true
  }

  async onNoteImagesChanged(images: { blob: Blob, src: string, path: string }[], exercise: TrackedTrainingExercise) {
    console.log(images)
    this.showSpinner();
    exercise.tempNoteImages = images;

    if (this.localCache) {
      try {
        if (await Filesystem.stat({ path: TrainingTrackingService.CACHE_FOLDER + '/' + TrainingTrackingService.SUB_IMAGES_FOLDER })) {
          await Filesystem.rmdir({ path: TrainingTrackingService.CACHE_FOLDER + '/' + TrainingTrackingService.SUB_IMAGES_FOLDER, directory: Directory.Cache, recursive: true });
        }
      }
      catch (ex) {
        console.error(ex);
      }
      try {
        for (let index = 0; index < images?.length; index++) {
          const image = images[index];
          if (image?.blob) {
            await this.cacheBlob(exercise.id + '_' + index, image.blob, TrainingTrackingService.CACHE_FOLDER + '/' + TrainingTrackingService.SUB_IMAGES_FOLDER);
          }
        }
      }
      catch (ex) {
        console.error(ex);
      }
    }
    else {
      try {
        this.setSpinnerText(this.translate.instant("Bild wird gespeichert..."));
        for (let image of images) {
          if (image.blob && !image.path) {
            await this.trainingTrackingService.uploadImage(image, this.user);
            image.blob = null;
            if (!exercise.noteImagePaths) exercise.noteImagePaths = [];
            if (!exercise.noteImagePaths.includes(image.path)) {
              exercise.noteImagePaths.push(image.path);
            }
          }
        }
      }
      catch (ex) {
        this.toastr.error(this.translate.instant("Fehler beim Speichern des Bildes"), this.translate.instant("Fehler"));
        console.error(ex);
      }
    }
    this.hideSpinner();
    this.hasChangesInternal = true
  }


  public timerHidden: boolean = false;

  hideTimer() {
    this.timerHidden = true;
  }

  public elapsedTimerTime: number = 0;
  public timerRunning: boolean = false;
  public focusedSet: TrackedTrainingSet = null;
  public focusedExercise: TrackedTrainingExercise = null;
  private isPauseTimer: boolean = false;

  public setElapsedTime(elapsedTime: number) {
    if (elapsedTime > 0 && this.focusedSet) {
      if (this.isPauseTimer && this.focusedExercise?.setParameters?.includes(SetParameter.pauseDuration)) {
        this.focusedSet.pauseDuration = elapsedTime;
      }
      else if (this.focusedExercise?.setParameters?.includes(SetParameter.time)) {
        this.focusedSet.time = elapsedTime;
      }
    }
  }

  scrollExerciseIntoView(trackedExerciseid: string) {
    let exerciseItem = document.getElementById('exercise-' + trackedExerciseid);
    if (exerciseItem) {
      exerciseItem.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    }
  }

  setFirstFocusedSet(trackedSet: TrackedTrainingSet, trackedExercise: TrackedTrainingExercise, plannedSet?: TrainingSet, preventAutoStart: boolean = false) {
    this.isPauseTimer = false;
    this.focusedExercise = trackedExercise;
    this.focusedSet = trackedSet;
    let timerTime = plannedSet?.time ?? plannedSet?.maxTime ?? trackedSet?.time;
    let setNumber = "#" + ((trackedExercise?.getRealSetIndex(trackedSet) ?? 0) + 1)?.toString();
    if (trackedSet?.isDropset) {
      setNumber = 'D';
    }
    else if (trackedSet?.isWarmupSet) {
      setNumber = 'W';
    }
    let timerTitle = (this.getExerciseById(trackedExercise.exerciseId)?.trainingExercise?.name?.GetValue(this.translate.currentLang) ?? '') + ' (' + setNumber + ')';
    this.scrollExerciseIntoView(trackedExercise?.id);
    let autoRun = trackedExercise.autoRun && timerTime > 0;
    let autoStart = !preventAutoStart && autoRun;

    this.timerComponent?.setProperties(timerTime, timerTitle, autoStart, autoRun);
  }

  setPauseTimer(set: TrackedTrainingSet, exercise: TrackedTrainingExercise) {
    this.isPauseTimer = true;
    this.focusedSet = set;
    this.focusedExercise = exercise;
    let setNumber = "#" + ((exercise?.getRealSetIndex(set) ?? 0) + 1)?.toString();
    if (set?.isDropset) {
      setNumber = 'D';
    }
    else if (set?.isWarmupSet) {
      setNumber = 'W';
    }
    let timerTitle = this.translate.instant('Pause') + ' (' + setNumber + ')';
    let timerTime = set.pauseDuration;
    this.timerComponent?.setProperties(timerTime, timerTitle, exercise.autoRun || this.autoStartPause, exercise.autoRun);
  }

  timerFinished() {
    if (this.focusedSet && this.focusedExercise) {
      let superSet = this.getSuperSetByExercise(this.focusedExercise);
      this.focusedSet.isTracked = true;
      this.isTrackedChanged(this.focusedSet, this.focusedExercise, superSet);
      this.hasChangesInternal = true;
    }
  }

  getSuperSetByExercise(exercise: TrackedTrainingExercise): TrackedSuperSet {
    return this.trainingSessionContainer.trackedSuperSets?.find(x => x?.trackedSuperSetExercises?.find(y => y?.id == exercise?.id));
  }

  setCalculatedSetValues(trackedSet: TrackedTrainingSet, exercise: TrackedTrainingExercise) {
    try {
      if (trackedSet && exercise) {
        let trackedSetIndex = exercise.trackedSets.indexOf(trackedSet);
        if (trackedSetIndex >= 0 && exercise.trackedSets?.length > (trackedSetIndex + 1)) {
          let nextTrackedSet = exercise.trackedSets[trackedSetIndex + 1];
          let plannedSet = this.getPlannedSetByIndex(exercise, trackedSetIndex + 1);
          if (plannedSet && nextTrackedSet && !nextTrackedSet?.isTracked) {
            exercise?.plannedExercise?.setParameters?.forEach(setParameter => {
              if (TrainingPlanEditorComponent.hasSetParameterFormulaInput(setParameter)) {
                let formulaParameter = setParameter.toString() + "Formula";
                let formula = plannedSet[formulaParameter];
                if (formula) {
                  if (formula.match(TrainingPlanEditorComponent.refSetPattern)) {
                    let splittedRefNumber = formula.split('%#');
                    if (splittedRefNumber?.length > 1) {
                      let indexOfRefSet = parseInt(splittedRefNumber[1]) - 1;
                      if (indexOfRefSet >= 0 && indexOfRefSet <= exercise.sets.length) {
                        let refSet = exercise.trackedSets[indexOfRefSet];
                        let refValue = refSet[setParameter.toString()];
                        if (refValue) {
                          let percentage = parseFloat(splittedRefNumber[0]);
                          if (percentage > 0) {
                            let steps = TrainingSessionEditorComponent.SetParameter2ValueStepMapping[setParameter];
                            if (setParameter == SetParameter.pace || setParameter == SetParameter.pace500) {
                              let calculatedValue = refValue / (percentage / 100);
                              if (calculatedValue > 0) {
                                calculatedValue = Math.round(calculatedValue / steps) * steps;
                                nextTrackedSet[setParameter.toString()] = calculatedValue;
                              }
                            }
                            else {
                              let calculatedValue = refValue * (percentage / 100);
                              if (calculatedValue > 0) {
                                calculatedValue = Math.round(calculatedValue / steps) * steps;
                                nextTrackedSet[setParameter.toString()] = calculatedValue;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
          }
        }
      }
    }
    catch (ex) {
      console.error(ex);
    }
  }

  isTrackedChanged(set: BaseTrainingSet, exercise: TrackedTrainingExercise, superSet: TrackedSuperSet) {
    let trackedSet = set as TrackedTrainingSet;
    this.setCalculatedSetValues(trackedSet, exercise);
    if (!this.isPauseTimer && exercise.setParameters.includes(SetParameter.pauseDuration) && trackedSet.isTracked && trackedSet.pauseDuration > 0) {
      this.setPauseTimer(trackedSet, exercise);
      return;
    }
    if (superSet?.trackedSuperSetExercises?.length > 1) {
      let round = superSet.trackedSuperSetRounds?.find(x => x.trackedExerciseSets.find(y => y?.trackedTrainingSet == trackedSet));
      if (round) {
        let currentTrackedExerciseSetIndex = round.trackedExerciseSets.findIndex(x => x.trackedTrainingSet == trackedSet);
        if (currentTrackedExerciseSetIndex >= 0) {
          if (trackedSet.isTracked) {
            if (currentTrackedExerciseSetIndex >= round.trackedExerciseSets.length - 1) {
              let currentRoundIndex = superSet.trackedSuperSetRounds.indexOf(round);
              if (currentRoundIndex > -1 && currentRoundIndex < superSet.trackedSuperSetRounds.length - 1) {
                let nextRound = superSet.trackedSuperSetRounds[currentRoundIndex + 1];
                if (nextRound.trackedExerciseSets.length > 0) {
                  let nextTrackedSet = nextRound.trackedExerciseSets[0];
                  this.focusedExercise = nextTrackedSet.trackedTrainingExercise;
                  this.focusedSet = nextTrackedSet.trackedTrainingSet;
                  let currentIndex = nextTrackedSet.trackedTrainingExercise.trackedSets?.indexOf(nextTrackedSet.trackedTrainingSet);
                  this.setFirstFocusedSet(nextTrackedSet.trackedTrainingSet, nextTrackedSet.trackedTrainingExercise, this.getPlannedSetByIndex(exercise, currentIndex));
                }
              }
              else {
                let nextExercise = this.trainingSessionContainer.trackedTrainingSession.trackedTrainingExercises.find(x => x.id != exercise.id && x.trackedSets.find(y => !y.isTracked));
                if (nextExercise) {
                  let firstNotTrackedSet = nextExercise.trackedSets.find(x => !x.isTracked);
                  this.setFirstFocusedSet(firstNotTrackedSet, nextExercise, this.getPlannedSetByIndex(nextExercise, 0));
                }
              }
            }
            else {
              let nextTrackedSet = round.trackedExerciseSets[currentTrackedExerciseSetIndex + 1];
              this.focusedSet = nextTrackedSet.trackedTrainingSet;
              this.focusedExercise = nextTrackedSet.trackedTrainingExercise;
              let currentIndex = nextTrackedSet.trackedTrainingExercise.trackedSets?.indexOf(nextTrackedSet.trackedTrainingSet);
              this.setFirstFocusedSet(nextTrackedSet.trackedTrainingSet, nextTrackedSet.trackedTrainingExercise, this.getPlannedSetByIndex(exercise, currentIndex));
            }
          }
          else {
            let currentIndex = exercise.trackedSets?.indexOf(trackedSet);
            this.setFirstFocusedSet(trackedSet, exercise, this.getPlannedSetByIndex(exercise, currentIndex));
          }
        }
      }
    }
    else {
      let currentIndex = exercise.trackedSets?.indexOf(trackedSet);
      if (currentIndex >= 0) {
        if (trackedSet?.isTracked) {
          if (exercise.trackedSets?.length > (currentIndex + 1)) {
            let nextTrackedSet = exercise.trackedSets[currentIndex + 1];
            this.setFirstFocusedSet(nextTrackedSet, exercise, this.getPlannedSetByIndex(exercise, currentIndex + 1));
          }
          else {
            let nextExercise = this.trainingSessionContainer.trackedTrainingSession.trackedTrainingExercises.find(x => x.id != exercise.id && x.trackedSets.find(y => !y.isTracked));
            if (nextExercise) {
              let firstNotTrackedSet = nextExercise.trackedSets.find(x => !x.isTracked);
              this.setFirstFocusedSet(firstNotTrackedSet, nextExercise, this.getPlannedSetByIndex(nextExercise, 0), true);
            }
          }
        }
        else {
          this.setFirstFocusedSet(trackedSet, exercise, this.getPlannedSetByIndex(exercise, currentIndex));
        }
      }
    }
  }

  getPlannedSetByIndex(exercise: TrackedTrainingExercise, index: number): TrainingSet {
    if (exercise?.sets?.length > index) {
      return exercise.sets[index];
    }
    return null;
  }

  focusedTable(trackedExercise: TrackedTrainingExercise, forceFirstSet: boolean = false) {
    if (!this.timerRunning) {
      let firstNotTrackedSetIndex = trackedExercise.trackedSets?.findIndex(x => !x.isTracked);
      if(firstNotTrackedSetIndex < 0 && forceFirstSet) {
        firstNotTrackedSetIndex = 0;
      }
      if (firstNotTrackedSetIndex >= 0) {
        this.setFirstFocusedSet(trackedExercise.trackedSets[firstNotTrackedSetIndex], trackedExercise, this.getPlannedSetByIndex(trackedExercise, firstNotTrackedSetIndex), true);
      }
    }
  }

  isLastSuperSet(trackedSuperSet: TrackedSuperSet) {
    return this.trainingSessionContainer.trackedSuperSets.indexOf(trackedSuperSet) == this.trainingSessionContainer.trackedSuperSets.length - 1;
  }

}