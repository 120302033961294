<app-kpi-collection></app-kpi-collection>

<div class="row" *ngIf="user">
  <div class="col-12" *ngIf="entryConfigs.length < totalElements">
    <div *ngIf="!isConfigEnabled(null)" class="icon-button display-inlineblock marginleft-10 marginright-10" mat-button [matMenuTriggerFor]="menu">
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
           xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
              d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
      </svg>
      <span translate>Statistik hinzufügen</span>
    </div>
    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngIf="!isConfigEnabled(PaymentAnalyticsStatisticId.REVENUE_OVER_TIME)"
              (click)="onAddConfigEntry(PaymentAnalyticsStatisticId.REVENUE_OVER_TIME)"><span translate>Umsatz</span>
      </button>
      <button mat-menu-item *ngIf="!isConfigEnabled(PaymentAnalyticsStatisticId.ACTIVE_SALES)"
              (click)="onAddConfigEntry(PaymentAnalyticsStatisticId.ACTIVE_SALES)"><span translate>Aktive Käufe</span>
      </button>
      <button mat-menu-item *ngIf="!isConfigEnabled(PaymentAnalyticsStatisticId.TOP10_REVENUE)"
              (click)="onAddConfigEntry(PaymentAnalyticsStatisticId.TOP10_REVENUE)"><span translate>Top10 Umsatz</span>
      </button>
    </mat-menu>
  </div>
  <ng-container *ngFor="let config of entryConfigs; index as index">

    <div class="config-tile col-12 {{getColSize(config)}}">
      <div *ngIf="config.itemId == PaymentAnalyticsStatisticId.REVENUE_OVER_TIME" class="marginbottom-30">
        <app-graph-revenue-over-time [statisticConfig]="config"
                                     (onChangeConfigSizeEvent)="onChangeConfigSize($event)"
                                     (onRemoveConfigEvent)="onRemoveConfig($event)"
                                     (onMoveConfigDownEvent)="onMoveConfigDown($event)"
                                     (onMoveConfigUpEvent)="onMoveConfigUp($event)"
                                     (onConfigSettingChangedEvent)="onConfigSettingChanged()"
        ></app-graph-revenue-over-time>
      </div>

      <div *ngIf="config.itemId == PaymentAnalyticsStatisticId.ACTIVE_SALES" class="marginbottom-30">
        <app-graph-active-sales [statisticConfig]="config"
                                (onChangeConfigSizeEvent)="onChangeConfigSize($event)"
                                (onRemoveConfigEvent)="onRemoveConfig($event)"
                                (onMoveConfigDownEvent)="onMoveConfigDown($event)"
                                (onMoveConfigUpEvent)="onMoveConfigUp($event)"
                                (onConfigSettingChangedEvent)="onConfigSettingChanged()"></app-graph-active-sales>
      </div>

      <div *ngIf="config.itemId == PaymentAnalyticsStatisticId.TOP10_REVENUE" class="marginbottom-30">
        <app-graph-top-ten-revenue [statisticConfig]="config"
                                   (onChangeConfigSizeEvent)="onChangeConfigSize($event)"
                                   (onRemoveConfigEvent)="onRemoveConfig($event)"
                                   (onMoveConfigDownEvent)="onMoveConfigDown($event)"
                                   (onMoveConfigUpEvent)="onMoveConfigUp($event)"
                                   (onConfigSettingChangedEvent)="onConfigSettingChanged()"></app-graph-top-ten-revenue>
      </div>
    </div>
  </ng-container>
</div>
