<div *ngIf="coach" class="wrapper">
    <div class="row">
        <div class="col-8 bold">Event</div>
        <div class="col-2 bold">Email</div>
        <div class="col-2 bold">Push</div>
        <ng-container *ngFor="let entry of notificationSettingsEntries">
            <div class="col-8">{{entry.de}}</div>
            <div class="col-2">
                <input class="input checkbox margin-horizontal-auto" [checked]="emailNotificationSettings[entry.key] ?? false" (change)="onEmailNotificationEntryChanged(entry.key, $event.target.checked)" type="checkbox">
            </div>
            <div class="col-2">
                <input class="input checkbox margin-horizontal-auto" [checked]="pushNotificationSettings[entry.key] ?? false" (change)="onPushNotificationEntryChanged(entry.key, $event.target.checked)" type="checkbox">
            </div>
        </ng-container>
        <div class="col-12">
            <div class="spacer-20"></div>
            <div class="label">Email-Adresse</div>
            <div class="spacer-5"></div>
            <input [ngModel]="coach.communicationEmailAddress" (input)="onCommunicationEmailAddressChanged($event.target.value)" class="input small wide {{coach?.isEmailValid() ? '' : 'invalid'}}" type="text" placeholder="Email für Benachrichtigungen"/>
            <div class="spacer-10"></div>
            <div class="icon-button" *ngIf="communicationEmailAddressChanged" (click)="onSaveCommunicationEmailAddress()">
                Speichern
            </div>
        </div>
    </div>
</div>