<div class="container">
    <div class="row">

        <div class="col-12 col-lg-4"></div>
        <div class="col-12 col-lg-4">
            <div class="branding-wrapper">
                <h3 translate>Willkommen!</h3>
            </div>
        </div>
        <div class="col-12 col-lg-4">
            <div *ngIf="user">
                <div class="user-selection">
                    <div class="profile-container">
                        <div class="profile-picture">
                            <button class="profile-button" title="Profilbild hochladen">
                                <img draggable="false" src="{{user.profilePictureUrl}}">
                            </button>
                        </div>
                        <h3 class="name">{{user?.getName()}}</h3>
                    </div>
                    <span translate>Nicht du?</span><br>
                    <div class="spacer-10"></div>
                    <button class="button-filled small white" id="logout-button" (click)="onLogout()" translate>Abmelden</button>
                </div>
                <div class="spacer-25"></div>
            </div>
        </div>

        <div class="col-12">
            <ng-container *ngIf="user && payment && !isPaymentAccessible()">
                <div class="textalign-center"><h2 translate>Du kannst mit diesem Account nicht auf die Rechnung zugreifen.</h2></div>
                <div class="spacer-25"></div>
            </ng-container>
            <ng-container *ngIf="!user && !isPaymentAccessible()">
                <div class="textalign-center">
                    <h2 translate>Melde dich an, um auf die Rechnung zuzugreifen.</h2>
                    <div class="spacer-25"></div>
                    <button class="button-filled small" (click)="onLogin()" translate>
                        Anmelden
                    </button>
                    <div class="spacer-25"></div>
                </div>
            </ng-container>

            <ng-container *ngIf="isPaymentAccessible()">
                <div class="textalign-center">
                    <ng-container *ngIf="loadingInvoice">
                        <div class="spacer-25"></div>
                        <mat-spinner class="loading-statistics-spinner" diameter="30" strokeWidth="2"></mat-spinner>
                        <div class="spacer-10"></div>
                    </ng-container>
                    <button *ngIf="invoiceUrl" class="button-filled small" (click)="onDownloadInvoice()" translate>
                        Rechnung herunterladen
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
    
</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>