export class DateStringBase {
    private _date: Date;
    public get date(): Date {
        return this._date;
    }
    public set date(value: Date) {
        this._date = value;
        this.dateString = value?.getTimestampStringWithoutTime() ?? null;
    }
    public dateString: string = null;

    constructor();
    constructor(init: DateStringBase);
    constructor(init?: DateStringBase) {
        if (init?.dateString !== undefined) {
            this.date = new Date().parseTimestampString(init.dateString)
        }
        else if (init?.date) {
            this._date = init.date as Date

            if (this._date != null && this._date instanceof Date) {
                this.dateString = this._date?.getTimestampStringWithoutTime() ?? null;
            }
            else {
                this.dateString = null;
            }
        }
    }
}

export class StartEndDateStringBase {
    private _startDate: Date;
    public get startDate(): Date {
        return this._startDate;
    }
    public set startDate(value: Date) {
        this._startDate = value;
        this.startDateString = value?.getTimestampStringWithoutTime() ?? null;
    }
    public startDateString: string = null;


    private _endDate: Date;
    public get endDate(): Date {
        return this._endDate;
    }
    public set endDate(value: Date) {
        this._endDate = value;
        this.endDateString = value?.getTimestampStringWithoutTime() ?? null;
    }
    public endDateString: string = null;

    constructor();
    constructor(init: StartEndDateStringBase);
    constructor(init?: StartEndDateStringBase) {
        if (init?.startDateString !== undefined) {
            this.startDate = new Date().parseTimestampString(init.startDateString)
        }
        else if (init?.startDate) {
            this._startDate = init.startDate as Date

            if (this._startDate != null && this._startDate instanceof Date) {
                this.startDateString = this._startDate?.getTimestampStringWithoutTime() ?? null;
            }
            else {
                this.startDateString = null;
            }
        }


        if (init?.endDateString !== undefined) {
            this.endDate = init?.endDateString ? new Date().parseTimestampString(init.endDateString) : null
        }
        else if (init?.endDate) {
            this._endDate = init.endDate as Date

            if (this._endDate != null && this._endDate instanceof Date) {
                this.endDateString = this._endDate.getTimestampStringWithoutTime();
            }
            else {
                this.endDateString = null;
            }
        }
        else {
            this.endDate = null
            this.endDateString = null
        }
    }
}
