<div class="layout-box adjust-mobile">
    <div *ngIf="displayedUser?.currentlyLoadingBodyDataStatistics" class="float-right">
        <mat-spinner class="loading-statistics-spinner" diameter="30" strokeWidth="2"></mat-spinner>
    </div>
    <h3>{{environment.firebaseProjectId == 'traindoo-app' ? 'Graphische Auswertung' : 'Körperdaten'}}</h3>
    <div class="d-inline-block wrapper-bodydatatype-selection">
        <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownBodyDataGraphType" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getSelectedBodyDataGraphTypesString()}}</button>
        <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphType">
            <div *ngFor="let type of defaultBodyDataGraphTypes">
                <button class="dropdown-item {{selectedBodyDataGraphTypes.includes(type) ? 'selected' : ''}}" type="button" (click)="onBodyDataGraphTypeChanged(type)">
                    {{type.name}}
                    <svg *ngIf="selectedBodyDataGraphTypes.includes(type)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                    </svg>
                </button>
            </div>
            <div *ngFor="let type of additionalBodyDataGraphTypes">
                <button class="dropdown-item {{selectedBodyDataGraphTypes.includes(type) ? 'selected' : ''}}" type="button" (click)="onBodyDataGraphTypeChanged(type)">
                    {{type.name}}
                    <svg *ngIf="selectedBodyDataGraphTypes.includes(type)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
    <div class="d-inline-block wrapper-timerange-selection">
        <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownBodyDataGraphTimeRange" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{selectedBodyDataGraphTimeRange.name}}</button>
        <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
            <div *ngFor="let range of bodyDataGraphTimeRanges">
                <button class="dropdown-item" type="button" (click)="onBodyDataGraphTimeRangeChanged(range)">{{range.name}}</button>
            </div>
        </div>
    </div>
    <div class="d-inline-block" *ngIf="selectedBodyDataGraphTimeRange.id == 6">
        <div class="display-inlineblock">
            Von: 
            <span class="marginleft-5">{{selectedStartDate?.asFormatedString()}}</span>
            <input [disabled]="displayedUser?.currentlyLoadingBodyDataStatistics" [matDatepicker]="startDatePicker" class="invisible-datetime-input" [value]="selectedStartDate" [matDatepickerFilter]="startDateFilterBodyData" class="visibility-hidden width-0 padding-0" (dateInput)="onStartDateChanged($event.value?.toDate())">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker ></mat-datepicker><br>
        </div>
        <div class="display-inlineblock">
            Bis: 
            <span class="marginleft-5">{{selectedEndDate?.asFormatedString()}}</span>
            <input [disabled]="displayedUser?.currentlyLoadingBodyDataStatistics" [matDatepicker]="endDatePicker" class="invisible-datetime-input" [value]="selectedEndDate" [matDatepickerFilter]="endDateFilterBodyData" class="visibility-hidden width-0 padding-0" (dateInput)="onEndDateChanged($event.value?.toDate())">
            <span *ngIf="!selectedEndDate">unbegrenzt</span>
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker ></mat-datepicker>
        </div>
    </div>
    <div *ngIf="environment.firebaseProjectId != 'traindoo-app'" class="toggle-wrapper checkin-data-toggle display-inlineblock marginright-25 mb-1">
        <div class="toggle {{displayQuestionaireMetrics ? 'on' : ''}}" (click)="onDisplayQuestionaireMetricsChanged()">
            <div class="handle"></div>
        </div>
        <div class="text">
            Check-In-Daten anzeigen
        </div>
    </div>
    <dx-chart *ngIf="progressStatisticsGraphData"
        palette="nutrilizePalette"
        [dataSource]="progressStatisticsGraphData"
        title="" 
        (onLegendClick)="legendClickHandler($event)">
        
        <ng-container *ngFor="let graphItem of displayedStatisticsGraphItems">
            <dxi-series *ngIf="graphItem.style != 'average'" valueField="{{graphItem.valueField}}" name="{{graphItem.name}}" type="spline" axis="{{graphItem.axis || ''}}">
            </dxi-series>
            <dxi-series *ngIf="graphItem.style == 'average'" valueField="{{graphItem.valueField}}" name="{{graphItem.name}}" type="line" dashStyle="dash" color="#CCC" axis="{{graphItem.axis || ''}}">
                <dxo-point [visible]="false"></dxo-point>
            </dxi-series>
            <!--dxi-series *ngIf="aggregateChartData" valueField="{{graphItem.valueField}}" name="&empty;-{{graphItem.name}}" type="stepline" dashStyle="dash" axis="{{graphItem.axis || ''}}">
                <dxo-aggregation
                    [enabled]="aggregateChartData"
                    method="avg">
                </dxo-aggregation>
                <dxo-point [visible]="false"></dxo-point>
            </dxi-series-->
        </ng-container>

        <dxo-argument-axis aggregationInterval="week">
            <ng-container *ngFor="let strip of progressStatisticsGraphStrips">
                <dxi-strip [startValue]="strip.startValue" [endValue]="strip.endValue" color="#ffeaed"></dxi-strip>
            </ng-container>
        </dxo-argument-axis>
        <dxo-common-series-settings #seriesSettings
        argumentField="date" type="spline">
        <dxo-point [visible]="true" [size]="9"></dxo-point>
        </dxo-common-series-settings>
        <dxo-margin [bottom]="20"></dxo-margin>
        <dxo-common-axis-settings>
            <dxo-grid [visible]="true" color="var(--darkestBackgroundColor)"></dxo-grid>
        </dxo-common-axis-settings>
        <dxo-legend
            verticalAlignment="{{utilityService.onMobile() ? 'bottom' : 'center'}}"
            horizontalAlignment="{{utilityService.onMobile() ? 'center' : 'right'}}"
            itemTextPosition="top">
        </dxo-legend>
        <ng-container *ngFor="let axis of statisticsGraphAxis; let i = index">
            <dxi-value-axis name="{{axis.name}}" [visualRange]="axis.visualRange" [tickInterval]="axis.tickInterval || undefined" [visible]="i <= 1" [position]="i == 0 ? 'left' : 'right'">
                <dxo-tick [visible]="i <= 1"></dxo-tick>
                <dxo-label [visible]="i <= 1" [customizeText]="i == 0 ? customizeBodyDataGraphLeftYAxis : customizeBodyDataGraphRightYAxis"></dxo-label>
            </dxi-value-axis>
        </ng-container>
        <dxo-argument-axis [allowDecimals]="false" [axisDivisionFactor]="60" >
            <dxo-tick-interval [days]="1"></dxo-tick-interval>
            <dxo-label>
                <dxo-format type="dd. MMM"></dxo-format>
            </dxo-label>
        </dxo-argument-axis>
        <dxo-export [enabled]="false"></dxo-export>
        <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeBodyDataGraphTooltip"></dxo-tooltip>
    </dx-chart>

    <div *ngIf="canShowImageMetrics" class="toggle-wrapper display-inlineblock marginright-25">
        <div class="toggle {{showImageMetrics ? 'on' : ''}}" (click)="onShowImageMetricsChanged(!showImageMetrics)">
            <div class="handle"></div>
        </div>
        <div class="text">
            Bildmetriken anzeigen
        </div>
    </div>

    <div *ngIf="canShowVideoMetrics" class="toggle-wrapper display-inlineblock marginright-25">
        <div class="toggle {{showVideoMetrics ? 'on' : ''}}" (click)="onShowVideoMetricsChanged(!showVideoMetrics)">
            <div class="handle"></div>
        </div>
        <div class="text">
            Videometriken anzeigen
        </div>
    </div>

    <div class="toggle-wrapper display-inlineblock marginright-25">
        <div class="toggle {{showDataTable ? 'on' : ''}}" (click)="onShowDataTableChanged(!showDataTable)">
            <div class="handle"></div>
        </div>
        <div class="text">
            Daten tabellarisch anzeigen
        </div>
    </div>

    <!--div class="toggle-wrapper display-inlineblock">
        <div class="toggle {{aggregateChartData ? 'on' : ''}}" (click)="onAggregateChartDataChanged(!aggregateChartData)">
            <div class="handle"></div>
        </div>
        <div class="text">
                Wöchentlichen Durchschnitt anzeigen
        </div>
    </div-->



    <dx-data-grid *ngIf="showDataTable && canShowDataTable" id="progressStatisticsTable"
        [dataSource]="progressStatisticsGraphData"
        [allowColumnReordering]="true"
        [allowColumnResizing]="false"
        [showBorders]="true"
        [showRowLines]="true"
        [columnAutoWidth]="true"
        [columnMinWidth]="100"
        [selectedRowKeys]="[]"
        (onSelectionChanged)="onSelectionChanged($event)" (onExporting)="chartExportService.onExporting($event)">

        <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
        <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
        <dxo-selection mode="multiple" [allowSelectAll]="false"></dxo-selection>

        <dxi-column dataField="dateRendered" caption="Datum" [width]="75" [fixed]="true" [showInColumnChooser]="true" [visible]="isTableDateColumnVisible" (visibleChange)="tableColumnVisibilityChanged($event, 'date')"></dxi-column>
        <dxi-column dataField="weekNumber" caption="KW" [groupIndex]="0"></dxi-column>

        <ng-container *ngFor="let graphItem of tableStatisticsGraphItems">
            <dxi-column [showInColumnChooser]="true" dataField="{{graphItem.valueField + 'Rendered'}}" caption="{{graphItem.name}}" [showEditorAlways]="false" [visible]="graphItem.visible" (visibleChange)="tableColumnVisibilityChanged($event, graphItem?.valueField)"></dxi-column>
        </ng-container>

        <dxo-summary [calculateCustomSummary]="calculateCustomSummaryRow">
            <ng-container *ngFor="let graphItem of tableStatisticsGraphItems">
                <dxi-group-item *ngIf="graphItem.summaryType != null" showInColumn="{{graphItem.valueField + 'Rendered'}}" summaryType="custom" name="{{graphItem.valueField + 'Rendered'}}" [customizeText]="graphItem.summaryType == 'avg' ? customizeGroupSummaryTextAverage : customizeGroupSummaryTextSum" [alignByColumn]="true"></dxi-group-item>
            </ng-container>
        </dxo-summary>
        <dxo-export [enabled]="true"></dxo-export>

    </dx-data-grid>
    <div *ngIf="showDataTable && !canShowDataTable" class="margin-10">
        Tabellarische Ansicht für diesen Zeitraum nicht verfügbar.
    </div>

    <div class="metric-image-view" *ngIf="showImageMetrics && canShowImageMetrics">
        <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownImageMetricTypes" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{selectedImageMetricType.name}}</button>
        <div class="icon-button marginleft-25" (click)="onOpenCompareImageDialog()" tooltip="Markiere die Bilder, die du vergleichen möchtest, mit einem Haken.">
            Ausgewählte Bilder vergleichen
        </div>
        <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
            <div *ngFor="let type of imageMetricTypes">
                <button class="dropdown-item" type="button" (click)="onImageMetricTypeChanged(type)">{{type.name}}</button>
            </div>
        </div>
        
        <div *ngIf="visibleImages.length > 0; else noImages_content" class="horizontal-image-scroll-wrapper">
            <div *ngFor="let image of visibleImages" class="display-inlineblock marginleft-5">
                <div class="label">{{image.dateRendered}}: </div><div class="layout-box image-box link" (click)="onOpenMetricTypeImage(image.imageURL)" [ngStyle]="{'background-image':'url('+image.imageURL+')'}"></div>
                <div class="w-100 compare-checkbox-container" (click)="onImageSelected(image);$event.stopPropagation()">
                    <input class="input checkbox margin-horizontal-auto h-100 m-2" [checked]="selectedCompareImages.includes(image)" tooltip="Zum Vergleich hinzufügen" type="checkbox">
                </div>
            </div>
        </div>
        <ng-template #noImages_content>
            <div>
                In diesem Zeitraum sind keine Bilder verfügbar.
            </div>
        </ng-template>
    </div>
    <div class="metric-image-view" *ngIf="showVideoMetrics && canShowVideoMetrics">
        <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownVideoMetricTypes" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{selectedVideoMetricType.name}}</button>
        <div class="dropdown-menu">
            <div *ngFor="let type of videoMetricTypes">
                <button class="dropdown-item" type="button" (click)="onVideoMetricTypeChanged(type)">{{type.name}}</button>
            </div>
        </div>
        
        <div *ngIf="visibleVideos.length > 0; else noVideos_content" class="horizontal-image-scroll-wrapper">
            <div *ngFor="let video of visibleVideos" class="display-inlineblock marginleft-5">
                <div class="label">{{video.dateRendered}}: </div>
                <div class="layout-box video-box">
                    <video controls playsinline src="{{video?.videoURL}}" preload="metadata"></video>
                </div>
            </div>
        </div>
        <ng-template #noVideos_content>
            <div>
                In diesem Zeitraum sind keine Videos verfügbar.
            </div>
        </ng-template>
    </div>
</div>