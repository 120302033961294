<div class="dialog-topbar">
    <h3 class="display-inlineblock margin-0" translate>Kalorien- und Nährstoffrechner</h3>
    <div class="icon-button float-right" (click)="onCancelDialog()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
    </div>
</div>
<div class="dialog-content-wrapper">
    <span translate>Kalorienbedarf berechnen:</span><br>
    <br>
    <div class="row">
        <div class="col-4">
            <div class="label" translate>Körpergewicht</div>
            <input class="light small textalign-right" [ngModel]="weight" (input)="onWeightChanged($event.target.value)"> kg
        </div>
        <div class="col-4">
            <div class="label" translate>Körpergröße</div>
            <input class="light small textalign-right" [ngModel]="height" (input)="onHeightChanged($event.target.value)"> cm
        </div>
        <div class="col-4">
            <div class="label" translate>Alter</div>
            <input class="light small textalign-right" [ngModel]="age" (input)="onAgeChanged($event.target.value)"> Jahre
        </div>
        <div *ngIf="selectedFormularItem?.formular == 'mifflinPAL' || selectedFormularItem?.formular == 'harrisBenedictPAL'" class="col-12">
            <div class="spacer-10"></div>
            <div class="label" translate>Aktivitätslevel</div>
            <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownPAL" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" translate>{{selectedPalValue.name}}</button>
            <div class="dropdown-menu" aria-labelledby="dropdownFormular">
                <div *ngFor="let item of palSelectionItems">
                    <button class="dropdown-item" type="button" (click)="onPalSelectionChanged(item)">{{item.name}}</button>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="d-inline-block wrapper-formular-selection">
        <div class="label" translate>Formel</div>
        <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownFormular" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{selectedFormularItem.name}}</button>
        <div class="dropdown-menu" aria-labelledby="dropdownFormular">
            <div *ngFor="let item of formularSelectionItems">
                <button class="dropdown-item" type="button" (click)="onFormularSelectionChanged(item)">{{item.name}}</button>
            </div>
        </div>
    </div>
    = <input class="input-nutrition light small textalign-right {{calorieNeed > 0 ? '' : 'invalid'}}" [ngModel]="calorieNeed" (input)="onCalorieNeedChanged($event.target.value)"> {{'kcal' | translate}}<br><br>
    <br>

    <span>Kalorienziel festlegen:</span><br>
    <div class="spacer-20"></div>
    <div>
        <div class="chip link" (click)="onSetCalorieGoal(null)" translate>
            Kalorienbedarf übernehmen
        </div>
        <div class="chip link" (click)="onSetCalorieGoal(calorieNeed - 500)" translate [translateParams]="{calorieNeedParameter: (calorieNeed - 500)}">
            calorieNeedParameter kcal für -0,5 kg / Woche
        </div>
        <div class="chip link" (click)="onSetCalorieGoal(calorieNeed - 250)" translate [translateParams]="{calorieNeedParameter: (calorieNeed - 250)}">
            calorieNeedParameter kcal für -0,25 kg / Woche
        </div>
        <div class="chip link" (click)="onSetCalorieGoal(calorieNeed + 500)" translate [translateParams]="{calorieNeedParameter: (calorieNeed + 500)}">
            calorieNeedParameter kcal für +0,5 kg / Woche
        </div>
        <div class="chip link" (click)="onSetCalorieGoal(calorieNeed + 250)" translate [translateParams]="{calorieNeedParameter: (calorieNeed + 250)}">
            calorieNeedParameter kcal für +0,25 kg / Woche
        </div>
    </div>
    <br>
    <div class="spacer-20"></div>
    <input class="input-nutrition light small textalign-right {{getCalorieGoal() > 0 ? '' : 'invalid'}}" [ngModel]="getCalorieGoal()" (input)="onCalorieGoalChanged($event.target.value)"> {{'kcal' | translate}}<br><br>
    <br>

    <span translate>Nährstoffverteilung anpassen:</span><br>
    <br>
    <input class="input-nutrition light small textalign-right" [ngModel]="macroCalculatorSliderValues[0]" (input)="onCarbohydratePercentageChanged($event.target.value)"> % K
    <input class="input-nutrition light small textalign-right marginleft-25" [ngModel]="macroCalculatorSliderValues[1] - macroCalculatorSliderValues[0]" (input)="onProteinPercentageChanged($event.target.value)"> % E
    <input class="input-nutrition light small textalign-right marginleft-25" [ngModel]="100 - macroCalculatorSliderValues[1]" disabled> % F
    <br>
    <nouislider *ngIf="user" [config]="macroCalculatorSliderConfig" [(ngModel)]="macroCalculatorSliderValues" ></nouislider>
    <br>
    <span class="bold">
        <span translate>Ergebnis:</span> {{getCarbohydratesGoal()}} {{'g K' | translate}}/ {{getProteinGoal()}} {{'g E' | translate}}/ {{getFatGoal()}} {{'g F' | translate}}/ {{getCalculatedCalorieGoalForMacros()}} {{'kcal' | translate}}</span>
    <br><br>
    <div class="actions">
        <div class="icon-button" (click)="onApplyMacroCalculatorValues()" translate>
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
            </svg>
            Übernehmen
        </div>
    </div>
</div>