import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-video-view-dialog',
  templateUrl: './video-view-dialog.component.html',
  styleUrls: ['./video-view-dialog.component.css']
})
export class VideoViewDialogComponent {

  public videoUrls: any[] = []
  public title: string

  constructor(public dialogRef: MatDialogRef<VideoViewDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {videoUrl: string, title: string}) {
    if (data.videoUrl != null) {
      this.videoUrls.push({videoUrl: data.videoUrl});
    }
    
    this.title = data.title ?? null 
    dialogRef.disableClose = false
  }

  ngOnInit(): void {
  }

  onCloseDialog(){
    this.dialogRef.close();
  }
}

