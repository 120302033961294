import { environment } from "src/environments/environment"
import { CoachSubscription } from "./subscription.model"
import { CoachInvoice } from "./invoice-coach.model"

export class LicenceHolder {
    uid: string
    name: string
    onTrial: boolean
    trialEndDate: Date
    availableTrialLicences: number
    referralCounter: number
    isAdmin: boolean

    stripeCustomerId: string
    stripeAccountId: string
    stripePriceId: string
    hasRecipeDatabaseAccess: boolean
    preStarterPackageClient: boolean
    allowPaymentMethodCreditCard: boolean
    overwriteHasPortalAccess: boolean
    overwriteCoachingLicenceCount: number
    hasPaymentAccess: boolean
    customLandingPageSlug: string
    customDataProcessingAgreement: string

    subscription: CoachSubscription
    coachInvoices: CoachInvoice[]

    get licenceHolderName(): string {
        return this.name
    }

    constructor();
    constructor(init: LicenceHolder);
    constructor(init?: LicenceHolder) {
        this.uid = init && init.uid || null
        this.name = init && init.name || null
        this.onTrial = init && init.onTrial || false
        this.trialEndDate = init && init.trialEndDate ? new Date((init as any).trialEndDate.seconds * 1000) : null

        this.stripeCustomerId = init && init.stripeCustomerId || null
        this.stripeAccountId = init && init.stripeAccountId || null
        this.stripePriceId = init && init.stripePriceId || null
        this.subscription = init && init.subscription || null
        this.hasRecipeDatabaseAccess = init && init.hasRecipeDatabaseAccess || false
        this.availableTrialLicences = init && init.availableTrialLicences != null ? init.availableTrialLicences : 1
        this.preStarterPackageClient = init && init.preStarterPackageClient || false
        this.allowPaymentMethodCreditCard = init && init.allowPaymentMethodCreditCard || false
        this.overwriteHasPortalAccess = init && init.overwriteHasPortalAccess || false
        this.overwriteCoachingLicenceCount = init && init.overwriteCoachingLicenceCount || null
        this.hasPaymentAccess = environment.isWhitelabel ? true : init && init.hasPaymentAccess || false
        this.customLandingPageSlug = init && init.customLandingPageSlug || null
        this.referralCounter = init && init.referralCounter || 0
        this.isAdmin = init && init.isAdmin || false
        this.coachInvoices = init && init.coachInvoices || null
        this.customDataProcessingAgreement = init && init.customDataProcessingAgreement || null
    }

    hasFailedPayments() {
        if (!this.coachInvoices || this.coachInvoices.length == 0) return false
        return this.coachInvoices.filter(x => x.status == 'failed').length > 0
    }
}