<div class="dialog-topbar">
    <div class="row">
        <div class="col">
            <h3 class="margin-0">Voreinstellungen</h3>
        </div>
    </div>
</div>
<div class="dialog-content-wrapper">
    <div class="row">
        <div class="col-12">
            <h3>Gruppen</h3>
            <div class="spacer-10"></div>
            <div class="d-inline-block wrapper-selection">
                <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownTrigger" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getAssignedGroupNames() || 'Auswählen'}}</button>
                <div class="dropdown-menu checkin-dropdown" aria-labelledby="dropdownBodyDataGraphTimeRange">
                    <div *ngFor="let group of userService.getClientGroups()" class="dropdown-item link" (click)="onAssignToClientGroup(group);$event.stopPropagation()">
                        <input class="input checkbox" type="checkbox" [checked]="user.metadataUser?.assignedClientGroups?.includes(group)"><div class="display-inline">{{group}}</div>
                    </div>
                </div>
            </div>
            <div class="spacer-20"></div>

            <h3>Check-Ins</h3>
            <app-checkin-assignment-view [User]="user"></app-checkin-assignment-view>
            <div class="spacer-20"></div>

            <h3>Ernährung</h3>
            <div class="recipesharing-toggle toggle-wrapper margin-4 mobile-marginbottom-10" matTooltip="Aktiviere den Ernährungsbereich in Traindoo für den Athleten.">
                <div class="toggle {{isNutritionEnabled() ? 'on' : ''}}" (click)="onEnableNutritionChanged(!isNutritionEnabled())">
                    <div class="handle"></div>
                </div>
                <div class="text">
                    Ernährung aktivieren
                    <div class="label" *ngIf="isNutritionEnabled()">Es fallen zusätzliche Kosten an.</div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="dialog-bottombar">
    <div class="row">
        <button class="button-filled small float-right" (click)="onConfirmDialog()">Fertig</button>
    </div>
</div>