<div class="dialog-topbar">
    <div *ngIf="canAddComparisonQuestionaireResult" class="icon-button display-inlineblock float-left" (click)="addComparisonQuestionaireResult()" translate>
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
        </svg>
        Check-In zum Vergleichen hinzufügen
    </div>
    <div class="icon-button float-right" (click)="onCloseDialog()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        <span *ngIf="!utilityService.onMobile()" translate>Schließen</span>
    </div>
</div>
<div class="dialog-content-wrapper row horizontal-scoll-wrapper flex-nowrap">
    <div class="d-inline-block questionaire-col layout-box" *ngFor="let questionaireResult of questionaireResults">
        <app-completed-questionaire-view (onCloseQuestionaireResult)="closeQuestionaire($event)" (onQuestionaireResultChangesSaved)="questionaireResultChangesSaved($event)" [isClosable]="questionaireResults.length > 1" [questionaireResult]="questionaireResult" [user]="user" [openMetricDataImage]="openMetricDataImage?.bind(this)" [editMode]="editMode"></app-completed-questionaire-view>
    </div>
</div>