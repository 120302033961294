<div *ngIf="userService.getLoggedInUser()?.coach?.hasLicenceHolderRights" class="">
    <div class="row">
        <div *ngIf="isAdmin() && licenceHolders" class="col-12 col-xxl-10">
            <div class="layout-box box-licenceholders">
                <h3 class="display-inlineblock marginright-25">Accountinhaber</h3>
                <button class="button-filled small connect-client" (click)="onCreateLicenceHolder()">Accountinhaber erstellen</button>
                <button class="button-filled small light connect-client marginleft-10" (click)="onUpdateEmail()">Email ändern</button>
                <button class="button-filled small light connect-client marginleft-10" (click)="onOpenTokenCreation()">Token erstellen</button>
                <div class="display-inlineblock marginleft-10 float-right" *ngIf="isAdmin()">
                    <div class="icon-button" (click)="onRedeemToken()">
                        Token einlösen
                    </div>
                </div>
                <br>
                <div class="spacer-25"></div>
                <div class="licenceholders-wrapper">
                    <div class="" *ngFor="let licenceHolder of licenceHolders; let i = index">
                        <div class="row licenceholder-item {{licenceHolder.uid == selectedLicenceHolder?.uid ? 'selected' : ''}}" (click)="onSelectLicenceHolder(licenceHolder)">
                            <div class="col-8">
                                {{licenceHolder.name}}
                            </div>
                            <div class="col-1">
                                <input class="input checkbox" type="checkbox" [checked]="licenceHolder.onTrial" (change)="onIsOnTrialToggled(licenceHolder, $event.target.checked);$event.stopPropagation()"> PP
                            </div>
                            <div class="col-3" *ngIf="isAdmin()">
                                <div class="icon-button" (click)="onCreateCustomToken(licenceHolder.uid)">
                                    Token erstellen
                                </div>
                                <div class="icon-button marginleft-25" (click)="onDownloadLicenceOverview(licenceHolder)">
                                    Lizenzübersicht
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectedLicenceHolder" class="edit-licenceholder">
                    <div class="spacer-25"></div>
                    <input [(ngModel)]="selectedLicenceHolder.name" class="input small wide {{selectedLicenceHolder.name.length > 0 ? '' : 'invalid'}} marginright-25" type="text" placeholder="Name"/>
                    Trainerlizenzen: <input [(ngModel)]="selectedLicenceHolder.availableTrialLicences" class="input small marginright-25" type="number" placeholder="1"/>
                    Weiterempfehlungen: <input [(ngModel)]="selectedLicenceHolder.referralCounter" class="input small" type="number" placeholder="0"/>
                    <div class="spacer-10"></div>
                    <div>Email: {{selectedLicenceHolderEmail}}</div>
                    <div class="spacer-10"></div>
                    <div *ngIf="selectedLicenceHolder.name.length > 0" class="icon-button" (click)="onSaveLicenceHolder()">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                        </svg>
                        Speichern
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="userService.getLoggedInUser()?.hasPortalAccess()" class="col-12 col-xxl-10">
            <div class="layout-box">
                <h3 class="display-inlineblock marginright-25">Coaches</h3>
                <button *ngIf="isAdmin() || environment.isWhitelabel" class="button-filled small connect-client" (click)="onCreateCoach()">Coach erstellen</button>
                <br>
                <div class="label" *ngIf="!isAdmin() && !environment.isWhitelabel">Um neue Coach-Accounts anzulegen, kontaktiere uns.</div>
                <div class="spacer-25"></div>
                <div class="row licenceholder-item {{coach.uid == selectedCoach?.uid ? 'selected' : ''}}" *ngFor="let coach of coachesOfLicenceHolder; let i = index" (click)="onSelectCoach(coach)">
                    <div class="col">
                        {{coach.name}}
                    </div>
                    <div class="col">
                        {{coach.isLicenceHolder() ? 'Accountinhaber:in' : ''}}
                    </div>
                    <div class="col">
                        <input class="input checkbox" type="checkbox" [checked]="coach.hasLicenceHolderRights" (change)="onHasLicenceHolderRightsToggled(coach, $event.target.checked);$event.stopPropagation()" [disabled]="coach.isLicenceHolder()"> Admin-Rechte
                    </div>
                    <div class="col" *ngIf="isAdmin()">
                        <div class="icon-button" (click)="onCreateCustomToken(coach.uid)">
                            Token erstellen
                        </div>
                    </div>
                </div>
                <div *ngIf="selectedCoach" class="edit-licenceholder">
                    <div class="spacer-25"></div>
                    <input [(ngModel)]="selectedCoach.name" class="input small wide {{selectedCoach.name.length > 0 ? '' : 'invalid'}}" type="text" placeholder="Name"/>
                    <div class="spacer-10"></div>
                    <div>Email: {{selectedCoachEmail}}</div>
                    <div class="spacer-10"></div>
                    <div class="form-group">
                        <div class="d-inline-block wrapper-repetition-selection">
                            <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownAccess" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Zugriff festlegen</button>
                            <div class="dropdown-menu dropdown-notificationTarget" aria-labelledby="dropdownAccess">
                                <div class="dropdown-item link" (click)="onCoachCanAccessAllUsersChanged();$event.stopPropagation()">
                                    <input class="input checkbox" type="checkbox" [checked]="selectedCoach.canAccessAllUsers" [disabled]="selectedCoach.hasLicenceHolderRights"><div class="display-inlineblock"> Alle</div>
                                </div>
                                <div class="dropdown-divider"></div>
                                <ng-container *ngFor="let coach of coachesOfLicenceHolder">
                                    <div class="dropdown-item link" (click)="onCoachCoachesAccessChanged(coach);$event.stopPropagation()">
                                        <input class="input checkbox" type="checkbox" [checked]="selectedCoach.accessibleCoachUids.includes(coach.uid) || coach.uid == selectedCoach.uid" [disabled]="coach.uid == selectedCoach.uid || selectedCoach.canAccessAllUsers"><div class="display-inlineblock"> {{coach.name}}'s Kunden</div>
                                    </div>
                                </ng-container>
                                <div class="dropdown-divider"></div>
                                <div class="dropdown-item link" *ngFor="let group of getSelectableClientGroups()" (click)="onCoachUserAccessGroupsChanged(group);$event.stopPropagation()">
                                    <input class="input checkbox" type="checkbox" [checked]="selectedCoach.accessibleGroupNames.includes(group)" [disabled]="selectedCoach.canAccessAllUsers"><div class="display-inlineblock"> {{group}}</div>
                                </div>
                                <div class="dropdown-divider"></div>
                                <div class="dropdown-item link" *ngFor="let user of getSelectableClients()" (click)="onCoachUserAccessChanged(user);$event.stopPropagation()">
                                    <input class="input checkbox" type="checkbox" [checked]="selectedCoach.accessibleUserUids.includes(user.uid)" [disabled]="selectedCoach.canAccessAllUsers"><div class="display-inlineblock"> {{user.getName()}}</div>
                                </div>
                            </div>
                        </div>
                        <input class="input checkbox" type="checkbox" [checked]="selectedCoach.canAssignCoachesAndLicences()" (change)="onCanAssignCoachesAndLicencesToggled(selectedCoach, $event.target.checked);$event.stopPropagation()" [disabled]="selectedCoach.isLicenceHolder() || selectedCoach.hasLicenceHolderRights"> <div class="label display-inlineblock marginleft-10" tooltip="Aktiviere diese Einstellung, wenn der Coach die Möglichkeit haben soll, auch ohne Admin-Rechte den zugewiesenen Coach eines Kunden anzupassen.">Darf zugewiesenen Coach einer Lizenz anpassen</div>
                    </div>
                    <div class="spacer-10"></div>
                    <div *ngIf="selectedCoach.name.length > 0" class="icon-button" (click)="onSaveCoach()">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                        </svg>
                        Speichern
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>
