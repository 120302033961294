<div *ngIf="environment.firebaseProjectId == 'traindoo-app'" class="row margin-0">
  <div class="col-12 already-user-box" *ngIf="userService.getLoggedInUser()?.licenceHolder">
    <div class="container"> 
      <div class="bold display-inlineblock text" translate>
        Du bist bereits ein TRAINDOO Coach!
      </div>
      <a class="btn btn-lg btn-primary btn-block mb-1 button-filled light float-right" routerLink="/dashboard" translate>Zum Dashboard</a>
    </div>
  </div>
  <div class="col-12 already-user-box" *ngIf="!userService.getLoggedInUser()?.licenceHolder">
    <div class="container"> 
      <div class="bold display-inlineblock text" translate>
        Bist du bereits Coach bei TRAINDOO 2.0?
      </div>
      <a class="btn btn-lg btn-primary btn-block mb-1 button-filled light float-right" routerLink="/login" translate>Anmelden</a>
    </div>
  </div>
  <div class="col-12">
    <div class="container pt-3">
      <div class="row justify-content-sm-center">
          <div class="col-12">
            <div class="branding">
              <img draggable="false" class="logo" src="assets/traindoo/Traindoo Full Logo.png">
            </div>
          </div>
          <div class="col-12">
            <div class="layout-box login">
              <h2 translate>Starte als Coach mit TRAINDOO</h2>
              <div class="">
                <div class="row">
                  <div class="col-12" *ngIf="step == 0">
                    <div class="bold" translate>Gib ein paar Daten zu deinem Coaching an.</div>
                    <div class="spacer-20"></div>
                    <form>
                      <div translate>Wie heißt du?</div>
                      <div class="spacer-10"></div>
                      <input [(ngModel)]="coachName" [ngModelOptions]="{standalone: true}" type="text" class="form-control mb-2" [placeholder]="'Vorname' | translate" required/>
                      <div class="spacer-10"></div>
                      <div translate>Hat dein Coaching oder Unternehmen einen Namen? Dann gib ihn hier an. Wenn nicht, gib deinen Vor- und Nachnamen an.</div>
                      <div class="spacer-10"></div>
                      <input [(ngModel)]="licenceHolderName" [ngModelOptions]="{standalone: true}" type="text" class="form-control mb-2" [placeholder]="'Coachingname oder Vor- und Nachname' | translate" required autofocus/>
                      <div class="spacer-10"></div>
                      <button class="btn btn-lg btn-primary btn-block mb-1 button-filled float-right" type="submit" (click)="onContinue()" translate>Weiter</button>
                      <br><br>
                    </form>
                  </div>
                  <div class="col-12" *ngIf="step == 1">
                    <div class="bold" translate>Noch ein paar rechtliche Themen.</div>
                    <div class="spacer-20"></div>
                    <form>
                      <div class="terms">
                        <input class="input checkbox" type="checkbox" [(ngModel)]="termsAccepted"><span [innerHTML]="getTermsAndConditionsUrl()"></span>.
                      </div>
                      <div class="spacer-10"></div>
                      <button *ngIf="termsAccepted" class="btn btn-lg btn-primary btn-block mb-1 button-filled float-right" type="submit" (click)="onContinue()" translate>Weiter</button>
                      <br><br>
                    </form>
                  </div>
                  <div class="col-12" *ngIf="step == 2 && !loading">
                    <div *ngIf="createAccount" class="bold" translate>Erstelle einen Account.</div>
                    <div *ngIf="!createAccount" class="bold" translate>Melde dich mit deinem Account an.</div>
                    <div *ngIf="!createAccount">
                      <div class="spacer-10"></div>
                      <span translate>Unter dieser Email-Adresse besteht bereits ein Nutzeraccount. Melde dich mit deinen bestehenden Zugangsdaten an, um als Coach zu starten.</span>
                    </div>
                    <div class="spacer-20"></div>
                    <form>
                      <input [(ngModel)]="mail" [ngModelOptions]="{standalone: true}" type="text" class="form-control mb-2" [placeholder]="'Email' | translate" required autofocus/>
                      <input *ngIf="createAccount" [(ngModel)]="mailValidation" [ngModelOptions]="{standalone: true}" type="text" class="form-control mb-2" [placeholder]="'Email bestätigen' | translate" required/>
                      <input [(ngModel)]=password [ngModelOptions]="{standalone: true}" type="password" class="form-control mb-2" [placeholder]="'Password' | translate" required/>
                      <div class="spacer-10"></div>
                      <button *ngIf="createAccount" class="btn btn-lg btn-primary btn-block mb-1 button-filled float-right" type="submit" (click)="register()" translate>Registrieren</button>
                      <button *ngIf="!createAccount" class="btn btn-lg btn-primary btn-block mb-1 button-filled float-right" type="submit" (click)="login()" translate>Anmelden</button>
                      <br><br>
                    </form>
                  </div>
                  <div class="col-12" *ngIf="step == 2 && loading">
                    <div class="bold" translate>Dein Account wird erstellt... 🚀</div>
                    <div class="spacer-20"></div>
                    <div class="loading-animation">
                      <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
                    </div>
                    <div class="spacer-20"></div>

                  </div>
                  <div class="col-12" *ngIf="step == 3">
                    <div class="bold" translate>Fertig!<br>Du kannst jetzt in TRAINDOO loslegen.</div>
                    <div class="spacer-20"></div>

                    <button class="btn btn-lg btn-primary btn-block mb-1 button-filled float-right" type="submit" (click)="goToDashboard()" translate>Jetzt loslegen</button>
                    <div class="spacer-20"></div>

                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="step == 0" class="old-traindoo textalign-center">
              <div class="bold" translate>Nutzt du Traindoo 1.0?</div>
              <div class="spacer-10"></div>
              <span translate>Aktuell arbeiten wir an der Übertragung der Accounts von Traindoo 1.0 auf Traindoo 2.0. Arbeite vorübergehend weiter in Traindoo 1.0 und wechsle später zu Traindoo 2.0.</span>
              <div class="spacer-20"></div>
              <a class="button-filled" href="https://beta.app.traindoo.io" translate>Zu Traindoo 1.0</a>
            </div>
            <div class="spacer-50"></div>
          </div>
      </div>
    </div>
  </div>
</div>