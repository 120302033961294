import {Component, NgZone} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from 'src/app/auth/auth.service';
import {Product, ProductDurationUnit, ProductLicenseType} from 'src/app/model/product.model';
import {User} from 'src/app/model/user.model';
import {FirestoreService} from 'src/app/services/firestore.service';
import {PaymentService} from 'src/app/services/payment.service';
import {UtilityService} from 'src/app/services/utility.service';
import {ProductEditorComponent} from '../product-editor/product-editor.component';
import {firstValueFrom} from 'rxjs';
import {EmailTemplate, PaymentSettings} from 'src/app/model/payment-settings.model';
import {SingleExerciseComponent} from 'src/app/training/single-exercise/single-exercise.component';
import {EmailTemplateDialogComponent} from 'src/app/dialogs/email-template-dialog/email-template-dialog.component';
import {environment} from 'src/environments/environment';
import {ProductPurchase} from 'src/app/model/product-purchase.model';
import {Payment} from 'src/app/model/payment.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent {

  public PaymentComponent = PaymentComponent

  public environment = environment

  public showOrders: boolean = false
  public showPayment: boolean = false
  public customLandingPageSlug: string = null

  user: User
  stripeConnectAccountLink: string = null
  get stripeAccountVerified() {
    return this.user?.getStripeAccountId() && this.paymentService.onboardingCompleted && this.paymentService.chargesEnabled && this.paymentService.payoutsEnabled
  }
  get onboardingCompleted() {
    return this.user?.getStripeAccountId() && this.paymentService.onboardingCompleted
  }

  get paymentSettings(): PaymentSettings {
    return this.paymentService.paymentSettings
  }

  get products(): Product[] {
    return this.paymentService.activeProducts
  }

  get productPurchases(): ProductPurchase[] {
    return this.paymentService.productPurchases
  }
  get payments(): Payment[] {
    return this.paymentService.payments
  }

  get accountBalance() {
    return this.paymentService.accountBalance
  }
  getPrintablePendingBalance() {
    if (!this.accountBalance) return null
    return (this.accountBalance.pendingAmount / 100).toString().replace('.', ',') + ' ' + PaymentComponent.getPrintableCurrency(this.accountBalance.pendingCurrency)
  }
  getPrintableAvailableBalance() {
    if (!this.accountBalance) return null
    return (this.accountBalance.availableAmount / 100).toString().replace('.', ',') + ' ' + PaymentComponent.getPrintableCurrency(this.accountBalance.availableCurrency)
  }

  isColorValid(color: string) {
    return color == null || color.length == 0 || (color.startsWith('#') && color.length > 3)
  }
  isLandingPageSlugValid() {
    return this.customLandingPageSlug == null || this.customLandingPageSlug.length == 0 || this.customLandingPageSlug.match(/^[a-zA-Z0-9\-]+$/)
  }
  async onCheckLandingPageSlug() {
    if (!this.isLandingPageSlugValid()) {
      this.toastr.error(this.translate.instant('Der Slug darf nur aus Buchstaben, Zahlen und Bindestrichen bestehen.'), null,  {
        positionClass: 'toast-bottom-center'
      })
      return
    }
    this.spinner.show()
    if (this.customLandingPageSlug == null || this.customLandingPageSlug.length == 0) {
      this.customLandingPageSlug = null
      await this.userService.updateLandingPageSlug(this.user.licenceHolderUid, null)
    } else {
      var licenceHolderUid = await firstValueFrom(this.userService.getLicenceHolderUidBySlug(this.customLandingPageSlug))
      if (licenceHolderUid && licenceHolderUid != this.user.licenceHolderUid) {
        this.toastr.error(this.translate.instant('Dieser Link ist bereits vergeben'))
      } else {
        await this.userService.updateLandingPageSlug(this.user.licenceHolderUid, this.customLandingPageSlug)
        this.toastr.success(this.translate.instant('Link erfolgreich gespeichert'))
      }
    }
    this.spinner.hide()
  }

  static getPrintableCurrency(currency: string) {
    if (currency == 'eur' || currency == null) return '€'
    if (currency == 'chf') return 'CHF'
    if (currency == 'usd') return '$'
    if (currency == 'gbp') return '£'
    return currency
}

  public static TAB_SETUP_INFORMATION = 'information'
  public get tabSetupInformation() {
    return PaymentComponent.TAB_SETUP_INFORMATION
  }
  public static TAB_SETUP_INVOICING = 'invoicing'
  public get tabSetupInvoicing() {
    return PaymentComponent.TAB_SETUP_INVOICING
  }
  public static TAB_SETUP_EMAILS = 'emails'
  public get tabSetupEmails() {
    return PaymentComponent.TAB_SETUP_EMAILS
  }
  public static TAB_SETUP_LANDINGPAGE = 'landingpage'
  public get tabSetupLandingPage() {
    return PaymentComponent.TAB_SETUP_LANDINGPAGE
  }
  public currentSetupTab = PaymentComponent.TAB_SETUP_INFORMATION

  onChangeSetupTab(tab: string) {
    this.currentSetupTab = tab
  }

  constructor(private router: Router, public userService: FirestoreService, private authService: AuthService, public paymentService: PaymentService, public utilityService: UtilityService, private toastr: ToastrService, private ngZone: NgZone, public dialog: MatDialog, private spinner: NgxSpinnerService, public translate: TranslateService) {
    this.user = userService.getLoggedInUser()
    this.loadData()

    this.customLandingPageSlug = this.user?.licenceHolder?.customLandingPageSlug
    userService.observableUser.subscribe(x => {
      this.user = x
      this.loadData()
      this.customLandingPageSlug = this.user?.licenceHolder?.customLandingPageSlug
    })

  }

  loadData() {
    if (this.user?.getStripeAccountId()) {
      if (this.onboardingCompleted) {
        if (!this.showOrders && !this.showPayment) {
          if (this.userService.getLoggedInUser().coach?.canAccessPayment) {
            this.showOrders = true
          } else if (this.userService.getLoggedInUser().coach?.canAccessProducts) {
            this.showPayment = true
          }
        }
      } else {
        if (!this.showOrders && !this.showPayment) this.showPayment = true
        this.loadStripeConnectAccountLink()
      }

      this.paymentService.loadPaymentSettings()

    } else {
      if (!this.showOrders && !this.showPayment) this.showPayment = true
    }
  }

  resetTabs() {
    this.showOrders = false;
    this.showPayment = false;
  }

  swapTab(tab: string) {
    this.resetTabs();
    switch(tab) {
      case "orders":
        this.showOrders = true;
        break;
      case "payment":
        this.showPayment = true;
        break;
      default:
        break;
    }
  }

  public productSearchInput: string
  public filteredProducts: Product[]

  onProductSearchInputChanged(text: string) {
    this.productSearchInput = text
    if (!text || text.length == 0) {
      this.filteredProducts = this.products
    } else {
      this.filteredProducts = []
      this.products.forEach(product => {
        if (product.name.toLowerCase().includes(text.toLowerCase()) || product.price.toString().includes(text)) {
          this.filteredProducts.push(product)
        }
      })
    }
  }
  onDeleteProductSearchInput() {
    (<HTMLInputElement> document.getElementById('productsearch-input')).value = ''
    this.filteredProducts = this.products
    this.productSearchInput = null
  }

  async onDuplicateProduct(product: Product) {
    this.spinner.show()
    var newProduct = new Product(product)
    newProduct.id = null
    newProduct.name = newProduct.name + ' (Kopie)'
    await this.userService.saveProduct(newProduct)
    if (newProduct.thumbnailPath) {
      if (!product.thumbnailUrl) {
        product.thumbnailUrl = await this.userService.getDownloadUrl(product.getFullThumbnailPath(product.licenceHolderUid))
      }
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = new Blob([xhr.response], { type: 'image/jpg' });
        const file = new File([blob], "thumbnail.jpg", {
          type: 'image/jpeg',
          lastModified: Date.now()
        });
        this.userService.uploadImage(file, newProduct.getFullThumbnailPath(this.userService.getLoggedInUser().licenceHolderUid), newProduct.thumbnailPath)
      };
      xhr.open('GET', product.thumbnailUrl)
      xhr.setRequestHeader('Access-Control-Request-Headers', 'access-control-allow-origin')
      xhr.send();
    }
    this.spinner.hide()
  }

  onPaymentMethodSelected(method: string) {
    if (!this.paymentSettings) this.paymentService.paymentSettings = new PaymentSettings()
    if (this.paymentSettings.availablePaymentMethods.includes(method)) {
      this.paymentSettings.availablePaymentMethods = this.paymentSettings.availablePaymentMethods.filter(x => x != method)
    } else {
      this.paymentSettings.availablePaymentMethods.push(method)
    }
    /*console.log('Load')
    this.userService.getStripeAccountPaymentMethods(this.user.getStripeAccountId()).then(paymentMethods => {
      console.log(paymentMethods)
    })*/
  }
  onCurrencySelected(currency: string) {
    if (!this.paymentSettings) this.paymentService.paymentSettings = new PaymentSettings()
    this.paymentSettings.currency = currency
  }

  async onSaveSettings() {
    await this.paymentService.onSavePaymentSettings()
    this.toastr.success("Einstellungen gespeichert.", "",  {
      positionClass: 'toast-bottom-center'
    })
    this.loadData()
  }

  onUploadTermsAndConditions() {
    document.getElementById('input-terms').click()
  }

  async termsAndConditionsUploaded(e) {
    if (e.target.files && e.target.files[0]) {
      if (!SingleExerciseComponent.checkUploadFile(e.target.files[0], 5000000, ["pdf"], this.toastr, this.translate)) return;
      var file = e.target.files[0];
      var result = await this.userService.uploadFile("licence_holders/" + this.user.licenceHolderUid + "/settings/payment/" + file.name, file)
      if (!this.paymentSettings) this.paymentService.paymentSettings = new PaymentSettings()
      this.paymentService.paymentSettings.termsAndConditionsPath = file.name
      await this.paymentService.onSavePaymentSettings()
    }
  }

  createStripeConnectAccount() {
    this.spinner.show()
    this.userService.createStripeConnectAccount(this.user.licenceHolderUid, this.authService.user.email, this.user.licenceHolder?.licenceHolderName ?? this.user.name).subscribe(result => {
      var accountId = result.accountId
      this.user.licenceHolder.stripeAccountId = accountId
      this.spinner.hide()
      this.loadStripeConnectAccountLink()
    }, err => {
      this.spinner.hide()
      console.log(err)
    })
  }
  loadStripeConnectAccountLink() {
    this.spinner.show()

    this.userService.createStripeConnectAccountLink(this.user.getStripeAccountId()).subscribe(result => {
      this.ngZone.run( () => {
        this.spinner.hide()
        this.stripeConnectAccountLink = result.redirectUrl
      });
    }, err => {})

  }

  onSelectProduct(product: Product) {
    if (!product.stripeAccountId) product.stripeAccountId = this.user.getStripeAccountId()

    const dialogRef = this.dialog.open(ProductEditorComponent, { data: { product: product }, width: '1000px'})
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.shouldSave && !result.shouldDelete) {
          if (result.newThumbnail) product.thumbnailPath = 'thumbnail.png'
          await this.userService.saveProduct(product)
          if (result.newThumbnail) {
            this.userService.uploadImage(result.newThumbnail, product.getFullThumbnailPath(this.userService.getLoggedInUser().licenceHolderUid), 'thumbnail.png')
          }
        } else if (result.shouldDelete) {
          await this.userService.deleteStripeProduct(product)
        }
      }
    });
  }

  onCreateProduct() {
    var product = new Product()
    product.recurring = false
    product.licenceHolderUid = this.user.licenceHolderUid
    product.durationUnit = ProductDurationUnit.MONTH
    product.stripeAccountId = this.user.getStripeAccountId()
    product.licenceType = ProductLicenseType.COACHING
    product.price = 0
    product.currency = this.paymentSettings?.currency

    this.onSelectProduct(product)
  }

  onEditPurchaseConfirmationEmail() {
    if (!this.paymentSettings) this.paymentService.paymentSettings = new PaymentSettings()
    var template = this.paymentSettings.customerPurchaseConfirmationEmailTemplate?.clone()
    if (!template) {
      template = new EmailTemplate()
      template.subject = this.translate.instant('Danke für deinen Kauf')
      template.heading = this.translate.instant('Deine Kaufbestätigung')
      template.body = this.translate.instant('Hallo {{Name}},<br><br>vielen Dank für deinen Kauf.<br>Ich freue mich auf unseren gemeinsamen Weg!<br><br>Hier kannst du deinen Kauf einsehen und verwalten: {{PurchaseLink}}<br><br>Viele Grüße')
    }
    const dialogRef = this.dialog.open(EmailTemplateDialogComponent, { data: { template: template, requiredPlaceholders: ['Name', 'PurchaseLink'] }, width: '1000px'})
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.template) {
          this.paymentSettings.customerPurchaseConfirmationEmailTemplate = result.template
          await this.onSaveSettings()
        }
      }
    });
  }

  onEditKickoffEmail() {
    if (!this.paymentSettings) this.paymentService.paymentSettings = new PaymentSettings()
    var template = this.paymentSettings.customerKickoffEmailTemplate?.clone()
    if (!template) {
      template = new EmailTemplate()
      template.subject = this.translate.instant('Es kann losgehen!')
      template.heading = this.translate.instant('Es kann losgehen!')
      template.body = this.translate.instant('Hallo {{Name}},<br><br>endlich geht es los!<br>Melde dich in nutrilize mit deinem Account an und starte mit mir.<br><br>Viel Spaß und viele Grüße!')
    }
    const dialogRef = this.dialog.open(EmailTemplateDialogComponent, { data: { template: template }, width: '1000px'})
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.template) {
          this.paymentSettings.customerKickoffEmailTemplate = result.template
          await this.onSaveSettings()
        }
      }
    });
  }

  onEditInvoiceEmail() {
    if (!this.paymentSettings) this.paymentService.paymentSettings = new PaymentSettings()
    var template = this.paymentSettings.customerInvoiceEmailTemplate?.clone()
    if (!template) {
      template = new EmailTemplate()
      template.subject = this.translate.instant('Neue Rechnung verfügbar')
      template.heading = this.translate.instant('Neue Rechnung verfügbar')
      template.body = this.translate.instant('Hallo {{Name}},<br><br>es ist eine neue Rechnung für dich verfügbar. Du findest die Rechnung hier: {{InvoiceUrl}}<br>Wenn zur Zahlung weitere Aktionen nötig sind, erhältst du eine separate Email dazu.<br><br>Vielen Dank für dein Vertrauen und viele Grüße')
    }
    const dialogRef = this.dialog.open(EmailTemplateDialogComponent, { data: { template: template, requiredPlaceholders: ['Name', 'InvoiceUrl'] }, width: '1000px'})
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.template) {
          this.paymentSettings.customerInvoiceEmailTemplate = result.template
          await this.onSaveSettings()
        }
      }
    });
  }

  onEditPaymentRequestEmail() {
    if (!this.paymentSettings) this.paymentService.paymentSettings = new PaymentSettings()
    var template = this.paymentSettings.customerPaymentRequestEmailTemplate?.clone()
    if (!template) {
      template = new EmailTemplate()
      template.subject = this.translate.instant('Zahlung ausstehend')
      template.heading = this.translate.instant('Zahlung ausstehend')
      template.body = this.translate.instant('Hallo {{Name}},<br><br>um die Zahlung fertigzustellen, sind weitere Schritte nötig.<br>Bitte bezahle die Rechnung unter {{PaymentLink}}.<br><br>Viele Grüße')
    }
    const dialogRef = this.dialog.open(EmailTemplateDialogComponent, { data: { template: template, requiredPlaceholders: ['Name', 'PaymentLink'] }, width: '1000px'})
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.template) {
          this.paymentSettings.customerPaymentRequestEmailTemplate = result.template
          await this.onSaveSettings()
        }
      }
    });
  }

  onEditCancelationConfirmationEmail() {
    if (!this.paymentSettings) this.paymentService.paymentSettings = new PaymentSettings()
    var template = this.paymentSettings.customerCancelationConfirmationEmailTemplate?.clone()
    if (!template) {
      template = new EmailTemplate()
      template.subject = this.translate.instant('Deine Kündigungsbestätigung')
      template.heading = this.translate.instant('Deine Kündigungsbestätigung')
      template.body = this.translate.instant('Hallo {{Name}},<br><br>hiermit bestätige ich die Kündigung deines Kaufs zum {{EndDate}}.<br>Ich danke dir für dein Vertrauen und hoffe, du warst zufrieden!<br><br>Hier kannst du den Status einsehen und verwalten: {{PurchaseLink}}<br><br>Viele Grüße')
    }
    const dialogRef = this.dialog.open(EmailTemplateDialogComponent, { data: { template: template, requiredPlaceholders: ['Name', 'PurchaseLink', 'EndDate'] }, width: '1000px'})
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.template) {
          this.paymentSettings.customerCancelationConfirmationEmailTemplate = result.template
          await this.onSaveSettings()
        }
      }
    });
  }

  onEditPurchaseExpirationEmail() {
    if (!this.paymentSettings) this.paymentService.paymentSettings = new PaymentSettings()
    var template = this.paymentSettings.customerPurchaseExpirationEmailTemplate?.clone()
    if (!template) {
      template = new EmailTemplate()
      template.subject = this.translate.instant('Deine Laufzeit ist zu Ende')
      template.heading = this.translate.instant('Deine Laufzeit ist zu Ende')
      template.body = this.translate.instant('Hallo {{Name}},<br><br>leider ist die Laufzeit deines Kaufs zu Ende und deine Lizenz wurde deaktiviert.<br>Ich danke dir für dein Vertrauen und hoffe, du warst zufrieden!<br><br>Viele Grüße')
    }
    const dialogRef = this.dialog.open(EmailTemplateDialogComponent, { data: { template: template }, width: '1000px'})
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.template) {
          this.paymentSettings.customerPurchaseExpirationEmailTemplate = result.template
          await this.onSaveSettings()
        }
      }
    });
  }

  openApplePayArticle() {
    (window as any).Intercom('showArticle', 10394356);
  }
  getActivateStripeHintTranslation() {
    return this.translate.instant('Stelle sicher, dass du die Zahlungsmethoden auch in deinem <a class="underline" href="https://dashboard.stripe.com/settings/payment_methods" target="_blank">Stripe-Account</a> aktiviert hast.');
  }
}
