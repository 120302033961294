<div class="dialog-topbar">
    <div class="row">
        <div class="col">
            <div class="icon-button" (click)="onSaveProduct()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                </svg>
                <span *ngIf="!utilityService.onSmallDisplay()" translate>Speichern</span>
            </div>
        </div>
        <div class="col">
            <div class="icon-button float-right" (click)="onCancelDialog()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                <span *ngIf="!utilityService.onSmallDisplay()" translate>Schließen</span>
            </div>
            <div class="icon-button float-right delete-recipe marginleft-25 marginright-25" (click)="onDeleteProduct()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="padding-4 bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                </svg>
                <span *ngIf="!utilityService.onSmallDisplay()" translate>Löschen</span>
            </div>
        </div>
    </div>
</div>
<div class="dialog-content-wrapper">
    <ng-container *ngIf="product">

        <h2 class="" translate>Produkt bearbeiten</h2>
        <div class="row">
            <div class="col-6 col-sm-3">
                <div class="imagewrapper link" (click)="onUploadThumbnail()">
                    <div *ngIf="!product.thumbnailUrl" class="product-thumbnail">
                        <div class="label" translate>Bild hochladen (.png oder .jpg im Format 3:2)</div>
                    </div>
                    <div *ngIf="product.thumbnailUrl" class="product-thumbnail" [ngStyle]="{'background-image':'url('+ product.thumbnailUrl +')'}"></div>
                    <input id="input-thumbnail" type="file" style="display: none;" accept=".jpg, .png, .jpeg" (change)="uploadedThumbnail($event)">
                </div>
                <div class="spacer-10"></div>
            </div>
            <div class="col-12 col-sm-9">
                <div class="row">
                    <div class="col-12">
                        <div class="display-inlineblock">
                            <label for="input-name" class="label" translate>Name</label>
                            <input id="input-name" required class="input form-control wide" [(ngModel)]="product.name" [placeholder]="'Name' | translate" type="text">
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="spacer-10"></div>
                        <label for="input-subtitle" class="label" translate>Untertitel</label>
                        <input id="input-subtitle" class="input form-control wide full-width" [(ngModel)]="product.subtitle" [placeholder]="'Untertitel' | translate" type="text">
                    </div>
                    <div class="col-12">
                        <div class="spacer-10"></div>
                        <div class="display-inlineblock marginleft-25">
                            <input class="input checkbox mr-1" type="checkbox" [(ngModel)]="product.productPageEnabled"> <span translate>Produktseite aktivieren</span>
                        </div>
                        <div *ngIf="paymentSettings?.landingPageEnabled" class="display-inlineblock marginleft-25">
                            <input class="input checkbox mr-1" type="checkbox" [(ngModel)]="product.showOnLandingPage"> <span translate>Auf Landing-Page anzeigen</span>
                        </div>
                        <div *ngIf="product.showOnLandingPage" class="display-inlineblock marginleft-25">
                            <input class="input small mr-1" type="number" [(ngModel)]="product.landingPageOrder"> <span translate>Position</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="spacer-10"></div>
                <div for="input-description" class="label" translate>Beschreibung</div>
                <app-md-editor (valueChanged)="onProductDescriptionChanged($event)" [inputValue]="product.description"></app-md-editor>

                <div for="input-description" class="label" translate>Produktbeschreibung auf Rechnung</div>
                <div class="spacer-10"></div>
                <textarea id="input-description" class="input wide full-width" [(ngModel)]="product.invoiceDescription" type="text" rows="2"></textarea>
            </div>
        </div>
        <div class="spacer-25"></div>

        <div class="row">
            <div class="col-12 col-lg-6">

                <div class="">
                    <div class="label display-inlineblock" translate>Zahlung</div>
                    <div class="icon-button marginleft-10" [tooltip]="'Soll einmalig oder in regelmäßigen Abständen für das Produkt gezahlt werden?' | translate">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                        </svg>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="dual-switch">
                        <div class="option {{!product.recurring ? 'active' : ''}}" (click)="onSetRecurring(false)" translate>Einmalig</div>
                        <div class="option {{product.recurring ? 'active' : ''}}" (click)="onSetRecurring(true)" translate>Wiederkehrend</div>
                    </div>
                </div>

                <div class="spacer-25"></div>
                <div>
                    <div *ngIf="product.recurring" class="display-inlineblock">
                        <div class="label display-inlineblock" translate>Zahlungsintervall</div>
                        <div class="icon-button marginleft-10" [tooltip]="'In welchem Abstand soll für das Produkt gezahlt werden?' | translate">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                            </svg>
                        </div>
                        <div class="spacer-10"></div>
                        <div class="">
                            <div class="display-inlineblock">
                                <input id="input-duration" required class="input form-control display-inlineblock {{product.duration > 0 ? '' : 'invalid'}}" [(ngModel)]="product.duration" type="number">
                            </div>
                            <div class="durationunit-selection-wrapper">
                                <div class="d-inline-block wrapper-selection">
                                    <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownTrigger" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{product.getPrintableDurationUnit(translate)}}</button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownProduct">
                                        <button class="dropdown-item" type="button" (click)="onSetDurationUnit(ProductDurationUnit.DAY)" translate>Tage</button>
                                        <button class="dropdown-item" type="button" (click)="onSetDurationUnit(ProductDurationUnit.WEEK)" translate>Wochen</button>
                                        <button class="dropdown-item" type="button" (click)="onSetDurationUnit(ProductDurationUnit.MONTH)" translate>Monate</button>
                                        <button class="dropdown-item" type="button" (click)="onSetDurationUnit(ProductDurationUnit.YEAR)" translate>Jahre</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="display-inlineblock">
                        <label for="input-price" class="label display-block">Brutto-Preis</label>
                        <input id="input-price" required class="input form-control wide display-inlineblock {{priceInvalid ? 'invalid' : ''}}" [ngModel]="product.getPrintablePrice()" (input)="onPriceChanged($event.target.value)" placeholder="0"> <span class="display-inlineblock marginleft-10">
                        <div class="display-inlineblock wrapper-selection">
                            <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownTrigger" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{PaymentComponent.getPrintableCurrency(getCurrency())}}</button>
                            <div class="dropdown-menu currency-dropdown">
                                <div class="dropdown-item link {{product?.currency == null ? 'active' : ''}}" (click)="onCurrencySelected(null)">
                                    <div class="display-inline"><span translate>Standard</span> ({{PaymentComponent.getPrintableCurrency(paymentSettings?.currency)}})</div>
                                </div>
                                <div class="dropdown-item link {{product?.currency == 'eur' ? 'active' : ''}}" (click)="onCurrencySelected('eur')">
                                    <div class="display-inline">{{PaymentComponent.getPrintableCurrency('eur')}}</div>
                                </div>
                                <div class="dropdown-item link {{product?.currency == 'chf' ? 'active' : ''}}" (click)="onCurrencySelected('chf')">
                                    <div class="display-inline">{{PaymentComponent.getPrintableCurrency('chf')}}</div>
                                </div>
                            </div>
                        </div>
                        {{product.recurring ? 'je ' + product.getPrintableDuration(translate, 1) : ('einmalig' | translate)}}</span>
                    </div>
                    <div class="marginleft-10">
                        <input class="input checkbox" type="checkbox" [(ngModel)]="overwriteVatRate" (change)="onOverwriteVatRateChanged($event.currentTarget.checked)">
                        <div class="label display-inline" translate> Abweichender MwSt.-Satz</div>
                    </div>
                    <div *ngIf="overwriteVatRate" class="marginleft-10">
                        <div class="spacer-10"></div>
                        <input id="input-name" class="input small" [(ngModel)]="product.vatRate" placeholder="19" required type="number"> %
                    </div>
                    <ng-container *ngIf="!product.recurring && product.licenceType">
                        <div class="spacer-5"></div>
                        <div class="marginleft-10">
                            <input class="input checkbox" type="checkbox" [(ngModel)]="product.chargeInitialSetupFeeOnPurchase" (change)="onChargeInitialSetupFeeOnPurchaseChanged($event.currentTarget.checked)">
                            <div class="label display-inline"> Direkt bei Kaufabschluss einziehen (falls das Produkt später startet)</div>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="product.recurring">
                    <div class="spacer-25"></div>
                    <div class="display-inlineblock">
                        <label *ngIf="product.chargeInitialSetupFeeOnPurchase" for="input-price" class="label display-block" translate>Brutto-Preis Optionale Einmalgebühr</label>
                        <label *ngIf="!product.chargeInitialSetupFeeOnPurchase" for="input-price" class="label display-block" translate>Brutto-Preis Optionale Einmalgebühr/-rabatt</label>
                        <input id="input-price" class="input form-control wide display-inlineblock {{initialSetupFeeInvalid ? 'invalid' : ''}}" [ngModel]="product.getPrintableInitialSetupFee()" (input)="onInitialSetupFeeChanged($event.target.value)" placeholder=""> <span class="display-inlineblock marginleft-10"> {{PaymentComponent.getPrintableCurrency(getCurrency())}}</span>
                        <div class="icon-button marginleft-10" tooltip="Ein negativer Betrag führt zu einem Rabatt bei der ersten Zahlung.">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                            </svg>
                        </div>
                    </div>
                    <div class="spacer-5"></div>
                    <div class="marginleft-10">
                        <input class="input checkbox" type="checkbox" [(ngModel)]="product.chargeInitialSetupFeeOnPurchase" (change)="onChargeInitialSetupFeeOnPurchaseChanged($event.currentTarget.checked)">
                        <div class="label display-inline"> Direkt bei Kaufabschluss einziehen</div>
                    </div>
                </div>

                <div class="spacer-25"></div>

                <div class="">
                    <div class="label display-inlineblock" translate>Lizenz</div>
                    <div class="icon-button marginleft-10" [tooltip]="'Soll beim Kauf eine Lizenz für die App erstellt werden?' | translate">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                        </svg>
                    </div>
                    <div class="spacer-10"></div>
                    <div class="dual-switch">
                        <div class="option {{product.licenceType != null ? 'active' : ''}}" (click)="onSetLicenceProduct()" translate>Coaching (mit App)</div>
                        <div class="option {{product.licenceType == null ? 'active' : ''}}" (click)="onSetNoLicenceProduct()" translate>Keine (ohne App)</div>
                    </div>
                </div>

                <div class="spacer-25"></div>

                <div class="display-flex" *ngIf="product.recurring || product.licenceType != null">
                    <div class="display-inlineblock">
                        <div class="">
                            <div for="input-duration" class="label display-inlineblock" translate>Laufzeit</div>
                            <div class="icon-button marginleft-10" [tooltip]="'Soll es eine Mindestlaufzeit (fest) geben oder soll die Laufzeit zu jeder Zeit kündbar sein (unbegrenzt)?' | translate">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                </svg>
                            </div>
                            <div class="spacer-10"></div>
                            <div class="dual-switch">
                                <div class="option {{!product.hasFixedDuration() ? 'active' : ''}}" (click)="onSetDurationFixed(false)" translate>Unbegrenzt</div>
                                <div class="option {{product.hasFixedDuration() ? 'active' : ''}}" (click)="onSetDurationFixed(true)" translate>Fest</div>
                                <!--div *ngIf="!product.recurring" class="option {{product.licenceType == null ? 'active' : ''}}" (click)="onSetNoDuration()">Keine</div-->
                            </div>
                        </div>
                        <div *ngIf="!product.recurring && product.hasFixedDuration()" class="">
                            <div class="display-inlineblock">
                                <input id="input-duration" required class="input form-control display-inlineblock" [(ngModel)]="product.duration" type="number">
                            </div>
                            <div class="durationunit-selection-wrapper">
                                <div class="d-inline-block wrapper-selection">
                                    <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownTrigger" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{product.getPrintableDurationUnit(translate)}}</button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownProduct">
                                        <button class="dropdown-item" type="button" (click)="onSetDurationUnit(ProductDurationUnit.DAY)" translate>Tage</button>
                                        <button class="dropdown-item" type="button" (click)="onSetDurationUnit(ProductDurationUnit.WEEK)" translate>Wochen</button>
                                        <button class="dropdown-item" type="button" (click)="onSetDurationUnit(ProductDurationUnit.MONTH)" translate>Monate</button>
                                        <button class="dropdown-item" type="button" (click)="onSetDurationUnit(ProductDurationUnit.YEAR)" translate>Jahre</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="product.recurring && product.hasFixedDuration()" class="">
                            <div class="display-inlineblock">
                                <input id="input-duration" required class="input form-control display-inlineblock" [(ngModel)]="product.durationMultiplier" type="number"> Mal = {{product.getPrintableTotalRuntime(translate)}}
                            </div>
                        </div>
                    </div>
                    <div class="display-inlineblock marginleft-25">
                        <div *ngIf="product.recurring && product.hasFixedDuration()">
                            <div class="">
                                <div for="input-duration" class="label display-inlineblock" translate>Verlängerung</div>
                                <div class="icon-button marginleft-10" [tooltip]="'Soll die Laufzeit nach der Mindestlaufzeit automatisch verlängern?' | translate">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                    </svg>
                                </div>
                                <div class="spacer-10"></div>
                                <div class="dual-switch">
                                    <div class="option {{!product.autoRenew ? 'active' : ''}}" (click)="onSetAutoRenew(false)" translate>Keine</div>
                                    <div class="option {{product.autoRenew ? 'active' : ''}}" (click)="onSetAutoRenew(true)" translate>Automatisch</div>
                                </div>
                            </div>
                            <div *ngIf="!product.duration" class="display-inlineblock label marginleft-25" translate>Automatisch nur bei fester Laufzeit möglich</div>
                            <div *ngIf="product.autoRenew" class="">
                                <div class="display-inlineblock">
                                    <input id="input-cancelationperiod" required class="input form-control display-inlineblock {{isCancelationPeriodValid() ? '' : 'invalid'}}" [(ngModel)]="product.cancelationPeriod" type="number"> <span translate>Tage Kündigungsfrist</span>
                                </div>
                                <div *ngIf="!isCancelationPeriodValid()" class="label padding-10" translate>
                                    Die Kündigungsfrist muss mindestens 1 Tag betragen und kleiner als die Laufzeit sein.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="product.licenceType">
                    <div class="spacer-15"></div>
                    <div class="">
                        <div class="display-inlineblock">
                            <div class="label" translate>Start</div>
                            <div class="spacer-10"></div>
                            <div class="dual-switch">
                                <div class="option {{product.startDate == null ? 'active' : ''}}" (click)="onSetStartDate(null)" translate>Sofort</div>
                                <div class="option {{product.startDate != null ? 'active' : ''}}" (click)="onEnableStartDate()" translate>Ab Datum</div>
                            </div>
                        </div>
                        <div class="display-inlineblock">
                            <div *ngIf="product.startDate != null" class="display-inlineblock marginleft-25">
                                <div class="label" for="datetime" translate>Startdatum</div>
                                <div class="spacer-10"></div>
                                <input type="date" required class="form-datetime-input" id="datetime" [ngModel]="product.startDate | date:'yyyy-MM-dd'" (input)="onSetStartDate($event.target.value)">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="product.licenceType == 'Coaching'" class="col-12 col-lg-6">
                <span translate>Voreinstellungen</span>

                <div class="">
                    <div class="label" translate>Gruppen zuweisen</div>
                    <div class="spacer-10"></div>
                    <div class="d-inline-block wrapper-selection">
                        <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownTrigger" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getAssignedGroupNames() || ('Keine Gruppe' | translate)}}</button>
                        <div class="dropdown-menu checkin-dropdown" aria-labelledby="dropdownBodyDataGraphTimeRange">
                            <div *ngFor="let group of userService.getClientGroups()" class="dropdown-item link" (click)="onAssignToClientGroup(group);$event.stopPropagation()">
                                <input class="input checkbox" type="checkbox" [checked]="product?.assignedClientGroups.includes(group)"><div class="display-inline">{{group}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="spacer-10"></div>

                <div class="spacer-10"></div>
                <div class="">
                    <div class="label" translate>Check-Ins für Anamnese</div>
                    <div class="spacer-10"></div>
                    <div class="d-inline-block wrapper-selection">
                        <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownTrigger" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getSelectedOnboardingQuestionaires() || ('Kein Formular' | translate)}}</button>
                        <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
                            <div *ngFor="let questionaire of availableQuestionaires" class="dropdown-item link" (click)="onPreselectQuestionaire(questionaire)">
                                <input class="input checkbox" type="checkbox" [checked]="product?.onboardingQuestionaireIds.includes(questionaire.id)"><div class="display-inline">{{questionaire.internalName}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="spacer-10"></div>

                <div class="">
                    <div class="label" translate>Metriken zuweisen</div>
                    <div class="spacer-10"></div>
                    {{getPrintableAssignedMetrics() || ('Keine Metriken' | translate)}}

                    <button class="icon-button marginleft-25" (click)="onChangeAssignedMetrics()" translate>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                        </svg>
                        Anpassen
                    </button>
                    <button *ngIf="product?.assignedMetricIds?.length > 0" class="icon-button marginleft-25" (click)="onClearAssignedMetrics()" translate>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/>
                        </svg>
                        Leeren
                    </button>
                </div>
                <div class="spacer-25"></div>



                <div class="recipe-sharing-settings display-inlineblock">
                    <div class="recipesharing-toggle toggle-wrapper display-inlineblock marginright-25" [tooltip]="'Blende alle Kalorien- und Ernährungs-relevanten Zahlen in der App aus.' | translate">
                        <div class="toggle {{product.hideNutritionValues ? 'on' : ''}}" (click)="onHideNutritionValuesChanged()">
                            <div class="handle"></div>
                        </div>
                        <div class="text" translate>
                            Kalorien/Nährstoffe in der App ausblenden
                        </div>
                    </div>
                </div>
                <div class="spacer-10"></div>

                <div class="recipe-sharing-settings display-inlineblock">
                    <div class="recipesharing-toggle toggle-wrapper display-inlineblock marginright-25" [tooltip]="'Blende den Chat in der App aus.' | translate">
                        <div class="toggle {{product.hideChat ? 'on' : ''}}" (click)="onHideChatChanged()">
                            <div class="handle"></div>
                        </div>
                        <div class="text" translate>
                            Chat deaktivieren
                        </div>
                    </div>
                </div>

                <div class="spacer-25"></div>
            </div>


        </div>




        <div class="spacer-10"></div>



    </ng-container>
</div>
