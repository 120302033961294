<ng-container *ngIf="isProductAvailable()">
    <app-checkout-view [setProduct]="product" [setPaymentSettings]="paymentSettings" [setIsIframe]="true"></app-checkout-view>
</ng-container>
<ng-container *ngIf="product && !isProductAvailable()">
    <div class="product-wrapper layout-box padding-0">
        <div class="product-content-wrapper">
            <div class="spacer-25"></div>
            <div class="bold" translate>Dieses Produkt ist leider nicht mehr verfügbar.</div>
            <div class="spacer-25"></div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!product">
    <div class="product-wrapper layout-box padding-0 textalign-center">
        <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
    </div>
</ng-container>