import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Metric, MetricGroup } from '../model/metric.model';
import { UtilityService } from '../services/utility.service';
import { MetricsSelectionDialogComponent } from '../metrics-selection-dialog/metrics-selection-dialog.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FirestoreService } from '../services/firestore.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-metric-group-editor-dialog',
  templateUrl: './metric-group-editor-dialog.component.html',
  styleUrls: ['./metric-group-editor-dialog.component.css']
})
export class MetricGroupEditorDialogComponent implements OnInit {

  public selectedMetricGroup: MetricGroup;

  constructor(public dialogRef: MatDialogRef<MetricGroupEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {metricGroup: MetricGroup}, public dialog: MatDialog, public utilityService: UtilityService, private userService: FirestoreService, private spinner: NgxSpinnerService, private toastr: ToastrService, public translate: TranslateService) 
  {
    this.selectedMetricGroup = data.metricGroup;
  }

  ngOnInit(): void {
  }

  doBeforeUnload(){

  }

  async saveGroup(){
    try{
      this.spinner.show("metricSetSpinner");
      if(this.selectedMetricGroup.id == null) {
        await this.userService.saveMetricGroup(this.selectedMetricGroup);
      }
      else {
        await this.userService.updateMetricGroup(this.selectedMetricGroup);
      }
      this.dialogRef.close({updateMetricGroups: true});
    }
    catch(error){
      console.log(error) 
      this.toastr.error(this.translate.instant("Es ist ein unbekannter Fehler aufgetreten."), this.translate.instant("Speichern fehlgeschlagen"),  {
        positionClass: 'toast-bottom-center'
      });
    }
    finally{
      this.spinner.hide("metricSetSpinner");
    }
  }

  deleteGroup(){
    try{
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { message: this.translate.instant('Möchtest du die Gruppe wirklich löschen? Alle Nutzer:innen werden aus der Gruppe entfernt.'), title: this.translate.instant('Gruppe löschen') },
      });
      dialogRef.afterClosed().subscribe(async result => {
        this.spinner.show("metricSetSpinner");
        if (result) {
          await this.userService.deleteMetricGroup(this.selectedMetricGroup);
          this.dialogRef.close({updateMetricGroups: true});
        }
      });
    }
    catch(error){
      console.log(error)
      this.toastr.error(this.translate.instant("Es ist ein unbekannter Fehler aufgetreten."), this.translate.instant("Speichern fehlgeschlagen"),  {
        positionClass: 'toast-bottom-center'
      });
    }
    finally{
      this.spinner.hide("metricSetSpinner");
    }
  }
  cancelEditGroup(){
    this.dialogRef.close({updateMetricGroups: false});
  }
  addMetric(){
    // let selectable = this.userService?.metrics.filter(x => this.questionaire.questions.filter(s => s.metricId == x.metricId).length == 0);
    const dialogRef = this.dialog.open(MetricsSelectionDialogComponent, { data: { notAvailableMetricIds: this.selectedMetricGroup.metricIds, canShowMetricSets: false}, width: '1000px'})
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        console.log(result)
        if(result.shouldTake) {
          result.metrics.forEach(metric => {
            if(!this.selectedMetricGroup.metricIds.includes(metric.id)){
              this.selectedMetricGroup.metricIds.push(metric.id);
              this.selectedMetricGroup.metrics.push(metric);
            }
          });
          // this.questionaire.questions.push()
        }
      }
    });
  }

  onRemoveAssignedMetric(metric: Metric){
    const index = this.selectedMetricGroup.metrics.indexOf(metric, 0);
    if (index > -1) {
      this.selectedMetricGroup.metrics.splice(index, 1);
    }
    const idIndex = this.selectedMetricGroup.metricIds.indexOf(metric.id, 0);
    if (index > -1) {
      this.selectedMetricGroup.metricIds.splice(idIndex, 1);
    }
  }

  dropAssignedMetric(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedMetricGroup.metricIds, event.previousIndex, event.currentIndex);
    if(this.selectedMetricGroup.metrics.length == this.selectedMetricGroup.metricIds.length){
      moveItemInArray(this.selectedMetricGroup.metrics, event.previousIndex, event.currentIndex);
    }
  }


}
