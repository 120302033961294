import { FcmService } from '../../services/fcm.service';
import { Component, OnInit, NgZone, AfterViewInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';

import { FirestoreService } from '../../services/firestore.service';
import { UtilityService } from '../../services/utility.service';
import { NavbarService } from '../../services/navbar.service';
import { ChatService } from '../../services/chat.service';
import { User } from '../../model/user.model';
import { Chatmessage } from '../../model/chatmessage.model';
import { BroadcastChat, Chat } from '../../model/chat.model';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import {MatDialog } from '@angular/material/dialog';
import { Licence } from '../../model/lid.model';
import { environment } from 'src/environments/environment';
import { LicenceConnectionRequest } from '../../model/licence-connection-request';
import { DashboardBaseComponent } from '../dashboard-base/dashboard-base.component';

@Component({
  selector: 'app-dashboard-v1',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends DashboardBaseComponent implements OnInit {

  public loggedIn: boolean;

  public showHelpDialog = false
  public visibleVideoNumber = null
  public selectedClientGroup: string = null

  constructor(public chatService: ChatService, public authService: AuthService, public router: Router, public userService: FirestoreService, public utilityService: UtilityService, public navService: NavbarService, public dialog: MatDialog, public fcmService: FcmService) {
    super(chatService, authService, router, userService, utilityService, navService, dialog, fcmService)
  }

  ngOnInit(): void {
    this.loggedIn = this.authService.isLoggedIn();
  }

  onConnectNewClient() {
    this.router.navigateByUrl('licence')
  }
  
  static delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }  


  hasUnpinnedClients() {
    var hasUnpinned = false;
    this.userService.getAccessibleClients().forEach(c => {
      if (!this.userService.getLoggedInUser().portalSettingsCoach?.dashboardUids.includes(c.uid)) hasUnpinned = true;
    })
    return hasUnpinned;
  }

  getOptions() {
    var options = [];
    this.userService.getAccessibleClients().forEach(c => {
      if (!this.userService.getLoggedInUser().portalSettingsCoach?.dashboardUids.includes(c.uid)){
        options.push(c);
      }
    });
    return options;
  }

  

  deleteUserFromDashboard(uid: string) {
    this.userService.deleteUserFromDashboard(uid);
  }

  onAssignNutritionalGoal(uid: string) {
    this.router.navigateByUrl('client/' + uid + '?tab=settings')
  }

  
  onHideCreateSubscriptionHint() {
    this.userService.getLoggedInUser().portalSettingsLicenceHolder.hideCreateSubscriptionHint = true
    this.userService.updatePortalSettingsForLicenceHolder(this.userService.getLoggedInUser())
  }
  onHideTutorialsHint() {
    this.userService.getLoggedInUser().portalSettingsCoach.hideTutorialsHint = true
    this.userService.updatePortalSettingsForCoach(this.userService.getLoggedInUser())
  }
  onHideCoachAppHint() {
    this.userService.getLoggedInUser().portalSettingsCoach.hideCoachAppHint = true
    this.userService.updatePortalSettingsForCoach(this.userService.getLoggedInUser())
  }

  onAddGroupToDashboard(group: string) {
    this.userService.getLoggedInUser().portalSettingsCoach.dashboardClientGroups.push(group)
    this.userService.updateDashboardItems(this.userService.getLoggedInUser())
  }
  onAddClientToDashboard(client: User) {
      this.userService.getLoggedInUser().portalSettingsCoach.dashboardUids.push(client.uid)
      this.userService.updateDashboardItems(this.userService.getLoggedInUser())
  }
  onRemoveGroupFromDashboard(group: string) {
    if (this.userService.getLoggedInUser().portalSettingsCoach.dashboardClientGroups.includes(group)) {
      this.userService.getLoggedInUser().portalSettingsCoach.dashboardClientGroups.splice(this.userService.getLoggedInUser().portalSettingsCoach.dashboardClientGroups.indexOf(group), 1)
      this.userService.updateDashboardItems(this.userService.getLoggedInUser())
    }
  }
  onClientGroupSelected(group: string) {
    this.selectedClientGroup = group
  }
  getClientsForSelectedGroup(): User[] {
     if (!this.selectedClientGroup) return this.userService.getAccessibleClients()
     return this.userService.getAccessibleClients().filter(client => client.metadataUser?.assignedClientGroups.includes(this.selectedClientGroup))
  }
  getClientsForGroup(group: string): User[] {
    return this.userService.getAccessibleClients().filter(client => client.metadataUser?.assignedClientGroups.includes(group))
  }
  onUnselectClientGroup() {
    this.selectedClientGroup = null
  }

  getExpiringLicencesThreshold() {
    return this.userService.expiringLicencesThreshold
  }
  updateExpiringLicencesThreshold(threshold: number) {
    this.userService.expiringLicencesThreshold = threshold
    this.userService.refreshExpiringLicences()
  }
  
  onSendBroadcastMessageToClientGroup(clientGroup: string) {
    let broadcastChat: BroadcastChat = new BroadcastChat();
    broadcastChat.selectedClientGroups.push(clientGroup)
    this.chatService.openChat(broadcastChat)
  }

  
}
