<div class="row" *ngIf="user">
    <div class="col-12" *ngIf="entryConfigs.length < totalElements">
        <div class="icon-button display-inlineblock marginleft-10 marginright-10" mat-button [matMenuTriggerFor]="menu">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
            Statistik hinzufügen
        </div>
        <mat-menu #menu="matMenu">
            <button mat-menu-item *ngIf="!isConfigEnabled('checkin-table-view')" (click)="onAddConfigEntry('checkin-table-view')">Daily Check-Übersicht (Tabelle)</button>
            <button mat-menu-item *ngIf="!isConfigEnabled('training-history')" (click)="onAddConfigEntry('training-history')">Trainingskalender</button>
            <button mat-menu-item *ngIf="!isConfigEnabled('completed-questionaires-overview')" (click)="onAddConfigEntry('completed-questionaires-overview')">Check-In-Übersicht (Liste)</button>
            <button mat-menu-item *ngIf="!isConfigEnabled('body-data')" (click)="onAddConfigEntry('body-data')">Daily Check/Metrik-Statistik (Graph)</button>
            <button mat-menu-item *ngIf="!isConfigEnabled('training-graph')" (click)="onAddConfigEntry('training-graph')">Trainingsstatistik (Graph)</button>
            <button mat-menu-item *ngIf="!isConfigEnabled('nutrition-graph')" (click)="onAddConfigEntry('nutrition-graph')">Ernährungsstatistik (Graph)</button>
        </mat-menu>
    </div>
    <ng-container *ngFor="let config of entryConfigs; index as index">

        <div class="config-tile col-12 {{getColSize(config)}}">

            <div *ngIf="config.itemId == 'checkin-table-view'">
                <app-checkin-table-view [User]="user"></app-checkin-table-view>
            </div>

            <div *ngIf="config.itemId == 'training-history'">
                <ng-container *ngIf="!user.loadedTrainingPlans; else trainingHistoryTemplate">
                    <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
                </ng-container>
                <ng-template #trainingHistoryTemplate>
                    <app-training-history [User]="user"></app-training-history>
                </ng-template>
            </div>

            <div *ngIf="config.itemId == 'completed-questionaires-overview'">
                <div class="layout-box adjust-mobile">
                    <app-completed-questionaires-overview [user]="user" [relevantDailyConditions]="user.dailyConditions"></app-completed-questionaires-overview>
                </div>
            </div>

            <div *ngIf="config.itemId == 'body-data'">
                <app-body-data [selectedUserUid]="user.uid"></app-body-data>
            </div>

            <div *ngIf="config.itemId == 'training-graph'">
                <div class="layout-box adjust-mobile">
                    <h3>Trainingsstatistik</h3>
                    <app-training-graph [User]="user"></app-training-graph>
                </div>
            </div>

            <div *ngIf="config.itemId == 'nutrition-graph' && isNutritionEnabled()">
                <div class="layout-box">
                    <app-nutrition-graph [User]="user"></app-nutrition-graph>
                </div>
            </div>

            <div class="tile-actions">
                <button mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                    </svg>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="onRemoveConfig(config)">Entfernen</button>
                    <button *ngIf="index > 0" mat-menu-item (click)="onMoveConfigUp(config)">Nach vorne schieben</button>
                    <button *ngIf="index < entryConfigs.length - 1" mat-menu-item (click)="onMoveConfigDown(config)">Nach hinten schieben</button>
                    <button mat-menu-item [matMenuTriggerFor]="changeSize">Größe ändern</button>
                </mat-menu>
                <mat-menu #changeSize="matMenu">
                    <button mat-menu-item (click)="onChangeConfigSize(config, 'full')">1/1</button>
                    <button mat-menu-item (click)="onChangeConfigSize(config, 'two-thirds')">2/3</button>
                    <button mat-menu-item (click)="onChangeConfigSize(config, 'half')">1/2</button>
                    <button mat-menu-item (click)="onChangeConfigSize(config, 'one-third')">1/3</button>
                </mat-menu>
            </div>

        </div>

    </ng-container>
