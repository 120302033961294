<button *ngIf="User == null" class="button-filled small create-Exercise" (click)="onCreateQuestionaire()">Check-In erstellen</button>
<div *ngIf="User == null" class="spacer-25"></div>
<div class=" {{User != null ? 'block-height' : ''}}">
    <div class="row">
        <div class="col-12">
            <div class="spacer-10"></div>
            <input id="search-input" class="input wide" (input)="onSearchInputChanged($event.target.value)" placeholder="Check-Ins durchsuchen" type="text">
            <div class="icon-button deletesearch-icon delete-recipesearch" (click)="onDeleteSearchInput()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>
            <div class="spacer-25"></div>
        </div>
    </div>
    <div *ngIf="allQuestionaires == null">
        <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
    </div>
    <div class="row layout-box sub-box link" *ngFor="let questionaire of filteredQuestionaires" (click)="onQuestionaireSelected(questionaire)">
        <div class="col padding-0">
            <b>{{questionaire.internalName}}</b>
            <div>{{questionaire.name}}</div>
        </div>
        <div *ngIf="environment.firebaseProjectId == 'traindoo-app'" class="col only-desktop">
            <div class="row">
                <div class="col">
                    {{questionaire.getPrintableType()}}
                </div>
            </div>
        </div>
        <div *ngIf="environment.firebaseProjectId != 'traindoo-app'" class="col only-desktop">
            <div class="row">
                <div class="col">
                    {{getTriggerString(questionaire)}}
                    <div *ngIf="questionaire.nextExecutionDate" class="label display-block">Nächste Ausführung: {{questionaire.nextExecutionDate?.asDateTimeFormatedString()}}</div>
                </div>
            </div>
        </div>
        <div *ngIf="environment.firebaseProjectId != 'traindoo-app'" class="col only-desktop">
            <div class="row">
                <div class="col">
                    {{getRecipientsString(questionaire)}}
                    <div class="label display-block">{{getRecipientsCount(questionaire)}}</div>
                </div>
            </div>
        </div>
        <!-- <div class="col send-button">
        </div> -->
        <div class="col send-button">
            <i class="bi bi-files link" (click)="duplicateQuestionaire(questionaire);$event.stopPropagation()" tooltip="Check-In duplizieren" style="font-size: 20px;"></i>
            <i *ngIf="environment.firebaseProjectId != 'traindoo-app' || questionaire.type == 'manually' || questionaire.type == 'onboarding'" class="bi bi-send link marginleft-25" (click)="onSendQuestionaire(questionaire);$event.stopPropagation()" tooltip="Check-In direkt{{sendToName}} senden" style="font-size: 20px;"></i>
        </div>
    </div>
    <div class="margin-10 label">{{filteredQuestionaires?.length}} Check-Ins gefunden.</div>
</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "#4AE6D7" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>