import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-custom-settings-dialog',
  templateUrl: './custom-settings-dialog.component.html',
  styleUrls: ['./custom-settings-dialog.component.css']
})
export class CustomSettingsDialogComponent {

  public title: string = "";
  public availableSettings: string[] = [];
  public selectedSettings: string[] = [];
  public minimumSelectedItems: number = 0;
  public cancelButtonText: string = this.translate.instant("Abbrechen");
  public submitButtonText: string = this.translate.instant("Ok");

  constructor(public dialogRef: MatDialogRef<CustomSettingsDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { title: string, availableSettings: string[], selectedSettings: string[], minimumSelectedItems: number, cancelButtonText: string, submitButtonText: string }, public dialog: MatDialog, private translate: TranslateService)
  {
    this.title = data.title;
    this.availableSettings = data.availableSettings;
    this.selectedSettings = data.selectedSettings || [];
    this.minimumSelectedItems = data.minimumSelectedItems || 0;
    this.cancelButtonText = data.cancelButtonText;
    this.submitButtonText = data.submitButtonText;
  }

  onSelectSetting(setting: string){
    let index = this.selectedSettings.indexOf(setting);
    if(index >= 0){
      this.selectedSettings.splice(index, 1);
    }
    else {
      this.selectedSettings.push(setting)
    }
  }

  canSubmit(){
    if(this.selectedSettings.length < this.minimumSelectedItems){
      return false;
    }
    return true;
  }

  onCancel(){
    this.dialogRef.close({shouldTake: false});
  }

  onSubmit(){
    this.dialogRef.close({shouldTake: true, selectedSettings: this.selectedSettings})
  }
}
