import {Component, OnInit} from '@angular/core';
import {ProductPurchase} from "../../../model/product-purchase.model";
import {
  ActivePurchasesStatistic,
  ActivePurchasesStatisticTooltipInfo, PaymentAnalyticsService,
  DropdownItem,
  GraphTimeRangeOptions, Statistic
} from "../../../services/payment-analytics.service";
import {
  AnalyticsConfigMenuItem
} from "../analytics-config-menu/analytics-config-menu-item";
import * as moment from "moment/moment";

@Component({
  selector: 'app-graph-active-sales',
  templateUrl: './graph-active-sales.component.html',
  styleUrls: ['./graph-active-sales.component.css']
})
export class GraphActiveSalesComponent extends AnalyticsConfigMenuItem implements OnInit {
  private activePurchases: ProductPurchase[] = [];

  public graphTimeRanges: DropdownItem[] = []
  public selectedGraphTimeRange: DropdownItem = null;
  public activePurchasesStatistics: ActivePurchasesStatistic[] = [];
  public retentionHash: Map<string, ActivePurchasesStatisticTooltipInfo> = new Map();
  public month: moment.Moment = moment();

  customizeTooltip = (arg)=> {
    let argument = arg.argument;
    if(this.selectedGraphTimeRange.id === GraphTimeRangeOptions.THIS_MONTH || this.selectedGraphTimeRange.id === GraphTimeRangeOptions.LAST_MONTH) {
      argument = "Tag: " + argument;
    }
    if (arg.seriesName === "Anzahl aktiver Abos") {
      return {
        text: `
          ${argument}
          Anzahl aktiver Abos: ${arg.valueText}
          `
      };
    }
    const info = this.retentionHash.get(arg.argument);
    const development = info.plus - info.minus;
    return {
      text: `
        ${argument}
        <b>Entwicklung: ${development > 0 ? '+'+development : development}</b>
        Neukunden: +${info.plus}
        Abwanderungen: -${info.minus}
        `
    };
  };

  constructor(private paymentAnalyticsService: PaymentAnalyticsService) {
    super();
  }

  ngOnInit(): void {
    this.init();
  }

  private init() {
    this.graphTimeRanges = this.paymentAnalyticsService.getGraphTimeRanges();
    this.selectedGraphTimeRange = this.statisticConfig.selectedTimeRange ?? this.graphTimeRanges[0];
    this.activePurchases = this.paymentAnalyticsService.getCoachingPurchases();
    this.onGraphTimeRangeChanged(this.selectedGraphTimeRange);
  }

  onGraphTimeRangeChanged(selection: DropdownItem) {
    this.selectedGraphTimeRange = this.statisticConfig.selectedTimeRange = selection;
    this.onConfigSettingChanged();
    const possibleDates = this.activePurchases.filter(purchase => purchase.startDate).map(purchase => purchase.startDate);
    const {
      startDate,
      endDate,
      statistics
    } = this.paymentAnalyticsService.calculateGraphTimeRange(selection, possibleDates);
    this.month = startDate;
    this.calculateActivePurchases(statistics);
  }

  private calculateActivePurchases(stats: Statistic[]) {
    const statistics: ActivePurchasesStatistic[] = stats.map(stat => {
      return {
        ...stat,
        activePurchases: 0,
        activePurchasesProjection: 0,
        newClients: 0,
        newClientsProjection: 0,
        lostClients: 0,
        lostClientsProjection: 0,
      }
    });

    statistics.forEach(statistic => {
      const statisticDate = statistic.date;

      const numberActivePurchases = this.activePurchases.filter(purchase => {
        const endDate = purchase.getEndDate();
        switch (this.selectedGraphTimeRange.id) {
          case GraphTimeRangeOptions.LAST_7_DAYS:
            return statisticDate.isBetween(purchase.startDate, endDate, "day", "[]");
          case GraphTimeRangeOptions.LAST_28_DAYS:
          case GraphTimeRangeOptions.LAST_12_WEEKS:
            return statisticDate.isBetween(purchase.startDate, endDate, "week", "[]");
          case GraphTimeRangeOptions.TOTAL:
            return statisticDate.isBetween(purchase.startDate, endDate, "month", "[]");
          case GraphTimeRangeOptions.LAST_MONTH:
            return statisticDate.isBetween(purchase.startDate, endDate, "day", "[]");
          case GraphTimeRangeOptions.LAST_QUARTER:
            return statisticDate.isBetween(purchase.startDate, endDate, "month", "[]");
          case GraphTimeRangeOptions.THIS_MONTH:
            return statisticDate.isBetween(purchase.startDate, endDate, "day", "[]");
          case GraphTimeRangeOptions.THIS_QUARTER:
            return statisticDate.isBetween(purchase.startDate, endDate, "month", "[]");
          case GraphTimeRangeOptions.FORECAST:
            return statisticDate.isBetween(purchase.startDate, endDate, "month", "[]");
          case GraphTimeRangeOptions.LAST_365_DAYS:
            return statisticDate.isBetween(purchase.startDate, endDate, "month", "[]");
          case GraphTimeRangeOptions.THIS_YEAR:
            return statisticDate.isBetween(purchase.startDate, endDate, "month", "[]");
          default:
            throw new Error(`The ID: ${this.selectedGraphTimeRange.id} has not been implemented yet!`);
        }
      }).length;

      const numberNewPurchases = this.activePurchases.filter(purchase => {
        const startDate = purchase.startDate.clone();
        switch (this.selectedGraphTimeRange.id) {
          case GraphTimeRangeOptions.LAST_7_DAYS:
            return statisticDate.isSame(startDate, "day");
          case GraphTimeRangeOptions.LAST_28_DAYS:
          case GraphTimeRangeOptions.LAST_12_WEEKS:
            return statisticDate.isSame(startDate, "week");
          case GraphTimeRangeOptions.TOTAL:
            return statisticDate.isSame(startDate, "month");
          case GraphTimeRangeOptions.LAST_MONTH:
            return statisticDate.isSame(startDate, "day");
          case GraphTimeRangeOptions.LAST_QUARTER:
            return statisticDate.isSame(startDate, "month");
          case GraphTimeRangeOptions.THIS_MONTH:
            return statisticDate.isSame(startDate, "day");
          case GraphTimeRangeOptions.THIS_QUARTER:
            return statisticDate.isSame(startDate, "month");
          case GraphTimeRangeOptions.FORECAST:
            return statisticDate.isSame(startDate, "month");
          case GraphTimeRangeOptions.LAST_365_DAYS:
            return statisticDate.isSame(startDate, "month");
          case GraphTimeRangeOptions.THIS_YEAR:
            return statisticDate.isSame(startDate, "month");
          default:
            throw new Error(`The ID: ${this.selectedGraphTimeRange.id} has not been implemented yet!`)
        }
      }).length;

      const numberLostPurchases = this.activePurchases.filter(purchase => {
        const endDate = purchase.getEndDate();
        switch (this.selectedGraphTimeRange.id) {
          case GraphTimeRangeOptions.LAST_7_DAYS:
            return statisticDate.clone().subtract(1, "days").isSame(endDate, "day");
          case GraphTimeRangeOptions.LAST_28_DAYS:
          case GraphTimeRangeOptions.LAST_12_WEEKS:
            return statisticDate.clone().subtract(1, "weeks").isSame(endDate, "week");
          case GraphTimeRangeOptions.TOTAL:
            return statisticDate.clone().subtract(1, "months").isSame(endDate, "month");
          case GraphTimeRangeOptions.LAST_MONTH:
            return statisticDate.clone().subtract(1, "days").isSame(endDate, "day");
          case GraphTimeRangeOptions.LAST_QUARTER:
            return statisticDate.clone().subtract(1, "months").isSame(endDate, "month");
          case GraphTimeRangeOptions.THIS_MONTH:
            return statisticDate.clone().subtract(1, "days").isSame(endDate, "day");
          case GraphTimeRangeOptions.THIS_QUARTER:
            return statisticDate.clone().subtract(1, "months").isSame(endDate, "month");
          case GraphTimeRangeOptions.FORECAST:
            return statisticDate.clone().subtract(1, "months").isSame(endDate, "month");
          case GraphTimeRangeOptions.LAST_365_DAYS:
            return statisticDate.clone().subtract(1, "months").isSame(endDate, "month");
          case GraphTimeRangeOptions.THIS_YEAR:
            return statisticDate.clone().subtract(1, "months").isSame(endDate, "month");
          default:
            throw new Error(`The ID: ${this.selectedGraphTimeRange.id} has not been implemented yet!`)
        }
      }).length;

      const {
        isNotProjection,
        isProjection
      } = this.paymentAnalyticsService.getProjection(this.selectedGraphTimeRange.id, statisticDate);

      if (isNotProjection && !isProjection) {
        statistic.activePurchases += numberActivePurchases;
        statistic.activePurchasesProjection = null;

        statistic.newClients += numberNewPurchases;
        statistic.newClientsProjection = null;
        statistic.lostClients -= numberLostPurchases;
        statistic.lostClientsProjection = null;
      }

      if (isProjection && !isNotProjection) {
        statistic.activePurchases = null;
        statistic.activePurchasesProjection += numberActivePurchases;

        statistic.newClients = null;
        statistic.newClientsProjection += numberNewPurchases;
        statistic.lostClients = null;
        statistic.lostClientsProjection -= numberLostPurchases;
      }

      if (isProjection && isNotProjection) {
        statistic.activePurchases += numberActivePurchases;
        statistic.activePurchasesProjection += numberActivePurchases;

        statistic.newClients += numberNewPurchases;
        statistic.newClientsProjection = null;
        statistic.lostClients -= numberLostPurchases;
        statistic.lostClientsProjection = null;
      }

      let hashValue: ActivePurchasesStatisticTooltipInfo = {
        plus: numberNewPurchases,
        minus: numberLostPurchases
      };
      this.retentionHash.set(statistic.timeArgument, hashValue);
    });

    this.activePurchasesStatistics = statistics;
  }

  protected readonly GraphTimeRangeOptions = GraphTimeRangeOptions;
}
