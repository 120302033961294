<div class="tile-actions">
  <button mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical"
      viewBox="0 0 16 16">
      <path
        d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
    </svg>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="onRemoveConfig()"><span translate>Entfernen</span></button>
    <button *ngIf="statisticConfig.position > 0" mat-menu-item (click)="onMoveConfigUp()"><span translate>Nach vorne
        schieben</span></button>
    <button *ngIf="statisticConfig.position < 2" mat-menu-item (click)="onMoveConfigDown()"><span translate>Nach hinten
        schieben</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="changeSize"><span translate>Größe ändern</span></button>
  </mat-menu>
  <mat-menu #changeSize="matMenu">
    <button mat-menu-item (click)="onChangeConfigSize(DashboardAnalyticsItemSize.FULL)">1/1</button>
    <button mat-menu-item (click)="onChangeConfigSize(DashboardAnalyticsItemSize.TWO_THIRDS)">2/3</button>
    <button mat-menu-item (click)="onChangeConfigSize(DashboardAnalyticsItemSize.HALF)">1/2</button>
    <button mat-menu-item (click)="onChangeConfigSize(DashboardAnalyticsItemSize.ONE_THIRD)">1/3</button>
  </mat-menu>
</div>