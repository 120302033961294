import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA,  MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Metric, MetricGroup } from '../model/metric.model';
import { FirestoreService } from '../services/firestore.service';
import { CustomMetricEditorDialogComponent } from '../custom-metric-editor-dialog/custom-metric-editor-dialog.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MetricGroupEditorDialogComponent } from '../metric-group-editor-dialog/metric-group-editor-dialog.component';
import { UtilityService } from '../services/utility.service';
import { EventLogService } from '../services/event-log.service';
import { FilterObject } from '../filter-selection-dropdown/filter-selection-dropdown.component';
import { environment } from 'src/environments/environment';
import { LanguageDictionary } from '../model/languagedictionary.model';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-metrics-selection-dialog',
  templateUrl: './metrics-selection-dialog.component.html',
  styleUrls: ['./metrics-selection-dialog.component.css']
})
export class MetricsSelectionDialogComponent implements OnInit {

  public environtment = environment

  public selectedLanguage = "de";

  public selectableMetrics: SelectableItem<Metric>[] = [];
  public filteredSelectableMetrics: SelectableItem<Metric>[] = [];

  public selectableMetricGroups: SelectableItem<MetricGroup>[];
  public filteredSelectableMetricGroups: SelectableItem<MetricGroup>[] = [];
  
  public showMetrics: boolean = true;
  public canShowMetricSets: boolean = true;

  public availableCreators: FilterObject[] = [
    new FilterObject(new LanguageDictionary<string>('Du', 'You', 'private'), false),
    new FilterObject(new LanguageDictionary<string>(environment.isWhitelabel ? 'Preset' : environment.brandName, environment.isWhitelabel ? 'Preset' : environment.brandName, 'public'), false),
  ];

  public availableMetricTypes: FilterObject[] = [];

  EventLogService = EventLogService;

  private notAvailableMetricIds: string[] = [];

  constructor(public dialogRef: MatDialogRef<MetricsSelectionDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {notAvailableMetricIds: string[], canShowMetricSets: boolean}, public dialog: MatDialog, private userService: FirestoreService, public utilityService: UtilityService, private toastr: ToastrService, private spinner: NgxSpinnerService, public translate: TranslateService) {
    this.canShowMetricSets = data.canShowMetricSets;
    this.setAvailableMetrics(this.userService.metrics, data.notAvailableMetricIds);
    this.userService?.observableMetrics.subscribe((metrics) => {
      this.setAvailableMetrics(this.userService.metrics, data.notAvailableMetricIds)
    });
    this.notAvailableMetricIds = data.notAvailableMetricIds;
    this.loadMetricGroups();
  }

  private loadMetricGroups(){
    this.selectableMetricGroups = [];
    let metricGroups = this.userService.metricGroups.sort((a, b) => a.name?.GetValue(this.translate.currentLang)?.localeCompare(b?.name?.GetValue(this.translate.currentLang)));
    metricGroups.forEach(metricGroup => {
      if(metricGroup.metricIds.find(metricId => !this.notAvailableMetricIds.includes(metricId)) != null){
        this.selectableMetricGroups.push(new SelectableItem<MetricGroup>(metricGroup, false));
      }
    });
    this.onMetricGroupsDeleteSearchInput();
  }

  setAvailableMetrics(metrics: Metric[], notAvailableMetricIds: string[]){
    let newSelectableMetrics: SelectableItem<Metric>[] = [];
    this.availableMetricTypes = [];
    metrics.forEach(metric => {
      if(!metric.deleted) {
        let disabled = notAvailableMetricIds.includes(metric.metricId);
        newSelectableMetrics.push(new SelectableItem<Metric>(metric, disabled, disabled));
        if(metric.metricType != null && this.availableMetricTypes.filter(x => x.originObject.originObject == metric.metricType).length == 0){
          this.availableMetricTypes.push(new FilterObject(Metric.metricTypeToLanguageDictionary(metric.metricType), false));
        }
      }
    });
    this.selectableMetrics = newSelectableMetrics;
    this.onMetricsDeleteSearchInput();
  }

  ngOnInit(): void {
    this.showMetrics = true;
  }

  onTakeSelection(){
    var selectedMetrics = []
    this.selectableMetrics.forEach(metric => {
      if(metric.IsSelected && !this.notAvailableMetricIds.includes(metric.Item.metricId)){
        selectedMetrics.push(metric.Item);
      }
    });

    this.selectableMetricGroups.forEach(metricGroup => {
      if(metricGroup.IsSelected){
        metricGroup.Item.metrics.forEach(metric => {
          if(!selectedMetrics.find(x => x.id == metric.id)){
            selectedMetrics.push(metric);
          }
        });
      }
    });
    this.onCloseDialog(true, selectedMetrics);
  }

  onCancelSelection(){
    this.onCloseDialog(false,[]);
  }

  onCloseDialog(shouldTake: boolean, selectedMetrics?: Metric[]){
    this.dialogRef.close({shouldTake: shouldTake, metrics: selectedMetrics});
  }

  selectMetric(metric: SelectableItem<Metric>){
    if(!metric.SelectionDisabled) {
      metric.IsSelected = !metric.IsSelected;
    }
  }

  isAnyMetricSelected(){
    return this.selectableMetrics?.find(x => x.IsSelected) != null;
  }

  onSelectAllClicked(){
    let selected = this.isAnyMetricSelected();
    this.selectableMetrics.forEach(metric => {
      metric.IsSelected = !selected;
    });
  }

  selectMetricGroup(selectable: SelectableItem<MetricGroup>){
    selectable.IsSelected = !selectable.IsSelected;
  }
  
  onCreateCustomMetric() {
    let customMetric = new Metric()
    customMetric.metricType = 'NUMBER'
    customMetric.dataType = 'INTEGER'

    const dialogRef = this.dialog.open(CustomMetricEditorDialogComponent, { data: { customMetric: customMetric, coach: this.userService.getLoggedInUser()}, width: '1000px'})
  }

  onCreateMetricGroup(){
    let metricGroup = new MetricGroup();
    metricGroup.licenceHolderUid = this.userService.getLoggedInUser().licenceHolderUid;
    const dialogRef = this.dialog.open(MetricGroupEditorDialogComponent, { data: { metricGroup: metricGroup}, width: '1000px'})
    dialogRef.afterClosed().subscribe(async result => {
      if (result?.updateMetricGroups) {
        this.loadMetricGroups();
      }
    });
  }

  onEditMetricGroup(metricGroup: MetricGroup){
    const dialogRef = this.dialog.open(MetricGroupEditorDialogComponent, { data: { metricGroup: metricGroup.clone()}, width: '1000px'})
    dialogRef.afterClosed().subscribe(async result => {
      if (result?.updateMetricGroups) {
        this.loadMetricGroups();
      }
    });
  }
  onDeleteMetricGroup(metricGroup: MetricGroup){
    if(!metricGroup.public) {
      try{
        this.spinner.show("metricSelectionSpinner");
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: { message: this.translate.instant('Möchtest du dieses Metrik-Set wirklich löschen?'), title: this.translate.instant('Metrik-Set löschen') },
        });
        dialogRef.afterClosed().subscribe(async result => {
          if (result) {
            await this.userService.deleteMetricGroup(metricGroup);
            this.loadMetricGroups();
          }
        });
      }
      catch(error){
        console.log(error)
        this.toastr.error(this.translate.instant("Es ist ein unbekannter Fehler aufgetreten."), this.translate.instant("Speichern fehlgeschlagen"),  {
          positionClass: 'toast-bottom-center'
        });
      }
      finally{
        this.spinner.hide("metricSelectionSpinner");
      }
    }
  }

  onDeleteCustomMetric(customMetric: Metric){
    if(!customMetric.public) {
      try{
        this.spinner.show("metricSelectionSpinner");
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: { message: this.translate.instant('Möchtest du diese Metrik wirklich löschen?'), title: this.translate.instant('Metrik löschen') },
        });
        dialogRef.afterClosed().subscribe(async result => {
          if (result) {
            this.userService.deleteCustomMetric(customMetric);
            customMetric.deleted = true;
          }
        });
      }
      catch(error){
        console.log(error)
        this.toastr.error(this.translate.instant("Es ist ein unbekannter Fehler aufgetreten."), this.translate.instant("Löschen fehlgeschlagen"),  {
          positionClass: 'toast-bottom-center'
        });
      }
      finally{
        this.spinner.hide("metricSelectionSpinner");
      }
    }
  }

  private searchTextMetrics: string = "";
  onMetricsSearchInputChanged(value: string){
    this.searchTextMetrics = value;
    this.setFilteredMetrics();
  }

  onMetricsDeleteSearchInput(){
    this.searchTextMetrics = '';
    let element = document.getElementById('metrics-search-input');
    if(element != null){
      (<HTMLInputElement> element).value = ''
    }
    this.setFilteredMetrics();
  }


  setFilteredMetrics() {
    
    // this.filteredSelectableMetrics = this.selectableMetrics.filter(x => this.searchTextMetrics.length == 0 || x.Item.nameDe.toLowerCase().includes(this.searchTextMetrics.toLowerCase()));
    // this.filteredSelectableMetrics = this.selectableMetrics.filter(x => x.Item.public == this.availableCreators.filter(x => x.isFiltered && x.originObject.originObject == 'public').length > 0);
    this.filteredSelectableMetrics = this.selectableMetrics.filter(x => this.fallsInFilters(x));
  }

  hasFilter(filterObjects:FilterObject[]):boolean{
    return filterObjects?.filter(x => x.isFiltered)?.length > 0
  }

  fallsInFilters(selectableItem: SelectableItem<Metric>){
    if(this.searchTextMetrics == '' || selectableItem.Item.nameTranslation?.GetValue(this.translate.currentLang)?.toLowerCase()?.includes(this.searchTextMetrics.toLowerCase())){
      if(!this.hasFilter(this.availableMetricTypes) || this.availableMetricTypes.filter(x => x.isFiltered && x.originObject.originObject == selectableItem.Item.metricType).length > 0){
        if(!this.hasFilter(this.availableCreators) || this.availableCreators.filter(x => x.isFiltered && x.originObject.originObject == (selectableItem.Item.public ? 'public' : 'private')).length > 0){
          return true;
        }
      }
    }
    return false;
  }

  onRemoveAllFilters(){
    this.availableCreators.forEach(x => x.isFiltered = false);
    this.availableMetricTypes.forEach(x => x.isFiltered = false);
    this.setFilteredMetrics();
  }

  onMetricGroupsSearchInputChanged(value: string){
    this.filteredSelectableMetricGroups = this.selectableMetricGroups.filter(x => x.Item?.name?.GetValue(this.translate.currentLang).toLowerCase().includes(value.toLowerCase()));
  }

  onMetricGroupsDeleteSearchInput(){
    this.filteredSelectableMetricGroups = this.selectableMetricGroups;
    let element = document.getElementById('metric-groups-search-input');
    if(element != null){
      (<HTMLInputElement> element).value = ''
    }
  }

  onEditCustomMetric(customMetric: Metric){
    if(!customMetric.public) {
      const dialogRef = this.dialog.open(CustomMetricEditorDialogComponent, { data: { customMetric: customMetric, coach: this.userService.getLoggedInUser()}, width: '1000px'})
    }
  }

  swapTab(tabName: string) {
    if(tabName == 'metricSets') {
      this.showMetrics = false;
    }
    else {
      this.showMetrics = true;
    }
  }
}

export class SelectableItem<T> {
  constructor(item: T, isSelected: boolean = false, selectionDisabled: boolean = false) {
    this.Item = item;
    this.IsSelected = isSelected;
    this.SelectionDisabled = selectionDisabled;
  }

  public Item: T;
  public IsSelected: boolean = false;
  public SelectionDisabled: boolean = false;
}