<div class="dialog-topbar">
    <div class="icon-button float-right" (click)="onCancel()" translate>
        Abbrechen
    </div>
</div>
<div class="dialog-content-wrapper">
    <div class="d-inline-block wrapper-timerange-selection">
        <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownLicenceHolders" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{selectedLicenceHolder?.name}}</button>
        <div class="dropdown-menu" aria-labelledby="dropdownLicenceHolders">
            <div *ngFor="let licenceHolder of licenceHolders">
                <button class="dropdown-item" type="button" (click)="onSelectLicenceHolder(licenceHolder)">{{licenceHolder.name}}</button>
            </div>
        </div>
    </div>
    <div class="spacer-10"></div>
    <input [(ngModel)]="email" class="input wide {{email.length > 0 ? '' : 'invalid'}}" type="text" [placeholder]="'Email-Adresse' | translate"/>
    <div class="spacer-10"></div>
    <input [(ngModel)]="password" class="input wide {{password.length >= 8 ? '' : 'invalid'}}" type="text" [placeholder]="'Passwort' | translate"/>
    <div class="spacer-10"></div>
    <input [(ngModel)]="name" class="input wide {{name.length > 0 ? '' : 'invalid'}}" type="text" [placeholder]="'Name' | translate"/>
    <div class="spacer-10"></div>

    <div *ngIf="email.length > 0 && name.length > 0" class="icon-button" (click)="createCoach()" translate>
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
        Erstellen
    </div>
</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>