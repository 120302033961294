import { PlannedTrainingExercise, SetParameter, TrainingSet, Week } from "./training-plan.model";

export class TrackedTrainingSet {
    public setNumber: number;
    public weight: number;
    public reps: number;
    public exertionValue: number;

    public time: number;
    public pace: number;
    public distance: number;
    public timeUnderTension: string;
    public rpe: number;
    public rir: number;
    public calories: number;
    public caloriesPerHour: number;
    public rpm: number;
    public watts: number;
    
    public pauseDuration: number;
    public coacheeNote: string;
    
    public heartRate: number;
    public isWarmupSet: boolean;
    public isDropset: boolean;
    public pace500: number;
    public speed: number;
    

    constructor(setNumber: number, weight: number, reps: number, exertionValue: number, time: number, pace: number, distance: number, timeUnderTension: string, rpe: number, rir: number, calories: number, caloriesPerHour: number, rpm: number, watts: number, pauseDuration: number, coacheeNote: string, heartRate: number, isWarmupSet: boolean, isDropset: boolean, pace500: number, speed: number) {
        this.setNumber = setNumber;
        this.weight = weight;
        this.reps = reps;
        this.exertionValue = exertionValue;

        this.time = time;
        this.pace = pace;
        this.distance = distance;
        this.timeUnderTension = timeUnderTension;
        this.rpe = rpe;
        this.rir = rir;
        this.calories = calories;
        this.caloriesPerHour = caloriesPerHour;
        this.rpm = rpm;
        this.watts = watts;

        this.pauseDuration = pauseDuration;
        this.coacheeNote = coacheeNote;

        this.heartRate = heartRate;
        this.isWarmupSet = isWarmupSet || false;
        this.isDropset = isDropset || false;
        this.pace500 = pace500;
        this.speed = speed;
    }

    getWeight() {
      return this.weight ?? 0
    }

    getTotalWeight() {
      return this.weight * this.reps
    }
    getRepCount() {
      return this.reps ?? 0
    }
    getE1RM() {
      var reps = this.getRepCount()
      if (this.rir != null) {
        reps = reps + this.rir
      } else if (this.rpe != null) {
        reps = reps + (10 - this.rpe)
      }
      if (reps == null || reps == 0) return null
      if (this.getWeight() == 0) return null
      return ((this.getWeight() * reps * 0.0333) + this.getWeight()).roundToPlaces(2)
    }

    getEXRM(x: number) {
      if(x == 1) return this.getE1RM();
      var reps = this.getRepCount()
      if (this.rir != null) {
        reps = reps + this.rir
      } else if (this.rpe != null) {
        reps = reps + (10 - this.rpe)
      }
      return (this.getWeight() * (1 + (reps / 30)) / (1 + (x / 30))).roundToPlaces(2);
    }
    
  }
  
  export class TrackedVideoRecording {
    public setNumber: number;
    public recordingTime: Date;
    public videoPath: string;
    public thumbnailPath: string;
    public note: string;
    public feedbackNote: string
    public feedbackRead: boolean
    public viewedByCoach: boolean

    // Tmp:
    public tmpId = Math.floor(Math.random() * 1000000000)?.toString()
    public urlsLoaded = false
    public videoUrl: string
    public thumbnailUrl: string

    constructor(setNumber: number, recordingTime: Date, videoPath: string, thumbnailPath: string, note: string, feedbackNote: string, feedbackRead: boolean, videoUrl: string, thumbnailUrl: string, viewedByCoach: boolean | null) {
        this.setNumber = setNumber;
        this.recordingTime = recordingTime || null;
        this.videoPath = videoPath || null;
        this.thumbnailPath = thumbnailPath || null;
        this.note = note || null;
        this.feedbackNote = feedbackNote || null;
        this.feedbackRead = feedbackRead || null;
        this.videoUrl = videoUrl || null;
        this.thumbnailPath = thumbnailUrl || null;
        this.viewedByCoach = viewedByCoach != null ? viewedByCoach : null
    }

    asMap():Record<string, any>{
      return {
          "setNumber": this.setNumber,
          "recordingTime": this.recordingTime,
          "videoPath": this.videoPath,
          "thumbnailPath": this.thumbnailPath,
          "note": this.note,
          "feedbackNote": this.feedbackNote,
          "feedbackRead": this.feedbackRead,
          "viewedByCoach": this.viewedByCoach
      }
  }
  }

  export class TrackedTrainingExercise {
    public id: string
    public completed: boolean;
    public note: string;
    public startDate: Date;
    public exerciseId: string;
    public plannedExerciseId: string;
    public trackedSessionId: string
    public groupHeading?: string;
    public trackedSets: TrackedTrainingSet[];
    public recordings: TrackedVideoRecording[];
    public nextSupersetExerciseId: string;
    public deleted: boolean;
    public replacedExerciseId: string;
    public sets: TrainingSet[];
    public position: number;
    public noteImagePaths: string[];

    constructor();
    constructor(init: TrackedTrainingExercise);
    constructor(init?:TrackedTrainingExercise) {
        this.id = init.id || null
        this.completed = init.completed || false;
        this.note = init.note || null;
        this.startDate = init.startDate || null;
        this.exerciseId = init.exerciseId || null;
        this.plannedExerciseId = init.plannedExerciseId || null;
        this.trackedSessionId = init.trackedSessionId || null;
        this.groupHeading = init && init.groupHeading || null
        this.trackedSets = init.trackedSets.map(x => new TrackedTrainingSet(x.setNumber, x.weight, x.reps, x.exertionValue, x.time, x.pace, x.distance, x.timeUnderTension, x.rpe, x.rir, x.calories, x.caloriesPerHour, x.rpm, x.watts, x.pauseDuration, x.coacheeNote, x.heartRate, x.isWarmupSet, x.isDropset, x.pace500, x.speed)) || [];
        this.recordings = init.recordings?.map(x => new TrackedVideoRecording(x.setNumber, x.recordingTime, x.videoPath, x.thumbnailPath, x.note, x.feedbackNote, x.feedbackRead, x.videoUrl, x.thumbnailUrl, x.viewedByCoach)) || [];
        this.nextSupersetExerciseId = init.nextSupersetExerciseId || null;
        this.deleted = init.deleted || false;
        this.replacedExerciseId = init.replacedExerciseId || null;
        this.sets = init.sets?.map(x => new TrainingSet(x)) || [];
        this.position = init.position || null;
        this.noteImagePaths = init.noteImagePaths || null
    }

    getTrackedVideoRecordingBySetNumber(setNumber: number): TrackedVideoRecording {
      var result = this.recordings.filter(x => x.setNumber == setNumber)
      if (result.length > 0) return result[0]
    }

    getTotalWeight(exerciseId: string = null) {
      if (exerciseId != null && exerciseId != this.exerciseId) return null
      var value = 0
      for (var set of this.trackedSets) {
        value += set.getTotalWeight()
      }
      return value
    }

    getMaxWeight(exerciseId: string = null) {
      if (exerciseId != null && exerciseId != this.exerciseId) return null
      var value = null
      if (this.trackedSets) {
        for (var set of this.trackedSets) {
          if (set.weight != null) {
            if (value == null || set.weight > value) value = set.weight
          }
        }
      }
      return value
    }

    getE1RM(exerciseId: string = null) {
      if (exerciseId != null && exerciseId != this.exerciseId) return null
      var value = null
      if (this.trackedSets) {
        for (var set of this.trackedSets) {
          var v = set.getE1RM()
          if (v != null && (value == null || v > value)) value = set.getE1RM()
        }
      }
      return value
    }

    getEXRM(x: number) {
      if(x == 1) return this.getE1RM();
      var value = null
      if (this.trackedSets) {
        for (var set of this.trackedSets) {
          var v = set.getE1RM()
          if (v != null && (value == null || v > value)) value = set.getEXRM(x)
        }
      }
      return value
    }

    getAverageExertion(exerciseId: string = null) {
      if (exerciseId != null && exerciseId != this.exerciseId) return null
      var value = 0
      var count = null
      if (this.trackedSets) {
        for (var set of this.trackedSets) {
          if (set.exertionValue != null) {
            value += set.exertionValue
            if (count == null) {
              count = 1
            } else {
              count++
            }
          }
        }
      }
      if (count != null) return value / count
      return null
    }
    
    getAverageRPE(exerciseId: string = null) {
      if (exerciseId != null && exerciseId != this.exerciseId) return null
      var value = 0
      var count = null
      if (this.trackedSets) {
        for (var set of this.trackedSets) {
          if (set.rpe != null) {
            value += set.rpe
            if (count == null) {
              count = 1
            } else {
              count++
            }
          }
        }
      }
      if (count != null) return value / count
      return null
    }
    
    getAverageRIR(exerciseId: string = null) {
      if (exerciseId != null && exerciseId != this.exerciseId) return null
      var value = 0
      var count = null
      if (this.trackedSets) {
        for (var set of this.trackedSets) {
          if (set.rir != null) {
            value += set.rir
            if (count == null) {
              count = 1
            } else {
              count++
            }
          }
        }
      }
      if (count != null) return value / count
      return null
    }

    getSetCount(exerciseId: string = null) {
      if (exerciseId != null && exerciseId != this.exerciseId) return null
      return this.trackedSets?.length ?? 0
    }

    getRepCount(exerciseId: string = null) {
      if (exerciseId != null && exerciseId != this.exerciseId) return null
      var value = 0
      if (this.trackedSets) {
        for (var set of this.trackedSets) {
          value += set.getRepCount()
        }
      }
      return value
    }

    hasVideoRecordings() {
      return this.recordings?.length > 0
    }
    hasCoacheeNotes() {
      return this.trackedSets?.filter(x => x.coacheeNote?.length > 0)?.length > 0
    }

    getSetIndexWithoutWarmupSets(set: TrackedTrainingSet): number {
      return this.trackedSets?.filter(x => !x.isWarmupSet)?.indexOf(set);
    }

    getRealSetIndex(set: TrackedTrainingSet): number {
      return this.trackedSets?.filter(x => !x.isWarmupSet && !x.isDropset)?.indexOf(set);
    }

    hasNote() {
      return this.note?.length > 0
    }
    hasNoteImages() {
      return this.noteImagePaths?.length > 0
    }
  }
  
  export class TrackedTrainingSession {
    public id: string;
    public startDate: Date;
    public endDate: Date;
    public trainingPlanId: string;
    public plannedSessionId: string;
    public sessionName: string;
    public deleted: boolean;
    public viewedByCoach: boolean

    public custom: boolean;

    public trackedTrainingExercises: TrackedTrainingExercise[]
    public continued: boolean;
    
    //temp
    public plannedWeekIndex: number;
    public plannedWeek: Week;
    
    constructor();
    constructor(init: TrackedTrainingSession);
    constructor(init?:TrackedTrainingSession) {
      this.id = init?.id || null
      this.startDate = init?.startDate || null;
      this.endDate = init?.endDate || null;
      this.trainingPlanId = init?.trainingPlanId || null;
      this.plannedSessionId = init?.plannedSessionId || null;
      this.sessionName = init?.sessionName || null;
      this.trackedTrainingExercises = init?.trackedTrainingExercises || [];
      this.deleted = init?.deleted || false;
      this.custom = init?.custom || false;
      this.viewedByCoach = init && init.viewedByCoach != null ? init.viewedByCoach : null;
      this.continued = init?.continued || false;
    }

    containsExercise(exerciseId: string) {
      return this.trackedTrainingExercises.filter(exercise => exercise.exerciseId == exerciseId).length > 0
    }

    getTrackedExerciseByExerciseId(exerciseId: string): TrackedTrainingExercise {
      var result = this.trackedTrainingExercises.filter(exercise => exercise.exerciseId == exerciseId)
      if (result.length > 0) return result[0]
      return null
    }

    getSetCount(exerciseId: string = null) {
      var value = null
      if (this.trackedTrainingExercises) {
        for (var exercise of this.trackedTrainingExercises) {
          var v = exercise.getSetCount(exerciseId)
          if (v != null) {
            if (value == null) {
              value = v
            } else {
              value += v
            }
          }
        }
      }
      return value
    }
    getRepCount(exerciseId: string = null) {
      var value = null
      if (this.trackedTrainingExercises) {
        for (var exercise of this.trackedTrainingExercises) {
          var v = exercise.getRepCount(exerciseId)
          if (v != null) {
            if (value == null) {
              value = v
            } else {
              value += v
            }
          }
        }
      }
      return value
    }
    getTrainingDuration() {
      if (this.startDate && this.endDate) return Math.floor((this.endDate.getTime() - this.startDate.getTime()) / (1000 * 60))
      return null
    }
    getPrintableTrainingDuration() {
      var duration = this.getTrainingDuration()
      if (duration == null) return null
      return Math.floor(duration / 60) + ':' + ((duration % 60) < 10 ? '0' : '') + (duration % 60)
    }

    getTotalWeight(exerciseId: string = null) {
      var value = null
      if (this.trackedTrainingExercises) {
        for (var exercise of this.trackedTrainingExercises) {
          var v = exercise.getTotalWeight(exerciseId)
          if (v != null) {
            if (value == null) {
              value = v
            } else {
              value += v
            }
          }
        }
      }
      return value
    }

    getMaxWeight(exerciseId: string = null) {
      var value = null
      if (this.trackedTrainingExercises) {
        for (var exercise of this.trackedTrainingExercises) {
          var v = exercise.getMaxWeight(exerciseId)
          if (v != null && (value == null || v > value)) value = exercise.getMaxWeight(exerciseId)
        }
      }
      return value
    }

    getE1RM(exerciseId: string = null) {
      var value = null
      if (this.trackedTrainingExercises) {
        for (var exercise of this.trackedTrainingExercises) {
          var v = exercise.getE1RM(exerciseId)
          if (v != null && (value == null || v > value)) value = exercise.getE1RM(exerciseId)
        }
      }
      return value
    }

    getEXRM(x: number) {
      if(x == 1) return this.getE1RM();
      var value = null
      if (this.trackedTrainingExercises) {
        for (var exercise of this.trackedTrainingExercises) {
          var v = exercise.getE1RM()
          if (v != null && (value == null || v > value)) value = exercise.getEXRM(x);
        }
      }
      return value
    }

    getAverageExertion(exerciseId: string = null) {
      var value = 0
      var count = null
      if (this.trackedTrainingExercises) {
        for (var exercise of this.trackedTrainingExercises) {
          var exertion = exercise.getAverageExertion(exerciseId)
          if (exertion != null) {
            value += exertion
            if (count == null) {
              count = 1
            } else {
              count++
            }
          }
        }
      }
      if (count != null) return value / count
      return null
    }

    getAverageRPE(exerciseId: string = null) {
      var value = 0
      var count = null
      if (this.trackedTrainingExercises) {
        for (var exercise of this.trackedTrainingExercises) {
          var exertion = exercise.getAverageRPE(exerciseId)
          if (exertion != null) {
            value += exertion
            if (count == null) {
              count = 1
            } else {
              count++
            }
          }
        }
      }
      if (count != null) return value / count
      return null
    }

    getAverageRIR(exerciseId: string = null) {
      var value = 0
      var count = null
      if (this.trackedTrainingExercises) {
        for (var exercise of this.trackedTrainingExercises) {
          var exertion = exercise.getAverageRIR(exerciseId)
          if (exertion != null) {
            value += exertion
            if (count == null) {
              count = 1
            } else {
              count++
            }
          }
        }
      }
      if (count != null) return value / count
      return null
    }
  }
  
  export class TrackedSuperSets {
    public TrackedTrainingSets: TrackedTrainingSet[] = []
  
  }
  
  export class TrackedExerciseSet {
    constructor(trackedTrainingSet: TrackedTrainingSet, trackedTrainingExercise: ExtendedTrackedTrainingExercise, exerciseThumbnailNumber: number, plannedTrainingExercise?: PlannedTrainingExercise) {
      this.trackedTrainingSet = trackedTrainingSet;
      this.trackedTrainingExercise = trackedTrainingExercise;
      this.exerciseThumbnailNumber = exerciseThumbnailNumber;
      this.plannedTrainingExercise = plannedTrainingExercise;
    }
  
    public trackedTrainingSet: TrackedTrainingSet;
    public trackedTrainingExercise: ExtendedTrackedTrainingExercise;
  
    public exerciseThumbnailNumber: number;
    public plannedTrainingExercise: PlannedTrainingExercise;
  }
  
  export class TrackedSuperSetRounds {
    public trackedExerciseSets: TrackedExerciseSet[] = []
    

    public getTimeInSeconds(): number {
      let time: number = 0;
      this.trackedExerciseSets.forEach(exerciseSet => {
        if(exerciseSet.trackedTrainingSet.time) {
          time += exerciseSet.trackedTrainingSet.time
        }
        if(exerciseSet.trackedTrainingSet.pauseDuration) {
          time += exerciseSet.trackedTrainingSet.pauseDuration
        }
      });
      return time;
    }

    public getTime():Date{
      let time = this.getTimeInSeconds();
      return new Date(0,0,0,0,0,time,0);
    }
  }
  
  export class TrackedSuperSet {
    constructor(trackedSuperExercises: ExtendedTrackedTrainingExercise[]) {
      this.trackedSuperSetExercises = trackedSuperExercises;
    }
  
    public trackedSuperSetRounds: TrackedSuperSetRounds[] = []
    public trackedSuperSetExercises: ExtendedTrackedTrainingExercise[] = []

    public plannedSuperSetRounds: Map<SetParameter, string>[][] = [];

    hasVideoRecordings() {
      return this.trackedSuperSetExercises.filter(x => x.hasVideoRecordings()).length > 0
    }
    hasCoacheeNotes() {
      return this.trackedSuperSetExercises.filter(x => x.hasCoacheeNotes()).length > 0
    }
  }

  export class ExtendedTrackedTrainingExercise extends TrackedTrainingExercise{
    public isExerciseExchanged: boolean = false;

    /**
     *
     */
    constructor();
    constructor(init: TrackedTrainingExercise);
    constructor(init?:TrackedTrainingExercise) {
      super(init);
    }

    static createFromTrackedTrainingExercise(trackedTrainingExercise: TrackedTrainingExercise): ExtendedTrackedTrainingExercise {
      return trackedTrainingExercise as ExtendedTrackedTrainingExercise
    }
  }