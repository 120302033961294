<div class="container">
    <div class="row">
        <div class="col-12 col-lg-4"></div>
        <div class="col-12 col-lg-4">
            <div class="branding-wrapper">
                <h3 translate>Willkommen!</h3>
                <img draggable="false" *ngIf="brandingImageUrl" class="branding-image" src="{{brandingImageUrl}}" alt="">
            </div>
        </div>
        <div class="col-12 col-lg-4">
            <div *ngIf="user && userSelected">
                <div class="user-selection">
                    <div class="profile-container">
                        <div class="profile-picture">
                            <button class="profile-button" title="Profilbild hochladen">
                                <img draggable="false" src="{{user.profilePictureUrl}}">
                            </button>
                        </div>
                        <h3 class="name">{{user?.getName()}}</h3>
                    </div>
                    <span translate>Nicht du?</span><br>
                    <div class="spacer-10"></div>
                    <button class="button-filled small white" id="logout-button" (click)="onLogout()" translate>Abmelden</button>
                </div>
                <div class="spacer-25"></div>
            </div>
        </div>
        
        <div class="col-12">
            <ng-container *ngIf="purchase?.deleted">
                <div class="textalign-center"><h2 translate>Dieses Angebot ist leider nicht mehr verfügbar.</h2></div>
            </ng-container>
        </div>

        <div class="col-12">
            <ng-container *ngIf="user && purchase && !isProductPurchaseAccessible()">
                <div class="textalign-center">
                    <h2 translate>Du kannst mit diesem Account nicht auf das Angebot zugreifen.</h2>
                    <button class="button-filled small white" id="logout-button" (click)="onLogout()" translate>Abmelden</button>
                </div>
                <div class="spacer-25"></div>
            </ng-container>
            <ng-container *ngIf="!user && !isProductPurchaseAccessible()">
                <div class="textalign-center">
                    <h2 translate>Melde dich an, um auf das Angebot zuzugreifen.</h2>
                    <div class="spacer-25"></div>
                    <button class="button-filled small" (click)="onLogin()" translate>
                        Anmelden
                    </button>
                    <div class="spacer-25"></div>
                </div>
            </ng-container>
            
            <div class="row">
                <div class="col">
                    <ng-container *ngIf="purchase?.status == 'uncompleted' && product && purchase && !purchase?.deleted">
                        <app-checkout-view [setProductPurchase]="purchase" [setProduct]="product" [setPaymentSettings]="paymentSettings"></app-checkout-view>
                    </ng-container>
                </div>
                <div *ngIf="showDescription" class="col">
                    <div class="layout-box description-box">
                        <h3 class="display-inlineblock" translate>Produktbeschreibung</h3>
                        <div class="icon-button float-right" (click)="onToggleDescription()">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </div>
                        <markdown>{{product?.description}}</markdown>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="isProductPurchaseAccessible()">
                <ng-container *ngIf="purchase?.status == 'purchased'">
                    <div class="textalign-center">
                        <h2 translate>Dein Kauf war erfolgreich! Bald geht es los. 💪</h2>
                        <div class="spacer-10"></div>
                        <span translate [translateParams]="{startDateParameter: purchase.startDate.asFormatedString()}">Am startDateParameter erhältst du eine Kickoff-Email.</span><br>
                        <div class="spacer-25"></div>
                        <ng-container *ngIf="purchase?.licenceType">
                            <span translate>Lade dir jetzt schon meine App herunter und richte sie ein, um direkt starten zu können!</span><br>
                            <div class="spacer-25"></div>
                            <a href="{{getInAppLink()}}" target="_blank">
                                <button class="button-filled small" translate>App einrichten</button>
                            </a>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngIf="purchase?.status == 'active' || purchase?.status == 'canceled' || purchase?.status == 'expired'">
                    <div class="textalign-center">
                        <ng-container *ngIf="purchase?.status == 'active'">
                            <h2 translate>Dein Kauf war erfolgreich! Du kannst jetzt loszulegen. 💪</h2>
                            <div class="spacer-25"></div>
                            <ng-container *ngIf="purchase?.licenceType">
                                <span translate>Lade meine App herunter und melde dich mit diesem Account an.</span><br>
                                <div class="spacer-25"></div>
                                <a href="{{getInAppLink()}}" target="_blank">
                                    <button class="button-filled small" translate>App einrichten</button>
                                </a>
                            </ng-container>
                        </ng-container>

                        <div class="spacer-25"></div>
                        <div class="spacer-25"></div>
                        <div class="bold">Kaufdetails</div>
                        <div class="spacer-10"></div>
                        <span translate>Start:</span> {{purchase.getPrintableStartDate(translate)}}<br>
                        <ng-container *ngIf="purchase.endDate">
                            <span translate>Ende:</span> {{purchase.endDate.asFormatedString()}}<br>
                        </ng-container>
                        <div class="spacer-10"></div>
                        <div class="label">{{purchase.getPrintableRenewalInformation(translate)}}<br>{{purchase.getPrintableCancelationPolicy(translate)}}</div>
                        <div class="spacer-10"></div>
                        <button *ngIf="purchase.canBeCanceled()" class="button-filled small white" (click)="onCancelPurchase()" translate>
                            Kündigen
                        </button>
                        <div class="spacer-10"></div>
                        <button *ngIf="purchase?.status == 'active'" class="button-filled small white" (click)="onUpdatePaymentMethod()" translate>
                            Zahlungsmethode ändern
                        </button>
                        <ng-container *ngIf="loading">
                            <div class="spacer-25"></div>
                            <mat-spinner class="loading-statistics-spinner" diameter="30" strokeWidth="2"></mat-spinner>
                            <div class="spacer-10"></div>
                        </ng-container>

                        <ng-container *ngIf="enablePaymentMethodSelection && !loading">
                            <div class="spacer-25"></div>
                            <app-paymentmethod-selection [paymentMethods]="getPaymentMethods()" (confirmPaymentMethod)="onConfirmPaymentMethod($event)"></app-paymentmethod-selection>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>

            <div *ngIf="user" class="textalign-center">
                <div class="spacer-25"></div>
                <div class="spacer-25"></div>
                <button class="button-filled small white" (click)="onViewInvoices()" translate>
                    Rechnungshistorie ansehen
                </button>
                <div class="spacer-25"></div>
            </div>
        </div>
        
        <div class="checkout-footer col-12">
            <app-payment-imprint [paymentSettings]="paymentSettings"></app-payment-imprint>
        </div>
    </div>
    
</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>