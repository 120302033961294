<!-- <div id="editor">
    <textarea id="editor-textarea" class="{{readOnlyMode ? 'disabled' : ''}}" [placeholder]="placeHolder" [disabled]="readOnlyMode" (input)="onValueChange($event)" [ngModel]='inputValue' (focus)="onFocus($event)" (blur)="onBlur($event)"></textarea>
    <div [innerHtml]="compiled"></div>
</div> -->
<div class="layout-box editor-box">
    <div id="editor" (focus)="onFocus($event)" (blur)="onBlur($event)">
        <div class="NgxEditor__Wrapper">
            <ngx-editor-menu *ngIf="!readOnlyMode" [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
            <ngx-editor [editor]="editor" [(ngModel)]="editorValue" [disabled]="readOnlyMode" [placeholder]="placeHolder" (focusIn)="onFocus($event)" (focusOut)="onBlur($event)"></ngx-editor>
          </div>
    </div>
</div>