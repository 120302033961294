import { TranslateService } from "@ngx-translate/core"
import { LicenceHolder } from "./licenceholder.model"

export class CoachInvoice {
    id: string
    invoiceId: string
    stripeCustomerId: string
    invoiceNumber: string
    licenceHolderId: string
    amount: number
    netAmount: number
    currency: string
    status: string
    date: Date
    deleted: boolean
    containsStarterPackage: boolean

    // Tmp:
    licenceHolder: LicenceHolder
    
    constructor();
    constructor(init: CoachInvoice);
    constructor(init?: CoachInvoice) {
        this.id = init && init.id || null
        this.invoiceId = init && init.invoiceId || null
        this.stripeCustomerId = init && init.stripeCustomerId || null
        this.invoiceNumber = init && init.invoiceNumber || null
        this.licenceHolderId = init && init.licenceHolderId || null
        this.amount = init && init.amount || null
        this.netAmount = init && init.netAmount || null
        this.currency = init && init.currency || null
        this.status = init && init.status || null
        this.deleted = init && init.deleted || false
        this.containsStarterPackage = init && init.containsStarterPackage || false
        if (init && (init as any).isStarterPackage == true) {
            this.containsStarterPackage = true
        }
        this.date = init && init.date ? new Date((init as any).date.seconds * 1000) : null
    }

    getPrintablePrice() {
        return (this.amount / 100).toString().replace('.', ',')
    }
    getPrintablePriceWithCurrency() {
        return this.getPrintablePrice() + ' ' + this.getPrintableCurrency()
    }
    getPrintableCurrency() {
        if (this.currency == 'eur' || this.currency == null) return '€'
        return '€'
    }
    getPrintableStatus(translate: TranslateService) {
        switch (this.status) {
            case 'draft':    return translate.instant('Entwurf');
            case 'paid': return translate.instant('Bezahlt');
            case 'unpaid': return translate.instant('Unbezahlt');
            case 'processing': return translate.instant('In Verarbeitung');
            case 'pending': return translate.instant('In Verarbeitung');
            case 'manual_transfer_initiated': return translate.instant('Manuelle Überweisung initiiert');
            case 'refunded': return translate.instant('Erstattet');
            case 'voided': return translate.instant('Storniert');
            case 'failed': return translate.instant('Fehlgeschlagen');
            case 'failed_retrying': return translate.instant('Wird erneut versucht');
            case 'initiated': return translate.instant('Initiiert');
            case 'open': return translate.instant('Offen (keine Zahlungsmethode)');
            default: return translate.instant('Unbekannt');
        }
    }

    getClientName() {
        return this.licenceHolder?.name
    }
}