<div class="row">
  <div class="col-12 col-sm-5 col-md-3 col-xl-2 superwidth marginbottom-15">
    <app-kpi-clv></app-kpi-clv>
  </div>
  <div class="col-12 col-sm-5 col-md-3 col-xl-2 superwidth marginbottom-15">
    <app-kpi-customer-retention></app-kpi-customer-retention>
  </div>
  <div class="col-12 col-sm-5 col-md-3 col-xl-2 superwidth marginbottom-15">
    <app-kpi-monthly-rev></app-kpi-monthly-rev>
  </div>
  <div class="col-12 col-sm-5 col-md-3 col-xl-2 superwidth marginbottom-15">
    <app-kpi-yearly-rev></app-kpi-yearly-rev>
  </div>
  <div class="col-12 col-sm-5 col-md-3 col-xl-2 superwidth marginbottom-15">
    <app-kpi-active-sales></app-kpi-active-sales>
  </div>
</div>
