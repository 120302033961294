<div class="statistic-box">
  <h3 class="d-inline-block wrapper-timerange-selection marginright-10" translate>Aktive Abos</h3>
  <div class="d-inline-block wrapper-timerange-selection">
    <button class="button-filled white small dropdown-toggle margin-inbetween" type="button"
            id="dropdownGraphTimeRange" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">{{ selectedGraphTimeRange?.name | translate }}
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownGraphTimeRange">
      <div *ngFor="let range of graphTimeRanges">
        <button class="dropdown-item" type="button" (click)="onGraphTimeRangeChanged(range)">{{ range.name | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="d-inline-block float-right">
    <app-analytics-config-menu *ngIf="statisticConfig"
                               [statisticConfig]="statisticConfig"
                               (onChangeConfigSizeEvent)="onChangeConfigSize($event)"
                               (onRemoveConfigEvent)="onRemoveConfig()"
                               (onMoveConfigDownEvent)="onMoveConfigDown()"
                               (onMoveConfigUpEvent)="onMoveConfigUp()"
                               class="d-inline-block"></app-analytics-config-menu>
  </div>

  <dx-chart palette="nutrilizePalette" [dataSource]="activePurchasesStatistics">
    <dxo-common-series-settings argumentField="timeArgument"></dxo-common-series-settings>
    <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip"></dxo-tooltip>


    <dxi-series [name]="'Neukunden' | translate" valueField="newClients" type="stackedbar" color="#ACF3AE"
                axis="activePurchases" barWidth="10"></dxi-series>
    <dxi-series [name]="'Neukunden' | translate" valueField="newClientsProjection"
                type="stackedbar" color="#ddfade" axis="activePurchases" barWidth="10" [showInLegend]=false></dxi-series>

    <dxi-series [name]="'Abwanderungen' | translate" valueField="lostClients" type="stackedbar" color="#FA6B84"
                axis="activePurchases" barWidth="10"></dxi-series>
    <dxi-series [name]="'Abwanderungen' | translate" valueField="lostClientsProjection"
                type="stackedbar" color="#fdc3cd" axis="activePurchases" barWidth="10" [showInLegend]=false></dxi-series>

    <dxi-series [name]="'Anzahl aktiver Abos' | translate" valueField="activePurchases" type="spline"
                axis="activePurchases" color="#1db2f5"></dxi-series>
    <dxi-series [name]="'Anzahl aktiver Abos' | translate" valueField="activePurchasesProjection" type="spline"
                dashStyle="dot" axis="activePurchases" color="#1db2f5" [showInLegend]=false></dxi-series>

    <dxi-value-axis name="activePurchases" position="left" showZero="true">
      <dxi-constant-line
        [width]="2"
        [value]="0"
        dashStyle="dash"
      ></dxi-constant-line>
    </dxi-value-axis>

    <!--<dxi-value-axis name="progress" position="right" tickInterval="1">
      <dxo-title text="Verlauf"></dxo-title>

      <dxi-constant-line
        [width]="2"
        [value]="0"
        dashStyle="dash"
      ></dxi-constant-line>
    </dxi-value-axis>-->

    <dxo-argument-axis *ngIf="selectedGraphTimeRange.id === GraphTimeRangeOptions.THIS_MONTH || selectedGraphTimeRange.id === GraphTimeRangeOptions.LAST_MONTH" [title]="month.format('MMMM YYYY')"></dxo-argument-axis>

    <dxo-legend
      verticalAlignment="bottom"
      horizontalAlignment="center"
      itemTextPosition="top"
      visible="true">
    </dxo-legend>
  </dx-chart>
</div>
