import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {

  public message: string
  public title: string
  public positiveButton: string
  public negativeButton: string

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {message: string, title: string, positiveButton: string, negativeButton: string}, translate: TranslateService) {
    this.message = data.message
    this.title = data.title
    this.positiveButton = data.positiveButton || translate.instant('Bestätigen')
    this.negativeButton = data.negativeButton || translate.instant('Abbrechen')
  }

  ngOnInit(): void {
  }

  onCancelDialog() {
    this.dialogRef.close(false);
  }
  onConfirmDialog() {
    this.dialogRef.close(true);
  }

}
