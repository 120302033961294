import { Component, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { PaymentSettings } from 'src/app/model/payment-settings.model';
import { ProductPurchase } from 'src/app/model/product-purchase.model';
import { Product } from 'src/app/model/product.model';
import { User } from 'src/app/model/user.model';
import { ChatService } from 'src/app/services/chat.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { environment } from 'src/environments/environment';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import {Title} from "@angular/platform-browser";
import { TranslateService } from '@ngx-translate/core';
declare var Calendly: any;

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.css']
})
export class ProfileViewComponent {

  get user(): User {
    return this.userService.getLoggedInUser()
  }

  public environment = environment

  public userSelected = false

  public isLoading = true

  licenceHolderUid: string
  brandingImageUrl: string
  paymentSettings: PaymentSettings
  products: Product[] = null

  constructor(private router: Router, public userService: FirestoreService, private authService: AuthService, private chatService: ChatService, private route: ActivatedRoute, private ngZone: NgZone, public dialog: MatDialog, private titleService: Title, private translate: TranslateService) {
    authService.getAuthState().subscribe(user => {
      if (user) {

      } else {
        this.userSelected = false
      }
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(async (param) => {
      var id = param.get('id')
      if (id?.includes('?')) {
        id = id.split('?')[0]
      }
      if (id?.includes('%')) {
        id = id.split('%')[0]
      }
      var hasLicenceHolder = await this.userService.hasLicenceHolderWithUid(id)
      if (hasLicenceHolder) {
        this.licenceHolderUid = id
      } else {
        this.licenceHolderUid = await firstValueFrom(this.userService.getLicenceHolderUidBySlug(id))
      }
      await this.loadPaymentSettings()
      this.loadBrandingSettings()
      this.loadProducts()
    });
    
  }

  isLandingPageEnabled() {
    return this.paymentSettings?.landingPageEnabled ?? false
  }

  async loadBrandingSettings() {
    var snapshot = await firstValueFrom(this.userService.getBrandingSettings(this.licenceHolderUid))
    if (snapshot.exists) {
      var logoFileName = snapshot?.data()?.logoFileName
      if (logoFileName) {
        this.brandingImageUrl = await firstValueFrom(this.userService.getBrandingImage(this.licenceHolderUid, logoFileName))
      }
    }
  }
  async loadPaymentSettings() {
    this.paymentSettings = await this.userService.getPaymentSettings(this.licenceHolderUid)
    this.titleService.setTitle(this.paymentSettings?.companyName ?? this.translate.instant("Meine Landing-Page"))
    if (this.paymentSettings?.termsAndConditionsPath) {
      this.paymentSettings.termsAndConditionsUrl = await this.userService.getDownloadUrl("licence_holders/" + this.licenceHolderUid + "/settings/payment/" + this.paymentSettings.termsAndConditionsPath)
    }
    if (this.paymentSettings?.customAccentColor) {
      document.documentElement.style.setProperty('--accentColor', this.paymentSettings.customAccentColor);
      document.documentElement.style.setProperty('--accentColorHover', this.paymentSettings.customAccentColor + 'aa');
      document.documentElement.style.setProperty('--textOnAccentColor', this.paymentSettings.customOnAccentTextColor);
    }
    if (this.paymentSettings?.customBackgroundColor) {
      document.documentElement.style.setProperty('--lightBackgroundColor', this.paymentSettings.customBackgroundColor);
    }
    if (this.paymentSettings?.customTextColor) {
      document.documentElement.style.setProperty('--textColor', this.paymentSettings.customTextColor);
    }
    this.isLoading = false
  }
  async loadProducts() {
    this.userService.getProducts(this.licenceHolderUid).subscribe(products => {
      this.products = products.filter(p => !p.deleted && p.showOnLandingPage)
      this.products.sort((a, b) => (a.landingPageOrder ?? 99) - (b.landingPageOrder ?? 99))
      for (let product of this.products) {
        if (product.thumbnailPath) {
          this.userService.getDownloadUrl(product.getFullThumbnailPath(this.licenceHolderUid)).then(url => product.thumbnailUrl = url)
        }
      }
    })
    
  }

  onShowProduct(product: Product) {
    window.open(product.getProductPageLink(), "_blank");
  }

  onLogout() {
    this.userService.logout();
    this.chatService.logout();
    this.userSelected = false
  }

}
