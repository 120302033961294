export interface ILanguageDictionary<Type>{
    originObject: Type;
    comparableValue: string;
    de: string;
    en: string;
    es: string;
    fr: string;

    clone(): ILanguageDictionary<Type>;
    SetValue(languageCode:string, value:string);
    GetValue(languageCode:string):string;
    ParseFromMap(input:Record<string, string>)
    AsMap():Record<string,string>
}

export class LanguageDictionary<Type> implements ILanguageDictionary<Type> {
    originObject: Type;
    de: string = null;
    en: string = null;
    es: string = null;
    fr: string = null;

    get comparableValue(): string {
        return this.de + this.en + this.es + this.fr;
    }

    constructor(de?: string, en?: string, originObject?: Type, es?: string, fr?: string) {
        this.de = de || null;
        this.en = en || null;
        this.es = es || null;
        this.fr = fr || null;
        this.originObject = originObject || null;
    }

    clone(): LanguageDictionary<Type> {
        return new LanguageDictionary<Type>(this.de, this.en, null, this.es, this.fr);
    }

    hasAnyValue(): boolean {
        return this.comparableValue?.length > 0;
    }

    public SetValue(languageCode: string, value: string) {
        if (languageCode == "de") this.de = value;
        else if (languageCode == "en") this.en = value;
        else if (languageCode == "es") this.es = value;
        else if (languageCode == "fr") this.fr = value;
        else console.error("Language code not supported: " + languageCode);
    }
    public GetValue(languageCode: string): string {
        return (this.AsMap()[languageCode])?.toString();
    }

    public ParseFromMap(input: Record<string, string>) {
        this.de = input["de"] || this.de;
        this.en = input["en"] || this.en;
        this.es = input["es"] || this.es;
        this.es = input["fr"] || this.fr;
    }

    public AsMap(): Record<string, string> {
        return {
            ["de"]: this.de,
            ["en"]: this.en,
            ["es"]: this.es,
            ["fr"]: this.fr
        };
    }

    public getValues(): string[] {
        return [this.de, this.en, this.es, this.fr];
    }
}
