import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DashboardComponent } from '../dashboard/dashboard-v1/dashboard.component';
import { NutritionalGoalV2 } from '../model/nutritionalgoalv2.model';
import { CycleConfig, Situation, SituationType } from '../model/nutritionconfig.model';
import { NutritionPlanConfig } from '../model/nutritionplanconfig.model';
import { MealType, MealTypeTranslation, NutritionPlanMealConfig, NutritionPlanV2 } from '../model/nutritionplanv2.model';
import { Tag } from '../model/tag.model';
import { User } from '../model/user.model';
import { NutritionPlanComponent } from '../nutrition-plan/nutrition-plan.component';
import { FirestoreNutritionPlanService } from '../services/firestore-nutritionplan.service';
import { NutritionService } from '../services/nutrition.service';
import { Moment } from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-config-editor-dialog',
  templateUrl: './config-editor-dialog.component.html',
  styleUrls: ['./config-editor-dialog.component.css']
})
export class NutritionPlanConfigEditorDialogComponent implements OnInit {

  public isAutoFillMode = false
  public isDuplicationMode = false
  public autoFillOnlySelectedDays = false
  public allowFillOnlySelectedDays = true
  public canEdit = true
  public canDelete = false

  public user: User
  public config: CycleConfig
  public planConfig: NutritionPlanConfig
  public selectedSituation: Situation
  public mealSuggestionAccuracy: string
  public allowWeightAdjustment: boolean = false

  public mealTypeTranslations = MealTypeTranslation
  public mealTypes = Object.values(MealType).filter(value => typeof value === 'string')
  public hasStructuralChanges = false
  public needsPlanConfigRebuild = false

  constructor(public dialogRef: MatDialogRef<NutritionPlanConfigEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {config: CycleConfig, planConfig: NutritionPlanConfig, user: User, isAutoFillMode: boolean, isDuplicationMode: boolean, allowFillOnlySelectedDays: boolean, canEdit: boolean, mealSuggestionAccuracy: string, allowWeightAdjustment: boolean }, private nutritionPlanService: FirestoreNutritionPlanService, private nutritionService: NutritionService, private toastr: ToastrService, private renderer: Renderer2, public translate: TranslateService) {
    this.config = data.config
    this.planConfig = data.planConfig
    if (this.config) {
      this.selectedSituation = this.config.situations[0]
      this.canDelete = this.config.isInFuture()
    } else if (this.planConfig) {
      this.canDelete = this.planConfig.isInFuture() || !this.planConfig.isActive
    }
    this.user = data.user
    this.isAutoFillMode = data.isAutoFillMode || false
    this.isDuplicationMode = data.isDuplicationMode || false
    if (data.allowFillOnlySelectedDays != null) this.allowFillOnlySelectedDays = data.allowFillOnlySelectedDays
    this.hasStructuralChanges = false
    this.needsPlanConfigRebuild = false
    this.canEdit = data.canEdit != null ? data.canEdit : true
    this.mealSuggestionAccuracy = data.mealSuggestionAccuracy
    this.allowWeightAdjustment = data.allowWeightAdjustment
    this.updatePossibleSituations()
  }

  hasRecipesInDatabase() {
    return this.nutritionService.recipes?.length > 10
  }

  ngOnInit(): void {
    this.allTags = Tag.getAllTags()
    this.nutritionService.customTags.forEach(tag => {
      this.allTags.push(tag)
    })
    this.allTags.sort((a, b) => a.getPrintableName(this.translate).localeCompare(b.getPrintableName(this.translate)))
    // Close tag search dropdowns when clicking outside of list.
    this.renderer.listen('window', 'click',(e) => {
      this.planConfig?.selectedNutritionPlan.mealConfigs.forEach(config => config.searchInputFocused = false)
      if (this.planConfig?.selectedNutritionPlan) this.planConfig.selectedNutritionPlan.searchInputFocused = false
    })
    this.mealConfigsChanged()
  }
  
  onIsRepeatingChanged(value: boolean) {
    this.planConfig.selectedNutritionPlan.isRepeating = value
    if (this.planConfig.selectedNutritionPlan.repetitionDuration == 0) this.planConfig.selectedNutritionPlan.repetitionDuration = 1
    this.needsPlanConfigRebuild = true
  }

  public today = new Date()
  startDateFilterNutritionPlanConfig = (d: Moment | null): boolean => {
    if (this.planConfig.id && this.planConfig.startDate < this.today) return false
    if (d?.toDate() < this.today && !d?.toDate().isSameDate(this.today)) return false

    if (this.user.planConfigs.length > 0) {
      var hasActiveConfig = false
      for (var c of this.user.planConfigs) {
        if (c.isActiveAtDate(d?.toDate())) hasActiveConfig = true
      }
      if (!hasActiveConfig) return true
      var maxStartDate = this.user.planConfigs[0].startDate
      if (d?.toDate() < maxStartDate || d?.toDate().isSameDate(maxStartDate)) return false
    }
    return true
  }
  endDateFilterNutritionPlanConfig = (d: Moment | null): boolean => {
    if (d?.toDate() < this.today && !d?.toDate().isSameDate(this.today)) return false
    if (this.planConfig.startDate && d?.toDate() < this.planConfig.startDate) return false
    if (this.planConfig.id) {
      if (d?.toDate() < this.planConfig.startDate || d?.toDate().isSameDate(this.planConfig.startDate)) return false
    }
    return true
  }

  initialConfig: CycleConfig = null
  possibleSituations: Situation[] = []
  onConnectSituation(situation: Situation) {
    this.planConfig.selectedNutritionPlan.connectedSituationId = situation.id
    this.mealConfigsChanged()
  }
  updatePossibleSituations() {
    if (!this.planConfig) return
    var situations = []
    var config = null
    this.user.configs.forEach(c => {
      if (c.isActiveAtDate(this.planConfig.startDate)) {
        config = c
        situations = c.situations
      }
    })
    this.initialConfig = config
    this.possibleSituations = situations
  }
  getSituationById(id: string) {
    var situation: Situation = null
    this.possibleSituations.forEach(s => {
      if (s.id == id) situation = s
    })
    return situation
  }

  onStartDateChanged(date: Date) {
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    if (this.config) {
      if (this.config.endDate) {
        this.config.endDate = date.clone().addDays(NutritionPlanComponent.dateDiffInDays(this.config.startDate, this.config.endDate))
      }
      this.config.startDate = date
    }
    if (this.planConfig) {
      if (this.planConfig.endDate) {
        this.planConfig.endDate = date.clone().addDays(NutritionPlanComponent.dateDiffInDays(this.planConfig.startDate, this.planConfig.endDate))
      }
      this.planConfig.startDate = date
    }
    this.updatePossibleSituations()
  }
  onEndDateChanged(date: Date) {
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    if (this.config) {
      this.config.endDate = date
    } 
    if (this.planConfig) {
      this.planConfig.endDate = date
    }
  }

  onRemoveEndDate() {
    this.planConfig.endDate = null
  }
  canRemoveEndDate() {
    var isLastConfig = true
    this.user.planConfigs.forEach(c => {
      if (this.planConfig.startDate <= c.startDate && c.id != this.planConfig.id) isLastConfig = false
    })
    return isLastConfig
  }

  onSelectPlan(plan: NutritionPlanV2) {
    this.planConfig.selectedNutritionPlan = plan
    this.mealConfigsChanged()
    NutritionPlanComponent.assignAllMatchingRecipes(this.planConfig, this.nutritionService.recipes)
  }
  onAddAdditionalPlan() {
    var plan = this.planConfig.selectedNutritionPlan.clone()
    plan.id = null
    plan.name = (plan.name || '') + ' ' + this.translate.instant('Kopie')
    plan.repeatingMeals = []
    this.planConfig.nutritionPlans.push(plan)
    this.onSelectPlan(plan)
    this.needsPlanConfigRebuild = true
  }
  onDeletePlan(plan: NutritionPlanV2) {
    plan.deleted = true
    this.planConfig.selectedNutritionPlan = this.planConfig.nutritionPlans[0]
  }

  onAddNewMealConfig() {
    this.planConfig.selectedNutritionPlan.mealConfigs.push(new NutritionPlanMealConfig())
    this.updateMealConfigPositions()
    this.mealConfigsChanged()
    this.hasStructuralChanges = true
  }
  onRemoveMealConfig(mealConfig: NutritionPlanMealConfig) {
    this.planConfig.selectedNutritionPlan.mealConfigs.splice(this.planConfig.selectedNutritionPlan.mealConfigs.indexOf(mealConfig), 1)
    this.updateMealConfigPositions()
    this.mealConfigsChanged()
    this.hasStructuralChanges = true
  }
  onMealTypeSelected(mealConfig: NutritionPlanMealConfig, mealType: MealType) {
    mealConfig.mealType = mealType
    this.mealConfigsChanged()
  }
  updateMealConfigPositions() {
    for (var i = 0; i < this.planConfig.selectedNutritionPlan.mealConfigs.length; i++) {
      this.planConfig.selectedNutritionPlan.mealConfigs[i].position = i
    }
  }

  dropMealConfig(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.planConfig.selectedNutritionPlan.mealConfigs, event.previousIndex, event.currentIndex);
    this.mealConfigsChanged()
    this.hasStructuralChanges = true
  }

  allTags: Tag[]
  onFocusRestrictionSearchInput(mealNumber: number) {
    // Set cursor on correct input field.
    if (mealNumber == -1) {
      this.planConfig.selectedNutritionPlan.searchInputFocused = true
    } else {
      this.planConfig.selectedNutritionPlan.mealConfigs[mealNumber].searchInputFocused = true
    }
  }
  onRecipeTagSearchInputChanged(value: string, mealNumber: number) {
    console.log('Search for: ' + value + ' in meal ' + mealNumber)
    if (mealNumber == -1) {
      this.planConfig.selectedNutritionPlan.tagSearchResults = []
      this.allTags.forEach(tag => {
        if (tag.getPrintableName(this.translate).toLowerCase().includes(value.toLowerCase()) && !this.planConfig.selectedNutritionPlan.hasRestriction(tag, this.translate)) this.planConfig.selectedNutritionPlan.tagSearchResults.push(tag)
      })
      this.planConfig.selectedNutritionPlan.searchInputFocused = true
    } else {
      this.planConfig.selectedNutritionPlan.mealConfigs[mealNumber].tagSearchResults = []
      this.allTags.forEach(tag => {
        if (tag.getPrintableName(this.translate).toLowerCase().includes(value.toLowerCase()) && !this.planConfig.selectedNutritionPlan.mealConfigs[mealNumber].hasRestriction(tag, this.translate) && !this.planConfig.selectedNutritionPlan.hasRestriction(tag, this.translate)) this.planConfig.selectedNutritionPlan.mealConfigs[mealNumber].tagSearchResults.push(tag)
      })
      this.planConfig.selectedNutritionPlan.mealConfigs[mealNumber].searchInputFocused = true
    }
  }
  onRecipeTagSearchResultSelected(restriction: Tag, mealNumber: number, exclude: boolean = false) {
    var restriction = restriction.clone()
    if (exclude) restriction.setExcluding()
    if (mealNumber == -1) {
      if (!this.planConfig.selectedNutritionPlan.hasRestriction(restriction, this.translate)) this.planConfig.selectedNutritionPlan.tagRestrictions.push(restriction)
      this.planConfig.selectedNutritionPlan.searchInputFocused = false
    } else {
      if (!this.planConfig.selectedNutritionPlan.mealConfigs[mealNumber].hasRestriction(restriction, this.translate)) this.planConfig.selectedNutritionPlan.mealConfigs[mealNumber].tagRestrictions.push(restriction)
      this.planConfig.selectedNutritionPlan.mealConfigs[mealNumber].searchInputFocused = false
    }
    NutritionPlanComponent.assignAllMatchingRecipes(this.planConfig, this.nutritionService.recipes)
  }
  onRemoveTagFromMealConfig(tag: Tag, mealConfig: NutritionPlanMealConfig) {
    if (mealConfig == null) {
      if (this.planConfig.selectedNutritionPlan.tagRestrictions.includes(tag)) this.planConfig.selectedNutritionPlan.tagRestrictions.splice(this.planConfig.selectedNutritionPlan.tagRestrictions.indexOf(tag), 1)
    } else {
      if (mealConfig.tagRestrictions.includes(tag)) mealConfig.tagRestrictions.splice(mealConfig.tagRestrictions.indexOf(tag), 1)
    }
    NutritionPlanComponent.assignAllMatchingRecipes(this.planConfig, this.nutritionService.recipes)
  }

  // AutoFill settings:

  nutritionPlanCalorieDistributionSliderValues = []
  nutritionPlanCalorieDistributionSliderConfig: any

  mealConfigsChanged() {
    if (!this.planConfig) return
    var total = 0
    this.planConfig.selectedNutritionPlan.mealConfigs.forEach(c => {
      if (c.mealSizeProportion == null || c.mealSizeProportion == undefined) c.mealSizeProportion = 0.2
      total = total + c.mealSizeProportion
    })
    this.nutritionPlanCalorieDistributionSliderConfig = null
    this.nutritionPlanCalorieDistributionSliderValues = []
    var connect = [true]
    for (var i = 0; i < this.planConfig.selectedNutritionPlan.mealConfigs.length - 1; i++) {
      var lastValue = i == 0 ? 0 : this.nutritionPlanCalorieDistributionSliderValues[i - 1] / 100
      this.nutritionPlanCalorieDistributionSliderValues.push( (this.planConfig.selectedNutritionPlan.mealConfigs[i].mealSizeProportion / total + lastValue) * 100 )
      connect.push(true)
    }
    DashboardComponent.delay(250).then(() => {
      this.nutritionPlanCalorieDistributionSliderConfig = {
        behaviour: 'drag',
        connect: connect,
        step: 1,
        range: {
          min: 0,
          max: 100
        },
      };
      this.nutritionPlanCalorieDistributionSliderConfig.connect = connect
      this.onAutoFillSliderChanged()
    })
  }

  onAutoFillSliderChanged() {
    var elements = document.getElementsByClassName('noUi-connect')
    var totalWidth = 0
    for (var i = 0; i < elements.length; i++) {
      var element = (<HTMLElement> elements[i])
      var percentage = this.getPlannedMealCaloriePercentage(i) * 100
      var mealType = this.planConfig.selectedNutritionPlan.mealConfigs[i].getType(this.translate) || this.translate.instant('Mahlzeit')
      if (mealType?.length == 0) mealType = this.translate.instant('Mahlzeit') + ' ' + (i + 1)
      if (percentage > 10) {
        element.innerHTML = this.getPlannedCaloriesForMeal(i) + ' kcal (' + percentage.roundToInt() + '%)' + '<div class="mealtype-label">' + mealType + '</div>'
      } else {
        element.innerHTML = this.getPlannedCaloriesForMeal(i) + ''
      }
      element.style.width = percentage + '%'
      element.style.left = totalWidth + '%'
      element.style.transform = 'none'
      totalWidth += percentage
    }
    
    for (var i = 0; i < this.planConfig.selectedNutritionPlan.mealConfigs.length; i++) {
      this.planConfig.selectedNutritionPlan.mealConfigs[i].mealSizeProportion = this.getPlannedMealCaloriePercentage(i)
    }
  }

  getPlannedMealCaloriePercentage(mealNumber: number) {
    if (!this.planConfig.selectedNutritionPlan) return 0
    if (mealNumber == 0) {
      var percentage = (this.nutritionPlanCalorieDistributionSliderValues[0] || this.nutritionPlanCalorieDistributionSliderValues) / 100
      if (!percentage) return 0
      return percentage
    } else if (mealNumber == this.planConfig.selectedNutritionPlan.mealConfigs.length - 1) {
      var percentage = (100 - (this.nutritionPlanCalorieDistributionSliderValues[mealNumber - 1] || this.nutritionPlanCalorieDistributionSliderValues)) / 100
      return percentage
    } else {
      var percentage = (this.nutritionPlanCalorieDistributionSliderValues[mealNumber] - this.nutritionPlanCalorieDistributionSliderValues[mealNumber - 1]) / 100
      return percentage
    }
  }
  getPlannedCaloriesForMeal(mealNumber: number) {
    if (!this.planConfig.selectedNutritionPlan) return 0
    var totalCalories = this.initialConfig.getNutritionalGoalForDateAndSituation(this.planConfig.startDate, this.planConfig.selectedNutritionPlan.connectedSituationId)?.getCalories() || 2000
    if (mealNumber == 0) {
      var percentage = (this.nutritionPlanCalorieDistributionSliderValues[0] || this.nutritionPlanCalorieDistributionSliderValues) / 100
      return Math.round(percentage * totalCalories)
    } else if (mealNumber == this.planConfig.selectedNutritionPlan.mealConfigs.length - 1) {
      var percentage = (100 - (this.nutritionPlanCalorieDistributionSliderValues[mealNumber - 1] || this.nutritionPlanCalorieDistributionSliderValues)) / 100
      return Math.round(percentage * totalCalories)
    } else {
      var percentage = (this.nutritionPlanCalorieDistributionSliderValues[mealNumber] - this.nutritionPlanCalorieDistributionSliderValues[mealNumber - 1]) / 100
      return Math.round(percentage * totalCalories)
    }
  }

  onSetMealSuggestionAccuracy(accuracy: string) {
    this.mealSuggestionAccuracy = accuracy
  }

  onSaveConfig() {
    if (this.config) {
      this.config.situations.forEach(situation => {
        situation.nutritionalGoals.forEach(goal => {
          goal.situationId = situation.id
          goal.situation = situation
        })
      })
      if (this.config.isValid()) {
        this.onCloseDialog(true, false)
      } else {
        this.toastr.error(this.translate.instant("Korrigiere/Vervollständige die Nährstoffziele, um zu speichern."), "",  {
          positionClass: 'toast-bottom-center'
        });
      }
    } else {
      this.onCloseDialog(true, false)
    }
  }
  onConfirmAutoFill() {
    this.onSaveConfig()
  }
  onCloseConfig() {
    this.onCloseDialog(false, false)
  }
  onDeleteConfig() {
    this.onCloseDialog(false, true)
  }

  onCloseDialog(shouldSave: boolean, shouldDelete: boolean) {
    this.dialogRef.close({shouldSave: shouldSave, shouldDelete: shouldDelete, config: this.config, planConfig: this.planConfig, autoFillOnlySelectedDays: this.autoFillOnlySelectedDays, hasStructuralChanges: this.hasStructuralChanges, needsPlanConfigRebuild: this.needsPlanConfigRebuild, mealSuggestionAccuracy: this.mealSuggestionAccuracy, allowWeightAdjustment: this.allowWeightAdjustment });
  }
}
