import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { LanguageService } from 'src/app/services/language.service';
import { UtilityService } from 'src/app/services/utility.service';
import { WeightConversionPipe } from 'src/app/weight.pipe';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rpe-table-dialog',
  templateUrl: './rpe-table-dialog.component.html',
  styleUrls: ['./rpe-table-dialog.component.css']
})
export class RpeTableDialogComponent {

  static repList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
  static rpeList = [10, 9.5, 9, 8.5, 8, 7.5, 7, 6.5, 6, 5.5]

  // Tuscherers RPE Table: https://www.data-drivenstrength.com/guiides/intermediate-sets-for-better-training-cycles
  static rpeBaseValues = [
    1, 0.955, 0.922, 0.892, 0.863, 0.837, 0.811, 0.786, 0.762, 0.739, 0.707, 0.68,
    0.653, 0.626, 0.599, 0.584, 0.57, 0.557, 0.544, 0.532, 0.52, 0.509, 0.498,
    0.488, 0.477, 0.468, 0.458, 0.449, 0.44, 0.432,
  ];

  static calisthenicsBaseValues = [
    [1, 0.955, 0.922, 0.892, 0.863, 0.837, 0.811, 0.786, 0.762, 0.739, 0.707],
    [
      0.9775, 0.9385, 0.907, 0.8775, 0.85, 0.824, 0.7985, 0.774, 0.7505, 0.723,
      0.6935,
    ],
    [0.955, 0.922, 0.892, 0.863, 0.837, 0.811, 0.786, 0.762, 0.739, 0.707, 0.68],
    [
      0.9385, 0.907, 0.8775, 0.85, 0.824, 0.7985, 0.774, 0.7505, 0.723, 0.6935,
      0.6665,
    ],
    [0.922, 0.892, 0.863, 0.837, 0.811, 0.786, 0.762, 0.739, 0.707, 0.68, 0.653],
    [
      0.907, 0.8775, 0.85, 0.824, 0.7985, 0.774, 0.7505, 0.723, 0.6935, 0.6665,
      0.6395,
    ],
    [0.892, 0.863, 0.837, 0.811, 0.786, 0.762, 0.739, 0.707, 0.68, 0.653, 0.626],
    [
      0.8775, 0.85, 0.824, 0.7985, 0.774, 0.7505, 0.723, 0.6935, 0.6665, 0.6395,
      0.6125,
    ],
    [0.863, 0.837, 0.811, 0.786, 0.762, 0.739, 0.707, 0.68, 0.653, 0.626, 0.599],
    [
      0.85, 0.824, 0.7985, 0.774, 0.7505, 0.723, 0.6935, 0.6665, 0.6395, 0.6125,
      0.5915,
    ],
];

  useCalisthenicsFormula: boolean = false
  user: User

  rpeList = RpeTableDialogComponent.rpeList
  repList = RpeTableDialogComponent.repList

  values = []

  weight: number = 100
  reps: number = 1
  rpe: number = 10
  fixedOneRM: number
  // For calisthenics
  bodyWeight: number = 80
  bodyWeightPercentage: number = 50

  strgPressed: boolean = false

  constructor(public dialogRef: MatDialogRef<RpeTableDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { weight: number, reps: number, rpe: number, fixedOneRM: number, useCalisthenicsFormula: boolean, user: User }, public userService: FirestoreService, public utilityService: UtilityService, private toastr: ToastrService, public languageService: LanguageService, private weightPipe: WeightConversionPipe, private transalte: TranslateService) {
    if (data.weight != null) this.weight = data.weight
    if (data.reps != null) this.reps = data.reps
    if (data.rpe != null) this.rpe = data.rpe
    if (data.fixedOneRM != null) {
      this.fixedOneRM = data.fixedOneRM
      this.weight = data.fixedOneRM
    }
    this.user = data.user ?? null
    if (this.user && this.user.latestBodyWeight) {
      this.bodyWeight = this.user.latestBodyWeight
    }
    if (data.useCalisthenicsFormula != null) {
      this.useCalisthenicsFormula = data.useCalisthenicsFormula
    }
    this.calculateValues()

    dialogRef.disableClose = true;
    dialogRef.backdropClick().subscribe(() => {
      this.onCloseDialog()
    })
  }

  get oneRM() {
    if (this.fixedOneRM) return this.fixedOneRM
    if (this.useCalisthenicsFormula) {
      // (REPS + 10 - RPE) * 0.033 * (LOAD + %BW * BW) + (LOAD + %BW * BW) - %BW * BW = 1RM
      return ((this.weight + this.bodyWeightPercentage / 100 * this.bodyWeight) * (this.reps + 10 - this.rpe) * 0.0333 + (this.weight + this.bodyWeightPercentage / 100 * this.bodyWeight) - this.bodyWeightPercentage / 100 * this.bodyWeight)
    }
    // (REPS + 10 - RPE) * 0.033 * LOAD + LOAD = 1RM
    return ((this.weight * (this.reps + 10 - this.rpe) * 0.0333) + this.weight)
  }
  getPrintableOneRm() {
    return this.weightPipe.transform(this.oneRM, this.languageService.selectedUnitSystem, true)
  }

  @HostListener('window:keydown', ['$event'])
  async onKeyDown(event: KeyboardEvent) {
      if ((event.metaKey || event.ctrlKey)) {
          event.preventDefault();
          this.strgPressed = true
      }
  }

  @HostListener('document:keyup', ['$event'])
  async onKeyUp(event: KeyboardEvent) {
      this.strgPressed = false
  }

  onValueChanged(value: any, type: string) {
    if (type === "weight") {
      this.weight = parseFloat(value)
    } else if (type === "reps") {
      this.reps = parseInt(value)
    } else if (type === "rpe") {
      this.rpe = parseFloat(value)
    } else if (type === "bodyWeight") {
      this.bodyWeight = parseFloat(value)
    } else if (type === "bodyWeightPercentage") {
      this.bodyWeightPercentage = parseFloat(value)
    }
    this.fixedOneRM = null
  }

  calculateValues() {
    for (var i = 0; i < 11; i++) {
      var tableIndex = i
      for (var j = 0; j < 10; j++) {
        var value = 0
        if (this.useCalisthenicsFormula) {
          value = RpeTableDialogComponent.calisthenicsBaseValues[j][tableIndex]
        } else {
          if (j == 0) {
            value = RpeTableDialogComponent.rpeBaseValues[tableIndex]
            tableIndex += 1
          } else if (j % 2 == 0) {
            value = RpeTableDialogComponent.rpeBaseValues[tableIndex]
            tableIndex += 1
          } else {
            value = RpeTableDialogComponent.rpeBaseValues[tableIndex] + (RpeTableDialogComponent.rpeBaseValues[tableIndex - 1] - RpeTableDialogComponent.rpeBaseValues[tableIndex]) / 2
          }
        }
        this.values.push(value)
      }
    }

  }

  getTableValue(repIndex: number, rpeIndex: number) {
    if (this.useCalisthenicsFormula) {
      return Math.floor(((this.oneRM + this.bodyWeight) * this.values[repIndex * 10 + rpeIndex] - this.bodyWeight) * 10) / 10
    }
    return Math.floor(this.oneRM * this.values[repIndex * 10 + rpeIndex] * 10) / 10;
  }
  getWeight(rpe: number, reps: number) {
    var reps = reps + 10 - rpe
    var weight = this.oneRM / (reps * 0.0333 + 1)
    return weight
  }
  getPercentage(repIndex: number, rpeIndex: number) {
    return this.values[repIndex * 10 + rpeIndex] * 100
  }

  onClickValue(repIndex: number, rpeIndex: number, reps: number, rpe: number) {
    if (this.strgPressed) {
      this.dialogRef.close({ weight: parseFloat(this.getTableValue(repIndex, rpeIndex).toFixed(1)), reps: reps, rpe: rpe, useCalisthenicsFormula: this.useCalisthenicsFormula })
    } else {
      this.toastr.success(this.transalte.instant("In Zwischenablage kopiert"), "",  {
        positionClass: 'toast-bottom-center'
      })
    }
  }

  onCloseDialog() {
    this.dialogRef.close({ useCalisthenicsFormula: this.useCalisthenicsFormula })
  }
}
