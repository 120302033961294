import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PaymentAnalyticsConfig, PaymentAnalyticsItemSize} from "../payment-analytics.component";

@Component({
  selector: 'app-analytics-config-menu',
  templateUrl: './analytics-config-menu.component.html',
  styleUrls: ['./analytics-config-menu.component.css']
})
export class AnalyticsConfigMenuComponent {

  protected readonly DashboardAnalyticsItemSize = PaymentAnalyticsItemSize;

  @Input()
  public statisticConfig: PaymentAnalyticsConfig;

  @Output()
  public onRemoveConfigEvent: EventEmitter<PaymentAnalyticsConfig> = new EventEmitter<PaymentAnalyticsConfig>();
  @Output()
  public onChangeConfigSizeEvent: EventEmitter<PaymentAnalyticsConfig> = new EventEmitter<PaymentAnalyticsConfig>();
  @Output()
  public onMoveConfigUpEvent: EventEmitter<PaymentAnalyticsConfig> = new EventEmitter<PaymentAnalyticsConfig>();
  @Output()
  public onMoveConfigDownEvent: EventEmitter<PaymentAnalyticsConfig> = new EventEmitter<PaymentAnalyticsConfig>();


  onRemoveConfig() {
    this.onRemoveConfigEvent.emit(this.statisticConfig);
  }

  onChangeConfigSize(size: PaymentAnalyticsItemSize) {
    this.statisticConfig.size = size
    this.onChangeConfigSizeEvent.emit(this.statisticConfig);
  }

  onMoveConfigUp() {
    this.onMoveConfigUpEvent.emit(this.statisticConfig);
  }

  onMoveConfigDown() {
    this.onMoveConfigDownEvent.emit(this.statisticConfig);
  }
}
