import { Component, Input } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { FirestoreService } from '../services/firestore.service';

interface ILanguageSelectorComponent {
  availableLanguageCodes: string[];
  selectedLanguageCode: string;
  setSelectedLanguageCode(code: string): void;
  getLanguageName(code: string): string;
  getIconPath(code: string): string;
}

export class LanguageSelectorBase {
  getLanguageName(code: string): string {
    switch (code) {
      case 'de':
        return 'Deutsch';
      case 'en':
        return 'English';
      case 'es':
        return 'Español';
        case 'fr':
          return 'Français';
      default:
        return 'Unknown';
    }
  }

  getIconPath(code: string): string {
    return LanguageSelectorBase.getIconPath(code);
  }

  static getIconPath(code: string): string {
    switch (code) {
      case 'de':
        return 'assets/icons/icon-germany.png';
      case 'en':
        return "assets/icons/icon-united-kingdom.png";
      case 'es':
        return "assets/icons/icon-spain.png";
      case 'fr':
        return "assets/icons/icon-france.png";
      default:
        return 'Unknown';
    }
  }
}

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css']
})
export class LanguageSelectorComponent extends LanguageSelectorBase implements ILanguageSelectorComponent {

  @Input() public customDropdownMenuClass: string = null;

  constructor(public languageService: LanguageService) {
    super();
  }

  public availableLanguageCodes: string[] = this.languageService.availableLanguages.map(l => l.code);

  public get selectedLanguageCode(): string {
    return this.languageService.selectedLanguageCode;
  }

  setSelectedLanguageCode(code: string): void {
    this.languageService.selectLanguage(code);
  }
}


@Component({
  selector: 'app-global-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css']
})
export class GlobalLanguageSelectorComponent extends LanguageSelectorBase implements ILanguageSelectorComponent {

  @Input() public customDropdownMenuClass: string = null;

  constructor(public translate: TranslateService, private userService: FirestoreService) {
    super();
  }

  public get availableLanguageCodes(): string[] {
    return this.translate.langs;
  }

  public get selectedLanguageCode(): string {
    return this.translate.currentLang;
  }

  setSelectedLanguageCode(code: string): void {
    this.translate.use(code);
    let loggedInUser = this.userService.getLoggedInUser();
    if(loggedInUser?.isCoach) {
      this.userService.updateSelectedPortalLanguageCode(loggedInUser.coach, code);
    }
  }
}

