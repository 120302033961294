<div class="">
    <div class="spacer-10"></div>
    <div class="row">
        <div class="d-none d-md-block col-md-3 col-lg-3 col-xl-4 col-xxl-4">
            <div class="icon-button float-left marginright-25" (click)="toggleDiaryExportDialog()" translate>
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="padding-4 bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                    <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"/>
                </svg>
                Tagebuch exportieren
            </div>
        </div>
        <div id="diaryexport-dialog" class="dialog-wrapper">
            <div class="dialog layout-box">
                <div class="dialog-content-wrapper margin-0">
                    <h3 class="display-inlineblock" translate>Tagebuch als PDF exportieren</h3>
                    <div class="icon-button float-right" (click)="toggleDiaryExportDialog()">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                    <div class="content">
                        <div class="" translate>
                            Von: 
                            <span class="marginleft-10">{{diaryExportStartDate?.asFormatedString()}}</span>
                            <input [matDatepicker]="startDatePicker" [value]="diaryExportStartDate" class="visibility-hidden width-0 padding-0" (dateInput)="onDiaryExportStartDateChanged($event.value?.toDate())">
                            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker ></mat-datepicker><br>
                        </div>
                        <div class="" translate>
                            Bis: 
                            <span class="marginleft-10">{{diaryExportEndDate?.asFormatedString()}}</span>
                            <input [matDatepicker]="endDatePicker" [value]="diaryExportEndDate" class="visibility-hidden width-0 padding-0" (dateInput)="onDiaryExportEndDateChanged($event.value?.toDate())">
                            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #endDatePicker ></mat-datepicker><br>
                        </div>
                        <div class="spacer-25"></div>
                    </div>
                    <div class="actions" translate>
                        <div *ngIf="diaryExportStartDate && diaryExportEndDate" class="icon-button" (click)="onConfirmDiaryExport()">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                            </svg>
                            Exportieren
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-5 col-md-4 col-lg-4 col-xl-3 col-xxl-3 date-selection">
            <button class="button change-date p-0" (click)="backOneDay()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                </svg>
            </button>
            
            <input [matDatepicker]="diaryDatePicker" class="visibility-hidden width-0 padding-0" (dateInput)="onDiaryDateChanged($event.value?.toDate())">
            <mat-datepicker-toggle matSuffix [for]="diaryDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #diaryDatePicker></mat-datepicker>
            <div class="date-display">{{weekDays[currentDate.getDayNumber()].substr(0, 2)}}., {{currentDate.getDate()}}.{{currentDate.getMonth() + 1}}.{{currentDate.getFullYear()}}</div>
            <button class="button change-date p-0" (click)="forwardOneDay()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </button>
            
            <div class="spacer-10"></div>
        </div>
        <div class="col-5 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
            <button class="button-filled white small" (click)="goToToday()" translate>Heute</button>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-2">
            <div class="icon-button marginright-25" (click)="refresh()" translate>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                </svg>
                Aktualisieren
            </div>
        </div>
    </div>
</div>
<div class="container-fluid padding-0">
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="layout-box adjust-mobile daily-summary">
                <h3 translate>Zusammenfassung</h3>
                <div class="container-fluid padding-0">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-xl-3 col-xxl-4 paddingbottom-10">
                            <div class="circulargraph">
                                <dx-circular-gauge
                                    class="gauge"
                                    [value]="math.min(displayedUser.getCalorieGoal(currentDate), displayedUser.getCalorieSummaryForMeals())">
                                    <dxo-size [height]="80" [width]="80"></dxo-size>
                                    <dxo-range-container orientation="center" backgroundColor="#E6EAEF" width="0" offset="0">
                                    </dxo-range-container>
                                    <dxo-value-indicator
                                        offset="0"
                                        type="rangebar"
                                        color="{{colorCalorieGraph}}"
                                        backgroundColor="{{displayedUser.getCalorieSummaryForMeals() > displayedUser.getCalorieGoal(currentDate) ? '#D9253D' : 'var(--darkestBackgroundColor)'}}"
                                        size="10">
                                    </dxo-value-indicator>
                                    <dxo-scale
                                        [startValue]="0"
                                        [endValue]="math.max(displayedUser.getCalorieGoal(currentDate), displayedUser.getCalorieSummaryForMeals())">
                                        <dxo-label [visible]="false"></dxo-label>
                                        <dxo-tick [visible]="false"></dxo-tick>
                                    </dxo-scale>
                                    <dxo-geometry
                                        [startAngle]="90"
                                        [endAngle]="90">
                                    </dxo-geometry>
                                </dx-circular-gauge>
                            </div>
                            <div class="circulargraph-description">
                                <span class="consumed">{{displayedUser.getCalorieSummaryForMeals().roundToPlaces(0)}}</span><br>
                                <span class="total">/ {{displayedUser.getCalorieGoal(currentDate)}}</span><br>
                                {{'kcal' | translate}} ({{(displayedUser.getCalorieSummaryForMeals() / displayedUser.getCalorieGoal(currentDate) * 100).toFixed(0)}}%)
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-xl-5 col-xxl-4">
                            <div class="lineargraph">
                                <dx-linear-gauge
                                    [value]="math.min(displayedUser.getCarbohydratesGoal(currentDate), displayedUser.getCarbohydratesSummaryForMeals())">
                                    <dxo-size [height]="20" [width]="120"></dxo-size>
                                    <dxo-value-indicator
                                        offset="0"
                                        type="rangebar"
                                        color="{{colorCarbohydratesGraph}}"
                                        backgroundColor="{{displayedUser.getCarbohydratesSummaryForMeals() > displayedUser.getCarbohydratesGoal(currentDate) ? '#FF5169' : 'var(--darkestBackgroundColor)'}}"
                                        size="10">
                                    </dxo-value-indicator>
                                    <dxo-scale
                                        [startValue]="0"
                                        [endValue]="math.max(displayedUser.getCarbohydratesGoal(currentDate), displayedUser.getCarbohydratesSummaryForMeals())">
                                        <dxo-label [visible]="false"></dxo-label>
                                    </dxo-scale>
                                </dx-linear-gauge>
                            </div>
                            <div class="lineargraph-description">
                                <span class="consumed">{{displayedUser.getCarbohydratesSummaryForMeals().roundToPlaces(0)}}</span>
                                <span class="total">/ {{displayedUser.getCarbohydratesGoal(currentDate)}}g </span>
                                <span translate>Kohlenhydrate</span> ({{(displayedUser.getCarbohydratesSummaryForMeals() / displayedUser.getCarbohydratesGoal(currentDate) * 100).toFixed(0)}}%)
                            </div>
                            <div class="spacer-10"></div>

                            <div class="lineargraph">
                                <dx-linear-gauge
                                    [value]="math.min(displayedUser.getProteinGoal(currentDate), displayedUser.getProteinSummaryForMeals())">
                                    <dxo-size [height]="20" [width]="120"></dxo-size>
                                    <dxo-value-indicator
                                        offset="0"
                                        type="rangebar"
                                        color="{{colorProteinGraph}}"
                                        backgroundColor="{{displayedUser.getProteinSummaryForMeals() > displayedUser.getProteinGoal(currentDate) ? '#FF5169' : 'var(--darkestBackgroundColor)'}}"
                                        size="10">
                                    </dxo-value-indicator>
                                    <dxo-scale
                                        [startValue]="0"
                                        [endValue]="math.max(displayedUser.getProteinGoal(currentDate), displayedUser.getProteinSummaryForMeals())">
                                        <dxo-label [visible]="false"></dxo-label>
                                    </dxo-scale>
                                </dx-linear-gauge>
                            </div>
                            <div class="lineargraph-description">
                                <span class="consumed">{{displayedUser.getProteinSummaryForMeals().roundToPlaces(0)}}</span>
                                <span class="total">/ {{displayedUser.getProteinGoal(currentDate)}}g </span>
                                <span translate>Eiweiß</span> ({{(displayedUser.getProteinSummaryForMeals() / displayedUser.getProteinGoal(currentDate) * 100).toFixed(0)}}%)
                            </div>
                            <div class="spacer-10"></div>
                        
                            <div class="lineargraph">
                                <dx-linear-gauge
                                    [value]="math.min(displayedUser.getFatGoal(currentDate), displayedUser.getFatSummaryForMeals())">
                                    <dxo-size [height]="20" [width]="120"></dxo-size>
                                    <dxo-value-indicator
                                        offset="0"
                                        type="rangebar"
                                        color="{{colorFatGraph}}"
                                        backgroundColor="{{displayedUser.getFatSummaryForMeals() > displayedUser.getFatGoal(currentDate) ? '#FF5169' : 'var(--darkestBackgroundColor)'}}"
                                        size="10">
                                    </dxo-value-indicator>
                                    <dxo-scale
                                        [startValue]="0"
                                        [endValue]="math.max(displayedUser.getFatGoal(currentDate), displayedUser.getFatSummaryForMeals())">
                                        <dxo-label [visible]="false"></dxo-label>
                                    </dxo-scale>
                                </dx-linear-gauge>
                            </div>
                            <div class="lineargraph-description">
                                <span class="consumed">{{displayedUser.getFatSummaryForMeals().roundToPlaces(0)}}</span>
                                <span class="total">/ {{displayedUser.getFatGoal(currentDate)}}g </span>
                                <span translate>Fett</span> ({{(displayedUser.getFatSummaryForMeals() / displayedUser.getFatGoal(currentDate) * 100).toFixed(0)}}%)
                            </div>
                        </div>

                        <div class="col-6 col-sm-6 col-xl-4">
                            <ng-container *ngTemplateOutlet="graphTemplate; context: {name: 'Zucker' | translate, value: displayedUser.getSugarSummaryForMeals(), goalValue: displayedUser.getCommonTargetValue('sugar', currentDate) || displayedUser.getCarbohydratesSummaryForMeals(), roundToPlaces: 0 }"></ng-container>
                            <div class="spacer-10"></div>
                            <ng-container *ngTemplateOutlet="graphTemplate; context: {name: 'Gesätt. Fetts.' | translate, value: displayedUser.getSaturatedFatSummaryForMeals(), goalValue: displayedUser.getCommonTargetValue('saturatedFat', currentDate) || displayedUser.getFatSummaryForMeals(), roundToPlaces: 0 }"></ng-container>
                            <div class="spacer-10"></div>
                            <ng-container *ngTemplateOutlet="graphTemplate; context: {name: 'Ballaststoffe' | translate, value: displayedUser.getFibreSummaryForMeals(), goalValue: displayedUser.getCommonTargetValue('fibre', currentDate) || 30, roundToPlaces: 0 }"></ng-container>
                            <div class="spacer-10"></div>
                            <ng-container *ngTemplateOutlet="graphTemplate; context: {name: 'Salz' | translate, value: displayedUser.getSaltSummaryForMeals(), goalValue: displayedUser.getCommonTargetValue('salt', currentDate) || 5, roundToPlaces: 1 }"></ng-container>
                            <div class="spacer-10"></div>

                            <ng-template #graphTemplate let-name="name" let-value="value" let-goalValue="goalValue" let-roundToPlaces="roundToPlaces">
                                <div class="lineargraph">
                                    <dx-linear-gauge [value]="value">
                                        <dxo-size [height]="16" [width]="80"></dxo-size>
                                        <dxo-value-indicator offset="0" type="rangebar" color="{{colorOtherNutrientsGraph}}" backgroundColor="var(--darkestBackgroundColor)" size="8"></dxo-value-indicator>
                                        <dxo-scale [startValue]="0" [endValue]="math.max(goalValue, 0.1)"><dxo-label [visible]="false"></dxo-label>
                                        </dxo-scale>
                                    </dx-linear-gauge>
                                </div>
                                <div class="lineargraph-description small">
                                    <span class="consumed">{{value.roundToPlaces(roundToPlaces)}}</span>
                                    <span class="total">/ {{goalValue.roundToPlaces(roundToPlaces)}}g</span>
                                    {{name}} ({{goalValue > 0 ? (value / goalValue * 100).toFixed(0) : '0'}}%)
                                </div>
                            </ng-template>

                        </div>
                        <div class="nutritionalvalues-button display-inlineblock link" [popover]="nutritionalValuePopover" (click)="onNutritionalValuesFocused(displayedUser, cycleConfigOfSelectedDate)" placement="top" [outsideClick]="true" container="body" boundariesElement="window">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                            </svg>
                            <div class="label display-inlineblock marginleft-10" translate>Mikronährstoffbilanz</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="row layout-box adjust-mobile">
                <h3 class="d-inline-block" *ngIf="environment.firebaseProjectId == 'traindoo-app'" translate>Daily Check</h3>
                <h3 class="d-inline-block" *ngIf="environment.firebaseProjectId != 'traindoo-app'" translate>Tägliche Metriken</h3>
                <div class="container-fluid padding-0">
                    <div class="row">
                        <div class="col-12 col-xl-6">
                            <div class="margin-10">
                                <div class="icon color-red">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lightning" viewBox="0 0 16 16">
                                        <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09zM4.157 8.5H7a.5.5 0 0 1 .478.647L6.11 13.59l5.732-6.09H9a.5.5 0 0 1-.478-.647L9.89 2.41 4.157 8.5z"/>
                                    </svg>
                                </div>
                                <span translate [translateParams]="{kcalParameter: displayedUser?.getBurnedCalories()?.toFixed(0) || 0}">kcalParameter kcal verbrannt</span>
                                <span *ngIf="displayedUser?.getBurnedCaloriesForCalendarWeek() != null" translate [translateParams]="{kcalParameter: displayedUser?.getBurnedCaloriesForCalendarWeek()?.toFixed(0) || 0}"> | kcalParameter kcal in KW</span>
                            </div>
                        </div>
                        <div class="col-12 col-xl-6">
                            <div class="margin-10">
                                <div class="icon color-blue">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-droplet" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M7.21.8C7.69.295 8 0 8 0c.109.363.234.708.371 1.038.812 1.946 2.073 3.35 3.197 4.6C12.878 7.096 14 8.345 14 10a6 6 0 0 1-12 0C2 6.668 5.58 2.517 7.21.8zm.413 1.021A31.25 31.25 0 0 0 5.794 3.99c-.726.95-1.436 2.008-1.96 3.07C3.304 8.133 3 9.138 3 10a5 5 0 0 0 10 0c0-1.201-.796-2.157-2.181-3.7l-.03-.032C9.75 5.11 8.5 3.72 7.623 1.82z"/>
                                        <path fill-rule="evenodd" d="M4.553 7.776c.82-1.641 1.717-2.753 2.093-3.13l.708.708c-.29.29-1.128 1.311-1.907 2.87l-.894-.448z"/>
                                    </svg>
                                </div>
                                <span *ngIf="!editUserDataService.isEditModeActivated; else edit_water_intake" translate [translateParams]="{waterIntakeParameter: displayedUser?.dailyCondition?.waterIntake ? math.round(displayedUser.dailyCondition.waterIntake * 100) / 100 : 0}">
                                    waterIntakeParameter l getrunken
                                </span>
                                <ng-template #edit_water_intake translate>
                                    <input class="input small" type="text" step="0.2" min="0" pattern="[0-9]+([,\.][0-9]+)?" [ngModel]="displayedUser?.dailyCondition?.waterIntake" (input)="onWaterIntakeChanged($event.target.value)" placeholder="0.0">
                                    l getrunken
                                </ng-template>
                            </div>
                        </div>
                        <div class="col-12 col-xl-6" *ngIf="displayedUser?.getSelectedSituationName(currentDate, translate)">
                            <div class="margin-10">
                                <div class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bullseye" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10zm0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/>
                                        <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"/>
                                        <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    </svg>
                                </div>
                                {{displayedUser?.getSelectedSituationName(currentDate, translate)}}
                            </div>
                        </div>
                        <div *ngIf="loadingMetricData" class="col-12 loading-animation">
                            <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
                        </div>
                        <div class="col-12 col-xl-6" *ngIf="displayedUser?.dailyCondition?.sleepDuration && displayedUser?.dailyCondition?.metricData?.length == 0">
                            <div class="margin-10">
                                <div class="icon color-yellow">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-moon" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M14.53 10.53a7 7 0 0 1-9.058-9.058A7.003 7.003 0 0 0 8 15a7.002 7.002 0 0 0 6.53-4.47z"/>
                                    </svg>
                                </div>
                                <span translate [translateParams]="{sleepDurationParameter: displayedUser?.dailyCondition?.sleepDuration?.getPrintableSleepDuration()}">
                                    sleepDurationParameter h geschlafen
                                </span>
                            </div>
                        </div>
                        <div class="col-12" *ngIf="displayedUser?.dailyCondition?.note && displayedUser?.dailyCondition?.metricData?.length == 0">
                            <div class="margin-10">
                                <div class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sticky" viewBox="0 0 16 16">
                                        <path d="M2.5 1A1.5 1.5 0 0 0 1 2.5v11A1.5 1.5 0 0 0 2.5 15h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 15 8.586V2.5A1.5 1.5 0 0 0 13.5 1h-11zM2 2.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5V8H9.5A1.5 1.5 0 0 0 8 9.5V14H2.5a.5.5 0 0 1-.5-.5v-11zm7 11.293V9.5a.5.5 0 0 1 .5-.5h4.293L9 13.793z"/>
                                    </svg>
                                </div>
                                {{displayedUser?.dailyCondition?.note}}
                            </div>
                        </div>

                        <ng-container *ngFor="let metricData of displayedUser?.dailyCondition?.metricData">
                            <div class="col-12 metricdata-wrapper">
                                <div class="margin-top-15px">
                                    <app-metricdata-view [user]="displayedUser" [setMetricData]="metricData" [setEditMode]="editUserDataService.isEditModeActivated" (removeMetricData)="onRemoveMetricData($event)"></app-metricdata-view>
                                </div>
                            </div>
                        </ng-container>

                        <div class="col-12" *ngIf="editUserDataService.isEditModeActivated && !loadingMetricData">
                            <div class="icon-button margintop-10" [matMenuTriggerFor]="menu" translate>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                </svg>
                                Neue Metrik tracken
                            </div>
                            <mat-menu #menu="matMenu">
                                <ng-container *ngFor="let metric of getFilteredAssignedMetrics(displayedUser)">
                                    <button mat-menu-item (click)="onTrackSelectedMetric(metric, displayedUser)">
                                        <div class="icon" [innerHTML]="EventLogService.getEventItemMetricIcon(metric) | sanitizedHtml">
                                        </div>{{metric.nameTranslation.GetValue(translate.currentLang)}}
                                    </button>
                                </ng-container>
                                <ng-container *ngIf="userService.getLoggedInUser().isCoach">
                                    <mat-divider></mat-divider>
                                    <button mat-menu-item (click)="onTrackMetric(currentDate, displayedUser)" translate>
                                        <div class="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                                            </svg>
                                        </div>
                                        Weitere Metriken
                                    </button>
                                </ng-container>
                            </mat-menu>
                        </div>
                        <div class="col-12" *ngIf="editUserDataService.isEditModeActivated && !loadingMetricData">
                            <div>
                                <button class="button-filled small save-metricchanges-button" (click)="saveMetricDataChanges()" translate>Speichern</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="spacer-20"></div>
                <div *ngIf="displayedUser?.dailyCondition?.questionaireResults?.length > 0" class="container-fluid padding-0">
                    <div class="row">
                        <div class="col-12">
                            <div class="layout-box adjust-mobile">
                                <app-completed-questionaires-overview (shouldRefresh)="refresh()" [user]="displayedUser" [relevantDailyConditions]="[displayedUser?.dailyCondition]"></app-completed-questionaires-overview>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid padding-0">
    <div class="row">
        <div class="col-12">
            <div class="layout-box adjust-mobile">
                <h3 class="d-inline-block" translate>Mahlzeiten</h3>
                <div *ngIf="editUserDataService.isEditModeActivated" class="icon-button marginleft-25" (click)="onTrackMeal(currentDate, displayedUser.meals, displayedUser)" translate>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>
                    Mahlzeit tracken
                </div>
                <div class="container-fluid padding-0">
                    <div *ngIf="loadingMeals" class="loading-animation">
                        <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
                    </div>
                    <div class="row align-items-left">
                        <div *ngFor="let m of displayedUser.meals" class="col-12 col-lg-12 col-xl-12 col-xxl-6">
                            <div class="meal-box">
                                <div class="layout-box image-box link" [ngStyle]="{'background-image':'url('+m.imageLink+')'}" (click)="onOpenMealImage(m)"></div>
                                
                                <div class="meal-information">
                                    <div *ngIf="utilityService.onSmallDisplay()" class="display-block">
                                        <div class="bold" *ngIf="m.name">
                                            {{m.name}}
                                        </div>
                                        <div class="label mobile">
                                            <div>
                                                {{m.getPrintableDate(displayedUser?.dailyCondition)}}, {{m.getPrintableMealType(translate)}}
                                                <span *ngIf="m.plannedMealId" class="" translate>
                                                    &nbsp;&nbsp;| Aus Ernährungsplan
                                                </span>
                                            </div>
                                        </div>
                                        <div class="spacer-10"></div>
                                    </div>
                                    <div class="layout-box sub-box" *ngIf="m.coacheeNote">
                                        <div class="label black-label display-block" translate [translateParams]="{userNameParameter: displayedUser?.getName()}">
                                            Notiz von userNameParameter:
                                        </div>
                                        <div class="display-block">
                                            {{m.coacheeNote}}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div *ngIf="!utilityService.onSmallDisplay()" class="col">
                                            <div class="row col bold" *ngIf="m.name">
                                                {{m.name}}
                                            </div>
                                            <div class="row col time-display">
                                                {{m.getPrintableDate(displayedUser?.dailyCondition)}}, {{m.getPrintableMealType(translate)}} 
                                                <span *ngIf="m.plannedMealId" class="" translate>
                                                    &nbsp;&nbsp;| Aus Ernährungsplan
                                                </span>
                                            </div>
                                            
                                            <div class="spacer-10"></div>
                                        </div>
                                        <div class="nutritional-values col-auto">
                                            {{m.getPrintableMacros(translate)}}
                                            <div class="nutritionalvalues-button display-inlineblock link" [popover]="nutritionalValuePopover" (click)="onNutritionalValuesFocused(m, cycleConfigOfSelectedDate)" placement="auto" [outsideClick]="true" container="body" boundariesElement="window">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngFor="let f of m.foods" class="row food-wrapper">
                                        <div class="col">
                                            {{f.name}} <span class="brands" *ngIf="f.brands">{{f.brands}}</span>
                                            <svg *ngIf="f.nutritionFactId?.startsWith('bls')" xmlns="http://www.w3.org/2000/svg" class="detailed-nutritionfact display-inlineblock" width="120" height="32" viewBox="0 0 120 32">
                                                <path id="icon_detailed" d="M96,36H-24V4H96V36ZM73.323,10.8V29H87.675V24.242H79.459V10.8Zm-9.62,0V29h6.136V10.8ZM47.713,25.828h6.917L55.851,29H62.2L54.239,10.8H48.207L40.251,29h6.241l1.221-3.171ZM29.565,15.558V29H35.7V15.558h5.33V10.8h-16.8v4.758ZM7.6,10.8V29H22.727V24.372h-9.1V21.98h7.7V17.56h-7.7V15.428h8.762V10.8Zm-22,0V29h8.97A12.432,12.432,0,0,0-.062,27.895a8.443,8.443,0,0,0,3.614-3.159A8.958,8.958,0,0,0,4.839,19.9a8.958,8.958,0,0,0-1.287-4.836A8.443,8.443,0,0,0-.062,11.905,12.432,12.432,0,0,0-5.431,10.8Zm8.71,13.416H-8.265V15.584h2.574a4.4,4.4,0,0,1,3.159,1.13A4.217,4.217,0,0,1-1.349,19.9a4.217,4.217,0,0,1-1.183,3.185A4.4,4.4,0,0,1-5.691,24.216Zm58.63-2.808H49.4l1.768-4.577,1.767,4.575Z" transform="translate(24 -4)"/>
                                            </svg>
                                            <div class="nutritional-values-small">
                                                {{f.getCarbohydrates().roundToPlaces(0)}}{{'g K' | translate}}/ {{f.getProtein().roundToPlaces(0)}}{{'g E' | translate}}/ {{f.getFat().roundToPlaces(0)}}{{'g F' | translate}}/ {{f.getCalories().roundToPlaces(0)}} {{'kcal' | translate}}
                                            </div>
                                        </div>    
                                        <div class="col-auto">
                                            {{f.weight}}g
                                        </div>
                                    </div>
                                </div>
                                <div class="meal-comment-wrapper" *ngIf="userService.getLoggedInUser().isCoach">
                                    <input id="meal-comment" name="name" (focus)="onInputFocus(m)" class="input-message {{checkForAcknowledged(m)}}" [(ngModel)]="m.comment" [placeholder]="'Dein Feedback...' | translate">
                                    <button class="btn-link send-comment {{m.show ? 'focused' : ''}}" (click)="sendFeedback(m);">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                        </svg>
                                    </button>
                                </div>
                                <div class="meal-comment-wrapper" *ngIf="!userService.getLoggedInUser().isCoach">
                                    <input disabled id="meal-comment" name="name" (focus)="onInputFocus(m)" class="input-message" [(ngModel)]="m.comment">
                                </div>
                                <div *ngIf="userService.getLoggedInUser().isCoach && !editUserDataService.isEditModeActivated" class="recipe-conversion" translate>
                                    <div class="icon-button small float-right" (click)="onConvertToRecipe(m)" translate>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-inbox" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm9.954 5H10.45a2.5 2.5 0 0 1-4.9 0H1.066l.32 2.562a.5.5 0 0 0 .497.438h12.234a.5.5 0 0 0 .496-.438L14.933 9zM3.809 3.563A1.5 1.5 0 0 1 4.981 3h6.038a1.5 1.5 0 0 1 1.172.563l3.7 4.625a.5.5 0 0 1 .105.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z"/>
                                        </svg>
                                        In Rezept umwandeln
                                    </div>
                                </div>
                                <div *ngIf="editUserDataService.isEditModeActivated" class="recipe-conversion">
                                    <div class="icon-button small float-right" (click)="onEditTrackMeal(m, displayedUser)" translate>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="padding-4 bi bi-pen" viewBox="0 0 16 16">
                                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                                        </svg>
                                        Bearbeiten
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid padding-0" *ngIf="editUserDataService.isEditModeActivated && relevanNutritionPlanMeals?.length > 0">
    <div class="row">
        <div class="col-12">
            <div class="layout-box adjust-mobile">
                <h3 class="d-inline-block" translate>Ernährungsplan</h3>
                <ng-container>
                    <div class="horizontal-scroll-wrapper">
                        <div class="display-inlineblock marginleft-5" *ngFor="let dayItem of relevanNutritionPlanMeals">
                            <div class="layout-box planned-meal {{dayItem == alternativeMealDay ? 'active' : ''}}">
                                <div class="nutritionplan-meal-button display-inlineblock link" (click)="onShowMealInfo(dayItem?.meal, displayedUser)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                    </svg>
                                </div>
                                <div class="row">
                                    <div class="col-auto layout-box plannedmeal-image-box" [ngStyle]="{'background-image':'url('+dayItem.meal.imageURL+')'}"></div>
                                    <div class="col planned-meal-info">
                                        <div class="row label">{{dayItem?.meal?.getType(translate)}}</div>
                                        <div class="row nutritionplan-meal-hader">{{ dayItem?.meal?.nameTranslation?.GetValue(translate.currentLang) ?? dayItem?.meal?.name}}</div>
                                    </div>
                                </div>
                                <div class="nutritionplan-meal-values">
                                    {{dayItem?.meal?.getPrintableNutritionalValues()}}
                                </div>
                                <div class="row col-12 justify-content-between">
                                    <div class="link mt-3" [tooltip]="'Mahlzeit tracken' | translate" (click)="onTrackSelectedMeal(dayItem?.meal, dayItem.mealConfig, displayedUser)">
                                        <svg width="25px" height="25px" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                        </svg>
                                    </div>
                                    <div class="link mt-3" [tooltip]="'Alternativen anzeigen' | translate" *ngIf="dayItem?.hasAlternativeMeals()" [matMenuTriggerFor]="alternativeMealsMenu">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" fill="currentColor" class="bi bi-arrow-repeat float-right" viewBox="0 0 16 16">
                                            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                                            <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                                        </svg>
                                    </div>
                                    <mat-menu #alternativeMealsMenu="matMenu">
                                        <div class="container" *ngIf="dayItem?.alternativeMeals?.length > 0">
                                            <ng-container *ngFor="let meal of dayItem?.alternativeMeals">
                                                <div class="layout-box alternative-meal">
                                                    <div class="row">
                                                        <div class="col-auto layout-box plannedmeal-image-box" [ngStyle]="{'background-image':'url('+meal.imageURL+')'}"></div>
                                                        <div class="col ml-3">
                                                            <div class="row label">{{meal?.getType(translate)}}</div>
                                                            <div class="row">{{meal?.nameTranslation?.GetValue(translate.currentLang) ?? meal?.name}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="row col-12">
                                                        <div class="link mt-3" tooltip="Mahlzeit tracken" (click)="onTrackSelectedMeal(meal, dayItem.mealConfig, displayedUser)">
                                                            <svg width="20" height="20" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </mat-menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid padding-0">
    <div class="row">
        <div class="col-12">
            <div class="layout-box adjust-mobile">
                <h3 class="d-inline-block" translate>Aktivitäten</h3>
                <div *ngIf="editUserDataService.isEditModeActivated" class="icon-button marginleft-25" (click)="onCreateActivity(displayedUser)" translate>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>
                    Aktivität tracken
                </div>
                <div class="container-fluid padding-0">
                    <div class="row">
                        <div class="col-12">
                            <div *ngFor="let a of displayedUser.activities" class="activity-box-wrapper">
                                <div class="activity-box {{a.trainingSession ? 'cursor-pointer' : ''}}" (click)="onOpenActivity(a, displayedUser)">
                                    <div class="container-fluid">
                                        <div class="row align-items-left">
                                            <div class="col-md padding-0">
                                                <div class="d-flex justify-content-between">
                                                    <div class="width-100">
                                                        <div class="time-display">
                                                            {{a.getPrintableDate(displayedUser?.dailyCondition, translate)}}
                                                        </div>
                                                        <div *ngIf="a.activityFactId != ActivityEditorComponent.dailyActivityFactId; else dailyActivity;">{{a.name}} <br></div>
                                                        <ng-template #dailyActivity><div><span translate>Alltagsaktivität</span> <br></div></ng-template>
                                                        <div>{{a.duration}} min</div>
                                                        <div *ngIf="a.note?.length > 0" class="activitynote" tooltip="{{a.note}}">
                                                            <div class="spacer-10"></div>
                                                            <div class="label" translate>Notiz</div>
                                                            {{a.note}}
                                                        </div>
                                                        <div class="spacer-25"></div>
                                                    </div>    
                                                    <div>
                                                    </div>
                                                </div>
                                                <div class="d-flex justify-content-between">
                                                    <div *ngIf="editUserDataService.isEditModeActivated && !a.trainingSession" class="icon-button small">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="padding-4 bi bi-pen" viewBox="0 0 16 16">
                                                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                                                        </svg>
                                                    </div>
                                                    <div>
                                                    </div>    
                                                    <div>
                                                        <b>{{a.caloriesBurned?.toFixed(0) ?? 0}} {{'kcal' | translate}}</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div *ngIf="editUserDataService.isEditModeActivated && !a.trainingSession" class="row">
                                            <div class="icon-button small float-right">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="padding-4 bi bi-pen" viewBox="0 0 16 16">
                                                    <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                                                </svg>
                                                Bearbeiten
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="recipename-dialog" class="dialog-wrapper">
    <div class="dialog layout-box">
        <div class="dialog-content-wrapper margin-0">
            <h3 class="display-inlineblock" translate>Rezept erstellen</h3>
            <div class="icon-button float-right" (click)="toggleRecipeNameDialog()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>
            <div class="content" translate>
                Rezeptname:<br>
                <div class="spacer-10"></div>
                <input [ngModel]="convertedRecipe?.getName()" (input)="onConvertedRecipeNameChanged($event.target.value)" class="input recipe-name" type="text" placeholder=""/>
                <div class="spacer-25"></div>
            </div>
            <div class="actions">
                <div class="icon-button" (click)="onConfirmRecipeConversion()" translate>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                    </svg>
                    Erstellen
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #nutritionalValuePopover>
    <app-nutritional-value-popover [nutritionalValueHolder]="nutritionalValueHolder" [cycleConfig]="selectedCycleConfig"></app-nutritional-value-popover>
</ng-template>
