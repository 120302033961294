import {MatDialog } from '@angular/material/dialog';
import { CompletedQuestionaireResultsDialogComponent } from './../completed-questionaire-results-dialog/completed-questionaire-results-dialog.component';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DailyCondition } from 'src/app/model/dailycondition.model';
import { AssignedQuestionaire, QuestionaireResult } from 'src/app/model/questionaires.model';
import { User } from 'src/app/model/user.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { FirestoreService } from 'src/app/services/firestore.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { UtilityService } from 'src/app/services/utility.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { EditUserDataService } from 'src/app/services/edit-user-data.service';
import { ToastrService } from 'ngx-toastr';
import { MetricData } from 'src/app/model/metricdata.model';
import { LanguageService } from 'src/app/services/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-completed-questionaires-overview',
  templateUrl: './completed-questionaires-overview.component.html',
  styleUrls: ['./completed-questionaires-overview.component.css']
})
export class CompletedQuestionairesOverviewComponent {

  public User: User;
  @Input() set user(value: User){
    this.User = value;
    // this.init();
  }

  @Input() set relevantDailyConditions(value: DailyCondition[]){
    this.dailyConditions = value;
    // this.loadData();
  }

  @Output() shouldRefresh = new EventEmitter<boolean>();

  public dailyConditions: DailyCondition[] = [];
  public showAllQuestionaires: boolean = true;

  public selectedQuestionaireResults: QuestionaireResult[] = [];
  
  constructor(private dialog: MatDialog, private spinnerService: NgxSpinnerService, private userService: FirestoreService, public utilityService: UtilityService, public editUserDataService: EditUserDataService, private toastr: ToastrService, private languageService: LanguageService, private translate: TranslateService) { }

  // ngOnInit(): void {
  //   this.init();
  // }
  // private dailyConditionSubscription: Subscription;
  // init(){
  //   this.dailyConditionSubscription?.unsubscribe();
  //   this.loadData();
  //   this.dailyConditionSubscription = this.User.dailyConditionChanged.subscribe(() => {
  //     this.loadData();
  //   });
  // }

  // loadData(){
  //   this.dailyConditions = this.User.dailyConditions.filter(x => x.questionaireResults.length > 0).sort((a, b) => b.date.getTime() - a.date.getTime());
  //   this.dailyConditions.forEach(dailyCondition => {
  //     dailyCondition.questionaireResults.sort((a, b) => b.assignedQuestionaire.assignmentDate?.getTime() - a.assignedQuestionaire.assignmentDate?.getTime()).sort((a, b) => b.assignedQuestionaire.completionDate?.getTime() - a.assignedQuestionaire.completionDate?.getTime());
  //   });
  // }

  onQuestionaireResultSelected(questionaireResult: QuestionaireResult)
  {
    if(this.selectedQuestionaireResults.includes(questionaireResult)){
      this.selectedQuestionaireResults = this.selectedQuestionaireResults.filter(x => x !== questionaireResult);
    }
    else {
      this.selectedQuestionaireResults.push(questionaireResult);
    }
  }

  onOpenQuestionaireResults(){
    if(this.selectedQuestionaireResults.length > 0){
      let allAvailableQuestionaireResults = this.dailyConditions.map(x => x.questionaireResults).reduce((a, b) => a.concat(b), []).filter(x => x.canAccess(this.getCoach()));
      const dialogRef = this.dialog.open(CompletedQuestionaireResultsDialogComponent, { data: { selectedQuestionaireResults: this.selectedQuestionaireResults, allAvailableQuestionaireResults: allAvailableQuestionaireResults, user: this.User}, autoFocus: false});
      dialogRef.afterClosed().subscribe(async result => {
        if(result?.hasSavedChanges){
          this.shouldRefresh.emit(true);
        }
      });
    }
  }

  onOpenQuestionaire(questionaireResult: QuestionaireResult){
    if (!questionaireResult.canAccess(this.getCoach())) return
    let allAvailableQuestionaireResults = this.dailyConditions.map(x => x.questionaireResults).reduce((a, b) => a.concat(b), []);
    this.onEditQuestionaire(questionaireResult.clone(), allAvailableQuestionaireResults);
  }

  onEditQuestionaire(questionaireResult: QuestionaireResult, allAvailableQuestionaireResults: QuestionaireResult[]){
    const dialogRef = this.dialog.open(CompletedQuestionaireResultsDialogComponent, { data: { selectedQuestionaireResults: [questionaireResult], allAvailableQuestionaireResults: allAvailableQuestionaireResults, user: this.User, editMode: this.editUserDataService.isEditModeActivated}, autoFocus: false});
    dialogRef.afterClosed().subscribe(async result => {
      if(result?.hasSavedChanges){
        this.shouldRefresh.emit(true);
      }
    });
  }

  async onFilloutQuestionaire(questionaireResult: QuestionaireResult){
    let clonedQuestionaireResult = questionaireResult.clone();
    let allAvailableQuestionaireResults = this.dailyConditions.map(x => x.questionaireResults).reduce((a, b) => a.concat(b), []);
    for(let question of clonedQuestionaireResult.assignedQuestionaire.questions){
      let metricData = new MetricData();
      if (question.metricId) {
        metricData.metric = this.userService.getMetricByMetricId(question.metricId)
        if (!metricData.metric) metricData.metric = await this.userService.fetchMetricByMetricId(question.metricId)
      }
      metricData.metricId = question.metricId;
      metricData.id = question.metricId;
      metricData.value = null;
      metricData.position = question.position;
      metricData.date = new Date();
      if(metricData.metric != null){
        clonedQuestionaireResult.metricData.push(metricData);
      }
    }
    this.onEditQuestionaire(clonedQuestionaireResult, allAvailableQuestionaireResults)
  }

  async onDeleteQuestionaire(questionaireResult: QuestionaireResult){
    if(!questionaireResult.assignedQuestionaire.completed) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { message: this.translate.instant('Möchtest du den zugewiesenen Check-In wirklich löschen?'), title: this.translate.instant('Check-In löschen') },
      });
      dialogRef.afterClosed().subscribe(async result => {
        if(result){
          this.spinnerService.show();
          await this.userService.deleteAssignedQuestionaire(this.User, questionaireResult.assignedQuestionaire);
          // this.init();
          this.spinnerService.hide();
        }
      });
    }
  }

  onShowAllQuestionaires(){
    this.showAllQuestionaires = !this.showAllQuestionaires;
  }

  getCoach() {
    return this.userService.getLoggedInUser()
  }

}
