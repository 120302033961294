<div class="dialog-topbar">
    <div class="icon-button" (click)="onSaveTrainingPlanTemplateFolder()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
        <span class="icon-button-text">Speichern</span>
    </div>
    <div class="display-inlineblock marginleft-25">
        <app-language-selector></app-language-selector>
    </div>
    <div class="icon-button float-right" (click)="onCancelTrainingPlanTemplateFolder()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        <span class="icon-button-text">Schließen</span>
    </div>
    <button class="icon-button float-right marginright-25" (click)="onDeleteTrainingPlanTemplateFolder()" *ngIf="selectedTrainingPlanTemplateFolder.id">
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"  fill="currentColor" class="bi bi-trash padding-4" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
        <span class="icon-button-text">Löschen</span>
    </button>
</div>
<div class="dialog-content-wrapper">
    <div class="row justify-content-center">
        <div class="col-lg-2 col-md-5">
            <div class="row">
                <div class="col-lg col-6 mb-2">
                    <label for="thumbnail-input">Thumbnail</label>
                    <div class="thumbnail-image link image-dropzone" (click)="openThumbnailDialog()" (dragover)="onDragOver($event)" (drop)="onDropThumbnail($event)" [ngStyle]="{'background-image':'url('+ thumbnailImageSrc +')'}">
                        <div *ngIf="!thumbnailImageSrc" for="thumbnail-input" class="dropzone-hint">
                            Thumbnail (.png oder .jpg) ablegen oder auswählen
                            <br>Format: 720x720px (1:1)
                        </div>
                        <input id="thumbnail-input" type="file" style="display: none;" accept=".jpg, .png, .jpeg" (change)="uploadThumbnail($event)">
                    </div>
                </div>
            </div>
            <div class="row">
                <button *ngIf="thumbnailImageSrc" class="label" (click)="onRemoveThumbnail()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"  fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                    </svg>
                    Thumbnail entfernen
                </button>
            </div>
            <div class="spacer-10"></div>
        </div>
        <div class="col-lg-10 col-sm-12">
            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <label for="input-exercise-name">Ordnername</label>
                    <input id="input-exercise-name" required class="input plannedmeal-name form-control full-width-input" [ngModel]="selectedTrainingPlanTemplateFolder.name.GetValue(languageService.selectedLanguageCode)" (input)="setLanguageDictionaryValue(selectedTrainingPlanTemplateFolder, 'name', $event.target.value)" placeholder="Name" type="text">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <div class="spacer-10"></div>
                    <label for="input-exercise-name">Beschreibung</label>
                    <app-md-editor id="editor-note" class="single-line" placeHolder="Beschreibung"
                        (valueChanged)="setLanguageDictionaryValue(selectedTrainingPlanTemplateFolder, 'description', $event)"
                        [inputValue]="selectedTrainingPlanTemplateFolder.description.GetValue(languageService.selectedLanguageCode)">
                    </app-md-editor>
                    
                    <!-- <input id="input-exercise-name" class="input plannedmeal-name form-control full-width-input" [ngModel]="selectedTrainingPlanTemplateFolder.description.GetValue(languageService.selectedLanguageCode)" (input)="setLanguageDictionaryValue(selectedTrainingPlanTemplateFolder, 'description', $event.target.value)" placeholder="Beschreibung" type="text"> -->
                </div>
            </div>
            <div class="row" *ngIf="templateSharingEnabled">
                <div class="col-lg-6 col-sm-12">
                    <div class="spacer-25"></div>
                    <label for="dropdownRepetion">Verfügbar für</label>
                    <div class="wrapper-repetition-selection">
                        <button class="button-filled light small dropdown-toggle margin-inbetween" [class.button-invalid]='getPrintableAssignments(selectedTrainingPlanTemplateFolder)?.length === 0' type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getPrintableAssignments(selectedTrainingPlanTemplateFolder)}}</button>
                        <div class="dropdown-menu dropdown-recipient" aria-labelledby="dropdownNotificationTarget">
                            <div class="dropdown-item link" (click)="onTargetSelectionChangedGroup('Alle');$event.stopPropagation()">
                                <input [disabled]="!canAccessGroup('Alle')" class="input checkbox" type="checkbox" [checked]="selectedTrainingPlanTemplateFolder?.assignedGroupNames.includes('Alle')"><div class="display-inlineblock">Alle</div>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div class="dropdown-item link" *ngFor="let group of getSelectableClientGroups()" (click)="onTargetSelectionChangedGroup(group);$event.stopPropagation()">
                                <input [disabled]="group != 'Alle' && areAllUsersTargeted() || !canAccessGroup(group)" class="input checkbox" type="checkbox" [checked]="selectedTrainingPlanTemplateFolder?.assignedGroupNames.includes(group)"><div class="display-inlineblock">{{group}}</div>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div class="dropdown-item link" *ngFor="let user of getSelectableClients()" (click)="onTargetSelectionChangedUser(user);$event.stopPropagation()">
                                <input [disabled]="areAllUsersTargeted()" class="input checkbox" type="checkbox" [checked]="selectedTrainingPlanTemplateFolder?.assignedUids.includes(user.uid)"><div class="display-inlineblock">{{user.getName()}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="spacer-25"></div>
            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <h3 class="display-inlineblock">Trainingsplan-Vorlagen</h3>
                    <div class="icon-button display-inlineblock marginleft-25" (click)="onAddTrainingPlanTemplates()">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                        Vorlagen auswählen
                    </div>
                </div>
            </div>
            <div *ngFor="let template of filteredTrainingPlanTemplates" class="col-12">
                <div class="row layout-box sub-box link">
                    <div class="col">
                        {{template.nameTranslation.GetValue(languageService.selectedLanguageCode)}}
                    </div>
                    <div class="col-auto flaot-right" (click)="onRemoveTrainingPlanTemplate(template)">
                        <svg width="16" height="16" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                        </svg>
                    </div>
                    <!-- <div class="float-right">
                        <div class="thumbnail-image"[ngStyle]="{'background-image':'url('+ thumbnailImageSrc +')'}">
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="row" *ngIf="trainingService.TrainingTemplateAdministratorEditModeActivated">
                <div class="col">
                    <div class="spacer-10"></div>
                    <label for="dropdown-access" class="col-12">Verfügbar für</label>
                    <div class="d-inline-block wrapper-repetition-selection col-12">
                        <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdown-access" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{selectedTrainingPlanTemplateFolder.access}}</button>
                        <div class="dropdown-menu" aria-labelledby="dropdown-access">
                            <div *ngFor="let access of selectableTrainingPlanAccess">
                                <button class="dropdown-item" type="button" (click)="selectedTrainingPlanTemplateFolder.access = access">{{access}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="spacer-10"></div>
        </div>
    </div>
</div>