
export enum EventMode {
    allOfCoach,
    singleUsers
  }
  
  export class UniversalDropdownItem{
    checked: boolean;
    element: any;
  }
  
  export enum EventType{
    progress_tracked,
    checkin_completed,
    //licence_redeemed,
    //meal_tracked,
    training_tracked,
    recordings_tracked,
  }
  
  export const EventType2LabelMapping: Record<EventType, string> = {
    [EventType.progress_tracked]: "Fortschritte",
    [EventType.checkin_completed]: "Fortschritte",
    //[EventType.licence_redeemed]: "Eingelöste Lizenzen",
    //[EventType.meal_tracked]: "Mahlzeiten",
    [EventType.training_tracked]: "Training",
    [EventType.recordings_tracked]: "Training"
  };
  
  export const EventTypeString2LabelMapping: Record<string, string> = {
    'progress_tracked': marker("Fortschritte getrackt"),
    'checkin_completed': marker("Check-In absolviert"),
    //licence_redeemed': "Eingelöste Lizenzen",
    //'meal_tracked': "Mahlzeiten getrackt",
    'training_tracked': marker("Training absolviert"),
    "recordings_tracked": marker("Trainingsvideo aufgenommen"),
    'all': marker("Alle")
  };
  
  export class EventLog {
    public id: string;
    public coachUid: string;
    public date: Date;
    public event: string;
    public eventItems: Map<string, any>;
    public licenseHolderUid: string;
    public type: string;
    public userUid: string;
    public read: boolean;
    public referenceDate: Date;
    public eventItemsExpanded: boolean;

    constructor();
    constructor(init: EventLog);
    
    constructor(init?: EventLog){
      this.id = init && init.id || null
      this.coachUid = init && init.coachUid || null;
      this.date = init && init.date || new Date();
      this.event = init && init.event || null;
      this.eventItems = init && init.eventItems || null;
      this.licenseHolderUid = init && init.licenseHolderUid || null;
      this.type = init && init.type || null;
      this.userUid = init && init.userUid || null;
      this.read = init && init.read || false;
      this.referenceDate = init && init.referenceDate || null
      this.eventItemsExpanded = init && init.eventItemsExpanded || false
    }
  }

  
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
@Pipe({
  name: 'sanitizedHtml'
})
export class SanitizedHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value: any): any {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}