import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { FilterObject } from 'src/app/filter-selection-dropdown/filter-selection-dropdown.component';
import { InputFieldDialogComponent } from 'src/app/inputfield-dialog/inputfield-dialog.component';
import { Chat } from 'src/app/model/chat.model';
import { Coach } from 'src/app/model/coach.model';
import { ILanguageDictionary, LanguageDictionary } from 'src/app/model/languagedictionary.model';
import { Licence } from 'src/app/model/lid.model';
import { TrackedTrainingSession } from 'src/app/model/training-monitoring.model';
import { TrainingSession } from 'src/app/model/training-plan.model';
import { User } from 'src/app/model/user.model';
import { CompletedQuestionaireResultsDialogComponent } from 'src/app/questionaire/completed-questionaire-results-dialog/completed-questionaire-results-dialog.component';
import { ChatService } from 'src/app/services/chat.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { PaymentService } from 'src/app/services/payment.service';
import { UtilityService } from 'src/app/services/utility.service';
import { TrainingHistoryDialogComponent } from 'src/app/training-monitoring/training-history-dialog/training-history-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-clients-overview',
  templateUrl: './clients-overview.component.html',
  styleUrls: ['./clients-overview.component.css']
})
export class ClientsOverviewComponent {

  public environment = environment

  constructor(public chatService: ChatService, private authService: AuthService, private router: Router, public userService: FirestoreService, public navService: NavbarService, public utilityService: UtilityService, public dialog: MatDialog, private toastr: ToastrService, private paymentService: PaymentService, private spinner: NgxSpinnerService) { }

  public selectedCoach: Coach
  public coaches: Coach[]
  public clients: User[];
  public filteredClients: User[];
  public clientSearchInput: string
  public groupSearchInput: string
  public filteredClientGroups: string[]
  public user: User;
  public chats = [];
  public uid: string;
  public selectedClientGroup: string = null
  public areLicencesLoaded = false
  public hasOtherThanOwnClients = false
  public showAllGroups = false

  public get inListView() {
    if (this.forcedViewMode) {
      return this.forcedViewMode == 'list'
    }
    return this.user?.portalSettingsCoach?.showClientsInListView ?? false
  }
  public listViewPage = 0
  public maxPage = 0

  public static CLIENTS_PER_PAGE = 50

  public availableQuestionaireTypes: FilterObject[] = [
    new FilterObject(new LanguageDictionary('Noch nicht angesehen', 'Not viewed', 'unviewed'), false)
  ];
  public clientsSortAttribute = 'creationDate'
  public clientsSortDirection = 'desc'

  @Input() forcedViewMode: string
  @Input() gridItemClasses: string = 'col-12 col-sm-6 col-xl-4 col-xxl-3'

  ngOnInit(): void {
    this.user = this.userService.getLoggedInUser();

    var user = this.userService.getLoggedInUser()
    if (!user) {
      this.waitforUser()
    } else {
      this.init()
    }
  }

  init() {
    this.selectedCoach = this.userService.getLoggedInUser()?.coach
    this.loadCoaches()
    this.uid = this.authService.user?.uid;
    this.clients = [];
    this.clients = this.userService.getAccessibleClients();
    this.hasOtherThanOwnClients = this.clients.length > this.userService.getClientsOfCoach().length
    if (this.hasOtherThanOwnClients && this.userService.getClientsOfCoach().length == 0) this.selectedCoach = null
    if (this.userService.areLicencesLoaded()) this.areLicencesLoaded = true
    this.updateFilteredClients()
    this.updateFilteredClientGroups()
    this.userService.observableClients.subscribe(clients => {
      if (!this.coaches) this.loadCoaches()
      this.clients = this.userService.getAccessibleClients()
      if (this.userService.areLicencesLoaded()) this.areLicencesLoaded = true
      this.updateFilteredClients()
    })
    if (!this.authService.isLoggedIn) {
      this.router.navigate['login'];
    }
  }

  waitforUser() {
    var userSubscription = this.userService.observableUser.subscribe((user) => {
      if (!user) {
        this.router.navigate(['../login'])
        return
      }
      this.init()
    })
  }

  onShowAllGroups(show: boolean) {
    this.showAllGroups = show
  }

  loadCoaches() {
    if (!this.selectedCoach) {
      this.selectedCoach = this.userService.getLoggedInUser()?.coach
    }
    if (!this.selectedCoach) return
    this.userService.getAllCoachesByLicenceHolderUid(this.selectedCoach.licenceHolderUid).subscribe(coaches => {
      this.coaches = coaches
    })
  }

  getChatForClient(client: User): Chat {
    return this.chatService.getChatForUid(client.uid)
  }
  onOpenChat(chat: Chat) {
    this.chatService.openChat(chat)
  }
  
  isQuestionareFilterSelected(filterObjects:FilterObject[]):boolean{
    return filterObjects?.filter(x => x.isFiltered)?.length > 0
  }
  fallsInQuestionaireFilter(filters:FilterObject[], user: User, isTranslatable: boolean = true):boolean{
    if (!this.isQuestionareFilterSelected(filters)) return true
    return user.getAssignedQuestionaires().filter(q => filters.filter(f => f.isFiltered && ( this.getCompareText(f.originObject, isTranslatable) == 'unviewed' && user.getNumberOfNotViewedQuestionaires() > 0 || !q.completed && this.getCompareText(f.originObject, isTranslatable) === q.name ) ).length > 0).length > 0
  }
  getCompareText(filterObject: ILanguageDictionary<any>, isTranslatable: boolean = true):string{
    if (isTranslatable) return filterObject.comparableValue
    else return filterObject.originObject.toString()
  }

  onClientsSortChange(sortAttribute: string) {
    if (this.clientsSortAttribute == sortAttribute) {
      if (this.clientsSortDirection == 'desc') {
        this.clientsSortDirection = 'asc'
      } else {
        this.clientsSortDirection = 'desc'
        this.clientsSortAttribute = 'creationDate'
      }
    } else {
      this.clientsSortDirection = 'desc'
      this.clientsSortAttribute = sortAttribute
    }
  }
  private userDataPromiseMap = new Map<string, Promise<User>>()

  getDataForUser(client: User) {
    if (!client) return null
    if (this.userDataPromiseMap.has(client.uid)) {
      return this.userDataPromiseMap.get(client.uid)
    }
    var promise: Promise<User> = new Promise(async (resolve, reject) => {
      //await this.userService.loadAllAssignedQuestionairesForUser(client)
      var startDate = new Date()
      startDate.setDate(startDate.getDate() - 21)
      var endDate = new Date()
      var dailyConditions = (await firstValueFrom(this.userService.getDailyConditionsForUser(client, startDate, endDate))).filter(x => x.date >= startDate && x.date <= endDate).sort((a, b) => a.date.getTime() - b.date.getTime())
      var firstBodyWeight = null
      var lastBodyWeight = null
      var metricId = environment.isWhitelabel ? 'NUT_bodyWeight' : 'bodyWeight'
      dailyConditions.forEach(condition => {
        if (condition.getMetricDataByMetricId(metricId)?.value != null) {
          if (!firstBodyWeight) firstBodyWeight = condition.getMetricDataByMetricId(metricId).value
          lastBodyWeight = condition.getMetricDataByMetricId(metricId).value
        }
      })
      if (firstBodyWeight && lastBodyWeight) {
        var difference = lastBodyWeight - firstBodyWeight
        client.bodyWeightDelta = difference
      }
      client.getUncompletedQuestionaires().forEach(questionaire => {
        if (this.availableQuestionaireTypes.filter(x => x.originObject.originObject == questionaire.name).length == 0) {
          this.availableQuestionaireTypes.push(new FilterObject(new LanguageDictionary(questionaire.name, questionaire.name, questionaire.name), false),)
        }
      })
      var productPurchase = this.paymentService.productPurchases.find(x => x.customerUid == client.uid)
      if (productPurchase) {
        client.productPurchaseEndDate = productPurchase.endDate
      }

      var trackedSessions = await this.userService.getTrackedTrainingSessionsWithExercises(client, startDate.clone(), endDate)
      client.trackedTrainingSessions = trackedSessions

      if (environment.firebaseProjectId == 'traindoo-app') {
        await this.userService.loadLicenceSettingsForUser(client)
      }

      resolve(client)
    });
    this.userDataPromiseMap.set(client.uid, promise)
    return promise;
  }

  onSelectCoach(coach: Coach) {
    this.selectedCoach = coach
    this.selectedClientGroup = null
    this.updateFilteredClients()
  }

  getSelectableCoaches() {
    return this.coaches?.filter(coach => this.userService.getLoggedInUser()?.coach?.canAccessCoachClients(coach))
  }

  onClientSearchInputChanged(text: string) {
    this.clientSearchInput = text
    this.updateFilteredClients()
  }
  onGroupSearchInputChanged(text: string) {
    this.groupSearchInput = text
    this.updateFilteredClientGroups()
  }

  onShowAllClients() {
    this.selectedCoach = null 
    this.selectedClientGroup = null
    this.updateFilteredClients()
  }

  updateFilteredClientGroups() {
    if (!this.groupSearchInput || this.groupSearchInput.length == 0) {
      this.filteredClientGroups = this.userService.getClientGroups()
    } else {
      this.filteredClientGroups = []
      this.userService.getClientGroups().forEach(group => {
        if (group.toLowerCase().includes(this.groupSearchInput.toLowerCase())) {
          this.filteredClientGroups.push(group)
        }
      })
    }
  }

  updateFilteredClients() {
    this.hasOtherThanOwnClients = this.clients.length > this.userService.getClientsOfCoach().length
    var accessibleClients = []
    if (!this.selectedCoach && !this.selectedClientGroup) {
      accessibleClients = this.clients
    } else {
      this.clients.forEach(client => {
        if (this.selectedCoach && this.selectedCoach.canAccessUser(client) && this.selectedCoach.uid == client.coachUid) {
          accessibleClients.push(client)
        } else if (this.selectedClientGroup && this.userService.getLoggedInUser()?.coach?.canAccessUser(client) && client.metadataUser?.assignedClientGroups.includes(this.selectedClientGroup)) {
          accessibleClients.push(client)
        }
      })
    }
    if (!this.clientSearchInput || this.clientSearchInput.length == 0) {
      this.filteredClients = accessibleClients
    } else {
      this.filteredClients = []
      accessibleClients.forEach(client => {
        if (client.getName()?.toLowerCase().includes(this.clientSearchInput.toLowerCase())) {
          this.filteredClients.push(client)
        }
      })
    }
    this.maxPage = Math.ceil(this.filteredClients.length / ClientsOverviewComponent.CLIENTS_PER_PAGE) - 1
    if (this.listViewPage > this.maxPage) this.listViewPage = this.maxPage
  }

  onDeleteClientSearchInput() {
    (<HTMLInputElement> document.getElementById('clientsearch-input')).value = ''
    this.filteredClients = this.clients
    this.clientSearchInput = null
  }

  onLicenceIssuedChanged(licence: Licence, event: any){
    licence.issued = event.currentTarget.checked;
    this.userService.updateLicenceIssued(licence);
  }

  onClientGroupSelected(tag: string) {
    this.selectedClientGroup = tag
    this.selectedCoach = null
    this.updateFilteredClients()
  }

  getClientGroups(): string[] {
    return this.userService.getClientGroups()
  }

  getFilteredClientGroups(): string[] {
    if (this.filteredClientGroups) return this.filteredClientGroups
    return this.getClientGroups()
  }

 onCreateClientGroup() {
  const dialogRef = this.dialog.open(InputFieldDialogComponent, {
    data: { message: '', title: 'Gruppe erstellen' },
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result && result.input) {
      var clientGroup = result.input
      this.user.portalSettingsCoach.clientGroups.push(clientGroup)
      this.userService.clientGroups.push(clientGroup)
      this.userService.sortClientGroups()
      this.userService.updatePortalSettingsForCoach(this.user)
    }
  });
 }
 onDeleteClientGroup(clientGroup: string) {
  const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    data: { message: 'Möchtest du die Gruppe wirklich löschen? Alle Nutzer:innen werden aus der Gruppe entfernt.', title: 'Gruppe löschen' },
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      if (this.userService.clientGroups.includes(clientGroup)) this.userService.clientGroups.splice(this.userService.clientGroups.indexOf(clientGroup), 1)
      if (this.user.portalSettingsCoach.clientGroups.includes(clientGroup)) this.user.portalSettingsCoach.clientGroups.splice(this.user.portalSettingsCoach.clientGroups.indexOf(clientGroup), 1)
      this.userService.updatePortalSettingsForCoach(this.user)
      this.userService.getAccessibleClients().forEach(client => {
        this.userService.removeClientFromGroup(client, clientGroup)
      })
      if (this.user.portalSettingsCoach.dashboardClientGroups.includes(clientGroup)) this.user.portalSettingsCoach.dashboardClientGroups.splice(this.user.portalSettingsCoach.dashboardClientGroups.indexOf(clientGroup), 1)
      if (clientGroup == this.selectedClientGroup) this.selectedClientGroup = null
    }
  });
 }

 async openNextNotViewedQuestionaire(user: User) {
  var questionaire = user.getNextNotViewedQuestionaire()
  if (questionaire) {
    var questionaireResult = user.questionaireResults.find(x => x.questionaireId == questionaire.id)
    if (!questionaireResult && questionaire.completionDate) {
      var dailyCondition = await firstValueFrom(this.userService.getDailyConditionByDate(user, questionaire.completionDate))
      if (dailyCondition && dailyCondition.questionaireResults) {
        questionaireResult = dailyCondition.questionaireResults.find(x => x.questionaireId == questionaire.id)
      }
    }
    if (questionaireResult) {
      questionaireResult.assignedQuestionaire = questionaire
      const dialogRef = this.dialog.open(CompletedQuestionaireResultsDialogComponent, { data: { selectedQuestionaireResults: [questionaireResult], allAvailableQuestionaireResults: null, user: user}, autoFocus: false});
    } else {
      this.toastr.info("Öffne den Coachee, um die ungesehenen Check-Ins anzusehen.", "",  {
        positionClass: 'toast-bottom-center'
      });
    }
  }
 }
 openNextNotViewedTraining(user: User) {
    var trackedTrainingSession = user.getNextNotViewedTraining()
    if (trackedTrainingSession) {
      this.openTrackedTrainingSession(trackedTrainingSession, user)
    } else {
      this.toastr.info("Öffne den Coachee, um die ungesehenen Trainings anzusehen.", "",  {
        positionClass: 'toast-bottom-center'
      });
    }
 }
 openNextNotViewedExerciseRecording(user: User) {
  var trackedTrainingSession = user.getNextTrackedTrainingWithNotViewedExerciseRecording()
  if (trackedTrainingSession) {
    this.openTrackedTrainingSession(trackedTrainingSession, user)
  } else {
    this.toastr.info("Öffne den Coachee, um die ungesehenen Videoaufnahmen anzusehen.", "",  {
      positionClass: 'toast-bottom-center'
    });
  }
 }
 async openTrackedTrainingSession(trackedTrainingSession: TrackedTrainingSession, user: User) {
  let questionaireResults = this.user.questionaireResults?.filter(x => x.assignedQuestionaire?.trackedSessionId == trackedTrainingSession?.id);
  let plannedSession = await this.getPlannedSession(trackedTrainingSession, user);
  const dialogRef = this.dialog.open(TrainingHistoryDialogComponent, { data: { user: user, selectedTrackedTrainingSession: trackedTrainingSession, selectedPlannedTrainingSession: plannedSession, questionaireResults: questionaireResults}})
 }
 async getPlannedSession(trackedTrainingSession: TrackedTrainingSession, user: User):Promise<TrainingSession>{
  try{
    this.spinner.show();
    await this.userService.loadTrainingPlansIfNotLoaded(user, this.user, false)
    var session = user.trainingPlans.filter(x => x.id == trackedTrainingSession.trainingPlanId)[0]?.sessions?.filter(x => x.id == trackedTrainingSession.plannedSessionId)[0]
    return session
  }
  catch(e){
    console.error(e);
  }
  finally{
    this.spinner.hide();
  }
  return null;
}

 getClientsForSelectedGroup(): User[] {
  var result = this.filteredClients?.filter(x => this.fallsInQuestionaireFilter(this.availableQuestionaireTypes, x, false)).sort((a, b) => {
    if (this.clientsSortAttribute == 'expiringNutritionPlanDate') {
      if (this.clientsSortDirection == 'asc') {
        if (a.getExpiringNutritionPlanDate() == null) return 1
        if (b.getExpiringNutritionPlanDate() == null) return -1
        return a.getExpiringNutritionPlanDate().getTime() - b.getExpiringNutritionPlanDate().getTime()
      } else {
        if (a.getExpiringNutritionPlanDate() == null) return -1
        if (b.getExpiringNutritionPlanDate() == null) return 1
        return b.getExpiringNutritionPlanDate().getTime() - a.getExpiringNutritionPlanDate().getTime()
      }
    } else if (this.clientsSortAttribute == 'expiringTrainingPlanDate') {
      if (this.clientsSortDirection == 'asc') {
        if (a.getExpiringTrainingPlanDate() == null) return 1
        if (b.getExpiringTrainingPlanDate() == null) return -1
        return a.getExpiringTrainingPlanDate().getTime() - b.getExpiringTrainingPlanDate().getTime()
      } else {
        if (a.getExpiringTrainingPlanDate() == null) return -1
        if (b.getExpiringTrainingPlanDate() == null) return 1
        return b.getExpiringTrainingPlanDate().getTime() - a.getExpiringTrainingPlanDate().getTime()
      }
    } else if (this.clientsSortAttribute == 'lastChatMessageDate') {
      var aChat = this.chatService.getChatForUid(a.uid)
      var bChat = this.chatService.getChatForUid(b.uid)
      if (this.clientsSortDirection == 'asc') {
        if (aChat?.getLatestMessage() == null) return 1
        if (bChat?.getLatestMessage() == null) return -1
        return aChat.getLatestMessage()?.time.getTime() - bChat.getLatestMessage().time.getTime()
      } else {
        if (aChat?.getLatestMessage() == null) return -1
        if (bChat?.getLatestMessage() == null) return 1
        return bChat.getLatestMessage()?.time.getTime() - aChat.getLatestMessage().time.getTime()
      }
    } else if (this.clientsSortAttribute == 'endDate') {
      if (this.clientsSortDirection == 'asc') {
        if (a.getCoachingEndDate() == null) return 1
        if (b.getCoachingEndDate() == null) return -1
        return a.getCoachingEndDate().getTime() - b.getCoachingEndDate().getTime()
      } else {
        if (a.getCoachingEndDate() == null) return -1
        if (b.getCoachingEndDate() == null) return 1
        return b.getCoachingEndDate().getTime() - a.getCoachingEndDate().getTime()
      }
    }
  })
  if (this.inListView && result?.length > ClientsOverviewComponent.CLIENTS_PER_PAGE) {
    var start = this.listViewPage * ClientsOverviewComponent.CLIENTS_PER_PAGE
    var end = start + ClientsOverviewComponent.CLIENTS_PER_PAGE
    return result.slice(start, end)
  }
  return result
 }

  dateFormatter(date:Date):string{
    return date.asFormatedString()
  }

  goToPreviousPage() {
    if (this.listViewPage > 0) this.listViewPage--
  }
  goToNextPage() {
    this.listViewPage++
  }

  createNewGroupAndAddUser(user: User) {
    const dialogRef = this.dialog.open(InputFieldDialogComponent, {
      data: { message: '', title: 'Gruppe erstellen' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.input) {
        var clientGroup = result.input
        if (!this.getCoach().portalSettingsCoach.clientGroups.includes(clientGroup)) this.getCoach().portalSettingsCoach.clientGroups.push(clientGroup)
        this.userService.updatePortalSettingsForCoach(this.getCoach())
        if (!this.userService.clientGroups.includes(clientGroup)) this.userService.clientGroups.push(clientGroup)
        this.userService.sortClientGroups()
        this.userService.addClientToGroup(user, result.input)
      }
    });
  }
  getCoach() {
    return this.userService.getLoggedInUser()
  }

  onDeactivateLicenceOfClient(user: User) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du die Lizenz wirklich deaktivieren? ' + user.getName() + ' kann danach nicht mehr in der Coaching Zone von dir betreut werden.', title: 'Lizenz deaktivieren' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.deactivateLicence(user.licence.lid);
      }
    });
  }

  onRenameClient(user: User) {
    const dialogRef = this.dialog.open(InputFieldDialogComponent, {
      data: { message: '', title: 'Kund:in umbenennen' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.input) {
        user.metadataUser.userName = result.input
        this.userService.updateCoachingMetaData(user, false)
      }
    });
  }

  isNutritionEnabled(user: User) {
    if (user.nutritionEnabled != null) return user.nutritionEnabled
    return this.userService.getLoggedInUser()?.isNutritionEnabled()
  }

  openUser(event: MouseEvent, userUid: string) {
    if (event.ctrlKey) {
      window.open('/client/' + userUid, '_blank');
    } else {
      this.router.navigate(['/client/' + userUid]);
    }
  }
}
