import {Component} from '@angular/core';
import {PaymentAnalyticsService} from "../../../../services/payment-analytics.service";
import {ProductPurchase} from "../../../../model/product-purchase.model";
import * as moment from "moment";

@Component({
  selector: 'app-kpi-active-sales',
  templateUrl: './kpi-active-sales.component.html',
  styleUrls: ['./kpi-active-sales.component.css']
})
export class KpiActiveSalesComponent {
  private activePurchases: ProductPurchase[] = [];
  public info: string = null;
  public result: string = null;
  public growthRate: number = 0;

  constructor(private paymentAnalyticsService: PaymentAnalyticsService) {
    this.init();
  }

  private init() {
    this.activePurchases = this.paymentAnalyticsService.getCoachingPurchases();
    const nowMoment = moment();
     const activePurchasesCount = this.activePurchases.filter(purchase => {
      if (!purchase.startDate) return false;
      return nowMoment.isBetween(purchase.startDate, purchase.getEndDate(), "day", "[]");
    }).length;

    this.result = `${activePurchasesCount}`;

    const lastMonthMoment = nowMoment.subtract(1, 'month');
    const lastMonthActivePurchasesCount = this.activePurchases.filter(purchase => {
      if (!purchase.startDate) return false;
      return lastMonthMoment.isBetween(purchase.startDate, purchase.getEndDate(), "day", "[]");
    }).length;

    this.growthRate = ((activePurchasesCount/lastMonthActivePurchasesCount) - 1);
    if(isNaN(this.growthRate)) {
      this.growthRate = Infinity;
    }
    this.info = `Veränderung im Vergleich zu ${lastMonthActivePurchasesCount} zum ${lastMonthMoment.format('DD.MM.YY')}: ${(this.growthRate * 100).toFixed(0)}%`;
  }

  protected readonly Infinity = Infinity;
}
