<div class="dialog-topbar">
    <button class="button-filled small create-Exercise" (click)="onTakeChanges()">Übernehmen</button>
    <div class="icon-button float-right" (click)="onCancelChanges()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        <ng-container *ngIf="!utilityService.onSmallDisplay()">Schließen</ng-container>
    </div>
</div>
<div class="dialog-content-wrapper">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-sm-12">
                <label for="input-exercise-name">Inhalt</label>
                <app-md-editor id="editor-note" class="multi-line"
                    (valueChanged)="onEditorValueChanged($event)"
                    [inputValue]="questionaireItem.content || ''"
                    [placeHolder]="''">
                </app-md-editor>
            </div>
            <div class="spacer-10"></div>
        </div>
        <div class="row">
            <div class="col-lg-6 mb-2">
                <label for="thumbnail-input">Bild</label>
                <div class="question-image link" (click)="onUploadImage()" (dragover)="onDragOver($event)" (drop)="onDropImage($event)">
                    <div *ngIf="!questionaireItem?.imageURL" for="question-input" class="dropzone-hint">Bild (.png oder .jpg) ablegen oder auswählen</div>
                    <div *ngIf="questionaireItem?.imageURL" class="question-image" [ngStyle]="{'background-image':'url('+ questionaireItem?.imageURL +')'}"></div>
                    <input id="input-recipeimage" type="file" style="display: none;" accept=".jpg, .png" (change)="onNewImageUploaded($event)">
                </div>
            </div>
            <div class="col-lg-6 mb-2">
                <div class="spacer-10"></div>
                <label for="upload-video">Video</label>
                <div class="question-image link" (click)="openVideoDialog()" *ngIf="!questionaireItem?.videoURL" (dragover)="onDragOver($event)" (drop)="onDropVideo($event)">
                    <div for="question-input" class="dropzone-hint">Video (.mp4, x-m4v oder .mov) ablegen oder auswählen</div>
                    <input id="video-upload" class="link" type="file" style="display: none;" accept=".mp4, .x-m4v, .mov" (change)="onVideoSelected($event)">
                </div>
                <div class="spacer-10"></div>
                <video [src]="questionaireItem?.videoURL" *ngIf="questionaireItem?.videoURL" height="200" class="video-preview" id="videoPlayer" controls></video>
                <div *ngIf="!questionaireItem.updatedVideo && questionaireItem.videoPath?.toLowerCase()?.endsWith('.mov') || questionaireItem.updatedVideo?.name?.toLowerCase()?.endsWith('.mov')" class="label">
                    Für .mov-Dateien ist möglicherweise keine Vorschau verfügbar.
                    <div class="spacer-10"></div>
                </div>
                <!-- <button *ngIf="videoSrc && !previewOnly" class="small light" (click)="onDeleteVideo()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"  fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                    </svg>
                    Video entfernen
                </button> -->
            </div>
            <!-- <div class="col-lg-6 mb-2">
                <label for="thumbnail-input">Video</label>
                <div class="question-image link" (click)="onUploadVideo()" (dragover)="onDragOver($event)" (drop)="onDropVideo($event)">
                    <div *ngIf="!questionaireItem?.imageURL" for="question-input" class="dropzone-hint">Bild (.png oder .jpg) ablegen oder auswählen</div>
                    <div *ngIf="questionaireItem?.imageURL" class="question-image" [ngStyle]="{'background-image':'url('+ questionaireItem?.imageURL +')'}"></div>
                    <input id="input-videofile" type="file" style="display: none;" accept=".jpg, .png" (change)="onNewVideoUploaded($event)">
                </div>
            </div> -->
        </div>  
    </div>
</div>