import {Injectable} from '@angular/core';
import {Payment, PaymentStatus} from "../model/payment.model";
import {PaymentService} from "./payment.service";
import {ProductPurchase, ProductPurchaseStatus} from "../model/product-purchase.model";
import {Product, ProductLicenseType} from "../model/product.model";
import * as moment from "moment";
import {User} from "../model/user.model";

export type DropdownItem = {
  id: GraphTimeRangeOptions;
  tmp: string;
  name: string;
  unit: string;
  order: number;
}

export enum GraphTimeRangeOptions {
  LAST_7_DAYS = 'last-7-days',
  LAST_28_DAYS = 'last-28-days',
  LAST_MONTH = 'last-month',
  LAST_12_WEEKS = 'last-28-weeks',
  LAST_QUARTER = 'last-quarter',
  LAST_365_DAYS = 'last-365-days',
  THIS_MONTH = 'this-month',
  THIS_QUARTER = 'this-quarter',
  THIS_YEAR = 'this-year',
  FORECAST = 'forecast',
  TOTAL = 'total'
}

export type GraphTimeRange = {
  startDate: moment.Moment;
  endDate: moment.Moment;
  statistics: Statistic[];
}

export type Statistic = {
  timeArgument: string;
  date: moment.Moment;
}

export type RevenueStatistic = Statistic & {
  revenuePaid: number;
  revenueOpen: number;
  revenueProjection: number;
}

export type ActivePurchasesStatisticTooltipInfo = {
  plus: number;
  minus: number;
}

export type ActivePurchasesStatistic = Statistic & {
  activePurchases: number;
  activePurchasesProjection: number;

  newClients: number;
  newClientsProjection: number;
  lostClients: number;
  lostClientsProjection: number;
}

export type PaymentsAndProduct = {
  product: Product,
  payments: Payment[]
}
export type PaymentsByProduct = Map<string, PaymentsAndProduct>;

export type PaymentsAndCustomer = {
  customer: User,
  payments: Payment[],
}
export type PaymentsByCustomer = Map<string, PaymentsAndCustomer>;


@Injectable({
  providedIn: 'root'
})
export class PaymentAnalyticsService {

  constructor(private paymentService: PaymentService) {
  }

  getCurrency(printable?: boolean) {
    if (printable) {
      return this.paymentService.getPrintableCurrency(this.paymentService.paymentSettings.currency);
    }
    return this.paymentService.paymentSettings.currency;
  }

  getCurrencyFilteredPayments() {
    return this.paymentService.payments.filter(payment => payment.currency === this.paymentService.paymentSettings.currency);
  }

  getCurrencyFilteredProductPurchases() {
    return this.paymentService.productPurchases.filter(productPurchase => productPurchase.currency === this.paymentService.paymentSettings.currency);
  }

  transformNumberToMoney(num: number, currency?: string, locale: string = 'de-DE'): string {
    if (!currency) {
      currency = this.paymentService.paymentSettings.currency;
    }

    if (!currency) {
      console.log("Hab keine Currency!");
    }
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formatter.format(num);
  }

  getGraphTimeRange(option: GraphTimeRangeOptions): DropdownItem {
    return this.getGraphTimeRanges().find(opt => opt.id === option);
  }

  getGraphTimeRanges(): DropdownItem[] {
    return [
      {id: GraphTimeRangeOptions.LAST_7_DAYS, name: "Letzte 7 Tage", tmp: '', unit: null, order: 99},
      {id: GraphTimeRangeOptions.LAST_28_DAYS, name: "Letzte 28 Tage", tmp: '', unit: null, order: 99},
      {id: GraphTimeRangeOptions.LAST_MONTH, name: "Letzter Monat", tmp: '', unit: null, order: 99},
      {id: GraphTimeRangeOptions.LAST_12_WEEKS, name: "Letzte 12 Wochen", tmp: '', unit: null, order: 99},
      {id: GraphTimeRangeOptions.LAST_QUARTER, name: "Letztes Quartal", tmp: '', unit: null, order: 99},
      {id: GraphTimeRangeOptions.LAST_365_DAYS, name: "Letzte 365 Tage", tmp: '', unit: null, order: 99},
      {id: GraphTimeRangeOptions.THIS_MONTH, name: "Dieser Monat", tmp: '', unit: null, order: 99},
      {id: GraphTimeRangeOptions.THIS_QUARTER, name: "Dieses Quartal", tmp: '', unit: null, order: 99},
      {id: GraphTimeRangeOptions.THIS_YEAR, name: "Dieses Jahr", tmp: '', unit: null, order: 99},
      {id: GraphTimeRangeOptions.FORECAST, name: "Prognose", tmp: '', unit: null, order: 99},
      {id: GraphTimeRangeOptions.TOTAL, name: "Gesamter Zeitraum", tmp: '', unit: null, order: 99},
    ];
  }

  calculateGraphTimeRange(selectedRange: DropdownItem, possibleDates: Date[]): GraphTimeRange {
    let start = moment(new Date());
    let end = moment(new Date());
    let diff = 0;
    let statistics: Statistic[] = [];

    switch (selectedRange.id) {
      case GraphTimeRangeOptions.LAST_7_DAYS:
        start.subtract(7, "days");
        diff = end.diff(start, "days", true) + 1;
        statistics = Array.from({length: diff}, (_, index) => {
          const date = start.clone().add(index, "days");
          return {
            "timeArgument": date.format("DD.MM.YYYY"),
            "date": date
          };
        });
        break;
      case GraphTimeRangeOptions.LAST_28_DAYS:
        start.subtract(4, "weeks");
        diff = end.diff(start, "weeks", true) + 1;
        statistics = Array.from({length: diff}, (_, index) => {
          const date = start.clone().add(index, "weeks");
          return {
            "timeArgument": `KW ${date.format("WW 'YY")}`,
            "date": date
          };
        });
        break;
      case GraphTimeRangeOptions.LAST_MONTH:
        start.subtract(1, "month").startOf("month");
        end.subtract(1, "month").endOf("month");
        diff = end.diff(start, "days", true) + 1;
        statistics = Array.from({length: diff}, (_, index) => {
          const date = start.clone().add(index, "days");
          return {
            "timeArgument": `${date.format("DD")}`,
            "date": date
          };
        });
        break;
      case GraphTimeRangeOptions.LAST_12_WEEKS:
        start.subtract(12, "weeks");
        diff = end.diff(start, "weeks", true) + 1;
        statistics = Array.from({length: diff}, (_, index) => {
          const date = start.clone().add(index, "weeks");
          return {
            "timeArgument": `KW ${date.format("WW 'YY")}`,
            "date": date
          };
        });
        break;
      case GraphTimeRangeOptions.LAST_QUARTER:
        start.subtract(1, "quarter").startOf("quarter");
        end.subtract(1, "quarter").endOf("quarter");
        diff = end.diff(start, "months", true) + 1;
        statistics = Array.from({length: diff}, (_, index) => {
          const date = start.clone().add(index, "months");
          return {
            "timeArgument": `${date.format("MMMM 'YY")}`,
            "date": date
          };
        });
        break;
      case GraphTimeRangeOptions.LAST_365_DAYS:
        start.subtract(365, "days");
        diff = Math.ceil(end.diff(start, "months", true)) + 1;
        statistics = Array.from({length: diff}, (_, index) => {
          const date = start.clone().add(index, "months");
          return {
            "timeArgument": `${date.format("MMMM 'YY")}`,
            "date": date
          };
        });
        break;
      case GraphTimeRangeOptions.THIS_MONTH:
        start.startOf("month");
        end.endOf("month");
        diff = end.diff(start, "days", true) + 1;
        statistics = Array.from({length: diff}, (_, index) => {
          const date = start.clone().add(index, "days");
          return {
            "timeArgument": `${date.format("DD")}`,
            "date": date
          };
        });
        break;
      case GraphTimeRangeOptions.THIS_QUARTER:
        start.startOf("quarter");
        end.endOf("quarter");
        diff = end.diff(start, "months", true) + 1;
        statistics = Array.from({length: diff}, (_, index) => {
          const date = start.clone().add(index, "months");
          return {
            "timeArgument": `${date.format("MMMM 'YY")}`,
            "date": date
          };
        });
        break;
      case GraphTimeRangeOptions.THIS_YEAR:
        start.startOf("year");
        diff = end.diff(start, "months", true) + 1;
        statistics = Array.from({length: diff}, (_, index) => {
          const date = start.clone().add(index, "months");
          return {
            "timeArgument": `${date.format("MMMM 'YY")}`,
            "date": date
          };
        });
        break;
      case GraphTimeRangeOptions.FORECAST:
        start.subtract(3, "months").startOf("month");
        end.add(3, "months").endOf("month");
        diff = end.diff(start, "months", true) + 1;
        statistics = Array.from({length: diff}, (_, index) => {
          const date = start.clone().add(index, "months");
          return {
            "timeArgument": `${date.format("MMMM 'YY")}`,
            "date": date
          };
        });
        break;
      case GraphTimeRangeOptions.TOTAL:
        start = moment(new Date(Math.min.apply(null, possibleDates)));
        diff = Math.ceil(end.clone().endOf("month").diff(start.clone().startOf("month"), "months", true));
        statistics = Array.from({length: diff}, (_, index) => {
          const date = start.clone().add(index, "months");
          return {
            "timeArgument": `${date.format("MMMM 'YY")}`,
            "date": date
          };
        });
        break;
      default:
        throw new Error(`The ID: ${selectedRange.id} has not been implemented yet!`)
    }

    return {
      startDate: start,
      endDate: end,
      statistics: statistics
    }
  }

  getPaidOpenPaymentsFromCoachings(): Payment[] {
    const coachingPurchases = this.getCoachingPurchases();
    const paidPayments = this.getPaidOpenPayments();
    const coachingPayments: Payment[] = [];

    for (const purchase of coachingPurchases) {
      const payments = paidPayments.filter(payment => payment.productPurchaseId === purchase.id);
      coachingPayments.push(...payments);
    }

    return coachingPayments;
  }

  getCoachingPurchases(): ProductPurchase[] {
    return this.getCurrencyFilteredProductPurchases().filter(purchase => {
      return !!purchase.startDate
        && (purchase.recurring || purchase.endDate)
        && purchase.licenceType === ProductLicenseType.COACHING
        && (purchase.status === ProductPurchaseStatus.PURCHASED
          || purchase.status === ProductPurchaseStatus.ACTIVE
          || purchase.status === ProductPurchaseStatus.EXPIRED
          || purchase.status === ProductPurchaseStatus.CANCELED);
    });
  }

  getPaidPayments(): Payment[] {
    return this.getCurrencyFilteredPayments().filter(payment => payment.status === PaymentStatus.PAID);
  }

  getPaidOpenPayments(): Payment[] {
    return this.getCurrencyFilteredPayments().filter(payment => (payment.status === PaymentStatus.PAID) || (payment.status === PaymentStatus.PROCESSING));
  }

  getPaidProcessingAndFuturePayments(): Payment[] {
    let payments = this.getCurrencyFilteredPayments().filter(payment => payment.status === PaymentStatus.PAID || payment.status === PaymentStatus.PROCESSING);
    const futurePurchases = this.getCurrencyFilteredProductPurchases().filter(purchase => purchase.nextPaymentDate);
    const futureFakePayments = [];
    futurePurchases.forEach(purchase => {
      const forecasts = this.getForecastPayments(purchase);
      futureFakePayments.push(...forecasts);
    });
    payments.push(...futureFakePayments);
    return payments;
  }

  getPaymentsByProducts(): PaymentsByProduct {
    const paymentsByProducts = new Map<string, PaymentsAndProduct>();
    this.paymentService.activeProducts.forEach(product => {
      const paymentsForProduct = this.getPaidOpenPayments().filter(payment => payment.productId === product.id);
      if (paymentsByProducts.has(product.id)) {
        const payAndProd = paymentsByProducts.get(product.id);
        payAndProd.payments.push(...paymentsForProduct);
      } else {
        paymentsByProducts.set(product.id, {
          product: product,
          payments: paymentsForProduct
        });
      }
    });

    return paymentsByProducts;
  }

  getPaymentsByCustomer(): PaymentsByCustomer {
    const paymentsByCustomers = new Map<string, PaymentsAndCustomer>();
    this.getCurrencyFilteredProductPurchases().forEach(productPurchase => {
      const customer = productPurchase.customer;
      if (!customer) return;
      const paymentsForCustomer = this.getPaidOpenPayments().filter(payment => payment.customerUid === productPurchase.customerUid);
      if (paymentsByCustomers.has(customer.uid)) {
        const payAndCust = paymentsByCustomers.get(customer.uid);
        payAndCust.payments.push(...paymentsForCustomer);
      } else {
        if (customer.uid) {
          paymentsByCustomers.set(customer.uid, {
            customer: customer,
            payments: paymentsForCustomer
          });
        }
      }
    });

    return paymentsByCustomers;
  }

  getProjection(selectionId: GraphTimeRangeOptions, statisticDate: moment.Moment) {
    let isNotProjection = false;
    let isProjection = false;

    const now = new Date();
    switch (selectionId) {
      case GraphTimeRangeOptions.LAST_7_DAYS:
        isNotProjection = statisticDate.isSameOrBefore(now, "day");
        isProjection = statisticDate.isSameOrAfter(now, "day");
        break;
      case GraphTimeRangeOptions.LAST_28_DAYS:
      case GraphTimeRangeOptions.LAST_12_WEEKS:
        isNotProjection = statisticDate.isSameOrBefore(now, "week");
        isProjection = statisticDate.isSameOrAfter(now, "week");
        break;
      case GraphTimeRangeOptions.TOTAL:
        isNotProjection = statisticDate.isSameOrBefore(now, "month");
        isProjection = statisticDate.isSameOrAfter(now, "month");
        break;
      case GraphTimeRangeOptions.THIS_MONTH:
        isNotProjection = statisticDate.isSameOrBefore(now, "day");
        isProjection = statisticDate.isSameOrAfter(now, "day");
        break;
      case GraphTimeRangeOptions.THIS_QUARTER:
        isNotProjection = statisticDate.isSameOrBefore(now, "month");
        isProjection = statisticDate.isSameOrAfter(now, "month");
        break;
      case GraphTimeRangeOptions.FORECAST:
        isNotProjection = statisticDate.isSameOrBefore(now, "month");
        isProjection = statisticDate.isSameOrAfter(now, "month");
        break;
      case GraphTimeRangeOptions.LAST_MONTH:
      case GraphTimeRangeOptions.LAST_QUARTER:
      case GraphTimeRangeOptions.LAST_365_DAYS:
      case GraphTimeRangeOptions.THIS_YEAR:
        isNotProjection = true;
        isProjection = false;
        break;
      default:
      // throw new Error(`The ID: ${this.selectedGraphTimeRange.id} has not been implemented yet!`);
    }

    return {
      isNotProjection,
      isProjection
    }
  }

  getForecastPayments(productPurchase: ProductPurchase): Payment[] {
    const nowMoment = moment();
    const startMoment = moment(productPurchase.startDate);
    const forecastPayments: Payment[] = [];
    let nextPaymentDate: Date = productPurchase.nextPaymentDate.clone();

    if (!productPurchase.recurring) return forecastPayments;

    let durationMultiplier = productPurchase.durationMultiplier ?? 1;
    const runtime = startMoment.diff(nowMoment, productPurchase.durationUnit);
    durationMultiplier -= runtime;

    const fakePayment = new Payment();
    fakePayment.date = nextPaymentDate;
    fakePayment.amount = productPurchase.price;
    fakePayment.customerUid = productPurchase.customerUid;
    fakePayment.status = PaymentStatus.FORECAST;
    fakePayment.currency = productPurchase.currency;
    forecastPayments.push(fakePayment);
    durationMultiplier--;

    while (durationMultiplier > 0) {
      nextPaymentDate = moment(nextPaymentDate).add(1, productPurchase.durationUnit).toDate();
      const fakePayment = new Payment();
      fakePayment.date = nextPaymentDate;
      fakePayment.amount = productPurchase.price;
      fakePayment.customerUid = productPurchase.customerUid;
      fakePayment.status = PaymentStatus.FORECAST;
      fakePayment.currency = productPurchase.currency;
      forecastPayments.push(fakePayment);
      durationMultiplier--;
    }

    if (productPurchase.autoRenew) {
      const cancelMoment = moment(productPurchase.getNextRenewalDate()).subtract(productPurchase.cancelationPeriod, "days");
      if (nowMoment.isAfter(cancelMoment)) {
        nextPaymentDate = moment(nextPaymentDate).add(1, productPurchase.durationUnit).toDate();
        const fakePayment = new Payment();
        fakePayment.date = nextPaymentDate;
        fakePayment.amount = productPurchase.price;
        fakePayment.customerUid = productPurchase.customerUid;
        fakePayment.status = PaymentStatus.FORECAST;
        fakePayment.currency = productPurchase.currency;
        forecastPayments.push(fakePayment);
      }
    }

    return forecastPayments;
  }
}
