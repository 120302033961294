<div id="purchase-dialog" class="layout-box">
    <div class="container-fluid component-wrapper" >
        <div class="top-bar">
            <div class="icon-button marginleft-25" (click)="onCloseDialog()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                <span class="icon-button-text" translate>Schließen</span>
            </div>
        </div>
        <div class="spacer-50"></div>

        <div *ngIf="!user" translate>
            Melde dich an, um deine Rechnungen zu sehen.
        </div>

        <ng-container *ngIf="user">
            <div class="row">
                <div *ngIf="customerPaymentSettings" class="col-12">
                    <h3>Standard-Zahlungsmethode</h3>
                    <div *ngFor="let paymentMethod of customerPaymentSettings.defaultPaymentMethods">
                        Bei {{paymentMethod.licenceHolderName ?? 'Coach'}}: {{paymentMethod.getPrintablePaymentMethod(translate)}}
                        <div class="display-inlineblock marginleft-25">
                            <button class="button-filled small light" (click)="onChangeDefaultPaymentMethod(paymentMethod)" translate>
                                Ändern
                            </button>
                        </div>
                    </div>
                    
                    <ng-container *ngIf="choosePaymentMethod">
                        <div class="spacer-25"></div>
                        <app-paymentmethod-selection [paymentMethods]="getPaymentMethods()" (confirmPaymentMethod)="onConfirmPaymentMethod($event)"></app-paymentmethod-selection>
                        <div class="spacer-25"></div>
                    </ng-container>
                    <ng-container *ngIf="collectBillingAddress">
                        <div class="spacer-10"></div>
                        <div class="" translate>Rechnungsadresse:</div>
                        <div class="spacer-10"></div>
                        <input id="input-name" required class="input form-control wide" [(ngModel)]="address.name" [placeholder]="'Vor- und Nachname' | translate" type="text">
                        <div class="spacer-10"></div>
                        <input id="input-name" required class="input form-control wide" [(ngModel)]="address.street" [placeholder]="'Straße und Hausnummer' | translate" type="text">
                        <div class="spacer-10"></div>
                        <input id="input-name" required class="input form-control wide" [(ngModel)]="address.postalCode" [placeholder]="'Postleitzahl' | translate" type="text">
                        <div class="spacer-10"></div>
                        <input id="input-name" required class="input form-control wide" [(ngModel)]="address.city" [placeholder]="'Stadt' | translate" type="text">
                        <div class="spacer-10"></div>
                        <input id="input-name" required class="input form-control wide" [(ngModel)]="address.country" [placeholder]="'Land' | translate" type="text">
                        <div class="spacer-10"></div>
                        <button class="button-filled small product-button {{isAddressValid() ? '' : 'inactive'}}" (click)="onConfirmBillingAddress()" translate>
                            Zahlungsmethode hinterlegen
                        </button>
                    </ng-container>

                    <div class="spacer-25"></div>
                </div>
                <div class="col-12">
                    <h3 translate>Rechnungshistorie</h3>
                </div>
                <div class="spacer-10"></div>
                <ng-container *ngFor="let payment of payments">
                    <div class="col-12 layout-box sub-box">
                        <div class="label">
                            {{payment.date?.asFormatedString()}}
                        </div>
                        <div>
                            <div class="link display-inlineblock" (click)="onOpenInvoice(payment);$event.stopPropagation()">{{payment.invoiceNumber}}</div>
                            <div class="icon-button small display-inlineblock marginleft-10" (click)="onOpenInvoice(payment);$event.stopPropagation()" translate>
                                <svg width="22" height="22" viewBox="0 0 16 16" class="bi bi-arrow-up-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                </svg>
                                Öffnen
                            </div>
                            <div class="spacer-10"></div>
                            <div class="display-inlineblock">
                                {{payment.getPrintablePriceWithCurrency()}}
                            </div>
                            <div class="display-inlineblock">
                                <div *ngIf="payment.getPrintableStatus(translate)" class="status-indicator-wrapper">
                                    <div class="status-indicator {{payment.status}}"></div>
                                    {{payment.getPrintableStatus(translate)}}
                                </div>
                            </div>
                            <div *ngIf="payment.status == 'initiated' && payment.bankTransferInstructionsUrl" class="display-inlineblock marginleft-25">
                                <button class="button-filled small" (click)="onOpenBankTransferInstructions(payment)" translate>
                                    Bezahlen
                                </button>
                            </div>
                            <div *ngIf="payment.status == 'failed'" class="display-inlineblock marginleft-25">
                                <button class="button-filled small" (click)="onPayInvoice(payment)" translate>
                                    Bezahlen
                                </button>
                            </div>
                        </div>
                        <div class="spacer-10"></div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>

</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "#4AE6D7" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>