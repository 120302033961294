<div class="dialog-topbar">
    <h3>{{title}}</h3>
</div>
<div class="dialog-content-wrapper">
    <div class="d-inline-block wrapper-selection">
        <button class="button-filled light small dropdown-toggle margin-inbetween" [class.button-invalid]='getPrintableRecipients()?.length === 0' type="button" id="dropdownRecipient" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getPrintableRecipients()}}</button>
        <div class="dropdown-menu dropdown-recipient" aria-labelledby="dropdownNotificationTarget">
            <div class="dropdown-item link" (click)="onTargetSelectionChangedGroup('Alle');$event.stopPropagation()">
                <input [disabled]="!canAccessGroup('Alle')" class="input checkbox" type="checkbox" [checked]="selectedClientGroups?.includes('Alle')"><div class="display-inlineblock">Alle</div>
            </div>
            <ng-container *ngIf="availableClientGroups?.length > 0">
                <div class="dropdown-divider"></div>
                <div class="label marginleft-10">Gruppen</div>
                <div class="dropdown-item link" *ngFor="let group of availableClientGroups" (click)="onTargetSelectionChangedGroup(group);$event.stopPropagation()">
                    <input [disabled]="group != 'Alle' && areAllUsersTargeted() || !canAccessGroup(group)" class="input checkbox" type="checkbox" [checked]="selectedClientGroups?.includes(group)"><div class="display-inlineblock">{{group}}</div>
                </div>
            </ng-container>
            <div class="dropdown-divider"></div>
            <div class="label marginleft-10">Kunden</div>
            <div class="dropdown-item link" *ngFor="let user of availableClients" (click)="onTargetSelectionChangedUser(user);$event.stopPropagation()">
                <input [disabled]="areAllUsersTargeted()" class="input checkbox" type="checkbox" [checked]="selectedClients?.includes(user)"><div class="display-inlineblock">{{user.getName()}}</div>
            </div>
        </div>
    </div>
</div>

<mat-dialog-actions>
    <div class="actions">
        <button class="button-filled small light" (click)="onCancel()">{{cancelButtonText}}</button>
        <button class="button-filled small light float-right" [disabled]="selectedClients?.length == 0 && selectedClientGroups?.length == 0" (click)="onSubmit()">{{submitButtonText}}</button>
    </div>
</mat-dialog-actions>