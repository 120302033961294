<div class="dialog-topbar">
    <h3>Neuen Plan erstellen</h3>
</div>

<div class="dialog-content-wrapper">
    <label for="input-plan-name">Planname</label>
    <input id="input-plan-name" maxlength="35" required class="input plannedmeal-name form-control wide-input" [(ngModel)]="name" placeholder="Name" type="text">
    <div class="spacer-25"></div>
    Achtung: Die Wahl des Startdatums bestimmt den Wochentag, an dem jede Trainingswoche des Athleten startet.
    <div class="">
        Startdatum: 
        <span class="marginleft-10">{{startDate?.asFormatedString()}}</span>
        <input [matDatepicker]="startDatePicker" [value]="startDate" [matDatepickerFilter]="startDateFilterTrainingPlanConfig" class="visibility-hidden width-0 padding-0" (dateInput)="onStartDateChanged($event.value?.toDate())">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker ></mat-datepicker><br>
    </div>
    <div class="spacer-25"></div>
    <div class="link" *ngIf="!template" (click)="takeWeekFromLastPlan = !takeWeekFromLastPlan; $event.stopPropagation()">
        <input class="input checkbox" type="checkbox" [checked]="takeWeekFromLastPlan"><div class="display-inlineblock ml-1">Letzte Woche des letzten Zyklus kopieren</div>
    </div>
    <ng-container *ngIf="!takeWeekFromLastPlan">
        <div class="spacer-25"></div>
        Vorlage (optional): <ng-container *ngIf="template">{{template.getName()}}</ng-container><br>
        <div class="spacer-10"></div>
        <button *ngIf="template" class="button-filled small light" (click)="onRemoveTrainingPlanTemplate()">Vorlage entfernen</button>
        <button *ngIf="!template" class="button-filled small light" (click)="onOpenTrainingPlanTemplatesSelection()">Vorlage auswählen</button>
    </ng-container>

    <div class="spacer-25"></div>
    <div class="actions row">
        <div class="col">
            <button class="button-filled small light" (click)="onCancelDialog()">Schließen</button>
        </div>
        <div class="col">
            <button class="button-filled small float-right" (click)="onConfirmDialog()">Erstellen</button>
        </div>
    </div>
</div>