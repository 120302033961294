<div class="layout-box sub-box">
    <div class="row">
        <div class="col">
            <div class="row">
                <h3 class="mb-0 col-auto">{{selectedSession?.sessionName}}</h3>
            </div>
            <div class="row label">
                <div class="col-auto">
                    <ng-container *ngIf="selectedSession?.plannedWeek">
                        W{{selectedSession?.plannedWeekIndex + 1}}
                        <ng-container *ngIf="selectedSession?.plannedWeek?.name?.GetValue(languageService.selectedLanguageCode) && selectedSession?.plannedWeek?.name?.GetValue(languageService.selectedLanguageCode) !== ('W' + (selectedSession?.plannedWeekIndex + 1))">
                            ({{selectedSession?.plannedWeek?.name?.GetValue(languageService.selectedLanguageCode) ?? ''}})
                        </ng-container>,
                    </ng-container>
                    {{selectedSession?.endDate | date:'dd.MM.yyyy'}}
                </div>
            </div>
        </div>
        <div class="spacer-10"></div>
    </div>
    <ng-template #loadingData>
        <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
    </ng-template>
    <div *ngIf="!loading; else loadingData;" class="row">
        <div *ngIf="navigationDisabled && trackedTrainingExercise">
            <h3 class="col-12 mb-0">
                {{trainingService.getExerciseById(trackedTrainingExercise?.exerciseId)?.name?.GetValue(languageService.selectedLanguageCode)}}
            </h3>
            <div class="col-12 label mb-2">
                {{trainingService.getExerciseById(trackedTrainingExercise?.exerciseId)?.subName?.GetValue(languageService.selectedLanguageCode)}}
            </div>
        </div>
        <ng-container *ngIf="!selectedSession?.deleted && trackedTrainingExercise && !trackedTrainingExercise?.deleted && (!trackedTrainingExercise?.replacedExerciseId || trackedTrainingExercise?.exerciseId == plannedExercise?.exerciseId); else noData;">
            <ng-container *ngTemplateOutlet="exerciseTable; context: {trackedTrainingExercise: trackedTrainingExercise, smallDisplay: utilityService.onMobile() }"></ng-container>
        </ng-container>
        <ng-template #noData>
            <div class="col-12 col-md-10">
                <ng-container *ngIf="trackedTrainingExercise?.replacedExerciseId && trackedTrainingExercise?.exerciseId != plannedExercise?.exerciseId; else exerciseDeleted;">
                    Die Übung wurde während des Trainings durch eine andere Übung ersetzt.
                    <div class="layout-box alternative-exercise-box">
                        <h3 class="col-12 mb-0">
                            {{trainingService.getExerciseById(trackedTrainingExercise.exerciseId)?.name?.GetValue(languageService.selectedLanguageCode)}}
                        </h3>
                        <div class="col-12 label mb-2">
                            {{trainingService.getExerciseById(trackedTrainingExercise.exerciseId)?.subName?.GetValue(languageService.selectedLanguageCode)}}
                        </div>
                        <ng-container *ngTemplateOutlet="exerciseTable; context: {trackedTrainingExercise: trackedTrainingExercise, smallDisplay: utilityService.onMobile()}"></ng-container>
                    </div>
                </ng-container>
            </div>
            <ng-template #exerciseDeleted>
                <ng-container *ngIf="trackedTrainingExercise?.deleted; else missingInformation;">
                    Die Übung wurde gelöscht.
                </ng-container>
            </ng-template>
            <ng-template #missingInformation>
                Keine Daten vorhanden.
            </ng-template>
        </ng-template>
    </div>
    <div class="row" *ngIf="trackedTrainingExercise?.note">
        <div class="spacer-25"></div>
        <div class="col-12">
            <svg _ngcontent-aot-c1="" class="bi bi-sticky" fill="currentColor" height="16" viewBox="0 0 16 16"
                width="16" xmlns="http://www.w3.org/2000/svg">
                <path _ngcontent-aot-c1=""
                    d="M2.5 1A1.5 1.5 0 0 0 1 2.5v11A1.5 1.5 0 0 0 2.5 15h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 15 8.586V2.5A1.5 1.5 0 0 0 13.5 1h-11zM2 2.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5V8H9.5A1.5 1.5 0 0 0 8 9.5V14H2.5a.5.5 0 0 1-.5-.5v-11zm7 11.293V9.5a.5.5 0 0 1 .5-.5h4.293L9 13.793z">
                </path>
            </svg>
            {{trackedTrainingExercise.note}}
        </div>
        <div class="spacer-25"></div>
    </div>
    <div *ngIf="!navigationDisabled" class="date-selection jump-button-container">
        <button class="button change-date p-0" (click)="jumpToPreviousTrackedExercise()">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-left" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
            </svg>
        </button>
        <button class="button change-date ml-2 p-0" (click)="jumpToNextTrackedExercise()">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
            </svg>
        </button>
    </div>
</div>

<ng-template #exerciseTable let-trackedTrainingExercise="trackedTrainingExercise" let-smallDisplay="smallDisplay">
    <table class="col-12 sets-table exercise-table">
            <thead>
                <tr>
                    <th class="setnumber">
                        <div class="label">Satz</div>
                        <div class="text-center">#</div>
                    </th>
                    <th *ngIf="trackedTrainingExercise?.hasVideoRecordings()" class="video-column">
                        <div class="label">Video</div>
                        <div class="text-center">&nbsp;</div>
                    </th>
                    <th class="value-column"
                        *ngFor="let setParameter of getAvailableSetParameters(trackedTrainingExercise?.trackedSets)">
                        <div class="label">{{setParameter2SubHeadingMapping[setParameter]}}</div>
                        <div>{{setParameter2LabelUnitMapping[setParameter] | unitConversion: languageService.selectedUnitSystem}}</div>
                    </th>
                    <!-- <th class="value-column" *ngIf="hasExertionValues(trackedTrainingExercise.trackedSets)">
                        <div class="label">{{setParameter2SubHeadingMapping[setParameter.rir]}}</div>
                        {{getExertionUnitFromPlannedExercise(getPlannedExerciseById(superSet.trackedSuperSetExercises[0]?.plannedExerciseId, trainingSessionContainer))}}
                    </th> -->
                    <th *ngIf="environment.firebaseProjectId == 'traindoo-app'" class="video-column">
                        <div class="label">1RM</div>
                        <div class="text-center">&nbsp;</div>
                    </th>
                    <th class="value-column"
                        *ngIf="trackedTrainingSetHasAnyPauseDuration(trackedTrainingExercise?.trackedSets)">
                        <div class="label">Pausendauer</div>
                        <div>{{setParameter2LabelUnitMapping[setParameter.time]}}</div>
                    </th>
                    <th *ngIf="trackedTrainingExercise?.hasCoacheeNotes()" class="video-column">
                        <div class="label">Notiz</div>
                        <div class="text-center">&nbsp;</div>
                    </th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let set of trackedTrainingExercise.trackedSets; let i = index">
                    <td class="setnumber"
                        class="{{isPartOfDropset(trackedTrainingExercise.sets[i], trackedTrainingExercise.sets, i) ? 'dropset-line' : ''}}">
                        <ng-container *ngIf="set.isWarmupSet || set.isDropset; else defaultSet">
                            <div matTooltip="’W’ kennzeichnet einen Warmup-Satz. Dieser Satz fließt nicht in deine Statistik mit ein."
                                class="setmode-indicator" *ngIf="set.isWarmupSet">
                                W
                            </div>
                            <div matTooltip="’D’ kennzeichnet einen Dropset." class="setmode-indicator"
                                *ngIf="set.isDropset">
                                D
                            </div>
                        </ng-container>
                        <ng-template #defaultSet>
                            <span tooltip="Satz-Typ wählen">
                                {{trackedTrainingExercise.getRealSetIndex(set) + 1}}
                            </span>
                        </ng-template>
                    </td>
                    <td *ngIf="trackedTrainingExercise?.hasVideoRecordings()">
                        <div *ngIf="trackedTrainingExercise.getTrackedVideoRecordingBySetNumber(set.setNumber)">
                            <div class="icon-button"
                                (click)="onShowTrackedVideoRecording(trackedTrainingExercise.getTrackedVideoRecordingBySetNumber(set.setNumber))">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-camera-video" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z" />
                                </svg>
                                <div *ngIf="trackedTrainingExercise.getTrackedVideoRecordingBySetNumber(set.setNumber)?.viewedByCoach == false"
                                    class="indicator small display-inlineblock marginleft-5"
                                    tooltip="Du hast diese Videoaufnahme noch nicht angesehen."></div>
                            </div>
                        </div>
                    </td>
                    <td class="value-column"
                        *ngFor="let setParameter of getAvailableSetParameters(trackedTrainingExercise.trackedSets)">
                        <ng-container *ngIf="setParameter == 'time'; else notduration;">
                            {{getSecondsAsTime(set[setParameter])}}<div
                                *ngIf="trackedTrainingExercise.sets[i]?.getValueBySetParameter(setParameter, languageService.selectedUnitSystem)"
                                class="label" style="display: inline;"> /
                                {{trackedTrainingExercise.sets[i]?.getValueBySetParameter(setParameter, languageService.selectedUnitSystem)}}</div>
                        </ng-container>
                        <ng-template #notduration>
                            <ng-container *ngIf="setParameter == 'weight'">{{set[setParameter] | weightConversion: languageService.selectedUnitSystem: true }}</ng-container>
                            <ng-container *ngIf="setParameter != 'weight'">{{set[setParameter] }}</ng-container>
                            <div
                                *ngIf="trackedTrainingExercise.sets[i]?.getValueBySetParameter(setParameter, languageService.selectedUnitSystem)"
                                class="label" style="display: inline;"> /
                                {{trackedTrainingExercise.sets[i]?.getValueBySetParameter(setParameter, languageService.selectedUnitSystem)}}</div>
                        </ng-template>
                    </td>
                    <!-- <td class="value-column" *ngIf="hasExertionValues(superSet.trackedSuperSetExercises[0].trackedSets)">
                        {{set.exertionValue}}
                    </td> -->
                    <td class="value-column" *ngIf="environment.firebaseProjectId == 'traindoo-app'">
                        {{set?.getE1RM() | weightConversion: languageService.selectedUnitSystem: true }}
                    </td>
                    <td class="value-column"
                        *ngIf="trackedTrainingSetHasAnyPauseDuration(trackedTrainingExercise.trackedSets)">
                        {{getSecondsAsTime(set.pauseDuration)}}
                    </td>
                    <td *ngIf="trackedTrainingExercise?.hasCoacheeNotes()" class="{{smallDisplay ? '' : 'note-column'}}">
                        <ng-container *ngIf="set.coacheeNote?.length > 0">
                            <div *ngIf="smallDisplay && set.coacheeNote?.length > 0; else showNoteContent;">
                                <div class="icon-button" tooltip="{{set.coacheeNote}}">
                                    <i class="bi bi-chat-left fontsize-20"></i>
                                </div>
                            </div>
                            <ng-template #showNoteContent>
                                <div>
                                    {{set.coacheeNote}}
                                </div>
                            </ng-template>
                        </ng-container>
                    </td>
                    <td *ngIf="environment.firebaseProjectId == 'traindoo-app'">
                        <div class="icon-button display-inlineblock marginleft-10 button-exerciserecording" (click)="onOpenRpeDialog(set)">
                            <i class="bi bi-table fontsize-18"></i>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
</ng-template>
