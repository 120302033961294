import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Licence } from 'src/app/model/lid.model';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-user-activation-dialog',
  templateUrl: './user-activation-dialog.component.html',
  styleUrls: ['./user-activation-dialog.component.css']
})
export class UserActivationDialogComponent {

  constructor(public dialogRef: MatDialogRef<UserActivationDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { user: User }, public userService: FirestoreService, private toastr: ToastrService) {
    this.user = data.user
  }

  public user: User

  isNutritionEnabled() {
    if (this.user.nutritionEnabled != null) return this.user.nutritionEnabled
    return this.userService.getLoggedInUser().isNutritionEnabled()
  }

  onEnableNutritionChanged(value: boolean) {
    this.user.nutritionEnabled = value
    this.userService.updateLicenceSettings(this.user)
  }

  getAssignedGroupNames(): string {
    var text = '';
    this.user.metadataUser?.assignedClientGroups?.forEach(group => {
      if (text.length > 0){
        text = text.concat(', ')
      }
      text = text.concat(group);
    })
    if (text.length > 0) return text
    return null
  }
  onAssignToClientGroup(group: string) {
    if (!this.user.metadataUser.assignedClientGroups.includes(group)) {
      this.userService.addClientToGroup(this.user, group)
    } else {
      this.userService.removeClientFromGroup(this.user, group)
    }
  }

  onCloseDialog() {
    this.onConfirmDialog()
  }
  async onConfirmDialog() {
    this.dialogRef.close()
  }
  
}
