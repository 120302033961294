<div *ngIf="environment.firebaseProjectId == 'traindoo-app'" class="row margin-0">
  <div class="col-12 already-user-box" *ngIf="userService.getLoggedInUser()?.licenceHolder">
    <div class="container"> 
      <div class="bold display-inlineblock text">
        Du bist bereits ein TRAINDOO Coach!
      </div>
      <a class="btn btn-lg btn-primary btn-block mb-1 button-filled light float-right" routerLink="/dashboard">Zum Dashboard</a>
    </div>
  </div>
  <div class="col-12 already-user-box" *ngIf="!userService.getLoggedInUser()?.licenceHolder">
    <div class="container"> 
      <div class="bold display-inlineblock text">
        Bist du bereits Coach bei TRAINDOO 2.0?
      </div>
      <a class="btn btn-lg btn-primary btn-block mb-1 button-filled light float-right" routerLink="/login">Anmelden</a>
    </div>
  </div>
  <div class="col-12">
    <div class="container pt-3">
      <div class="row justify-content-sm-center">
          <div class="col-12">
            <div class="branding">
              <img draggable="false" class="logo" src="assets/traindoo/Traindoo Full Logo.png">
            </div>
          </div>
          <div class="col-12">
            <div class="layout-box login">
              <h2>Starte als Coach mit TRAINDOO</h2>
              <div class="">
                <div class="row">
                  <div class="col-12" *ngIf="step == 0">
                    <div class="bold">Gib ein paar Daten zu deinem Coaching an.</div>
                    <div class="spacer-20"></div>
                    <form>
                      <div>Wie heißt du?</div>
                      <div class="spacer-10"></div>
                      <input [(ngModel)]="coachName" [ngModelOptions]="{standalone: true}" type="text" class="form-control mb-2" placeholder="Vorname" required/>
                      <div class="spacer-10"></div>
                      <div>Hat dein Coaching oder Unternehmen einen Namen? Dann gib ihn hier an. Wenn nicht, gib deinen Vor- und Nachnamen an.</div>
                      <div class="spacer-10"></div>
                      <input [(ngModel)]="licenceHolderName" [ngModelOptions]="{standalone: true}" type="text" class="form-control mb-2" placeholder="Coachingname oder Vor- und Nachname" required autofocus/>
                      <div class="spacer-10"></div>
                      <button class="btn btn-lg btn-primary btn-block mb-1 button-filled float-right" type="submit" (click)="onContinue()">Weiter</button>
                      <br><br>
                    </form>
                  </div>
                  <div class="col-12" *ngIf="step == 1">
                    <div class="bold">Noch ein paar rechtliche Themen.</div>
                    <div class="spacer-20"></div>
                    <form>
                      <div class="terms">
                        <input class="input checkbox" type="checkbox" [(ngModel)]="termsAccepted"> Ich stimme den <a href="{{environment.termsAndConditionsUrl}}" target="_blank" class="underline">Nutzungsbedingungen von {{environment.brandName}}</a> zu.
                      </div>
                      <div class="spacer-10"></div>
                      <button *ngIf="termsAccepted" class="btn btn-lg btn-primary btn-block mb-1 button-filled float-right" type="submit" (click)="onContinue()">Weiter</button>
                      <br><br>
                    </form>
                  </div>
                  <div class="col-12" *ngIf="step == 2 && !loading">
                    <div *ngIf="createAccount" class="bold">Erstelle einen Account.</div>
                    <div *ngIf="!createAccount" class="bold">Melde dich mit deinem Account an.</div>
                    <div *ngIf="!createAccount">
                      <div class="spacer-10"></div>
                      Unter dieser Email-Adresse besteht bereits ein Nutzeraccount. Melde dich mit deinen bestehenden Zugangsdaten an, um als Coach zu starten.
                    </div>
                    <div class="spacer-20"></div>
                    <form>
                      <input [(ngModel)]="mail" [ngModelOptions]="{standalone: true}" type="text" class="form-control mb-2" placeholder="Email" required autofocus/>
                      <input *ngIf="createAccount" [(ngModel)]="mailValidation" [ngModelOptions]="{standalone: true}" type="text" class="form-control mb-2" placeholder="Email bestätigen" required/>
                      <input [(ngModel)]=password [ngModelOptions]="{standalone: true}" type="password" class="form-control mb-2" placeholder="Password" required/>
                      <div class="spacer-10"></div>
                      <button *ngIf="createAccount" class="btn btn-lg btn-primary btn-block mb-1 button-filled float-right" type="submit" (click)="register()">Registrieren</button>
                      <button *ngIf="!createAccount" class="btn btn-lg btn-primary btn-block mb-1 button-filled float-right" type="submit" (click)="login()">Anmelden</button>
                      <br><br>
                    </form>
                  </div>
                  <div class="col-12" *ngIf="step == 2 && loading">
                    <div class="bold">Dein Account wird erstellt... 🚀</div>
                    <div class="spacer-20"></div>
                    <div class="loading-animation">
                      <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
                    </div>
                    <div class="spacer-20"></div>

                  </div>
                  <div class="col-12" *ngIf="step == 3">
                    <div class="bold">Fertig!<br>Du kannst jetzt in TRAINDOO loslegen.</div>
                    <div class="spacer-20"></div>

                    <button class="btn btn-lg btn-primary btn-block mb-1 button-filled float-right" type="submit" (click)="goToDashboard()">Jetzt loslegen</button>
                    <div class="spacer-20"></div>

                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="step == 0" class="old-traindoo textalign-center">
              <div class="bold">Nutzt du Traindoo 1.0?</div>
              <div class="spacer-10"></div>
              Aktuell arbeiten wir an der Übertragung der Accounts von Traindoo 1.0 auf Traindoo 2.0. Arbeite vorübergehend weiter in Traindoo 1.0 und wechsle später zu Traindoo 2.0.
              <div class="spacer-20"></div>
              <a class="button-filled" href="https://beta.app.traindoo.io">Zu Traindoo 1.0</a>
            </div>
            <div class="spacer-50"></div>
          </div>
      </div>
    </div>
  </div>
</div>