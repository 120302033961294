import { LanguageDictionary } from "./languagedictionary.model";

export class ActivityFact {
    public id: string;
    public nameTranslation: LanguageDictionary<string> = new LanguageDictionary<string>();
    public met: number;
    public valid: boolean;

    constructor();
    constructor(init: ActivityFact);
    constructor(init?: ActivityFact) {
        this.id = init && init.id || null
        this.nameTranslation = init && new LanguageDictionary<string>(init.nameTranslation?.de, init.nameTranslation?.en, init.nameTranslation?.es, init.nameTranslation?.fr) || new LanguageDictionary<string>()
        this.met = init && init.met || 0
        this.valid = init && init.valid || false
    }

    getBurnedCaloriesPerMinute(bodyWeight: number) {
        return 0.0175 * this.met * bodyWeight;
    }
}