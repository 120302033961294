<div *ngIf="!userService.getLoggedInUser()?.disabled" class="page-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-6 greeting order-2 order-md-1">
                <h2 [ngStyle]="utilityService.customMobileAppStyle('dashboardH2')">Hi {{userService.getLoggedInUser()?.name ?? ''}}!</h2>
            </div>
            <div class="col-10 col-md-4 col-lg-6 profile order-1 order-md-2">
                <div class="profile-container">
                    <div class="profile-picture">
                        <button class="profile-button" title="Profilbild hochladen" (click)="openProfilePicDialog()">
                            <span class="hint">Profilbild hochladen</span>
                            <input id="input-profilepicture" type="file" style="display: none;" accept=".jpg, .png, .jpeg" (change)="uploadProfilePic($event)">
                            <img draggable="false" src="{{userService.getLoggedInUser()?.profilePictureUrl}}">
                        </button>
                    </div>
                    <h3 class="name">{{userService.getLoggedInUser()?.name}}</h3>
                    <div *ngIf="utilityService.onNativeMobileApp()" class="refresh-button" tooltip="Seite neu laden." (click)="refreshCapacitorApp()">
                        <!-- <i class="bi bi-arrow-clockwise"></i> -->
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                          </svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="hasAccess()">
            <div class="col-12 col-xl-6 col-xxl-8" [ngStyle]="utilityService.customMobileAppStyle('dashboardCustomers')">
                <div class="row">
                    <div *ngIf="isTrialEndingSoon()" class="col-12 col-md-8 col-xl-8 col-xxl-6">
                        <div class="layout-box">
                            <img draggable="false" class="logo" src="assets/traindoo/Traindoo Full Logo.png">
                            <div class="spacer-20"></div>
                            <h3 translate>Deine Testphase ist bald beendet!</h3>
                            <span translate>
                                Wenn dir TRAINDOO gefallen hat, freuen wir uns, wenn du dein Abonnement erstellst und TRAINDOO weiterhin für dein Coaching nutzt.
                            </span>
                            <div class="spacer-10"></div>
                            <span translate>
                                Wenn du Fragen hast oder Hilfe benötigst, schreib uns gerne eine Nachricht über den Chat unten rechts oder sieh dir unsere Tutorials an.
                            </span>
                            <div class="spacer-25"></div>
                            <button class="button-filled small" (click)="openAccountSettings()" translate>
                                Abonnement erstellen
                            </button>
                        </div>
                        <div class="spacer-20"></div>
                    </div>

                    <ng-container *ngIf="userService.licenceConnectionRequests?.length > 0">
                        <div class="col-12">
                            <div class="spacer-25"></div>
                            <h3 translate>Verbindungsanfragen:</h3>
                        </div>
                        <ng-container *ngFor="let request of userService.licenceConnectionRequests">
                            <div class="col-12 col-sm-6 col-lg-6 col-xl-12 col-xxl-4">
                                <div class="layout-box">
                                    {{request.user?.getName() ?? request.id}}
                                    <div class="spacer-10"></div>
                                    <ng-container *ngIf="!request.loading; else requestLoading">
                                        <div class="icon-button" (click)="onDeclineConnectionRequest(request)" translate>
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                            Ablehnen
                                        </div>
                                        <div class="icon-button float-right" (click)="onAcceptConnectionRequest(request)" translate>
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                            </svg>
                                            Annehmen
                                        </div>
                                    </ng-container>
                                    <ng-template #requestLoading>
                                        <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
                                    </ng-template>
                                </div>
                            </div>
                        </ng-container>
                        <div class="spacer-20"></div>
                    </ng-container>

                    <app-clients-overview [forcedViewMode]="'grid'" class="width-100" [gridItemClasses]="'col-12 col-sm-6 col-md-6 col-xl-6 col-xxl-4'"></app-clients-overview>

                    <div *ngIf="userService.getLoggedInUser()?.isCoach && userService.clientsLoaded && userService.getLoggedInUser()?.coach && userService.getAccessibleClients()?.length == 0 && getPendingLicences()?.length == 0" class="col-12 col-md-8 col-xl-8 col-xxl-6">
                        <div class="layout-box turquoise">
                            <h3 translate>Noch ganz schön leer hier...</h3>
                            <span translate>
                                Füge dich als ersten Athleten hinzu!
                            </span>
                            <br><br>
                            <span translate [translateParams]="{trainerCodeParameter: userService.getLoggedInUser()?.coach.trainerCode}">
                                Lade dir dazu auf deinem Smartphone die TRAINDOO 2.0 App herunter und gib nach der Anmeldung deinen Trainercode trainerCodeParameter ein.
                            </span>
                            <br><br>
                            <span translate>
                                Deinen Trainercode findest du auch unten links über dein Profilbild. Über ihn können sich alle deine Athleten mit dir verbinden.
                            </span>
                        </div>
                        <div class="spacer-20"></div>
                    </div>

                    <div class="icon-button button-reactivate-client" (click)="onReactivateClient()" translate>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
                        </svg>
                        Athlet reaktivieren
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-6 col-xxl-4" [ngStyle]="utilityService.customMobileAppStyle('dashboardOverview')">
                <div *ngIf="!environment.isWhitelabel && !isAdmin() && userService.getLoggedInUser()?.isCoach && userService.getLoggedInUser()?.licenceHolder?.hasFailedPayments()" class="layout-box link" (click)="openAccountSettings()">
                    <div class="alert-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                            <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"/>
                            <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
                        </svg>
                    </div>
                    <h3 class="display-inlineblock" translate>Du hast fehlgeschlagene Zahlungen</h3>
                    <div translate>Klicke hier, um zur Zahlungsübersicht zu gelangen und deine offenen Rechnungen zu begleichen.</div>
                </div>
                <div *ngIf="userService.getLoggedInUser()?.isCoach" class="layout-box adjust-mobile">
                    <div *ngIf="userService.getLoggedInUser()?.isCoach" class="row justify-content-center">
                        <div class="div textalign-center tab-button-group adjust-mobile">
                            <button *ngIf="userService.getLoggedInUser()?.isCoach" class="{{eventLogTab}}" (click)="swapTab('eventLogView')" translate>
                                Feed
                            </button>
                            <button *ngIf="!utilityService?.onNativeMobileApp()" class="{{unreadMessagesTab}}" (click)="swapTab('unreadMessagesView')">
                                <span translate>Nachrichten</span> ({{chatService.getUnreadChats()?.length}})
                            </button>
                            <button *ngIf="userService.getLoggedInUser()?.isCoach && getNumberOfExpiringPlans() > 0" class="{{getExpiringNutritionPlansTab}}" (click)="swapTab('expiringNutritionPlansView')">
                                <span translate>Pläne</span> ({{getNumberOfExpiringPlans()}})
                            </button>
                        </div>
                    </div>
                    <div [ngClass]="utilityService?.onNativeMobileApp() ? 'spacer-10' : 'spacer-25'"></div>

                    <div *ngIf="showEventLogView">
                        <app-event-log [allCoachUsers]="true" ></app-event-log>
                    </div>
                    <div *ngIf="showTasksView">
                        <app-task-list></app-task-list>
                    </div>
                    <div *ngIf="showUnreadMessagesView">
                        <h3 translate>Ungelesene Nachrichten</h3>
                        <div *ngFor="let chat of chatService.getUnreadChats()">
                            <div class="layout-box chat-box" (click)="onOpenChat(chat)">
                                <div class="chat-name">{{chat.chatPartner?.getName()}}</div>
                                <div class="message-preview">{{chat.unreadMessages[0]?.message}}</div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showExpiringNutritionPlansView">
                        <h3 translate>Ablaufende Pläne</h3>
                        <ng-container *ngFor="let client of getExpiringPlanClients()">
                            <div *ngIf="client.hasExpiringNutritionPlan()" class="layout-box sub-box">
                                <div class="bold">{{client.getName()}}</div>
                                <div class=""><span translate>Ablaufdatum Ernährungsplan:</span> {{client.getExpiringNutritionPlanDate().asFormatedString()}}</div>
                                <div class="icon-button small" routerLink="/client/{{client.uid}}" translate>
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                    </svg>
                                    Coachee öffnen
                                </div>
                                <div class="icon-button marginleft-25" (click)="onHideExpiringNutritionPlanHint(client)" translate>
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                    Ausblenden
                                </div>
                            </div>
                            <div *ngIf="client.hasExpiringTrainingPlan()" class="layout-box sub-box">
                                <div class="bold">{{client.getName()}}</div>
                                <div class=""><span translate>Ablaufdatum Trainingsplan:</span> {{client.getExpiringTrainingPlanDate().asFormatedString()}}</div>
                                <div class="icon-button small" routerLink="/client/{{client.uid}}" translate>
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                    </svg>
                                    Coachee öffnen
                                </div>
                                <div class="icon-button marginleft-25" (click)="onHideExpiringTrainingPlanHint(client)" translate>
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                    Ausblenden
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                
            </div>
        </div>


        <div class="row" *ngIf="!hasAccess()">
            <div class="col-12 col-xl-6 col-xxl-8" [ngStyle]="utilityService.customMobileAppStyle('dashboardCustomers')">
                <div class="row">
                    <div class="col-12 col-md-8 col-xl-8 col-xxl-6">
                        <div class="layout-box">
                            <img draggable="false" class="logo" src="assets/traindoo/Traindoo Full Logo.png">
                            <div class="spacer-20"></div>
                            <h3 translate>Deine Testphase ist beendet!</h3>
                            <span translate>
                                Wenn dir TRAINDOO gefallen hat, freuen wir uns, wenn du dein Abonnement erstellst und TRAINDOO weiterhin für dein Coaching nutzt.
                            </span>
                            <div class="spacer-10"></div>
                            <span translate>
                                Wenn du Fragen hast oder Hilfe benötigst, schreib uns gerne eine Nachricht über den Chat unten rechts oder sieh dir unsere Tutorials an.
                            </span>
                            <div class="spacer-25"></div>
                            <button class="button-filled small" (click)="openAccountSettings()" translate>
                                Abonnement erstellen
                            </button>
                        </div>
                        <div class="spacer-20"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <chat *ngIf="userService.getLoggedInUser()?.isCoach">
    </chat>
</div>

<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>