import { AuthService } from 'src/app/auth/auth.service';
import { Component, ComponentFactory, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { NutritionPlanConfigEditorDialogComponent as NutritionPlanConfigEditorDialogComponent } from '../config-editor-dialog/config-editor-dialog.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MealEditorDialogComponent } from '../meal-editor-dialog/meal-editor-dialog.component';
import { Meal } from '../model/meal.model';
import { NutritionPlan } from '../model/nutritionplan.model';
import { CycleConfig, Situation, SituationType, SituationTranslation } from '../model/nutritionconfig.model';
import { PlannedMeal } from '../model/plannedmeal.model';
import { PlannedMealV2 } from '../model/plannedmealv2.model';
import { User } from '../model/user.model';
import { FirestoreNutritionPlanService } from '../services/firestore-nutritionplan.service';
import { NutritionalGoalV2 } from '../model/nutritionalgoalv2.model';
import { NutritionPlanConfig } from '../model/nutritionplanconfig.model';
import { MealType, MealTypeTranslation, NutritionPlanMealConfig, NutritionPlanV2 } from '../model/nutritionplanv2.model';
import { Recipe } from '../model/recipe.model';
import { NutritionService } from '../services/nutrition.service';
import { PlannedFood } from '../model/plannedfood.model';
import { PlanningTemplate } from '../model/planning-template.model';
import { InputFieldDialogComponent } from '../inputfield-dialog/inputfield-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { NutritionplanExportService } from '../services/nutritionplan-export.service';
import { NotificationService } from '../services/notification.service';
import { PastePlanTemplateDialogComponent } from '../paste-plantemplate-dialog/paste-plantemplate-dialog.component';
import { CreateTemplateDialogComponent } from '../create-template-dialog/create-template-dialog.component';
import { UtilityService } from '../services/utility.service';
import { FirestoreService } from '../services/firestore.service';
import { NutritionalValuePopoverComponent } from '../nutritional-value-popover/nutritional-value-popover.component';
import { NutritionalValueHolder } from '../model/basenutritionfact.model';
import { CustomSettingsDialogComponent } from '../dialogs/custom-settings-dialog/custom-settings-dialog.component';
import { firstValueFrom } from 'rxjs';
import { MealsuggestionsSettingsDialogComponent } from '../dialogs/mealsuggestions-settings-dialog/mealsuggestions-settings-dialog.component';
import { LanguageService } from '../services/language.service';
import { Day } from '../model/day.model';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { NumberToWeekDayLetterMapping } from '../model/task.model';

export class CalendarWeek {
  days: CalendarDay[]

  constructor() {
    this.days = []
  }
}

export class CalendarDay {
  date: Date
  items: DayItem[]
  config: CycleConfig
  planConfig: NutritionPlanConfig

  // Tmp:
  dayNumber: number
  isRepetition: boolean = false
  isToday: boolean = false
  isFirstDayOfConfig = false
  isFirstVisibleDayOfConfig = false
  isFirstVisibleDayOfPlanConfig = false

  isFirstDayOfPlanConfig() {
    return this.planConfig?.selectedNutritionPlan?.isRepeating && !this.isRepetition
  }

  constructor() {
    this.items = []
  }

  getNutritionalValue(nutritionalValue: string): number {
    var value = 0
    this.items.forEach(item => {
        if (item.meal) value += item.meal.getNutritionalValue(nutritionalValue)
    })
    return value
  }

  getItemByMealConfigId(id: string): DayItem {
    var value = null
    this.items.forEach(item => {
        if (item.mealConfig?.id == id) value = item
    })
    return value
  }

  isInPast(): boolean {
    let today = new Date()
    return this.date.getTime() < today.getTime() && !this.date.isSameDate(today)
  }

  getGraphForegroundWidth(graphType: string) {
    return (this.getNutritionalValue(graphType) < this.config.getNutritionalGoalForDate(this.date).getNutritionalValue(graphType) ? (this.getNutritionalValue(graphType) / this.config.getNutritionalGoalForDate(this.date).getNutritionalValue(graphType) * 100) : ( this.config.getNutritionalGoalForDate(this.date).getNutritionalValue(graphType) / this.getNutritionalValue(graphType) * 100) )
  }
  hasOverfilledGraph(graphType: string) {
    return this.getNutritionalValue(graphType) >= this.config.getNutritionalGoalForDate(this.date).getNutritionalValue(graphType)
  }
}

export class DayItem {
  meal: PlannedMealV2
  alternativeMeals: PlannedMealV2[]
  mealConfig: NutritionPlanMealConfig

  constructor(mealConfig: NutritionPlanMealConfig, meal: PlannedMealV2) {
    this.meal = meal
    this.mealConfig = mealConfig
    this.alternativeMeals = []
  }

  hasAlternativeMeals() {
    return this.alternativeMeals?.length > 0
  }
}

@Component({
  selector: 'app-nutrition-plan',
  templateUrl: './nutrition-plan.component.html',
  styleUrls: ['./nutrition-plan.component.css']
})
export class NutritionPlanComponent implements OnInit {

  public environment = environment

  public months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
  public weekDays = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']
  
  public NumberToWeekDayLetterMapping = NumberToWeekDayLetterMapping;

  public LIST_VIEW = 'LIST_VIEW'
  public WEEK_VIEW = 'WEEK_VIEW'
  public CALENDAR_VIEW = 'CALENDAR_VIEW'

  public showTrainingPlans = false
  public showMetricsAndQuestionaires = false

  @Input() set selectedUser(value: User) {
    this.user = value;
    this.configToSelectedIdMapping = new Map()
    this.reloadData(false, false)
  }
  @Input() set selectedCoach(value: User) {
    this.coach = value;
  }

  @Output() openPlanEvent = new EventEmitter<boolean>();

  public componentFactory: ComponentFactory<any>

  mode: string = this.LIST_VIEW
  coach: User

  user: User
  meals: PlannedMealV2[]

  selectedDate: Date
  weeks: CalendarWeek[]
  configs: CycleConfig[]
  planConfigs: NutritionPlanConfig[]

  showAllNutritionConfigs = false
  hasOlderNutritionConfigs = false
  showAllNutritionPlanConfigs = false
  hasOlderNutritionPlanConfigs = false

  editingConfig: CycleConfig
  editingPlanConfig: NutritionPlanConfig
  openedPlanConfig: NutritionPlanConfig = null

  deprecatedTempates: NutritionPlan[]
  planningTemplates: PlanningTemplate[]

  filteredDeprecatedTempates: NutritionPlan[]
  filteredPlanningTemplates: PlanningTemplate[]

  configToSelectedIdMapping: Map<String, String> = new Map()

  public spinnerText = null
  public dayEndItem = new DayItem(null, null)

  constructor(private nutritionPlanService: FirestoreNutritionPlanService, public nutritionService: NutritionService, private exportService: NutritionplanExportService, public notificationService: NotificationService, public userService: FirestoreService, public dialog: MatDialog, private spinner: NgxSpinnerService, private toastr: ToastrService, public utilityService: UtilityService, private authService: AuthService, public languageService: LanguageService, private componentFactoryResolver: ComponentFactoryResolver, public translate: TranslateService) {
    this.selectedDate = new Date()
    this.selectedDate.setHours(0)
    this.selectedDate.setMinutes(0)
    this.selectedDate.setSeconds(0)
    this.selectedDate.setMilliseconds(0)

    this.configs = []
    this.weeks = []

    if(!this.onPhone()) {
      nutritionPlanService.loadNutritionPlanTemplates()
      nutritionPlanService.loadPlanningTemplates()
    }

    // this.initRecipes()
  }

  ngOnInit(): void {
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(NutritionalValuePopoverComponent);
    this.notificationService.showBadge = false
    this.notificationService.showComposer = false
  }

  ngOnDestroy() {
    this.openPlanEvent.emit(false);
  }

  async initRecipes() {
    this.spinner.show();
    if (!(this.nutritionService.recipes?.length > 0)){
      await firstValueFrom(this.nutritionService.loadRecipes())
    }
    
    this.autoFillRecipeDatabase = []
    this.nutritionService.recipes?.forEach(recipe => this.autoFillRecipeDatabase.push(recipe))
    this.autoFillRecipeDatabase = this.shuffle(this.autoFillRecipeDatabase)
    this.spinner.hide();
  }

  isAdmin(): boolean {
    return this.authService.isAdmin()
  }

  onChangeMode(mode: string) {
    this.mode = mode
    if (mode == this.CALENDAR_VIEW) {
      this.reloadData()
    } else {
      if (mode == this.LIST_VIEW) this.openedPlanConfig = null
      this.populatePlanView()
    }
  }
  
  onSelectSituation(config: CycleConfig, situation: Situation) {
    config.selectedSituation = situation
    this.configToSelectedIdMapping.set(config.id, situation.id)
    this.populatePlanView()
  }

  onHideNutritionValuesChanged(value: boolean) {
    this.user.hideNutritionValues = value
    this.userService.updateLicenceSettings(this.user)
  }
  onEnableRecipeSuggestionsChanged(value: boolean) {
    this.user.recipeSuggestionsEnabled = value
    this.userService.updateLicenceSettings(this.user)
  }
  isRecipeSuggestionsEnabled() {
    if (this.user.recipeSuggestionsEnabled != null) return this.user.recipeSuggestionsEnabled
    return this.coach.isRecipeSuggestionsEnabled()
  }
  onEnableCoacheeNutritionalGoalEditingChanged(value: boolean) {
    this.user.coacheeNutritionalGoalEditingEnabled = value
    this.userService.updateLicenceSettings(this.user)
  }

  // Date selection:

  onShowToday() {
    if (this.mode == this.WEEK_VIEW) {
      this.showWeekForDate(new Date())
    } else {
      this.showMonthForDate(new Date())
    }
  }

  onShowPreviousMonth() {
    var d = new Date(this.selectedDate)
    if (this.mode == this.WEEK_VIEW) {
      d.addDays(-7)
      this.showWeekForDate(d)
    } else {
      d.setMonth(d.getMonth() - 1, 1)
      this.showMonthForDate(d)
    }
  }

  onShowNextMonth() {
    var d = new Date(this.selectedDate)
    if (this.mode == this.WEEK_VIEW) {
      d.addDays(7)
      this.showWeekForDate(d)
    } else {
      d.setMonth(d.getMonth() + 1, 1)
      this.showMonthForDate(d)
    }
  }

  showMonthForDate(date: Date) {
    this.selectedDate = new Date(date.getFullYear(), date.getMonth(), 1)
    this.reloadData()
  }
  showWeekForDate(date: Date) {
    this.selectedDate = date
    this.selectedDate.setHours(0)
    this.selectedDate.setMinutes(0)
    this.selectedDate.setSeconds(0)
    this.selectedDate.setMilliseconds(0)
    this.reloadData()
  }
  onShowInWeekView(day: CalendarDay) {
    this.mode = this.WEEK_VIEW
    this.openedPlanConfig = day.planConfig
    this.showWeekForDate(day.date)
  }

  onShowAllNutritionConfigs(show: boolean) {
    this.showAllNutritionConfigs = show
  }
  onShowAllNutritionPlanConfigs(show: boolean) {
    this.showAllNutritionPlanConfigs = show
  }

  public nutritionalValueHolder: NutritionalValueHolder
  onNutritionalValuesFocused(nutritionalValueHolder: NutritionalValueHolder) {
    this.nutritionalValueHolder = nutritionalValueHolder
  }

  static async composeDays(startDate: Date, endDate: Date, user: User, configs: CycleConfig[], planConfigs: NutritionPlanConfig[], meals: PlannedMealV2[], nutritionPlanService: FirestoreNutritionPlanService, nutritionService: NutritionService): Promise<CalendarDay[]> {
    var days: CalendarDay[] = []

    var dayCount = 0
    var iterator = startDate.clone()
    var lastDay = endDate.clone()

    var matchedConfigs = []
    var matchedPlanConfigs = []
    while (iterator.getTime() <= lastDay.getTime()) {
      var day = new CalendarDay()
      day.dayNumber = dayCount
      day.date = new Date(iterator)
      if (day.date.isSameDate(new Date)) day.isToday = true
      // Search for NutritionConfig applicable for the day.
      configs.forEach(config => {
        if (day.date >= config.startDate && (config.endDate == null || day.date < config.endDate) || day.date.isSameDate(config.startDate) || day.date.isSameDate(config.endDate)) {
          day.config = config
          if (day.date.isSameDate(config.startDate)) day.isFirstDayOfConfig = true
          if (!matchedConfigs.includes(config)) {
            day.isFirstVisibleDayOfConfig = true
            matchedConfigs.push(config)
          }
        }
      })
      // Search for NutritionPlanConfig applicable for the day.
      planConfigs.forEach(config => {
        if (day.date >= config.startDate && (config.endDate == null || day.date < config.endDate) || day.date.isSameDate(config.startDate) || day.date.isSameDate(config.endDate)) {
          day.planConfig = config
          if (!matchedPlanConfigs.includes(config)) {
            day.isFirstVisibleDayOfPlanConfig = true
            matchedPlanConfigs.push(config)
          }
          if (day.date.isSameDate(config.startDate)) config.startDateNumber = day.dayNumber
          config.selectedNutritionPlan?.mealConfigs.forEach(mealConfig => {
            day.items.push(new DayItem(mealConfig, null))
          })
        }
      })

      // Search for Meals of the day and match them with configs.
      var mealsForDay = []
      if (day.planConfig?.selectedNutritionPlan?.isRepeating) {
        var distance = NutritionPlanComponent.dateDiffInDays(day.planConfig.startDate, day.date)
        var repetitionDayNumber = distance % day.planConfig.selectedNutritionPlan.repetitionDuration
        mealsForDay = day.planConfig.selectedNutritionPlan.repeatingMeals?.filter( m => m.repetitionDayNumber == repetitionDayNumber && m.nutritionPlanId == day.planConfig.selectedNutritionPlan.id ).sort((a, b) => a.number - b.number) || []
        if (NutritionPlanComponent.dateDiffInDays(day.planConfig.startDate, day.date) >= day.planConfig.selectedNutritionPlan.repetitionDuration) {
          day.isRepetition = true
        }
      } else if (day.planConfig?.selectedNutritionPlan) {
        mealsForDay = meals.filter( m => m.date.isSameDate(iterator) && m.nutritionPlanId == day.planConfig.selectedNutritionPlan.id ).sort((a, b) => a.number - b.number)
      }

      var lastHandledItem: DayItem = null
      var lastHandledNumber = -1
      var number = 0
      while (mealsForDay.length > 0) {
        var m = mealsForDay[0]
        await nutritionService.loadBaseRecipeForPlannedMeal(m, false)
        if (lastHandledNumber == m.number) {
          if (lastHandledItem) {
            if (m.isAlternative) {
              lastHandledItem.alternativeMeals.push(m)
            } else {
              if (lastHandledItem.meal) {
                lastHandledItem.alternativeMeals.push(m)
              } else {
                lastHandledItem.meal = m
              }
            }
          }
        } else {
          if (m.mealConfigId) {
            var matchedIndex = -1
            day.items.forEach((item, index) => {
              if (matchedIndex < 0 && item.mealConfig && !item.meal && m.mealConfigId == item.mealConfig.id) {
                matchedIndex = index
              }
            })
            if (matchedIndex >= 0) {
              if (m.isAlternative) {
                day.items[matchedIndex].alternativeMeals.push(m)
              } else {
                if (day.items[matchedIndex].meal) {
                  day.items[matchedIndex].alternativeMeals.push(m)
                } else {
                  day.items[matchedIndex].meal = m
                }
              }
              number = matchedIndex
              lastHandledItem = day.items[number]
              lastHandledNumber = number
            } else {
              number = m.number
              lastHandledNumber = m.number
              if (number >= day.items.length) {
                if (m.isAlternative) {
                  var dayItem = new DayItem(null, null)
                  dayItem.alternativeMeals.push(m)
                  day.items.push(dayItem)
                  lastHandledItem = dayItem
                } else {
                  var dayItem = new DayItem(null, m)
                  day.items.push(dayItem)
                  lastHandledItem = dayItem
                }
              } else {
                if (m.isAlternative) {
                  day.items[number].alternativeMeals.push(m)
                } else {
                  if (day.items[number].meal) {
                    day.items[number].alternativeMeals.push(m)
                  } else {
                    day.items[number].meal = m
                  }
                }
                lastHandledItem = day.items[number]
                lastHandledNumber = number
              }
            }
          } else {
            number = m.number
            lastHandledNumber = m.number
            if (number >= day.items.length) {
              if (m.isAlternative) {
                var dayItem = new DayItem(null, null)
                dayItem.alternativeMeals.push(m)
                day.items.push(dayItem)
                lastHandledItem = dayItem
              } else {
                var dayItem = new DayItem(null, m)
                day.items.push(dayItem)
                lastHandledItem = dayItem
              }
              lastHandledNumber = number
            } else {
              if (m.isAlternative) {
                var dayItem = new DayItem(null, null)
                dayItem.alternativeMeals.push(m)
                day.items.splice(number, 0, dayItem)
                lastHandledItem = dayItem
              } else {
                var dayItem = new DayItem(null, m)
                day.items.splice(number, 0, dayItem)
                lastHandledItem = dayItem
              }
              lastHandledNumber = number
            }
          }
        }
        mealsForDay.splice(0, 1)
        m = null
      }

      //await nutritionPlanService?.updateMealPositions(day, user)
      days.push(day)
      dayCount++
      iterator = iterator.addDays(1)
    }

    return days
  }

  async reloadData(updateNutritionPlanEndDate: boolean = false, loadMeals: boolean = true) {
    this.spinner.show()

    var firstDay = this.selectedDate
    if (this.mode == this.CALENDAR_VIEW) firstDay = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1)
    firstDay.addDays(- firstDay.getDayNumber())
    firstDay.setHours(0)
    firstDay.setMinutes(0)
    firstDay.setSeconds(0)
    firstDay.setMilliseconds(0)
    var lastDay = this.selectedDate.clone()
    if (this.mode == this.CALENDAR_VIEW) lastDay = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, 0)
    lastDay.addDays(6 - lastDay.getDayNumber())

    this.hasOlderNutritionConfigs = false
    this.showAllNutritionConfigs = false
    this.hasOlderNutritionPlanConfigs = false
    this.showAllNutritionPlanConfigs = false

    var openedPlanConfigId = this.openedPlanConfig?.id || null

    await this.nutritionPlanService.loadCycleConfigs(this.user)
    this.configs = this.user.configs
    this.sortConfigs()
    this.user.configs = this.configs

    await this.nutritionPlanService.loadNutritionPlanConfigs(this.user)
    this.planConfigs = this.user.planConfigs

    this.configs.forEach(config => {
      if (this.configToSelectedIdMapping.has(config.id)) config.setSelectedSituationById(this.configToSelectedIdMapping.get(config.id))
    })
    this.planConfigs.forEach(config => {
      if (this.configToSelectedIdMapping.has(config.id)) config.setSelectedNutritionPlanById(this.configToSelectedIdMapping.get(config.id))
      if (openedPlanConfigId && openedPlanConfigId == config.id) {
        this.openedPlanConfig = config
        this.applyConnectedSituationId(config, config.selectedNutritionPlan)
      }
    })

    if (updateNutritionPlanEndDate) {
      var maxEndDate: Date = undefined
      this.planConfigs.forEach(plan => {
        if (plan.isActive && !plan.isOlderThanToday()) {
          if (plan.endDate) {
            if (maxEndDate === undefined || maxEndDate && plan.endDate.isSameOrAfterDate(maxEndDate)) maxEndDate = plan.endDate
          } else {
            maxEndDate = null
          }
        }
      })
      if (this.user.metadata && this.user.metadata.nutritionPlanEndDate != maxEndDate) {
        this.user.metadata.nutritionPlanEndDate = maxEndDate || null
        this.userService.updateMetadata(this.user)
      }
    }

    if (this.configs.length == 0) {
      this.migrateNutritionalGoals()
    }
    /*if (this.planConfigs.length == 0 && this.user.nutritionPlans.length > 0) {
      var activePlan
      this.user.nutritionPlans.forEach(plan => {
        if (plan.isRunning() && !activePlan) {
          activePlan = plan
        }
      })
      if (activePlan) {
        await this.migrateNutritionPlan(activePlan)
      }
    }*/

    // Prepare NutritionConfigs.
    this.hasOlderNutritionConfigs = this.configs.find(c => c.isOlderThanToday()) != null

    // Prepare NutritionPlanConfigs.
    for (var i = 0; i < this.planConfigs.length; i++) {
      var planConfig = this.planConfigs[i]
      planConfig.colorScheme = '' + (i % 3)

      // Load repeatable meals for Config if necessary:
      if (planConfig.isActiveAtDate(firstDay) || planConfig.isActiveAtDate(lastDay) || (planConfig.startDate.isSameOrAfterDate(firstDay) && planConfig.startDate.isSameOrBeforeDate(lastDay)))  {
          await this.setupPlanConfig(planConfig, this.user)
      }
      if (planConfig.isOlderThanToday()) this.hasOlderNutritionPlanConfigs = true
      
    }

    if(!this.onPhone() && loadMeals) {
      await this.loadMealsForSelectedDateRange();
    }

    this.spinner.hide()

    this.showMetricsAndQuestionaires = true;
    this.showTrainingPlans = true;
  }

  async loadMealsForSelectedDateRange() {
      this.spinner.show();
      // Load meals for current time range.
      var firstDay = this.selectedDate.clone()
      if (this.mode == this.CALENDAR_VIEW) firstDay = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1)
      firstDay.addDays(- firstDay.getDayNumber())
      var lastDay = this.selectedDate.clone()
      if (this.mode == this.CALENDAR_VIEW) lastDay = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, 0)
      lastDay.addDays(6 - lastDay.getDayNumber())
      this.meals = (await this.nutritionPlanService.getNutritionPlanMealsForDateRange(this.user, firstDay, lastDay)).filter(m => !m.isRepeating)
      this.populatePlanView()
      this.spinner.hide();
  }

  sortConfigs() {
    this.configs = this.configs.sort((a, b) => b.startDate.getTime() - a.startDate.getTime())
    this.user.configs = this.configs
  }
  sortPlanConfigs() {
    this.planConfigs = this.planConfigs.sort((a, b) => b.startDate.getTime() - a.startDate.getTime())
  }

  updateLatestNutritionPlanEndDate() {
    var maxEndDate: Date = undefined
    this.planConfigs.forEach(plan => {
      if (plan.isActive && !plan.isOlderThanToday()) {
        if (plan.endDate) {
          if (maxEndDate == undefined || maxEndDate && plan.endDate.isSameOrAfterDate(maxEndDate)) maxEndDate = plan.endDate
        } else {
          maxEndDate = null
        }
      }
    })
    if (this.user.metadata != null && maxEndDate != this.user.metadata?.nutritionPlanEndDate) {
      this.user.metadata.nutritionPlanEndDate = maxEndDate || null
      this.userService.updateMetadata(this.user)
    }
  }

  async setupPlanConfig(planConfig: NutritionPlanConfig, user: User, overwriteMeals: PlannedMealV2[] = null) {
    for (var j = 0; j < planConfig.nutritionPlans.length; j++) {
      var plan = planConfig.nutritionPlans[j]

      if (plan.isRepeating && !plan.repeatingMeals) {
        plan.repeatingMeals = []
        var configStartDate = planConfig.startDate.clone()
        var configEndDate = configStartDate.clone().addDays(plan.repetitionDuration)

        var meals = overwriteMeals?.filter(m => m.nutritionPlanId ==  plan.id && m.isRepeating) ?? (await this.nutritionPlanService.getNutritionPlanMealsForDateRange(user, configStartDate, configEndDate)).filter(m => m.nutritionPlanId == plan.id)
        meals.forEach(meal => {
          if (meal.isRepeating) {
            plan.repeatingMeals.push(meal)
          }
        })
      }
    }
  }

  async populatePlanView() {

    var firstDay = this.selectedDate.clone()
    if (this.mode == this.CALENDAR_VIEW) firstDay = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1)
    firstDay.addDays(- firstDay.getDayNumber())
    firstDay.setHours(0)
    firstDay.setMinutes(0)
    firstDay.setSeconds(0)
    firstDay.setMilliseconds(0)
    var lastDay = this.selectedDate.clone()
    if (this.mode == this.CALENDAR_VIEW) lastDay = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, 0)
    lastDay.addDays(6 - lastDay.getDayNumber())

    this.weeks = []
    var dayCount = 0
    var days = await NutritionPlanComponent.composeDays(firstDay, lastDay, this.user, this.configs, this.planConfigs, this.meals, this.nutritionPlanService, this.nutritionService)

    for (var dayCount = 0; dayCount < days.length; dayCount++) {
      if (this.weeks.length <= Math.floor(dayCount / 7)) this.weeks.push(new CalendarWeek())
      this.weeks[Math.floor(dayCount / 7)].days.push(days[dayCount])
    }

  }

  public static MS_PER_DAY = 1000 * 60 * 60 * 24;

  static dateDiffInDays(a, b) {
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc2 - utc1) / this.MS_PER_DAY);
  }
  
  // CONFIG

  focusedConfig: CycleConfig
  onHoverConfig(config: CycleConfig, hovered: boolean) {
    if (config && hovered) {
      this.focusedConfig = config
    } else {
      this.focusedConfig = null
    }
  }

  async onEndNutritionConfig(day: CalendarDay) {
    day.config.endDate = day.date.clone()
    await this.nutritionPlanService.updateNutritionConfig(day.config, this.user).toPromise()
    this.populatePlanView()
  }

  public static getMaxEndDateForConfig(configs: any[], startDate: Date): Date {
    var maxDate: Date = null
    configs.forEach(config => {
      if (config.startDate > startDate) {
        if (maxDate && startDate < maxDate || !maxDate) maxDate = config.startDate.clone()
      }
    })
    if (maxDate) {
      maxDate = maxDate.addDays(-1)
      maxDate.setHours(0)
      maxDate.setMinutes(0)
      maxDate.setSeconds(0)
      maxDate.setMilliseconds(0)
    }
    return maxDate
  }

  async onCreatePlanConfig() {
    var startDate = this.getPlanConfigsMaxStartDate()
    var planConfig = NutritionPlanConfig.defaultConfig(startDate, null, 0)
    planConfig.coachUid = this.coach.uid
    planConfig.licenceHolderUid = this.coach.licenceHolderUid
    await this.initRecipes();
    const dialogRef = this.dialog.open(NutritionPlanConfigEditorDialogComponent, {
      data: { planConfig: planConfig, user: this.user}, width: '1000px', autoFocus: false, restoreFocus: false
    })
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.shouldSave) {
          var planConfig: NutritionPlanConfig = result.planConfig
          var confirmed = await this.confirmCollisionsBeforeSaving(planConfig)
          if (!confirmed) return
          this.spinner.show()
          await this.nutritionPlanService.insertNutritionPlanConfig(planConfig, this.user, null).toPromise()
          this.planConfigs.push(planConfig)
          await this.adjustCollidingNutritionPlanConfigs(planConfig)
          if (result.allowWeightAdjustment != null) this.allowWeightAdjustment = result.allowWeightAdjustment
          if (result.mealSuggestionAccuracy != null) this.mealSuggestionAccuracy = result.mealSuggestionAccuracy
          this.spinner.hide()
          this.reloadData(true)
        }
      }
    });
  }

  async confirmCollisionsBeforeSaving(editedConfig: NutritionPlanConfig): Promise<boolean> {
    var colliding = editedConfig.collidesWith(this.planConfigs)
    if (colliding) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { message: this.translate.instant('Es wurden zeitliche Überschneidungen mit einem anderen Plan gefunden.<br>Möchtest du den Plan wirklich erstellen und den anderen Plan kürzen?'), title: this.translate.instant('Überschneidung gefunden') },
      })
      var result = await dialogRef.afterClosed().toPromise()
      if (result != true) return false
    }
    return true
  }

  async onEditNutritionPlanConfig(existingConfig: NutritionPlanConfig) {
    var planConfig = existingConfig?.clone() || NutritionPlanConfig.defaultConfig(new Date(), NutritionPlanComponent.getMaxEndDateForConfig(this.planConfigs, new Date()), 0)
    if (!existingConfig) {
      planConfig.coachUid = this.coach.uid
      planConfig.licenceHolderUid = this.coach.licenceHolderUid
    }
    
    await this.initRecipes()
    const dialogRef = this.dialog.open(NutritionPlanConfigEditorDialogComponent, {
      data: { planConfig: planConfig, user: this.user}, width: '1000px', autoFocus: false, restoreFocus: false
    })
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.shouldSave) {
          this.spinner.show()
          var planConfig: NutritionPlanConfig = result.planConfig
          var needsPlanConfigRebuild = result.needsPlanConfigRebuild
          var hasStructuralChanges = result.hasStructuralChanges
          var planIdMapping = {}
          if (existingConfig == null || (existingConfig?.isCurrentlyActive() && !planConfig.startDate.isSameOrAfterDate(new Date()) && needsPlanConfigRebuild)) {
            planConfig.id = null
            if (planConfig.startDate.isSameOrBeforeDate(new Date())) planConfig.startDate = new Date()
            planConfig.nutritionPlans?.forEach(plan => {
              if (plan.id) {
                planIdMapping[plan.id] = FirestoreNutritionPlanService.generateUniqueString()
                plan.id = planIdMapping[plan.id]
              }
            })

            await this.nutritionPlanService.insertNutritionPlanConfig(planConfig, this.user, null).toPromise()
            this.planConfigs.push(planConfig)
            this.sortPlanConfigs()
          } else {
            await this.nutritionPlanService.updateNutritionPlanConfig(planConfig, this.user).toPromise()
            this.planConfigs.forEach( (item, index) => {
              if (item.id === planConfig.id) this.planConfigs.splice(index, 1, planConfig)
            })
          }

          await this.setupPlanConfig(planConfig, this.user)
          await this.adjustCollidingNutritionPlanConfigs(planConfig)
          await this.adjustNutritionPlanConfigMeals(planConfig, existingConfig, hasStructuralChanges, planIdMapping)
          this.sortPlanConfigs()
          this.updateLatestNutritionPlanEndDate()
          if (result.allowWeightAdjustment != null) this.allowWeightAdjustment = result.allowWeightAdjustment
          if (result.mealSuggestionAccuracy != null) this.mealSuggestionAccuracy = result.mealSuggestionAccuracy
          this.spinner.hide()
          if (planConfig.isActive) this.notificationService.composeNutritionPlanUpdatedNotification(planConfig.nutritionPlans[0].id)

        } else if (result.shouldDelete) {
          if (existingConfig) {

            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
              data: { message: this.translate.instant('Möchtest du diesen Plan wirklich löschen?'), title: this.translate.instant('Plan löschen') },
            })

            dialogRef.afterClosed().subscribe(async result => {
              if (result) {
                this.spinner.show()
                var planConfig: NutritionPlanConfig = existingConfig.clone()
                if (existingConfig.isActive && existingConfig?.isCurrentlyActive() && !existingConfig.startDate.isSameDate(new Date())) {
                  planConfig.endDate = new Date().addDays(-1)
                  await this.nutritionPlanService.updateNutritionPlanConfig(planConfig, this.user).toPromise()
                  await this.adjustNutritionPlanConfigMeals(planConfig, existingConfig)
                  this.spinner.hide()
                  this.reloadData(true)
                } else {
                  var planConfig = existingConfig
                  for (var plan of existingConfig.nutritionPlans) {
                    var meals = await this.nutritionPlanService.getNutritionPlanMealsByPlanId(this.user, plan.id)
                    for (var meal of meals) {
                      await this.nutritionPlanService.deleteNutritionPlanMeal(meal, this.user)
                    }
                  }
                  this.nutritionPlanService.deleteNutritionPlanConfig(planConfig, this.user).then(res => {
                    this.spinner.hide()
                    this.reloadData(true)
                  })
                }
              }
            })
          }
        }
      }
    });
  }

  async onDuplicateNutritionPlanConfig(existingConfig: NutritionPlanConfig) {
    var planConfig = existingConfig.clone()
    planConfig.id = null
    planConfig.startDate = this.getPlanConfigsMaxStartDate()
    planConfig.nutritionPlans.forEach(plan => plan.id = null)
    if (existingConfig.endDate) {
      var duration = NutritionPlanComponent.dateDiffInDays(existingConfig.startDate, existingConfig.endDate)
      planConfig.endDate = planConfig.startDate.clone().addDays(duration)
    }
    planConfig.isActive = false
    await this.initRecipes();
    const dialogRef = this.dialog.open(NutritionPlanConfigEditorDialogComponent, {
      data: { planConfig: planConfig, user: this.user, isDuplicationMode: true}, width: '1000px', autoFocus: false, restoreFocus: false
    })
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.shouldSave) {
          var planConfig: NutritionPlanConfig = result.planConfig
          
          var confirmed = await this.confirmCollisionsBeforeSaving(planConfig)
          if (!confirmed) return

          this.spinner.show()
          if (!planConfig.id) {
            await this.nutritionPlanService.insertNutritionPlanConfig(planConfig, this.user, null).toPromise()
            this.sortPlanConfigs()
          }
          this.spinner.hide()

          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: { message: this.translate.instant('Möchtest du auch die Mahlzeiten des alten Plans kopieren?'), title: this.translate.instant('Plan duplizieren'), negativeButton: this.translate.instant('Ohne Mahlzeiten kopieren'), positiveButton: this.translate.instant('Mahlzeiten kopieren')},
          })
          dialogRef.afterClosed().subscribe(async result => {
            if (result) {
              this.spinner.show()
              // Copy meals from existing plan into new created plan.
              for (var planIndex = 0; planIndex < existingConfig.nutritionPlans.length; planIndex++) {
                var plan = existingConfig.nutritionPlans[planIndex]
                var matchingPlan = planConfig.nutritionPlans[planIndex]
                var meals = await this.nutritionPlanService.getNutritionPlanMealsByPlanId(this.user, plan.id)
                var mealIndex = 0
                for (var meal of meals) {
                  mealIndex++
                  this.spinnerText = this.translate.instant('Plan kopieren.') + '<br>' + this.translate.instant('Plan') + ' ' + (planIndex + 1) + '/' + existingConfig.nutritionPlans.length + ', ' + this.translate.instant('Mahlzeit') + ' ' + mealIndex + '/' + meals.length
                  await this.nutritionService.loadBaseRecipeForPlannedMeal(meal, false)
                  var distance = NutritionPlanComponent.dateDiffInDays(existingConfig.startDate, meal.date)
                  var newDate = planConfig.startDate.clone().addDays(distance)
                  meal.nutritionPlanId = matchingPlan.id
                  if (planConfig.endDate == null || planConfig.endDate > newDate || planConfig.endDate.isSameDate(newDate)) {
                    await this.nutritionPlanService.getNutritionPlanMeal(meal, this.user, null, false, false)
                    var newMeal = meal.clone()
                    newMeal.date = newDate
                    await this.nutritionPlanService.insertNutritionPlanMeal(newMeal, this.user, null)
                  }
                }
              }
              this.spinnerText = null
              this.spinner.hide()
            }
            await this.adjustCollidingNutritionPlanConfigs(planConfig)
            if (result.allowWeightAdjustment != null) this.allowWeightAdjustment = result.allowWeightAdjustment
            if (result.mealSuggestionAccuracy != null) this.mealSuggestionAccuracy = result.mealSuggestionAccuracy
            this.reloadData(true)
          })
        
        }
      }
    });
  }

  onDeleteNutritionPlanConfig(planConfig: NutritionPlanConfig) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: this.translate.instant('Möchtest du diesen Plan wirklich löschen?'), title: this.translate.instant('Plan löschen') },
    })
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        await this.nutritionPlanService.deleteNutritionPlanConfig(planConfig, this.user)
        this.planConfigs.splice(this.planConfigs.indexOf(planConfig), 1)
        this.updateLatestNutritionPlanEndDate()
      }
    })
  }

  onExportPlanConfig(planConfig: NutritionPlanConfig) {
    let availableSettings = [this.translate.instant("Rezepte exportieren"), this.translate.instant("Zutaten in Kalenderansicht anzeigen")]
    let dialogRef = this.dialog.open(CustomSettingsDialogComponent, { data: { title: this.translate.instant("Ernährungsplan exportieren"), availableSettings: availableSettings, selectedSettings: [availableSettings[0]], cancelButtonText: this.translate.instant("Abbrechen"), submitButtonText: this.translate.instant("Erstellen")}})
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if(result.shouldTake){
          let exportRecipes = result.selectedSettings.includes(availableSettings[0])
          let showIngredientsInTable = result.selectedSettings.includes(availableSettings[1])
          this.spinner.show()
          let progressSubscription = this.exportService.exportProgress.subscribe((progress) => {
            this.updateNutritionplanExportSpinnerText(progress);
          });
          this.exportService.onExportNutritionPlanConfig(planConfig, this.user, this.nutritionPlanService, showIngredientsInTable, exportRecipes).then(success => {
            this.spinner.hide();
            progressSubscription.unsubscribe();
            this.spinnerText = null;
          }).catch(ex => {
            console.error(ex);
            this.toastr.error(this.translate.instant("Es ist ein unbekannter Fehler aufgetreten."), this.translate.instant("Fehler"), {
              positionClass: 'toast-bottom-center'
            })
          });
        }
      }
    });
  }

  async onCopyAndEditNutritionPlanConfig(existingConfig: NutritionPlanConfig, day: Day) {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: this.translate.instant('Möchtest du diesen Plan wirklich ab diesem Tag bearbeiten?'), title: this.translate.instant('Plan bearbeiten') },
    })
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show()
        var newConfig = existingConfig.clone()
        newConfig.id = null
        newConfig.startDate = day.date.clone()
        newConfig.nutritionPlans.forEach(plan => plan.id = null)
        existingConfig.endDate = newConfig.startDate.clone().addDays(-1)
        await this.nutritionPlanService.updateNutritionPlanConfig(existingConfig, this.user).toPromise()
        await this.nutritionPlanService.insertNutritionPlanConfig(newConfig, this.user, null).toPromise()
        this.openedPlanConfig = newConfig
        this.sortPlanConfigs()
    
        for (var planIndex = 0; planIndex < existingConfig.nutritionPlans.length; planIndex++) {
          var plan = existingConfig.nutritionPlans[planIndex]
          var matchingPlan = newConfig.nutritionPlans[planIndex]
          var meals = await this.nutritionPlanService.getNutritionPlanMealsByPlanId(this.user, plan.id)
          var mealIndex = 0
          for (var meal of meals) {
            mealIndex++
            this.spinnerText = this.translate.instant('Plan kopieren.') + '<br>' + this.translate.instant('Plan') + ' ' + (planIndex + 1) + '/' + existingConfig.nutritionPlans.length + ', ' + this.translate.instant('Mahlzeit') + ' ' + mealIndex + '/' + meals.length
            var distance = NutritionPlanComponent.dateDiffInDays(existingConfig.startDate, meal.date)
            var newDate = newConfig.startDate.clone().addDays(distance)
            meal.nutritionPlanId = matchingPlan.id
            if (newConfig.endDate == null || newConfig.endDate > newDate || newConfig.endDate.isSameDate(newDate)) {
              await this.nutritionPlanService.getNutritionPlanMeal(meal, this.user, null, false, false)
              var newMeal = meal.clone()
              newMeal.date = newDate
              await this.nutritionPlanService.insertNutritionPlanMeal(newMeal, this.user, null)
            }
          }
        }
        this.spinnerText = null
        this.spinner.hide()
    
        this.reloadData()
      }
    })
    
  }
  
  async adjustNutritionPlanConfigMeals(newConfig: NutritionPlanConfig, oldConfig: NutritionPlanConfig, hasStructuralChanges: boolean = false, planIdMapping: any = null) {
    if (oldConfig) {
      // Delete all overflowing meals if endDate was adjusted.
      if (newConfig.endDate != null && oldConfig.endDate != null && newConfig.endDate < oldConfig.endDate || newConfig.endDate != null && oldConfig.endDate == null) {
        var meals = await this.nutritionPlanService.getNutritionPlanMealsForDateRange(this.user, newConfig.endDate.clone().addDays(1), oldConfig.endDate || newConfig.startDate.clone().addDays(365))
        for (var meal of meals) {
          await this.nutritionPlanService.deleteNutritionPlanMeal(meal, this.user)
        }
      }
      // Update date of meals if the startDate of a repeating NutritionPlan has changed.
      if (!oldConfig.startDate.isSameDate(newConfig.startDate)) {
        for (var newPlan of newConfig.nutritionPlans) {
          if (newPlan.isRepeating) {
            for (var i = 0; i < newPlan.repeatingMeals.length; i++) {
              var meal: PlannedMealV2 = newPlan.repeatingMeals[i]
              await this.nutritionPlanService.getNutritionPlanMeal(meal, this.user, null)
              if (oldConfig.id != newConfig.id) meal.id = null
              meal.date = newConfig.startDate.clone().addDays(meal.repetitionDayNumber)
              if (planIdMapping && planIdMapping[meal.nutritionPlanId]) {
                meal.nutritionPlanId = planIdMapping[meal.nutritionPlanId]
                await this.nutritionPlanService.insertNutritionPlanMeal(meal, this.user, null)
              } else {
                await this.nutritionPlanService.updateNutritionPlanMealPosition(meal, this.user)
              }
            }
          }
        }
      }
      // Delete all overflowing meals if repetitionDuration of two repeating plans has changed.
      for (var newPlan of newConfig.nutritionPlans) {
        var oldPlan: NutritionPlanV2
        oldConfig.nutritionPlans.forEach(p => {
          if (p.id == newPlan.id || p.name == newPlan.name) oldPlan = p
        })
        if (oldPlan && oldPlan.isRepeating && newPlan.isRepeating && newPlan.repetitionDuration < oldPlan.repetitionDuration) {
          var maxDate = newConfig.startDate.clone().addDays(newPlan.repetitionDuration - 1)
          var meals = await this.nutritionPlanService.getNutritionPlanMealsByPlanId(this.user, oldPlan.id)
          for (var meal of meals) {
            if (meal.repetitionDayNumber >= newPlan.repetitionDuration || !meal.date.isSameOrBeforeDate(maxDate)) await this.nutritionPlanService.deleteNutritionPlanMeal(meal, this.user)
          }
        }
      }
      // Convert between repeating and non-repeating meals.
      for (var newPlan of newConfig.nutritionPlans) {
        var oldPlan: NutritionPlanV2
        oldConfig.nutritionPlans.forEach(p => {
          if (p.id == newPlan.id || p.name == newPlan.name) oldPlan = p
        })
        if (oldPlan && oldPlan.isRepeating && !newPlan.isRepeating) {
          for (var i = 0; i < newPlan.repeatingMeals.length; i++) {
            var meal: PlannedMealV2 = newPlan.repeatingMeals[i]
            meal.date = newConfig.startDate.clone().addDays(meal.repetitionDayNumber)
            meal.isRepeating = false
            meal.repetitionDayNumber = null
            await this.nutritionPlanService.updateNutritionPlanMealPosition(meal, this.user)
            this.meals.push(meal)
          }
          newPlan.repeatingMeals = []
        } else if (oldPlan && !oldPlan.isRepeating && newPlan.isRepeating) {
          var meals = await this.nutritionPlanService.getNutritionPlanMealsForDateRange(this.user, newConfig.startDate, newConfig.endDate || newConfig.startDate.clone().addDays(365))
          for (var meal of meals) {
            if (meal.nutritionPlanId == newPlan.id) {
              var distance = NutritionPlanComponent.dateDiffInDays(newConfig.startDate, meal.date)
              if (distance >= newPlan.repetitionDuration) {
                await this.nutritionPlanService.deleteNutritionPlanMeal(meal, this.user)
              } else {
                var repetitionDayNumber = distance % newPlan.repetitionDuration
                meal.isRepeating = true
                meal.repetitionDayNumber = repetitionDayNumber
                await this.nutritionPlanService.updateNutritionPlanMealPosition(meal, this.user)
                newConfig.selectedNutritionPlan.repeatingMeals.push(meal)
              }
            }
          }
        }
      }
      if (hasStructuralChanges) {
        var meals = await this.nutritionPlanService.getNutritionPlanMealsForDateRange(this.user, newConfig.startDate, newConfig.endDate || newConfig.startDate.clone().addDays(365))
        for (var newPlan of newConfig.nutritionPlans) {
          var oldPlan: NutritionPlanV2
          oldConfig.nutritionPlans.forEach(p => {
            if (p.id == newPlan.id || p.name == newPlan.name) oldPlan = p
          })
          if (oldPlan) {
            for (var i = 0; i < newPlan.mealConfigs.length; i++) {
              var mealConfigId = newPlan.mealConfigs[i].id
              var oldPosition: number = null
              for (var j = 0; j < oldPlan.mealConfigs.length; j++) {
                if (mealConfigId == oldPlan.mealConfigs[j].id) oldPosition = j
              }
              if (oldPosition != null) {
                var movement = i - oldPosition
                if (movement != 0) {
                  for (var meal of meals) {
                    if (meal.mealConfigId == mealConfigId) {
                      meal.number = meal.number + movement
                      await this.nutritionPlanService.updateNutritionPlanMealPosition(meal, this.user)
                    }
                  }
                }
              }
            }

          }
        }

      }
    }
  }

  async adjustCollidingNutritionPlanConfigs(editedConfig: NutritionPlanConfig) {
    await this.nutritionPlanService.adjustCollidingNutritionPlanConfigs(editedConfig, this.planConfigs, this.user)
  }

  onChangeSelectedNutritionPlan(planConfig: NutritionPlanConfig, plan: NutritionPlanV2) {
    planConfig.selectedNutritionPlan = plan
    this.configToSelectedIdMapping.set(planConfig.id, plan.id)
    this.applyConnectedSituationId(planConfig, plan)
    this.populatePlanView()
  }

  applyConnectedSituationId(planConfig: NutritionPlanConfig, plan: NutritionPlanV2) {
    var initialConfig: CycleConfig = null
    initialConfig = this.configs.find(c => c.isActiveAtDate(planConfig.startDate));
    if (initialConfig) {
      if (plan.connectedSituationId) {
        initialConfig.setSelectedSituationById(plan.connectedSituationId)
      } else {
        initialConfig.setSelectedSituationById(SituationType.default)
      }
    }
  }

  async onCreateConfig() {
    var config = CycleConfig.defaultConfig(this.getCycleConfigsMaxStartDate(), null)
    if (this.autoFillRecipeDatabase?.length == 0) await this.initRecipes()
    const dialogRef = this.dialog.open(NutritionPlanConfigEditorDialogComponent, {
      data: { config: config, user: this.user}, width: '1000px', autoFocus: false, restoreFocus: false
    })
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.shouldSave) {
          var config: CycleConfig = result.config
          this.editingConfig = config
          if (result.allowWeightAdjustment != null) this.allowWeightAdjustment = result.allowWeightAdjustment
          if (result.mealSuggestionAccuracy != null) this.mealSuggestionAccuracy = result.mealSuggestionAccuracy
          this.onSaveConfig(null)
        }
      }
    });
  }

  async onEditConfig(existingConfig: CycleConfig) {
    if (this.editingConfig && this.editingConfig.id == existingConfig.id) {
      return
    }
    this.editingConfig = existingConfig.clone()
    await this.initRecipes();
    const dialogRef = this.dialog.open(NutritionPlanConfigEditorDialogComponent, {
      data: { config: this.editingConfig, user: this.user, canEdit: (existingConfig.isCurrentlyActive() || existingConfig.isInFuture())}, width: '1000px', autoFocus: false, restoreFocus: false
    })
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.shouldSave) {
          var config: CycleConfig = result.config
          this.editingConfig = config
          await this.onSaveConfig(existingConfig)
          if (result.allowWeightAdjustment != null) this.allowWeightAdjustment = result.allowWeightAdjustment
          if (result.mealSuggestionAccuracy != null) this.mealSuggestionAccuracy = result.mealSuggestionAccuracy
        } else if (result.shouldDelete) {
          await this.onDeleteConfig(existingConfig)
        }
      }
      this.editingConfig = null
    });

  }
  onCloseConfig() {
    this.editingConfig = null
  }
  async onSaveConfig(existingConfig: CycleConfig) {
    if (this.editingConfig.isValid()) {
      var config = this.editingConfig
      config.situations.forEach(situation => {
        situation.nutritionalGoals.forEach(goal => {
          goal.situationId = situation.id
          goal.situation = situation
        })
      })
      if (!config.id) {
        // Create new NutritionConfig. Adjust endDate of previous NutritionConfig if necessary.
        await this.nutritionPlanService.insertNutritionConfig(config, this.user, null).toPromise()
        this.configs.push(config)
        await this.adjustCollidingConfigs(config)
        this.reloadData()
      } else {
        // Update existing NutritionConfig.
        if (existingConfig && existingConfig.isCurrentlyActive() && !existingConfig.startDate.isToday() && config.hasStructuralDifference(existingConfig, this.translate)) {
          config.id = null
          config.startDate = new Date()
          await this.nutritionPlanService.insertNutritionConfig(config, this.user, null).toPromise()
          this.configs.forEach( (item, index) => {
            if (item.id === config.id) this.configs.splice(index, 1, config)
          })
          await this.adjustCollidingConfigs(config)
          this.reloadData()
        } else {
          await this.nutritionPlanService.updateNutritionConfig(config, this.user).toPromise()
          this.configs.forEach( (item, index) => {
            if (item.id === config.id) this.configs.splice(index, 1, config)
          })
          await this.adjustCollidingConfigs(config)
          this.reloadData()
        }
      }
      this.editingConfig = null
      this.notificationService.composeNutritionalGoalNotification()
    } else {
      this.toastr.error(this.translate.instant("Korrigiere/Vervollständige die Nährstoffziele, um zu speichern."), "",  {
        positionClass: 'toast-bottom-center'
      });
    }
  }

  async adjustCollidingConfigs(editedConfig: CycleConfig) {
    for (var config of this.configs) {
      if (!editedConfig || config.id == editedConfig.id) continue
      if (editedConfig.intersectsWith(config)) {
        if (editedConfig.startsInside(config)) {
          config.endDate = editedConfig.startDate.clone().addDays(-1)
          await this.nutritionPlanService.updateNutritionConfig(config, this.user).toPromise()
        } else if (config.startsInside(editedConfig)) {
          editedConfig.endDate = config.startDate.clone().addDays(-1)
          await this.nutritionPlanService.updateNutritionConfig(editedConfig, this.user).toPromise()
        }
      }
    }
    this.sortConfigs()
    /*if (this.configs.length > 0 && this.configs[0].endDate) {
      this.configs[0].endDate = null
      this.nutritionPlanService.updateNutritionConfig(this.configs[0], this.user).toPromise()
    }*/
  }

  async onDeleteConfig(config: CycleConfig) {
    var actionName = this.translate.instant('beenden')
    if (config.isInFuture()) actionName = this.translate.instant('löschen')
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: this.translate.instant('Möchtest du dieses Nährstoffziel wirklich {{actionNameParameter}}?', {actionNameParameter: actionName}), title: this.translate.instant('Nährstoffziel') + '' + actionName },
    })
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (config.startDate.isSameDate(new Date()) || config.startDate > new Date()) {
          await this.nutritionPlanService.deleteNutritionConfig(config, this.user)
          this.configs.forEach( (item, index) => {
            if (item.id === config.id) this.configs.splice(index, 1)
          })
        } else {
          config.endDate = new Date().addDays(-1)
          await this.nutritionPlanService.updateNutritionConfig(config, this.user).toPromise()
          this.configs.forEach( (item, index) => {
            if (item.id === config.id) this.configs.splice(index, 1, config)
          })
        }
        await this.adjustCollidingConfigs(null)
      }
    })
  }

  async onDuplicateConfig(existingConfig: CycleConfig) {
    var config = existingConfig.clone()
    config.id = null
    config.startDate = this.getCycleConfigsMaxStartDate()
    if (existingConfig.endDate) {
      var duration = NutritionPlanComponent.dateDiffInDays(existingConfig.startDate, existingConfig.endDate)
      config.endDate = config.startDate.clone().addDays(duration)
    }
    this.editingConfig = config
    await this.initRecipes();
    const dialogRef = this.dialog.open(NutritionPlanConfigEditorDialogComponent, {
      data: { config: this.editingConfig, user: this.user}, width: '1000px', autoFocus: false, restoreFocus: false
    })
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.shouldSave) {
          var config: CycleConfig = result.config
          // Create new NutritionConfig. Adjust endDate of previous NutritionConfig if necessary.
          var previousConfig = null
          this.configs.forEach(c => {
            if (!previousConfig || c.startDate > previousConfig.startDate) previousConfig = c
          })
          if (previousConfig) {
            previousConfig.endDate = config.startDate.clone().addDays(-1)
            await this.nutritionPlanService.updateNutritionConfig(previousConfig, this.user).toPromise()
          }
          await this.nutritionPlanService.insertNutritionConfig(config, this.user, null).toPromise()
          this.configs.push(config)
          this.editingConfig = null
          if (result.allowWeightAdjustment != null) this.allowWeightAdjustment = result.allowWeightAdjustment
          if (result.mealSuggestionAccuracy != null) this.mealSuggestionAccuracy = result.mealSuggestionAccuracy
          this.reloadData()
        }
      }
    });
  }

  getCycleConfigsMaxStartDate() {
    var startDate = null
    if (this.configs.length > 0) {
      startDate = this.configs[0].endDate?.clone().addDays(1)
    }
    if (!startDate) {
      this.configs.forEach(c => {
        if (startDate == null || c.startDate > startDate) startDate = c.startDate.clone()
      })
      if (startDate) startDate.addDays(7)
    }
    if (!startDate || startDate < new Date()) startDate = new Date()
    return startDate
  }

  getPlanConfigsMaxStartDate() {
    var startDate = null
    if (this.planConfigs.length > 0) {
      startDate = this.planConfigs[0].endDate?.clone().addDays(1)
    }
    if (!startDate) {
      this.planConfigs.forEach(c => {
        if (startDate == null || c.startDate > startDate) startDate = c.startDate.clone()
      })
      if (startDate) startDate.addDays(7)
      if (startDate < new Date()) startDate = new Date()
    }
    if (!startDate || startDate < new Date()) startDate = new Date()
    return startDate
  }

  async onOpenNutritionPlanConfig(planConfig: NutritionPlanConfig) {
    if (this.onPhone()) return
    if (!planConfig.canAccess(this.coach)) return
    if (this.autoFillRecipeDatabase?.length == 0) await this.initRecipes()
    this.openPlanEvent.emit(true)
    this.openedPlanConfig = planConfig
    this.mode = this.WEEK_VIEW
    this.selectedDate = new Date()
    if (!planConfig.isActiveAtDate(this.selectedDate)) {
      this.selectedDate = planConfig.startDate.clone()
    }
    this.reloadData()
  }

  onCloseNutritionPlanConfig() {
    this.editingPlanConfig = null
    this.openedPlanConfig = null
    this.mode = this.LIST_VIEW
    this.openPlanEvent.emit(false)
  }

  async onActivatePlanConfig(planConfig: NutritionPlanConfig) {
    if (planConfig.isActive) return
    this.spinner.show()
    planConfig.isActive = true
    await this.nutritionPlanService.updateNutritionPlanConfig(planConfig, this.user).toPromise()
    this.updateLatestNutritionPlanEndDate()
    for (var plan of planConfig.nutritionPlans) {
      var meals = await this.nutritionPlanService.getNutritionPlanMealsByPlanId(this.user, plan.id)
      for (var meal of meals) {
        await this.nutritionPlanService.updateNutritionPlanMealPosition(meal, this.user)
      }
    }
    this.spinner.hide()
    this.notificationService.composeNutritionPlanCreatedNotification(planConfig.nutritionPlans[0].id, planConfig.startDate)
  }

  // DAYS

  focusedDay: CalendarDay
  onFocusDay(day: CalendarDay, hovered: boolean) {
    if (day && hovered) {
      this.focusedDay = day
    } else {
      this.focusedDay = null
    }
  }

  selectedDays: CalendarDay[] = []

  areMultipleDaysSelected() {
    return this.selectedDays.length > 1
  }

  onSelectDay(day: CalendarDay) {
    if (this.isCopyingDays || this.isMovingDays) {
      return
    }
    if (this.selectedDays?.length == 1 && this.selectedDays.includes(day)) {
      /*this.selectedDays = []
      this.isSelectingDays = false*/
      this.isSelectingDays = false
      return
    }
    this.selectedDays = [day]
    this.isSelectingDays = false
    this.selectedDayItem = null
  }

  isSelectingDays = false
  mouseDownDay: CalendarDay = null
  lastMouseOverDay: CalendarDay = null
  onMouseDownDay(event, day: CalendarDay) {
    if (!this.isSelectingDays && !this.isCopyingDays && !this.isMovingDays) {
      this.isSelectingDays = true
      this.mouseDownDay = day
    }
  }
  onMouseOverDay(event, day: CalendarDay) {
    if (this.isSelectingDays && this.lastMouseOverDay != day) {
      this.lastMouseOverDay = day
      this.selectedDays = []
      var startDate = this.mouseDownDay.date
      var endDate = day.date
      this.weeks.forEach(week => {
        week.days.forEach(day => {
          if (day.date >= startDate && day.date <= endDate) this.selectedDays.push(day)
        })
      })
    }
  }
  onMouseUpDay(event, day: CalendarDay) {
    if (this.mouseDownDay == day) {
      this.mouseDownDay = null
      this.onSelectDay(day)
      return
    }
    this.onMouseOverDay(event, day)
    this.mouseDownDay = null
    this.isSelectingDays = false
  }


  async onClearDays(day: CalendarDay) {
    if (day && !this.selectedDays.includes(day)) this.onSelectDay(day)
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: this.translate.instant('Möchtest du den Tag/die Woche wirklich leeren?'), title: this.translate.instant('Tag(e) leeren') },
    })
    var res = await firstValueFrom(dialogRef.afterClosed())
    if (!res) return
    
    this.spinner.show()
    for (var d = 0; d < this.selectedDays.length; d++) {
      let day = this.selectedDays[d]
      if (day.date.isSameOrAfterDate(this.today) && day.planConfig?.id == this.openedPlanConfig?.id) {
        for (var i = 0; i < day.items.length; i++) {
          var dayItem = day.items[i]
          if (dayItem.meal) {
            await this.nutritionPlanService.deleteNutritionPlanMeal(dayItem.meal, this.user)
            if (day.planConfig?.selectedNutritionPlan.isRepeating) {
              day.planConfig.selectedNutritionPlan.repeatingMeals.splice(day.planConfig.selectedNutritionPlan.repeatingMeals.indexOf(dayItem.meal), 1)
            } else {
              this.meals.splice(this.meals.indexOf(dayItem.meal), 1)
            }
            dayItem.meal = null
            if (!dayItem.mealConfig && day.items.includes(dayItem) && !dayItem.hasAlternativeMeals()) {
              day.items.splice(day.items.indexOf(dayItem), 1)
            }
          }
          if (dayItem.alternativeMeals?.length > 0) {
            for (var j = 0; j < dayItem.alternativeMeals.length; j++) {
              var meal = dayItem.alternativeMeals[j]
              await this.nutritionPlanService.deleteNutritionPlanMeal(meal, this.user)
              if (day.planConfig?.selectedNutritionPlan.isRepeating) {
                day.planConfig.selectedNutritionPlan.repeatingMeals.splice(day.planConfig.selectedNutritionPlan.repeatingMeals.indexOf(meal), 1)
              } else {
                this.meals.splice(this.meals.indexOf(meal), 1)
              }
            }
          }
        }
      }
      this.cleanUpDay(day)
      await this.updateMealPositions(day)
    }
    this.spinner.hide()
    this.populatePlanView()
  }

  today = new Date()

  async onClearWeek()  {
    this.selectedDays = []
    for (var day of this.weeks[0].days) {
      this.selectedDays.push(day)
    }
    this.onClearDays(null)
  }
  async onCopyWeek()  {
    this.selectedDays = []
    for (var day of this.weeks[0].days) {
      this.selectedDays.push(day)
    }
    this.isCopyingDays = true
  }
  async onMoveWeek()  {
    this.selectedDays = []
    for (var day of this.weeks[0].days) {
      this.selectedDays.push(day)
    }
    this.isMovingDays = true
  }

  isCopyingDays = false
  isCopyingMeal = false
  isCopyingDayItem = false

  onCancelAction() {
    this.isCopyingDayItem = false
    this.isCopyingDays = false
    this.isCopyingMeal = false
    this.isMovingDays = false
  }

  onCopyDays(day: CalendarDay) {
    if (!this.selectedDays.includes(day)) this.onSelectDay(day)
    this.isCopyingDays = true
  }

  async onPasteOnDay(targetDay: CalendarDay) {
    this.spinner.show()
    var date = targetDay.date.clone()
    var duration = this.selectedDays.length

    var days: CalendarDay[] = []
    var startDate = date.clone()
    var endDate = startDate.clone().addDays(duration - 1)

    var meals = (await this.nutritionPlanService.getNutritionPlanMealsForDateRange(this.user, startDate, endDate)).filter(m => !m.isRepeating)
    var days = await NutritionPlanComponent.composeDays(startDate, endDate, this.user, this.configs, this.planConfigs, meals, null, this.nutritionService)
    
    for (var d = 0; d < this.selectedDays.length; d++) {
      var copiedDay = this.selectedDays[d]
      var matchingDay: CalendarDay = null
      days.forEach(d1 => {
        if (!matchingDay && d1.date.isSameDate(date)) matchingDay = d1
      })

      if (matchingDay && matchingDay.planConfig && (!matchingDay.planConfig?.selectedNutritionPlan?.isRepeating || NutritionPlanComponent.dateDiffInDays(matchingDay.planConfig.startDate, matchingDay.date) < matchingDay.planConfig.selectedNutritionPlan?.repetitionDuration)) {
        if (matchingDay.planConfig.selectedNutritionPlan?.isRepeating && !matchingDay.planConfig.selectedNutritionPlan.repeatingMeals) await this.setupPlanConfig(matchingDay.planConfig, this.user)
        // Delete existing meals.
        for (var i = 0; i < matchingDay.items.length; i++) {
          var dayItem = matchingDay.items[i]
          if (dayItem.meal) {
            await this.nutritionPlanService.deleteNutritionPlanMeal(dayItem.meal, this.user)
            if (matchingDay.planConfig?.selectedNutritionPlan.isRepeating) {
              matchingDay.planConfig.selectedNutritionPlan.repeatingMeals.splice(matchingDay.planConfig.selectedNutritionPlan.repeatingMeals.indexOf(dayItem.meal), 1)
            } else {
              this.meals.splice(this.meals.indexOf(dayItem.meal), 1)
            }
            dayItem.meal = null
            if (!dayItem.mealConfig && matchingDay.items.includes(dayItem) && !dayItem.hasAlternativeMeals()) {
              matchingDay.items.splice(matchingDay.items.indexOf(dayItem), 1)
            }
          }
          if (dayItem.alternativeMeals?.length > 0) {
            for (var j = 0; j < dayItem.alternativeMeals.length; j++) {
              var meal = dayItem.alternativeMeals[j]
              await this.nutritionPlanService.deleteNutritionPlanMeal(meal, this.user)
              if (matchingDay.planConfig?.selectedNutritionPlan.isRepeating) {
                matchingDay.planConfig.selectedNutritionPlan.repeatingMeals.splice(matchingDay.planConfig.selectedNutritionPlan.repeatingMeals.indexOf(meal), 1)
              } else {
                this.meals.splice(this.meals.indexOf(meal), 1)
              }
            }
          }
        }
        // Add new meals.
        var lastDayItemNumber = 0
        for (var j = 0; j < copiedDay.items.length; j++) {
          var copiedDayItem = copiedDay.items[j]
          if (copiedDayItem.meal) {
            await this.nutritionPlanService.getNutritionPlanMeal(copiedDayItem.meal, this.user, null, false, false)
            var copiedMeal = copiedDayItem.meal.clone()
            copiedMeal.recalculateNutritionalValues()
            var matchingItem: DayItem = null
            if (copiedMeal.mealConfigId) {
              matchingDay.items.forEach((e, index) => {
                if (e.mealConfig?.id == copiedMeal.mealConfigId) {
                  matchingItem = e
                  lastDayItemNumber = index
                }
              })
            }
            if (matchingItem) {
              matchingItem.meal = copiedMeal
            } else {
              matchingItem = new DayItem(null, copiedMeal)
              lastDayItemNumber++
              matchingDay.items.splice(lastDayItemNumber, 0, matchingItem)
            }
            copiedMeal.date = matchingDay.date
            copiedMeal.id = null
            copiedMeal.nutritionPlanId = matchingDay.planConfig.selectedNutritionPlan.id
            if (matchingDay.planConfig?.selectedNutritionPlan.isRepeating) {
              copiedMeal.isRepeating = true
              copiedMeal.repetitionDayNumber = NutritionPlanComponent.dateDiffInDays(matchingDay.planConfig.startDate, copiedMeal.date) % matchingDay.planConfig.selectedNutritionPlan.repetitionDuration
              matchingDay.planConfig.selectedNutritionPlan.repeatingMeals.push(copiedMeal)
            } else {
              copiedMeal.isRepeating = false
              copiedMeal.repetitionDayNumber = 0
              this.meals.push(copiedMeal)
            }
            await this.nutritionPlanService.insertNutritionPlanMeal(copiedMeal, this.user, null)

            if (copiedDayItem.alternativeMeals?.length > 0) {
              for (var k = 0; k < copiedDayItem.alternativeMeals.length; k++) {
                await this.nutritionPlanService.getNutritionPlanMeal(copiedDayItem.alternativeMeals[k], this.user, null, false, false)
                var copiedMeal = copiedDayItem.alternativeMeals[k].clone()
                copiedMeal.recalculateNutritionalValues()
                copiedMeal.date = matchingDay.date
                copiedMeal.id = null
                copiedMeal.nutritionPlanId = matchingDay.planConfig.selectedNutritionPlan.id
                matchingItem.alternativeMeals.push(copiedMeal)
                if (matchingDay.planConfig?.selectedNutritionPlan.isRepeating) {
                  copiedMeal.isRepeating = true
                  copiedMeal.repetitionDayNumber = NutritionPlanComponent.dateDiffInDays(matchingDay.planConfig.startDate, copiedMeal.date) % matchingDay.planConfig.selectedNutritionPlan.repetitionDuration
                  matchingDay.planConfig.selectedNutritionPlan.repeatingMeals.push(copiedMeal)
                } else {
                  this.meals.push(copiedMeal)
                }
                await this.nutritionPlanService.insertNutritionPlanMeal(copiedMeal, this.user, null)
              }
            }
          } else {
            var matchingItem = new DayItem(null, copiedMeal)
            lastDayItemNumber++
            matchingDay.items.splice(lastDayItemNumber, 0, matchingItem)
          }

        }
      }
      //await this.updateMealPositions(matchingDay)
      date.addDays(1)
    }
    this.isCopyingDays = false
    this.isMovingDays = false
    this.spinner.hide()
    this.reloadData()
  }
  
  isMovingDays = false

  onMoveDays(day: CalendarDay) {
    if (!this.selectedDays.includes(day)) this.onSelectDay(day)
    this.isMovingDays = true
  }

  async onAutoFillPlan(originalPlanConfig: NutritionPlanConfig) {
    await this.initRecipes();
    NutritionPlanComponent.assignAllMatchingRecipes(originalPlanConfig, this.autoFillRecipeDatabase)
    const dialogRef = this.dialog.open(NutritionPlanConfigEditorDialogComponent, {
      data: { planConfig: originalPlanConfig, user: this.user, isAutoFillMode: true, allowFillOnlySelectedDays: false, mealSuggestionAccuracy: this.mealSuggestionAccuracy, allowWeightAdjustment: this.allowWeightAdjustment}, width: '1000px', autoFocus: false, restoreFocus: false
    })

    var result = await dialogRef.afterClosed().toPromise()
    if (result) {
      if (result.shouldSave) {
        let planConfig: NutritionPlanConfig = result.planConfig
        await this.nutritionPlanService.updateNutritionPlanConfig(planConfig, this.user).toPromise()
        this.planConfigs.forEach( (item, index) => {
          if (item.id === planConfig.id) this.planConfigs.splice(index, 1, planConfig)
        })
        var days: CalendarDay[] = []

        var startDate = planConfig.startDate.clone()
        if (!startDate.isSameOrAfterDate(new Date()) && !planConfig.selectedNutritionPlan?.isRepeating) {
          startDate = new Date()
          startDate.setHours(0)
          startDate.setMinutes(0)
          startDate.setSeconds(0)
          startDate.setMilliseconds(0)
        }
        var endDate = planConfig.endDate?.clone() || planConfig.startDate.clone().addDays(31)
        if (!startDate.isSameDate(endDate) && startDate.isSameOrAfterDate(endDate)) {
          this.toastr.error(this.translate.instant("Der Plan kann nicht befüllt werden, da er in der Vergangenheit liegt. Dupliziere den Plan und setze das Startdatum neu."), "",  {
            positionClass: 'toast-bottom-center'
          });
          return
        }
        
        this.spinner.show()
        await this.setupPlanConfig(planConfig, this.user)
        if (planConfig.selectedNutritionPlan.isRepeating) endDate = startDate.clone().addDays(planConfig.selectedNutritionPlan.repetitionDuration)
        var meals = (await this.nutritionPlanService.getNutritionPlanMealsForDateRange(this.user, startDate, endDate)).filter(m => !m.isRepeating)
        var days = await NutritionPlanComponent.composeDays(startDate, endDate, this.user, this.configs, this.planConfigs, meals, null, this.nutritionService)
        if (result.mealSuggestionAccuracy) this.mealSuggestionAccuracy = result.mealSuggestionAccuracy
        if (result.allowWeightAdjustment != null) this.allowWeightAdjustment = result.allowWeightAdjustment
        this.spinner.hide()
        this.runAutoFill(days, planConfig, 2)
        
      }
    }
  }

  async onAutoFillDays(day: CalendarDay) {
    if (!this.selectedDays.includes(day)) this.onSelectDay(day)
    var planConfig = day.planConfig.clone()
    await this.initRecipes();
    const dialogRef = this.dialog.open(NutritionPlanConfigEditorDialogComponent, {
      data: { planConfig: planConfig, user: this.user, isAutoFillMode: true}, width: '1000px', autoFocus: false, restoreFocus: false
    })

    var result = await dialogRef.afterClosed().toPromise()
    if (result) {
      if (result.shouldSave) {
        if (result.allowWeightAdjustment != null) this.allowWeightAdjustment = result.allowWeightAdjustment
        if (result.mealSuggestionAccuracy != null) this.mealSuggestionAccuracy = result.mealSuggestionAccuracy
        var autoFillOnlySelectedDays = result.autoFillOnlySelectedDays || false
        var planConfig: NutritionPlanConfig = result.planConfig
        await this.nutritionPlanService.updateNutritionPlanConfig(planConfig, this.user).toPromise()
        day.planConfig = planConfig
        this.planConfigs.forEach( (item, index) => {
          if (item.id === planConfig.id) this.planConfigs.splice(index, 1, planConfig)
        })
        var days: CalendarDay[] = []

        if (autoFillOnlySelectedDays) {
          this.selectedDays.forEach(day => {
            if (day.planConfig?.id == planConfig.id) {
              days.push(day)
            }
          })
        } else {
          var startDate = day.planConfig.startDate.clone()
          var endDate = day.planConfig.endDate?.clone() || day.planConfig.startDate.clone().addDays(31)
          
          await this.setupPlanConfig(planConfig, this.user)
          if (planConfig.selectedNutritionPlan.isRepeating) endDate = startDate.clone().addDays(planConfig.selectedNutritionPlan.repetitionDuration)
      
          var days = await NutritionPlanComponent.composeDays(startDate, endDate, this.user, this.configs, this.planConfigs, this.meals, null, this.nutritionService)

        }
        this.runAutoFill(days, planConfig, 2)
        
      }
    }
  }

  async runAutoFill(days: CalendarDay[], planConfig: NutritionPlanConfig, maxIterations: number) {
    await this.initRecipes();
    this.spinner.show()

    var calorieDistribution = []
    // Relative size of each meal in respect to the calories left of the day.
    var cumulatedCalorieDistribution = []
    var summedPercentages = 0
    for (var i = 0; i < planConfig.selectedNutritionPlan.mealConfigs.length; i++) {
      if (i == 0) {
        var percentage = planConfig.selectedNutritionPlan.mealConfigs[i].mealSizeProportion
        summedPercentages += percentage
        calorieDistribution.push(percentage)
        cumulatedCalorieDistribution.push(percentage)
      } else if (i == planConfig.selectedNutritionPlan.mealConfigs.length - 1) {
        var percentage = planConfig.selectedNutritionPlan.mealConfigs[i].mealSizeProportion
        summedPercentages += percentage
        calorieDistribution.push(percentage)
        cumulatedCalorieDistribution.push(1)
      } else {
        var percentage = planConfig.selectedNutritionPlan.mealConfigs[i].mealSizeProportion
        calorieDistribution.push(percentage)
        cumulatedCalorieDistribution.push( percentage / ( 1 - summedPercentages) )
        summedPercentages += percentage
      }
    }
    NutritionPlanComponent.assignAllMatchingRecipes(planConfig, this.autoFillRecipeDatabase)
    
    var recipeServingsLeft: Map<Recipe, number> = new Map()
    var allUsedRecipes: Recipe[] = []
    var autoFillProgress = 0
    
    var usedRecipesByMealNumber: Recipe[][] = []
    var recipeResetDone = false
    var noMatchFound = false

    var toleranceAdjustment = this.mealSuggestionAccuracy == 'high' ? 0.6 : 1

    // Fill one day after another and try optimizing each days macros.
    for (var dayNumber = 0; dayNumber < days.length; dayNumber++) {
      var day = days[dayNumber]
      if (!day.config) continue

      var nutritionalGoal: NutritionalGoalV2 = day.config.getNutritionalGoalForDate(day.date)
      var totalCalories = nutritionalGoal.getCalories()
      var totalMacros = nutritionalGoal.getCarbohydrates() + nutritionalGoal.getProtein() + nutritionalGoal.getFat() * 2
      var macroDistributionGoal = [nutritionalGoal.getCarbohydrates() / totalMacros, nutritionalGoal.getProtein() / totalMacros, nutritionalGoal.getFat() * 2 / totalMacros]

      var caloriesLeft = totalCalories
      var carbohydratesLeft = nutritionalGoal.getCarbohydrates()
      var proteinLeft = nutritionalGoal.getProtein()
      var fatLeft = nutritionalGoal.getFat()
      var totalMacrosLeft = carbohydratesLeft + proteinLeft + fatLeft * 2

      // Fill one meal after another.
      for (var mealNumber = 0; mealNumber < day.planConfig.selectedNutritionPlan.mealConfigs.length; mealNumber++) {
        autoFillProgress = (dayNumber * day.planConfig.selectedNutritionPlan.mealConfigs.length + mealNumber) / (days.length * day.planConfig.selectedNutritionPlan.mealConfigs.length) * 100
        this.updateSpinnerText(autoFillProgress)
        while (usedRecipesByMealNumber.length <= mealNumber) usedRecipesByMealNumber.push([])
        var mealConfig = day.planConfig.selectedNutritionPlan.mealConfigs[mealNumber]
        var mealCalorieGoal = caloriesLeft * cumulatedCalorieDistribution[mealNumber]
        var dayItem = day.getItemByMealConfigId(mealConfig.id)

        if (!mealConfig.autoFillEnabled) {
          caloriesLeft -= mealCalorieGoal
          carbohydratesLeft -= macroDistributionGoal[0] * mealCalorieGoal / 4.1
          proteinLeft -= macroDistributionGoal[1] * mealCalorieGoal / 4.1
          fatLeft -= macroDistributionGoal[2] / 2 * mealCalorieGoal / 9.3
          continue
        }

        if (this.allowWeightAdjustment) {

          if (dayItem.meal != null) {
            // Meal item already filled.
            var meal = dayItem.meal
            caloriesLeft -= meal.getNutritionalValue('calories')
            carbohydratesLeft -= meal.getNutritionalValue('carbohydrates')
            proteinLeft -= meal.getNutritionalValue('protein')
            fatLeft -= meal.getNutritionalValue('fat')
            filled = true
          } else {

            /*
            if (!recipe.loaded) {
            recipe.ingredients = await this.nutritionService.getRecipeIngredients(recipe)
            if (!recipe.calories) recipe.recalculateNutritionalValues()
            recipe.loaded = true
          }
          var servingSize = 1
          var calorieDifference = Math.abs(recipe.getCalories() - mealCalorieGoal)
          var adjustedServingSize = 1
          var improvingDifference = true
          while (!recipe.hasFixedServing && improvingDifference && adjustedServingSize > 0) {
            adjustedServingSize = adjustedServingSize + 0.5
            var newCalories = recipe.getCalories() * adjustedServingSize
            if (Math.abs(newCalories - mealCalorieGoal) < calorieDifference) {
              calorieDifference = Math.abs(newCalories - mealCalorieGoal)
              servingSize = adjustedServingSize
            } else {
              improvingDifference = false
            }
          }
          var precision = (allowCoarseFit ? 0.20 : 0.125) * toleranceAdjustment
          if (allowCoarsestFit) precision = 0.5
          if (calorieDifference <= mealCalorieGoal * precision && servingSize >= 0.5 && servingSize <= 3) {
            var plannedMeal = new PlannedMealV2()
            //plannedMeal.name = recipe.name
            plannedMeal.date = day.date
            plannedMeal.number = mealNumber
            plannedMeal.nutritionPlanId = day.planConfig.selectedNutritionPlan.id
            plannedMeal.mealConfigId = mealConfig.id
            plannedMeal.type = mealConfig.mealType
            plannedMeal.customType = mealConfig.customMealType
            //plannedMeal.thumbnailPath = recipe.getThumbnailPath()
            //plannedMeal.instructions = recipe.instructions

            plannedMeal.baseRecipe = recipe
            plannedMeal.baseMealTemplateId = recipe.id
            plannedMeal.baseMealTemplateServingMultiplier = servingSize
            plannedMeal.recalculateBaseRecipeFoods()

            if (day.planConfig?.selectedNutritionPlan.isRepeating) {
              plannedMeal.isRepeating = true
              plannedMeal.repetitionDayNumber = NutritionPlanComponent.dateDiffInDays(day.planConfig.startDate, day.date)
            } else {
              plannedMeal.isRepeating = false
            }

            await this.nutritionPlanService.insertNutritionPlanMeal(plannedMeal, this.user, null).toPromise()
            if (day.planConfig?.selectedNutritionPlan.isRepeating) {
              day.planConfig.selectedNutritionPlan.repeatingMeals.push(plannedMeal)
            } else {
              this.meals.push(plannedMeal)
            }
            dayItem.meal = plannedMeal
            
            filled = true
            if (servingSize < recipe.getServings()) {
              recipeServingsLeft.set(recipe, (recipeServingsLeft.get(recipe) ?? recipe.getServings()) - servingSize)
            */

            var mainMeal = (await this.onSuggestMealAlternatives(day, dayItem, 1, allUsedRecipes)).concat([]).shift()
            if (!mainMeal) {
              allUsedRecipes = []
              mainMeal = (await this.onSuggestMealAlternatives(day, dayItem, 1, allUsedRecipes)).concat([]).shift()
            }
            if (mainMeal) {
              if (mainMeal.baseRecipe) allUsedRecipes.push(mainMeal.baseRecipe)
              await this.nutritionPlanService.insertNutritionPlanMeal(mainMeal, this.user, null)
              if (day.planConfig?.selectedNutritionPlan.isRepeating) {
                day.planConfig.selectedNutritionPlan.repeatingMeals.push(mainMeal)
              } else {
                this.meals.push(mainMeal)
              }
              dayItem.meal = mainMeal
              filled = true
            }
          }

          var openAlternatives = mealConfig.autoFillAlternatives -  dayItem.alternativeMeals.length
          if (openAlternatives > 0) {
            var alternatives = (await this.onSuggestMealAlternatives(day, dayItem, openAlternatives)).concat([])
            for (var alternative of alternatives) {
              await this.onAddSuggestionToAlternatives(day, dayItem, alternative)
            }
          }

        } else {

          // Adjust the target macro distribution of the meal by macros left for the day.
          macroDistributionGoal = [carbohydratesLeft / totalMacrosLeft, proteinLeft / totalMacrosLeft, fatLeft * 2 / totalMacrosLeft]
          var filled = false
          if (dayItem.meal != null) {
            // Meal item already filled.
            var meal = dayItem.meal
            caloriesLeft -= meal.getNutritionalValue('calories')
            carbohydratesLeft -= meal.getNutritionalValue('carbohydrates')
            proteinLeft -= meal.getNutritionalValue('protein')
            fatLeft -= meal.getNutritionalValue('fat')
            filled = true
          }
          recipeResetDone = false
          var allowCoarseFit = false
          // Iterate over recipes and choose the first one that fits well.
          for (var recipeNumber = 0; recipeNumber < mealConfig.matchingRecipes.length + 1 && !filled; recipeNumber++) {
            // If no fitting recipe has been found in this iteration, allow coarse fit of calories and try again.
            if (recipeNumber == mealConfig.matchingRecipes.length) {
              // If allowing coarse fit already, reset list of used recipes (if not done already).
              if (allowCoarseFit) {
                // If still no match found, skip the meal.
                if (recipeResetDone) {
                  // Skip meal item because no match could be found.
                  recipeNumber = mealConfig.matchingRecipes.length + 2
                  recipeResetDone = false
                  caloriesLeft -= mealCalorieGoal
                  carbohydratesLeft -= macroDistributionGoal[0] * mealCalorieGoal / 4.1
                  proteinLeft -= macroDistributionGoal[1] * mealCalorieGoal / 4.1
                  fatLeft -= macroDistributionGoal[2] / 2 * mealCalorieGoal / 9.3
                  totalMacrosLeft = carbohydratesLeft + proteinLeft + fatLeft * 2
                  noMatchFound = true
                  continue
                } else {
                  recipeNumber = -1
                  usedRecipesByMealNumber[mealNumber].forEach(recipe => {
                    allUsedRecipes.splice(allUsedRecipes.indexOf(recipe), 1)
                  })
                  usedRecipesByMealNumber[mealNumber] = []
                  recipeResetDone = true
                  continue
                }
              } else {
                allowCoarseFit = true
                recipeNumber = -1
                continue
              }
            }

            var recipe = mealConfig.matchingRecipes[recipeNumber]
            if (allUsedRecipes.includes(recipe)) continue
            // Skip if recipe was already used in plan except if there is a serving left.
            //if (allUsedRecipes.includes(recipe) && (!recipeServingsLeft.has(recipe) || recipeServingsLeft.get(recipe) < 1)) continue

            // Filter recipes by macro distribution.
            var allowCoarsestFit = mealCalorieGoal <= totalCalories * 0.125
            var totalRecipeMacros = recipe.getCarbohydrates() + recipe.getProtein() + recipe.getFat() * 2
            var recipeMacroDistribution = [recipe.getCarbohydrates() / totalRecipeMacros, recipe.getProtein() / totalRecipeMacros, recipe.getFat() * 2 / totalRecipeMacros]
            // Focus on hitting minimum protein value for the meal if it is not the last one of the day.
            if (mealCalorieGoal < caloriesLeft / 3 && !allowCoarsestFit) {
              if (recipeMacroDistribution[1] - macroDistributionGoal[1] < -0.1 * toleranceAdjustment) continue
            } else if (mealCalorieGoal < caloriesLeft / 2 && !allowCoarsestFit) {
              if (Math.abs(recipeMacroDistribution[0] - macroDistributionGoal[0]) > 0.35 * toleranceAdjustment
                || recipeMacroDistribution[1] - macroDistributionGoal[1] < 0
                || Math.abs(recipeMacroDistribution[2] - macroDistributionGoal[2]) > 0.125 * toleranceAdjustment) continue
            } else {
              if ((Math.abs(recipeMacroDistribution[0] - macroDistributionGoal[0]) > 0.1 * toleranceAdjustment
                //|| Math.abs(recipeMacroDistribution[1] - macroDistributionGoal[1]) > 0.1 
                || recipeMacroDistribution[1] - macroDistributionGoal[1] < -0.1 * toleranceAdjustment
                || Math.abs(recipeMacroDistribution[2] - macroDistributionGoal[2]) > 0.07 * toleranceAdjustment) && !allowCoarsestFit) continue
            }

            if (!recipe.loaded) {
              await this.nutritionService.loadFullRecipe(recipe)
              if (!recipe.calories) recipe.recalculateNutritionalValues()
              recipe.loaded = true
            }

            // Adjust serving size of recipe to fit calories.
            var servingSize = 1
            var calorieDifference = Math.abs(recipe.getCalories() - mealCalorieGoal)
            var adjustedServingSize = 1
            var improvingDifference = true
            while (!recipe.hasFixedServing && improvingDifference && adjustedServingSize > 0) {
              adjustedServingSize = adjustedServingSize + 0.5
              var newCalories = recipe.getCalories() * adjustedServingSize
              if (Math.abs(newCalories - mealCalorieGoal) < calorieDifference) {
                calorieDifference = Math.abs(newCalories - mealCalorieGoal)
                servingSize = adjustedServingSize
              } else {
                improvingDifference = false
              }
            }
            var precision = (allowCoarseFit ? 0.20 : 0.125) * toleranceAdjustment
            if (allowCoarsestFit) precision = 0.5

            // Choose recipe.
            if (calorieDifference <= mealCalorieGoal * precision && servingSize >= 0.5 && servingSize <= 3) {
              var plannedMeal = new PlannedMealV2()
              plannedMeal.date = day.date.clone()
              var hourDiff = day.planConfig.startDate.getHours()
              plannedMeal.date.setHours(hourDiff)
              plannedMeal.number = dayItem.meal?.number || day.items.indexOf(dayItem)
              plannedMeal.nutritionPlanId = day.planConfig.selectedNutritionPlan.id
              plannedMeal.mealConfigId = mealConfig.id
              plannedMeal.type = mealConfig.mealType
              plannedMeal.customType = mealConfig.customMealType
              plannedMeal.baseRecipe = recipe
              plannedMeal.baseMealTemplateId = recipe.id
              plannedMeal.baseMealTemplateServingMultiplier = servingSize
              plannedMeal.recalculateBaseRecipeFoods()

              if (day.planConfig?.selectedNutritionPlan.isRepeating) {
                plannedMeal.isRepeating = true
                plannedMeal.repetitionDayNumber = NutritionPlanComponent.dateDiffInDays(day.planConfig.startDate, day.date)
              } else {
                plannedMeal.isRepeating = false
              }
              
              plannedMeal.nutritionFactsLoaded = true
              plannedMeal.recalculateNutritionalValues()

              await this.nutritionPlanService.insertNutritionPlanMeal(plannedMeal, this.user, null)
              if (day.planConfig?.selectedNutritionPlan.isRepeating) {
                day.planConfig.selectedNutritionPlan.repeatingMeals.push(plannedMeal)
              } else {
                this.meals.push(plannedMeal)
              }
              dayItem.meal = plannedMeal
              
              filled = true
              if (servingSize < recipe.getServings()) {
                recipeServingsLeft.set(recipe, (recipeServingsLeft.get(recipe) ?? recipe.servings) - servingSize)
                //console.log('# Servings left: ' + recipeServingsLeft.get(recipe) + ' of ' + recipe.getName())
              }
              
              usedRecipesByMealNumber[mealNumber].push(recipe)
              allUsedRecipes.push(recipe)
              caloriesLeft -= plannedMeal.getNutritionalValue('calories')
              carbohydratesLeft -= plannedMeal.getNutritionalValue('carbohydrates')
              proteinLeft -= plannedMeal.getNutritionalValue('protein')
              fatLeft -= plannedMeal.getNutritionalValue('fat')
              totalMacrosLeft = carbohydratesLeft + proteinLeft + fatLeft * 2

            }
          }
          var openAlternatives = mealConfig.autoFillAlternatives -  dayItem.alternativeMeals.length
          if (openAlternatives > 0) {
            var alternatives = (await this.onSuggestMealAlternatives(day, dayItem, openAlternatives)).concat([])
            for (var alternative of alternatives) {
              await this.onAddSuggestionToAlternatives(day, dayItem, alternative)
            }
          }
        }

      }
    }
    this.spinnerText = null
    this.spinner.hide()
    if (noMatchFound && maxIterations > 1) {
      noMatchFound = false
      this.runAutoFill(days, planConfig, maxIterations - 1)
    } else {
      this.reloadData()
      if (noMatchFound) {
        this.toastr.error(this.translate.instant("Hinweis: Es konnten nicht für alle Slots passende Rezepte gefunden werden. Bitte kontrolliere den Plan noch einmal."), "",  {
          positionClass: 'toast-bottom-center'
        });
      }
    }
  }

  autoFillRecipeDatabase: Recipe[] = []
  static assignAllMatchingRecipes(planConfig: NutritionPlanConfig, recipeDatabase: Recipe[]) {
    planConfig.selectedNutritionPlan.mealConfigs.forEach(mealConfig => {
      NutritionPlanComponent.assignMatchingRecipes(planConfig, mealConfig, recipeDatabase)
    })
  }
  static assignMatchingRecipes(planConfig: NutritionPlanConfig, mealConfig: NutritionPlanMealConfig, recipeDatabase: Recipe[]) {
    var allRestrictions = []
    planConfig.selectedNutritionPlan.tagRestrictions.forEach(tag => {
      allRestrictions.push(tag)
    })
    mealConfig.tagRestrictions.forEach(tag => {
      allRestrictions.push(tag)
    })
    var recipes: Recipe[] = []
    if (allRestrictions.length == 0) {
      recipes = recipeDatabase
    } else {
      recipeDatabase.forEach(recipe => {
        var match = true
        allRestrictions.forEach(tag => {
          if (!recipe.matchesTag(tag)) match = false
        })
        if (match) recipes.push(recipe)
      })
    }
    mealConfig.matchingRecipes = recipes
  }

  shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }
  

  updateSpinnerText(autoFillProgress: number) {
    var text = this.translate.instant('Mahlzeiten planen ({{autoFillProgressParameter}}%)', {autoFillProgressParameter: autoFillProgress.toFixed(0)})
    if (autoFillProgress <= 33) {
      text += '<br><br>Loading carbs...'
    } else if (autoFillProgress <= 67) {
      text += '<br><br>Optimizing protein...'
    } else {
      text += '<br><br>Cutting fats...'
    }
    this.spinnerText = text
  }

  updateNutritionplanExportSpinnerText(progress: string){
    this.spinnerText = this.translate.instant('Ernährungsplan exportieren (' + progress + ')')
  }

  async onMoveToDay(targetDay: CalendarDay) {
    this.spinner.show()
    var date = targetDay.date.clone()
    var duration = this.selectedDays.length

    var days: CalendarDay[] = []
    var startDate = date.clone()
    var endDate = startDate.clone().addDays(duration - 1)
    var days = await NutritionPlanComponent.composeDays(startDate, endDate, this.user, this.configs, this.planConfigs, this.meals, null, this.nutritionService)

    for (var d = 0; d < this.selectedDays.length; d++) {
      var copiedDay = this.selectedDays[d]
      var matchingDay: CalendarDay = null
      days.forEach(d1 => {
        if (!matchingDay && d1.date.isSameDate(date)) matchingDay = d1
      })

      if (matchingDay && matchingDay.planConfig && (!matchingDay.planConfig?.selectedNutritionPlan?.isRepeating || NutritionPlanComponent.dateDiffInDays(matchingDay.planConfig.startDate, matchingDay.date) < matchingDay.planConfig.selectedNutritionPlan?.repetitionDuration)) {
        // Delete existing meals.
        for (var i = 0; i < matchingDay.items.length; i++) {
          var dayItem = matchingDay.items[i]
          if (dayItem.meal) {
            await this.nutritionPlanService.deleteNutritionPlanMeal(dayItem.meal, this.user)
            if (matchingDay.planConfig?.selectedNutritionPlan.isRepeating) {
              matchingDay.planConfig.selectedNutritionPlan.repeatingMeals.splice(matchingDay.planConfig.selectedNutritionPlan.repeatingMeals.indexOf(dayItem.meal), 1)
            } else {
              this.meals.splice(this.meals.indexOf(dayItem.meal), 1)
            }
            dayItem.meal = null
            if (!dayItem.mealConfig && matchingDay.items.includes(dayItem) && !dayItem.hasAlternativeMeals()) {
              matchingDay.items.splice(matchingDay.items.indexOf(dayItem), 1)
            }
          }
          if (dayItem.alternativeMeals?.length > 0) {
            for (var j = 0; j < dayItem.alternativeMeals.length; j++) {
              var meal = dayItem.alternativeMeals[j]
              await this.nutritionPlanService.deleteNutritionPlanMeal(meal, this.user)
              if (matchingDay.planConfig?.selectedNutritionPlan.isRepeating) {
                matchingDay.planConfig.selectedNutritionPlan.repeatingMeals.splice(matchingDay.planConfig.selectedNutritionPlan.repeatingMeals.indexOf(meal), 1)
              } else {
                this.meals.splice(this.meals.indexOf(meal), 1)
              }
            }
          }
        }
        // Add new meals.
        for (var j = 0; j < copiedDay.items.length; j++) {
          var copiedDayItem = copiedDay.items[j]
          if (copiedDayItem.meal) {
            await this.nutritionPlanService.getNutritionPlanMeal(copiedDayItem.meal, this.user, null)
            var copiedMeal = copiedDayItem.meal.clone()
            copiedMeal.recalculateNutritionalValues()
            var matchingItem: DayItem = null
            if (copiedMeal.mealConfigId) {
              matchingDay.items.forEach(e => {
                if (e.mealConfig?.id == copiedMeal.mealConfigId) matchingItem = e
              })
            }
            if (matchingItem) {
              matchingItem.meal = copiedMeal
            } else {
              matchingItem = new DayItem(null, copiedMeal)
            }
            copiedMeal.id = null
            copiedMeal.date = matchingDay.date
            copiedMeal.nutritionPlanId = matchingDay.planConfig.selectedNutritionPlan.id
            if (matchingDay.planConfig?.selectedNutritionPlan.isRepeating) {
              copiedMeal.isRepeating = true
              copiedMeal.repetitionDayNumber = NutritionPlanComponent.dateDiffInDays(matchingDay.planConfig.startDate, copiedMeal.date) % matchingDay.planConfig.selectedNutritionPlan.repetitionDuration
              matchingDay.planConfig.selectedNutritionPlan.repeatingMeals.push(copiedMeal)
            } else {
              copiedMeal.isRepeating = false
              copiedMeal.repetitionDayNumber = 0
              this.meals.push(copiedMeal)
            }
            await this.nutritionPlanService.insertNutritionPlanMeal(copiedMeal, this.user, null)

            if (copiedDayItem.alternativeMeals?.length > 0) {
              for (var k = 0; k < copiedDayItem.alternativeMeals.length; k++) {
                await this.nutritionPlanService.getNutritionPlanMeal(copiedDayItem.alternativeMeals[k], this.user, null)
                var copiedMeal = copiedDayItem.alternativeMeals[k].clone()
                copiedMeal.recalculateNutritionalValues()
                copiedMeal.date = matchingDay.date
                copiedMeal.id = null
                matchingItem.alternativeMeals.push(copiedMeal)
                if (matchingDay.planConfig?.selectedNutritionPlan.isRepeating) {
                  copiedMeal.isRepeating = true
                  copiedMeal.repetitionDayNumber = NutritionPlanComponent.dateDiffInDays(matchingDay.planConfig.startDate, copiedMeal.date) % matchingDay.planConfig.selectedNutritionPlan.repetitionDuration
                  matchingDay.planConfig.selectedNutritionPlan.repeatingMeals.push(copiedMeal)
                } else {
                  this.meals.push(copiedMeal)
                }
                await this.nutritionPlanService.insertNutritionPlanMeal(copiedMeal, this.user, null)
              }
            }
          }

        }
        this.updateMealPositions(matchingDay)
      }
      date.addDays(1)
    }
    this.isMovingDays = false
    this.isCopyingDays = false
    this.spinner.hide()
    await this.onClearDays(this.selectedDays[0])
    this.reloadData()
  }

  // MEALS

  onAddNewMeal(day: CalendarDay) {

    var meal = new PlannedMealV2()
    meal.date = day.date.clone()
    var hourDiff = day.planConfig.startDate.getHours()
    meal.date.setHours(hourDiff)
    meal.nutritionPlanId = day.planConfig.selectedNutritionPlan.id
    if (day.planConfig?.selectedNutritionPlan.isRepeating) {
      meal.isRepeating = true
      meal.repetitionDayNumber = NutritionPlanComponent.dateDiffInDays(day.planConfig.startDate, day.date)
    } else {
      meal.isRepeating = false
    }

    const dialogRef = this.dialog.open(MealEditorDialogComponent, {
      data: { meal: meal, user: this.user }, width: '1000px'
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.shouldSave) {
          var meal = result.meal
          this.spinner.show()
          await this.nutritionPlanService.insertNutritionPlanMeal(result.meal, this.user, null)
          day.items.push(new DayItem(null, meal))
          await this.updateMealPositions(day)
          if (day.planConfig?.selectedNutritionPlan.isRepeating) {
            day.planConfig.selectedNutritionPlan.repeatingMeals.push(meal)
          } else {
            this.meals.push(meal)
          }
          this.populatePlanView()
          this.spinner.hide()
        } else if (result.shouldDelete) {
        }
      }
    })
  }

  async onEditMeal(day: CalendarDay, dayItem: DayItem, originalMeal: PlannedMealV2) {
    if (this.autoFillRecipeDatabase?.length == 0) await this.initRecipes()
    this.spinner.show()
    await this.nutritionPlanService.getNutritionPlanMeal(originalMeal, this.user, null)
    this.spinner.hide()

    var targetValues = null
    if (dayItem.mealConfig && day.config) {
      var caloriesLeft = dayItem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date)?.getCalories()
      var carbohydratesLeft = dayItem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date)?.getCarbohydrates()
      var proteinLeft = dayItem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date)?.getProtein()
      var fatLeft = dayItem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date)?.getFat()
      targetValues = [carbohydratesLeft, proteinLeft, fatLeft, caloriesLeft]
    }
    console.log('Edit PlannedMeal: ' + originalMeal.id)
    const dialogRef = this.dialog.open(MealEditorDialogComponent, {
      data: { meal: originalMeal.clone(), user: this.user, targetValues: targetValues}, width: '1000px'
    });
    
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.shouldSave) {
          this.spinner.show()
          var updatedMeal = result.meal
          updatedMeal.nutritionPlanConfigId = day.planConfig.id
          this.nutritionPlanService.updateNutritionPlanMeal(updatedMeal, this.user).then(res => {
            if (day.planConfig?.selectedNutritionPlan.isRepeating) {
              day.planConfig.selectedNutritionPlan.repeatingMeals.splice(day.planConfig.selectedNutritionPlan.repeatingMeals.indexOf(originalMeal), 1, updatedMeal)
            } else {
              this.meals.splice(this.meals.indexOf(originalMeal), 1, updatedMeal)
            }
            if (dayItem.meal == originalMeal) {
              dayItem.meal = updatedMeal
            } else {
              dayItem.alternativeMeals.splice(dayItem.alternativeMeals.indexOf(originalMeal), 1, updatedMeal)
            }
            this.populatePlanView()
            this.spinner.hide()
          })
        } else if (result.shouldDelete) {
          this.spinner.show()
          await this.deleteMeal(day, dayItem, originalMeal)
          this.populatePlanView()
          this.spinner.hide()
        }
      }
    });
  }

  async deleteMeal(day: CalendarDay, dayItem: DayItem, originalMeal: PlannedMealV2) {
    await this.nutritionPlanService.deleteNutritionPlanMeal(originalMeal, this.user)
    if (day.planConfig?.selectedNutritionPlan.isRepeating) {
      day.planConfig.selectedNutritionPlan.repeatingMeals.splice(day.planConfig.selectedNutritionPlan.repeatingMeals.indexOf(originalMeal), 1)
    } else {
      this.meals.splice(this.meals.indexOf(originalMeal), 1)
    }
    if (dayItem.meal == originalMeal) {
      dayItem.meal = null
      if (dayItem.alternativeMeals.length > 0) {
        var meal = dayItem.alternativeMeals[0]
        dayItem.alternativeMeals.splice(0, 1)
        meal.isAlternative = false
        dayItem.meal = meal
        await this.nutritionPlanService.updateNutritionPlanMealPosition(meal, this.user)
      }
    } else {
      dayItem.alternativeMeals.splice(dayItem.alternativeMeals.indexOf(originalMeal), 1)
    }
    await this.updateMealPositions(day)
  }

  onAddNewMealAtItem(day: CalendarDay, dayItem: DayItem) {
    
    var meal = new PlannedMealV2()
    meal.date = day.date.clone()
    var hourDiff = day.planConfig.startDate.getHours()
    meal.date.setHours(hourDiff)
    meal.mealConfigId = dayItem.mealConfig.id
    meal.nutritionPlanId = day.planConfig.selectedNutritionPlan.id
    meal.type = dayItem.mealConfig.mealType
    meal.customType = dayItem.mealConfig.customMealType
    if (day.planConfig?.selectedNutritionPlan.isRepeating) {
      meal.isRepeating = true
      meal.repetitionDayNumber = NutritionPlanComponent.dateDiffInDays(day.planConfig.startDate, day.date)
    } else {
      meal.isRepeating = false
    }

    var targetValues = null
    if (dayItem.mealConfig && day.config) {
      var caloriesLeft = dayItem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date)?.getCalories()
      var carbohydratesLeft = dayItem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date)?.getCarbohydrates()
      var proteinLeft = dayItem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date)?.getProtein()
      var fatLeft = dayItem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date)?.getFat()
      targetValues = [carbohydratesLeft, proteinLeft, fatLeft, caloriesLeft]
    }
    
    const dialogRef = this.dialog.open(MealEditorDialogComponent, {
      data: { meal: meal, user: this.user, targetValues: targetValues}, width: '1000px'
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.shouldSave) {
          var meal = result.meal
          this.spinner.show()
          await this.nutritionPlanService.insertNutritionPlanMeal(meal, this.user, null)
          dayItem.meal = meal
          await this.updateMealPositions(day)
          if (day.planConfig?.selectedNutritionPlan.isRepeating) {
            day.planConfig.selectedNutritionPlan.repeatingMeals.push(meal)
          } else {
            this.meals.push(meal)
          }
          this.populatePlanView()
          this.spinner.hide()
        } else if (result.shouldDelete) {
        }
      }
    })
  }

  async onDeleteMeal(day: CalendarDay, dayItem: DayItem, meal: PlannedMealV2) {
    this.spinner.show()
    await this.deleteMeal(day, dayItem, meal)
    this.populatePlanView()
    this.spinner.hide()
  }

  copyingMeal: PlannedMealV2
  async onCopyMeal(day: CalendarDay, dayItem: DayItem, meal: PlannedMealV2) {
    this.spinner.show()
    this.isCopyingMeal = true
    this.copyingMeal = meal
    await this.nutritionPlanService.getNutritionPlanMeal(this.copyingMeal, this.user, null, false, false)
    this.spinner.hide()
  }
  
  async onPasteAtEnd(day: CalendarDay) {
    this.spinner.show()
    if (this.isCopyingMeal) {

      var meal = this.copyingMeal.clone()
      meal.nutritionPlanId = day.planConfig.selectedNutritionPlan.id
      meal.number = day.items.length
      meal.date = day.date.clone()
      var hourDiff = day.planConfig.startDate.getHours()
      meal.date.setHours(hourDiff)
      meal.isAlternative = false
      if (day.planConfig.selectedNutritionPlan.isRepeating) {
        meal.isRepeating = true
        meal.repetitionDayNumber = NutritionPlanComponent.dateDiffInDays(day.planConfig.startDate, meal.date)
        day.planConfig.selectedNutritionPlan.repeatingMeals.push(meal)
      } else {
        meal.isRepeating = false
        meal.repetitionDayNumber = 0
        this.meals.push(meal)
      }
      await this.nutritionPlanService.insertNutritionPlanMeal(meal, this.user, null)
      day.items.push(new DayItem(null, meal))
      this.isCopyingMeal = false
    }
    this.spinner.hide()
    this.populatePlanView()
  }

  async onPasteOnItem(day: CalendarDay, dayItem: DayItem) {
    this.spinner.show()
    if (this.isCopyingMeal) {
      var meal = this.copyingMeal.clone()
      //console.log(meal)
      meal.nutritionPlanId = day.planConfig.selectedNutritionPlan.id
      if (dayItem?.mealConfig) {
        meal.mealConfigId = dayItem.mealConfig.id
        meal.type = dayItem.mealConfig.mealType
        meal.customType = dayItem.mealConfig.customMealType
      }
      meal.number = day.items.indexOf(dayItem)
      meal.date = day.date.clone()
      var hourDiff = day.planConfig.startDate.getHours()
      meal.date.setHours(hourDiff)
      if (dayItem.meal) {
        meal.isAlternative = true
      } else {
        meal.isAlternative = false
      }
      if (day.planConfig.selectedNutritionPlan.isRepeating) {
        meal.isRepeating = true
        meal.repetitionDayNumber = NutritionPlanComponent.dateDiffInDays(day.planConfig.startDate, meal.date)
        day.planConfig.selectedNutritionPlan.repeatingMeals.push(meal)
      } else {
        meal.isRepeating = false
        meal.repetitionDayNumber = 0
        this.meals.push(meal)
      }
      await this.nutritionPlanService.insertNutritionPlanMeal(meal, this.user, null)
      this.isCopyingMeal = false
    } else if (this.isCopyingDayItem) {
      if (this.copyingDayItem.meal) {
        var meal = this.copyingDayItem.meal.clone()
        meal.nutritionPlanId = day.planConfig.selectedNutritionPlan.id
        if (dayItem?.mealConfig) {
          meal.mealConfigId = dayItem.mealConfig.id
          meal.type = dayItem.mealConfig.mealType
          meal.customType = dayItem.mealConfig.customMealType
        }
        meal.number = day.items.indexOf(dayItem)
        meal.date = day.date.clone()
        var hourDiff = day.planConfig.startDate.getHours()
        meal.date.setHours(hourDiff)
        meal.isAlternative = false
        if (day.planConfig.selectedNutritionPlan.isRepeating) {
          meal.isRepeating = true
          meal.repetitionDayNumber = NutritionPlanComponent.dateDiffInDays(day.planConfig.startDate, meal.date)
          day.planConfig.selectedNutritionPlan.repeatingMeals.push(meal)
        } else {
          meal.isRepeating = false
          meal.repetitionDayNumber = 0
          this.meals.push(meal)
        }
        await this.nutritionPlanService.insertNutritionPlanMeal(meal, this.user, null)
      }
      for (var copyingMeal of this.copyingDayItem.alternativeMeals) {
        var meal = copyingMeal.clone()
        meal.nutritionPlanId = day.planConfig.selectedNutritionPlan.id
        if (dayItem?.mealConfig) {
          meal.mealConfigId = dayItem.mealConfig.id
          meal.type = dayItem.mealConfig.mealType
          meal.customType = dayItem.mealConfig.customMealType
        }
        meal.number = day.items.indexOf(dayItem)
        meal.date = day.date.clone()
        var hourDiff = day.planConfig.startDate.getHours()
        meal.date.setHours(hourDiff)
        meal.isAlternative = true
        if (day.planConfig.selectedNutritionPlan.isRepeating) {
          meal.isRepeating = true
          meal.repetitionDayNumber = NutritionPlanComponent.dateDiffInDays(day.planConfig.startDate, meal.date)
          day.planConfig.selectedNutritionPlan.repeatingMeals.push(meal)
        } else {
          meal.isRepeating = false
          meal.repetitionDayNumber = 0
          this.meals.push(meal)
        }
        await this.nutritionPlanService.insertNutritionPlanMeal(meal, this.user, null)
      }
      this.isCopyingDayItem = false
    }
    this.spinner.hide()
    this.populatePlanView()
  }

  copyingDayItem: DayItem
  async onCopyDayItem(day: CalendarDay, dayItem: DayItem) {
    this.spinner.show()
    this.isCopyingDayItem = true
    this.copyingDayItem = dayItem
    if (this.copyingDayItem.meal) {
      await this.nutritionPlanService.getNutritionPlanMeal(this.copyingDayItem.meal, this.user, null, false, false)
    }
    for (var copyingMeal of this.copyingDayItem.alternativeMeals) {
      await this.nutritionPlanService.getNutritionPlanMeal(copyingMeal, this.user, null, false, false)
    }
    this.spinner.hide()
  }

  async onDeleteDayItem(day: CalendarDay, dayItem: DayItem) {
    this.spinner.show()
    if (dayItem.meal) {
      await this.nutritionPlanService.deleteNutritionPlanMeal(dayItem.meal, this.user)
      if (day.planConfig?.selectedNutritionPlan.isRepeating) {
        day.planConfig.selectedNutritionPlan.repeatingMeals.splice(day.planConfig.selectedNutritionPlan.repeatingMeals.indexOf(dayItem.meal), 1)
      } else {
        this.meals.splice(this.meals.indexOf(dayItem.meal), 1)
      }
      dayItem.meal = null
      if (!dayItem.mealConfig && day.items.includes(dayItem) && !dayItem.hasAlternativeMeals()) {
        day.items.splice(day.items.indexOf(dayItem), 1)
      }
    }
    if (dayItem.alternativeMeals?.length > 0) {
      for (var j = 0; j < dayItem.alternativeMeals.length; j++) {
        var meal = dayItem.alternativeMeals[j]
        await this.nutritionPlanService.deleteNutritionPlanMeal(meal, this.user)
        if (day.planConfig?.selectedNutritionPlan.isRepeating) {
          day.planConfig.selectedNutritionPlan.repeatingMeals.splice(day.planConfig.selectedNutritionPlan.repeatingMeals.indexOf(meal), 1)
        } else {
          this.meals.splice(this.meals.indexOf(meal), 1)
        }
      }
    }
    await this.cleanUpDay(day)
    await this.updateMealPositions(day)
    this.populatePlanView()
    this.spinner.hide()
  }

  draggedDay: CalendarDay
  draggedDayItem: DayItem
  draggedMeal: PlannedMealV2
  dragOverItem: any
  dragOverMeal: PlannedMealV2
  dragOutsideItem: boolean = false
  draggingMeal = false

  onDragStartMealItem(event, day: CalendarDay, dayItem: DayItem) {
    this.draggedDay = day
    this.draggedDayItem = dayItem
    this.draggingMeal = true
    event.dataTransfer.setData('text', dayItem.meal.id)
    event.dataTransfer.effectAllowed = 'move'
  }
  onDragStartMeal(event, day: CalendarDay, dayItem: DayItem, meal: PlannedMealV2) {
    this.draggedDay = day
    this.draggedDayItem = dayItem
    this.draggedMeal = meal
    this.draggingMeal = true
    event.dataTransfer.setData('text', meal.id)
    event.dataTransfer.effectAllowed = 'move'
  }
  onDragOverMealItemConfig(event, day: CalendarDay, dayItem: DayItem) {
    event.preventDefault()
    this.dragOverItem = dayItem.mealConfig
    this.focusedDay = day
  }
  onDragOverMeal(event, day: CalendarDay, dayItem: DayItem, meal: PlannedMealV2, dragOutsideItem: boolean = false) {
    event.preventDefault()
    this.dragOverItem = dayItem
    this.dragOverMeal = meal ?? null
    this.focusedDay = day
    this.dragOutsideItem = dragOutsideItem
  }
  onDragOverMealItemSpace(event, day: CalendarDay, dayItem: DayItem, dragOutsideItem: boolean = false) {
    event.preventDefault()
    this.dragOverItem = dayItem
    this.focusedDay = day
    this.dragOutsideItem = dragOutsideItem
  }
  onDragOverDay(event, day: CalendarDay) {
    event.preventDefault()
    this.dragOverItem = null
    this.focusedDay = day
  }
  onDragEnd(event, day: CalendarDay, dayItem: DayItem) {
    this.draggingMeal = false
    this.draggedDay = null
    this.draggedDayItem = null
  }
  async onDropOnMealItemConfig(event, day: CalendarDay, dayItem: DayItem) {
    event.preventDefault();
    var draggedDayItem = this.draggedDayItem
    var draggedDay = this.draggedDay
    if (this.draggedMeal) {
      // Dragging single meal from dayItem:
      var meal = this.draggedMeal
      meal.nutritionPlanId = day.planConfig.selectedNutritionPlan.id
      meal.mealConfigId = dayItem.mealConfig.id
      meal.number = day.items.indexOf(dayItem)
      meal.date = day.date.clone()
      var hourDiff = day.planConfig.startDate.getHours()
      meal.date.setHours(hourDiff)
      meal.type = dayItem.mealConfig.mealType
      meal.customType = dayItem.mealConfig.customMealType
      if (meal.isAlternative) {
        meal.isAlternative = false
        await this.nutritionPlanService.updateNutritionPlanMealPosition(meal, this.user)
        draggedDayItem.alternativeMeals.splice(draggedDayItem.alternativeMeals.indexOf(meal), 1)
      } else {
        await this.nutritionPlanService.updateNutritionPlanMealPosition(meal, this.user)
        if (draggedDayItem.alternativeMeals.length > 0) {
          var shiftedMeal = draggedDayItem.alternativeMeals.splice(0, 1)[0]
          shiftedMeal.isAlternative = false
          draggedDayItem.meal = shiftedMeal
          await this.nutritionPlanService.updateNutritionPlanMealPosition(shiftedMeal, this.user)
        }
      }
    } else {
      // Dragging whole dayItem:
      var meal = draggedDayItem.meal
      meal.date = day.date.clone()
      var hourDiff = day.planConfig.startDate.getHours()
      meal.date.setHours(hourDiff)
      meal.mealConfigId = dayItem.mealConfig.id
      meal.type = dayItem.mealConfig.mealType
      meal.customType = dayItem.mealConfig.customMealType
      meal.number = day.items.indexOf(dayItem)
      await this.nutritionPlanService.updateNutritionPlanMealPosition(meal, this.user)
      dayItem.meal = meal
      draggedDayItem.meal = null
    }
    this.cleanUpDay(draggedDay)
    await this.updateMealPositions(day)
    this.populatePlanView()
    this.draggingMeal = false
    this.draggedDay = null
    this.draggedDayItem = null
  }

  async onDropBeforeDayItem(event, day: CalendarDay, dayItem: DayItem) {
    event.preventDefault();
    var draggedDayItem = this.draggedDayItem
    var meal = draggedDayItem.meal
    var draggedDay = this.draggedDay
    meal.mealConfigId = null
    meal.date = day.date.clone()
    var hourDiff = day.planConfig.startDate.getHours()
    meal.date.setHours(hourDiff)
    meal.number = day.items.indexOf(dayItem) - 1
    var newItem = new DayItem(null, meal)
    day.items.splice(day.items.indexOf(dayItem), 0, newItem)
    draggedDayItem.meal = null
    await this.updateMealPositions(day)
    this.cleanUpDay(draggedDay)
    this.updateMealPositions(day)
    this.populatePlanView()
    this.draggingMeal = false
  }

  async onDropAsMainMeal(event, day: CalendarDay, dayItem: DayItem, meal: PlannedMealV2) {
    event.preventDefault();
    var meal = this.draggedMeal
    var previousMeal = dayItem.meal
    var draggedDayItem = this.draggedDayItem
    previousMeal.isAlternative = true
    dayItem.alternativeMeals.push(previousMeal)
    dayItem.meal = meal
    meal.isAlternative = false
    meal.nutritionPlanId = day.planConfig.selectedNutritionPlan.id ?? null
    meal.mealConfigId = dayItem.mealConfig?.id ?? null
    meal.type = dayItem.mealConfig?.mealType ?? null
    meal.customType = dayItem.mealConfig?.customMealType ?? null
    meal.date = day.date.clone()
    var hourDiff = day.planConfig.startDate.getHours()
    meal.date.setHours(hourDiff)
    meal.number = previousMeal.number
    await this.nutritionPlanService.updateNutritionPlanMealPosition(previousMeal, this.user)
    await this.nutritionPlanService.updateNutritionPlanMealPosition(meal, this.user)
    if (!meal.isAlternative) {
      if (draggedDayItem.alternativeMeals.length > 0) {
        var shiftedMeal = draggedDayItem.alternativeMeals[0]
        draggedDayItem.alternativeMeals.splice(0, 1)
        shiftedMeal.isAlternative = false
        draggedDayItem.meal = shiftedMeal
        await this.nutritionPlanService.updateNutritionPlanMeal(shiftedMeal, this.user)
      }
    }
    this.populatePlanView()
    this.draggingMeal = false
  }

  async onDropAfterMeal(event, day: CalendarDay, dayItem: DayItem, meal: PlannedMealV2) {
    event.preventDefault();
    var meal = this.draggedMeal
    if (!meal.isAlternative) {
      var draggedDayItem = this.draggedDayItem
      if (draggedDayItem.alternativeMeals.length > 0) {
        var shiftedMeal = draggedDayItem.alternativeMeals[0]
        draggedDayItem.alternativeMeals.splice(0, 1)
        shiftedMeal.isAlternative = false
        draggedDayItem.meal = shiftedMeal
        await this.nutritionPlanService.updateNutritionPlanMeal(shiftedMeal, this.user)
      }
    }
    meal.nutritionPlanId = day.planConfig.selectedNutritionPlan.id
    meal.mealConfigId = dayItem.mealConfig?.id ?? null
    meal.type = dayItem.mealConfig?.mealType ?? null
    meal.customType = dayItem.mealConfig?.customMealType ?? null
    meal.number = dayItem.meal?.number
    meal.isAlternative = true
    meal.date = day.date.clone()
    var hourDiff = day.planConfig.startDate.getHours()
    meal.date.setHours(hourDiff)
    await this.nutritionPlanService.updateNutritionPlanMealPosition(meal, this.user)
    this.populatePlanView()
    this.draggingMeal = false
  }
  async onDropOnDayEndSpace(event, day: CalendarDay, dayItem: DayItem) {
    event.preventDefault();
    var draggedDayItem = this.draggedDayItem
    var meal = draggedDayItem.meal
    var draggedDay = this.draggedDay
    meal.mealConfigId = null
    meal.date = day.date.clone()
    var hourDiff = day.planConfig.startDate.getHours()
    meal.date.setHours(hourDiff)
    meal.number = day.items.length
    await this.nutritionPlanService.updateNutritionPlanMealPosition(meal, this.user)
    var newItem = new DayItem(null, meal)
    day.items.push(newItem)
    if (!meal.isAlternative) {
      if (draggedDayItem.alternativeMeals.length > 0) {
        var shiftedMeal = draggedDayItem.alternativeMeals[0]
        draggedDayItem.alternativeMeals.splice(0, 1)
        shiftedMeal.isAlternative = false
        draggedDayItem.meal = shiftedMeal
        await this.nutritionPlanService.updateNutritionPlanMeal(shiftedMeal, this.user)
      }
    }
    draggedDayItem.meal = null
    this.cleanUpDay(draggedDay)
    this.updateMealPositions(day)
    this.populatePlanView()
    this.draggingMeal = false
  }
  cleanUpDay(day: CalendarDay) {
    day.items.forEach((item, index) => {
      if (!item.meal && !item.mealConfig && !item.hasAlternativeMeals()) day.items.splice(index, 1)
    })
  }
  async updateMealPositions(day: CalendarDay) {
    day.items.forEach((dayItem, index) => {
      if (!dayItem.meal && !dayItem.mealConfig) {
        day.items.splice(index, 1)
      }
    })
    await this.nutritionPlanService.updateMealPositions(day, this.user)
  }

  // MEAL ALTERNATIVES

  onAddAlternativeMealAtItem(day: CalendarDay, dayItem: DayItem) {
    var meal = new PlannedMealV2()
    meal.date = day.date.clone()
    var hourDiff = day.planConfig.startDate.getHours()
    meal.date.setHours(hourDiff)
    meal.number = dayItem.meal.number
    if (dayItem.mealConfig) {
      meal.mealConfigId = dayItem.mealConfig.id
      meal.type = dayItem.mealConfig.mealType
      meal.customType = dayItem.mealConfig.customMealType
    }
    meal.isAlternative = true
    if (day.planConfig?.selectedNutritionPlan.isRepeating) {
      meal.isRepeating = true
      meal.repetitionDayNumber = NutritionPlanComponent.dateDiffInDays(day.planConfig.startDate, day.date)
    } else {
      meal.isRepeating = false
    }
    meal.nutritionPlanId = day.planConfig.selectedNutritionPlan.id

    var targetValues = null
    if (dayItem.mealConfig && day.config) {
      var caloriesLeft = dayItem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date)?.getCalories()
      var carbohydratesLeft = dayItem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date)?.getCarbohydrates()
      var proteinLeft = dayItem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date)?.getProtein()
      var fatLeft = dayItem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date)?.getFat()
      targetValues = [carbohydratesLeft, proteinLeft, fatLeft, caloriesLeft]
    }
    
    const dialogRef = this.dialog.open(MealEditorDialogComponent, {
      data: { meal: meal, user: this.user, targetValues: targetValues}, width: '1000px'
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.shouldSave) {
          this.spinner.show()
          await this.nutritionPlanService.insertNutritionPlanMeal(result.meal, this.user, null)
          dayItem.meal = result.meal
          await this.updateMealPositions(day)
          if (day.planConfig?.selectedNutritionPlan.isRepeating) {
            day.planConfig.selectedNutritionPlan.repeatingMeals.push(meal)
          } else {
            this.meals.push(meal)
          }
          this.populatePlanView()
          this.spinner.hide()
        } else if (result.shouldDelete) {
        }
      }
    });
  }

  loadingMealSuggestions = false
  alternativeMealSuggestions: PlannedMealV2[]
  selectedDayItem: DayItem

  async onAddSuggestionToAlternatives(day: CalendarDay, dayItem: DayItem, meal: PlannedMealV2) {
    if (dayItem.meal) {
      meal.isAlternative = true
    }
    await this.nutritionPlanService.insertNutritionPlanMeal(meal, this.user, null)
    if (day.planConfig?.selectedNutritionPlan.isRepeating) {
      day.planConfig.selectedNutritionPlan.repeatingMeals.push(meal)
    } else {
      this.meals.push(meal)
    }
    if (dayItem.meal) {
      dayItem.alternativeMeals.push(meal)
    } else {
      dayItem.meal = meal
    }
    this.alternativeMealSuggestions.splice(this.alternativeMealSuggestions.indexOf(meal), 1)
  }

  public mealSuggestionAccuracy: string = 'medium'
  public allowWeightAdjustment: boolean = true

  toggleMealSuggestionSettings() {
    const dialogRef = this.dialog.open(MealsuggestionsSettingsDialogComponent, {
      data: { mealSuggestionAccuracy: this.mealSuggestionAccuracy, allowWeightAdjustment: this.allowWeightAdjustment }, width: '400px'
    })
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.mealSuggestionAccuracy = result.mealSuggestionAccuracy
        this.allowWeightAdjustment = result.allowWeightAdjustment
        this.onSuggestMealAlternatives(this.selectedDays[0], this.selectedDayItem)
      }
    })
  }

  getMealConfigTargetMacros(day: CalendarDay, dayitem: DayItem) {
    if (!dayitem.mealConfig || !day.config) return null
    var caloriesLeft = dayitem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date)?.getCalories()
    var carbohydratesLeft = dayitem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date)?.getCarbohydrates()
    var proteinLeft = dayitem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date)?.getProtein()
    var fatLeft = dayitem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date)?.getFat()
    if (caloriesLeft != null) return carbohydratesLeft.toFixed() + ' K / ' + proteinLeft.toFixed() + ' P / ' + fatLeft.toFixed() + ' F / ' + caloriesLeft.toFixed() + ' kcal'
    return null
  }

  static fitMealIntoMacros(meal: PlannedMealV2, targetValues: number[]): PlannedMealV2 {
    var carbohydratesLeft = targetValues[0]
    var proteinLeft = targetValues[1]
    var fatLeft = targetValues[2]
    var caloriesLeft = targetValues[3]

    var caloriesPerServing = meal.getCalories()
    var servingMultiplier = caloriesLeft / caloriesPerServing
    servingMultiplier = Math.round(servingMultiplier * 4) / 4

    meal.getFoods().forEach(food => {
      if (food.isDummy) {
        food.weight = food.weight
        if (food.weight) food.weight = (food.weight * servingMultiplier).roundToPlaces(0)
        food.calories = (food.getCalories() * servingMultiplier).roundToPlaces(0)
        food.carbohydrates = (food.getCarbohydrates() * servingMultiplier).roundToPlaces(0)
        food.protein = (food.getProtein() * servingMultiplier).roundToPlaces(0)
        food.fat = (food.getFat() * servingMultiplier).roundToPlaces(0)
      } else {
        food.weight = (food.weight * servingMultiplier).roundToPlaces(0)
        food.recalculateNutritionalValues()
      }
    })
    meal.recalculateNutritionalValues()

    return meal
  }

  static fitRecipeIntoMacros(recipe: Recipe, targetValues: number[], allowWeightAdjustment: boolean = true): PlannedMealV2 {
    var carbohydratesLeft = targetValues[0]
    var proteinLeft = targetValues[1]
    var fatLeft = targetValues[2]
    var caloriesLeft = targetValues[3]

    var caloriesPerServing = recipe.getCalories()
    var servingMultiplier = caloriesLeft / caloriesPerServing
    servingMultiplier = Math.round(servingMultiplier * 4) / 4

    if (!allowWeightAdjustment || !recipe.allowWeightAdjustment) {
      var plannedMeal = new PlannedMealV2()
      plannedMeal.baseRecipe = recipe
      plannedMeal.baseMealTemplateId = recipe.id
      plannedMeal.baseMealTemplateServingMultiplier = servingMultiplier
      plannedMeal.recalculateBaseRecipeFoods()
      return plannedMeal

    } else {
      
      var plannedMeal = new PlannedMealV2()
      plannedMeal.name = recipe.getName()
      plannedMeal.nameTranslation = recipe.nameTranslation ?? recipe.baseRecipe?.nameTranslation ?? null
      //plannedMeal.baseMealTemplateId = recipe.id
      plannedMeal.instructions = recipe.getInstructions()
      plannedMeal.instructionsTranslation = recipe.instructionsTranslation ?? recipe.baseRecipe?.instructionsTranslation ?? null
      plannedMeal.thumbnailPath = recipe.getThumbnailPath()
      plannedMeal.imageURL = recipe.thumbnailURL
      plannedMeal.foods = []
      plannedMeal.nutritionFactsLoaded = true

      recipe.getIngredients().forEach(ingredient => {
        if (!ingredient.id) ingredient.id = FirestoreNutritionPlanService.generateUniqueString()
        var food = new PlannedFood()
        food.nutritionFactId = ingredient.nutritionFactId
        food.nutritionFact = ingredient.nutritionFact
        food.servingSize = ingredient.servingSize
        food.name = ingredient.name
        food.isDummy = ingredient.isDummy
        food.allowWeightAdjustment = ingredient.allowWeightAdjustment
        food.sourceIngredientId = ingredient.id
        if (ingredient.isDummy) {
          food.weight = ingredient.weight
          if (food.weight) food.weight = (ingredient.weight / recipe.getServings() * servingMultiplier).roundToPlaces(0)
          food.calories = (ingredient.getCalories() / recipe.getServings() * servingMultiplier).roundToPlaces(0)
          food.carbohydrates = (ingredient.getCarbohydrates() / recipe.getServings() * servingMultiplier).roundToPlaces(0)
          food.protein = (ingredient.getProtein() / recipe.getServings() * servingMultiplier).roundToPlaces(0)
          food.fat = (ingredient.getFat() / recipe.getServings() * servingMultiplier).roundToPlaces(0)
        } else {
          food.weight = (ingredient.weight / recipe.getServings() * servingMultiplier).roundToPlaces(0)
          food.recalculateNutritionalValues()
        }
        plannedMeal.foods.push(food);
      })
      plannedMeal.recalculateNutritionalValues()


      plannedMeal = NutritionPlanComponent.optimizeFoodWeights(plannedMeal, 1, [carbohydratesLeft, proteinLeft, fatLeft, caloriesLeft])

      plannedMeal.foods.forEach(food => {
        if (food.weight) food.weight = food.weight.roundToPlaces(0)
        food.recalculateNutritionalValues()
      })
      
      plannedMeal.recalculateNutritionalValues()

      return plannedMeal
    }
    return null
  }

  async onSuggestMealAlternatives(day: CalendarDay, dayItem: DayItem, maxCount: number = null, usedRecipes: Recipe[] = []): Promise<PlannedMealV2[]> {
    if (this.autoFillRecipeDatabase?.length == 0) await this.initRecipes()

    this.selectedDays = [day]
    this.selectedDayItem = dayItem
    this.alternativeMealSuggestions = []
    this.loadingMealSuggestions = true

    if (day.config?.getNutritionalGoalForDate(day.date)) {
      var caloriesLeft = dayItem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date).getCalories()
      var carbohydratesLeft = dayItem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date).getCarbohydrates()
      var proteinLeft = dayItem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date).getProtein()
      var fatLeft = dayItem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date).getFat()
      var sugarLeft = dayItem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date).getSugar()
      var fibreLeft = dayItem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date).getFibre()
      var saturatedFatLeft = dayItem.mealConfig.mealSizeProportion * day.config.getNutritionalGoalForDate(day.date).getSaturatedFat()
    } else if (dayItem.meal) {
      var caloriesLeft = dayItem.meal.getNutritionalValue('calories')
      var carbohydratesLeft = dayItem.meal.getNutritionalValue('carbohydrates')
      var proteinLeft = dayItem.meal.getNutritionalValue('protein')
      var fatLeft = dayItem.meal.getNutritionalValue('fat')
      var sugarLeft = dayItem.meal.getNutritionalValue('sugar')
      var fibreLeft = dayItem.meal.getNutritionalValue('fibre')
      var saturatedFatLeft = dayItem.meal.getNutritionalValue('saturatedFat')
    } else {
      this.toastr.error(this.translate.instant("Fehler: Es muss ein Nährstoffziel für den Tag vorhanden sein."), "",  {
        positionClass: 'toast-bottom-center'
      });
      return
    }
    var totalMacrosLeft = carbohydratesLeft + proteinLeft + fatLeft * 2

    //console.log('### MACRO GOAL: ' + caloriesLeft + ' kcal, ' + carbohydratesLeft + ' C, ' + proteinLeft + ' P, ' + fatLeft + ' F')

    var mealConfig = dayItem.mealConfig
    NutritionPlanComponent.assignMatchingRecipes(day.planConfig, mealConfig, this.autoFillRecipeDatabase)
    mealConfig.matchingRecipes = this.shuffle(mealConfig.matchingRecipes)
    var macroDistributionGoal = [carbohydratesLeft / totalMacrosLeft, proteinLeft / totalMacrosLeft, fatLeft * 2 / totalMacrosLeft]
    //var extendedMacroDistributionGoal = [sugarLeft / totalMacrosLeft, fibreLeft / totalMacrosLeft, saturatedFatLeft * 2 / totalMacrosLeft]
    var mealCalorieGoal = caloriesLeft

    var allowCoarseFit = false

    // Iterate over recipes and choose the first one that fits well.
    for (var recipeNumber = 0; recipeNumber < mealConfig.matchingRecipes.length + 1; recipeNumber++) {
      // If no fitting recipe has been found in this iteration, allow coarse fit of calories and try again.
      if (maxCount && this.alternativeMealSuggestions.length >= maxCount) {
        recipeNumber = Number.MAX_VALUE
        continue
      } else if (recipeNumber == mealConfig.matchingRecipes.length) {
        if (this.alternativeMealSuggestions.length > 0) {
          continue
        } else if (!allowCoarseFit) {
          allowCoarseFit = true
          recipeNumber = -1
          continue
        } else {
          continue
        }
      }

      var recipe = mealConfig.matchingRecipes[recipeNumber]
      // Skip recipes that are already selected for this slot.
      if (dayItem.meal && dayItem.meal.getName() == recipe.getName()) continue
      if (dayItem.alternativeMeals?.length > 0 && dayItem.alternativeMeals.filter(m => m.getName() == recipe.getName()).length > 0) continue
      if (usedRecipes?.length > 0 && usedRecipes.filter(m => m.getName() == recipe.getName()).length > 0) continue

      if (!recipe.loaded) {
        await this.nutritionService.loadFullRecipe(recipe)
        recipe.recalculateNutritionalValues()
        recipe.loaded = true
      }

      if (this.allowWeightAdjustment && recipe.allowWeightAdjustment) {
        
        var toleranceAdjustment = this.mealSuggestionAccuracy == 'high' ? 1 : 2.5

        // Filter by macro distibution.
        var allowCoarsestFit = mealCalorieGoal <= caloriesLeft * 0.125
        var totalRecipeMacros = recipe.getCarbohydrates() + recipe.getProtein() + recipe.getFat() * 2
        var recipeMacroDistribution = [recipe.getCarbohydrates() / totalRecipeMacros, recipe.getProtein() / totalRecipeMacros, recipe.getFat() * 2 / totalRecipeMacros]
        //var recipeExtendedMacroDistribution = [recipe.getNutritionalValue('sugar') / totalRecipeMacros, recipe.getNutritionalValue('fibre') / totalRecipeMacros, recipe.getNutritionalValue('saturatedFat') * 2 / totalRecipeMacros]

        if (Math.abs(recipeMacroDistribution[0] - macroDistributionGoal[0]) > 0.3 * toleranceAdjustment
          || Math.abs(recipeMacroDistribution[1] - macroDistributionGoal[1]) > 0.25 * toleranceAdjustment
          || Math.abs(recipeMacroDistribution[2] - macroDistributionGoal[2]) > 0.2 * toleranceAdjustment) {
          continue
        }
        
        var totalMacros = recipe.getCarbohydrates() + recipe.getProtein() + recipe.getFat() * 2

        // Adjust serving size to fit calorie goal.
        var servingSize = 0.5
        var adjustedServingSize = 0.5
        var calorieDifference = Math.abs((recipe.getCalories() * adjustedServingSize) - mealCalorieGoal)
        var improvingDifference = true
        while (!recipe.hasFixedServing && improvingDifference && adjustedServingSize > 0) {
          adjustedServingSize = adjustedServingSize + 0.25
          var newCalories = recipe.getCalories() * adjustedServingSize
          if (Math.abs(newCalories - mealCalorieGoal) < calorieDifference) {
            calorieDifference = Math.abs(newCalories - mealCalorieGoal)
            servingSize = adjustedServingSize
          } else {
            improvingDifference = false
          }
        }
        
        if (calorieDifference / mealCalorieGoal <= (mealCalorieGoal < 500 ? 0.4 : 0.25) * toleranceAdjustment && servingSize >= 0.5 && servingSize <= 3) {
          
          var plannedMeal = new PlannedMealV2()
          plannedMeal.name = recipe.getName()
          plannedMeal.nameTranslation = recipe.nameTranslation ?? recipe.baseRecipe?.nameTranslation ?? null
          plannedMeal.baseMealTemplateId = recipe.id // environment.firebaseProjectId == 'traindoo-app' ? recipe.id : null
          plannedMeal.baseRecipe = recipe
          plannedMeal.date = day.date.clone()
          var hourDiff = day.planConfig.startDate.getHours()
          plannedMeal.date.setHours(hourDiff)
          plannedMeal.number = dayItem.meal?.number || day.items.indexOf(dayItem)
          plannedMeal.nutritionPlanId = day.planConfig.selectedNutritionPlan.id
          plannedMeal.mealConfigId = mealConfig.id
          plannedMeal.type = mealConfig.mealType
          plannedMeal.customType = mealConfig.customMealType
          plannedMeal.instructions = recipe.getInstructions() ?? null
          plannedMeal.instructionsTranslation = recipe.instructionsTranslation ?? recipe.baseRecipe?.instructionsTranslation ?? null
          plannedMeal.thumbnailPath = null // environment.firebaseProjectId == 'traindoo-app' ? null : recipe.getThumbnailPath() // Changed because thumbnails were not loading.
          plannedMeal.imageURL = recipe.thumbnailURL
          plannedMeal.foods = []
          plannedMeal.nutritionFactsLoaded = true

          if (day.planConfig?.selectedNutritionPlan.isRepeating) {
            plannedMeal.isRepeating = true
            plannedMeal.repetitionDayNumber = NutritionPlanComponent.dateDiffInDays(day.planConfig.startDate, day.date)
          } else {
            plannedMeal.isRepeating = false
          }
          
          recipe.getIngredients().forEach(ingredient => {
            if (!ingredient.id) ingredient.id = FirestoreNutritionPlanService.generateUniqueString()
            var food = new PlannedFood()
            food.nutritionFactId = ingredient.nutritionFactId
            food.nutritionFact = ingredient.nutritionFact
            food.servingSize = ingredient.servingSize
            food.name = ingredient.name
            food.isDummy = ingredient.isDummy
            food.allowWeightAdjustment = ingredient.allowWeightAdjustment
            food.sourceIngredientId = ingredient.id
            if (ingredient.isDummy) {
              food.weight = ingredient.weight
              if (food.weight) food.weight = (ingredient.weight / recipe.getServings() * servingSize).roundToPlaces(0)
              food.calories = (ingredient.getCalories() / recipe.getServings() * servingSize).roundToPlaces(0)
              food.carbohydrates = (ingredient.getCarbohydrates() / recipe.getServings() * servingSize).roundToPlaces(0)
              food.protein = (ingredient.getProtein() / recipe.getServings() * servingSize).roundToPlaces(0)
              food.fat = (ingredient.getFat() / recipe.getServings() * servingSize).roundToPlaces(0)
            } else {
              food.weight = (ingredient.weight / recipe.getServings() * servingSize).roundToPlaces(0)
              food.recalculateNutritionalValues()
            }
            plannedMeal.foods.push(food);
          })
          plannedMeal.recalculateNutritionalValues()

          totalMacros = plannedMeal.getCarbohydrates() + plannedMeal.getProtein() + plannedMeal.getFat() * 2
          var macroDeviation = [(plannedMeal.getCarbohydrates() - carbohydratesLeft) / carbohydratesLeft, (plannedMeal.getProtein() - proteinLeft) / proteinLeft, (plannedMeal.getFat() - fatLeft) / fatLeft]

          //console.log('Goal macros: ' + caloriesLeft + 'kcal, '  + carbohydratesLeft + ' C, ' + proteinLeft + ' P, ' + fatLeft + ' F')
          
          plannedMeal = NutritionPlanComponent.optimizeFoodWeights(plannedMeal, toleranceAdjustment, [carbohydratesLeft, proteinLeft, fatLeft, caloriesLeft])

          macroDeviation = [(plannedMeal.getCarbohydrates() - carbohydratesLeft) / carbohydratesLeft, (plannedMeal.getProtein() - proteinLeft) / proteinLeft, (plannedMeal.getFat() - fatLeft) / fatLeft]
          totalMacros = plannedMeal.getCarbohydrates() + plannedMeal.getProtein() + plannedMeal.getFat() * 2

          //console.log('Calorie deviation ' + (Math.abs(mealCalorieGoal - plannedMeal.getCalories()) / mealCalorieGoal ))

          if (Math.abs(mealCalorieGoal - plannedMeal.getCalories()) / mealCalorieGoal > (mealCalorieGoal < 500 ? 0.075 : 0.05) * toleranceAdjustment) {
            continue
          }


          if ( Math.abs(macroDeviation[0]) > (carbohydratesLeft < 20 ? 0.1 : (carbohydratesLeft < 50 ? 0.075 : 0.05)) * toleranceAdjustment
              || Math.abs(macroDeviation[1]) > (proteinLeft < 20 ? 0.1 : (proteinLeft < 50 ? 0.075 : 0.05)) * toleranceAdjustment
              || Math.abs(macroDeviation[2]) > (fatLeft < 10 ? 0.2 : (fatLeft < 20 ? 0.1 : 0.075)) * toleranceAdjustment ) {
            continue
          }
          
          plannedMeal.foods.forEach(food => {
            if (food.weight) food.weight = food.weight.roundToPlaces(0)
            food.recalculateNutritionalValues()
          })
          
          plannedMeal.recalculateNutritionalValues()

          this.alternativeMealSuggestions.push(plannedMeal)

        }

      } else {

        var toleranceAdjustment = this.mealSuggestionAccuracy == 'high' ? 1 : 2

        // Filter by macro distibution.
        var allowCoarsestFit = mealCalorieGoal <= caloriesLeft * 0.125
        var totalRecipeMacros = recipe.getCarbohydrates() + recipe.getProtein() + recipe.getFat() * 2
        var recipeMacroDistribution = [recipe.getCarbohydrates() / totalRecipeMacros, recipe.getProtein() / totalRecipeMacros, recipe.getFat() * 2 / totalRecipeMacros]
        // Focus on hitting minimum protein value for the meal if it is not the last one of the day.
        if (mealCalorieGoal < caloriesLeft / 3 && !allowCoarsestFit) {
          if (recipeMacroDistribution[1] - macroDistributionGoal[1] < -0.1 * toleranceAdjustment) continue
        } else if (mealCalorieGoal < caloriesLeft / 2 && !allowCoarsestFit) {
          if (Math.abs(recipeMacroDistribution[0] - macroDistributionGoal[0]) > 0.35  * toleranceAdjustment
            || recipeMacroDistribution[1] - macroDistributionGoal[1] < 0 
            || Math.abs(recipeMacroDistribution[2] - macroDistributionGoal[2]) > 0.125 * toleranceAdjustment) continue
        } else {
          if ((Math.abs(recipeMacroDistribution[0] - macroDistributionGoal[0]) > 0.1 * toleranceAdjustment
            //|| Math.abs(recipeMacroDistribution[1] - macroDistributionGoal[1]) > 0.1 
            || recipeMacroDistribution[1] - macroDistributionGoal[1] < -0.1 * toleranceAdjustment
            || Math.abs(recipeMacroDistribution[2] - macroDistributionGoal[2]) > 0.07 * toleranceAdjustment) && !allowCoarsestFit) continue
        }

        /*if (!recipe.loaded) {
          await this.nutritionService.loadFullRecipe(recipe)
          if (!recipe.calories) recipe.recalculateNutritionalValues()
          recipe.loaded = true
        }*/

        // Adjust serving size to fit calorie goal.
        var servingSize = 1
        var calorieDifference = Math.abs(recipe.getCalories() - mealCalorieGoal)
        var adjustedServingSize = 1
        var improvingDifference = true
        while (!recipe.hasFixedServing && improvingDifference && adjustedServingSize > 0) {
          adjustedServingSize = adjustedServingSize + 0.5
          var newCalories = recipe.getCalories() * adjustedServingSize
          if (Math.abs(newCalories - mealCalorieGoal) < calorieDifference) {
            calorieDifference = Math.abs(newCalories - mealCalorieGoal)
            servingSize = adjustedServingSize
          } else {
            improvingDifference = false
          }
        }
        var precision = (allowCoarseFit ? 0.20 : 0.125) * toleranceAdjustment
        if (allowCoarsestFit) precision = precision * 2
        if (calorieDifference <= mealCalorieGoal * precision && servingSize > 0.5 && servingSize <= 3) {

          var plannedMeal = new PlannedMealV2()
          plannedMeal.date = day.date.clone()
          var hourDiff = day.planConfig.startDate.getHours()
          plannedMeal.date.setHours(hourDiff)
          plannedMeal.number = dayItem.meal?.number || day.items.indexOf(dayItem)
          plannedMeal.nutritionPlanId = day.planConfig.selectedNutritionPlan.id
          plannedMeal.mealConfigId = mealConfig.id
          plannedMeal.type = mealConfig.mealType
          plannedMeal.customType = mealConfig.customMealType
          plannedMeal.baseRecipe = recipe
          plannedMeal.baseMealTemplateId = recipe.id
          plannedMeal.baseMealTemplateServingMultiplier = servingSize
          plannedMeal.recalculateBaseRecipeFoods()

          if (day.planConfig?.selectedNutritionPlan.isRepeating) {
            plannedMeal.isRepeating = true
            plannedMeal.repetitionDayNumber = NutritionPlanComponent.dateDiffInDays(day.planConfig.startDate, day.date)
          } else {
            plannedMeal.isRepeating = false
          }
          
          this.alternativeMealSuggestions.push(plannedMeal)
          
        }

      }
      
    }
    this.loadingMealSuggestions = false
    return this.alternativeMealSuggestions
  }

  static optimizeFoodWeights(plannedMeal: PlannedMealV2, toleranceAdjustment: number = 2.5, targetValues: number[]): PlannedMealV2 {
    var carbohydratesLeft = targetValues[0]
    var proteinLeft = targetValues[1]
    var fatLeft = targetValues[2]
    var macroDeviation = [(plannedMeal.getCarbohydrates() - carbohydratesLeft) / carbohydratesLeft, (plannedMeal.getProtein() - proteinLeft) / proteinLeft, (plannedMeal.getFat() - fatLeft) / fatLeft]
    var totalMacros = plannedMeal.getCarbohydrates() + plannedMeal.getProtein() + plannedMeal.getFat() * 2
    for (var i = 0; i < 2; i++) {
      var baseMeal = plannedMeal.clone()
      //console.log('Meal macros: ' + plannedMeal.getCalories() + ' kcal, ' + plannedMeal.getCarbohydrates() + ' C, ' + plannedMeal.getProtein() + ' P, ' + plannedMeal.getFat() + ' F')
      plannedMeal.foods.forEach(food => food.alreadyAdjusted = false)

      // Adjust protein to fit macro distribution.
      var macroIndex = 1
      if (Math.abs(macroDeviation[macroIndex]) > 0.05 * toleranceAdjustment) {
        //console.log('Adjust protein')
        var adjustingIngredient: PlannedFood = null
        plannedMeal.foods.forEach(ingredient => {
          if (ingredient.allowWeightAdjustment && !ingredient.alreadyAdjusted) {
            var totalFoodMacros = ingredient.getCarbohydrates() + ingredient.getProtein() + ingredient.getFat() * 2
            if (ingredient.getProtein() / totalFoodMacros > 0.3) {
              if (adjustingIngredient == null || ingredient.getProtein() / totalFoodMacros > macroPortion) {
                adjustingIngredient = ingredient
                macroPortion = ingredient.getProtein() / totalFoodMacros
              }
            }
          }
        })
        if (adjustingIngredient) {
          var canAdjust = true
          //console.log('Can adjust ' + adjustingIngredient.getName() + ', ' + adjustingIngredient.weight)
          var adjustmentFactor = 0
          if (macroDeviation[macroIndex] > 0) {
            adjustmentFactor = -1
          } else {
            adjustmentFactor = 1
          }
          var adjustmentMultiplier = 1
          while (Math.abs(macroDeviation[macroIndex]) > 0.05 * toleranceAdjustment && canAdjust) {
            var newAdjustmentMultiplier = adjustmentMultiplier + (adjustmentFactor > 0 ? 0.1 : -0.1) * (i == 0 ? 1 : 0.5)
            adjustingIngredient.weight = baseMeal.getFoodByIngredientId(adjustingIngredient.sourceIngredientId).weight * newAdjustmentMultiplier
            //console.log('New weight: ' + adjustingIngredient.weight + ' ' + adjustingIngredient.sourceIngredientId)
            adjustingIngredient.recalculateNutritionalValues()
            plannedMeal.recalculateNutritionalValues()

            totalMacros = plannedMeal.getCarbohydrates() + plannedMeal.getProtein() + plannedMeal.getFat() * 2
            var newMacroDeviation = [(plannedMeal.getCarbohydrates() - carbohydratesLeft) / carbohydratesLeft, (plannedMeal.getProtein() - proteinLeft) / proteinLeft, (plannedMeal.getFat() - fatLeft) / fatLeft]

            if (Math.abs(newMacroDeviation[macroIndex]) > Math.abs(macroDeviation[macroIndex])) {
              adjustingIngredient.weight = baseMeal.getFoodByIngredientId(adjustingIngredient.sourceIngredientId).weight * adjustmentMultiplier
              adjustingIngredient.recalculateNutritionalValues()
              plannedMeal.recalculateNutritionalValues()
              totalMacros = plannedMeal.getCarbohydrates() + plannedMeal.getProtein() + plannedMeal.getFat() * 2
              canAdjust = false
              //console.log('Reset weight')
            } else {
              adjustmentMultiplier = newAdjustmentMultiplier
              macroDeviation = newMacroDeviation
            }

            if (adjustmentMultiplier > 2 || adjustmentMultiplier < 0.5) canAdjust = false
          }
          if (adjustmentMultiplier != 1) {
            adjustingIngredient.alreadyAdjusted = true
          }
        }
      }
      //console.log('Meal macros: ' + plannedMeal.getCalories() + ' kcal, ' + plannedMeal.getCarbohydrates() + ' C, ' + plannedMeal.getProtein() + ' P, ' + plannedMeal.getFat() + ' F')

      // Adjust carbohydrates to fit macro distribution.
      macroIndex = 0
      if (Math.abs(macroDeviation[macroIndex]) > 0.05 * toleranceAdjustment) {
        //console.log('Adjust carbs')
        var adjustingIngredient: PlannedFood = null
        var macroPortion = null
        plannedMeal.foods.forEach(ingredient => {
          if (ingredient.allowWeightAdjustment && !ingredient.alreadyAdjusted) {
            var totalFoodMacros = ingredient.getCarbohydrates() + ingredient.getProtein() + ingredient.getFat() * 2
            if (ingredient.getCarbohydrates() / totalFoodMacros > 0.4) {
              if (adjustingIngredient == null || ingredient.getCarbohydrates() / totalFoodMacros > macroPortion) {
                adjustingIngredient = ingredient
                macroPortion = ingredient.getCarbohydrates() / totalFoodMacros
              }
            }
          }
        })
        if (adjustingIngredient) {
          var canAdjust = true
          //console.log('Can adjust ' + adjustingIngredient.getName())
          var adjustmentFactor = 0
          if (macroDeviation[macroIndex] > 0) {
            adjustmentFactor = -1
          } else {
            adjustmentFactor = 1
          }
          var adjustmentMultiplier = 1
          while (Math.abs(macroDeviation[macroIndex]) > 0.05 * toleranceAdjustment && canAdjust) {
            var newAdjustmentMultiplier = adjustmentMultiplier + (adjustmentFactor > 0 ? 0.1 : -0.1) * (i == 0 ? 1 : 0.5)
            adjustingIngredient.weight = baseMeal.getFoodByIngredientId(adjustingIngredient.sourceIngredientId).weight * newAdjustmentMultiplier
            adjustingIngredient.recalculateNutritionalValues()
            //console.log('New weight: ' + adjustingIngredient.weight)
            plannedMeal.recalculateNutritionalValues()

            totalMacros = plannedMeal.getCarbohydrates() + plannedMeal.getProtein() + plannedMeal.getFat() * 2
            var newMacroDeviation = [(plannedMeal.getCarbohydrates() - carbohydratesLeft) / carbohydratesLeft, (plannedMeal.getProtein() - proteinLeft) / proteinLeft, (plannedMeal.getFat() - fatLeft) / fatLeft]
            //console.log('New Meal macros: ' + plannedMeal.getCalories() + ' kcal, ' + plannedMeal.getCarbohydrates() + ' C, ' + plannedMeal.getProtein() + ' P, ' + plannedMeal.getFat() + ' F')

            if (Math.abs(newMacroDeviation[macroIndex]) > Math.abs(macroDeviation[macroIndex])) {
              adjustingIngredient.weight = baseMeal.getFoodByIngredientId(adjustingIngredient.sourceIngredientId).weight * adjustmentMultiplier
              adjustingIngredient.recalculateNutritionalValues()
              plannedMeal.recalculateNutritionalValues()
              totalMacros = plannedMeal.getCarbohydrates() + plannedMeal.getProtein() + plannedMeal.getFat() * 2
              canAdjust = false
            } else {
              adjustmentMultiplier = newAdjustmentMultiplier
              macroDeviation = newMacroDeviation
            }

            if (adjustmentMultiplier > 2 || adjustmentMultiplier < 0.5) canAdjust = false
          }
          if (adjustmentMultiplier != 1) adjustingIngredient.alreadyAdjusted = true
        }
      }

      // Adjust fat to fit macro distribution.
      macroIndex = 2
      if (Math.abs(macroDeviation[macroIndex]) > 0.05 * toleranceAdjustment) {
        //console.log('Adjust fats')
        var adjustingIngredient: PlannedFood = null
        var macroPortion = null
        plannedMeal.foods.forEach(ingredient => {
          if (ingredient.allowWeightAdjustment && !ingredient.alreadyAdjusted) {
            var totalFoodMacros = ingredient.getCarbohydrates() + ingredient.getProtein() + ingredient.getFat() * 2
            if (ingredient.getFat() * 2 / totalFoodMacros > 0.3) {
              if (adjustingIngredient == null || ingredient.getFat() * 2 / totalFoodMacros > macroPortion) {
                adjustingIngredient = ingredient
                macroPortion = ingredient.getFat() * 2 / totalFoodMacros
              }
            }
          }
        })
        if (adjustingIngredient) {
          var canAdjust = true
          //console.log('Can adjust ' + adjustingIngredient.getName())
          var adjustmentFactor = 0
          if (macroDeviation[macroIndex] > 0) {
            adjustmentFactor = -1
          } else {
            adjustmentFactor = 1
          }
          var adjustmentMultiplier = 1
          while (Math.abs(macroDeviation[macroIndex]) > 0.05 * toleranceAdjustment && canAdjust) {
            var newAdjustmentMultiplier = adjustmentMultiplier + (adjustmentFactor > 0 ? 0.1 : -0.1) * (i == 0 ? 1 : 0.5)
            adjustingIngredient.weight = baseMeal.getFoodByIngredientId(adjustingIngredient.sourceIngredientId).weight * newAdjustmentMultiplier
            adjustingIngredient.recalculateNutritionalValues()
            plannedMeal.recalculateNutritionalValues()

            totalMacros = plannedMeal.getCarbohydrates() + plannedMeal.getProtein() + plannedMeal.getFat() * 2
            var newMacroDeviation = [(plannedMeal.getCarbohydrates() - carbohydratesLeft) / carbohydratesLeft, (plannedMeal.getProtein() - proteinLeft) / proteinLeft, (plannedMeal.getFat() - fatLeft) / fatLeft]

            if (Math.abs(newMacroDeviation[macroIndex]) > Math.abs(macroDeviation[macroIndex])) {
              adjustingIngredient.weight = baseMeal.getFoodByIngredientId(adjustingIngredient.sourceIngredientId).weight * adjustmentMultiplier
              adjustingIngredient.recalculateNutritionalValues()
              plannedMeal.recalculateNutritionalValues()
              totalMacros = plannedMeal.getCarbohydrates() + plannedMeal.getProtein() + plannedMeal.getFat() * 2
              canAdjust = false
            } else {
              adjustmentMultiplier = newAdjustmentMultiplier
              macroDeviation = newMacroDeviation
            }

            if (adjustmentMultiplier > 2 || adjustmentMultiplier < 0.5) canAdjust = false
          }
          if (adjustmentMultiplier != 1) adjustingIngredient.alreadyAdjusted = true
        }
      }

      //console.log('Final macros round ' + (i + 1) + ': ' + plannedMeal.getCalories() + ' kcal, ' + plannedMeal.getCarbohydrates() + ' C, ' + plannedMeal.getProtein() + ' P, ' + plannedMeal.getFat() + ' F')
    }

    return plannedMeal
  }

  onHideMealAlternatives() {
    this.alternativeMealSuggestions = null
    this.selectedDayItem = null
    this.loadingMealSuggestions = false
  }
  
  
  // TEMPLATES

  public searchInput: string

  onTemplateSearchInputChanged(text: string) {
    this.searchInput = text
    this.updateFilteredTemplates()
  }
  updateFilteredTemplates() {
    if (!this.searchInput || this.searchInput.length == 0) {
      this.filteredDeprecatedTempates = this.deprecatedTempates
      this.filteredPlanningTemplates = this.planningTemplates
    } else {
      this.filteredPlanningTemplates = []
      this.planningTemplates.forEach(template => {
        if (template.getName()?.toLowerCase().includes(this.searchInput.toLowerCase())) {
          this.filteredPlanningTemplates.push(template)
        }
      })
      this.filteredDeprecatedTempates = []
      this.deprecatedTempates.forEach(template => {
        if (template.name?.toLowerCase().includes(this.searchInput.toLowerCase())) {
          this.filteredDeprecatedTempates.push(template)
        }
      })
    }
  }
  onDeleteTemplateSearchInput() {
    this.searchInput = null;
    (<HTMLInputElement> document.getElementById('templatesearch-input')).value = ''
    this.filteredDeprecatedTempates = this.deprecatedTempates
    this.filteredPlanningTemplates = this.planningTemplates
  }

  public showNutritionPlanTemplates = false
  onShowNutritionPlanTemplates() {
    this.deprecatedTempates = this.nutritionPlanService.nutritionPlanTemplates
    this.planningTemplates = this.nutritionPlanService.planningTemplates
    this.showNutritionPlanTemplates = true
    this.searchInput = null
    this.onDeleteTemplateSearchInput()
  }
  onHideNutritionPlanTemplates() {
    this.showNutritionPlanTemplates = false
  }

  draggedTemplate: any
  draggingDeprecatedTemplate = false
  draggingTemplate = false
  onDragStartDeprecatedTemplate(event, template: any) {
    this.draggedTemplate = template
    this.draggingTemplate = true
    this.draggingDeprecatedTemplate = true
    event.dataTransfer.setData('text', template.id)
    event.dataTransfer.effectAllowed = 'move'
  }
  onDragStartPlanningTemplate(event, template: any) {
    this.draggedTemplate = template
    this.draggingTemplate = true
    this.draggingDeprecatedTemplate = false
    event.dataTransfer.setData('text', template.id)
    event.dataTransfer.effectAllowed = 'move'
  }
  onDragEndTemplate(event, template: any) {
    this.draggedTemplate = null
    this.draggingTemplate = false
  }

  selectNutritionplanTemplate(template: any, deprecated: boolean) {
    if(this.mode != this.LIST_VIEW) {
      return;
    }
    this.insertNutritionplanTemplate(template, deprecated)
  }

  async onDropOnDay(event, day: CalendarDay) {
    event.preventDefault()
    if (!this.draggingTemplate) return
    if (!day.planConfig && this.draggingDeprecatedTemplate) {
      // Copying a deprecated plan template
      this.spinner.show()
      var template: NutritionPlan = this.draggedTemplate
      template.startDate = day.date
      await this.nutritionPlanService.loadNutritionPlanTemplateContent(template).toPromise()

      var planConfig = new NutritionPlanConfig()
      planConfig.isActive = false
      planConfig.startDate = day.date
      planConfig.endDate = new Date(planConfig.startDate).addDays(template.duration - 1)
      var maxEndDate = NutritionPlanComponent.getMaxEndDateForConfig(this.planConfigs, planConfig.startDate)
      if (maxEndDate && maxEndDate < planConfig.endDate) planConfig.endDate = maxEndDate

      var plan = new NutritionPlanV2()
      plan.name = template.name
      plan.order = 0
      plan.isRepeating = false

      planConfig.nutritionPlans.push(plan)
      planConfig.selectedNutritionPlan = plan

      if (template.customMealTypes) {
        planConfig.selectedNutritionPlan.mealConfigs = []
        template.customMealTypes.forEach(deprecatedType => {
          var mealConfig = new NutritionPlanMealConfig()
          mealConfig.position = planConfig.selectedNutritionPlan.mealConfigs.length
          var mealType: MealType
          Object.values(MealType).forEach(type => {
            if (!mealType && deprecatedType == MealTypeTranslation[type]) mealType = type
          })
          if (!mealType) {
            mealType = MealType.custom
            mealConfig.customMealType = deprecatedType
          }
          mealConfig.mealType = mealType
          planConfig.selectedNutritionPlan.mealConfigs.push(mealConfig)
        })
      }
      await this.nutritionPlanService.insertNutritionPlanConfig(planConfig, this.user, null).toPromise()

      for (var i = 0; i < template.days.length; i++) {
        var templateDay = template.days[i]
        var matchingDay: CalendarDay = null
        this.weeks.forEach(week => {
          week.days.forEach(day => {
            if (!matchingDay && day.date.isSameDate(templateDay.date)) matchingDay = day
          })
        })
        if (matchingDay && !matchingDay.planConfig && (matchingDay.date < planConfig.endDate || matchingDay.date.isSameDate(planConfig.endDate))) {
          matchingDay.planConfig = planConfig
          for (var j = 0; j < templateDay.plannedMeals.length; j++) {
            var templateMeal = templateDay.plannedMeals[j]
            if (templateMeal) {
              var meal = new PlannedMealV2()
              meal.date = matchingDay.date
              meal.name = templateMeal.name
              meal.nutritionPlanId = plan.id
              if (template.customMealTypes && template.customMealTypes.length > templateMeal.number) {
                var deprecatedType = template.customMealTypes[templateMeal.number]
                planConfig.selectedNutritionPlan.mealConfigs.forEach(c => {
                  if (c.getType(this.translate) == deprecatedType) {
                    meal.mealConfigId = c.id
                    meal.type = c.mealType
                  }
                })
              }
              meal.foods = templateMeal.foods
              meal.recalculateNutritionalValues()
              
              await this.nutritionPlanService.insertNutritionPlanMeal(meal, this.user, null)
              var item = new DayItem(null, meal)
              matchingDay.items.push(item)
            }
          }
          this.updateMealPositions(matchingDay)
        }
      }
      await this.adjustCollidingNutritionPlanConfigs(planConfig)
      this.spinner.hide()
      this.reloadData()

    } else if (!day.planConfig && !this.draggingDeprecatedTemplate) {
      // Copying a PlanningTemplate.

      this.spinner.show()
      var startDate = day.date.clone()

      // Prepare NutritionPlanConfigs:
      var planningTemplate: PlanningTemplate = this.draggedTemplate
      planningTemplate.nutritionPlanConfigs = await this.nutritionPlanService.getNutritionPlanConfigs(this.user, planningTemplate).toPromise()
      for (let planConfig of planningTemplate.nutritionPlanConfigs) {
        planConfig.startDate = startDate.clone().addDays(planConfig.startDayNumber)
        planConfig.isActive = false
        if (planConfig.endDayNumber) planConfig.endDate = startDate.clone().addDays(planConfig.endDayNumber)
        await this.nutritionPlanService.insertNutritionPlanConfig(planConfig, this.user, null).toPromise()
      }

      // Prepare PlannedMeals:
      var meals = await this.nutritionPlanService.getPlanningTemplateMeals(planningTemplate).toPromise()
      for (let meal of meals) {
        meal.date = startDate.clone().addDays(meal.dayNumber)
        await this.nutritionPlanService.insertNutritionPlanMeal(meal, this.user, null)
      }
      
      this.spinner.hide()
      this.reloadData()
    }
    this.draggingMeal = false
    this.draggingTemplate = false
    this.draggedTemplate = null
    this.draggedDay = null
    this.draggedDayItem = null
    this.onHideNutritionPlanTemplates()
  }


  onDragOverTemplateDropzone(event) {
    event.preventDefault()
  }

  async onDropOnTempateDropzone(event) {
    event.preventDefault()
    if (!this.draggingTemplate) return
    await this.insertNutritionplanTemplate(this.draggedTemplate, this.draggingDeprecatedTemplate);
  }

  async insertNutritionplanTemplate(draggedTemplate: any, draggingDeprecatedTemplate: boolean) {    
    var startDate: Date = null
    if (this.planConfigs.length > 0) {
      startDate = this.planConfigs[0].endDate?.clone().addDays(1)
      if (!startDate) startDate = this.planConfigs[0].startDate.clone().addDays(7)
    }
    if (startDate < new Date()) startDate = new Date()

    const dialogRef = this.dialog.open(PastePlanTemplateDialogComponent, {
      data: { startDate: startDate },
    });
    var res = await dialogRef.afterClosed().toPromise()
    if (!res || !res.startDate) {
      return
    }
    startDate = res.startDate

    if (draggingDeprecatedTemplate) {
      // Copying a deprecated plan template.

      this.spinner.show()
      var template: NutritionPlan = draggedTemplate
      
      template.startDate = startDate
      await this.nutritionPlanService.loadNutritionPlanTemplateContent(template).toPromise()

      var planConfig = new NutritionPlanConfig()
      planConfig.startDate = startDate
      planConfig.endDate = new Date(planConfig.startDate).addDays(template.duration - 1)
      var maxEndDate = NutritionPlanComponent.getMaxEndDateForConfig(this.planConfigs, planConfig.startDate)
      if (maxEndDate && maxEndDate < planConfig.endDate) planConfig.endDate = maxEndDate
      planConfig.isActive = false

      var plan = new NutritionPlanV2()
      plan.name = template.name
      plan.order = 0
      plan.isRepeating = false

      planConfig.nutritionPlans.push(plan)
      planConfig.selectedNutritionPlan = plan

      var mealConfigIds = []
      if (template.customMealTypes) {
        planConfig.selectedNutritionPlan.mealConfigs = []
        template.customMealTypes.forEach(deprecatedType => {
          var mealConfig = new NutritionPlanMealConfig()
          mealConfig.position = planConfig.selectedNutritionPlan.mealConfigs.length
          var mealType: MealType
          Object.values(MealType).forEach(type => {
            if (!mealType && deprecatedType == MealTypeTranslation[type]) mealType = type
          })
          if (!mealType) {
            mealType = MealType.custom
            mealConfig.customMealType = deprecatedType
          }
          mealConfig.mealType = mealType
          mealConfig.id = FirestoreNutritionPlanService.generateUniqueString()
          mealConfigIds.push(mealConfig.id)
          planConfig.selectedNutritionPlan.mealConfigs.push(mealConfig)
        })
      }
      await this.nutritionPlanService.insertNutritionPlanConfig(planConfig, this.user, null).toPromise()

      var days: CalendarDay[] = []
      var startDate = planConfig.startDate.clone()
      var endDate = planConfig.endDate.clone()
      var days = await NutritionPlanComponent.composeDays(startDate, endDate, this.user, this.configs, this.planConfigs, this.meals, null, this.nutritionService)

      var mealCount = 0
      var totalCount = template.days.length * template.mealsPerDay
      for (var i = 0; i < template.days.length; i++) {
        var templateDay = template.days[i]
        var matchingDay: CalendarDay = null
        days.forEach(day => {
          if (!matchingDay && day.date.isSameDate(templateDay.date)) matchingDay = day
        })
        if (matchingDay && !matchingDay.planConfig && (matchingDay.date < planConfig.endDate || matchingDay.date.isSameDate(planConfig.endDate))) {
          matchingDay.planConfig = planConfig
          for (var j = 0; j < templateDay.plannedMeals.length; j++) {
            var templateMeal = templateDay.plannedMeals[j]
            if (templateMeal) {
              this.spinnerText = this.translate.instant('Vorlage einfügen.') + '<br>' + this.translate.instant('Mahlzeit') + ' ' + mealCount + '/' + totalCount
              var meal = new PlannedMealV2()
              meal.date = matchingDay.date
              meal.name = templateMeal.name
              meal.nutritionPlanId = plan.id
              if (template.customMealTypes && template.customMealTypes.length > templateMeal.number) {
                var deprecatedType = template.customMealTypes[templateMeal.number]
                meal.mealConfigId = mealConfigIds[templateMeal.number]
                meal.customType = deprecatedType
                /*planConfig.selectedNutritionPlan.mealConfigs.forEach(c => {
                  if (c.getType() == deprecatedType) {
                    meal.mealConfigId = c.id
                    meal.type = c.mealType
                  }
                })*/
              }
              meal.instructions = templateMeal.instructions
              meal.note = templateMeal.note
              meal.foods = templateMeal.foods
              meal.recalculateNutritionalValues()
              
              await this.nutritionPlanService.insertNutritionPlanMeal(meal, this.user, null)
              var item = new DayItem(null, meal)
              matchingDay.items.push(item)
              mealCount++
            }
          }
          await this.updateMealPositions(matchingDay)
        }
      }
      await this.adjustCollidingNutritionPlanConfigs(planConfig)

    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { message: this.translate.instant('Sollen die Mahlzeiten des Plans automatisch an die Nährstoffziele des Coachees angepasst werden?'), title: this.translate.instant('Mahlzeiten anpassen'), positiveButton: this.translate.instant('Automatisch anpassen'), negativeButton: this.translate.instant('Nur kopieren') },
      })
      var adjustMeals = await dialogRef.afterClosed().toPromise()

      // Copying a PlanningTemplate.
      this.spinner.show()

      // Prepare NutritionPlanConfigs:
      this.spinnerText = this.translate.instant('Vorlage einfügen.') + '<br>' + this.translate.instant('Plan kopieren')
      var planningTemplate: PlanningTemplate = draggedTemplate
      planningTemplate.nutritionPlanConfigs = await this.nutritionPlanService.getNutritionPlanConfigs(this.user, planningTemplate).toPromise()
      var planIdMapping = {}
      for (let planConfig of planningTemplate.nutritionPlanConfigs) {
        planConfig.startDate = startDate.clone().addDays(planConfig.startDayNumber)
        if (planConfig.endDayNumber) planConfig.endDate = startDate.clone().addDays(planConfig.endDayNumber)
        planConfig.isActive = false
        planConfig.nutritionPlans?.forEach(plan => {
          planIdMapping[plan.id] = FirestoreNutritionPlanService.generateUniqueString()
          plan.id = planIdMapping[plan.id]
          plan.connectedSituationId = null
        })
        await this.nutritionPlanService.insertNutritionPlanConfig(planConfig, this.user, null).toPromise()
        await this.adjustCollidingNutritionPlanConfigs(planConfig)
      }

      // Prepare PlannedMeals:
      var meals = await this.nutritionPlanService.getPlanningTemplateMeals(planningTemplate).toPromise()
      for (var meal of meals) {
        meal.date = startDate.clone().addDays(meal.dayNumber)
        meal.nutritionPlanId = planIdMapping[meal.nutritionPlanId]
      }

      if (adjustMeals == true) {
        var mealCount = 0

        for (let planConfig of planningTemplate.nutritionPlanConfigs) {
          var startDate = planConfig.startDate.clone()
          var endDate = planConfig.endDate?.clone() || planConfig.startDate.clone().addDays(31)

          await this.setupPlanConfig(planConfig, this.user, meals)

          for (let nutritionPlan of planConfig.nutritionPlans) {

            planConfig.selectedNutritionPlan = nutritionPlan
            if (nutritionPlan.isRepeating) endDate = startDate.clone().addDays(nutritionPlan.repetitionDuration - 1)
            
            var days = await NutritionPlanComponent.composeDays(startDate, endDate, this.user, this.configs, planningTemplate.nutritionPlanConfigs, meals, null, this.nutritionService)
  
            for (let day of days) {
              if (!day.config) continue
  
              var nutritionalGoal: NutritionalGoalV2 = day.config.getNutritionalGoalForDate(day.date)
              var totalCalories = nutritionalGoal.getCalories()
  
              var caloriesLeft = totalCalories
              var carbohydratesLeft = nutritionalGoal.getCarbohydrates()
              var proteinLeft = nutritionalGoal.getProtein()
              var fatLeft = nutritionalGoal.getFat()
  
              var calorieDistribution = []
              // Relative size of each meal in respect to the calories left of the day.
              var cumulatedCalorieDistribution = []
              var summedPercentages = 0
              for (var i = 0; i < planConfig.selectedNutritionPlan.mealConfigs.length; i++) {
                if (i == 0) {
                  var percentage = planConfig.selectedNutritionPlan.mealConfigs[i].mealSizeProportion
                  summedPercentages += percentage
                  calorieDistribution.push(percentage)
                  cumulatedCalorieDistribution.push(percentage)
                } else if (i == planConfig.selectedNutritionPlan.mealConfigs.length - 1) {
                  var percentage = planConfig.selectedNutritionPlan.mealConfigs[i].mealSizeProportion
                  summedPercentages += percentage
                  calorieDistribution.push(percentage)
                  cumulatedCalorieDistribution.push(1)
                } else {
                  var percentage = planConfig.selectedNutritionPlan.mealConfigs[i].mealSizeProportion
                  calorieDistribution.push(percentage)
                  cumulatedCalorieDistribution.push( percentage / ( 1 - summedPercentages) )
                  summedPercentages += percentage
                }
              }
  
              // Fill one meal after another.
              var mealNumber = 0
              for (let dayItem of day.items) {
                
                var mealCalorieGoal = caloriesLeft * cumulatedCalorieDistribution[mealNumber]
                var mealMacroGoal = [carbohydratesLeft * cumulatedCalorieDistribution[mealNumber], proteinLeft * cumulatedCalorieDistribution[mealNumber], fatLeft * cumulatedCalorieDistribution[mealNumber]]
  
                var mealsOfDay = []
                if (dayItem.meal) mealsOfDay.push(dayItem.meal)
                if (dayItem.alternativeMeals?.length > 0) {
                  dayItem.alternativeMeals.forEach(meal => mealsOfDay.push(meal))
                }

                var targetValues = [mealMacroGoal[0], mealMacroGoal[1], mealMacroGoal[2], mealCalorieGoal]

                for (let meal of mealsOfDay) {
                  
                  await this.nutritionService.loadBaseRecipeForPlannedMeal(meal, true)

                  if (meal.baseRecipe) {
                    var plannedMeal = NutritionPlanComponent.fitRecipeIntoMacros(meal.baseRecipe, targetValues, true)
  
                    meal.name = plannedMeal.name
                    meal.baseMealTemplateId = plannedMeal.baseMealTemplateId
                    meal.baseMealTemplateServingMultiplier = plannedMeal.baseMealTemplateServingMultiplier
                    meal.baseRecipe = plannedMeal.baseRecipe
                    if (!meal.mealTemplateId) meal.mealTemplateId = plannedMeal.mealTemplateId
                    if (!meal.imageURL) meal.imageURL = plannedMeal.imageURL
                    meal.thumbnailPath = plannedMeal.thumbnailPath ?? null
                    meal.instructions = plannedMeal.instructions ?? null
                    meal.foods = plannedMeal.foods?.map(food => food) ?? null
                    meal.baseRecipeFoods = plannedMeal.baseRecipeFoods?.map(food => food) ?? null
                    meal.nutritionFactsLoaded = true
                    meal.recalculateNutritionalValues()
  
                    await this.nutritionPlanService.insertNutritionPlanMeal(meal, this.user, null)
  
                  } else {

                    await this.nutritionPlanService.getNutritionPlanMeal(meal, this.user, planningTemplate)

                    var plannedMeal = NutritionPlanComponent.fitMealIntoMacros(meal, targetValues)
                    meal.recalculateNutritionalValues()
  
                    await this.nutritionPlanService.insertNutritionPlanMeal(meal, this.user, null)

                  }
  
                }

                if (mealsOfDay.length > 0) {
                  caloriesLeft -= mealsOfDay[0].getNutritionalValue('calories')
                  carbohydratesLeft -= mealsOfDay[0].getNutritionalValue('carbohydrates')
                  proteinLeft -= mealsOfDay[0].getNutritionalValue('protein')
                  fatLeft -= mealsOfDay[0].getNutritionalValue('fat')
                } else {
                  caloriesLeft -= mealCalorieGoal
                  carbohydratesLeft -= mealMacroGoal[0]
                  proteinLeft -= mealMacroGoal[1]
                  fatLeft -= mealMacroGoal[2]
                }
  
                mealCount++
                mealNumber++
                this.spinnerText = this.translate.instant('Vorlage einfügen.') + '<br>' + this.translate.instant('Mahlzeit') + ' ' + mealCount + '/' + meals.length
              }
              
            }
            

          }

        }
      } else if (adjustMeals == false) {
        var mealCount = 0
        for (let meal of meals) {
          await this.nutritionPlanService.insertNutritionPlanMeal(meal, this.user, null)
          mealCount++
          this.spinnerText = this.translate.instant('Vorlage einfügen.') + '<br>' + this.translate.instant('Mahlzeit') + ' ' + mealCount + '/' + meals.length
        }
      }


    }
    
    this.spinnerText = null
    this.spinner.hide()
    this.draggingMeal = false
    this.draggingTemplate = false
    this.draggedTemplate = null
    this.draggedDay = null
    this.draggedDayItem = null
    this.onHideNutritionPlanTemplates()
    this.reloadData()
  }

  async onDeletePlanningTemplate(template: PlanningTemplate) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: this.translate.instant('Möchtest du diese Vorlage wirklich löschen?'), title: this.translate.instant('Vorlage löschen') },
    })
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        await this.nutritionPlanService.deletePlanningTemplate(template)
        this.planningTemplates.splice(this.planningTemplates.indexOf(template), 1)
      }
    })
  }

  async onSavePlanConfigAsTemplate(originalPlanConfig: NutritionPlanConfig) {
    var description = ''
    description += this.translate.instant('Dauer:') + ' ' + originalPlanConfig.getPrintableDuration(this.translate) + ' , ' + originalPlanConfig.nutritionPlans.length + ' ' + this.translate.instant('Pläne')
    originalPlanConfig.nutritionPlans.forEach((plan, index) => {
      description += '\n' + (index + 1) + '. ' + plan.getName(this.translate) + ': ' + plan.mealConfigs.length + ' ' + this.translate.instant('Mahlzeiten')
    })
    var config: CycleConfig = null
    this.weeks[0].days.forEach(day => {
      if (!config && day.config && day.planConfig == originalPlanConfig) {
        config = day.config
      }
    })
    if (config) {
      description += '\n' + this.translate.instant('Ziele:')
      config.situations.forEach((situation, index) => {
        description += '\n' + (index + 1) + '. ' + situation.getName(this.translate) + ': ' + (situation.nutritionalGoals.length > 1 ? ' ' + this.translate.instant('Tagesabhängiges Nährstoffziel') : situation.nutritionalGoals[0].getPrintableGoal(this.translate))
      })
    }

    const dialogRef = this.dialog.open(CreateTemplateDialogComponent, {
      data: { name: '', description: description }, width: '800px',
    });
    var result = await dialogRef.afterClosed().toPromise()
    if (result != null) {
      this.spinner.show()
      console.log('Save as template')
      // Create PlanningTemplate:
      var startDate = originalPlanConfig.startDate.clone()
      var template = new PlanningTemplate()
      template.name = result.name || this.translate.instant('Vorlage')
      template.coachUid = this.coach.uid
      template.licenceHolderUid = this.coach.licenceHolderUid
      template.description = result.description

      // Clone NutritionPlanConfig and PlannedMeals:
      let planConfig = originalPlanConfig.clone()
      planConfig.startDayNumber = 0
      if (planConfig.endDate) {
        planConfig.endDayNumber = NutritionPlanComponent.dateDiffInDays(planConfig.startDate, planConfig.endDate)
      }
      planConfig.startDate = null
      planConfig.endDate = null
      template.nutritionPlanConfigs.push(planConfig)
      
      await this.nutritionPlanService.insertPlanningTemplate(template, this.coach).toPromise()
      this.nutritionPlanService.planningTemplates.push(template)
      var planIndex = 0
      for (let config of template.nutritionPlanConfigs) {
        await this.nutritionPlanService.insertNutritionPlanConfig(config, null, template).toPromise()
        for (let plan of config.nutritionPlans) {
          planIndex++
          var meals = await this.nutritionPlanService.getNutritionPlanMealsByPlanId(this.user, plan.id)
          var mealIndex = 0
          for (let meal of meals) {
            mealIndex++
            await this.nutritionPlanService.getNutritionPlanMeal(meal, this.user, null, false, true, false)
            meal.dayNumber = NutritionPlanComponent.dateDiffInDays(startDate, meal.date)
            await this.nutritionPlanService.insertNutritionPlanMeal(meal, this.user, template)
            this.spinnerText = this.translate.instant('Vorlage speichern.') + '<br>' + this.translate.instant('Plan') + ' ' + planIndex + ', ' + this.translate.instant('Mahlzeit') + ' ' + mealIndex + '/' + meals.length
          }
        }
      }
      for (let config of template.cycleConfigs) {
        await this.nutritionPlanService.insertNutritionConfig(config, null, template).toPromise()
      }
      this.spinnerText = null
      this.spinner.hide()
    }
  }

  // MIGRATION

  async migrateNutritionalGoals() {
    if (this.user.nutritionalGoalsFromCoach?.length > 0) {
      let goals = this.user.nutritionalGoalsFromCoach
      var config = new CycleConfig()
      config.name = this.translate.instant('Nährstoffziel')
      config.startDate = new Date()
      config.endDate = null
      var defaultSituation = new Situation()
      defaultSituation.id = SituationType.default
      defaultSituation.type = SituationType.default
      config.situations.push(defaultSituation)
      config.selectedSituation = defaultSituation
      goals.forEach(goal => {
        if (goal.applicableWeekdays.length == 1 && goal.applicableWeekdays[0] == 'alt') {
          var situation = new Situation()
          situation.type = SituationType.custom
          situation.customName = goal.name
          situation.nutritionalGoals.push(NutritionalGoalV2.fromDeprecatedWithSituation(goal, situation))
          config.situations.push(situation)
        } else {
          defaultSituation.nutritionalGoals.push(NutritionalGoalV2.fromDeprecatedWithSituation(goal, defaultSituation))
        }
      })
      await this.nutritionPlanService.insertNutritionConfig(config, this.user, null).toPromise()
      this.configs.push(config)
    }
  }

  async migrateNutritionPlan(deprectedPlan: NutritionPlan) {
    this.spinner.show()

    var startDate: Date = deprectedPlan.startDate

    await this.nutritionPlanService.retrieveNutritionPlanContent(deprectedPlan, this.user).toPromise()

    var planConfig = new NutritionPlanConfig()
    planConfig.startDate = startDate
    planConfig.endDate = new Date(planConfig.startDate).addDays(deprectedPlan.duration - 1)
    var maxEndDate = NutritionPlanComponent.getMaxEndDateForConfig(this.planConfigs, planConfig.startDate)
    if (maxEndDate && maxEndDate < planConfig.endDate) planConfig.endDate = maxEndDate
    planConfig.isActive = false

    var plan = new NutritionPlanV2()
    plan.name = deprectedPlan.name
    plan.order = 0
    plan.isRepeating = false

    planConfig.nutritionPlans.push(plan)
    planConfig.selectedNutritionPlan = plan

    if (deprectedPlan.customMealTypes) {
      planConfig.selectedNutritionPlan.mealConfigs = []
      deprectedPlan.customMealTypes.forEach(deprecatedType => {
        var mealConfig = new NutritionPlanMealConfig()
        mealConfig.position = planConfig.selectedNutritionPlan.mealConfigs.length
        var mealType: MealType
        Object.values(MealType).forEach(type => {
          if (!mealType && deprecatedType == MealTypeTranslation[type]) mealType = type
        })
        if (!mealType) {
          mealType = MealType.custom
          mealConfig.customMealType = deprecatedType
        }
        mealConfig.mealType = mealType
        planConfig.selectedNutritionPlan.mealConfigs.push(mealConfig)
      })
    }
    await this.nutritionPlanService.insertNutritionPlanConfig(planConfig, this.user, null).toPromise()

    var days: CalendarDay[] = []
    var startDate = planConfig.startDate.clone()
    var endDate = planConfig.endDate.clone()
    var days = await NutritionPlanComponent.composeDays(startDate, endDate, this.user, this.configs, this.planConfigs, this.meals, null, this.nutritionService)

    var mealCount = 0
    var totalCount = deprectedPlan.days.length * deprectedPlan.mealsPerDay
    for (var i = 0; i < deprectedPlan.days.length; i++) {
      var templateDay = deprectedPlan.days[i]
      var matchingDay: CalendarDay = null
      days.forEach(day => {
        if (!matchingDay && day.date.isSameDate(templateDay.date)) matchingDay = day
      })
      if (matchingDay && !matchingDay.planConfig && (matchingDay.date < planConfig.endDate || matchingDay.date.isSameDate(planConfig.endDate))) {
        matchingDay.planConfig = planConfig
        for (var j = 0; j < templateDay.plannedMeals.length; j++) {
          var templateMeal = templateDay.plannedMeals[j]
          if (templateMeal) {
            this.spinnerText = this.translate.instant('Plan übertragen.') + '<br>' + this.translate.instant('Mahlzeit') + ' ' + mealCount + '/' + totalCount
            var meal = new PlannedMealV2()
            meal.date = matchingDay.date
            meal.name = templateMeal.name
            meal.nutritionPlanId = plan.id
            if (deprectedPlan.customMealTypes && deprectedPlan.customMealTypes.length > templateMeal.number) {
              var deprecatedType = deprectedPlan.customMealTypes[templateMeal.number]
              planConfig.selectedNutritionPlan.mealConfigs.forEach(c => {
                if (c.getType(this.translate) == deprecatedType) {
                  meal.mealConfigId = c.id
                  meal.type = c.mealType
                }
              })
            }
            meal.foods = templateMeal.foods
            meal.recalculateNutritionalValues()
            
            await this.nutritionPlanService.insertNutritionPlanMeal(meal, this.user, null)
            var item = new DayItem(null, meal)
            matchingDay.items.push(item)
            mealCount++
            
          }
        }
        await this.updateMealPositions(matchingDay)
      }
    }
    await this.adjustCollidingNutritionPlanConfigs(planConfig)
    this.reloadData()
    this.spinner.hide()
  }

  onMobile() {
    return this.utilityService.onMobile()
  }
  onPhone() {
    return this.utilityService.onSmallDisplay()
  }
}
