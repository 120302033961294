<div class="dialog-topbar">
    <ng-container *ngIf="editMode && (recording.videoPath || recording.newVideoFile); else titleTemplate">
        <div class="icon-button float-left" (click)="onCloseDialog()" translate>
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
            Schließen
        </div>
        <button class="button-filled small create-Exercise float-right" (click)="onSaveChanges()" translate>Übernehmen</button>
    </ng-container>
    <ng-template #titleTemplate>
        <div class="bold float-left">{{('{{setNumberParameter}}. Satz' | translate:{setNumberParameter: (recording.setNumber
            + 1)})}} {{exercise?.name?.GetValue(translate.currentLang) ?? exercise?.name?.de}}</div>
        <div class="icon-button float-right" (click)="onCloseDialog()" translate>
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
            Schließen
        </div>
    </ng-template>
</div>
<div class="dialog-content-wrapper">
    <div class="container">
        <div *ngIf="editMode; else defaultNoteView" class="row">
            <div class="col-12 col-md-6">
                <label translate>Notiz</label>
                <app-md-editor-legacy id="editor-note" class="multi-line" (valueChanged)="onCoacheeNoteChanged($event)"
                    [inputValue]="recording.note" [placeHolder]="''"></app-md-editor-legacy>
            </div>
            <div class="spacer-25"></div>
        </div>
        <ng-template #defaultNoteView>
            <div class="row" *ngIf="recording.note">
                <div class="col-12">
                    <svg _ngcontent-aot-c1="" class="bi bi-sticky" fill="currentColor" height="16" viewBox="0 0 16 16"
                        width="16" xmlns="http://www.w3.org/2000/svg">
                        <path _ngcontent-aot-c1=""
                            d="M2.5 1A1.5 1.5 0 0 0 1 2.5v11A1.5 1.5 0 0 0 2.5 15h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 15 8.586V2.5A1.5 1.5 0 0 0 13.5 1h-11zM2 2.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5V8H9.5A1.5 1.5 0 0 0 8 9.5V14H2.5a.5.5 0 0 1-.5-.5v-11zm7 11.293V9.5a.5.5 0 0 1 .5-.5h4.293L9 13.793z">
                        </path>
                    </svg>
                    {{recording.note}}
                </div>
                <div class="spacer-25"></div>
            </div>
        </ng-template>
        <div *ngIf="!editMode || recording.videoUrl || recording.videoPath; else videoUploadTemplate"
            class="video-wrapper">
            <video *ngIf="recording.urlsLoaded && recording.videoUrl" (play)="onVideoClicked()" controls playsinline [muted]="'muted'"
                [src]="recording.videoUrl" poster="{{recording.thumbnailUrl}}" preload="metadata"></video>
            <div *ngIf="recording.urlsLoaded && !recording.videoUrl" translate>
                Das Video wurde noch nicht vollständig hochgeladen.
            </div>
            <div *ngIf="!recording.urlsLoaded">
                <span translate>Video wird geladen.</span>
                {{(firestoreService.loadTrackedVideoRecordingUrls(recording, userUid) | async)?.videoUrl}}
            </div>
            <button *ngIf="editMode" class="small light" (click)="onDeleteVideo()" translate>
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-trash"
                    viewBox="0 0 16 16">
                    <path
                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path fill-rule="evenodd"
                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                </svg>
                Video entfernen
            </button>
        </div>
        <ng-template #videoUploadTemplate>
            <div>
                <label for="upload-video" translate>Video hinterlegen</label>
                <div class="video-input link" (click)="openVideoDialog()">
                    <label class="hint label" translate>Hochladen (max. 50MB)</label>
                    <input [disabled]="!editMode" id="video-upload" class="link" type="file" style="display: none;"
                        accept=".mp4, .x-m4v, .mov" (change)="onUploadVideoSelected($event)">
                </div>
                <div class="spacer-10"></div>
            </div>
        </ng-template>
        <div *ngIf="recording.videoUrl && !editMode" class="link open-link-button icon-button w-100 text-center mt-3"
            (click)="goToLink(recording.videoUrl)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                    d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z" />
            </svg>
            Video in neuem Tab öffnen
        </div>
        <div class="spacer-25"></div>
        <div class="feedback-wrapper">
            <div class="" translate>Feedback geben:</div>
            <div class="feedback-wrapper">
                <app-md-editor-legacy #mdEditor id="editor-note" class="multi-line" (valueChanged)="onEditorValueChanged($event)"
                    [inputValue]="recording.feedbackNote || ''" [placeHolder]="''">
                </app-md-editor-legacy>
                <button mat-button [matMenuTriggerFor]="menu" class="toggle-emoji-picker" (click)="onEmojiPickerButtonClick($event)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-emoji-smile" viewBox="0 0 18 18">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
                    </svg>
                </button>
                <mat-menu #menu="matMenu" class="emoji-picker">
                    <div (click)="$event.stopPropagation()">
                        <emoji-mart [enableSearch]="false" class="emoji-mart" (emojiSelect)="handleEmojiSelection($event)" title="Emoji wählen"></emoji-mart> 
                    </div>
                </mat-menu>
                <button *ngIf="hasChanges && !editMode" class="button-savenote" (click)="onSendFeedback()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-check2" viewBox="0 0 16 16">
                        <path
                            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</div>