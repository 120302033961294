<div class="nutritionalvalue-popover">
    <div class="nutritionalvalue-group">
        <span class="bold" translate>Nährstoffe:</span><br>
        <ng-container *ngFor="let nutritionalValue of BaseNutritionFact.getNutritionalValuesForGroup(0)">
            <div class="row">
                <div *ngIf="nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != null && nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != undefined" class="col-12 nutritionalvalue-entry">
                    {{BaseNutritionFact.getPrintableNutritionalValue(translate, nutritionalValue, nutritionalValueHolder?.getNutritionalValue(nutritionalValue), cycleConfig?.commonTargetValues[nutritionalValue])}}
                </div>
            </div>
        </ng-container>
    </div>
    <div class="nutritionalvalue-group">
        <span class="bold" translate>Nährstoffdetails:</span><br>
        <ng-container *ngFor="let nutritionalValue of BaseNutritionFact.getNutritionalValuesForGroup(1)">
            <div class="row">
                <div *ngIf="nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != null && nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != undefined" class="col-12 nutritionalvalue-entry">
                    {{BaseNutritionFact.getPrintableNutritionalValue(translate, nutritionalValue, nutritionalValueHolder?.getNutritionalValue(nutritionalValue), cycleConfig?.commonTargetValues[nutritionalValue])}}
                </div>
            </div>
        </ng-container>
    </div>
    <div class="nutritionalvalue-group">
        <span class="bold" translate>Vitamine:</span><br>
        <ng-container *ngFor="let nutritionalValue of BaseNutritionFact.getNutritionalValuesForGroup(3)">
            <div class="row">
                <div *ngIf="nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != null && nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != undefined" class="col-12 nutritionalvalue-entry">
                    {{BaseNutritionFact.getPrintableNutritionalValue(translate, nutritionalValue, nutritionalValueHolder?.getNutritionalValue(nutritionalValue), cycleConfig?.commonTargetValues[nutritionalValue])}}
                </div>
            </div>
        </ng-container>
    </div>
    <div class="nutritionalvalue-group">
        <span class="bold" translate>Mineralstoffe und Spurenelemente:</span><br>
        <ng-container *ngFor="let nutritionalValue of BaseNutritionFact.getNutritionalValuesForGroup(4)">
            <div class="row">
                <div *ngIf="nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != null && nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != undefined" class="col-12 nutritionalvalue-entry">
                    {{BaseNutritionFact.getPrintableNutritionalValue(translate, nutritionalValue, nutritionalValueHolder?.getNutritionalValue(nutritionalValue), cycleConfig?.commonTargetValues[nutritionalValue])}}
                </div>
            </div>
        </ng-container>
    </div>
    <div class="nutritionalvalue-group">
        <span class="bold" translate>Kohlenhydratzusammensetzung:</span><br>
        <ng-container *ngFor="let nutritionalValue of BaseNutritionFact.getNutritionalValuesForGroup(2)">
            <div class="row">
                <div *ngIf="nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != null && nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != undefined" class="col-12 nutritionalvalue-entry">
                    {{BaseNutritionFact.getPrintableNutritionalValue(translate, nutritionalValue, nutritionalValueHolder?.getNutritionalValue(nutritionalValue), cycleConfig?.commonTargetValues[nutritionalValue])}}
                </div>
            </div>
        </ng-container>
    </div>
    <div class="nutritionalvalue-group">
        <span class="bold" translate>Aminosäuren:</span><br>
        <ng-container *ngFor="let nutritionalValue of BaseNutritionFact.getNutritionalValuesForGroup(5)">
            <div class="row">
                <div *ngIf="nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != null && nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != undefined" class="col-12 nutritionalvalue-entry">
                    {{BaseNutritionFact.getPrintableNutritionalValue(translate, nutritionalValue, nutritionalValueHolder?.getNutritionalValue(nutritionalValue), cycleConfig?.commonTargetValues[nutritionalValue])}}
                </div>
            </div>
        </ng-container>
    </div>
    <div class="nutritionalvalue-group">
        <span class="bold" translate>Fettzusammensatzung:</span><br>
        <ng-container *ngFor="let nutritionalValue of BaseNutritionFact.getNutritionalValuesForGroup(6)">
            <div class="row">
                <div *ngIf="nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != null && nutritionalValueHolder?.getNutritionalValue(nutritionalValue) != undefined" class="col-12 nutritionalvalue-entry">
                    {{BaseNutritionFact.getPrintableNutritionalValue(translate, nutritionalValue, nutritionalValueHolder?.getNutritionalValue(nutritionalValue), cycleConfig?.commonTargetValues[nutritionalValue])}}
                </div>
            </div>
        </ng-container>
    </div>
    <div class="nutritionalvalue-group">
        <div class="row col-12 footnote">
            *<span translate>EU-RDA (2008)</span> <br>
            **<span translate>von Coach geplant</span>
        </div>
    </div>
</div>