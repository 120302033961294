import { Component } from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Coach } from 'src/app/model/coach.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-coach-notifications',
  templateUrl: './coach-notifications.component.html',
  styleUrls: ['./coach-notifications.component.css']
})
export class CoachNotificationsComponent {

  public notificationSettingsEntries: any[] = [
    { key: 'trainingCompleted', de: marker('Training absolviert')},
    { key: 'questionaireCompleted', de: marker('Check-In ausgefüllt')},
    { key: 'productPurchased', de: marker('Produkt verkauft')},
  ]

  coach: Coach
  emailNotificationSettings: any
  pushNotificationSettings: any
  communicationEmailAddressChanged: boolean = false

  constructor(public userService: FirestoreService, public toastr: ToastrService, private translate: TranslateService) {
    this.coach = this.userService.getLoggedInUser().coach
    this.emailNotificationSettings = this.coach.emailNotificationSettings
    this.pushNotificationSettings = this.coach.pushNotificationSettings
    if (!this.emailNotificationSettings && environment.firebaseProjectId != 'traindoo-app') {
      this.emailNotificationSettings = {}
      this.emailNotificationSettings['productPurchased'] = true
    } else if (!this.emailNotificationSettings) {
      this.emailNotificationSettings = {}
    }
    if (!this.pushNotificationSettings) {
      this.pushNotificationSettings = {}
    }
  }

  async onEmailNotificationEntryChanged(key: string, value: boolean) {
    if (!this.emailNotificationSettings) this.emailNotificationSettings = {}
    this.emailNotificationSettings[key] = value
    this.coach.emailNotificationSettings = this.emailNotificationSettings
    this.coach.pushNotificationSettings = this.pushNotificationSettings
    await this.userService.updateCoachNotificationSettings(this.coach)
  }
  async onPushNotificationEntryChanged(key: string, value: boolean) {
    if (!this.pushNotificationSettings) this.pushNotificationSettings = {}
    this.pushNotificationSettings[key] = value
    this.coach.emailNotificationSettings = this.emailNotificationSettings
    this.coach.pushNotificationSettings = this.pushNotificationSettings
    await this.userService.updateCoachNotificationSettings(this.coach)
  }

  onCommunicationEmailAddressChanged(value: string) {
    this.coach.communicationEmailAddress = value
    this.communicationEmailAddressChanged = true
  }
  async onSaveCommunicationEmailAddress() {
    if (!this.coach.isEmailValid()) {
      this.toastr.error(this.translate.instant('Bitte gib eine korrekte Email-Adresse an.'), this.translate.instant("Speichern nicht möglich."),  {
        positionClass: 'toast-bottom-center'
      });
      return
    }
    await this.userService.updateCoachCommunicationEmailAddress(this.coach)
    this.communicationEmailAddressChanged = false
  }
}
