import {Component} from '@angular/core';
import {PaymentService} from "../../../../services/payment.service";
import {PaymentAnalyticsService} from "../../../../services/payment-analytics.service";

@Component({
  selector: 'app-kpi-clv',
  templateUrl: './kpi-clv.component.html',
  styleUrls: ['./kpi-clv.component.css']
})
export class KpiClvComponent {

  private averageCLV: number = 0;
  private paymentsByCustomer: Map<string, number>;

  constructor(private paymentService: PaymentService, private paymentAnalyticsService: PaymentAnalyticsService) {
    this.calculateCLV();
  }

  getPrintableCLV() {
    return this.paymentAnalyticsService.transformNumberToMoney(this.averageCLV, this.paymentService.paymentSettings.currency);
  }

  private calculateCLV() {
    const customerPayments = this.paymentAnalyticsService.getPaidOpenPaymentsFromCoachings();
    this.paymentsByCustomer = new Map<string, number>();

    for (let payment of customerPayments) {
      const id = payment.customerUid;
      let paymentAmount = payment.amount / 100;

      if (this.paymentsByCustomer.has(id)) {
        let amount = this.paymentsByCustomer.get(id);
        paymentAmount += amount;
      }

      this.paymentsByCustomer.set(id, paymentAmount);
    }

    const payments = Array.from(this.paymentsByCustomer.values());
    this.averageCLV = payments.reduce((acc, cur) => acc + cur, 0) / (payments.length || 1);
  }

  getPrintableCLVInfo() {
    if (!this.paymentService.accountBalance) return null

    const payments = Array.from(this.paymentsByCustomer.values());
    const customers = payments.length;
    const money = payments.reduce((acc, cur) => acc + cur, 0);


    return `Insgesamt eingegangene Zahlungen: ${this.paymentAnalyticsService.transformNumberToMoney(money)}, bei ${customers} Coachees`;
  }
}
