<div class="dialog-topbar">
    <button class="button-filled small create-Exercise" (click)="onTakeSelection()" translate>Übernehmen</button>
    <div class="icon-button float-right" (click)="onCancelSelection()" translate>
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        Schließen
    </div>
</div>
<div class="dialog-content-wrapper">
    <div class="row">
        <div *ngIf="canShowMetricSets" class="col-12 textalign-center">
            <button class="tab-button {{showMetrics ? 'active' : ''}}" (click)="swapTab('metrics')" translate>
                Metriken
                <div class="underline"></div>
            </button>
            <button class="tab-button {{!showMetrics ? 'active' : ''}}" (click)="swapTab('metricSets')">
                <span translate>Metrik-Sets</span>
                <div class="underline"></div>
            </button>
        </div>
    </div>
    <div *ngIf="showMetrics">
        <div class="bold display-inlineblock" translate>Metriken</div>
        <div class="icon-button display-inlineblock marginleft-10" (click)="onCreateCustomMetric()" translate>
            <svg class="bi bi-plus" fill="currentColor" height="1em" viewBox="0 0 16 16" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" fill-rule="evenodd"></path>
            </svg>
            Erstellen
        </div>
        <div class="row">
            <div class="col-12">
                <div class="spacer-10"></div>
                <input id="metrics-search-input" class="input wide" (input)="onMetricsSearchInputChanged($event.target.value)" [placeholder]="'Metriken durchsuchen' | translate" type="text">
                <div class="icon-button deletesearch-icon delete-recipesearch" (click)="onMetricsDeleteSearchInput()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </div>
                <div class="remove-all-filters-button only-desktop" translate>
                    Filter zurücksetzen
                    <button class="filter-button" (click)="onRemoveAllFilters(); $event.stopPropagation()">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </button>
                </div>
                <div class="spacer-25"></div>
            </div>
        </div>
        <div class="mobile-filter">
            <div class="row">
                <div class="col-12 col-md-6 mobile-filter-container">
                    <button class="filter-button" data-toggle="collapse" href="#collapseFilterContainer" role="button" aria-expanded="false" aria-controls="collapseFilterContainer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filter" viewBox="0 0 16 16">
                            <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
                        </svg>
                    </button>
                    <span translate>Filter</span>
                </div>
            </div>
            <div class="collapse" id="collapseFilterContainer">
                <div class="card card-body">
                    <div class="row">
                        <div class="col-12 col-md-6 mobile-filter-container mobile-reset-filter">
                            <button class="filter-button" (click)="onRemoveAllFilters(); $event.stopPropagation()">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </button>
                            <span translate>Filter zurücksetzen</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="row">
                                <div class="col-12 col-md-6 mobile-filter-container">
                                    <app-filter-selection-dropdown [availableFilterObjects]="availableMetricTypes" (filterChanged)="setFilteredMetrics()" [selectedLanguage]="translate.currentLang"></app-filter-selection-dropdown>
                                    <span translate>Art</span>
                                </div>
                                <div class="col-12 col-md-6 mobile-filter-container">
                                    <app-filter-selection-dropdown [availableFilterObjects]="availableCreators" (filterChanged)="setFilteredMetrics()" [selectedLanguage]="translate.currentLang"></app-filter-selection-dropdown>
                                    <span translate>Ersteller</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="spacer-10"></div>
        <div class="row header-row">
            <div class="col">
                <b translate>Name</b>
            </div>
            <div class="col">
                <span translate>Art</span>
                <span class="filter-button-span"><app-filter-selection-dropdown [availableFilterObjects]="availableMetricTypes" (filterChanged)="setFilteredMetrics()" [selectedLanguage]="translate.currentLang"></app-filter-selection-dropdown></span>
            </div>
            <div class="col">
                <span translate>Ersteller</span>
                <span class="filter-button-span"><app-filter-selection-dropdown [availableFilterObjects]="availableCreators" (filterChanged)="setFilteredMetrics()" [selectedLanguage]="translate.currentLang"></app-filter-selection-dropdown></span>
            </div>
        </div>
        <div class="spacer-25"></div>
        <div class="row justify-content-center">
            <ng-container *ngFor="let selectable of filteredSelectableMetrics">
                <div *ngIf="!selectable.deleted" class="col-12">
                    <div class="metric-wrapper">
                        <div class="row layout-box sub-box" (click)="selectMetric(selectable)">
                            <div class="checkbox-wrapper">
                                <input class="input checkbox" type="checkbox" [checked]="selectedMetricIds.includes(selectable.metricId)">
                            </div>
                            <div class="col">
                                <div class="name bold">{{selectable.nameTranslation.GetValue(translate.currentLang)}}</div>
                                <div class="description" *ngIf="!utilityService.onMobile()">
                                    {{selectable.descriptionDe}}
                                </div>
                            </div>
                            <div class="col-auto col-md">
                                <div class="row">
                                    <div [innerHTML]="EventLogService.getEventItemMetricIcon(selectable) | sanitizedHtml">
                                    </div>
                                    <ng-container *ngIf="!utilityService.onSmallDisplay()">{{selectable.getPrintableType(translate)}}</ng-container>
                                </div>
                            </div>
                            <div *ngIf="utilityService.onMobile() && !selectable.public">
                                <button class="float-right" mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                    </svg>
                                </button>
                                <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="onEditCustomMetric(selectable);$event.stopPropagation()" translate>Bearbeiten</button>
                                <button mat-menu-item (click)="onDeleteCustomMetric(selectable);$event.stopPropagation()" translate>Löschen</button>
                                </mat-menu>
                            </div>
                            <div class="col" *ngIf="!utilityService.onMobile()">
                                <div class="row only-desktop">
                                    <div *ngIf="selectable.public">{{environtment.isWhitelabel ? 'Preset' : 'nutrilize'}}</div>
                                    <div *ngIf="!selectable.public">Du</div>
                                </div>
                                <div *ngIf="!selectable.public" class="row float-right">
                                    <div class="icon-button marginright-10" (click)="onEditCustomMetric(selectable);$event.stopPropagation()" translate>
                                        Bearbeiten
                                    </div>
                                    <div class="icon-button delete-button" (click)="onDeleteCustomMetric(selectable);$event.stopPropagation()" translate>
                                        Löschen
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div *ngIf="!showMetrics"> 
        <div class="bold display-inlineblock" translate>Metrik-Sets</div>
        <div class="icon-button display-inlineblock marginleft-10" (click)="onCreateMetricGroup()" translate>
            <svg class="bi bi-plus" fill="currentColor" height="1em" viewBox="0 0 16 16" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" fill-rule="evenodd"></path>
            </svg>
            Erstellen
        </div>
        <div class="label" translate>
            Metrik-Sets sind Gruppen von Metriken, die du gesammelt an Coachees zuweisen kannst.
        </div>
        <div class="spacer-10"></div>
        <div class="row">
            <div class="col-12">
                <div class="spacer-10"></div>
                <input id="metric-groups-search-input" class="input wide" (input)="onMetricGroupsSearchInputChanged($event.target.value)" [placeholder]="'Metriken durchsuchen' | translate" type="text">
                <div class="icon-button deletesearch-icon delete-recipesearch" (click)="onMetricGroupsDeleteSearchInput()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </div>
                <div class="spacer-25"></div>
            </div>
        </div>
        <div class="spacer-10"></div>
        <div class="row justify-content-center">
            <ng-container *ngFor="let selectable of filteredSelectableMetricGroups">
                <div class="col-12">
                    <div class="metric-wrapper">
                        <div class="row layout-box sub-box" (click)="selectMetricGroup(selectable)">
                            <div class="checkbox-wrapper">
                                <input class="input checkbox" type="checkbox" [(ngModel)]="selectable.IsSelected">
                            </div>
                            <div class="col col-sm-3">
                                <div class="name bold">{{selectable.Item.name.GetValue(translate.currentLang)}}</div>
                            </div>
                            <div class="col-12 col-sm-8">
                                <div class="row">
                                    {{selectable.Item.getMetricNamesString(translate.currentLang)}}
                                </div>
                                <div *ngIf="!selectable.Item.public" class="row float-right">
                                    <div class="icon-button marginright-10" (click)="onEditMetricGroup(selectable.Item);$event.stopPropagation()" translate>
                                        Bearbeiten
                                    </div>
                                    <div class="icon-button" (click)="onDeleteMetricGroup(selectable.Item);$event.stopPropagation()" translate>
                                        Löschen
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<ngx-spinner name="metricSelectionSpinner" bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>