import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unitConversion'
})
export class UnitConversionPipe implements PipeTransform {

  transform(value: string, unitSystem: string): any {
    if (value == 'kg') {
      if (unitSystem == 'imperial') {
        return 'lbs'
      }
    }
    return value
  }
}