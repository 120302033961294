import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FirestoreService} from '../services/firestore.service';
import {User} from '../model/user.model';
import {UtilityService} from '../services/utility.service';
import {NutritionalSummary} from '../model/nutritionalsummary.model';
import {map} from 'rxjs/operators';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {ChatService} from '../services/chat.service';
import {ToastrService} from 'ngx-toastr';
import {NutritionService} from '../services/nutrition.service';
import {BodyData} from '../model/bodydata.model';
import {registerPalette} from "devextreme/viz/palette";
import {NutritionStatisticsItem} from '../model/nutritionstatistics.model';
import '../prototypes'
import {DailyCondition} from '../model/dailycondition.model';
import {Metric} from '../model/metric.model';
import {MetricData} from '../model/metricdata.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {NutritionalValuePopoverComponent} from '../nutritional-value-popover/nutritional-value-popover.component';
import {NutritionalValueHolder} from '../model/basenutritionfact.model';
import {MetricDataImageDialogComponent} from '../metric-data-image-dialog/metric-data-image-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ChartExportService} from '../services/chart-export.service';
import {DxDataGridComponent} from "devextreme-angular";
import * as moment from 'moment';
import {Moment} from 'moment';
import {environment} from 'src/environments/environment';
import {EventLogService} from "../services/event-log.service";
import {MetricGoalDialogComponent} from "./metric-goal-dialog/metric-goal-dialog.component";
import {GoalPlan, GoalPlanConfig, GoalPlanMap, GoalPlanType} from "../model/goal-plan.model";
import {GoalPlanService} from "../services/goal-plan.service";
import {DxChartTypes} from "devextreme-angular/ui/chart"
import {TranslateService} from '@ngx-translate/core';
import {marker} from '@colsen1991/ngx-translate-extract-marker';
import { SelectableGroup, SelectableItem } from '../common/input/selection-dropdown/selection-dropdown.component';


@Component({
  selector: 'app-body-data',
  templateUrl: './body-data.component.html',
  styleUrls: ['./body-data.component.css']
})
export class BodyDataComponent implements OnInit, OnDestroy {
  @ViewChild(DxDataGridComponent) datagrid: DxDataGridComponent
  private _selectedUserUid: string;

  @Input() set selectedUserUid(value: string) {
    if (this._selectedUserUid != value) {
      this._selectedUserUid = value;
      this.ngOnInit();
    }
  }

  public math = Math
  public componentFactory: ComponentFactory<any>;

  public environment = environment

  public displayQuestionaireMetrics: boolean = true;

  onDisplayQuestionaireMetricsChanged() {
    this.displayQuestionaireMetrics = !this.displayQuestionaireMetrics;
    this.updateBodyStatisticsGraph(this.displayedUser);
  }


  protected displayMetricGoals: boolean = true;

  onDisplayMetricGoalsChanged() {
    this.displayMetricGoals = !this.displayMetricGoals;
    this.updateBodyStatisticsGraph(this.displayedUser);
  }

  public weekDays = [this.translate.instant('Sonntag'), this.translate.instant('Montag'), this.translate.instant('Dienstag'), this.translate.instant('Mittwoch'), this.translate.instant('Donnerstag'), this.translate.instant('Freitag'), this.translate.instant('Samstag')];

  public static weekdays() {
    return this.weekdays
  }

  static bodyWeightId = 0;
  static circumferencesId = 1;
  static bodyFatId = 2;
  static bmiId = 3;
  static sleepId = 4;
  static waterId = 5;


  public defaultBodyDataGraphTypes: DropdownItem[] = [
    // {id: 0, name: "Gewicht", tmp: '', unit: null},
    {id: BodyDataComponent.circumferencesId, name: this.translate.instant("Umfänge"), tmp: '', unit: null, order: 1},
    // {id: 2, name: "KFA", tmp: '', unit: null},
    // {id: 3, name: "BMI", tmp: '', unit: null},
    //{id: 4, name: "Schlaf (Wohlbefinden)", tmp: '', unit: null},
    {id: BodyDataComponent.waterId, name: this.translate.instant("Wasserzufuhr"), tmp: '', unit: null, order: 2},
  ]
  public additionalBodyDataGraphTypes: DropdownItem[] = []
  public graphItemSelectionGroups: SelectableGroup[] = []

  public bodyDataGraphTimeRanges: DropdownItem[] = [
    {id: -1, name: this.translate.instant("Letzte 7 Tage"), tmp: '', unit: null, order: 99},
    {id: 0, name: this.translate.instant("Letzte 14 Tage"), tmp: '', unit: null, order: 99},
    {id: 1, name: this.translate.instant("Letzte 31 Tage"), tmp: '', unit: null, order: 99},
    {id: 2, name: this.translate.instant("Letzte 90 Tage"), tmp: '', unit: null, order: 99},
    {id: 3, name: this.translate.instant("Letzte 180 Tage"), tmp: '', unit: null, order: 99},
    {id: 4, name: this.translate.instant("Letzte 365 Tage"), tmp: '', unit: null, order: 99},
    {id: 5, name: this.translate.instant("Gesamter Zeitraum"), tmp: '', unit: null, order: 99},
    {id: 6, name: this.translate.instant("Benutzerdefinierter Zeitraum"), tmp: '', unit: null, order: 99},
  ]

  public additionalBodyDataGraphTypesForTable = []

  public selectedBodyDataGraphType: DropdownItem = this.defaultBodyDataGraphTypes[0]
  public selectedBodyDataGraphTypes: DropdownItem[] = []
  public selectedBodyDataGraphTimeRange: DropdownItem = this.bodyDataGraphTimeRanges[1]

  public selectedStartDate: Date = undefined;
  public selectedEndDate: Date = undefined

  protected planMap: GoalPlanMap = null;

  onStartDateChanged(startDate: Date) {
    this.selectedStartDate = startDate;
    if (this.selectedStartDate && this.selectedEndDate) {
      this.selectedBodyDataGraphTimeRangeChanged();
    }
  }

  onEndDateChanged(endDate: Date) {
    this.selectedEndDate = endDate;
    if (this.selectedStartDate && this.selectedEndDate) {
      this.selectedBodyDataGraphTimeRangeChanged();
    }
  }

  public today = new Date()
  startDateFilterBodyData = (d: Moment | null): boolean => {
    if (d?.toDate() > this.today) return false
    return true
  }
  endDateFilterBodyData = (d: Moment | null): boolean => {
    if (d?.toDate() < this.selectedStartDate) return false
    return true
  }

  onBodyDataGraphTypeChanged(selection: DropdownItem) {
    if (this.selectedBodyDataGraphTypes.includes(selection)) {
      this.selectedBodyDataGraphTypes.forEach((item, index) => {
        if (item == selection) this.selectedBodyDataGraphTypes.splice(index, 1);
      });
    } else {
      this.selectedBodyDataGraphTypes.push(selection)
    }
    this.saveSelectedBodyDataGraphTypesInPortalSettings()
    this.updateBodyStatisticsGraph(this.displayedUser)
  }

  onSelectedGraphItemChanged(item: SelectableItem) {
    if (item.isSelected) {
      if (!this.selectedBodyDataGraphTypes.includes(item.value)) this.selectedBodyDataGraphTypes.push(item.value)
    } else {
      this.selectedBodyDataGraphTypes.forEach((selectedItem, index) => {
        if (selectedItem == item.value) this.selectedBodyDataGraphTypes.splice(index, 1);
      })
    }
    this.saveSelectedBodyDataGraphTypesInPortalSettings()
    this.updateBodyStatisticsGraph(this.displayedUser)
  }

  saveSelectedBodyDataGraphTypesInPortalSettings() {
    var currentUser = this.userService.getLoggedInUser();
    if (currentUser?.isCoach) {
      // saves new selectedBodyDataGraphTypes
      this.selectedBodyDataGraphTypes.map(x => x.name).forEach(typeName => {
        if (!currentUser.portalSettingsCoach?.selectedBodyDataGraphTypNames?.includes(typeName)) {
          currentUser.portalSettingsCoach.selectedBodyDataGraphTypNames.push(typeName)
        }
      });
      currentUser.portalSettingsCoach?.selectedBodyDataGraphTypNames?.forEach((typeName, index) => {
        // removes all deselected bodyDataGraphTypes
        if (!this.selectedBodyDataGraphTypes.map(x => x.name).includes(typeName)) {
          if (this.additionalBodyDataGraphTypes.map(x => x.name).includes(typeName) || this.defaultBodyDataGraphTypes.map(x => x.name).includes(typeName)) {
            currentUser.portalSettingsCoach.selectedBodyDataGraphTypNames.splice(index, 1);
          }
        }
      });
      // currentUser.portalSettingsCoach.selectedBodyDataGraphTypNames = this.selectedBodyDataGraphTypes.map(x => x.name) //would simply store all SelectedBodyDataGraphTypes, but causes types that the current user has not selected to be removed as well
      this.userService.updatePortalSettingsForCoach(currentUser)
    }
  }

  loadPreviousSelectedBodyDataGraphTypes() {
    var currentUser = this.userService.getLoggedInUser();
    var previousSelectedBodyDataGraphTypNames = []
    if (currentUser.isCoach) {
      previousSelectedBodyDataGraphTypNames = currentUser.portalSettingsCoach.selectedBodyDataGraphTypNames
    }
    if (previousSelectedBodyDataGraphTypNames.length == 0) {
      // if(this.selectedBodyDataGraphTypes?.length == 0) this.selectedBodyDataGraphTypes.push(this.defaultBodyDataGraphTypes[0])
      return
    }
    this.selectedBodyDataGraphTypes = []
    this.additionalBodyDataGraphTypes.forEach(type => {
      if (previousSelectedBodyDataGraphTypNames.includes(type.name)) {
        if (!this.selectedBodyDataGraphTypes.map(x => x.name).includes(type.name)) {
          this.selectedBodyDataGraphTypes.push(type)
        }
      }
    });
    this.defaultBodyDataGraphTypes.forEach(type => {
      if (previousSelectedBodyDataGraphTypNames.includes(type.name)) {
        if (!this.selectedBodyDataGraphTypes.map(x => x.name).includes(type.name)) {
          this.selectedBodyDataGraphTypes.push(type)
        }
      }
    });
    if (this.selectedBodyDataGraphTypes.length == 0) {
      let bodyWeightType = this.additionalBodyDataGraphTypes.find(x => x.tmp == 'metricbodyWeight')
      if (bodyWeightType != null) {
        this.selectedBodyDataGraphTypes.push(bodyWeightType)
      }
    }

    this.graphItemSelectionGroups = [
      {
        name: null,
        items: this.defaultBodyDataGraphTypes.concat(this.additionalBodyDataGraphTypes).map(x => {
          return {
            value: x,
            name: x.name,
            description: null,
            isSelected: this.selectedBodyDataGraphTypes.includes(x),
          }
        })
      }
    ]
  }

  onBodyDataGraphTimeRangeChanged(selection: DropdownItem) {
    this.selectedBodyDataGraphTimeRange = selection;
    this.selectedBodyDataGraphTimeRangeChanged();
  }

  selectedBodyDataGraphTimeRangeChanged() {
    this.loadStatisticsDataForUser(this.displayedUser)
    this.updateBodyStatisticsGraph(this.displayedUser)
    this.updateVisibleImages(this.displayedUser)
  }

  // Logged-in User
  public user: User;
  // Displayed/Selected User
  public displayedUser: User;

  public currentDate = new Date();

  public isUser: boolean;

  public isCustomer = false;
  public navBarTriggered;

  private subscriptionBodyData: Subscription;

  allStatisticsGraphItems: any[] = []
  tableStatisticsGraphItems: any[] = []
  displayedStatisticsGraphItems: any[] = []
  progressStatisticsGraphData: any[] = []
  statisticsGraphAxis: any[] = []
  displayedMetricInGraph: Metric = null
  displayedMetricIdsInGraph: string[] = []
  progressStatisticsGraphStrips: any[] = []
  showDataTable: boolean = false
  canShowDataTable: boolean = false

  aggregateChartData: boolean = false

  protected argumentAxisVisualRange = [null, null];

  nutritionStatisticsGraphData: NutritionStatisticsItem[] = []
  weeklyReportCalorieIntake: number = 0
  weeklyReportCalorieGoal: number = 0
  weeklyReportAbsoluteDeviation: number = 0
  weeklyReportDeviationPercentage: number = 0

  onShowDataTableChanged(value: boolean) {
    this.showDataTable = value
  }

  onAggregateChartDataChanged(value: boolean) {
    this.aggregateChartData = value
  }

  customizeBodyDataGraphYAxisText = (arg: any) => {
    if (this.selectedBodyDataGraphType.id == 0) {
      return arg.valueText + " kg";
    } else if (this.selectedBodyDataGraphType.id == 1) {
      return arg.valueText + " cm";
    } else if (this.selectedBodyDataGraphType.id == 2) {
      return arg.valueText + "%";
    } else if (this.selectedBodyDataGraphType.id == 4) {
      var sleepDuration = parseInt(arg.valueText)
      return Math.floor(sleepDuration / 60) + ':' + ((sleepDuration % 60) < 10 ? '0' : '') + (sleepDuration % 60) + ' h';
    } else if (this.selectedBodyDataGraphType.id == 5) {
      return arg.valueText + " l";
    } else if (this.selectedBodyDataGraphType.id == 6 && this.displayedMetricInGraph != null) {
      if (this.displayedMetricInGraph.isMetricTypeNumber()) {
        return arg.valueText + " " + (this.displayedMetricInGraph.unit ?? '');
      } else if (this.displayedMetricInGraph.isMetricTypeSelection()) {
        var value = parseInt(arg.valueText)
        return this.displayedMetricInGraph.getSelectableValues()[value]
      } else if (this.displayedMetricInGraph.isMetricTypeYesNo()) {
        var value = parseInt(arg.valueText)
        return value == 0 ? this.translate.instant('Nein') : this.translate.instant('Ja')
      } else if (this.displayedMetricInGraph.isMetricTypeDuration()) {
        var duration = parseInt(arg.valueText)
        return Math.floor(duration / 60) + ':' + ((duration % 60) < 10 ? '0' : '') + (duration % 60) + ' h';
      } else {
        return arg.valueText
      }
    } else {
      return arg.valueText
    }
  }
  customizeBodyDataGraphLeftYAxis = (arg: any) => {
    return arg.valueText
  }
  customizeBodyDataGraphRightYAxis = (arg: any) => {
    return arg.valueText
  }
  customizedAxisUnits: string[] = []
  customizeBodyDataGraphTooltip = (arg: any) => {
    var name = arg.point?.series?.name;
    var text = arg.point?.data?.date.getPrintableWeekday(this.translate).substr(0, 2) + ', ' + arg.point?.data?.date.asFormatedString() + '\n'
    this.displayedStatisticsGraphItems.forEach(item => {
      if (arg.point?.data[item.valueField] != undefined && arg.point?.data[item.valueField] != null) {
        if (item.name == name) {
          text = text + "<b>" + item.name + ': ' + arg.point?.data[item.valueField + 'Rendered'] + ' ' + (item.unit || '') + "</b>" + '\n'
        } else {
          text = text + item.name + ': ' + arg.point?.data[item.valueField + 'Rendered'] + ' ' + (item.unit || '') + '\n'
        }
      } else if (arg.point?.data[item.valueFieldMin] != undefined && arg.point?.data[item.valueFieldMin] != null) {
        const min = arg.point?.data[item.valueFieldMin + 'Rendered'];
        const max = arg.point?.data[item.valueFieldMax + 'Rendered'];
        const shownValue = min === max ? min : min + '-' + max;
        if (item.name == name) {
          text = text + "<b>" + item.name + ': ' + shownValue + ' ' + (item.unit || '') + "</b>" + '\n'
        } else {
          text = text + item.name + ': ' + shownValue + ' ' + (item.unit || '') + '\n'
        }
      }
    })
    return {text: text}
  }

  getPrintableSleepDuration(sleepDuration: number) {
    return Math.floor(sleepDuration / 60) + ':' + ((sleepDuration % 60) < 10 ? '0' : '') + (sleepDuration % 60).toFixed(2)
  }

  customizeGroupSummaryTextAverage(e) {
    return "∅: " + Math.round((e.value + Number.EPSILON) * 100) / 100
  };

  customizeGroupSummaryTextSum(e) {
    return "Σ: " + Math.round((e.value + Number.EPSILON) * 100) / 100
  };

  progressGraphTickIntervall = undefined
  progressGraphVisualRange = [null, null]

  constructor(public nutritionService: NutritionService, public chatService: ChatService, private authService: AuthService, private router: Router, public userService: FirestoreService, private route: ActivatedRoute, public utilityService: UtilityService, private toastr: ToastrService, private spinner: NgxSpinnerService, private componentFactoryResolver: ComponentFactoryResolver, private dialog: MatDialog, public chartExportService: ChartExportService, public translate: TranslateService, private goalPlanService: GoalPlanService) {
    this.calculateCustomSummaryRow = this.calculateCustomSummaryRow.bind(this);
  }

  ngOnInit(): void {
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(NutritionalValuePopoverComponent);

    var bodyDataGraphColorPalette = {
      simpleSet: ['var(--accentColor)', '#4ACCE6', '#4A79E6', '#934AE6', '#E64ADC', '#E64A6E', '#E67D4A', '#E6BC4A', '#D6E64A', '#9CE64A', '#4AE693'],
      indicatingSet: ['var(--accentColor)', '#4AE6E6', '#4AD6E6'],
      gradientSet: ['var(--accentColor)', '#4AE6E6']
    };
    registerPalette('nutrilizePalette', bodyDataGraphColorPalette);

    if (this._selectedUserUid === undefined) {

      this.authService.getAuthState().subscribe(user => {
        if (user) {
          this.authService.setUser(user)
          this.userService.onLoggedIn(user.uid)
          this.userService.initUser(this.authService.user.uid)
          this._selectedUserUid = user.uid
          this.init();
        }
      });
    } else {
      this.init();
    }
    if (!this.authService.isLoggedIn()) {
      this.router.navigate['login'];
    }
  }

  private async init() {
    this.user = this.userService.getLoggedInUser();
    this.isUser = this.user.isUser;
    this.selectedBodyDataGraphTimeRange = this.bodyDataGraphTimeRanges[2]

    this.userService.getAccessibleClients().forEach(c => {
      if (c.uid == this._selectedUserUid) {
        this.displayedUser = c
      }
    });
    if (this.user.uid != this._selectedUserUid) {
      this.isCustomer = true;
    } else if (!this.displayedUser?.uid) {
      this.displayedUser = this.user
    }

    this.planMap = this.displayedUser.observableGoalPlans?.value ?? null
    if (this.planMap) this.updateBodyStatisticsGraph(this.displayedUser)

    this.displayedUser.observableGoalPlans.subscribe((planMap) => {
      if (planMap != null) {
        this.planMap = planMap;
        this.updateBodyStatisticsGraph(this.displayedUser);
      }
    });
    if (!this.displayedUser.loadingGoalPlans) {
      this.userService.loadLicenceSettingsForUser(this.displayedUser)
    }

    this.prepareDefaultDataGraphTypes(this.displayedUser)

    this.userService.loadAssignedMetricsForUser(this.displayedUser)
    if (this.defaultBodyDataGraphTypes.length > 0) {
      this.selectedBodyDataGraphTypes = [this.defaultBodyDataGraphTypes[0]]
    }

    if (!this.displayedUser.bodyDataMigrated && (!this.displayedUser.bodyData || this.displayedUser.bodyData.length == 0)) {
      this.displayedUser.currentlyLoadingBodyDataStatistics = true;
      this.loadBodyDatasForUser(this.displayedUser)
    } else {
      this.updateBodyStatisticsGraph(this.displayedUser)
    }
    if (!this.displayedUser.dailyConditions || this.displayedUser.dailyConditions.length == 0) {
      await this.loadDailyConditionsForUser(this.displayedUser)
    } else {
      this.prepareAdditionalDataGraphTypes(this.displayedUser)
      this.updateBodyStatisticsGraph(this.displayedUser)
      this.prepareImageMetricTypes(this.displayedUser)
    }

    this.userService.observableMetrics.subscribe(async metrics => {
      if ((!this.displayedUser.dailyConditions || this.displayedUser.dailyConditions.length == 0) && this.subscriptionDailyCondition == null) await this.loadDailyConditionsForUser(this.displayedUser)
    })
  }

  public nutritionalValueHolder: NutritionalValueHolder

  onNutritionalValuesFocused(nutritionalValueHolder: NutritionalValueHolder) {
    this.nutritionalValueHolder = nutritionalValueHolder
  }

  ngOnDestroy(): void {
    if (this.subscriptionBodyData) this.subscriptionBodyData.unsubscribe()
  }

  customAggregationFunction(aggregationInfo: any, series: any) {
    if (!aggregationInfo.data.length) {
      return;
    }
    const val = aggregationInfo.data.map((item) => item.bmi);
    const maxVal = Math.max.apply(null, val);
    const minVal = Math.min.apply(null, val);

    return {
      date: new Date((aggregationInfo.intervalStart.valueOf() + aggregationInfo.intervalEnd.valueOf()) / 2),
      maxVal,
      minVal,
    };
  };

  getSelectedBodyDataGraphTypesString() {
    if (this.selectedBodyDataGraphTypes.length == 0) {
      return this.translate.instant("Metrik auswählen")
    } else if (this.selectedBodyDataGraphTypes.length == 1) {
      return this.selectedBodyDataGraphTypes[0]?.name
    } else {
      var text = ""
      this.selectedBodyDataGraphTypes.forEach(type => {
        text += type?.name + ", "
      })
      text = text.substr(0, text.length - 2)
      return text
    }
  }

  loadBodyDatasForUser(user: User) {
    if (user.bodyDataMigrated) {
      return;
    }
    this.subscriptionBodyData = this.userService.getAllBodyDataForUser(user).subscribe(documents => {
      var bodyData = []
      var averageWeightMap = new Map()
      var averageWeightCountMap = new Map()
      documents.forEach(document => {
        var b = new BodyData(document as BodyData)
        b.date = new Date((document as any).date.seconds * 1000)
        if (b.bodyWeight && b.bodyWeight > 0 && user.bodyHeight) b.bmi = Math.round(b.bodyWeight / Math.pow(user.bodyHeight / 100, 2) * 100) / 100
        if (b.bodyWeight && b.bodyWeight > 0) {
          var averageWeight = averageWeightMap.get(b.date.getWeekNumber())
          var averageWeightCount = averageWeightCountMap.get(b.date.getWeekNumber())
          if (averageWeight) {
            averageWeight = averageWeight + b.bodyWeight
            averageWeightCount++
          } else {
            averageWeight = b.bodyWeight
            averageWeightCount = 1
          }
          averageWeightMap.set(b.date.getWeekNumber(), averageWeight)
          averageWeightCountMap.set(b.date.getWeekNumber(), averageWeightCount)
        }
        bodyData.push(b)
      })
      bodyData.sort((b1, b2) => b1.date.getTime() - b2.date.getTime())

      var nextBodyDataDate = null
      var newBodyDatas = []
      bodyData.forEach(b => {
        if (nextBodyDataDate == null) {
          nextBodyDataDate = b.date.clone().addDays(1)
        } else {
          if (b.date.isSameDate(nextBodyDataDate)) {
            nextBodyDataDate = b.date.clone().addDays(1)
          } else {
            for (var newDate = nextBodyDataDate; newDate.getTime() < b.date.getTime(); newDate = newDate.clone().addDays(1)) {
              var newBodyData = new BodyData()
              newBodyData.date = new Date(newDate.getTime())
              newBodyDatas.push(newBodyData)
            }
            nextBodyDataDate = b.date.clone().addDays(1)
          }
        }
      })
      if (newBodyData != null && nextBodyDataDate.getTime() < Date.now()) {
        for (var newDate = nextBodyDataDate; newDate.getTime() < Date.now(); newDate = newDate.clone().addDays(1)) {
          var newBodyData = new BodyData()
          newBodyData.date = new Date(newDate.getTime())
          newBodyDatas.push(newBodyData)
        }
      }
      bodyData = bodyData.concat(newBodyDatas).sort((b1, b2) => b1.date.getTime() - b2.date.getTime())
      bodyData.forEach(b => {
        var weekNumber = b.date.getWeekNumber()
        var averageWeight = averageWeightMap.get(weekNumber) / averageWeightCountMap.get(weekNumber)
        b.bodyWeightWeeklyAverage = Math.round(averageWeight * 100) / 100
      })
      user.bodyData = bodyData;
      this.updateBodyStatisticsGraph(user)
      user.currentlyLoadingBodyDataStatistics = false;
    })
  }

  prepareDefaultDataGraphTypes(user: User) {
    this.defaultBodyDataGraphTypes = []
    if (!user.bodyDataMigrated) {
      this.defaultBodyDataGraphTypes.push({
        id: BodyDataComponent.bodyWeightId,
        name: this.translate.instant("Gewicht"),
        tmp: '',
        unit: null,
        order: 1
      })
      this.defaultBodyDataGraphTypes.push({
        id: BodyDataComponent.circumferencesId,
        name: this.translate.instant("Umfänge"),
        tmp: '',
        unit: null,
        order: 2
      })
      this.defaultBodyDataGraphTypes.push({
        id: BodyDataComponent.bodyFatId,
        name: this.translate.instant("KFA"),
        tmp: '',
        unit: null,
        order: 3
      })
      this.defaultBodyDataGraphTypes.push({
        id: BodyDataComponent.bmiId,
        name: this.translate.instant("BMI"),
        tmp: '',
        unit: null,
        order: 4
      })
      this.defaultBodyDataGraphTypes.push({
        id: BodyDataComponent.waterId,
        name: this.translate.instant("Wasserzufuhr"),
        tmp: '',
        unit: null,
        order: 5
      })
    }
    this.selectedBodyDataGraphTypes = this.selectedBodyDataGraphTypes?.filter(x => this.defaultBodyDataGraphTypes.find(d => d.name == x.name) != null || this.additionalBodyDataGraphTypes.find(d => d.name == x.name) != null)
  }

  isMetricPossibleOnChart(metric: Metric) {
    return metric.isMetricTypeNumber() || metric.isMetricTypeDuration() || metric.isMetricTypeSelection() || metric.isMetricTypeYesNo() || metric.isMetricTypeToDo();
  }

  isMetricPossibleOnTable(metric: Metric) {
    return this.isMetricPossibleOnChart(metric) || metric.isMetricTypeText() || metric.isMetricTypeMultiselect();
  }

  async prepareAdditionalDataGraphTypes(user: User) {
    var handledMetricIds: string[] = []
    var additionalBodyDataGraphTypes = []
    this.additionalBodyDataGraphTypesForTable = []
    if (user.bodyDataMigrated) {
      additionalBodyDataGraphTypes.push({
        id: BodyDataComponent.circumferencesId,
        name: this.translate.instant("Umfänge"),
        tmp: '',
        unit: null,
        order: 3
      })
      additionalBodyDataGraphTypes.push({
        id: BodyDataComponent.bmiId,
        name: this.translate.instant("BMI"),
        tmp: '',
        unit: null,
        order: 4
      })
      additionalBodyDataGraphTypes.push({
        id: BodyDataComponent.waterId,
        name: this.translate.instant("Wasserzufuhr"),
        tmp: '',
        unit: null,
        order: 5
      })
    }
    for (let dailyCondition of user.dailyConditions) {
      for (let data of dailyCondition.getMetricDataListWithQuestionaires()) {
        if (!Metric.circumferenceMetricIds.includes(data.metricId) && !handledMetricIds.includes(data.metricId) && additionalBodyDataGraphTypes.filter(function (e) {
          return e.tmp === 'metric' + data.metricId;
        }).length == 0) {
          var metric = this.userService.getMetricByMetricId(data.metricId)
          if (!metric) metric = await this.userService.fetchMetricByMetricId(data.metricId)
          var order = 7
          if (metric?.metricId == 'bodyWeight' || metric?.metricId == 'NUT_bodyWeight') {
            order = 1
          } else if (metric?.metricId == 'bodyFat' || metric?.metricId == 'NUT_bodyFat') {
            order = 2
          }
          if (metric != null && (this.isMetricPossibleOnChart(metric))) {
            additionalBodyDataGraphTypes.push({
              id: 6,
              name: metric.nameTranslation?.GetValue(this.translate.currentLang) ?? metric.nameTranslation?.GetValue('de') ?? this.translate.instant('Metrik ') + data.metricId,
              tmp: 'metric' + data.metricId,
              unit: metric.unit,
              order: order
            })
          } else if (metric != null && this.isMetricPossibleOnTable(metric)) {
            this.additionalBodyDataGraphTypesForTable.push({
              valueField: 'metric' + data.metricId,
              name: metric.nameTranslation?.GetValue(this.translate.currentLang) ?? metric.nameTranslation?.GetValue('de') ?? this.translate.instant('Metrik ') + data.metricId + (metric.unit != null ? ' (' + metric.unit + ')' : ''),
              summaryType: ""
            })
          }

          handledMetricIds.push(data.metricId)
        }
      }
    }
    this.selectedBodyDataGraphTypes = this.selectedBodyDataGraphTypes.filter(x => this.defaultBodyDataGraphTypes.find(d => d.name == x.name) != null || this.additionalBodyDataGraphTypes.find(d => d.name == x.name) != null)
    this.additionalBodyDataGraphTypes = additionalBodyDataGraphTypes;
    this.additionalBodyDataGraphTypes.sort((a, b) => {
      if (a.order == b.order) {
        if (Metric.bodyDataMetricIds.includes(a.tmp.replaceAll('metric', ''))) {
          if (Metric.bodyDataMetricIds.includes(b.tmp.replaceAll('metric', ''))) {
            return a.name.localeCompare(b.name);
          } else {
            return -1;
          }
        } else if (Metric.bodyDataMetricIds.includes(b.tmp.replaceAll('metric', ''))) {
          return 1
        }
        return a.name.localeCompare(b.name);
      }
      return a.order - b.order
    });
    this.graphItemSelectionGroups = [
      {
        name: null,
        items: this.defaultBodyDataGraphTypes.concat(additionalBodyDataGraphTypes).map(x => {
          return {
            value: x,
            name: x.name,
            description: null,
            isSelected: this.selectedBodyDataGraphTypes.includes(x),
          }
        })
      }
    ]
    this.loadPreviousSelectedBodyDataGraphTypes()
  }

  private subscriptionDailyCondition: Subscription

  async loadDailyConditionsForUser(user: User) {
    if (this.subscriptionDailyCondition) {
      this.subscriptionDailyCondition.unsubscribe()
      this.subscriptionDailyCondition = null
    }
    this.subscriptionDailyCondition = this.userService.getDailyConditionsForUser(user).subscribe(async dailyConditions => {
      // var dailyConditions = []
      // documents.forEach(document => {
      //   var d = new DailyCondition(document as DailyCondition)
      //   dailyConditions.push(d)
      // })

      user.dailyConditions = dailyConditions.sort((a, b) => b.date.getTime() - a.date.getTime());

      this.prepareAdditionalDataGraphTypes(user)
      await this.loadStatisticsDataForUser(user)

      this.prepareImageMetricTypes(user)
    });
  }

  async loadStatisticsDataForUser(user: User) {

    // verhindert das Laden von weiteren Nutritiondaten.
    // if (user.statisticsUpdated.value) return


    var timeRange: number
    if (this.selectedBodyDataGraphTimeRange.id == 0) {
      timeRange = 31;
    } else if (this.selectedBodyDataGraphTimeRange.id == 1) {
      timeRange = 31;
    } else if (this.selectedBodyDataGraphTimeRange.id == 2) {
      timeRange = 90;
    } else if (this.selectedBodyDataGraphTimeRange.id == 3) {
      timeRange = 180;
    } else if (this.selectedBodyDataGraphTimeRange.id == 4) {
      timeRange = 365;
    } else if (this.selectedBodyDataGraphTimeRange.id == 5) {
      timeRange = this.getFullTimeRangeNumber(user);
    } else if (this.selectedBodyDataGraphTimeRange.id == 6) {
      if (this.selectedStartDate && this.selectedEndDate && this.selectedEndDate > this.selectedStartDate) {
        this.selectedEndDate.setHours(0, 0, 0, 0)
        this.selectedStartDate.setHours(0, 0, 0, 0)
        timeRange = (this.selectedEndDate.getTime() - this.selectedStartDate?.getTime()) / (1000 * 3600 * 24)
      } else {
        return;
      }
    }


    if (user.currentlyLoadingNutritionStatistics) return

    var startDate = new Date();
    var endDate = new Date()

    if (this.selectedBodyDataGraphTimeRange.id == 6) {
      startDate = this.selectedStartDate;
      endDate = this.selectedEndDate
    } else {
      startDate.setDate(startDate.getDate() - timeRange);
      this.selectedStartDate = startDate
      this.selectedEndDate = endDate
      if (timeRange <= user.nutritionStatistics.length) {
        return
      }
    }

    if (timeRange <= 180) {
      user.currentlyLoadingNutritionStatistics = true
      startDate.setHours(0, 0, 0, 0);

      var statistics = await this.userService.loadNutritionStatisticsForDateRange(user, startDate, endDate)

      user.nutritionStatistics = statistics
      user.statisticsUpdated.next(true)
      user.currentlyLoadingNutritionStatistics = false
      // this.updateNutritionStatisticsGraph(user)
      this.updateBodyStatisticsGraph(user)
    }

  }

  public progressStatisticsGroupCount = new Map()
  public selectedProgressStatisticsRowDates = []

  calculateCustomSummaryRow(options) {
    if (options.summaryProcess === "start") {
      options.totalValue = 0;
      this.progressStatisticsGroupCount.set(options.name, 0)
    } else if (options.summaryProcess === "calculate") {
      if (!this.selectedProgressStatisticsRowDates.includes(options.value["date"]) && options.value[options.name] != null) {
        options.totalValue = options.totalValue + options.value[options.name]
        this.progressStatisticsGroupCount.set(options.name, this.progressStatisticsGroupCount.get(options.name) + 1)
      }
    } else if (options.summaryProcess === "finalize") {
      var summaryType = 'avg'
      var item = this.allStatisticsGraphItems.filter(x => x.valueField == options.name.replace('Rendered', '')).shift()
      if (item) {
        summaryType = item.summaryType
      }
      if (summaryType == 'avg' && this.progressStatisticsGroupCount.get(options.name)) {
        options.totalValue = options.totalValue / this.progressStatisticsGroupCount.get(options.name)
      }
    }
  }

  onSelectionChanged(e) {
    e.currentSelectedRowKeys.forEach(element => {
      if (!this.selectedProgressStatisticsRowDates.includes(element["date"])) this.selectedProgressStatisticsRowDates.push(element["date"])
    });
    e.currentDeselectedRowKeys.forEach(element => {
      this.selectedProgressStatisticsRowDates.forEach((item, index) => {
        if (item == element["date"]) this.selectedProgressStatisticsRowDates.splice(index, 1);
      });
    });
    e.component.refresh(true);
  }


  private getFullTimeRangeNumber(user: User): number {
    let firstBodyDataDate = user.getFirstBodyDataDate() || new Date();
    let firstDailyConditionDate = user.getFirstDailyConditionDate() || new Date();
    var firstDate = firstBodyDataDate > firstDailyConditionDate ? firstDailyConditionDate : firstBodyDataDate;
    firstDate?.setHours(0, 0, 0, 0)
    var now = new Date();
    now.setHours(0, 0, 0, 0);
    return (now.getTime() - firstDate?.getTime()) / (1000 * 3600 * 24);
  }

  async updateBodyStatisticsGraph(user: User) {
    this.progressStatisticsGraphStrips = []
    var timeRange: number;

    if (this.selectedBodyDataGraphTimeRange.id == -1) {
      timeRange = 7;
    } else if (this.selectedBodyDataGraphTimeRange.id == 0) {
      timeRange = 14;
    } else if (this.selectedBodyDataGraphTimeRange.id == 1) {
      timeRange = 31;
    } else if (this.selectedBodyDataGraphTimeRange.id == 2) {
      timeRange = 90;
    } else if (this.selectedBodyDataGraphTimeRange.id == 3) {
      timeRange = 180;
    } else if (this.selectedBodyDataGraphTimeRange.id == 4) {
      timeRange = 365;
    } else if (this.selectedBodyDataGraphTimeRange.id == 5) {
      timeRange = this.getFullTimeRangeNumber(user);
    } else if (this.selectedBodyDataGraphTimeRange.id == 6) {
      if (this.selectedStartDate && this.selectedEndDate && this.selectedEndDate > this.selectedStartDate) {
        this.selectedEndDate.setHours(0, 0, 0, 0)
        this.selectedStartDate.setHours(0, 0, 0, 0)
        timeRange = (this.selectedEndDate.getTime() - this.selectedStartDate?.getTime()) / (1000 * 3600 * 24)
      } else {
        return;
      }
    }
    this.canShowDataTable = (timeRange <= 180 || !this.utilityService.onSmallDisplay())

    this.allStatisticsGraphItems = [
      {valueField: "bodyWeight", name: this.translate.instant(marker("Gewicht (kg)")), summaryType: "avg"},
      {valueField: "bodyWeightWeeklyAverage", name: this.translate.instant(marker("∅-Gewicht (kg)")), style: "avg"},
      {valueField: "calories", name: this.translate.instant(marker("Kalorien (kcal)")), summaryType: "avg"},
      {valueField: "carbohydrates", name: this.translate.instant(marker("Kohlenhydrate (g)")), summaryType: "avg"},
      {valueField: "protein", name: this.translate.instant(marker("Eiweiß (kcal)")), summaryType: "avg"},
      {valueField: "fat", name: this.translate.instant(marker("Fett (g)")), summaryType: "avg"},
      {valueField: "sugar", name: this.translate.instant(marker("Zucker (g)")), summaryType: "avg"},
      {valueField: "saturatedFat", name: this.translate.instant(marker("Gesättigte Fetts. (g)")), summaryType: "avg"},
      {valueField: "fibre", name: this.translate.instant(marker("Ballaststoffe (g)")), summaryType: "avg"},
      {valueField: "salt", name: this.translate.instant(marker("Salz (g)")), summaryType: "avg"},
      {
        valueField: "activitiesDuration",
        name: this.translate.instant(marker("Aktivitätsdauer (min)")),
        summaryType: "avg"
      },
      {
        valueField: "activitiesCalories",
        name: this.translate.instant(marker("Verbrannte Kalorien (kcal)")),
        summaryType: "sum"
      },
      {valueField: "hipCircumference", name: this.translate.instant(marker("Hüftumfang (cm)")), summaryType: "avg"},
      {
        valueField: "waistCircumference",
        name: this.translate.instant(marker("Taillenumfang (cm)")),
        summaryType: "avg"
      },
      {valueField: "chestCircumference", name: this.translate.instant(marker("Brustumfang (cm)")), summaryType: "avg"},
      {
        valueField: "leftArmCircumference",
        name: this.translate.instant(marker("Armumfang links (cm)")),
        summaryType: "avg"
      },
      {
        valueField: "rightArmCircumference",
        name: this.translate.instant(marker("Armumfang rechts (cm)")),
        summaryType: "avg"
      },
      {
        valueField: "leftThighCircumference",
        name: this.translate.instant(marker("Beinumfang links (cm)")),
        summaryType: "avg"
      },
      {
        valueField: "rightThighCircumference",
        name: this.translate.instant(marker("Beinumfang rechts (cm)")),
        summaryType: "avg"
      },
      {
        valueField: "leftCalfCircumference",
        name: this.translate.instant(marker("Wadenumfang links (cm)")),
        summaryType: "avg"
      },
      {
        valueField: "rightCalfCircumference",
        name: this.translate.instant(marker("Wadenumfang rechts (cm)")),
        summaryType: "avg"
      },
      {valueField: "bellyCircumference", name: this.translate.instant(marker("Bauchumfang (cm)")), summaryType: "avg"},
      {valueField: "seatCircumference", name: this.translate.instant(marker("Gesäßumfang (cm)")), summaryType: "avg"},
      {valueField: "bodyFatPercentage", name: this.translate.instant(marker("KFA (%)")), summaryType: "avg"},
      {valueField: "bmi", name: this.translate.instant(marker("BMI")), summaryType: "avg"},
      {valueField: "sleepDuration", name: this.translate.instant(marker("Schlaf (h)")), summaryType: "avg"},
      {valueField: "waterIntake", name: this.translate.instant(marker("Wasserzufuhr (l)")), summaryType: "avg"}
    ]
    this.additionalBodyDataGraphTypes.forEach(item => {
      if (item.tmp == 'metricbodyWeight') {
        this.allStatisticsGraphItems = [{
          valueField: item.tmp,
          name: item.name + (item.unit != null ? ' (' + item.unit + ')' : ''),
          summaryType: "avg"
        }].concat(this.allStatisticsGraphItems)
      } else {
        this.allStatisticsGraphItems.push({
          valueField: item.tmp,
          name: item.name + (item.unit != null ? ' (' + item.unit + ')' : ''),
          summaryType: "avg"
        })
      }
    })

    var startDate = new Date();
    if (this.selectedBodyDataGraphTimeRange.id == 6) {
      startDate = this.selectedStartDate;
      // endDate = this.selectedEndDate
    } else {
      startDate.setDate(startDate.getDate() - timeRange);
      this.selectedStartDate = startDate
      this.selectedEndDate = new Date()
    }

    startDate.setHours(0, 0, 0, 0);

    var dailyCondition: DailyCondition;

    var statisticsItems: any[];
    var statisticsItem: any;
    statisticsItems = []
    this.displayedMetricInGraph = null

    this.displayedStatisticsGraphItems = []
    this.displayedMetricIdsInGraph = []
    this.statisticsGraphAxis = []

    this.customizeBodyDataGraphLeftYAxis = (arg: any) => {
      return arg.valueText;
    }
    this.customizeBodyDataGraphRightYAxis = (arg: any) => {
      return arg.valueText;
    }

    let uniqueMetrics: string[] = [];
    if (this.displayMetricGoals && this.planMap && this.planMap.size > 0) {
      uniqueMetrics = Array.from(this.planMap.values()).filter((goalPlan, index, array) => {
        const item = array.find(it => it.metric.metricId === goalPlan.metric.metricId);
        return array.indexOf(item) === index;
      }).map(plan => plan.metric.metricId);
    }

    var selectedTodoMetricIds: string[] = []

    var typeCount = 0
    for (let selectedBodyDataGraphType of this.selectedBodyDataGraphTypes) {

      if (selectedBodyDataGraphType.id >= 0 && selectedBodyDataGraphType.id <= 3) {
        if (selectedBodyDataGraphType.id == BodyDataComponent.bodyWeightId) {
          // this.displayedStatisticsGraphItems.push({valueField: "bodyWeight", name: "Gewicht", axis: "weightAxis", unit: "kg"})

          this.displayedStatisticsGraphItems.push({
            valueField: "bodyWeight",
            name: this.translate.instant("Gewicht"),
            axis: "weightAxis",
            unit: "kg"
          }, {
            valueField: "bodyWeightWeeklyAverage",
            name: this.translate.instant("∅-Gewicht"),
            axis: "weightAxis",
            unit: "kg",
            style: "average"
          })
          this.statisticsGraphAxis.push({name: "weightAxis", visualRange: [null, null]})
          if (typeCount == 0) {
            this.customizeBodyDataGraphLeftYAxis = (arg: any) => {
              return arg.valueText + " kg";
            }
          } else if (typeCount == 1) {
            this.customizeBodyDataGraphRightYAxis = (arg: any) => {
              return arg.valueText + " kg";
            }
          }
          if (this.displayMetricGoals) {
            const metricId = uniqueMetrics.find(metricId => metricId === 'bodyWeight');
            if (metricId) {
              this.displayedMetricIdsInGraph.push("bodyWeight");
              const progressPlans = this.displayedUser.getGoalPlansByMetricId(metricId, GoalPlanType.PROGRESS)
              const weeklyPlan = this.displayedUser.getGoalPlanByMetricId(metricId, GoalPlanType.WEEKLY);
              const dailyPlan = this.displayedUser.getGoalPlanByMetricId(metricId, GoalPlanType.DAILY);
              progressPlans.forEach(progressPlan => {
                if(progressPlan && progressPlan.isActiveBetween(this.selectedStartDate, this.selectedEndDate)) {
                  this.displayedStatisticsGraphItems.push({
                    valueField: "goal_" + metricId + "_" + progressPlan.id,
                    valueFieldMin: "goal_" + metricId + "_" + progressPlan.id + "_min",
                    valueFieldMax: "goal_" + metricId + "_" + progressPlan.id + "_max",
                    name: progressPlan.name,
                    axis: "weightAxis",
                    unit: "kg",
                    style: "goal"
                  });
                }
              })
              if(weeklyPlan && weeklyPlan.isActiveBetween(this.selectedStartDate, this.selectedEndDate)) {
                this.displayedStatisticsGraphItems.push({
                  valueField: "goal_" + metricId + "_" + weeklyPlan.id,
                  valueFieldMin: "goal_" + metricId + "_" + weeklyPlan.id + "_min",
                  valueFieldMax: "goal_" + metricId + "_" + weeklyPlan.id + "_max",
                  name: weeklyPlan.name,
                  axis: "weightAxis",
                  unit: "kg",
                  style: "goal"
                });
              }
              if(dailyPlan && dailyPlan.isActiveBetween(this.selectedStartDate, this.selectedEndDate)) {
                this.displayedStatisticsGraphItems.push({
                  valueField: "goal_" + metricId + "_" + dailyPlan.id,
                  valueFieldMin: "goal_" + metricId + "_" + dailyPlan.id + "_min",
                  valueFieldMax: "goal_" + metricId + "_" + dailyPlan.id + "_max",
                  name: dailyPlan.name,
                  axis: "weightAxis",
                  unit: "kg",
                  style: "goal"
                });
              }
            }
          }
        } else if (selectedBodyDataGraphType.id == BodyDataComponent.circumferencesId) {

          if (!user.bodyDataMigrated) {
            this.displayedStatisticsGraphItems.push(
              {
                valueField: "hipCircumference",
                name: this.translate.instant("Hüftumfang"),
                axis: "circumferenceAxis",
                unit: "cm"
              },
              {
                valueField: "waistCircumference",
                name: this.translate.instant("Taillenumfang"),
                axis: "circumferenceAxis",
                unit: "cm"
              },
              {
                valueField: "chestCircumference",
                name: this.translate.instant("Brustumfang"),
                axis: "circumferenceAxis",
                unit: "cm"
              },
              {
                valueField: "leftArmCircumference",
                name: this.translate.instant("Armumfang links"),
                axis: "circumferenceAxis",
                unit: "cm"
              },
              {
                valueField: "rightArmCircumference",
                name: this.translate.instant("Armumfang rechts"),
                axis: "circumferenceAxis",
                unit: "cm"
              },
              {
                valueField: "leftThighCircumference",
                name: this.translate.instant("Beinumfang links"),
                axis: "circumferenceAxis",
                unit: "cm"
              },
              {
                valueField: "rightThighCircumference",
                name: this.translate.instant("Beinumfang rechts"),
                axis: "circumferenceAxis",
                unit: "cm"
              },
              {
                valueField: "leftCalfCircumference",
                name: this.translate.instant("Wadenumfang links"),
                axis: "circumferenceAxis",
                unit: "cm"
              },
              {
                valueField: "rightCalfCircumference",
                name: this.translate.instant("Wadenumfang rechts"),
                axis: "circumferenceAxis",
                unit: "cm"
              },
              {
                valueField: "bellyCircumference",
                name: this.translate.instant("Bauchumfang"),
                axis: "circumferenceAxis",
                unit: "cm"
              },
              {
                valueField: "seatCircumference",
                name: this.translate.instant("Gesäßumfang"),
                axis: "circumferenceAxis",
                unit: "cm"
              }
            );
          } else {
            Metric.circumferenceMetricIds.forEach(circumferenceMetricId => {
              this.displayedStatisticsGraphItems.push({
                valueField: 'metric' + circumferenceMetricId,
                name: Metric.getCircumferenceMetricIdAsName(circumferenceMetricId, this.translate),
                axis: "circumferenceAxis",
                unit: "cm"
              })
            });
          }

          this.statisticsGraphAxis.push({name: "circumferenceAxis", visualRange: [null, null]})
          if (typeCount == 0) {
            this.customizeBodyDataGraphLeftYAxis = (arg: any) => {
              return arg.valueText + " cm";
            }
          } else if (typeCount == 1) {
            this.customizeBodyDataGraphRightYAxis = (arg: any) => {
              return arg.valueText + " cm";
            }
          }
          if (this.displayMetricGoals) {
            Metric.circumferenceMetricIds.forEach(circumferenceMetricId => {
              const metricId = uniqueMetrics.find(metricId => metricId === circumferenceMetricId);
              if (metricId) {
                this.displayedMetricIdsInGraph.push(circumferenceMetricId);
                const progressPlan = this.displayedUser.getGoalPlanByMetricId(metricId, GoalPlanType.PROGRESS);
                const weeklyPlan = this.displayedUser.getGoalPlanByMetricId(metricId, GoalPlanType.WEEKLY);
                const dailyPlan = this.displayedUser.getGoalPlanByMetricId(metricId, GoalPlanType.DAILY);
                if(progressPlan && progressPlan.isActiveBetween(this.selectedStartDate, this.selectedEndDate)) {
                  this.displayedStatisticsGraphItems.push({
                    valueField: "goal_" + metricId + "_" + progressPlan.id,
                    valueFieldMin: "goal_" + metricId + "_" + progressPlan.id + "_min",
                    valueFieldMax: "goal_" + metricId + "_" + progressPlan.id + "_max",
                    name: progressPlan.name,
                    axis: "circumferenceAxis",
                    unit: "cm",
                    style: "goal"
                  });
                }
                if(weeklyPlan && weeklyPlan.isActiveBetween(this.selectedStartDate, this.selectedEndDate)) {
                  this.displayedStatisticsGraphItems.push({
                    valueField: "goal_" + metricId + "_" + weeklyPlan.id,
                    valueFieldMin: "goal_" + metricId + "_" + weeklyPlan.id + "_min",
                    valueFieldMax: "goal_" + metricId + "_" + weeklyPlan.id + "_max",
                    name: weeklyPlan.name,
                    axis: "circumferenceAxis",
                    unit: "cm",
                    style: "goal"
                  });
                }
                if(dailyPlan && dailyPlan.isActiveBetween(this.selectedStartDate, this.selectedEndDate)) {
                  this.displayedStatisticsGraphItems.push({
                    valueField: "goal_" + metricId + "_" + dailyPlan.id,
                    valueFieldMin: "goal_" + metricId + "_" + dailyPlan.id + "_min",
                    valueFieldMax: "goal_" + metricId + "_" + dailyPlan.id + "_max",
                    name: dailyPlan.name,
                    axis: "circumferenceAxis",
                    unit: "cm",
                    style: "goal"
                  });
                }
              }
            });
          }
        } else if (selectedBodyDataGraphType.id == BodyDataComponent.bodyFatId) {

          this.displayedStatisticsGraphItems.push({
            valueField: "bodyFatPercentage",
            name: "KFA",
            unit: "%",
            axis: "percentageAxis"
          })
          this.statisticsGraphAxis.push({name: "percentageAxis", visualRange: [null, null]})
          if (typeCount == 0) {
            this.customizeBodyDataGraphLeftYAxis = (arg: any) => {
              return arg.valueText + " %";
            }
          } else if (typeCount == 1) {
            this.customizeBodyDataGraphRightYAxis = (arg: any) => {
              return arg.valueText + " %";
            }
          }
          if (this.displayMetricGoals) {
            const metricId = uniqueMetrics.find(metricId => metricId === 'bodyFatPercentage');
            if (metricId) {
              this.displayedMetricIdsInGraph.push("bodyFatPercentage");
              const progressPlan = this.displayedUser.getGoalPlanByMetricId(metricId, GoalPlanType.PROGRESS);
              const weeklyPlan = this.displayedUser.getGoalPlanByMetricId(metricId, GoalPlanType.WEEKLY);
              const dailyPlan = this.displayedUser.getGoalPlanByMetricId(metricId, GoalPlanType.DAILY);
              if(progressPlan && progressPlan.isActiveBetween(this.selectedStartDate, this.selectedEndDate)) {
                this.displayedStatisticsGraphItems.push({
                  valueField: "goal_" + metricId + "_" + progressPlan.id,
                  valueFieldMin: "goal_" + metricId + "_" + progressPlan.id + "_min",
                  valueFieldMax: "goal_" + metricId + "_" + progressPlan.id + "_max",
                  name: progressPlan.name,
                  axis: "percentageAxis",
                  unit: "%",
                  style: "goal"
                });
              }
              if(weeklyPlan && weeklyPlan.isActiveBetween(this.selectedStartDate, this.selectedEndDate)) {
                this.displayedStatisticsGraphItems.push({
                  valueField: "goal_" + metricId + "_" + weeklyPlan.id,
                  valueFieldMin: "goal_" + metricId + "_" + weeklyPlan.id + "_min",
                  valueFieldMax: "goal_" + metricId + "_" + weeklyPlan.id + "_max",
                  name: weeklyPlan.name,
                  axis: "percentageAxis",
                  unit: "%",
                  style: "goal"
                });
              }
              if(dailyPlan && dailyPlan.isActiveBetween(this.selectedStartDate, this.selectedEndDate)) {
                this.displayedStatisticsGraphItems.push({
                  valueField: "goal_" + metricId + "_" + dailyPlan.id,
                  valueFieldMin: "goal_" + metricId + "_" + dailyPlan.id + "_min",
                  valueFieldMax: "goal_" + metricId + "_" + dailyPlan.id + "_max",
                  name: dailyPlan.name,
                  axis: "percentageAxis",
                  unit: "%",
                  style: "goal"
                });
              }
            }
          }
        } else if (selectedBodyDataGraphType.id == BodyDataComponent.bmiId) {
          this.displayedStatisticsGraphItems.push({
            valueField: "bmi",
            name: this.translate.instant("BMI"),
            axis: "bmiAxis"
          })
          this.statisticsGraphAxis.push({name: "bmiAxis", visualRange: [null, null]})
          if (this.displayMetricGoals) {
            const metricId = uniqueMetrics.find(metricId => metricId === 'bmi');
            if (metricId) {
              const progressPlan = this.displayedUser.getGoalPlanByMetricId(metricId, GoalPlanType.PROGRESS);
              const weeklyPlan = this.displayedUser.getGoalPlanByMetricId(metricId, GoalPlanType.WEEKLY);
              const dailyPlan = this.displayedUser.getGoalPlanByMetricId(metricId, GoalPlanType.DAILY);
              if(progressPlan && progressPlan.isActiveBetween(this.selectedStartDate, this.selectedEndDate)) {
                this.displayedStatisticsGraphItems.push({
                  valueField: "goal_" + metricId + "_" + progressPlan.id,
                  valueFieldMin: "goal_" + metricId + "_" + progressPlan.id + "_min",
                  valueFieldMax: "goal_" + metricId + "_" + progressPlan.id + "_max",
                  name: progressPlan.name,
                  axis: "bmiAxis",
                  style: "goal"
                });
              }
              if(weeklyPlan && weeklyPlan.isActiveBetween(this.selectedStartDate, this.selectedEndDate)) {
                this.displayedStatisticsGraphItems.push({
                  valueField: "goal_" + metricId + "_" + weeklyPlan.id,
                  valueFieldMin: "goal_" + metricId + "_" + weeklyPlan.id + "_min",
                  valueFieldMax: "goal_" + metricId + "_" + weeklyPlan.id + "_max",
                  name: weeklyPlan.name,
                  axis: "bmiAxis",
                  style: "goal"
                });
              }
              if(dailyPlan && dailyPlan.isActiveBetween(this.selectedStartDate, this.selectedEndDate)) {
                this.displayedStatisticsGraphItems.push({
                  valueField: "goal_" + metricId + "_" + dailyPlan.id,
                  valueFieldMin: "goal_" + metricId + "_" + dailyPlan.id + "_min",
                  valueFieldMax: "goal_" + metricId + "_" + dailyPlan.id + "_max",
                  name: dailyPlan.name,
                  axis: "bmiAxis",
                  style: "goal"
                });
              }
            }
          }
        }
      } else {
        if (selectedBodyDataGraphType.id == BodyDataComponent.waterId) {
          this.displayedStatisticsGraphItems.push({
            valueField: "waterIntake",
            name: this.translate.instant("Wasserzufuhr"),
            unit: "l",
            axis: "waterIntakeAxis"
          })
          this.statisticsGraphAxis.push({name: "waterIntakeAxis", visualRange: [0, null]})
          if (typeCount == 0) {
            this.customizeBodyDataGraphLeftYAxis = (arg: any) => {
              return arg.valueText + " l";
            }
          } else if (typeCount == 1) {
            this.customizeBodyDataGraphRightYAxis = (arg: any) => {
              return arg.valueText + " l";
            }
          }
          if (this.displayMetricGoals) {
            const metricId = uniqueMetrics.find(metricId => metricId === 'waterIntake');
            if (metricId) {
              this.displayedMetricIdsInGraph.push("waterIntake");
              const progressPlan = this.displayedUser.getGoalPlanByMetricId(metricId, GoalPlanType.PROGRESS);
              const weeklyPlan = this.displayedUser.getGoalPlanByMetricId(metricId, GoalPlanType.WEEKLY);
              const dailyPlan = this.displayedUser.getGoalPlanByMetricId(metricId, GoalPlanType.DAILY);
              if(progressPlan && progressPlan.isActiveBetween(this.selectedStartDate, this.selectedEndDate)) {
                this.displayedStatisticsGraphItems.push({
                  valueField: "goal_" + metricId + "_" + progressPlan.id,
                  valueFieldMin: "goal_" + metricId + "_" + progressPlan.id + "_min",
                  valueFieldMax: "goal_" + metricId + "_" + progressPlan.id + "_max",
                  name: progressPlan.name,
                  axis: "waterIntakeAxis",
                  unit: "l",
                  style: "goal"
                });
              }
              if(weeklyPlan && weeklyPlan.isActiveBetween(this.selectedStartDate, this.selectedEndDate)) {
                this.displayedStatisticsGraphItems.push({
                  valueField: "goal_" + metricId + "_" + weeklyPlan.id,
                  valueFieldMin: "goal_" + metricId + "_" + weeklyPlan.id + "_min",
                  valueFieldMax: "goal_" + metricId + "_" + weeklyPlan.id + "_max",
                  name: weeklyPlan.name,
                  axis: "waterIntakeAxis",
                  unit: "l",
                  style: "goal"
                });
              }
              if(dailyPlan && dailyPlan.isActiveBetween(this.selectedStartDate, this.selectedEndDate)) {
                this.displayedStatisticsGraphItems.push({
                  valueField: "goal_" + metricId + "_" + dailyPlan.id,
                  valueFieldMin: "goal_" + metricId + "_" + dailyPlan.id + "_min",
                  valueFieldMax: "goal_" + metricId + "_" + dailyPlan.id + "_max",
                  name: dailyPlan.name,
                  axis: "waterIntakeAxis",
                  unit: "l",
                  style: "goal"
                });
              }
            }
          }
        } else {
          var metric = this.userService.getMetricByMetricId(selectedBodyDataGraphType.tmp.replace('metric', ''))
          if (!metric) metric = await this.userService.fetchMetricByMetricId(selectedBodyDataGraphType.tmp.replace('metric', ''))
          if (metric.metricId == 'bodyWeight' || metric.metricId == 'NUT_bodyWeight') {
            this.displayedStatisticsGraphItems.push({
              valueField: "bodyWeightWeeklyAverage",
              name: this.translate.instant("∅-Körpergewicht"),
              axis: "kgAxis",
              unit: "kg",
              style: "average"
            })
          }
          var axisName = (metric.unit ? metric.unit : metric.id) + "Axis"
          var existingAxis = this.statisticsGraphAxis.filter(x => x.name == axisName).shift() ?? null
          this.displayedStatisticsGraphItems.push({
            valueField: selectedBodyDataGraphType.tmp,
            name: selectedBodyDataGraphType.name,
            unit: metric.unit,
            axis: axisName
          })
          if (this.displayMetricGoals) {
            const metricId = uniqueMetrics.find(metricId => metricId === metric.metricId);
            if (metricId) {
              this.displayedMetricIdsInGraph.push(metric.metricId);
              const progressPlans = this.displayedUser.getGoalPlansByMetricId(metricId, GoalPlanType.PROGRESS);
              const weeklyPlan = this.displayedUser.getGoalPlanByMetricId(metricId, GoalPlanType.WEEKLY);
              const dailyPlan = this.displayedUser.getGoalPlanByMetricId(metricId, GoalPlanType.DAILY);
              progressPlans.forEach(progressPlan => {
                if (progressPlan && progressPlan.isActiveBetween(this.selectedStartDate, this.selectedEndDate)) {
                  this.displayedStatisticsGraphItems.push({
                    valueField: "goal_" + metricId + "_" + progressPlan.id,
                    valueFieldMin: "goal_" + metricId + "_" + progressPlan.id + "_min",
                    valueFieldMax: "goal_" + metricId + "_" + progressPlan.id + "_max",
                    name: progressPlan.name,
                    axis: axisName,
                    unit: metric.unit,
                    style: "goal"
                  });
                }
              })
              if(weeklyPlan && weeklyPlan.isActiveBetween(this.selectedStartDate, this.selectedEndDate)) {
                this.displayedStatisticsGraphItems.push({
                  valueField: "goal_" + metricId + "_" + weeklyPlan.id,
                  valueFieldMin: "goal_" + metricId + "_" + weeklyPlan.id + "_min",
                  valueFieldMax: "goal_" + metricId + "_" + weeklyPlan.id + "_max",
                  name: weeklyPlan.name,
                  axis: axisName,
                  unit: metric.unit,
                  style: "goal"
                });
              }
              if(dailyPlan && dailyPlan.isActiveBetween(this.selectedStartDate, this.selectedEndDate)) {
                this.displayedStatisticsGraphItems.push({
                  valueField: "goal_" + metricId + "_" + dailyPlan.id,
                  valueFieldMin: "goal_" + metricId + "_" + dailyPlan.id + "_min",
                  valueFieldMax: "goal_" + metricId + "_" + dailyPlan.id + "_max",
                  name: dailyPlan.name,
                  axis: axisName,
                  unit: metric.unit,
                  style: "goal"
                });
              }
            }
          }

          var axisRange = [null, null]
          var tickInterval = undefined
          if (metric?.isMetricTypeToDo()) {
            if (!selectedTodoMetricIds.includes(metric.metricId)) {
              selectedTodoMetricIds.push(metric.metricId)
            }
            axisRange = [0, 1]
            tickInterval = 1
            if (typeCount == 0) {
              this.customizeBodyDataGraphLeftYAxis = (arg: any) => {
                var value = parseInt(arg.valueText)
                return value == 0 ? this.translate.instant('Nicht erledigt') : this.translate.instant('Erledigt')
              }
            } else if (typeCount == 1) {
              this.customizeBodyDataGraphRightYAxis = (arg: any) => {
                var value = parseInt(arg.valueText)
                return value == 0 ? this.translate.instant('Nicht erledigt') : this.translate.instant('Erledigt')
              }
            }
          } else if (metric?.isMetricTypeYesNo()) {
            axisRange = [0, 1]
            tickInterval = 1
            if (typeCount == 0) {
              this.customizeBodyDataGraphLeftYAxis = (arg: any) => {
                var value = parseInt(arg.valueText)
                return value == 0 ? this.translate.instant('Nein') : this.translate.instant('Ja')
              }
            } else if (typeCount == 1) {
              this.customizeBodyDataGraphRightYAxis = (arg: any) => {
                var value = parseInt(arg.valueText)
                return value == 0 ? this.translate.instant('Nein') : this.translate.instant('Ja')
              }
            }
          } else if (metric?.isMetricTypeSelection()) {
            axisRange = [0, metric.getSelectableValues().length - 1]
            tickInterval = 1
            if (typeCount == 0) {
              this.customizeBodyDataGraphLeftYAxis = (arg: any) => {
                var value = parseInt(arg.valueText)
                return metric.getSelectableValues()[value]
              }
            } else if (typeCount == 1) {
              this.customizeBodyDataGraphRightYAxis = (arg: any) => {
                var value = parseInt(arg.valueText)
                return metric.getSelectableValues()[value]
              }
            }
          } else if (metric?.isMetricTypeMultiselect()) {
            axisRange = [0, metric.getSelectableValues().length - 1]
            tickInterval = 1
            if (typeCount == 0) {
              this.customizeBodyDataGraphLeftYAxis = (arg: any) => {
                var value = parseInt(arg.valueText)
                return metric.getSelectableValues()[value]
              }
            } else if (typeCount == 1) {
              this.customizeBodyDataGraphRightYAxis = (arg: any) => {
                var value = parseInt(arg.valueText)
                return metric.getSelectableValues()[value]
              }
            }
          } else if (metric?.isMetricTypeNumber()) {
            axisRange = [metric.minValue != null ? metric.minValue : null, metric.maxValue != null ? metric.maxValue : null]
            var unit = metric.unit ?? ''
            if (existingAxis) {

            } else {
              if (typeCount == 0) {
                this.customizedAxisUnits[0] = unit
                this.customizeBodyDataGraphLeftYAxis = (arg: any) => {
                  return arg.valueText + " " + this.customizedAxisUnits[0];
                }
              } else if (typeCount == 1) {
                this.customizedAxisUnits[1] = unit
                this.customizeBodyDataGraphRightYAxis = (arg: any) => {
                  return arg.valueText + " " + this.customizedAxisUnits[1];
                }
              }
            }
          } else if (metric?.isMetricTypeDuration()) {
            if (typeCount == 0) {
              this.customizeBodyDataGraphLeftYAxis = (arg: any) => {
                var duration = parseInt(arg.valueText)
                return Math.floor(duration / 60) + ':' + ((duration % 60) < 10 ? '0' : '') + (duration % 60) + ' h';
              }
            } else if (typeCount == 1) {
              this.customizeBodyDataGraphRightYAxis = (arg: any) => {
                var duration = parseInt(arg.valueText)
                return Math.floor(duration / 60) + ':' + ((duration % 60) < 10 ? '0' : '') + (duration % 60) + ' h';
              }
            }
          }
          if (!existingAxis) {
            this.statisticsGraphAxis.push({
              name: axisName,
              visualRange: axisRange,
              tickInterval: tickInterval,
              unit: metric.unit
            })
          }
        }
      }
      typeCount++
    }

    if (this.displayMetricGoals && this.planMap && this.planMap.size > 0) {
      this.planMap.forEach((goalPlan) => {
        const metricId = goalPlan.metric.metricId;
        const earlyGoals = goalPlan.goalSteps.filter(goalStep => {
          return goalStep.date < startDate;
        });

        earlyGoals.forEach(goalStep => {
          const date = goalStep.date;
          let statisticsItem = statisticsItems.find(item => item.date === date);
          if(!statisticsItem) {
            statisticsItem = {
              date: date,
              dateRendered: date.asShortFormatedString(),
              weekNumber: date.getYearOfWeekOfYearNumber() + '/' + (date.getWeekOfYearNumber() < 10 ? '0' : '') + date.getWeekOfYearNumber()
            }
          }
          statisticsItem["goal_" + metricId + "_" + goalPlan.id] = goalStep.value;
          statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_min"] = goalStep.value ?? goalStep.minValue;
          statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_max"] = goalStep.value ?? goalStep.maxValue;
          if(goalPlan.metric.isMetricTypeDuration()) {
            const valRendered = moment.utc(moment.duration(statisticsItem["goal_" + metricId + "_" + goalPlan.id], "minutes").as('milliseconds')).format('HH:mm');
            const minRendered = moment.utc(moment.duration(statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_min"], "minutes").as('milliseconds')).format('HH:mm');
            const maxRendered = moment.utc(moment.duration(statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_max"], "minutes").as('milliseconds')).format('HH:mm');
            statisticsItem["goal_" + metricId + "_" + goalPlan.id + "Rendered"] = valRendered;
            statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_minRendered"] = minRendered;
            statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_maxRendered"] = maxRendered;
          } else {
            statisticsItem["goal_" + metricId + "_" + goalPlan.id + "Rendered"] = statisticsItem["goal_" + metricId + "_" + goalPlan.id];
            statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_minRendered"] = statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_min"];
            statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_maxRendered"] = statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_max"];
          }
          statisticsItem["planId"] = goalPlan.id;

          statisticsItems.push(statisticsItem);
        });
      });
    }

    this.tableStatisticsGraphItems = []
    var dataForValueFieldAvailable = new Map()
    dataForValueFieldAvailable.set("waterIntake", true)

    var averageWeightMap = new Map()
    var averageWeightCountMap = new Map()

    for (var i = 0; i <= timeRange; i++) {
      var date = new Date(startDate.getTime())
      date.setDate(date.getDate() + i)
      statisticsItem = {
        date: date,
        dateRendered: date.asShortFormatedString(),
        weekNumber: date.getYearOfWeekOfYearNumber() + '/' + (date.getWeekOfYearNumber() < 10 ? '0' : '') + date.getWeekOfYearNumber()
      }

      if (selectedTodoMetricIds.length > 0) {
        selectedTodoMetricIds.forEach(metricId => {
          statisticsItem["metric" + metricId] = 0;
          statisticsItem["metric" + metricId + "Rendered"] = this.translate.instant("Nicht erledigt");
        });
      }

      if (!user.bodyDataMigrated) {
        var b = user.getBodyDataItemForDate(date)
        if (b && !b.migrated) {
          if (b.bodyWeight != null) {
            dataForValueFieldAvailable.set("bodyWeight", true)
          }

          statisticsItem["bodyWeight"] = b.bodyWeight
          statisticsItem["bodyWeightRendered"] = statisticsItem["bodyWeight"]
          statisticsItem["bodyWeightWeeklyAverage"] = b.bodyWeightWeeklyAverage
          statisticsItem["bodyWeightWeeklyAverageRendered"] = statisticsItem["bodyWeightWeeklyAverage"]

          statisticsItem["hipCircumference"] = b.hipCircumference
          statisticsItem["hipCircumferenceRendered"] = statisticsItem["hipCircumference"]
          if (b.hipCircumference != null) dataForValueFieldAvailable.set("hipCircumference", true)
          statisticsItem["waistCircumference"] = b.waistCircumference
          statisticsItem["waistCircumferenceRendered"] = statisticsItem["waistCircumference"]
          if (b.waistCircumference != null) dataForValueFieldAvailable.set("waistCircumference", true)
          statisticsItem["chestCircumference"] = b.chestCircumference
          statisticsItem["chestCircumferenceRendered"] = statisticsItem["chestCircumference"]
          if (b.chestCircumference != null) dataForValueFieldAvailable.set("chestCircumference", true)
          statisticsItem["leftArmCircumference"] = b.leftArmCircumference
          statisticsItem["leftArmCircumferenceRendered"] = statisticsItem["leftArmCircumference"]
          if (b.leftArmCircumference != null) dataForValueFieldAvailable.set("leftArmCircumference", true)
          statisticsItem["rightArmCircumference"] = b.rightArmCircumference
          statisticsItem["rightArmCircumferenceRendered"] = statisticsItem["rightArmCircumference"]
          if (b.rightArmCircumference != null) dataForValueFieldAvailable.set("rightArmCircumference", true)
          statisticsItem["leftThighCircumference"] = b.leftThighCircumference
          statisticsItem["leftThighCircumferenceRendered"] = statisticsItem["leftThighCircumference"]
          if (b.leftThighCircumference != null) dataForValueFieldAvailable.set("leftThighCircumference", true)
          statisticsItem["rightThighCircumference"] = b.rightThighCircumference
          statisticsItem["rightThighCircumferenceRendered"] = statisticsItem["rightThighCircumference"]
          if (b.rightThighCircumference != null) dataForValueFieldAvailable.set("rightThighCircumference", true)

          statisticsItem["leftCalfCircumference"] = b.leftCalfCircumference
          statisticsItem["leftCalfCircumferenceRendered"] = statisticsItem["leftCalfCircumference"]
          if (b.leftCalfCircumference != null) dataForValueFieldAvailable.set("leftCalfCircumference", true)

          statisticsItem["rightCalfCircumference"] = b.rightCalfCircumference
          statisticsItem["rightCalfCircumferenceRendered"] = statisticsItem["rightCalfCircumference"]
          if (b.rightCalfCircumference != null) dataForValueFieldAvailable.set("rightCalfCircumference", true)

          statisticsItem["bellyCircumference"] = b.bellyCircumference
          statisticsItem["bellyCircumferenceRendered"] = statisticsItem["bellyCircumference"]
          if (b.bellyCircumference != null) dataForValueFieldAvailable.set("bellyCircumference", true)

          statisticsItem["seatCircumference"] = b.seatCircumference
          statisticsItem["seatCircumferenceRendered"] = statisticsItem["seatCircumference"]
          if (b.seatCircumference != null) dataForValueFieldAvailable.set("seatCircumference", true)


          statisticsItem["bodyFatPercentage"] = b.bodyFatPercentage
          statisticsItem["bodyFatPercentageRendered"] = statisticsItem["bodyFatPercentage"]
          if (b.bodyFatPercentage != null) dataForValueFieldAvailable.set("bodyFatPercentage", true)
          statisticsItem["bmi"] = b.bmi
          statisticsItem["bmiRendered"] = statisticsItem["bmi"]
          if (b.bmi != null) dataForValueFieldAvailable.set("bmi", true)
        }
      }

      // Collect DailyCondition and MetricData.
      dailyCondition = user.getDailyConditionForDate(date)
      if (dailyCondition) {
        if (dailyCondition.menstruating || dailyCondition.getMetricDataByMetricId(Metric.metricIdMenstruation())?.value == true) {
          var strip = {
            startValue: new Date(date.getTime() - 1000 * 60 * 60 * 12),
            endValue: new Date(date.getTime() + 1000 * 60 * 60 * 12)
          }
          this.progressStatisticsGraphStrips.push(strip)
        }
        statisticsItem["sleepDuration"] = dailyCondition.sleepDuration
        statisticsItem["sleepDurationRendered"] = dailyCondition.getPrintableSleepDuration()
        if (dailyCondition.sleepDuration != null) dataForValueFieldAvailable.set("sleepDuration", true)
        statisticsItem["waterIntake"] = Math.round((dailyCondition.waterIntake + Number.EPSILON) * 100) / 100
        statisticsItem["waterIntakeRendered"] = statisticsItem["waterIntake"]
        let availableMetrics = dailyCondition.getAllAvailableMetrics();
        availableMetrics.forEach(metric => {
          let averageMetricData = dailyCondition.getAverageMetricDataWithQuestionairesByMetricId(metric.metricId, this.displayQuestionaireMetrics);

          if (averageMetricData == null) return

          if (metric.metricId == "bodyFat" || metric.metricId == "NUT_bodyFat") {
            if (averageMetricData.value && averageMetricData.value > 1) {
              statisticsItem["metric" + metric.metricId] = averageMetricData.value ?? undefined
            } else {
              statisticsItem["metric" + metric.metricId] = (averageMetricData.value * 100)
            }
          } else {
            statisticsItem["metric" + metric.metricId] = averageMetricData.value ?? undefined
          }
          statisticsItem["metric" + metric.metricId + "Rendered"] = averageMetricData.getPrintableValue(this.translate) ?? undefined
          if (averageMetricData.value != null) dataForValueFieldAvailable.set("metric" + averageMetricData.metricId, true)

          if (metric.metricId == 'bodyWeight' || metric.metricId == 'NUT_bodyWeight') {
            //let weight = statisticsItem[metric.metricId]
            if (averageMetricData?.value) {
              let bmi = Math.round(averageMetricData.value / Math.pow(user.bodyHeight / 100, 2) * 100) / 100;
              if (bmi) {
                statisticsItem["bmi"] = Math.round(averageMetricData.value / Math.pow(user.bodyHeight / 100, 2) * 100) / 100
                statisticsItem["bmiRendered"] = statisticsItem["bmi"]
                dataForValueFieldAvailable.set("bmi", true)
              }
              var averageWeight = averageWeightMap.get(statisticsItem.weekNumber)
              var averageWeightCount = averageWeightCountMap.get(statisticsItem.weekNumber)
              if (averageWeight) {
                averageWeight = averageWeight + averageMetricData.value
                averageWeightCount++
              } else {
                averageWeight = averageMetricData.value
                averageWeightCount = 1
              }
              averageWeightMap.set(statisticsItem.weekNumber, averageWeight)
              averageWeightCountMap.set(statisticsItem.weekNumber, averageWeightCount)
            }
          }
        });
      }

      if (this.planMap && this.planMap.size > 0) {
        this.planMap.forEach((goalPlan) => {
          const metricId = goalPlan.metric.metricId;
          if(goalPlan.endDate && goalPlan.endDate.isSameDate(date)) {
            const goalStep = goalPlan.getEndStep();
            statisticsItem["goal_" + metricId + "_" + goalPlan.id] = goalStep.value;
            statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_min"] = goalStep.value ?? goalStep.minValue;
            statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_max"] = goalStep.value ?? goalStep.maxValue;
            if(goalPlan.metric.isMetricTypeDuration()) {
              const valRendered = moment.utc(moment.duration(statisticsItem["goal_" + metricId + "_" + goalPlan.id], "minutes").as('milliseconds')).format('HH:mm');
              const minRendered = moment.utc(moment.duration(statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_min"], "minutes").as('milliseconds')).format('HH:mm');
              const maxRendered = moment.utc(moment.duration(statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_max"], "minutes").as('milliseconds')).format('HH:mm');
              statisticsItem["goal_" + metricId + "_" + goalPlan.id + "Rendered"] = valRendered;
              statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_minRendered"] = minRendered;
              statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_maxRendered"] = maxRendered;
            } else {
              statisticsItem["goal_" + metricId + "_" + goalPlan.id + "Rendered"] = statisticsItem["goal_" + metricId + "_" + goalPlan.id];
              statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_minRendered"] = statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_min"];
              statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_maxRendered"] = statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_max"];
            }
            statisticsItem["planId"] = goalPlan.id;
          }
          goalPlan.goalSteps.filter(goalStep => goalStep.date.isSameDate(date)).forEach(goalStep => {
            statisticsItem["goal_" + metricId + "_" + goalPlan.id] = goalStep.value;
            statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_min"] = goalStep.value ?? goalStep.minValue;
            statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_max"] = goalStep.value ?? goalStep.maxValue;
            if(goalPlan.metric.isMetricTypeDuration()) {
              const valRendered = moment.utc(moment.duration(statisticsItem["goal_" + metricId + "_" + goalPlan.id], "minutes").as('milliseconds')).format('HH:mm');
              const minRendered = moment.utc(moment.duration(statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_min"], "minutes").as('milliseconds')).format('HH:mm');
              const maxRendered = moment.utc(moment.duration(statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_max"], "minutes").as('milliseconds')).format('HH:mm');
              statisticsItem["goal_" + metricId + "_" + goalPlan.id + "Rendered"] = valRendered;
              statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_minRendered"] = minRendered;
              statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_maxRendered"] = maxRendered;
            } else {
              statisticsItem["goal_" + metricId + "_" + goalPlan.id + "Rendered"] = statisticsItem["goal_" + metricId + "_" + goalPlan.id];
              statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_minRendered"] = statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_min"];
              statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_maxRendered"] = statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_max"];
            }
            statisticsItem["planId"] = goalPlan.id;
          });
        });
      }

      // Collect nutritional data.
      var nutritionStatistic = user.getNutritionStatisticsItemForDate(date)
      if (nutritionStatistic) {
        statisticsItem["calories"] = nutritionStatistic.nutritionalSummary.calories.roundToPlaces(1)
        statisticsItem["caloriesRendered"] = statisticsItem["calories"]
        dataForValueFieldAvailable.set("calories", true)
        statisticsItem["carbohydrates"] = nutritionStatistic.nutritionalSummary.carbohydrates.roundToPlaces(1)
        statisticsItem["carbohydratesRendered"] = statisticsItem["carbohydrates"]
        dataForValueFieldAvailable.set("carbohydrates", true)
        statisticsItem["protein"] = nutritionStatistic.nutritionalSummary.protein.roundToPlaces(1)
        statisticsItem["proteinRendered"] = statisticsItem["protein"]
        dataForValueFieldAvailable.set("protein", true)
        statisticsItem["fat"] = nutritionStatistic.nutritionalSummary.fat.roundToPlaces(1)
        statisticsItem["fatRendered"] = statisticsItem["fat"]
        dataForValueFieldAvailable.set("fat", true)

        statisticsItem["sugar"] = nutritionStatistic.nutritionalSummary.sugar.roundToPlaces(1)
        statisticsItem["sugarRendered"] = statisticsItem["sugar"]
        dataForValueFieldAvailable.set("sugar", true)
        statisticsItem["saturatedFat"] = nutritionStatistic.nutritionalSummary.saturatedFat.roundToPlaces(1)
        statisticsItem["saturatedFatRendered"] = statisticsItem["saturatedFat"]
        dataForValueFieldAvailable.set("saturatedFat", true)
        statisticsItem["fibre"] = nutritionStatistic.nutritionalSummary.fibre.roundToPlaces(1)
        statisticsItem["fibreRendered"] = statisticsItem["fibre"]
        dataForValueFieldAvailable.set("fibre", true)
        statisticsItem["salt"] = nutritionStatistic.nutritionalSummary.salt.roundToPlaces(1)
        statisticsItem["saltRendered"] = statisticsItem["salt"]
        dataForValueFieldAvailable.set("salt", true)

        if (nutritionStatistic.activities) {
          var duration = 0
          var calories = 0
          nutritionStatistic.activities.forEach(a => {
            if (a.activityFactId != 'acf71') duration += (a.duration || 0)
            calories += a.caloriesBurned
          })
          try {
            statisticsItem["activitiesDuration"] = duration.roundToPlaces(1)
            statisticsItem["activitiesDurationRendered"] = statisticsItem["activitiesDuration"]
            dataForValueFieldAvailable.set("activitiesDuration", true)
            statisticsItem["activitiesCalories"] = calories.roundToPlaces(1)
            statisticsItem["activitiesCaloriesRendered"] = statisticsItem["activitiesCalories"]
            dataForValueFieldAvailable.set("activitiesCalories", true)
          } catch (ex) {
            console.error(ex)
          }
        }
      }

      statisticsItems.push(statisticsItem)
    }


    if (this.planMap && this.planMap.size > 0) {
      // alle Steps einfügen
      this.planMap.forEach((goalPlan) => {
        const metricId = goalPlan.metric.metricId;
        const lateGoals = goalPlan.goalSteps.filter(goalStep => {
          return this.selectedEndDate < goalStep.date;
        });

        lateGoals.forEach(goalStep => {
          const date = goalStep.date;
          let statisticsItem = statisticsItems.find(item => item.date === date);
          if(!statisticsItem) {
            statisticsItem = {
              date: date,
              dateRendered: date.asShortFormatedString(),
              weekNumber: date.getYearOfWeekOfYearNumber() + '/' + (date.getWeekOfYearNumber() < 10 ? '0' : '') + date.getWeekOfYearNumber()
            }
          }

          statisticsItem["goal_" + metricId + "_" + goalPlan.id] = goalStep.value;
          statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_min"] = goalStep.value ?? goalStep.minValue;
          statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_max"] = goalStep.value ?? goalStep.maxValue;
          if(goalPlan.metric.isMetricTypeDuration()) {
            const valRendered = moment.utc(moment.duration(statisticsItem["goal_" + metricId + "_" + goalPlan.id], "minutes").as('milliseconds')).format('HH:mm');
            const minRendered = moment.utc(moment.duration(statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_min"], "minutes").as('milliseconds')).format('HH:mm');
            const maxRendered = moment.utc(moment.duration(statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_max"], "minutes").as('milliseconds')).format('HH:mm');
            statisticsItem["goal_" + metricId + "_" + goalPlan.id + "Rendered"] = valRendered;
            statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_minRendered"] = minRendered;
            statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_maxRendered"] = maxRendered;
          } else {
            statisticsItem["goal_" + metricId + "_" + goalPlan.id + "Rendered"] = statisticsItem["goal_" + metricId + "_" + goalPlan.id];
            statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_minRendered"] = statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_min"];
            statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_maxRendered"] = statisticsItem["goal_" + metricId + "_" + goalPlan.id + "_max"];
          }
          statisticsItem["planId"] = goalPlan.id;

          statisticsItems.push(statisticsItem);
        });
      });

      const lastStatisticItem = statisticsItems[statisticsItems.length - 1];
      const superLastDay = moment(lastStatisticItem.date).add(1, "week").toDate();
      const superLastStatisticsItem = {
        date: superLastDay,
        dateRendered: superLastDay.asShortFormatedString(),
        weekNumber: superLastDay.getYearOfWeekOfYearNumber() + '/' + (superLastDay.getWeekOfYearNumber() < 10 ? '0' : '') + superLastDay.getWeekOfYearNumber()
      };
      this.planMap.forEach((goalPlan) => {
        const metricId = goalPlan.metric.metricId;
        if ((goalPlan.type === GoalPlanType.DAILY || goalPlan.type === GoalPlanType.WEEKLY) &&  goalPlan.isActive(superLastDay)) {
          const lastStep = goalPlan.goalSteps[goalPlan.goalSteps.length - 1];

          superLastStatisticsItem["goal_" + metricId + "_" + goalPlan.id] = lastStep.value;
          superLastStatisticsItem["goal_" + metricId + "_" + goalPlan.id + "_min"] = lastStep.value ?? lastStep.minValue;
          superLastStatisticsItem["goal_" + metricId + "_" + goalPlan.id + "_max"] = lastStep.value ?? lastStep.maxValue;
          if(goalPlan.metric.isMetricTypeDuration()) {
            const valRendered = moment.utc(moment.duration(superLastStatisticsItem["goal_" + metricId + "_" + goalPlan.id], "minutes").as('milliseconds')).format('HH:mm');
            const minRendered = moment.utc(moment.duration(superLastStatisticsItem["goal_" + metricId + "_" + goalPlan.id + "_min"], "minutes").as('milliseconds')).format('HH:mm');
            const maxRendered = moment.utc(moment.duration(superLastStatisticsItem["goal_" + metricId + "_" + goalPlan.id + "_max"], "minutes").as('milliseconds')).format('HH:mm');
            superLastStatisticsItem["goal_" + metricId + "_" + goalPlan.id + "Rendered"] = valRendered;
            superLastStatisticsItem["goal_" + metricId + "_" + goalPlan.id + "_minRendered"] = minRendered;
            superLastStatisticsItem["goal_" + metricId + "_" + goalPlan.id + "_maxRendered"] = maxRendered;
          } else {
            superLastStatisticsItem["goal_" + metricId + "_" + goalPlan.id + "Rendered"] = superLastStatisticsItem["goal_" + metricId + "_" + goalPlan.id];
            superLastStatisticsItem["goal_" + metricId + "_" + goalPlan.id + "_minRendered"] = superLastStatisticsItem["goal_" + metricId + "_" + goalPlan.id + "_min"];
            superLastStatisticsItem["goal_" + metricId + "_" + goalPlan.id + "_maxRendered"] = superLastStatisticsItem["goal_" + metricId + "_" + goalPlan.id + "_max"];
          }

          superLastStatisticsItem["planId"] = goalPlan.id;
        }
      });
      statisticsItems.push(superLastStatisticsItem);
    }

    statisticsItems.forEach(items => {
      if (items['metricbodyWeight'] != null || items['metricNUT_bodyWeight'] != null) {
        var weekNumber = items.weekNumber;
        var averageWeight = averageWeightMap.get(weekNumber) / averageWeightCountMap.get(weekNumber)
        let weeklyAverage = Math.round(averageWeight * 100) / 100
        items["bodyWeightWeeklyAverage"] = weeklyAverage
        items["bodyWeightWeeklyAverageRendered"] = weeklyAverage

      }
    })
    this.isTableDateColumnVisible = this.isTableColumnVisible("date")
    this.allStatisticsGraphItems.forEach(item => {
      if (dataForValueFieldAvailable.get(item.valueField) == true) {
        item.visible = this.isTableColumnVisible(item.valueField);
        this.tableStatisticsGraphItems.push(item)
      }
    })
    this.additionalBodyDataGraphTypesForTable.forEach(item => {
      if (dataForValueFieldAvailable.get(item.valueField) == true) {
        item.visible = this.isTableColumnVisible(item.valueField);
        this.tableStatisticsGraphItems.push(item)
      }
    });
    Metric.circumferenceMetricIds.forEach(metricId => {
      let item = {
        valueField: "metric" + metricId,
        name: Metric.getCircumferenceMetricIdAsName(metricId, this.translate),
        summaryType: "avg",
        visible: true
      }
      if (dataForValueFieldAvailable.get(item.valueField) == true && this.tableStatisticsGraphItems.find(x => x.valueField == item.valueField) == null) {
        item.visible = this.isTableColumnVisible(item.valueField);
        this.tableStatisticsGraphItems.push(item)
      }
    });

    if (this.displayedMetricInGraph != null && this.displayedMetricInGraph.isMetricTypeYesNo()) {
      this.progressGraphVisualRange = [0, 1]
    } else if (this.displayedMetricInGraph != null && this.displayedMetricInGraph.isMetricTypeSelection()) {
      this.progressGraphVisualRange = [0, this.displayedMetricInGraph.getSelectableValues().length - 1]
    } else if (this.displayedMetricInGraph != null && this.displayedMetricInGraph.isMetricTypeNumber()) {
      this.progressGraphVisualRange = [this.displayedMetricInGraph.minValue != null ? this.displayedMetricInGraph.minValue : null, this.displayedMetricInGraph.maxValue != null ? this.displayedMetricInGraph.maxValue : null]
    } else {
      this.progressGraphVisualRange = [null, null]
    }
    if (this.displayedMetricInGraph != null && (this.displayedMetricInGraph.isMetricTypeSelection() || this.displayedMetricInGraph.isMetricTypeYesNo())) {
      this.progressGraphTickIntervall = 1
    } else {
      this.progressGraphTickIntervall = undefined
    }

    // TODO get the metricIds somehow
    const firsts = [];
    this.selectedBodyDataGraphTypes.forEach(selectedBodyDataGraphType => {
      let metricId = null;
      switch (selectedBodyDataGraphType.id) {
        case BodyDataComponent.bodyWeightId:
          metricId = 'bodyWeight';
          break;
        case BodyDataComponent.circumferencesId:
          metricId = BodyDataComponent.circumferencesId;
          break;
        case BodyDataComponent.bodyFatId:
          metricId = 'bodyFatPercentage';
          break;
        case BodyDataComponent.waterId:
          metricId = 'waterIntake';
          break;
        case BodyDataComponent.bmiId:
          metricId = 'bmi';
          break;
        default:
          metricId = selectedBodyDataGraphType.tmp.replace('metric', '');
          break;
      }

      if (metricId == BodyDataComponent.circumferencesId) {
        Metric.circumferenceMetricIds.forEach(circumferenceMetricId => {
          const items = statisticsItems.filter(item => item.date >= this.selectedStartDate);
          const first = items.find(item => {
            return Object.keys(item).filter(key => key.includes(circumferenceMetricId)).length;
          });

          if (first) {
            const condi = this.goalPlanService.getLatestDailyConditionByMetricId(this.displayedUser, metricId, moment(first.date).subtract(1, "day").toDate());
            firsts.push({
              statisticsItem: first,
              metricId: metricId,
              dailyCondition: condi
            });
          }
        });
      } else {
        const items = statisticsItems.filter(item => item.date >= this.selectedStartDate);
        const first = items.find(item => {
          return Object.keys(item).filter(key => key.includes(metricId)).length;
        });

        if (first) {
          const condi = this.goalPlanService.getLatestDailyConditionByMetricId(this.displayedUser, metricId, moment(first.date).subtract(1, "day").toDate());
          firsts.push({
            statisticsItem: first,
            metricId: metricId,
            dailyCondition: condi
          });
        }
      }
    });

    // wenn keine Dailycondition: range verändern. Aber was ist wenn 2 eine haben und eine nicht?
    if (firsts.filter(first => !first.dailyCondition).length > 0) {
      const lonelyItems = firsts.filter(first => !first.dailyCondition);
      const earliestDate = new Date(Math.min(...lonelyItems.map(item => item.statisticsItem.date.getTime())));
      const newStartDate = moment(earliestDate).subtract(1, "days").startOf("day").toDate();
      this.argumentAxisVisualRange = [newStartDate, this.selectedEndDate];
    }
    const withCondis = firsts.filter(first => first.dailyCondition);
    withCondis.forEach((item) => {
      const statisticsItem = {
        date: item.dailyCondition.date,
        dateRendered: item.dailyCondition.date.asShortFormatedString(),
        weekNumber: item.dailyCondition.date.getYearOfWeekOfYearNumber() + '/' + (item.dailyCondition.date.getWeekOfYearNumber() < 10 ? '0' : '') + item.dailyCondition.date.getWeekOfYearNumber()
      }

      statisticsItem["metric" + item.metricId] = item.dailyCondition.getMetricDataByMetricId(item.metricId).value;
      statisticsItems.unshift(statisticsItem);
    });
    if(withCondis.length) {
      this.argumentAxisVisualRange = [this.selectedStartDate, this.selectedEndDate];
    }

    this.progressStatisticsGraphData = statisticsItems;
  }

  legendClickHandler(e) {
    const series = e.target;
    if (series.isVisible()) {
      series.hide();
    } else {
      series.show();
    }
  }

  pointClickHandler(e: DxChartTypes.PointClickEvent) {
    const point = e.target;
    const series = point.series;

    const valueField = series.getValueFields()[0] as string;
    if (!valueField || !valueField.includes("goal_")) {
      return;
    }
    const goalPlan = this.planMap.get(point.data.planId);
    if (goalPlan) {
      this.onUpdateMetricGoal(goalPlan);
    }
  }

  loadStatisticForUserAndDate(user: User, date: Date): Observable<NutritionStatisticsItem> {
    // Fetch Meals and NutritionalGoal.
    return combineLatest(
      this.userService.getMealsWithFoodsByDate(user, date, false, false),
      this.userService.getNutritionalGoalIncludingActivitiesByDate(user, date)
    ).pipe(
      map(([meals, nutritionalGoal]) => {
        var statistic = new NutritionStatisticsItem()
        statistic.date = date
        var nutritionalSummary = new NutritionalSummary();
        statistic.nutritionalSummary = nutritionalSummary;
        statistic.nutritionalGoal = nutritionalGoal

        statistic.carbohydrates = 0
        statistic.protein = 0
        statistic.fat = 0
        statistic.carbohydratesGoal = nutritionalGoal.carbohydrates
        statistic.proteinGoal = nutritionalGoal.protein
        statistic.fatGoal = nutritionalGoal.fat
        statistic.meals = meals

        statistic.sugar = 0
        statistic.saturatedFat = 0
        statistic.fibre = 0
        statistic.salt = 0

        if (nutritionalSummary.calories >= nutritionalGoal.calories) {
          statistic.calories = 0
          statistic.calorieGoal = nutritionalGoal.calories
          statistic.calorieDeficit = 0;
          statistic.calorieSurplus = nutritionalSummary.calories - nutritionalGoal.calories
        } else {
          statistic.calories = nutritionalSummary.calories
          statistic.calorieGoal = 0
          statistic.calorieSurplus = 0
          statistic.calorieDeficit = nutritionalGoal.calories - nutritionalSummary.calories
        }

        meals.forEach(meal => {
          nutritionalSummary.carbohydrates = nutritionalSummary.carbohydrates + meal.getCarbohydrates()
          nutritionalSummary.protein = nutritionalSummary.protein + meal.getProtein()
          nutritionalSummary.fat = nutritionalSummary.fat + meal.getFat()
          nutritionalSummary.calories = nutritionalSummary.calories + meal.getCalories()

          nutritionalSummary.sugar = nutritionalSummary.sugar + meal.getSugar();
          nutritionalSummary.saturatedFat = nutritionalSummary.saturatedFat + meal.getSaturatedFat();
          nutritionalSummary.fibre = nutritionalSummary.fibre + meal.getFibre();
          nutritionalSummary.salt = nutritionalSummary.salt + meal.getSalt();

          if (nutritionalSummary.calories >= nutritionalGoal.calories) {
            statistic.calories = 0
            statistic.calorieGoal = nutritionalGoal.calories
            statistic.calorieDeficit = 0;
            statistic.calorieSurplus = nutritionalSummary.calories - nutritionalGoal.calories
          } else {
            statistic.calories = nutritionalSummary.calories
            statistic.calorieGoal = 0
            statistic.calorieSurplus = 0
            statistic.calorieDeficit = nutritionalGoal.calories - nutritionalSummary.calories
          }
          statistic.carbohydrates = nutritionalSummary.carbohydrates
          statistic.protein = nutritionalSummary.protein
          statistic.fat = nutritionalSummary.fat

          statistic.sugar = nutritionalSummary.sugar
          statistic.saturatedFat = nutritionalSummary.saturatedFat
          statistic.fibre = nutritionalSummary.fibre
          statistic.salt = nutritionalSummary.salt
        })

        return statistic
      })
    );
  }

  onOpenMetricDataImage(metricData: MetricData) {
    const dialogRef = this.dialog.open(MetricDataImageDialogComponent, {data: {imageURL: metricData.mediaLink}});
  }

  /*Bild Metriken anzeigen */
  showImageMetrics: boolean = false
  canShowImageMetrics: boolean = false

  showVideoMetrics: boolean = false
  canShowVideoMetrics: boolean = false

  onShowImageMetricsChanged(value: boolean) {
    this.showImageMetrics = value
    // this.prepareImageMetricTypes(this.displayedUser)
    this.updateVisibleImages(this.displayedUser)
  }

  onShowVideoMetricsChanged(value: boolean) {
    this.showVideoMetrics = value
    this.updateVisibleImages(this.displayedUser)
  }


  visibleImages: any = []
  visibleVideos: any = []

  async updateVisibleImages(user: User) {
    this.visibleImages = []
    this.visibleVideos = []
    this.selectedCompareImages = []
    if (!this.selectedImageMetricType) return
    var timeRange: number;
    if (this.selectedBodyDataGraphTimeRange.id == 0) {
      timeRange = 14;
    } else if (this.selectedBodyDataGraphTimeRange.id == 1) {
      timeRange = 31;
    } else if (this.selectedBodyDataGraphTimeRange.id == 2) {
      timeRange = 90;
    } else if (this.selectedBodyDataGraphTimeRange.id == 3) {
      timeRange = 180;
    } else if (this.selectedBodyDataGraphTimeRange.id == 4) {
      timeRange = 365;
    } else if (this.selectedBodyDataGraphTimeRange.id == 5) {
      timeRange = this.getFullTimeRangeNumber(user);
    } else if (this.selectedBodyDataGraphTimeRange.id == 6) {
      if (this.selectedStartDate && this.selectedEndDate && this.selectedEndDate > this.selectedStartDate) {
        this.selectedEndDate.setHours(0, 0, 0, 0)
        this.selectedStartDate.setHours(0, 0, 0, 0)
        timeRange = (this.selectedEndDate.getTime() - this.selectedStartDate?.getTime()) / (1000 * 3600 * 24)
      } else {
        return;
      }
    }

    var startDate = new Date();

    if (this.selectedBodyDataGraphTimeRange.id == 6) {
      startDate = this.selectedStartDate;
    } else {
      startDate.setDate(startDate.getDate() - timeRange);
      startDate.setHours(0, 0, 0, 0);
    }
    var dailyCondition: DailyCondition;

    var statisticsItems: any[];
    var statisticsItem: any;
    statisticsItems = []

    for (var i = 0; i <= timeRange; i++) {
      var date = new Date(startDate.getTime())
      date.setDate(date.getDate() + i)
      dailyCondition = user.getDailyConditionForDate(date)
      if (dailyCondition) {
        var metricDatas = dailyCondition.getMetricDataListWithQuestionaires()
        for (var metricData of metricDatas) {
          if (metricData.metric !== null) {
            if (metricData.metric.isMetricTypeImage() && metricData.metric.metricId === this.selectedImageMetricType.tmp.replace('metric', '')) {
              if (!metricData.mediaLink) {
                metricData.mediaLink = await this.userService.getMetricDataMediaLink(metricData, user.uid)
              }
              if (metricData.mediaLink) {
                statisticsItem = {
                  date: date,
                  dateRendered: date.asShortFormatedString(),
                  weekNumber: date.getWeekOfYearNumber(),
                  imageURL: metricData.mediaLink ?? undefined
                }
                this.visibleImages.push(statisticsItem)
              }
            } else if (metricData.metric.isMetricTypeVideo() && metricData.metric.metricId === this.selectedVideoMetricType.tmp.replace('metric', '')) {
              if (!metricData.mediaLink) {
                metricData.mediaLink = await this.userService.getMetricDataMediaLink(metricData, user.uid)
              }
              if (metricData.mediaLink) {
                statisticsItem = {
                  date: date,
                  dateRendered: date.asShortFormatedString(),
                  weekNumber: date.getWeekOfYearNumber(),
                  videoURL: metricData.mediaLink ?? undefined
                }
                this.visibleVideos.push(statisticsItem)
              }
            }
          }
        }
      }
    }
  }

  onImageMetricTypeChanged(selection: DropdownItem) {
    this.selectedImageMetricType = selection
    this.updateVisibleImages(this.displayedUser)
    this.selectedCompareImages = []
  }

  onVideoMetricTypeChanged(selection: DropdownItem) {
    this.selectedVideoMetricType = selection
    this.updateVisibleImages(this.displayedUser)
  }


  onImageSelected(image: any) {
    if (this.selectedCompareImages.includes(image)) {
      this.selectedCompareImages = this.selectedCompareImages.filter(item => item.imageURL != image.imageURL)
    } else {
      this.selectedCompareImages.push(image)
    }
  }

  onOpenCompareImageDialog() {
    if (this.selectedCompareImages?.length <= 0) return
    const dialogRef = this.dialog.open(MetricDataImageDialogComponent, {data: {compareImages: this.selectedCompareImages}});
  }

  onOpenMetricTypeImage(imageLink: string) {
    const dialogRef = this.dialog.open(MetricDataImageDialogComponent, {data: {imageURL: imageLink}});
  }

  imageMetricTypes: DropdownItem[] = []
  selectedImageMetricType: DropdownItem
  selectedCompareImages: any[] = []

  videoMetricTypes: DropdownItem[] = []
  selectedVideoMetricType: DropdownItem

  prepareImageMetricTypes(user: User) {
    var handledMetricIds: string[] = []
    this.imageMetricTypes = []
    this.videoMetricTypes = []
    this.selectedCompareImages = []
    user.dailyConditions.forEach(dailyCondition => {
      dailyCondition.getMetricDataListWithQuestionaires()?.forEach(data => {
        if (!handledMetricIds.includes(data.metricId) && this.imageMetricTypes.filter(function (e) {
          return e.tmp === 'metric' + data.metricId;
        }).length == 0) {
          var metric = this.userService.getMetricByMetricId(data.metricId)
          if (metric != null && metric.isMetricTypeImage()) {
            this.imageMetricTypes.push({
              id: 6,
              name: metric.nameTranslation?.GetValue(this.translate.currentLang) ?? metric.nameTranslation?.GetValue('de') ?? this.translate.instant('Metrik ') + data.metricId,
              tmp: 'metric' + data.metricId,
              unit: metric.unit,
              order: 99
            })
          }
          if (metric != null && metric.isMetricTypeVideo()) {
            this.videoMetricTypes.push({
              id: 6,
              name: metric.nameTranslation?.GetValue(this.translate.currentLang) ?? metric.nameTranslation?.GetValue('de') ?? this.translate.instant('Metrik ') + data.metricId,
              tmp: 'metric' + data.metricId,
              unit: metric.unit,
              order: 99
            })
          }
          handledMetricIds.push(data.metricId)
        }
      })
    })
    if (this.imageMetricTypes.length > 0) {
      this.selectedImageMetricType = this.imageMetricTypes[0]
      this.canShowImageMetrics = true
    } else {
      this.selectedImageMetricType = undefined
      this.canShowImageMetrics = false
    }

    if (this.videoMetricTypes.length > 0) {
      this.selectedVideoMetricType = this.videoMetricTypes[0]
      this.canShowVideoMetrics = true
    } else {
      this.selectedVideoMetricType = undefined
      this.canShowVideoMetrics = false
    }
    this.updateVisibleImages(this.displayedUser)
  }

  logout() {
    this.userService.logout();
    this.authService.logout();
  }

  getNutritionalSummary() {
    return this.user.nutritionalSummary;
  }

  createRange(number) {
    var items: number[] = [];
    for (var i = 0; i < number; i++) {
      items.push(i);
    }
    return items;
  }

  public isTableDateColumnVisible: boolean = true

  isTableColumnVisible(columnName: string) {
    let loggedInUser = this.userService.getLoggedInUser();
    if (loggedInUser?.isCoach) {
      return loggedInUser?.portalSettingsCoach?.hiddenBodyDataTableColumns?.includes(columnName) == false
    }
    return true
  }

  tableColumnVisibilityChanged(visible, columnName) {
    let loggedInUser = this.userService.getLoggedInUser();
    if (loggedInUser.isCoach) {
      if (visible == false) {
        let loggedInUser = this.userService.getLoggedInUser();
        loggedInUser.portalSettingsCoach.hiddenBodyDataTableColumns.push(columnName)
      } else {
        loggedInUser.portalSettingsCoach.hiddenBodyDataTableColumns = loggedInUser.portalSettingsCoach.hiddenBodyDataTableColumns.filter(x => x != columnName)
      }
      this.userService.updatePortalSettingsForCoach(loggedInUser)
    }
  }


  protected readonly EventLogService = EventLogService;

  protected onAddMetricGoal() {
    const selection = this.selectedBodyDataGraphTypes[0];
    let metric = null;
    if (selection && selection.tmp) {
      metric = this.userService.getMetricByMetricId(selection.tmp.replace('metric', ''));
    }

    const data: GoalPlanConfig = {
      metric: metric,
      type: GoalPlanType.DAILY,
      isNew: true,
      user: this.displayedUser
    };
    const dialogRef = this.dialog.open(MetricGoalDialogComponent, {data: data, width: '850px'});
    dialogRef.afterClosed().subscribe((result: GoalPlan) => {
      if (result) {
        if(this.planMap == null) {
          this.planMap = new Map<string, GoalPlan>();
        }
        this.planMap.set(result.id, result);
        let promise = null;
        if (this.planMap.size === 1) {
          promise = this.goalPlanService.addFirstGoalPlan(this.displayedUser, result);
        } else {
          promise = this.goalPlanService.updateGoalPlans(this.displayedUser, result);
        }

        if (this.displayMetricGoals) {
          promise.then(() => {
            this.updateBodyStatisticsGraph(this.displayedUser);
          });
        }
      }
    });
  }

  protected onUpdateMetricGoal(goalPlan: GoalPlan) {
    const metric = goalPlan.metric;

    const data: GoalPlanConfig = {
      metric: metric,
      type: goalPlan.type,
      isNew: false,
      goalPlan: goalPlan,
      user: this.displayedUser
    };
    const dialogRef = this.dialog.open(MetricGoalDialogComponent, {data: data, width: '850px'});
    dialogRef.afterClosed().subscribe((result: GoalPlan) => {
      if (result) {
        if (result.deleted) {
          this.planMap.delete(result.id);
        }
        const promise = this.goalPlanService.updateGoalPlans(this.displayedUser, result);

        if (this.displayMetricGoals) {
          promise.then(() => {
            this.updateBodyStatisticsGraph(this.displayedUser);
          });
        }
      }
    });
  }

  private customFilter(statisticsItem: any): boolean {
    // Custom logic to determine visibility
    const statMoment = moment(statisticsItem.date);
    return statMoment.isBetween(this.selectedStartDate, this.selectedEndDate, "day", "[]");
  }

  protected onDataGridInitialized(event) {
    event.component.filter(this.customFilter.bind(this));
  }
}

class DropdownItem {
  id: number;
  tmp: string;
  name: string;
  unit: string;
  order: number;
}

class MetricType {
  type: string;
  description: string;
}
