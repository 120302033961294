<div *ngIf="utilityService.onNativeMobileApp()" class="container-fluid">
  <ng-container *ngFor="let c of chatService.getOpenChats()">
    <single-chat [chat]="c" class="single-chat-container">
    </single-chat>
  </ng-container>
</div>
<div *ngIf="!utilityService.onNativeMobileApp() && chatService?.getChats()" class="container-fluid">
  <div class="row">
    <div class="single-chats col-12 col-lg-8 col-xl-9">
      <div class="container-fluid">
        <div class="row {{utilityService.onMobile() ? 'col' : ''}}">
          <ng-container *ngFor="let c of chatService.getOpenChats()">
            <div class="{{utilityService.onMobile() ? '' : 'col-12 col-xl-6'}}">
              <single-chat cdkDrag cdkDragHandle [inputDisabled]="draggingSingleChat"
                [cdkDragStartDelay]="dragStartDelay" (touchstart)="dragStartDelay = 100"
                (cdkDragStarted)="dragStarted($event)" [cdkDragDisabled]="utilityService.onMobile()"
                (cdkDragEnd)="dragEnd()" (cdkDragDropped)="dragDropped()" (cdkDragReleased)="dragReleased($event)"
                [chat]="c">
              </single-chat>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="!utilityService.onNativeMobileApp()" class="chats-overview col-12 col-lg-4 col-xl-3">
      <div class="card-container">
        <div class="card-header link">
          <h2 class="margin-0 display-inlineblock link w-100" (click)="toggleFold()">
            Chats
            <div class="chevron-indicator" *ngIf="folded === 'open'">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-chevron-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
              </svg>
            </div>
            <div class="chevron-indicator" *ngIf="folded !== 'open'">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-chevron-up" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
              </svg>
            </div>
            <div *ngIf="chatService.hasUnreadMessages(getSelectedCoachUids(), getSelectedGroupNames())"
              class="message-indication glyphicon link"></div>
          </h2>
          <div class="chat-buttons-wrapper">
            <div *ngIf="folded === 'open'" class="chat-filter-dropdown-wrapper dropbottom">
              <button id="chat-filter-dropdown" class="button-filled light small dropdown-toggle margin-inbetween m-0"
                type="button" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">{{getPrintableFilteredClients()}}</button>
              <div #dropdownMenu id="filterDropdownMenu" class="dropdown-menu dropdown-notificationTarget"
                aria-labelledby="dropdownNotificationTarget">
                <div *ngIf="selectableGroupNames[0]?.name == 'Alle'" class="dropdown-item link"
                  (click)="onFilterSelectionChangedGroup(selectableGroupNames[0], !selectableGroupNames[0].checked);$event.stopPropagation()">
                  <input class="input checkbox" type="checkbox" [checked]="selectableGroupNames[0].checked">
                  <div class="display-inlineblock">{{selectableGroupNames[0].name}}</div>
                </div>
                <div class="dropdown-divider"></div>
                <div class="label marginleft-10" translate>Coaches</div>
                <div class="dropdown-item link" *ngFor="let group of selectableCoachGroups"
                  (click)="onFilterSelectionChangedCoachGroup(group, !group.checked);$event.stopPropagation()">
                  <input class="input checkbox" type="checkbox" [checked]="group.checked">
                  <div class="display-inlineblock">{{group.name}}</div>
                </div>
                <ng-container *ngIf="selectableGroupNames?.length > 1">
                  <div class="dropdown-divider"></div>
                  <div class="label marginleft-10" translate>Gruppen</div>
                  <ng-container *ngFor="let group of selectableGroupNames">
                    <div *ngIf="group.name != 'Alle'" class="dropdown-item link"
                      (click)="onFilterSelectionChangedGroup(group, !group.checked);$event.stopPropagation()">
                      <input class="input checkbox" type="checkbox" [checked]="group.checked">
                      <div class="display-inlineblock">{{group.name}}</div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <div class="broadcast-icon link" (click)="openBraodcastChat();$event.stopPropagation()"
              [tooltip]="'Broadcast-Nachricht versenden' | translate">
              <i class="bi bi-megaphone"></i>
            </div>
          </div>
        </div>
        <div class="card-body chat-list" [@panelState]="{value: folded}">
          <input id="clientsearch-input" class="input wide client mb-3"
            (input)="onClientSearchInputChanged($event.target.value); $event.stopPropagation()"
            [placeholder]="'Suchen' | translate" type="text">
          <div *ngIf="clientSearchInput?.length > 0" class="icon-button deletesearch-icon delete-clientsearch"
            (click)="onDeleteClientSearchInput()">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </div>
          <div
            *ngFor="let c of chatService.getFilteredChats(clientSearchInput, getSelectedCoachUids(), getSelectedGroupNames())">
            <div *ngIf="folded == 'open'" class="layout-box chat-box" (click)="chatService.openChat(c)">

              <div class="row">
                <div class="chat-image-col">
                  <div class="profile-picture">
                    <img draggable="false" *ngIf="c?.chatPartner?.profilePictureUrl" loading="lazy"
                      (error)="utilityService.onImageErrorHide($event)" src="{{c?.chatPartner?.profilePictureUrl}}">
                    <div *ngIf="!c?.chatPartner?.profilePictureUrl">
                      <img draggable="false" loading="lazy" (error)="utilityService.onImageErrorHide($event)"
                        src="{{ (userService.getProfilePictureForUser(c?.chatPartner) | async) }}">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="chat-name display-inlineblock">{{c.chatPartner.getName()}}</div>
                  <div *ngIf="c.unreadMessages?.length > 0" class="message-indication single-chat glyphicon"></div>
                </div>
                <div>
                  <div *ngIf="c?.messages?.length > 0" class="message-date">{{dateFormatter(c.messages[0]?.time)}}</div>
                </div>
              </div>

              <div class="row" *ngIf="c?.messages?.length > 0">

                <div class="col message-preview-wrapper" *ngIf="!c.messages[0]?.isDeleted">
                  <div class="message-preview">
                    <div class="d-inline-block" *ngIf="c.messages[0]?.hasAttachment()">
                      <div class="d-inline-block" *ngIf="c.messages[0]?.hasImageAttachment(); else audio_attached">
                        <i class="bi bi-image"></i>
                        <div class="d-inline-block ml-1" *ngIf="c.messages[0]?.message == null || c.messages[0]?.message?.length == 0" translate>
                          Bild
                        </div>
                      </div>
                      <ng-template #audio_attached>
                        <div class="d-inline-block" *ngIf="c.messages[0]?.hasAudioAttachment(); else file_attached">
                          <i class="bi bi-mic"></i>
                          {{c.messages[0]?.getAudioDuration()}}
                        </div>
                      </ng-template>
                      <ng-template #file_attached>
                        <div class="d-inline-block">
                          <i class="bi bi-file-text"></i>
                          <div class="d-inline-block ml-1" *ngIf="c.messages[0]?.message == null || c.messages[0]?.message?.length == 0" translate>
                            Datei
                          </div>
                        </div>
                      </ng-template>
                    </div>
                    <div class="d-inline-block" *ngIf="c.messages[0]?.hasSharedFileReference()" translate><i class="bi bi-file-earmark"></i>
                      <div class="d-inline-block ml-1" *ngIf="c.messages[0]?.message == null || c.messages[0]?.message?.length == 0" translate>
                        Dateiablage
                      </div>
                    </div>
                    {{c.messages[0]?.message}}
                  </div>
                </div>

                <div class="col" *ngIf="c.messages[0]?.isDeleted === true">
                  <div class="deleted-message">
                    <svg id="slash-circle" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                      viewBox="0 0 16 16">
                      <path id="Pfad_113" data-name="Pfad 113"
                        d="M8,15a7,7,0,1,1,7-7,7,7,0,0,1-7,7Zm0,1A8,8,0,1,0,0,8,8,8,0,0,0,8,16Z" />
                      <path id="Pfad_114" data-name="Pfad 114"
                        d="M13.438,2.562a.5.5,0,0,0-.708,0L2.56,12.732a.5.5,0,1,0,.708.708L13.438,3.27a.5.5,0,0,0,0-.708Z" />
                    </svg>
                    <ng-container *ngIf="c.messages[0]?.uid != authService.user.uid; else mymessagedeleted;"
                      translate>Diese Nachricht wurde gelöscht.</ng-container>
                    <ng-template #mymessagedeleted translate>Du hast diese Nachricht gelöscht.</ng-template>
                  </div>
                </div>

                <div>
                  <button mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                      <path
                        d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                    </svg>
                  </button>
                  <mat-menu #menu="matMenu" class="chat-cdk-overlay-container">
                    <button mat-menu-item (click)="chatService.markLastMessageAsUnread(c)">{{'Als ungelesen markieren' |
                      translate}}</button>
                  </mat-menu>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>