import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { MetricDataImageDialogComponent } from 'src/app/metric-data-image-dialog/metric-data-image-dialog.component';
import { TrainingService } from 'src/app/services/training.service';
import { SingleExerciseComponent } from 'src/app/training/single-exercise/single-exercise.component';

@Component({
  selector: 'app-image-upload-view',
  templateUrl: './image-upload-view.component.html',
  styleUrls: ['./image-upload-view.component.css']
})
export class ImageUploadViewComponent {

  constructor(private ngZone: NgZone, public toastr: ToastrService, public trainingService: TrainingService, public dialog: MatDialog) { }

  @Input() set filePaths(value: string[]) {
    this.images = value.map(path => { return {blob: null, src: null, path: path}})
    this.loadImages()
  }
  images: any[] = [] // {blob: Blob, src: string, path: string}

  @Input() canEdit: boolean = true

  @Input() set pathPrefix(value: string) {
    this._pathPrefix = value
    if (this._pathPrefix) this.loadImages()
  }
  _pathPrefix: string = null

  @Output() imagesChanged = new EventEmitter()


  loadImages() {
    this.images.forEach(async image => {
      var path = image.path
      if (this._pathPrefix) path = this._pathPrefix + path
      firstValueFrom(this.trainingService.fireStorage.ref(path).getDownloadURL()).then(async (link) => {
        image.src = link
      })
    })
  }

  onUploadNewFile() {
    (document.querySelector('#file-input') as HTMLElement).click()
  }
  uploadThumbnail(e) {
    if (e.target.files && e.target.files[0]) {
      if (!SingleExerciseComponent.checkUploadFile(e.target.files[0], 50000000, ["jpg", "jpeg", "png"], this.toastr)) return;
      this.convertThumbnail(e.target.files[0])
    }
  }

  convertThumbnail(thumbnail: File) {
    var thumbnailImage = new Image();
    thumbnailImage.src = URL.createObjectURL(thumbnail)
    thumbnailImage.onload = (event) => {
      var newWidth = 360;
      var newHeight = 360;

      var width = thumbnailImage.width;
      var height = thumbnailImage.height;

      var p = new Image();
      p.width = newWidth;
      var i = width / newHeight;
      p.height = height / i;
      p.src = thumbnailImage.src;
      
      var x = 0;
      var y = 0;
      var min = height;
      if (width > height) {
        x = (width - height) / 2;
      } else {
        y = (height - width) / 2;
        min = width;
      }
      const elem = document.createElement('canvas');
      elem.width = newWidth;
      elem.height = newHeight;
      const ctx = elem.getContext('2d');
      ctx.drawImage(p, x, y, min, min, 0, 0, newWidth, newHeight);
      ctx.canvas.toBlob(async (blob) => {
          this.ngZone.run( async () => {
            var imageBlob = blob
            var thumbnailImageSrc = await this.blobToBase64(blob);
            this.images.push({blob: imageBlob, src: thumbnailImageSrc, path: null})
            this.imagesChanged.emit(this.images)
          });
          return blob
      }, 'image/png', 1);
    }
  }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  onDragOver(event) {
    event.preventDefault();
  }
  
  onDropThumbnail(event) {
    event.preventDefault();
    if (event.dataTransfer.files?.length > 0) {
      var file = event.dataTransfer.files[0]
      if (!SingleExerciseComponent.checkUploadFile(file, 1000000, ["jpg", "jpeg", "png", "gif"], this.toastr)) return
      this.convertThumbnail(file);
    }
  }
  
  onOpenImage(image: any) {
    if (image.src) {
      const dialogRef = this.dialog.open(MetricDataImageDialogComponent, { data: { imageURL: image.src}});
    }
  }

  onDeleteImage(image: any) {
    this.images = this.images.filter(i => i !== image)
    this.imagesChanged.emit(this.images)
  }
}
