import { NutritionalGoal } from "./nutritionalgoal.model";
import { Situation } from "./nutritionconfig.model";

export class NutritionalGoalV2 {
    date: Date;
    adjustWithActivityCalories: boolean;
    applicableWeekdays: string[];
    situationId: string
    goalId: string
    targetValues: any
    targetValueTolerances: any
    activityCaloriesDistribution: number[]
    activityCaloriesMultiplier: number

    // Temporary
    situation: Situation

    constructor();
    constructor(init: NutritionalGoalV2);
    constructor(init?: NutritionalGoalV2) {
        this.date = init && init.date || new Date()
        this.goalId = init && init.goalId || null
        this.targetValues = init && init.targetValues || {}
        this.targetValueTolerances = init && init.targetValueTolerances || {}
        this.adjustWithActivityCalories = init && init.adjustWithActivityCalories || false
        this.applicableWeekdays = init && init.applicableWeekdays || ["all"]
        this.situationId = init && init.situationId || null
        this.activityCaloriesDistribution = init && init.activityCaloriesDistribution || null
        if (this.activityCaloriesDistribution != null && this.activityCaloriesDistribution.length != 3) this.activityCaloriesDistribution = null
        this.activityCaloriesMultiplier = init && init.activityCaloriesMultiplier || 1
    }

    isValid(): boolean {
        return this.getCarbohydrates() != null && this.getProtein() != null && this.getFat() != null && this.getCalories() != null && this.getCarbohydrates() > 0 && this.getProtein() > 0 && this.getFat() > 0 && this.getCalories() > 0
    }
    hasValues(): boolean {
        return this.getCarbohydrates() != null && this.getProtein() != null && this.getFat() != null && this.getCalories() != null
    }

    getCalories() {
        return this.targetValues['calories'] ?? null
    }
    getCarbohydrates() {
        return this.targetValues['carbohydrates'] ?? null
    }
    getProtein() {
        return this.targetValues['protein'] ?? null
    }
    getFat() {
        return this.targetValues['fat'] ?? null
    }
    getFibre() {
        return this.targetValues['fibre'] ?? null
    }
    getSalt() {
        return this.targetValues['salt'] ?? null
    }
    getSugar() {
        return this.targetValues['sugar'] ?? null
    }
    getSaturatedFat() {
        return this.targetValues['saturatedFat'] ?? null
    }

    getNutritionalValue(nutritionalValue: string): number {
        return this.targetValues[nutritionalValue] ?? null
    }

    getPrintableGoal() {
        return this.getCarbohydrates()?.toFixed(0) + 'g K / ' + this.getProtein()?.toFixed(0) + 'g E / ' + this.getFat()?.toFixed(0) + 'g F / ' + this.getCalories()?.toFixed(0) + ' kcal'
    }

    addWeekday(day: string) {
        if (!this.applicableWeekdays.includes(day)) this.applicableWeekdays.push(day)
    }
    removeWeekday(day: string) {
        this.applicableWeekdays.forEach( (item, i) => {
            if (item === day) {
              this.applicableWeekdays.splice(i, 1)
            }
        });
    }

    hasStructuralDifference(goal: NutritionalGoalV2) {
        var hasDiff = false
        if (this.getCalories() != goal.getCalories()) hasDiff = true
        if (this.getCarbohydrates() != goal.getCarbohydrates()) hasDiff = true
        if (this.getProtein() != goal.getProtein()) hasDiff = true
        if (this.getFat() != goal.getFat()) hasDiff = true
        if (this.applicableWeekdays != goal.applicableWeekdays) hasDiff = true
        return hasDiff
    }

    getAdjustedWithCalories(caloriesBurned: number) {
        var goal = this.clone()
        var calories = caloriesBurned * (goal.activityCaloriesMultiplier ?? 1)
        var distribution = goal.activityCaloriesDistribution?.map(x => x / 100)
        if (distribution == null || distribution.length != 3) distribution = [ goal.targetValues['carbohydrates'] * 4.1 / goal.targetValues['calories'], goal.targetValues['protein'] * 4.1 / goal.targetValues['calories'], goal.targetValues['fat'] * 9.3 / goal.targetValues['calories'] ]
        goal.targetValues['carbohydrates'] += distribution[0] * calories / 4.1
        goal.targetValues['protein'] += distribution[1] * calories / 4.1
        goal.targetValues['fat'] += distribution[2] * calories / 9.3
        goal.targetValues['calories'] += calories
        goal.targetValues['carbohydrates'] = Math.round(goal.targetValues['carbohydrates'])
        goal.targetValues['protein'] = Math.round(goal.targetValues['protein'])
        goal.targetValues['fat'] = Math.round(goal.targetValues['fat'])
        goal.targetValues['calories'] = Math.round(goal.targetValues['calories'])
        return goal
    }

    clone(): NutritionalGoalV2 {
        var nutritionalGoal = new NutritionalGoalV2()
        nutritionalGoal.date = this.date
        nutritionalGoal.targetValues = {}
        nutritionalGoal.targetValues['calories'] = this.targetValues['calories']
        nutritionalGoal.targetValues['carbohydrates'] = this.targetValues['carbohydrates']
        nutritionalGoal.targetValues['protein'] = this.targetValues['protein']
        nutritionalGoal.targetValues['fat'] = this.targetValues['fat']
        nutritionalGoal.targetValueTolerances = this.targetValueTolerances
        nutritionalGoal.adjustWithActivityCalories = this.adjustWithActivityCalories
        nutritionalGoal.applicableWeekdays = []
        this.applicableWeekdays.forEach(day => {
            nutritionalGoal.applicableWeekdays.push(day + '')
        })
        nutritionalGoal.goalId = this.goalId
        nutritionalGoal.situationId = this.situationId
        nutritionalGoal.activityCaloriesDistribution = this.activityCaloriesDistribution ? [...this.activityCaloriesDistribution] : null
        nutritionalGoal.activityCaloriesMultiplier = this.activityCaloriesMultiplier
        return nutritionalGoal
    }

    static fromDeprecated(old: NutritionalGoal): NutritionalGoalV2 {
        var nutritionalGoal = new NutritionalGoalV2()
        nutritionalGoal.date = old.date
        nutritionalGoal.targetValues = {}
        nutritionalGoal.targetValues['calories'] = old.calories
        nutritionalGoal.targetValues['carbohydrates'] = old.carbohydrates
        nutritionalGoal.targetValues['protein'] = old.protein
        nutritionalGoal.targetValues['fat'] = old.fat
        nutritionalGoal.adjustWithActivityCalories = old.adjustWithActivityCalories
        nutritionalGoal.applicableWeekdays = old.applicableWeekdays
        nutritionalGoal.goalId = old.goalId
        return nutritionalGoal
    }
    static fromDeprecatedWithSituation(old: NutritionalGoal, situation: Situation): NutritionalGoalV2 {
        var nutritionalGoal = new NutritionalGoalV2()
        nutritionalGoal.date = old.date
        nutritionalGoal.targetValues = {}
        nutritionalGoal.targetValues['calories'] = old.calories
        nutritionalGoal.targetValues['carbohydrates'] = old.carbohydrates
        nutritionalGoal.targetValues['protein'] = old.protein
        nutritionalGoal.targetValues['fat'] = old.fat
        nutritionalGoal.adjustWithActivityCalories = old.adjustWithActivityCalories
        nutritionalGoal.applicableWeekdays = old.applicableWeekdays
        if (nutritionalGoal.applicableWeekdays.length == 1 && nutritionalGoal.applicableWeekdays[0] == 'alt') nutritionalGoal.applicableWeekdays = ['all']
        nutritionalGoal.goalId = old.goalId
        nutritionalGoal.situation = situation
        nutritionalGoal.situationId = situation.id
        return nutritionalGoal
    }

    public static WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
}