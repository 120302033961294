<div class="dialog-topbar">
    <div class="icon-button float-right link" (click)="onCloseDialog()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        <ng-container *ngIf="!utilityService.onMobile()">
            Schließen
        </ng-container>
    </div>
</div>
<div class="dialog-content-wrapper">
    <div class="rpe-table layout-box sub-box">
        <div class="rpe-row header-row textalign-center">
            <div class="rpe-col">
                REPS
            </div>
        </div>
        <div class="rpe-row header-row">
            <div class="rpe-col">
                RPE
            </div>
            <div class="rpe-col">
                RIR
            </div>
            <ng-container *ngFor="let rep of repList">
                <div class="rpe-col">
                    {{rep}}
                </div>
            </ng-container>
        </div>

        <ng-container *ngFor="let iRpe of rpeList; index as index">
            <div class="rpe-row">
                <div class="rpe-col leading-col">
                    {{iRpe}}
                </div>
                <div class="rpe-col leading-col">
                    {{10 - iRpe}}
                </div>
                <ng-container *ngFor="let rep of repList; index as tableIndex">
                    <div class="rpe-col link {{rep == reps && iRpe == rpe ? 'bordered' : ''}}" ngxClipboard [cbContent]="getTableValue(tableIndex, index) | weightConversion: languageService.selectedUnitSystem: true" (click)="onClickValue(tableIndex, index, rep, iRpe)">
                        <div class="weight">
                            {{getTableValue(tableIndex, index) | weightConversion: languageService.selectedUnitSystem: true}}
                        </div>
                        <div class="percentage">
                            {{getPercentage(tableIndex, index).toFixed(1)}}%
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>

    <div class="value-input">
        <div class="valuefield-wrapper">
            <div class="label">Gewicht</div>
            <input class="input small form-control white-input input-noteunit" [(ngModel)]="weight" type="number">
        </div>
        <div class="valuefield-wrapper">
            <div class="label">Reps</div>
            <input class="input small form-control white-input input-noteunit" [(ngModel)]="reps" type="number">
        </div>
        <div class="valuefield-wrapper">
            <div class="label">RPE</div>
            <input class="input small form-control white-input input-noteunit" [(ngModel)]="rpe" type="number">
        </div>
        <div class="valuefield-wrapper">
            <div class="label">1RM</div>
            = {{getPrintableOneRm()}} {{languageService.getUnit()}}
        </div>
    </div>

    <div class="spacer-20"></div>
    <div class="textalign-center">
        <div class="label">
            Klicke auf einen Wert in der Tabelle, um das Gewicht in die Zwischenablage zu kopieren.<br>
            Halte dabei Strg gedrückt, um Gewicht, Wiederholungen und RPE/RIR zu übernehmen.
        </div>
    </div>
    <div class="spacer-20"></div>
</div>
