import { Injectable, NgZone } from '@angular/core';
import { FirestoreService } from './firestore.service';
import { EventLog, EventType, EventType2LabelMapping, EventTypeString2LabelMapping, SanitizedHtmlPipe } from '../model/event-log.model';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import { DropdownItem } from '../model/automatic-push-notification.model';
import { User } from '../model/user.model';
import { Metric } from '../model/metric.model';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class EventLogService {

  private users: User[] = [];
  private _allCoachUsers: boolean = true;

  public observableEventLogs: Observable<EventLog[]>;
  public eventLogs:EventLog[] = [];
  public filteredEventLogs = []
  private eventLogSubscription:Subscription;
  private observableClientsSubscription:Subscription;

  public selectableClients: DropdownItem[] = [];
  public selectableCoachGroups: DropdownItem[] = [];
  public selectableGroupNames: DropdownItem[] = [];

  public selectableEventTypes: DropdownItem[] =[];
  public allEventTypesChecked: boolean = false;

  public eventTypes = Object.values(EventType).filter(value => typeof value === 'string');
  public eventTypeLabels = Object.values(EventType2LabelMapping).filter(value => typeof value === 'string');
  public eventType2LabelMapping = EventType2LabelMapping;
  public eventTypeString2LabelMapping = EventTypeString2LabelMapping;

  public maxEventsPerPage = 2;
  public selectedDate:Date = new Date()

  public nextPageButtonVisible: boolean = false;

  public selectedEventTypes: EventType[] = [];

  public isBusy = true;


  // public filteredEventLogs:EventLog[] = [];

  // public spinner: NgxSpinnerService;


  constructor(public userService: FirestoreService, private router: Router, private zone: NgZone, private spinner: NgxSpinnerService, private translate: TranslateService) {
    this.refreshObservableEventLogs();
    this.observableClientsSubscription = this.userService.observableClients.subscribe(x => this.refreshSelectableClientsGroups())
    if(this.selectableGroupNames?.length > 0) this.selectableGroupNames[0].checked = true;
    this.refreshSelectableClientsGroups();

    this.userService.observableUser.subscribe(user => {
      firstValueFrom(this.userService.getAllCoachesByLicenceHolderUid(this.userService.getLoggedInUser().licenceHolderUid)).then(coaches => {
        try {
          this.selectableCoachGroups = []
          coaches.forEach(coach => {
            this.selectableCoachGroups.push(new DropdownItem(coach.uid, coach.name, true))
          })
          this.refreshSelectableClientsGroups()
        } catch(ex){
          console.error(ex);
        }
      })
    })
  }

  public setEventUsers(value: User[]){
    if (this.equals(this.users, value)) return;
    this.users = value;
    this.refreshSelectableClientsGroups()
    this.filterEventLogs()
  }

  public setAllCoachUsers(value: boolean){
    if(this._allCoachUsers === value) return;
    this._allCoachUsers = value;
    this.refreshSelectableClientsGroups()
    this.filterEventLogs()
  }


  refreshSelectableClientsGroups(){
    this.selectableClients = []
    this.selectableGroupNames = []
    this.selectableEventTypes = []
    this.eventTypes?.forEach(type => {
      if(!this.selectableEventTypes.map(x => x.name).includes(type.toString())){
        this.selectableEventTypes.push(new DropdownItem('', type.toString(), this.userService.getLoggedInUser()?.portalSettingsCoach?.selectedEventLogTypes?.length === 0 || this.userService.getLoggedInUser()?.portalSettingsCoach?.selectedEventLogTypes?.includes(type.toString())))
      }
    });
    this.allEventTypesChecked = (this.selectableEventTypes.filter(x => x.checked).length === this.selectableEventTypes.length)
    if (this._allCoachUsers) {
      this.selectableGroupNames.push(new DropdownItem('all', 'Alle', true));
      this.userService.getAccessibleClients().forEach(client => {
        if(!this.selectableClients.map(x => x.uid).includes(client.uid)) this.selectableClients.push(new DropdownItem(client.uid, client.getName(), true))
      });
      this.userService.getClientGroups().forEach(group => {
        if(!this.selectableGroupNames.map(x => x.name).includes(group)) this.selectableGroupNames.push(new DropdownItem('', group, true))
      });
    } else {
      this.users.forEach(client => {
        if(!this.selectableClients.map(x => x.uid).includes(client.uid)) this.selectableClients.push(new DropdownItem(client.uid, client.getName(), true))
      });
      if (!this.selectableGroupNames.map(x => x.uid).includes('all')) this.selectableGroupNames.push(new DropdownItem('all', 'Alle', true));
    }
  }

  refreshObservableEventLogs(){
    this.isBusy = true
    //this.spinner.show()
    this.filteredEventLogs = []
    this.observableEventLogs = this.userService.getEventLogs(this.userService.getLoggedInUser()?.coach?.licenceHolderUid, this.selectedDate)
    this.eventLogSubscription?.unsubscribe()
    this.eventLogSubscription = this.observableEventLogs.subscribe(
      eventLogs => this.eventLogsUpdate(eventLogs)
    )
  }

  eventLogsUpdate(eventLogs:EventLog[]){
    eventLogs.forEach(log => {
      if(this.eventLogs?.filter(x => x.eventItemsExpanded && x.id == log.id)?.length > 0) log.eventItemsExpanded = true
    });
    this.eventLogs = eventLogs
    this.filterEventLogs()
    this.isBusy = false
    //this.spinner.hide()
  }

  filterEventLogs() {
    var filteredEventLogs = []
    this.eventLogs.forEach(log => {
      if(this.validEvent(log)) filteredEventLogs.push(log)
    });
    this.filteredEventLogs = filteredEventLogs
    this.isBusy = false
    //this.spinner.hide()
  }

  private equals = (a, b) => a.length === b.length && a.every((v, i) => v === b[i]);

  onSelectedDateChanged(date: Date){
    this.selectedDate = date
    this.refreshObservableEventLogs()
  }
  public weekDays = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
  public static weekdays() {
    return this.weekdays
  }

  nextPage(){
    this.selectedDate = this.selectedDate.addDays(1)
    this.refreshObservableEventLogs()
  }
  prevPage(){
    this.selectedDate = this.selectedDate.addDays(-1)
    this.refreshObservableEventLogs()
  }


  getEventText(event: EventLog):string{
    var userName = this.getNameByUid(event.userUid)
    var formattedText = "";

    if(userName){
      formattedText += userName
    }
    formattedText += this.parseEventTypeToText(event.type, event);
    return formattedText
  }

  getPrintableSelectedEventTypes(){
    var selectedEventTypes = this.selectableEventTypes.filter(x => x.checked).map(x => x.name)
    if (selectedEventTypes?.length == this.selectableEventTypes?.length) return null
    return this.translate.instant('Ausgewählte Ereignisse')
  }

  onAllEventTypesCheckedChanged(){
    this.allEventTypesChecked = !this.allEventTypesChecked;
    this.selectableEventTypes.forEach(type => {
      type.checked = this.allEventTypesChecked;
    });
    this.filterEventLogs()
    this.updateSelectedEventTypesInPortalSettings()
  }

  onSelectedEventTypesChanged(eventTypeItem: DropdownItem, checked: boolean){
    eventTypeItem.checked = checked
    this.allEventTypesChecked = (this.selectableEventTypes.filter(x => x.checked).length === this.selectableEventTypes.length)
    this.filterEventLogs()
    this.updateSelectedEventTypesInPortalSettings()
  }

  updateSelectedEventTypesInPortalSettings(){
    if(this.allEventTypesChecked) this.userService.getLoggedInUser().portalSettingsCoach.selectedEventLogTypes = []
    else {
      this.userService.getLoggedInUser().portalSettingsCoach.selectedEventLogTypes = this.selectableEventTypes.filter(x => x.checked).map(x => x.name)
    }
    this.userService.updatePortalSettingsForCoach(this.userService.getLoggedInUser())
  }

  getPrintableFilteredClients():string{
    var checkedElements = '';
    if(this.selectableGroupNames?.filter(x => x.checked)?.map(x => x.name)?.includes("Alle")){
      if(this._allCoachUsers) return this.translate.instant('Alle Coachees')
      else return this.translate.instant('Alle Coachees')
    }
    else{
      this.selectableGroupNames.filter(x => x.checked).forEach(group => {
        if(checkedElements.length > 0){
          checkedElements = checkedElements.concat(', ')
        }
        checkedElements = checkedElements.concat(group.name);
      });
    }
    return checkedElements;
  }

  parseEventTypeToText(eventType: string, event: EventLog){
    switch(eventType) {
      case "progress": {
        switch(event.event){
          case "progress_tracked": {
            /*if(referenceDate){
              return " hat einen Fortschritt für den " + referenceDate?.asFormatedString() + " getrackt."
            }*/
            return " " + this.translate.instant("hat neue Fortschritte getrackt.")
          }
          case "checkin_completed": {
            /*if(referenceDate){
              return " hat einen Fortschritt für den " + referenceDate?.asFormatedString() + " getrackt."
            }*/
            return " " + this.translate.instant("hat einen Check-In absolviert.")
          }
        }
        break;
      }
      case "training": {
        switch(event.event){
          case "training_tracked": {
            return " " + this.translate.instant("hat ein Training absolviert.")
          }
          case "recordings_tracked": {
            return " " + this.translate.instant(" hat Trainingsvideos aufgenommen.")
          }
        }
         break;
      }
      case "nutrition": {
        switch(event.event){
          case "meal_tracked": {
            if(event.referenceDate){
                return " " + this.translate.instant("hat eine Mahlzeit für den {{dateParameter}} getrackt.", { dateParameter: event.referenceDate?.asFormatedString() });
            }
            return " " + this.translate.instant("hat eine Mahlzeit getrackt.")
          }
        }
         break;
      }
      case "administration":{
        switch(event.event){
          case "licence_redeemed":{
            return " " + this.translate.instant("hat eine Lizenz eingelöst.")
          }
        }
      }
      default: {
         return "";
      }
   }
  }

  getEventItemText(eventItemKey: string, eventItemValue: any):string{
    var formattedText = "";
    var rawName = eventItemKey;
    var value = eventItemValue;

    if (rawName == 'sessionName') {
      formattedText = this.translate.instant('Einheit:') + " " + eventItemValue
    } else if (rawName == 'duration') {
      formattedText = this.translate.instant('Dauer:') + ' ' + eventItemValue + ' min'
    } else if (rawName == 'caloriesBurned') {
      formattedText = this.translate.instant('Kalorienverbrauch:') + ' ' + eventItemValue + ' kcal'
    } else if (rawName == 'numberOfRecordings') {
      formattedText = this.translate.instant('Anzahl Aufnahmen:') + ' ' + eventItemValue
    } else if (rawName == 'trackedSessionId') {
      return ''
    } else if (rawName == 'questionaireName') {
      formattedText = this.translate.instant('Check-In:') + ' ' + eventItemValue
    } else if (rawName == 'assignedQuestionaireId') {
      return ''
    } else if (rawName.includes("metricId")){
      var metricId = rawName.replace("metricId_", "");
      var metric = this.userService.metrics.filter(m => m.id == metricId)[0]
      if (metric) {
        formattedText += EventLogService.getEventItemMetricIcon(metric)
        formattedText += metric?.nameTranslation?.GetValue(this.translate.currentLang) ?? ""
        if(value !== null){
          var parsedValue = this.getPrintableValue(metric, value);
          if (parsedValue !== null) {
            formattedText += ": " + parsedValue
            if(metric.unit) formattedText += " " + metric.unit
          }
        }
      }
    } else {
      formattedText += '<div class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-speedometer2" viewBox="0 0 16 16"><path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z"/><path fill-rule="evenodd" d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"/></svg></div>'
      var itemObj = this.notMetricEventItem.get(rawName)
      if(itemObj){
        formattedText += this.translate.instant(itemObj.name)
        if(value) {
          if(rawName === "bodyFat"){
            formattedText += ": " + (Number(value) * 100);
          }
          else{
            formattedText += ": " + value;
          }
          formattedText += " " + itemObj.unit
        }
      }
      else{
        formattedText += rawName
        if(value) formattedText += ": " + value
      }
    }
    if(formattedText.length === 0) formattedText = this.translate.instant("Unbekannte Metrik")

    return formattedText;
  }

  public static getEventItemMetricIcon(metric: Metric):string{
    var html = ""
    if(metric?.isMetricTypeText()) {
      html = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sticky" viewBox="0 0 16 16"><path d="M2.5 1A1.5 1.5 0 0 0 1 2.5v11A1.5 1.5 0 0 0 2.5 15h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 15 8.586V2.5A1.5 1.5 0 0 0 13.5 1h-11zM2 2.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5V8H9.5A1.5 1.5 0 0 0 8 9.5V14H2.5a.5.5 0 0 1-.5-.5v-11zm7 11.293V9.5a.5.5 0 0 1 .5-.5h4.293L9 13.793z"/></svg>'
    }
    else if (metric?.isMetricTypeNumber()){
      html = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-speedometer2" viewBox="0 0 16 16"><path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z"/><path fill-rule="evenodd" d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"/></svg>'
    }
    else if (metric?.isMetricTypeSelection()){
      html = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/></svg>'
    }
    else if (metric?.isMetricTypeYesNo()){
      html = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16"><path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/></svg>'
    }
    else if (metric?.isMetricTypeDuration() || metric?.isMetricTypeDurationWithSeconds()){
      html = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-history" viewBox="0 0 16 16"><path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/><path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/><path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/></svg>'
    }
    else if (metric?.isMetricTypeImage()){
      html = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-image" viewBox="0 0 16 16"><path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/><path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/></svg>'
    }
    else if (metric?.isMetricTypeVideo()){
      html = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera-video" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"/></svg>'
    }
    else if (metric?.isMetricTypeMultiselect()){
      html = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/></svg>'
    }
    else if (metric?.isMetricTypeToDo()){
      html = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16"><path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/></svg>'
    }
    return '<div class="icon">'+ html+'</div>'
  }

  notMetricEventItem = new Map<string, any>([
    ["bodyWeight", {name: marker("Gewicht"), unit: "kg"}],
    ["bodyWeightWeeklyAverage", {name: marker("∅-Gewicht"), unit: "kg"}],
    ["calories", {name: marker("Kalorien"), unit: "kcal"}],
    ["carbohydrates", {name: marker("Kohlenhydrate"), unit: "g"}],
    ["protein", {name: marker("Eiweiß"), unit: "kcal"}],
    ["fat", {name: marker("Fett"), unit: "g"}],
    ["sugar", {name: marker("Zucker"), unit: "g"}],
    ["saturatedFat", {name: marker("Gesättigte Fetts."), unit: "g"}],
    ["fibre", {name: marker("Ballaststoffe"), unit: "g"}],
    ["salt", {name: marker("Salz"), unit: "g"}],
    ["hipCircumference", {name: marker("Hüftumfang"), unit: "cm"}],
    ["waistCircumference", {name: marker("Taillenumfang"), unit: "cm"}],
    ["chestCircumference", {name: marker("Brustumfang"), unit: "cm"}],
    ["leftArmCircumference", {name: marker("Armumfang links"), unit: "cm"}],
    ["rightArmCircumference", {name: marker("Armumfang rechts"), unit: "cm"}],
    ["leftThighCircumference", {name: marker("Beinumfang links"), unit: "cm"}],
    ["rightThighCircumference", {name: marker("Beinumfang rechts"), unit: "cm"}],
    ["leftCalfCircumference", {name: marker("Wadenumfang links"), unit: "cm"}],
    ["rightCalfCircumference", {name: marker("Wadenumfang rechts"), unit: "cm"}],
    ["bellyCircumference", {name: marker("Bauchumfang"), unit: "cm"}],
    ["seatCircumference", {name: marker("Gesäßumfang"), unit: "cm"}],
    ["bodyFat", {name: marker("KFA"), unit: "%"}],
    ["bmi", {name: marker("BMI"), unit: ""}],
    ["sleepDuration", {name: marker("Schlafdauer"), unit: "h"}],
    ["waterIntake", {name: marker("Wasserzufuhr"), unit: "l"}],
  ]);

  getPrintableValue(metric: Metric, value: any): string {
    if (metric.metricType == 'TEXT') return value;
    if (metric.metricType == 'SELECTION') return metric.getSelectableValues(this.translate.currentLang)[value as number];
    if (metric.metricType == 'DURATION') {
        var sleepDuration = value as number
        return Math.floor(sleepDuration / 60) + ':' + ((sleepDuration % 60) < 10 ? '0' : '') + (sleepDuration % 60) + ' h'
    }
    if (metric.metricType == 'DURATION_SECONDS') {
      var sleepDuration = (value as number).roundToPlaces(0)
      var seconds = sleepDuration % 60
      var minutes = Math.floor(sleepDuration / 60) % 60
      var hours = Math.floor(sleepDuration / 3600)
      return hours + ':' + (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds + ' s'
  }
    if (metric.metricType == 'YES_NO') return value ? this.translate.instant("Ja") : this.translate.instant("Nein");
    if (metric.metricType == 'IMAGE') return value;
    if (metric.metricType == 'TODO') return value ? this.translate.instant("Erledigt") : "-";
    return value
  }

  onFilterSelectionChangedUser(client: DropdownItem, checked: boolean){
    client.checked = checked;
    if(this.selectableClients?.filter(x => x.checked).length !== this.selectableClients?.length){
      if(this.selectableGroupNames?.length > 0) this.selectableGroupNames[0].checked = false;
    }
    this.updateAllClientsSelected()
    this.filterEventLogs()
  }

  onFilterSelectionChangedGroup(clientGroup: DropdownItem, checked: boolean){
    clientGroup.checked = checked;
    if (clientGroup.uid === 'all') {
      // this.selectableClients.forEach(client => {
      //   client.checked = checked;
      // });
      this.selectableGroupNames.forEach(group => {
        group.checked = checked;
      })
      this.selectableCoachGroups.forEach(group => {
        group.checked = checked;
      })
    }
    if (this.selectableGroupNames?.filter(x => x.checked)?.length !== this.selectableGroupNames?.length || this.selectableCoachGroups?.filter(x => x.checked)?.length !== this.selectableCoachGroups?.length) {
      if (this.selectableGroupNames?.length > 0) this.selectableGroupNames[0].checked = false;
    }
    this.updateAllClientsSelected()
    this.filterEventLogs()
  }

  onFilterSelectionChangedCoachGroup(clientGroup: DropdownItem, checked: boolean) {
    clientGroup.checked = checked;
    if (this.selectableGroupNames?.filter(x => x.checked)?.length !== this.selectableGroupNames?.length || this.selectableCoachGroups?.filter(x => x.checked)?.length !== this.selectableCoachGroups?.length) {
      if (this.selectableGroupNames?.length > 0) this.selectableGroupNames[0].checked = false;
    }
    this.updateAllClientsSelected()
    this.filterEventLogs()
  }

  onMarkAllAsReadSelected(){
    this.filteredEventLogs.filter(x => x.read == false).forEach(eventLog => {
      eventLog.read = true;
      this.userService.updateReadEventLog(eventLog);
    });
  }

  updateAllClientsSelected() {
    if(this.selectableGroupNames[0].checked) return;
    // this.selectableGroupNames[0].checked = (this.selectableGroupNames.filter(x => x.checked).length === this.selectableGroupNames.length-1) && (this.selectableClients.filter(x => x.checked).length === this.selectableClients.length);
    this.selectableGroupNames[0].checked = (this.selectableGroupNames.filter(x => x.checked).length === this.selectableGroupNames.length - 1 && this.selectableCoachGroups.filter(x => x.checked).length === this.selectableCoachGroups.length);
  }


  validEvent(eventLog: EventLog):boolean{
    var result:boolean = false;
    if(this.getNameByUid(eventLog.userUid) == null) return false;
    if(!this._allCoachUsers && !this.users?.map(x => x.uid)?.includes(eventLog.userUid)) return false;
    if(!this.selectableEventTypes?.filter(x => x.checked)?.map(x => x.name)?.includes(eventLog.event)) return false;
    if(this.selectableGroupNames?.filter(x => x.checked)?.map(x => x.uid)?.includes("all")) return true;
    if (this.selectableCoachGroups?.filter(x => x.checked)?.map(x => x.uid)?.includes(eventLog.coachUid)) {
      return true
    }
    console.log(result)
    // if(this.selectableClients.filter(x => x.checked).map(x => x.uid).includes(eventLog.userUid)) return true;
    if(this._allCoachUsers) {
      console.log('All')
      if (this.selectableGroupNames.filter(x => x.checked).length === this.selectableGroupNames.length - 1) return true
      this.userService.getAccessibleClients().filter(x => x.uid === eventLog.userUid)[0]?.metadataUser?.assignedClientGroups?.forEach(group => {
        if(this.selectableGroupNames?.filter(x => x.checked)?.map(x => x.name.toString()).includes(group.toString()))
        {
          result = true;
        }
      })
    }
    return result;
  }

  parseEventItem(name:string):string{
    if(name.includes("metricId")){
      var metricId = name.replace("metricId_", "");
      var metric = this.userService.metrics.filter(m => m.id == metricId)[0]
      if(metric){
        return metric?.nameTranslation?.GetValue(this.translate.currentLang);
      }
    }
    else {
      return name;
    }
  }


  routeToTarget(event: EventLog){
    if(!event.read){
      event.read = true;
      this.userService.updateReadEventLog(event)
    }
    if(event.type == 'progress' && event.eventItems['assignedQuestionaireId'] != null){
        if(event.userUid) this.router.navigateByUrl('client/' + event.userUid + `?assignedQuestionaire=${event.eventItems['assignedQuestionaireId']}`)
    }
    else if(event.type == 'training' && event.eventItems['trackedSessionId'] != null){
      if(event.userUid) this.router.navigateByUrl('client/' + event.userUid + `?trackedSession=${event.eventItems['trackedSessionId']}`)
    }
    else {
      if(event.userUid) this.router.navigateByUrl('client/' + event.userUid)
    }
  }

  toggleEventItemsCollapse(event: EventLog){
    if(!event.read){
      event.read = true;
      this.userService.updateReadEventLog(event)
    }
    if(event?.eventItems == null) {
      this.router.navigateByUrl('client/' + event?.userUid)
      return;
    }
    event.eventItemsExpanded = !event.eventItemsExpanded
  }

  getNameByUid(uid: string):string{
    return this.userService.getAccessibleClients()?.filter(x => x.uid == uid)[0]?.getName();
  }
}
