import { Injectable } from '@angular/core';
import { Configuration, OpenAIApi } from "openai";
import { MergedTrainingExercise } from '../model/training-exercise';
import { NgxSpinnerService } from 'ngx-spinner';
import { FirestoreService } from './firestore.service';
import { Recipe } from '../model/recipe.model';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private spinner: NgxSpinnerService, private userService: FirestoreService) {
    var subscription = userService.observableUser.subscribe(user => {
      if (user) {
        this.selectedUnitSystem = user.coach?.unitSystem || 'metric'
        subscription.unsubscribe()
      }
    })
  }

  public selectedLanguageCode: string = 'de';
  public selectedUnitSystem: string = 'metric'; // metric or imperial

  getUnit() {
    return this.selectedUnitSystem === 'metric' ? 'kg' : 'lbs'
  }

  getPrintableUnitSystem() {
    return this.selectedUnitSystem === 'metric' ? 'Metrisch' : 'Imperial'
  }
  onSelectUnitSystem(unitSystem: string) {
    this.selectedUnitSystem = unitSystem
    this.userService.getLoggedInUser().coach.unitSystem = unitSystem
    this.userService.updateUnitSystem(this.userService.getLoggedInUser())
  }

  public setSelectedLanguageCode(code: string){
    this.selectedLanguageCode = code;
  }

  public availableLanguages: any[] = [
    { code: 'de', name: 'Deutsch' },
    { code: 'en', name: 'English' },
  ];

  public switchLanguage(){
    if(this.selectedLanguageCode === 'de'){
      this.selectedLanguageCode = 'en';
    } else {
      this.selectedLanguageCode = 'de';
    }
  }
  selectLanguage(code: string){
    this.selectedLanguageCode = code;
  }

  public get isLanguageEnglish(){
    return this.selectedLanguageCode === 'en';
  }

  public get isLanguageGerman(){
    return this.selectedLanguageCode === 'de';
  }
  get oppositeLanguageCode(){
    return this.isLanguageEnglish ? 'de' : 'en';
  }
  
  // open ai

  async translateRecipeName(recipe: Recipe){
    this.spinner.show();
    const command = this.isLanguageEnglish ? `Translate the following recipe name to english: ${recipe.nameTranslation[this.oppositeLanguageCode]}.`:
    `Übersetze den folgenden Rezepznamen auf deutsch: ${recipe.nameTranslation[this.oppositeLanguageCode]}.`;
    recipe.nameTranslation[this.selectedLanguageCode] = await this.runOpenAICommand(command);
    this.spinner.hide();
  }

  async translateRecipeInstructions(recipe: Recipe){
    this.spinner.show();
    const command = this.isLanguageEnglish ? `Translate the following recipe instructions to english: ${recipe.instructionsTranslation[this.oppositeLanguageCode]}.`:
    `Übersetze die folgenden Rezeptanweisungen auf deutsch: ${recipe.instructionsTranslation[this.oppositeLanguageCode]}.`;
    recipe.instructionsTranslation[this.selectedLanguageCode] = await this.runOpenAICommand(command);
    this.spinner.hide();
  }


  async generateInstructionsForExercise(exerciseName: string, exerciseSubName?: StringConstructor){
    const command = this.isLanguageEnglish ? `Act as a fitness trainer. Generate instructions for the exercise ${exerciseName}${exerciseSubName ? `(${exerciseSubName})` : ''}. Structure them with the two sections '**Set-Up**' and '**Exercise Execution**'. Use at most 5 steps per section. Use simple words. Dont write an introduction and dont write additional infos at the end`
     : 
     `Act as a fitness trainer. Generiere Anweisungen für die Übung ${exerciseName}${exerciseSubName ? ` (${exerciseSubName})` : ''}. Strukturiere sie mit den beiden Abschnitten '**Set-Up:**' und '**Übungsausführung:**'. Verwende maximal 5 Schritte pro Abschnitt. Verwende einfache Wörter. Schreibe keine Einleitung und schreibe keine zusätzlichen Informationen am Ende.`;
    return await this.runOpenAICommand(command);
  }

  async translateOrGenerateInstructionsForExercise(exercise: MergedTrainingExercise){
    this.spinner.show();
    let oppositeLanguage = this.isLanguageEnglish ? 'de' : 'en';
      if(exercise.instructions.GetValue(oppositeLanguage)){
        exercise.instructions[this.selectedLanguageCode] = await this.translateExerciseInstruction(exercise.instructions.GetValue(oppositeLanguage));
      }
      else {
        exercise.instructions[this.selectedLanguageCode] = await this.generateInstructionsForExercise(exercise.name.GetValue(this.selectedLanguageCode));
      }
    this.spinner.hide();
  }


  async translateExerciseInstruction(instruction: string){
    const command = this.isLanguageEnglish ? `Act as a fitness trainer. Translate the following exercise instructions to english: ${instruction}.`:
    `Act as a fitness trainer. Übersetze die folgenden Übungsanweisungen auf deutsch: ${instruction}.`;
    return await this.runOpenAICommand(command);
  }



  async runOpenAICommand (command: string):Promise<string> {
    console.log(command)
    if(!this.userService.getLoggedInUser().chatGenerationKey){
      return "";
    }
    try {
      const configuration = new Configuration({
        organization: "org-SuuOjptvkK9i4dHXRjZp7GWP",
          apiKey: this.userService.getLoggedInUser().chatGenerationKey,
      });
      const openai = new OpenAIApi(configuration);
      let result = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [{role: "user", content: command}],
      });
  
      console.log(result)
      return result.data?.choices[0]?.message?.content || '';
    } catch (error) {
      console.log(error)
    }
    return "";
  }

}