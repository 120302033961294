import {Metric} from "./metric.model";
import {User} from "./user.model";
import * as moment from "moment";

export enum GoalPlanType {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  PROGRESS = 'progress',
}

export enum WeeklyAggregation {
  SUM = 'sum',
  AVG = 'average'
}

export type GoalPlanMap = Map<string, GoalPlan>;

export type GoalStepModel =  {
  date: Date;
  value: number | null;
  minValue: number | null;
  maxValue: number | null;
  name: string;
  showInApp: boolean;
}

export class GoalStep  {
  date: Date;
  value: number | null;
  minValue: number | null;
  maxValue: number | null;
  name: string;
  showInApp: boolean;

  public static FromFirebase(json) {
    //TODO type safety
    return new GoalStep({
      date: json.dateString,
      value: json.value,
      minValue: json.minValue,
      maxValue: json.maxValue,
      name: json.name,
      showInApp: json.showInApp
    });
  }

  constructor();
  constructor(init: GoalStep | GoalStepModel);
  constructor(init?: GoalStep | GoalStepModel) {
    this.date = init && init.date ? new Date(init.date) : null;
    this.name = init && init.name || null;
    this.value = init && init.value !== null ? init.value : null;
    this.minValue = init && init.minValue !== null ? init.minValue : null;
    this.maxValue = init && init.maxValue !== null ? init.maxValue : null;
    this.showInApp = !!init?.showInApp;
  }

  public getGoalValue(direction: number): number {
    return this.value ?? (direction > 0 ? this.maxValue : this.minValue);
  }

  public getValue(): number | string | null {
    if(this.value != null) return this.value;
    if(this.minValue != null && this.maxValue != null) return this.minValue + '-' + this.maxValue;
    return null;
  }

  public isEqual(step: GoalStep): boolean {
    return step.name == this.name &&
    step.minValue == this.minValue &&
    step.maxValue == this.maxValue &&
    step.showInApp == this.showInApp &&
    step.date == this.date;
  }
}

export type GoalPlanConfig = {
  isNew: boolean;
  type: GoalPlanType;
  metric: Metric | null;
  goalPlan?: GoalPlan | null;
  user: User;
}

export type GoalPlanModel = {
  id: string;
  name: string;
  type: GoalPlanType
  metric: Metric;
  startDate: string;
  endDate: string | null;
  goalSteps: GoalStep[];
  showInApp: boolean;
  deleted: boolean;
  aggregation: WeeklyAggregation | null;
  showOnCoacheeDashboard: boolean;
  scheduledPushNotificationIds: string[];
}

export class GoalPlan {
  id: string;
  name: string;
  type: GoalPlanType
  metric: Metric;
  startDate: Date;
  endDate: Date | null;
  goalSteps: GoalStep[];
  showInApp: boolean;
  deleted: boolean;
  aggregation: WeeklyAggregation | null;
  showOnCoacheeDashboard: boolean;
  scheduledPushNotificationIds: string[];

  static numberFormatter = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  public static FromFirebase(json) {
    //TODO type safety
    return new GoalPlan({
      id: json.id,
      name: json.name,
      type: json.type,
      // metric muss beim Aufruf von FromFirebase dem json künstlich hinzugefügt werden
      metric: json.metric,
      startDate: json.startDateString,
      endDate: json.endDateString,
      goalSteps: json.goalSteps.map(stepJson => GoalStep.FromFirebase(stepJson)),
      showInApp: json.showInApp,
      deleted: json.deleted,
      aggregation: json.aggregation,
      showOnCoacheeDashboard: json.showOnCoacheeDashboard,
      scheduledPushNotificationIds: json.scheduledPushNotificationIds,
    });
  }

  constructor();
  constructor(init: GoalPlan | GoalPlanModel);
  constructor(init?: GoalPlan | GoalPlanModel) {
    this.id = init && init.id || null;
    this.name = init && init.name || null;
    this.type = init && init.type || GoalPlanType.PROGRESS;
    this.metric = init && init.metric || null;
    this.startDate = init && init.startDate ? new Date(init.startDate) : new Date();
    this.endDate = init && init.endDate ? new Date(init.endDate) : null;
    this.goalSteps = init && init.goalSteps.map(step => new GoalStep(step)) || [];
    this.showInApp = !!init?.showInApp;
    this.deleted = !!init?.deleted;
    this.aggregation = init && init.aggregation || null;
    this.showOnCoacheeDashboard = !!init?.showOnCoacheeDashboard;
    this.scheduledPushNotificationIds = init && init.scheduledPushNotificationIds || [];
  }

  public getGoal() {
    const firstStep = this.getFirstStep();
    const endStep = this.getEndStep();
    if(firstStep == endStep) {
      return endStep.getGoalValue(0);
    }
    return this.getGoalFromTo(firstStep, endStep);
  }

  public getGoalFromTo(from: GoalStep, to: GoalStep) {
    // TODO
    // Direction is basically the difference...
    const direction = this.getDirectionFromTo(from, to);
    const endValue = to.getGoalValue(direction);
    if(from == to) {
      return to.getGoalValue(0);
    }
    return Math.abs(endValue - from.value);
  }

  public getCurrentGoal(): string {
    let info = "Ziel abgelaufen";
    const activeStep = this.getActiveStep();
    if (activeStep) {
      if (activeStep.value) {
        info = `${GoalPlan.numberFormatter.format(activeStep.value)} ${this.metric.unit ?? ""}`;
        if (this.metric.isMetricTypeDuration()) {
          const dur = moment.utc(moment.duration(activeStep.value, "minutes").as('milliseconds')).format('HH:mm');
          info = `${dur} h`;
        }
      } else {
        info = `${GoalPlan.numberFormatter.format(activeStep.minValue)}-${GoalPlan.numberFormatter.format(activeStep.maxValue)} ${this.metric.unit ?? ""}`;
        if (this.metric.isMetricTypeDuration()) {
          const minDur = moment.utc(moment.duration(activeStep.minValue, "minutes").as('milliseconds')).format('HH:mm');
          const maxDur = moment.utc(moment.duration(activeStep.maxValue, "minutes").as('milliseconds')).format('HH:mm');
          info = `${minDur}h - ${maxDur}h`;
        }
      }
    }
    return info;
  }

  /**
   * Wenn kein active Step da ist, wird der letzte Step zurückgegeben
   * @param date
   */
  public getActiveStep(date?: Date): GoalStep {
    const today = date ? moment(date) : moment();

    const activeStep = this.goalSteps.find((step: GoalStep, index, steps) => {
      if(today.isBefore(step.date, "day")) return false;

      const nextStep = steps.length > index + 1 ? steps[index + 1] : null;
      if(!nextStep) {
        return !(this.endDate && today.isAfter(this.endDate, "day"));
      }

      return !today.isSameOrAfter(nextStep.date, "day");
    });

    return activeStep || this.getEndStep();
  }

  public getNextStep(step?: GoalStep): GoalStep {
    const activeStep = step || this.getActiveStep();
    if (!activeStep) return this.getEndStep();

    const index = this.goalSteps.indexOf(activeStep);
    if (index >= this.goalSteps.length-1) return this.getEndStep();
    return this.goalSteps[index + 1];
  }

  public getFirstStep(): GoalStep {
    return this.goalSteps[0];
  }

  public getEndStep(): GoalStep {
    return this.goalSteps[this.goalSteps.length - 1];
  }

  public isActive(weekDate?: Date): boolean {
    const dateMoment = moment(weekDate);
    if(this.type == GoalPlanType.WEEKLY) {
      return dateMoment.isSameOrAfter(this.startDate, "week") && !(this.endDate && dateMoment.isAfter(this.endDate));
    }
    if(dateMoment.isBefore(this.startDate)) return false;
    return !(this.endDate && dateMoment.isAfter(this.endDate));
  }

  public isActiveBetween(startDate: Date, endDate: Date): boolean {
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);

    if(!this.endDate) return true;
    const planMoment = moment(this.endDate);

    if(planMoment.isAfter(endMoment)) return true;

    return planMoment.isBetween(startMoment, endMoment, "day", "[]");
  }

  /**
   * Berechnet sich immer aus Step und NextStep
   * - Wenn < 0 dann Zielzunahme
   * - Wenn = 0 dann konstant
   * - Wenn > 0 dann Zielabnahme
   */
  public getDirection(step?: GoalStep) {
    const fromStep = step || this.getFirstStep();
    return this.getDirectionFromTo(fromStep, this.getNextStep(step));
  }

  /**
   * Berechnet sich immer aus FromStep und ToStep
   * - Wenn < 0 dann Zielzunahme
   * - Wenn = 0 dann konstant
   * - Wenn > 0 dann Zielabnahme
   */
  public getDirectionFromTo(fromStep: GoalStep, toStep: GoalStep): number {
    const stepValue = fromStep.value ?? (fromStep.minValue + fromStep.maxValue)/2;
    const nextStepValue = toStep.value ?? (toStep.minValue + toStep.minValue)/2;

    return stepValue - nextStepValue;
  }

  public toFireStore() {
    return  {
      name: this.name,
      type: this.type,
      metricId: this.metric.metricId,
      startDateString: this.startDate.getTimestampStringWithoutTime(),
      endDateString: this.endDate ? this.endDate.getTimestampStringWithoutTime() : null,
      showInApp: this.showInApp,
      showOnCoacheeDashboard: this.showOnCoacheeDashboard,
      deleted: this.deleted,
      aggregation: this.aggregation,
      goalSteps: this.goalSteps.map(step => {
        return {
          dateString: step.date.getTimestampStringWithoutTime(),
          value: step.value,
          minValue: step.minValue,
          maxValue: step.maxValue,
          name: step.name,
          showInApp: step.showInApp,
        };
      }),
      scheduledPushNotificationIds: this.scheduledPushNotificationIds,
    };
  }
}
