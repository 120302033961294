<div *ngIf="displayedUser" class="page-content">
    <div class="container-fluid" *ngIf="loaded">
        <div *ngIf="utilityService.onMobile()" class="textalign-center">
            <div class="client-sub-navigation">
                <ul>
                    <li *ngIf="userService.getLoggedInUser()?.isTrainingEnabled()" class="nav-item sub-item {{isClientsTrainingMenuItemActive() ? 'active' : ''}}">
                        <button class="menuitem-button" (click)="openTraining()">
                            <div class="icon">
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_72_4122)">
                                    <path d="M22 12.05H23.5M9.25 12.05H15.25M1 12.05H2.5M19 7.55005H21.25C21.4489 7.55005 21.6397 7.62907 21.7803 7.76972C21.921 7.91037 22 8.10114 22 8.30005V15.8C22 15.999 21.921 16.1897 21.7803 16.3304C21.6397 16.471 21.4489 16.55 21.25 16.55H19M5.5 16.55H3.25C3.05109 16.55 2.86032 16.471 2.71967 16.3304C2.57902 16.1897 2.5 15.999 2.5 15.8V8.30005C2.5 8.10114 2.57902 7.91037 2.71967 7.76972C2.86032 7.62907 3.05109 7.55005 3.25 7.55005H5.5M16 5.30005H18.25C18.6642 5.30005 19 5.63584 19 6.05005V18.05C19 18.4643 18.6642 18.8 18.25 18.8H16C15.5858 18.8 15.25 18.4643 15.25 18.05V6.05005C15.25 5.63584 15.5858 5.30005 16 5.30005ZM6.25 5.30005H8.5C8.91421 5.30005 9.25 5.63584 9.25 6.05005V18.05C9.25 18.4643 8.91421 18.8 8.5 18.8H6.25C5.83579 18.8 5.5 18.4643 5.5 18.05V6.05005C5.5 5.63584 5.83579 5.30005 6.25 5.30005Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_72_4122">
                                    <rect width="24" height="24" fill="white" transform="translate(0 0.300049)"/>
                                    </clipPath>
                                    </defs>
                                </svg>    
                            </div>                 
                        </button>
                    </li>
                    <li class="nav-item sub-item {{isClientsAnalyticsMenuItemActive() ? 'active' : ''}}">
                        <button class="menuitem-button" (click)="openAnalytics()">
                            <div class="icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21 20H3V5M21.0002 9.5L15.0002 14.75L9.00019 10.25L3.00019 15.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </button>
                    </li>
                    <li class="nav-item sub-item {{isClientsNutritionMenuItemActive() ? 'active' : ''}}">
                        <button class="menuitem-button" (click)="openNutrition()">
                            <div class="icon">
                                <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.875 8.875V19M4.875 8.875C5.77011 8.875 6.62855 8.51942 7.26149 7.88649C7.89442 7.25355 8.25 6.39511 8.25 5.5L7.5 1M4.875 8.875C3.97989 8.875 3.12145 8.51942 2.48851 7.88649C1.85558 7.25355 1.5 6.39511 1.5 5.5L2.25 1M16.125 13H10.875C10.875 13 12 2.5 16.125 1V19M4.875 1V4.75" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </button>
                    </li>
                </ul>
            </div>
        </div>

        <ng-template #profileBlock>
            <div class="layout-box profile-container">
                <div class="profile-row">
                    <div class="profile-picture">
                        <img draggable="false" *ngIf="displayedUser?.profilePictureUrl" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{displayedUser?.profilePictureUrl}}">
                        <div *ngIf="!displayedUser?.profilePictureUrl">
                            <img draggable="false" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{ (userService.getProfilePictureForUser(displayedUser) | async) }}">
                        </div>
                      </div>
                    <div class="profile-text {{displayedUser.hasOtherLanguageSettings() ? ' has-languagesettings' : ''}}">
                        <h3 class="profile-name">{{displayedUser.getName()}}</h3>
                        <div *ngIf="displayedUser.hasOtherLanguageSettings()" tooltip="Dein Coachee hat eine andere App-Sprache eingestellt."><img class="language-flag" src="assets/icons/icon-united-kingdom.png" alt=""></div>
                    </div>
                    <button *ngIf="userService.getAccessibleClients()?.length > 0" class="button change-client p-0" id="dropdownClients" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownClients">
                        <div *ngFor="let client of userService.getAccessibleClients()">
                            <button class="dropdown-item {{displayedUser?.uid == client?.uid ? 'selected' : ''}}" type="button" routerLink="/client/{{client?.uid}}">{{client.getName()}}</button>
                        </div>
                    </div>
                </div>
                <div class="profile-information display-block">
                    <div *ngIf="displayedUser.nextCompetitionDate">
                        Nächster Wettkampf: {{displayedUser.nextCompetitionDate?.asFormatedString()}}
                    </div>
                    <div>
                        {{displayedUser?.getPrintableTrainingPlan() ?? 'Kein Training geplant'}}{{displayedUser?.getPrintableCurrentTrainingWeek() ?? ''}}{{displayedUser?.getPrintableLastTrainingPlanDate()}}
                    </div>
                </div>
                <div class="spacer-10"></div>
                <button class="button-filled small light" (click)="openUserProfile()">Athleten-Einstellungen</button>
            </div>
        </ng-template>

        <div class="viewport" *ngIf="utilityService.onNativeMobileApp()">
            <ul class="list">
              <li class="item">
                <ng-template [ngTemplateOutlet]="profileBlock"></ng-template>
              </li>
              <li class="item">
                <div class="layout-box quicknote-container">
                    <app-quick-note-view [setUser]="displayedUser"></app-quick-note-view>
                </div>
              </li>
              <li class="item">
                <div class="layout-box onerm-container">
                    <app-one-rm-editor [User]="displayedUser" [TrainingPlan]="selectedTrainingPlan"></app-one-rm-editor>
                </div>
              </li>
            </ul>
            <ul class="indicatorsList" aria-hidden>
              <li class="indicator"></li>
              <li class="indicator"></li>
              <li class="indicator"></li>
            </ul>
        </div>
        <div class="row" *ngIf="!utilityService.onNativeMobileApp()">
            <div class="col-12 col-md-6 col-lg-3 order-md-2 order-lg-3">
                <ng-template [ngTemplateOutlet]="profileBlock"></ng-template>
            </div>
            <div class="col-12 col-md-6 col-lg-3 order-md-1 order-lg-1">
                <div class="layout-box quicknote-container">
                    <app-quick-note-view [setUser]="displayedUser"></app-quick-note-view>
                </div>
            </div>
            <div class="col-12 col-lg-6 order-md-3 order-lg-2" *ngIf="isClientsNutritionMenuItemActive()">
            </div>
            <div class="col-12 col-lg-6 order-md-3 order-lg-2" *ngIf="isClientsTrainingMenuItemActive()">
                <div class="layout-box onerm-container">
                    <app-one-rm-editor [User]="displayedUser" [TrainingPlan]="selectedTrainingPlan"></app-one-rm-editor>
                </div>
            </div>
            <div class="col-12 col-lg-6 order-md-3 order-lg-2" *ngIf="isClientsAnalyticsMenuItemActive()">
                <div class="layout-box onerm-container">
                    <h3 class="display-inlineblock">Check-Ins</h3>
                    <app-checkin-assignment-view [User]="displayedUser"></app-checkin-assignment-view>
                </div>
            </div>
        </div>

        <ng-container *ngIf="isClientsTrainingMenuItemActive()">
            <div class="row">
                <div class="col-12">
                    <app-training-plan-editor-inline [SelectedCoach]="user" [User]="displayedUser" (SelectedTrainingPlan)="onSelectedTrainingPlanChanged($event)">
                    </app-training-plan-editor-inline>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="isClientsAnalyticsMenuItemActive()">
            <app-client-analytics [User]="displayedUser"></app-client-analytics>
        </ng-container>

        <ng-container *ngIf="isClientsNutritionMenuItemActive()">
            <ng-container *ngIf="isNutritionEnabled()">
                <div class="row {{nutritionPlanOpened ? 'nutritionplan-opened' : ''}}">
                    <div class="col-12">
                        <h2>Ernährungsplanung</h2>
                        <app-nutrition-plan [selectedUser]="displayedUser" [selectedCoach]="user"  (openPlanEvent)="onNutritionPlanOpened($event)"></app-nutrition-plan>
                    </div>
                    <div class="spacer-25"></div>
                    <div class="col-12 diary-wrapper">
                        <h2>Tagebuch</h2>
                        <app-diary [setDisplayedUser]="displayedUser"></app-diary>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!isNutritionEnabled()">
                <div class="row">
                    <div class="col-12">
                        <div class="spacer-25"></div>
                        <h3>Ernährungsbereich deaktiviert.</h3>
                        Wenn du die Ernährung des Athleten planen und den Ernährungs-Tab in der Kunden-App hinzufügen möchtest, aktiviere den Ernährungsbereich in den Athleten-Einstellungen.
                    </div>
                    <div class="spacer-25"></div>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div *ngIf="userService.getLoggedInUser().isCoach" class="container">
        <div class="row">
            <div class="col">
                <chat>
                </chat>
            </div>
        </div>
    </div>
</div>