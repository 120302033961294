<div class="padding-0">
    <div class="container-left-align settings-container" *ngIf="trainingPlanSettingsExpanded">
        <div class="spacer-10"></div>
        <div class="row">
            <div class="col-auto">
                <div class="spacer-10"></div>
                <div class="row col">
                    <div class="plan-thumbnail-image link image-dropzone" (click)="openThumbnailDialog()" (dragover)="$event.preventDefault()" (drop)="onDropThumbnail($event)" [ngStyle]="{'background-image':'url('+ thumbnailImageSrc +')'}">
                        <div *ngIf="!thumbnailImageSrc" for="thumbnail-input" class="dropzone-hint">
                            <span translate>Thumbnail (.png oder .jpg) ablegen oder auswählen</span>
                            <br translate>Format: 720x240px (3:1)
                        </div>
                        <input [disabled]="readOnlyMode" id="thumbnail-input" type="file" style="display: none;" accept=".jpg, .png, .jpeg" (change)="uploadThumbnail($event)">
                    </div>
                </div>
                <div class="row col">
                    <button *ngIf="thumbnailImageSrc && !readOnlyMode" class="label" (click)="onRemoveThumbnail()" translate>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"  fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                        </svg>
                        Thumbnail entfernen
                    </button>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="spacer-10"></div>
                        <label for="input-plan-name" *ngIf="!trainingPlan.isTemplate" translate>Planname</label>
                        <label for="input-plan-name" *ngIf="trainingPlan.isTemplate" translate>Vorlagen-Name</label>
                        <input [disabled]="readOnlyMode" id="input-plan-name" maxlength="35" required class="input plannedmeal-name form-control wide-input" [(ngModel)]="trainingPlan.nameTranslation[selectedLanguageCode]" [placeholder]="'Name' | translate" type="text">
                        <div class="spacer-10"></div>
                        <label for="input-plan-name" translate>Untertitel</label>
                        <input [disabled]="readOnlyMode" id="input-plan-name" maxlength="70" class="input plannedmeal-name form-control wide-input" [(ngModel)]="trainingPlan.subNameTranslation[selectedLanguageCode]" [placeholder]="'Name' | translate" type="text">
                    </div>
                    <div class="col">
                        <ng-container *ngIf="!trainingPlan.isTemplate">
                            <div class="" [matTooltip]="planHasTrackedSessions() ? ('Plan enthält bereits aufgezeichnete Einheiten' | translate) : ''">
                                <span translate>Start:</span> 
                                <span class="marginleft-10">{{trainingPlan.startDate?.asFormatedString()}}</span>
                                <input [disabled]="readOnlyMode || planHasTrackedSessions()" [matDatepicker]="startDatePicker" [value]="trainingPlan?.startDate" [matDatepickerFilter]="startDateFilterTrainingPlanConfig" class="visibility-hidden width-0 padding-0" (dateInput)="onStartDateChanged($event.value?.toDate())">
                                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #startDatePicker ></mat-datepicker><br>
                            </div>
                            <div *ngIf="!trainingPlan.isPeriodicPlan" class="">
                                <span translate>Ende:</span> 
                                <span class="marginleft-10">{{trainingPlan.endDate?.asFormatedString()}}</span>
                                <input [disabled]="readOnlyMode" [matDatepicker]="endDatePicker" [value]="trainingPlan?.endDate" [matDatepickerFilter]="endDateFilterTrainingPlanConfig" class="visibility-hidden width-0 padding-0" (dateInput)="onEndDateChanged($event.value?.toDate())">
                                <span *ngIf="!trainingPlan.endDate" translate>unbegrenzt</span>
                                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #endDatePicker ></mat-datepicker>
                                <div *ngIf="trainingPlan.endDate && canRemoveEndDate()" class="icon-button display-inlineblock marginleft-25" (click)="onRemoveEndDate()">
                                    Enddatum entfernen
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="trainingPlan.isTemplate">
                            <div class="spacer-10"></div>
                            <label translate>Dauer</label>
                            <div>
                                <input [disabled]="readOnlyMode || trainingPlan.isPeriodicPlan" min="0" max="600" step="1" class="input small form-control number-input display-inlineblock" [ngModel]="getDurationNumber(trainingPlan.durationInDays)" (input)="setDurationNumber($event.target.value)" (click)="$event.stopPropagation()" type="number">
                                <div class="d-inline-block wrapper-repetition-selection">
                                    <ng-container *ngIf="!readOnlyMode && !trainingPlan.isPeriodicPlan; else noEditDuration">
                                        <button [disabled]="readOnlyMode || trainingPlan.isPeriodicPlan" class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" translate>{{durationDropdownSelection}}</button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
                                            <div *ngFor="let duration of durationDropdown">
                                                <button [disabled]="readOnlyMode || trainingPlan.isPeriodicPlan" class="dropdown-item" type="button" (click)="onDurationDropdownSelectionChanged(duration)" translate>{{duration}}</button>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #noEditDuration>
                                        <span class="ml-1" translate>
                                            {{durationDropdownSelection}}
                                        </span>
                                    </ng-template>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!readOnlyMode">
                            <div class="spacer-25"></div>
                            <div class="recipe-sharing-settings">
                                <div class="recipesharing-toggle toggle-wrapper display-inlineblock marginleft-0" [tooltip]="'Durch diese Einstellung kann dein Coachee den Plan selbst anpassen und Änderungen vornehmen.' | translate">
                                    <div class="toggle {{trainingPlan.userEditable ? 'on' : ''}}" (click)="onUserEditableChanged(!trainingPlan.userEditable)">
                                        <div class="handle"></div>
                                    </div>
                                    <div class="text" translate>
                                        Coachee kann Plan bearbeiten
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-lg-6 col-sm-12" *ngIf="trainingPlan.isTemplate && templateSharingEnabled && !readOnlyMode">
                        <div class="spacer-10"></div>
                        <label for="dropdownRepetion" translate>Vorlage teilen</label>
                        <div class="wrapper-repetition-selection">
                            <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getPrintableAssignments(trainingPlan)}}</button>
                            <div class="dropdown-menu dropdown-recipient" aria-labelledby="dropdownNotificationTarget">
                                <div class="dropdown-item link" (click)="onTargetSelectionChangedGroup('Alle');$event.stopPropagation()">
                                    <input [disabled]="readOnlyMode" [disabled]="!canAccessGroup('Alle') || areAllUsersAssignedByFolders()" class="input checkbox" type="checkbox" [checked]="areAllUsersTargeted()"><div class="display-inlineblock" translate>Alle</div>
                                </div>
                                <div class="dropdown-divider"></div>
                                <div class="dropdown-item link" *ngFor="let group of getSelectableClientGroups()" (click)="onTargetSelectionChangedGroup(group);$event.stopPropagation()">
                                    <input [disabled]="readOnlyMode" [disabled]="group != 'Alle' && areAllUsersTargeted() || !canSetGroup(group)" class="input checkbox" type="checkbox" [checked]="trainingPlan?.assignedGroupNames.includes(group) || isGroupAssignedByFolders(group)"><div class="display-inlineblock">{{group}}</div>
                                </div>
                                <div class="dropdown-divider"></div>
                                <div class="dropdown-item link" *ngFor="let user of getSelectableClients()" (click)="onTargetSelectionChangedUser(user);$event.stopPropagation()">
                                    <input [disabled]="readOnlyMode" [disabled]="areAllUsersTargeted() || !canSetUser(user)" class="input checkbox" type="checkbox" [checked]="trainingPlan?.assignedUids.includes(user.uid) || isUserAssignedByFolders(user.uid)"><div class="display-inlineblock">{{user.getName()}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="spacer-10"></div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12">
                <div class="spacer-10"></div>
                <label for="input-description" translate>Hinweis an deinen Coachee</label>
                <textarea [disabled]="readOnlyMode" id="input-description" class="input" [(ngModel)]="trainingPlan.hintTranslation[selectedLanguageCode]" [placeholder]="'Hinweis' | translate" type="text" rows="5"></textarea>
            </div>
        </div>
        <div class="spacer-10"></div>
        <div class="row">
            <div class="col-lg-6 col-md-8 col-sm-12" *ngIf="trainingPlan.isTemplate">
                <label for="input-description" translate>Beschreibung für dich</label>
                <textarea [disabled]="readOnlyMode" id="input-description" class="input" [(ngModel)]="trainingPlan.descriptionTranslation[selectedLanguageCode]" [placeholder]="'Beschreibung' | translate" type="text" rows="5"></textarea>
            </div>
        </div>
    </div>
    <div class="spacer-10"></div>
    <div class="row" *ngIf="trainingPlan.isTemplate && trainingTemplateAdministratorEditModeActivated">
        <div class="col">
            <div class="spacer-10"></div>
            <label for="dropdown-access" class="col-12" translate>Verfügbar für</label>
            <div class="d-inline-block wrapper-repetition-selection col-12">
                <button [disabled]="readOnlyMode" class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdown-access" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{trainingPlan.access}}</button>
                <div class="dropdown-menu" aria-labelledby="dropdown-access">
                    <div *ngFor="let access of selectableTrainingPlanAccess">
                        <button [disabled]="readOnlyMode" class="dropdown-item" type="button" (click)="trainingPlan.access = access">{{access}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>