import { Component, ComponentFactoryResolver, Input, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { registerPalette } from 'devextreme/viz/palette';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ClientComponent } from 'src/app/client/client-v1/client-v1.component';
import { BaseNutritionFact, NutritionalValue } from 'src/app/model/basenutritionfact.model';
import { Meal } from 'src/app/model/meal.model';
import { NutritionStatisticsItem } from 'src/app/model/nutritionstatistics.model';
import { User } from 'src/app/model/user.model';
import { ChartExportService } from 'src/app/services/chart-export.service';
import { EditUserDataService } from 'src/app/services/edit-user-data.service';
import { FirestoreNutritionPlanService } from 'src/app/services/firestore-nutritionplan.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { LanguageService } from 'src/app/services/language.service';
import { NutritionService } from 'src/app/services/nutrition.service';
import { NutritionplanExportService } from 'src/app/services/nutritionplan-export.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-nutrition-graph',
  templateUrl: './nutrition-graph.component.html',
  styleUrls: ['./nutrition-graph.component.css']
})
export class NutritionGraphComponent {

  constructor(public nutritionService: NutritionService, private router: Router, public userService: FirestoreService, private nutritionPlanService: FirestoreNutritionPlanService, private route: ActivatedRoute, public utilityService: UtilityService, private nutritionPlanExportService: NutritionplanExportService, private toastr: ToastrService, private spinner: NgxSpinnerService,  public dialog: MatDialog, private componentFactoryResolver: ComponentFactoryResolver, private renderer: Renderer2, public chartExportService: ChartExportService, public editUserDataService: EditUserDataService, public languageService: LanguageService) {
    this.calculateCustomSummaryRow = this.calculateCustomSummaryRow.bind(this);
  }
  
  public BaseNutritionFact = BaseNutritionFact

  public user: User
  @Input() set User(user: User) {
    this.user = user
    if (user) {
      this.init()
    }
  }

  public statisticsGraphTypes: DropdownItem[] = [
    {id: 0, name: "Kalorien", tmp: '', unit: null},
    {id: 1, name: "Makronährstoffe", tmp: '', unit: null},
    {id: 2, name: "Mikronährstoffe", tmp: '', unit: null},
  ]
  public statisticsGraphTimeRanges: DropdownItem[] = [
    {id: 0, name: "Letzte 7 Tage", tmp: '', unit: null},
    {id: 1, name: "Letzte 14 Tage", tmp: '', unit: null},
    {id: 2, name: "Letzte 31 Tage", tmp: '', unit: null},
    {id: 3, name: "Benutzerdefinierter Zeitraum", tmp: '', unit: null},
  ]
  public selectedStatisticsGraphType: DropdownItem = this.statisticsGraphTypes[0]
  public selectedStatisticsGraphTimeRange: DropdownItem = this.statisticsGraphTimeRanges[0]  
  public selectedStatisticsStartDate: Date = undefined;
  public selectedStatisticsEndDate: Date = undefined
  public nutritionStatisticsGraphParams: any[] = []

  statisticsGraphAxis: any[] = []

  nutritionStatisticsGraphData: NutritionStatisticsItem[] = []
  weeklyReportCalorieIntake: number = 0
  weeklyReportCalorieGoal: number = 0
  weeklyReportAbsoluteDeviation: number = 0
  weeklyReportDeviationPercentage: number = 0

  private statisticsUpdateSubscription: Subscription
  public lineColors: string[] = []

  ngOnInit(): void {

    var bodyDataGraphColorPalette = {
      simpleSet: ['var(--accentColor)', '#4ACCE6', '#4A79E6', '#934AE6', '#E64ADC', '#E64A6E', '#E67D4A', '#E6BC4A', '#D6E64A', '#9CE64A', '#4AE693'],
      indicatingSet: ['var(--accentColor)', '#4AE6E6', '#4AD6E6'],
      gradientSet: ['var(--accentColor)', '#4AE6E6']
    };
    registerPalette('nutrilizePalette', bodyDataGraphColorPalette);
    
    this.nutritionStatisticsGraphParams = []
    this.lineColors = ClientComponent.generateColors(Object.keys(NutritionalValue).length / 2 + 1);
    let colorIndex = 0;
    this.isNutritionTableDateColumnVisible = this.isNutritionTableColumnVisible('date');
    for (let index = 0; index < 7; index++) {
      let nutritionalValueNames = BaseNutritionFact.getNutritionalValuesForGroup(index)
      nutritionalValueNames.forEach(valueName => {
        colorIndex++;
        var color = (valueName == 'carbohydrates') ? '#9E31FF' : (valueName == 'protein') ? '#5CEFFF' : (valueName == 'fat') ? '#B7FD78' : (valueName == 'calories') ? '#FF5169' : this.lineColors[colorIndex] ?? '';
        this.nutritionStatisticsGraphParams.push({
          name: BaseNutritionFact.getNutritionalValueTranslation(valueName),
          style: '',
          valueField: valueName,
          isSelected: valueName == 'carbohydrates' || valueName == 'protein' || valueName == 'fat',
          valueGroup: index,
          unit: BaseNutritionFact.getDefaultUnit(valueName),
          color: color,
          graphTypeId: index == 0 ? 1 : 2,
          visible: this.isNutritionTableColumnVisible(valueName)
        });
        this.nutritionStatisticsGraphParams.push({
          name: BaseNutritionFact.getNutritionalValueTranslation(valueName) + "-Ziel",
          style: 'goal',
          valueField: valueName + "Goal",
          isSelected: valueName == 'carbohydrates' || valueName == 'protein' || valueName == 'fat',
          valueGroup: index,
          unit: BaseNutritionFact.getDefaultUnit(valueName),
          color: color,
          graphTypeId: index == 0 ? 1 : 2,
          visible: this.isNutritionTableColumnVisible(valueName + "Goal")
        });
      });
    }
  }

  init() {
    this.userStatistics = []

    if (this.statisticsUpdateSubscription) {
      this.statisticsUpdateSubscription.unsubscribe()
    }


    this.statisticsUpdateSubscription = this.user.statisticsUpdated.subscribe(async updated => {
      if (updated) {
        this.updateNutritionStatisticsGraph(this.user)
      }
    })

    this.nutritionPlanService.loadCycleConfigs(this.user).then(async () => {
      //this.cycleConfigOfSelectedDate = this.displayedUser.getCycleConfigForDate(this.currentDate);
      this.updateNutritionStatisticsGraph(this.user)
    })
  }

  async onStatisticsStartDateChanged(startDate: Date) {
    this.selectedStatisticsStartDate = startDate;
    if(this.selectedStatisticsStartDate && this.selectedStatisticsEndDate){
      await this.updateNutritionStatisticsGraph(this.user)
    }
  }
  async onStatisticsEndDateChanged(endDate: Date) {
    this.selectedStatisticsEndDate = endDate;
    if(this.selectedStatisticsStartDate && this.selectedStatisticsEndDate){
      await this.updateNutritionStatisticsGraph(this.user)
    }
  }

  public isNutritionTableDateColumnVisible: boolean = true
  isNutritionTableColumnVisible(columnName: string){
    let loggedInUser = this.userService.getLoggedInUser();
    if(loggedInUser?.isCoach){
      return loggedInUser?.portalSettingsCoach?.hiddenNutritionTableColumns?.includes(columnName) == false
    }
    return true
  }

  nutritionTableColumnVisibilityChanged(visible, columnName){
    let loggedInUser = this.userService.getLoggedInUser();
    if(loggedInUser.isCoach){
      if(visible == false){
            let loggedInUser = this.userService.getLoggedInUser();
            loggedInUser.portalSettingsCoach.hiddenNutritionTableColumns.push(columnName)
      }
      else{
        loggedInUser.portalSettingsCoach.hiddenNutritionTableColumns = loggedInUser.portalSettingsCoach.hiddenNutritionTableColumns.filter(x => x != columnName)
      }
      this.userService.updatePortalSettingsForCoach(loggedInUser)
    }
  }

  public today = new Date()
  startDateFilterStatisticsData = (d: Moment | null): boolean => {
    if (d?.toDate() > this.today) return false
    return true
  }
  endDateFilterStatisticsData = (d: Moment | null): boolean => {
    if (d?.toDate() > this.today) return false
    return true
  }

  public progressStatisticsGroupCount = new Map()
  public selectedProgressStatisticsRowDates = []

  calculateCustomSummaryRow(options) {
    if (options.summaryProcess === "start") {
      options.totalValue = 0;
      this.progressStatisticsGroupCount.set(options.name, 0)
    } else if (options.summaryProcess === "calculate") {
      if (!this.selectedProgressStatisticsRowDates.includes(options.value["date"]) && options.value[options.name] != null) {
        options.totalValue = options.totalValue + options.value[options.name]
        this.progressStatisticsGroupCount.set(options.name,  this.progressStatisticsGroupCount.get(options.name) + 1)
      }
    } else if (options.summaryProcess === "finalize") {
      if (this.progressStatisticsGroupCount.get(options.name)) {
        options.totalValue = options.totalValue / this.progressStatisticsGroupCount.get(options.name)
      }
    }
  }
  onSelectionChanged(e) {
    e.currentSelectedRowKeys.forEach(element => {
      if (!this.selectedProgressStatisticsRowDates.includes(element["date"])) this.selectedProgressStatisticsRowDates.push(element["date"])
    });
    e.currentDeselectedRowKeys.forEach(element => {
      this.selectedProgressStatisticsRowDates.forEach( (item, index) => {
        if (item == element["date"]) this.selectedProgressStatisticsRowDates.splice(index, 1);
      });
    });
    e.component.refresh(true);
  }

  async onStatisticsGraphTypeChanged(selection: DropdownItem) {
    this.selectedStatisticsGraphType = selection
    await this.updateNutritionStatisticsGraph(this.user)
  }
  
  async onStatisticsGraphTimeRangeChanged(selection: DropdownItem) {
    this.selectedStatisticsGraphTimeRange = selection
    await this.updateNutritionStatisticsGraph(this.user)
  }

  public async onNutritionStatisticsGraphParamsClicked(graphParam: any) {
    graphParam.isSelected = !graphParam.isSelected
    let goals = this.nutritionStatisticsGraphParams.filter(x => x.valueField == graphParam.valueField + 'Goal')
    if(goals.length > 0) goals[0].isSelected = graphParam.isSelected
    await this.updateNutritionStatisticsGraph(this.user)

  }

  showMicrosDataTable: boolean = false
  canShowMicrosDataTable: boolean = true
  showMicrosDataTableGoals: boolean = true

  onShowMicrosDataTableChanged(value: boolean){
    this.showMicrosDataTable = value
  }

  onShowMicrosDataTableGoalsChanged(value: boolean){
    this.showMicrosDataTableGoals = value
  }

  
  customizeStatisticsGraphTooltip = (arg: any) => {
    if (this.selectedStatisticsGraphType.id == 0) {
      if (arg.point?.data.calorieDeficit > 0) return { text: arg.point?.data?.date.asFormatedString() + '\n' + 'Konsum: ' + arg.point?.data?.calories.roundToPlaces(0) + ' kcal\nDifferenz: ' + arg.point?.data?.calorieDeficit.roundToPlaces(0) + ' kcal' }
      if (arg.point?.data.calorieSurplus > 0) return { text: arg.point?.data?.date.asFormatedString() + '\n' + 'Konsum: ' + (arg.point?.data?.calorieGoal + arg.point?.data?.calorieSurplus).roundToPlaces(0) + ' kcal\nÜberschuss: ' + arg.point?.data?.calorieSurplus.roundToPlaces(0) + ' kcal' }
    } else {
      var tooltipText = arg.point?.data?.date.asFormatedString() + '\n'
      this.nutritionStatisticsGraphParams.forEach(graphParam => {
        if(graphParam.graphTypeId == this.selectedStatisticsGraphType.id && graphParam.isSelected && graphParam.style != 'goal') {
          if(arg.point?.data[graphParam.valueField + 'Goal']) tooltipText += this.chartTooltipFormatter(graphParam.name + ': ' + arg.point?.data[graphParam.valueField]?.roundToBestPlaces() + graphParam.unit + ' / ' + arg.point?.data[graphParam.valueField + 'Goal']?.roundToBestPlaces() +  graphParam.unit + ((arg.point?.data[graphParam.valueField + 'IsCoachGoal'] || graphParam.graphTypeId == 1) ? '**' : '*') + '\n', arg.seriesName == graphParam.name || arg.seriesName == graphParam.name + '-Ziel')
          else tooltipText += this.chartTooltipFormatter(graphParam.name + ': ' + arg.point?.data[graphParam.valueField]?.roundToBestPlaces() + graphParam.unit + '\n', arg.seriesName == graphParam.name)
        }
      })
      tooltipText += '\n \n'
      tooltipText += '*EU-RDA (2008)\n'
      tooltipText +=  '**von Coach geplant\n'
      return { text: tooltipText}
    }
  }

  private chartTooltipFormatter(value: string, isBold:boolean) : string{
    if(isBold){
      return "<b>" + value + "</b>";
    }
    else {
      return value
    }
  }

  customizeGroupSummaryText (e) {
    return "∅: " + Math.round((e.value + Number.EPSILON) * 100) / 100
  };
  progressGraphTickIntervall = undefined
  progressGraphVisualRange = [null, null]

  public loadingStatisticsForDateRange = false;
  private userStatistics: NutritionStatisticsItem[] = [];

  private checkTimeRangeAvailable(statisticItems: NutritionStatisticsItem[], startDate: Date, endDate: Date): boolean{
    if(statisticItems == null || statisticItems.length == 0)
    {
      return false;
    }
    // let availableStartDate = statisticItems[0].date;
    let sortedStatisticsItems = statisticItems.map(x => x.date).sort((a, b) => a.getTime() - b.getTime());
    if(sortedStatisticsItems == null) return false;

    let availableStartDate = sortedStatisticsItems[0];
    availableStartDate?.setHours(0,0,0,0);
    
    let availableEndDate = sortedStatisticsItems[sortedStatisticsItems.length - 1];
    availableEndDate.setHours(0,0,0,0);
    return (availableStartDate != null && availableEndDate != null && availableStartDate <= startDate && availableEndDate >= endDate)
  }


  async updateNutritionStatisticsGraph(user: User) {
    //if (user.statistics == null || user.statistics.length == 0) return;
    var timeRange = 7
    if (this.selectedStatisticsGraphTimeRange.id == 0) {
      timeRange = 7;
    } else if (this.selectedStatisticsGraphTimeRange.id == 1) {
      timeRange = 14;
    } else if (this.selectedStatisticsGraphTimeRange.id == 2) {
      timeRange = 31;
    } else if (this.selectedStatisticsGraphTimeRange.id == 3){
      if(this.selectedStatisticsStartDate && this.selectedStatisticsEndDate && this.selectedStatisticsEndDate > this.selectedStatisticsStartDate) {
        this.selectedStatisticsEndDate.setHours(0,0,0,0)
        this.selectedStatisticsStartDate.setHours(0,0,0,0)
        timeRange = (this.selectedStatisticsEndDate.getTime() - this.selectedStatisticsStartDate?.getTime()) / (1000 * 3600 * 24)
      } else {
        return
      }
    }

    
    this.canShowMicrosDataTable = timeRange <= 180
    
    var startDate = new Date();
    var endDate = new Date().addDays(-1);
    
    if(this.selectedStatisticsGraphTimeRange.id == 3) {
      startDate = this.selectedStatisticsStartDate;
      endDate = this.selectedStatisticsEndDate
    }
    else {
      startDate.setDate(startDate.getDate() - timeRange);
      this.selectedStatisticsStartDate = startDate
      this.selectedStatisticsEndDate = endDate
    }
    startDate.setHours(0,0,0,0);
    endDate.setHours(0,0,0,0);

    this.nutritionStatisticsGraphData = []
    this.statisticsGraphAxis = []
    if(this.selectedStatisticsGraphType?.id == 0) {
      this.statisticsGraphAxis.push({name: "kcalAxis", visualRange: [null, null], title: 'kcal'});
    }
    else {
      let selectedGraphParams = this.nutritionStatisticsGraphParams.filter(x => x.isSelected && x.graphTypeId == this.selectedStatisticsGraphType.id && x.graphTypeId > 0);
      if(selectedGraphParams.filter(x => x.unit == 'kcal').length > 0) this.statisticsGraphAxis.push({name: "kcalAxis", visualRange: [null, null], title: 'kcal'});
      if(selectedGraphParams.filter(x => x.unit == 'g' || x.unit == '%').length > 0) this.statisticsGraphAxis.push({name: "gAxis", visualRange: [null, null], title: 'g'});
      if(
        selectedGraphParams.filter(x => x.unit == 'mg').length > 0) this.statisticsGraphAxis.push({name: "mgAxis", visualRange: [null, null], title: 'mg'});
    }

    if(!this.checkTimeRangeAvailable(this.userStatistics, startDate, endDate)) {
        if(this.checkTimeRangeAvailable(user.statistics, startDate, endDate)){
          this.userStatistics = user.statistics
        }
        else {
          this.loadingStatisticsForDateRange = true
          this.userStatistics = await this.userService.loadNutritionStatisticsForDateRange(user, startDate, endDate);
          this.loadingStatisticsForDateRange = false
        }
    }

    var statistics: NutritionStatisticsItem[] = []
    this.weeklyReportCalorieIntake = 0
    this.weeklyReportCalorieGoal = 0
    this.weeklyReportAbsoluteDeviation = 0
    this.weeklyReportDeviationPercentage = 0
    var weeklyReportRelativeDeviation = 0
    var weeklyReportDayCount = 0

    this.userStatistics?.forEach(s => {
      if (s.date.getTime() >= startDate.getTime() && s.date.getTime() <= endDate.getTime()) {
        let cycleConfig = user.getCycleConfigForDate(s.date)

        if (this.selectedStatisticsGraphType.id == 2) {

          let nutritionStatistic = new NutritionStatisticsItem()
          nutritionStatistic['weekNumber'] = s.date.getYearOfWeekOfYearNumber() + '/' + (s.date.getWeekOfYearNumber() < 10 ? '0' : '') + s.date.getWeekOfYearNumber()
          nutritionStatistic.date = s.date;
          nutritionStatistic["dateRendered"] = s.date.asShortFormatedString();

          
          statistics.push(nutritionStatistic)
          var mealsOfDay: Meal[] = []
          s.meals.forEach(m => {
            if (m.date.isSameDate(s.date)) {
              if(!mealsOfDay.includes(m)) {
                mealsOfDay.push(m)
              }
            }
          })
          mealsOfDay.forEach(meal => {
              meal.foods.forEach(food => {
                this.nutritionStatisticsGraphParams.forEach(graphParam => {
                  if(graphParam.graphTypeId == 2 && graphParam.isSelected && graphParam.style != 'goal') {
                    let value = food.getNutritionalValue(graphParam.valueField)
                    let coachGoal = cycleConfig?.commonTargetValues[graphParam.valueField]
                    let goal = (coachGoal != undefined) ? BaseNutritionFact.getConvertedValueByUnit(coachGoal, graphParam.unit): BaseNutritionFact.getRawRecommendedDailyAllowance(graphParam.valueField, graphParam.unit)
                    if(goal != undefined) {
                      nutritionStatistic[graphParam.valueField + 'Goal'] = goal
                      nutritionStatistic[graphParam.valueField + 'GoalRendered'] = goal?.roundToBestPlaces()
                      nutritionStatistic[graphParam.valueField + 'IsCoachGoal'] = coachGoal != null
                    }
                    if(!nutritionStatistic[graphParam.valueField]) nutritionStatistic[graphParam.valueField] = 0;
                    if(value) nutritionStatistic[graphParam.valueField] += BaseNutritionFact.getConvertedValueByUnit(value, graphParam.unit);
                    nutritionStatistic[graphParam.valueField + 'Rendered'] = nutritionStatistic[graphParam.valueField]?.roundToBestPlaces();
                  }
                });
              });
          });

        } else if (this.selectedStatisticsGraphType.id == 1) {

          s['weekNumber'] = s.date.getYearOfWeekOfYearNumber() + '/' + (s.date.getWeekOfYearNumber() < 10 ? '0' : '') + s.date.getWeekOfYearNumber()
          s["dateRendered"] = s.date.asShortFormatedString();
          this.nutritionStatisticsGraphParams.forEach(graphParam => {

            if(graphParam.graphTypeId == 1 && graphParam.isSelected && graphParam.style != 'goal') {
              let coachGoal = cycleConfig?.commonTargetValues[graphParam.valueField]
              let goal = (coachGoal != undefined) ? BaseNutritionFact.getConvertedValueByUnit(coachGoal, graphParam.unit): BaseNutritionFact.getRawRecommendedDailyAllowance(graphParam.valueField, graphParam.unit)

              if(goal != undefined) {
                s[graphParam.valueField + 'Goal'] = goal
                s[graphParam.valueField + 'IsCoachGoal'] = coachGoal != null
              }
              s[graphParam.valueField + 'Rendered'] = s[graphParam.valueField]?.roundToBestPlaces();
            }
          });
          s.calories = s.nutritionalSummary.calories;
          s["caloriesGoal"] = s.nutritionalGoal.calories;
          statistics.push(s);

        }  else {
          if(s.date.getHours() != 0) {
            if(s.date.getHours() < 12) {
              s.date.setHours(0,0,0,0)
            }
            else {
              s.date.addDays(1);
              s.date.setHours(0,0,0,0);
            }
          }
          if (s.nutritionalSummary.calories >= s.nutritionalGoal.calories) {
            s.calories = 0
            s.calorieGoal = s.nutritionalGoal.calories
            s.calorieDeficit = 0;
            s.calorieSurplus = s.nutritionalSummary.calories - s.nutritionalGoal.calories
          } else {
            s.calories = s.nutritionalSummary.calories
            s.calorieGoal = 0
            s.calorieSurplus = 0
            s.calorieDeficit = s.nutritionalGoal.calories - s.nutritionalSummary.calories
          }

          this.weeklyReportCalorieIntake += s.nutritionalSummary.calories
          this.weeklyReportCalorieGoal += s.nutritionalGoal.calories
          if (s.nutritionalSummary.calories > 0) {
            weeklyReportDayCount++
            this.weeklyReportAbsoluteDeviation += (s.nutritionalSummary.calories - s.nutritionalGoal.calories)
            weeklyReportRelativeDeviation += (s.nutritionalSummary.calories - s.nutritionalGoal.calories) / s.nutritionalGoal.calories
          }
          statistics.push(s);
        }
      }
    })
    if (weeklyReportDayCount > 0) {
      this.weeklyReportAbsoluteDeviation = this.weeklyReportAbsoluteDeviation / weeklyReportDayCount
      weeklyReportRelativeDeviation = weeklyReportRelativeDeviation / weeklyReportDayCount
    }
    this.weeklyReportDeviationPercentage = Math.abs(weeklyReportRelativeDeviation * 100)
    this.nutritionStatisticsGraphData = statistics
  }
}

class DropdownItem {
  id: number;
  tmp: string;
  name: string;
  unit: string;
}