// noinspection JSUnusedAssignment

import {Injectable} from '@angular/core';
import {FirestoreService} from "./firestore.service";
import {GoalPlan, GoalPlanMap, GoalPlanType, WeeklyAggregation} from "../model/goal-plan.model";
import {User} from "../model/user.model";
import {DailyCondition} from "../model/dailycondition.model";
import * as moment from "moment";
import {MetricData} from "../model/metricdata.model";
import {GoalVisualizationConfig} from "../graph/goal-visualization/goal-visualization.component";

@Injectable({
  providedIn: 'root'
})
export class GoalPlanService {
  private readonly USER_COLLECTION_NAME: string = "Users";
  private readonly SHARED_COLLECTION_NAME: string = "Shared";

  constructor(private firestoreService: FirestoreService) {
  }

  public getUsedMetrics(user: User, type: GoalPlanType): string[] {
    let usedMetrics = []
    if (user.observableGoalPlans?.value?.size) {
      /**
       * Wenn Metrik in Progress verwendet: weg
       * Wenn Metrik in Weekly verwendet: daily möglich
       * Wenn Metrik in Daily verwendet: weekly möglich
       */
      const activePlans = Array.from(user.observableGoalPlans.value.values()).filter(plan => plan.isActive() &&
        !plan.deleted);
      usedMetrics = activePlans.filter(plan => {
        if (plan.type === GoalPlanType.PROGRESS) return true;
        if (type === GoalPlanType.PROGRESS) return true;
        return type === plan.type
      }).map(plan => plan.metric.metricId);
    }

    return usedMetrics;
  }

  private cloneGoalPlanMap(goalPlanMap: GoalPlanMap): GoalPlanMap {
    const goalMap: GoalPlanMap = new Map<string, GoalPlan>();

    goalPlanMap.forEach((goalPlan: GoalPlan, id: string) => {
      goalMap.set(id, new GoalPlan(goalPlan));
    });

    return goalMap;
  }

  public async addFirstGoalPlan(user: User, singlePlan: GoalPlan) {
    if (user.getLid()) {
      const json = {};
      json[singlePlan.id] = singlePlan.toFireStore();
      await this.firestoreService.firestore.collection(`${this.USER_COLLECTION_NAME}/${user.uid}/${this.SHARED_COLLECTION_NAME}/`).doc(user.getLid()).set({
        goalPlans: json,
        timestamp: new Date()
      }, {merge: true});
      await this.firestoreService.firestore.collection(`${this.USER_COLLECTION_NAME}/`).doc(user.uid).update({timestamp: new Date()});
      const plans = user.observableGoalPlans.value;
      plans.set(singlePlan.id, singlePlan);

      if (plans) {
        const newPlans = this.cloneGoalPlanMap(plans);
        if (newPlans) {
          user.observableGoalPlans.next(newPlans);
        }
      }
    }
  }

  public async updateGoalPlans(user: User, singlePlan: GoalPlan) {
    if (user.getLid()) {
      const json = {};
      json[`goalPlans.${singlePlan.id}`] = singlePlan.toFireStore();
      json["timestamp"] = new Date();
      await this.firestoreService.firestore.collection(`${this.USER_COLLECTION_NAME}/${user.uid}/${this.SHARED_COLLECTION_NAME}/`).doc(user.getLid()).update(json);
      await this.firestoreService.firestore.collection(`${this.USER_COLLECTION_NAME}/`).doc(user.uid).update({timestamp: new Date()});
      const plans = user.observableGoalPlans.value;
      plans.set(singlePlan.id, singlePlan);

      if (plans) {
        const newPlans = this.cloneGoalPlanMap(plans);
        if (newPlans) {
          user.observableGoalPlans.next(newPlans);
        }
      }
    }
  }

  /*
    public async updateAllGoalPlans(user: User, plans: GoalPlanMap) {
      if (user.getLid()) {
        const goalPlanMap = {};
        plans.forEach(plan => {
          goalPlanMap[plan.id] = plan.toFireStore();
        });
        await this.firestoreService.firestore.collection('Users/' + user.uid + "/Shared/").doc(user.getLid()).set({
          goalPlans: goalPlanMap,
          timestamp: new Date()
        }, {merge: true});
        await this.firestoreService.firestore.collection('Users/').doc(user.uid).update({timestamp: new Date()});
        user.observableGoalPlans.next(this.cloneGoalPlanMap(plans));
      }
    }

   */

  public getLatestDailyConditionByMetricId(user: User, metricId: string, date: Date = new Date(), safetyCounter: number = 0): DailyCondition {
    if (safetyCounter >= 1000) return null;
    if (!user.dailyConditions.length) return null;
    let daily = user.getDailyConditionForDate(date);

    if (!daily) {
      const dateMoment = moment(date);
      safetyCounter++;
      return this.getLatestDailyConditionByMetricId(user, metricId, dateMoment.subtract(1, 'days').toDate(), safetyCounter);
    }

    const metricData = daily.getMetricDataByMetricId(metricId);
    if (!metricData) {
      const dateMoment = moment(date);
      safetyCounter++;
      return this.getLatestDailyConditionByMetricId(user, metricId, dateMoment.subtract(1, 'days').toDate(), safetyCounter);
    }

    return daily;
  }

  public getActiveMetricConfig(metricId: string, user: User, type?: GoalPlanType, date?: Date, toEndGoal?: boolean): GoalVisualizationConfig {
    const currentDate = date || new Date();
    if (!metricId) return null;
    const plan = user.getActiveGoalPlanByMetricId(metricId, type, date);

    if (!plan) return null;

    let config: GoalVisualizationConfig = null;
    switch (plan.type) {
      case GoalPlanType.PROGRESS:
        config = this.getProgressMetricConfig(plan, user, currentDate, !!toEndGoal);
        break;
      case GoalPlanType.WEEKLY:
        config = this.getWeeklyMetricConfig(plan, user, currentDate);
        break;
      case GoalPlanType.DAILY:
        config = this.getDailyMetricConfig(plan, user, currentDate);
        break;
      default:
        console.log("Something went wrong");
        return null;
    }

    if (config === null) return null;


    const isBool = plan.metric.isMetricTypeToDo() || plan.metric.isMetricTypeYesNo();
    let activeStep = plan.getActiveStep(currentDate);
    if(plan.type == GoalPlanType.PROGRESS) {
      activeStep = plan.getNextStep(activeStep);
    }
    if (activeStep) {
      let info = null;
      if (activeStep.value !== null) {
        info = `/${activeStep.value}`;
        if (plan.metric.isMetricTypeDuration()) {
          const dur = moment.utc(moment.duration(activeStep.value, "minutes").as('milliseconds')).format('HH:mm');
          info = `/${dur}`;
        }
        if (isBool && plan.type === GoalPlanType.DAILY) {
          info = `/${activeStep.value ? 1 : 0}`;
        }
      } else {
        info = `/${activeStep.minValue}-${activeStep.maxValue}`;
        if (plan.metric.isMetricTypeDuration()) {
          const durMin = moment.utc(moment.duration(activeStep.minValue, "minutes").as('milliseconds')).format('HH:mm');
          const durMax = moment.utc(moment.duration(activeStep.maxValue, "minutes").as('milliseconds')).format('HH:mm');
          info = `/${durMin} - ${durMax}`;
        }
      }
      config.info = info;
    }

    return config;
  }

  public getMetricConfig(metricId: string, user: User, type?: GoalPlanType, date?: Date, toEndGoal?: boolean): GoalVisualizationConfig {
    const currentDate = date || new Date();
    if (!metricId) return null;
    const plan = user.getGoalPlanByMetricId(metricId, type);

    if (!plan) return null;

    let config: GoalVisualizationConfig = null;
    switch (plan.type) {
      case GoalPlanType.PROGRESS:
        config = this.getProgressMetricConfig(plan, user, currentDate, !!toEndGoal);
        break;
      case GoalPlanType.WEEKLY:
        config = this.getWeeklyMetricConfig(plan, user, currentDate);
        break;
      case GoalPlanType.DAILY:
        config = this.getDailyMetricConfig(plan, user, currentDate);
        break;
      default:
        console.log("Something went wrong");
        return null;
    }

    if (config === null) return null;


    const isBool = plan.metric.isMetricTypeToDo() || plan.metric.isMetricTypeYesNo();
    const activeStep = plan.getActiveStep(currentDate);
    if (activeStep) {
      let info = null;
      if (activeStep.value !== null) {
        info = `/${activeStep.value}`;
        if (plan.metric.isMetricTypeDuration()) {
          const dur = moment.utc(moment.duration(activeStep.value, "minutes").as('milliseconds')).format('HH:mm');
          info = `/${dur}`;
        }
        if (isBool && plan.type === GoalPlanType.DAILY) {
          info = `/${activeStep.value ? 1 : 0}`;
        }
      } else {
        info = `/${activeStep.minValue}-${activeStep.maxValue}`;
        if (plan.metric.isMetricTypeDuration()) {
          const durMin = moment.utc(moment.duration(activeStep.minValue, "minutes").as('milliseconds')).format('HH:mm');
          const durMax = moment.utc(moment.duration(activeStep.maxValue, "minutes").as('milliseconds')).format('HH:mm');
          info = `/${durMin} - ${durMax}`;
        }
      }
      config.info = info;
    }

    return config;
  }

  public getProgressMetricConfig(plan: GoalPlan, user: User, date: Date, toEndGoal: boolean) {
    let goalValueMin = 0;
    let goalValueMax = 0;
    const direction = plan.getDirection();
    if (toEndGoal) {
      if (direction > 0) {
        goalValueMin = Math.abs(plan.getFirstStep().value - (plan.getEndStep().value ?? plan.getEndStep().maxValue));
        goalValueMax = Math.abs(plan.getFirstStep().value - (plan.getEndStep().value ?? plan.getEndStep().minValue));
      } else {
        goalValueMin = Math.abs(plan.getFirstStep().value - (plan.getEndStep().value ?? plan.getEndStep().minValue));
        goalValueMax = Math.abs(plan.getFirstStep().value - (plan.getEndStep().value ?? plan.getEndStep().maxValue));
      }
    } else {
      if (direction > 0) {
        goalValueMin = Math.abs(plan.getFirstStep().value - (plan.getNextStep().value ?? plan.getNextStep().maxValue));
        goalValueMax = Math.abs(plan.getFirstStep().value - (plan.getNextStep().value ?? plan.getNextStep().minValue));
      } else {
        goalValueMin = Math.abs(plan.getFirstStep().value - (plan.getNextStep().value ?? plan.getNextStep().minValue));
        goalValueMax = Math.abs(plan.getFirstStep().value - (plan.getNextStep().value ?? plan.getNextStep().maxValue));
      }
    }
    const endStep = toEndGoal ? plan.getEndStep() : plan.getNextStep();
    const condi = user.getDailyConditionForDate(date) || this.getLatestDailyConditionByMetricId(user, plan.metric.metricId, date);
    let metricData = condi?.getMetricDataByMetricId(plan.metric.metricId);
    if (!metricData) {
      const latestCondi = this.getLatestDailyConditionByMetricId(user, plan.metric.metricId, date);
      metricData = latestCondi?.getMetricDataByMetricId(plan.metric.metricId);
    }
    if (!metricData) {
      console.log("No MetricData was found");
      return null;
    }
    const currentValue = Math.abs(endStep.getGoalValue(direction) - metricData.value);
    const currentProgress = Math.abs((direction > 0 ? goalValueMax : goalValueMin) - currentValue);
    // TODO
    const config: GoalVisualizationConfig = {
      minGoalValue: goalValueMin,
      maxGoalValue: goalValueMax,
      currentValue: currentProgress,
      maxValue: metricData.metric.maxValue ? metricData.metric.maxValue : goalValueMax,
      info: null,
      metricData: metricData
    };

    return config;
  }

  public getWeeklyMetricConfig(plan: GoalPlan, user: User, date: Date) {
    const activeStep = plan.getActiveStep(date);
    const activeDirection = plan.getDirection(activeStep)
    if (!activeStep) return null;
    const isBool = plan.metric.isMetricTypeToDo() || plan.metric.isMetricTypeYesNo();

    const goalValue = activeStep.getGoalValue(activeDirection);
    let value = 0;
    let metricData = new MetricData();
    metricData.metricId = plan.metric.metricId;
    metricData.metric = plan.metric;
    metricData.value = 0;

    const currentMoment = moment(date);
    const weeklyConditions = user.dailyConditions.filter(condi => {
      const data = condi.getMetricDataByMetricId(plan.metric.metricId);

      return data && currentMoment.isSame(condi.date, "week")
    });
    value = weeklyConditions.reduce((prev, curr) => {
      const data = curr.getMetricDataByMetricId(plan.metric.metricId);
      let val = 0;
      if (data) {
        val = isBool ? data.value ? 1 : 0 : data.value || 0;
        metricData.metricId = data.metricId;
        if (data.metric) {
          metricData.metric = data.metric;
        }
        metricData.value = metricData.value ? metricData.value + val : val;
      }
      return prev + val;
    }, 0);

    if (plan.aggregation == WeeklyAggregation.AVG) {
      metricData.value = (weeklyConditions.length === 0 ? 0 : value / weeklyConditions.length).roundToInt();
      value = (weeklyConditions.length === 0 ? 0 : value / weeklyConditions.length).roundToInt();
    }

    // TODO
    const config: GoalVisualizationConfig = {
      minGoalValue: activeStep.value ?? (activeDirection > 0 ? activeStep.minValue : activeStep.maxValue),
      maxGoalValue: activeStep.value ?? (activeDirection > 0 ? activeStep.maxValue : activeStep.minValue),
      currentValue: value,
      maxValue: metricData.metric?.maxValue ? metricData.metric.maxValue : activeStep.value ?? (activeDirection > 0 ? activeStep.maxValue : activeStep.minValue),
      info: null,
      metricData: metricData
    };

    return config;
  }

  public getDailyMetricConfig(plan: GoalPlan, user: User, date: Date) {
    const activeStep = plan.getActiveStep(date);
    const activeDirection = plan.getDirection(activeStep)
    if (!activeStep) return null;
    const isBool = plan.metric.isMetricTypeToDo() || plan.metric.isMetricTypeYesNo();

    const goalValue = activeStep.getGoalValue(activeDirection);
    let value = 0;
    let metricData = new MetricData();
    metricData.value = 0;
    metricData.metric = plan.metric;
    metricData.metricId = plan.metric.metricId;

    const condi = user.getDailyConditionForDate(date);
    if (condi) {
      metricData = condi.getMetricDataByMetricId(plan.metric.metricId);
      value = metricData?.value;
    }

    // TODO
    const config: GoalVisualizationConfig = {
      minGoalValue: activeStep.value ?? (activeDirection > 0 ? activeStep.minValue : activeStep.maxValue),
      maxGoalValue: activeStep.value ?? (activeDirection > 0 ? activeStep.maxValue : activeStep.minValue),
      currentValue: value,
      maxValue: metricData.metric?.maxValue ? metricData.metric.maxValue : activeStep.value ?? (activeDirection > 0 ? activeStep.maxValue : activeStep.minValue),
      info: null,
      metricData: metricData
    };

    if (isBool) {
      config.minGoalValue = config.minGoalValue ? 1 : 0;
      config.maxGoalValue = config.maxGoalValue ? 1 : 0;
      config.currentValue = config.currentValue ? 1 : 0;
    }

    return config;
  }
}
