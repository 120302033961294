import { Component, ComponentFactoryResolver, Input } from '@angular/core';
import { User } from '../model/user.model';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FirestoreService } from '../services/firestore.service';
import { UtilityService } from '../services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { DailyCondition } from '../model/dailycondition.model';
import { Metric } from '../model/metric.model';
import { LanguageService } from '../services/language.service';
import { MetricData } from '../model/metricdata.model';
import { MetricDataImageDialogComponent } from '../metric-data-image-dialog/metric-data-image-dialog.component';
import { AssignedQuestionaire, QuestionaireResult } from '../model/questionaires.model';
import { CompletedQuestionaireResultsDialogComponent } from '../questionaire/completed-questionaire-results-dialog/completed-questionaire-results-dialog.component';
import { EditUserDataService } from '../services/edit-user-data.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { NutritionStatisticsItem } from '../model/nutritionstatistics.model';
import { TranslateService } from '@ngx-translate/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Week } from '../model/training-plan.model';
import { VideoViewDialogComponent } from '../dialogs/video-view-dialog/video-view-dialog.component';

@Component({
  selector: 'app-checkin-table-view',
  templateUrl: './checkin-table-view.component.html',
  styleUrls: ['./checkin-table-view.component.css']
})
export class CheckinTableViewComponent {

  public user: User
  @Input() set User(value: User){
    this.user = value
    this.init()
  }

  public showAll: boolean = false

  public weekCount = 2
  public weeks: ReportingWeek[] = []
  public dailyConditions: DailyCondition[]

  defaultTableColumnIds: string[] = null
  showNutritionData: boolean = false

  constructor(private authService: AuthService, private router: Router, public userService: FirestoreService, private route: ActivatedRoute, public utilityService: UtilityService, private toastr: ToastrService, private spinner: NgxSpinnerService, private componentFactoryResolver: ComponentFactoryResolver, private dialog: MatDialog, public languageService: LanguageService, public editUserDataService: EditUserDataService, public spinnerService: NgxSpinnerService, public translate: TranslateService) {

  }

  init() {
    this.defaultTableColumnIds = this.user.weekStatisticsTableColumnIds ?? null
    if (this.defaultTableColumnIds?.includes('nutrition_calories')) this.showNutritionData = true
    
    if (this.user?.dailyConditions?.length > 0) {
      this.dailyConditions = this.user.dailyConditions
      this.updateData()
    } else {
      this.user.dailyConditionChanged.subscribe(() => {
        this.dailyConditions = this.user.dailyConditions
        this.updateData()
      })
      this.user.statisticsUpdated.subscribe(() => {
        this.updateData()
      })
    }
  }

  updateData() {
    var startOfCurrentWeek = new Date().getStartOfWeek()
    if (this.user.trainingPlans?.length > 0 && this.user.trainingPlans[0].weeks?.length > 0) {
      var plan = this.user.trainingPlans[0]
      var startDate = plan.startDate
      var dayOfWeek = startDate.getDay()
      if (dayOfWeek > 0) {
        startOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + dayOfWeek - 1)
      } else if (dayOfWeek == 0) {
        startOfCurrentWeek.setDate(startOfCurrentWeek.getDate() - 1)
      }
      if (startOfCurrentWeek.isSameOrAfterDate(new Date()) && !startOfCurrentWeek.isSameDate(new Date())) {
        startOfCurrentWeek.setDate(startOfCurrentWeek.getDate() - 7)
      }

    }
    if (!this.weeks || this.weeks.length == 0) {
      this.weeks = []
    }
    
    for (var i = 0; i < this.weekCount; i++) {
      var newWeek = false
      var week: ReportingWeek = this.weeks.find(x => x.startDate.isSameDate(startOfCurrentWeek))
      if (!week) {
        week = new ReportingWeek()
        newWeek = true
        week.startDate = new Date(startOfCurrentWeek)
        var plan = this.user.getTrainingPlanForDate(week.startDate)
        if (plan) {
          var trainingWeek = plan.getWeekForDate(week.startDate)
          if (trainingWeek && trainingWeek.name) week.name = trainingWeek.name.GetValue('de')
        }
      }
      week.dailyConditions = []
      week.averageValues = []
      week.questionaireResults = []
      this.dailyConditions?.forEach(dailyCondition => {
        if (dailyCondition.date.isSameOrAfterDate(week.startDate) && dailyCondition.date.isSameOrBeforeDate(week.getEndDate())) {
          week.dailyConditions.push(dailyCondition)
        }
      })
      
      if (week.dailyConditions.length < 7) {
        for (var j = 0; j < 7; j++) {
          var date = new Date(week.startDate)
          date.setDate(date.getDate() + j)
          if (!week.dailyConditions.find(x => x.date.isSameDate(date))) {
            var dailyCondition = new DailyCondition()
            dailyCondition.date = date
            week.dailyConditions.push(dailyCondition)
          }
        }
      }

      week.metrics = []
      this.user.assignedMetrics.forEach(metric => {
        if (metric.metricId != null) week.metrics.push(metric)
      })
      week.dailyConditions.forEach(dailyCondition => {
        dailyCondition.metricData.forEach(metricData => {
          if (metricData.value != null && week.metrics.find(x => x.metricId == metricData.metricId) == null) {
            week.metrics.push(metricData.metric)
          }
        })
        dailyCondition.questionaireResults?.forEach(questionaireResult => {
          if (questionaireResult.assignedQuestionaire?.type != 'training_feedback') {
            if (!week.questionaireResults) week.questionaireResults = []
            week.questionaireResults.push(questionaireResult)
          }
        })
      })

      week.metrics.forEach(metric => {
        var metricData = new MetricData()
        metricData.metricId = metric.metricId
        metricData.metric = metric
        metricData.value = 0
        var count = 0
        if (metric.dataType != 'INTEGER' && metric.dataType != 'DOUBLE') return
        week.dailyConditions.forEach(dailyCondition => {
          var data = dailyCondition.getMetricDataByMetricId(metric.metricId)
          if (data && data.value != null) {
            metricData.value += data.value
            count++
          }
        })
        if (count > 0) {
          metricData.value = (metricData.value / count).roundToPlaces(2)
          metricData.metric = metric
          week.averageValues.push(metricData)
        }
      })

      if (!week.nutritionStatistics && this.showNutritionData) {
        week.loadingNutritionData = true
        
        var items = this.getNutritionStatisticsForDateRange(week.startDate, week.getEndDate())
        if (items.length == 7 || week.getEndDate().isSameOrAfterDate(new Date()) && items.length > 0) {
          week.nutritionStatistics = items
          if (items.length < 7) {
            while (items.length < 7) {
              items.push(new NutritionStatisticsItem())
            }
          }
          week.nutritionStatistics = week.nutritionStatistics.reverse()
          if (week.nutritionStatistics.length == 0) week.nutritionStatistics = null
          week.loadingNutritionData = false
          
          var calories = 0
          var carbohydrates = 0
          var protein = 0
          var fat = 0
          var count = 0
          week.nutritionStatistics?.forEach(nutritionStatistic => {
            if (nutritionStatistic.getCalories() > 0) {
              calories += nutritionStatistic.getCalories()
              carbohydrates += nutritionStatistic.carbohydrates
              protein += nutritionStatistic.protein
              fat += nutritionStatistic.fat
              count++
            }
          })
          if (count > 0) {
            week.averageNutritionValues.set('calories', calories / count)
            week.averageNutritionValues.set('carbohydrates', carbohydrates / count)
            week.averageNutritionValues.set('protein', protein / count)
            week.averageNutritionValues.set('fat', fat / count)
          }
        }
        week.loadingNutritionData = false
      }

      if (this.defaultTableColumnIds?.length > 0) {
        var newMetricsOrder = []
        this.defaultTableColumnIds.forEach(metricId => {
          var metric = week.metrics.find(x => x.metricId == metricId)
          if (metric) newMetricsOrder.push(metric)
        })
        week.metrics.forEach(metric => {
          if (!newMetricsOrder.includes(metric)) newMetricsOrder.push(metric)
        })
        week.metrics = newMetricsOrder
      }

      if (week.dailyConditions.length > 0 && newWeek) this.weeks.push(week)
      startOfCurrentWeek.setDate(startOfCurrentWeek.getDate() - 7)

      if (!this.defaultTableColumnIds || this.defaultTableColumnIds.length == 0) {
        this.defaultTableColumnIds = []
        week.metrics.forEach(metric => {
          if (!this.defaultTableColumnIds.includes(metric.metricId)) {
            this.defaultTableColumnIds.push(metric.metricId)
          }
        })
        if (this.defaultTableColumnIds.length > 0) this.saveTableColumnConfig()
      }

      week.tableColumnIds = this.defaultTableColumnIds.filter(id => week.metrics.find(x => x.metricId == id) != null || id.startsWith('nutrition_') && this.showNutritionData)
      week.metrics.forEach(metric => {
        if (!week.tableColumnIds.includes(metric.metricId)) {
          week.tableColumnIds.push(metric.metricId)
        }
      })
    }
  }


  onElementInView(event: any, week: ReportingWeek) {
    if (event) {
      if (!week.nutritionStatistics && this.showNutritionData) {
        week.loadingNutritionData = true
        this.loadNutritionData(week.startDate, week.getEndDate())
      }
    }
  }

  async loadNutritionData(startDate: Date, endDate: Date) {
    var result = await this.userService.loadNutritionStatisticsForDateRange(this.user, startDate, endDate)
    result.forEach(x => {
      if (!this.user.nutritionStatistics.find(y => y.date.isSameDate(x.date))) {
        this.user.nutritionStatistics.push(x)
      }
    })
    this.updateData()
  }

  async onOpenMetricImage(metricData: MetricData) {
    if (!metricData.mediaLink) {
      metricData.mediaLink = await this.userService.getMetricDataMediaLink(metricData, this.user.uid)
    }
    const dialogRef = this.dialog.open(MetricDataImageDialogComponent, { data: { imageURL: metricData.mediaLink}});
  }
  async onOpenMetricVideo(metricData: MetricData) {
    if (!metricData.mediaLink) {
      metricData.mediaLink = await this.userService.getMetricDataMediaLink(metricData, this.user.uid)
    }
    const dialogRef = this.dialog.open(VideoViewDialogComponent, { data: { videoUrl: metricData.mediaLink}});
  }

  selectedMetricDataForComparison: any[] = []

  onMetricDataSelectedForComparison(image: any) {
    if(this.selectedMetricDataForComparison.includes(image)){
      this.selectedMetricDataForComparison = this.selectedMetricDataForComparison.filter(item => item.imageURL != image.imageURL)
    } else{
      this.selectedMetricDataForComparison.push(image)
    }
  }

  async onOpenCompareImageDialog() {
    if (this.selectedMetricDataForComparison?.length <= 0) return
    var images = []
    for (var metricData of this.selectedMetricDataForComparison) {
      if (!metricData.mediaLink) {
        metricData.mediaLink = await this.userService.getMetricDataMediaLink(metricData, this.user.uid)
      }
      images.push({imageURL: metricData.mediaLink})
    }
    const dialogRef = this.dialog.open(MetricDataImageDialogComponent, { data: { compareImages: images}});
  }

  onOpenQuestionaire(questionaireResult: QuestionaireResult){
    if (!questionaireResult.canAccess(this.getCoach())) return
    let allAvailableQuestionaireResults = this.dailyConditions.map(x => x.questionaireResults).reduce((a, b) => a.concat(b), []);
    this.onEditQuestionaire(questionaireResult.clone(), allAvailableQuestionaireResults);
  }

  onEditQuestionaire(questionaireResult: QuestionaireResult, allAvailableQuestionaireResults: QuestionaireResult[]){
    const dialogRef = this.dialog.open(CompletedQuestionaireResultsDialogComponent, { data: { selectedQuestionaireResults: [questionaireResult], allAvailableQuestionaireResults: allAvailableQuestionaireResults, user: this.user, editMode: this.editUserDataService.isEditModeActivated}, autoFocus: false});
    dialogRef.afterClosed().subscribe(async result => {

    });
  }

  async onFilloutQuestionaire(questionaireResult: QuestionaireResult){
    let clonedQuestionaireResult = questionaireResult.clone();
    let allAvailableQuestionaireResults = this.dailyConditions.map(x => x.questionaireResults).reduce((a, b) => a.concat(b), []);
    for(let question of clonedQuestionaireResult.assignedQuestionaire.questions){
      let metricData = new MetricData();
      metricData.metric = this.userService.getMetricByMetricId(question.metricId)
      if (!metricData.metric) metricData.metric = await this.userService.fetchMetricByMetricId(question.metricId)
      metricData.metricId = question.metricId;
      metricData.id = question.metricId;
      metricData.value = null;
      metricData.position = question.position;
      metricData.date = new Date();
      if(metricData.metric != null){
        clonedQuestionaireResult.metricData.push(metricData);
      }
    }
    this.onEditQuestionaire(clonedQuestionaireResult, allAvailableQuestionaireResults)
  }


  async onDeleteQuestionaire(questionaireResult: QuestionaireResult){
    if(!questionaireResult.assignedQuestionaire.completed) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { message: this.translate.instant('Möchtest du den zugewiesenen Check-In wirklich löschen?'), title: this.translate.instant('Check-In löschen') },
      });
      dialogRef.afterClosed().subscribe(async result => {
        if(result){
          this.spinnerService.show();
          await this.userService.deleteAssignedQuestionaire(this.user, questionaireResult.assignedQuestionaire);
          // this.init();
          this.spinnerService.hide();
        }
      });
    }
  }

  getColumnName(columnId: string) {
    if (columnId.startsWith('nutrition_')) {
      if (columnId == 'nutrition_calories') return this.translate.instant('Kalorien')
      if (columnId == 'nutrition_carbohydrates') return this.translate.instant('Kohlenhydrate')
      if (columnId == 'nutrition_protein') return this.translate.instant('Protein')
      if (columnId == 'nutrition_fat') return this.translate.instant('Fett')
      return columnId
    }
    return this.getMetricById(columnId)?.getName(this.translate.currentLang)
  }

  getMetricById(metricId: string): Metric {
    return this.userService.getMetricByMetricId(metricId)
  }

  getNutritionStatisticsForDateRange(startDate: Date, endDate: Date) {
    return this.user.nutritionStatistics.filter(x => x.date.isSameOrAfterDate(startDate) && x.date.isSameOrBeforeDate(endDate))
  }

  async onShowNutritionDataToggled(week: ReportingWeek, value: boolean) {
    this.showNutritionData = value
    if (value) {
      this.defaultTableColumnIds = this.defaultTableColumnIds.concat(['nutrition_calories', 'nutrition_carbohydrates', 'nutrition_protein', 'nutrition_fat'])
    } else {
      this.defaultTableColumnIds = this.defaultTableColumnIds.filter(x => !x.startsWith('nutrition_'))
    }
    
    this.updateData()
  }

  onShowMoreWeeks() {
    this.weekCount += 10
    this.updateData()
  }
  onHideWeeks() {
    this.weekCount = 2
    this.updateData()
  }

  dropTableColumn(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.defaultTableColumnIds, event.previousIndex, event.currentIndex);
    this.updateData()
    this.saveTableColumnConfig()
  }

  async saveTableColumnConfig() {
    this.user.weekStatisticsTableColumnIds = this.defaultTableColumnIds
    await this.userService.updateLicenceSettings(this.user)
  }

  getCoach() {
    return this.userService.getLoggedInUser()
  }
}

export class ReportingWeek {
  startDate: Date
  name: string
  dailyConditions: DailyCondition[]
  metrics: Metric[]
  questionaireResults: QuestionaireResult[]
  averageValues: MetricData[]
  tableColumnIds: string[]

  nutritionStatistics: NutritionStatisticsItem[]
  loadingNutritionData: boolean = false
  averageNutritionValues: Map<string, number> = new Map<string, number>()

  getEndDate() {
    var endDate = new Date(this.startDate)
    endDate.setDate(endDate.getDate() + 6)
    return endDate
  }

  getAverageMetricData(metricId: string) {
    return this.averageValues.find(x => x.metricId == metricId)
  }
  getAverageNutritionData(key: string) {
    return this.averageNutritionValues?.get(key)
  }
}