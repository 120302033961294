<div class="container">
    <div class="spacer-10"></div>
    <div class="hint label">
        <i class="bi bi-info-circle"></i>
        <span>{{getCardioZonesHintText()}}</span>
    </div>
    <div class="spacer-10"></div>
    <h2>
        Zonen
    </h2>
    <div class="spacer-10"></div>
    <table *ngIf="zones?.length > 0" class="col-12 sets-table">
        <thead class="variable-header">
            <tr>
                <th class="width-0"></th>
                <th>
                    Zone
                </th>
                <th>
                    Herzfrequenz (%)
                </th>
                <th>
                    Pace (%)
                </th>
                <th>
                    Pace500 (%)
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let zone of zones; let i = index;">
                <tr>
                    <td>
                        <div class="indicator-color-container" *ngIf="!zone.isMaxCardioZone">
                            <div class="zone-color-indicator link"
                                (click)="colorPicker.click(); $event.stopPropagation();"
                                [style.background-color]="getIndicatorColor(zone)">
                            </div>
                            <input #colorPicker class="hidden-color-picker" (click)="$event.stopPropagation();"
                                (colorPickerChange)="setIndicatorColor(zone, $event)"
                                [colorPicker]="getIndicatorColor(zone)"
                                [style.background-color]="getIndicatorColor(zone)" cpOutputFormat="hex" />
                        </div>
                    </td>
                    <td>
                        <input id="input-variable-name" [pattern]="namePattern" placeholder="Zone 1" maxlength="15"
                            required class="input small full-width {{zone.isZoneNameInvalid(zones) ? 'invalid' : ''}}"
                            [value]="zone.name" (input)="setName($event.target.value, i)" type="text">
                    </td>
                    <td>
                        <ng-container *ngIf="!zone.isMaxCardioZone; else maxHRPercentage">
                            <input *ngIf="!zone.isMaxCardioZone" [pattern]="minMaxPercentagePattern" placeholder="10-20"
                                maxlength="15" required class="input small percentage-input"
                                [value]="getMinMaxPercentageString(zone, SetParameter.heartRate)"
                                (input)="setPercentages($event.target.value, zone, SetParameter.heartRate)" type="text"> %
                        </ng-container>
                        <ng-template #maxHRPercentage>
                            <div class="p-2">
                                100%
                            </div>
                        </ng-template>
                    </td>
                    <td>
                        <ng-container *ngIf="!zone.isMaxCardioZone; else maxPacePercentage">
                            <input [pattern]="minMaxPercentagePattern" placeholder="10-20" maxlength="15" required
                                class="input small percentage-input"
                                [value]="getMinMaxPercentageString(zone, SetParameter.pace)"
                                (input)="setPercentages($event.target.value, zone, SetParameter.pace)" type="text"> %
                        </ng-container>
                        <ng-template #maxPacePercentage>
                            <div class="p-2">
                                100%
                            </div>
                        </ng-template>
                    </td>
                    <td>
                        <ng-container *ngIf="!zone.isMaxCardioZone; else maxPace500Percentage">
                            <input *ngIf="!zone.isMaxCardioZone" [pattern]="minMaxPercentagePattern" placeholder="10-20"
                                maxlength="15" required class="input small percentage-input"
                                [value]="getMinMaxPercentageString(zone, SetParameter.pace500)"
                                (input)="setPercentages($event.target.value, zone, SetParameter.pace500)" type="text"> %
                        </ng-container>
                        <ng-template #maxPace500Percentage>
                            <div class="p-2">
                                100%
                            </div>
                        </ng-template>
                    </td>
                    <td>
                        <i *ngIf="!zone.isMaxCardioZone" class="bi bi-x-lg link" (click)="removeCardioZone(zone)"></i>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
    <div class="spacer-10"></div>
    <div class="icon-button" (click)="addCardioZone()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg>
        Hinzufügen
    </div>
</div>