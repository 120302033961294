<span *ngIf="!hideInfo" class="goal-step-info">{{info}}</span>
<div class="circulargraph">
  <dx-circular-gauge
    class="gauge"
    [value]="currentValue">
    <dxo-size [height]="30" [width]="30"></dxo-size>
    <dxo-range-container orientation="center" [backgroundColor]="bgColor" width="5" offset="0">
      <dxi-range *ngIf="currentValue < minimumRangeValue" [startValue]="minimumRangeValue" [endValue]="maximumRangeValue" [color]="rangeColor"></dxi-range>
    </dxo-range-container>

    <dxo-value-indicator
      offset="-2"
      type="rangebar"
      [color]="mainColor"
      size="4">
    </dxo-value-indicator>
    <dxo-scale
      [startValue]="0"
      [endValue]="maximumValue"
      [customTicks]="[minimumRangeValue, maximumRangeValue]">
      <dxo-label [visible]="false"></dxo-label>
      <dxo-tick [length]="4" [shift]="-2" color="#fff"></dxo-tick>
    </dxo-scale>
    <dxo-geometry
      [startAngle]="90"
      [endAngle]="90">
    </dxo-geometry>
  </dx-circular-gauge>
</div>
