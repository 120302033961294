import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { marked } from 'marked';
import { Editor, Toolbar } from 'ngx-editor';
import { ToastrService } from 'ngx-toastr';
import { NodeHtmlMarkdown } from 'node-html-markdown';

@Component({
  selector: 'app-md-editor',
  templateUrl: './md-editor.component.html',
  styleUrls: ['./md-editor.component.css']
})
export class MdEditorComponent implements OnInit, OnDestroy {
  @Input() customElementId: string 
  @Input() compiled: string;
  @Input() placeHolder: string;
  @Input() readOnlyMode: boolean = false;
  
  @Input() set inputValue(value: string) {
    if(this.markdownValue != value) {
      this.setHTMLAndMarkdownValue(value);
      this.forceUpdateChange.emit(false);
    }
  }
  @Output() inputValueChange = new EventEmitter<string>();

  @Input() set forceUpdate(value: boolean) {
    if(value) {
      this.setHTMLAndMarkdownValue(this.markdownValue);
      this.forceUpdateChange.emit(false);
    }
  }
  @Output() forceUpdateChange = new EventEmitter<boolean>();

  @Output() valueChanged = new EventEmitter<string>();
  @Output() focusChanged = new EventEmitter<boolean>();

  editor: Editor;
  htmlValue: string = "";

  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['strike'],
    ['ordered_list', 'bullet_list'],
    [{link: {showOpenInNewTab: false}}]
  ]

  public get editorValue(): string {
    if(this.htmlValue?.length <= 0) {
      this.setHTMLAndMarkdownValue(this.inputValue);
    }
    return this.htmlValue;
  }
  public set editorValue(value: string) {
    this.onValueChange(value);
  }

  constructor(private toastr: ToastrService) { }
  
  ngOnInit(): void {
    this.editor = new Editor();
    this.editor.view.dom.onfocus = (e) => this.onFocus(e);
    this.editor.view.dom.onblur = (e) => this.onBlur(e);
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  setHTMLAndMarkdownValue(markdown: string) {
    try {
      this.htmlValue = this.compileMarkdown(markdown);
      this.markdownValue = NodeHtmlMarkdown.translate(this.htmlValue);
    }
    catch(ex){
      console.error(ex);
      this.toastr.error("Fehler beim Umwandeln der Editor-Eingabe.")
    }
  }

  compileMarkdown(value: string): string {
    if (value == null) return "";
    return marked.parser(marked.lexer(value));
  }

  private markdownValue: string = null;
  onValueChange(html: any) {
    try {
      let markdown = NodeHtmlMarkdown.translate(html);
      if(this.markdownValue == markdown) return;
      this.markdownValue = markdown;
      this.valueChanged.emit(markdown);
    }
    catch(ex){
      console.error(ex);
      this.toastr.error("Fehler beim Umwandeln der Editor-Eingabe.")
    }
  }

  onFocus(e) {
    this.focusChanged.emit(true)
  }
  onBlur(e) {
    this.focusChanged.emit(false)
  }
}
