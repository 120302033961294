<div class="dialog-topbar">
    <h3 translate>Geld überweisen</h3>
</div>

<div class="dialog-content-wrapper">
    <div class="" translate>Bitte führe die folgende Überweisung durch, um deine offenen Rechnungen zu begleichen:</div>

    <div class="bank-information">
        <div class="information-wrapper">
            <div class="information-title" translate>IBAN:</div>
            <div class="information" ngxClipboard [cbContent]="'DE49100101237216974553'" (click)="onCopied()">
                DE49 1001 0123 7216 9745 53
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-files copy-button" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"/>
                    <path d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z"/>
                </svg>
            </div>
        </div>
        <div class="information-wrapper">
            <div class="information-title" translate>Kontoinhaber:</div>
            <div class="information" ngxClipboard [cbContent]="'nutrilize GmbH'" (click)="onCopied()">
                nutrilize GmbH
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-files copy-button" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"/>
                    <path d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z"/>
                </svg>
            </div>
        </div>
        <div class="information-wrapper">
            <div class="information-title" translate>Betrag:</div>
            <div class="information">{{getPrintableAmountWithCurrency()}}</div>
        </div>
        <div class="information-wrapper">
            <div class="information-title" translate>Verwendungszweck:</div>
            <div class="information" ngxClipboard [cbContent]="purposeOfTransfer" (click)="onCopied()">
                {{purposeOfTransfer}}
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-files copy-button" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"/>
                    <path d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z"/>
                </svg>
            </div>
        </div>
    </div>

    <div translate>Klicke dann auf "Überweisung getätigt".<br>Sobald die Überweisung bei uns eingegangen ist, stellen wir den Status auf "Bezahlt" um.</div>
</div>

<mat-dialog-actions>
    <div class="actions">
        <button class="button-filled small light" (click)="onClose()" translate>Schließen</button>
        <button class="button-filled small light float-right" (click)="onConfirmBankTransfer()" translate>Überweisung getätigt</button>
    </div>
</mat-dialog-actions>