<div class="page-content">

    <div *ngIf="paymentService?.loading" class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h2 class="" translate>Zahlung</h2>
            </div>
            <div class="col-12">
                <div class="spinner-wrapper">
                    <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!paymentService?.loading" class="container-fluid">
        <div class="row">
            <div class="col-9 col-lg-9 col-xl-8 col-xxl-4 order-1 order-xxl-1 profile">
                <h2 class="" translate>Zahlung</h2>
            </div>
            <div class="col-12 col-xl-12 col-xxl-4 order-3 order-xxl-2">
                <div class="div textalign-center tab-button-group adjust-mobile">
                    <div class="spacer-10"></div>
                    <button *ngIf="(userService.getLoggedInUser()?.coach?.hasLicenceHolderRights || userService.getLoggedInUser()?.coach?.canAccessPayment) && onboardingCompleted" class="tab-button {{showOrders ? 'active' : ''}}" (click)="swapTab('orders')">
                       <span translate>Verwaltung</span>
                        <div class="underline"></div>
                    </button>
                    <button *ngIf="userService.getLoggedInUser()?.coach?.hasLicenceHolderRights || userService.getLoggedInUser()?.coach?.canAccessProducts" class="tab-button {{showPayment ? 'active' : ''}}" (click)="swapTab('payment')">
                        <span translate>Einrichtung</span>
                        <div class="underline"></div>
                    </button>
                </div>
            </div>
        </div>

        <div *ngIf="showOrders">
            <app-orders></app-orders>
        </div>
        <div *ngIf="showPayment">
            <div class="row">

                <div class="col-12 col-lg-4">
                    <div *ngIf="utilityService.onNativeMobileApp()" class="layout-box">
                        <h3 class="display-inlineblock" translate>Status</h3><br>
                        <span translate>Rufe die Coaching Zone im Browser auf und melde dich an, um auf die Zahlungsabwicklung zuzugreifen.</span>
                    </div>
            
                    <div *ngIf="!utilityService.onNativeMobileApp()" class="layout-box">
                        <h3 class="display-inlineblock">Status</h3>
                        <div *ngIf="user?.getStripeAccountId() && stripeAccountVerified != null" class="account-verification">
                            <div class="verification-indicator {{stripeAccountVerified == false ? 'red' : ''}}"></div>
                            {{stripeAccountVerified == true ? ('Verifiziert' | translate) : ('Unvollständig' | translate)}}
                        </div>
                        <a *ngIf="!environment.isWhitelabel" href="https://www.loom.com/share/2e2c5368f9cb4488a9d7c41ebb9e29ff?sid=1e041f0d-5e24-446e-89b7-4d0db3b38a7b" target="_blank" class="preview-link marginleft-25">
                            <div class="icon-button" translate>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                </svg>
                                Tutorial aufrufen
                            </div>
                        </a>
                        <div *ngIf="onboardingCompleted && products.length > 0 && accountBalance != null">
                            <div *ngIf="accountBalance.availableAmount > 0 || accountBalance.pendingAmount == 0" class="display-inlineblock marginright-25">
                                <div class="label" translate>
                                    Verfügbare Auszahlungen
                                </div>
                                <div class="balance-value">
                                    {{getPrintableAvailableBalance()}} 
                                    <a class="icon-button marginleft-10" [tooltip]="'Das Datum deiner nächsten Auszahlung kannst du in Stripe festlegen. Klicke, um zu Stripe zu gelangen.' | translate" href="https://dashboard.stripe.com/settings/payouts" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                            <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div *ngIf="accountBalance.pendingAmount > 0" class="display-inlineblock">
                                <div class="label" translate>
                                    Bald verfügbare Auszahlungen
                                </div>
                                <div class="balance-value">
                                    {{getPrintablePendingBalance()}}
                                    <div class="icon-button marginleft-10" tooltip="Sobald der Betrag bei Stripe eingegangen und verfügbar ist, steht er zur Auszahlung bereit.">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                            <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="spacer-10"></div>
            
                        <div class="layout-box sub-box" *ngIf="!(stripeAccountVerified && payments?.length > 0)">
                            <div class="row">
                                <div class="spacer-10"></div>

                                <div class="step-wrapper col-12">
                                    <div class="step-indicator">
                                        <div class="step-number">1</div>
                                        <div class="step-line"></div>
                                    </div>
                                    <div class="step-content">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="step-name" translate>Zahlungsabwicklung aktivieren</div>
                                            </div>
                                            <div class="spacer-10"></div>
                                            <ng-container *ngIf="!user?.getStripeAccountId()">
                                                <button class="button-filled small" (click)="createStripeConnectAccount()" translate>
                                                    Aktivieren
                                                </button>
                                            </ng-container>
                                            <div class="spacer-10"></div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="step-wrapper col-12" *ngIf="user?.getStripeAccountId()">
                                    <div class="step-indicator">
                                        <div class="step-number">2</div>
                                        <div class="step-line"></div>
                                    </div>
                                    <div class="step-content">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="step-name">Stripe-Account einrichten</div>
                                            </div>
                                            <div class="spacer-10"></div>
                                            <div *ngIf="!onboardingCompleted" class="col-12">
                                                <span>
                                                    Zur Abwicklung von Zahlungen arbeiten wir mit Stripe zusammen. Erstelle über den folgenden Button einen Stripe-Account oder melde dich mit einem bestehenden Account an.
                                                </span>
                                                <div class="spacer-10"></div>
                                                <a *ngIf="stripeConnectAccountLink" href="{{stripeConnectAccountLink}}" target="_blank" class="display-inlineblock marginleft-25">
                                                    <button class="button-filled small" translate>
                                                        Einrichtung starten
                                                    </button>
                                                </a>
                                            </div>
                                            <div class="spacer-10"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="step-wrapper col-12" *ngIf="onboardingCompleted">
                                    <div class="step-indicator">
                                        <div class="step-number">3</div>
                                        <div class="step-line"></div>
                                    </div>
                                    <div class="step-content">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="step-name" translate>Angaben vervollständigen</div>
                                            </div>
                                            <div class="spacer-10"></div>
                                            <div *ngIf="!paymentSettings?.isInformationValid()" class="col-12">
                                                <span translate>Fülle alle Felder im unteren Bereich vollständig aus.</span>
                                                <div class="spacer-10"></div>
                                            </div>
                                            <div class="spacer-10"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="step-wrapper col-12" *ngIf="paymentSettings?.isInformationValid()">
                                    <div class="step-indicator">
                                        <div class="step-number">4</div>
                                        <div class="step-line"></div>
                                    </div>
                                    <div class="step-content">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="step-name" translate>Produkte anlegen</div>
                                            </div>
                                            <div class="spacer-10"></div>
                                            <div *ngIf="products?.length == 0" class="col-12">
                                                <span translate>Lege nun deine ersten Produkte an.</span><br>
                                                <span class="bold" translate>Wichtig: </span> <span translate>Lösche oder bearbeite keine Kunden, Produkte oder Zahlungen über das Stripe-Dashboard. Nutze dafür ausschließlich die Coaching Zone.</span>
                                                <div class="spacer-10"></div>
                                            </div>
                                            <div *ngIf="products?.length > 0" class="col-12">
                                                <span class="bold" translate>Wichtig: </span> <span translate>Lösche oder bearbeite keine Kunden, Produkte oder Zahlungen über das Stripe-Dashboard. Nutze dafür ausschließlich die Coaching Zone.</span>
                                                <div class="spacer-10"></div>
                                            </div>
                                            <div class="spacer-10"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="step-wrapper col-12" *ngIf="products?.length > 0">
                                    <div class="step-indicator">
                                        <div class="step-number">5</div>
                                        <div class="step-line"></div>
                                    </div>
                                    <div class="step-content">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="step-name" translate>Zahlungen erhalten</div>
                                            </div>
                                            <div class="spacer-10"></div>
                                            <div class="col-12">
                                                <span translate>Ziehe deine erste (Test-) Zahlung ein.</span><br>
                                                <span class="bold" translate>Wichtig: </span> <span translate>Beginne bei deinen ersten Zahlungen mit einem Betrag unter 250€, da Stripe neue Accounts zunächst genauer prüft und hohe Beträge blockiert. Sobald du die ersten Zahlungen erfolgreich erhalten hast, kannst du die Beträge erhöhen.</span>
                                                <div class="spacer-10"></div>
                                            </div>
                                            <div class="spacer-10"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div *ngIf="!onboardingCompleted && !user?.getStripeAccountId()" class="col-12 col-lg-8">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="spacer-25"></div>
                                <span [innerHTML]="'Möchtest du Zahlungen von deinen Coachees annehmen und deine Produkte verkaufen?<br><br>Folgende Funktionen sind schon verfügbar:<br>- Produkte mit verschiedenen Laufzeiten, Preisen und Konfigurationen anlegen<br>- Landing-Page mit ausgewählten Produkten und Calendly-Integration teilen<br>- Zahlungen erhalten und automatisch Rechnungen erstellen<br>- Emailvorlagen anpassen<br>- Einzelne Produkte auf der eigenen Website einbinden<br>- Und vieles mehr ist in Entwicklung!<br><br>Um die Funktionen nutzen zu können, führe die Schritte aus und richte die Zahlungsabwicklung ein!<br><br>Kosten fallen nur bei Transaktionen an. Diese kannst du in der Preisübersicht auf Seite 2 einsehen.<br><br>' | translate"></span>
                                <a *ngIf="environment.firebaseProjectId != 'traindoo-app'" href="https://drive.google.com/file/d/1xK3O7UwfIxYOchL94qst5kKhtFqWU8R-/view?usp=drive_link" target="_blank" class="pricing-button">
                                    <div class="icon-button" translate>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="padding-4 bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                                            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                                            <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"/>
                                        </svg>
                                        Preisübersicht ansehen
                                    </div>
                                </a>
                
                                <div class="spacer-25"></div>

                                <div *ngIf="environment.firebaseProjectId != 'traindoo-app'" class="row">
                                    <div class="col-12 col-xxl-9 col-xxxl-6">
                                        <div style="position: relative; padding-bottom: 64.92335437330928%; height: 0;"><iframe src="https://www.loom.com/embed/2e2c5368f9cb4488a9d7c41ebb9e29ff?sid=b0467f27-ebc2-488a-b47b-9fc06bc029ad" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="onboardingCompleted && !utilityService.onNativeMobileApp()" class="col-12 col-lg-8">
                    <div class="layout-box products-layout-box">
                        <h3 class="display-inlineblock" translate>Produkte</h3>
                        <button *ngIf="paymentService.chargesEnabled" class="button-filled small marginleft-25" (click)="onCreateProduct()" translate>
                            Erstellen
                        </button>
            
                        <div class="spacer-10"></div>
                        <input id="productsearch-input" class="input wide client display-inlineblock" (input)="onProductSearchInputChanged($event.target.value)" placeholder="Suchen" type="text">
                        <div *ngIf="productSearchInput?.length > 0" class="icon-button deletesearch-icon delete-clientsearch" (click)="onDeleteProductSearchInput()">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </div>
                        <div class="spacer-10"></div>

                        <div *ngIf="!paymentService.chargesEnabled" translate>Richte deinen Account ein, um Produkte zu erstellen.</div>
                        
                        <div class="products-wrapper">
                            <div class="row layout-box sub-box link" *ngFor="let product of (filteredProducts ?? products)" (click)="onSelectProduct(product)">
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <b>{{product.name}}</b>
                                        </div>
                                        <div class="col">
                                            {{product.getPrintablePriceWithCurrency()}} {{product.getPrintablePaymentFrequency(translate)}}<ng-container *ngIf="product.getPrintableInitialSetupFee() != null"><br>+ {{product.getPrintableInitialSetupFeeWithCurrency()}} Einmalgebühr</ng-container>
                                        </div>
                                        <div class="col">
                                            {{product.getPrintableDuration(translate)}}
                                        </div>
                                        <div class="col">
                                            <div class="icon-button" (click)="onDuplicateProduct(product);$event.stopPropagation()">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="padding-4 bi bi-files" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"/>
                                                    <path d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z"/>
                                                </svg>
                                                <span class="icon-button-text" translate>Duplizieren</span>
                                            </div><br>
                                            <ng-container *ngIf="product.productPageEnabled || product.showOnLandingPage">
                                                <a *ngIf="paymentService.canUsePayment" href="{{product.getProductPageLink()}}" target="_blank" (click)="$event.stopPropagation()">
                                                    <div class="icon-button small" translate>
                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                                        </svg>
                                                        Produktseite
                                                    </div>
                                                </a>
                                                <div class="label" *ngIf="!paymentService.canUsePayment" translate>
                                                    Stelle erst die Einrichtung fertig, um die Produktseite zu nutzen.
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12" *ngIf="onboardingCompleted && userService.getLoggedInUser()?.coach?.hasLicenceHolderRights">
                    <div *ngIf="utilityService.onNativeMobileApp()" class="layout-box">
                        <h3 class="display-inlineblock" translate>Zahlungsabwicklung</h3><br>
                        <span translate>Rufe die Coaching Zone im Browser auf und melde dich an, um auf die Zahlungsabwicklung zuzugreifen.</span>
                    </div>
            
                    <div *ngIf="!utilityService.onNativeMobileApp()" class="layout-box">
                        <h3 class="display-inlineblock" translate>Einstellungen</h3>
                        <div class="spacer-10"></div>

                        <div class="row">
                            <div class="col-12 textalign-center tab-button-group adjust-mobile">
                                <button class="tab-button {{currentSetupTab == tabSetupInformation ? 'active' : ''}} {{paymentSettings?.isInformationValid() ? '' : 'has-error'}}" (click)="onChangeSetupTab(tabSetupInformation)">
                                    <span translate>Anbieterinformationen</span>
                                    <div class="underline"></div>
                                </button>
                                <button class="tab-button {{currentSetupTab == tabSetupInvoicing ? 'active' : ''}}" (click)="onChangeSetupTab(tabSetupInvoicing)">
                                    <span translate>Rechnungsstellung</span>
                                    <div class="underline"></div>
                                </button>
                                <button class="tab-button {{currentSetupTab == tabSetupEmails ? 'active' : ''}} {{paymentSettings?.areTemplatesValid() ? '' : 'has-error'}}" (click)="onChangeSetupTab(tabSetupEmails)">
                                    <span translate>Email-Vorlagen</span>
                                    <div class="underline"></div>
                                </button>
                                <button class="tab-button {{currentSetupTab == tabSetupLandingPage ? 'active' : ''}}" (click)="onChangeSetupTab(tabSetupLandingPage)">
                                    <span translate>Landing-Page</span>
                                    <div class="underline"></div>
                                </button>
                            </div>
                        </div>
                        <div class="spacer-25"></div>
                        
                        <div *ngIf="currentSetupTab == tabSetupInformation && paymentSettings" class="row">
                            <div class="col-12 bold">
                                <span translate>Anbieterinformationen</span>
                                <div class="spacer-10"></div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                                <div class="label" translate>Name/Firmenname</div>
                                <div class="spacer-10"></div>
                                <input id="input-name" class="input wide" [(ngModel)]="paymentSettings.companyName" [placeholder]="'Name' | translate" required type="text">
                                <div class="spacer-10"></div>
                                <div class="label" translate>USt-IdNr</div>
                                <div class="spacer-10"></div>
                                <input id="input-name" class="input wide" [(ngModel)]="paymentSettings.vatIdNo" [placeholder]="'USt-IdNr' | translate" type="text">
                                <div class="spacer-10"></div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                                <div class="label" translate>Email</div>
                                <div class="spacer-10"></div>
                                <input id="input-email" class="input wide {{paymentSettings?.isEmailValid() ? '' : 'invalid'}}" [(ngModel)]="paymentSettings.email" [placeholder]="'Email-Adresse' | translate" required type="text">
                                <div class="spacer-10"></div>
                                <div class="label" translate>Handy/Telefon</div>
                                <div class="spacer-10"></div>
                                <input id="input-phone" class="input wide" [(ngModel)]="paymentSettings.phone" [placeholder]="'Telefon-/Handynummer' | translate" type="text">
                                <div class="spacer-10"></div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                                <div class="label" translate>Anschrift</div>
                                <div class="spacer-10"></div>
                                <textarea id="input-description" class="input wide {{paymentSettings?.address?.length > 0 ? '' : 'invalid'}}" [(ngModel)]="paymentSettings.address" [placeholder]="'Adresse' | translate" type="text" rows="4"></textarea>
                                <div class="spacer-10"></div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                                <div class="label display-inlineblock" translate>Impressum-Link</div>
                                <div class="icon-button marginleft-25" [tooltip]="'Hinterlege den Link zum Impressum deiner Website oder zur nutrilize Landing-Page. Der Impressum-Link wird im Footer deiner Emails erscheinen.' | translate">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                    </svg>
                                </div>
                                <div class="spacer-10"></div>
                                <input id="input-name" class="input wide" [(ngModel)]="paymentSettings.imprintUrl" placeholder="" required type="text">
                                <div class="spacer-10"></div>

                                <div class="label display-inlineblock" translate>Abweichende Impressumangaben</div>
                                <div class="icon-button marginleft-25" [tooltip]="'Auf deinen Produktseiten und der Landing-Page erscheinen unten deine Anbieterinformationen als Impressum. Wenn du diese Angaben inkl. Anschrift nicht öffentlich machen möchtest, kannst du hier alternative Angaben machen.' | translate">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                    </svg>
                                </div>
                                <div class="spacer-10"></div>
                                <textarea id="input-description" class="input wide" [(ngModel)]="paymentSettings.alternativeImprintAddress" [placeholder]="'Name und Adresse' | translate" type="text" rows="4"></textarea>
                                <div class="spacer-10"></div>
                            </div>

                            <div class="col-12">
                                <div class="spacer-10"></div>
                                <div class="caution" *ngIf="!paymentSettings?.isInformationValid()">
                                    <span translate>Bitte fülle alle erforderlichen Felder aus.</span>
                                    <div class="spacer-25"></div>
                                </div>
                                <button class="button-filled small" (click)="onSaveSettings()" translate>Speichern</button>
                            </div>
                            <div class="spacer-10"></div>
                        </div>
                        
                        <div *ngIf="currentSetupTab == tabSetupInvoicing" class="row">

                            <div class="col-12 col-md-4 col-lg-3 col-xl-2">
                                <div class="label" translate>Währung</div>
                                <div class="spacer-10"></div>
                                <div class="d-inline-block wrapper-selection">
                                    <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownTrigger" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{PaymentComponent.getPrintableCurrency(paymentSettings?.currency)}}</button>
                                    <div class="dropdown-menu currency-dropdown">
                                        <div class="dropdown-item link {{paymentSettings?.currency == null || paymentSettings?.currency == 'eur' ? 'active' : ''}}" (click)="onCurrencySelected('eur')">
                                            <div class="display-inline">{{PaymentComponent.getPrintableCurrency('eur')}}</div>
                                        </div>
                                        <div class="dropdown-item link {{paymentSettings?.currency == 'chf' ? 'active' : ''}}" (click)="onCurrencySelected('chf')">
                                            <div class="display-inline">{{PaymentComponent.getPrintableCurrency('chf')}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="spacer-10"></div>
                                <div class="label" translate>MwSt.-Satz</div>
                                <div class="spacer-10"></div>
                                <input id="input-name" class="input small" [(ngModel)]="paymentSettings.vatRate" [disabled]="paymentSettings.hideVatRate" placeholder="19" required type="number"> %
                                <div class="spacer-10"></div>
                                <div class="display-inlineblock">
                                    <input class="input checkbox" type="checkbox" [(ngModel)]="paymentSettings.hideVatRate"><div class="label display-inline"> MwSt. deaktivieren (z.B. bei Kleinunternehmerregelung)</div>
                                </div>
                                <div class="spacer-10"></div>
                            </div>
                            <div class="col-12 col-md-4 col-lg-3 col-xl-2">
                                <div class="label" translate>Zahlungsmethoden</div>
                                <div class="spacer-10"></div>
                                <div class="d-inline-block wrapper-selection">
                                    <button class="button-filled light small dropdown-toggle margin-inbetween {{paymentSettings?.availablePaymentMethods?.length > 0 ? '' : 'invalid'}}" type="button" id="dropdownTrigger" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{paymentSettings?.getPrintablePaymentMethods(translate) || ('Auswählen' | translate)}}</button>
                                    <div class="dropdown-menu ">
                                        <div class="dropdown-item link" (click)="onPaymentMethodSelected('sepa_debit');$event.stopPropagation()">
                                            <input class="input checkbox" type="checkbox" [checked]="paymentSettings?.availablePaymentMethods?.includes('sepa_debit')"><div class="display-inline" translate>Sepa-Lastschrift</div>
                                        </div>
                                        <div class="dropdown-item link" (click)="onPaymentMethodSelected('bank_transfer');$event.stopPropagation()">
                                            <input class="input checkbox" type="checkbox" [checked]="paymentSettings?.availablePaymentMethods?.includes('bank_transfer')"><div class="display-inline" translate>Banküberweisung</div>
                                        </div>
                                        <div class="dropdown-item link" (click)="onPaymentMethodSelected('card');$event.stopPropagation()">
                                            <input class="input checkbox" type="checkbox" [checked]="paymentSettings?.availablePaymentMethods?.includes('card')"><div class="display-inline" translate>Kreditkarte</div>
                                        </div>
                                        <!--div class="dropdown-item link" (click)="onPaymentMethodSelected('klarna');$event.stopPropagation()">
                                            <input class="input checkbox" type="checkbox" [checked]="paymentSettings?.availablePaymentMethods?.includes('klarna')"><div class="display-inline">Klarna</div>
                                        </div-->
                                    </div>
                                </div>
                                <div class="spacer-10"></div>
                                <div class="label" [innerHTML]="getActivateStripeHintTranslation()"></div>
                                <div class="spacer-10"></div>
                                <div class="label">Um Apple Pay und Google Pay zu nutzen, schau dir unseren <div class="underline link display-inline" (click)="openApplePayArticle()">Hilfe-Artikel</div> dazu an.</div>
                                <div class="spacer-10"></div>
                                <div class="" translate>Eigene AGBs (optional)</div>
                                <div class="spacer-10"></div>
                                <div *ngIf="paymentSettings?.termsAndConditionsUrl" class="display-inlineblock">
                                    <a href="{{paymentSettings.termsAndConditionsUrl}}" class="icon-button" target="_blank" translate>Aktuelle AGBs öffnen</a>
                                </div>
                                <button class="button-filled light small marginleft-25" (click)="onUploadTermsAndConditions()" translate>PDF hochladen</button>
                                <input id="input-terms" type="file" accept=".pdf" class="display-none" (change)="termsAndConditionsUploaded($event)">
                            </div>

                            <div class="col-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="bold" translate>Rechnungsnummer</div>
                                <div class="spacer-10"></div>
                                <div class="label">{{ 'Standardmäßig ist die Rechnungsnummer eine zufällige Zeichenfolge. Um sie anzupassen, lege das Format fest und nutze die Platzhalter. \{\{Number\}\} muss zwingend genutzt werden.' | translate }}</div>
                                <div class="spacer-10"></div>
                                <div class="label"><span>{{ 'Format:' | translate }}<br>{{ 'Verfügbare Platzhalter: \{\{Number\}\},\{\{Year}\},\{\{Month}\},\{\{Day}\}' | translate }}</span><span *ngIf="!paymentSettings?.isInvoiceNumberFormatValid()"><br>{{ '\{\{Number\}\} muss enthalten sein und es darf kein "/" vorkommen.' | translate }}</span></div>
                                <div class="spacer-10"></div>
                                <input id="input-email" class="input wide {{paymentSettings?.isInvoiceNumberFormatValid() ? '' : 'invalid'}}" [(ngModel)]="paymentSettings.invoiceNumberFormat" [placeholder]="'Standard: Zufällig' | translate" type="text">
                                <div class="spacer-10"></div>
                                <ng-container *ngIf="paymentSettings?.invoiceNumberFormat?.length > 0">
                                    <div class="label" translate>Nummer je Rechnung erhöhen um:</div>
                                    <div class="spacer-10"></div>
                                    <input id="input-name" class="input width-100px" [(ngModel)]="paymentSettings.invoiceNumberIncrement"  type="number">
                                    <div class="spacer-10"></div>
                                    <div class="label" translate>Aktuelle Nummer:</div>
                                    <div class="spacer-10"></div>
                                    <input id="input-name" class="input width-100px" [(ngModel)]="paymentSettings.invoiceNumberNext"  type="number">
                                    <div class="spacer-10"></div>
                                    <span translate>Nächste Rechnung:</span> {{paymentSettings?.getInvoiceFormatPreview()}}
                                </ng-container>
                            </div>

                            <div class="col-12 col-md-6 col-lg-6 col-xl-3">
                                <div class="label" translate>Rechnungszusatz</div>
                                <div class="spacer-10"></div>
                                <textarea id="input-invoicetext" class="input full-width" [(ngModel)]="paymentSettings.invoiceHintText" [placeholder]="'Zusatztext für Rechnung, bspw. Kleinunternehmerregelung' | translate" type="text" rows="4"></textarea>
                            </div>

                            <div class="col-12">
                                <div class="spacer-10"></div>
                                <button class="button-filled small" (click)="onSaveSettings()" translate>Speichern</button>
                            </div>
                        </div>
                        
                        <div *ngIf="currentSetupTab == tabSetupEmails && paymentSettings"  class="emailtemplates-wrapper">
                            <div class="bold" translate>Emailvorlagen</div>
                            <div class="spacer-10"></div>
                            <div class="caution" *ngIf="!paymentSettings?.areTemplatesValid()">
                                <span translate>Bitte fülle alle Emailvorlagen aus.</span>
                                <div class="spacer-25"></div>
                            </div>
                            <button class="button-filled light small marginleft-25 {{paymentSettings?.customerPurchaseConfirmationEmailTemplate ? '' : 'invalid'}}" (click)="onEditPurchaseConfirmationEmail()" translate>Kaufbestätigungs-Email anpassen</button>
                            <button class="button-filled light small marginleft-25 {{paymentSettings?.customerKickoffEmailTemplate ? '' : 'invalid'}}" (click)="onEditKickoffEmail()" translate>Kickoff-Email anpassen</button>
                            <button class="button-filled light small marginleft-25 {{paymentSettings?.customerInvoiceEmailTemplate ? '' : 'invalid'}}" (click)="onEditInvoiceEmail()" translate>Rechnungs-Email anpassen</button>
                            <button class="button-filled light small marginleft-25 {{paymentSettings?.customerPaymentRequestEmailTemplate ? '' : 'invalid'}}" (click)="onEditPaymentRequestEmail()" translate>Zahlungsaufforderungs-Email anpassen</button>
                            <button class="button-filled light small marginleft-25 {{paymentSettings?.customerCancelationConfirmationEmailTemplate ? '' : 'invalid'}}" (click)="onEditCancelationConfirmationEmail()" translate>Kündigungsbestätigungs-Email anpassen</button>
                            <button class="button-filled light small marginleft-25 {{paymentSettings?.customerPurchaseExpirationEmailTemplate ? '' : 'invalid'}}" (click)="onEditPurchaseExpirationEmail()" translate>Laufzeitende-Email anpassen</button>
                        </div>

                        <div *ngIf="currentSetupTab == tabSetupLandingPage" class="row">

                            <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                                <div class="display-inlineblock">
                                    <input class="input checkbox" type="checkbox" [(ngModel)]="paymentSettings.landingPageEnabled"> <span translate>Landing-Page aktivieren</span>
                                </div>
                                <div class="spacer-10"></div>
                                <ng-container *ngIf="paymentSettings.landingPageEnabled">
                                    <a *ngIf="paymentService.canUsePayment" href="{{paymentService.getLandingPageLink()}}" target="_blank">
                                        <div class="icon-button small" translate>
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                                            </svg>
                                            Landing-Page
                                        </div>
                                    </a>
                                    <div class="label" *ngIf="!paymentService.canUsePayment" translate>
                                        Stelle erst die Einrichtung fertig, um die Landing-Page zu nutzen.
                                    </div>
                                </ng-container>
                            </div>
                            
                            <div class="col-12 col-md-6 col-lg-4 col-xl-3" *ngIf="paymentSettings.landingPageEnabled">
                                <div class="label"><span translate>Landing-Page-Url anpassen</span> ({{environment.baseUrl}}/profile/{{customLandingPageSlug ?? user.licenceHolderUid}})</div>
                                <div class="spacer-10"></div>
                                <input id="input-name" class="input wide {{isLandingPageSlugValid() ? '' : 'invalid'}}" [(ngModel)]="customLandingPageSlug" placeholder="{{user.licenceHolderUid}}" type="text">
                                <div class="spacer-10"></div>
                                <button class="button-filled small" (click)="onCheckLandingPageSlug()" translate>Überprüfen und speichern</button>
                                <div class="spacer-25"></div>
                                <div class="label" translate>Calendly-Meeting-Link</div>
                                <div class="spacer-10"></div>
                                <input id="input-name" class="input wide" [(ngModel)]="paymentSettings.landingPageCalendlyUrl" placeholder="" type="text">
                                <div class="spacer-10"></div>
                                <ng-container *ngIf="paymentSettings.landingPageCalendlyUrl?.length > 0">
                                    <div class="display-inlineblock">
                                        <input class="input checkbox" type="checkbox" [(ngModel)]="paymentSettings.placeCalendlyAboveProducts"> <span translate class="label">Calendly über den Produkten platzieren</span>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                                <div class="label" translate>Buttonfarbe anpassen</div>
                                <div class="spacer-10"></div>
                                <input id="input-name" class="input wide {{isColorValid(paymentSettings.customAccentColor) ? '' : 'invalid'}}" [(ngModel)]="paymentSettings.customAccentColor" placeholder="#000000 (Hex-Code)" type="text">
                                <div class="spacer-10"></div>
                                <div class="label" translate>Button-Schriftfarbe anpassen</div>
                                <div class="spacer-10"></div>
                                <input id="input-name" class="input wide {{isColorValid(paymentSettings.customOnAccentTextColor) ? '' : 'invalid'}}" [(ngModel)]="paymentSettings.customOnAccentTextColor" placeholder="#ffffff (Hex-Code)" type="text">
                                <div class="spacer-10"></div>
                                <div class="label" translate>Schriftfarbe anpassen</div>
                                <div class="spacer-10"></div>
                                <input id="input-name" class="input wide {{isColorValid(paymentSettings.customTextColor) ? '' : 'invalid'}}" [(ngModel)]="paymentSettings.customTextColor" placeholder="#ffffff (Hex-Code)" type="text">
                                <div class="spacer-10"></div>
                                <div class="label" translate>Hintergrundfarbe anpassen</div>
                                <div class="spacer-10"></div>
                                <input id="input-name" class="input wide {{isColorValid(paymentSettings.customBackgroundColor) ? '' : 'invalid'}}" [(ngModel)]="paymentSettings.customBackgroundColor" placeholder="#ffffff (Hex-Code)" type="text">
                                <div class="spacer-10"></div>
                            </div>
                            
                            <div class="col-12">
                                <div class="spacer-10"></div>
                                <button class="button-filled small" (click)="onSaveSettings()" translate>Speichern</button>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>

                
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>