import { Injectable } from '@angular/core';
import { Meal } from '../model/meal.model';
import { NutritionPlan } from '../model/nutritionplan.model';
import { NutritionPlanConfig } from '../model/nutritionplanconfig.model';
import { PlannedMealV2 } from '../model/plannedmealv2.model';
import { User } from '../model/user.model';
import { CalendarDay, DayItem, NutritionPlanComponent } from '../nutrition-plan/nutrition-plan.component';
import { FirestoreNutritionPlanService } from './firestore-nutritionplan.service';
import { FirestoreService } from './firestore.service';
import { PlannedFood } from '../model/plannedfood.model';
import PieChart from "devextreme/viz/pie_chart";
import { BehaviorSubject } from 'rxjs';
import { PlanExportbaseService } from './plan-exportbase.service';
import { environment } from 'src/environments/environment';
import * as pdfMake from 'pdfmake';
import { UtilityService } from './utility.service';
import { NutritionService } from './nutrition.service';

@Injectable({
  providedIn: 'root'
})
export class NutritionplanExportService extends PlanExportbaseService {

  public weekDays = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']

  public exportProgress: BehaviorSubject<string> = new BehaviorSubject<string>("0%");

  // public exportProgress: number = 0;

  constructor(private firestoreService: FirestoreService, userService: FirestoreService, utilityService: UtilityService, private nutritionService: NutritionService) {
    super(userService, utilityService);
  }

  async onExportDiary(mealsForDays: Map<number, Meal[]>, user: User): Promise<boolean> {

    try {
      let docDefinition = {
        footer: {text: 'nutrilize', font: 'Montserrat', alignment: 'center'},
        pageOrientation: 'portrait',
        content: []
      }

      docDefinition.content.push({text: ("Ernährungstagebuch von " + user.name) + "\n", fontSize: 12, alignment: "left", font: "Montserrat", bold: true, margin: [0,0,0,5]})

      // Access loaded meals for each date:
      var keys = Array.from(mealsForDays.keys())
      for (var key of keys) {
        var date = new Date(key)
        var meals = mealsForDays.get(key)

        var carb = 0
        var prot = 0
        var fat = 0
        var calo = 0

        meals.forEach(m => {
          carb = carb + Math.round(m.getCarbohydrates())
          prot = prot + Math.round(m.getProtein())
          fat = fat + Math.round(m.getFat())
          calo = calo + Math.round(m.getCalories())
        })

        docDefinition.content.push({text: "Zusammenfassung vom " + this.weekDays[date.getDayNumber()].substr(0,2) + "., " + date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear() + "", fontSize: 10, alignment: "left", font: "Montserrat", bold: true, margin: [0,10,0,2]})
        docDefinition.content.push({text: "Nährwerte: " + carb + "g K / " + prot + "g E / " + fat + "g F / " + calo + " kcal" + "\n", fontSize: 7, alignment: "left", font: "Montserrat", bold: false, margin: [0,0,0,5]})

        if (meals.length == 0) {
          docDefinition.content.push({text: "Keine Mahlzeiten gefunden." + "\n", fontSize: 8, alignment: "left", font: "Montserrat", margin: [0,0,0,5]})
        }
        var tablePDFBig = {
          layout: 'noBorders',
          table: {
            widths: ['*', '*'],

            body: []
          }
        }

        tablePDFBig.table.body.push([{text: "", fontSize: 6, font: "Montserrat"}, {text: "", fontSize: 6, font: "Montserrat"}])

        var mealsArray = [];
        var summaryArray = [];
        var imageArray = [];
        var dateArray = [];
        var notesArray = [];
        var feedbackArray = [];

        var i = 0;

        for (var m of meals) {
          dateArray.push({text: (m.name ?? 'Mahlzeit') + ' um ' + m.date.toLocaleTimeString().substr(0, m.date.toLocaleTimeString().length - 3) + " Uhr", fontSize: 8, font: "Montserrat", bold: true, margin: [0,10,0,1]})

          summaryArray.push({text: "Nährwerte: " + Math.round(m.getCarbohydrates()) + "g K / " + Math.round(m.getProtein()) + "g E / " + Math.round(m.getFat()) + "g F / " + Math.round(m.getCalories()) + " kcal", fontSize: 7, font: "Montserrat"})

          notesArray.push({text: m.coacheeNote?.length > 0 ? ("Notiz: " + m.coacheeNote) : '', fontSize: 7, font: "Montserrat"})
          feedbackArray.push({text: m.comment?.length > 0 ? ("Feedback: " + m.comment) : '', fontSize: 7, font: "Montserrat"})

          var tablePDF = {
            layout: 'noBorders',
            table: {
              widths: ['auto', 'auto'],
              body: []
            }
          }

          var imageUrl = m.imageLink
          if (!imageUrl) {
            var promiseImage = await this.firestoreService.loadMealImageLinkForMeal(user, m).toPromise()
            imageUrl = m.imageLink
          }
          if (imageUrl) {
            let base64Image = await this.getBase64ImageFromURL(imageUrl);
            if(base64Image){
              imageArray.push({
                image: base64Image, fit: [100, 100]
              })
            }
            else {
              imageArray.push({text: "", fontSize: 8, font: "Montserrat"})
            }

          } else {
            imageArray.push({text: "", fontSize: 8, font: "Montserrat"})
          }

          tablePDF.table.body.push([{text: "", fontSize: 6, font: "Montserrat"}, {text: "", fontSize: 6, font: "Montserrat"}])

          m.foods.forEach(f => {
            var singleFoods = [];
            singleFoods.push({text: f.name, fontSize: 7, font: "Montserrat"});
            singleFoods.push({text: f.weight + "g", fontSize: 7, font: "Montserrat"});
            tablePDF.table.body.push(singleFoods)
          })
          mealsArray.push(tablePDF)
          i = i + 1
          if(i == 2) {
            tablePDFBig.table.body.push(dateArray)
            tablePDFBig.table.body.push(summaryArray)
            tablePDFBig.table.body.push(notesArray)
            tablePDFBig.table.body.push(feedbackArray)
            tablePDFBig.table.body.push(imageArray)
            tablePDFBig.table.body.push(mealsArray)
            mealsArray = []
            dateArray = []
            summaryArray = []
            imageArray = []
            notesArray = []
            feedbackArray = []
            i = 0
          }
        }

        if(i != 0) {
          dateArray.push({text: "", fontSize: 8, font: "Montserrat"})
          summaryArray.push({text: "", fontSize: 8, font: "Montserrat"})
          mealsArray.push({text: "", fontSize: 8, font: "Montserrat"})
          imageArray.push({text: "", fontSize: 8, font: "Montserrat"})
          notesArray.push({text: "", fontSize: 8, font: "Montserrat"})
          feedbackArray.push({text: "", fontSize: 8, font: "Montserrat"})
          tablePDFBig.table.body.push(dateArray)
          tablePDFBig.table.body.push(summaryArray)
          tablePDFBig.table.body.push(notesArray)
          tablePDFBig.table.body.push(feedbackArray)
          tablePDFBig.table.body.push(imageArray)
          tablePDFBig.table.body.push(mealsArray)
        }
        docDefinition.content.push(tablePDFBig)
      }

      let pdf = pdfMake.createPdf(docDefinition)
      this.downloadPdf(pdf, 'Ernährungstagebuch' + user.name + ".pdf");

      return true
    }
    catch(ex) {
      console.error(ex);
      return false;
    }
  }

  async getDataURL(url: string){
    if(url){
      let blob = await fetch(url).then(r => r.blob());
      let dataUrl = await new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
      return dataUrl;
    }
    else return ''
  }

  async getHeader(brandingImageURL: string){
    let logoDataURL = await this.getDataURL(brandingImageURL);
    if(logoDataURL){
      return {columns: [{image: logoDataURL, width: '*', fit: [100, 50], style: 'right', margin: [ 5, 5, 5, 5 ]}]};
    }
    else {
      return {columns: [{text: '', width: '*'}]};
    }
  }

  async getBrandingImageURL(): Promise<string>{
    try {
      var snapshot = await this.userService.getBrandingSettings(this.userService.getLoggedInUser().licenceHolderUid).toPromise()
      var logoFileName = snapshot?.data()?.logoFileName
      if (logoFileName) {
        return await this.userService.getBrandingImage(this.userService.getLoggedInUser().licenceHolderUid, logoFileName).toPromise()
      }
    } catch(ex) {
      console.error(ex);
    }
    return null;
  }

  async onExportNutritionPlanConfig(nutritionPlanConfig: NutritionPlanConfig, user: User, nutritionPlanService: FirestoreNutritionPlanService, showIngredientsInTable: boolean = true, exportRecipes: boolean = true): Promise<boolean> {
    this.exportProgress?.next("0%");
    var planConfig = nutritionPlanConfig.clone()
    var recipes: Map<string, PlannedMealV2> = new Map()

    let brandingImageURL = environment.isWhitelabel ? null : await this.getBrandingImageURL();
    let header = await this.getHeader(brandingImageURL);
    let footer = await this.getFooter(brandingImageURL);

    let docDefinition = {
      pageMargins: [40, 80, 40, 60],
      header: (currentPage: number) =>
      {
        if(currentPage === 1){
          return header;
        }
      },
      footer: (currentPage, pageCount) => {return {columns: [{text: `${currentPage} / ${pageCount}`, width: '*', style: 'left', margin: [ 15, 15, 15, 15 ]}, ...footer]}},

      pageOrientation: 'landscape',
      defaultStyle: {
        font: 'Montserrat',
        fontSize: 8,
      },
      content: [

      ],
      styles:{
        centered:
        {
            alignment: 'center'
        },
        right:
        {
            alignment: 'right'
        },
        left:
        {
            alignment: 'left'
        },
        carbohydratesPoint: {
          color: '#da4fdc',
          fontSize: 35,
          lineHeight: 0
        },
        proteinPoint: {
          color: '#4AD2DC'
        },
        fatPoint: {
          color: '#E5FF44'
        },
        caloriesPoint: {
          color: '#E92861',
          fontSize: 35,
          lineHeight: 0
        },
      }
    }
    var startDate = planConfig.startDate

    for (var i = 0; i < planConfig.nutritionPlans.length; i++) {
      var nutritionPlan = planConfig.nutritionPlans[i];
      planConfig.selectedNutritionPlan = nutritionPlan


      var endDate = planConfig.endDate// || planConfig.startDate.clone().addDays(365)
      if (nutritionPlan.isRepeating) {
        endDate = planConfig.startDate.clone().addDays(nutritionPlan.repetitionDuration - 1)
      }

      var meals = await nutritionPlanService.getNutritionPlanMealsByPlanId(user, nutritionPlan.id)
      for (var meal of meals) {
        await this.nutritionService.loadBaseRecipeForPlannedMeal(meal)
      }
      if (!endDate) {
        // Calculate endDate by max meal date.
        endDate = planConfig.startDate.clone()
        meals.forEach(m => {
          if (!m.date.isSameOrBeforeDate(endDate)) endDate = m.date
        })
      }

      let title = `${nutritionPlan.getName()} von ${this.userService.getLoggedInUser().name} für ${user.getName()} (${startDate.asShortFormatedString()} - ${endDate.asShortFormatedString()})`
      docDefinition.content.push({text: title, bold: true, fontSize: 12, margin: [0, 0, 0, 10]})

      var days = await NutritionPlanComponent.composeDays(startDate, endDate, user, [], [planConfig], meals, nutritionPlanService, this.nutritionService)

      var columns: any[] = []

      for (var dayIndex = 0; dayIndex < days.length; dayIndex++) {
        var day = days[dayIndex]

        let nutritionPlanCount = planConfig.nutritionPlans.length;
        if(nutritionPlanCount > 1){
          this.exportProgress?.next(`${((dayIndex / days.length) * 100).toFixed()}% (${i + 1}/${nutritionPlanCount})`)
        }
        else {
          this.exportProgress?.next(`${((dayIndex / days.length) * 100).toFixed()}%`)
        }

        var column = {
          width: '*',
          stack: []
        }
        var dayHeader = nutritionPlan.isRepeating ? 'Tag ' + (dayIndex + 1) : day.date.asShortFormatedString()
        column.stack.push({text: dayHeader})

        let mealColumnStack = [];

        for (var item of day.items) {
          if (item.meal) {

            //chart after getNutritionPlanMeal!!!
            await nutritionPlanService.getNutritionPlanMeal(item.meal, user, null)
            for (var alternativeMeal of item.alternativeMeals) {
              await nutritionPlanService.getNutritionPlanMeal(alternativeMeal, user, null)
            }
            mealColumnStack.push({
              stack: this.getDayItemStack(item, showIngredientsInTable)
            })
            // column.stack.push({
            //   stack: this.getDayItemStack(item, showIngredientsInTable)
            // })
            var recipeName = item.meal.getName() + ' (' + item.meal.getNutritionalValue('calories')?.toFixed(0) + ' kcal)'
            if (!recipes.has(recipeName)) {
              recipes.set(recipeName, item.meal)
            }
          }
          for (var meal of item.alternativeMeals) {
            var recipeName = meal.getName() + ' (' + meal.getNutritionalValue('calories')?.toFixed(0) + ' kcal)'
            if (!recipes.has(recipeName)) {
              recipes.set(recipeName, meal)
            }
          }
        }


        if(day.items?.filter(x => x.meal != null).length > 0) {
          let nutritionInfo = [
            {
              arg: `K: ${day.getNutritionalValue('carbohydrates').toFixed(0)} g`,
              val: day.getNutritionalValue('carbohydrates') || 0,
            },
            {
              arg: `E: ${day.getNutritionalValue('protein').toFixed(0)} g`,
              val: day.getNutritionalValue('protein') || 0,
            },
            {
              arg: `F: ${day.getNutritionalValue('fat').toFixed(0)} g`,
              val: day.getNutritionalValue('fat') || 0,
            },
          ]


          let paletteCollection = ['#da4fdc', '#4AD2DC', '#E5FF44']
          let chartElement = document.createElement("pieChartCont");

          let pieChart = new PieChart(chartElement, {
            innerRadius: 0.7,
            type: 'doughnut',
            margin: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0
            },
            width: 30,
            height: 30,
            dataSource: nutritionInfo,
            palette: paletteCollection,
            series: {
              valueField: 'val',
              argumentField: 'arg',
            },
            legend: {
              visible: false,
            },
          });
          pieChart.render();
          let svg = pieChart.svg();
          pieChart.dispose();
          column.stack.push({
            columns: [
              {svg: svg, width: 30, margin: [0, 7, 0, 0]},
              {stack: [
                {margin: [ 0, 3, 0, 0 ], columns: [{canvas: [
                  {
                    type: 'rect',
                    x: 1,
                    y: 1,
                    w: 6,
                    h: 6,
                    color: '#da4fdc',
                  },
                ], width: 'auto'}, {text: `K: ${day.getNutritionalValue('carbohydrates').toFixed(0)} g`, width: 'auto', fontSize: 6}] },
                {margin: [ 0, 3, 0, 0 ], columns: [{canvas: [
                  {
                    type: 'rect',
                    x: 1,
                    y: 1,
                    w: 6,
                    h: 6,
                    color: '#4AD2DC',
                  },
                ], width: 'auto'}, {text: `E: ${day.getNutritionalValue('protein').toFixed(0)} g`, width: 'auto', fontSize: 6}] },
                {margin: [ 0, 3, 0, 0 ], columns: [{canvas: [
                  {
                    type: 'rect',
                    x: 1,
                    y: 1,
                    w: 6,
                    h: 6,
                    color: '#E5FF44',
                  },
                ], width: 'auto'}, {text: `F: ${day.getNutritionalValue('fat').toFixed(0)} g`, width: 'auto', fontSize: 6}] },
                {margin: [ 0, 3, 0, 0 ], columns: [{canvas: [
                  {
                    type: 'rect',
                    x: 1,
                    y: 1,
                    w: 6,
                    h: 6,
                    color: '#E92861',
                  },
                ], width: 'auto'}, {text: `${day.getNutritionalValue('calories').toFixed(0)} kcal`, width: 'auto', fontSize: 6}] },
              ]}
            ]
          })
        }

        column.stack.push(...mealColumnStack);

        columns.push(column)

        if (dayIndex % 7 == 6 || dayIndex == days.length - 1) {
          if (columns) {
            docDefinition.content.push({
              columns: columns,
              columnGap: 10
            })
            if(dayIndex != days.length - 1){
              docDefinition.content.push({text: '', pageBreak: 'after'})
            }
          }
          columns = []
        }
      }
    }

    if(exportRecipes && recipes.size > 0) {
      docDefinition.content.push({text: 'Rezepte', bold: true, fontSize: 12, margin: [0, 0, 0, 10], pageBreak: 'before'})
      for (var key of Array.from(recipes.keys()).sort()) {
        var recipe = recipes.get(key)
        console.log('Recipe ' + recipe.getName() + ' ' + recipe.getThumbnailPath() + ' ' + recipe.imageURL)
        // recipe.imageURL = null
        if (!recipe.imageURL && recipe.getThumbnailPath()) {
          try {
            var url = await nutritionPlanService.fetchMealImageUrl(recipe)
            if (url != undefined && url != null) {
              recipe.imageURL = url
            }
          } catch (e) {}
        }
        docDefinition.content.push({
          columns: await this.getRecipeColumns(key, recipe),
          columnGap: 20,
          margin: [ 0, 10, 0, 10 ],
          unbreakable: true,
        })
      }
    }

    pdfMake.createPdf(docDefinition).download((this.getFileName(`${planConfig.getName()}_${user.getName()}`)) + ".pdf");
    return Promise.resolve(true)
  }

  getFoodStack(food: PlannedFood){
    return {text: this.getFoodString(food), margin: [ 0, 5, 0, 0 ]}
  }

  getFoodString(food: PlannedFood){
    return food.weight + ' ' + food.getUnit() + ' ' + food.getName();
  }

  async getRecipeColumns(name: string, recipe: PlannedMealV2): Promise<any[]> {
    var columns = []
    // let dataImage = await this.getImageElement(recipe)
    let dataImage = await this.getBase64ImageFromURL(recipe.imageURL);
    var nameStack: any[] = []
    if (name) nameStack.push({text: name, fontSize: 10, bold: true, margin: [ 0, 5, 0, 0 ]})
    nameStack.push({text: recipe.getPrintableNutritionalValues(), fontSize: 6, margin: [ 0, 5, 0, 0 ]})
    if(dataImage){
      nameStack.push({image: dataImage, margin: [ 0, 5, 0, 0 ], fit: [100, 100]});
      // nameStack.push(dataImage);
    }
    //nameStack.push(await this.getImageElement(recipe))

    columns.push({
      width: '*',
      stack: nameStack
    })

    var foodStack: any[] = []
    recipe.getFoods().forEach(food => {
      if(food.groupHeading){
        foodStack.push({text: food.groupHeading, bold: true, fontSize: 6, margin: [ 0, 10, 0, 0 ]});
      }
      foodStack.push(this.getFoodStack(food))
      if(food.getServing()){
        let servingString = food.weight / food.getServing().amount == 1 ? food.getServing().getPrintableName() : (food.weight / food.getServing().amount).roundToPlaces(2) + 'x ' + food.getServing().getPrintableName();
        foodStack.push({text: servingString, fontSize: 6, margin: [ 0, 0, 0, 0 ]})
      }
    })
    columns.push({
      width: '*',
      stack: foodStack
    })

    columns.push({
      width: '*',
      stack: [
        {text: 'Anweisungen', bold: true, fontSize: 6, margin: [ 0, 0, 0, 5 ]},
        {text: recipe.getInstructions()},
      ]
    })
    return columns
  }

  async getImageElement(recipe: PlannedMealV2): Promise<any> {
    var image
    if (recipe.imageURL) {
      image = await this.getBase64ImageFromURL(recipe.imageURL)
      if (image) {
        return {
          image: image, fit: [100, 100], margin: [0, 10, 0, 0]
        }
      }
    }
    return {text: ''}
  }


  getDayItemStack(item: DayItem, showIngredientsInTable: boolean): any {
    var stack: any[] = [
      {text: item.meal.getType() || 'Mahlzeit', bold: true, fontSize: 8, margin: [ 0, 20, 0, 5 ]},
      {text: item.meal.getName()},
      {text: item.meal.getPrintableNutritionalValues(), fontSize: 6, margin: [ 0, 5, 0, 0 ]},
      {},
    ]
    console.log('Recipe ' + item.meal.getName() + ' ' + item.meal.getPrintableNutritionalValues())
    console.log(item.meal)

    if(showIngredientsInTable && item.meal.getFoods().length > 0){
      stack.push({margin: [0,10,0,0], text: 'Zutaten:', bold: true, fontSize: 6})

      item.meal.getFoods().forEach(food => {
        if(food.groupHeading){
          stack.push({text: food.groupHeading, bold: true, fontSize: 4, margin: [ 0, 2, 0, 0 ]})
        }
        stack.push({text: food.weight + ' ' + food.getUnit() + ' ' + food.getName(), fontSize: 6});
      });
    }

    for (var meal of item.alternativeMeals) {
      stack.push({text: 'oder', alignment: 'center', fontSize: 6, margin: [ 0, 5, 0, 5 ]})
      stack.push({text: meal.getName()})
      stack.push({text: meal.getPrintableNutritionalValues(), fontSize: 6, margin: [ 0, 5, 0, 0 ]})

      if(showIngredientsInTable && meal.getFoods().length > 0){
        stack.push({margin: [0,10,0,0], text: 'Zutaten:', bold: true, fontSize: 6})

        meal.getFoods().forEach(food => {
          if(food.groupHeading){
            stack.push({text: food.groupHeading, bold: true, fontSize: 4, margin: [ 0, 2, 0, 0 ]})
          }
          stack.push({text: food.weight + ' ' + food.getUnit() + ' ' + food.getName(), fontSize: 6});
        });
      }
    }

    return stack
  }

  onExportNutritionPlan(nutritionPlan: NutritionPlan, user: User): void {
    let docDefinition = {
      footer: {text: 'nutrilize', font: 'Montserrat', alignment: 'center'},
      pageOrientation: 'landscape',
      content: []
    }

    docDefinition.content.push({text: (nutritionPlan.name || ("Ernährungsplan für " + user.name)) + "\n", fontSize: 10, alignment: "left", font: "Montserrat", bold: true, margin: [0,0,0,10]})

    var daysOnOnePage = 7
    var pageNumber = Math.floor(nutritionPlan.days.length / 7) + 1
    var mealsPerDay = nutritionPlan.customMealTypes.length

    for(var x = 0; x < pageNumber; x++) {

      var tablePDF = {
        layout: 'noBorders',
        table: {
          widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],

          body: []
        }
      }

      var weekDayDate = [];
      var nutriStatsDay = [];
      var mealType = [];
      var mealName = [];
      var mealNutris = [];
      var foods = [];

      for(var k = 0; k < mealsPerDay; k++){
        mealType.push([]);
        mealName.push([]);
        mealNutris.push([]);
        foods.push([]);
      }

      for(var i = 0; i < daysOnOnePage; i++) {
        if(nutritionPlan.days[x * daysOnOnePage + i] != null) {
          this.addContentLandscape(nutritionPlan, nutritionPlan.days[x * daysOnOnePage + i], weekDayDate, nutriStatsDay, mealType, mealName, mealNutris, foods, mealsPerDay)
        } else {
          this.addContentLandscapeEmpty(weekDayDate, nutriStatsDay, mealType, mealName, mealNutris, foods, mealsPerDay)
        }
      }

      tablePDF.table.body.push(weekDayDate);
      tablePDF.table.body.push(nutriStatsDay);
      for (var j = 0; j < mealsPerDay; j++) {
        tablePDF.table.body.push(mealType[j]);
        tablePDF.table.body.push(mealName[j]);
        tablePDF.table.body.push(mealNutris[j]);
        tablePDF.table.body.push(foods[j]);
      }

      docDefinition.content.push(tablePDF)
      if (!((x + 1) == pageNumber)) {
        docDefinition.content.push({text: "", pageBreak: 'after'})
      }
    }

    let pdf = pdfMake.createPdf(docDefinition);
    this.downloadPdf(pdf, (nutritionPlan.name || 'Plan') + ".pdf");
  }

  addContentLandscapeEmpty(wdd, nsd, mt, mn, mnu, f, mpd): void {
    //Adding weekday and date
    wdd.push({text: "", fontSize: 9, alignment: "center", font: "Montserrat", bold: true})

    //Adding whole nutri stats of the day
    nsd.push({text: "", alignment: "center", font: "Montserrat", fontSize: 7})

    for(var i = 0; i < mpd; i++){
      mt[i].push({text: ""});
      mn[i].push({text: ""});
      mnu[i].push({text: ""});
      f[i].push({text: ""});
    }
  }

  addContentLandscape(nutritionPlan: NutritionPlan, d1, wdd, nsd, mt, mn, mnu, f, mpd): void {
    //Adding weekday and date
    wdd.push({text: this.weekDays[d1.date.getDayNumber()].substr(0,2) + "., " + d1.date.getDate() + "." + (d1.date.getMonth() + 1) + "." + d1.date.getFullYear() + "\n", fontSize: 9, alignment: "center", font: "Montserrat", bold: true})

    //Adding whole nutri stats of the day
    nsd.push({text: Math.round(d1.getCarbohydrates()) + "g K / " + Math.round(d1.getProtein()) + "g E / " + Math.round(d1.getFat()) + "g F / " + Math.round(d1.getCalories()) + " kcal \n\n", alignment: "center", font: "Montserrat", fontSize: 7})

    for(var i = 0; i < mpd; i++){
      if(d1.plannedMeals[i] != null) {
        //Single meal type
        mt[i].push({text: nutritionPlan.customMealTypes[d1.plannedMeals[i].number] + "\n", font: 'Montserrat', fontSize: 7, bold: true, margin: [0, 0, 0, 5]})
        //Single meal name
        mn[i].push({text: (d1.plannedMeals[i].name ?? 'Mahlzeit') + "\n", font: "Montserrat", fontSize: 9, italics: true})
        //Single meal nutris
        mnu[i].push({text: Math.round(d1.plannedMeals[i].getCarbohydrates()) + "g K / " + Math.round(d1.plannedMeals[i].getProtein()) + "g E / " + Math.round(d1.plannedMeals[i].getFat()) + "g F / " + Math.round(d1.plannedMeals[i].getCalories()) + " kcal \n", fontSize: 7, font: "Montserrat", margin: [0, 0, 0, 10]})

        //Single foods in one meal
        var allFood1 = ""
        d1.plannedMeals[i].foods.forEach(f => {
          if (!f.isDummy) {
            allFood1 = allFood1 + (f.weight ? f.weight + 'g ' : '') + f.getName() + "\n"
          } else {
            allFood1 = allFood1 + (f.weight ? f.weight + 'g ' : '') + (f.name || 'Dummy') + "\n"
          }
        })
        f[i].push({text: allFood1 + "\n", fontSize: 7, font: "Montserrat", margin: [0, 0, 0, 10]})
      } else {
        mt[i].push({text: ""});
        mn[i].push({text: ""});
        mnu[i].push({text: ""});
        f[i].push({text: ""});
      }
    }
  }

  getImageCanvasWithRoundedCorners(image: HTMLImageElement, cornerRadius: number) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = image.width;
    canvas.height = image.height;
    context.fillStyle = "white";
    context.fillRect(0, 0, canvas.width, canvas.height);

    context.beginPath();
    context.moveTo(cornerRadius, 0);
    context.lineTo(canvas.width - cornerRadius, 0);
    context.quadraticCurveTo(canvas.width, 0, canvas.width, cornerRadius);
    context.lineTo(canvas.width, canvas.height - cornerRadius);
    context.quadraticCurveTo(canvas.width, canvas.height, canvas.width - cornerRadius, canvas.height);
    context.lineTo(cornerRadius, canvas.height);
    context.quadraticCurveTo(0, canvas.height, 0, canvas.height - cornerRadius);
    context.lineTo(0, cornerRadius);
    context.quadraticCurveTo(0, 0, cornerRadius, 0);
    context.closePath();
    context.clip();

    context.drawImage(image, 0, 0);

    return canvas;
  }

  getBase64ImageFromURL(url) {
    try{
      if(url){
        return new Promise((resolve, reject) => {
          var img = new Image();
          img.setAttribute("crossOrigin", "anonymous");
          img.onload = () => {
            let canvas = this.getImageCanvasWithRoundedCorners(img, 100);
            var dataURL = canvas.toDataURL("image/jpeg", 0.5);
            resolve(dataURL);
          };
          img.onerror = error => {
            console.error(error);
            // reject(error);
            resolve('');
          };
          img.src = url;
        });
      }
    }
    catch(ex){
      console.error(ex);
    }
    return '';
  }

}
