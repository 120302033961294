import {Component, OnDestroy} from '@angular/core';
import {CommunityService} from "../services/community.service";
import {Community, CommunityModel} from "../model/community.model";
import {Observable, Subscription, take, tap} from "rxjs";
import {LicenceHolder} from "../model/licenceholder.model";
import {DocumentSnapshot} from "@angular/fire/compat/firestore";
import {ToastrService} from "ngx-toastr";
import {CommunityManagementConfig} from "../model/community-management-config.model";
import firebase from "firebase/compat";
import Unsubscribe = firebase.Unsubscribe;
import {UtilityService} from "../services/utility.service";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmationDialogComponent} from "../confirmation-dialog/confirmation-dialog.component";
import {environment} from "../../environments/environment";
import {UnsavedChangesService} from "../services/unsavedchanges.service";
import {IComponentCanDeactivate} from "../app-routing.module";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.css']
})
export class CommunityComponent implements IComponentCanDeactivate, OnDestroy {
  canDeactivate: () => Promise<boolean> = async () => {
    if (this.isDirty()) {
      return await CommunityComponent.showDeactivateDialog(this.dialog);
    }

    return true;
  };

  static async showDeactivateDialog(dialog: MatDialog): Promise<boolean> {
    const dialogRef = dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Bist du sicher, dass du die Community-Bearbeitung verlassen möchtest?<br> Dadurch gehen ungespeicherte Änderungen verloren.',
        title: 'Bearbeitung verlassen',
        positiveButton: 'Verlassen ohne Speichern',
        negativeButton: 'Abbrechen'
      },
    });
    return await dialogRef.afterClosed().toPromise();
  }

  protected isCommunityEnabled: boolean = null;
  protected communityList: { community: Community, thumbnailURL: Observable<string> }[] = null;
  protected selectedCommunityConfig: CommunityManagementConfig = null;
  protected agreement: string = null;
  protected readonly editorPlaceholder: string = "Verfasse hier deine Richtlinien";
  protected agreementExpanded: boolean = false;
  private coaches: Map<string, string> = new Map();
  private users: Map<string, string>;
  private groups: Map<string, string>;
  private subscriptions: Subscription[] = [];
  private unSubscriptions: Unsubscribe[] = []
  private markdown: string = null;
  private dirty: boolean = false;

  constructor(private communityService: CommunityService, private toastr: ToastrService, protected utilityService: UtilityService, private dialog: MatDialog, private unsavedChangesService: UnsavedChangesService, private translate: TranslateService) {
    this.subscriptions.push(this.communityService.getCommunityEnabled().subscribe((snapshot: DocumentSnapshot<LicenceHolder>) => {
      const data = snapshot.data();
      const id = snapshot.id;
      this.isCommunityEnabled = data.communityEnabled;
    }));

    this.subscriptions.push(this.communityService.getCommunities().subscribe((communities: CommunityModel[]) => {
      this.communityList = communities.sort((communityA, communityB) => {
        const nameA = communityA.name.toLowerCase();
        const nameB = communityB.name.toLowerCase();

        if (nameA > nameB) return 1;
        if (nameA < nameB) return -1;

        return 0;
      }).map((model) => {
        return {
          community: new Community(model),
          thumbnailURL: this.communityService.getImage(model.imagePath)
        }
      });
      if (!utilityService.onMobile() && this.selectedCommunityConfig === null) {
        this.onCommunitySelected(this.communityList[0].community);
      }
    }));

    this.subscriptions.push(this.communityService.getAgreement().pipe(take(1)).subscribe((agreement => {
      this.agreement = agreement;
      this.markdown = agreement;
    })));

    this.subscriptions.push(this.communityService.getCoaches().subscribe((coaches) => {
      coaches.forEach(coach => {
        this.coaches.set(coach.uid, coach.name);
      });
    }));
    this.users = this.communityService.getUsers();
    this.groups = this.communityService.getGroups();

  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];

    this.unSubscriptions.forEach(unsubscribe => unsubscribe());
    this.unSubscriptions = [];
  }

  private isDirty(): boolean {
    return this.dirty || this.selectedCommunityConfig?.isDirty;
  }

  protected onEditorValueChanged(value: string) {
    if (value == this.editorPlaceholder || value?.length == 0) {
      this.markdown = null
    } else {
      this.markdown = value;
    }

    this.dirty = true;
  }

  public onCommunityEnabled() {
    this.isCommunityEnabled = !this.isCommunityEnabled;
    this.communityService.updateCommunityEnabled(this.isCommunityEnabled);
  }

  protected onCommunitySelected(community: Community) {
    const callback = (snapshot) => {
      const data = snapshot.data() as CommunityModel;
      const id = snapshot.id;

      data.id = id;
      if (!this.communityService.imagePathSeemsValid(data.imagePath, id)) {
        this.selectedCommunityConfig = new CommunityManagementConfig({
          community: new Community(data),
          coachesMap: this.coaches,
          usersMap: this.users,
          groupsMap: this.groups,
          imgUrl: ""
        });
        return;
      }

      this.communityService.getImage(data.imagePath).subscribe((url) => {
        if (url === null || url === undefined) {
          throw Error("Cannot find download url to path: " + data.imagePath);
        }
        this.selectedCommunityConfig = new CommunityManagementConfig({
          community: new Community(data),
          coachesMap: this.coaches,
          usersMap: this.users,
          groupsMap: this.groups,
          imgUrl: url
        });
      });
    };

    if (this.selectedCommunityConfig?.isDirty) {
      CommunityComponent.showDeactivateDialog(this.dialog).then((discardChanges) => {
        if (discardChanges) {
          this.unSubscriptions.push(this.communityService.getCommunityDoc(community.id, callback));
        }
        return;
      });
      return;
    }

    this.unSubscriptions.push(this.communityService.getCommunityDoc(community.id, callback));
  }

  protected onCreateCommunity() {
    if (this.selectedCommunityConfig?.isDirty) {
      CommunityComponent.showDeactivateDialog(this.dialog).then((discardChanges) => {
        if (discardChanges) {
          const newCommunity = this.communityService.createCommunity();
          this.selectedCommunityConfig = new CommunityManagementConfig({
            community: newCommunity,
            coachesMap: this.coaches,
            usersMap: this.users,
            groupsMap: this.groups,
            imgUrl: "",
            isNewlyCreated: true
          });
          this.dirty = true;
        }
        return;
      });
      return;
    }

    const newCommunity = this.communityService.createCommunity();
    this.selectedCommunityConfig = new CommunityManagementConfig({
      community: newCommunity,
      coachesMap: this.coaches,
      usersMap: this.users,
      groupsMap: this.groups,
      imgUrl: "",
      isNewlyCreated: true
    });
    this.dirty = true;
  }

  protected onDeleteCommunity() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: this.translate.instant('Bist du sicher, dass du die Community nameParameter löschen möchtest?', {nameParameter: this.selectedCommunityConfig.name}),
        title: this.translate.instant('nameParameter löschen', {nameParameter: this.selectedCommunityConfig.name}),
        positiveButton: this.translate.instant('Löschen'),
        negativeButton: this.translate.instant('Abbrechen')
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.communityService.deleteCommunity(this.selectedCommunityConfig.toCommunity()).then(() => {
          this.toastr.success(this.translate.instant('nameParameter wurde gelöscht!', {nameParameter: this.selectedCommunityConfig.name}));
          this.selectedCommunityConfig = null;
        }, (reason) => {
          this.toastr.error(this.translate.instant('Beim Löschen von nameParameter ist ein Fehler aufgetreten!', {nameParameter: this.selectedCommunityConfig.name}));
          console.error(this.translate.instant('Das Löschen von nameParameter ist fehlgeschlagen!', {nameParameter: this.selectedCommunityConfig.name}), reason);
        });
      }
    });
  }

  protected onUpdateCommunity() {
    let prom: Promise<any> = null;
    const image = this.selectedCommunityConfig.updatedImage;
    let id = null;
    if (this.selectedCommunityConfig.isNewlyCreated) {
      prom = this.communityService.addCommunity(this.selectedCommunityConfig.toCommunity()).then(docRef => {
        id = docRef.id;
        return this.communityService.uploadImage(image, id);
      });
    } else {
      prom = this.communityService.updateCommunity(this.selectedCommunityConfig.toCommunity()).then(docRef => {
        return this.communityService.uploadImage(image, this.selectedCommunityConfig.getCommunityId());
      });
    }

    prom.then(() => {
      this.toastr.success(`${this.selectedCommunityConfig.name} wurde gespeichert!`);

      this.selectedCommunityConfig.updatedImage = null;
      this.selectedCommunityConfig.isDirty = false;
      this.dirty = false;

      const currentItem = this.communityList.find(item => item.community.id === id);
      if(currentItem) {
        this.onCommunitySelected(currentItem.community);
      }
    }, (reason) => {
      console.error("Das Speichern der Community oder des Agreements ist fehlgeschlagen", reason);
      this.toastr.error(this.translate.instant('Beim Speichern von nameParameter oder der Regeln ist ein Fehler aufgetreten', {nameParameter: this.selectedCommunityConfig.name}));
    });
  }

  protected onCancelCommunity() {
    this.selectedCommunityConfig = null;
    this.dirty = false;
  }

  protected isAgreementDirty(): boolean {
    return this.agreement !== this.markdown;
  }

  protected updateAgreement() {
    if (!this.isAgreementDirty()) return;
    this.agreement = this.markdown;

    return this.communityService.updateAgreement(this.agreement).then(() => {

      this.toastr.success(this.translate.instant("Die Richtlinien wurden gespeichert!"));
      this.dirty = false;
    });
  }
}
