import {
  AfterContentChecked,
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  QueryList,
  ViewChildren
} from '@angular/core';
import {Metric} from "../../model/metric.model";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FirestoreService} from "../../services/firestore.service";
import {GoalPlan, GoalPlanConfig, GoalPlanType, GoalStep, WeeklyAggregation} from "../../model/goal-plan.model";
import {FirestoreNutritionPlanService} from "../../services/firestore-nutritionplan.service";
import {Moment} from "moment/moment";
import {GoalPlanService} from "../../services/goal-plan.service";
import {User} from "../../model/user.model";
import * as moment from "moment";
import {AutomaticPushNotification, Repetition,} from "../../model/automatic-push-notification.model";
import {Subscription} from "rxjs";
import {
  NotificationDialogComponent,
  NotificationDialogConfig
} from "../../settings/push-notification-settings/notification-dialog/notification-dialog.component";
import {ConfirmationDialogComponent} from "../../confirmation-dialog/confirmation-dialog.component";
import {TranslateService} from "@ngx-translate/core";
import {SelectableGroup, SelectableItem} from 'src/app/common/input/selection-dropdown/selection-dropdown.component';
import {NotificationService} from 'src/app/services/notification.service';

enum SubmitText {
  CREATE = 'Erstellen',
  UPDATE = 'Speichern',
}

enum CancelText {
  CREATE = 'Abbrechen',
  UPDATE = 'Löschen'
}

enum GoalPlanText {
  PROGRESS = 'Fortschrittssziel',
  DAILY = 'Tagesziel',
  WEEKLY = 'Wochenziel'
}

enum GoalPlanTextNote {
  PROGRESS = 'Plane ein Ziel, das an einem bestimmten Datum erreicht werden soll, wie ein Wunschkörpergewicht.',
  WEEKLY = 'Plane ein Ziel, das im Laufe einer Woche jede Woche erneut erreicht werden soll. Beispiele sind 50.000 Schritte oder 3 Trainings pro Woche.',
  DAILY = 'Plane ein Ziel, das jeden Tag aufs Neue von deinem Coachee erreicht werden soll, bspw. ein Schritt- oder Trinkziel.'
}

enum CreateOrUpdateText {
  CREATE = 'erstellen',
  UPDATE = 'bearbeiten'
}

enum EditMode {
  EASY = 'easy',
  DETAILED = 'detailed'
}

@Component({
  selector: 'app-metric-goal-dialog',
  templateUrl: './metric-goal-dialog.component.html',
  styleUrls: ['./metric-goal-dialog.component.css']
})
export class MetricGoalDialogComponent implements AfterViewInit, AfterViewChecked, AfterContentChecked, OnDestroy {
  @ViewChildren('validationInput') inputs: QueryList<ElementRef<HTMLInputElement>>;
  protected readonly GoalPlanType = GoalPlanType;
  protected readonly GoalPlanText = GoalPlanText;
  protected readonly WeeklyAggregation = WeeklyAggregation;
  protected readonly GoalPlanTextNote = GoalPlanTextNote;
  protected readonly EditMode = EditMode;

  protected title: GoalPlanText = null;
  protected createOrUpdateStr: CreateOrUpdateText = null;
  protected submitText: SubmitText = null;
  protected cancelText: CancelText = null;
  protected planName: string = '';
  protected intermediateGoals: GoalStep[] = [];
  protected showInApp: boolean = false;
  protected showOnCoacheeDashboard: boolean = false;
  protected type: GoalPlanType = null;
  protected aggregation: WeeklyAggregation = null;
  protected endDate: Date = null;
  // brauche nextgoal nicht mehr. Läuft dann über lastgoal in der Liste und onvaluechange pusht es in die liste
  protected nextGoal: GoalStep = null;
  protected selectedMetric: Metric = null;
  protected isNew: boolean = false;
  protected goalPlan: GoalPlan = null;
  protected notValid: boolean = true;
  protected metrics: Metric[] = [];
  protected mode: EditMode = EditMode.EASY;
  protected observablePushNotificationSubscription: Subscription = null;
  protected observalbeInputChanges: Subscription = null;
  protected dialogSubscriptions: Subscription[] = [];
  protected pushNotifications: AutomaticPushNotification[] = [];
  protected disableEasyMode: boolean = false;
  protected disableAggregation: boolean = false;
  protected disableWeeklyGoal: boolean = false;
  protected isDirty: boolean = false;

  metricSelectionGroups: SelectableGroup[] = []
  notificationSelectionGroups: SelectableGroup[] = []

  private readonly user: User = null;

  protected startDateFilter = (d: Moment | null): boolean => {
    if (!d) return false;

    // verhindern, dass Startdatum nach dem ersten Ziel gewählt werden kann.
    const firstStep = this.intermediateGoals[0];
    if (!firstStep) {
      if (this.type == GoalPlanType.WEEKLY) return d.day() === 1;
      return true;
    }

    if (firstStep.value == null || d.isBefore(firstStep.date, "date")) {
      if (this.type == GoalPlanType.WEEKLY) return d.day() === 1;
      return true;
    }

    if (this.intermediateGoals.length > 1) {
      const nextGoal = this.intermediateGoals[1];
      return this.type == GoalPlanType.WEEKLY ? (d.day() === 1) && d.isBefore(nextGoal.date, "date") : d.isBefore(nextGoal.date, "date");
    }

    if (this.type == GoalPlanType.WEEKLY) return d.day() === 1;
    return true;
  }

  protected stepDateFilter = (d: Moment | null): boolean => {
    if (!d) return false;

    // verhindern, dass Zieldatum vor Startdatum gewählt werden kann.
    if (!d.isAfter(this.intermediateGoals[0].date, "date")) return false;

    if (this.type == GoalPlanType.WEEKLY) return d.day() === 1;

    return true;
  }

  protected sundayDateFilter = (d: Moment | null): boolean => {
    if (!d) return false;

    if (this.type == GoalPlanType.WEEKLY) return d.day() === 0;

    return true;
  }

  constructor(public dialogRef: MatDialogRef<MetricGoalDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: GoalPlanConfig, private firestoreService: FirestoreService, private goalPlanService: GoalPlanService, private dialog: MatDialog, private translate: TranslateService, private notificationService: NotificationService) {
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => {
      if (!this.hasChanges()) {
        this.dialogRef.close();
        return;
      }
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translate.instant('Möchtest du das Ziel vor dem Schließen speichern?'),
          title: this.translate.instant('Ziel speichern'),
          positiveButton: this.translate.instant('Ja'),
          negativeButton: this.translate.instant('Nein')
        },
      });
      this.dialogSubscriptions.push(dialogRef.afterClosed().subscribe(result => {
        if (result == true) {
          this.onSaveGoalPlan();
        } else {
          this.dialogRef.close();
        }
      }));
    });

    this.isNew = this.data.isNew;
    this.type = this.data.type;
    this.user = this.data.user;
    this.observablePushNotificationSubscription = this.firestoreService.getScheduledPushNotifications(this.firestoreService.getLoggedInUser()?.coach.licenceHolderUid).subscribe((notifications) => {
      this.pushNotifications = notifications.filter(notification => notification.repetition == Repetition.daily);
      this.notificationSelectionGroups = [
        {
          name: null,
          items: this.pushNotifications.map(noti => {
            return {
              value: noti,
              name: noti.title,
              description: noti.description,
              isSelected: this.goalPlan?.scheduledPushNotificationIds.includes(noti.id)
            }
          })
        }
      ]
    });

    if (this.data.metric) {
      this.selectedMetric = this.firestoreService.getMetricByMetricId(this.data.metric.metricId);
    } else if (data.goalPlan) {
      this.selectedMetric = this.firestoreService.getMetricByMetricId(data.goalPlan.metric.metricId);
    }

    this.setTitle();
    this.setMetrics();

    if (this.isNew) {
      this.goalPlan = null;
      this.submitText = SubmitText.CREATE;
      this.cancelText = CancelText.CREATE;
      this.createOrUpdateStr = CreateOrUpdateText.CREATE;
      this.showInApp = true;
      this.showOnCoacheeDashboard = true;

      let startValue = null;

      if (this.selectedMetric) {
        if (!this.selectedMetric.isMetricTypeToDo() && !this.selectedMetric.isMetricTypeYesNo()) {
          const daily = this.goalPlanService.getLatestDailyConditionByMetricId(this.user, this.selectedMetric.metricId);
          if (daily) {
            startValue = daily.getMetricDataByMetricId(this.selectedMetric.metricId).value;
          }
        }
      }

      this.intermediateGoals.push(new GoalStep({
        date: this.type == GoalPlanType.DAILY ? new Date() : this.createWeeklyGoalStartDate(),
        name: "",
        value: startValue,
        maxValue: null,
        minValue: null,
        showInApp: this.showInApp
      }));
    } else {
      this.goalPlan = this.data.goalPlan;
      this.submitText = SubmitText.UPDATE;
      this.cancelText = CancelText.UPDATE;
      this.createOrUpdateStr = CreateOrUpdateText.UPDATE;
      this.intermediateGoals = this.goalPlan.goalSteps.map(step => new GoalStep(step));
      this.showInApp = this.goalPlan.showInApp;
      this.showOnCoacheeDashboard = this.goalPlan.showOnCoacheeDashboard;
      this.planName = this.goalPlan.name;
      this.aggregation = this.goalPlan.aggregation;
      this.endDate = this.goalPlan.endDate;
      this.selectedMetric = this.goalPlan.metric;

      const nextGoal = new GoalStep({
        date: this.type == GoalPlanType.DAILY ? new Date() : this.createWeeklyGoalStartDate(),
        name: "",
        value: this.goalPlan.getEndStep().value,
        maxValue: this.goalPlan.getEndStep().maxValue,
        minValue: this.goalPlan.getEndStep().minValue,
        showInApp: this.goalPlan.getEndStep().showInApp
      });

      if (!this.intermediateGoals[this.intermediateGoals.length - 1].date.isSameDate(nextGoal.date)) {
        this.nextGoal = nextGoal;
      } else {
        this.nextGoal = this.intermediateGoals.pop();
      }

      this.setDisable();
    }
  }

  ngAfterViewInit(): void {
    this.observalbeInputChanges = this.inputs.changes.subscribe(() => {
      setTimeout(this.validate, 50);
      //this.validate();
    });
  }

  ngAfterViewChecked() {
    this.validate();
  }

  ngAfterContentChecked() {
    this.validate();
  }

  ngOnDestroy() {
    this.observablePushNotificationSubscription.unsubscribe();
    this.observalbeInputChanges.unsubscribe();
    this.dialogSubscriptions.forEach(sub => sub.unsubscribe());
    this.dialogSubscriptions = [];
  }

  private hasChanges(): boolean {
    if (this.isNew) {
      return !!this.planName ||
        !!this.selectedMetric ||
        !!this.intermediateGoals[0].value ||
        !this.showOnCoacheeDashboard ||
        !this.showInApp ||
        !!this.endDate;
    }

    if (this.planName !== this.goalPlan.name) {
      return true;
    } else if (this.showInApp !== this.goalPlan.showInApp) {
      return true;
    } else if (this.showOnCoacheeDashboard !== this.goalPlan.showOnCoacheeDashboard) {
      return true;
    } else if (this.aggregation !== this.goalPlan.aggregation) {
      return true;
    } else if (this.endDate && (this.endDate !== this.goalPlan.endDate)) {
      return true;
    }

    if (this.intermediateGoals.length < this.goalPlan.goalSteps.length) {
      // nextGoal ist das letzte Ziel des Plans.
      if (this.nextGoal && !this.nextGoal.isEqual(this.goalPlan.getEndStep())) {
        return true;
      }
    }

    const noGoalChange = this.intermediateGoals.every((step, index, steps) => {
      const original = this.goalPlan.goalSteps[index];
      return original && original.isEqual(step);
    });

    if (!noGoalChange) {
      return true;
    }

    return this.isDirty;
  }

  private setMetrics() {
    this.metrics = [];
    const usedMetrics = this.isNew ? this.goalPlanService.getUsedMetrics(this.user, this.type) : []
    if (this.type === GoalPlanType.PROGRESS) {
      this.mode = EditMode.DETAILED;
      this.metrics.push(...this.firestoreService.metrics.filter(metric => !metric.deleted).filter(metric => (metric.isMetricTypeDuration() || metric.isMetricTypeNumber()) && !usedMetrics.includes(metric.metricId)));
    } else {
      this.metrics.push(...this.firestoreService.metrics.filter(metric => !metric.deleted).filter(metric => (metric.isMetricTypeDuration() || metric.isMetricTypeNumber() || metric.isMetricTypeToDo() || metric.isMetricTypeYesNo()) && !usedMetrics.includes(metric.metricId)));
    }

    const waterIntakeMetric = new Metric();
    waterIntakeMetric.metricType = "NUMBER";
    waterIntakeMetric.metricId = "waterIntake";
    waterIntakeMetric.nameDe = "Wasserzufuhr";
    waterIntakeMetric.unit = "l";
    if (!usedMetrics.includes(waterIntakeMetric.metricId)) this.metrics.push(waterIntakeMetric);

    this.metrics.sort((a, b) => {
      if (a.getName() < b.getName()) return -1;
      if (a.getName() > b.getName()) return 1;
      return 0;
    })
    if (this.isNew && usedMetrics.includes(this.selectedMetric?.metricId)) this.selectedMetric = this.metrics[0];

    this.metricSelectionGroups = [
      {
        name: "Zugewiesene Metriken",
        items: this.metrics.filter(m => this.user.assignedMetrics?.find(m1 => m1.metricId == m.metricId) != null).map(metric => {
          return {
            value: metric,
            name: metric.getName(),
            description: metric.descriptionDe,
            isSelected: this.selectedMetric?.metricId == metric.metricId
          }
        })
      },
      {
        name: "Nicht zugewiesene Metriken",
        items: this.metrics.filter(m => this.user.assignedMetrics?.find(m1 => m1.metricId == m.metricId) == null).map(metric => {
          return {
            value: metric,
            name: metric.getName(),
            description: metric.descriptionDe,
            isSelected: this.selectedMetric?.metricId == metric.metricId
          }
        })
      }
    ]
  }

  protected onCloseCustomMetricDialog() {
    this.dialogRef.close(null);
  }

  private setDisable() {
    if(!this.selectedMetric) {
      return;
    }

    if(this.type == GoalPlanType.WEEKLY && (this.selectedMetric.isMetricTypeToDo() || this.selectedMetric.isMetricTypeYesNo())) {
      this.disableAggregation = true;
    } else {
      this.disableAggregation = false;
    }

    this.disableEasyMode = this.intermediateGoals.some(step => {
      const date = this.type === GoalPlanType.DAILY ? new Date() : this.createWeeklyGoalStartDate();
      const stepMoment = moment(step.date);
      return stepMoment.isAfter(date, "day");
    });

    if (this.disableEasyMode) {
      this.mode = EditMode.DETAILED;
    }

    if (this.type === GoalPlanType.DAILY && (this.selectedMetric?.isMetricTypeToDo() || this.selectedMetric.isMetricTypeYesNo())) {
      this.disableEasyMode = true;
      this.mode = EditMode.EASY;
    }

    this.disableWeeklyGoal = this.intermediateGoals.length === 1 ? false : this.intermediateGoals.some(step => moment(step.date).day() !== 1);
  }

  protected onGoalDateChanged(goalDate: Date, goalStep: GoalStep) {
    goalStep.date = goalDate;
    this.intermediateGoals.sort((goalA, goalB) => {
      if (goalA.date < goalB.date) return -1;
      if (goalA.date > goalB.date) return 1;
      return 0
    });

    this.setDisable();
  }

  protected getSelectedMetric() {
    if (!this.selectedMetric) {
      return 'Bitte Metrik auswählen...';
    }

    return this.selectedMetric.getName();
  }

  protected getAggregation() {
    if (this.aggregation == WeeklyAggregation.AVG) return "Durchschnitt";
    if (this.aggregation == WeeklyAggregation.SUM) return "Summe";

    return "Aggregation wählen";
  }

  protected onSelectedItemChanged(item: SelectableItem) {
    if (item.isSelected) {
      this.onMetricSelected(item.value);
    }
  }

  protected onMetricSelected(metric: Metric) {
    this.selectedMetric = metric;

    if(this.type == GoalPlanType.WEEKLY && (this.selectedMetric.isMetricTypeToDo() || this.selectedMetric.isMetricTypeYesNo())) {
      this.aggregation = WeeklyAggregation.SUM;
    }

    this.intermediateGoals[0].value = null;
    if (!this.selectedMetric.isMetricTypeToDo() && !this.selectedMetric.isMetricTypeYesNo()) {
      const daily = this.goalPlanService.getLatestDailyConditionByMetricId(this.user, this.selectedMetric.metricId);
      if (daily) {
        this.intermediateGoals[0].value = daily.getMetricDataByMetricId(this.selectedMetric.metricId).value;
      }
    }

    this.setDisable();
  }

  /**
   * Speichert nicht bei Wochenziel bearbeiten im erweiterten Modus
   * */
  protected async onSaveGoalPlan() {
    if (!this.goalPlan) {
      this.goalPlan = new GoalPlan();
      this.goalPlan.id = FirestoreNutritionPlanService.generateUniqueString(12);
    }

    if (!this.isNew && this.mode == EditMode.EASY) {
      this.intermediateGoals.push(this.nextGoal)
    }

    this.goalPlan.name = this.planName;
    this.goalPlan.metric = this.selectedMetric;
    this.goalPlan.goalSteps = this.intermediateGoals;
    this.goalPlan.type = this.type;
    this.goalPlan.showInApp = this.showInApp;
    this.goalPlan.showOnCoacheeDashboard = this.showOnCoacheeDashboard;
    this.goalPlan.startDate = this.intermediateGoals[0].date;
    this.goalPlan.aggregation = this.aggregation;

    if (this.type == GoalPlanType.PROGRESS) {
      this.goalPlan.endDate = this.intermediateGoals[this.intermediateGoals.length - 1].date;
    } else {
      this.goalPlan.endDate = this.endDate || null;
    }

    // Activate push notification if it is not active
    for (var notificationId of this.goalPlan.scheduledPushNotificationIds) {
      var notification = this.pushNotifications.find(noti => noti.id == notificationId);
      if (notification) {
        if (!notification.active) {
          notification.active = true
          this.firestoreService.updateScheduledPushNotification(notification)
        }
      }
    }

    var isMetricAssigned = this.user.assignedMetrics?.find(m => m.metricId == this.selectedMetric.metricId) != null
    if (!isMetricAssigned && this.selectedMetric.metricId != 'waterIntake') {
      console.log('Assign metric to user', this.user.uid, this.user.getLid())
      this.user.assignedMetrics.push(this.selectedMetric)
      var date = new Date()
      this.user.assignedMetrics.forEach(metric => {
        metric.applied = false
        metric.assignmentDate = date
      });
      await this.firestoreService.insertAssignedMetrics(this.user, this.user.assignedMetrics)
    }

    this.notificationService.composeGoalPlanUpdatedNotification(this.goalPlan.id)

    this.dialogRef.close(this.goalPlan);
  }

  protected onCancelGoalPlan() {
    if (this.isNew) {
      this.dialogRef.close(null);
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translate.instant('Möchtest du das Ziel wirklich löschen?'),
          title: this.translate.instant('Ziel löschen'),
          positiveButton: this.translate.instant('Ja'),
          negativeButton: this.translate.instant('Nein')
        },
      });
      this.dialogSubscriptions.push(dialogRef.afterClosed().subscribe(result => {
        if (result == true) {
          this.goalPlan.deleted = true;
          this.dialogRef.close(this.goalPlan);
        }
      }));
    }
  }

  protected validate() {
    const inputsValid = this.inputs?.toArray().every(input => input.nativeElement.checkValidity());
    if (!inputsValid) {
      this.notValid = true;
      return;
    }

    if (this.type == GoalPlanType.WEEKLY) {
      if (this.aggregation !== WeeklyAggregation.AVG && this.aggregation !== WeeklyAggregation.SUM) {
        this.notValid = true;
        return;
      }
    }

    if (this.intermediateGoals.length === 0 && this.nextGoal == null) {
      this.notValid = true;
      return;
    }

    if (this.intermediateGoals?.length <= 1 && this.type == GoalPlanType.PROGRESS) {
      this.notValid = true;
      return;
    }

    if (this.selectedMetric) {
      this.notValid = false;
      return;
    }

    this.notValid = true;
  }

  protected onCreateIntermediateGoal() {
    const lastGoal = this.getLastGoal();
    let goalDate = new Date();
    if (this.type === GoalPlanType.WEEKLY) {
      goalDate = this.createWeeklyGoalStartDate(moment(lastGoal.date).add(1, "day").toDate());
    } else {
      goalDate = moment(lastGoal.date).add(1, "day").toDate();
    }

    this.intermediateGoals.push(new GoalStep({
      date: goalDate,
      name: "",
      value: null,
      maxValue: null,
      minValue: null,
      showInApp: this.showInApp
    }));

    this.setDisable();
  }

  protected onDeleteIntermediateGoal(goalStep: GoalStep) {
    this.intermediateGoals.splice(this.intermediateGoals.indexOf(goalStep), 1);
    if(this.nextGoal && goalStep.isEqual(this.nextGoal)) {
      this.nextGoal = null;
    }
    this.setDisable();
  }

  protected onHandleShowInApp(goalStep?: GoalStep) {
    const isAlreadyShowing = this.showInApp
    if (goalStep) {
      goalStep.showInApp = !goalStep.showInApp;

      this.showInApp = this.intermediateGoals.some(goal => goal.showInApp);
    } else {
      this.showInApp = !this.showInApp;
      this.intermediateGoals.forEach((goal) => goal.showInApp = this.showInApp);
    }

    if (!(isAlreadyShowing && this.showInApp)) {
      this.showOnCoacheeDashboard = this.showInApp;
    }
  }

  protected onTargetValueChanged(event: Event, intermediateGoal: GoalStep) {
    let inputValue = (event.target as HTMLInputElement).value;
    inputValue = inputValue.replace(',', '.');

    if (this.nextGoal && intermediateGoal == this.nextGoal) {
      this.isDirty = true;
    }

    if (inputValue.includes('-')) {
      const [minValue, maxValue] = inputValue.split('-');
      const min = this.selectedMetric.isMetricTypeDuration() ? Number(this.getMinutesFromFormattedDuration(minValue)) : Number(minValue);
      const max = this.selectedMetric.isMetricTypeDuration() ? Number(this.getMinutesFromFormattedDuration(maxValue)) : Number(maxValue);
      if (isNaN(min) || isNaN(max)) {
        this.notValid = true;
        return;
      }
      intermediateGoal.minValue = min;
      intermediateGoal.maxValue = max;
      intermediateGoal.value = null;
    } else {
      const value = this.selectedMetric.isMetricTypeDuration() ? Number(this.getMinutesFromFormattedDuration(inputValue)) : Number(inputValue);

      if (isNaN(value)) {
        this.notValid = true;
        return
      }

      intermediateGoal.value = value;
      intermediateGoal.minValue = null;
      intermediateGoal.maxValue = null;
    }
  }

  protected getPrintableLastGoal(): string {
    const lastGoal: GoalStep = this.goalPlan.getEndStep();

    return `${lastGoal.date.asFormatedString()}: ${this.goalPlan.metric.isMetricTypeDuration() ? this.getDuration(lastGoal.getValue()) : lastGoal.getValue()}`;
  }

  protected getLastGoal() {
    return this.intermediateGoals[this.intermediateGoals.length - 1];
  }

  protected onSetGoalPlanType(type: GoalPlanType) {
    if (!this.isNew) return;
    this.type = type;

    if (this.type == GoalPlanType.PROGRESS) {
      this.mode = EditMode.DETAILED;
    } else {
      this.mode = EditMode.EASY;
    }

    if (this.type == GoalPlanType.WEEKLY) {
      this.intermediateGoals[0].date = this.createWeeklyGoalStartDate(this.intermediateGoals[0].date);
    }

    this.setTitle();
    this.setDisable();
    this.setMetrics();
  }

  private setTitle(): void {
    if (this.type == GoalPlanType.PROGRESS) this.title = GoalPlanText.PROGRESS;
    if (this.type == GoalPlanType.WEEKLY) this.title = GoalPlanText.WEEKLY;
    if (this.type == GoalPlanType.DAILY) this.title = GoalPlanText.DAILY;
  }

  private createWeeklyGoalStartDate(date?: Date): Date {
    const startMoment = date ? moment(date) : moment();

    if (startMoment.day() !== 1) {
      if (startMoment.day() === 0) {
        startMoment.add(1, 'days');
      } else {
        startMoment.add(1, "week").day(1);
      }
    }

    return startMoment.toDate();
  }

  protected onEndDateChanged(date?: Date) {
    this.endDate = date || (this.type === GoalPlanType.WEEKLY ? moment().endOf("week").toDate() : new Date());
  }

  protected toggleEditMode() {
    if (this.mode == EditMode.DETAILED) {
      this.mode = EditMode.EASY;
      if(this.nextGoal == null) {
        this.nextGoal = new GoalStep();
        this.nextGoal.date =  this.type == GoalPlanType.DAILY ? new Date() : this.createWeeklyGoalStartDate();
      }
      if (this.intermediateGoals.indexOf(this.nextGoal) >= 0) {
        this.intermediateGoals.splice(this.intermediateGoals.indexOf(this.nextGoal), 1);
      }
      return;
    }

    if (this.mode == EditMode.EASY) {
      this.mode = EditMode.DETAILED;
      if (this.nextGoal && (!this.endDate || this.endDate > this.nextGoal.date)) {
        this.intermediateGoals.push(this.nextGoal);
      }
      return;
    }
  }

  onSelectedNotificationChanged(item: SelectableItem) {
    if (item.isSelected) {
      this.goalPlan.scheduledPushNotificationIds.push(item.value.id)
    } else {
      this.goalPlan.scheduledPushNotificationIds.splice(this.goalPlan.scheduledPushNotificationIds.indexOf(item.value.id), 1)
    }
  }

  protected onCreateNewPushNotification(): void {
    const noti = new AutomaticPushNotification();
    noti.repetition = Repetition.daily;
    noti.nextExecutionDate = new Date();

    const config: NotificationDialogConfig = {
      isNew: true,
      selectedPushNotification: noti,
      goalNotificatonMode: true
    }
    const dialog = this.dialog.open(NotificationDialogComponent, {data: config, width: "850px"});

    this.dialogSubscriptions.push(dialog.afterClosed().subscribe(notification => {
      let noti = notification
      if (noti && noti.id) {
        this.pushNotifications.push(noti)
        this.notificationSelectionGroups[0].items.push({
          value: noti,
          name: noti.title,
          description: noti.description,
          isSelected: true
        })
        this.goalPlan.scheduledPushNotificationIds.push(noti.id)
      }

    }));
  }

  protected getDuration(minutes: number | string): string {
    if (typeof minutes === "string" && minutes.includes('-')) {
      const [minValue, maxValue] = minutes.split('-');
      const min = minValue.includes(":") ? Number(this.getMinutesFromFormattedDuration(minValue)) : Number(minValue);
      const max = maxValue.includes(":") ? Number(this.getMinutesFromFormattedDuration(maxValue)) : Number(maxValue);

      return moment.utc(moment.duration(min, "minutes").as('milliseconds')).format('HH:mm') + "-" + moment.utc(moment.duration(max, "minutes").as('milliseconds')).format('HH:mm');
    }

    return moment.utc(moment.duration(minutes, "minutes").as('milliseconds')).format('HH:mm');
  }

  private getMinutesFromFormattedDuration(duration: string): number {
    let totalMinutes = 0;

    const [hours, minutes] = duration.split(':');
    totalMinutes += Number(hours) * 60;
    totalMinutes += Number(minutes);
    return totalMinutes;
  }

  protected isFirstOnly() {
    if(!this.intermediateGoals[0].showInApp) return true;
    return this.intermediateGoals.findLast(goal => goal.showInApp) === this.intermediateGoals[0];
  }
}
