import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.service';
import { environment } from 'src/environments/environment';
import { Montserrat } from '../fonts/montserratNormal';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { UtilityService } from './utility.service';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class PlanExportbaseService {

  constructor(public userService: FirestoreService, public utilityService: UtilityService)
  {
    var mon = new Montserrat();

    var montserratBase64Normal = mon.normal;
    var montserratBase64SemiBold = mon.semibold;
    var montserratBase64Bold = mon.bold;

    (<any>pdfMake).vfs['Montserrat'] = montserratBase64Normal;
    (<any>pdfMake).vfs['MontserratSemiBold'] = montserratBase64SemiBold;
    (<any>pdfMake).vfs['MontserratBold'] = montserratBase64Bold;

    (<any>pdfMake).fonts = {
      Montserrat: {
        normal: 'Montserrat',
        bold: 'MontserratBold',
        italics: 'MontserratSemiBold',
        bolditalics: 'Montserrat'
      }
    }
  }


  fetchImageAsBlob(url): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onerror = (event) => {
        reject(xhr.status);
      }
      xhr.onload = (event) => {
        var status = xhr.status;
        if (status == 200) {
          const blob = new Blob([xhr.response], { type: 'image/jpg' });
          resolve(blob)
        } else {
          reject(status);
        }
      };
      xhr.open('GET', url);
      // xhr.setRequestHeader('Access-Control-Request-Headers', 'access-control-allow-origin')
      xhr.send();
    });
}

  async getDataURL(url: string){
    if(url){
      try{
        let blob = await fetch(url).then(r => r.blob());
        // let blob = await this.fetchImageAsBlob(url);
        let dataUrl = await new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
        return dataUrl;
      }
      catch(ex){
        console.error(ex);
        return '';
      }
    }
    else return ''
  }

  async getHeader(brandingImageURL: string){
    let logoDataURL = await this.getDataURL(brandingImageURL);
    if(logoDataURL){
      return {columns: [{image: logoDataURL, width: '*', fit: [150, 70], style: 'right', margin: [ 5, 5, 5, 5 ]}]};
    }
    else {
      return {columns: [{text: '', width: '*'}]};
    }
  }

  async getBrandingImageURL(): Promise<string>{
    try{
      var snapshot = await this.userService.getBrandingSettings(this.userService.getLoggedInUser().licenceHolderUid).toPromise()
      var logoFileName = snapshot?.data()?.logoFileName
      if (logoFileName) {
        return await this.userService.getBrandingImage(this.userService.getLoggedInUser().licenceHolderUid, logoFileName).toPromise()
      }
    }
    catch(ex){
      console.error(ex);
    }
    return null;
  }

  async getFooter(brandingImageURL: string){
    let logoDataURL = await this.getDataURL(brandingImageURL);
    let poweredByDataURL = await this.getDataURL("assets/images/powered_by_nutrilize.png");

    let columns = [];

    // columns.push({text: '', width: '*',});
    if(logoDataURL){
      columns.push({image: logoDataURL, width: '*', fit: [100, 30], style: 'centered', margin: [ 5, 5, 5, 5 ]});
    }
    else {
      columns.push({text: '', width: '*'});
    }
    if (!environment.isWhitelabel) columns.push({image: poweredByDataURL, width: '*', fit: [100, 20], alignment: 'right', margin: [5, 5, 5, 5]});

    return columns;
  }


  public getFileName(name: string) {
    const sanitizedString = name.replace(/[\/\\:*?"<>|!+=]/g, '_');
    const maxLength = 255;
    if (sanitizedString.length > maxLength) {
      return sanitizedString.slice(0, maxLength);
    }

    return sanitizedString;
    // return name.replace(/[^a-zA-Z0-9]/g, '_').replace(/_+/g, '_');
  }


  downloadPdf(pdf, fileName: string) {
    if(this.utilityService.onNativeMobileApp()){
      pdf.getBase64(async (data) => {
        let path = `nutrilize/${fileName}`;
        const result = await Filesystem.writeFile({
          path,
          data: data,
          directory: Directory.Cache,
          recursive: true});
        // await Share.share({
        //   url: result.uri,
        //   dialogTitle: 'nutrilize',
        // });
        await FileOpener.openFile({path: result.uri});
      });
    }
    else{
      pdf.download(fileName);
    }
  }


}
