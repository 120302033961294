<div id="questionaire-editor" class="layout-box" (window:beforeunload)="doBeforeUnload()">
    <div class="container-fluid component-wrapper padding-0" *ngIf="questionaire">
        <div class="top-bar" *ngIf="!readOnlyMode">
            <div class="icon-button" (click)="saveQuestionaire()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                </svg>
                <span *ngIf="!utilityService.onSmallDisplay()" translate>Speichern</span>
            </div>
            <div class="icon-button float-right" (click)="cancelEditQuestionaire()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                <span *ngIf="!utilityService.onSmallDisplay()" translate>Schließen</span>
            </div>
            <div class="icon-button float-right delete-recipe marginleft-25 marginright-25" (click)="deleteQuestionaire()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="padding-4 bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                </svg>
                <span *ngIf="!utilityService.onSmallDisplay()" translate>Löschen</span>
            </div>
        </div>
        <div class="top-bar" *ngIf="readOnlyMode">
            <div class="icon-button" (click)="cancelEditQuestionaire()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                <span *ngIf="!utilityService.onSmallDisplay()" translate>Schließen</span>
            </div>
        </div>
        <div class="spacer-10"></div>
        <div class="row">
            <div class="col-12">
                <h2 class="" translate>Check-In bearbeiten</h2>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <label for="input-plan-name" translate>Interner Name</label>
                <input id="input-plan-name" [disabled]="readOnlyMode" maxlength="50" required class="input plannedmeal-name form-control wide-input" [(ngModel)]="questionaire.internalName" [placeholder]="'Name' | translate" type="text">
            </div>
            <div class="col-lg-3 col-sm-6 col-12 mobile-margintop-10">
                <label for="input-plan-name" translate>Sichtbarer Name</label>
                <input id="input-plan-name" [disabled]="readOnlyMode" maxlength="35" required class="input plannedmeal-name form-control wide-input" [(ngModel)]="questionaire.name" [placeholder]="'Name' | translate" type="text">
            </div>
            <div *ngIf="environment.firebaseProjectId == 'traindoo-app'" class="col-lg-3 col-sm-6 col-12 mobile-margintop-10">
                <label for="input-plan-name" translate>Typ</label>
                <div class="d-inline-block wrapper-selection">
                    <button class="button-filled light small dropdown-toggle margin-inbetween" [disabled]="readOnlyMode" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{questionaire?.getPrintableType(translate) ?? ('Auswählen' | translate)}}</button>
                    <div class="dropdown-menu">
                        <button class="dropdown-item" type="button" (click)="onTypeChanged('daily')" translate>Daily Check</button>
                        <button class="dropdown-item" type="button" (click)="onTypeChanged('weekly')" translate>Weekly Check</button>
                        <button class="dropdown-item" type="button" (click)="onTypeChanged('onboarding')" translate>Anamnesebogen</button>
                        <button class="dropdown-item" type="button" (click)="onTypeChanged('training_feedback')" translate>Trainingsfeedback</button>
                        <button class="dropdown-item" type="button" (click)="onTypeChanged('manually')" translate>Manuell</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="spacer-10"></div>
        <div *ngIf="environment.firebaseProjectId != 'traindoo-app' || questionaire?.type == 'manually'" class="row">
            <div class="col-lg-3 col-md-6 col-sm-12">
                <label for="dropdownRecipient" translate>Empfänger</label>
                <div class="d-inline-block wrapper-selection">
                    <button class="button-filled light small dropdown-toggle margin-inbetween" [class.button-invalid]='getPrintableRecipients()?.length === 0' type="button" id="dropdownRecipient" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getPrintableRecipients()}}</button>
                    <div class="dropdown-menu dropdown-recipient" aria-labelledby="dropdownNotificationTarget">
                        <div class="dropdown-item link" (click)="onTargetSelectionChangedGroup('Alle');$event.stopPropagation()">
                            <input [disabled]="!canAccessGroup('Alle')" class="input checkbox" type="checkbox" [checked]="questionaire.assignedGroupNames?.includes('Alle')"><div class="display-inlineblock" translate>Alle</div>
                        </div>
                        <ng-container *ngIf="getSelectableClientGroups()?.length > 0">
                            <div class="dropdown-divider"></div>
                            <div class="label marginleft-10" translate>Gruppen</div>
                            <div class="dropdown-item link" *ngFor="let group of getSelectableClientGroups()" (click)="onTargetSelectionChangedGroup(group);$event.stopPropagation()">
                                <input [disabled]="group != 'Alle' && areAllUsersTargeted() || !canAccessGroup(group)" class="input checkbox" type="checkbox" [checked]="questionaire.assignedGroupNames?.includes(group)"><div class="display-inlineblock">{{group}}</div>
                            </div>
                        </ng-container>
                        <div class="dropdown-divider"></div>
                        <div class="label marginleft-10" translate>Kunden</div>
                        <div class="dropdown-item link" *ngFor="let user of getSelectableClients()" (click)="onTargetSelectionChangedUser(user);$event.stopPropagation()">
                            <input [disabled]="areAllUsersTargeted()" class="input checkbox" type="checkbox" [checked]="questionaire.assignedUids?.includes(user.uid)"><div class="display-inlineblock">{{user.getName()}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="row">
                    <div class="col-12">
                        <label for="dropdownTrigger" translate>Trigger</label>
                        <div class="d-inline-block wrapper-selection">
                            <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{TriggerType2LabelMapping[questionaire?.trigger] | translate}}</button>
                            <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
                                <div *ngFor="let trig of triggerLabels">
                                    <button class="dropdown-item" type="button" (click)="onTriggerChanged(trig)" translate>{{trig}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="questionaire.trigger == TriggerType.recurring">
                    <div class="col">
                        <label for="dropdownRepetion" translate>Wiederholung</label>
                        <div class="d-inline-block wrapper-selection">
                            <button class="button-filled light small dropdown-toggle margin-inbetween" [class.button-invalid]='!questionaire?.repetition' type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{Repetition2LabelMapping[questionaire?.repetition] | translate}}</button>
                            <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
                                <div *ngFor="let rep of repetitionLabels">
                                    <button class="dropdown-item" type="button" (click)="onRepetitionSelectionChanged(rep)" translate>{{rep}}</button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="questionaire?.repetition === 'everyXDays'">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                            </svg>
                            <span translate>Wiederholung alle</span> <input type="number" min="1" max="4000" step="1" required mdInput [ngModel]="questionaire?.repetitionMultiplier" (input)="onRepetitionMultiplierChanged($event.target.value)"> <span translate>Tage</span>
                        </div>
                        <div *ngIf="!isRepetitionMultiplierValid()" class="label color-red" translate>Die Auswahl muss zwischen 1 und 3000 liegen.</div>
                    </div>
                    <div class="col">
                        <label class="form-datetime-label" for="datetime" translate>Nächste Ausführung</label>
                        <input type="datetime-local" required class="form-datetime-input" [ngClass]="{'invalid' : !isNextExecutionDateValid()}" id="datetime" [ngModel]="toDateString(questionaire.nextExecutionDate)" (input)="onNewExecutionDateChanged($event.target.value)">
                        <div *ngIf="!isNextExecutionDateValid()" class="label color-red d-inline-block" translate>Die nächste Ausführung muss in der Zukunft liegen.</div>
                    </div>
                </div>
                <div class="row" *ngIf="questionaire.trigger == TriggerType.event">
                    <div class="col-12">
                        <label for="dropdownEvent" translate>Event</label>
                        <div class="d-inline-block wrapper-selection">
                            <button class="button-filled light small dropdown-toggle margin-inbetween" [class.button-invalid]='!questionaire?.eventTrigger' type="button" id="dropdownEvent" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{EventTriggerType2LabelMapping[questionaire?.eventTrigger] | translate}}</button>
                            <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
                                <div *ngFor="let eve of eventTriggerLabels">
                                    <button class="dropdown-item" type="button" (click)="onEventTriggerSelectionChanged(eve)" translate>{{eve}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="spacer-50"></div>
        <div class="row">
            <div class="col-12">
                <div class="layout-box sub-box questions-box">
                    <div class="bold display-inlineblock" translate>Aufbau</div>
                    <div *ngIf="!readOnlyMode" class="icon-button display-inlineblock marginleft-25" (click)="addQuestions()" translate>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                        Fragen hinzufügen
                    </div>
                    <div *ngIf="!readOnlyMode" class="icon-button display-inlineblock marginleft-25" (click)="addInfoSlide()" translate>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                        Inhaltsseite hinzufügen
                    </div>
                    <div class="row" cdkDropList (cdkDropListDropped)="dropAssignedMetric($event)">
                        <ng-container *ngFor="let question of questionaire?.questions">
                            <div class="col-12 exercise-header">
                                <ng-container *ngIf="question.groupHeading != null">
                                    <div class="metric-wrapper">
                                        <div class="row">
                                            <div class="col-10 col-sm-11 col-xl-9">
                                                <input [(ngModel)]="question.groupHeading" type="text" class="input small groupheading-input">
                                                <div class="icon-button marginleft-10" (click)="onDeleteGroupHeading(question)">
                                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </ng-container>
                                <div *ngIf="question.groupHeading == null && !readOnlyMode" class="add-heading-marker-wrapper link {{hoveredQuestion === question ? 'visible' : 'hidden'}}" (click)="onAddGroupHeading(question)">
                                    <div class="add-heading-marker" translate>+ Überschrift hinzufügen</div>
                                </div>
                            </div>
                            <div (mouseover)="mouseEnterQuestion(question)" class="col-12" cdkDrag (click)="onQuestionClick(question)">
                                <div class="metric-wrapper">
                                    <div class="drag-handle link" cdkDragHandle>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-grip-vertical" viewBox="0 0 16 16">
                                            <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                        </svg>
                                    </div>
                                    <div cdkDragHandle class="row marginleft-10 link">
                                        <div class="col-10 col-lg-10 col-xl-7 link" *ngIf="question.isInfoSlide">
                                            <div class="name whitespace-prewrap">{{question.content}}</div>
                                            <img draggable="false" *ngIf="question.imageURL" src="{{question.imageURL}}" class="question-image" alt="">
                                            <video [src]="question.videoURL" *ngIf="question.videoURL" height="200" class="video-preview" id="videoPlayer" controls></video>
                                            <div *ngIf="!question.updatedVideo && question.videoPath?.toLowerCase()?.endsWith('.mov') || question.updatedVideo?.name?.toLowerCase()?.endsWith('.mov')" class="label">
                                                Für .mov-Dateien ist möglicherweise keine Vorschau verfügbar.
                                                <div class="spacer-10"></div>
                                            </div>
                                        </div>
                                        <div class="col-7 col-sm-7 col-xl-5 col-xl-3" *ngIf="!question.isInfoSlide">
                                            <div class="name">{{question.metric.nameTranslation.GetValue(translate.currentLang)}}</div>
                                            <div class="description">
                                                {{question.metric.descriptionDe}} [<span translate>Typ:</span> {{question.metric.getPrintableType(translate)}}]</div>
                                        </div>
                                        <div class="col-3 col-xl-2 link" *ngIf="!question.isInfoSlide" (click)="onReuiredChanged(question);$event.stopPropagation()">
                                            <input [checked]="question.required" [disabled]="readOnlyMode" type="checkbox" class="input checkbox required-checkbox">
                                            <div class="label d-inline-block" >{{utilityService.onMobile() ? ('Pflicht' | translate) : ('Pflichtfeld' | translate)}}</div>
                                        </div>
                                        <div class="col-2 col-sm-1 order-sm-3 order-lg-2 order-xl-3">
                                            <div *ngIf="!readOnlyMode" class="icon-button" (click)="onRemoveAssignedQustion(question)">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="spacer-10"></div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>