import {Component, Inject} from '@angular/core';
import {
  AutomaticPushNotification, DropdownItem, Label2RepetitionMapping,
  Repetition,
  Repetition2LabelMapping
} from "../../../model/automatic-push-notification.model";
import {User} from "../../../model/user.model";
import {Coach} from "../../../model/coach.model";
import {FirestoreService} from "../../../services/firestore.service";
import {ToastrService} from "ngx-toastr";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {Observable, Subscription} from "rxjs";

export type NotificationDialogConfig = {
  isNew: boolean
  selectedPushNotification: AutomaticPushNotification
  goalNotificatonMode: boolean
}

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.css']
})
export class NotificationDialogComponent {

  public repetitions = Object.values(Repetition).filter(value => typeof value === 'string');
  public repetitionLabels = Object.values(Repetition2LabelMapping).filter(value => typeof value === 'string');
  public repetition2LabelMapping = Repetition2LabelMapping;
  public label2RepetitionMapping = Label2RepetitionMapping;
  public scheduledPushNotifications: AutomaticPushNotification[] = [];
  private subscriptionCoaches: Subscription

  public selectedPushNotification: AutomaticPushNotification;
  public selectableGroupNames: DropdownItem[] = [];
  public selectableClients: DropdownItem[] = [];
  public selectableCoaches: Coach[] = []

  public selectedExecutionDate: string;
  public selectedExecutionTime: string;


  protected goalNotificatonMode: boolean = false

  constructor(public dialogRef: MatDialogRef<NotificationDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: NotificationDialogConfig, public userService: FirestoreService, private toastr: ToastrService, public dialog: MatDialog, private translate: TranslateService) {
    if (this.data) {
      this.selectedPushNotification = this.data.selectedPushNotification
      this.selectedExecutionDate = this.toDateString(this.selectedPushNotification.nextExecutionDate)
      this.goalNotificatonMode = this.data.goalNotificatonMode
      if (this.goalNotificatonMode) {
        this.selectedPushNotification.active = true
      }
    }

    this.selectableClients = []
    this.selectableGroupNames = [new DropdownItem('', 'Alle', this.selectedPushNotification.groupNames.includes('Alle'))]
    this.userService.getAccessibleClients().forEach(client => {
      this.selectableClients.push(new DropdownItem(client.uid, client.getName(), this.selectedPushNotification.userUids.includes(client.uid)))
    });
    this.userService.getClientGroups().forEach(group => {
      this.selectableGroupNames.push(new DropdownItem('', group, this.selectedPushNotification.groupNames.includes(group)))
    });
  }


  canAccessUser(user: User) {
    return this.userService.getLoggedInUser().coach.canAccessUser(user)
  }

  getScheduledPushNotifications():AutomaticPushNotification[]{
    return this.scheduledPushNotifications;
  }

  cancelEditPushNotification(){
    this.selectedPushNotification = null;
    this.dialogRef.close();
  }

  onTitleChanged(title: string){
    this.selectedPushNotification.title = title;
  }

  onDescriptionChanged(desc: string){
    this.selectedPushNotification.description = desc;
  }

  onNewExecutionDateChanged(date: string){
    this.selectedPushNotification.nextExecutionDate = this.parseDateString(date)
  }

  onNewExecutionTimeChanged(time: string){
    const date = new Date();
    const [hours, minutes] = time.split(':');
    if(Number(hours) < date.getHours() || Number(minutes) < date.getMinutes()) {
      date.addDays(1);
    }
    date.setHours(Number(hours));
    date.setMinutes(Number(minutes));
    this.selectedPushNotification.nextExecutionDate = date;
  }

  private parseDateString(date:string): Date {
    date = date.replace('T','-');
    var parts = date.split('-');
    var timeParts = parts[3].split(':');
    return new Date(+parts[0], +parts[1]-1, +parts[2], +timeParts[0], +timeParts[1]);
  }

  isNextExecutionDateValid() {
    var now = new Date()
    now.setMilliseconds(0)
    now.setSeconds(0)
    return this.selectedPushNotification?.nextExecutionDate >= now
  }

  onRepetitionSelectionChanged(rep: string){
    this.selectedPushNotification.repetition = this.label2RepetitionMapping[rep].toString();
  }

  onRepetitionMultiplierChanged(multiplier: string){
    this.selectedPushNotification.repetitionMultiplier = parseInt(multiplier, 10);
  }

  isRepetitionMultiplierValid(){
    return this.selectedPushNotification?.repetition !==  Repetition.everyXDays || (this.selectedPushNotification?.repetitionMultiplier >= 1 && this.selectedPushNotification?.repetitionMultiplier <= 3000);
  }

  getPrintableNotificationTarget(notification: AutomaticPushNotification):string{
    var checkedElements = '';
    if(notification == null){
      return ''
    }
    if(notification.coachUids != null){
      notification.coachUids.forEach(coachUid => {
        var coach = this.selectableCoaches.filter(c => c.uid == coachUid).shift()
        if (coach) {
          if(checkedElements.length > 0){
            checkedElements = checkedElements.concat(', ')
          }
          checkedElements = checkedElements.concat(this.translate.instant("{{coachNameParameter}}`s Kunden", {coachNameParameter: coach.name}));
        }
      });
    }
    if(notification.groupNames != null){
      if (notification.groupNames?.includes('Alle')) return this.translate.instant('Alle')
      notification.groupNames.forEach(name => {
        if(this.getSelectableClientGroups()?.includes(name)){
          if(checkedElements.length > 0){
            checkedElements = checkedElements.concat(', ')
          }
          checkedElements = checkedElements.concat(name);
        }
      });
    }
    if (this.selectableClients != null){
      this.selectableClients.forEach(element => {
        if(notification.userUids?.includes(element.uid)){
          if(checkedElements.length > 0){
            checkedElements = checkedElements.concat(', ')
          }
          checkedElements = checkedElements.concat(element.name);
        }
      });
    }
    return checkedElements;
  }

  onTargetSelectionChangedGroup(group: string){
    if (!this.canAccessGroup(group)) return
    if (this.selectedPushNotification.groupNames.includes(group)) {
      this.selectedPushNotification.groupNames.forEach( (item, index) => {
        if (item == group) this.selectedPushNotification.groupNames.splice(index, 1)
      })
    } else {
      this.selectedPushNotification.groupNames.push(group)
    }
  }

  getSelectableClientGroups() {
    return this.userService.getClientGroups()
  }

  canAccessGroup(group: string) {
    return this.userService.getLoggedInUser().coach.canAccessClientGroup(group)
  }

  onTargetSelectionChangedCoach(coach: Coach){
    if (!this.canAccessCoachGroup(coach)) return
    if (this.selectedPushNotification.coachUids.includes(coach.uid)) {
      this.selectedPushNotification.coachUids.forEach( (item, index) => {
        if (item == coach.uid) this.selectedPushNotification.coachUids.splice(index, 1)
      })
    } else {
      this.selectedPushNotification.coachUids.push(coach.uid)
    }
  }

  canAccessCoachGroup(coach: Coach) {
    return this.userService.getLoggedInUser().coach.canAccessCoachClients(coach)
  }

  getSelectableClients() {
    return this.userService.getAccessibleClients()
  }

  onTargetSelectionChangedUser(user: User){
    if (!this.canAccessUser(user)) return
    if (this.selectedPushNotification.userUids.includes(user.uid)) {
      this.selectedPushNotification.userUids.forEach( (item, index) => {
        if (item == user.uid) this.selectedPushNotification.userUids.splice(index, 1)
      })
    } else {
      this.selectedPushNotification.userUids.push(user.uid)
    }
  }

  areAllUsersTargeted() {
    return this.selectedPushNotification?.groupNames?.includes('Alle')
  }

  onActiveChanged(active: boolean){
    this.selectedPushNotification.active = active;
  }

  async onSave() {
    if (this.selectedPushNotification.nextExecutionDate == null || !this.isNextExecutionDateValid() || this.selectedPushNotification.repetition == null || this.selectedPushNotification.title == null || !this.isRepetitionMultiplierValid()){
      this.toastr.error(this.translate.instant("Überprüfe deine Eingaben!"), "" ,  {
        positionClass: 'toast-bottom-center'
      });
      return;
    }
    if (this.selectedPushNotification.id != null){
      await this.userService.updateScheduledPushNotification(this.selectedPushNotification);
    } else {
      this.selectedPushNotification.coachUid = this.userService.getLoggedInUser().uid;
      await this.userService.insertAutomaticPushNotification(this.selectedPushNotification);
    }
    this.dialogRef.close(this.selectedPushNotification);
    this.selectedPushNotification = null;
  }

  private toDateString(date: Date): string {
    return (date.getFullYear().toString() + '-'
        + ("0" + (date.getMonth() + 1)).slice(-2) + '-'
        + ("0" + (date.getDate())).slice(-2))
        + 'T' + date.toTimeString().slice(0,5);
  }
}
