import { Metric } from "./metric.model";
import { MetricData } from "./metricdata.model";
import { LanguageDictionary } from './languagedictionary.model';
import { FirestoreService } from "../services/firestore.service";
import { User } from "./user.model";

export class Questionaire {
    id: string;
    licenceHolderUid: string;
    internalName: string;
    name: string;
    assignedUids: string[];
    assignedGroupNames: string[];
    type: string;
    trigger: string;
    eventTrigger: string;
    repetition: string;
    repetitionMultiplier: number;
    nextExecutionDate: Date;
    timestamp: Date;
    questions: Question[];
    public: boolean
    deleted: boolean

    constructor();
    constructor(init: Questionaire);
    constructor(init?: Questionaire) {
        this.id = init && init.id || null;
        this.licenceHolderUid = init && init.licenceHolderUid || '';
        this.internalName = init && init.internalName || '';
        this.name = init && init.name || '';
        this.assignedUids = init && init.assignedUids|| [];
        this.assignedGroupNames = init && init.assignedGroupNames || [];
        this.type = init && init.type || null;
        this.trigger = init && init.trigger || TriggerType.manually;
        this.eventTrigger = init && init.eventTrigger || null;
        this.repetition = init && init.repetition || null;
        this.repetitionMultiplier = init && init.repetitionMultiplier || null;
        this.nextExecutionDate = init && init.nextExecutionDate || null;
        this.timestamp = init && init.timestamp || null;
        this.questions = init && init.questions.map(x => new Question(x.metricId, x.position, x.metric, x.groupHeading, x.content, x.imagePath, x.required, x.videoPath)) || [];
        this.deleted = init && init.deleted || false;
        this.public = init && init.public || false;
      }


    clone():Questionaire{
        return new Questionaire(this);
    }

    getPrintableType() {
        if (this.type == 'daily') return 'Daily Check'
        if (this.type == 'weekly') return 'Weekly Check'
        if (this.type == 'onboarding') return 'Anamnesebogen'
        if (this.type == 'training_feedback') return 'Trainingsfeedback'
        if (this.type == 'manually') return 'Manuell'
    }
}

export class Question {
    metricId: string;
    position: number;
    metric: Metric;
    groupHeading: string;
    content: string;
    imagePath: string;
    required: boolean;
    videoPath: string;

    imageURL: string;
    updatedImage: File;

    videoURL: any;
    updatedVideo: File;

    
    public get isInfoSlide() : boolean {
        return this.metricId == null;
    }
    
    
    constructor(metricId: string, position: number, metric: Metric, groupHeading: string, content: string, imagePath: string, required: boolean, videoPath: string){
        this.metricId = metricId;
        this.position = position;
        this.metric = metric;
        this.groupHeading = groupHeading || null;
        this.content = content || null;
        this.imagePath = imagePath || null;
        this.required = required || false;
        this.videoPath = videoPath || null;
    }

    asMap(){
        return {
            metricId: this.metricId,
            position: this.position,
            groupHeading: this.groupHeading,
            content: this.content,
            imagePath: this.imagePath,
            required: this.required,
            videoPath: this.videoPath,
        }
    }
}

export class AssignedQuestionaire {
    id: string;
    questionaireId: string;
    name: string;
    assignmentDate: Date;
    licenceHolderUid: string;
    completed: boolean;
    completionDate: Date; //(Referenz zur DailyCondition mit Ergebniswerten)
    timestamp: Date;
    eventTrigger: string; // (after_training, onboarding, null)
    questions: Question[];
    deleted: boolean;
    type: string;

    viewedByCoach: boolean
    feedback: string;
    feedbackRead: boolean;
    trackedSessionId: string;

    constructor();
    constructor(init: AssignedQuestionaire);
    constructor(init?: AssignedQuestionaire) {
        this.id = init && init.id || null;
        this.questionaireId = init && init.questionaireId || '';
        this.name = init && init.name || '';
        this.assignmentDate = init && init.assignmentDate || null;
        this.licenceHolderUid = init && init.licenceHolderUid|| '';
        this.completed = init && init.completed || false;
        this.completionDate = init && init.completionDate || null;
        this.timestamp = init && init.timestamp || null;
        this.eventTrigger = init && init.eventTrigger || null;
        this.questions = init && init.questions || [];
        this.deleted = init && init.deleted || false;
        this.viewedByCoach = init && init.viewedByCoach != null ? init.viewedByCoach : null;
        this.feedback = init && init.feedback || null;
        this.feedbackRead = init && init.feedbackRead || false;
        this.trackedSessionId = init && init.trackedSessionId || null;
        this.type = init && init.type || null;
      }

      clone():AssignedQuestionaire{
        return new AssignedQuestionaire(this);
      }
}

export enum TriggerType {
    event = "event",
    recurring = "recurring",
    manually = "manually"
}

export const TriggerType2LabelMapping: Record<TriggerType, string> = {
    [TriggerType.event]: "Bei Event",
    [TriggerType.recurring]: "Regelmäßiger Versand",
    [TriggerType.manually]: "Manuell",
};

export const Label2TriggerTypeMapping: Record<string, TriggerType> = {
    "Bei Event": TriggerType.event,
    "Regelmäßiger Versand":  TriggerType.recurring,
    "Manuell": TriggerType.manually,
};

export enum EventTriggerType {
    after_training = "after_training",
    onboarding = "onboarding"
}

export const EventTriggerType2LabelMapping: Record<EventTriggerType, string> = {
    [EventTriggerType.after_training]: "Nach jedem Training",
    [EventTriggerType.onboarding]: "Onboarding",
};

export const Label2EventTriggerTypeMapping: Record<string, EventTriggerType> = {
    "Nach jedem Training": EventTriggerType.after_training,
    "Onboarding": EventTriggerType.onboarding,
};


export class QuestionaireResult{
    questionaireId: string;
    metricData: MetricData[];
    assignedQuestionaire: AssignedQuestionaire;

    constructor();
    constructor(init: QuestionaireResult);
    constructor(init?: QuestionaireResult) {
        this.questionaireId = init && init.questionaireId || null;
        this.metricData = init && init.metricData || [];
        this.assignedQuestionaire = init && init.assignedQuestionaire || null;
    }

    metricDataAsFirebaseMap(metricData: MetricData[]){

    }

    canAccess(user: User) {
        if (user.coach?.licenceHolderUid == this.assignedQuestionaire?.licenceHolderUid) return true
        if (this.assignedQuestionaire?.licenceHolderUid == 'IAAsaah0p4gdNXwOVqviFsgpimM2') return true
        if (!user.coach) return true
        return false
    }
    
    asMap(){
        return {
            questionaireId: this.questionaireId,
            metricData: FirestoreService.getMetricDataAsFirebaseMap(this.metricData),
        }
    }

    clone():QuestionaireResult{
        let questionaireResult = new QuestionaireResult(this);
        questionaireResult.metricData = this.metricData.map(x => x.clone());        
        return questionaireResult;
    }
}