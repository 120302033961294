import {Component, OnInit} from '@angular/core';
import {Payment, PaymentStatus} from "../../../model/payment.model";
import {
  DropdownItem,
  GraphTimeRangeOptions,
  PaymentAnalyticsService,
  RevenueStatistic,
  Statistic
} from "../../../services/payment-analytics.service";
import * as moment from "moment";
import {AnalyticsConfigMenuItem} from "../analytics-config-menu/analytics-config-menu-item";
import { TranslateService } from '@ngx-translate/core';

type RevenueTooltipInfo = {
  total: string;
  real: string;
  open: string;
  forecast: string;
}
@Component({
  selector: 'app-graph-revenue-over-time',
  templateUrl: './graph-revenue-over-time.component.html',
  styleUrls: ['./graph-revenue-over-time.component.css']
})
export class GraphRevenueOverTimeComponent extends AnalyticsConfigMenuItem implements OnInit {
  private graphTimeDates: moment.Moment[] = [];
  private payments: Payment[] = [];
  private revenueHash: Map<string, RevenueTooltipInfo> = new Map();

  public graphTimeRanges: DropdownItem[] = []
  public revenueStatistics: RevenueStatistic[] = [];
  public selectedGraphTimeRange: DropdownItem = null;
  public month: moment.Moment = moment();

  customizeTooltip = (arg)=> {
    const info = this.revenueHash.get(arg.argument);
    let argument = arg.argument;
    if(this.selectedGraphTimeRange.id === GraphTimeRangeOptions.THIS_MONTH || this.selectedGraphTimeRange.id === GraphTimeRangeOptions.LAST_MONTH) {
      argument = this.translate.instant("Tag") + ': ' + argument;
    }
    let text = `${argument}</br><b>${this.translate.instant('Gesamt')}: ${info.total}</b></br>`;
    if(info.real) text += `${this.translate.instant('Realisiert')}: ${info.real}</br>`;
    if(info.open) text += `${this.translate.instant('Offen')}: ${info.open}</br>`;
    if(info.forecast) text += `${this.translate.instant('Zukünftig')}: ${info.forecast}</br>`;
    return {
      text: text
    };
  };

  constructor(private paymentAnalyticsService: PaymentAnalyticsService, private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.init();
  }

  private init() {
    let month = this.month;
    month.locale(this.translate.currentLang);
    this.graphTimeRanges = this.paymentAnalyticsService.getGraphTimeRanges();
    this.selectedGraphTimeRange = this.statisticConfig.selectedTimeRange ?? this.graphTimeRanges[0];
    this.payments = this.paymentAnalyticsService.getPaidProcessingAndFuturePayments();
    this.onGraphTimeRangeChanged(this.selectedGraphTimeRange)
  }

  onGraphTimeRangeChanged(selection: DropdownItem) {
    this.selectedGraphTimeRange = this.statisticConfig.selectedTimeRange = selection;
    this.onConfigSettingChanged();

    const possibleDates = this.payments.map(payment => payment.getDate());

    const {
      startDate,
      endDate,
      statistics
    } = this.paymentAnalyticsService.calculateGraphTimeRange(selection, possibleDates);
    this.graphTimeDates = [startDate, endDate];
    this.month = startDate;
    this.calculateRevenue(statistics);
  }

  private calculateRevenue(stats: Statistic[]) {
    const revenues = this.payments.filter(payment => {
      switch (this.selectedGraphTimeRange.id) {
        case GraphTimeRangeOptions.LAST_7_DAYS:
          return moment(payment.getDate()).isBetween(this.graphTimeDates[0], this.graphTimeDates[1], "day", "[]");
        case GraphTimeRangeOptions.LAST_28_DAYS:
        case GraphTimeRangeOptions.LAST_12_WEEKS:
          return moment(payment.getDate()).isBetween(this.graphTimeDates[0], this.graphTimeDates[1], "week", "[]");
        case GraphTimeRangeOptions.TOTAL:
          return moment(payment.getDate()).isBetween(this.graphTimeDates[0], this.graphTimeDates[1], "month", "[]");
        case GraphTimeRangeOptions.LAST_MONTH:
          return moment(payment.getDate()).isBetween(this.graphTimeDates[0], this.graphTimeDates[1], "day", "[]");
        case GraphTimeRangeOptions.LAST_QUARTER:
          return moment(payment.getDate()).isBetween(this.graphTimeDates[0], this.graphTimeDates[1], "month", "[]");
        case GraphTimeRangeOptions.THIS_MONTH:
          return moment(payment.getDate()).isBetween(this.graphTimeDates[0], this.graphTimeDates[1], "day", "[]");
        case GraphTimeRangeOptions.THIS_QUARTER:
          return moment(payment.getDate()).isBetween(this.graphTimeDates[0], this.graphTimeDates[1], "month", "[]");
        case GraphTimeRangeOptions.FORECAST:
          return moment(payment.getDate()).isBetween(this.graphTimeDates[0], this.graphTimeDates[1], "month", "[]");
        case GraphTimeRangeOptions.LAST_365_DAYS:
          return moment(payment.getDate()).isBetween(this.graphTimeDates[0], this.graphTimeDates[1], "month", "[]");
        case GraphTimeRangeOptions.THIS_YEAR:
          return moment(payment.getDate()).isBetween(this.graphTimeDates[0], this.graphTimeDates[1], "month", "[]");
        default:
          throw new Error(`The ID: ${this.selectedGraphTimeRange.id} has not been implemented yet!`)
      }
    });

    const statistics: RevenueStatistic[] = stats.map(stat => {
      return {
        ...stat,
        revenuePaid: 0,
        revenueOpen: 0,
        revenueProjection: 0,
      }
    });

    statistics.forEach(statistic => {
      const statisticDate = statistic.date;
      const payedPaymentsAmount = revenues.filter(payment => payment.status === PaymentStatus.PAID).filter(payment => {
        switch (this.selectedGraphTimeRange.id) {
          case GraphTimeRangeOptions.LAST_7_DAYS:
            return statisticDate.isSame(payment.getDate(), "day");
          case GraphTimeRangeOptions.LAST_28_DAYS:
          case GraphTimeRangeOptions.LAST_12_WEEKS:
            return statisticDate.isSame(payment.getDate(), "week");
          case GraphTimeRangeOptions.TOTAL:
            return statisticDate.isSame(payment.getDate(), "month");
          case GraphTimeRangeOptions.LAST_MONTH:
            return statisticDate.isSame(payment.getDate(), "day");
          case GraphTimeRangeOptions.LAST_QUARTER:
            return statisticDate.isSame(payment.getDate(), "month");
          case GraphTimeRangeOptions.THIS_MONTH:
            return statisticDate.isSame(payment.getDate(), "day");
          case GraphTimeRangeOptions.THIS_QUARTER:
            return statisticDate.isSame(payment.getDate(), "month");
          case GraphTimeRangeOptions.FORECAST:
            return statisticDate.isSame(payment.getDate(), "month");
          case GraphTimeRangeOptions.LAST_365_DAYS:
            return statisticDate.isSame(payment.getDate(), "month");
          case GraphTimeRangeOptions.THIS_YEAR:
            return statisticDate.isSame(payment.getDate(), "month");
          default:
            throw new Error(`The ID: ${this.selectedGraphTimeRange.id} has not been implemented yet!`)
        }
      }).reduce((previousValue, currentValue) => previousValue + currentValue.amount, 0) / 100;

      const openPaymentsAmount = revenues.filter(payment => payment.status === PaymentStatus.PROCESSING).filter(payment => {
        switch (this.selectedGraphTimeRange.id) {
          case GraphTimeRangeOptions.LAST_7_DAYS:
            return statisticDate.isSame(payment.getDate(), "day");
          case GraphTimeRangeOptions.LAST_28_DAYS:
          case GraphTimeRangeOptions.LAST_12_WEEKS:
            return statisticDate.isSame(payment.getDate(), "week");
          case GraphTimeRangeOptions.TOTAL:
            return statisticDate.isSame(payment.getDate(), "month");
          case GraphTimeRangeOptions.LAST_MONTH:
            return statisticDate.isSame(payment.getDate(), "day");
          case GraphTimeRangeOptions.LAST_QUARTER:
            return statisticDate.isSame(payment.getDate(), "month");
          case GraphTimeRangeOptions.THIS_MONTH:
            return statisticDate.isSame(payment.getDate(), "day");
          case GraphTimeRangeOptions.THIS_QUARTER:
            return statisticDate.isSame(payment.getDate(), "month");
          case GraphTimeRangeOptions.FORECAST:
            return statisticDate.isSame(payment.getDate(), "month");
          case GraphTimeRangeOptions.LAST_365_DAYS:
            return statisticDate.isSame(payment.getDate(), "month");
          case GraphTimeRangeOptions.THIS_YEAR:
            return statisticDate.isSame(payment.getDate(), "month");
          default:
            throw new Error(`The ID: ${this.selectedGraphTimeRange.id} has not been implemented yet!`)
        }
      }).reduce((previousValue, currentValue) => previousValue + currentValue.amount, 0) / 100;

      const projectionPaymentsAmount = revenues.filter(payment => payment.status === PaymentStatus.FORECAST).filter(payment => {
        switch (this.selectedGraphTimeRange.id) {
          case GraphTimeRangeOptions.LAST_7_DAYS:
            return statisticDate.isSame(payment.getDate(), "day");
          case GraphTimeRangeOptions.LAST_28_DAYS:
          case GraphTimeRangeOptions.LAST_12_WEEKS:
            return statisticDate.isSame(payment.getDate(), "week");
          case GraphTimeRangeOptions.TOTAL:
            return statisticDate.isSame(payment.getDate(), "month");
          case GraphTimeRangeOptions.LAST_MONTH:
            return statisticDate.isSame(payment.getDate(), "day");
          case GraphTimeRangeOptions.LAST_QUARTER:
            return statisticDate.isSame(payment.getDate(), "month");
          case GraphTimeRangeOptions.THIS_MONTH:
            return statisticDate.isSame(payment.getDate(), "day");
          case GraphTimeRangeOptions.THIS_QUARTER:
            return statisticDate.isSame(payment.getDate(), "month");
          case GraphTimeRangeOptions.FORECAST:
            return statisticDate.isSame(payment.getDate(), "month");
          case GraphTimeRangeOptions.LAST_365_DAYS:
            return statisticDate.isSame(payment.getDate(), "month");
          case GraphTimeRangeOptions.THIS_YEAR:
            return statisticDate.isSame(payment.getDate(), "month");
          default:
            throw new Error(`The ID: ${this.selectedGraphTimeRange.id} has not been implemented yet!`)
        }
      }).reduce((previousValue, currentValue) => previousValue + currentValue.amount, 0) / 100;

      statistic.revenuePaid += payedPaymentsAmount;
      statistic.revenueOpen += openPaymentsAmount;
      statistic.revenueProjection = projectionPaymentsAmount;

      this.revenueHash.set(statistic.timeArgument, {
        total: this.paymentAnalyticsService.transformNumberToMoney(payedPaymentsAmount + openPaymentsAmount + projectionPaymentsAmount),
        real: payedPaymentsAmount > 0 ? this.paymentAnalyticsService.transformNumberToMoney(payedPaymentsAmount) : null,
        open: openPaymentsAmount > 0 ? this.paymentAnalyticsService.transformNumberToMoney(openPaymentsAmount) : null,
        forecast: projectionPaymentsAmount > 0 ? this.paymentAnalyticsService.transformNumberToMoney(projectionPaymentsAmount) : null,
      })
    });

    this.revenueStatistics = statistics;
  }

  protected readonly GraphTimeRangeOptions = GraphTimeRangeOptions;
}
