import { Injectable } from '@angular/core';
import { Configuration, OpenAIApi } from "openai";
import { MergedTrainingExercise } from '../model/training-exercise';
import { NgxSpinnerService } from 'ngx-spinner';
import { FirestoreService } from './firestore.service';
import { Recipe } from '../model/recipe.model';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DateAdapter } from '@angular/material/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private spinner: NgxSpinnerService, private userService: FirestoreService, private translate: TranslateService, private toastr: ToastrService, private dateAdapter: DateAdapter<Date>) {
    var subscription = userService.observableUser.subscribe(user => {
      if (user) {
        this.selectedUnitSystem = user.coach?.unitSystem || 'metric'
        subscription.unsubscribe()
      }
    })
    this.selectedLanguageCode = translate.currentLang ?? 'de';

    translate.onLangChange.subscribe(event => {
      this.selectedLanguageCode = event.lang ?? 'de';
      dateAdapter?.setLocale(this.getFullLanguageCode(event.lang ?? 'de'));
    });
  }

  public mainLanguageCode: string = 'de';

  public selectedLanguageCode: string;
  public selectedUnitSystem: string = 'metric'; // metric or imperial

  getUnit() {
    return this.selectedUnitSystem === 'metric' ? 'kg' : 'lbs'
  }

  getPrintableUnitSystem() {
    return this.selectedUnitSystem === 'metric' ? 'Metrisch' : 'Imperial'
  }
  onSelectUnitSystem(unitSystem: string) {
    this.selectedUnitSystem = unitSystem
    this.userService.getLoggedInUser().coach.unitSystem = unitSystem
    this.userService.updateUnitSystem(this.userService.getLoggedInUser())
  }

  public setSelectedLanguageCode(code: string){
    this.selectedLanguageCode = code;
  }


  //todo: reeneable languages
  public availableLanguages: any[] = [
    { code: 'de', name: 'Deutsch' },
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Spanish' },
    { code: 'fr', name: 'French' },
  ];

  selectLanguage(code: string){
    this.selectedLanguageCode = code;
  }

  public get isLanguageEnglish(){
    return this.selectedLanguageCode === 'en';
  }

  public get isLanguageGerman(){
    return this.selectedLanguageCode === 'de';
  }

  public get isLanguageSpanish() {
    return this.selectedLanguageCode === 'es';
  }

  public get isLanguageFrench() {
    return this.selectedLanguageCode === 'fr';
  }

  public getGlobalSelectedLanguageCode(){ 
    return this.translate.currentLang;
  }

  public getFullLanguageCode(languageCode: string){
    return languageCode === 'de' ? 'de-DE' : languageCode === 'fr' ? 'fr-FR' : languageCode === 'es' ? 'es-ES' : 'en-US';
  }

  
  // open ai

  async translateRecipeNameToAllLanguages(recipe: Recipe, sourceLanguageCode: string, force: boolean = false){
    let sourceName = recipe.nameTranslation.GetValue(sourceLanguageCode) ?? recipe.baseRecipe?.nameTranslation.GetValue(sourceLanguageCode);
    if(!sourceName || sourceName.length <= 0){
      console.log('no name');
      return;
    }
    try {
      for(let language of this.availableLanguages){
        if(language.code === sourceLanguageCode){
          continue;
        }
        if((recipe.nameTranslation.GetValue(language.code) || recipe.baseRecipe?.instructionsTranslation?.GetValue(language.code)) && !force){
          continue;
        }
        let command = `Translate the following recipe name to ${language.name}: ${sourceName}`;
        let translation = await this.runOpenAICommand(command);
        recipe.nameTranslation.SetValue(language.code, translation);
      }
      recipe.nameTranslation = recipe.nameTranslation;
    }
    catch(ex) {
      console.error(ex);
      this.toastr.show(this.translate.instant('Übersetzung fehlgeschlagen'), this.translate.instant('Fehler'), {timeOut: 3000});
    }
  }

  async translateRecipeInstructionsToAllLanguages(recipe: Recipe, sourceLanguageCode: string, force: boolean = false){
    try {
      let instructions = recipe.instructionsTranslation.GetValue(sourceLanguageCode) ?? recipe.baseRecipe?.instructionsTranslation.GetValue(sourceLanguageCode);
      if(!instructions || instructions.length <= 0){
        console.log('no instructions');
        return;
      }
      for(let language of this.availableLanguages){
        if(language.code === sourceLanguageCode){
          continue;
        }
        if((recipe.instructionsTranslation.GetValue(language.code) || recipe.baseRecipe?.instructionsTranslation?.GetValue(language.code)) && !force){
          continue;
        }
        let command = `Translate the following recipe instructions to ${language.name}: ${instructions}`;
        let translation = await this.runOpenAICommand(command);
        recipe.instructionsTranslation.SetValue(language.code, translation);
      }
      recipe.instructionsTranslation = recipe.instructionsTranslation;
    }
    catch(ex) {
      console.error(ex);
      this.toastr.show(this.translate.instant('Übersetzung fehlgeschlagen'), this.translate.instant('Fehler'), {timeOut: 3000});
    }
  }

  async translateRecipeName(recipe: Recipe){
    this.spinner.show();
    const command = this.isLanguageEnglish ? `Translate the following recipe name to english: ${recipe.nameTranslation[this.mainLanguageCode]}`: this.isLanguageSpanish ? `Translate the following recipe name to spanish: ${recipe.nameTranslation[this.mainLanguageCode]}`:
    `Übersetze den folgenden Rezeptnamen auf deutsch: ${recipe.nameTranslation[this.mainLanguageCode]}`;
    recipe.nameTranslation[this.selectedLanguageCode] = await this.runOpenAICommand(command);
    this.spinner.hide();
  }

  async translateRecipeInstructions(recipe: Recipe){
    this.spinner.show();
    const command = this.isLanguageEnglish ? `Translate the following recipe instructions to english: ${recipe.instructionsTranslation[this.mainLanguageCode]}.`: this.isLanguageSpanish ? `Translate the following recipe instructions to spanish: ${recipe.instructionsTranslation[this.mainLanguageCode]}.`:
    `Übersetze die folgenden Rezeptanweisungen auf deutsch: ${recipe.instructionsTranslation[this.mainLanguageCode]}`;
    recipe.instructionsTranslation[this.selectedLanguageCode] = await this.runOpenAICommand(command);
    this.spinner.hide();
  }


  async generateInstructionsForExercise(exerciseName: string, exerciseSubName?: StringConstructor){
    const command = this.isLanguageEnglish ? `Act as a fitness trainer. Generate instructions for the exercise ${exerciseName}${exerciseSubName ? `(${exerciseSubName})` : ''}. Structure them with the two sections '**Set-Up**' and '**Exercise Execution**'. Use at most 5 steps per section. Use simple words. Dont write an introduction and dont write additional infos at the end`
     : this.isLanguageSpanish ? `Act as a fitness trainer. Generar instrucciones para el ejercicio ${exerciseName}${exerciseSubName ? `(${exerciseSubName})` : ''}. Estructúralos con las dos secciones '**Configuración**' y '**Ejecución del ejercicio**'. Use como máximo 5 pasos por sección. Use palabras simples. No escribas una introducción y no escribas información adicional al final`:
     `Act as a fitness trainer. Generiere Anweisungen für die Übung ${exerciseName}${exerciseSubName ? ` (${exerciseSubName})` : ''}. Strukturiere sie mit den beiden Abschnitten '**Set-Up:**' und '**Übungsausführung:**'. Verwende maximal 5 Schritte pro Abschnitt. Verwende einfache Wörter. Schreibe keine Einleitung und schreibe keine zusätzlichen Informationen am Ende.`;
    return await this.runOpenAICommand(command);
  }

  async translateOrGenerateInstructionsForExercise(exercise: MergedTrainingExercise){
    this.spinner.show();
    let oppositeLanguage = this.isLanguageEnglish ? 'de' : this.isLanguageGerman ? 'en': this.isLanguageSpanish ? 'es': 'fr';
      if(exercise.instructions.GetValue(oppositeLanguage)){
        exercise.instructions[this.selectedLanguageCode] = await this.translateExerciseInstruction(exercise.instructions.GetValue(oppositeLanguage));
      }
      else {
        exercise.instructions[this.selectedLanguageCode] = await this.generateInstructionsForExercise(exercise.name.GetValue(this.selectedLanguageCode));
      }
    this.spinner.hide();
  }


  async translateExerciseInstructionsToAllLanguages(exercise: MergedTrainingExercise, sourceLanguageCode: string, force: boolean = false){
    try {
      if(!exercise.instructions || exercise.instructions.GetValue(sourceLanguageCode)?.length <= 0 || !exercise.instructions?.GetValue(sourceLanguageCode)){
        console.log('no instructions');
        return;
      }
      this.spinner.show();
      for(let language of this.availableLanguages){
        if(language.code === sourceLanguageCode || exercise.instructions?.GetValue(sourceLanguageCode)?.length <= 0){
          continue;
        }
        if(exercise.instructions.GetValue(language.code) && !force){
          continue;
        }
        let command = `Act as a fitness trainer. Translate the following exercise instructions to ${language.name}: ${exercise.instructions[sourceLanguageCode]}`;
        let translation = await this.runOpenAICommand(command);
        exercise.instructions.SetValue(language.code, translation);
      }
      exercise.instructions = exercise.instructions;
      this.spinner.hide();
    }
    catch(ex) {
      console.error(ex);
      this.toastr.show(this.translate.instant('Übersetzung fehlgeschlagen'), this.translate.instant('Fehler'), {timeOut: 3000});
    }
  }

  async translateExerciseNameToAllLanguages(exercise: MergedTrainingExercise, sourceLanguageCode: string, force: boolean = false){
    try {
      if(!exercise.name || exercise.name.GetValue(sourceLanguageCode)?.length <= 0 || !exercise.name?.GetValue(sourceLanguageCode)){
        console.log('no name');
        return;
      }
      this.spinner.show();
      for(let language of this.availableLanguages){
        if(language.code === sourceLanguageCode || exercise.name?.GetValue(sourceLanguageCode)?.length <= 0){
          continue;
        }
        if(exercise.name.GetValue(language.code) && !force){
          continue;
        }
        let command = `Act as a fitness trainer. Translate the following exercise name to ${language.name} and only return the resulting translation: ${exercise.name[sourceLanguageCode]}`;
        let translation = await this.runOpenAICommand(command);
        exercise.name.SetValue(language.code, translation);
      }
      exercise.name = exercise.name;
      this.spinner.hide();
    }
    catch(ex) {
      console.error(ex);
      this.toastr.show(this.translate.instant('Übersetzung fehlgeschlagen'), this.translate.instant('Fehler'), {timeOut: 3000});
    }
  }
  
  async translateExerciseSubNameToAllLanguages(exercise: MergedTrainingExercise, sourceLanguageCode: string, force: boolean = false){
    try {
      if(!exercise.subName || exercise.subName.GetValue(sourceLanguageCode)?.length <= 0 || !exercise.subName?.GetValue(sourceLanguageCode)){
        console.log('no subname');
        return;
      }
      this.spinner.show();
      for(let language of this.availableLanguages){
        if(language.code === sourceLanguageCode || exercise.subName?.GetValue(sourceLanguageCode)?.length <= 0){
          continue;
        }
        if(exercise.subName.GetValue(language.code) && !force){
          continue;
        }
        let command = `Act as a fitness trainer. Translate the following exercise name subtitle to ${language.name} and only return the resulting translation: ${exercise.subName[sourceLanguageCode]}`;
        let translation = await this.runOpenAICommand(command);
        exercise.subName.SetValue(language.code, translation);
      }
      exercise.subName = exercise.subName;
      this.spinner.hide();
    }
    catch(ex) {
      console.error(ex);
      this.toastr.show(this.translate.instant('Übersetzung fehlgeschlagen'), this.translate.instant('Fehler'), {timeOut: 3000});
    }
  }

  async translateExerciseInstruction(instruction: string){
    if(instruction.length <= 0){
      return instruction;
    }
    const command = this.isLanguageEnglish ? `Act as a fitness trainer. Translate the following exercise instructions to english: ${instruction}.`:
    this.isLanguageSpanish ? `Act as a fitness trainer. Traduce las siguientes instrucciones de ejercicio al español: ${instruction}.`:
    `Act as a fitness trainer. Übersetze die folgenden Übungsanweisungen auf deutsch: ${instruction}.`;
    return await this.runOpenAICommand(command);
  }



  async runOpenAICommand (command: string):Promise<string> {
    if(!this.userService.getLoggedInUser().chatGenerationKey){
      return "";
    }
    try {
      const configuration = new Configuration({
        organization: "org-SuuOjptvkK9i4dHXRjZp7GWP",
          apiKey: this.userService.getLoggedInUser().chatGenerationKey,
      });
      const openai = new OpenAIApi(configuration);
      let result = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [{role: "user", content: command}],
      });
  
      return result.data?.choices[0]?.message?.content || '';
    } catch (error) {
      console.log(error)
    }
    return "";
  }

  canUseAutomaticTranslation(): boolean {
    return this.userService.getLoggedInUser().chatGenerationKey ? true : false;
  }

}