<button *ngIf="!showArrowOnly" mat-button [matMenuTriggerFor]="menu" class="button-filled light small dropdown-toggle margin-inbetween" (click)="openDropdown();$event.stopPropagation()" [class.button-invalid]='invalid' [disabled]='disabled'>{{getButtonText()}}</button>
<button *ngIf="showArrowOnly" mat-button [matMenuTriggerFor]="menu" class="button change-client p-0" (click)="openDropdown();$event.stopPropagation()" [class.button-invalid]='invalid' [disabled]='disabled'>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
    </svg>
</button>
<mat-menu #menu="matMenu" class="selection-dropdown-body">
    <div class="filter-container" (click)="$event.stopPropagation();">
        <div *ngIf="hasSearchField">
            <app-search-field class="searchfield-wrapper" (inputChanged)="onSearchInputChanged($event)" [additionalClasses]="''"></app-search-field>
        </div>
        <ng-container *ngFor="let group of (filteredSelectableGroups ?? selectableGroups)">
            <div class="wrapper-selectablegroup">
                <div *ngIf="group.name" class="label text-groupname">{{group.name}}</div>
                <div *ngFor="let item of group.items" class="selectableitem-wrapper {{item.isSelected ? 'selected' : ''}}" (click)="onItemClicked(item)">
                    <button class="button-selectableitem" type="button">{{ item.name }}</button>
                    <div *ngIf="item.description" class="selectableitem-description">{{item.description}}</div>
                </div>
            </div>
        </ng-container>
    </div>
</mat-menu>