<div class="dialog-topbar">
    <h3 *ngIf="showEndDate" translate>Zeitraum auswählen</h3>
    <h3 *ngIf="!showEndDate" translate>Datum auswählen</h3>
</div>
<div class="dialog-content-wrapper">
    <p *ngIf="message">
        {{message}}
    </p>

    <div class="">
        {{showEndDate ? ('Start' | translate) : ('Datum' | translate)}}:
        <span class="marginleft-10">{{startDate?.asFormatedString()}}</span>
        <input [matDatepicker]="startDatePicker" class="invisible-datetime-input" [matDatepickerFilter]="startDateFilter" [value]="startDate" class="visibility-hidden width-0 padding-0" (dateInput)="onStartDateChanged($event.value?.toDate())">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker ></mat-datepicker><br>
    </div>
    <div *ngIf="showEndDate">
        <span translate>Ende:</span> 
        <span class="marginleft-10">{{endDate?.asFormatedString()}}</span>
        <input [matDatepicker]="endDatePicker" class="invisible-datetime-input" [value]="endDate" class="visibility-hidden width-0 padding-0" (dateInput)="onEndDateChanged($event.value?.toDate())">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker ></mat-datepicker>
    </div>

    <br>
    <div class="actions row">
        <button class="button-filled small light float-right col m-2" (click)="onCancelDialog()" translate>Abbrechen</button>
        <button class="button-filled small light float-right col m-2" (click)="onConfirmDialog()" translate>Bestätigen</button>
    </div>
</div>