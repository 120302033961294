import { Component, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { LanguageService } from 'src/app/services/language.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-settings-v2',
  templateUrl: './settings-v2.component.html',
  styleUrls: ['./settings-v2.component.css']
})
export class SettingsV2Component {

  constructor(public userService: FirestoreService, public languageService: LanguageService, public authService: AuthService, public ngZone: NgZone, public utilityService: UtilityService, private translate: TranslateService) {
    this.isDarkModeEnabled = localStorage.getItem('darkMode') == 'true'
    if (this.licenceHolder?.customDataProcessingAgreement) {
      this.customDataProcessingAgreement = this.licenceHolder?.customDataProcessingAgreement
    }
    this.user = this.userService.getLoggedInUser()
  }

  get isNutritionEnabled() {
    return this.userService.getLoggedInUser().isNutritionEnabled()
  }
  isDarkModeEnabled: boolean
  darkModeSettingChanged = false

  showTeamManagement = false
  customDataProcessingAgreement: string = null
  customDataProcessingAgreementChanged = false

  user: User

  subscription: any
  stripeCustomerPortalLink: string = null
  stripeCheckoutSessionLink: string = null

  get coach() {
    return this.userService.getLoggedInUser()?.coach
  }
  get licenceHolder() {
    return this.userService.getLoggedInUser()?.licenceHolder
  }

  ngOnInit(): void {
    if (this.utilityService.onNativeMobileApp()) return

    if (this.user.getStripeCustomerId()) {
      this.loadStripeCustomerPortalLink()
    } else if (this.user.isLicenceHolder) {
      if (!this.user.licenceHolder) {
        var licenceHolderSubscription = this.userService.getLicenceHolderByUid(this.user.licenceHolderUid).subscribe(licenceHolder => {
          if (this.user.licenceHolderUid == this.user.uid) this.user.isLicenceHolder = true
          this.user.licenceHolder = licenceHolder
          this.userService.loadSubscriptions(this.user)
          licenceHolderSubscription.unsubscribe()
          if (this.user.getStripeCustomerId()) {
            this.loadStripeCustomerPortalLink()
          } else {
            this.createStripeCustomer()
          }
        })
      } else {
        this.createStripeCustomer()
      }
    }
    if (this.user.getStripeCustomerId()) this.loadStripeCheckoutSession();
  }

  onCustomDataProcessingAgreementChanged(value: string) {
    this.customDataProcessingAgreement = value
    this.customDataProcessingAgreementChanged = true
  }
  async onSaveDataProcessingAgreement() {
    this.licenceHolder.customDataProcessingAgreement = this.customDataProcessingAgreement
    await this.userService.updateLicenceHolder(this.licenceHolder)
    this.customDataProcessingAgreementChanged = false
  }
  
  onNutritionEnabledChanged(value: boolean) {
    this.userService.getLoggedInUser().coach.nutritionEnabled = value
    this.userService.updateNutritionEnabled(this.userService.getLoggedInUser(), this.isNutritionEnabled)
  }
  onDarkModeEnabledChanged(value: boolean) {
    this.isDarkModeEnabled = value
    this.darkModeSettingChanged = true
    localStorage.setItem('darkMode', value ? 'true' : 'false')
  }
  onVoiceMessagesEnabledChanged() {
    var enabled = !this.coach.coacheeVoiceMessageEnabled
    this.userService.updateVoiceMessagesEnabled(this.coach, enabled)
  }

  onShowTeamManagement() {
    this.showTeamManagement = !this.showTeamManagement
  }


  loadStripeCustomerPortalLink() {
    this.userService.createStripeCustomerPortalSession(this.user.getStripeCustomerId()).subscribe(result => {
      this.ngZone.run( () => {
        this.stripeCustomerPortalLink = result.redirectUrl
      });
    }, err => {})
  }
  loadStripeCheckoutSession() {
    this.user.observableSubscription.subscribe(subscription => {
      console.log(subscription)
      this.ngZone.run( () => {
        this.subscription = subscription
      });
      if (!subscription) {
        this.userService.createStripeCheckoutSession(this.user.getStripeCustomerId(), this.user.licenceHolder?.stripePriceId ?? 'price_1Q0NKWIEyZaY1mQMlI9U1yCn', !this.user.licenceHolder?.preStarterPackageClient, this.user.licenceHolder?.allowPaymentMethodCreditCard ?? false).subscribe(result => {
          console.log(result)
          this.ngZone.run( () => {
            this.stripeCheckoutSessionLink = result.redirectUrl
          });
        }, err => {})
      } else {
      }
    })
  }
  createStripeCustomer() {
    this.userService.createStripeCustomer(this.user.licenceHolderUid, this.authService.user.email, this.user.licenceHolder?.licenceHolderName ?? this.user.name).subscribe(result => {
      var customerId = result.customerId
      this.user.licenceHolder.stripeCustomerId = customerId
      this.loadStripeCustomerPortalLink()
      this.loadStripeCheckoutSession()
    }, err => {
      console.log(err)
    })
  }

  getConditionsHintTranslation() {
    return this.translate.instant('Es gelten unsere <a href="https://www.traindoo.io/legal/agb" target="_blank" translate>Allgemeinen Geschäftsbedingungen.</a>');
  }
}
