<div class="statistic-box">
  <h3 class="d-inline-block wrapper-timerange-selection marginright-10" translate>Top 10 Gesamtumsatz pro</h3>
  <div class="d-inline-block wrapper-timerange-selection">
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
      <label class="btn top10-button small" [class.active]="selectedType === TotalRevenueType.BY_CUSTOMER"
             (click)="handleToggle(TotalRevenueType.BY_CUSTOMER)">
        <input type="radio" name="options" id="option1" autocomplete="off"
               [checked]="selectedType === TotalRevenueType.BY_CUSTOMER"> {{'Kunde' | translate}}
      </label>
      <label class="btn top10-button small" [class.active]="selectedType === TotalRevenueType.BY_PRODUCT"
             (click)="handleToggle(TotalRevenueType.BY_PRODUCT)">
        <input type="radio" name="options" id="option2" autocomplete="off"
               [checked]="selectedType === TotalRevenueType.BY_PRODUCT"> {{'Produkt' | translate}}
      </label>
    </div>
  </div>
  <div class="d-inline-block float-right">
    <app-analytics-config-menu *ngIf="statisticConfig"
                               [statisticConfig]="statisticConfig"
                               (onChangeConfigSizeEvent)="onChangeConfigSize($event)"
                               (onRemoveConfigEvent)="onRemoveConfig()"
                               (onMoveConfigDownEvent)="onMoveConfigDown()"
                               (onMoveConfigUpEvent)="onMoveConfigUp()"
                               class="d-inline-block"></app-analytics-config-menu>
  </div>

  <button class="top10-button float-right" [class.active]="showDataTable" (click)="onShowTable()">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-table"
         viewBox="0 0 16 16">
      <path
        d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 2h-4v3h4zm0 4h-4v3h4zm0 4h-4v3h3a1 1 0 0 0 1-1zm-5 3v-3H6v3zm-5 0v-3H1v2a1 1 0 0 0 1 1zm-4-4h4V8H1zm0-4h4V4H1zm5-3v3h4V4zm4 4H6v3h4z"/>
    </svg>
  </button>
  <div #piechartcontainer>
    <ng-container *ngIf="selectedType === TotalRevenueType.BY_CUSTOMER">
      <dx-pie-chart
        *ngIf="!showDataTable"
        #customerPieChart
        id="customer-pie"
        palette="nutrilizePalette"
        resolveLabelOverlapping="shift"
        [dataSource]="top10RevenueByCustomerStatistics"
        (onPointClick)="pointClickHandler($event)"
        (onLegendClick)="legendClickHandler($event)"
      >
        <dxi-series argumentField="customer" valueField="customerRevenue">
          <dxo-label [visible]="true" [customizeText]="customizeCustomerLabel">
            <dxo-connector [visible]="true" [width]="1"></dxo-connector>
          </dxo-label>
        </dxi-series>
        <dxo-legend
          verticalAlignment="bottom"
          horizontalAlignment="center"
          itemTextPosition="top"
          visible="true">
        </dxo-legend>
        <!--<dxo-size [height]="showDataTable ? 0 : 400"></dxo-size>-->

      </dx-pie-chart>

      <dx-data-grid *ngIf="showDataTable"
                    [dataSource]="totalRevenueByCustomerStatistics"
                    [showBorders]="true"
                    [showRowLines]="true"
                    [columnAutoWidth]="true"
                    [height]="400"
                    (onExporting)="chartExportService.onExporting($event)">

        <dxo-scrolling mode="virtual"></dxo-scrolling>

        <dxi-column dataField="customer" [caption]="'Kunde' | translate"></dxi-column>
        <dxi-column dataField="customerRevenue" [caption]="('Umsatz in' | translate) + ' ' + paymentAnalyticsService.getCurrency(true)"></dxi-column>

        <dxo-export [enabled]="true"></dxo-export>

      </dx-data-grid>
    </ng-container>

    <ng-container *ngIf="selectedType === TotalRevenueType.BY_PRODUCT">
      <dx-pie-chart
        *ngIf="!showDataTable"
        #productPieChart
        id="product-pie"
        palette="nutrilizePalette"
        resolveLabelOverlapping="shift"
        [dataSource]="top10RevenueByProductsStatistics"
        (onPointClick)="pointClickHandler($event)"
        (onLegendClick)="legendClickHandler($event)"
      >
        <dxi-series argumentField="product" valueField="productRevenue">
          <dxo-label [visible]="true" [customizeText]="customizeProductLabel">
            <dxo-connector [visible]="true" [width]="1"></dxo-connector>
          </dxo-label>
        </dxi-series>
        <dxo-legend
          verticalAlignment="bottom"
          horizontalAlignment="center"
          itemTextPosition="top"
          visible="true">
        </dxo-legend>

        <!--<dxo-size [height]="showDataTable ? 0 : 400"></dxo-size>-->
      </dx-pie-chart>

      <dx-data-grid *ngIf="showDataTable"
                    [dataSource]="totalRevenueByProductsStatistics"
                    [showBorders]="true"
                    [showRowLines]="true"
                    [columnAutoWidth]="true"
                    [height]="400"
                    (onExporting)="chartExportService.onExporting($event)">

        <dxo-scrolling mode="virtual"></dxo-scrolling>

        <dxi-column dataField="product" [caption]="'Produkt' | translate"></dxi-column>
        <dxi-column dataField="productRevenue" [caption]="('Umsatz in' | translate) + ' ' + paymentAnalyticsService.getCurrency(true)"></dxi-column>

        <dxo-export [enabled]="true"></dxo-export>

      </dx-data-grid>
    </ng-container>
  </div>
</div>
