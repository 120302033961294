import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-visual-selection-dialog',
  templateUrl: './visual-selection-dialog.component.html',
  styleUrls: ['./visual-selection-dialog.component.css']
})
export class VisualSelectionDialogComponent {

  public title: string = "";
  
  constructor(public dialogRef: MatDialogRef<VisualSelectionDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { title: string }, private translate: TranslateService)
  {
    this.title = data.title;
  }

  selectedItem(isPeriodic: boolean){
    this.dialogRef.close({ isPeriodic: isPeriodic });
  }

  getOngoingPlanGraphic() {
    if(this.translate.currentLang == 'de') return 'assets/images/ongoing_plan_graphic.png';
    else return 'assets/images/ongoing_plan_graphic_en.png';
  }

  getPeriodicPlanGraphic() {
    if(this.translate.currentLang == 'de') return 'assets/images/periodic_plan_graphic.png';
    else return 'assets/images/periodic_plan_graphic_en.png';
  }

  onCloseDialog(){
    this.dialogRef.close();
  }
}
