import { Component, NgZone, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IndividualFirebase } from 'src/app/app.module';
import { AuthService } from 'src/app/auth/auth.service';
import { ClientBaseComponent } from 'src/app/client/client-base/client-base.component';
import { MarketingKitComponent } from 'src/app/dialogs/marketing-kit/marketing-kit.component';
import { FirestoreService } from 'src/app/services/firestore.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { PaymentService } from 'src/app/services/payment.service';
import { TrainingService } from 'src/app/services/training.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation-bar-traindoo',
  templateUrl: './navigation-bar-traindoo.component.html',
  styleUrls: ['./navigation-bar-traindoo.component.css']
})
export class NavigationBarTraindooComponent {

  public environment = environment

  @Output() logoutEvent = new EventEmitter<boolean>()

  constructor(private mainFirebase: IndividualFirebase, public authService: AuthService, private paymentService: PaymentService, public router: Router, private activatedRoute: ActivatedRoute, public userService: FirestoreService, public utilityService: UtilityService, public navService: NavbarService, public dialog: MatDialog, private ngZone: NgZone, private toastr: ToastrService, private trainingService: TrainingService) {

  }

  isLibraryMenutItemActive() {
    return this.navService.getTraining()?.includes('active') || this.navService.getRecipes()?.includes('active') || this.navService.getTools()?.includes('active')
  }
  isClientsMenuItemActive() {
    return this.router.url.includes('client/')
  }

  openTraining() {
    this.navigateToClientPage('training')
  }
  openAnalytics() {
    this.navigateToClientPage('analytics')
  }
  openNutrition() {
    this.navigateToClientPage('nutrition')
  }
  async navigateToClientPage(page: string) {
    if(await this.canDeactivateClientPage()) {
      var userUid = this.router.url.split('client/')[1]
      if (userUid?.includes('?')) userUid = userUid.split('?')[0]
      if (!userUid || userUid == undefined) return null
      this.router.navigateByUrl('client/' + userUid + '?page=' + page)
    }
  }

  async canDeactivateClientPage() {
    if (environment.firebaseProjectId == 'traindoo-app' && this.trainingService.currentEditedTrainingPlanHasChanges) {
      return await ClientBaseComponent.showDeactivateTrainingDialog(this.dialog);
    }

    return true;
  }

  isClientsAnalyticsMenuItemActive() {
    return this.router.url.includes('analytics')
  }
  isClientsTrainingMenuItemActive() {
    return this.router.url.includes('training') || !this.router.url.includes('?page=')
  }
  isClientsNutritionMenuItemActive() {
    return this.router.url.includes('nutrition')
  }

  async logout() {
    this.logoutEvent.emit(true)
  }

  openMarketingKit() {
    const dialogRef = this.dialog.open(MarketingKitComponent, { data: { }, width: '1000px'})
  }

  onCopied() {
    this.toastr.success("In Zwischenablage kopiert", "",  {
      positionClass: 'toast-bottom-center'
    })
  }

  toggle() { 
    document.getElementsByClassName('navigation-bar')[0].classList.toggle('show'); 
    document.getElementsByClassName('navigation-toggle')[0].classList.toggle('open');
    return false; 
  }
}
