import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TrackedExerciseSet, TrackedTrainingExercise, TrackedTrainingSession, TrackedTrainingSet, TrackedVideoRecording } from 'src/app/model/training-monitoring.model';
import { PlannedTrainingExercise, SetParameter, SetParameter2LabelMapping, SetParameter2LabelUnitMapping, SetParameter2SubHeadingMapping, SetParameter2UnitMapping, TrainingPlan, TrainingSession } from 'src/app/model/training-plan.model';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { TrainingService } from 'src/app/services/training.service';
import { VideoRecordingDialogComponent } from '../video-recording-dialog/video-recording-dialog.component';
import { MergedTrainingExercise } from 'src/app/model/training-exercise';
import { LanguageDictionary } from 'src/app/model/languagedictionary.model';
import { LanguageService } from 'src/app/services/language.service';
import { WeightConversionPipe } from 'src/app/weight.pipe';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-exercise-history-dialog',
  templateUrl: './exercise-history-dialog.component.html',
  styleUrls: ['./exercise-history-dialog.component.css']
})
export class ExerciseHistoryDialogComponent implements OnInit {

  public setParameter2LabelMapping = SetParameter2LabelMapping
  public setParameter2UnitMapping = SetParameter2UnitMapping
  public setParameter2LabelUnitMapping = SetParameter2LabelUnitMapping
  public setParameter2SubHeadingMapping = SetParameter2SubHeadingMapping
  public setParameter = SetParameter

  public user: User
  public plannedExercise: PlannedTrainingExercise
  public allSessions: TrackedTrainingSession[] = []
  public sessions: TrackedTrainingSession[] = []

  public availableTrainingPlans: TrainingPlan[] = []
  public selectedSessions: TrainingSession[] = []

  public selectedLanguageCode = "de"
  public trainingGraphExpanded = false

  constructor(public dialogRef: MatDialogRef<ExerciseHistoryDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { user: User, exercise: PlannedTrainingExercise }, public dialog: MatDialog, private trainingService: TrainingService, private userService: FirestoreService, public languageService: LanguageService, private weightPipe: WeightConversionPipe, public translate: TranslateService) {
    this.user = data.user
    this.plannedExercise = data.exercise
  }

  ngOnInit(): void {
    this.userService.getTrackedTrainingSessionsWithExercises(this.user, new Date().addDays(-90), new Date()).then(sessions => {
      this.sessions = []
      this.allSessions = []
      this.availableTrainingPlans = []
      sessions.forEach(session => {
        if (session.containsExercise(this.plannedExercise.exerciseId)) {
          this.allSessions.push(session)
          if(session.custom){
            let existingTrainingPlan = this.availableTrainingPlans.find(x => x.id == 'custom');
            if(!existingTrainingPlan){
              let dummySession = new TrainingSession();
              dummySession.id = 'custom';
              dummySession.nameTranslation = new LanguageDictionary('Eigene Einheit', 'Custom Session');
              let dummyPlan = new TrainingPlan();
              dummyPlan.id = 'custom';
              dummyPlan.nameTranslation = new LanguageDictionary('Ungeplant', 'Unplanned');
              dummyPlan.sessions = [dummySession];
              this.availableTrainingPlans.push(dummyPlan);
            }
          }
          let trainingPlan = this.getTrainingPlanOfTrackedSession(session)?.clone();
          if (trainingPlan) {
            let plannedSession = this.getPlannedSession(session)?.clone();
            if (plannedSession) {
              let existingTrainingPlan = this.availableTrainingPlans.find(x => x.id == trainingPlan.id);
              if (!existingTrainingPlan) {
                this.availableTrainingPlans.push(trainingPlan);
                existingTrainingPlan = trainingPlan;
                existingTrainingPlan.sessions = []
              }
              if (plannedSession.baseSessionId) {
                if (!existingTrainingPlan.sessions.find(x => x.id == plannedSession.baseSessionId)) {
                  let baseSession = this.getPlannedSessionByIds(trainingPlan.id, plannedSession.baseSessionId);
                  if (baseSession) {
                    existingTrainingPlan.sessions.push(baseSession);
                  }
                }
              }
              else if (!existingTrainingPlan.sessions.find(x => x.id == plannedSession.id)) {
                existingTrainingPlan.sessions.push(plannedSession);
              }
            }

          }
        }
      })
      this.allSessions.sort((a, b) => b.startDate.getTime() - a.startDate.getTime())
      this.availableTrainingPlans = this.availableTrainingPlans.sort((a, b) => a.nameTranslation.GetValue(this.translate.currentLang).localeCompare(b.nameTranslation.GetValue(this.translate.currentLang))).sort((a, b) => a.id == 'custom' ? -1 : b.id == 'custom' ? 1 : 0)
      this.updateVisibleSessions();
    })
  }

  onOpenSessionSelection() {
    this.availableTrainingPlans = this.availableTrainingPlans.filter(plan => !plan.deleted)
  }

  getSelectedSessions() {
    if (this.selectedSessions.length == 0) {
      return this.translate.instant('Alle Einheiten')
    } else if (this.selectedSessions.length == 1) {
      return this.selectedSessions[0].name
    } else {
      var text = ""
      this.selectedSessions.forEach(type => {
        text += type.name + ", "
      })
      text = text.substr(0, text.length - 2)
      return text
    }
  }

  onSessionSelectionChanged(session: TrainingSession) {
    if (session == null) {
      this.selectedSessions = []
      this.updateVisibleSessions();
      return
    }
    if (this.selectedSessions.includes(session)) {
      this.selectedSessions.forEach((item, index) => {
        if (item == session) this.selectedSessions.splice(index, 1);
      });
    } else {
      this.selectedSessions.push(session)
    }
    this.updateVisibleSessions();
  }

  isSessionSelected(session: TrackedTrainingSession): boolean {
    if(this.selectedSessions.length == 0) return true;
    if(session.custom) return this.selectedSessions.find(x => x.id == 'custom') != null;
    let plannedSession = this.getPlannedSession(session);
    if(plannedSession?.baseSessionId) {
      return this.selectedSessions?.find(x => x.id == plannedSession.baseSessionId) != null;
    }
    return this.selectedSessions?.find(x => x.id == session.plannedSessionId) != null;
  }

  updateVisibleSessions() {
    this.sessions = this.allSessions.filter(x => this.isSessionSelected(x))
  }

  hasAnyPauseDuration(trainingSets: TrackedExerciseSet[]): boolean {
    return trainingSets.filter(x => x.trackedTrainingSet.pauseDuration > 0).length > 0
  }
  trackedTrainingSetHasAnyPauseDuration(trainingSets: TrackedTrainingSet[]): boolean {
    return trainingSets.filter(x => x.pauseDuration > 0).length > 0
  }

  getAvailableSetParameters(trackedExercise: TrackedTrainingExercise): SetParameter[] {
    return Object.values(SetParameter).filter(parameter => trackedExercise.setParameters.includes(parameter) || this.hasValues(trackedExercise.trackedSets, parameter));
  }
  getAvailableSetParametersByTrackedExerciseSets(exerciseSets: TrackedExerciseSet[]): SetParameter[] {
    return Object.values(SetParameter).filter(parameter => this.trackedExercisesHaveValues(exerciseSets, parameter))
  }
  hasValues(sets: TrackedTrainingSet[], setParameter: SetParameter): boolean {
    return sets.filter(x => x[setParameter.toString()] != null).length > 0
  }
  trackedExercisesHaveValues(sets: TrackedExerciseSet[], setParameter: SetParameter): boolean {
    return sets.filter(x => x.trackedTrainingSet[setParameter.toString()] != null).length > 0
  }

  hasExertionValues(sets: TrackedTrainingSet[]): boolean {
    return sets.filter(x => x.exertionValue).length > 0
  }
  getExertionUnitFromPlannedExercise(exercise: PlannedTrainingExercise): string {
    if (exercise.exertionUnit) {
      return exercise.exertionUnit;
    }
    else if (exercise.setParameters.includes(SetParameter.rir)) {
      return this.setParameter2LabelUnitMapping[SetParameter.rir]
    }
    else if (exercise.setParameters.includes(SetParameter.rpe)) {
      return this.setParameter2LabelUnitMapping[SetParameter.rpe]
    }
    return 'RPE/RIR'
  }

  getSecondsAsTime(inputSeconds: number): string {
    if (inputSeconds < 0) inputSeconds = 0
    return inputSeconds.asDurationString();
  }

  onShowTrackedVideoRecording(recording: TrackedVideoRecording, exercise: TrackedTrainingExercise) {
    const dialogRef = this.dialog.open(VideoRecordingDialogComponent, { data: { userUid: this.user.uid, trackedExercise: exercise, recording: recording, exercise: this.getExerciseById(exercise.exerciseId) }, width: '1000px', autoFocus: false }).afterClosed().subscribe(result => {
    })
  }

  getPlannedSession(trackedTrainingSession: TrackedTrainingSession): TrainingSession {
    return this.getPlannedSessionByIds(trackedTrainingSession.trainingPlanId, trackedTrainingSession.plannedSessionId);
  }

  getPlannedSessionByIds(trainingPlanId: string, plannedTrainingSessionId: string) {
    var session = this.user.trainingPlans.filter(x => x.id == trainingPlanId)[0]?.sessions?.filter(x => x.id == plannedTrainingSessionId)[0]
    return session;
  }

  getTrainingPlanOfTrackedSession(trackedTrainingSession: TrackedTrainingSession): TrainingPlan {
    return this.user.trainingPlans.find(x => x.id == trackedTrainingSession.trainingPlanId);
  }

  getPlannedSetValue(trackedTrainingSession: TrackedTrainingSession, trackedTrainingExercise: TrackedTrainingExercise, setIndex: number, setParameter: SetParameter): string {
    var plannedSession = this.getPlannedSession(trackedTrainingSession)
    var plannedExercise = plannedSession?.exercises?.filter(x => x.id == trackedTrainingExercise.plannedExerciseId)[0]
    if (plannedExercise?.exerciseId == trackedTrainingExercise?.exerciseId) {
      if (setIndex < plannedExercise.sets.length) {
        let plannedSet = plannedExercise.sets[setIndex]
        if (plannedSet && setParameter?.toString()) {
          let value = plannedSet[setParameter.toString()] || "";

          if (value) {
            if (setParameter == SetParameter.time) {
              return " / " + this.getSecondsAsTime(value);
            } else if (setParameter == SetParameter.weight) {
              return " / " + this.weightPipe.transform(value, this.languageService.selectedUnitSystem, true);
            } else {
              return " / " + value.toString();
            }
          }
        }
      }
    }

    return ""
  }

  getExerciseById(exerciseId: string): MergedTrainingExercise {
    return this.trainingService.getExerciseById(exerciseId)
  }

  onCloseDialog() {
    this.dialogRef.close()
  }

  isPartOfDropset(set: TrackedTrainingSet, sets: TrackedTrainingSet[], setIndex: number) {
    if (set?.isDropset) {
      return true;
    }
    else if (sets?.length > setIndex + 1) {
      return sets[setIndex + 1].isDropset;
    }
  }
}
