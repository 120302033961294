import {Component, EventEmitter, Input, Output} from "@angular/core";
import {PaymentAnalyticsConfig} from "../payment-analytics.component";

@Component({
  template: ''
})
export abstract class AnalyticsConfigMenuItem {
  @Input()
  public statisticConfig: PaymentAnalyticsConfig;
  @Output()
  public onRemoveConfigEvent: EventEmitter<PaymentAnalyticsConfig> = new EventEmitter<PaymentAnalyticsConfig>();
  @Output()
  public onChangeConfigSizeEvent: EventEmitter<PaymentAnalyticsConfig> = new EventEmitter<PaymentAnalyticsConfig>();
  @Output()
  public onMoveConfigUpEvent: EventEmitter<PaymentAnalyticsConfig> = new EventEmitter<PaymentAnalyticsConfig>();
  @Output()
  public onMoveConfigDownEvent: EventEmitter<PaymentAnalyticsConfig> = new EventEmitter<PaymentAnalyticsConfig>();
  @Output()
  public onConfigSettingChangedEvent: EventEmitter<PaymentAnalyticsConfig> = new EventEmitter<PaymentAnalyticsConfig>();


  onRemoveConfig() {
    this.onRemoveConfigEvent.emit(this.statisticConfig);
  }

  onChangeConfigSize(config: PaymentAnalyticsConfig) {
    this.onChangeConfigSizeEvent.emit(config);
  }

  onMoveConfigUp() {
    this.onMoveConfigUpEvent.emit(this.statisticConfig);
  }

  onMoveConfigDown() {
    this.onMoveConfigDownEvent.emit(this.statisticConfig);
  }

  onConfigSettingChanged() {
    this.onConfigSettingChangedEvent.emit(this.statisticConfig);
  }
}
