<div class="imprint-wrapper">
    <div class="label link underline" (click)="toggleExpanded()" translate>Anbieter</div>
    <ng-container *ngIf="expanded">
        <ng-container *ngIf="paymentSettings?.alternativeImprintAddress">
            <div class="whitespace-prewrap">{{paymentSettings?.alternativeImprintAddress}}</div>
        </ng-container>
        <ng-container *ngIf="!paymentSettings?.alternativeImprintAddress">
            <div class="whitespace-prewrap">{{paymentSettings?.companyName}}</div>
            <div class="spacer-10"></div>
            <div class="whitespace-prewrap">{{paymentSettings?.address}}</div>
            <div class="spacer-10"></div>
            <div class="whitespace-prewrap">{{paymentSettings?.phone}}</div>
            <div class="whitespace-prewrap">{{paymentSettings?.email}}</div>
            <div class="spacer-10"></div>
            <div *ngIf="paymentSettings?.vatIdNo" class="whitespace-prewrap"><span translate>USt-IdNr:</span> {{paymentSettings?.vatIdNo}}</div>
        </ng-container>
    </ng-container>
</div>