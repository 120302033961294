<ng-container *ngIf="smallGUI; else bigGUI">
  <div class="plan-name">
    {{planName}}
  </div>
  <div class="goal-data">
    {{currentValue}}<span class="goal-step-info">{{currentConfig.info}}</span> {{currentConfig.metricData.getPrintableUnit(translate)}}<app-goal-visualization [config]="currentConfig" [hideInfo]="true"></app-goal-visualization>
  </div>
</ng-container>
<ng-template #bigGUI>
  <ng-container *ngIf="initiated">
<!--  <ng-container *ngIf="nextConfig && endConfig">-->
    <table>
      <tbody>
      <tr>
        <td class="difference-text"><span translate="">Start</span>, <span class="difference-text small">{{startDate?.asYearSensitiveShortFormatedString()}}</span></td>
        <td class="textalign-right">{{startValue}}</td>
      </tr>
      <tr *ngIf="!hasNoProperToday">
        <td class="difference-border"></td>
        <td class="difference-text primary small textalign-right">{{startTodayDiff}}</td>
      </tr>
      <tr *ngIf="!hasNoProperToday">
        <td>{{todayString}}</td>
        <td class="textalign-right"><b>{{currentValue}}</b></td>
      </tr>
      <tr>
        <td class="difference-border"></td>
        <td class="difference-text small textalign-right">{{nextGoalDiff}}</td>
      </tr>
      <tr *ngIf="hasNextGoal">
        <td class="difference-text">{{nextText}}, <span class="difference-text small">{{nextGoalDate?.asYearSensitiveShortFormatedString()}}</span></td>
        <td class="textalign-right"><app-goal-visualization [config]="nextConfig" [hideInfo]="true"></app-goal-visualization> {{nextValue}}</td>
      </tr>
      <tr>
        <td class="difference-text">{{endText}}, <span class="difference-text small">{{endGoalDate?.asYearSensitiveShortFormatedString()}}</span></td>
        <td class="textalign-right"><app-goal-visualization [config]="endConfig" [hideInfo]="true"></app-goal-visualization> {{endValue}}</td>
      </tr>
      </tbody>
    </table>
  </ng-container>
</ng-template>

