import { Injectable } from '@angular/core';
import { NumberOfRounds, PlannedTrainingExercise, SetParameter, SetParameter2ShortLabelMapping, SuperSet, TrainingPlan, TrainingSession, TrainingSet } from '../model/training-plan.model';
import { User } from '../model/user.model';
import { BehaviorSubject } from 'rxjs';
import { PlanExportbaseService } from './plan-exportbase.service';
import { FirestoreService } from './firestore.service';
import { environment } from 'src/environments/environment';
import * as pdfMake from 'pdfmake/build/pdfMake';
import { MergedTrainingExercise } from '../model/training-exercise';
import { TrainingService } from './training.service';
import * as marked from 'marked';
import { UtilityService } from './utility.service';
import { Directory, Filesystem, FilesystemDirectory } from '@capacitor/filesystem';
import { NumberToShortWeekDayStringsMapping } from '../model/task.model';
import { TrainingPlanEditorComponent } from '../training/training-plan-editor/training-plan-editor.component';
import { CardioZone, CardioZoneGroup } from 'src/app/model/cardio-zone-group.model';
import { WeightConversionPipe } from '../weight.pipe';
import { LanguageService } from './language.service';
const htmlToPdfmake = require("html-to-pdfmake");

@Injectable({
  providedIn: 'root'
})
export class TrainingplanExportService extends PlanExportbaseService {

  public exportProgress: BehaviorSubject<string> = new BehaviorSubject<string>("0%");
  
  constructor(userService: FirestoreService, private trainingService: TrainingService, utilityService: UtilityService, private weightPipe: WeightConversionPipe, public languageService: LanguageService) {
    super(userService, utilityService);
  }

  getRoundedFlowColumn(dataURL: string){
      return {image: dataURL, fit: [20, 20]};
  }

  private accentColor = getComputedStyle(document.documentElement).getPropertyValue('--accentColor') || '#4AE6E6';

  private   maxCirclesPerLine: number = 38;
  private maxNumberOfSessions: number = 6;

  async onExportTrainingPlan(trainingPlan: TrainingPlan, user: User, languageCode: string, displayTrackingSheet: boolean, displayExericseDetails: boolean, cardioZoneGroups: CardioZoneGroup[]): Promise<boolean> {
    this.exportProgress?.next("0%");
    this.exerciseCount = 0;
    var plan = trainingPlan.clone()

    let numberOfFilloutSessions = trainingPlan.isPeriodicPlan ? 1 : this.maxNumberOfSessions;
    
    let brandingImageURL = environment.isWhitelabel ? null : await this.getBrandingImageURL();
    let header = await this.getHeader(brandingImageURL);
    
    let footer = await this.getFooter(brandingImageURL);
    let docDefinition = {
      pageMargins: [40, 80, 40, 60],
      header: (currentPage: number) =>
      {
        if(currentPage === 1){
          return header;
        }
      },
      footer: (currentPage, pageCount) => {return {columns: [{text: `${currentPage} / ${pageCount}`, width: '*', style: 'left', margin: [ 15, 15, 15, 15 ]}, ...footer]}},

      pageOrientation: 'landscape',
      defaultStyle: {
        font: 'Montserrat',
        fontSize: 8,
      },
      content: [

      ],
      styles:{
        centered:
        {
            alignment: 'center'
        },
        right:
        {
            alignment: 'right'
        },
        left:
        {
            alignment: 'left'
        },
        carbohydratesPoint: {
          color: '#da4fdc',
          fontSize: 35,
          lineHeight: 0
        },
        proteinPoint: {
          color: '#4AD2DC'
        },
        fatPoint: {
          color: '#E5FF44'
        },
        caloriesPoint: {
          color: '#E92861',
          fontSize: 35,
          lineHeight: 0
        },
      }
    }
    var startDate = plan.startDate
    var endDate = plan.endDate
    var daysCount: number = null;

    let title = `${plan.nameTranslation.GetValue(languageCode)} von ${this.userService.getLoggedInUser().name}`;
    if(user?.getName()){
      title +=  ` für ${user.getName()}`;
    }
    docDefinition.content.push({text: title, bold: true, fontSize: 12, margin: [0, 0, 0, 10]})

    if(trainingPlan.isTemplate){
      let durationInDays = trainingPlan.durationInDays;
      if(durationInDays){
        if(durationInDays % 7 == 0){
          let durationInWeeks = Math.round(durationInDays / 7);
          docDefinition.content.push({text: `Dauer: ${durationInWeeks} Wochen`, margin: [0, 0, 0, 5]});
        }
        else {
          docDefinition.content.push({text: `Dauer: ${durationInDays} Tage`, margin: [0, 0, 0, 5]});
        }
      }
    }
    else {
      if(startDate && endDate){
        docDefinition.content.push({text: `Startdatum: ${startDate.asFormatedString()}`, margin: [0, 0, 0, 5]});
        docDefinition.content.push({text: `Enddatum: ${endDate.asFormatedString()}`, margin: [0, 0, 0, 3]});
        if(endDate > startDate){
          let difference = endDate.getTime() - startDate.getTime();
          daysCount = Math.floor(difference / (1000 * 3600 * 24));
        }
      }
      else if(startDate){
        docDefinition.content.push({text: `Startdatum: ${startDate.asFormatedString()}`, margin: [0, 0, 0, 3]});
      }
    }
    
    if(plan.hintTranslation.GetValue(languageCode)){
      docDefinition.content.push({columns: [{text: "Hinweis:", bold: true, margin: [0, 0, 2, 0], width: "auto"}, {text: plan.hintTranslation.GetValue(languageCode), bold: false, margin: [0, 0, 0, 0], width: "auto"}], margin: [0, 0, 0, 3]})
    }
    docDefinition.content.push({text: "", margin: [0, 0, 0, 12]})



    var fillOutSessions: any[] = []
    var sessionInfos: any[] = []
    if(!trainingPlan.isPeriodicPlan){
      var sessionFlowColumns = [];

      var singleCircles: string[] = []; 
      let sessionCounter = 1;
      for (let index = 0; index < plan.sessions.length; index++) {
        const session = plan.sessions[index];
        if(!session.deleted && !session.hide){
          if(session.isRestDay){
            let roundedCanvas = this.getRoundedCanvasWithText("R");
            sessionFlowColumns.push({columns: [this.getRoundedFlowColumn(roundedCanvas), {text: session.name, width: 'auto', bold: false, alignment: "left", fontSize: 10, margin: [5, 3, 20, 0]}], width: "auto"})
            singleCircles.push(roundedCanvas)
          }
          else {
            let roundedCanvas = this.getRoundedCanvasWithText("E" + sessionCounter);
            sessionFlowColumns.push({columns: [this.getRoundedFlowColumn(roundedCanvas), {text: session.name, width: 'auto', bold: false, alignment: "left", fontSize: 10, margin: [5, 3, 20, 0]}], width: "auto"})
            singleCircles.push(roundedCanvas)
            sessionCounter += 1;
          }
        }
      }
      
      var circles = []
      var singleCirclesCount = singleCircles.length;

      let maxDays = (sessionCounter - 1) * numberOfFilloutSessions;

      let maxCircles = Math.min(...([daysCount, this.maxCirclesPerLine, maxDays]).filter(x => x != null))

      if(singleCirclesCount > 0){
        for (let index = 0; index < (maxCircles); index++) {
          circles.push(this.getRoundedFlowColumn(singleCircles[index % singleCirclesCount]))
        }
      }
      
      
      sessionInfos.push({columns: [{columns: [{text: 'Ablauf:', width: 'auto', bold: true, fontSize: 10, margin: [0, 3, 10, 0]}, ...sessionFlowColumns]}]})
      sessionInfos.push({columns: [{columns: circles, margin: [0, 5, 0, 0], alignment: "left", width: "auto", fontSize: 10}]})
    }



    for (let i = 0; i < plan.sessions.length; i++) {
      const session = plan.sessions[i];
      this.exportProgress?.next(`${((i / plan.sessions.length) * 100).toFixed()}%`)
      if (!session.deleted && !session.hide) {
        const sessionResult = await this.getSession(session, languageCode, numberOfFilloutSessions, trainingPlan, cardioZoneGroups);
        const sessionRow = sessionResult.contentTable;
        const fillOutContent = sessionResult.fillOutContent;

        if(fillOutContent.length > 0){
          fillOutSessions.push({text: session.name, bold: true, fontSize: 12, margin: [0, 20, 0, 0]})

          if(plan.isPeriodicPlan){
            if(session.plannedDate){
              if(plan.isTemplate){
                let dayNumber = (session?.plannedDate?.getDay() + 4) % 7;
                let date = NumberToShortWeekDayStringsMapping[dayNumber]
                let weekIndex = plan.weeks.findIndex(x => x.id == session.weekId);
                if(weekIndex != -1){
                  date += `, ${weekIndex + 1}. Woche`
                }
                fillOutSessions.push({text: date, bold: true, fontSize: 6, margin: [0, 0, 0, 0], color: 'grey'})
              }
              else {
                let weekDayString = NumberToShortWeekDayStringsMapping[session?.plannedDate?.getDay()]
                let date = `${weekDayString}, ${session.plannedDate.asFormatedString()}`
                fillOutSessions.push({text: date, bold: true, fontSize: 6, margin: [0, 0, 0, 0], color: 'grey'})
              }
            }
            else {
              fillOutSessions.push({text: "Frei absolvierbar", bold: true, fontSize: 6, margin: [0, 0, 0, 0], color: 'grey'})
            }
          }

          if(i >= (plan.sessions.length - 1) && !displayExericseDetails){
            fillOutSessions.push({stack: fillOutContent});
          }
          else {
            fillOutSessions.push({stack: fillOutContent, pageBreak: 'after'});
          }
        }
        
        if (!session.isRestDay && sessionRow.length > 0) {
          const body = sessionRow.filter(x => x.length > 0);
          sessionInfos.push({text: session.name, bold: true, fontSize: 12, margin: [0, 20, 0, 0] })
          if(body.length > 0){
            const sessionsTable = {
              table: {
                headerRows: 0,
                widths: ['auto', 100, '*'],
                body: body,
                unbreakable: true,
              },
              layout: {
                hLineWidth: function (i, node) {
                  return 0;
                },
                vLineWidth: function (i, node) {
                  return 4;
                }
              },
              margin: [0, 10, 0, 0],
            };
            sessionInfos.push(sessionsTable)
          }
        }
      }
    }

    if(displayTrackingSheet){
      for(let fillOutSession of fillOutSessions){
        docDefinition.content.push(fillOutSession);
      }
    }

    if(displayExericseDetails){
      for(let sessionInfo of sessionInfos){
        docDefinition.content.push(sessionInfo);
      }
    }

    let pdf = pdfMake.createPdf(docDefinition);    
    this.downloadPdf(pdf, (`${this.getFileName(plan.nameTranslation.GetValue(languageCode))}`) + ".pdf");

    return Promise.resolve(true)
  }


  private exerciseCount = 0;

  getSuperSetExerciseTableRows(trainingExercises: PlannedTrainingExercise[], setParameters: SetParameter[], numberOfSessions: number, trainingPlan: TrainingPlan, cardioZoneGroups: CardioZoneGroup[]) {
    // Finde die maximale Länge der inneren Arrays
    const maxLength = Math.max(...trainingExercises.map(arr => arr.sets.length));
    // Ergebnis-Array initialisieren
    const rows = [];
    // Iteriere durch die Indizes 0 bis zur maximalen Länge
    for (let i = 0; i < maxLength; i++) {
      // Iteriere durch die äußeren Arrays
      for (let index = 0; index < trainingExercises.length; index++) {
        const exercise = trainingExercises[index];
        // Füge das Element zum Ergebnis hinzu, wenn es existiert
        if (i < exercise?.sets?.length) {
          
          let row = []
          rows.push(row);
          const set = exercise.sets[i];
          row.push({text: `${set?.isWarmupSet ? 'W' : set?.isDropset ? 'D' : i+1}.${(this.exerciseCount - trainingExercises.length + index + 1)}`, color: 'black', alignment: 'center'})

          for (let index = 0; index < numberOfSessions; index++) {
            let setColumns = []
            for(let setParameter of setParameters){
              if(exercise.setParameters.includes(setParameter)){
                let cardioZoneGroup = TrainingPlanEditorComponent.getUserCardioZoneGroupByExerciseId(exercise.exerciseId, setParameter, cardioZoneGroups);
                let printValue = this.getSetPrintValue(set, setParameter, trainingPlan, cardioZoneGroup?.zones);
                setColumns.push({text: printValue, color: 'grey', alignment: 'center'});
              }
              else {
                setColumns.push({text: "-", color: 'grey', alignment: 'center'});
  
              }
            }
            
            row.push({columns: setColumns});
          }

        }
      }
    }
  
    return rows;
  }

  getSuperSetFillOutTable(superSet: SuperSet, languageCode: string, numberOfSessions: number, trainingPlan: TrainingPlan, cardioZoneGroups: CardioZoneGroup[]){
    let body = []
    let widths: string[] = []
    const emptyHeader = [];
    let header = []

    emptyHeader.push({text: 'Training', alignment: 'center', fontSize: 6, color: 'grey'});
    
    header.push({text: "Satz", alignment: 'center', noWrap: true});
    widths.push('auto')
    let setParameters: SetParameter[] = []
    for (let index = 0; index < numberOfSessions; index++) {
      widths.push('*')
      let subHeader = []
      setParameters = []
      for(let setParameter of superSet.exercises?.filter(x => !x.deleted)?.map(x => x.setParameters)?.flat()){
        if(!setParameters.includes(setParameter)){
          setParameters.push(setParameter)
          subHeader.push({text: this.setParameter2LabelMapping[setParameter], alignment: 'center', noWrap: true});
        }
      }
      emptyHeader.push({text: 'am: ', alignment: 'left', fontSize: 6, color: 'grey'});
      header.push({columns: subHeader});
    }
    body.push(emptyHeader)
    body.push(header)

    
    let result = this.getSuperSetExerciseTableRows(superSet.exercises?.filter(x => !x.deleted && x.exerciseId), setParameters, numberOfSessions, trainingPlan, cardioZoneGroups)
    body.push(...result);


    let numberOfFooterRows = 2;
    for (let i = 0; i < numberOfFooterRows; i++) {
      let footer = [{ text: ' ', margin: [0, 0, 0, 0], border: [true, false, false, (i == numberOfFooterRows - 1)], borderColor: ['grey', 'grey', 'grey', 'grey']}]
      for (let index = 0; index < numberOfSessions; index++) {
        footer.push({ text: ' ', margin: [0, 0, 0, 0], border: [false, false, (index == numberOfSessions - 1), (i == numberOfFooterRows - 1)], borderColor: ['grey', 'grey', 'grey', 'grey']})
      }
      body.push(footer);
    }

    
    const setTable = {
      width: 'auto',
      table: {
        headerRows: 1,
        body: body,
        widths: widths,
      },
      layout: {
        hLineWidth: (i, node) => 0.5,
        vLineWidth: (i, node) => 0.5,
      },
      margin: [0, 10, 0, 0],
      alignment: 'left',
      fontSize: 6,
      unbreakable: true,
    };

    return setTable;
  }

  getSuperSetFillOutColumn(superSet: SuperSet, mergedExercises: MergedTrainingExercise[], imageDataURLs: string[], leftBorderColor: string, languageCode: string, numberOfSessions: number, trainingPlan: TrainingPlan, cardioZoneGroups: CardioZoneGroup[]){
    let stackColumnRows = [] 
    let plannedExercises = superSet.exercises.filter(x => !x.deleted && x.exerciseId);

    let notes = [];
    for (let i = 0; i < plannedExercises.length; i++) {
      const imageDataURL = imageDataURLs[i];
      const mergedExercise = mergedExercises[i];
      const plannedExercise = plannedExercises[i];
      const exerciseNumber = (this.exerciseCount - mergedExercises.length + i + 1);

      if(i > 0){
        stackColumnRows.push([{text: "+", bold: true, fontSize: 18, margin: [10, 0, 10, 0], borderColor: [leftBorderColor, 'white', 'white', 'white']}])
      }
      let stack = [];
      if(plannedExercise?.note){
        notes.push({columns: [{text: `Hinweis Übung ${exerciseNumber}:`, bold: true, fontSize: 6, margin: [0, 0, 2, 0], width: "auto"}, {text: plannedExercise.note, bold: false, fontSize: 6, margin: [0, 0, 0, 0], width: "auto"}]});
      }
      
      if(plannedExercise?.groupHeadingTranslation != null){
        stackColumnRows.push([{text: plannedExercise.groupHeadingTranslation.GetValue(languageCode) || "", bold: true, fontSize: 8, margin: [0, 0, 0, 0], borderColor: [leftBorderColor, 'white', 'white', 'white']}])
      }
      stack.push(...[{ text: "Übung " + exerciseNumber, bold: true, fontSize: 6, margin: [0, 0, 0, 0] },
      this.getImageColumn(imageDataURL, [leftBorderColor, 'white', 'white', 'white']),
      { text: mergedExercise.name.GetValue(languageCode), bold: false, fontSize: 8, margin: [0, 0, 0, 0] },
      { text: mergedExercise.subName.GetValue(languageCode), bold: false, fontSize: 6, margin: [0, 0, 0, 0] }]);
      
      if(plannedExercise.pauseDuration){
        stack.push({text: `Pause: ${plannedExercise.pauseDuration} s`, bold: false, fontSize: 6, margin: [0, 2, 0, 0] })
      }

      stackColumnRows.push([{
        stack: stack,
        borderColor: [leftBorderColor, 'white', 'white', 'white']
      }]);
      
    }

    
    let body = [[{text: ""}]]
    if (stackColumnRows.length > 0) {
      body = stackColumnRows.filter(x => x.length > 0);
    }
    const sessionsTable = {
      table: {
        headerRows: 0,
        widths: ['*'],
        body: body,
        unbreakable: true,
      },
      layout: {
        hLineWidth: function (i, node) {
          return 0;
        },
        vLineWidth: function (i, node) {
          return 4;
        }
      },
      margin: [0, 10, 0, 0],
      width: '*'
    };
    
    let superSetConfig = plannedExercises[0]?.superSetConfig;
    let stack: any = [{text: superSetConfig.name || "Supersatz", bold: true, fontSize: 8, margin: [0, 0, 0, 0]}];
    if(superSetConfig?.numberOfRounds == NumberOfRounds.AMRAP && superSetConfig.totalAvailableTime){
      stack.push({text: `AMRAP (${superSetConfig.totalAvailableTime.asAutomaticShortDurationString()})`, fontSize: 8} )
    }
    stack.push(sessionsTable)
    let stackColumn = {
      stack: stack,
      width: '15%'
    }

    let tableColumn: any[] = [this.getSuperSetFillOutTable(superSet, languageCode, numberOfSessions, trainingPlan, cardioZoneGroups)];
    
    if(notes.length > 0){
      tableColumn.push(...notes)
    }
    if(superSetConfig?.numberOfRounds == NumberOfRounds.AMRAP){
      tableColumn.push({ text: "Absolvierte Runden:", bold: true, margin: [0, 5, 0, 0]})
    }
    return { columns: [stackColumn, { stack:  tableColumn}], width: '*', margin: [0, 10, 20, 0] };
  }

  async getSession(session: TrainingSession, languageCode: string, numberOfSessions: number, trainingPlan: TrainingPlan, cardioZoneGroups: CardioZoneGroup[]): Promise<any>{
    let content = [[]]
    let fillOutColumns = []
    let fillOutRows = []

    for (let superSet of session.superSets) {
      let isSuperSet = superSet.exercises.filter(x => !x.deleted).length > 1;

      var imageDataURLs: any[] = [];
      var mergedExercises: MergedTrainingExercise[] = [];
      let leftBorderColor = isSuperSet ? this.accentColor : 'white';
      
      for (let index = 0; index < superSet.exercises.length; index++) {
        let exercise = superSet.exercises[index];
        let columns = [];
        let mergedExercise = this.getExerciseById(exercise.exerciseId);
        if (!exercise.deleted && mergedExercise) {
          this.exerciseCount++;

          if(index > 0){
            content.push([{text: "+", bold: true, fontSize: 18, margin: [10, 0, 10, 0], borderColor: [leftBorderColor, 'white', 'white', 'white']}, {text: "", margin: [0, 5, 0, 5], borderColor: [leftBorderColor, 'white', 'white', 'white']}, {text: "", margin: [0, 5, 0, 5], borderColor: [leftBorderColor, 'white', 'white', 'white']}])
          }
          let imageDataURL = await this.getExerciseImage(mergedExercise)

          imageDataURLs.push(imageDataURL);
          mergedExercises.push(mergedExercise);

          let compiledMarkdown = this.compileMarkdown(mergedExercise.instructions?.GetValue(languageCode) || "");
          let parsed = htmlToPdfmake(compiledMarkdown, {defaultStyles:{
            b: {bold:true},
            strong: {bold:true},
            u: {decoration:'underline'},
            s: {decoration: 'lineThrough'},
            em: {italics:true},
            i: {italics:true},
            h1: {fontSize:24, bold:true, marginBottom:5},
            h2: {fontSize:22, bold:true, marginBottom:5},
            h3: {fontSize:20, bold:true, marginBottom:5},
            h4: {fontSize:18, bold:true, marginBottom:5},
            h5: {fontSize:16, bold:true, marginBottom:5},
            h6: {fontSize:14, bold:true, marginBottom:5},
            a: {color:'blue', decoration:'underline'},
            strike: {decoration: 'lineThrough'},
            p: {margin:[0, 0, 0, 0]},
            ul: {marginBottom:5},
            li: {marginLeft:5},
            table: {marginBottom:5},
            th: {bold:true, fillColor:'#EEEEEE'}
          }});

          columns.push(this.getImageColumn(imageDataURL, [leftBorderColor, 'white', 'white', 'white']));
          columns.push(this.getExerciseInfoColumn(mergedExercise, exercise, languageCode, this.exerciseCount));
          
          let parsedStack = [parsed];
          
          if(exercise.note){
            parsedStack.push({columns: [{text: "Hinweis:", bold: true, fontSize: 6, margin: [0, 0, 2, 0], width: "auto"}, {text: exercise.note, bold: false, fontSize: 6, margin: [0, 0, 0, 0], width: "auto"}], margin: [0, 3, 0, 0]})
          }
          
          columns.push({
            stack: [
              { text: "Anweisungen", bold: true, fontSize: 6, margin: [0, 0, 0, 0] },
              { stack: parsedStack},
            ],
            borderColor: ['white', 'white', 'white', 'white'],
            fillColor: "white",
            fillOpacity: 1
          });
          
          if(exercise.groupHeadingTranslation != null){
            content.push([{text: exercise.groupHeadingTranslation.GetValue(languageCode) || "", bold: true, fontSize: 8, margin: [0, 0, 0, 0], colSpan:3, borderColor: [index > 0 ? leftBorderColor : 'black', 'white', 'white', 'white']}, {}, {}]);
          }

          if(!isSuperSet){
            let fillOutColumn = this.getFillOutColumn(imageDataURL, leftBorderColor, mergedExercise, languageCode, exercise, numberOfSessions, trainingPlan, cardioZoneGroups);
            fillOutRows.push(fillOutColumn);
          }
        }
      
        
        if (columns.length > 0){
          content.push(columns);
        }
      }
      if(content.length > 0){
        content.push([{text: "", margin: [0, 10, 0, 10], borderColor: ['white', 'white', 'white', 'white']}, {text: "", margin: [0, 10, 0, 10], borderColor: ['white', 'white', 'white', 'white']}, {text: "", margin: [0, 10, 0, 10], borderColor: ['white', 'white', 'white', 'white']}])
      }
      if(isSuperSet){
        var superSetFillOutTable = this.getSuperSetFillOutColumn(superSet, mergedExercises, imageDataURLs, leftBorderColor, languageCode, numberOfSessions, trainingPlan, cardioZoneGroups);
        fillOutRows.push(superSetFillOutTable);
      }
    }

    return {contentTable: content, fillOutContent: fillOutRows};
  }

  compileMarkdown(value: string): string {
    if (value == null) return ""
    return marked.parser(marked.lexer(value.replace(/\n/g, '<br>')));
  }


  private setParameter2LabelMapping = SetParameter2ShortLabelMapping;

  private getFillOutColumn(imageDataURL: any, leftBorderColor: string, mergedExercise: MergedTrainingExercise, languageCode: string, exercise: PlannedTrainingExercise, numberOfSessions: number, trainingPlan: TrainingPlan, cardioZoneGroups: CardioZoneGroup[]) {
    let imageStackRow = this.getImageColumn(imageDataURL, [leftBorderColor, 'white', 'white', 'white']);
    let stack = [];
    stack.push(...[
      { text: "Übung " + this.exerciseCount, bold: true, fontSize: 6, margin: [0, 0, 0, 0] },
      imageStackRow,
      { text: mergedExercise.name.GetValue(languageCode), bold: false, fontSize: 8, margin: [0, 0, 0, 0] },
      { text: mergedExercise.subName.GetValue(languageCode), bold: false, fontSize: 6, margin: [0, 0, 0, 0] },
    ]);
    if(exercise.pauseDuration){
      stack.push({text: `Pause: ${exercise.pauseDuration} s`, bold: false, fontSize: 6, margin: [0, 2, 0, 0] })
    }
    
    let stackColumn = {
      stack: stack, width: '15%',
    };

    let tableColumn: any[] = [this.getExerciseSetsTable(exercise, languageCode, numberOfSessions, trainingPlan, cardioZoneGroups)];
    if(exercise.note){
      tableColumn.push({columns: [{text: "Hinweis:", bold: true, fontSize: 6, margin: [0, 0, 2, 0], width: "auto"}, {text: exercise.note, bold: false, fontSize: 6, margin: [0, 0, 0, 0], width: "auto"}], margin: [0, 3, 0, 0], width: "*"})
    }
    
    let resultStack = []
    
    if(exercise.groupHeadingTranslation != null){
      resultStack.push({text: exercise.groupHeadingTranslation.GetValue(languageCode) || "", bold: true, fontSize: 8, margin: [0, 10, 0, 0]})
    }
    resultStack.push({ columns: [stackColumn, { stack: [...tableColumn] }], width: '*', margin: [0, 10, 20, 0], unbreakable: true})
    return resultStack;
  }

  getExerciseSetsTable(plannedExercise: PlannedTrainingExercise, languageCode: string, numberOfSessions: number, trainingPlan: TrainingPlan, cardioZoneGroups: CardioZoneGroup[]){
    const body = [];
    const widths = [];
    const emptyHeader = [];
    const header = [];

    emptyHeader.push({text: 'Training', alignment: 'center', fontSize: 6, color: 'grey'});
    header.push({ text: 'Satz', alignment: 'center', noWrap: true});
    widths.push('auto');
    for (let index = 0; index < numberOfSessions; index++) {
      const subHeader = [];
      widths.push('*');
      for(let setParameter of plannedExercise.setParameters){
        subHeader.push({
              text: this.setParameter2LabelMapping[setParameter],
              alignment: 'center',
              margin: [0, 0, 0, 0],
              noWrap: true,
            });
      }
      emptyHeader.push({text: 'am: ', alignment: 'left', fontSize: 6, color: 'grey'});
      header.push({ columns: subHeader, alignment: 'center', noWrap: true });
    }
    body.push(emptyHeader)
    body.push(header);

    let setNumberCount = 1;
    for (let i = 0; i < plannedExercise.sets.length; i++) {
      const set = plannedExercise.sets[i];
      let row = []
      let setNumber: string = setNumberCount.toFixed().toString();
      if(set.isDropset){
        setNumber = 'D'
      }
      else if(set.isWarmupSet){
        setNumber = 'W'
      }
      else {
        setNumberCount++;
      }
      row.push({ text: setNumber, color: 'black', alignment: 'center' })
      for (let index = 0; index < numberOfSessions; index++) {
        const setParameterColumns = plannedExercise.setParameters.map((setParameter) => {
          let cardioZones = TrainingPlanEditorComponent.getUserCardioZoneGroupByExerciseId(plannedExercise.exerciseId, setParameter, cardioZoneGroups)?.zones;
          let printValue = this.getSetPrintValue(set, setParameter, trainingPlan, cardioZones);
          return { text: printValue, color: 'grey', alignment: 'center' };
        });
        row.push({ columns: setParameterColumns, color: 'black', alignment: 'center' })
      }
      body.push(row);
    }

    //empty rows
    let numberOfFooterRows = 2;
    for (let i = 0; i < numberOfFooterRows; i++) {
      let row = [{ text: ' ', color: 'grey', margin: [10, 0, 10, 0], border: [true, false, false, (i == numberOfFooterRows - 1)], borderColor: ['grey', 'grey', 'grey', 'grey']}]
      for (let index = 0; index < numberOfSessions; index++) {
        row.push({ text: ' ', color: 'grey', margin: [10, 0, 10, 0], border: [false, false, (index == numberOfSessions - 1), (i == numberOfFooterRows - 1)], borderColor: ['grey', 'grey', 'grey', 'grey']})
      }
      body.push(row);
    }

    const setTable = {
      width: '*',
      table: {
        headerRows: 1,
        body: body,
        widths: widths,
      },
      layout: {
        hLineWidth: (i, node) => 0.5,
        vLineWidth: (i, node) => 0.5,
      },
      margin: [0, 0, 0, 0],
      alignment: 'left',
      fontSize: 6,
    };

    return setTable;
  }

  getSetPrintValue(set: TrainingSet, setParameter: SetParameter, trainingPlan: TrainingPlan, cardioZones: CardioZone[], exercise: PlannedTrainingExercise = null){
    let printValue: string = set[setParameter]?.toString() || "";
    if (setParameter === SetParameter.time) {
        printValue = set[setParameter]?.asAutomaticShortDurationString() || '00:00';
    }
    else if (setParameter === SetParameter.pace) {
      if(set[setParameter] != undefined){
        printValue = set[setParameter]?.asAutomaticShortDurationString() || '00:00';
      }
      else if(set.minPace != undefined && set.maxPace != undefined){
        printValue = `${set.minPace.asAutomaticShortDurationString()}-${set.maxPace.asAutomaticShortDurationString()}`;
      }
      else {
        printValue = TrainingPlanEditorComponent.getSetPace(set, setParameter, trainingPlan.trainingVariables, cardioZones);
      }
    }
    else if(setParameter === SetParameter.heartRate){
      if(set.heartRate != undefined){
        printValue = set.heartRate.toString();
      }
      else if(set.minHeartRate != undefined && set.maxHeartRate != undefined){
        printValue = `${set.minHeartRate}-${set.maxHeartRate}`;
      }
      else {
        printValue = TrainingPlanEditorComponent.getSetHeartRate(set, trainingPlan.trainingVariables, cardioZones);
      }
    }
    else if(setParameter === SetParameter.weight){
      if(set.weight != undefined){
        printValue = set.weight.toString();
      }
      else {
        printValue = TrainingPlanEditorComponent.getSetWeight(set, trainingPlan.trainingVariables, this.languageService.selectedUnitSystem, this.weightPipe);
      }
    }
    else if (setParameter === SetParameter.reps) {
      if (set.reps != undefined) {
        printValue = set.reps?.toString() || "";
      } else if (set.maxReps != undefined && set.minReps != undefined) {
        printValue = `${set.minReps}-${set.maxReps}`;
      }
    }

    return printValue;
  }
  
  getExerciseById(exerciseId: string):MergedTrainingExercise{
    return this.trainingService.getExerciseById(exerciseId);
  }

  getImageColumn(imageDataURL: any, borderColor: any){
    if(imageDataURL){
      return {image: imageDataURL, fit: [30, 30], borderColor: borderColor, fillColor: "white", fillOpacity: 1}
    }
    return {text: "", borderColor: ['white', 'white', 'white', 'white'], fillColor: "white", fillOpacity: 1, width: 30}
  }

  async getExerciseImage(mergedTrainingExercise: MergedTrainingExercise){
    if(!mergedTrainingExercise) return null;
    if(!mergedTrainingExercise.thumbnailDownloadURL){
      await this.trainingService.getExerciseThumbnailUrl(mergedTrainingExercise);
    }
    return await this.getDataURL(mergedTrainingExercise.thumbnailDownloadURL);
  }

  getExerciseInfoColumn(mergedTrainingExercise: MergedTrainingExercise, plannedTrainingExercise: PlannedTrainingExercise, languageCode: string, exerciseCount: number){
    let infoStack = []
    
    infoStack.push({text: "Übung " + exerciseCount, bold: true, fontSize: 6, margin: [0, 0, 0, 0]})
    infoStack.push({text: mergedTrainingExercise.name.GetValue(languageCode), bold: false, fontSize: 8, margin: [0, 0, 0, 0]})
    infoStack.push({text: mergedTrainingExercise.subName.GetValue(languageCode), bold: false, fontSize: 6, margin: [0, 0, 0, 0]})
    infoStack.push({text: "Pausendauer", bold: true, fontSize: 6, margin: [0, 10, 0, 0]})
    infoStack.push({text: `${plannedTrainingExercise.pauseDuration}s`, bold: false, fontSize: 8, margin: [0, 0, 0, 0]})
    
    
    return {stack: infoStack, alignment: "left", margin: [0, 0, 10, 0], borderColor: ['white', 'white', 'white', 'white'], fillColor: "white", fillOpacity: 1};
  }

  getRoundedCanvasWithText(text: string): string {

    // Create an HTMLCanvas element with a circle and text
    let canvas = document.createElement('canvas');
    canvas.width = 70;
    canvas.height = 70;
    let ctx = canvas.getContext('2d');

    // Draw a gray circle
    ctx.beginPath();
    ctx.arc(35, 35, 30, 0, 2 * Math.PI);
    ctx.fillStyle = '#F6F8FC';
    ctx.fill();
    // ctx.strokeStyle = '#F6F8FC';
    // ctx.stroke();

    // Add text to the canvas
    ctx.fillStyle = 'black';
    ctx.font = '25px Montserrat';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(text, 35, 35);

    // Convert the HTMLCanvas to a data URL
    return canvas.toDataURL();
  }
}
