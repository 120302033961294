import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OpenAIService } from 'src/app/services/openai.service';
import { DomSanitizer } from '@angular/platform-browser';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { Questionaire } from 'src/app/model/questionaires.model';
import { QuestionairesService } from 'src/app/services/questionaires.service';
import { ToastrService } from 'ngx-toastr';
import { Metric } from 'src/app/model/metric.model';
import { LanguageService } from 'src/app/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { TrainingVariableEditorDialogComponent, TrainingVariableEditorDialogType } from 'src/app/training/training-variable-editor-dialog/training-variable-editor-dialog.component';

@Component({
  selector: 'app-profile-settings-dialog',
  templateUrl: './profile-settings-dialog.component.html',
  styleUrls: ['./profile-settings-dialog.component.css']
})
export class ProfileSettingsDialogComponent {

  public user: User
  public userEmail: string
  public allQuestionaires: Questionaire[]
  public loading = true

  constructor(public dialogRef: MatDialogRef<ProfileSettingsDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { user: User }, public userService: FirestoreService, public questionaireService: QuestionairesService, private toastr: ToastrService, public languageService: LanguageService, public translate: TranslateService, public dialog: MatDialog) {
    this.user = data.user
    if (!this.userEmail) this.userService.getEmailForUser(this.user.uid).then(email => {
      this.userEmail = email
    })
  }

  async ngOnInit(): Promise<void> {
    this.allQuestionaires = await this.questionaireService.getQuestionaires()
    await this.userService.loadAllAssignedQuestionairesForUser(this.user)
    this.loading = false
  }

  onCompetitionDateChanged(date: Date) {
    this.user.nextCompetitionDate = date
    this.userService.updateLicenceSettings(this.user)
  }

  isNutritionEnabled() {
    if (this.user.nutritionEnabled != null) return this.user.nutritionEnabled
    return this.userService.getLoggedInUser().isNutritionEnabled()
  }
  onEnableNutritionChanged(value: boolean) {
    this.user.nutritionEnabled = value
    this.userService.updateLicenceSettings(this.user)
  }

  getDefaultExertionParameter() {
    if (this.user.defaultExertionParameter == 'rpe') return 'RPE'
    if (this.user.defaultExertionParameter == 'rir') return 'RIR'
    return null
  }
  onSelectDefaultExertionParameter(value: string) {
    this.user.defaultExertionParameter = value
    this.userService.updateLicenceSettings(this.user)
  }


  openTrainingVariablesDialog(){
    let trainingVariables = this.user.trainingVariables;
    let globalForeignVariabels = this.userService.getLoggedInUser()?.trainingSettingsLicenceHolder?.trainingVariables?.filter(x => !trainingVariables?.find(y => y?.id == x?.id));
    let dialogRef = this.dialog.open(TrainingVariableEditorDialogComponent, {width: '800px', data: { user: this.user, trainingVariables: trainingVariables?.map(x => x.clone()), trainingVariableEditorDialogType: TrainingVariableEditorDialogType.user, foreignVariables: globalForeignVariabels}});
    dialogRef.afterClosed().subscribe(async result => {
      if(result){
        try{
          await this.userService.updateTrainingVariables(this.user, result.trainingVariables);
        }
        catch(ex){
          console.error(ex);
        }
        finally{
        }
      }
    });
  }

  onCancelDialog() {
    this.dialogRef.close(null);
  }
}
