import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaymentMethodOption } from 'src/app/model/payment-settings.model';

@Component({
  selector: 'app-paymentmethod-selection',
  templateUrl: './paymentmethod-selection.component.html',
  styleUrls: ['./paymentmethod-selection.component.css']
})
export class PaymentmethodSelectionComponent {

  public selectedPaymentMethod: PaymentMethodOption
  @Input() paymentMethods: PaymentMethodOption[]
  @Output() confirmPaymentMethod: EventEmitter<PaymentMethodOption> = new EventEmitter<PaymentMethodOption>()

  onSelectPaymentMethod(paymentMethod: PaymentMethodOption) {
    this.selectedPaymentMethod = paymentMethod
  }

  onConfirmPaymentMethod() {
    this.confirmPaymentMethod.emit(this.selectedPaymentMethod)
  }
}
