import { TranslateService } from "@ngx-translate/core";
import { PaymentSettings } from "./payment-settings.model";

export class CustomerPaymentSettings {
    
    defaultPaymentMethods: PaymentMethod[]

    constructor();
    constructor(init: CustomerPaymentSettings);
    constructor(init?: CustomerPaymentSettings) {
        if (init && init.defaultPaymentMethods) {
            this.defaultPaymentMethods = Object.keys(init.defaultPaymentMethods as any as Map<string, any>).map(key => {
                var pm = new PaymentMethod(init.defaultPaymentMethods[key]);
                pm.licenceHolderUid = key
                return pm
            })
        } else {
            this.defaultPaymentMethods = []
        }
    }
}

export class PaymentMethod {
    stripePaymentMethodId: string
    paymentMethodType: string
    licenceHolderUid: string

    // Tmp:
    licenceHolderName: string
    paymentSettings: PaymentSettings

    constructor();
    constructor(init: PaymentMethod);
    constructor(init?: PaymentMethod) {
        this.paymentMethodType = init && init.paymentMethodType || null
        this.stripePaymentMethodId = init && init.stripePaymentMethodId || null
    }

    getPrintablePaymentMethod(translate: TranslateService) {
        if (!this.paymentMethodType) return ''
        switch (this.paymentMethodType) {
            case 'card':    return translate.instant('Kreditkarte');
            case 'sepa_debit':    return translate.instant('SEPA-Lastschrift');
            case 'bank_transfer':   return translate.instant('Banküberweisung');
            case 'klarna':   return translate.instant('Klarna');
            default: return this.paymentMethodType;
        }
    }
}