import { TrainingHistoryDialogComponent, TrainingSessionContainer } from './../training-history-dialog/training-history-dialog.component';
import { TrackedTrainingSession, TrackedTrainingExercise, TrackedSuperSet } from './../../model/training-monitoring.model';
import { User } from './../../model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PlannedTrainingExercise, TrainingSession, TrainingPlan } from './../../model/training-plan.model';
import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { QuestionaireResult } from 'src/app/model/questionaires.model';
import { CalendarItem, SingleCalendarDay } from 'src/app/monthly-calendar/monthly-calendar.component';
import { LanguageService } from 'src/app/services/language.service';
import { TrainingPlanEditorComponent } from 'src/app/training/training-plan-editor/training-plan-editor.component';
import { Activity } from 'src/app/model/activity.model';
import { ActivityHistoryDialogComponent } from '../activity-history-dialog/activity-history-dialog.component';
import { ActivityEditorComponent } from 'src/app/activity-editor/activity-editor.component';

@Component({
  selector: 'app-training-history',
  templateUrl: './training-history.component.html',
  styleUrls: ['./training-history.component.css']
})
export class TrainingHistoryComponent implements OnInit {

  private displayNumberOfDays: number = 27

  // private startDate: Date;
  // private endDate: Date;
  public user: User

  @Input() set User(value: User) {
    this.user = value
    if (value != null) {
      this.init()
    }
  }

  public calendarItems: CalendarItem<TrackedTrainingSession | Activity>[] = [];
  private currentMonth: Date = null;
  public selectedCalendarDay: Date = null;

  public visibleTrackedTrainingSessions: TrackedTrainingSession[] = [];
  public trainingHistoryDataList: TrainingHistoryData[] = [];
  public selectedTrainingHistoryData: TrainingHistoryData = null;

  public selectedTrackedTrainingSessions: TrackedTrainingSession[] = [];
  public isBusy: boolean = false

  public endDate: Date = null;

  private accentColor = getComputedStyle(document.documentElement).getPropertyValue('--accentColor') || '#4AE6E6';

  constructor(public userService: FirestoreService, public dialog: MatDialog, private spinner: NgxSpinnerService, private languageService: LanguageService) { }

  ngOnInit(): void {
  }

  init() {
    this.currentMonth = new Date()
    this.currentMonth.setDate(1)
    this.currentMonth.setHours(0, 0, 0, 0);
    let endDate = new Date();
    endDate.setDate(1);
    endDate.setHours(0, 0, 0, 0);
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.addDays(-1);
    this.endDate = endDate;

    this.trainingHistoryDataList = []
    this.setCalendarItems();
  }

  removeTime(date = new Date()) {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }

  getDisplayedMonth() {
    if (!this.trainingHistoryDataList || this.trainingHistoryDataList.length < 15) return ''
    return this.trainingHistoryDataList[14].date.getPrintableMonth()
  }


  onSelectedCalendarMonthChanged(date: Date) {
    if (!date.isSameDate(this.currentMonth)) {
      this.currentMonth = date?.clone()
      this.setCalendarItems()
    }
  }

  onSelectedDayChanged(day: SingleCalendarDay<TrackedTrainingSession>) {
    if (day?.date) {
      this.selectedTrainingHistoryData = this.trainingHistoryDataList.find(x => x.date.isSameDate(day.date))
    }
  }

  async setCalendarItems() {
    try {
      if (!this.currentMonth) {
        this.currentMonth = new Date()
        this.currentMonth.setDate(1)
      }
      else {
        this.currentMonth.setDate(1)
        this.currentMonth.setHours(0, 0, 0, 0);
      }
      let startDate = this.currentMonth.clone()
      let endDate = this.currentMonth.clone();
      endDate.setMonth(endDate.getMonth() + 1);


      this.isBusy = true;
      let trackedSessions = await this.userService.getTrackedTrainingSessionsWithExercises(this.user, startDate, endDate);
      let trackedActivities = await this.userService.getAwaitableAllActivitiesByDateRange(this.user, startDate, endDate);
      this.calendarItems = []
      for (let i = new Date(startDate); i <= endDate; i = i.addDays(1)) {
        let trackedTrainingSessions = trackedSessions.filter(x => x?.startDate?.isSameDate(i))
        let trackedDayAcitivities = trackedActivities.filter(x => !x.trackedSessionId && x.activityFactId != ActivityEditorComponent.dailyActivityFactId && x.date?.isSameDate(i))
        if (trackedTrainingSessions.length > 0 || trackedDayAcitivities.length > 0) {
          this.trainingHistoryDataList.push(new TrainingHistoryData(i.clone(), trackedTrainingSessions, trackedDayAcitivities))
          for (let trackedTrainingSession of trackedTrainingSessions) {
            let plannedSession = this.getPlannedSession(trackedTrainingSession);
            let title = trackedTrainingSession.sessionName ?? plannedSession?.nameTranslation?.GetValue(this.languageService.selectedLanguageCode) ?? null;
            if (!title) {
              if (trackedTrainingSession.custom) {
                title = 'Eigene Einheit'
              }
            }
            let plannedSessions = this.user.trainingPlans?.find(x => x.id == trackedTrainingSession.trainingPlanId)?.sessions;
            let color = (plannedSessions?.length > 0) ? TrainingPlanEditorComponent.getBaseSessionReferenceColor(plannedSession, plannedSessions) ?? this.accentColor : this.accentColor;
            this.calendarItems.push(new CalendarItem<TrackedTrainingSession>(i.clone(), title, color, trackedTrainingSession))
          }

          for (let trackedActivity of trackedDayAcitivities) {
            let title = trackedActivity.name ?? null;
            if (!title) {
              title = 'Aktivität'
            }
            if(this.calendarItems.find(x => x.date.getTime() == i.getTime() && x.title == title) == null){
              this.calendarItems.push(new CalendarItem(i.clone(), title, null, trackedActivity))
            }
          }
        }
      }
      if (this.selectedTrainingHistoryData && this.selectedTrainingHistoryData.date?.getMonth() == this.currentMonth.getMonth() && this.selectedTrainingHistoryData.date?.getFullYear() == this.currentMonth.getFullYear()) {
        this.selectedTrainingHistoryData = this.trainingHistoryDataList?.find(x => x.date.isSameDate(this.selectedTrainingHistoryData.date))
        this.selectedCalendarDay = this.selectedTrainingHistoryData?.date
      }
      else {
        this.selectedCalendarDay = startDate.clone();
        let today = new Date();
        if (this.selectedCalendarDay.getMonth() == today.getMonth() && this.selectedCalendarDay.getFullYear() == today.getFullYear()) {
          this.selectedCalendarDay = today;
        }
        this.selectedTrainingHistoryData = this.trainingHistoryDataList?.find(x => x.date?.isSameDate(this.selectedCalendarDay))
      }
    }
    catch (ex) {
      console.error(ex)
    }
    finally {
      this.isBusy = false;
    }
  }

  getPlannedSession(trackedTrainingSession: TrackedTrainingSession): TrainingSession {
    var session = this.user.trainingPlans.filter(x => x.id == trackedTrainingSession.trainingPlanId)[0]?.sessions?.filter(x => x.id == trackedTrainingSession.plannedSessionId)[0]
    return session
  }

  onTrainingHistorySelectionChanged(trackedTrainingSession: TrackedTrainingSession) {
    this.selectedTrainingHistoryData = this.trainingHistoryDataList?.find(x => x.date.isSameDate(trackedTrainingSession.startDate));
  }

  onOpenTrackedTrainingSession(trackedTrainingSession: TrackedTrainingSession) {
    let questionaireResults = this.user.questionaireResults?.filter(x => x.assignedQuestionaire?.trackedSessionId == trackedTrainingSession?.id);
    
    let allAvailableSessionContainers: TrainingSessionContainer[] = [];
    let allAvailableTrackedSessions = this.calendarItems.filter(x => x.item instanceof TrackedTrainingSession).map(x => x.item as TrackedTrainingSession);
    for (let trackedTrainingSession of allAvailableTrackedSessions) {
      let trainingSessionContainer = new TrainingSessionContainer();
      let questionaireResults = this.user.questionaireResults?.filter(x => x.assignedQuestionaire?.trackedSessionId == trackedTrainingSession?.id);
      trainingSessionContainer.questionaireResults = questionaireResults;
      trainingSessionContainer.trackedTrainingSession = trackedTrainingSession;
      trainingSessionContainer.plannedTrainingSession = this.getPlannedSession(trackedTrainingSession);
      trainingSessionContainer.initSuperSets();
      allAvailableSessionContainers.push(trainingSessionContainer);
    }
    const dialogRef = this.dialog.open(TrainingHistoryDialogComponent, { data: { user: this.user, selectedTrackedTrainingSession: trackedTrainingSession, selectedPlannedTrainingSession: this.getPlannedSession(trackedTrainingSession), questionaireResults: questionaireResults, allAvailableTraingingSessionContainers: allAvailableSessionContainers } })
  }

  onOpenTrackedActivity(activity: Activity) {
    if (this.user.spikeUserId || activity.heartRate) {
      const dialogRef = this.dialog.open(ActivityHistoryDialogComponent, { data: { user: this.user, activity: activity }, width: '1000px' })
    }
  }

  onOpenSelectedTrackedTraininsessions() {
    if (this.selectedTrackedTrainingSessions?.length > 0) {
      let trainingSessionContainerList: TrainingSessionContainer[] = [];
      for (let trackedTrainingSession of this.selectedTrackedTrainingSessions) {
        let trainingSessionContainer = new TrainingSessionContainer();
        let questionaireResults = this.user.questionaireResults?.filter(x => x.assignedQuestionaire?.trackedSessionId == trackedTrainingSession?.id);
        trainingSessionContainer.questionaireResults = questionaireResults;
        trainingSessionContainer.trackedTrainingSession = trackedTrainingSession;
        trainingSessionContainer.plannedTrainingSession = this.getPlannedSession(trackedTrainingSession);
        trainingSessionContainer.initSuperSets();
        trainingSessionContainerList.push(trainingSessionContainer);
      }
      let allAvailableSessionContainers: TrainingSessionContainer[] = [];
      let allAvailableTrackedSessions = this.calendarItems.filter(x => x.item instanceof TrackedTrainingSession).map(x => x.item as TrackedTrainingSession);
      for (let trackedTrainingSession of allAvailableTrackedSessions) {
        let trainingSessionContainer = new TrainingSessionContainer();
        let questionaireResults = this.user.questionaireResults?.filter(x => x.assignedQuestionaire?.trackedSessionId == trackedTrainingSession?.id);
        trainingSessionContainer.questionaireResults = questionaireResults;
        trainingSessionContainer.trackedTrainingSession = trackedTrainingSession;
        trainingSessionContainer.plannedTrainingSession = this.getPlannedSession(trackedTrainingSession);
        trainingSessionContainer.initSuperSets();
        allAvailableSessionContainers.push(trainingSessionContainer);
      }
      const dialogRef = this.dialog.open(TrainingHistoryDialogComponent, { data: { user: this.user, trainingSessionContainerList: trainingSessionContainerList, allAvailableTraingingSessionContainers: allAvailableSessionContainers } });
    }
  }

  onTrackedTrainingSessionSelected(trackedTrainingSession: TrackedTrainingSession) {
    if (this.selectedTrackedTrainingSessions.filter(x => x.id == trackedTrainingSession.id).length > 0) {
      this.selectedTrackedTrainingSessions = this.selectedTrackedTrainingSessions.filter(x => x.id != trackedTrainingSession.id)
    } else {
      this.selectedTrackedTrainingSessions.push(trackedTrainingSession)
    }
  }

  getNumberOfSets(trackedTrainingSession: TrackedTrainingSession): number {
    let returnNumberOfSets: number = 0
    trackedTrainingSession.trackedTrainingExercises?.forEach(exercise => {
      if (exercise?.trackedSets) returnNumberOfSets += exercise.trackedSets.length
    });
    return returnNumberOfSets
  }

}

export class TrainingHistoryData {
  public date: Date
  public TrackedTrainingSessions: TrackedTrainingSession[] = [];
  public TrackedActivities: Activity[] = [];

  constructor(date: Date, trackedTrainingSessions: TrackedTrainingSession[], trackedActivities: Activity[]) {
    this.date = date
    this.TrackedTrainingSessions = trackedTrainingSessions.sort(x => x.startDate.getTime())
    this.TrackedActivities = trackedActivities.sort(x => x.date.getTime())
  }
}
