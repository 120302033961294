import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductPurchase } from 'src/app/model/product-purchase.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PaymentService } from 'src/app/services/payment.service';
import { Payment } from 'src/app/model/payment.model';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-paymentdetails-dialog',
  templateUrl: './paymentdetails-dialog.component.html',
  styleUrls: ['./paymentdetails-dialog.component.css']
})
export class PaymentDetailsDialogComponent {

  payment: Payment

  constructor(public dialogRef: MatDialogRef<PaymentDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {payment: Payment}, public userService: FirestoreService, private spinner: NgxSpinnerService, private dialog: MatDialog, private paymentService: PaymentService, private toastr: ToastrService, public translate: TranslateService) {
    this.payment = data.payment
    console.log(this.payment)
  }

  onRefundPayment() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: this.translate.instant('Möchtest du den Betrag wirklich rückerstatten?'), title: this.translate.instant('Betrag erstatten') },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show()
        this.userService.refundPayment(this.payment, this.paymentService.stripeAccountId).then((res) => {
          this.spinner.hide()
          if (res.success) {
            this.toastr.success('Betrag erfolgreich erstattet')
          } else if (res.error) {
            if (res.error == 'Insufficient funds') {
              this.toastr.error('Nicht genügend Guthaben auf dem Stripe-Konto')
            }
          }
        })
      }
    });
  }
  onVoidPayment() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: this.translate.instant('Möchtest du die Rechnung wirklich stornieren?'), title: this.translate.instant('Rechnung stornieren') },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show()
        this.userService.voidPayment(this.payment, this.paymentService.stripeAccountId).then(() => {
          this.spinner.hide()
        })
      }
    });
  }
  onReChargePayment() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: this.translate.instant('Möchtest du die Zahlung wirklich erneut einziehen?'), title: this.translate.instant('Zahlung erneut einziehen') },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show()
        this.userService.reChargePayment(this.payment, this.paymentService.stripeAccountId).then(() => {
          this.spinner.hide()
        })
      }
    });
  }
  onRecreateInvoice() {
    this.spinner.show()
    this.userService.recreateInvoicePdf(this.payment).then(() => {
      this.spinner.hide()
    })
  }
  onMarkPaymentAsPaid() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: this.translate.instant('Möchtest du die Zahlung wirklich als bezahlt markieren?'), title: this.translate.instant('Zahlung als bezahlt markieren') },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show()
        this.userService.markPaymentAsPaid(this.payment, this.paymentService.stripeAccountId).then(() => {
          this.spinner.hide()
        })
      }
    });
  }
  onRecreateCancellationInvoice() {
    console.log("Recreate cancellation invoice PDF")
    this.spinner.show()
    this.userService.recreateCancellationInvoicePdf(this.payment).then(() => {
      this.spinner.hide()
    })
  }
  onResendPaymentInstructions() {
    console.log("Resend payment instructions")
    this.spinner.show()
    this.userService.resendPaymentInstructions(this.payment, this.paymentService.stripeAccountId).then((res) => {
      this.spinner.hide()
      if (res.success) {
        this.toastr.success(this.translate.instant('Email erfolgreich versendet'))
      } else {
        this.toastr.error(this.translate.instant('Fehler beim Versenden der Email'))
      }
    })
  }

  onOpenInvoice() {
    this.spinner.show()
    this.userService.getDownloadUrl('payments/' + this.payment.id + '/' + (this.payment.invoiceFileName ?? (this.translate.instant('Rechnung') + '_' + this.payment.id + '.pdf'))).then(url => {
      this.spinner.hide()
      window.open(url, "_blank");
    })
  }

  onOpenCancellationInvoice() {
    this.spinner.show()
    this.userService.getDownloadUrl('payments/' + this.payment.id + '/' + (this.payment.cancellationInvoiceFileName ?? (this.translate.instant('Rechnung') + '_' + this.payment.id + '.pdf'))).then(url => {
      this.spinner.hide()
      window.open(url, "_blank");
    })
  }

  onCloseDialog() {
    this.closeDialog()
  }

  closeDialog() {
    this.dialogRef.close(null);
  }
}
