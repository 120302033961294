<div>
    <h3 class="display-inlineblock marginbottom-5" translate>Neuste Ereignisse</h3>
    <div *ngIf="utilityService.onSmallDisplay()" class="d-inline-block wrapper-moreSettings-selection dot-settings-button">
        <button class="light small margin-inbetween" type="button" id="dropdownMoreSettings" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="bi bi-three-dots-vertical"></i></button>
        <div class="dropdown-menu" aria-labelledby="dropdownMoreSettings">
            <button class="dropdown-item" type="button" (click)="eventLogService?.onMarkAllAsReadSelected()" translate>Als gelesen markieren</button>
        </div>
    </div>
    <button *ngIf="!utilityService.onSmallDisplay()" class="button-all-read marginbottom-5" type="button" (click)="eventLogService?.onMarkAllAsReadSelected()" translate>Als gelesen markieren</button>
</div>
<div class="margintop-10">
    <div class="display-inline wrapper-repetition-selection float-left marginright-10">
        <button class="button-filled light small dropdown-toggle" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{eventLogService?.getPrintableFilteredClients()}}</button>
        <div class="dropdown-menu dropdown-notificationTarget" aria-labelledby="dropdownNotificationTarget">
            <div *ngIf="eventLogService?.selectableGroupNames[0]?.uid == 'all'" class="dropdown-item link" (click)="eventLogService?.onFilterSelectionChangedGroup(eventLogService?.selectableGroupNames[0], !eventLogService?.selectableGroupNames[0].checked);$event.stopPropagation()">
                <input class="input checkbox" type="checkbox" [checked]="eventLogService?.selectableGroupNames[0].checked"><div class="display-inlineblock" translate>{{eventLogService?.selectableGroupNames[0].name}}</div>
            </div>
            <div class="dropdown-divider"></div>
            <div class="label marginleft-10" translate>Coaches</div>
            <div class="dropdown-item link" *ngFor="let group of eventLogService?.selectableCoachGroups" (click)="eventLogService?.onFilterSelectionChangedCoachGroup(group, !group.checked);$event.stopPropagation()">
                <input class="input checkbox" type="checkbox" [checked]="group.checked"><div class="display-inlineblock">{{group.name}}</div>
            </div>
            <div class="dropdown-divider"></div>
            <div class="label marginleft-10" translate>Gruppen</div>
            <ng-container *ngFor="let group of eventLogService?.selectableGroupNames">
                <div *ngIf="group.uid != 'all'" class="dropdown-item link" (click)="eventLogService?.onFilterSelectionChangedGroup(group, !group.checked);$event.stopPropagation()">
                    <input class="input checkbox" type="checkbox" [checked]="group.checked"><div class="display-inlineblock">{{group.name}}</div>
                </div>
            </ng-container>
            <!--div class="dropdown-divider"></div>
            <div class="dropdown-item link" *ngFor="let user of eventLogService?.selectableClients" (click)="eventLogService?.onFilterSelectionChangedUser(user, !user.checked);$event.stopPropagation()">
                <input class="input checkbox" type="checkbox" [checked]="user.checked"><div class="display-inlineblock">{{user.name}}</div>
            </div-->
        </div>
    </div>
    <!--div class="dropdown-button-spacer"></div-->
    <div class="display-inline wrapper-repetition-selection">
        <button class="button-filled light small dropdown-toggle" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div class="display-inlineblock">{{eventLogService?.getPrintableSelectedEventTypes() ?? ('Alle Ereignisse' | translate)}}</div>
            <div *ngIf="eventLogService?.getPrintableSelectedEventTypes()" class="indicator small marginleft-10"></div>
        </button>
        <div class="dropdown-menu dropdown-notificationTarget" aria-labelledby="dropdownNotificationTarget">
            <div class="dropdown-item link" (click)="eventLogService?.onAllEventTypesCheckedChanged();$event.stopPropagation()">
                <input class="input checkbox" type="checkbox" [checked]="eventLogService?.allEventTypesChecked"><div class="display-inlineblock" translate>Alle</div>
            </div>
            <div class="dropdown-divider"></div>
            <div class="dropdown-item link" *ngFor="let type of eventLogService?.selectableEventTypes" (click)="eventLogService?.onSelectedEventTypesChanged(type, !type.checked);$event.stopPropagation()">
                <input class="input checkbox" type="checkbox" [checked]="type.checked" [disabled]="eventLogService?.allEventTypesChecked"><div class="display-inlineblock" translate>{{eventLogService?.eventTypeString2LabelMapping[type.name]}}</div>
            </div>
        </div>
    </div>
    <!--div class="d-inline-block wrapper-moreSettings-selection">
        <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownMoreSettings" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Mehr...</button>
        <div class="dropdown-menu" aria-labelledby="dropdownMoreSettings">
            <button class="dropdown-item" type="button" (click)="onMarkAllAsReadSelected()">Alle Ereignisse als gelesen markieren</button>
        </div>
    </div-->
    <div class="spacer-5"></div>
</div>
<div class="row pagination-Buttons">
    <button class="button change-date"  (click)="eventLogService?.prevPage()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
        </svg>
    </button>
    <input [matDatepicker]="diaryDatePicker" class="visibility-hidden width-0 padding-0" (dateInput)="eventLogService?.onSelectedDateChanged($event.value?.toDate())">
    <mat-datepicker-toggle matSuffix [for]="diaryDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #diaryDatePicker></mat-datepicker>
    <div class="date-display">{{eventLogService?.weekDays[eventLogService?.selectedDate.getDay()].substr(0, 2)}}., {{eventLogService?.selectedDate.getDate()}}.{{eventLogService?.selectedDate.getMonth() + 1}}.{{eventLogService?.selectedDate.getFullYear()}}</div>
    <button class="button change-date d-inline-block" (click)="eventLogService?.nextPage()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
        </svg>
    </button>
</div>
<div class="event-log-list">
    <ng-container *ngFor="let event of eventLogService?.filteredEventLogs">
        <div class="layout-box sub-box container">
            <div class="link" (click)="eventLogService?.toggleEventItemsCollapse(event)">
                <div class="message-indication glyphicon" *ngIf="event?.read === false"></div>
                <b>{{eventLogService?.getEventText(event)}}</b>
                <div *ngIf="event?.eventItems && !event?.eventItemsExpanded" class="icon" id="dropdownClients">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </div>
                <div *ngIf="event?.eventItems && event?.eventItemsExpanded" class="icon" id="dropdownClients">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                    </svg>
                </div>
                <div class="icon-button button-visit-user" tooltip="{{getEventOpenText(event)}}" (click)="eventLogService?.routeToTarget(event)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/>
                        <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                    </svg>
                </div>
            </div>
            <div *ngIf="event?.eventItems && event?.eventItemsExpanded" class="expandable-event-items">
                <div *ngFor="let eventItem of event?.eventItems | keyvalue" class="event-item-singleentry">
                    <div [innerHTML]="eventLogService?.getEventItemText(eventItem.key, eventItem.value) | sanitizedHtml">
                    </div>
                </div>
                <div class="label reference-date">
                    <span translate>Am</span> {{event.referenceDate.asFormatedString()}}
                </div>
            </div>
            <div class="row">
                <div class="col time-display">{{event.date?.asTimeFormatedString()}}</div>
            </div>
            <!--div class="icon-button" (click)="routeToTarget(event)">
                Gehe zu {{getNameByUid(event?.userUid)}}
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                </svg>
            </div-->
        </div>
    </ng-container>
    <div *ngIf='eventLogService?.filteredEventLogs.length === 0 && isBusy() === false' translate>Keine Ereignisse für diesen Tag.</div>
    <div *ngIf="isBusy()" class="loading-animation">
        <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
    </div>
</div>