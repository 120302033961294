<div class="dialog-topbar">
    <div class="bold float-left">{{(recording.setNumber + 1)}}. Satz {{exercise?.name?.GetValue('de')}}</div>
    <div class="icon-button" (click)="onCloseDialog()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        Schließen
    </div>
</div>
<div class="dialog-content-wrapper">
    <div class="container">
        <div class="row" *ngIf="recording.note">
            <div class="col-12">
                <svg _ngcontent-aot-c1="" class="bi bi-sticky" fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-aot-c1="" d="M2.5 1A1.5 1.5 0 0 0 1 2.5v11A1.5 1.5 0 0 0 2.5 15h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 15 8.586V2.5A1.5 1.5 0 0 0 13.5 1h-11zM2 2.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5V8H9.5A1.5 1.5 0 0 0 8 9.5V14H2.5a.5.5 0 0 1-.5-.5v-11zm7 11.293V9.5a.5.5 0 0 1 .5-.5h4.293L9 13.793z"></path></svg>
                {{recording.note}}
            </div>
            <div class="spacer-25"></div>
        </div>
        <div class="video-wrapper">
            <video *ngIf="recording.urlsLoaded && recording.videoUrl" (play)="onVideoClicked()" controls playsinline src="{{recording.videoUrl}}" poster="{{recording.thumbnailUrl}}" preload="metadata"></video>
            <div *ngIf="recording.urlsLoaded && !recording.videoUrl">
                Das Video wurde noch nicht vollständig hochgeladen.
            </div>
            <div *ngIf="!recording.urlsLoaded">
                Video wird geladen.
                {{(firestoreService.loadTrackedVideoRecordingUrls(recording, userUid) | async)?.videoUrl}}
            </div>
        </div>
        <div class="link open-link-button icon-button w-100 text-center mt-3" (click)="goToLink(recording.videoUrl)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
            </svg>
            Video in neuem Tab öffnen
        </div>
        <div class="spacer-25"></div>
        <div class="feedback-wrapper">
            <div class="">Feedback geben:</div>
            <div class="feedback-wrapper">
                <app-md-editor-legacy id="editor-note" class="single-line"
                    (valueChanged)="onEditorValueChanged($event)"
                    [inputValue]="recording.feedbackNote || ''"
                    [placeHolder]="''">
                </app-md-editor-legacy>
                <button *ngIf="hasChanges" class="button-savenote" (click)="onSendFeedback()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</div>