import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NutritionalValueHolder } from '../model/basenutritionfact.model';
import { NutritionalGoal } from '../model/nutritionalgoal.model';
import { NutritionalGoalV2 } from '../model/nutritionalgoalv2.model';
import { PalValue, PalValues, User } from '../model/user.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-macro-calculator',
  templateUrl: './macro-calculator.component.html',
  styleUrls: ['./macro-calculator.component.css']
})
export class MacroCalculatorComponent implements OnInit {

  public user: User
  public weight: number
  public height: number
  public age: number
  public gender: string
  public selectedFormularItem: FormularDropdownItem
  public formularSelectionItems: FormularDropdownItem[] = [
    {formular: CalorieNeedFormular.harrisBenedict, name: this.translate.instant('Harris-Benedict')},
    {formular: CalorieNeedFormular.harrisBenedictPAL, name: this.translate.instant('Harris-Benedict mit PAL-Wert')},
    {formular: CalorieNeedFormular.mifflin, name: this.translate.instant('Mifflin-St.Jeor')},
    {formular: CalorieNeedFormular.mifflinPAL, name: this.translate.instant('Mifflin-St.Jeor mit PAL-Wert')},
    {formular: null, name: this.translate.instant('Manuell')},
  ]
  public selectedPalValue: PalValue
  public palSelectionItems: PalValue[] = User.getPalValues()

  public macroCalculatorNutritionalGoal: NutritionalGoal
  public macroCalculatorNutritionalGoalV2: NutritionalGoalV2
  public calorieNeed: number
  public calorieGoal: number
  public macroCalculatorCarbohydratesPercentage: number = 50
  public macroCalculatorProteinPercentage: number = 20
  public macroCalculatorFatPercentage: number = 30

  constructor(public dialogRef: MatDialogRef<MacroCalculatorComponent>, @Inject(MAT_DIALOG_DATA) private data: {user: User, nutritionalGoal: NutritionalGoal, nutritionalGoalV2: NutritionalGoalV2}, private translate: TranslateService) {
    this.macroCalculatorNutritionalGoal = data.nutritionalGoal || null
    this.macroCalculatorNutritionalGoalV2 = data.nutritionalGoalV2 || null
    if (this.macroCalculatorNutritionalGoalV2 && this.macroCalculatorNutritionalGoalV2.isValid()) {
      var carbsPercentage = parseFloat((this.macroCalculatorNutritionalGoalV2.targetValues['carbohydrates'] * 4.1 / this.macroCalculatorNutritionalGoalV2.targetValues['calories'] * 100).toFixed(1))
      var proteinPercentage = parseFloat((this.macroCalculatorNutritionalGoalV2.targetValues['protein'] * 4.1 / this.macroCalculatorNutritionalGoalV2.targetValues['calories'] * 100).toFixed(1))
      this.macroCalculatorSliderValues = [carbsPercentage, carbsPercentage + proteinPercentage]
      this.calorieNeed = this.macroCalculatorNutritionalGoalV2.targetValues['calories']
      this.selectedFormularItem = this.formularSelectionItems[4]
    } else {
      this.selectedFormularItem = this.formularSelectionItems[0]
    }
    this.user = data.user
    this.weight = this.user.latestBodyWeight || this.user.bodyWeight
    this.height = this.user.bodyHeight
    this.age = this.user.age
    this.gender = this.user.gender
    this.selectedPalValue = this.user.getPalValue()
    this.recalculateCalorieNeed()
  }

  ngOnInit(): void {}

  onFormularSelectionChanged(formularItem: FormularDropdownItem) {
    this.selectedFormularItem = formularItem
    this.recalculateCalorieNeed()
  }
  onPalSelectionChanged(palValue: PalValue) {
    this.selectedPalValue = palValue
    this.recalculateCalorieNeed()
  }

  onCalorieNeedChanged(text: string) {
    var regex = new RegExp("^[0-9]+$");
    if (regex.test(text) && parseInt(text) >= 0)  {
      var number = parseInt(text);
      this.calorieNeed = number
      this.selectedFormularItem = this.formularSelectionItems[3]
    }
  }
  onCalorieGoalChanged(text: string) {
    var regex = new RegExp("^[0-9]+$");
    if (regex.test(text) && parseInt(text) >= 0)  {
      var number = parseInt(text);
      this.calorieGoal = number
    }
  }
  onWeightChanged(text: string) {
    var regex = new RegExp("^[0-9]+$");
    if (regex.test(text) && parseFloat(text) >= 0)  {
      var number = parseFloat(text);
      this.weight = number
    }
    this.recalculateCalorieNeed()
  }
  onHeightChanged(text: string) {
    var regex = new RegExp("^[0-9]+$");
    if (regex.test(text) && parseFloat(text) >= 0)  {
      var number = parseFloat(text);
      this.height = number
    }
    this.recalculateCalorieNeed()
  }
  onAgeChanged(text: string) {
    var regex = new RegExp("^[0-9]+$");
    if (regex.test(text) && parseInt(text) >= 0)  {
      var number = parseInt(text);
      this.age = number
    }
    this.recalculateCalorieNeed()
  }
  recalculateCalorieNeed() {
    if (this.selectedFormularItem.formular == CalorieNeedFormular.harrisBenedict) {
      this.calorieNeed = Math.round(User.calculateCalorieNeedFromHarrisBenedict(this.weight, this.height, this.age, this.gender))
    } else if (this.selectedFormularItem.formular == CalorieNeedFormular.harrisBenedictPAL) {
      this.calorieNeed = Math.round(User.calculateCalorieNeedFromHarrisBenedictWithPAL(this.weight, this.height, this.age, this.gender, this.selectedPalValue?.encoding))
    } else if (this.selectedFormularItem.formular == CalorieNeedFormular.mifflin) {
      this.calorieNeed = Math.round(User.calculateCalorieNeedFromMifflin(this.weight, this.height, this.age, this.gender))
    } else if (this.selectedFormularItem.formular == CalorieNeedFormular.mifflinPAL) {
      this.calorieNeed = Math.round(User.calculateCalorieNeedFromMifflinWithPAL(this.weight, this.height, this.age, this.gender, this.selectedPalValue?.encoding))
    }
  }

  onCarbohydratePercentageChanged(text: string) {
    var regex = new RegExp("^[0-9]+$");
    if (regex.test(text) && parseInt(text) >= 0)  {
      var number = parseInt(text);
      this.macroCalculatorSliderValues = [number, this.macroCalculatorSliderValues[1]]
    }
  }
  onProteinPercentageChanged(text: string) {
    var regex = new RegExp("^[0-9]+$");
    if (regex.test(text) && parseInt(text) >= 0)  {
      var number = parseInt(text);
      this.macroCalculatorSliderValues = [this.macroCalculatorSliderValues[0], this.macroCalculatorSliderValues[0] + number]
    }
  }

  public nutritionalValueHolder: NutritionalValueHolder
  onNutritionalValuesFocused(nutritionalValueHolder: NutritionalValueHolder) {
    this.nutritionalValueHolder = nutritionalValueHolder
  }

  macroCalculatorSliderValues = [45, 75]
  macroCalculatorSliderConfig: any = {
    behaviour: 'drag',
    connect: [false, false, false],
    step: 1,
    range: {
      min: 0,
      max: 100
    },
  };
  getMacroCalculatorCarbohydratesPercentage() {
    return this.macroCalculatorSliderValues[0]
  }
  getMacroCalculatorProteinPercentage() {
    return this.macroCalculatorSliderValues[1] - this.macroCalculatorSliderValues[0]
  }
  getMacroCalculatorFatPercentage() {
    return 100- this.macroCalculatorSliderValues[1]
  }
  
  onMacroCalculatorPercentageChanged(type: string, text: string) {
    var regex = new RegExp("^[0-9]+$");
    if (regex.test(text) && parseInt(text) > 0)  {
      var number = parseInt(text);
      if (type == "C") {
        this.macroCalculatorCarbohydratesPercentage = number
      } else if (type == "P") {
        this.macroCalculatorProteinPercentage = number
      } else if (type == "F") {
        this.macroCalculatorFatPercentage = number
      }
    }
  }
  onApplyMacroCalculatorValues() {
    if (!this.macroCalculatorNutritionalGoal && !this.macroCalculatorNutritionalGoalV2) return
    if (this.macroCalculatorNutritionalGoal) {
      this.macroCalculatorNutritionalGoal.carbohydrates = this.getCarbohydratesGoal()
      this.macroCalculatorNutritionalGoal.protein = this.getProteinGoal()
      this.macroCalculatorNutritionalGoal.fat = this.getFatGoal()
      this.macroCalculatorNutritionalGoal.calories = this.getCalculatedCalorieGoalForMacros()
    } else {
      this.macroCalculatorNutritionalGoalV2.targetValues['carbohydrates'] = this.getCarbohydratesGoal()
      this.macroCalculatorNutritionalGoalV2.targetValues['protein'] = this.getProteinGoal()
      this.macroCalculatorNutritionalGoalV2.targetValues['fat'] = this.getFatGoal()
      this.macroCalculatorNutritionalGoalV2.targetValues['calories'] = this.getCalculatedCalorieGoalForMacros()
    }
    this.onConfirmDialog()
  }

  getCalorieGoal() {
    return this.calorieGoal ?? this.calorieNeed
  }
  getCarbohydratesGoal() {
    return (this.getCalorieGoal() / 100 * this.getMacroCalculatorCarbohydratesPercentage() / 4.1)?.roundToInt()
  }
  getProteinGoal() {
    return (this.getCalorieGoal() / 100 * this.getMacroCalculatorProteinPercentage() / 4.1)?.roundToInt()
  }
  getFatGoal() {
    return (this.getCalorieGoal() / 100 * this.getMacroCalculatorFatPercentage() / 9.3)?.roundToInt()
  }
  getCalculatedCalorieGoalForMacros() {
    return ((this.getCarbohydratesGoal() + this.getProteinGoal()) * 4.1 + this.getFatGoal() * 9.3)?.roundToInt()
  }
  onSetCalorieGoal(value: number) {
    this.calorieGoal = value
  }

  onCancelDialog() {
    this.dialogRef.close(null);
  }
  onConfirmDialog() {
    this.dialogRef.close({nutritionalGoal: this.macroCalculatorNutritionalGoal && this.macroCalculatorNutritionalGoalV2});
  }

}

class FormularDropdownItem {
  name: string;
  formular: CalorieNeedFormular
}
export enum CalorieNeedFormular {
  harrisBenedict = "harrisBenedict",
  harrisBenedictPAL = "harrisBenedictPAL",
  mifflin = "mifflin",
  mifflinPAL = "mifflinPAL",
}