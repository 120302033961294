import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Component, Input, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Metric} from '../model/metric.model';
import {User} from '../model/user.model';
import {FirestoreService} from '../services/firestore.service';
import {MatDialog} from '@angular/material/dialog';
import {MetricsSelectionDialogComponent} from '../metrics-selection-dialog/metrics-selection-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {GoalPlan, GoalPlanConfig, GoalPlanType} from "../model/goal-plan.model";
import {MetricGoalDialogComponent} from "../body-data/metric-goal-dialog/metric-goal-dialog.component";
import {GoalPlanService} from "../services/goal-plan.service";

@Component({
  selector: 'app-metrics-assignment',
  templateUrl: './metrics-assignment.component.html',
  styleUrls: ['./metrics-assignment.component.css']
})
export class MetricsAssignmentComponent implements OnInit {


  @Input() set selectedUser(value: User) {
    this.displayedUser = value;
    this.hadAssignedMetrics = this.displayedUser.assignedMetrics.length > 0
  }
  @Input() set selectedCoach(value: User) {
    this.coach = value;
  }

  displayedUser: User
  coach: User

  public PUBLIC_METRICS = 'PUBLIC_METRICS'
  public CUSTOM_METRICS = 'CUSTOM_METRICS'
  public selectedMetricSource: string = this.PUBLIC_METRICS
  public showCustomMetricMinValueError = false;
  public showCustomMetricMaxValueError = false;
  public showCustomMetricTargetValueError = false;
  public customMetricForDeletion: Metric = null
  public hadAssignedMetrics: boolean = false

  constructor(public userService: FirestoreService, private toastr: ToastrService, private dialog: MatDialog, public translate: TranslateService, private goalPlanService: GoalPlanService) { }

  ngOnInit(): void {
  }

  onSelectMetricSource(source: string) {
    this.selectedMetricSource = source
  }
  onAddAssignedMetric(metric: Metric) {
    this.displayedUser.assignedMetrics.push(metric)
  }
  onDeleteMetric(metric: Metric) {
    this.customMetricForDeletion = metric
  }
  onConfirmCustomMetricDeletion() {
    this.customMetricForDeletion.deleted = true
    this.userService.deleteCustomMetric(this.customMetricForDeletion)
    this.customMetricForDeletion = null
  }
  onCancelCustomMetricDeletion() {
    this.customMetricForDeletion = null
  }
  onRemoveAssignedMetric(metric: Metric) {
    this.displayedUser.assignedMetrics.forEach( (item, index) => {
      if (item.metricId == metric.metricId) this.displayedUser.assignedMetrics.splice(index, 1);
    });
  }

  dropAssignedMetric(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedUser?.assignedMetrics, event.previousIndex, event.currentIndex);
  }

  isMetricAssigned(metric: Metric): boolean {
    var assigned = false
    this.displayedUser.assignedMetrics.forEach( (item, index) => {
      if (item.metricId == metric.metricId) assigned = true
    });
    return assigned
  }
  saveAssignedMetrics() {
    var date = new Date()
    this.displayedUser.assignedMetrics.forEach(metric => {
      metric.applied = false
      metric.assignmentDate = date
    });
    this.userService.insertAssignedMetrics(this.displayedUser, this.displayedUser.assignedMetrics)
    this.toastr.success("Die Metriken wurden zugewiesen.", "",  {
      positionClass: 'toast-bottom-center'
    });
  }

  onAddMetric(){
    const dialogRef = this.dialog.open(MetricsSelectionDialogComponent, { data: { notAvailableMetricIds: this.displayedUser?.assignedMetrics.map(x => x.metricId), canShowMetricSets: true}, width: '1200px'})
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if(result.shouldTake) {
          result.metrics.forEach(metric => {
            if(!this.displayedUser?.assignedMetrics.find(x => x.metricId == metric.metricId)){
              this.displayedUser?.assignedMetrics.push(metric);
            }
          });
        }
      }
    });
  }

  public customMetric: Metric = null
  public customMetricSelectableValues: string[] = []
  public customMetricTypes: MetricType[] = [
    {type: 'NUMBER', description: 'Zahl'},
    {type: 'YES_NO', description: 'Ja/Nein'},
    {type: 'SELECTION', description: 'Auswahl mit vorgegebenen Werten'},
    {type: 'TEXT', description: 'Text'},
    {type: 'DURATION', description: 'Dauer (h:min)'},
    {type: 'IMAGE', description: 'Bild'},
    {type: 'VIDEO', description: 'Video'},
    {type: 'MULTISELECT', description: 'Auswahl mit mehreren vorgegebenen Werten'},
    {type: 'TODO', description: 'ToDo'},
  ]
  getDescriptionForMetricType(type: string) {
    var description = ''
    this.customMetricTypes.forEach(metricType => {
      if (metricType.type == type) description = metricType.description
    })
    return description
  }
  onCreateCustomMetric() {
    this.customMetric = new Metric()
    this.customMetricSelectableValues = [""]
    this.showCustomMetricMinValueError = false
    this.showCustomMetricMaxValueError = false
    this.showCustomMetricTargetValueError = false
    this.customMetric.metricType = 'NUMBER'
    this.customMetric.dataType = 'INTEGER'
  }
  onCustomMetricNameChanged(text: string) {
    this.customMetric.nameDe = text
  }
  onCustomMetricDescriptionChanged(text: string) {
    this.customMetric.descriptionDe = text
  }
  onCustomMetricUnitChanged(text: string) {
    if (text?.length == 0) text = null
    this.customMetric.unit = text
  }
  onCustomMetricValueExplanationChanged(text: string) {
    if (text?.length == 0) text = null
    this.customMetric.valueExplanation = text
  }
  onCustomMetricTypeChanged(text: string) {
    this.customMetric.metricType = text
    if (this.customMetric.isMetricTypeNumber()) this.customMetric.dataType = 'INTEGER'
    if (this.customMetric.isMetricTypeText()) this.customMetric.dataType = 'STRING'
    if (this.customMetric.isMetricTypeSelection()) this.customMetric.dataType = 'INTEGER'
    if (this.customMetric.isMetricTypeYesNo()) this.customMetric.dataType = 'BOOLEAN'
    if (this.customMetric.isMetricTypeDuration()) this.customMetric.dataType = 'INTEGER'
    if (this.customMetric.isMetricTypeImage()) this.customMetric.dataType = 'STRING'
    if (this.customMetric.isMetricTypeVideo()) this.customMetric.dataType = 'STRING'
    if (this.customMetric.isMetricTypeMultiselect()) this.customMetric.dataType = 'STRING'
    if (this.customMetric.isMetricTypeToDo()) this.customMetric.dataType = 'BOOLEAN'
    this.customMetric.targetValue = null
  }
  onCustomMetricDataTypeChanged(text: string) {
    this.customMetric.dataType = text
    this.customMetric.minValue = null
    this.customMetric.maxValue = null
    this.customMetric.targetValue = null
    this.showCustomMetricMinValueError = false
    this.showCustomMetricMaxValueError = false
    this.showCustomMetricTargetValueError = false
    this.customMetricSelectableValues = [""]

  }
  onCustomMetricMinValueChanged(text: string) {
    if (text == '-') {
      this.showCustomMetricMinValueError = true
      return
    }
    if (text == null || text.length == 0) {
      this.customMetric.minValue = null
      this.showCustomMetricMinValueError = false
      return
    }
    if (this.customMetric.isDataTypeDouble()) {
      if (text) text = text.replace(',', '.')
      var regex = new RegExp("^[0-9,.-]+$");
      if (text?.length > 0 && regex.test(text)) {
        var number = parseFloat(text)
        this.customMetric.minValue = number
        this.showCustomMetricMinValueError = false
      } else {
        this.showCustomMetricMinValueError = true
      }
    }
    if (this.customMetric.isDataTypeInteger()) {
      var regex = new RegExp("^[0-9-]+$");
      if (text?.length > 0 && regex.test(text))  {
        var number = parseInt(text);
        this.customMetric.minValue = number
        this.showCustomMetricMinValueError = false
      } else {
        this.showCustomMetricMinValueError = true
      }
    }
  }
  onCustomMetricMaxValueChanged(text: string) {
    if (text == '-') {
      this.showCustomMetricMaxValueError = true
      return
    }
    if (text == null || text.length == 0) {
      this.customMetric.maxValue = null
      this.showCustomMetricMaxValueError = false
      return
    }
    if (this.customMetric.isDataTypeDouble()) {
      if (text) text = text.replace(',', '.')
      var regex = new RegExp("^[0-9,.-]+$");
      if (text?.length > 0 && regex.test(text)) {
        var number = parseFloat(text)
        this.customMetric.maxValue = number
        this.showCustomMetricMaxValueError = false
      } else {
        this.showCustomMetricMaxValueError = true
      }
    }
    if (this.customMetric.isDataTypeInteger()) {
      var regex = new RegExp("^[0-9-]+$");
      if (text?.length > 0 && regex.test(text))  {
        var number = parseInt(text);
        this.customMetric.maxValue = number
        this.showCustomMetricMaxValueError = false
      } else {
        this.showCustomMetricMaxValueError = true
      }
    }
  }

  onMetricTargetValueChanged(text: string, metric: Metric) {
    metric.targetValue = null
    metric.hasTargetValueError = false
    if (text == '-') {
      metric.hasTargetValueError = true
      return
    }
    if (text == null || text.length == 0) {
      metric.targetValue = null
      metric.hasTargetValueError = false
      return
    }
    if (metric.isMetricTypeDuration()) {
      if (!text.includes(':') || text.endsWith(':')) {
        metric.hasTargetValueError = true
        return
      }
      var regex = new RegExp("^[0-9,:]+$");
      var values = text?.split(':')
      if (text?.length > 0 && regex.test(text) && values?.length == 2)  {
        var hours = parseInt(values[0]);
        var minutes = parseInt(values[1]);
        metric.targetValue = 60 * hours + minutes
        metric.hasTargetValueError = false
      } else {
        metric.hasTargetValueError = true
      }
    } else if (metric.isDataTypeDouble()) {
      if (text) text = text.replace(',', '.')
      var regex = new RegExp("^[0-9,.-]+$");
      if (text?.length > 0 && regex.test(text)) {
        var number = parseFloat(text)
        metric.targetValue = number
        metric.hasTargetValueError = false
      } else {
        metric.hasTargetValueError = true
      }
    } else if (metric.isDataTypeInteger()) {
      var regex = new RegExp("^[0-9-]+$");
      if (text?.length > 0 && regex.test(text))  {
        var number = parseInt(text);
        metric.targetValue = number
        metric.hasTargetValueError = false
      } else {
        metric.hasTargetValueError = true
      }
    }
  }
  onMetricSelectableValueChanged(text: string, index: number) {
    text = text.replace(";", "")
    this.customMetricSelectableValues[index] = text
  }
  onAddNewSelectableValue() {
    this.customMetricSelectableValues.push("")
  }
  onDeleteSelectableValue(index: number) {
    this.customMetricSelectableValues.splice(index, 1)
  }

  onCloseCustomMetricDialog() {
    this.customMetric = null
  }
  onSaveCustomMetric() {
    if (this.customMetric.isMetricTypeSelection()) {
      var values = ''
      this.customMetricSelectableValues.forEach(value => {
        values = values + value + ";"
      })
      if (values.endsWith(";")) values = values.substr(0, values.length - 1)
      this.customMetric.selectableValues = values
    }
    this.userService.insertNewCustomMetric(this.coach, this.customMetric)
    this.customMetric = null
  }
  isCustomMetricValid(): boolean {
    var valid = true
    if (this.customMetric.nameDe == null || this.customMetric.nameDe.length == 0) valid = false
    if (this.customMetric.isMetricTypeNumber()) {
      if (this.showCustomMetricMinValueError || this.showCustomMetricMaxValueError || this.showCustomMetricTargetValueError) valid = false
    }
    if (this.customMetric.isMetricTypeSelection()) {
      if (this.customMetricSelectableValues.length == 0) valid = false
      this.customMetricSelectableValues.forEach(value => {
        if (value == null || value.length == 0) valid = false
      })
    }
    if (this.customMetric.hasTargetValueError) valid = false
    return valid
  }

  createRange(number){
    var items: number[] = [];
    for(var i = 0; i < number; i++){
       items.push(i);
    }
    return items;
  }

  protected openMetricDialog(metric: Metric, type?: GoalPlanType) {
    const goalPlan = this.displayedUser.getGoalPlanByMetricId(metric.metricId, type);
    const isNew = !goalPlan;
    const data: GoalPlanConfig = {
      metric: metric,
      type: goalPlan ? goalPlan.type : GoalPlanType.DAILY,
      isNew: isNew,
      goalPlan: goalPlan,
      user: this.displayedUser
    };
    const dialogRef = this.dialog.open(MetricGoalDialogComponent, {data: data, width: '850px'});
    dialogRef.afterClosed().subscribe((result: GoalPlan) => {
      if (result) {
        if (isNew) {
          this.goalPlanService.addFirstGoalPlan(this.displayedUser, result).then(() => {
            console.log("plan added");
          });
        } else {
          this.goalPlanService.updateGoalPlans(this.displayedUser, result).then(() => {
            console.log("plan updated")
          });
        }
      }
    });
  }

  protected readonly GoalPlanType = GoalPlanType;
}

export class MetricType {
  type: string;
  description: string;
}
