<div class="layout-box sub-box">
    <div class="row">
        <div class="col-12" translate>
            <h3>Einstellungen</h3>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <label class="form-datetime-label w-auto" for="startdatetime" translate>Start:</label>
            <input type="datetime-local" required class="form-datetime-input" id="startdatetime"
                [ngModel]="toDateString(trainingSessionContainer?.trackedTrainingSession?.startDate)"
                (input)="onStartDateChanged($event.target.value)">
            <div *ngIf="!isStartDateValid()" class="small-text color-red" translate>Das Startdatum darf nicht leer sein.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <label class="form-datetime-label w-auto" for="enddatetime" translate>Ende:</label>
            <input type="datetime-local" class="form-datetime-input" id="enddatetime"
                [ngModel]="toDateString(trainingSessionContainer?.trackedTrainingSession?.endDate)"
                (input)="onEndDateChanged($event.target.value)">
            <div *ngIf="!isEndDateValid()" class="small-text color-red" translate>Das Enddatum muss nach dem Startdatum
                liegen.</div>
        </div>
    </div>
    <div class="row" *ngIf="!trainingSessionContainer?.trackedTrainingSession?.endDate">
        <div class="col-12">
            <div class="d-flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-info-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path
                        d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                </svg>
                <div class="label display-inlineblock marginleft-5 align-middle" translate>Das Enddatum wird nach dem
                    Speichern automatisch gesetzt.</div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <div class="toggle-wrapper display-inlineblock ml-0">
                <div class="toggle {{autoStartPause ? 'on' : ''}}" (click)="onToggleAutoStartPause()">
                    <div class="handle"></div>
                </div>
                <div class="text" translate
                    [tooltip]="'Der Pausen-Timer wird nach jedem Satz automatisch gestartet.' | translate">
                    Pausen-Timer automatisch starten
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!trainingSessionContainer?.trackedTrainingSession?.id" class="row mt-3">
        <div class="col-12">
            <div class="toggle-wrapper display-inlineblock ml-0">
                <div class="toggle {{autoCreateActivity ? 'on' : ''}}"
                    (click)="autoCreateActivityChange?.emit(!autoCreateActivity)">
                    <div class="handle"></div>
                </div>
                <div class="text" translate
                    [tooltip]="'Nach dem Speichern der Einheit wird automatisch eine Aktivität angelegt.' | translate">
                    Aktivität für absolvierte Einheit erstellen
                </div>
            </div>
        </div>
    </div>
</div>
<div cdkDropList (cdkDropListDropped)="dropTrainingExercise($event)">
    <div class="{{superSet.trackedSuperSetExercises.length > 1 ? 'super-set-row' : ''}}"
        *ngFor="let superSet of trainingSessionContainer.trackedSuperSets">
        <div *ngIf="superSet.trackedSuperSetExercises.length > 1">
            <div class="spacer-20"></div>
            <div class="col-6">
                <div class="row">
                    <h3 translate>Supersatz:</h3>
                </div>
                <div class="spacer-10"></div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="arrow-box" *ngIf="superSet.trackedSuperSetExercises.length > 1"></div>
                <ng-container *ngFor="let exercise of superSet.trackedSuperSetExercises; let exerciseIndex = index">
                    <div class="spacer-10"></div>
                    <div class="row">
                        <div class="col" (mouseover)="mouseEnterExercise(exercise)"
                            (touchstart)="mouseEnterExercise(exercise)">
                            <div class="arrow-down"
                                *ngIf="superSet.trackedSuperSetExercises.length > 1 && exerciseIndex === 0">
                            </div>
                            <div cdkDrag [cdkDragDisabled]="readOnlyMode"
                                class="layout-box sub-box exercise-box link ng-star-inserted exercise-container"
                                *ngIf="!exercise?.deleted">
                                <div class="drag-handle exercise-drag-handle" cdkDragHandle>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                                        class="bi bi-grip-vertical" viewBox="0 0 16 16">
                                        <path
                                            d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                    </svg>
                                </div>
                                <div *ngIf="!trainingExercisesLoaded()">
                                    <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
                                </div>
                                <div class="expanded-exercise-container">
                                    <div class="row">
                                        <div class="thumbnail-image">
                                            <img draggable="false"
                                                *ngIf="getExerciseByTrackedExercise(exercise)?.thumbnailDownloadURL"
                                                loading="lazy" (error)="utilityService.onImageErrorHide($event)"
                                                src="{{getExerciseByTrackedExercise(exercise)?.thumbnailDownloadURL}}">
                                            <div *ngIf="!getExerciseByTrackedExercise(exercise)?.thumbnailDownloadURL">
                                                <img loading="lazy" draggable="false"
                                                    (error)="utilityService.onImageErrorHide($event)"
                                                    src="{{ (trainingService.getExerciseThumbnailUrl(getExerciseByTrackedExercise(exercise)) | async) }}">
                                            </div>
                                        </div>
                                        <div class="exercise-name-col col-auto col-lg-4 col-xxxl-2">
                                            <div class="row">
                                                <div class="col mr-4">
                                                    <div class="row">
                                                        <b
                                                            *ngIf="exercise.exerciseId">{{getExerciseByTrackedExercise(exercise)?.name?.GetValue(translate.currentLang)}}</b>
                                                    </div>
                                                    <div class="row">
                                                        {{getExerciseByTrackedExercise(exercise)?.subName?.GetValue(translate.currentLang)}}
                                                    </div>
                                                    <div *ngIf="user" class="row">
                                                        <div class="spacer-10"></div>
                                                        <div class="icon-button ignore-disabled"
                                                            (click)="onShowExerciseHistory(exercise);$event.stopPropagation()"
                                                            translate>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16" fill="currentColor" class="bi bi-graph-up"
                                                                viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd"
                                                                    d="M0 0h1v15h15v1H0V0Zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07Z" />
                                                            </svg>
                                                            Historie anzeigen
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col exercise-table-wrapper">
                                            <app-exercise-set-table id="{{'exercise-' + exercise.id}}" [user]="user"
                                                [readOnlyMode]="readOnlyMode" [trackingMode]="true"
                                                [showVideoIcon]="true"
                                                (onVideoIconClicked)="onUploadVideo($event, exercise)"
                                                [selectedSetParameters]="getAvailableSetParameters(exercise)"
                                                (selectedSetParametersChange)="onSetParametersChanged($event, exercise)"
                                                [(sets)]="exercise.trackedSets" [(hasChanges)]="hasChangesInternal"
                                                [isPartOfSuperSet]="superSet.trackedSuperSetExercises.length > 1"
                                                [getRegexForSetParameter]="getRegexForSetParameter.bind(this)"
                                                [setTime]="setTime.bind(this)" [getTime]="getTime.bind(this)"
                                                [getSetRir]="getSetRir.bind(this)" [setSetRir]="setSetRir.bind(this)"
                                                [setSetRpe]="setSetRpe.bind(this)" [getSetRpe]="getSetRpe.bind(this)"
                                                [getSetHeartRate]="getSetHeartRate.bind(this)"
                                                [setSetHeartRate]="setSetHeartRate.bind(this)"
                                                [checkTUTINput]="checkTUTInput" [tutChange]="tutChange"
                                                [tutPattern]="tutPattern" [noteChanged]="noteChanged"
                                                [hasVideoRecording]="exercise.setHasVideoRecording.bind(exercise)"
                                                (isTrackedChanged)="isTrackedChanged($event, exercise, superSet)"
                                                [focusedSet]="focusedSet" (focusedTable)="focusedTable(exercise)"
                                                [(autoRun)]="exercise.autoRun"
                                                (trainingSetsChange)="onTrainingSetsChange($event)"></app-exercise-set-table>
                                        </div>
                                        <div class="col-12">
                                            <div class="spacer-10"></div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="label d-xl-none" translate>Notiz</div>
                                                    <app-md-editor-legacy [readOnlyMode]="readOnlyMode"
                                                        [placeHolder]="'Notiz hinzufügen...' | translate"
                                                        id="editor-note" class="mdeditor-whitefield"
                                                        [inputValue]="exercise.note"
                                                        (valueChanged)="exercise.note = $event"
                                                        (click)="$event.stopPropagation()">
                                                    </app-md-editor-legacy>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="spacer-25"></div>
                                            <div class="link ignore-disabled"
                                                (click)="imageAreaExpanded = !imageAreaExpanded">
                                                <ng-container *ngIf="imageAreaExpanded; else imageAreaCollapsed">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13"
                                                        fill="currentColor" class="bi bi-caret-down-fill"
                                                        viewBox="0 0 16 16">
                                                        <path
                                                            d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                    </svg>
                                                </ng-container>
                                                <ng-template #imageAreaCollapsed>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13"
                                                        fill="currentColor" class="bi bi-caret-right-fill"
                                                        viewBox="0 0 16 16">
                                                        <path
                                                            d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                    </svg>
                                                </ng-template>
                                                <span translate>Bilder</span>
                                            </div>
                                            <div class="spacer-10"></div>
                                            <div *ngIf="imageAreaExpanded" class="exercisenote-content">
                                                <app-image-upload-view [filePaths]="exercise.noteImagePaths"
                                                    [canEdit]="!readOnlyMode" [pathPrefix]="'users/' + user.uid + '/'"
                                                    [images]="exercise.tempNoteImages"
                                                    (imagesChange)="onNoteImagesChanged($event, exercise)"></app-image-upload-view>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="exercise.exerciseId" class="ignore-disabled exercise-info-button"
                                    (click)="onShowExerciseInfo(exercise);$event.stopPropagation()"
                                    [tooltip]="'Übungsdetails anzeigen' | translate">
                                    <div class="icon-button">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                            <path
                                                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path
                                                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                        </svg>
                                    </div>
                                </div>
                                <div *ngIf="exercise.replacedExerciseId"
                                    class="alternative-exercise-container d-flex justify-content-center"
                                    (click)="onSelectAlternativeExercise(exercise); $event.stopPropagation()"
                                    tooltip="{{('Ersetzte Übung auswählen:' | translate) + ' ' + getExerciseById(exercise.replacedExerciseId)?.name?.GetValue(translate.currentLang)}}">
                                    <div class="alternative-exercise-thumbnail">
                                        <img draggable="false"
                                            *ngIf="getExerciseById(exercise.replacedExerciseId)?.thumbnailDownloadURL"
                                            (error)="utilityService.onImageErrorHide($event)" loading="lazy"
                                            src="{{getExerciseById(exercise.replacedExerciseId)?.thumbnailDownloadURL}}">
                                        <div
                                            *ngIf="!getExerciseById(exercise.replacedExerciseId)?.thumbnailDownloadURL">
                                            <img loading="lazy" draggable="false"
                                                (error)="utilityService.onImageErrorHide($event)"
                                                src="{{ (trainingService.getExerciseThumbnailUrl(getExerciseById(exercise.replacedExerciseId)) | async) }}">
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!readOnlyMode" class="bottom-action-buttons">
                                    <div class="float-right marginleft-10"
                                        (click)="onRemoveExercise(exercise);$event.stopPropagation()"
                                        [tooltip]="'Übung entfernen' | translate">
                                        <div class="icon-button">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path
                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                                <path
                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="float-right"
                                        (click)="onReplaceExercise(exercise);$event.stopPropagation()"
                                        [tooltip]="'Übung ersetzen' | translate">
                                        <div class="icon-button">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16">
                                                <path
                                                    d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                                                <path fill-rule="evenodd"
                                                    d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button (mouseover)="mouseEnterExercise(exercise)"
                                (touchstart)="mouseEnterExercise(exercise)"
                                *ngIf="!readOnlyMode && !exercise?.deleted && (!isLastSuperSet(superSet) || exerciseIndex < superSet.trackedSuperSetExercises.length - 1)"
                                (click)="onSetSuperSetConnection(exercise)"
                                class="tab-button super-set-connect-button {{exerciseIndex < superSet.trackedSuperSetExercises.length - 1 ? 'active' : 'not-connected'}} {{hoveredExercise === exercise ? 'visible' : 'hidden'}}">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-link" viewBox="0 0 16 16" transform="rotate(90)">
                                    <path
                                        d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                                    <path
                                        d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="icon-button display-inlineblock add-exercise-button" (click)="onAddExercise()" translate>
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg>
        Übung hinzufügen
    </div>
</div>
<div *ngIf="!timerHidden" class="timer" cdkDrag>
    <app-timer #timerComponent (elapsedTimeChange)="setElapsedTime($event)" (timerFinished)="timerFinished()"
    (timerRunning)="timerRunning = $event"></app-timer>
    <div class="icon-button close close-timer-button" (click)="hideTimer()">
        <svg width="21" height="21" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
    </div>
</div>


<div id="exercises-table-dialog" class="dialog-wrapper show" *ngIf="showExerciseDialog">
    <div class="dialog layout-box">
        <div class="dialog-topbar">
            <div class="icon-button float-right" (click)="onCancelSelection()" translate>
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
                Schließen
            </div>
        </div>
        <div class="dialog-content-wrapper">
            <ng-container *ngIf="plannedMergedExercises?.length > 0">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="row">
                            <h3 class="col-auto" translate>Geplante Alternativübungen</h3>
                        </div>
                        <div class="spacer-10"></div>
                        <div class="horizontal-exercise-scroll">
                            <div *ngFor="let plannedExercise of plannedMergedExercises">
                                <div class="row layout-box sub-box link flex-nowrap mr-3"
                                    (click)="onTrainingExerciseSelectionChanged(plannedExercise)">
                                    <div class="thumbnail-image">
                                        <img draggable="false" *ngIf="plannedExercise.thumbnailDownloadURL" loading="lazy"
                                            (error)="utilityService.onImageErrorHide($event)"
                                            src="{{plannedExercise.thumbnailDownloadURL}}">
                                    </div>
                                    <div class="col">
                                        <div class="row">
                                            <div class="col">
                                                <b>{{plannedExercise.name?.GetValue(translate.currentLang)}}</b>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                {{plannedExercise.subName?.GetValue(translate.currentLang)}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </ng-container>
            <div class="row justify-content-center">
                <app-exercises-table [TrainingExercises]="trainingService.MergedTrainingExercises"
                    (trainingExerciseSelected)="onTrainingExerciseSelectionChanged($event)"></app-exercises-table>
            </div>
        </div>
    </div>
</div>
<ngx-spinner name="trackingSpinner" bdColor="rgba(0,0,0,0.33)" size="default" color="var(--accentColor)"
    type="ball-clip-rotate" [fullScreen]="true">
    <div *ngIf="spinnerText" class="spinner-overlaytext" [innerHTML]="spinnerText"></div>
</ngx-spinner>