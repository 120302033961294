<form *ngIf="communityConfig" [formGroup]="shadowControl">
  <div class="row">
    <div class="col-12 col-md-4 col-xl-3 col-xxxl-2">
      <div class="community-image link image-dropzone" (click)="onUploadImage()"
           [ngStyle]="{'background-image':'url('+ communityConfig.tempImgUrl +')'}">
        <div *ngIf="!communityConfig.tempImgUrl" class="sub-box no-community-image">
          <p class="dropzone-hint">
            <span translate>Thumbnail (.png oder .jpg) auswählen</span>
            <br><span translate>Format: 512x512px (1:1)</span>
          </p>
        </div>
        <input id="input-community-image" type="file" class="display-none" accept="image/png, image/jpg, image/jpeg"
               (change)="uploadImage($event)">
      </div>
    </div>
    <div class="col-12 col-md-8 col-xl-9 col-xxxl-10">
      <div class="form-group">
        <label for="name" class="label" translate>Community Name</label>
        <input id="name" type="text" formControlName="name"
               [placeholder]="'Name' | translate" class="input full-width"
               name="name">
      </div>

      <div class="form-group">
        <label for="description" class="label" translate>Community Beschreibung</label>
        <input formControlName="description" class="display-none">
        <app-md-editor id="description" class=""
                       [placeHolder]="EDITOR_PLACEHOLDER"
                       (valueChanged)="onEditorValueChanged($event)" [inputValue]="communityConfig.description">
        </app-md-editor>
      </div>
    </div>
  </div>
  <div class="spacer-20"></div>
  <h3 class="" translate>Community-Einstellungen</h3>
  <div class="custom-control custom-checkbox">
    <input id="hideMemberList" type="checkbox" formControlName="hideMemberList" class="form-control-input"
           name="hideMemberList">
    <label for="hideMemberList" class="permission-label" translate>Mitgliederliste in App ausblenden</label>
  </div>
  <div class="spacer-10"></div>

  <div class='bold' translate>Rechteverwaltung</div>
  <div class="spacer-10"></div>
  <p translate>Lege fest, welche Inhalte deine Coachees in dieser Community teilen dürfen. Wenn du keinen Haken gesetzt hast, können nur die von dir markierten Coaches posten.</p>
  <div class="spacer-10"></div>
  <div class="custom-control custom-checkbox">
    <input id="allowPostings" type="checkbox" formControlName="allowPostings" class="form-control-input"
           name="allowPostings">
    <label for="allowPostings" class="permission-label" translate>Beiträge erstellen</label>
  </div>

  <div *ngIf="communityConfig.allowPostings" class="custom-control custom-checkbox">
    <input id="allowRecipeSharing" type="checkbox" formControlName="allowRecipeSharing"
           class="form-control-input"
           name="allowRecipeSharing">
    <label for="allowRecipeSharing" class="permission-label" translate>Rezepte teilen</label>
  </div>

  <div *ngIf="communityConfig.allowPostings" class="custom-control custom-checkbox">
    <input id="allowTrainingSharing" type="checkbox" formControlName="allowTrainingSharing"
           class="form-control-input" name="allowTrainingSharing">
    <label for="allowTrainingSharing" class="permission-label" translate>Training teilen</label>
  </div>

  <div *ngIf="communityConfig.allowPostings" class="custom-control custom-checkbox">
    <input id="allowFileSharing" type="checkbox" formControlName="allowFileSharing"
           class="form-control-input" name="allowFileSharing">
    <label for="allowFileSharing" class="permission-label" translate>Einträge aus der Dateiablage teilen</label>
  </div>

  <div *ngIf="communityConfig.allowPostings" class="custom-control custom-checkbox">
    <input id="allowVoiceMessagesInPostings" type="checkbox" formControlName="allowVoiceMessagesInPostings"
           class="form-control-input" name="allowVoiceMessagesInPostings">
    <label for="allowVoiceMessagesInPostings" class="permission-label" translate>Sprachnachrichten versenden</label>
  </div>

  <div class="spacer-20"></div>
  <div class='bold' translate>Mitglieder</div>
  <div class="spacer-10"></div>
  <div class='label' translate>
    Du kannst als Coach aktuell nur über die Kunden-App in einer Community aktiv sein.<br>Wenn du als Nutzer die gleiche Email-Adresse wie als Coach nutzt, musst du dich nur als Coach hinzufügen.<br>Wenn du eine andere Email-Adresse nutzt, musst du dich als Nutzer und als Coach hinzufügen sowie in der Lizenzverwaltung bei deiner Kundenlizenz beim Haken Trainerlizenz dich als Coach auswählen.
    <span class="link underline" (click)="openIntercomArticle()" translate>Hilfe-Artikel anzeigen</span>
  </div>
  <div class="spacer-10"></div>
  <div class="form-group">
    <label for="coaches-box" translate>Coaches: </label>
    <input formControlName="coaches" class="display-none">
    <button class="button-filled light small dropdown-toggle marginleft-10"
            type="button" id="dropdownAssignedCoaches" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">{{ getSelectedCoachesString() }}
    </button>
    <div id="coaches-box" class="dropdown-menu dropdown-assignedusers" aria-labelledby="dropdownAssignedCoaches">
      <div class="dropdown-item link" *ngFor="let coach of communityConfig.coaches.keys()"
           (click)="onCoachSelected(coach);$event.stopPropagation();">
        <input class="input checkbox" type="checkbox" [checked]="communityConfig.getCoachIds().includes(coach)">
        <div class="display-inlineblock">{{ communityConfig.coaches.get(coach) }}</div>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label for="user-box" translate>Mitglieder: </label>
    <input formControlName="users" class="display-none">
    <button class="button-filled light small dropdown-toggle marginleft-10"
            type="button" id="dropdownAssignedUsers" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">{{ getSelectedUsersString() }}
    </button>
    <div id="user-box" class="dropdown-menu dropdown-assignedusers" aria-labelledby="dropdownAssignedUsers">
      <div class="dropdown-item link" (click)="onGroupSelected(ALL_GROUP);$event.stopPropagation()">
        <input class="input checkbox" type="checkbox" [checked]="allUsersTargeted()">
        <div class="display-inlineblock" translate>Alle</div>
      </div>
      <ng-container *ngIf="communityConfig.groups.size > 0">
        <div class="dropdown-divider"></div>
        <div class="label marginleft-10" translate>Gruppen</div>
        <div class="dropdown-item link" *ngFor="let group of communityConfig.groups.keys()"
             (click)="onGroupSelected(group);$event.stopPropagation()">
          <input [disabled]="allUsersTargeted()" class="input checkbox" type="checkbox"
                 [checked]="isGroupSelected(group)">
          <div class="display-inlineblock">{{ communityConfig.groups.get(group) }}</div>
        </div>
      </ng-container>
      <div class="dropdown-divider"></div>
      <div class="label marginleft-10" translate>Mitglieder</div>
      <div class="dropdown-item link" *ngFor="let user of communityConfig.users.keys()"
           (click)="onUserSelected(user);$event.stopPropagation()">
        <input [disabled]="allUsersTargeted()" class="input checkbox" type="checkbox" [checked]="isUserSelected(user)">
        <div class="display-inlineblock">{{ communityConfig.users.get(user) }}</div>
      </div>
    </div>
  </div>
</form>
