import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MetricData} from '../model/metricdata.model';
import {UtilityService} from '../services/utility.service';
import {EventLogService} from '../services/event-log.service';
import {MatDialog} from '@angular/material/dialog';
import {MetricDataImageDialogComponent} from '../metric-data-image-dialog/metric-data-image-dialog.component';
import {ImageEditorComponent} from '../utilities/image-editor/image-editor.component';
import {FirestoreService} from '../services/firestore.service';
import {FirestoreNutritionPlanService} from '../services/firestore-nutritionplan.service';
import {User} from '../model/user.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {SingleExerciseComponent} from '../training/single-exercise/single-exercise.component';
import {DomSanitizer} from '@angular/platform-browser';
import {LanguageService} from '../services/language.service';
import {TranslateService} from '@ngx-translate/core';
import {GoalPlanService} from "../services/goal-plan.service";
import {
  GoalVisualizationConfig,
  GoalVisualizationOrigin
} from "../graph/goal-visualization/goal-visualization.component";
import {GoalPlanType} from "../model/goal-plan.model";

@Component({
  selector: 'app-metricdata-view',
  templateUrl: './metricdata-view.component.html',
  styleUrls: ['./metricdata-view.component.css']
})
export class MetricdataViewComponent {

  // @Input() metricData: MetricData;
  public metricData: MetricData;
  @Input() set setMetricData(metricData: MetricData) {
    this.metricData = metricData;
    // if(this.metricData.metric.isMetricTypeVideo()){
    //   this.trustedVideoURL = this.domSanitizer.bypassSecurityTrustResourceUrl(this.metricData.mediaLink);
    // }
    this.initConfig();
  }

  // public trustedVideoURL: any;


  public editMode: boolean = false;

  @Input() set setEditMode(value: boolean) {
    this.editMode = value;
  }

  @Input() user: User;

  @Output() removeMetricData = new EventEmitter<MetricData>();

  @Input() isQuestionaireMetricData: boolean = false;

  @Input() openMetricDataImage: (metricData: MetricData) => void;

  @Input()
  protected origin: GoalVisualizationOrigin;

  @Input()
  protected selectedDate: Date;

  public EventLogService = EventLogService;

  protected config: GoalVisualizationConfig = null;

  constructor(public utilityService: UtilityService, private dialog: MatDialog, private userService: FirestoreService, public spinner: NgxSpinnerService, private toastr: ToastrService, private domSanitizer: DomSanitizer, public languageService: LanguageService, public translate: TranslateService, private goalPlanService: GoalPlanService) { }

  protected initConfig() {
    if(this.origin == GoalVisualizationOrigin.DIARY_WEEKLY) {
      this.config = this.goalPlanService.getActiveMetricConfig(this.metricData.metricId, this.user, GoalPlanType.WEEKLY, this.selectedDate);
      return this.config;
    }
    if(this.origin == GoalVisualizationOrigin.DIARY_DAILY) {
      const dailyConfig = this.goalPlanService.getActiveMetricConfig(this.metricData.metricId, this.user, GoalPlanType.DAILY, this.selectedDate);
      const progressConfig = this.goalPlanService.getActiveMetricConfig(this.metricData.metricId, this.user, GoalPlanType.PROGRESS, this.selectedDate);

      this.config = dailyConfig || progressConfig;
      return this.config;
    }
  }

  onOpenMetricDataImage(metricData: MetricData) {
    if (this.openMetricDataImage) {
      this.openMetricDataImage(metricData);
    } else {
      const dialogRef = this.dialog.open(MetricDataImageDialogComponent, { data: { imageURL: metricData.mediaLink}});
    }
  }

  onRemoveMetricData() {
    this.removeMetricData.emit(this.metricData);
  }

  onMetricSelectionChanged(metricData: MetricData, index: number) {
    metricData.value = index;
  }

  multiSelectionChanged(metricData: MetricData, index: number){
    let selectedValues = metricData.getSelectedValues();
    if(selectedValues.includes(index.toString())){
      selectedValues = selectedValues.filter(x => x != index.toString());
    }
    else{
      selectedValues.push(index.toString());
    }
    metricData.setSelectedValues(selectedValues);
  }

  switchYesNo(){
    this.metricData.value = !this.metricData.value;
  }


  async uploadMetricDataVideo(event: any, metricData: MetricData) {
    if (event.target.files && event.target.files[0]) {
      try{
        this.spinner.show("image-editor-spinner");
        let newVideo: File = event.target.files[0];
        if(!SingleExerciseComponent.checkUploadFile(event.target.files[0], 50000000, ["mp4", "x-m4v", "mov", "quicktime"], this.toastr, this.translate)) return;
        var reader = new FileReader();
        reader.readAsDataURL(newVideo);
        reader.onload = (event) => {
          let newVideoSrc = (<FileReader>event.target)?.result?.toString();
          if(newVideoSrc != null){
            let videoURL = newVideoSrc.replace('video/quicktime', 'video/mp4');
            metricData.mediaLink = videoURL.toString();
          }
        }
        metricData.value = FirestoreNutritionPlanService.generateUniqueString() + newVideo.getExtension();
        let videoPath = "users/" + this.user.uid + "/metric_data/" + metricData.getPrintableValue(this.translate);

        await this.userService.uploadFile(videoPath, newVideo);
      }
      catch(ex){
        console.error(ex);
        this.toastr.error(this.translate.instant("Upload des Bildes fehlgeschlagen."));
      }
      finally{
        this.spinner.hide("image-editor-spinner");
      }
    }
  }

  async uploadMetricDataImage(event: any, metricData: MetricData) {
    if (event.target.files && event.target.files[0]) {
      const dialogRef = this.dialog.open(ImageEditorComponent, {
        data: { imageFile: event.target.files[0], aspectRatio: 1, maintainAspectRatio: false, containWithinAspectRatio: true, maxDimension: 1080 },
        width: '1000px',
      });
      dialogRef.afterClosed().subscribe(async result => {
        if(result?.croppedImage) {
          try{
            this.spinner.show("image-editor-spinner");
            let thumbnailImageSrc: any = await this.blobToBase64(result.croppedImage);
            metricData.value = (new Date()).getTime().toString() + result.croppedImage.getExtension();
            metricData.mediaLink = thumbnailImageSrc;
            let imagePath = "users/" + this.user.uid + "/metric_data/" + metricData.getPrintableValue(this.translate);
            await this.userService.uploadImage(result.croppedImage, imagePath, metricData.getPrintableValue(this.translate));
          }
          catch(ex){
            console.error(ex);
            this.toastr.error(this.translate.instant("Upload des Bildes fehlgeschlagen."));
          }
          finally{
            this.spinner.hide("image-editor-spinner");
          }
        }
      });
    }
  }

  blobToBase64(blob): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }

  protected readonly GoalVisualizationOrigin = GoalVisualizationOrigin;
}
