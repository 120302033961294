import { FirestoreNutritionPlanService } from "../services/firestore-nutritionplan.service";
import { SetParameter } from "./training-plan.model";

export class TrainingVariable {
    public id: string = null;
    public name: string = "";
    public weight: number = undefined;
    public minPace: number = undefined;
    public maxPace: number = undefined;
    public pace: number = undefined;
    public minHeartRate: number = undefined;
    public maxHeartRate: number = undefined;
    public heartRate: number = undefined;

    public minPace500: number = undefined;
    public maxPace500: number = undefined;
    public pace500: number = undefined;

    public minReps: number = undefined;
    public maxReps: number = undefined;
    public reps: number = undefined;
    public useCalisthenicsFormula: boolean = false;


    //temp
    public paceAvailableOnInstantiation: boolean = false;
    public heartRateAvailableOnInstantiation: boolean = false;
    public weightAvailableOnInstantiation: boolean = false;
    public pace500AvailableOnInstantiation: boolean = false;
    public repsAvailableOnInstantiation: boolean = false;
    public isNew: boolean = false;
    
    constructor();
    constructor(init: TrainingVariable);
    constructor(init?: TrainingVariable) {
        if(!init?.id){
            this.isNew = true;
        }
        this.id = init && init.id || FirestoreNutritionPlanService.generateUniqueString()
        this.name = init && init.name || ""
        this.weight = init && init.weight !== undefined ? init.weight : undefined
        this.minPace = init && init.minPace !== undefined ? init.minPace : undefined
        this.maxPace = init && init.maxPace !== undefined ? init.maxPace : undefined
        this.pace = init && init.pace !== undefined ? init.pace : undefined
        this.minHeartRate = init && init.minHeartRate !== undefined ? init.minHeartRate : undefined
        this.maxHeartRate = init && init.maxHeartRate !== undefined ? init.maxHeartRate : undefined
        this.heartRate = init && init.heartRate !== undefined ? init.heartRate : undefined

        this.minPace500 = init && init.minPace500 !== undefined ? init.minPace500 : undefined
        this.maxPace500 = init && init.maxPace500 !== undefined ? init.maxPace500 : undefined
        this.pace500 = init && init.pace500 !== undefined ? init.pace500 : undefined

        this.minReps = init && init.minReps !== undefined ? init.minReps : undefined
        this.maxReps = init && init.maxReps !== undefined ? init.maxReps : undefined
        this.reps = init && init.reps !== undefined ? init.reps : undefined

        this.paceAvailableOnInstantiation = this.minPace !== undefined || this.maxPace !== undefined || this.pace !== undefined
        this.heartRateAvailableOnInstantiation = this.minHeartRate !== undefined || this.maxHeartRate !== undefined || this.heartRate !== undefined
        this.weightAvailableOnInstantiation = this.weight !== undefined
        this.pace500AvailableOnInstantiation = this.minPace500 !== undefined || this.maxPace500 !== undefined || this.pace500 !== undefined
        this.repsAvailableOnInstantiation = this.minReps !== undefined || this.maxReps !== undefined || this.reps !== undefined

        this.useCalisthenicsFormula = init && init.useCalisthenicsFormula || false

        if(!this.paceAvailableOnInstantiation && !this.heartRateAvailableOnInstantiation && !this.weightAvailableOnInstantiation && !this.pace500AvailableOnInstantiation && !this.repsAvailableOnInstantiation){
            this.paceAvailableOnInstantiation = true
            this.heartRateAvailableOnInstantiation = true
            this.weightAvailableOnInstantiation = true
            this.pace500AvailableOnInstantiation = true
            this.repsAvailableOnInstantiation = true
        }
    }

    clone(){
        return new TrainingVariable(this);
    }

    asMap(){
        let map = {}
        map['id'] = this.id
        if(this.name) map['name'] = this.name
        if(this.weight !== undefined) map['weight'] = this.weight
        if(this.minPace !== undefined) map['minPace'] = this.minPace
        if(this.maxPace !== undefined) map['maxPace'] = this.maxPace
        if(this.pace !== undefined) map['pace'] = this.pace
        if(this.minHeartRate !== undefined) map['minHeartRate'] = this.minHeartRate
        if(this.maxHeartRate !== undefined) map['maxHeartRate'] = this.maxHeartRate
        if(this.heartRate !== undefined) map['heartRate'] = this.heartRate
        if(this.minPace500 !== undefined) map['minPace500'] = this.minPace500
        if(this.maxPace500 !== undefined) map['maxPace500'] = this.maxPace500
        if(this.pace500 !== undefined) map['pace500'] = this.pace500
        if(this.minReps !== undefined) map['minReps'] = this.minReps
        if(this.maxReps !== undefined) map['maxReps'] = this.maxReps
        if(this.reps !== undefined) map['reps'] = this.reps
        if(this.useCalisthenicsFormula !== undefined && this.useCalisthenicsFormula == true) map['useCalisthenicsFormula'] = this.useCalisthenicsFormula
        return map
    }

    setVariableProperties(variable: TrainingVariable){
        this.id = variable.id
        this.name = variable.name
        this.weight = variable.weight
        this.minPace = variable.minPace
        this.maxPace = variable.maxPace
        this.pace = variable.pace
        this.minHeartRate = variable.minHeartRate
        this.maxHeartRate = variable.maxHeartRate
        this.heartRate = variable.heartRate
        this.minPace500 = variable.minPace500
        this.maxPace500 = variable.maxPace500
        this.pace500 = variable.pace500
        this.minReps = variable.minReps
        this.maxReps = variable.maxReps
        this.reps = variable.reps
        this.paceAvailableOnInstantiation = variable.paceAvailableOnInstantiation
        this.heartRateAvailableOnInstantiation = variable.heartRateAvailableOnInstantiation
        this.weightAvailableOnInstantiation = variable.weightAvailableOnInstantiation
        this.pace500AvailableOnInstantiation = variable.pace500AvailableOnInstantiation
        this.repsAvailableOnInstantiation = variable.repsAvailableOnInstantiation
        this.isNew = variable.isNew
        this.useCalisthenicsFormula = variable.useCalisthenicsFormula
    }
}
