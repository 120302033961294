import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { BehaviorSubject } from 'rxjs';
import { User } from '../model/user.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  updateTrigger: BehaviorSubject<boolean>
  showComposer: boolean = false
  showBadge: boolean = false

  notificationTitle: string
  notificationContent: string
  type: string
  newType: string
  typeId: string

  constructor(private firebaseFunctions: AngularFireFunctions) {
    this.updateTrigger = new BehaviorSubject(undefined)
  }

  composeNotification(title: string, content: string, type: string, newType: string, composerView: boolean, typeId?: string) {
    if (composerView != null) {
      this.showComposer = composerView
      this.showBadge = !composerView
    }
    this.notificationTitle = title
    this.notificationContent = content
    this.type = type
    this.newType = newType
    this.showComposer = true
    this.typeId = typeId ?? null
    this.updateTrigger.next(true)
  }

  composeNutritionPlanCreatedNotification(typeId: string, startDate: Date) {
    this.composeNotification('Neuer Ernährungsplan erhalten', 'Dein Coach hat dir einen neuen Ernährungsplan ab dem ' + startDate.asFormatedString() + ' erstellt.', 'NEW_ASSIGNMENTS', 'newNutritionPlan', true, typeId)
  }
  composeNutritionPlanUpdatedNotification(typeId: string) {
    this.composeNotification('Ernährungsplan aktualisiert', 'Dein Coach hat deinen Ernährungsplan aktualisiert.', 'NEW_ASSIGNMENTS', 'nutritionPlanUpdated', true, typeId)
  }
  composeNutritionalGoalNotification(typeId?: string) {
    this.composeNotification('Nährstoffziele aktualisiert', 'Dein Coach hat deine Nährstoffziele aktualisiert.', 'NEW_ASSIGNMENTS', 'newNutritionalGoal', true, typeId)
  }
  composeTrainingPlanCreationNotification(typeId: string) {
    this.composeNotification('Neuer Trainingsplan erhalten', 'Dein Coach hat dir einen neuen Trainingsplan erstellt.', 'NEW_ASSIGNMENTS', 'newTrainingPlan', true, typeId)
  }
  composeTrainingPlanUpdateNotification(typeId: string) {
    this.composeNotification('Trainingsplan aktualisiert', 'Dein Coach hat deinen Trainingsplan aktualisiert.', 'NEW_ASSIGNMENTS', 'trainingPlanUpdated', true, typeId)
  }
  composeFileExchangeUpdateNotification(typeId?: string) {
    var title = 'Dateiablage aktualisiert'
    var body = 'Dein Coach hat die Dateiablage aktualisiert.'
    if (environment.firebaseProjectId == 'traindoo-app') {
      title = 'Wissen aktualisiert'
      body = 'Dein Coach hat die Wissensdatenbank aktualisiert.'
    }
    this.composeNotification(title, body, 'NEW_ASSIGNMENTS', 'fileExchangeUpdated', true, typeId)
  }

  async sendNotification(user: User, type: string, title: string, text: string) {
    if (user && user.fcmToken && user.fcmToken?.length > 0) {
      const callable = user.isAndroidUser() ? this.firebaseFunctions.httpsCallable('sendNotificationHttps') : this.firebaseFunctions.httpsCallable('sendNotificationiOSHttps');
      await callable({ type: type, title: title, body: text, fcmToken: user.fcmToken }).pipe().toPromise()
    }
  }
}
