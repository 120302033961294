<div class="dialog-topbar">
    <div class="row">
        <div class="col">
            <div class="icon-button float-right" (click)="onCloseDialog()" translate>
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                Schließen
            </div>
        </div>
    </div>
</div>
<div class="dialog-content-wrapper">
    <h3 translate>Auszahlungsabgleich</h3>
    <div class="spacer-10"></div>
    <span [innerHTML]="getStripeReportHintTranslation()"></span>
    <div class="spacer-25"></div>
    <div class="upload-field" (click)="onUploadPayoutReport()" (dragover)="onDragOverUploadField($event)" (drop)="onDropOnUploadField($event)">
        <span class="label" translate>Auszahlungsabgleich (.csv) auswählen oder ablegen</span>
    </div>
    <input id="input-file" type="file" style="display: none;" accept=".csv" (change)="onPayoutFileUploaded($event)">
    <div class="spacer-25"></div>
    <div *ngIf="loading" class="loading-animation">
        <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
    </div>
</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>