import {Component, Input} from '@angular/core';
import {FirestoreService} from "../../services/firestore.service";
import {User} from '../../model/user.model';
import {TotalRevenueType} from "./graph-top-ten-revenue/graph-top-ten-revenue.component";
import {
  PaymentAnalyticsService,
  DropdownItem,
  GraphTimeRangeOptions
} from "../../services/payment-analytics.service";

enum PaymentAnalyticsStatisticId {
  REVENUE_OVER_TIME,
  ACTIVE_SALES,
  TOP10_REVENUE,
}

export enum PaymentAnalyticsItemSize {
  ONE_THIRD,
  TWO_THIRDS,
  HALF,
  FULL
}

export type PaymentAnalyticsConfig = {
  itemId: PaymentAnalyticsStatisticId;
  position: number;
  size: PaymentAnalyticsItemSize;
  top10ToggleState: TotalRevenueType;
  selectedTimeRange: DropdownItem;
  hiddenCustomers?: string[];
  hiddenProducts?: string[];
}

@Component({
  selector: 'app-payment-analytics',
  templateUrl: './payment-analytics.component.html',
  styleUrls: ['./payment-analytics.component.css']
})
export class PaymentAnalyticsComponent {
  protected readonly PaymentAnalyticsStatisticId = PaymentAnalyticsStatisticId;

  public user: User;
  @Input() set User(value: User) {
    this.user = value;
    this.init();
  }

  public totalElements = 6;
  public entryConfigs: PaymentAnalyticsConfig[] = [];

  constructor(public userService: FirestoreService, private paymentAnalyticsService: PaymentAnalyticsService) {
  }

  init() {
    this.entryConfigs = this.userService.getLoggedInUser().coach.portalSettingsCoach.paymentAnalyticsPageConfigs;
    const initDropDownItem = this.paymentAnalyticsService.getGraphTimeRange(GraphTimeRangeOptions.LAST_7_DAYS);
    if (this.entryConfigs.length == 0) {
      this.entryConfigs.push({
        itemId: PaymentAnalyticsStatisticId.REVENUE_OVER_TIME,
        position: 0,
        size: PaymentAnalyticsItemSize.HALF,
        top10ToggleState: null,
        selectedTimeRange: initDropDownItem
      });

      this.entryConfigs.push({
        itemId: PaymentAnalyticsStatisticId.ACTIVE_SALES,
        position: 1,
        size: PaymentAnalyticsItemSize.HALF,
        top10ToggleState: TotalRevenueType.BY_CUSTOMER,
        selectedTimeRange: initDropDownItem
      });

      this.entryConfigs.push({
        itemId: PaymentAnalyticsStatisticId.TOP10_REVENUE,
        position: 2,
        size: PaymentAnalyticsItemSize.HALF,
        top10ToggleState: TotalRevenueType.BY_CUSTOMER,
        selectedTimeRange: initDropDownItem,
        hiddenCustomers: [],
        hiddenProducts: []
      });
    }
  }

  isConfigEnabled(itemId?: PaymentAnalyticsStatisticId) {
    if(itemId === null) {
      return Object.keys(PaymentAnalyticsStatisticId).filter(key => isNaN(Number(key))).length === this.entryConfigs.length;
    }
    return this.entryConfigs.filter(x => x.itemId == itemId).length > 0;
  }

  getColSize(config: PaymentAnalyticsConfig) {
    if (config.size == PaymentAnalyticsItemSize.FULL) return 'col-12';
    if (config.size == PaymentAnalyticsItemSize.HALF) return 'col-lg-6';
    if (config.size == PaymentAnalyticsItemSize.ONE_THIRD) return 'col-lg-4';
    if (config.size == PaymentAnalyticsItemSize.TWO_THIRDS) return 'col-lg-8';
    return 'col-lg-12';
  }

  onAddConfigEntry(itemId: PaymentAnalyticsStatisticId) {
    this.entryConfigs.push({
      itemId: itemId,
      position: this.entryConfigs.length,
      size: PaymentAnalyticsItemSize.HALF,
      top10ToggleState: itemId === PaymentAnalyticsStatisticId.TOP10_REVENUE ? TotalRevenueType.BY_CUSTOMER: null,
      selectedTimeRange: this.paymentAnalyticsService.getGraphTimeRange(GraphTimeRangeOptions.LAST_7_DAYS),
      hiddenCustomers: itemId === PaymentAnalyticsStatisticId.TOP10_REVENUE ? []: null,
      hiddenProducts: itemId === PaymentAnalyticsStatisticId.TOP10_REVENUE ? []: null
    });
    this.onConfigSettingChanged();
  }

  onRemoveConfig(config: PaymentAnalyticsConfig) {
    this.entryConfigs = this.entryConfigs.filter(x => x != config);
    this.onConfigSettingChanged();
  }

  onChangeConfigSize(config: PaymentAnalyticsConfig) {
    this.onConfigSettingChanged();
  }

  onMoveConfigUp(config: PaymentAnalyticsConfig) {
    const index = this.entryConfigs.indexOf(config);
    if (index > 0) {
      this.entryConfigs[index] = this.entryConfigs[index - 1];
      this.entryConfigs[index - 1] = config;
    }
    this.entryConfigs.forEach((config, index) => {config.position = index});
    this.onConfigSettingChanged();
  }

  onMoveConfigDown(config: PaymentAnalyticsConfig) {
    const index = this.entryConfigs.indexOf(config);
    if (index < this.entryConfigs.length - 1) {
      this.entryConfigs[index] = this.entryConfigs[index + 1];
      this.entryConfigs[index + 1] = config;
    }
    this.entryConfigs.forEach((config, index) => {config.position = index});
    this.onConfigSettingChanged();
  }

  onConfigSettingChanged() {
    this.userService.getLoggedInUser().coach.portalSettingsCoach.paymentAnalyticsPageConfigs = this.entryConfigs;
    this.userService.updatePortalSettingsForCoach(this.userService.getLoggedInUser());
  }
}
