<div class="container-fluid padding-0">
    <div class="page-content">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <h2 class="display-inlineblock">Outreach-Dashboard</h2>
                    <ng-container *ngIf="selectedUsers?.length > 0">
                        <div class="label marginleft-25 display-inlineblock">
                            {{selectedUsers.length}} Nutzer ausgewählt
                        </div>
                        <div class="icon-button marginleft-25" (click)="onSendBroadcastMessage()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
                                <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z"/>
                            </svg>
                            <span class="icon-button-text"> Nachricht senden</span>
                        </div>
                        <div class="icon-button marginleft-25" (click)="onHideSelectedUsers()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                            </svg>
                            <span class="icon-button-text"> Verbergen</span>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="areUsersLoaded" class="col-12">
                    <div class="layout-box table-wrapper-box">
                        <div class="table">
                            <div class="row table-item table-header">
                                <div class="select-col">
                                    <input type="checkbox" (change)="onSelectAllUsers($event.target.checked)">
                                </div>
                                <div class="col">
                                    Name
                                </div>
                                <div class="actions"></div>
                                <div class="col">
                                    Geschlecht
                                </div>
                                <div class="col">
                                    Aktuelles Gewicht
                                </div>
                                <div class="col">
                                    Startgewicht
                                </div>
                                <div class="col">
                                    Geburtsdatum
                                </div>
                                <div class="col">
                                    Lizenz
                                    <app-filter-selection-dropdown class="marginleft-10" [availableFilterObjects]="availableLicenceTypes" [selectedLanguage]="'de'"></app-filter-selection-dropdown>
                                </div>
                                <div class="col sort-attribute {{usersSortAttribute == 'signUpDate' ? 'active' : ''}} {{usersSortDirection}}" (click)="onUsersSortChange('signUpDate')">
                                    Sign-up-Datum
                                </div>
                                <div class="col sort-attribute {{usersSortAttribute == 'lastAppUseDate' ? 'active' : ''}} {{usersSortDirection}}" (click)="onUsersSortChange('lastAppUseDate')">
                                    Letzte App-Nutzung
                                </div>
                                <div class="col sort-attribute {{usersSortAttribute == 'lastChatMessageDate' ? 'active' : ''}} {{usersSortDirection}}" (click)="onUsersSortChange('lastChatMessageDate')">
                                    Letzter Chat-Kontakt
                                </div>
                            </div>
                
                            <div class="row table-item link" *ngFor="let client of getUsers()">
                                <div class="select-col">
                                    <input type="checkbox" (change)="onSelectUser(client, $event.target.checked)" [checked]="selectedUsers.includes(client)">
                                </div>
                                <div class="col">
                                    {{client.user.getName()}}
                                </div>
                                <div class="actions">
                                </div>

                                <ng-container *ngIf="getDataForUser(client) | async; let userDataWrapper">
                                    <div class="col">
                                        {{userDataWrapper.user.gender}}
                                    </div>
                                    <div class="col">
                                        {{userDataWrapper.user.latestBodyWeight}}
                                    </div>
                                    <div class="col">
                                        {{userDataWrapper.user.firstBodyWeight}}
                                    </div>
                                    <div class="col">
                                        {{userDataWrapper.user.birthDate?.asFormatedString()}}
                                    </div>
                                    <div class="col">
                                        {{userDataWrapper.licenceType}}
                                    </div>
                                    <div class="col">
                                        {{userDataWrapper.user.signUpDate?.asFormatedString()}}
                                    </div>
                                    <div class="col">
                                        {{userDataWrapper.user.lastAppUseDate?.asFormatedString()}}
                                    </div>
                                    <div class="col">
                                        <div *ngIf="getChatForClient(userDataWrapper); let chat; else noChat"  (click)="onOpenChat(chat);$event.stopPropagation()">
                                            <div *ngIf="chat?.getLatestMessage() != null" class="message-date display-inlineblock">{{chat?.getLatestMessage()?.time?.asFormatedString()}}</div>
                                            <div *ngIf="chat?.getLatestMessage() == null" class="message-date display-inlineblock">
                                                <div class="icon-button">
                                                    Öffnen
                                                </div>
                                            </div>
                                            <div class="indicator small marginleft-10 display-inlineblock" *ngIf="chat?.unreadMessages?.length > 0" tooltip="Du hast ungelesene Nachrichten."></div>
                                        </div>
                                        <ng-template #noChat>
                                            <div class="icon-button" (click)="onCreateAndOpen(userDataWrapper);$event.stopPropagation()">
                                                Kontaktieren
                                            </div>
                                        </ng-template>
                                    </div>
                                </ng-container>
                                
                            </div>

                            <div class="row table-item">
                                <div class="col">
                                    {{filteredUsers.length}} Nutzer gefunden
                                </div>
                                <div class="col">
                                </div>
                                <div class="col">
                                </div>
                                <div class="col">
                                </div>
                                <div class="col">
                                </div>
                            </div>
                        </div>

                        <button class="button change-page p-0" *ngIf="listViewPage > 0" (click)="goToPreviousPage()">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                            </svg>
                        </button>
                        <div class="current-page">Seite {{listViewPage + 1}} von {{maxPage + 1}}</div>
                        <button class="button change-page p-0" *ngIf="listViewPage < maxPage" (click)="goToNextPage()">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="!areUsersLoaded" class="col-12">
                <div class="spinner-wrapper">
                    <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
                </div>
            </div>
    </div>
</div>
<div *ngIf="userService.getLoggedInUser().isCoach" class="container">
    <div class="row">
        <div class="col">
            <chat>
            </chat>
        </div>
    </div>
</div>