<div id="recipe-editor" class="layout-box" (window:beforeunload)="doBeforeUnload()">
    <div class="container-fluid component-wrapper paddingbottom-safezone" >
        <div class="top-bar">
            <div *ngIf="!readOnlyMode" class="icon-button" (click)="onSaveRecipe()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                </svg>
                <span class="icon-button-text" translate>Speichern</span>
            </div>
            <div class="display-inlineblock marginleft-25">
                <app-language-selector></app-language-selector>                
                <div *ngIf="!readOnlyMode && languageService.canUseAutomaticTranslation()" class="icon-button" (click)="translateRecipeIntoAllLanguages()" translate>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-magic" viewBox="0 0 16 16">
                        <path d="M9.5 2.672a.5.5 0 1 0 1 0V.843a.5.5 0 0 0-1 0zm4.5.035A.5.5 0 0 0 13.293 2L12 3.293a.5.5 0 1 0 .707.707zM7.293 4A.5.5 0 1 0 8 3.293L6.707 2A.5.5 0 0 0 6 2.707zm-.621 2.5a.5.5 0 1 0 0-1H4.843a.5.5 0 1 0 0 1zm8.485 0a.5.5 0 1 0 0-1h-1.829a.5.5 0 0 0 0 1zM13.293 10A.5.5 0 1 0 14 9.293L12.707 8a.5.5 0 1 0-.707.707zM9.5 11.157a.5.5 0 0 0 1 0V9.328a.5.5 0 0 0-1 0zm1.854-5.097a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L8.646 5.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0l1.293-1.293Zm-3 3a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L.646 13.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0z"/>
                    </svg>
                    In alle Sprachen übersetzen
                </div>
            </div>
            <div class="icon-button float-right" (click)="onCloseRecipe()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                <span class="icon-button-text" translate>Schließen</span>
            </div>
            <div *ngIf="!readOnlyMode" class="icon-button float-right delete-recipe marginleft-25 marginright-25" (click)="onDeleteRecipe()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="padding-4 bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                </svg>
                <span class="icon-button-text" translate>Löschen</span>
            </div>
            <div *ngIf="!readOnlyMode" class="icon-button float-right" (click)="onDuplicateRecipe()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="padding-4 bi bi-files" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"/>
                    <path d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z"/>
                </svg>
                <span class="icon-button-text" translate>Duplizieren</span>
            </div>
        </div>
        <div class="spacer-50"></div>
        
        <div class="row">
            <div class="col-6 col-sm-3 col-md-2">
                <div class="thumbnail-image link image-dropzone" (click)="onUploadRecipeImage()" [ngStyle]="{'background-image':'url('+ selectedRecipe?.thumbnailURL +')'}">
                    <div *ngIf="!selectedRecipe?.thumbnailURL" for="input-recipeimage" class="dropzone-hint">
                        Format: 800x800px (1:1)
                    </div>
                    <input id="input-recipeimage" type="file" style="display: none;" accept=".jpg, .png, .jpeg" (change)="onNewRecipeImageUploaded($event)">
                </div>
                <div class="spacer-10"></div>
                <div *ngIf="!readOnlyMode && canUseImageGeneration()" class="icon-button" (click)="autoGenerateRecipeImage()" translate>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-magic" viewBox="0 0 16 16">
                        <path d="M9.5 2.672a.5.5 0 1 0 1 0V.843a.5.5 0 0 0-1 0zm4.5.035A.5.5 0 0 0 13.293 2L12 3.293a.5.5 0 1 0 .707.707zM7.293 4A.5.5 0 1 0 8 3.293L6.707 2A.5.5 0 0 0 6 2.707zm-.621 2.5a.5.5 0 1 0 0-1H4.843a.5.5 0 1 0 0 1zm8.485 0a.5.5 0 1 0 0-1h-1.829a.5.5 0 0 0 0 1zM13.293 10A.5.5 0 1 0 14 9.293L12.707 8a.5.5 0 1 0-.707.707zM9.5 11.157a.5.5 0 0 0 1 0V9.328a.5.5 0 0 0-1 0zm1.854-5.097a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L8.646 5.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0l1.293-1.293Zm-3 3a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L.646 13.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0z"/>
                    </svg>
                    Generieren
                </div>
            </div>
            <div *ngIf="utilityService.onSmallDisplay() && !readOnlyMode" class="col-6">
                <div *ngIf="recipeSharingEnabled" class="recipesharing-toggle toggle-wrapper">
                    <div class="toggle {{selectedRecipe?.shared ? 'on' : ''}}" (click)="onShareSelectedRecipeChanged(!selectedRecipe?.shared)">
                        <div class="handle"></div>
                    </div>
                    <div class="text" translate>
                        Rezept teilen
                    </div>
                </div>
                <br><br>
                <button *ngIf="recipeSharingEnabled && selectedRecipe?.shared" class="button-filled light small dropdown-toggle" type="button" id="dropdownAssignedUsers" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{areAllUsersTargeted() ? ('Alle Kunden' | translate) : ('Ausgewählte Kunden' | translate)}}</button>
                <div *ngIf="recipeSharingEnabled && selectedRecipe?.shared" class="dropdown-menu dropdown-assignedusers" aria-labelledby="dropdownAssignedUsers">
                    <div class="dropdown-item link" (click)="onAllAssignedUserDropdownValueChanged(!areAllUsersTargeted());$event.stopPropagation()">
                        <input class="input checkbox" type="checkbox" [checked]="areAllUsersTargeted()"><div class="display-inlineblock">Alle</div>
                    </div>
                    <ng-container *ngIf="getSelectableClientGroups()?.length > 0">
                        <div class="dropdown-divider"></div>
                        <div class="label marginleft-10" translate>Gruppen</div>
                        <div class="dropdown-item link" *ngFor="let group of getSelectableClientGroups()" (click)="onAssignedGroupDropdownValueChanged(group);$event.stopPropagation()">
                            <input [disabled]="areAllUsersTargeted()" class="input checkbox" type="checkbox" [checked]="selectedRecipe?.assignedGroupNames.includes(group)"><div class="display-inlineblock">{{group}}</div>
                        </div>
                    </ng-container>
                    <div class="dropdown-divider"></div>
                    <div class="label marginleft-10" translate>Kunden</div>
                    <div class="dropdown-item link" *ngFor="let user of getSelectableClients()" (click)="onAssignedUserDropdownValueChanged(user);$event.stopPropagation()">
                        <input [disabled]="areAllUsersTargeted()" class="input checkbox" type="checkbox" [checked]="selectedRecipe?.assignedUsers.includes(user.uid)"><div class="display-inlineblock">{{user.getName()}}</div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-9">
                <div *ngIf="!utilityService.onSmallDisplay() && !readOnlyMode">
                    <div *ngIf="recipeSharingEnabled" class="recipesharing-toggle toggle-wrapper">
                        <div class="toggle {{selectedRecipe?.shared ? 'on' : ''}}" (click)="onShareSelectedRecipeChanged(!selectedRecipe?.shared)">
                            <div class="handle"></div>
                        </div>
                        <div class="text" translate>
                            Rezept teilen
                        </div>
                    </div>
                    <button *ngIf="recipeSharingEnabled && selectedRecipe?.shared" class="button-filled light small dropdown-toggle marginleft-25" type="button" id="dropdownAssignedUsers" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{areAllUsersTargeted() ? ('Alle Kunden' | translate) : ('Ausgewählte Kunden' | translate)}}</button>
                    <div *ngIf="recipeSharingEnabled && selectedRecipe?.shared" class="dropdown-menu dropdown-assignedusers" aria-labelledby="dropdownAssignedUsers">
                        <div class="dropdown-item link" (click)="onAllAssignedUserDropdownValueChanged(!areAllUsersTargeted());$event.stopPropagation()">
                            <input class="input checkbox" type="checkbox" [checked]="areAllUsersTargeted()"><div class="display-inlineblock" translate>Alle</div>
                        </div>
                        <ng-container *ngIf="getSelectableClientGroups()?.length > 0">
                            <div class="dropdown-divider"></div>
                            <div class="label marginleft-10" translate>Gruppen</div>
                            <div class="dropdown-item link" *ngFor="let group of getSelectableClientGroups()" (click)="onAssignedGroupDropdownValueChanged(group);$event.stopPropagation()">
                                <input [disabled]="areAllUsersTargeted()" class="input checkbox" type="checkbox" [checked]="selectedRecipe?.assignedGroupNames.includes(group)"><div class="display-inlineblock">{{group}}</div>
                            </div>
                        </ng-container>
                        <div class="dropdown-divider"></div>
                        <div class="label marginleft-10" translate>Kunden</div>
                        <div class="dropdown-item link" *ngFor="let user of getSelectableClients()" (click)="onAssignedUserDropdownValueChanged(user);$event.stopPropagation()">
                            <input [disabled]="areAllUsersTargeted()" class="input checkbox" type="checkbox" [checked]="selectedRecipe?.assignedUsers.includes(user.uid)"><div class="display-inlineblock">{{user.getName()}}</div>
                        </div>
                    </div>
                    <br><br>
                </div>
                <div class="icon-button col-12" *ngIf="languageService.canUseAutomaticTranslation() && !selectedRecipe?.nameTranslation[languageService.selectedLanguageCode] && selectedRecipe?.nameTranslation[languageService.mainLanguageCode]" (click)="languageService.translateRecipeName(selectedRecipe)" translate>
                    Automatisch übersetzen
                </div>
                <input [ngModel]="selectedRecipe?.getName(languageService.selectedLanguageCode)" (input)="onRecipeNameChanged($event.target.value)" class="input recipe-name" type="text" [placeholder]="'Rezeptname' | translate" required  [disabled]="readOnlyMode"/>
                <div *ngIf="showRecipeNameErrorMessage" class="error-message margin-10">Bitte gib einen Namen für das Rezept an.</div><br><br>
                <div class="display-block">
                    <span translate>Portionen: </span><input [ngModel]="selectedRecipe?.getServings()" (input)="onNumberServingsChanged($event.target.value)" class="input recipe-servings small textalign-right" type="text"  [disabled]="readOnlyMode"/>
                </div>
                <div class="display-block margintop-10">
                    <div class="display-inlineblock marginright-25">
                        <span translate>Zubereitungszeit: </span><input [ngModel]="selectedRecipe?.getPreparationTime()" (input)="onPreparationTimeChanged($event.target.value)" class="input recipe-servings small textalign-right" type="text"  [disabled]="readOnlyMode"/> min
                    </div>
                    <div class="display-inlineblock">
                        <span translate>Gesamtzeit: </span><input [ngModel]="selectedRecipe?.getTotalTime()" (input)="onTotalTimeChanged($event.target.value)" class="input recipe-servings small textalign-right" type="text"  [disabled]="readOnlyMode"/> min
                    </div>
                </div>
            </div>
        </div>
        <div class="spacer-25"></div>
        <h3 translate>Inhalt</h3>
        <div class="fontweight-bold">{{selectedRecipe?.getCarbohydrates().toFixed(0)}}{{'g K' | translate}} / {{selectedRecipe?.getProtein().toFixed(0)}}{{'g E' | translate}} / {{selectedRecipe?.getFat().toFixed(0)}}{{'g F' | translate}} / {{selectedRecipe?.getCalories().toFixed(0)}} {{'kcal' | translate}} {{'p. P.' | translate}}
            <div class="nutritionalvalues-button display-inlineblock link marginleft-10" [popover]="nutritionalValuePopover" (click)="onNutritionalValuesFocused(selectedRecipe)" placement="top" [outsideClick]="true" container="body">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                </svg>
            </div>
        </div>
        <div class="spacer-10"></div>
        <!--ng-container *ngIf="selectedRecipe?.getIngredients()?.length == 0">
            <div class="icon-button" (click)="onLoadRecipeBackup()">
                Wiederherstellen
            </div>
        </ng-container-->
        <div cdkDropList class="row meal-content" (cdkDropListDropped)="dropFood($event)">
            <div *ngFor="let food of selectedRecipe?.getIngredients()" class="col-12 col-lg-10 col-xxl-9 meal-item" cdkDrag>
                <div class="row">
                    <div class="col-12 food-header">
                        <div *ngIf="food.groupHeading">
                            <input [(ngModel)]="food.groupHeading" type="text" class="input small groupheading-input" [disabled]="readOnlyMode">
                            <div *ngIf="!readOnlyMode" class="icon-button marginleft-25" (click)="onDeleteGroupHeading(food)">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </div>
                        </div>
                        <div *ngIf="!food.groupHeading && !readOnlyMode" class="add-heading-marker-wrapper link" (click)="onAddGroupHeading(food)">
                            <div class="add-heading-marker">+ <span translate>Überschrift hinzufügen</span></div>
                        </div>
                    </div>
                    <div class="col-6 col-sm-6">
                        <div class="drag-handle link" cdkDragHandle>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-grip-vertical" viewBox="0 0 16 16">
                                <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                            </svg>
                        </div>
                        <div *ngIf="!food.isDummy" class="food-name">{{food.nutritionFact?.getName(languageService.selectedLanguageCode)}}<span class="brands">{{food.nutritionFact?.getBrands(languageService.selectedLanguageCode) != null ? ' (' + food.nutritionFact?.getBrands(languageService.selectedLanguageCode) + ')' : ''}}</span></div>
                        <div *ngIf="food.isDummy" class="food-name">{{food.name || ('Dummy' | translate)}}</div>
                        <div class="nutritional-values-small">
                            {{food.getCarbohydrates()?.toFixed(0)}}{{'g K' | translate}} / {{food.getProtein()?.toFixed(0)}}{{'g E' | translate}} / {{food.getFat()?.toFixed(0)}}{{'g F' | translate}} / {{food.getCalories()?.toFixed(0)}} {{'kcal' | translate}}
                        </div>
                    </div>
                    <div *ngIf="!food.isDummy" class="food-weight col-4 col-sm-3">
                        <div><input class="input-weight light small textalign-right" [ngModel]="food?.weight" (input)="onIngredientWeightChanged($event.target.value, food)" [disabled]="readOnlyMode"> {{food?.getUnit()}}</div>
                        <div *ngIf="food.getServing()" class="serving-size nutritional-values-small">{{food.weight / food.getServing().amount == 1 ? food.getServing().getPrintableName(translate) : (food.weight / food.getServing().amount).roundToPlaces(2) + 'x ' + food.getServing().getPrintableName(translate)}}</div>
                        <div *ngIf="utilityService.onSmallDisplay() && (!food.isDummy && (true))" class="display-inlineblock">
                            <input class="input checkbox width-auto display-inlineblock" type="checkbox" [checked]="food.allowWeightAdjustment" (change)="onSetAllowWeightAdjustment(food, $event.target.checked)"><div class="label display-inlineblock marginleft-10" [tooltip]="'Haken setzen, wenn dieses Lebensmittel vom Algorithmus unabhängig der anderen Lebensmittel im Rezept skaliert werden darf' | translate" translate> Flex.</div>
                        </div>
                    </div>
                    <div *ngIf="food.isDummy" class=" col-4 col-sm-3 col-xxxl-2">
                        <div *ngIf="food?.weight" class="marginleft-25">{{food?.weight}} {{food?.getUnit()}}</div>
                    </div>
                    <div *ngIf="!utilityService.onSmallDisplay() && (!food.isDummy && (true))" class="food-weight col-3 col-sm-2">
                        <input class="input checkbox width-auto display-inlineblock" type="checkbox" [checked]="food.allowWeightAdjustment" (change)="onSetAllowWeightAdjustment(food, $event.target.checked)"><div class="label display-inlineblock marginleft-10" [tooltip]="'Haken setzen, wenn dieses Lebensmittel vom Algorithmus unabhängig der anderen Lebensmittel im Rezept skaliert werden darf' | translate" translate> Flex.</div>
                    </div>
                    <div *ngIf="!readOnlyMode" class="delete-food col-2 col-sm-1">
                        <div class="icon-button float-right" (click)="onDeleteIngredient(food)">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="spacer-50"></div>
        <app-ingredient-selector *ngIf="!readOnlyMode"
            [mode]="'INGREDIENT'"
            [selectedFoodSearchSource]="'NUTRITIONFACTS'"
            (ingredientAdded)="onAddIngredient($event)">
        </app-ingredient-selector>

        <div class="spacer-50"></div>
        <h3 class="display-inlineblock" translate>Anweisungen</h3>
        <div class="icon-button col-12" *ngIf="languageService.canUseAutomaticTranslation() && !editorValue && selectedRecipe?.instructionsTranslation[languageService.mainLanguageCode]" (click)="languageService.translateRecipeInstructions(selectedRecipe)" translate>
            Automatisch übersetzen
        </div>
        <app-md-editor class="min-height"
            [readOnlyMode]="readOnlyMode"
            (valueChanged)="onEditorValueChanged($event)"
            [inputValue]="editorValue">
        </app-md-editor>
        <div class="spacer-25"></div>
        <h3 class="display-inlineblock">Tags</h3><br>
        <ng-container *ngFor="let tag of selectedRecipe?.getComputedTags()">
            <div class="chip link" (click)="removeTagFromSelectedRecipe(tag)">
                {{tag.getPrintableName(translate)}}
                <div *ngIf="!readOnlyMode" class="icon-button link" (click)="removeTagFromSelectedRecipe(tag)">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </div>
            </div>
        </ng-container>
        <br>
        <div class="mt-4"></div>
        <div *ngIf="!readOnlyMode">
            <div translate>
                Tags hinzufügen:
            </div>
            <ng-container *ngFor="let tag of defaultTags">
                <div *ngIf="!selectedRecipe?.matchesTag(tag)" class="chip link" (click)="addTagToSelectedRecipe(tag)">
                    {{tag.getPrintableName(translate)}}
                </div>
            </ng-container>
            <hr>
            <ng-container *ngFor="let tag of nutritionService.customTags">
                <div *ngIf="!selectedRecipe?.matchesTag(tag)" class="chip link" (click)="addTagToSelectedRecipe(tag)">
                    {{tag.getPrintableName(translate)}}
                    <div class="icon-button link" (click)="onRemoveSelectableTag(tag);$event.stopPropagation()">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
            </ng-container>
            <div class="chip" *ngIf="!readOnlyMode">
                <input class="input light small input-customtag" [ngModel]="customTagName"  (input)="onCustomTagNameChanged($event.target.value)" [placeholder]="'Eigener Tag' | translate">
                <div class="icon-button link" (click)="onAddCustomTag()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>
                </div>
            </div>
        </div>
        <div class="spacer-50"></div>
        
        
    </div>

</div>

<ng-template #nutritionalValuePopover>
    <app-nutritional-value-popover [nutritionalValueHolder]="nutritionalValueHolder"></app-nutritional-value-popover>
</ng-template>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "#4AE6D7" type = "ball-clip-rotate" [fullScreen] = "true"><div *ngIf="spinnerText" class="spinner-overlaytext" [innerHTML]="spinnerText"></div></ngx-spinner>