import { NumberOfRounds, PlannedTrainingExercise, SetParameter2LabelMapping, SetParameter2LabelUnitMapping, SetParameter2SubHeadingMapping, SetParameter2UnitMapping, SuperSetConfig, TrainingPlan, TrainingSet } from './../../model/training-plan.model';
import { TrainingService } from './../../services/training.service';
import { ExtendedTrackedTrainingExercise, TrackedExerciseSet, TrackedSuperSet, TrackedSuperSetRounds, TrackedTrainingExercise, TrackedTrainingSession, TrackedTrainingSet, TrackedVideoRecording } from './../../model/training-monitoring.model';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TrainingSession, SetParameter } from 'src/app/model/training-plan.model';
import { MergedTrainingExercise } from 'src/app/model/training-exercise';
import { VideoRecordingDialogComponent } from '../video-recording-dialog/video-recording-dialog.component';
import { Questionaire, QuestionaireResult } from 'src/app/model/questionaires.model';
import { CompletedQuestionaireResultsDialogComponent } from 'src/app/questionaire/completed-questionaire-results-dialog/completed-questionaire-results-dialog.component';
import { User } from 'src/app/model/user.model';
import { UtilityService } from 'src/app/services/utility.service';
import { Activity } from 'src/app/model/activity.model';
import { TrainingSessionContainer } from '../training-history-dialog/training-history-dialog.component';
import { FirestoreService } from 'src/app/services/firestore.service';
import { firstValueFrom } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { LanguageService } from 'src/app/services/language.service';
import { LanguageDictionary } from 'src/app/model/languagedictionary.model';
import { TrainingPlanEditorHelper } from 'src/app/training/training-plan-editor/utilities/training-plan-editor-helper';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-tracked-training-session',
  templateUrl: './tracked-training-session.component.html',
  styleUrls: ['./tracked-training-session.component.css']
})
export class TrackedTrainingSessionComponent {

  // public trainingSessionContainerList: TrainingSessionContainer[] = [];

  public selectedTrackedSuperSets: TrackedSuperSet[] = [];

  public setParameter2LabelMapping = SetParameter2LabelMapping;
  public setParameter2UnitMapping = SetParameter2UnitMapping;
  public setParameter2LabelUnitMapping = SetParameter2LabelUnitMapping;
  public setParameter2SubHeadingMapping = SetParameter2SubHeadingMapping;
  public setParameter = SetParameter;

  public selectedLanguageCode = "de"

  // public questionaireResults: QuestionaireResult[] = [];
  public selectedQuestionaireResults: QuestionaireResult[] = [];

  @Input() user: User;
  @Input() questionaireResults: QuestionaireResult[];

  public trainingSessionContainerList: TrainingSessionContainer[];
  @Input() set TrainingSessionContainerList(value: TrainingSessionContainer[]){
    this.trainingSessionContainerList = value;
    this.loadActivities()
  }

  @Output() TrainingSessionContainerListChange: EventEmitter<TrainingSessionContainer[]> = new EventEmitter<TrainingSessionContainer[]>();

  isCoach() {
    return this.userService.getLoggedInUser().isCoach
  }

  constructor(public dialog: MatDialog, public trainingService: TrainingService, public utilityService: UtilityService, private userService: FirestoreService, public spinner: NgxSpinnerService, public languageService: LanguageService, private toastr: ToastrService) 
  {
    // let selectedTrackedTrainingSession = data.selectedTrackedTrainingSession;
    // let selectedPlannedTrainingSession = data.selectedPlannedTrainingSession;
    // this.questionaireResults = data.questionaireResults;
    // this.trainingSessionContainerList = data.trainingSessionContainerList || null;
    // this.userUid = data.user?.uid;
    // this.user = data.user;
    // console.log(this.trainingSessionContainerList)
    // if(this.trainingSessionContainerList == null){
    //   this.trainingSessionContainerList = [];
    //   this.trainingSessionContainerList.push(new TrainingSessionContainer(selectedTrackedTrainingSession, selectedPlannedTrainingSession, this.questionaireResults));
    // }
  }

  getTimeOfSuperSet(superSet: TrackedSuperSet){
    let time = 0;
    superSet?.trackedSuperSetRounds.forEach(round => {
      let seconds = round?.getTimeInSeconds();
      if(seconds > 0){
        time += seconds;
      }
    });
    
    return new Date(0,0,0,0,0,time,0);
  }

  async loadActivities() {
    for (var container of this.trainingSessionContainerList) {
      if (container.activity) continue

      var activities = await firstValueFrom(this.userService.getActivitiesByDate(this.user, container.trackedTrainingSession.startDate))
      activities.forEach(activity => {
        if (activity.trackedSessionId == container.trackedTrainingSession.id){
          container.activity = activity;
        }
      })
    }
  }

  ngOnInit(): void {
    if (this.trainingSessionContainerList?.length == 1 && this.trainingSessionContainerList[0].trackedTrainingSession?.viewedByCoach == false) {
      this.userService.markTrackedTrainingSessionAsViewed(this.trainingSessionContainerList[0].trackedTrainingSession, this.user)
    }
  }

  closeTrainingSessionContainer(trainingSessionContainer: TrainingSessionContainer){
    this.trainingSessionContainerList = this.trainingSessionContainerList.filter(x => x !== trainingSessionContainer);
    this.TrainingSessionContainerListChange.emit(this.trainingSessionContainerList);
  }
  
  getPlannedExerciseById(exerciseId: string, trainingSessionContainer: TrainingSessionContainer):PlannedTrainingExercise{
    return trainingSessionContainer.plannedTrainingSession?.exercises.filter(x => x.id == exerciseId)[0]
  }
  getExerciseById(exerciseId: string):MergedTrainingExercise{
    return this.trainingService.getExerciseById(exerciseId)
  }
  
  hasExertionValues(sets: TrackedTrainingSet[]):boolean {
    return sets.filter(x => x.exertionValue).length > 0
  }

  hasAnyPauseDuration(trainingSets: TrackedExerciseSet[]): boolean{
    return trainingSets.filter(x => x.trackedTrainingSet.pauseDuration > 0).length > 0
  }
  trackedTrainingSetHasAnyPauseDuration(trainingSets: TrackedTrainingSet[]): boolean{
    return trainingSets.filter(x => x.pauseDuration > 0).length > 0
  }

  getAvailableSetParameters(trainingSets: TrackedTrainingSet[]): SetParameter[]{
    return Object.values(SetParameter).filter(parameter => this.hasValues(trainingSets, parameter))
  }
  getAvailableSetParametersByTrackedExerciseSets(exerciseSets: TrackedExerciseSet[]): SetParameter[]{
    return TrainingSessionContainer.getAvailableSetParametersByTrackedExerciseSets(exerciseSets);
  }

  trackedExercisesHaveValues(sets: TrackedExerciseSet[], setParameter: SetParameter): boolean {
    return TrainingSessionContainer.trackedExercisesHaveValues(sets, setParameter);
  }

  hasValues(sets: TrackedTrainingSet[], setParameter: SetParameter): boolean {
    return sets.filter(x => x[setParameter.toString()] != null).length > 0
  }

  getExertionUnitFromPlannedExercise(exercise: PlannedTrainingExercise): string{
    if(exercise.exertionUnit) {
      return exercise.exertionUnit;
    }
    else if(exercise.setParameters.includes(SetParameter.rir)) {
      return this.setParameter2LabelUnitMapping[SetParameter.rir]
    }
    else if(exercise.setParameters.includes(SetParameter.rpe)){
      return this.setParameter2LabelUnitMapping[SetParameter.rpe]
    }
    return 'RPE/RIR'
  }

  getSecondsAsTime(inputSeconds: number): string{
    if(inputSeconds == undefined) return "";
    if(inputSeconds < 0) inputSeconds = 0
    return inputSeconds.asDurationString();
  }
  
  async onShowTrackedVideoRecording(recording: TrackedVideoRecording, exercise: TrackedTrainingExercise) {
    const dialogRef = this.dialog.open(VideoRecordingDialogComponent, { data: { userUid: this.user?.uid, trackedExercise: exercise, recording: recording, exercise: this.getExerciseById(exercise.exerciseId)}, width: '1000px', autoFocus: false}).afterClosed().subscribe(result => {
    })
  }

  onQuestionaireResultSelected(questionaireResult: QuestionaireResult)
  {
    if(this.selectedQuestionaireResults.includes(questionaireResult)){
      this.selectedQuestionaireResults = this.selectedQuestionaireResults.filter(x => x !== questionaireResult);
    }
    else {
      this.selectedQuestionaireResults.push(questionaireResult);
    }
  }

  onOpenQuestionaireResults(){
    if(this.selectedQuestionaireResults.length > 0){
      // let allAvailableQuestionaireResults = this.displayedUser.dailyConditions.map(x => x.questionaireResults).reduce((a, b) => a.concat(b), []);
      const dialogRef = this.dialog.open(CompletedQuestionaireResultsDialogComponent, { data: { selectedQuestionaireResults: this.selectedQuestionaireResults, allAvailableQuestionaireResults: this.questionaireResults, user: this.user}, autoFocus: false});
    }
  }

  onOpenQuestionaire(questionaireResult: QuestionaireResult){
    // let allAvailableQuestionaireResults = this.displayedUser.dailyConditions.map(x => x.questionaireResults).reduce((a, b) => a.concat(b), []);
    const dialogRef = this.dialog.open(CompletedQuestionaireResultsDialogComponent, { data: { selectedQuestionaireResults: [questionaireResult], allAvailableQuestionaireResults: this.questionaireResults, user: this.user}, autoFocus: false});
  }

  getPlannedSetParameterValue(trackedSuperSet: TrackedSuperSet, roundIndex: number, exerciseIndex: number, setParameter: SetParameter): string{
    try{
      
      return trackedSuperSet.plannedSuperSetRounds[roundIndex][exerciseIndex].get(setParameter);
    }
    catch(ex){
      console.error(ex);
    }
  }

  getNewPlannedSetParamterValue(trackedExercise: TrackedTrainingExercise, setIndex: number, setParameter: SetParameter): string{
    try{
      if(trackedExercise.sets.length > setIndex && trackedExercise.sets[setIndex].getValueBySetParameter(setParameter)){
        return "/" + trackedExercise.sets[setIndex].getValueBySetParameter(setParameter, this.languageService.selectedUnitSystem);
      }
      return "";
    }
    catch(ex){
      console.error(ex);
    }

  }

  onMarkVideosAsViewed() {
    this.trainingSessionContainerList.forEach(container => {
      container.trackedSuperSets.forEach(superSet => {
        superSet.trackedSuperSetExercises.forEach(exercise => {
          var hasChanges = false
          exercise.recordings.forEach(recording => {
            if (!recording.viewedByCoach) {
              recording.viewedByCoach = true
              hasChanges = true
            }
          })
          if (hasChanges) this.userService.markExerciseRecordingAsViewed(this.user.uid, exercise).then()
        })
      })
    })
  }
  
  isPartOfDropset(set: TrackedTrainingSet, sets: TrackedTrainingSet[], setIndex: number){
    if(set.isDropset){
      return true;
    }
    else if(sets.length > setIndex + 1){
      return sets[setIndex + 1].isDropset;
    }
  }

  async onSaveTrainingAsTemplate(trainingSessionContainer: TrainingSessionContainer){
    let trainingPlanTemplate = new TrainingPlan();
    trainingPlanTemplate.sessions = [];
    let plannedSession = new TrainingSession();
    for (let index = 0; index < trainingSessionContainer.trackedTrainingSession.trackedTrainingExercises.length; index++) {
      const exercise = trainingSessionContainer.trackedTrainingSession.trackedTrainingExercises[index];
      
      let plannedExercise = new PlannedTrainingExercise();
      plannedExercise.exerciseId = exercise.exerciseId;
      plannedExercise.sets = [];
      let equalPauseDurations = exercise.trackedSets?.map(x => x.pauseDuration)?.every((val, i, arr) => val === arr[0]);
      let setParameters = this.getAvailableSetParameters(exercise.trackedSets);
      if(equalPauseDurations){
        setParameters = setParameters.filter(x => x != SetParameter.pauseDuration);
      }
      plannedExercise.setParameters = setParameters;
      if(equalPauseDurations){
        plannedExercise.pauseDuration = exercise.trackedSets[0].pauseDuration;
      }
      for(let set of exercise.trackedSets){
        let plannedSet = new TrainingSet();
        for (var setParameter of setParameters) {
          if(setParameter == SetParameter.pauseDuration && equalPauseDurations) continue;
          plannedSet[setParameter.toString()] = set[setParameter];
        }
        plannedSet.isDropset = set.isDropset;
        plannedSet.isWarmupSet = set.isWarmupSet;
        plannedExercise.sets.push(plannedSet);
      }
      if(plannedExercise.groupHeading) {
        plannedExercise.groupHeading = exercise.groupHeading;
      }
      if(exercise.nextSupersetExerciseId) {
        plannedExercise.connectedSuperSetExercise = true;
        if(index > 0){
          let previousExercise = trainingSessionContainer.trackedTrainingSession.trackedTrainingExercises[index - 1];
          if(!previousExercise.nextSupersetExerciseId){
            plannedExercise.superSetConfig = new SuperSetConfig();
          }
        }
        else {
          plannedExercise.superSetConfig = new SuperSetConfig();
        }
      }
      else {
        let previousSuperSetExercise = trainingSessionContainer.trackedTrainingSession.trackedTrainingExercises.find(x => x.nextSupersetExerciseId == exercise.exerciseId);
        if(previousSuperSetExercise){
          plannedExercise.connectedSuperSetExercise = true;
        }
      }
      plannedSession.exercises.push(plannedExercise);
    }

    trainingSessionContainer.trackedSuperSets
    let sessionName = trainingSessionContainer.trackedTrainingSession?.sessionName ?? trainingSessionContainer.plannedTrainingSession?.name ?? "Training";
    trainingPlanTemplate.nameTranslation = new LanguageDictionary<string>(sessionName, sessionName);
    plannedSession.name = sessionName;
    trainingPlanTemplate.sessions.push(plannedSession);
    let trainingPlanEditorHelper = new TrainingPlanEditorHelper(this.trainingService, this.dialog, this.spinner, this.userService, this.toastr);
    await trainingPlanEditorHelper.saveTrainingPlanAsTemplate(trainingPlanTemplate, [], this.userService.getLoggedInUser());
  }
}
