import { Component, Input, OnDestroy } from '@angular/core';
import { DashboardComponent } from 'src/app/dashboard/dashboard-v1/dashboard.component';
import { Note } from 'src/app/model/note.model';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import * as marked from 'marked';
import { KeepAwake } from '@capacitor-community/keep-awake';
import { UtilityService } from 'src/app/services/utility.service';
import { AudioRecorder } from 'src/app/audio-recorder';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnDestroy {

  constructor(private userService: FirestoreService, private utilityService: UtilityService, private toastr: ToastrService, private spinner: NgxSpinnerService, loggingService: LoggingService) {
    this.audioRecorder = new AudioRecorder(600, toastr, utilityService, loggingService);
  }


  ngOnDestroy(): void {
    this.audioRecorder.Cancel();
  }

  public user: User
  public filteredNotes: Note[] = []
  public editableNote: Note = new Note()
  public audioRecorder: AudioRecorder;

  compiledMarkdown: string;
  editorValue: string;
  editorPlaceholder = 'Neue Notiz...';

  editorInputValue: string = "";
  forceEditorUpdate: boolean = false;
  noteName: string = "";

  _searchText: string = "";
  get searchText() {
    return this._searchText;
  }
  set searchText(value: string) {
    this._searchText = value;
    if(value.length > 0) {
      this.filteredNotes = this.user.notes?.filter(note => note.title?.toLowerCase().includes(value?.toLowerCase()) || note.content?.toLowerCase().includes(value?.toLowerCase()))
    }
    else {
      this.filteredNotes = this.user.notes
    }
  }

  onDeleteSearchInput() {
    this.searchText = ""
  }

  @Input() set setUser(value: User) {
    this.user = value
    this.filteredNotes = this.user.notes
  }

  onEditNote(note: Note) {
    this.editableNote = note.clone();
    this.forceEditorUpdate = true;
  }
  onEditorValueChanged(value: string) {
    if (value == this.editorPlaceholder || value?.length == 0) {
      this.editableNote.content = null
    } else {
      this.editableNote.content = value
    }
    this.compiledMarkdown = this.compileMarkdown(value);
  }
  compileMarkdown(value: string): string {
    if (value == null) return ""
    // Änderung durch Versionsdowngrade von 7 -> 4
    return marked.Parser.parse(marked.Lexer.lex(value));
    // return marked.parser(marked.lexer(value.replace(/\n/g, '<br>')));
  }
  onSelectAttachment() {
    document.getElementById('input-note-attachment').click()
  }
  onRemoveSelectedFile() {
    this.editableNote.attachment = null
    this.editableNote.attachedFileName = null
  }
  onFileSelected(e) {
    let file = e.target.files[0];
    this.editableNote.attachment = file
    this.editableNote.attachedFileName = file.name
  }
  onSaveNote() {
    if (this.editableNote.id == null) {
      this.editableNote.date = new Date()
      this.userService.insertNoteForUser(this.editableNote, this.user)
      DashboardComponent.delay(1000).then(() => {
        var notes = document.getElementById("notes-wrapper")
        try {
          notes.scrollTop = notes.scrollHeight;
        } catch(err) { }
      })
    } else {
      if ((this.editableNote.content == null || this.editableNote.content.length == 0) && this.editableNote.attachedFileName == null && this.editableNote.voiceRecordingFileName == null) {
        this.userService.deleteNoteForUser(this.editableNote, this.user)
      } else {
        this.userService.updateNoteForUser(this.editableNote, this.user)
      }
    }
    this.editableNote = new Note();
    this.onEditorValueChanged(null);
    // this.forceEditorUpdate = true;
  }

  onRemoveVoiceRecording() {
    this.editableNote.voiceRecordingFileName = null
    this.editableNote.voiceRecordingUrl = null
    this.editableNote.tempVoiceRecordingFile = null
  }

  async startAudioRecording() {
    if(!this.audioRecorder.IsRecording()){
      if(this.utilityService.onNativeMobileApp() && await KeepAwake.isSupported()) {
        await KeepAwake.keepAwake();
      }
      await this.audioRecorder.Start();
    }
  }

  public async stopAudioRecorder(){
    if(this.audioRecorder.elapsedSeconds > 0){
      this.spinner.show();
      try{
        this.editableNote.tempVoiceRecordingFile = await this.audioRecorder.Stop();
        this.editableNote.voiceRecordingFileName = this.editableNote.tempVoiceRecordingFile.name;
        this.editableNote.voiceRecordingUrl = null;
      }
      catch(e) {
        console.log(e)
        this.toastr.error("Es ist ein unbekannter Fehler aufgetreten.")
      }
      this.spinner.hide();
    }
    else {
      this.audioRecorder.Cancel()
    }

    if(this.utilityService.onNativeMobileApp() && await KeepAwake.isSupported()) {
      await KeepAwake.allowSleep();
    }
  }

  async cancelAudioRecording(){
    this.audioRecorder.Cancel()
    this.editableNote.tempVoiceRecordingFile = null


    if(this.utilityService.onNativeMobileApp() && await KeepAwake.isSupported()) {
      await KeepAwake.allowSleep();
    }
  }
}
