import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {GoalPlan, GoalPlanType} from "../../model/goal-plan.model";
import {User} from "../../model/user.model";
import {Subscription} from "rxjs";
import {GoalPlanService} from "../../services/goal-plan.service";
import * as moment from "moment";
import {GoalVisualizationConfig} from "../../graph/goal-visualization/goal-visualization.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-goal-summary',
  templateUrl: './goal-summary.component.html',
  styleUrls: ['./goal-summary.component.css']
})
export class GoalSummaryComponent implements OnInit, OnDestroy {
  get plan(): GoalPlan {
    return this._plan;
  }

  @Input()
  set plan(value: GoalPlan) {
    this._plan = value;

    if(this.initiated) this.init();
  }
  private _plan!: GoalPlan;

  get date(): Date {
    return this._date;
  }

  @Input()
  set date(value: Date) {
    this._date = value;
    // wird nur bei Wochen gesetzt, daher muss nur weekly neu getriggert werden
    if(this.initiated) this.initWeekly();
  }
  @Input() protected user!: User;
  private _date: Date;

  protected smallGUI: boolean = false;
  protected startValue: string = null;
  protected startDate: Date = null;
  protected nextGoalDate: Date = null;
  protected endGoalDate: Date = null;
  protected currentValue: string = null;
  protected planName: string = null;
  protected nextValue: string = null;
  protected nextText: string = null;
  protected endValue: string = null;
  protected endText: string = null;
  protected startTodayDiff: string = null;
  protected nextGoalDiff: string = null;
  protected hasNextGoal: boolean = false;
  protected hasNoProperToday: boolean = false;
  protected todayString: string = "Heute";
  protected initiated: boolean = false;

  protected currentConfig: GoalVisualizationConfig = null;
  protected nextConfig: GoalVisualizationConfig = null;
  protected endConfig: GoalVisualizationConfig = null;

  private subscriptions: Subscription[] = [];

  constructor(private goalPlanService: GoalPlanService, public translate: TranslateService) {
  }

  ngOnInit() {
    this.subscriptions.push(this.user.statisticsUpdated.subscribe(async updated => {
      if (updated) {
        this.init()
      }
    }));
  }

  ngOnDestroy() {
    if (this.subscriptions.length) {
      this.subscriptions.forEach(s => s.unsubscribe());
      this.subscriptions = [];
    }
  }

  private init() {
    if (this._plan.type !== GoalPlanType.PROGRESS) {
      this.smallGUI = true;
      this.initWeekly();
    } else {
      this.smallGUI = false;
      this.initProgress();
    }
    this.initiated = true;
  }

  private initProgress() {
    const firstStep = this._plan.goalSteps[0];
    const nextStep = this._plan.getNextStep();
    const endStep = this._plan.getEndStep();
    this.hasNextGoal = nextStep != endStep;
    const todayDaily = this.goalPlanService.getLatestDailyConditionByMetricId(this.user, this._plan.metric.metricId);


    if(!todayDaily || todayDaily.date < this.plan.startDate) {
      this.hasNoProperToday = true;
    }
    const todayMetricData = todayDaily?.getMetricDataByMetricId(this._plan.metric.metricId);


    const nextDirection = this._plan.getDirectionFromTo(this._plan.getActiveStep(), nextStep);
    const nextGoal = nextStep.value ?? (nextDirection > 0 ? nextStep.maxValue : nextStep.minValue);

    const endDirection = this._plan.getDirectionFromTo(this._plan.getActiveStep(), endStep);
    const endGoal = endStep.value ?? (endDirection > 0 ? endStep.maxValue : endStep.minValue);

    this.startValue = `${firstStep.value} ${this._plan.metric.unit ?? ""}`;
    if (this._plan.metric.isMetricTypeDuration()) {
      const dur = moment.utc(moment.duration(firstStep.value, "minutes").as('milliseconds')).format('HH:mm');
      this.startValue = `${dur} h`;
    }
    this.startDate = this._plan.startDate;

    if(!moment().isSame(todayDaily?.date, "day")) {
      this.todayString = todayDaily.date.asFormatedString();
    }
    this.currentValue = `${todayMetricData?.value || 0} ${this._plan.metric.unit ?? ""}`;
    if (this._plan.metric.isMetricTypeDuration()) {
      const dur = moment.utc(moment.duration(todayMetricData?.value, "minutes").as('milliseconds')).format('HH:mm');
      this.currentValue = `${dur} h`;
    }

    this.nextConfig = this.goalPlanService.getMetricConfig(this._plan.metric.metricId, this.user, this.plan.type, nextStep.date, false);
    this.nextValue = `${nextGoal} ${this._plan.metric.unit ?? ""}`;
    if (this._plan.metric.isMetricTypeDuration()) {
      const dur = moment.utc(moment.duration(nextGoal, "minutes").as('milliseconds')).format('HH:mm');
      this.nextValue = `${dur} h`;
    }
    this.nextText = nextStep.name;
    this.nextGoalDate = nextStep.date;

    this.endConfig = this.goalPlanService.getMetricConfig(this._plan.metric.metricId, this.user, this.plan.type, endStep.date, true);
    this.endValue = `${endGoal} ${this._plan.metric.unit ?? ""}`;
    if (this._plan.metric.isMetricTypeDuration()) {
      const dur = moment.utc(moment.duration(endGoal, "minutes").as('milliseconds')).format('HH:mm');
      this.endValue = `${dur} h`;
    }
    this.endText = endStep.name;
    this.endGoalDate = endStep.date;

    this.startTodayDiff = this.getStartTodayDiff();
    this.nextGoalDiff = this.getTodayNextGoalDiff();
  }

  private initWeekly() {
    this.currentConfig = this.goalPlanService.getMetricConfig(this._plan.metric.metricId, this.user, this._plan.type, this._date);
    if(!this.currentConfig) return;

    this.planName = `${this._plan.name}:`;
    this.currentValue = `${this.currentConfig.currentValue}`;
    if (this._plan.metric.isMetricTypeDuration()) {
      const dur = moment.utc(moment.duration(this.currentConfig.currentValue, "minutes").as('milliseconds')).format('HH:mm');
      this.currentValue = `${dur}`;
    }
  }

  private getStartTodayDiff(): string {
    const firstStep = this._plan.goalSteps[0];
    const todayDaily = this.goalPlanService.getLatestDailyConditionByMetricId(this.user, this._plan.metric.metricId);
    if (!todayDaily) return "0 " + this._plan.metric.unit ?? "";
    const todayMetricData = todayDaily.getMetricDataByMetricId(this._plan.metric.metricId);
    if (!todayMetricData) return "0 " + this._plan.metric.unit ?? "";
    const diff = firstStep.value - todayMetricData.value;
    if (this._plan.metric.isMetricTypeDuration()) {
      const dur = moment.utc(moment.duration(Math.abs(diff), "minutes").as('milliseconds')).format('HH:mm');
      return `${diff > 0 ? "-" : "+"}${dur} h`;
    }
    return `${diff > 0 ? "-" : "+"}${diff.toFixed(1)} ${this._plan.metric.unit ?? ""}`;
  }

  private getTodayNextGoalDiff(): string {
    const nextStep = this._plan.getNextStep();
    const todayDaily = this.goalPlanService.getLatestDailyConditionByMetricId(this.user, this._plan.metric.metricId);
    if (!todayDaily) return "0 " + this._plan.metric.unit ?? "";
    const todayMetricData = todayDaily.getMetricDataByMetricId(this._plan.metric.metricId);
    if (!nextStep || !todayMetricData) return "0 " + this._plan.metric.unit ?? "";
    let nextGoal = nextStep.value;
    if(nextGoal === null) {
      const direction = this._plan.getDirectionFromTo(this._plan.getActiveStep(), nextStep);
      nextGoal = direction > 0 ? nextStep.maxValue : nextStep.minValue;
    }
    const diff = (this.hasNoProperToday ? this.plan.getFirstStep().value : todayMetricData.value) - nextGoal;
    if (this._plan.metric.isMetricTypeDuration()) {
      const dur = moment.utc(moment.duration(Math.abs(diff), "minutes").as('milliseconds')).format('HH:mm');
      return `${diff > 0 ? "-" : "+"}${dur} h`;
    }
    return `${diff > 0 ? "-" : "+"}${diff.toFixed(1)} ${this._plan.metric.unit ?? ""}`;
  }
}
