import { Component } from '@angular/core';
import {PaymentAnalyticsService} from "../../../../services/payment-analytics.service";
import * as moment from "moment";

@Component({
  selector: 'app-kpi-yearly-rev',
  templateUrl: './kpi-yearly-rev.component.html',
  styleUrls: ['./kpi-yearly-rev.component.css']
})
export class KpiYearlyRevComponent {
  public result: string = null;
  public info: string = null;
  public growthRate: number = 0;

  constructor(private paymentAnalyticsService: PaymentAnalyticsService) {
    this.init();
  }

  private init() {
    const startMoment = moment().startOf('year');
    const endMoment = moment().endOf('day');
    const revenues = this.paymentAnalyticsService.getPaidProcessingAndFuturePayments().filter(payment => {
      const paidMoment = moment(payment.getDate());
      return paidMoment.isBetween(startMoment, endMoment, "day", "[]");
    });

    const rev = revenues.reduce((previousValue, currentValue) => previousValue + currentValue.amount/100, 0);
    this.result = `${this.paymentAnalyticsService.transformNumberToMoney(rev)}`;


    const lastYearStartMoment = moment().subtract(1, 'year').startOf('year');
    const lastYearEndMoment = moment().subtract(1, 'year').endOf('day');
    const lastYearsRevenues = this.paymentAnalyticsService.getPaidProcessingAndFuturePayments().filter(payment => {
      const paidMoment = moment(payment.getDate());
      return paidMoment.isBetween(lastYearStartMoment, lastYearEndMoment, "day", "[]");
    });

    const lastYearsRev = lastYearsRevenues.reduce((previousValue, currentValue) => previousValue + currentValue.amount/100, 0);

    this.growthRate = (rev/lastYearsRev) - 1;
    if(isNaN(this.growthRate)) {
      this.growthRate = Infinity;
    }
    this.info = `Veränderung im Vergleich zu ${this.paymentAnalyticsService.transformNumberToMoney(lastYearsRev)} vom ${lastYearStartMoment.format('DD.MM.YY')} bis zum ${lastYearEndMoment.format('DD.MM.YY')}: ${(this.growthRate * 100).toFixed(0)}%`;
  }

  protected readonly Infinity = Infinity;
}
