<div class="page-content">
    <div class="container-fluid">
        <div class="" *ngIf="environment.firebaseProjectId == 'traindoo-app' && utilityService.onMobile()">
            <app-library-mobile-navigation></app-library-mobile-navigation>
        </div>
        <h2 class="" translate>Rezepte</h2>
        <div class="row">
            <div class="col-12" *ngIf="needsMigration()">
                <div class="layout-box turquoise">
                    <h3 translate>Wichtig: Update für deine Rezeptdatenbank</h3>
                    <div *ngIf="clientsWithOldAppVersion">
                        <span class="bold" translate>ACHTUNG:</span> <span translate [translateParams]="{clientsWithOldAppVersionParameter: clientsWithOldAppVersion.length,  clientNamesWithOldAppVersionParameter: getClientNamesWithOldAppVersion()}">clientsWithOldAppVersionParameter Coachees (clientNamesWithOldAppVersionParameter) haben noch die alte App-Version und können die neuen Rezepte nicht nutzen. Bitte informiere sie über das Update, bevor du deine Rezepte updatest.</span>
                        <div class="spacer-25"></div>
                    </div>
                    <span translate="recipeDatabaseUpdateHintText">
                    </span>
                    <br>                    
                    <span class="bold" translate>ACHTUNG:</span> <span translate>Ersetze nur die Rezepte, die du nicht selbst abgeändert hast. Ansonsten werden deine Änderungen verworfen und unsere neue Rezept-Version übernommen.</span>
                    <div class="spacer-25"></div>
                    {{getNumberOfRecipeUpdates()}} <span translate>Updates verfügbar</span>
                    <div class="icon-button marginleft-25" (click)="onMigrateAllRecipes()" translate>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16">
                            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9"/>
                            <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z"/>
                        </svg>
                        Alle Rezepte durch neue Version ersetzen
                    </div>
                    <div class="spacer-10"></div>
                    <div class="icon-button" (click)="onHideRecipeMigrationHint()" translate>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                        </svg>
                        Abschließen
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="layout-box">
                    <div>
                        <h3 class="display-inlineblock" translate>Deine Rezepte</h3>
                        <button class="button-filled small create-recipe" (click)="onCreateRecipe()" translate>Erstellen</button>
                        <button class="button-filled small marginleft-25" (click)="onOpenRecipeDatabase()" translate>Rezeptdatenbank</button>

                        <div class="toggle-wrapper display-inlineblock marginleft-25 mt-1" *ngIf="environment.isWhitelabel && environment.firebaseProjectId != 'traindoo-app'">
                            <div class="toggle {{inAppRecipesMode ? 'on' : ''}}" (click)="onInAppRecipesModeChanged()">
                                <div class="handle"></div>
                            </div>
                            <div class="text" translate>
                                In-App Rezepte
                            </div>
                        </div>
                        <div class="display-inlineblock marginleft-10 float-right">
                            <div class="icon-button" mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-gear padding-4" viewBox="0 0 16 16">
                                    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0"/>
                                    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z"/>
                                </svg>
                                <span class="only-desktop" translate>Einstellungen</span>
                            </div>
                            <mat-menu #menu="matMenu" class="padding-15">
                                <span translate>Rezept-Einstellungen:</span>
                                <div class="spacer-10"></div>
                                <div class="recipesharing-toggle toggle-wrapper margin-4 mobile-marginbottom-10" [matTooltip]="'Durch diese Einstellung wird noch nichts automatisch geteilt. Du musst jedes Rezept individuell teilen und Nutzern zuweisen.' | translate">
                                    <div class="toggle {{recipeSharingEnabled ? 'on' : ''}}" (click)="onRecipeSharingEnabledChanged(!recipeSharingEnabled);$event.stopPropagation()">
                                        <div class="handle"></div>
                                    </div>
                                    <div class="text" translate>
                                        Rezept-Pool mit Coachees in App teilen
                                    </div>
                                </div>
                                <div class="spacer-10"></div>
                                <div *ngIf="recipeSharingEnabled" class="recipesharing-toggle toggle-wrapper margin-4 mobile-marginbottom-10">
                                    <div class="toggle {{autoRecipeSharingEnabled ? 'on' : ''}}" (click)="onAutoRecipeSharingEnabledChanged(!autoRecipeSharingEnabled);$event.stopPropagation()">
                                        <div class="handle"></div>
                                    </div>
                                    <div class="text" translate>
                                        Alle meine Rezepte im Rezept-Pool mit allen Coachees teilen
                                    </div>
                                </div>
                                <div class="spacer-10"></div>
                                <div *ngIf="recipeSharingEnabled" class="recipesharing-toggle toggle-wrapper margin-4 mobile-marginbottom-10" [matTooltip]="'Aktiviere, dass Coachees sich standardmäßig automatisch Rezeptvorschläge erstellen lassen können. Du kannst diese Einstellung bei jedem Coachee individuell überschreiben.' | translate">
                                    <div class="toggle {{isRecipeSuggestionsEnabled ? 'on' : ''}}" (click)="onRecipeSuggestionsEnabledChanged(!isRecipeSuggestionsEnabled);$event.stopPropagation()">
                                        <div class="handle"></div>
                                    </div>
                                    <div class="text" translate>
                                        Rezeptvorschläge in App zulassen
                                    </div>
                                </div>
                                <div class="spacer-10"></div>
                                <div class="recipesharing-toggle toggle-wrapper margin-4 mobile-marginbottom-10"  [matTooltip]="'Durch diese Einstellung können Coachees deine Rezepte und Mahlzeiten per QR-Code/Link mit anderen Benutzern teilen.' | translate">
                                    <div class="toggle qr-sharing-toggle {{recipeQRCodeSharingEnabled ? 'on' : ''}}" (click)="onIsRecipeQRCodeSharingEnabledChanged(!recipeQRCodeSharingEnabled);$event.stopPropagation()">
                                        <div class="handle"></div>
                                    </div>
                                    <div class="text" translate>
                                        Teilen meiner Rezepte mit anderen App-Nutzern zulassen
                                    </div>
                                </div>

                            </mat-menu>
                        </div>
                    </div><br>
                    <div class="row recipe-list">
                        <div class="col-12">
                            <div class="spacer-10"></div>
                            <input id="recipesearch-input" class="input wide" (input)="onRecipeSearchInputChanged($event.target.value)" [placeholder]="'Nach Name oder #Tag suchen' | translate" type="text">
                            <div class="icon-button deletesearch-icon delete-recipesearch" (click)="onDeleteRecipeSearchInput()">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </div>
                            <div class="action-toolbar display-inlineblock marginleft-25" *ngIf="selectedRecipes?.length > 0">
                                <div class="icon-button marginleft-25" (click)="onEditSharingOfSelectedRecipes()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
                                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
                                    </svg>
                                    <span class="icon-button-text" translate>Teilen ändern</span>
                                </div>
                                <div class="icon-button delete-recipe marginleft-25" (click)="onDeleteSelectedRecipes()">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="padding-4 bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                    </svg>
                                    <span class="icon-button-text" translate>Löschen</span>
                                </div>
                                <div class="icon-button delete-recipe marginleft-25" *ngIf="!inAppRecipesMode && environment.isWhitelabel && environment.firebaseProjectId != 'traindoo-app'" (click)="onCopySelectedRecipesToIap()">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="padding-4 bi bi-files" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"/>
                                        <path d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z"/>
                                    </svg>
                                    <span class="icon-button-text" translate>Zu In-App Rezepten kopieren</span>
                                </div>
                                <div class="icon-button marginleft-25" (click)="onSelectAllRecipes()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
                                        <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
                                        <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
                                    </svg>
                                    <span class="icon-button-text" translate>Alle auswählen</span>
                                </div>
                                <div class="icon-button delete-recipe marginleft-25" (click)="onResetSelectedRecipes()">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                    <span class="icon-button-text" translate>Auswahl aufheben</span>
                                </div>
                            </div>
                            <div class="spacer-25"></div>
                        </div>
                        <div *ngFor="let recipe of getFilteredRecipes()" class="col-12 col-xl-6">
                            <div class="layout-box sub-box link" (click)="onRecipeSelected(recipe)">
                                <div class="checkbox-wrapper" (click)="onAddRecipeToSelection(recipe);$event.stopPropagation()">
                                    <input class="input checkbox margin-vertical-auto h-100" type="checkbox" [checked]="selectedRecipes.includes(recipe)">
                                </div>

                                <div class="recipe-content-wrapper">
                                    <div *ngIf="recipe.thumbnailURL" class="recipe-image" [ngStyle]="{'background-image':'url('+ recipe.thumbnailURL +')'}"></div>
                                    <div *ngIf="!recipe.thumbnailURL">
                                        <div class="recipe-image" [ngStyle]="{'background-image':'url('+ (nutritionService.getRecipeThumbnailUrl(recipe) | async) +')'}"></div>
                                    </div>

                                    <div class="recipe-information">
                                        <div class="recipe-name bold display-inlineblock">{{recipe.getName(translate.currentLang)}}</div>
                                        <!--div *ngIf="recipe.modelVersion != 2 || recipe.thumbnailPath == null" class="indicator marginleft-10"></div-->
                                        <div class="spacer-10"></div>
                                        <div class="recipe-nutritional-values">
                                            {{recipe?.getCarbohydrates()?.toFixed(0)}}{{'g K' | translate}} / {{recipe?.getProtein()?.toFixed(0)}}{{'g E' | translate}} / {{recipe?.getFat()?.toFixed(0)}}{{'g F' | translate}} / {{recipe?.getCalories()?.toFixed(0)}} {{'kcal' | translate}}
                                        </div>
                                        <div class="spacer-10"></div>
                                        <div class="recipe-tags">
                                            <ng-container *ngFor="let tag of recipe.getComputedTags()">
                                                {{tag.getPrintableName(translate)}}&nbsp;&nbsp;
                                            </ng-container>
                                        </div>
                                        <div *ngIf="recipe.newRecipeVersion">
                                            <div class="spacer-10"></div>
                                            <div class="indicator"></div>
                                            <div class="label display-inlineblock marginleft-10"><span translate>Änderungen:</span> {{getRecipeVersionChanges(recipe)}}</div>
                                            <div class="spacer-10"></div>
                                            <div>
                                                <div class="icon-button" (click)="onMigrateToNewVersion(recipe);$event.stopPropagation()" translate>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16">
                                                        <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9"/>
                                                        <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z"/>
                                                    </svg>
                                                    Durch neue Version ersetzen
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!loadingRecipes && nutritionService.recipes?.length == 0" class="textalign-center">
                        <div class="spacer-50"></div>
                        <br><span translate>Du hast keine Rezepte in deiner Datenbank.</span><br><span translate>Erstelle eigene Rezepte oder befülle deine Datenbank mithilfe unserer Rezeptdatenbank über den Button oben.</span><br>
                        <div class="spacer-50"></div>
                    </div>
                    <div class="margin-10 label">{{getFilteredRecipes()?.length}} <span translate>Rezepte gefunden.</span></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="recipe-database-dialog" class="dialog-wrapper">
    <div class="dialog layout-box">
        <div class="dialog-topbar">
            <div class="row">
                <div class="col">
                    <h2 class="margin-0" translate>Rezeptdatenbank</h2>
                </div>
                <div class="col-2">
                    <div class="icon-button close float-right" (click)="toggleRecipeDatabaseDialog()">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="dialog-content-wrapper">
            <div *ngIf="!hasRecipeDatabaseAccess" translate>
                Unsere Rezeptdatenbank umfasst über 300 vollständige und geprüfte Rezepte mit Bild, Zutaten, Anweisungen und detaillierten Nährstoffangaben. Zudem wird sie jeden Monat um Rezepte verschiedener Kategorien erweitert.<br>
                Jedes Rezept kannst du direkt in deine eigene Sammlung aufnehmen und dann anpassen, mit deinen Kund:innen teilen und in Ernährungsplänen verwenden.<br>
                Melde dich bei uns, wenn du die Rezeptdatenbank nutzen möchtest.
            </div>
            <div *ngIf="hasRecipeDatabaseAccess" class="recipe-list">
                <div class="col-12 padding-0 marginbottom-10">
                    <div class="spacer-10"></div>
                    <input id="recipedatabasesearch-input" class="input wide" (input)="onRecipeDatabaseSearchInputChanged($event.target.value)" [placeholder]="'Nach Name oder #Tag suchen' | translate" type="text">
                    <div class="icon-button deletesearch-icon delete-recipesearch" (click)="onDeleteRecipeDatabaseSearchInput()">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                    <div class="icon-button float-right marginleft-25" (click)="onCopyRecipeDatabase()" translate>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                        Alle Rezepte hinzufügen
                    </div>
                    <div *ngIf="selectedDatabaseRecipes?.length > 0" class="icon-button float-right marginleft-25" (click)="onCopySelectedDatabaseRecipe()" translate>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                        Markierte Rezepte hinzufügen
                    </div>
                </div>
                <div class="col-12">
                    <div class="recipe-tags">
                        <div class="chip link client-group {{recipeFilter == ALL_RECIPES ? ' active' : ''}}" (click)="onSelectRecipeFilter(ALL_RECIPES)" translate>
                            Alle Rezepte
                        </div>
                        <div class="chip link client-group {{recipeFilter == UNUSED_RECIPES ? ' active' : ''}}" (click)="onSelectRecipeFilter(UNUSED_RECIPES)" translate>
                            Noch nicht in deiner Datenbank
                        </div>
                        <div class="chip link client-group {{recipeFilter == NEW_RECIPES ? ' active' : ''}}" (click)="onSelectRecipeFilter(NEW_RECIPES)" translate>
                            Neue Rezepte
                        </div>
                    </div>
                </div>
                <div class="spacer-25"></div>
                <div *ngFor="let recipe of getFilteredDatabaseRecipes()" class="col-12 padding-0">

                    <div class="layout-box sub-box link" (click)="onViewDatabaseRecipe(recipe)">

                        <div class="checkbox-wrapper" (click)="onAddDatabaseRecipeToSelection(recipe);$event.stopPropagation()">
                            <input class="input checkbox margin-vertical-auto h-100" type="checkbox" [checked]="selectedDatabaseRecipes.includes(recipe)">
                        </div>

                        <div class="recipe-content-wrapper">
                            <div *ngIf="recipe.thumbnailURL" class="recipe-image" [ngStyle]="{'background-image':'url('+ recipe?.thumbnailURL +')'}"></div>
                            <div class="recipe-information">
                                <div class="recipe-name bold display-inlineblock">{{recipe.getName(translate.currentLang)}}</div>
                                <div *ngIf="recipe.alreadyInDatabase" class="display-inlineblock marginleft-10" [matTooltip]="'Bereits in deiner Datenbank.' | translate">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16" class="bi bi-check2-circle">
                                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"></path>
                                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"></path>
                                    </svg>
                                </div>
                                <div *ngIf="isNewRecipe(recipe)" class="indicator marginleft-10"></div>
                                <div class="spacer-10"></div>
                                <div class="recipe-nutritional-values">
                                    {{recipe?.getCarbohydrates()?.toFixed(0)}}{{'g K' | translate}} / {{recipe?.getProtein()?.toFixed(0)}}{{'g E' | translate}} / {{recipe?.getFat()?.toFixed(0)}}{{'g F' | translate}} / {{recipe?.getCalories()?.toFixed(0)}} {{'kcal' | translate}}
                                </div>
                                <div class="spacer-10"></div>
                                <div class="recipe-tags" *ngIf="recipe.getComputedTags()">
                                    <ng-container *ngFor="let tag of recipe.getComputedTags()">
                                        {{tag.getPrintableName(translate)}}&nbsp;&nbsp;
                                    </ng-container>
                                </div>
                            </div>
                            <div class="recipe-footer">
                                <div class="spacer-10"></div>
                                <div class="icon-button" (click)="onAddDatabaseRecipe(recipe);$event.stopPropagation()" translate>
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                    </svg>
                                    Zu eigenen Rezepten hinzufügen
                                </div>
                                
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"><div *ngIf="spinnerText" class="spinner-overlaytext" [innerHTML]="spinnerText"></div></ngx-spinner>