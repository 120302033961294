<div class="layout-box adjust-mobile">
  <div *ngIf="user?.currentlyLoadingBodyDataStatistics" class="float-right">
    <mat-spinner class="loading-statistics-spinner" diameter="30" strokeWidth="2"></mat-spinner>
  </div>
  <div>
    <h3 class="display-inlineblock" translate>
      Ziele
    </h3>
    <button class="icon-button small" (click)="onAddMetricGoal()">
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
           xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
              d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
      </svg>
      <span translate>Neues Ziel planen</span>
    </button>
  </div>

  <div class="row">
    <div class="col-12 col-sm-6 col-lg-4 col-xl-3 col-xxxl-3 col-xxxxl-2" [class.inactive]="!plan.isActive()" *ngFor="let plan of progressPlans">
      <div class="layout-box sub-box cursor-pointer" (click)="onUpdateMetricGoal(plan)">
        <b>{{ plan.name }}</b>
        <app-goal-summary [plan]="plan" [user]="user"></app-goal-summary>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-lg-4 col-xl-3 col-xxxl-3 col-xxxxl-2">
      <div class="layout-box sub-box">
        <b translate>Wochenziele</b>
        <div class="date-selection-wrapper">
          <div class="date-selection">
            <button class="small button change-date" (click)="onShowPreviousWeek()">
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-left" fill="currentColor"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
              </svg>
            </button>
            <div class="date-display">{{ ('KW' | translate) + ' ' + today.getWeekOfYearNumber() }}</div>
            <button class="button change-date" (click)="onShowNextWeek()">
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="spacer-10"></div>
        <div class="cursor-pointer weeklygoal-item" [class.inactive]="!weeklyPlan.isActive(today)" *ngFor="let weeklyPlan of weeklyPlans"
           (click)="onUpdateMetricGoal(weeklyPlan)">
          <app-goal-summary [plan]="weeklyPlan" [user]="user" [date]="today"></app-goal-summary>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-lg-4 col-xl-3 col-xxxl-3 col-xxxxl-2">
      <div class="layout-box sub-box wrapper-dailygoals">
        <b translate>Aktuelle Tagesziele</b>
        <div class="spacer-10"></div>
        <p class="cursor-pointer dailygoal-item" [class.inactive]="!dailyPlan.isActive()" *ngFor="let dailyPlan of dailyPlans"
           (click)="onUpdateMetricGoal(dailyPlan)">
          <ng-container *ngIf="!(dailyPlan.metric.isMetricTypeYesNo() || dailyPlan.metric.isMetricTypeToDo()); else yesNoToDo">
            {{ dailyPlan.name }}: {{ dailyPlan.getCurrentGoal() }}
          </ng-container>
          <ng-template #yesNoToDo>
            {{ dailyPlan.name }}
          </ng-template>
        </p>
      </div>
    </div>
  </div>
</div>
