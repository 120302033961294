<div id="ingredient-selector">
    <div class="searchsource-selection">
        <button *ngIf="mode != INGREDIENT_MODE" class="button tab-button searchsource" (click)="onSelectSearchSource('CUSTOM_RECIPES')" translate>
            Eigene Rezepte
            <div *ngIf="selectedFoodSearchSource == 'CUSTOM_RECIPES'" class="underline"></div>
        </button>
        <button class="button tab-button searchsource" (click)="onSelectSearchSource('NUTRITIONFACTS')" translate>
            Lebensmittel
            <div *ngIf="selectedFoodSearchSource == 'NUTRITIONFACTS'" class="underline"></div>
        </button>
        <button class="button tab-button searchsource" (click)="onSelectSearchSource('DUMMIES')" translate>
            Dummies
            <div *ngIf="selectedFoodSearchSource == 'DUMMIES'" class="underline"></div>
        </button>
    </div>
    <div *ngIf="selectedFoodSearchSource == 'CUSTOM_RECIPES'" id="recipe-search">
        <input id="recipesearch-input" class="input plannedmeal-recipe" (input)="onRecipeSearchInputChanged($event.target.value)" (click)="onRecipeSearchFocused()" [placeholder]="'Rezept nach Name oder #Tag suchen' | translate" type="text">
        <div class="icon-button float-right deletesearch-icon delete-recipesearch" (click)="onDeleteRecipeSearch()">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
        </div>
        <div id="recipesearch-results" class="layout-box sub-box" *ngIf="filteredRecipeSearchResults?.length > 0 && recipeSearchFocused">
            <div *ngFor="let recipe of filteredRecipeSearchResults" class="search-result-item link" (click)="onRecipeSelected(recipe)">
                <div class="thumbnail-image">
                    <img draggable="false" *ngIf="recipe.thumbnailURL" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{recipe.thumbnailURL}}">
                    <div *ngIf="!recipe.thumbnailURL">
                        <img draggable="false" loading="lazy" (error)="utilityService.onImageErrorHide($event)" src="{{ (nutritionService.getRecipeThumbnailUrl(recipe) | async) }}">
                    </div>
                </div>
                <div class="display-block">
                    {{recipe.getName(translate.currentLang)}}
                    <div class="nutritional-values-small">
                        {{recipe.getCarbohydrates().toFixed(0)}}{{'g K' | translate}} / {{recipe.getProtein().toFixed(0)}}{{'g E' | translate}} / {{recipe.getFat().toFixed(0)}}{{'g F' | translate}} / {{recipe.getCalories().toFixed(0)}} {{'kcal' | translate}}
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="selectedRecipe" class="add-recipe-options">
            <input class="input-recipemultiplier light small textalign-right" [ngModel]="selectedRecipeMultiplier"  (input)="onRecipeMultiplierChanged($event.target.value)"> {{'Portionen' | translate}} = {{(selectedRecipe.getCarbohydrates() * selectedRecipeMultiplier).toFixed(0)}}{{'g K' | translate}} / {{(selectedRecipe.getProtein() * selectedRecipeMultiplier).toFixed(0)}}{{'g E' | translate}} / {{(selectedRecipe.getFat() * selectedRecipeMultiplier).toFixed(0)}}{{'g F' | translate}} / {{(selectedRecipe.getCalories() * selectedRecipeMultiplier).toFixed(0)}} {{'kcal' | translate}}
            <div class="icon-button add-to-meal" (click)="onAddRecipeToMeal()" translate>
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
                Zur Mahlzeit hinzufügen
            </div>
            <div *ngIf="canUseAutoServing" class="icon-button marginleft-25" (click)="onAdjustAndAddRecipeToMeal()" translate>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-magic" viewBox="0 0 16 16">
                    <path d="M9.5 2.672a.5.5 0 1 0 1 0V.843a.5.5 0 0 0-1 0zm4.5.035A.5.5 0 0 0 13.293 2L12 3.293a.5.5 0 1 0 .707.707zM7.293 4A.5.5 0 1 0 8 3.293L6.707 2A.5.5 0 0 0 6 2.707zm-.621 2.5a.5.5 0 1 0 0-1H4.843a.5.5 0 1 0 0 1zm8.485 0a.5.5 0 1 0 0-1h-1.829a.5.5 0 0 0 0 1zM13.293 10A.5.5 0 1 0 14 9.293L12.707 8a.5.5 0 1 0-.707.707zM9.5 11.157a.5.5 0 0 0 1 0V9.328a.5.5 0 0 0-1 0zm1.854-5.097a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L8.646 5.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0l1.293-1.293Zm-3 3a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L.646 13.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0z"/>
                </svg>
                Portion automatisch berechnen
            </div>
        </div>
    </div>
    <div [style.display]="selectedFoodSearchSource == 'NUTRITIONFACTS' ? 'block' : 'none'" id="nutritionfact-search">
        <div id="nutritionfact-search-box" class="search-panel">
            <div class="search-panel__results">
                <div id="searchbox" (click)="onNutritionFactSearchClicked()"></div>
                <div class="icon-button float-right deletesearch-icon delete-nutritionfactsearch" (click)="onDeleteNutritionFactSearch()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </div>
                <div class="nutritional-values display-none" translate [translateParams]="{carbsParameter: selectedNutritionFact?.getCarbohydrates().toFixed(0), proteinsParameter: selectedNutritionFact?.getProtein().toFixed(0), fatParameter: selectedNutritionFact?.getFat().toFixed(0), caloriesParameter: selectedNutritionFact?.getCalories().toFixed(0)}">
                    Pro 100g: carbsParameter g K / proteinsParameter g E / fatParameter g F / caloriesParameter kcal
                </div>
                <ng-template #nutritionalValuePopover>
                    <app-nutritional-value-popover [nutritionalValueHolder]="nutritionalValueHolder"></app-nutritional-value-popover>
                </ng-template>
                <div class="ais-Hits">
                    <ul class="ais-Hits-list" *ngIf="state?.hits?.length > 0 && nutritionFactSearchFocused">
                        <li *ngFor="let hit of state.hits" class="ais-Hits-item link" (click)="onNutritionFactSelected(hit)">
                        <div>{{ hit[typeSenseNameQuery] ?? hit?.nameDe }}
                            <span class="brands" *ngIf="hit.brands">({{ getBrands(hit.brands) }})</span>
                            <svg *ngIf="hit.detailed" xmlns="http://www.w3.org/2000/svg" class="detailed-nutritionfact display-inlineblock" width="120" height="32" viewBox="0 0 120 32">
                                <path id="icon_detailed" d="M96,36H-24V4H96V36ZM73.323,10.8V29H87.675V24.242H79.459V10.8Zm-9.62,0V29h6.136V10.8ZM47.713,25.828h6.917L55.851,29H62.2L54.239,10.8H48.207L40.251,29h6.241l1.221-3.171ZM29.565,15.558V29H35.7V15.558h5.33V10.8h-16.8v4.758ZM7.6,10.8V29H22.727V24.372h-9.1V21.98h7.7V17.56h-7.7V15.428h8.762V10.8Zm-22,0V29h8.97A12.432,12.432,0,0,0-.062,27.895a8.443,8.443,0,0,0,3.614-3.159A8.958,8.958,0,0,0,4.839,19.9a8.958,8.958,0,0,0-1.287-4.836A8.443,8.443,0,0,0-.062,11.905,12.432,12.432,0,0,0-5.431,10.8Zm8.71,13.416H-8.265V15.584h2.574a4.4,4.4,0,0,1,3.159,1.13A4.217,4.217,0,0,1-1.349,19.9a4.217,4.217,0,0,1-1.183,3.185A4.4,4.4,0,0,1-5.691,24.216Zm58.63-2.808H49.4l1.768-4.577,1.767,4.575Z" transform="translate(24 -4)"/>
                            </svg>
                        </div>
                        <div class="nutritional-values-small display-inlineblock">
                            {{ hit.carbohydrates?.toFixed(1) }}{{'g K' | translate}} / {{ hit.protein?.toFixed(1) }}{{'g E' | translate}} / {{ hit.fat?.toFixed(1) }}{{'g F' | translate}} / {{ hit.calories.toFixed(0) }} {{'kcal' | translate}}
                        </div>
                        <div class="nutritionalvalues-button display-inlineblock float-right" [popover]="nutritionalValuePopover" (click)="onNutritionFactFocused(hit); $event.stopPropagation()" placement="top" [outsideClick]="true" container="body">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                        </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div id="pagination" [style.display]="nutritionFactSearchFocused ? 'block' : 'none'"></div>
        <div *ngIf="selectedNutritionFact" class="add-nutritionfact-options">
            <div *ngIf="!selectedNutritionFactServing && !customNutritionFactServing">
                <input class="input-weight light small textalign-right" [ngModel]="selectedWeight"  (input)="onSelectedWeightChanged($event.target.value)"> {{selectedNutritionFact.getUnit()}} = {{(selectedNutritionFact.getCarbohydrates() * selectedWeight / 100).toFixed(0)}}{{'g K' | translate}}/ {{(selectedNutritionFact.getProtein() * selectedWeight / 100).toFixed(0)}}{{'g E' | translate}}/ {{(selectedNutritionFact.getFat() * selectedWeight / 100).toFixed(0)}}{{'g F' | translate}}/ {{(selectedNutritionFact.getCalories() * selectedWeight / 100).toFixed(0)}} {{'kcal' | translate}}
            </div>
            <div *ngIf="selectedNutritionFactServing && !customNutritionFactServing">
                <input class="input-servingmultiplier light small textalign-right" [ngModel]="selectedServingMultiplier"  (input)="onServingMultiplierChanged($event.target.value)"> x 
                <div class="serving-wrapper link">
                    <div class="button unselect-serving-button" (click)="unselectNutritionFactServing()">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                    <div class="serving">{{selectedNutritionFactServing.getPrintableName(translate)}}</div>
                </div>
                = {{(selectedNutritionFact.getCarbohydrates() * selectedNutritionFactServing.amount * selectedServingMultiplier / 100).toFixed(0)}}{{'g K' | translate}}/ {{(selectedNutritionFact.getProtein() * selectedNutritionFactServing.amount * selectedServingMultiplier / 100).toFixed(0)}}{{'g E' | translate}}/ {{(selectedNutritionFact.getFat() * selectedNutritionFactServing.amount * selectedServingMultiplier / 100).toFixed(0)}}{{'g F' | translate}}/ {{(selectedNutritionFact.getCalories() * selectedNutritionFactServing.amount * selectedServingMultiplier / 100).toFixed(0)}} {{'kcal' | translate}}
            </div>
            <div *ngIf="customNutritionFactServing">
                <div class="serving-wrapper link">
                    <div class="button unselect-serving-button" (click)="unselectNutritionFactServing()">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                    <input class="input wide light small" [ngModel]="customNutritionFactServingName"  (input)="onCustomServingNameChanged($event.target.value)" [placeholder]="'Bezeichnung' | translate">
                    <input class="input-weight light small textalign-right marginleft-10" [ngModel]="selectedWeight"  (input)="onSelectedWeightChanged($event.target.value)"> {{selectedNutritionFact.getUnit()}}
                </div>
                = {{(selectedNutritionFact.getCarbohydrates() * selectedWeight / 100).toFixed(0)}}{{'g K' | translate}}/ {{(selectedNutritionFact.getProtein() * selectedWeight / 100).toFixed(0)}}{{'g E' | translate}}/ {{(selectedNutritionFact.getFat() * selectedWeight / 100).toFixed(0)}}{{'g F' | translate}}/ {{(selectedNutritionFact.getCalories() * selectedWeight / 100).toFixed(0)}} {{'kcal' | translate}}
            </div>
            <div class="icon-button add-to-meal" (click)="onAddNutritionFactToMeal()" translate>
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
                Zur Mahlzeit hinzufügen
            </div>
            <div class="nutritionfact-servings">
                <ng-container *ngFor="let serving of selectedNutritionFact?.parsedServingSizes">
                    <div class="chip link" (click)="onServingSelected(serving)">{{serving.getPrintableName(translate)}}</div>
                </ng-container>
                <div class="chip link" (click)="onCreateCustomServing()" translate>Eigene Portion</div>
            </div>
        </div>
    </div>
    <div *ngIf="selectedFoodSearchSource == 'DUMMIES'" id="dummy-selection">
        <div class="row">
            <div class="col-12">
                <input id="dummysearch-input" class="input plannedmeal-recipe" (input)="onDummySearchInputChanged($event.target.value)" (click)="onDummySearchFocused()" [placeholder]="'Gespeicherte Dummies durchsuchen' | translate" type="text">
                <div class="icon-button float-right deletesearch-icon delete-recipesearch" (click)="onDeleteDummySearch()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </div>
                <div id="recipesearch-results" class="layout-box sub-box" *ngIf="dummySearchResults?.length > 0 && dummySearchFocused">
                    <div *ngFor="let nutritionFact of dummySearchResults" class="search-result-item link" (click)="onDummySelected(nutritionFact)">
                        <div class="display-block">
                            {{nutritionFact.getName()}}
                            <div class="nutritional-values-small">
                                {{nutritionFact.getCarbohydrates().toFixed(0)}}{{'g K' | translate}}/ {{nutritionFact.getProtein().toFixed(0)}}{{'g E' | translate}}/ {{nutritionFact.getFat().toFixed(0)}}{{'g F' | translate}}/ {{nutritionFact.getCalories().toFixed(0)}} {{'kcal' | translate}}
                            </div>
                            <div class="icon-button marginleft-10 display-inlineblock float-right" (click)="onDeleteDummyNutritionFact(nutritionFact);$event.stopPropagation()" translate>
                                Löschen
                            </div>
                        </div>
                    </div>
                </div>
                <div class="spacer-25"></div>
            </div>
            <div class="col-12 dummy-type" translate>Lebensmitteldummy:</div>
            <div class="col-12">
                <div>
                    <span class="">
                        <input class="input dummy-name small" [ngModel]="foodDummyName" (keyup)="onFoodDummyNameChanged($event.target.value)" type="text" [placeholder]="'Name (optional)' | translate"/>
                        <input class="input-nutrition dummy-weight light small textalign-right marginleft-10" [ngModel]="foodDummyWeight" (keyup)="onFoodDummyWeightChanged($event.target.value)"> g
                    </span><br>
                    <span class="marginleft-10" translate>
                        Pro 100g:
                    </span>
                    <span class="">
                        <input class="input-nutrition light small textalign-right marginleft-10" [ngModel]="foodDummyNutritionalSummary?.carbohydrates" (keyup)="onFoodDummyCarbohydratesChanged($event.target.value)"> {{'g K' | translate}}
                    </span>
                    <span class="">
                        <input class="input-nutrition light small textalign-right marginleft-10" [ngModel]="foodDummyNutritionalSummary?.protein" (keyup)="onFoodDummyProteinChanged($event.target.value)"> {{'g E' | translate}}
                    </span>
                    <span class="">
                        <input class="input-nutrition light small textalign-right marginleft-10" [ngModel]="foodDummyNutritionalSummary?.fat" (keyup)="onFoodDummyFatChanged($event.target.value)"> {{'g F' | translate}}
                    </span>
                    <span class="">
                        <input class="input-nutrition light small textalign-right marginleft-10" [ngModel]="foodDummyNutritionalSummary?.calories" placeholder="{{getFoodDummyCalories()}}" (keyup)="onFoodDummyCaloriesChanged($event.target.value)"> {{'kcal' | translate}}
                    </span>
                    <div *ngIf="foodDummyNutritionalSummary?.isValid(false)" class="icon-button add-to-meal" (click)="addFoodDummyToMeal()" translate>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                        Zur Mahlzeit hinzufügen
                    </div>
                    <div *ngIf="foodDummyNutritionalSummary?.isValid(false)" class="icon-button add-to-meal marginleft-10" (click)="onSaveFoodDummy()" translate>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-floppy" viewBox="0 0 16 16">
                            <path d="M11 2H9v3h2z"/>
                            <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z"/>
                        </svg>
                        Speichern
                    </div>
                </div>
            </div>
            <div class="col-12 dummy-type" translate>Dummy mit Nährstoffangaben:</div>
            <div class="col-12">
                <div>
                    <span class="">
                        <input class="input dummy-name small" [ngModel]="marcoDummyName" (keyup)="onMacroDummyNameChanged($event.target.value)" type="text" [placeholder]="'Name (optional)' | translate"/>
                    </span><br>
                    <span class="">
                        <input class="input-nutrition light small textalign-right" [ngModel]="macroDummyNutritionalSummary?.carbohydrates" (keyup)="onMacroDummyCarbohydratesChanged($event.target.value)"> {{'g K' | translate}}
                    </span>
                    <span class="">
                        <input class="input-nutrition light small textalign-right marginleft-10" [ngModel]="macroDummyNutritionalSummary?.protein" (keyup)="onMacroDummyProteinChanged($event.target.value)"> {{'g E' | translate}}
                    </span>
                    <span class="">
                        <input class="input-nutrition light small textalign-right marginleft-10" [ngModel]="macroDummyNutritionalSummary?.fat" (keyup)="onMacroDummyFatChanged($event.target.value)"> {{'g F' | translate}}
                    </span>
                    <span class="marginleft-10">= {{macroDummyNutritionalSummary?.calories}} {{'kcal' | translate}}</span>
                    <div *ngIf="macroDummyNutritionalSummary?.isValid()" class="icon-button add-to-meal" (click)="addMacroDummyToMeal()" translate>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                        Zur Mahlzeit hinzufügen
                    </div>
                    <div *ngIf="macroDummyNutritionalSummary?.isValid()" class="icon-button add-to-meal marginleft-10" (click)="onSaveMacroDummy()" translate>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-floppy" viewBox="0 0 16 16">
                            <path d="M11 2H9v3h2z"/>
                            <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z"/>
                        </svg>
                        Speichern
                    </div>
                </div>
            </div>
            <div class="col-12 dummy-type" translate>Dummy nur mit Kalorien:</div>
            <div class="col-12"><div>
                <span class="">
                    <input class="input dummy-name small" [ngModel]="calorieDummyName" (keyup)="onCalorieDummyNameChanged($event.target.value)" type="text" [placeholder]="'Name (optional)' | translate"/>
                </span><br>
                <span class="">
                    <input class="input-nutrition light small textalign-right" [ngModel]="calorieDummyCalories" (keyup)="onCalorieDummyCaloriesChanged($event.target.value)"> {{'kcal' | translate}}
                </span>
                <div class="icon-button add-to-meal" (click)="addCalorieDummyToMeal()" translate>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>
                    Zur Mahlzeit hinzufügen
                </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="loadingNutritionalValues" class="loading-animation">
        <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
    </div>
</div>