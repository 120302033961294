import {Component} from '@angular/core';
import {PaymentAnalyticsService} from "../../../../services/payment-analytics.service";
import {ProductPurchase, ProductPurchaseStatus} from "../../../../model/product-purchase.model";
import * as moment from "moment";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-kpi-active-sales',
  templateUrl: './kpi-active-sales.component.html',
  styleUrls: ['./kpi-active-sales.component.css']
})
export class KpiActiveSalesComponent {
  private activePurchases: ProductPurchase[] = [];
  public info: string = null;
  public result: string = null;
  public growthRate: number = 0;

  constructor(private paymentAnalyticsService: PaymentAnalyticsService, private translate: TranslateService) {
    this.init();
  }

  private init() {
    this.activePurchases = this.paymentAnalyticsService.getCoachingPurchases();
    const nowMoment = moment();
     const activePurchasesCount = this.activePurchases.filter(purchase => {
      if (!purchase.startDate) return false;
      const endDate = purchase.getEndDate() ?? nowMoment;
      return nowMoment.isBetween(purchase.startDate, endDate, "day", "[]");
    }).length;

    this.result = `${activePurchasesCount}`;

    const lastMonthMoment = nowMoment.subtract(1, 'month');
    const lastMonthActivePurchasesCount = this.activePurchases.filter(purchase => {
      if (!purchase.startDate) return false;
      const endDate = purchase.getEndDate() ?? nowMoment;
      return lastMonthMoment.isBetween(purchase.startDate, endDate, "day", "[]");
    }).length;

    this.growthRate = ((activePurchasesCount/lastMonthActivePurchasesCount) - 1);
    if(isNaN(this.growthRate)) {
      this.growthRate = Infinity;
    }

    this.info = this.translate.instant('Veränderung im Vergleich zu lastMonthActivePurchasesCountParameter zum lastDateParameter: growthRateParameter%', { lastMonthActivePurchasesCountParameter: lastMonthActivePurchasesCount, lastDateParameter: lastMonthMoment.format('DD.MM.YY'), growthRateParameter: (this.growthRate * 100).toFixed(0) });

    // this.info = `Veränderung im Vergleich zu ${lastMonthActivePurchasesCount} zum ${lastMonthMoment.format('DD.MM.YY')}: ${(this.growthRate * 100).toFixed(0)}%`;
  }

  protected readonly Infinity = Infinity;
}
