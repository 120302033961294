<div class="page-content">
    <div class="container-fluid">
        <h2 class="" translate>Rezepte</h2>
        <div class="row">
            <div class="col-12">
                <div class="layout-box">
                    <div>
                        <h3 class="display-inlineblock" translate>Deine Rezepte</h3>
                    </div>
                    <div class="spacer-25"></div>
                    <div class="row recipe-list">
                        <div class="col-12">
                            <div class="spacer-10"></div>
                            <input id="recipesearch-input" class="input wide" (input)="onRecipeSearchInputChanged($event.target.value)" [placeholder]="'Nach Name oder #Tag suchen' | translate" type="text">
                            <div class="icon-button deletesearch-icon delete-recipesearch" (click)="onDeleteRecipeSearchInput()">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </div>
                            <div class="spacer-25"></div>
                        </div>
                        <div *ngFor="let recipe of getFilteredRecipes()" class="col-12 col-xl-6">
                            <div class="layout-box sub-box link" (click)="onRecipeSelected(recipe)">
                                <div *ngIf="recipe.thumbnailURL" class="recipe-image" [ngStyle]="{'background-image':'url('+ recipe.thumbnailURL +')'}"></div>
                                <div *ngIf="!recipe.thumbnailURL">
                                    <div class="recipe-image" [ngStyle]="{'background-image':'url('+ (nutritionService.getRecipeThumbnailUrl(recipe) | async) +')'}"></div>
                                </div>

                                <div class="recipe-information">
                                    <div class="recipe-name bold display-inlineblock">{{recipe.getName()}}</div>
                                    <div class="spacer-10"></div>
                                    <div class="recipe-nutritional-values">
                                        {{recipe?.getCarbohydrates()?.toFixed(0)}}{{'g K' | translate}}/ {{recipe?.getProtein()?.toFixed(0)}}{{'g E' | translate}}/ {{recipe?.getFat()?.toFixed(0)}}{{'g F' | translate}}/ {{recipe?.getCalories()?.toFixed(0)}} {{'kcal' | translate}}
                                    </div>
                                    <div class="spacer-10"></div>
                                    <div class="recipe-tags">
                                        <ng-container *ngFor="let tag of recipe.getComputedTags()">
                                            {{tag.getPrintableName(translate)}}&nbsp;&nbsp;
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="single-recipe-view" class="layout-box {{(selectedRecipe ? 'show' : '')}}">
    <div class="container-fluid">
        <div class="icon-button" (click)="onCloseRecipe()" translate>
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
            Schließen
        </div>
        <div class="spacer-10"></div>
        <div class="row">
            <div class="col-6 col-md-2">
                <div class="recipe-imagewrapper">
                    <div *ngIf="selectedRecipe?.thumbnailURL" class="recipe-image" [ngStyle]="{'background-image':'url('+ selectedRecipe?.thumbnailURL +')'}"></div>
                    <div *ngIf="!selectedRecipe?.thumbnailURL">
                        <div class="recipe-image" [ngStyle]="{'background-image':'url('+ (nutritionService.getRecipeThumbnailUrl(selectedRecipe) | async) +')'}"></div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-9">
                <h3>
                    {{selectedRecipe?.getName()}}
                </h3>
                <div class="small">
                    {{selectedRecipe?.getServings()}} <span translate>Portionen</span>
                </div>
            </div>
        </div>
        <div class="spacer-25"></div>
        <h3 translate>Inhalt</h3>
        <div class="fontweight-bold">{{selectedRecipe?.getCarbohydrates().toFixed(0)}}{{'g K' | translate}}/ {{selectedRecipe?.getProtein().toFixed(0)}}{{'g E' | translate}}/ {{selectedRecipe?.getFat().toFixed(0)}}{{'g F' | translate}}/ {{selectedRecipe?.getCalories().toFixed(0)}} <span translate>kcal p. P.</span> 
            <div class="nutritionalvalues-button display-inlineblock link marginleft-10" [popover]="nutritionalValuePopover" (click)="onNutritionalValuesFocused(selectedRecipe)" placement="top" [outsideClick]="true" container="body">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                </svg>
            </div>
        </div>
        <div class="spacer-10"></div>
        <div class="row meal-content">
            <div *ngFor="let food of selectedRecipe?.getIngredients()" class="col-12 col-lg-10 col-xl-9 col-xxl-6 meal-item">
                <div class="row">
                    <div class="col-8">
                        <div *ngIf="!food.isDummy">{{food.nutritionFact?.getName()}}<span class="brands">{{food.nutritionFact?.getBrands() != null ? ' (' + food.nutritionFact?.getBrands() + ')' : ''}}</span></div>
                        <div *ngIf="food.isDummy">{{food.name || 'Dummy'}}</div>
                        <div class="nutritional-values-small">
                            {{food.getCarbohydrates().toFixed(0)}}{{'g K' | translate}}/ {{food.getProtein().toFixed(0)}}{{'g E' | translate}}/ {{food.getFat().toFixed(0)}}{{'g F' | translate}}/ {{food.getCalories().toFixed(0)}} {{'kcal' | translate}}
                        </div>
                    </div>
                    <div *ngIf="!food.isDummy" class="food-weight col-3">
                        <div>{{food?.weight}} g</div>
                        <div *ngIf="food.getServing()" class="serving-size nutritional-values-small">{{food.weight / food.getServing().amount == 1 ? food.getServing().getPrintableName(translate) : (food.weight / food.getServing().amount).roundToPlaces(2) + 'x ' + food.getServing().getPrintableName(translate)}}</div>
                    </div>
                    <div *ngIf="food.isDummy" class="col-3">
                        <div *ngIf="food?.weight" class="marginleft-25">{{food?.weight}} g</div>
                    </div>
                </div>  
            </div>
        </div>
        <div class="spacer-50"></div>
        <h3 class="display-inlineblock" translate>Anweisungen</h3>
        <app-md-editor class="min-height" [inputValue]="editorValue"
            [(forceUpdate)]="forceEditorUpdate"
            [placeHolder]="placeholder" [readOnlyMode]="true">
        </app-md-editor>
        <div class="spacer-25"></div>
        <h3 class="display-inlineblock" translate>Tags</h3><br>
        <ng-container *ngFor="let tag of selectedRecipe?.getComputedTags()">
            <div class="chip">
                {{tag.getPrintableName(translate)}}
            </div>
        </ng-container>
        <div class="spacer-50"></div>
    </div>
</div>
<ng-template #nutritionalValuePopover>
    <app-nutritional-value-popover [nutritionalValueHolder]="nutritionalValueHolder"></app-nutritional-value-popover>
</ng-template>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "#000000" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>