import { PlannedMealV2 } from './../model/plannedmealv2.model';
import { Injectable } from '@angular/core';
import { MealEditorDialogComponent } from '../meal-editor-dialog/meal-editor-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from '../model/user.model';
import { Meal } from '../model/meal.model';
import { FirestoreService } from './firestore.service';
import { Food } from '../model/food.model';
import { BaseNutritionFact } from '../model/basenutritionfact.model';
import { PlannedFood } from '../model/plannedfood.model';
import { LanguageService } from './language.service';
import { MealType } from '../model/nutritionplanv2.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class EditUserDataService {
  
  public isEditModeActivated: boolean = false;

  constructor(public dialog: MatDialog, private spinner: NgxSpinnerService, private userService: FirestoreService, private languageService: LanguageService, private translate: TranslateService) { }

  public switchEditMode() {
    this.isEditModeActivated = !this.isEditModeActivated;
  }

  name: string
    nutritionFactId: string
    weight: number
    
    calories: number
    carbohydrates: number
    protein: number
    fat: number
    sugar: number
    polyols: number
    fibre: number
    saturatedFat: number
    monounsaturatedFat: number
    polyunsaturatedFat: number
    transFat: number
    omega3Fat: number
    omega6Fat: number
    vitaminA: number
    vitaminC: number
    vitaminD: number
    vitaminB1: number
    vitaminB2: number
    vitaminB3: number
    vitaminB5: number
    vitaminB6: number
    vitaminB7: number
    vitaminB9: number
    vitaminB12: number
    vitaminE: number
    vitaminK: number
    iron: number
    magnesium: number
    zinc: number
    calcium: number
    sodium: number
    potassium: number
    phosphorus: number
    sulphur: number
    chlorine: number
    copper: number
    manganese: number
    fluorine: number
    iodine: number
    minerals: number
    organicAcids: number
    mannitol: number
    sorbitol: number
    xylitol: number
    glucose: number
    fructose: number
    galactose: number
    sucrose: number
    maltose: number
    lactose: number
    glycogen: number
    starch: number
    monosaccharides: number
    disaccharides: number
    oligosaccharides: number
    polysaccharides: number
    waterSolubleFibre: number
    waterInsolubleFibre: number
    essentialAminoAcids: number
    nonessentialAminoAcids: number
    leucine: number
    isoleucine: number
    valine: number
    uricAcid: number
    purine: number
    linoleicAcid: number
    alphaLinolenicAcid: number
    arachidonicAcid: number
    eicosapentaenoicAcid: number
    docosahexaenoicAcid: number
    cholesterol: number
    salt: number
    alcoholContent: number
    alcohol: number
    water: number


  static plannedFoodToFood(plannedFood: PlannedFood, selectedLanguageCode: string): Food {
    let food = new Food();
    if (plannedFood.nutritionFact) {
      food.name = plannedFood.nutritionFact.nameTranslation.GetValue(selectedLanguageCode)
      food.nutritionFactId = plannedFood.nutritionFact.id
      food.nutritionFact = plannedFood.nutritionFact
    } else {
      food.nutritionFactId = plannedFood.nutritionFactId;
      food.name = plannedFood.getName(selectedLanguageCode);
    }

    food.weight = plannedFood.weight;
    food.isDummy = plannedFood.isDummy;
    food.brands = plannedFood.nutritionFact?.brands ?? null
    BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
      if (plannedFood.getNutritionalValue(nutritionalValue)) {
        food.setNutritionalValue(nutritionalValue, plannedFood.getNutritionalValue(nutritionalValue))
      }
      else {
        food.setNutritionalValue(nutritionalValue, null)
      }
    })
    return food;
  }

  static plannedMealV2ToMeal(plannedMeal: PlannedMealV2, selectedLanguageCode: string): Meal {
    let meal = new Meal();
    // meal.id = plannedMeal.id;
    meal.date = plannedMeal.date;
    // meal.number = plannedMeal.number;
    meal.comment = plannedMeal.comment;
    meal.commentAcknowledged = false;
    if(plannedMeal.type == MealType.custom){
      meal.mealType = plannedMeal.customType;
    }
    else if(plannedMeal.type) {
      meal.mealType = MealType[plannedMeal.type] || MealType.none;
    }
    else {
      meal.mealType = MealType.none;
    }
    meal.imagePath = plannedMeal.thumbnailPath;
    meal.imageLink = plannedMeal.imageURL;
    meal.instructions = plannedMeal.instructions;
    // meal.show = plannedMeal.show;
    meal.foods = plannedMeal.foods.map(x => EditUserDataService.plannedFoodToFood(x, selectedLanguageCode));
    meal.calories = plannedMeal.calories;
    meal.carbohydrates = plannedMeal.carbohydrates;
    meal.protein = plannedMeal.protein;
    meal.fat = plannedMeal.fat;
    meal.sugar = plannedMeal.sugar;
    meal.fibre = plannedMeal.fibre;
    meal.saturatedFat = plannedMeal.saturatedFat;
    meal.salt = plannedMeal.salt;
    meal.name = plannedMeal.name;
    meal.plannedMealId = plannedMeal.id;
    return meal;
  }

  static foodToPlannedFood(food: Food, selectedLanguageCode: string): PlannedFood {
    let plannedFood = new PlannedFood();
    plannedFood.nutritionFactId = food.nutritionFactId;
    plannedFood.weight = food.weight;
    plannedFood.nutritionFact = new BaseNutritionFact();
    plannedFood.nutritionFact.nameTranslation.SetValue(food.name, selectedLanguageCode);
    plannedFood.name = food.name;
    plannedFood.isDummy = food.isDummy;
    BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
      if (food[nutritionalValue]) {
        plannedFood.nutritionFact.setNutritionalValue(nutritionalValue, food[nutritionalValue])
      }
      else {
        plannedFood.nutritionFact.setNutritionalValue(nutritionalValue, null)
      }
    })
    return plannedFood;
  }

  static mealToPlannedMealV2(meal: Meal, selectedLanguageCode: string): PlannedMealV2 {
    let plannedMeal = new PlannedMealV2();
    plannedMeal.id = meal.id;
    plannedMeal.date = meal.date;
    plannedMeal.number = 0;
    plannedMeal.comment = meal.comment;
    // plannedMeal.commentAcknowledged = meal.commentAcknowledged;
    plannedMeal.type = meal.mealType;
    plannedMeal.imageURL = meal.imageLink;
    // plannedMeal.show = meal.show;
    plannedMeal.foods = meal.foods.map(x => EditUserDataService.foodToPlannedFood(x, selectedLanguageCode));
    plannedMeal.calories = meal.calories;
    plannedMeal.carbohydrates = meal.carbohydrates;
    plannedMeal.protein = meal.protein;
    plannedMeal.fat = meal.fat;
    plannedMeal.sugar = meal.sugar;
    plannedMeal.fibre = meal.fibre;
    plannedMeal.saturatedFat = meal.saturatedFat;
    plannedMeal.salt = meal.salt;
    return plannedMeal;
  }

  public async trackMeal(trackedMeal: Meal, user: User){
    // let trackedMeal = EditUserDataService.plannedMealV2ToMeal(plannedMeal, this.languageService.selectedLanguageCode);
    // console.log(trackedMeal)
    trackedMeal.recalculateNutritionalValues();
    await this.userService.createTrackedMealForUser(user, trackedMeal);
  }

  public async updateTrackedMeal(trackedMeal: Meal, user: User){
    // let trackedMeal = EditUserDataService.plannedMealV2ToMeal(plannedMeal, this.languageService.selectedLanguageCode);
    // console.log(trackedMeal)
    // trackedMeal.comment = oldMeal.comment;
    // trackedMeal.commentAcknowledged = oldMeal.commentAcknowledged;
    // trackedMeal.id = oldMeal.id;
    trackedMeal.recalculateNutritionalValues();
    await this.userService.updateTrackedMealForUser(user, trackedMeal);
  }

  public async deleteTrackedMeal(trackedMeal: Meal, user: User){
    await this.userService.deleteTrackedMealForUser(user, trackedMeal);
  }

}
