<div id="purchase-dialog" class="layout-box">
    <div class="container-fluid component-wrapper" >
        <div class="top-bar">
            <div class="icon-button" (click)="onCloseDialog()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                <span class="icon-button-text" translate>Schließen</span>
            </div>
        </div>
        <div class="spacer-50"></div>
        
        <div class="row">
            <div class="col-12">
                <h3 translate>Kaufdetails</h3>
                <div class="spacer-10"></div>
                <div class="bold" translate>
                    Käufer
                </div>
                {{productPurchase.customer?.getName() ?? productPurchase.licence?.presetName ?? productPurchase.licence?.email ?? 'Unbekannter Kunde'}}<br>
                <span *ngIf="email">
                    {{email}}
                </span>
                <span *ngIf="!email">
                    <mat-spinner class="loading-statistics-spinner" diameter="25" strokeWidth="2"></mat-spinner>
                </span>
                <div class="spacer-10"></div>
                <span translate>Rechnungsadresse:</span>
                <div *ngIf="!editAddress && address" class="icon-button marginleft-20 display-inlineblock" (click)="onEditAddress()" translate>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
                        <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                    </svg>
                    Bearbeiten
                </div>
                <div *ngIf="!editAddress && address">
                    {{address.name}}<br>
                    {{address.street}}<br>
                    {{address.postalCode}} {{address.city}}<br>
                    {{address.country}}
                    <div class="spacer-10"></div>
                </div>
                <div *ngIf="editAddress">
                    <div class="spacer-10"></div>
                    <input id="input-name" required class="input form-control wide" [(ngModel)]="address.name" placeholder="Vor- und Nachname" type="text">
                    <div class="spacer-10"></div>
                    <input id="input-name" required class="input form-control wide" [(ngModel)]="address.street" placeholder="Straße und Hausnummer" type="text">
                    <div class="spacer-10"></div>
                    <input id="input-name" required class="input form-control wide" [(ngModel)]="address.postalCode" placeholder="Postleitzahl" type="text">
                    <div class="spacer-10"></div>
                    <input id="input-name" required class="input form-control wide" [(ngModel)]="address.city" placeholder="Stadt" type="text">
                    <div class="spacer-10"></div>
                    <input id="input-name" required class="input form-control wide" [(ngModel)]="address.country" placeholder="Land" type="text">
                    <div class="spacer-10"></div>
                    <div class="icon-button marginleft-10" (click)="onSaveAddress()" translate>
                        Speichern
                    </div>
                </div>
                <div *ngIf="getDefaultPaymentMethod()">
                    <div class="spacer-10"></div>
                    <span translate>Standardzahlungsmethode:</span> {{getDefaultPaymentMethod()}}
                </div>
                
            </div>
            <div class="spacer-25"></div>
            <div class="col-12">
                <div class="bold">Status</div>
                <div class="spacer-10"></div>
                <div *ngIf="productPurchase.getPrintableStatus(translate)" class="status-indicator-wrapper">
                    <div class="status-indicator {{productPurchase.status}}"></div>
                    {{productPurchase.getPrintableStatus(translate)}}
                </div>
                <div class="spacer-25"></div>
                Start: {{productPurchase.getPrintableStartDate(translate)}}
                <div class="icon-button marginleft-10" *ngIf="productPurchase.status == 'purchased'" (click)="onEditStartDate()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
                        <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                    </svg>
                    <span class="icon-button-text" translate> Startdatum anpassen</span>
                </div>
                <br>
                Ende: {{productPurchase.getPrintableEndDate(translate)}}
                <ng-container *ngIf="productPurchase.status == 'active'">
                    <div *ngIf="productPurchase.endDate && productPurchase.recurring" class="icon-button marginleft-10" (click)="onEditEndDate()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                        </svg>
                        <span class="icon-button-text" translate> Verlängern/Verkürzen</span>
                    </div>
                    <div *ngIf="!productPurchase.endDate" class="icon-button small marginleft-10" (click)="onCancelSubscription()" translate>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                        Verlängerung kündigen
                    </div>
                    <div *ngIf="productPurchase.endDate" class="icon-button small marginleft-10" (click)="onEnableAutoRenew()" translate>
                        <i class="bi bi-arrow-repeat"></i>
                        Automatisch verlängern
                    </div>
                </ng-container>
                <br>
                <ng-container *ngIf="productPurchase.nextPaymentDate && productPurchase.status != 'paused'">
                    Nächste Zahlung: {{productPurchase.nextPaymentDate?.asFormatedString()}}
                    <div class="icon-button marginleft-10" (click)="onEditPaymentDate()" translate>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                        </svg>
                        Abrechnungsdatum anpassen
                    </div>
                    <div class="icon-button marginleft-10" (click)="onSkipNextPayment()" translate>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                          </svg>
                        Nächste Zahlung aussetzen
                    </div>
                </ng-container>

                <div class="spacer-25"></div>
                <div class="label" translate>Verfügbare Zahlungsmethoden</div>
                <div class="spacer-10"></div>
                <div class="d-inline-block wrapper-selection">
                    <button class="button-filled light small dropdown-toggle margin-inbetween {{getPrintablePaymentMethods() != null ? '' : 'invalid'}}" type="button" id="dropdownTrigger" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getPrintablePaymentMethods() || ('Auswählen' | translate)}}</button>
                    <div class="dropdown-menu checkin-dropdown" aria-labelledby="dropdownBodyDataGraphTimeRange">
                        <div class="dropdown-item link" (click)="onPaymentMethodSelected('sepa_debit');$event.stopPropagation()">
                            <input class="input checkbox" type="checkbox" [checked]="isPaymentMethodAvailable('sepa_debit')"><div class="display-inline" translate>Sepa-Lastschrift</div>
                        </div>
                        <div class="dropdown-item link" (click)="onPaymentMethodSelected('bank_transfer');$event.stopPropagation()">
                            <input class="input checkbox" type="checkbox" [checked]="isPaymentMethodAvailable('bank_transfer')"><div class="display-inline" translate>Banküberweisung</div>
                        </div>
                        <div class="dropdown-item link" (click)="onPaymentMethodSelected('card');$event.stopPropagation()">
                            <input class="input checkbox" type="checkbox" [checked]="isPaymentMethodAvailable('card')"><div class="display-inline" translate>Kreditkarte</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="productPurchase.status == 'active'" class="icon-button marginleft-10" (click)="onRequestPaymentMethodChange()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
                        <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z"/>
                      </svg>
                    <span class="icon-button-text" translate> Änderung anfordern</span>
                </div>
            </div>
            <div class="spacer-25"></div>
            <div class="col-12">
                <div class="bold" translate>
                    Produkt
                </div>
                <div>
                    {{productPurchase.getProductName() ?? ('Unbekanntes Produkt' | translate)}}
                </div>
                <div>
                    {{productPurchase.getPrintablePriceWithCurrency() + ' ' + productPurchase.getPrintablePaymentFrequency(translate)}}
                </div>
                <div *ngIf="productPurchase.getPrintableInitialSetupFee() != null">
                    + {{productPurchase.getPrintableInitialSetupFeeWithCurrency()}} <span translate>Einmalgebühr</span>
                </div>
                <div>
                    {{productPurchase.getPrintableDuration(translate)}} {{productPurchase.autoRenew ? translate.instant(marker('automatische Verlängerung')) : ''}}
                </div>
            </div>
            <div class="spacer-25"></div>
            <div class="col-12">
                <div class="bold" translate>
                    Verlauf
                </div>
                <div class="spacer-10"></div>
                <div class="row">
                    <div class="col-12" *ngFor="let log of productPurchase.logs">
                        <div class="timeline-container">
                            <div class="timeline"></div>
                            <div class="point"></div>
                        </div>
                        <div class="log-content">
                            <div class="label date">{{log.date?.asDateTimeFormatedString()}}</div>
                            {{log.getPrintableEvent(translate)}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "#4AE6D7" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>