import { Component, ComponentFactoryResolver, Input } from '@angular/core';
import { User } from '../model/user.model';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FirestoreService } from '../services/firestore.service';
import { UtilityService } from '../services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { DailyCondition } from '../model/dailycondition.model';
import { Metric } from '../model/metric.model';
import { LanguageService } from '../services/language.service';
import { MetricData } from '../model/metricdata.model';
import { MetricDataImageDialogComponent } from '../metric-data-image-dialog/metric-data-image-dialog.component';
import { AssignedQuestionaire, QuestionaireResult } from '../model/questionaires.model';
import { CompletedQuestionaireResultsDialogComponent } from '../questionaire/completed-questionaire-results-dialog/completed-questionaire-results-dialog.component';
import { EditUserDataService } from '../services/edit-user-data.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-checkin-table-view',
  templateUrl: './checkin-table-view.component.html',
  styleUrls: ['./checkin-table-view.component.css']
})
export class CheckinTableViewComponent {

  public user: User
  @Input() set User(value: User){
    this.user = value
    this.init()
  }

  public showAll: boolean = false

  public weeks: ReportingWeek[] = []
  public dailyConditions: DailyCondition[]

  constructor(private authService: AuthService, private router: Router, public userService: FirestoreService, private route: ActivatedRoute, public utilityService: UtilityService, private toastr: ToastrService, private spinner: NgxSpinnerService, private componentFactoryResolver: ComponentFactoryResolver, private dialog: MatDialog, public languageService: LanguageService, public editUserDataService: EditUserDataService, public spinnerService: NgxSpinnerService) {

  }

  init() {
    if (this.user?.dailyConditions?.length > 0) {
      this.dailyConditions = this.user.dailyConditions
      this.updateData()
    } else {
      this.user.dailyConditionChanged.subscribe(() => {
        this.dailyConditions = this.user.dailyConditions
        this.updateData()
      })
    }
  }

  updateData() {
    this.weeks = []
    var startOfWeek = new Date().getStartOfWeek()
    for (var i = 0; i < 104; i++) {
      var week = new ReportingWeek()
      week.startDate = new Date(startOfWeek)
      week.dailyConditions = []
      week.averageValues = []
      this.dailyConditions.forEach(dailyCondition => {
        if (dailyCondition.date.isSameOrAfterDate(week.startDate) && dailyCondition.date.isSameOrBeforeDate(week.getEndDate())) {
          week.dailyConditions.push(dailyCondition)
        }
      })

      week.metrics = []
      this.user.assignedMetrics.forEach(metric => {
        if (metric.metricId != null) week.metrics.push(metric)
      })
      week.dailyConditions.forEach(dailyCondition => {
        dailyCondition.metricData.forEach(metricData => {
          if (metricData.value != null && week.metrics.find(x => x.metricId == metricData.metricId) == null) {
            week.metrics.push(metricData.metric)
          }
        })
        dailyCondition.questionaireResults?.forEach(questionaireResult => {
          if (questionaireResult.assignedQuestionaire?.type != 'training_feedback') {
            if (!week.questionaireResults) week.questionaireResults = []
            week.questionaireResults.push(questionaireResult)
          }
        })
      })

      week.metrics.forEach(metric => {
        var metricData = new MetricData()
        metricData.metricId = metric.metricId
        metricData.metric = metric
        metricData.value = 0
        var count = 0
        if (metric.dataType != 'INTEGER' && metric.dataType != 'DOUBLE') return
        week.dailyConditions.forEach(dailyCondition => {
          var data = dailyCondition.getMetricDataByMetricId(metric.metricId)
          if (data && data.value != null) {
            metricData.value += data.value
            count++
          }
        })
        if (count > 0) {
          metricData.value = (metricData.value / count).roundToPlaces(2)
          metricData.metric = metric
          week.averageValues.push(metricData)
        }
      })

      if (week.dailyConditions.length > 0) this.weeks.push(week)
      startOfWeek.setDate(startOfWeek.getDate() - 7)
    }
  }

  async onOpenMetricImage(metricData: MetricData) {
    if (!metricData.mediaLink) {
      metricData.mediaLink = await this.userService.getMetricDataMediaLink(metricData, this.user.uid)
    }
    const dialogRef = this.dialog.open(MetricDataImageDialogComponent, { data: { imageURL: metricData.mediaLink}});
  }
  async onOpenMetricVideo(metricData: MetricData) {
    if (!metricData.mediaLink) {
      metricData.mediaLink = await this.userService.getMetricDataMediaLink(metricData, this.user.uid)
    }
    const dialogRef = this.dialog.open(MetricDataImageDialogComponent, { data: { imageURL: metricData.mediaLink}});
  }

  selectedMetricDataForComparison: any[] = []

  onMetricDataSelectedForComparison(image: any) {
    if(this.selectedMetricDataForComparison.includes(image)){
      this.selectedMetricDataForComparison = this.selectedMetricDataForComparison.filter(item => item.imageURL != image.imageURL)
    } else{
      this.selectedMetricDataForComparison.push(image)
    }
  }

  async onOpenCompareImageDialog() {
    if (this.selectedMetricDataForComparison?.length <= 0) return
    var images = []
    for (var metricData of this.selectedMetricDataForComparison) {
      if (!metricData.mediaLink) {
        metricData.mediaLink = await this.userService.getMetricDataMediaLink(metricData, this.user.uid)
      }
      images.push({imageURL: metricData.mediaLink})
    }
    const dialogRef = this.dialog.open(MetricDataImageDialogComponent, { data: { compareImages: images}});
  }

  onOpenQuestionaire(questionaireResult: QuestionaireResult){
    if (!questionaireResult.canAccess(this.getCoach())) return
    let allAvailableQuestionaireResults = this.dailyConditions.map(x => x.questionaireResults).reduce((a, b) => a.concat(b), []);
    this.onEditQuestionaire(questionaireResult.clone(), allAvailableQuestionaireResults);
  }

  onEditQuestionaire(questionaireResult: QuestionaireResult, allAvailableQuestionaireResults: QuestionaireResult[]){
    const dialogRef = this.dialog.open(CompletedQuestionaireResultsDialogComponent, { data: { selectedQuestionaireResults: [questionaireResult], allAvailableQuestionaireResults: allAvailableQuestionaireResults, user: this.user, editMode: this.editUserDataService.isEditModeActivated}, autoFocus: false});
    dialogRef.afterClosed().subscribe(async result => {

    });
  }

  async onFilloutQuestionaire(questionaireResult: QuestionaireResult){
    let clonedQuestionaireResult = questionaireResult.clone();
    let allAvailableQuestionaireResults = this.dailyConditions.map(x => x.questionaireResults).reduce((a, b) => a.concat(b), []);
    for(let question of clonedQuestionaireResult.assignedQuestionaire.questions){
      let metricData = new MetricData();
      metricData.metric = this.userService.getMetricByMetricId(question.metricId)
      if (!metricData.metric) metricData.metric = await this.userService.fetchMetricByMetricId(question.metricId)
      metricData.metricId = question.metricId;
      metricData.id = question.metricId;
      metricData.value = null;
      metricData.position = question.position;
      metricData.date = new Date();
      if(metricData.metric != null){
        clonedQuestionaireResult.metricData.push(metricData);
      }
    }
    this.onEditQuestionaire(clonedQuestionaireResult, allAvailableQuestionaireResults)
  }


  async onDeleteQuestionaire(questionaireResult: QuestionaireResult){
    if(!questionaireResult.assignedQuestionaire.completed) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { message: 'Möchtest du den zugewiesenen Check-In wirklich löschen?', title: 'Check-In löschen' },
      });
      dialogRef.afterClosed().subscribe(async result => {
        if(result){
          this.spinnerService.show();
          await this.userService.deleteAssignedQuestionaire(this.user, questionaireResult.assignedQuestionaire);
          // this.init();
          this.spinnerService.hide();
        }
      });
    }
  }

  onShowAll(value: boolean) {
    this.showAll = value
  }

  getCoach() {
    return this.userService.getLoggedInUser()
  }
}

export class ReportingWeek {
  startDate: Date
  dailyConditions: DailyCondition[]
  metrics: Metric[]
  questionaireResults: QuestionaireResult[]
  averageValues: MetricData[]

  getEndDate() {
    var endDate = new Date(this.startDate)
    endDate.setDate(endDate.getDate() + 6)
    return endDate
  }

  getAverageMetricData(metricId: string) {
    return this.averageValues.find(x => x.metricId == metricId)
  }
}