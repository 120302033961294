import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TrainingPlanTemplateFolder } from 'src/app/model/training-plan-template-folder.model';
import { TrainingPlan, TrainingSession, Week } from 'src/app/model/training-plan.model';
import { LanguageService } from 'src/app/services/language.service';
import { TrainingService } from 'src/app/services/training.service';

@Component({
  selector: 'app-session-templates-dialog',
  templateUrl: './session-templates-dialog.component.html',
  styleUrls: ['./session-templates-dialog.component.css']
})
export class SessionTemplatesDialogComponent {

  public showSessionTemplates: boolean = false;
  public filteredTrainingPlanTemplates: TrainingPlan[];
  public selectedSessionTemplateTab: string = "OWN"

  public expandedWeek: Week = null;

  public selectedSessions: TrainingSession[] = []
  public selectedWeeks: {week: Week, trainingPlan: TrainingPlan}[] = []

  isWeekSelected(week: Week, trainingPlan: TrainingPlan){
    return this.selectedWeeks.find(x => x.week == week && x.trainingPlan == trainingPlan) != null;
  }

  public weekSelectionMode: boolean = false;
  public availableTemplateFolders: TrainingPlanTemplateFolder[] = [];

  public selectedTemplateFolder: TrainingPlanTemplateFolder = null;

  constructor(public dialogRef: MatDialogRef<SessionTemplatesDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { weekSelectionMode: boolean, availableTemplateFolders: TrainingPlanTemplateFolder[] }, public trainingService: TrainingService, public languageService: LanguageService, private spinner: NgxSpinnerService, public translate: TranslateService){
    this.weekSelectionMode = data.weekSelectionMode ?? false;
    this.availableTemplateFolders = data.availableTemplateFolders ?? [];
    this.updateFilteredSessionTemplates();
  }

  public sessionTemplateSearchInput: string

  onSelectedTemplateFolderChanged(folder: TrainingPlanTemplateFolder){
    this.selectedTemplateFolder = folder;
    this.updateFilteredSessionTemplates();
  }

  onSelectTemplateTab(tabName: string){
    this.selectedSessionTemplateTab = tabName
  }

  toggleMenu(week: Week){
    if(this.expandedWeek == week){
      this.expandedWeek = null;
    }
    else {
      this.expandedWeek = week;
    }
  }

  async selectSessionTemplate(session: TrainingSession, template: TrainingPlan){
    if(this.weekSelectionMode) return;
    this.spinner.show("sessionTemplateSpinner")
    if(!this.selectedSessions.includes(session)){
      await this.trainingService.getTrainingPlanTemplatePlannedExercises(template);
      this.selectedSessions.push(session);
    }
    else{
      this.selectedSessions = this.selectedSessions.filter(x => x != session)
    }
    this.spinner.hide("sessionTemplateSpinner")
  }

  async selectWeek(week: Week, template: TrainingPlan){
    this.spinner.show("sessionTemplateSpinner")
    if(!this.isWeekSelected(week, template)){
      await this.trainingService.getTrainingPlanTemplatePlannedExercises(template);
      this.selectedWeeks.push({week: week, trainingPlan: template});
    }
    else{
      this.selectedWeeks = this.selectedWeeks.filter(x => x.week != week || x.trainingPlan != template)
    }
    this.spinner.hide("sessionTemplateSpinner")
  }

  onSessionTemplateSearchInputChanged(text: string) {
    this.sessionTemplateSearchInput = text
    this.updateFilteredSessionTemplates()
  }

  async getTrainingPlanTemplates() {
    let trainingPlanTemplates = await this.trainingService.getTrainingPlanTemplates();
    if(this.weekSelectionMode){
      trainingPlanTemplates = trainingPlanTemplates.filter(x => x.isPeriodicPlan);
    }
    return trainingPlanTemplates;
  }

  async updateFilteredSessionTemplates() {
    this.spinner.show("sessionTemplateSpinner");
    if (!this.sessionTemplateSearchInput || this.sessionTemplateSearchInput.length === 0) {
      this.filteredTrainingPlanTemplates = (await this.getTrainingPlanTemplates())?.filter(template => template.sessions?.filter(x => !x.isRestDay)?.length > 0);
    } else {
    this.filteredTrainingPlanTemplates = 
      (await this.getTrainingPlanTemplates())
        .filter(template => template.sessions?.filter(x => !x.isRestDay)?.length > 0 && (template.getName()?.toLowerCase().includes(this.sessionTemplateSearchInput.toLowerCase()) || template.sessions?.find(x => x.getInternalOrRealPublicName(this.translate)?.toLowerCase()?.includes(this.sessionTemplateSearchInput.toLowerCase())) != null));
    }
    if(this.selectedTemplateFolder != null) {
      this.filteredTrainingPlanTemplates = this.filteredTrainingPlanTemplates.filter(x => x.folderIds.includes(this.selectedTemplateFolder?.id));
    }
    this.spinner.hide("sessionTemplateSpinner");
  }

  async onDeleteSessionTemplateSearchInput() {
    this.sessionTemplateSearchInput = null;
    (<HTMLInputElement> document.getElementById('templatesearch-input')).value = ''
    this.filteredTrainingPlanTemplates = await this.trainingService.getTrainingPlanTemplates()
  }

  async onTakeSelection(){
    if(this.weekSelectionMode){
      this.dialogRef.close({shouldTake: true, selectedWeeks: this.selectedWeeks})   
      // if(newSessions.length > 0){
      //   this.dialogRef.close({shouldTake: true, selectedWeeks: newSessions})
      // }
      return;
    }
    else {
      let selectedSessions = this.selectedSessions;
      let newSessions = [];
      for (let session of selectedSessions) {
        let newSession = new TrainingSession(session.name, session.internalName, null, session.isRestDay, session.exercises, false, session.weekId, session.plannedDate, session.plannedDateString, session.baseSessionId, session.hide, session.indicatorColor, session.estimatedDurationInMinutes, session.nameTranslation);
        newSession.exercises.forEach(exercise => {
          exercise.id = null;
          exercise.sessionId = newSession.id;
        });
        newSessions.push(newSession);
      }
      
  
      if(newSessions.length > 0){
        this.dialogRef.close({shouldTake: true, selectedSessions: newSessions})
      }
    }
  }

  onCloseDialog(){
    this.dialogRef.close({shouldTake: false})
  }
}
