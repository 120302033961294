import { FcmService } from '../../services/fcm.service';
import { Component, OnInit, NgZone, AfterViewInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';

import { FirestoreService } from '../../services/firestore.service';
import { UtilityService } from '../../services/utility.service';
import { NavbarService } from '../../services/navbar.service';
import { ChatService } from '../../services/chat.service';
import {MatDialog } from '@angular/material/dialog';
import { LicenceConnectionRequest } from '../../model/licence-connection-request';
import { DashboardBaseComponent } from '../dashboard-base/dashboard-base.component';

@Component({
  selector: 'app-dashboard-traindoo',
  templateUrl: './dashboard-traindoo.component.html',
  styleUrls: ['./dashboard-traindoo.component.css']
})
export class DashboardTraindooComponent extends DashboardBaseComponent {

  constructor(public chatService: ChatService, public authService: AuthService, public router: Router, public userService: FirestoreService, public utilityService: UtilityService, public navService: NavbarService, public dialog: MatDialog, public fcmService: FcmService) {
    super(chatService, authService, router, userService, utilityService, navService, dialog, fcmService)
  }

  ngOnInit(): void {
    
  }

  public hasSubscription: boolean = null

  async onDeclineConnectionRequest(request: LicenceConnectionRequest) {
    await this.userService.deleteLicenceConnectionRequest(request)
  }
  
  async onAcceptConnectionRequest(request: LicenceConnectionRequest) {
    var licence = await this.userService.allocateActiveLicence(request.userUid)
    if (licence) {
      await this.userService.setCurrentLicenceForUser(licence)
      await this.userService.deleteLicenceConnectionRequest(request)
    }
  }

  hasAccess() {
    return this.userService.getLoggedInUser()?.hasPortalAccess()
  }

  isTrialEndingSoon() {
    if (this.userService.getLoggedInUser().observableSubscription == null) return false
    if (this.userService.getLoggedInUser().observableSubscription.value != null) return false
    if (this.userService.getLoggedInUser()?.licenceHolder?.onTrial == false) return false
    return this.userService.getLoggedInUser()?.licenceHolder?.trialEndDate && this.userService.getLoggedInUser().licenceHolder.trialEndDate.getTime() - new Date().getTime() < 1000 * 60 * 60 * 24 * 3
  }
}
