
<h3 class="display-inlineblock" id="push-notification-headline" translate>Push-Notifications</h3>
<button class="button-filled small create-PushNotification" (click)="onCreatePushNotification()" translate>Erstellen</button>
<br>
<div class="row notification-wrapper" *ngFor="let notification of observablePushNotifications | async" [class.deactivated-notification]='!notification?.active'>
    <div class="col col-12 col-sm-5 col-md-3 col-xl-4">
        <b translate>Titel:</b> {{notification.title}}
    </div>
    <div  class="col col-12 col-sm-5 col-md-3 col-xl-4">
        <b translate>Nächste Ausführung: </b>{{notification?.nextExecutionDate?.asDateTimeFormatedString()}}
    </div>
    <div class="col col-5 col-sm-3 col-md-3 col-lg-3 col-xl-2">
        <div class="icon-button small" (click)="onEditPushNotification(notification)" translate>
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
            </svg>
            Bearbeiten
        </div>
    </div>
    <div class="col col-5 col-sm-3 col-md-3 col-lg-3 col-xl-2">
        <div class="icon-button" (click)="onDeletePushNotification(notification)" translate>
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
            Löschen
        </div>
    </div>
</div>
