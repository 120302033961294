import { Component } from '@angular/core';
import { FirestoreService } from '../services/firestore.service';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { User } from '../model/user.model';
import { ChatService } from '../services/chat.service';
import { BroadcastChat, Chat } from '../model/chat.model';
import { FilterObject } from '../filter-selection-dropdown/filter-selection-dropdown.component';
import { ILanguageDictionary, LanguageDictionary } from '../model/languagedictionary.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-iap-dashboard',
  templateUrl: './iap-dashboard.component.html',
  styleUrls: ['./iap-dashboard.component.css']
})
export class IapDashboardComponent {

  constructor(public userService: FirestoreService, public authService: AuthService, private chatService: ChatService, public dialog: MatDialog) { }

  public areUsersLoaded = false
  public users: UserDataWrapper[] = []
  public filteredUsers: UserDataWrapper[] = []
  
  public usersSortAttribute = 'signUpDate'
  public usersSortDirection = 'desc'
  public availableLicenceTypes: FilterObject[] = [
    new FilterObject(new LanguageDictionary('Free', 'Free', 'Free'), true),
    new FilterObject(new LanguageDictionary('Premium', 'Premium', 'Premium'), true),
    new FilterObject(new LanguageDictionary('Coaching', 'Coaching', 'Coaching'), false)
  ];

  public listViewPage = 0
  public maxPage = 0

  public static CLIENTS_PER_PAGE = 50

  ngOnInit(): void {
    this.loadData()
  }

  loadData() {
    if (!this.authService.canAccessIapDashboard()) return
    if (environment.firebaseProjectId != 'grow-fitness-app') return

    this.userService.getAllUsers().then(async (users) => {
      var userWrappers = []
      users.forEach(user => {
        if (user.hideOnOutreachDashboard) return
        var activeUser = this.userService.getAllClientsOfLicenceHolder().find(c => c.uid == user.uid)
        if (activeUser) {
          userWrappers.push(activeUser)
        } else {
          userWrappers.push(user)
        }
      })
      this.users = []
      for (var u of userWrappers) {
        var userDataWrapper = new UserDataWrapper(u)
        userDataWrapper.licenceType = 'Free'
        if (this.userService.getAllClientsOfLicenceHolder().find(c => c.uid == userDataWrapper.user.uid)) {
          userDataWrapper.licenceType = 'Coaching'
        } else {
          var licences = await this.userService.getConnectedLicencesByUser(userDataWrapper.user)
          licences.forEach(licence => {
            if (licence.licenceType == 'Premium') {
              if (userDataWrapper.licenceType != 'Coaching') userDataWrapper.licenceType = 'Premium'
            }
          })
        }
        this.users.push(userDataWrapper)
      }
      this.maxPage = Math.ceil(this.users.length / IapDashboardComponent.CLIENTS_PER_PAGE) - 1
      this.areUsersLoaded = true
    })
  }

  goToPreviousPage() {
    if (this.listViewPage > 0) this.listViewPage--
  }
  goToNextPage() {
    this.listViewPage++
  }

  private userDataPromiseMap = new Map<string, Promise<UserDataWrapper>>()

  getDataForUser(userDataWrapper: UserDataWrapper) {
    if (!userDataWrapper) return null
    if (this.userDataPromiseMap.has(userDataWrapper.user.uid)) {
      return this.userDataPromiseMap.get(userDataWrapper.user.uid)
    }

    var promise: Promise<UserDataWrapper> = new Promise(async (resolve, reject) => {

      var chat = this.getChatForClient(userDataWrapper)
      if (!chat) {
        var chats = await this.userService.getChatForUser(userDataWrapper.user.uid)
        if (chats.length > 0) {
          chat = new Chat(chats[0])
          chat.chatPartner = userDataWrapper.user
          this.chatService.loadMessages(chat);
        }
        userDataWrapper.chat = chat
      }

      resolve(userDataWrapper)
    });
    this.userDataPromiseMap.set(userDataWrapper.user.uid, promise)
    return promise;
  }

  getChatForClient(userDataWrapper: UserDataWrapper): Chat {
    if (userDataWrapper.chat) return userDataWrapper.chat
    return this.chatService.getChatForUid(userDataWrapper.user.uid)
  }
  onOpenChat(chat: Chat) {
    this.chatService.openChat(chat)
  }
  async onCreateChat(userDataWrapper: UserDataWrapper) {
    var chat = await this.userService.createChat([userDataWrapper.user.uid, this.userService.getLoggedInUser().licenceHolderUid])
    chat.chatPartner = userDataWrapper.user
    userDataWrapper.chat = chat
    this.chatService.loadMessages(chat)
    return chat
  }
  async onCreateAndOpen(userDataWrapper: UserDataWrapper) {
    var chat = await this.onCreateChat(userDataWrapper)
    this.onOpenChat(chat)
  }

  onUsersSortChange(sortAttribute: string) {
    if (this.usersSortAttribute == sortAttribute) {
      if (this.usersSortDirection == 'desc') {
        this.usersSortDirection = 'asc'
      } else {
        this.usersSortDirection = 'desc'
        this.usersSortAttribute = 'creationDate'
      }
    } else {
      this.usersSortDirection = 'desc'
      this.usersSortAttribute = sortAttribute
    }
  }

  hasFilter(filterObjects:FilterObject[]):boolean{
    return filterObjects?.filter(x => x.isFiltered)?.length > 0
  }
  fallsInFilter(filter:FilterObject[], item: string, isTranslatable: boolean = true): boolean {
    return (!this.hasFilter(filter) || (filter.filter(i => i.isFiltered && this.getCompareText(i.originObject, isTranslatable) === item)).length > 0)
  }
  getCompareText(filterObject: ILanguageDictionary<any>, isTranslatable: boolean = true):string{
    if(isTranslatable) return filterObject.comparableValue
    else return filterObject.originObject.toString()
  }

  selectedUsers: UserDataWrapper[] = []

  onSelectAllUsers(checked: boolean) {
    if (checked) {
      this.selectedUsers = this.getUsers()
    } else {
      this.selectedUsers = []
    }
  }
  onSelectUser(user: UserDataWrapper, checked: boolean) {
    if (checked) {
      this.selectedUsers.push(user)
    } else {
      this.selectedUsers = this.selectedUsers.filter(x => x != user)
    }
  }

  async onSendBroadcastMessage() {
    let broadcastChat = new BroadcastChat();
    broadcastChat.selectedChats = []
    for (let userDataWrapper of this.selectedUsers) {
      var chat = this.getChatForClient(userDataWrapper)
      if (!chat) chat = await this.onCreateChat(userDataWrapper)
      broadcastChat.selectedChats.push(chat)
    }
    this.chatService.openChat(broadcastChat);
  }

  async onHideSelectedUsers() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du die ausgewählten Nutzer wirklich verbergen?', title: 'Nutzer verbergen' },
    });
    dialogRef.afterClosed().subscribe(async confirmationResult => {
      if (confirmationResult == true) {
        for (let userDataWrapper of this.selectedUsers) {
          userDataWrapper.user.hideOnOutreachDashboard = true
          await this.userService.updateUserHideOnOutreachDashboard(userDataWrapper.user)
        }
        this.users = this.users.filter(x => !x.user.hideOnOutreachDashboard)
        this.maxPage = Math.ceil(this.users.length / IapDashboardComponent.CLIENTS_PER_PAGE) - 1
        this.selectedUsers = []
      }
    });
  }

  getUsers(): UserDataWrapper[] {
    this.filteredUsers = this.users?.filter(x => this.fallsInFilter(this.availableLicenceTypes, x.licenceType, false))?.sort((a1, b1) => {
      var a = a1.user
      var b = b1.user
      if (this.usersSortAttribute == 'signUpDate') {
        if (a.signUpDate == null && b.signUpDate == null) return 0
        if (this.usersSortDirection == 'asc') {
          if (a.signUpDate == null) return 1
          if (b.signUpDate == null) return -1
          return a.signUpDate.getTime() - b.signUpDate.getTime()
        } else {
          if (a.signUpDate == null) return 1
          if (b.signUpDate == null) return -1
          return b.signUpDate.getTime() - a.signUpDate.getTime()
        }
      } else if (this.usersSortAttribute == 'lastAppUseDate') {
        if (a.lastAppUseDate == null && b.lastAppUseDate == null) return 0
        if (this.usersSortDirection == 'asc') {
          if (a.lastAppUseDate == null) return 1
          if (b.lastAppUseDate == null) return -1
          return a.lastAppUseDate.getTime() - b.lastAppUseDate.getTime()
        } else {
          if (a.lastAppUseDate == null) return 1
          if (b.lastAppUseDate == null) return -1
          return b.lastAppUseDate.getTime() - a.lastAppUseDate.getTime()
        }
      } else if (this.usersSortAttribute == 'lastChatMessageDate') {
        var aChat = a1.chat
        var bChat = b1.chat
        if (aChat?.getLatestMessage() == null && bChat?.getLatestMessage() == null) return 0
        if (this.usersSortDirection == 'asc') {
          if (aChat?.getLatestMessage() == null) return 1
          if (bChat?.getLatestMessage() == null) return -1
          return aChat.getLatestMessage().time.getTime() - bChat.getLatestMessage().time.getTime()
        } else {
          if (aChat?.getLatestMessage() == null) return 1
          if (bChat?.getLatestMessage() == null) return -1
          return bChat.getLatestMessage().time.getTime() - aChat.getLatestMessage().time.getTime()
        }
      }
      return 0
    })
    this.maxPage = Math.ceil(this.filteredUsers.length / IapDashboardComponent.CLIENTS_PER_PAGE) - 1
    if (this.listViewPage > this.maxPage) this.listViewPage = this.maxPage
    if (this.filteredUsers?.length > IapDashboardComponent.CLIENTS_PER_PAGE) {
      var start = this.listViewPage * IapDashboardComponent.CLIENTS_PER_PAGE
      var end = start + IapDashboardComponent.CLIENTS_PER_PAGE
      return this.filteredUsers.slice(start, end)
    }
    return this.filteredUsers
  }
}

export class UserDataWrapper {
  constructor(user: User) {
    this.user = user
  }
  user: User
  licenceType: string
  chat: Chat
}