<div class="dialog-topbar">
    <h3>Vorlage Erstellen</h3>
</div>
<div class="dialog-content-wrapper">
    <div class="row">
        <div class="col">
            Vorlagenname:<br>
            <input class="input wide" [(ngModel)]="name" placeholder="Name" type="text">
        </div>
        <div *ngIf="availableTemplateFolders?.length > 0" class="col">
            <div class="form-group">
                Vorlagen-Ordner<br>
                <div class="d-inline-block wrapper-repetition-selection">
                    <button class="button-filled light small dropdown-toggle margin-inbetween" type="button"
                        id="dropdownFolder" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">{{getPrintableSelectedTemplateFolders()}}</button>
                    <div class="dropdown-menu dropdown-notificationTarget p-2" aria-labelledby="dropdownNotificationTarget">
                        <ng-container *ngIf="availableTemplateFolders?.length > 0">
                            <div class="dropdown-item link" *ngFor="let folder of availableTemplateFolders"
                                (click)="onTemplateFolderSelected(folder);$event.stopPropagation()">
                                <input class="input checkbox" type="checkbox"
                                    [checked]="selectedTemplateFolders.includes(folder)">
                                <div class="display-inlineblock">
                                    {{folder?.name?.GetValue(languageService.selectedLanguageCode)}}</div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
    <p>
        Beschreibung:<br>
        <textarea class="input wide" [(ngModel)]="description" placeholder="Beschreibung" type="text"
            rows="5"></textarea>
    </p>
    <br>
    <div class="actions">
        <button class="button-filled small light float-right" (click)="onConfirmDialog()">Erstellen</button>
        <button class="button-filled small light float-right marginright-25"
            (click)="onCancelDialog()">Abbrechen</button>
    </div>
</div>