import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Coach } from '../model/coach.model';
import { LicenceHolder } from '../model/licenceholder.model';
import { FirestoreService } from '../services/firestore.service';
import { FirestoreNutritionPlanService } from '../services/firestore-nutritionplan.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-licenceholder-dialog',
  templateUrl: './create-licenceholder-dialog.component.html',
  styleUrls: ['./create-licenceholder-dialog.component.css']
})
export class CreateLicenceHolderDialogComponent implements OnInit {

  public email: string = ''
  public password: string = ''
  public name: string = ''
  public onTrial: boolean = false

  public uid: string = null

  constructor(public dialogRef: MatDialogRef<CreateLicenceHolderDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {}, private userService: FirestoreService, private spinner: NgxSpinnerService, private translate: TranslateService) {

  }

  ngOnInit(): void {
  }
  onIsOnTrialToggled(value: boolean) {
    this.onTrial = value
  }

  createUserAccount() {
    this.spinner.show()
    this.email = this.email?.replaceAll(' ', '')
    this.userService.createFirebaseUser(this.email, this.password).subscribe(async result => {
      if (result.uid) {
        this.uid = result.uid
        var licenceHolder = new LicenceHolder()
        licenceHolder.uid = this.uid
        licenceHolder.name = this.name
        licenceHolder.onTrial = this.onTrial
        await this.userService.updateLicenceHolder(licenceHolder)
        var coach = new Coach()
        coach.uid = this.uid
        coach.licenceHolderUid = this.uid
        coach.name = this.name
        coach.hasRecipeDatabaseAccess = true
        coach.trainingEnabled = true
        coach.trainerCode = FirestoreNutritionPlanService.generateUniqueString().toUpperCase()
        await this.userService.updateCoach(coach)
        this.spinner.hide()
        if (result.result == 'error' && result.reason == 'Exists') {
          this.spinner.hide()
          alert(this.translate.instant('Account existiert bereits. Passwort nicht gesetzt.'))
        } 
        this.onCloseDialog()
      } else {
        this.userService.getFirebaseUserUid(this.email).subscribe(async result => {
          if (result.uid) {
            this.uid = result.uid
            var licenceHolder = new LicenceHolder()
            licenceHolder.uid = this.uid
            licenceHolder.name = this.name
            licenceHolder.onTrial = this.onTrial
            await this.userService.updateLicenceHolder(licenceHolder)
            var coach = new Coach()
            coach.uid = this.uid
            coach.licenceHolderUid = this.uid
            coach.name = this.name
            coach.hasRecipeDatabaseAccess = true
            coach.trainingEnabled = true
            coach.trainerCode = FirestoreNutritionPlanService.generateUniqueString().toUpperCase()
            await this.userService.updateCoach(coach)
            this.spinner.hide()
            alert(this.translate.instant('Account existiert bereits mit einem eigenen Passwort. Upgrade zum Coach durchgeführt.'))
          } else {
            this.spinner.hide()
            alert(this.translate.instant('Coach konnte nicht erstellt werden.'))
          }
          this.onCloseDialog()
        })
      }
    })
  }
  onCloseDialog() {
    this.dialogRef.close({uid: this.uid});
  }

  onCancel() {
    this.dialogRef.close()
  }
}

