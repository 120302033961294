<div class="dialog-topbar">
    <h3 translate>Email anpassen</h3>
</div>
<div class="dialog-content-wrapper">
    <p>
        <input class="input wide" [(ngModel)]="template.subject" [placeholder]="'Betreff' | translate" type="text">
    </p>
    <br>
    <p>
        <input  class="input full-width" [(ngModel)]="template.heading" [placeholder]="'Überschrift' | translate" type="text">
    </p>
    <br>
    <textarea class="input full-width" [(ngModel)]="template.body" [placeholder]="'Inhalt' | translate" type="text" rows="10"></textarea><br>
    <ng-container *ngIf="requiredPlaceholders?.length > 0">
        <div class="spacer-10"></div>
        <div class="label">
            <span translate>Notwendige Variablen: </span>
            <ng-container *ngFor="let placeholder of requiredPlaceholders">
                <div class="placeholder link" (click)="onPlaceholderClicked(placeholder)" ngxClipboard [cbContent]="'{\{' + placeholder + '}\}'">
                    {{ '{\{' + placeholder + '}\}' }}
                </div>
            </ng-container>
        </div>
    </ng-container>
    <div class="" *ngIf="!containsAllRequiredPlaceholders()">
        <span class="bold">Achtung:</span> Deine Vorlage enthält nicht alle notwendigen Variablen. Bitte füge die fehlenden Variablen hinzu.
    </div>
    <div class="spacer-25"></div>
    <div class="actions row">
        <div class="col-12">
            <button class="button-filled small light" (click)="onCancelDialog()" translate>Abbrechen</button>
            <button class="button-filled small light float-right" (click)="onConfirmDialog()" translate>Bestätigen</button>
        </div>
    </div>
</div>