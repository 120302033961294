import { NgxImageCompressService } from 'ngx-image-compress';
import { Component, NgZone, OnInit } from '@angular/core';
import { Recipe } from '../model/recipe.model';
import { NutritionService } from '../services/nutrition.service';
import { Ingredient } from '../model/ingredient.model';
import { FirestoreService } from '../services/firestore.service';
import { UtilityService } from '../services/utility.service';
import * as marked from 'marked';
import { Tag } from '../model/tag.model';
import { ToastrService } from 'ngx-toastr';
import { DashboardComponent } from '../dashboard/dashboard-v1/dashboard.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientComponent } from '../client/client-v1/client-v1.component';
import { NutritionalValueHolder } from '../model/basenutritionfact.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import {MatDialog } from '@angular/material/dialog';
import { User } from '../model/user.model';
import { RecipeEditorComponent } from '../recipe-editor/recipe-editor.component';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { RecipeSharingDialogComponent } from '../dialogs/recipe-sharing-dialog/recipe-sharing-dialog.component';
import { AuthService } from '../auth/auth.service';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recipes',
  templateUrl: './recipes.component.html',
  styleUrls: ['./recipes.component.css']
})
export class RecipesComponent implements OnInit {

  public environment = environment

  public ALL_RECIPES = 'all'
  public NEW_RECIPES = 'new'
  public UNUSED_RECIPES = 'unused'

  public recipeFilter = this.ALL_RECIPES

  public recipeSharingEnabled: boolean = false
  public autoRecipeSharingEnabled: boolean = false
  public hasRecipeDatabaseAccess: boolean = false

  get isRecipeSuggestionsEnabled() {
    return this.userService.getLoggedInUser().isRecipeSuggestionsEnabled()
  }
  get hideRecipeMigrationHint() {
    return this.userService.getLoggedInUser()?.portalSettingsLicenceHolder?.hideRecipeMigrationHint ?? true
  }
  get recipeQRCodeSharingEnabled() {
    return this.userService.getLoggedInUser().coach.recipeQRCodeSharingEnabled ?? false
  }

  inAppRecipesMode = false
  public inAppRecipes: Recipe[]

  //public allAssignedUsersSelected: boolean = false
  //public assignedUsersDropdownItems: DropdownItem[] = []
  public filteredRecipes: Recipe[]
  public selectedRecipe: Recipe
  public defaultTags: Tag[]
  public filteredDatabaseRecipes: Recipe[]
  public searchInput: string

  public lastRecipeDatabaseOpenDate: Date

  public loadingRecipes = false
  public spinnerText = null

  public clientsWithOldAppVersion: User[] = null

  getPopover() {
    return 'Überschrift\nText'
  }

  constructor(public nutritionService: NutritionService, private userService: FirestoreService, public utilityService: UtilityService, private toastr: ToastrService, private spinner: NgxSpinnerService, public dialog: MatDialog, private ngZone: NgZone) { }

  ngOnInit(): void {
    if (!(this.nutritionService.recipes?.length > 0)) {
      this.loadingRecipes = true
      this.spinner.show()
      this.nutritionService.loadRecipes().toPromise().then(async success => {
        this.spinner.hide()
        /*this.nutritionService.recipes.forEach(recipe => {
          if (!recipe.thumbnailURL) this.nutritionService.loadRecipeImage(recipe)
        })*/
        this.loadingRecipes = false
        this.matchNewAndOldRecipeVersions()
      })
    } else {
      //this.recoverRecipes()
    }

    this.matchNewAndOldRecipeVersions()

    /*this.nutritionService.recipes.forEach(recipe => {
      if (!recipe.thumbnailURL) this.nutritionService.loadRecipeImage(recipe)
    })*/
  
    this.filteredRecipes = null
    this.filteredDatabaseRecipes = null
    this.recipeSharingEnabled = this.userService.getLoggedInUser().isRecipeSharingEnabled()
    this.hasRecipeDatabaseAccess = this.userService.getLoggedInUser().hasRecipeDatabaseAccess    

    this.autoRecipeSharingEnabled = this.userService.getLoggedInUser().portalSettingsLicenceHolder.autoShareNewRecipesEnabled
    this.lastRecipeDatabaseOpenDate = this.userService.getLoggedInUser()?.portalSettingsCoach?.lastRecipeDatabaseOpenDate

    //this.migrateNutrilizeRecipes('to4IhVEXA4U3gcwtZkOPVYS1yp13')
  }

  async onInAppRecipesModeChanged() {
    this.inAppRecipesMode = !this.inAppRecipesMode
    if (this.inAppRecipesMode) {
      if (!this.inAppRecipes) {
        this.spinner.show()
        this.inAppRecipes = await firstValueFrom(this.nutritionService.getRecipesByUid('d6EQi9raVsfZ3yNLuwsgoIZV68n1', null, false))
        var recipes = this.inAppRecipes
        for (let recipe of recipes) {
          if (recipe.modelVersion == 2 && recipe.baseMealTemplateId) {
            var baseRecipe = await this.nutritionService.getBaseRecipe(recipe.baseMealTemplateId)
            if (baseRecipe) recipe.merge(baseRecipe)
          }
        }
        recipes.sort((a,b) => a.getName().localeCompare(b.getName()));
        this.filteredRecipes = this.inAppRecipes
        this.spinner.hide()
      } else {
        this.filteredRecipes = this.inAppRecipes
      }
    } else {
      this.updateFilteredRecipes()
    }
  }

  async recoverRecipes() {
    console.log('Recover recipes')
    for (var recipe of this.nutritionService.recipes) {
      await this.nutritionService.loadFullRecipe(recipe)
      if (recipe.getIngredients().length == 0) {
        console.log(recipe.getName())
        await RecipeEditorComponent.loadRecipeBackup(recipe, this.ngZone, this.userService)
        console.log(recipe.getIngredients().length)
        if (recipe.getIngredients().length > 0) await this.nutritionService.updateRecipe(recipe, this.userService.getLoggedInUser())
      }
    }
  }

  migrateNutrilizeRecipes(creatorUid: string) {
    this.nutritionService.getRecipesByUid(creatorUid, null, false).toPromise().then(recipes => {
      for (var recipe of recipes) {
        if (recipe.getName().toLowerCase().includes('nutrilize')) {
          console.log(recipe.getName())
          //this.firestore.collection('MealTemplates/').doc(recipe.id).update({creatorUid: 'd6EQi9raVsfZ3yNLuwsgoIZV68n1'})
          // nutrilize: d6EQi9raVsfZ3yNLuwsgoIZV68n1
          // Alex: to4IhVEXA4U3gcwtZkOPVYS1yp13
        }
      }
    })
  }

  onSelectRecipeFilter(filter: string) {
    this.recipeFilter = filter
  }

  needsMigration() {
    return !this.hideRecipeMigrationHint && this.nutritionService.recipes?.length > 0 //&& this.userService.getClients()?.length > 0 && this.userService.getClients()?.length <= 10
  }
  onHideRecipeMigrationHint() {
    this.userService.getLoggedInUser().portalSettingsLicenceHolder.hideRecipeMigrationHint = true
    this.userService.updatePortalSettingsForLicenceHolder(this.userService.getLoggedInUser())
  }
  matchNewAndOldRecipeVersions() {
    if (this.nutritionService.recipesLoaded == true && this.nutritionService.recipes.length == 0 && !this.hideRecipeMigrationHint) {
      this.onHideRecipeMigrationHint()
      return
    }
    if (!this.needsMigration()) return
    this.nutritionService.recipes.forEach(recipe => {
      if (recipe.modelVersion < 2) {
        this.nutritionService.databaseRecipes.forEach(dbRecipe => {
          if (dbRecipe.getName() == recipe.getName()) {
            if (recipe.getCalories() == dbRecipe.getCalories() && recipe.getName('en') == dbRecipe.getName('en') && recipe.getInstructions('de') == dbRecipe.getInstructions('de') && recipe.getInstructions('en') == dbRecipe.getInstructions('en') && recipe.getServings() == dbRecipe.getServings() && recipe.allowWeightAdjustment == dbRecipe.allowWeightAdjustment ) return
            recipe.newRecipeVersion = dbRecipe
          }
        })
      }
    })
    this.clientsWithOldAppVersion = []
    this.userService.getClients().forEach(client => {
      if (client.versionCode < 294) {
        this.clientsWithOldAppVersion.push(client)
      }
    })
    if (this.clientsWithOldAppVersion.length == 0) this.clientsWithOldAppVersion = null
  }
  getClientNamesWithOldAppVersion() {
    return this.clientsWithOldAppVersion.map(client => client.getName()).join(', ')
  }
  getNumberOfRecipeUpdates() {
    var count = 0
    this.nutritionService.recipes.forEach(recipe => {
      if (recipe.newRecipeVersion) count++
    })
    return count
  }
  getRecipeVersionChanges(recipe: Recipe) {
    var changes = []
    if (recipe.newRecipeVersion) {
      if (recipe.getCalories() != recipe.newRecipeVersion.getCalories()) changes.push('Nährwerte/Lebensmittel')
      if (recipe.getName('en') != recipe.newRecipeVersion.getName('en')) changes.push('Name (EN)')
      if (recipe.getInstructions('de') != recipe.newRecipeVersion.getInstructions('de')) changes.push('Anleitung (DE)')
      if (recipe.getInstructions('en') != recipe.newRecipeVersion.getInstructions('en')) changes.push('Anleitung (EN)')
      if (recipe.getServings() != recipe.newRecipeVersion.getServings()) changes.push('Portionen')
      if (recipe.allowWeightAdjustment != recipe.newRecipeVersion.allowWeightAdjustment) changes.push('Gewichtsanpassung für Lebensmittel')
    }
    return changes?.join(', ')
  }

  async onMigrateAllRecipes() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du wirklich alle Rezepte auf die neue Version aktualisieren?', title: 'Alle Rezepte updaten' },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show()
        for (var i = 0; i < this.nutritionService.recipes.length; i++) {
          var recipe = this.nutritionService.recipes[i]
          if (recipe.newRecipeVersion) {
            await this.onMigrateToNewVersion(recipe, false)
          }
        }
        this.spinner.hide()
      }
    })
  }

  async onMigrateToNewVersion(recipe: Recipe, useSpinner: boolean = true) {
    if (useSpinner) this.spinner.show()
    
    var newRecipe = recipe.newRecipeVersion.cloneFromBaseRecipe()
    newRecipe.shared = recipe.shared
    newRecipe.assignedUsers = recipe.assignedUsers
    newRecipe.loaded = false

    await this.nutritionService.insertRecipe(newRecipe, this.userService.getLoggedInUser(), false)
    await this.nutritionService.deleteRecipe(recipe, this.userService.getLoggedInUser(), false)

    var index = this.nutritionService.recipes.indexOf(recipe)
    this.nutritionService.recipes.splice(index, 1, newRecipe)
    this.nutritionService.clearRecipeThumbnailUrl(newRecipe)

    if (useSpinner) this.spinner.hide()
  }

  ngOnDestroy(): void {
    this.spinner.hide()
  }

  getLastRecipeDatabaseOpenDate() {
    return this.lastRecipeDatabaseOpenDate
  }
  getFilteredRecipes() {
    return this.filteredRecipes || this.nutritionService.recipes
  }
  getFilteredDatabaseRecipes() {
    return (this.filteredDatabaseRecipes || this.nutritionService.databaseRecipes || []).filter(r => {
      if (this.recipeFilter == this.ALL_RECIPES) return true
      if (this.recipeFilter == this.NEW_RECIPES) return this.isNewRecipe(r)
      if (this.recipeFilter == this.UNUSED_RECIPES) return !r.alreadyInDatabase
    })
  }
  isNewRecipe(recipe: Recipe) {
    var ownRecipe = this.nutritionService.recipes.find(r => r.baseMealTemplateId == recipe.id)
    return !this.getLastRecipeDatabaseOpenDate() || recipe.creationDate && recipe.creationDate > this.getLastRecipeDatabaseOpenDate() && (!ownRecipe || ownRecipe.isModifiedBaseRecipe())
  }

  onRecipeSearchInputChanged(text: string) {
    this.searchInput = text
    this.updateFilteredRecipes()
  }

  updateFilteredRecipes() {
    if (!this.searchInput || this.searchInput.length == 0) {
      this.filteredRecipes = this.nutritionService.recipes
    } else {
      var words = this.searchInput.split(' ')
      var tags = words.filter(x => x.includes('#')).map(x => x.replace('#', '').toLowerCase())
      words = words.filter(x => !x.includes('#'))
      
      this.filteredRecipes = this.nutritionService.recipes.filter(recipe => {
        var contains = true
        words.forEach(word => {
          if (!recipe.getName().toLowerCase().includes(word.toLowerCase())) contains = false
        })
        
        if (tags.length > 0) {
          var allTagsMatching = true
          tags.forEach(tag => {
            var match = false
            recipe.getComputedTags().forEach(t => {
              if (t.printableIdentifier.toLowerCase().includes(tag)) match = true
            })
            if (!match) allTagsMatching = false
          })
          if (!allTagsMatching) contains = false
        }
        return contains
      })
    }
  }
  onDeleteRecipeSearchInput() {
    this.searchInput = null;
    (<HTMLInputElement> document.getElementById('recipesearch-input')).value = ''
    this.filteredRecipes = this.nutritionService.recipes
  }
  
  onRecipeDatabaseSearchInputChanged(text: string) {
    if (!text || text.length == 0) {
      this.filteredDatabaseRecipes = this.nutritionService.databaseRecipes
    } else {

      var tags = text.includes('#') ? text.split(' ').map(x => x.replace('#', '').toLowerCase()).filter(x => x?.length > 0) : null
      if (tags?.length == 0) tags = null
      this.filteredDatabaseRecipes = []
      this.nutritionService.databaseRecipes.forEach(recipe => {
        if ((tags == null && recipe.getName().toLowerCase().includes(text.toLowerCase()))) {
          this.filteredDatabaseRecipes.push(recipe)
        } else if (tags != null) {
          var allTagsMatching = true
          tags.forEach(tag => {
            var match = false
            recipe.getComputedTags().forEach(t => {
              if (t.printableIdentifier.toLowerCase().includes(tag)) match = true
            })
            if (!match) allTagsMatching = false
          })
          if (allTagsMatching) this.filteredDatabaseRecipes.push(recipe)
        }
      })
    }
  }
  onDeleteRecipeDatabaseSearchInput() {
    (<HTMLInputElement> document.getElementById('recipedatabasesearch-input')).value = ''
    this.filteredDatabaseRecipes = this.nutritionService.databaseRecipes
  }

  onIsRecipeQRCodeSharingEnabledChanged(value: boolean) {
    this.spinner.show();
    this.userService.getLoggedInUser().coach.recipeQRCodeSharingEnabled = value
    this.userService.updateCoach(this.userService.getLoggedInUser().coach)
    this.spinner.hide();
  }

  onRecipeSharingEnabledChanged(value: boolean) {
    this.recipeSharingEnabled = value
    this.userService.updateRecipeSharingEnabled(this.userService.getLoggedInUser(), this.recipeSharingEnabled)
    if (this.recipeSharingEnabled) {
      this.toastr.warning("Durch diese Einstellung wird noch nichts automatisch geteilt. Du musst jedes Rezept individuell teilen und Nutzern zuweisen.", "",  {
        positionClass: 'toast-bottom-center',
        timeOut: 10000,
      });
    }
  }
  async onAutoRecipeSharingEnabledChanged(value: boolean) {
    this.spinner.show()
    this.autoRecipeSharingEnabled = value
    this.userService.getLoggedInUser().portalSettingsLicenceHolder.autoShareNewRecipesEnabled = value
    this.userService.updatePortalSettingsForLicenceHolder(this.userService.getLoggedInUser())
    if (value) {
      for (var recipe of this.nutritionService.recipes) {
        recipe.shared = true
        recipe.assignedUsers = ["all"]
        await this.nutritionService.updateRecipeSharingSettings(recipe, this.userService.getLoggedInUser())
      }
    }
    this.spinner.hide()
  }

  onRecipeSuggestionsEnabledChanged(value: boolean) {
    this.userService.getLoggedInUser().coach.recipeSuggestionsEnabled = value
    this.userService.updateRecipeSuggestionsEnabled(this.userService.getLoggedInUser(), this.isRecipeSuggestionsEnabled)
  }

  async onOpenRecipeDatabase() {
    if (this.hasRecipeDatabaseAccess && !(this.nutritionService.databaseRecipes?.length > 0)) {
      this.spinner.show()
      await this.nutritionService.loadRecipeDatabase()
      this.userService.updatePortalSettingsLastRecipeDatebaseOpenDate(this.userService.getLoggedInUser(), new Date())
      this.spinner.hide()
    }
    this.matchDatabaseWithOwnRecipes()
    this.toggleRecipeDatabaseDialog()
  }

  matchDatabaseWithOwnRecipes() {
    this.nutritionService.databaseRecipes.forEach(dbRecipe => {
      var contains = false
      this.nutritionService.recipes.forEach(userRecipe => {
        if (userRecipe.getName() == dbRecipe.getName()) contains = true
      })
      if (contains) dbRecipe.alreadyInDatabase = true
    })
  }

  toggleRecipeDatabaseDialog() {
    document.getElementById('recipe-database-dialog').classList.toggle('show'); 
  }

  async onAddDatabaseRecipe(databaseRecipe: Recipe) {
    this.spinner.show()
    await this.copyRecipeFromDatabase(databaseRecipe)
    this.spinner.hide()
  }

  async copyRecipeFromDatabase(databaseRecipe: Recipe) {
    if (!databaseRecipe.loaded && databaseRecipe.modelVersion < 2) {
      databaseRecipe.ingredients = await this.nutritionService.getRecipeIngredients(databaseRecipe, true, false)
      if (!databaseRecipe.calories) databaseRecipe.recalculateNutritionalValues()
      databaseRecipe.loaded = true
    }
    var recipe = databaseRecipe.cloneFromBaseRecipe()
    if (this.inAppRecipesMode) recipe.creatorUid = 'd6EQi9raVsfZ3yNLuwsgoIZV68n1'
    recipe.id = null
    recipe.shared = false
    recipe.assignedUsers = []
    recipe.loaded = false
    if (this.autoRecipeSharingEnabled) {
      recipe.shared = true
      recipe.assignedUsers = ["all"]
    }
    console.log('Copy recipe ', databaseRecipe.modelVersion, ' ', recipe.thumbnailPath)
    if (databaseRecipe.modelVersion < 2) {
      if (databaseRecipe.thumbnailURL) {
        recipe.thumbnailPath = 'meal_templates/' + databaseRecipe.id + '/thumbnail.jpg'
      }
    }
    await this.nutritionService.insertRecipe(recipe, this.userService.getLoggedInUser(), true, this.inAppRecipesMode ? 'd6EQi9raVsfZ3yNLuwsgoIZV68n1' : null)
  }

  fetchImage(url): Promise<File> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        var status = xhr.status;
        if (status == 200) {
          const blob = new Blob([xhr.response], { type: 'image/jpg' });
          const file = new File([blob], "thumbnail.jpg", {
            type: 'image/jpeg',
            lastModified: Date.now()
          });
          resolve(file)
        } else {
          reject(status);
        }
      };
      xhr.open('GET', url);
      xhr.setRequestHeader('Access-Control-Request-Headers', 'access-control-allow-origin')
      xhr.send();
    })
  }

  async onViewDatabaseRecipe(recipe: Recipe) {
    if (!recipe.loaded) {
      this.spinner.show()
      await this.nutritionService.loadFullRecipe(recipe)
      if (!recipe.calories) recipe.recalculateNutritionalValues()
      recipe.loaded = true
      this.spinner.hide()
    }
    
    const dialogRef = this.dialog.open(RecipeEditorComponent, { data: { recipe: recipe, readOnly: true}, width: '1000px'})
  }

  selectedDatabaseRecipes: Recipe[] = []
  onAddDatabaseRecipeToSelection(recipe: Recipe) {
    if (this.selectedDatabaseRecipes.includes(recipe)) {
      this.selectedDatabaseRecipes.splice(this.selectedDatabaseRecipes.indexOf(recipe), 1)
    } else {
      this.selectedDatabaseRecipes.push(recipe)
    }
  }

  async onCopySelectedDatabaseRecipe() {
    this.spinner.show()
    for (var i = 0; i < this.selectedDatabaseRecipes.length; i++) {
      this.spinnerText = 'Rezepte kopieren (' + (i + 1) + ' von ' + this.selectedDatabaseRecipes.length + ')'
      var recipe = this.selectedDatabaseRecipes[i]
      await this.copyRecipeFromDatabase(recipe)
    }
    this.selectedDatabaseRecipes = []
    this.spinner.hide()
    this.spinnerText = null
  }

  async onCopyRecipeDatabase() {
    this.spinner.show()
    for (var i = 0; i < this.nutritionService.databaseRecipes.length; i++) {
      this.spinnerText = 'Rezepte kopieren (' + (i + 1) + ' von ' + this.nutritionService.databaseRecipes.length + ')'
      var recipe = this.nutritionService.databaseRecipes[i]
      var contains = false;
      (this.inAppRecipesMode ? this.inAppRecipes : this.nutritionService.recipes).forEach(userRecipe => {
        if (userRecipe.getName() == recipe.getName()) contains = true
      })
      if (!contains) {
        await this.copyRecipeFromDatabase(recipe)
      }
    }
    this.spinner.hide()
    this.spinnerText = null
  }

  selectedRecipes: Recipe[] = []
  onAddRecipeToSelection(recipe: Recipe) {
    if (this.selectedRecipes.includes(recipe)) {
      this.selectedRecipes.splice(this.selectedRecipes.indexOf(recipe), 1)
    } else {
      this.selectedRecipes.push(recipe)
    }
  }
  onEditSharingOfSelectedRecipes() {
    if (this.selectedRecipes.length == 0) return
    const dialogRef = this.dialog.open(RecipeSharingDialogComponent, {
      data: { shared: this.selectedRecipes[0].shared, assignedUsers: this.selectedRecipes[0].assignedUsers, assignedGroupNames: this.selectedRecipes[0].assignedGroupNames }, width: '400px'
    })
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        var shared = result.shared
        var assignedUsers = result.assignedUsers
        var assignedGroupNames = result.assignedGroupNames
        this.spinner.show()
        var i = 0
        for (var recipe of this.selectedRecipes) {
          this.spinnerText = 'Rezepte teilen (' + (i + 1) + ' von ' + this.selectedRecipes.length + ')'
          recipe.shared = shared
          recipe.assignedUsers = assignedUsers
          recipe.assignedGroupNames = assignedGroupNames
          await this.nutritionService.updateRecipeSharingSettings(recipe, this.userService.getLoggedInUser())
          i++
        }
        this.spinnerText = null
        this.spinner.hide()
        this.updateFilteredRecipes()
      }
    })
  }
  onResetSelectedRecipes() {
    this.selectedRecipes = []
  }
 
  onSelectAllRecipes() {
    this.selectedRecipes = this.getFilteredRecipes()
  }
  
  async onDeleteSelectedRecipes() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du diese Rezepte wirklich löschen?', title: 'Rezepte löschen' },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show()
        for (var recipe of this.selectedRecipes) {
          await this.nutritionService.deleteRecipe(recipe, this.userService.getLoggedInUser(), false).then(success => { })
        }
        this.selectedRecipes = []
        this.loadingRecipes = true
        this.nutritionService.loadRecipes().toPromise().then(success => {
          this.spinner.hide()
          /*this.nutritionService.recipes.forEach(recipe => {
            if (!recipe.thumbnailURL) this.nutritionService.loadRecipeImage(recipe)
          })*/
          this.loadingRecipes = false
        })
      }
    })
  }

  async onCopySelectedRecipesToIap() {
    this.spinner.show()
    for (var databaseRecipe of this.selectedRecipes) {
      if (!databaseRecipe.loaded && databaseRecipe.modelVersion < 2) {
        databaseRecipe.ingredients = await this.nutritionService.getRecipeIngredients(databaseRecipe, true, false)
        if (!databaseRecipe.calories) databaseRecipe.recalculateNutritionalValues()
        databaseRecipe.loaded = true
      }
      var recipe = databaseRecipe.clone()
      recipe.creatorUid = 'd6EQi9raVsfZ3yNLuwsgoIZV68n1'
      recipe.id = null
      recipe.shared = false
      recipe.assignedUsers = []
      recipe.loaded = false
      if (databaseRecipe.modelVersion < 2) {
        if (databaseRecipe.thumbnailURL) {
          recipe.thumbnailPath = 'meal_templates/' + databaseRecipe.id + '/thumbnail.jpg'
        }
      }
      await this.nutritionService.insertRecipe(recipe, this.userService.getLoggedInUser(), true, 'd6EQi9raVsfZ3yNLuwsgoIZV68n1')
    }
    this.spinner.hide()
  }

  async onRecipeSelected(recipe: Recipe) {
    console.log('Open recipe ' + recipe.id + ' (base: ' + recipe.baseMealTemplateId + ')')
    if (!recipe.loaded || !recipe.nutritionFactsLoaded || !recipe.baseRecipe?.nutritionFactsLoaded) {
      this.spinner.show()
      await this.nutritionService.loadFullRecipe(recipe)
      recipe.recalculateNutritionalValues()
      recipe.loaded = true
      this.spinner.hide()
    }
    this.selectedRecipe = recipe.clone()
    
    const dialogRef = this.dialog.open(RecipeEditorComponent, { data: { recipe: this.selectedRecipe}, width: '1000px'})
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show()
        if (result.shouldSaveAsNew && !result.shouldDelete && !AuthService.ADMIN_UIDS.includes(this.userService.getLoggedInUser().uid)) {
          this.selectedRecipe.id = null
          this.selectedRecipe.creationDate = new Date()
          await this.nutritionService.insertRecipe(this.selectedRecipe, this.userService.getLoggedInUser()).then(success => this.updateFilteredRecipes())
          await this.nutritionService.deleteRecipe(recipe, this.userService.getLoggedInUser()).then(success => { })
        } else if (result.shouldSave && !result.shouldDelete || (result.shouldSaveAsNew && AuthService.ADMIN_UIDS.includes(this.userService.getLoggedInUser().uid))) {
          if (!this.selectedRecipe.id) {
            await this.nutritionService.insertRecipe(this.selectedRecipe, this.userService.getLoggedInUser())
          } else {
            await this.nutritionService.updateRecipe(this.selectedRecipe, this.userService.getLoggedInUser())
          }
        } else if (result.shouldDelete) {
          await this.nutritionService.deleteRecipe(this.selectedRecipe, this.userService.getLoggedInUser())
        }
        this.spinner.hide()
        this.updateFilteredRecipes()
      }
    });

  }
  onCreateRecipe() {
    this.selectedRecipe = new Recipe()
    this.selectedRecipe.loaded = true
    this.selectedRecipe.creationDate = new Date()
    if (this.autoRecipeSharingEnabled) {
      this.selectedRecipe.shared = true
      this.selectedRecipe.assignedUsers = ["all"]
    }
    this.onRecipeSelected(this.selectedRecipe)
  }

}
