import { Component, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/auth.service';
import { Payment } from 'src/app/model/payment.model';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { environment } from 'src/environments/environment';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-invoice-view',
  templateUrl: './invoice-view.component.html',
  styleUrls: ['./invoice-view.component.css']
})
export class InvoiceViewComponent {

  get user(): User {
    return this.userService.getLoggedInUser()
  }

  public environment = environment


  paymentId: string
  payment: Payment
  invoiceUrl: string = null
  loadingInvoice = false


  constructor(private router: Router, public userService: FirestoreService, private authService: AuthService, private route: ActivatedRoute, private ngZone: NgZone, public dialog: MatDialog, private spinner: NgxSpinnerService, private toastr: ToastrService, private translate: TranslateService) {
    authService.getAuthState().subscribe(user => {
      if (user) this.loadPayment()
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(async (param) => {
      this.paymentId = param.get('id');
      console.log(this.paymentId)
      this.loadPayment()
    })
  }

  loadPayment() {
    if (this.paymentId) {
      this.userService.getPayment(this.paymentId).then(payment => {
        this.payment = payment
        this.loadDownloadUrl()
      })
    }
  }
  onLogin() {
    const dialogRef = this.dialog.open(LoginDialogComponent, { data: { presetUid: this.payment?.customerUid, redirectPath: 'invoice/' + this.paymentId }, width: '500px'})
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.success) {
          this.loadPayment()
        }
      }
    });
  }
  onLogout() {
    this.userService.logout();
    this.authService.logoutToPage('invoice/' + this.paymentId);
  }

  isPaymentAccessible() {
    if (!this.payment) return false
    if (this.payment.customerUid != null && this.user && this.payment.customerUid != this.user.uid) {
      return false
    }
    if (this.payment.customerUid != null && !this.user) return false
    return true
  }

  loadDownloadUrl() {
    console.log('Load download url')
    if (!this.isPaymentAccessible()) return
    this.loadingInvoice = true
    this.userService.getDownloadUrl('payments/' + this.paymentId + '/' + (this.payment.invoiceFileName ?? (this.translate.instant('Rechnung') + '_' + this.paymentId + '.pdf'))).then(url => {
      this.invoiceUrl = url
      this.loadingInvoice = false
    })
  }

  onDownloadInvoice() {
    window.open(this.invoiceUrl, "_blank");
  }
}
