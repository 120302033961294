
<div class="dialog-topbar {{readOnlyMode ? 'disabled': ''}}">
    <div *ngIf="!readOnlyMode" class="icon-button" (click)="onSavePlannedMeal()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
        <span *ngIf="!utilityService?.onSmallDisplay()" translate>
            Speichern
        </span>
    </div>
    <div class="icon-button float-right ignore-disabled" (click)="onClosePlannedMeal()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        <span *ngIf="!utilityService?.onSmallDisplay()" translate>
            Schließen
        </span>
    </div>
    <div *ngIf="!readOnlyMode" class="icon-button float-right delete-plannedmeal" (click)="onDeletePlannedMeal()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
        <span *ngIf="!utilityService?.onSmallDisplay()" translate>
            Löschen
        </span>
    </div>
</div>
<div class="dialog-content-wrapper" id="meal-editor">
    <div class="row">
        <div *ngIf="meal?.imageLink" class="col-auto">
            <div class="plannedmeal-imagewrapper small-close-button-wrapper">
                <div class="button small-close-button delete-plannedmeal-image" (click)="onDeletePlannedMealImage()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </div>
                <div class="plannedmeal-image" [ngStyle]="{'background-image':'url('+ meal?.imageLink +')'}"></div>
            </div>
            <div class="spacer-25"></div>
        </div>
        <div class="col-md-9 col-sm-12">

            <div class="row">
                <div class="d-inline-block wrapper-mealtype-selection">
                    <button class="button-filled light small dropdown-toggle margin-inbetween {{meal.mealType == null ? ' button-invalid' : ''}}" type="button" id="dropdownMealType" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getMealTypeTranslation(meal.mealType)}}</button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMealType">
                        <div *ngFor="let mealType of mealTypes">
                            <button class="dropdown-item" type="button" (click)="onMealTypeSelected(mealType)">{{getMealTypeTranslation(mealType)}}</button>
                        </div>
                    </div>
                </div>
                <!-- <input *ngIf="meal.type == 'CUSTOM'" class="input mealconfig small wide" [(ngModel)]="meal.customType" type="text" placeholder="Mahlzeitentyp" /><br> -->
            </div>
            <div class="row">
                <div class="col">
                    <div class="spacer-10"></div>
                    <label for="input-mealname" translate>Name</label>
                    <input [disabled]="readOnlyMode" class="input wide-input" [ngModel]="meal?.name" (input)="onMealNameChanged($event.target.value)" [placeholder]="'Name' | translate" type="text">
                </div>
            </div>
            <div class="row">
                <div class="col-auto">
                    <div class="spacer-10"></div>
                    <label for="input-time" translate>Uhrzeit</label>
                    <input [disabled]="readOnlyMode" id="input-time" class="input form-control wide-input" [ngModel]="getTimeOfDate()" (input)="setTimeOfDate($event.target.value)" type="time">
                </div>
            </div>
            <!-- <input id="input-mealname" class="input plannedmeal-name" [ngModel]="meal?.name" (input)="onPlannedMealNameChanged($event.target.value)" placeholder="Name" type="text"> -->
        </div>
    </div>
    
    <div class="spacer-10"></div>
    <div class="meal-content">
        <h3 translate>Inhalt</h3>
        <div class="fontweight-bold">{{meal?.getNutritionalValue('carbohydrates')?.toFixed(0)}}{{'g K' | translate}}/ {{meal?.getNutritionalValue('protein')?.toFixed(0)}}{{'g E' | translate}}/ {{meal?.getNutritionalValue('fat')?.toFixed(0)}}{{'g F' | translate}}/ {{meal?.getNutritionalValue('calories')?.toFixed(0)}} {{'kcal' | translate}}
            <div class="nutritionalvalues-button display-inlineblock link marginleft-10 ignore-disabled" [popover]="nutritionalValuePopover" (click)="onNutritionalValuesFocused(meal)" placement="top" [outsideClick]="true" container="body">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                </svg>
            </div>
        </div>
        <div class="spacer-10"></div>
        <div cdkDropList class="row" (cdkDropListDropped)="dropFood($event)">
            <div *ngFor="let food of meal?.foods" class="col-12 meal-item" cdkDrag>
                <div class="row">
                    <div class="col-8">
                        <div *ngIf="!readOnlyMode" class="drag-handle link" cdkDragHandle>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-grip-vertical" viewBox="0 0 16 16">
                                <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                            </svg>
                        </div>

                        <div *ngIf="!food.isDummy">{{food.name}}<span class="brands">{{food.nutritionFact?.getBrands(languageService.selectedLanguageCode) != null ? ' (' + food.nutritionFact?.getBrands(languageService.selectedLanguageCode) + ')' : ''}}</span></div>
                        <div *ngIf="food.isDummy">{{food.name || 'Dummy'}}</div>
                        <div class="nutritional-values-small">
                            {{food.getNutritionalValue('carbohydrates')?.toFixed(0) ?? '0'}}{{'g K' | translate}}/ {{food.getNutritionalValue('protein')?.toFixed(0) ?? '0'}}{{'g E' | translate}}/ {{food.getNutritionalValue('fat')?.toFixed(0) ?? '0'}}{{'g F' | translate}}/ {{food.getNutritionalValue('calories')?.toFixed(0) ?? '0'}} {{'kcal' | translate}}
                        </div>
                    </div>
                    <div class="food-weight col-3">
                        <div *ngIf="!food.isDummy">
                            <input [disabled]="readOnlyMode" class="input-weight light small textalign-right" [ngModel]="food?.weight" (input)="onPlannedFoodWeightChanged($event.target.value, food)"> g
                        </div>
                    </div>
                    
                    <!-- <div *ngIf="food.isDummy && food.weight != null" class="col-3">
                        <div><input class="input-weight light small textalign-right" [ngModel]="food?.weight" (input)="onPlannedFoodWeightChanged($event.target.value, food)"> {{food?.getUnit()}}</div>
                    </div>
                    <div *ngIf="food.isDummy && food.weight == null" class="col-3">
                        <div *ngIf="food?.weight" class="textalign-right">{{food?.weight}} {{food?.getUnit()}}</div>
                    </div> -->
                    <div class="delete-food col-1">
                        <div class="icon-button float-right" (click)="onDeleteFood(food)">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <ng-container *ngIf="!readOnlyMode">
        <div class="spacer-50"></div>
        <app-ingredient-selector
            [mode]="'PLANNED_FOOD'"
            [selectedFoodSearchSource]="'CUSTOM_RECIPES'"
            (plannedFoodAdded)="onAddPlannedFood($event)"
            (plannedMealAdded)="onAddPlannedMeal($event)">
        </app-ingredient-selector>
    </ng-container>

    <div class="spacer-25"></div>
    <div class="ignore-disabled">
        <h3 translate>Hinweis</h3>
        <app-md-editor  id="editor-note" class="single-line" 
            [(forceUpdate)]="forceEditorValueNoteUpdate"
            [readOnlyMode]="readOnlyMode"
            [placeHolder]="placeholderNote"
            (valueChanged)="onEditorValueNoteChanged($event)"
            [inputValue]="editorValueNote">
        </app-md-editor>
    </div>
    <div class="spacer-25"></div>
    <div class="ignore-disabled">
        <h3 translate>Zubereitung</h3>
        <app-md-editor id="editor-instructions"
            [(forceUpdate)]="forceEditorValueInstructionsUpdate"
            [readOnlyMode]="readOnlyMode"
            [placeHolder]="placeholderInstructions"
            (valueChanged)="onEditorValueInstructionsChanged($event)"
            [inputValue]="editorValueInstructions">
        </app-md-editor>
    </div>
    <div class="spacer-50"></div>
</div>
<ng-template #nutritionalValuePopover>
    <app-nutritional-value-popover [nutritionalValueHolder]="nutritionalValueHolder"></app-nutritional-value-popover>
</ng-template>