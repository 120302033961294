import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TrainingPlanTemplateFolder } from '../model/training-plan-template-folder.model';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-create-template-dialog',
  templateUrl: './create-template-dialog.component.html',
  styleUrls: ['./create-template-dialog.component.css']
})
export class CreateTemplateDialogComponent implements OnInit {

  public name: string
  public description: string
  public availableTemplateFolders: TrainingPlanTemplateFolder[] = [];
  public selectedTemplateFolders: TrainingPlanTemplateFolder[] = [];

  constructor(public dialogRef: MatDialogRef<CreateTemplateDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {name: string, description: string, availableTemplateFolders: TrainingPlanTemplateFolder[], selectedTemplateFolders: TrainingPlanTemplateFolder[]}, public languageService: LanguageService) {
    this.name = data.name
    this.description = data.description
    this.availableTemplateFolders = data.availableTemplateFolders ?? [];
    this.selectedTemplateFolders = data.selectedTemplateFolders ?? [];
  }

  ngOnInit(): void {
  }

  getPrintableSelectedTemplateFolders() {
    let selectedTemplateFoldersString = this.selectedTemplateFolders?.map(x => x.name[this.languageService.selectedLanguageCode])?.join(', ');
    if (selectedTemplateFoldersString?.length > 40) {
      selectedTemplateFoldersString = selectedTemplateFoldersString.substring(0, 40) + '...';
    }
    return selectedTemplateFoldersString;
  }

  onTemplateFolderSelected(templateFolder: TrainingPlanTemplateFolder) {
    if (this.selectedTemplateFolders.includes(templateFolder)) {
      this.selectedTemplateFolders = this.selectedTemplateFolders.filter(x => x !== templateFolder);
    } else {
      this.selectedTemplateFolders.push(templateFolder);
    }
  }

  onCancelDialog() {
    this.dialogRef.close(null);
  }
  onConfirmDialog() {
    this.dialogRef.close({name: this.name, description: this.description, selectedTemplateFolders: this.selectedTemplateFolders});
  }

}
