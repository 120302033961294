import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-banktransfer-dialog',
  templateUrl: './banktransfer-dialog.component.html',
  styleUrls: ['./banktransfer-dialog.component.css']
})
export class BanktransferDialogComponent {

  public amount: number
  public currency: string
  public purposeOfTransfer: string

  constructor(public dialogRef: MatDialogRef<BanktransferDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { amount: number, currency: string, purposeOfTransfer: string }, public dialog: MatDialog, private toastr: ToastrService, private translate: TranslateService) {
    this.amount = data.amount
    this.currency = data.currency
    this.purposeOfTransfer = data.purposeOfTransfer
  }

  
  getPrintableAmount() {
      return (this.amount / 100).toString().replace('.', ',')
  }
  getPrintableAmountWithCurrency() {
      return this.getPrintableAmount() + ' ' + this.getPrintableCurrency()
  }
  getPrintableCurrency() {
      if (this.currency == 'eur' || this.currency == null) return '€'
      if (this.currency == 'chf') return 'CHF'
      if (this.currency == 'usd') return '$'
      if (this.currency == 'gbp') return '£'
      return this.currency
  }

  onCopied() {
    this.toastr.success(this.translate.instant("In Zwischenablage kopiert"), "",  {
      positionClass: 'toast-bottom-center'
    })
  }

  onConfirmBankTransfer() {
    this.dialogRef.close({bankTransferInitiated: true})
  }

  onClose() {
    this.dialogRef.close();
  }
}
