// import { Identifiers } from '@angular/compiler';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { Serving } from './serving.model';

export class ServingInquiry {

    public IDENTIFIER_PACKAGE = 'PACKAGE'
    public IDENTIFIER_SERVING = 'SERVING'
    public IDENTIFIER_CUSTOM = 'CUSTOM'

    public id: string
    public baseNutritionFactId: string
    public amount: number
    public identifier: string
    public servingSize: string
    public unit: string

    constructor();
    constructor(init: ServingInquiry);
    constructor(init?: ServingInquiry) {
        this.id = null
        this.baseNutritionFactId = init && init.baseNutritionFactId || null
        this.amount = init && init.amount || null
        this.identifier = init && init.identifier || null
        this.servingSize = init && init.servingSize || null
        this.unit = init && init.unit || null
    }

    static servingsFromString(string: string): Serving[] {
        if (!string || string.length == 0) return []
        var servings = []
        var rawServings = string.split(';')
        rawServings.forEach(rawServing => {
            var serving = this.servingFromString(rawServing)
            if (serving) servings.push(serving)
        })
        return servings
    }

    static servingFromString(rawServing: string): Serving {
        try {
            var serving = new Serving()
            serving.rawServing = rawServing
            var data = rawServing.split(':')
            var weight = 0;
            if (data[1].indexOf('kg') != -1) {
                weight = parseFloat(data[1].replace('kg', '')) * 1000;
            } else if (data[1].indexOf('g') != -1) {
                weight = parseFloat(data[1].replace('g', ''))
            } else if (data[1].indexOf('ml') != -1) {
                weight = parseFloat(data[1].replace('ml', ''))
            } else if (data[1].indexOf('l') != -1) {
                weight = parseFloat(data[1].replace('l', '')) * 1000;
            }
            serving.amount = weight
            if (data[0] == serving.IDENTIFIER_PACKAGE) {
                serving.identifier = serving.IDENTIFIER_PACKAGE
                serving.printableIdentifier = marker('Packung')
                return serving
            } else if (data[0] == serving.IDENTIFIER_SERVING) {
                serving.identifier = serving.IDENTIFIER_SERVING
                serving.printableIdentifier = marker('Portion')
                return serving
            } else if (data[0].startsWith(serving.IDENTIFIER_CUSTOM)) {
                serving.identifier = serving.IDENTIFIER_CUSTOM
                serving.printableIdentifier = data[0].replace('CUSTOM\'', '').replace('\'', '')
                return serving
            }
        } catch (error) {}
        return null
    }
}