import { Component, Input, OnInit } from '@angular/core';
import {MatDialog } from '@angular/material/dialog';
import { MergedTrainingExercise } from 'src/app/model/training-exercise';
import { TrackedTrainingSession, TrackedVideoRecording } from 'src/app/model/training-monitoring.model';
import { User } from 'src/app/model/user.model';
import { VideoRecordingDialogComponent } from '../video-recording-dialog/video-recording-dialog.component';
import { ChartExportService } from 'src/app/services/chart-export.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { LanguageService } from 'src/app/services/language.service';
import { TrainingSessionContainer } from '../training-history-dialog/training-history-dialog.component';
import { SetParameter, SetParameter2LabelUnitMapping, SetParameter2ShortLabelMapping } from 'src/app/model/training-plan.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-training-graph-extension',
  templateUrl: './training-graph-extension.component.html',
  styleUrls: ['./training-graph-extension.component.css']
})
export class TrainingGraphExtensionComponent implements OnInit {

  private user: User
  @Input() set User(value: User){
    this.user = value;
  }

  public get userUid() {
    return this.user?.uid
  }

  private sessions: TrackedTrainingSession[]
  @Input() set TrackedTrainingSessions(value: TrackedTrainingSession[]){
    this.sessions = value;
    this.updateTableData()
    this.updateVideoRecordingSessions()
  }

  private exercises: MergedTrainingExercise[]
  @Input() set TrainingExercise(value: MergedTrainingExercise[]){
    this.exercises = value;
    this.updateTableData()
    this.updateVideoRecordingSessions()
  }
  
  constructor(public dialog: MatDialog, public chartExportService: ChartExportService, public firestoreService: FirestoreService, public languageService: LanguageService, public translate: TranslateService) { }

  tableData: any[] = []
  graphParams: any[] = []

  showDataTable: boolean = false
  onShowDataTableChanged(value: boolean) {
    this.showDataTable = value
  }
  showVideoRecordings: boolean = false
  onShowVideoRecordingsChanged(value: boolean) {
    this.showVideoRecordings = value
  }

  videoRecordingSessions: TrackedTrainingSession[] = []
  

  ngOnInit(): void {
  }

  calculateCellValue(rowData: any) {
    return - rowData.date
  }

  getRecordingsForSession(session: TrackedTrainingSession): TrackedVideoRecording[] {
    // if (!this.exercises || this.exercises.length == 0) return []
    // var trackedExercise = session.getTrackedExerciseByExerciseId(this.exercises[0].sourceExerciseId)
    let recordings = session.trackedTrainingExercises.filter(x => x.hasVideoRecordings()).map(x => x.recordings).flat();
    return recordings;
  }

  getExercisesForRecordingSessions(sessions: TrackedTrainingSession[]): string[] {
    let exerciseIds = [];
    sessions.forEach(session => {
      session.trackedTrainingExercises.forEach(exercise => {
        if(exercise.hasVideoRecordings() && !exerciseIds.includes(exercise.exerciseId)){
          exerciseIds.push(exercise.exerciseId);
        }
      })
    });
    return exerciseIds;
  }

  getSessionsForExercise(exerciseId: string, sessions: TrackedTrainingSession[]): TrackedTrainingSession[] {
    return sessions.filter(x => x.trackedTrainingExercises.find(y => y.hasVideoRecordings() && y.exerciseId == exerciseId && (this.exercises == null || this.exercises?.length == 0 || this.exercises.find(z => z.sourceExerciseId == exerciseId) != null)));
  }

  getRecordingsForExercise(exerciseId: string, session: TrackedTrainingSession): TrackedVideoRecording[] {
    let trackedExercise = session.getTrackedExerciseByExerciseId(exerciseId);
    return trackedExercise.recordings;
  }

  getMergedExerciseById(exerciseId: string): MergedTrainingExercise {
    return this.exercises.find(x => x.sourceExerciseId == exerciseId);
  }

  getPlannedSession(session: TrackedTrainingSession) {
    return this.user.getPlannedSessionById(session.trainingPlanId, session.plannedSessionId)
  }

  async onShowTrackedVideoRecording(recording: TrackedVideoRecording, session: TrackedTrainingSession, exerciseId: string) {
    var trackedExercise = session.getTrackedExerciseByExerciseId(exerciseId)
    const dialogRef = await this.dialog.open(VideoRecordingDialogComponent, { data: { userUid: this.user.uid, trackedExercise: trackedExercise, recording: recording, exercise: this.getMergedExerciseById(exerciseId)}, width: '1000px', autoFocus: false}).afterClosed().subscribe(result => {
    })
  }

  updateVideoRecordingSessions() {
    var sessions: TrackedTrainingSession[] = []
    this.sessions.forEach(session => {
      if(session?.trackedTrainingExercises?.find(x => x.hasVideoRecordings() && (this.exercises == null || this.exercises?.length == 0 || this.exercises.find(z => z.sourceExerciseId == x.exerciseId) != null))){
        sessions.push(session)
      }
    })
    sessions.sort((a, b) => b.startDate.getTime() - a.startDate.getTime())
    this.videoRecordingSessions = sessions
  }

  updateTableData() {
    if (!this.exercises) return

    var graphParams = []
    var additionalGraphParamIds = []
    let trackedSets = this.sessions.map(x => x.trackedTrainingExercises).flat().map(x => x.trackedSets).flat();
    let availableParameterSets = Object.values(SetParameter).filter(parameter => {
      return trackedSets.find(x => x[parameter] != null) != null;
    });
    this.exercises.forEach(exercise => {
      let subGraphParams = []
      availableParameterSets.forEach(parameter => {
        if(parameter != SetParameter.rir && parameter != SetParameter.rpe){
          subGraphParams.push({valueField: (exercise.sourceExerciseId ?? '') + parameter, name: this.translate.instant(SetParameter2ShortLabelMapping[parameter] ?? ' '), axis: "countAxis", unit: this.translate.instant(SetParameter2LabelUnitMapping[parameter] ?? ' ')})
        }
      });
      subGraphParams.push({valueField: (exercise.sourceExerciseId ?? '') + "e1rm", name: "E1RM", axis: "weightAxis", unit: ""});
      graphParams.push( { exerciseId: exercise.sourceExerciseId, name: exercise.name.GetValue(this.translate.currentLang) + (exercise.subName?.GetValue(this.translate.currentLang) ? ' (' + exercise.subName?.GetValue(this.translate.currentLang) + ')' : ''), subGraphParams: subGraphParams } )
      // graphParams.push( { exerciseId: exercise.sourceExerciseId, name: exercise.name.GetValue('de') + (exercise.subName ? ' (' + exercise.subName.GetValue('de') + ')' : ''), subGraphParams: [
      //   {valueField: (exercise.sourceExerciseId ?? '') + "reps", name: "Wdh", axis: "countAxis", unit: "Wdh"},
      //   {valueField: (exercise.sourceExerciseId ?? '') + "weight", name: "Gewicht", axis: "weightAxis", unit: "kg"},
      //   {valueField: (exercise.sourceExerciseId ?? '') + "e1rm", name: "E1RM", axis: "weightAxis", unit: ""},
      //   //{valueField: (exercise.sourceExerciseId ?? '') + "rpe", name: "RPE", axis: "rpeAxis", unit: ""},
      //   //{valueField: (exercise.sourceExerciseId ?? '') + "rir", name: "RIR", axis: "rirAxis", unit: ""},
      // ] } )
    })

    var statisticsItems: any[] = []

    this.sessions.forEach(session => {

      var setItemsMap: Map<number, any[]> = new Map()

      this.exercises.forEach(exercise => {

        var trackedExercise = session.getTrackedExerciseByExerciseId(exercise.sourceExerciseId)

        trackedExercise?.trackedSets.forEach(set => {
          var statisticsItem: any
          if (setItemsMap.has(set.setNumber)) {
            statisticsItem = setItemsMap.get(set.setNumber)
          } else {
            var date = session.startDate
            statisticsItem = {
              date: date,
              dateRendered: date.asShortFormatedString(),
              headingRendered: (this.user.getPlannedSessionById(session.trainingPlanId, session.plannedSessionId)?.nameTranslation?.GetValue(this.translate.currentLang) || this.translate.instant('Eigene Einheit')) + ' ' + date.asShortFormatedString(),
              set: (set.setNumber + 1),
              setRendered: this.translate.instant('{{setNumberParameter}}. Satz', {setNumberParameter: set.setNumber + 1})
            }
            setItemsMap.set(set.setNumber, statisticsItem)
          }

          // user availableParameterSets
          availableParameterSets.forEach(parameter => {
            var value = set[parameter]
            if (value != null) {
              statisticsItem[exercise.sourceExerciseId + parameter] = value
              statisticsItem[exercise.sourceExerciseId + parameter + "Rendered"] = value
            }
          });
          
          // var totalWeight = set.getWeight()
          // if (totalWeight != null) {
          //   statisticsItem[exercise.sourceExerciseId + "weight"] = totalWeight
          //   statisticsItem[exercise.sourceExerciseId + "weightRendered"] = totalWeight
          // }
          // var repCount = set.getRepCount()
          // if (repCount != null) {
          //   statisticsItem[exercise.sourceExerciseId + "reps"] = repCount
          //   statisticsItem[exercise.sourceExerciseId + "repsRendered"] = repCount
          // }
          var exertion = set.exertionValue
          if (exertion != null) {
            statisticsItem[exercise.sourceExerciseId + "exertion"] = exertion
            statisticsItem[exercise.sourceExerciseId + "exertionRendered"] = exertion
          }
          var rpe = set.rpe
          if (rpe != null) {
            statisticsItem[exercise.sourceExerciseId + "rpe"] = rpe
            statisticsItem[exercise.sourceExerciseId + "rpeRendered"] = rpe
            if (!additionalGraphParamIds.includes((exercise.sourceExerciseId ?? '') + "rpe")) {
              graphParams.find(gp => gp.exerciseId == exercise.sourceExerciseId).subGraphParams.push({valueField: (exercise.sourceExerciseId ?? '') + "rpe", name: "RPE", axis: "rpeAxis", unit: ""})
              additionalGraphParamIds.push((exercise.sourceExerciseId ?? '') + "rpe")
            }
          }
          var rir = set.rir
          if (rir != null) {
            statisticsItem[exercise.sourceExerciseId + "rir"] = rir
            statisticsItem[exercise.sourceExerciseId + "rirRendered"] = rir
            if (!additionalGraphParamIds.includes((exercise.sourceExerciseId ?? '') + "rir")) {
              graphParams.find(gp => gp.exerciseId == exercise.sourceExerciseId).subGraphParams.push({valueField: (exercise.sourceExerciseId ?? '') + "rir", name: "RIR", axis: "rirAxis", unit: ""})
              additionalGraphParamIds.push((exercise.sourceExerciseId ?? '') + "rir")
            }
          }
          var e1RM = set.getE1RM()
          if (e1RM != null) {
            statisticsItem[exercise.sourceExerciseId + "e1rm"] = e1RM
            statisticsItem[exercise.sourceExerciseId + "e1rmRendered"] = e1RM
          }

        })

      })

      setItemsMap.forEach((value, key) => {
        statisticsItems.push(value)
      })
      
    })

    this.graphParams = graphParams
    this.tableData = statisticsItems
  }

}
