import { Metric } from "./metric.model"

export class MetricData {
    id: string
    metricId: string
    date: Date
    metric: Metric
    value: any
    mediaLink: string

    position: number;

    constructor();
    constructor(init: MetricData);
    constructor(init?: MetricData) {
        this.id = init && init.id || null
        this.metricId = init && init.metricId || null
        this.value = init && init.value != null ? init.value : null
        this.date = init && init.date || null
        this.metric = init && init.metric || null
        this.mediaLink = init && init.mediaLink || this.mediaLink || null

        this.position = init && init.position || 0
    }

    getSelectedValues(): string[] {
        if (!this.value || this.value.length == 0) return []
        return this.value.toString().split(';') || []
    }

    setSelectedValues(values: string[]) {
        this.value = values.join(';')
    }

    isIndexSelected(value: number): boolean {
        return this.getSelectedValues().includes(value.toString());
    }


    getPrintableValue(): string {
        if (this.metricId == 'bodyFat' && this.value < 1) {
            if (this.value != null) return (this.value * 100).toString();
            return null
        }
        if (this.metric?.metricType == 'TEXT') return this.value;
        if (this.metric?.metricType == 'SELECTION') {
            if (this.value != null) {
                return this.metric?.getSelectableValues()[(this.value as number).roundToInt()];
            }
        }
        if (this.metric?.metricType == 'MULTISELECT') {
            let selectedElements = "";
            if (this.value != null) {
                let selectableValues = this.metric?.getSelectableValues();
                if(selectableValues?.length > 0){
                    for(let i = 0; i < selectableValues.length; i++){
                        if(this.isIndexSelected(i)){
                            if(selectedElements.length > 0){
                                selectedElements = selectedElements.concat('; ')
                            }
                            selectedElements = selectedElements.concat(selectableValues[i]);
                        }
                    }
                }
            }
            return selectedElements;
        }
        if (this.metric?.metricType == 'DURATION') {
            var sleepDuration = (this.value as number).roundToPlaces(0)
            return Math.floor(sleepDuration / 60) + ':' + ((sleepDuration % 60) < 10 ? '0' : '') + (sleepDuration % 60) + ' h'
        }
        if (this.metric?.metricType == 'DURATION_SECONDS') {
            var sleepDuration = (this.value as number).roundToPlaces(0)
            var seconds = sleepDuration % 60
            var minutes = Math.floor(sleepDuration / 60) % 60
            var hours = Math.floor(sleepDuration / 3600)
            return hours + ':' + (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds + ' s'
        }
        if (this.metric?.metricType == 'YES_NO') return this.value ? "Ja" : "Nein";
        if (this.metric?.metricType == 'TODO') return this.value ? "Erledigt" : "Nicht erledigt";
        if (this.metric?.metricType == 'IMAGE') return this.value;
        if (this.metric?.metricType == 'VIDEO') return this.value;
        if (this.metric?.metricType == 'NUMBER') return this.value?.roundToPlaces(2)
        return this.value
    }

    setPrintableValue(setValue: string){
        try{
            if (this.metricId == 'bodyFat' && parseInt(setValue) < 1) {
                if (parseInt(setValue) != null) return (parseInt(setValue) * 100).toString();
                // return null
            }
            else if (this.metric?.metricType == 'TEXT') {
                this.value = setValue;
                // return this.value;
            }
            else if(this.metric.metricType == 'NUMBER'){
                if(this.metric.dataType == 'INTEGER')
                {
                    this.value = parseInt(setValue);
                    if(Number.isNaN(this.value)) this.value = null;
                }
                else
                {
                    setValue = setValue.replace(',','.');
                    this.value = Number(setValue);
                    if(Number.isNaN(this.value)) this.value = null;
                }
            }
            else if (this.metric?.metricType == 'SELECTION') {
                if (this.value != null) {
                    return this.metric?.getSelectableValues()[(this.value as number).roundToInt()];
                }
            }
            else {
                this.value = setValue;
            }
        }
        catch(ex) {
            console.error(ex);
        }
    }

    setDefaultValue(){
        if (this.metric?.isMetricTypeText()) this.value = "";
        else if (this.metric?.isMetricTypeSelection()) this.value = 0;
        else if (this.metric?.isMetricTypeDuration()) this.value = 0;
        else if (this.metric?.isMetricTypeYesNo()) this.value = false;
        else if (this.metric?.isMetricTypeToDo()) this.value = false;
        else if (this.metric?.isMetricTypeImage()) this.value = null;
        else if (this.metric?.isMetricTypeVideo()) this.value = null;
        else if (this.metric?.isMetricTypeNumber()) {
            if (this.metric?.isDataTypeInteger()) this.value = null;
            else if (this.metric?.isDataTypeDouble()) this.value = null;
        }
        else this.value = null
    }

    isValueValid(allowEmpty: boolean = false): boolean {
        if (this.metric?.isMetricTypeText()) return true;
        if (this.metric?.isMetricTypeDuration()) {
            if (this.value == null && allowEmpty) return true;
            return this.value != null && this.value >= 0;
        }
        if (this.metric?.isMetricTypeSelection()) {
            if (this.value == null && allowEmpty) return true;
            if (this.value == null) return false;
            if (this.value < 0) return false;
            if (this.value >= this.metric?.getSelectableValues().length) return false;
            return true;
        }
        if (this.metric?.isMetricTypeToDo() || this.metric?.isMetricTypeYesNo()) {
            if (this.value == null) this.value = false;
            return true;
        }
        if (this.metric?.isMetricTypeImage() || this.metric?.isMetricTypeVideo()) {
            if (this.mediaLink == null && allowEmpty) return true;
            if (this.mediaLink == null) return false;
            if (this.mediaLink.length == 0) return false;
            return true;
        }
        if(this.metric?.isMetricTypeNumber()){
            if (this.value == null && allowEmpty) return true;
            if(Number.isNaN(this.value)) return false;
            if(this.value == null) return false;
            if(this.metric?.maxValue != null && this.value > this.metric.maxValue) return false;
            if(this.metric?.minValue != null && this.value < this.metric.minValue) return false;
        }
        return true
    }

    asFirebaseMap(){
        return {
            [this.metricId]: this.value,
        }
    }

    
    clone(): MetricData {
        let clonedMetricData = new MetricData(this);
        clonedMetricData.metric = this.metric.clone();
        return clonedMetricData;
    }

}