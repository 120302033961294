import { EditUserDataService } from '../../services/edit-user-data.service';
import { Component, OnDestroy, OnInit, ComponentFactory, ComponentFactoryResolver, Renderer2, Input } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FirestoreService } from '../../services/firestore.service';
import { GENDER_DIV, GENDER_FEMALE, GENDER_MALE, PHYSICAL_ACTIVITY_LEVEL_ACTIVE, PHYSICAL_ACTIVITY_LEVEL_INACTIVE, PHYSICAL_ACTIVITY_LEVEL_LITTLE_ACTIVE, PHYSICAL_ACTIVITY_LEVEL_VERY_ACTIVE, User } from '../../model/user.model';
import { Meal } from '../../model/meal.model';
import { UtilityService } from '../../services/utility.service';
import { NutritionalSummary } from '../../model/nutritionalsummary.model';
import { map } from 'rxjs/operators';
import { combineLatest, firstValueFrom, Observable, Subscription } from 'rxjs';
import { ChatService } from '../../services/chat.service';
import { ToastrService } from 'ngx-toastr';
import { NutritionalGoal } from '../../model/nutritionalgoal.model';
import { NutritionService } from '../../services/nutrition.service';
import { NutritionPlan } from '../../model/nutritionplan.model';
import { Day } from '../../model/day.model';
import { PlannedMeal } from '../../model/plannedmeal.model';
import { Recipe } from '../../model/recipe.model';
// import * as algoliasearch from 'algoliasearch'
import { registerPalette } from "devextreme/viz/palette";
import { NutritionStatisticsItem } from '../../model/nutritionstatistics.model';
import '../../prototypes'
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseNutritionFact, NutritionalValue } from '../../model/basenutritionfact.model';
import {MatDialog } from '@angular/material/dialog';
import { MacroCalculatorComponent } from '../../macro-calculator/macro-calculator.component';
import { Tag } from '../../model/tag.model';
import { FirestoreNutritionPlanService } from '../../services/firestore-nutritionplan.service';
import { NutritionplanExportService } from '../../services/nutritionplan-export.service';
import { Activity } from '../../model/activity.model';
import { TrainingHistoryDialogComponent } from '../../training-monitoring/training-history-dialog/training-history-dialog.component';
import { QuestionaireResult } from '../../model/questionaires.model';
import { CompletedQuestionaireResultsDialogComponent } from '../../questionaire/completed-questionaire-results-dialog/completed-questionaire-results-dialog.component';
import { FileSharingDialogComponent } from '../../dialogs/file-sharing-dialog/file-sharing-dialog.component';
import { environment } from 'src/environments/environment';
import { TrackedTrainingSession } from '../../model/training-monitoring.model';
import { ChartExportService } from '../../services/chart-export.service';
import { TrainingSession } from '../../model/training-plan.model';
import { LanguageService } from '../../services/language.service';
import { PaymentService } from '../../services/payment.service';
import { TrainingVariableEditorDialogComponent, TrainingVariableEditorDialogType } from '../../training/training-variable-editor-dialog/training-variable-editor-dialog.component';
import { SpikeService } from '../../services/spike-service.service';
import { NotesDialogComponent } from '../../notes/notes-dialog/notes-dialog.component';
import { Moment } from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { LanguageSelectorBase } from 'src/app/language-selector/language-selector.component';

@Component({
  selector: 'app-client-v1',
  templateUrl: './client-v1.component.html',
  styleUrls: ['./client-v1.component.css']
})
export class ClientComponent implements OnInit, OnDestroy {

  public GENDER_MALE = GENDER_MALE;
  public GENDER_FEMALE = GENDER_FEMALE;
  public GENDER_DIV = GENDER_DIV;

  public PHYSICAL_ACTIVITY_LEVEL_INACTIVE = PHYSICAL_ACTIVITY_LEVEL_INACTIVE;
  public PHYSICAL_ACTIVITY_LEVEL_LITTLE_ACTIVE = PHYSICAL_ACTIVITY_LEVEL_LITTLE_ACTIVE;
  public PHYSICAL_ACTIVITY_LEVEL_ACTIVE = PHYSICAL_ACTIVITY_LEVEL_ACTIVE;
  public PHYSICAL_ACTIVITY_LEVEL_VERY_ACTIVE = PHYSICAL_ACTIVITY_LEVEL_VERY_ACTIVE;

  public LanguageSelectorBase = LanguageSelectorBase;

  // Logged-in User
  public user: User;
  // Displayed/Selected User
  public displayedUser: User
  public editableDisplayedUser: User

  @Input() set setDisplayedUser(value: User) {
    if (value?.uid != this.displayedUser?.uid) {
      this.displayedUser = value
      this.init()
    } else {
      this.displayedUser = value
    }
  }

  constructor(public nutritionService: NutritionService, public chatService: ChatService, private authService: AuthService, private router: Router, public userService: FirestoreService, private paymentService: PaymentService, private nutritionPlanService: FirestoreNutritionPlanService, private route: ActivatedRoute, public utilityService: UtilityService, private nutritionPlanExportService: NutritionplanExportService, private toastr: ToastrService, private spinner: NgxSpinnerService,  public dialog: MatDialog, private componentFactoryResolver: ComponentFactoryResolver, private renderer: Renderer2, public chartExportService: ChartExportService, private spikeService: SpikeService, public editUserDataService: EditUserDataService, public languageService: LanguageService, public translate: TranslateService) {

  }

  public showDeprecatedSettings: boolean = false

  public isUser: boolean;
  public showProgress: boolean;
  public showMeals: boolean;
  public showChallenges: boolean;

  public progress = "tab-button active";
  public meals = "tab-button";
  public settings = "tab-button";

  public navBarTriggered;

  public diary = "";
  private subsciptionChats: Subscription;

  public isEditUserInformationActive: boolean = false;

  editorValueInstructions: string;
  editorValueNote: string;
  
  public BaseNutritionFact = BaseNutritionFact

  private statisticsUpdateSubscription: Subscription

  public today = new Date()

  isAdmin(): boolean {
    return this.authService.isAdmin()
  }

  init() {
    this.userEmail = null

    var tab = this.route.snapshot.queryParamMap.get("tab");
    var assignedQuestionaireId = this.route.snapshot.queryParamMap.get("assignedQuestionaire");
    var trackedSessionIdToOpen = this.route.snapshot.queryParamMap.get("trackedSession");


    this.user = this.userService.getLoggedInUser();
    if (!this.user) {
      this.router.navigate['login'];
      return
    }

    if (!this.displayedUser?.uid) {
      this.displayedUser = this.user
    }
    //if (!this.displayedUser.nutritionPlans || this.displayedUser.nutritionPlans.length == 0) this.loadNutritionPlansForUser(this.displayedUser)
    //if (!this.canAccessNewSettings()) this.showDeprecatedSettings = true

    
    let loadTrainingPlans = true
    this.statisticsUpdateSubscription = this.displayedUser.statisticsUpdated.subscribe(async updated => {
      if (updated) {
        if(assignedQuestionaireId){
          let questionaireResult = this.displayedUser?.questionaireResults?.find(x => x.assignedQuestionaire?.id == assignedQuestionaireId);
          if (questionaireResult){
            this.onOpenQuestionaire(questionaireResult);
            assignedQuestionaireId = null;
          }
        } else if (trackedSessionIdToOpen) {
          let sessionToOpen = await this.userService.getTrackedTrainingSessionById(this.displayedUser, trackedSessionIdToOpen);
          if (sessionToOpen){
            loadTrainingPlans = false;
            await this.userService.loadTrainingPlans(this.displayedUser, this.user)
            if (this.displayedUser.trackedTrainingSessions?.length > 0) {
              if (this.displayedUser.trackedTrainingSessions.find(x => x.id == sessionToOpen.id) != null) {
                sessionToOpen = this.displayedUser.trackedTrainingSessions.find(x => x.id == sessionToOpen.id);
              }
            }
            this.onOpenTrainingSession(sessionToOpen);
            trackedSessionIdToOpen = null;
          }
        }
      }
    })

    /*this.nutritionPlanService.loadCycleConfigs(this.displayedUser).then(async () => {
      if (this.displayedUser.configs?.length > 0) this.showDeprecatedSettings = false
      //this.cycleConfigOfSelectedDate = this.displayedUser.getCycleConfigForDate(this.currentDate);
    })*/

    this.loadData(loadTrainingPlans)
    this.setTimeZoneOffsetOfUser(this.displayedUser);

    if (tab) {
      this.swapTab(tab)
    }
  }

  ngOnInit(): void {
    //ClientComponent.showSpinner(this.spinner, 3)

    this.showProgress = true;
    this.showMeals = false;
    this.showChallenges = false;

    this.user = this.userService.getLoggedInUser();

    this.isUser = this.user?.isUser;

    var bodyDataGraphColorPalette = {
      simpleSet: ['var(--accentColor)', '#4ACCE6', '#4A79E6', '#934AE6', '#E64ADC', '#E64A6E', '#E67D4A', '#E6BC4A', '#D6E64A', '#9CE64A', '#4AE693'],
      indicatingSet: ['var(--accentColor)', '#4AE6E6', '#4AD6E6'],
      gradientSet: ['var(--accentColor)', '#4AE6E6']
    };
    registerPalette('nutrilizePalette', bodyDataGraphColorPalette);
    
  }

  loadData(loadTrainingPlans: boolean) {
    //this.loadRemoteNutritionalGoalForUser(this.displayedUser)
    this.userService.loadLicenceSettingsForUser(this.displayedUser)
    this.userService.loadAssignedMetricsForUser(this.displayedUser)
    if(loadTrainingPlans){
      setTimeout(async () => {
        await this.userService.loadTrainingPlans(this.displayedUser, this.user)
      }, 5000);
    }
    //this.userService.loadTrainingPlans(this.displayedUser)
  }

  static generateColors(numColors):string[] {
    const colors = [];
    const hueIncrement = 1 / numColors;
    let hue = Math.random(); // Startfarbton zufällig wählen
  
    for (let i = 0; i < numColors; i++) {
      const saturation = Math.random(); // Zufällige Sättigung
      const value = 0.7 + Math.random() * 0.3; // Zufälliger Helligkeitswert
      const rgb = this.hsvToRgb(hue, saturation, value); // Umwandlung in den RGB-Farbraum
      const hex = this.rgbToHex(rgb); // Konvertierung in Hexadezimalwert
      colors.push(hex);
      
      hue = (hue + hueIncrement + Math.random() * 0.2) % 1; // Zufällige Schrittweite für Farbton hinzufügen
    }
    return colors;
  }
  
  static hsvToRgb(h, s, v) {
    let r, g, b;
    h = (h + 1) % 1; // Normalisiere h auf den Bereich von 0 bis 1
    s = Math.max(0.2, Math.min(0.7, s)); // Stelle sicher, dass s zwischen 0 und 0.7 liegt
    v = Math.max(0.1, Math.min(1, v)); // Stelle sicher, dass v zwischen 0 und 1 liegt
  
    const i = Math.floor(h * 6);
    const f = h * 6 - i;
    const p = v * (1 - s);
    const q = v * (1 - f * s);
    const t = v * (1 - (1 - f) * s);
  
    switch (i % 6) {
      case 0:
        r = v;
        g = t;
        b = p;
        break;
      case 1:
        r = q;
        g = v;
        b = p;
        break;
      case 2:
        r = p;
        g = v;
        b = t;
        break;
      case 3:
        r = p;
        g = q;
        b = v;
        break;
      case 4:
        r = t;
        g = p;
        b = v;
        break;
      case 5:
        r = v;
        g = p;
        b = q;
        break;
    }
  
    return [r, g, b];
  }

  static componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }
  
  static rgbToHex(rgb) {
    const [r, g, b] = rgb.map((value) => Math.floor(value * 255));
    const redHex = this.componentToHex(r);
    const greenHex = this.componentToHex(g);
    const blueHex = this.componentToHex(b);
    return `#${redHex}${greenHex}${blueHex}`;
  }
  

  canAccessNewSettings() {
    return (this.displayedUser?.operatingSystem == 'Android' && this.displayedUser?.versionCode >= 115) || (this.displayedUser?.operatingSystem == 'iOS' && this.displayedUser?.versionCode >= 122)
  }
  onToggleDeprecatedSettings() {
    this.showDeprecatedSettings = !this.showDeprecatedSettings
  }
  

  ngOnDestroy(): void {
    if (this.subsciptionChats) this.subsciptionChats.unsubscribe()
  }

  public macroCalculatorNutritionalGoal: NutritionalGoal
  onOpenMacroCalculator(nutritionalGoal: NutritionalGoal) {
    this.macroCalculatorNutritionalGoal = nutritionalGoal

    const dialogRef = this.dialog.open(MacroCalculatorComponent, {
      data: { nutritionalGoal: this.macroCalculatorNutritionalGoal, user: this.displayedUser }, width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.nutritionalGoal) {
        this.macroCalculatorNutritionalGoal = result.nutritionalGoal
      }
    });
  }

  public userEmail: string
  toggleClientDataDialog() {
    this.editableDisplayedUser = this.displayedUser.clone();
    if (!this.userEmail) this.userService.getEmailForUser(this.displayedUser.uid).then(email => {
      this.userEmail = email
    })
    document.getElementById('clientdata-dialog').classList.toggle('show');
  }

  openTrainingVariablesDialog(){
    let trainingVariables = this.displayedUser.trainingVariables;
    let globalForeignVariabels = this.userService.getLoggedInUser()?.trainingSettingsLicenceHolder?.trainingVariables?.filter(x => !trainingVariables?.find(y => y?.id == x?.id));
    let dialogRef = this.dialog.open(TrainingVariableEditorDialogComponent, {width: '800px', data: { user: this.displayedUser, trainingVariables: trainingVariables?.map(x => x.clone()), trainingVariableEditorDialogType: TrainingVariableEditorDialogType.user, foreignVariables: globalForeignVariabels}});
    dialogRef.afterClosed().subscribe(async result => {
      if(result){
        try{
          this.spinner.show()
          await this.userService.updateTrainingVariables(this.displayedUser, result.trainingVariables);
        }
        catch(ex){
          console.error(ex);
        }
        finally{
          this.spinner.hide();
        }
      }
    });
  }

  openFileSharing() {
    const dialogRef = this.dialog.open(FileSharingDialogComponent, {width: '1000px', panelClass: 'file-manager', data: { user: this.displayedUser }})
  }

  
  toggleNotesDialog() {
    this.spinner.show()
    firstValueFrom(this.userService.getAllNotesForUser(this.displayedUser)).then(notes => {
      this.spinner.hide()
      this.displayedUser.notes = notes
      this.dialog.open(NotesDialogComponent, {width: '1000px', data: { user: this.displayedUser }});
    })
  }

  /*loadNutritionPlansForUser(user: User) {
    this.nutritionService.retrieveNutritionPlansForUser(user).toPromise().then()
  }*/
  /*loadRemoteNutritionalGoalForUser(user: User) {
    user.nutritionalGoalFromCoach = null
    user.nutritionalGoalsFromCoach = []
    var subscriptionRemoteNG = this.userService.getRemoteNutritionalGoalForUser(user).subscribe(nutritionalGoal => {
      if (nutritionalGoal) {
        user.nutritionalGoalFromCoach = nutritionalGoal
        if (nutritionalGoal.adjustWithActivityCalories == null) {
          user.nutritionalGoalFromCoach.adjustWithActivityCalories = user.adjustNutritionalGoalWithActivities
        }
      }
      if (subscriptionRemoteNG) subscriptionRemoteNG.unsubscribe()
    })
    var subscriptionRemoteNGs = this.userService.getRemoteNutritionalGoalsForUser(user).subscribe(nutritionalGoals => {
      if (nutritionalGoals.length > 0) {
        user.nutritionalGoalsFromCoach = nutritionalGoals
        nutritionalGoals.forEach(nutritionalGoal => {
          if (nutritionalGoal.applicableWeekdays.includes('alt')) {
            this.alternativeNutritionalGoalsFromCoach.push(nutritionalGoal)
          } else {
            this.nutritionalGoalsFromCoach.push(nutritionalGoal)
          }
        })
      } else {
        var nutritionalGoal = new NutritionalGoal()
        nutritionalGoal.applicableWeekdays = ['all']
        this.nutritionalGoalsFromCoach.push(nutritionalGoal)
        
        if (this.canAccessNewSettings()) this.showDeprecatedSettings = false
      }
      if (subscriptionRemoteNGs) subscriptionRemoteNGs.unsubscribe()
    })
  }*/


  async resendInvitationEmail() {
    this.spinner.show()
    var imageUrl = null
    var snapshot = await this.userService.getBrandingSettings(this.user.licenceHolderUid || this.user.uid).toPromise()
    if (snapshot.exists) {
      var logoFileName = snapshot?.data()?.logoFileName
      if (logoFileName) {
        imageUrl = await this.userService.getBrandingImage(this.user.licenceHolderUid || this.user.uid, logoFileName).toPromise()
      }
    }
    let firebaseUserEmail = await this.userService.getEmailForUser(this.displayedUser.uid)
    var paymentSettings = this.paymentService.paymentSettings
    let dynamicLink = environment.inAppLink + '?link=' + environment.inAppLink + 'app?email%3D' + firebaseUserEmail + '&apn=' +  environment.androidBundleId + '&isi=' + environment.iOSAppStoreNumber + '&ibi=' + environment.iOSBundleId
    let subject = paymentSettings?.customerKickoffEmailTemplate?.subject?.replace('{{Name}}', this.displayedUser.getName() ?? '')
    let heading = paymentSettings?.customerKickoffEmailTemplate?.heading?.replace('{{Name}}', this.displayedUser.getName() ?? '')
    let body = paymentSettings?.customerKickoffEmailTemplate?.body?.replace('{{Name}}', this.displayedUser.getName() ?? '')
    firstValueFrom(this.userService.sendInvitationEmail(firebaseUserEmail, this.displayedUser.getName(), heading, body, dynamicLink, 'App öffnen', subject, imageUrl, paymentSettings?.email ?? this.authService.user?.email ?? 'no-reply@nutrilize.app', this.user.licenceHolderUid)).then(res => {
      this.spinner.hide()
      if (res?.result == 'success') {
        this.toastr.success(this.translate.instant("Email wurde versendet."), "",  {
          positionClass: 'toast-bottom-center'
        });
      } else {
        this.toastr.error(this.translate.instant("Beim Versenden der Email ist ein Fehler aufgetreten."), "",  {
          positionClass: 'toast-bottom-center'
        });
      }
    }, error => {
      console.log(error)
    })
  }
  
  swapTab(tab: string) {
    this.resetTabs();
    switch(tab) {
      case "progress":
        this.showProgress = true;
        this.progress = "tab-button active";
        break;
      case "meals":
        this.showMeals = true;
        this.meals = "tab-button active";
        break;
      case "settings":
        this.showChallenges = true;
        this.settings = "tab-button active";
        break;
      default:
        break;
    }
  }

  resetTabs() {
    this.showMeals = false;
    this.showProgress = false;
    this.showChallenges = false;
    this.progress = "tab-button";
    this.meals = "tab-button";
    this.settings = "tab-button";
  }



  loadStatisticForUserAndDate(user: User, date: Date): Observable<NutritionStatisticsItem> {
    // Fetch Meals and NutritionalGoal.
    var currentOffset = (date.getTimezoneOffset() * 60000 * -1)
    var userOffset = user.getDailyConditionForDate(date)?.timezoneOffset
    var timezoneOffset = null
    if (userOffset != null && userOffset != currentOffset) {
      timezoneOffset = currentOffset - userOffset
    }
    return combineLatest(
      this.userService.getMealsWithFoodsByDate(user, date, false, true, timezoneOffset),
      this.userService.getNutritionalGoalIncludingActivitiesByDate(user, date)
    ).pipe(
      map(([meals, nutritionalGoal]) => {
        var statistic = new NutritionStatisticsItem()
        statistic.date = date
        var nutritionalSummary = new NutritionalSummary();
        statistic.nutritionalSummary = nutritionalSummary;
        statistic.nutritionalGoal = nutritionalGoal

        statistic.carbohydrates = 0
        statistic.protein = 0
        statistic.fat = 0
        statistic.carbohydratesGoal = nutritionalGoal.carbohydrates
        statistic.proteinGoal = nutritionalGoal.protein
        statistic.fatGoal = nutritionalGoal.fat
        statistic.meals = meals

        statistic.sugar = 0
        statistic.saturatedFat = 0
        statistic.fibre = 0
        statistic.salt = 0

        if (nutritionalSummary.calories >= nutritionalGoal.calories) {
          statistic.calories = 0
          statistic.calorieGoal = nutritionalGoal.calories
          statistic.calorieDeficit = 0;
          statistic.calorieSurplus = nutritionalSummary.calories - nutritionalGoal.calories
        } else {
          statistic.calories = nutritionalSummary.calories
          statistic.calorieGoal = 0
          statistic.calorieSurplus = 0
          statistic.calorieDeficit = nutritionalGoal.calories - nutritionalSummary.calories
        }
        
        meals.forEach(meal => {
          nutritionalSummary.carbohydrates = nutritionalSummary.carbohydrates + meal.getCarbohydrates()
          nutritionalSummary.protein = nutritionalSummary.protein + meal.getProtein()
          nutritionalSummary.fat = nutritionalSummary.fat + meal.getFat()
          nutritionalSummary.calories = nutritionalSummary.calories + meal.getCalories()

          nutritionalSummary.sugar = nutritionalSummary.sugar + meal.getSugar();
          nutritionalSummary.saturatedFat = nutritionalSummary.saturatedFat + meal.getSaturatedFat();
          nutritionalSummary.fibre = nutritionalSummary.fibre + meal.getFibre();
          nutritionalSummary.salt = nutritionalSummary.salt + meal.getSalt();
          
          if (nutritionalSummary.calories >= nutritionalGoal.calories) {
            statistic.calories = 0
            statistic.calorieGoal = nutritionalGoal.calories
            statistic.calorieDeficit = 0;
            statistic.calorieSurplus = nutritionalSummary.calories - nutritionalGoal.calories
          } else {
            statistic.calories = nutritionalSummary.calories
            statistic.calorieGoal = 0
            statistic.calorieSurplus = 0
            statistic.calorieDeficit = nutritionalGoal.calories - nutritionalSummary.calories
          }
          statistic.carbohydrates = nutritionalSummary.carbohydrates
          statistic.protein = nutritionalSummary.protein
          statistic.fat = nutritionalSummary.fat

          statistic.sugar = nutritionalSummary.sugar
          statistic.saturatedFat = nutritionalSummary.saturatedFat
          statistic.fibre = nutritionalSummary.fibre
          statistic.salt = nutritionalSummary.salt
        })
        
        return statistic
      })
    );
  }

  public loadingMeals = false
  public loadingMetricData = false

  async saveUserInformationChanges(originUser: User, editedUser: User){
    try{
      this.spinner.show();
      if(originUser.birthDate != editedUser.birthDate){
        if(editedUser.birthDate == null || editedUser.birthDate >= new Date()){
          this.toastr.error(this.translate.instant("Bitte gib ein gültiges Geburtsdatum ein"), "",  {
            positionClass: 'toast-bottom-center'
          });
          return;
        }
        editedUser.age = editedUser.getAgeByBirthDate();
      }
      if(editedUser.name == null || editedUser?.name?.length <= 0) {
        this.toastr.error(this.translate.instant("Bitte gib einen gültigen Namen ein"), "",  {
          positionClass: 'toast-bottom-center'
        });
        return;
      }
      if(editedUser.bodyHeight == null || editedUser.bodyHeight == 0) {
        this.toastr.error(this.translate.instant("Bitte gib eine gültige Körpergröße ein"), "",  {
          positionClass: 'toast-bottom-center'
        });
        return;
      }
      await this.userService.saveEditabledUserInformation(editedUser);
      this.setEditableUserInformation(originUser, editedUser);
      this.toastr.success(this.translate.instant("Änderungen gespeichert"), "",  {
        positionClass: 'toast-bottom-center'
      });
    }
    catch(ex){
      console.error(ex);
    }
    finally{
      this.spinner.hide();
    }
  }

  setEditableUserInformation(originUser: User, editedUser: User) {
    originUser.name = editedUser.name;
    originUser.birthDate = editedUser.birthDate;
    originUser.age = editedUser.age;
    originUser.bodyHeight = editedUser.bodyHeight;
    originUser.gender = editedUser.gender;
    originUser.physicalActivityLevel = editedUser.physicalActivityLevel;
  }

  switchUserDataEditMode(){
    this.isEditUserInformationActive = !this.isEditUserInformationActive;
  }

  switchEditMode(){
    this.editUserDataService.switchEditMode();
    //this.refresh();
  }

  logout() {
    this.userService.logout();
    this.authService.logout();
    this.router.navigate(['login']);
  }


  getNutritionalSummary() {
    return this.user.nutritionalSummary;
  }

  createRange(number){
    var items: number[] = [];
    for(var i = 0; i < number; i++){
       items.push(i);
    }
    return items;
  }

  getPlannedSession(trackedTrainingSession: TrackedTrainingSession): TrainingSession{
    var session = this.displayedUser.trainingPlans.filter(x => x.id == trackedTrainingSession.trainingPlanId)[0]?.sessions?.filter(x => x.id == trackedTrainingSession.plannedSessionId)[0]
    return session
  }

  onOpenTrainingSession(trainingSession: TrackedTrainingSession, activity: Activity = null) {
    if (trainingSession != null) {
      let questionaireResults = this.displayedUser?.questionaireResults?.filter(x => x.assignedQuestionaire?.trackedSessionId == trainingSession.id);
      const dialogRef = this.dialog.open(TrainingHistoryDialogComponent, { data: { user: this.displayedUser, selectedTrackedTrainingSession: trainingSession, activity: activity, selectedPlannedTrainingSession: this.getPlannedSession(trainingSession), questionaireResults: questionaireResults}})
    }
  }

  onOpenQuestionaire(questionaireResult: QuestionaireResult){
    let allAvailableQuestionaireResults = this.displayedUser.dailyConditions.map(x => x.questionaireResults).reduce((a, b) => a.concat(b), []);
    const dialogRef = this.dialog.open(CompletedQuestionaireResultsDialogComponent, { data: { selectedQuestionaireResults: [questionaireResult], allAvailableQuestionaireResults: allAvailableQuestionaireResults, user: this.displayedUser, editMode: this.editUserDataService.isEditModeActivated}, autoFocus: false});
    dialogRef.afterClosed().subscribe(async result => {
      if (result && result.save) {
        this.spinner.show()
        try {
          await this.userService.updateQuestionaireResults(this.displayedUser.dailyCondition, this.displayedUser);
          this.toastr.success(this.translate.instant("Änderungen gespeichert"), "",  {
            positionClass: 'toast-bottom-center'
          });
        } catch(ex){
          console.error(ex);
          this.toastr.error(this.translate.instant("Fehler beim Speichern"), "",  {
            positionClass: 'toast-bottom-center'
          });
        } finally{
          this.spinner.hide()
        }
      }
    });
  }

  public nutritionPlanOpened: boolean = false
  onNutritionPlanOpened(value: boolean) {
    this.nutritionPlanOpened = value
  }

  async setTimeZoneOffsetOfUser(user: User){
    try{
      let today = new Date();
      let dailyCondition = await this.userService.getLatestDailyConditionForTimezoneOffset(user);
      let currentTimeZoneOffsetMilliseconds = today.getTimezoneOffset() * 60000;

      if(dailyCondition?.timezoneOffset != undefined){
        let diff = dailyCondition?.timezoneOffset + currentTimeZoneOffsetMilliseconds;
        if(diff != 0){
          user.currentTimezoneOffsetString = this.convertMillisecondsToHoursAndMinutesString(diff);
          user.curentTimezoneOffset = diff;
          return;
        }
      }
      user.currentTimezoneOffsetString = null;
    }
    catch(error){
      console.log(error);
    }
  }

  private convertMillisecondsToHoursAndMinutesString(milliseconds: number): string {
    const sign = milliseconds < 0 ? '-' : '';
    const absoluteMilliseconds = Math.abs(milliseconds);
    const totalMinutes = Math.floor(absoluteMilliseconds / 60000);
    const hours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    const hoursString = hours.toString();
    const minutesString = remainingMinutes.toString().padStart(2, '0');
    return `${sign}${hoursString}:${minutesString}`;
  }


}



class AutoFillMealConfig {
  mealNumber: number
  mealType: string

  autoFillEnabled: boolean = true
  recipeTagRestrictions: Tag[]
  matchingRecipes: Recipe[]

  searchInputFocused: boolean = false
  filteredRestrictionSearchResults = []
}