import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { IndividualFirebase } from '../app.module';
import { TrackedSuperSet, TrackedTrainingSession, TrackedVideoRecording } from '../model/training-monitoring.model';
import { User } from '../model/user.model';
import { FirestoreNutritionPlanService } from './firestore-nutritionplan.service';
import { SetParameter, TrainingSession } from '../model/training-plan.model';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, timestamp } from 'rxjs';
import { Activity } from '../model/activity.model';
import { FirestoreService } from './firestore.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { QuestionairesService } from './questionaires.service';
import { AssignedQuestionaire, EventTriggerType } from '../model/questionaires.model';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { TrainingSessionContainer } from '../training-monitoring/training-history-dialog/training-history-dialog.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { TrackingSessionDialogComponent } from '../training/tracking/tracking-session/tracking-session-dialog/tracking-session-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TrainingTrackingService {

  static CACHE_FOLDER = 'SESSION-CACHE';
  static SUB_VIDEOS_FOLDER = 'VIDEOS';
  static SUB_IMAGES_FOLDER = 'IMAGES';
  static FILE_NAME = 'tracking-session.json';

  constructor(private mainFirebase: IndividualFirebase, private translate: TranslateService, private userService: FirestoreService, private toastr: ToastrService, private questionaireService: QuestionairesService, private dialog: MatDialog, private spinner: NgxSpinnerService, private sanitizer: DomSanitizer) { }


  get firestore(): AngularFirestore {
    return this.mainFirebase.firestore
  }
  get fireStorage(): AngularFireStorage {
    return this.mainFirebase.storage
  }


  async createOrUpdateTrackedTrainingSession(trackedTrainingSession: TrackedTrainingSession, user: User, updateSpinnerText: Function, autoCreateActivity: boolean): Promise<boolean> {
    try {
      if (!trackedTrainingSession.startDate) {
        trackedTrainingSession.startDate = new Date();
        trackedTrainingSession.startDate.setMinutes(trackedTrainingSession?.startDate?.getMinutes() - 5);
      }
      if (!trackedTrainingSession.endDate) {
        trackedTrainingSession.endDate = new Date();
        if (trackedTrainingSession.endDate < trackedTrainingSession.startDate) {
          trackedTrainingSession.endDate = new Date(trackedTrainingSession.startDate);
          trackedTrainingSession.endDate.setMinutes(trackedTrainingSession.endDate.getMinutes() + 5);
        }
      }
      let trackedTrainingSessionMap = trackedTrainingSession.asMap();

      let progress = 0;
      updateSpinnerText(this.translate.instant("Training wird gespeichert...") + " (" + progress + "%)");

      if (trackedTrainingSession.id) {
        await this.firestore.collection<any>('Users/' + user.uid + '/TrackedTrainingSessions/').doc(trackedTrainingSession.id).update(trackedTrainingSessionMap);
        await this.updateActivityTime(trackedTrainingSession, user);
      }
      else {
        let trackedTrainingSessionRef = await this.firestore.collection<any>('Users/' + user.uid + '/TrackedTrainingSessions/').add(trackedTrainingSessionMap);
        trackedTrainingSession.id = trackedTrainingSessionRef.id;
        if(autoCreateActivity) {
          await this.createActivity(trackedTrainingSession, user);
        }
      }
      progress = 10;
      updateSpinnerText(this.translate.instant("Training wird gespeichert...") + " (" + progress + "%)");

      let date = trackedTrainingSession.startDate;

      let exercisesCount = trackedTrainingSession.trackedTrainingExercises.length;
      let progressStep = 90 / exercisesCount;

      for (let i = 0; i < trackedTrainingSession.trackedTrainingExercises.length; i++) {
        progress += progressStep.roundToInt();
        if (progress > 100) {
          progress = 99;
        }
        updateSpinnerText(this.translate.instant("Training wird gespeichert...") + " (" + progress + "%)");
        const trackedExercise = trackedTrainingSession.trackedTrainingExercises[i];
        trackedExercise.trackedSessionId = trackedTrainingSession.id;
        trackedExercise.position = i;
        trackedExercise.completed = true;

        if (trackedExercise.plannedExercise) {
          trackedExercise.sets = trackedExercise.plannedExercise.sets;
        }
        trackedExercise.trackedSets = trackedExercise.trackedSets?.filter(x => x?.isTracked);

        if (!trackedExercise.startDate) {
          trackedExercise.startDate = date;
        }
        else {
          if (trackedExercise.startDate < trackedTrainingSession.startDate) {
            trackedExercise.startDate = trackedTrainingSession.startDate;
          }
          if (trackedExercise.startDate > trackedTrainingSession.endDate) {
            trackedExercise.startDate = trackedTrainingSession.endDate;
          }
        }

        if (trackedExercise?.getTotalTime() > 0) {
          date = new Date(date);
          date.setSeconds(date.getSeconds() + trackedExercise?.getTotalTime());

          if (date > new Date()) {
            date = new Date();
          }
        }


        if (trackedExercise?.recordings?.length > 0) {
          for (let recording of trackedExercise.recordings) {
            if (recording.newVideoFile) {
              await this.uploadVideo(recording, user);
            }
          }
        }
        if (trackedExercise.tempNoteImages) {
          trackedExercise.noteImagePaths = [];
          for (let image of trackedExercise.tempNoteImages) {
            await this.uploadImage(image, user);
            trackedExercise.noteImagePaths.push(image.path);
          }
        }
        trackedExercise.recordings.filter(x => x.newVideoFile)


        let trackedExerciseMap = trackedExercise.asMap();

        if (trackedExercise.id) {
          await this.firestore.collection<any>('Users/' + user.uid + '/TrackedTrainingExercises/').doc(trackedExercise.id).set(trackedExerciseMap, { merge: true });
        }
        else {
          let trackedExerciseRef = await this.firestore.collection<any>('Users/' + user.uid + '/TrackedTrainingExercises/').add(trackedExerciseMap);
          trackedExercise.id = trackedExerciseRef.id;
        }
      }

      if (user.trackedTrainingSessions) {
        let index = user.trackedTrainingSessions.findIndex(x => x.id == trackedTrainingSession.id);
        if (index >= 0) {
          user.trackedTrainingSessions[index] = trackedTrainingSession;
        }
        else {
          user.trackedTrainingSessions.push(trackedTrainingSession);
        }
      }
    }
    catch (ex) {
      console.error(ex);
      return false;
    }
    return true;
  }

  async uploadVideo(recording: TrackedVideoRecording, user: User) {
    if (recording.newVideoFile && !recording.videoPath) {
      let randomId = FirestoreNutritionPlanService.generateUniqueString(13);
      recording.videoPath = 'training/exercise_recordings/' + randomId + recording.newVideoFile.getExtension();
      return this.fireStorage.upload('users/' + user.uid + '/' + recording.videoPath, recording.newVideoFile);
    }
  }

  async uploadImage(image: { blob: Blob, src: any, path: string }, user: User) {
    if (image.blob && !image.path) {
      let randomId = FirestoreNutritionPlanService.generateUniqueString(13);
      image.path = 'note_images/' + randomId + '.png';
      return this.fireStorage.upload('users/' + user.uid + '/' + image.path, image.blob);
    }
  }

  async createQuestionaires(trackedSession: TrackedTrainingSession, user: User) {
    let questionaires = await this.questionaireService.getQuestionaires();
    let trainingQuestionaires = questionaires.filter(x => x.eventTrigger == EventTriggerType.after_training);
    let assignedQuestionaires = [];

    for (let questionaire of trainingQuestionaires) {
      if (!questionaire.deleted && questionaire.eventTrigger == EventTriggerType.after_training) {
        let userIsPartOfGroup = user.metadataUser?.assignedClientGroups.find(groupName => questionaire.assignedGroupNames.includes(groupName));
        if (questionaire.assignedUids.includes(user.uid) || userIsPartOfGroup) {
          let assignedQuestionaire = new AssignedQuestionaire();
          assignedQuestionaire.assignmentDate = new Date();
          assignedQuestionaire.eventTrigger = questionaire.eventTrigger;
          assignedQuestionaire.licenceHolderUid = questionaire.licenceHolderUid;
          assignedQuestionaire.name = questionaire.name;
          assignedQuestionaire.questionaireId = questionaire.id;
          assignedQuestionaire.questions = questionaire.questions;
          assignedQuestionaire.timestamp = new Date()
          assignedQuestionaire.trackedSessionId = trackedSession.id;
          assignedQuestionaire.assignmentDate = trackedSession.startDate;

          await this.questionaireService.createAssignedQuestionaire(assignedQuestionaire, user);
          assignedQuestionaires.push(assignedQuestionaire);
        }
      }
    }
    return assignedQuestionaires;
  }

  static trainingActivityFactId = "acf41";
  async createActivity(trackedSession: TrackedTrainingSession, user: User) {
    try {
      let totalSessionTime = trackedSession.endDate.getTime() - trackedSession.startDate.getTime();
      let activityFacts = await this.userService.getAllActivityFacts();
      let activityFact = activityFacts?.find(x => x.id == TrainingTrackingService.trainingActivityFactId);

      if (activityFact == null) {
        this.toastr.error(this.translate.instant("Fehler beim Erstellen der Aktivität"), this.translate.instant("Fehler"));
        return;
      }
      let activity = new Activity();
      activity.activityFactId = activityFact.id;
      activity.date = trackedSession.startDate;
      activity.duration = Math.ceil(totalSessionTime / 1000 / 60);
      activity.caloriesBurned = (activityFact.getBurnedCaloriesPerMinute(user.bodyWeight) * activity.duration)?.roundToPlaces(2);
      if (trackedSession.plannedSessionId) {
        activity.name = trackedSession.sessionName ?? "";
      }
      else {
        activity.name = this.translate.instant("Freies Training");
      }
      activity.trackedSessionId = trackedSession.id;
      activity.sourceName = environment.brandName;

      await this.userService.saveOrUpdateActivity(activity, user);
    }
    catch (ex) {
      console.error(ex);
      this.toastr.error(this.translate.instant("Fehler beim Erstellen der Aktivität"), this.translate.instant("Fehler"));
    }
  }

  async updateActivityTime(trackedSession: TrackedTrainingSession, user: User) {
    try {
      let activity = user?.activities?.find(x => x.trackedSessionId == trackedSession.id);
      if (activity) {
        let activityFacts = await this.userService.getAllActivityFacts();
        let activityFact = activityFacts.find(x => x.id == TrainingTrackingService.trainingActivityFactId);
        activity.duration = Math.ceil((trackedSession.endDate.getTime() - trackedSession.startDate.getTime()) / 1000 / 60);
        activity.caloriesBurned = (activityFact.getBurnedCaloriesPerMinute(user.bodyWeight) * activity.duration)?.roundToPlaces(2);
        activity.date = trackedSession.startDate;
        await this.userService.saveOrUpdateActivity(activity, user);
      }
    }
    catch (ex) {
      console.error(ex);
      this.toastr.error(this.translate.instant("Fehler beim Aktualisieren der Aktivität"), this.translate.instant("Fehler"));
    }
  }

  public async deleteTrackedTrainingSession(trackedTrainingSession: TrackedTrainingSession, user: User) {
    await this.firestore.collection<any>('Users/' + user.uid + '/TrackedTrainingSessions/').doc(trackedTrainingSession.id).update({ deleted: true, timestamp: new Date() });
    trackedTrainingSession.deleted = true;
    for (let trackedExercise of trackedTrainingSession.trackedTrainingExercises) {
      await this.firestore.collection<any>('Users/' + user.uid + '/TrackedTrainingExercises/').doc(trackedExercise.id).update({ deleted: true, timestamp: new Date() });
      trackedExercise.deleted = true;
    }
  }


  async removeCachedTrainingData() {
    try {
      await Filesystem.rmdir({ path: TrainingTrackingService.CACHE_FOLDER, directory: Directory.Cache, recursive: true });
    }
    catch (ex) {
      console.error("Error deleting cache file", ex);
    }
  }

  async openCachedTrainingIfExists(user: User) {
    try {
      let directory = TrainingTrackingService.CACHE_FOLDER;
      let files = await Filesystem.readdir({ path: directory, directory: Directory.Cache });
      let file = files.files.find(x => x.name == TrainingTrackingService.FILE_NAME);
      if (file) {
        let json = await Filesystem.readFile({ path: directory + '/' + file.name, directory: Directory.Cache, encoding: Encoding.UTF8 });
        let trackedTrainingSession = TrackedTrainingSession.fromJson(json.data as string);
        if (trackedTrainingSession?.tempUserUid == user.uid) {
          let confirmationResult = await firstValueFrom(this.dialog.open(ConfirmationDialogComponent, { data: { message: this.translate.instant("Es wurde eine ungespeicherte Trainingseinheit gefunden.<br>Möchtest du diese öffnen?"), title: this.translate.instant("Hinweis"), positiveButton: this.translate.instant("Öffnen"), negativeButton: this.translate.instant("Löschen") } }).afterClosed());
          if (confirmationResult === false) {
            await Filesystem.rmdir({ path: TrainingTrackingService.CACHE_FOLDER, directory: Directory.Cache, recursive: true });
          }
          else if (confirmationResult === true) {
            this.spinner.show();
            let trainingSessionContainer = new TrainingSessionContainer();
            trainingSessionContainer.trackedTrainingSession = trackedTrainingSession;
            trainingSessionContainer.plannedTrainingSession = this.getPlannedSessionByTrackedSession(trackedTrainingSession, user);
            trainingSessionContainer.initSuperSets();
            await this.loadCachedVideos(trackedTrainingSession);
            await this.loadCachedImages(trackedTrainingSession);
            this.spinner.hide();
            if (trainingSessionContainer) {
              let result = await firstValueFrom(this.dialog.open(TrackingSessionDialogComponent, { panelClass: 'training-tracking-dialog', data: { trainingSessionContainer: trainingSessionContainer, user: user, hasInitialChanges: true }, width: '100vw', maxWidth: '1000px' }).afterClosed());
              if (result) {
                if (result.shouldTake && result.trainingSessionContainer) {
                  if (result.assignedQuestionaires?.length > 0) {
                    await this.questionaireService.showAssignedQuestionairesEditor(result.assignedQuestionaires, user);
                  }
                  user?.updatedCachedTrackedTrainingSession?.emit();
                }
              }
            }
          }
        }
      }
    }
    catch (ex) {
      console.error(ex);
    }
    finally {
      this.spinner.hide();
    }
  }
  
    getPlannedSessionByTrackedSession(trackedTrainingSession: TrackedTrainingSession, user: User): TrainingSession {
      if(!trackedTrainingSession?.plannedSessionId) return null;
      return user.trainingPlans.find(x => x.id == trackedTrainingSession.trainingPlanId)?.sessions?.find(x => x.id == trackedTrainingSession?.plannedSessionId) ?? null;
    }

  private async loadCachedVideos(trackedTrainingSession: TrackedTrainingSession) {
    try {
      let videoFiles = await Filesystem.readdir({ path: TrainingTrackingService.CACHE_FOLDER + '/' + TrainingTrackingService.SUB_VIDEOS_FOLDER, directory: Directory.Cache });
      for (let videoFile of videoFiles.files) {
        let splitted = videoFile.name.split('_');
        if (splitted.length != 2) continue;
        let exerciseId = splitted[0];
        let setNumber = parseInt(splitted[1]);
        let recording = trackedTrainingSession.trackedTrainingExercises.find(x => x.id == exerciseId)?.recordings.find(x => x.setNumber == setNumber);
        let video = await Filesystem.readFile({ path: TrainingTrackingService.CACHE_FOLDER + '/' + TrainingTrackingService.SUB_VIDEOS_FOLDER + '/' + videoFile.name, directory: Directory.Cache });
        let videoData = video.data as string;
        let blob = this.base64ToBlob(videoData, 'video/mp4');
        let file = new File([blob], videoFile.name);
        // const videoUrl = URL.createObjectURL(blob);

        if (file && blob) {
          recording.newVideoFile = file;
          // recording.videoUrl = videoUrl;
          recording.newVideoBlob = blob;
          recording.urlsLoaded = true;
        }

        console.error('Video geladen:', videoFile.name);
      }
    }
    catch (ex) {
      console.error(ex);
    }
  }

  private async loadCachedImages(trackedTrainingSession: TrackedTrainingSession) {
    try {
      let imageFiles = await Filesystem.readdir({ path: TrainingTrackingService.CACHE_FOLDER + '/' + TrainingTrackingService.SUB_IMAGES_FOLDER, directory: Directory.Cache });
      let files = imageFiles.files.sort((a, b) => a.name.localeCompare(b.name));
      for (let imageFile of files) {
        let splitted = imageFile.name.split('_');
        if (splitted.length != 2) continue;
        let exerciseId = splitted[0];
        let exercise = trackedTrainingSession.trackedTrainingExercises.find(x => x.id == exerciseId);
        let image = await Filesystem.readFile({ path: TrainingTrackingService.CACHE_FOLDER + '/' + TrainingTrackingService.SUB_IMAGES_FOLDER + '/' + imageFile.name, directory: Directory.Cache });
        let imageData = image.data;
        let blob = new Blob([imageData], { type: 'image/jpeg' });
        if (blob) {
          if (!exercise.tempNoteImages) exercise.tempNoteImages = [];
          exercise.tempNoteImages.push({ blob: blob, src: null, path: null });
        }
      }
      for (let exercise of trackedTrainingSession?.trackedTrainingExercises) {
        if (exercise.noteImagePaths?.length > 0) {
          let newList = exercise.noteImagePaths.map(x => { return { blob: null, src: null, path: x } })?.concat(exercise.tempNoteImages);
          if (newList) {
            exercise.tempNoteImages = newList;
          }
        }
      }
    }
    catch (ex) {
      console.error(ex);
    }
  }

  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

  private base64ToBlob(base64: string, type: string) {
    var binStr = atob(base64);
    var len = binStr.length;
    var arr = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type: type });
  }


}
