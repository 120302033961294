<div class="row {{isIframe ? 'iframe-content' : ''}}">
    <div class="col">
        <div class="product-wrapper layout-box padding-0">
            <div *ngIf="getProductThumbnailUrl()" class="product-thumbnail" [ngStyle]="{'background-image':'url('+ getProductThumbnailUrl() +')'}"></div>
            <div class="product-content-wrapper">
                <div class="product-name">
                    {{getProductName()}}
                </div>
                <div *ngIf="getProductSubtitle()" class="product-subtitle">
                    {{getProductSubtitle()}}
                </div>
        
                <div *ngIf="!isIframe && !showDescription && getProductDescription() && !isPreview" class="label link" (click)="onToggleDescription()">
                    <span translate>Beschreibung anzeigen</span>
                    <button class="button">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </button>
                </div>
                <div class="spacer-10"></div>
        
                <div class="product-price-wrapper">
                    <div class="product-price">{{getPrintablePriceWithCurrency()}}</div>
                    <div class="product-frequency">{{getPrintablePaymentFrequency()}}<br>{{getPrintableDuration()}}</div>
                    <div *ngIf="hasInitialSetupFee()" class="product-setupfee">+ {{getPrintableInitialSetupFeeWithCurrency()}}</div>
                </div>
        
                <div class="product-start">
                    {{getPrintableRuntime()}}
                </div>
        
                <div class="spacer-25"></div>

                <ng-container *ngIf="isPreview">
                    <button class="button-filled small product-button" (click)="onShowProduct()" translate>
                        Ansehen
                    </button>
                </ng-container>
                
                <ng-container *ngIf="!isPreview">
                    <button *ngIf="!userSelected" class="button-filled small product-button" (click)="onStartPurchase()">
                        {{isFree() ? ('Kostenlos starten' | translate) : ('Kaufen' | translate)}}
                    </button>
                    <ng-container *ngIf="userSelected && !loadingCheckoutLink && !stripeCheckoutSessionLink && !enableCheckout">
                        <div class="spacer-25"></div>
                        <app-paymentmethod-selection [paymentMethods]="getPaymentMethods()" (confirmPaymentMethod)="onConfirmPaymentMethod($event)"></app-paymentmethod-selection>
                    </ng-container>
                    <ng-container *ngIf="userSelected && loadingCheckoutLink || loading">
                        <div class="spacer-25"></div>
                        <mat-spinner class="loading-statistics-spinner" diameter="30" strokeWidth="2"></mat-spinner>
                        <div class="spacer-10"></div>
                    </ng-container>
                    <ng-container *ngIf="user && userSelected && !loadingCheckoutLink && enableCheckout && collectBillingAddress && !loading">
                        <div class="spacer-10"></div>
                        <div class="" translate>Rechnungsadresse:</div>
                        <div class="spacer-10"></div>
                        <input id="input-name" required class="input form-control wide" [(ngModel)]="address.name" placeholder="Vor- und Nachname" type="text">
                        <div class="spacer-10"></div>
                        <input id="input-name" required class="input form-control wide" [(ngModel)]="address.street" placeholder="Straße und Hausnummer" type="text">
                        <div class="spacer-10"></div>
                        <input id="input-name" required class="input form-control wide" [(ngModel)]="address.postalCode" placeholder="Postleitzahl" type="text">
                        <div class="spacer-10"></div>
                        <input id="input-name" required class="input form-control wide" [(ngModel)]="address.city" placeholder="Stadt" type="text">
                        <div class="spacer-10"></div>
                        <input id="input-name" required class="input form-control wide" [(ngModel)]="address.country" placeholder="Land" type="text">
                        <div class="spacer-10"></div>
                    </ng-container>
                    <ng-container *ngIf="user && !loadingCheckoutLink && stripeCheckoutSessionLink && !isFree()">
                        <div class="spacer-10"></div>
                        <div class="terms">
                            <input class="input checkbox" type="checkbox" [checked]="termsAccepted" (change)="onAcceptTerms($event.target.checked)"> <span [innerHTML]="getTermsAndConditionsUrl()"></span>
                        </div>
                        <div class="spacer-25"></div>
                        <div class="label" translate>Mit dem Klick auf "Kauf abschließen" und der Angabe deiner Zahlungsdaten wird der Kauf durchgeführt.</div>
                        <div class="spacer-10"></div>
                        <button class="button-filled small product-button {{termsAccepted == true && (!collectBillingAddress || isAddressValid()) ? '' : 'inactive'}}" (click)="onPurchaseProduct()" translate>
                            Kauf abschließen
                        </button>
                    </ng-container>
                    <ng-container *ngIf="user && userSelected && !loadingCheckoutLink && !stripeCheckoutSessionLink && enableCheckout && !loading">
                        <div class="spacer-10"></div>
                        <div class="terms">
                            <input class="input checkbox" type="checkbox" [checked]="termsAccepted" (change)="onAcceptTerms($event.target.checked)"> <span [innerHTML]="getTermsAndConditionsUrl()"></span>
                        </div>
                        <div class="spacer-25"></div>
                        <button class="button-filled small product-button {{termsAccepted == true && (!collectBillingAddress || isAddressValid()) ? '' : 'inactive'}}" (click)="onPurchaseProduct()">
                            {{getPrice() == 0 ? ('Jetzt starten' | translate) : ('Kauf abschließen' | translate)}}
                        </button>
                    </ng-container>
                </ng-container>
                
                <ng-container *ngIf="error">
                    <div class="label">{{error}}</div>
                </ng-container>
            </div>
        </div>
    </div>
    <div *ngIf="getProductDescription() && showDescription" class="col">
        <div class="layout-box description-box">
            <h3 class="display-inlineblock" translate>Produktbeschreibung</h3>
            <div class="icon-button float-right" (click)="onToggleDescription()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>
            <markdown>{{getProductDescription()}}</markdown>
        </div>
    </div>
</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>