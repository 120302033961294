import { Component, ComponentFactory, ComponentFactoryResolver, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardComponent } from '../dashboard/dashboard-v1/dashboard.component';
import { InputFieldDialogComponent } from '../inputfield-dialog/inputfield-dialog.component';
import { BaseNutritionFact, NutritionalValueHolder } from '../model/basenutritionfact.model';
import { Ingredient } from '../model/ingredient.model';
import { NutritionalSummary } from '../model/nutritionalsummary.model';
import { MealType, MealTypeTranslation } from '../model/nutritionplanv2.model';
import { PlannedFood } from '../model/plannedfood.model';
import { PlannedMeal } from '../model/plannedmeal.model';
import { PlannedMealV2 } from '../model/plannedmealv2.model';
import { Recipe } from '../model/recipe.model';
import { NutritionalValuePopoverComponent } from '../nutritional-value-popover/nutritional-value-popover.component';
import { FirestoreNutritionPlanService } from '../services/firestore-nutritionplan.service';
import { FirestoreService } from '../services/firestore.service';
import { NutritionService } from '../services/nutrition.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LanguageService } from '../services/language.service';
import { LanguageDictionary } from '../model/languagedictionary.model';
import { User } from '../model/user.model';
import { NutritionPlanComponent } from '../nutrition-plan/nutrition-plan.component';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

@Component({
  selector: 'app-meal-editor-dialog',
  templateUrl: './meal-editor-dialog.component.html',
  styleUrls: ['./meal-editor-dialog.component.css']
})
export class MealEditorDialogComponent implements OnInit {

  public meal: PlannedMealV2
  public readOnlyMode: boolean = false;
  public user: User
  public targetValues: number[]

  editorValueNote: string;
  placeholderInstructions = marker('Zubereitung...')
  placeholderNote = ('Hinweis/Notiz o. Ä. für Kund:in')
  public componentFactory: ComponentFactory<any>

  public mealTypeTranslations = MealTypeTranslation
  public mealTypes = Object.values(MealType).filter(value => typeof value === 'string')

  constructor(public dialogRef: MatDialogRef<MealEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {meal: PlannedMealV2, readOnlyMode: boolean, user: User, targetValues: number[] }, private nutritionPlanService: FirestoreNutritionPlanService, private nutritionService: NutritionService, private userService: FirestoreService, private componentFactoryResolver: ComponentFactoryResolver, public dialog: MatDialog, public languageService: LanguageService, public translate: TranslateService) {
    this.meal = data.meal || new PlannedMealV2()
    this.readOnlyMode = data.readOnlyMode || false
    this.user = data.user || null
    this.targetValues = data.targetValues || null
    if (!this.meal.imageURL && this.meal.thumbnailPath) {
      nutritionPlanService.fetchMealImageUrl(this.meal).then(url => {
        this.meal.imageURL = url
      }).catch(error => {
        this.meal.thumbnailPath = null
        this.meal.imageURL = null
      })
    }

    this.placeholderInstructions = this.translate.instant(this.placeholderInstructions)
    this.placeholderNote = this.translate.instant(this.placeholderNote)
  }

  ngOnInit(): void {
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(NutritionalValuePopoverComponent)
    this.editorValueNote = this.meal.note
  }

  get editorValueInstructions(): string{
    return this.meal?.getInstructions(this.languageService.selectedLanguageCode) ?? ''
  }
  set editorValueInstructions(value: string){
    if (this.meal != null) {
      if (!this.meal.instructionsTranslation) this.meal.instructionsTranslation = new LanguageDictionary<string>()
      this.meal.instructionsTranslation[this.languageService.selectedLanguageCode] = value
    }
  }

  onSavePlannedMeal() {
    if (this.meal.getInstructions() != this.editorValueInstructions) {
      this.meal.instructions = this.editorValueInstructions
      if (!this.meal.instructionsTranslation) this.meal.instructionsTranslation = new LanguageDictionary<string>()
      this.meal.instructionsTranslation[this.languageService.selectedLanguageCode] = this.editorValueInstructions
    }

    if (this.meal.note != this.editorValueNote) this.meal.note = this.editorValueNote
    
    if (!this.meal.foods && !this.meal.baseRecipe && !this.meal.baseRecipeFoods) {
      this.meal.foods = []
      this.meal.nutritionFactsLoaded = true
    }
    this.meal.recalculateNutritionalValues()
    this.onCloseDialog(true, false)
  }
  onPlannedMealNameChanged(text: string) {
    this.meal.name = text
    if (!this.meal.nameTranslation) this.meal.nameTranslation = new LanguageDictionary<string>()
    this.meal.nameTranslation[this.languageService.selectedLanguageCode] = text
  }
  onDeletePlannedMealImage() {
    this.meal.mealTemplateId = null
    this.meal.imageURL = null
    this.meal.thumbnailPath = ''
  }
  onDeletePlannedMeal() {
    this.onCloseDialog(false, true)
  }
  onAddPlannedFood(plannedFood: PlannedFood) {
    if (!this.meal.foods) {
      if (this.meal.baseRecipeFoods) {
        this.meal.foods = this.meal.baseRecipeFoods.map(f => f.clone())
      } else {
        this.meal.foods = []
      }
      this.meal.nutritionFactsLoaded = true
    }
    this.meal.foods.push(plannedFood)
    this.meal.recalculateNutritionalValues()
  }

  onMealTypeSelected(mealType: MealType) {
    this.meal.type = mealType
  }
  onRecipeForAutoServingSelected(recipe: Recipe) {
    var targetValues = [].concat(this.targetValues)
    if (this.meal.getCalories() > 0) {
      targetValues[0] = targetValues[0] - this.meal.getCarbohydrates()
      targetValues[1] = targetValues[1] - this.meal.getProtein()
      targetValues[2] = targetValues[2] - this.meal.getFat()
      targetValues[3] = targetValues[3] - this.meal.getCalories()
    }
    var plannedMeal = NutritionPlanComponent.fitRecipeIntoMacros(recipe, targetValues, true)
    
    if (!this.meal.getName()) this.meal.name = plannedMeal.name
    if (this.meal.baseMealTemplateId || this.meal.getFoods()?.length > 0) {
      this.concatWithSecondMeal(plannedMeal)
    } else {
      this.meal.nameTranslation = plannedMeal.nameTranslation
      this.meal.name = plannedMeal.name
      this.meal.baseMealTemplateId = plannedMeal.baseMealTemplateId
      this.meal.baseMealTemplateServingMultiplier = plannedMeal.baseMealTemplateServingMultiplier
      this.meal.baseRecipe = plannedMeal.baseRecipe
      if (!this.meal.mealTemplateId) this.meal.mealTemplateId = plannedMeal.mealTemplateId
      if (!this.meal.imageURL) this.meal.imageURL = plannedMeal.imageURL
      this.meal.thumbnailPath = plannedMeal.thumbnailPath ?? null
      this.meal.instructions = plannedMeal.instructions
      this.meal.instructionsTranslation = plannedMeal.instructionsTranslation
      this.meal.foods = plannedMeal.foods?.map(food => food) ?? null
      this.meal.baseRecipeFoods = plannedMeal.baseRecipeFoods?.map(food => food) ?? null
    }
    this.meal.nutritionFactsLoaded = true
    this.meal.recalculateNutritionalValues()
  }
  canUseAutoServing() {
    return this.targetValues != null && (!this.meal.getCalories() || ( this.meal?.getCalories() < this.targetValues[3] * 0.8 && (this.targetValues[3] - this.meal?.getCalories()) > 100))
  }
  onAddPlannedMeal(plannedMeal: PlannedMealV2) {
    if (!this.meal.getName()) this.meal.name = plannedMeal.name
    if (this.meal.baseMealTemplateId || this.meal.getFoods()?.length > 0) {
      this.concatWithSecondMeal(plannedMeal)
    } else {
      this.meal.baseMealTemplateId = plannedMeal.baseMealTemplateId
      this.meal.baseMealTemplateServingMultiplier = plannedMeal.baseMealTemplateServingMultiplier
      this.meal.baseRecipe = plannedMeal.baseRecipe
      if (!this.meal.mealTemplateId) this.meal.mealTemplateId = plannedMeal.mealTemplateId
      if (!this.meal.imageURL) this.meal.imageURL = plannedMeal.imageURL
      this.meal.thumbnailPath = plannedMeal.thumbnailPath ?? null
      this.meal.instructions = this.meal.instructions?.length > 0 ? this.meal.instructions + '\n\n\n' + '**' + plannedMeal.getName() + '**' + '\n\n' + (plannedMeal.getInstructions() || '') : null
      this.meal.foods = plannedMeal.foods?.map(food => food) ?? null
      this.meal.baseRecipeFoods = plannedMeal.baseRecipeFoods?.map(food => food) ?? null
      this.editorValueNote = this.meal.note
    }
    this.meal.nutritionFactsLoaded = true
    this.meal.recalculateNutritionalValues()
  }

  concatWithSecondMeal(plannedMeal: PlannedMealV2) {
    if (this.meal.baseRecipe) {
      if (!this.meal.thumbnailPath) this.meal.thumbnailPath = this.meal.baseRecipe.getThumbnailPath()
      if (!this.meal.instructions && this.meal.baseRecipe.getInstructions()) {
        this.meal.instructions = '**' + this.meal.baseRecipe.getName() + '**' + '\n\n' + this.meal.baseRecipe.getInstructions()
        if (!this.meal.instructionsTranslation) this.meal.instructionsTranslation = new LanguageDictionary<string>()
        this.meal.instructionsTranslation['de'] = '**' + this.meal.baseRecipe.getName('de') + '**' + '\n\n' + this.meal.baseRecipe.getInstructions('de')
        this.meal.instructionsTranslation['en'] = '**' + this.meal.baseRecipe.getName('en') + '**' + '\n\n' + this.meal.baseRecipe.getInstructions('en')
      }
    }
    if (!this.meal.foods) {
      this.meal.foods = []
      if (this.meal.baseRecipeFoods) this.meal.foods.push(...this.meal.baseRecipeFoods.map(food => food.clone()))
    }
    var previousFoodsLength = this.meal.foods.length
    this.meal.foods.push(...plannedMeal.getFoods().map(food => food.clone()))
    this.meal.foods[previousFoodsLength].groupHeading = plannedMeal.getName()
    this.meal.baseRecipeFoods = null
    if (this.meal.getInstructions()?.length > 0 && !this.meal.baseRecipe) {
      this.meal.instructions = this.meal.getInstructions() + '\n\n\n' + '**' + plannedMeal.getName() + '**' + '\n\n' + (plannedMeal.getInstructions() || '')
      if (this.meal.instructionsTranslation) {
        if (this.meal.instructionsTranslation['de']) this.meal.instructionsTranslation['de'] = this.meal.instructionsTranslation['de'] + '\n\n\n' + '**' + plannedMeal.getName('de') + '**' + '\n\n' + (plannedMeal.getInstructions('de') || '')
        if (this.meal.instructionsTranslation['en']) this.meal.instructionsTranslation['en'] = this.meal.instructionsTranslation['en'] + '\n\n\n' + '**' + plannedMeal.getName('en') + '**' + '\n\n' + (plannedMeal.getInstructions('en') || '')
      }
    } else if (this.meal.baseRecipe?.getInstructions()) {
      this.meal.instructions = '**' + this.meal.baseRecipe.getName() + '**' + '\n\n' + this.meal.baseRecipe.getInstructions() + '\n\n\n' + '**' + plannedMeal.getName() + '**' + '\n\n' + (plannedMeal.getInstructions() || '')
      if (!this.meal.instructionsTranslation) this.meal.instructionsTranslation = new LanguageDictionary<string>()
      this.meal.instructionsTranslation['de'] = '**' + this.meal.baseRecipe.getName('de') + '**' + '\n\n' + this.meal.baseRecipe.getInstructions('de') + '\n\n\n' + '**' + plannedMeal.getName('de') + '**' + '\n\n' + (plannedMeal.getInstructions('de') || '')
      this.meal.instructionsTranslation['en'] = '**' + this.meal.baseRecipe.getName('en') + '**' + '\n\n' + this.meal.baseRecipe.getInstructions('en') + '\n\n\n' + '**' + plannedMeal.getName('en') + '**' + '\n\n' + (plannedMeal.getInstructions('en') || '')
    } else {
      this.meal.instructions = plannedMeal.getInstructions()
    }
  }

  onDeletePlannedFood(food: PlannedFood) {
    if (!this.meal.foods) {
      this.meal.foods = this.meal.baseRecipeFoods.filter(f => f != food).map(f => f.clone())
    } else {
      this.meal.foods.forEach( (item, index) => {
        if (item == food) this.meal.foods.splice(index, 1);
      })
    }
    this.meal.recalculateNutritionalValues()
  }
  onPlannedFoodWeightChanged(text: string, food: PlannedFood) {
    if (!this.meal.foods) {
      if (this.meal.baseRecipeFoods) {
        this.meal.foods = this.meal.baseRecipeFoods.map(f => f.clone())
        food = this.meal.foods.find(f => f.nutritionFactId == food.nutritionFactId && f.name == food.name && f.weight == food.weight)
      } else {
        this.meal.foods = []
      }
      this.meal.nutritionFactsLoaded = true
    }
    var regex = new RegExp("^[0-9]+$");
    if (food.weight != null && food.weight > 0 && food.tmpPer100Calories == null) {
      food.tmpPer100Calories = food.calories / food.weight * 100
      food.tmpPer100Carbohydrates = food.carbohydrates / food.weight * 100
      food.tmpPer100Protein = food.protein / food.weight * 100
      food.tmpPer100Fat = food.fat / food.weight * 100
    }
    if (regex.test(text) && parseInt(text) > 0)  {
      var number = parseInt(text);
      food.weight = number
    } else {
      food.weight = 0
    }
    food.recalculateNutritionalValues()
    this.meal.recalculateNutritionalValues()
  }
  onEditorValueInstructionsChanged(value: string) {
    if (value == this.placeholderInstructions) {
      this.editorValueInstructions = ''
    } else {
      this.editorValueInstructions = value
    }
  }
  onEditorValueNoteChanged(value: string) {
    if (value == this.placeholderNote) {
      this.editorValueNote = ''
    } else {
      this.editorValueNote = value
    }
  }
  onClosePlannedMeal() {
    this.onCloseDialog(false, false)
  }

  dropFood(event: CdkDragDrop<string[]>) {
    if (!this.meal.foods) {
      if (this.meal.baseRecipeFoods) {
        this.meal.foods = this.meal.baseRecipeFoods.map(f => f.clone())
      } else {
        this.meal.foods = []
      }
      this.meal.nutritionFactsLoaded = true
    }
    moveItemInArray(this.meal.foods, event.previousIndex, event.currentIndex);
  }

  public nutritionalValueHolder: NutritionalValueHolder
  onNutritionalValuesFocused(nutritionalValueHolder: NutritionalValueHolder) {
    this.nutritionalValueHolder = nutritionalValueHolder
  }
  
  onAddGroupHeading(ingredient: Ingredient) {
    ingredient.groupHeading = 'Gruppe'
  }
  onDeleteGroupHeading(ingredient: Ingredient) {
    ingredient.groupHeading = null
  }

  onCloseDialog(shouldSave: boolean, shouldDelete: boolean) {
    this.dialogRef.close({shouldSave: shouldSave, shouldDelete: shouldDelete, meal: this.meal});
  }


  onSavePlannedMealAsRecipe(meal: PlannedMealV2) {
    var recipe = new Recipe()
    recipe.ingredients = []
    recipe.setName(meal.name ?? null)
    recipe.setInstructions(meal.getInstructions() ?? null)
    recipe.thumbnailPath = meal.getThumbnailPath() ?? null
    meal.foods.forEach(food => {
      var ingredient = new Ingredient()
      ingredient.weight = food.weight
      ingredient.nutritionFactId = food.nutritionFactId
      if (!food.nutritionFactId || !(food.nutritionFactId.startsWith('ntf') || food.nutritionFactId.startsWith('brc') || food.nutritionFactId.startsWith('bnd') || food.nutritionFactId.startsWith('bls'))) {
        ingredient.nutritionalSummary = new NutritionalSummary()
        ingredient.nutritionalSummary.carbohydrates = food.carbohydrates
        ingredient.nutritionalSummary.protein = food.protein
        ingredient.nutritionalSummary.fat = food.fat
        ingredient.nutritionalSummary.calories = food.calories
        ingredient.name = food.name
        ingredient.isDummy = true
        recipe.ingredients.push(ingredient)
      } else if (food.nutritionFactId && (food.nutritionFactId.startsWith('ntf') || food.nutritionFactId.startsWith('brc') || food.nutritionFactId.startsWith('bnd') || food.nutritionFactId.startsWith('bls'))) {
        var subscriptionIngredient0 = this.userService.getBaseNutritionFactById(ingredient.nutritionFactId).subscribe(nutritionFact => {  
          ingredient.nutritionFact = nutritionFact
          if (nutritionFact != undefined && subscriptionIngredient0) subscriptionIngredient0.unsubscribe()
        })
        recipe.ingredients.push(ingredient)
      }
    })
    BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
      recipe.setNutritionalValue(nutritionalValue, meal.getNutritionalValue(nutritionalValue))
    })
    if (!meal.imageURL != null && meal.thumbnailPath != null) {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = new Blob([xhr.response], { type: 'image/jpg' });
        const file = new File([blob], "thumbnail.jpg", {
          type: 'image/jpeg',
          lastModified: Date.now()
        });
        recipe.updatedImage = file
      };
      xhr.open('GET', meal.imageURL);
      xhr.setRequestHeader('Access-Control-Request-Headers', 'access-control-allow-origin')
      xhr.send();
    }
    const dialogRef = this.dialog.open(InputFieldDialogComponent, {
      data: { message: '', title: this.translate.instant('Rezept erstellen'), input: meal.name },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.input) {
        var name = result.input
        recipe.setName(name)
        if (this.userService.getLoggedInUser().portalSettingsLicenceHolder.autoShareNewRecipesEnabled ?? false) {
          recipe.shared = true
          recipe.assignedUsers = ["all"]
        }
        this.nutritionService.insertRecipe(recipe, this.userService.getLoggedInUser()).then()
      }
    });
  }
}
