<div class="dialog-topbar">
    <h2 class="padding-0 margin-0" translate>Athleten-Einstellungen</h2>
</div>
<div class="dialog-content-wrapper">
    <h3 translate>Kundendaten</h3>
    <div class="row">
        <div class="col-5" translate>Email:</div>
        <div class="col-7">{{userEmail}}</div>
        <div class="col-5" translate>Aktuelles Gewicht:</div>
        <div class="col-7">{{(user.latestBodyWeight ?? user.bodyWeight) | weightConversion: languageService.selectedUnitSystem: true }} {{languageService.getUnit()}}</div>
        <div class="col-5" translate>Startgewicht:</div>
        <div class="col-7">{{(user.firstBodyWeight ?? user.bodyWeight) | weightConversion: languageService.selectedUnitSystem: true }} {{languageService.getUnit()}}</div>
        <div class="col-5" translate>Körpergröße:</div>
        <div class="col-7">{{user.bodyHeight.toFixed(0)}} cm</div>
        <ng-container *ngIf="user.birthDate">
            <div class="col-5" translate>Geburtsdatum:</div>
            <div class="col-7">{{user.birthDate?.asFormatedString()}}</div>
        </ng-container>
        <div class="col-5" translate>Alter:</div>
        <div class="col-7">{{user.age}} <span translate>Jahre</span></div>
        <div class="col-5" translate>Geschlecht:</div>
        <div class="col-7">{{user.getPrintableGender(translate)}}</div>
        <div class="col-5" translate>Aktivitätslevel (PAL-Wert):</div>
        <div class="col-7">{{user.getPrintablePhysicalActivityLevel(translate)}}</div>
        <div class="col-5" *ngIf="user?.spikeProvider && user?.spikeUserId" translate>Verbundenes Wearable:</div>
        <div class="col-7" *ngIf="user?.spikeProvider && user?.spikeUserId">{{user?.spikeProvider}}</div>
    </div>

    <div class="spacer-20"></div>
    <div class="row">
        <div class="col-12">
            <h3 translate>Check-Ins</h3>
            <app-checkin-assignment-view [User]="user"></app-checkin-assignment-view>
        </div>
        <div class="spacer-10"></div>
        <div class="col-12">
            <h3 translate>Training</h3>
        </div>
        <div class="col-5" translate>Nächster Wettkampf:</div>
        <div class="col-7">
            <div class="competitiondate-wrapper">
                <input [matDatepicker]="competitionDatePicker" class="visibility-hidden width-0 padding-0" (dateInput)="onCompetitionDateChanged($event.value?.toDate())">
                <mat-datepicker-toggle matSuffix [for]="competitionDatePicker" class="competition-datepicker-toggle"></mat-datepicker-toggle>
                <mat-datepicker #competitionDatePicker></mat-datepicker>
                {{user.nextCompetitionDate?.asFormatedString()}}
                <div *ngIf="user.nextCompetitionDate" class="display-inlineblock link marginleft-10">
                    <i class="bi bi-x-lg" (click)="onCompetitionDateChanged(null)"></i>
                </div>
            </div>
        </div>

        <button class="button-filled small light button-trainingvariables margin-10" (click)="openTrainingVariablesDialog()" translate>Ausdauer-Einstellungen</button>
        <div class="col-12">
            <div class="spacer-20"></div>
        </div>
        <div class="col-5" translate>
            Standard-Parameter RPE/RIR
        </div>
        <div class="col-7">
            <div class="d-inline-block wrapper-selection">
                <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getDefaultExertionParameter() ?? ('Nach Übung' | translate)}}</button>
                <div class="dropdown-menu" aria-labelledby="dropdownExertionParameter">
                    <button class="dropdown-item" type="button" (click)="onSelectDefaultExertionParameter(null)" translate>Nach Übung</button>
                    <button class="dropdown-item" type="button" (click)="onSelectDefaultExertionParameter('rpe')" translate>RPE</button>
                    <button class="dropdown-item" type="button" (click)="onSelectDefaultExertionParameter('rir')" translate>RIR</button>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="spacer-10"></div>
            <h3 translate>Sonstige</h3>
        </div>
        <div class="col-5" translate>Ernährungsbereich:</div>
        <div class="col-7">
            <div class="recipesharing-toggle toggle-wrapper margin-4 mobile-marginbottom-10" [matTooltip]="'Aktiviere den Ernährungsbereich in Traindoo für den Athleten.' | translate">
                <div class="toggle {{isNutritionEnabled() ? 'on' : ''}}" (click)="onEnableNutritionChanged(!isNutritionEnabled())">
                    <div class="handle"></div>
                </div>
                <div class="text">
                    <span translate>Ernährung aktivieren</span> <span *ngIf="user.nutritionEnabled == null" translate>(Standardeinstellung)</span>
                    <div class="label" *ngIf="isNutritionEnabled()" translate>Es fallen zusätzliche Kosten an.</div>
                </div>
            </div>
        </div>
        <div class="spacer-20"></div>
    </div>

    <div class="spacer-20"></div>

    <div class="actions">
        <button class="button-filled small light" (click)="onCancelDialog()" translate>Schließen</button>
    </div>
</div>
