import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ILanguageDictionary } from 'src/app/model/languagedictionary.model';
import { FilterObject } from '../filter-selection-dropdown/filter-selection-dropdown.component';

@Component({
  selector: 'app-filter-dual-selection-dropdown',
  templateUrl: './filter-dual-selection-dropdown.component.html',
  styleUrls: ['./filter-dual-selection-dropdown.component.css']
})
export class FilterDualSelectionDropdownComponent {

  @Input() availableFilterObjects1: FilterObject[] = [];
  @Input() availableFilterObjects2: FilterObject[] = [];
  @Input() isTranslatable: boolean = true;
  @Input() selectedLanguage: string = "de"
  @Input() hasSearchField: boolean = false;
  @Input() labelFilter1: string = null
  @Input() labelFilter2: string = null

  @Output() filterChanged = new EventEmitter()
  @Output() onFilterOpened = new EventEmitter()

  searchInput: string = null

  constructor() { }
  
  ngOnInit(): void {
  }

  public get hasAnyFilter():boolean{
    return this.availableFilterObjects1?.filter(x => x.isFiltered)?.length > 0 || this.availableFilterObjects2?.filter(x => x.isFiltered)?.length > 0
  }

  getSearchResultFilterObjects1(): FilterObject[] {
    if (!this.searchInput || this.searchInput.length == 0) return this.availableFilterObjects1
    return this.availableFilterObjects1?.filter(x => this.getText(x).toLowerCase().includes(this.searchInput.toLowerCase()))
  }

  getSearchResultFilterObjects2(): FilterObject[] {
    if (!this.searchInput || this.searchInput.length == 0) return this.availableFilterObjects2
    return this.availableFilterObjects2?.filter(x => this.getText(x).toLowerCase().includes(this.searchInput.toLowerCase()))
  }

  openFilter() {
    this.onFilterOpened.emit()
  }

  onSetFilteredItem(filterObject: FilterObject){
    filterObject.isFiltered = !filterObject.isFiltered
    this.filterChanged.emit()
  }

  public getText(filterObject:FilterObject):string{
    if(this.isTranslatable) return filterObject.originObject.GetValue(this.selectedLanguage)
    return filterObject.originObject.originObject.toString()
  }

  onSearchInputChanged(text: string) {
    this.searchInput = text
  }
  onDeleteSearchInput() {
    (<HTMLInputElement> document.getElementById('search-input')).value = ''
    this.searchInput = null
  }
}
