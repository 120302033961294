import { TranslateService } from "@ngx-translate/core";
import { DataSample } from "../graph/cardio-evaluation/cardio-evaluation.component";
import { TimezonePipe } from "../timezone.pipe";
import { DailyCondition } from "./dailycondition.model";
import { TrackedTrainingSession } from "./training-monitoring.model";
import { marker } from "@colsen1991/ngx-translate-extract-marker";

export class Activity {
    id: string;
    date: Date;
    activityFactId: string;
    caloriesBurned: number;
    duration: number;
    name: string;
    heartRate: string
    speed: string
    note: string
    trackedSessionId: string
    trainingSession: TrackedTrainingSession
    sourceName: string

    // Tmp
    endDate: Date

    constructor();
    constructor(init: Activity);
    constructor(init?: Activity) {
        this.id = init && init.id != null ? init.id : null
        this.date = init && init.date ? new Date((init as any).date.seconds * 1000) : null
        this.activityFactId = init && init.activityFactId || null
        this.caloriesBurned = init && init.caloriesBurned != null ? init.caloriesBurned : null
        this.duration = init && init.duration != null ? init.duration : 0
        this.name = init && init.name || null
        this.heartRate = init && init.heartRate || null
        this.speed = init && init.speed || null
        this.note = init && init.note || null
        if(this.date && this.duration) {
            this.endDate = new Date(this.date.getTime() + this.duration * 1000 * 60)
        }
        this.trackedSessionId = init && init.trackedSessionId || null
        this.sourceName = init && init.sourceName || null
    }
    
    timezonePipe: TimezonePipe = new TimezonePipe();
    getPrintableDate(dailyCondition: DailyCondition, translate: TranslateService) {
        let date = this.timezonePipe.transform(this.date, dailyCondition)
        if (date && !(date.getHours() == 0 && date.getMinutes() == 0)) {
            return translate.instant(marker("Um timeParameter Uhr"), { timeParameter: date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() })
        }
        return ""
    }

    getDataSamples() {
        var samples = []
        var heartRateValues = this.heartRate?.split(";") ?? []
        var speedValues = this.speed?.split(";") ?? []
        var moment = this.date?.clone();
        moment.setHours(0, 0, 0, 0);
        if (heartRateValues?.length > 0 || speedValues?.length > 0) {
            var d = new Date(this.date.getTime())
            for (var i = 0; i < Math.max(heartRateValues.length, speedValues.length); i++) {
                var heartRate = heartRateValues[i]
                var rawSpeed = speedValues[i]
                var speed = rawSpeed != null ? parseFloat(rawSpeed) : null
                var pace = speed == 0 || speed == null ? null : 1000 / (speed * 60)
                var heartRateNumber = null
                if(heartRate != null && !isNaN(parseInt(heartRate))){
                    heartRateNumber = parseInt(heartRate)
                }
                samples.push(new DataSample({
                    date: d,
                    heartRate: heartRateNumber,
                    speed: speed,
                    pace: pace,
                    moment: moment
                }))
                d = new Date(d.getTime() + 15000)
                moment = new Date(moment.getTime() + 15000)
            }
        }
        return samples.filter(x => x.heartRate != null || x.speed != null)
    }

    clone(){
        let clonedActivity = new Activity(this);
        clonedActivity.date = this.date.clone();
        clonedActivity.endDate = this.endDate?.clone();
        return clonedActivity;
    }
}
