import { LanguageDictionary } from "./languagedictionary.model"

export class SharedFile {
    id: string
    name: LanguageDictionary<string> = new LanguageDictionary<string>();
    description: LanguageDictionary<string>;
    thumbnailPath: string

    set fileName(value: string) {
        this.fileNameTranslation.de = value
    }
    get fileName() {
        return this.fileNameTranslation?.de ?? ''
    }

    fileNameTranslation: LanguageDictionary<string> = new LanguageDictionary<string>(null, null);
    path: string
    webLink: string
    timestamp: Date
    deleted: boolean
    url: string
    showOnDashboard: boolean

    personal: boolean = true

    thumbnailLink: string = null
    position: number = 0
    version: number = 1

    
    assignedUids: string[];
    assignedGroupNames: string[];
    isHidden: boolean;
    public: boolean
    freeContent: boolean


    constructor();
    constructor(init: SharedFile);
    constructor(init?: SharedFile) {
        this.id = init && init.id || null
        this.description = init && init.description != null ? new LanguageDictionary(init.description.de, init.description.en, init.description.originObject, init.description.es, init.description.fr) : new LanguageDictionary(null, null)
        this.thumbnailPath = init && init.thumbnailPath || null
        this.timestamp = init && init.timestamp || null
        this.deleted = init && init.deleted || false
        this.fileName = init && init.fileName || null
        this.fileNameTranslation = init && init.fileNameTranslation != null ? new LanguageDictionary(init.fileNameTranslation.de, init.fileNameTranslation.en, init.fileNameTranslation.originObject, init.fileNameTranslation.es, init.fileNameTranslation.fr) : new LanguageDictionary(this.fileName, null)
        this.name = init && init.name != null ? new LanguageDictionary(init.name.de, init.name.en, init.name.originObject, init.name.es) : new LanguageDictionary(this.fileNameTranslation.de, this.fileNameTranslation.en, null, this.fileNameTranslation.es, this.fileNameTranslation.fr)
        this.webLink = init && init.webLink || null
        this.path = init && init.path || null
        this.position = init && init.position || 0
        this.version = init && init.version || 1
        this.showOnDashboard = init && init.showOnDashboard || false
        this.public = init && init.public || false
        this.freeContent = init && init.freeContent || false

        if (init?.assignedUids == null && init?.assignedGroupNames == null){
            this.assignedUids = []
            this.assignedGroupNames = ['Alle']
        } else {
            this.assignedUids = init && init.assignedUids?.map(x => x) || []
            this.assignedGroupNames = init && init.assignedGroupNames?.map(x => x) || []
        }

        this.isHidden = init && init.isHidden || false;
    }

    isAtPath(path: string) {
        return path == (this.path ?? '')
    }

    isInFolder(folder: SharedFile){
        if(folder == null) return this.isAtPath('');
        if(this.version > 1){
          return this.isAtPath(folder.id);
        }
        else {
          return this.isAtPath(folder.getFullPath());
        }
    }

    getFullPath() {
        if (this.version > 1){
            return this.id + '/' + this.fileName
        }
        if (this.path?.length > 0) return this.path + '/' + this.fileName
        return this.fileName
    }

    getFullThumbnailPath() {
        if(this.isImage()) return this.getFullPath();
        if (this.id) return this.id + '/' + this.thumbnailPath;
        return '';
    }

    isFolder() {
        return !this.isWebLink() && this.fileNameIsFolder();
    }

    fileNameIsFolder() {
        if (this.fileNameTranslation?.hasAnyValue() && this.fileNameTranslation?.getValues()?.find(x => x?.includes('.'))) {
            return false;
        }
        else if(!this.fileNameTranslation?.hasAnyValue()){
            return false;
        }
        return true;
    }

    isWebLink() {
        return this.webLink != null && this.webLink?.length > 0
    }
    isImage() {
        return this.fileName.toLowerCase().endsWith('.jpg') || this.fileName.toLowerCase().endsWith('.jpeg') || this.fileName.toLowerCase().endsWith('.png') || this.fileName.toLowerCase().endsWith('.svg')
    }
    isText() {
        let check = !this.isFolder() && !this.fileNameTranslation?.hasAnyValue() && !this.isWebLink();
        return !this.isFolder() && !this.fileNameTranslation?.hasAnyValue() && !this.isWebLink();
    }

    isVideoLink() {
        return this.isWebLink() && this.webLink.match(SharedFile.urlVideoLinkPattern);
        // const videoLinkRegex = /(youtube\.com|youtu\.be|youtube\.de|youtube-nocookie\.com|vimeo.com|player.vimeo.com)/;
        // return videoLinkRegex.test(link);
    }

    isHiddenFolder(){
        return !this.personal && this.isFolder() && this.assignedGroupNames?.length == 0 && this.assignedUids?.length == 0;
    }
    
    private videoFileExtensions = ['.mp4', '.mov', '.m4a']

    isVideo(){
        return this.isVideoLink() || this.videoFileExtensions.some(x => this.fileName.toLowerCase().endsWith(x))
    }

    isPotentiallyBrowserIncompatibleVideo(){
        return this.isVideo() && !this.isVideoLink() && this.fileName.toLowerCase().endsWith('.mov');
    }

    getSharedFileType(){
        if(this.isVideoLink()) return SharedFileType.VideoLink
        if(this.isWebLink()) return SharedFileType.WebLink
        if(this.isFolder()) return SharedFileType.Folder
        if(this.isText()) return SharedFileType.Text
        return SharedFileType.File
    }

    public static urlVideoLinkPattern = "^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.be|youtube\.de|youtube-nocookie\.com|vimeo.com|player.vimeo.com)\/.+$";

    clone(): SharedFile {
        var file = new SharedFile()
        file.id = this.id
        file.timestamp = this.timestamp
        file.fileName = this.fileName
        file.path = this.path
        file.webLink = this.webLink
        file.deleted = this.deleted
        file.url = this.url
        file.personal = this.personal

        file.name = this.name.clone()
        file.description = this.description.clone()
        file.thumbnailPath = this.thumbnailPath
        file.fileNameTranslation = this.fileNameTranslation.clone()

        file.thumbnailLink = this.thumbnailLink
        file.position = this.position
        file.version = this.version
        file.isHidden = this.isHidden
        file.assignedUids = this.assignedUids?.map(x => x)
        file.assignedGroupNames = this.assignedGroupNames?.map(x => x)
        file.showOnDashboard = this.showOnDashboard
        file.public = this.public
        file.freeContent = this.freeContent

        return file
    }

    toMap():Record<string, any> {
        var returnMap: Record<string, any> = {
            'fileName': this.fileName,
            'path': this.path,
            'webLink': this.webLink,
            'timestamp': new Date(),
            'deleted': this.deleted,

            'fileNameTranslation': this.fileNameTranslation.AsMap(),
            'description': this.description.AsMap(),
            'name': this.name.AsMap(),
            'thumbnailPath': this.thumbnailPath,
            'position': this.position,
            'version': this.version,
            'isHidden': this.isHidden,
            'showOnDashboard': this.showOnDashboard,
            'public': this.public,
            'freeContent': this.freeContent
        }
        
        if(this.isFolder()){
            returnMap['assignedUids'] = this.assignedUids
            returnMap['assignedGroupNames'] = this.assignedGroupNames
        }
        return returnMap
    }
}

export enum SharedFileType{
    File,
    Folder,
    WebLink,
    VideoLink,
    Text
}