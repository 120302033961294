import { FcmService } from '../../services/fcm.service';
import { Component, OnInit, NgZone, AfterViewInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';

import { FirestoreService } from '../../services/firestore.service';
import { UtilityService } from '../../services/utility.service';
import { NavbarService } from '../../services/navbar.service';
import { ChatService } from '../../services/chat.service';
import {MatDialog } from '@angular/material/dialog';
import { LicenceConnectionRequest } from '../../model/licence-connection-request';
import { DashboardBaseComponent } from '../dashboard-base/dashboard-base.component';
import { UserActivationDialogComponent } from 'src/app/traindoo/user-activation-dialog/user-activation-dialog.component';
import { firstValueFrom } from 'rxjs';
import { Licence } from 'src/app/model/lid.model';
import { DropdownItem, DropdownSelectionDialogComponent } from 'src/app/dialogs/dropdown-selection-dialog/dropdown-selection-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard-traindoo',
  templateUrl: './dashboard-traindoo.component.html',
  styleUrls: ['./dashboard-traindoo.component.css']
})
export class DashboardTraindooComponent extends DashboardBaseComponent {

  constructor(public chatService: ChatService, public authService: AuthService, public router: Router, public userService: FirestoreService, public utilityService: UtilityService, public navService: NavbarService, public dialog: MatDialog, public fcmService: FcmService, public spinner: NgxSpinnerService) {
    super(chatService, authService, router, userService, utilityService, navService, dialog, fcmService)
  }

  ngOnInit(): void {
    
  }

  public hasSubscription: boolean = null

  async onDeclineConnectionRequest(request: LicenceConnectionRequest) {
    await this.userService.deleteLicenceConnectionRequest(request)
  }
  
  async onAcceptConnectionRequest(request: LicenceConnectionRequest) {
    var userUid = request.userUid
    var subscription = this.userService.observableClients.subscribe(async clients => {
      var client = clients.changed.find(x => x.uid == userUid)
      if (client) {
        subscription.unsubscribe()
        const dialogRef = this.dialog.open(UserActivationDialogComponent, { data: { user: client }, width: '600px'})
      }
    })
    request.loading = true

    var existingLicence = await this.userService.getLicenceOfCoachAndUser(this.userService.getLoggedInUser().licenceHolderUid, userUid)
    if (existingLicence) {
      await this.userService.reactivateLicence(existingLicence, this.userService.getLoggedInUser().uid)
      await this.userService.deleteLicenceConnectionRequest(request)
    } else {
      var licence = await this.userService.allocateActiveLicence(request.userUid)
      if (licence) {
        await this.userService.setCurrentLicenceForUser(licence)
        await this.userService.deleteLicenceConnectionRequest(request)
      }
    }
    request.loading = false
  }

  hasAccess() {
    return this.userService.getLoggedInUser()?.hasPortalAccess()
  }

  isTrialEndingSoon() {
    if (this.userService.getLoggedInUser().observableSubscription == null) return false
    if (this.userService.getLoggedInUser().observableSubscription.value != null) return false
    if (this.userService.getLoggedInUser()?.licenceHolder?.onTrial == false) return false
    return this.userService.getLoggedInUser()?.licenceHolder?.trialEndDate && this.userService.getLoggedInUser().licenceHolder.trialEndDate.getTime() - new Date().getTime() < 1000 * 60 * 60 * 24 * 3
  }

  oldClientsSubscription: Promise<any> = null
  oldLicences: Licence[] = []

  onReactivateClient() {
    this.spinner.show()
    this.oldClientsSubscription = this.userService.getOldLicences().then(async licences => {
      this.oldLicences = licences
      this.oldLicences.sort((a, b) => a.user?.getName()?.localeCompare(b.user?.getName()))
      this.oldClientsSubscription = null
      this.spinner.hide()
      var items: DropdownItem[] = this.oldLicences.map(x => { return { id: x.userUid, name: x.user?.getName() ?? x.presetName, checked: false } })

      var dialogRef = this.dialog.open(DropdownSelectionDialogComponent, { data: { title: 'Athlet reaktivieren', availableDropdownItems: items }, width: '600px'})
      var result = await firstValueFrom(dialogRef.afterClosed())
      if (result?.selectedDropdownItem) {
        var licence = this.oldLicences.find(x => x.userUid == result.selectedDropdownItem.id)
        this.spinner.show()
        await this.userService.reactivateLicence(licence, this.userService.getLoggedInUser().uid)
        this.spinner.hide()
      }
    })
  }
}
