import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dropdown-selection-dialog',
  templateUrl: './dropdown-selection-dialog.component.html',
  styleUrls: ['./dropdown-selection-dialog.component.css']
})
export class DropdownSelectionDialogComponent {

  public title: string = ""
  public availableDropdownItems: DropdownItem[]
  public selectedDropdownItem: DropdownItem
  public cancelButtonText: string
  public submitButtonText: string


  constructor(public dialogRef: MatDialogRef<DropdownSelectionDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: { title: string, availableDropdownItems: DropdownItem[], selectedDropdownItem: DropdownItem, cancelButtonText: string, submitButtonText: string })
  {
    this.title = data.title;
    this.availableDropdownItems = data.availableDropdownItems;
    this.selectedDropdownItem = data.selectedDropdownItem
    this.cancelButtonText = data.cancelButtonText ?? "Abbrechen";
    this.submitButtonText = data.submitButtonText ?? "Übernehmen";
  }

  onSelectionChanged(item: DropdownItem){
    this.selectedDropdownItem = item
  }

  canSubmit(): boolean {
    return this.selectedDropdownItem != null
  }

  onCancel(): void {
    this.dialogRef.close({shouldTake: false})
  }

  onSubmit(): void {
    this.dialogRef.close({shouldTake: true, selectedDropdownItem: this.selectedDropdownItem});
  }
}

export class DropdownItem {
  id: string
  name: string
  checked: boolean
}
