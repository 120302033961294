import {Community} from "./community.model";

export type ManagementConfig = {
  community: Community;
  coachesMap: Map<string, string>;
  usersMap: Map<string, string>;
  groupsMap: Map<string, string>;
  imgUrl: string;
  isNewlyCreated?: boolean;
}

export class CommunityManagementConfig {
  private community: Community = null;

  public readonly isNewlyCreated: boolean;
  public name: string = null;
  public description: string = null;
  public imagePath: string = null;
  public tempImgUrl: string = null;
  public allowPostings: boolean = false;
  public allowRecipeSharing: boolean = false;
  public allowTrainingSharing: boolean = false;
  public allowVoiceMessagesInPostings: boolean = true;
  public allowFileSharing: boolean = false;
  public coaches: Map<string, string> = null;
  public users: Map<string, string> = null;
  public groups: Map<string, string> = null;
  public updatedImage: File = null;
  public hideMemberList: boolean = false;
  public isDirty: boolean = false;

  constructor(config: ManagementConfig) {
    if(config === null || config === undefined) {
      throw Error('CommunityManagementConfig must be defined');
    }
    if(config.community === null || config.imgUrl === null) {
      throw Error("Community or imgUrl was not declared!");
    }

    this.isNewlyCreated = !!config.isNewlyCreated;
    this.community = config.community;
    this.name = config.community.name || '';
    this.description = config.community.description || '';
    this.imagePath = config.community.imagePath || '';
    this.tempImgUrl = config.imgUrl || '';
    this.allowPostings = !!config.community.allowPostings;
    this.allowRecipeSharing = this.allowPostings && !!config.community.allowRecipeSharing;
    this.allowTrainingSharing = this.allowPostings && !!config.community.allowTrainingSharing;
    this.allowVoiceMessagesInPostings = this.allowPostings && !!config.community.allowVoiceMessagesInPostings;
    this.allowFileSharing =  this.allowPostings && !!config.community.allowFileSharing;
    this.hideMemberList = !!config.community.hideMemberList;

    this.coaches = new Map<string, string>();
    if(config.coachesMap) {
      this.coaches = config.coachesMap;
    }

    this.users = new Map<string, string>();
    if(config.usersMap) {
      this.users = config.usersMap;
    }

    this.groups = new Map<string, string>();
    if(config.groupsMap) {
      this.groups = config.groupsMap;
    }
  }

  public addOrRemoveCoach(coachId: string) {
    if (this.community.coachUids.indexOf(coachId) === -1) {
      this.community.coachUids.push(coachId);
    } else {
      this.community.coachUids.splice(this.community.coachUids.indexOf(coachId), 1);
    }

    if(this.community.selectedUserUids.indexOf(coachId) === -1) {
      this.community.selectedUserUids.push(coachId);
    } else {
      this.community.selectedUserUids.splice(this.community.selectedUserUids.indexOf(coachId), 1);
    }
    this.isDirty = true;
  }

  public getCoachIds(): string[] {
    return [...this.community.coachUids];
  }

  public getCoachesNames(): string {
    return this.getCoachIds().map(coach => {
      const coachName = this.coaches.get(coach);

      if(coachName) return coachName;
      return coach;
    }).join(', ');
  }

  public addOrRemoveMember(memberId: string) {
    if (this.community.selectedUserUids.indexOf(memberId) === -1) {
      this.community.selectedUserUids.push(memberId);
    } else {
      this.community.selectedUserUids.splice(this.community.selectedUserUids.indexOf(memberId), 1);
    }

    this.isDirty = true;
  }

  public getMemberIds(): string[] {
    return [...this.community.selectedUserUids];
  }

  public getMembersNames(): string {
    return this.getMemberIds().map(user => {
      const coachName = this.coaches.get(user);
      const userName = this.users.get(user);

      if(coachName) return coachName + ' (Coach)';
      if(userName) return userName;
      return user;
    }).join(', ');
  }

  public addOrRemoveGroup(groupId: string) {
    if (this.community.selectedGroupIds.indexOf(groupId) === -1) {
      this.community.selectedGroupIds.push(groupId);
    } else {
      this.community.selectedGroupIds.splice(this.community.selectedGroupIds.indexOf(groupId), 1);
    }

    this.isDirty = true;
  }

  public getGroupIds(): string[] {
    return [...this.community.selectedGroupIds];
  }

  public getGroupNames(): string {
    return this.getGroupIds().map(group => {
      const groupName = this.groups.get(group);

      if(groupName) return groupName;
      return group;
    }).join(', ');
  }

  public getGroupNamesAndMemberNames(): string {
    const combined = [...this.getGroupIds(), ...this.getMemberIds()];
    return combined.map(id => {
      const groupName = this.groups.get(id);
      const coachName = this.coaches.get(id);
      const userName = this.users.get(id);

      if(groupName) return groupName;
      if(coachName) return coachName + ' (Coach)';
      if(userName) return userName;
      return id;
    }).join(', ');
  }

  public getCommunityId(): string {
    return this.community.id;
  }

  public toCommunity(): Community {
    this.community.name = this.name;
    this.community.description = this.description;
    this.community.imagePath = this.imagePath;
    this.community.allowPostings = this.allowPostings;
    this.community.allowRecipeSharing = this.allowPostings && this.allowRecipeSharing;
    this.community.allowTrainingSharing = this.allowPostings && this.allowTrainingSharing;
    this.community.allowVoiceMessagesInPostings = this.allowPostings && this.allowVoiceMessagesInPostings;
    this.community.allowFileSharing = this.allowPostings && this.allowFileSharing;
    this.community.hideMemberList = this.hideMemberList;
    return this.community;
  }
}
