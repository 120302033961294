import { Component, OnInit } from '@angular/core';

import { AuthService } from  '../../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UtilityService } from 'src/app/services/utility.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent {
  public environment = environment

  public email = '';
  public password = '';
  public name = '';

  public emailConfirmed = false
  public createAccount = false
  public errorMessage: string

  constructor(public  authService:  AuthService, private userService: FirestoreService, private router: Router, private route: ActivatedRoute, public utilityService: UtilityService, private navService: NavbarService, private translate: TranslateService) { }

  ngOnInit(): void {
    //this.router.routerState
    this.route.paramMap.subscribe((param) => {
      this.authService.redirectUrl = param.get('redirectURL');
    });
    if (this.authService?.isLoggedIn()) {
      if (this.authService?.redirectUrl === '/body-data') {
        this.router.navigate['body-data'];
      }
      else if (this.authService?.redirectUrl === '/chat' && this.utilityService?.onNativeMobileApp()){
        this.router.navigate['chat'];
      }
      else {
        this.router.navigate['dashboard'];
      }
    }
  }

  // Check if string is a valid email address
  isEmailValid() {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email?.replaceAll(' ', '').replace(/[\u200B-\u200D\uFEFF]/g, ''))
  }

  async onConfirmEmail() {
    this.errorMessage = null
    var email = this.email?.replaceAll(' ', '').replace(/[\u200B-\u200D\uFEFF]/g, '')
    if (!email || email == '' || !this.isEmailValid()) {
      this.errorMessage = this.translate.instant('Bitte gib eine gültige Email-Adresse ein.')
      this.emailConfirmed = false
      return
    }
    
    var emailExists = await this.authService.isEmailExisting(email)
    if (emailExists) {
      this.emailConfirmed = true
      this.createAccount = false
    } else {
      this.createAccount = true
      this.emailConfirmed = true
    }
  }

  onLogIn() {
    this.errorMessage = null
    var email = this.email?.replaceAll(' ', '').replace(/[\u200B-\u200D\uFEFF]/g, '')
    if (this.createAccount) {
      if (this.name && this.name.length > 0) {
        this.authService.registerUser(email, this.password, this.name).then((result) => {
          console.log(result)
        }).catch((error) => {
          this.handleAuthError(error)
        });
      } else {
        this.errorMessage = this.translate.instant('Bitte gib deinen Namen ein.')
        return
      }
    } else {
      this.authService.login(email, this.password).then((result) => {
        // Handled in AppComponent in AuthService.AuthState subscription.
      }).catch((error) => {
        this.handleAuthError(error)
      });
    }
  }

  handleAuthError(error: any) {
    console.log(error)
    if (error?.code == 'auth/wrong-password') {
      this.errorMessage = this.translate.instant('Passwort falsch. Bitte korrigiere deine Email-Adresse oder dein Passwort.')
    } else if (error?.code == 'auth/invalid-email') {
      this.errorMessage = this.translate.instant('Die Email-Adresse ist falsch formatiert. Bitte korrigiere sie.')
    } else if (error?.code == 'auth/user-not-found') {
      this.errorMessage = this.translate.instant('Unter dieser Email-Adresse konnte kein Account gefunden werden. Bitte probiere es mit einer anderen Email-Adresse.')
    } else if (error?.code == 'auth/missing-password') {
      this.errorMessage = this.translate.instant('Bitte gib dein Passwort ein.')
    } else if (error?.code == 'auth/weak-password') {
      this.errorMessage = this.translate.instant('Das Passwort muss mindestens 6 Zeichen haben. Bitte wähle ein anderes Passwort.')
    } else {
      this.errorMessage = error.message
    }
  }

  goToWebsite() {
    window.open('https://nutrilize.app', "_blank");
  }
}
