<div class="spacer-10"></div>
<div class="icon-button burger row" (click)="closeSessionsColumn()">
    <svg width="1em" height="1em" viewBox="0 0 16 16" class="hidden toggle-sessions-button bi bi-list"
        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
            d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
    </svg>
    <h3 class="session-heading" (click)="toggleSessionsColumn()" translate>Einheiten:</h3>
</div>
<div class="spacer-10"></div>
<ng-container *ngIf="trainingPlan.isPeriodicPlan; else notPeriodic">
    <ng-container *ngIf="isBusy">
        <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
    </ng-container>
    <div *ngIf="!isBusy">
        <div  (mouseover)="mouseEnterWeek(week)" (touchstart)="mouseEnterWeek(week)" id="{{'week-' + week?.id}}" *ngFor="let week of trainingPlan?.weeks; let weekNumber = index"
            class="row week-list">
            <ng-container *ngIf="weekDaysByWeekIdInternal[week.id]?.length > 0">
                <div class="w-100">
                    <div *ngIf="week.name?.GetValue(languageService.selectedLanguageCode) === undefined"
                        class="add-heading-marker-wrapper label link"
                        (click)="onAddWeekName(week)">
                        <div *ngIf="hoveredWeek == week" class="add-heading-marker" translate>+ Woche benennen</div>
                    </div>
                    <div class="week-heading" *ngIf="week.name?.GetValue(languageService.selectedLanguageCode) !== undefined">
                        <input
                            [(ngModel)]="week.name[languageService.selectedLanguageCode]"
                            type="text" class="input small weekname-input">
                        <div class="icon-button marginleft-20 drag-item"
                            (click)="onDeleteWeekName(week)">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div *ngIf="internalWeekNameEnabled" class="w-100">
                    <div *ngIf="week.internalName === null"
                        class="add-heading-marker-wrapper label link"
                        (click)="onAddInternalWeekName(week)">
                        <div *ngIf="hoveredWeek == week" class="add-heading-marker" translate>+ Woche benennen (intern)</div>
                    </div>
                    <div class="week-heading" *ngIf="week.internalName !== null">
                        <input
                            [(ngModel)]="week.internalName"
                            type="text" class="input small weekname-input" [placeholder]="'Interner Name' | translate">
                        <div class="icon-button marginleft-20 drag-item"
                            (click)="onDeleteInternalWeekName(week)">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </div>
                    </div>
                </div>
                
                <div class="col">
                    <div (drop)="onDropWeekInternal(week, false);$event.stopPropagation()"
                        (dragover)="onDragOverWeek($event, week, false);$event.stopPropagation()"
                        *ngIf="draggingWeekInternal && draggingWeekInternal != week && weekNumber == 0 && !isFirstDayOfWeekInThePast(week) && !isAnyTrackedSessionInWeek(week)"
                        class="week-dropzone before {{focusedBeforeDropzoneWeekInternal == week ? 'hovered' : ''}}"
                        (dragenter)="onDragOverWeek($event, week, false);$event.stopPropagation()"
                        (dragleave)="onDragLeaveWeek($event, week, false)"></div>
                    <ng-container *ngIf="!draggingWeekInternal && !week.collapsed; else hiddenWeekTemplate">
                        <div id="{{'weekDay-' + weekDay?.getTime()}}"
                            *ngFor="let weekDay of weekDaysByWeekIdInternal[week.id]" class="weekday-container">
                            <div class="label display-inline" *ngIf="weekDay != null"
                            (drop)="onDropOnWeekDayItemInternal(weekDay, week.id);$event.stopPropagation()"
                            (dragover)="onDragOverWeekDayItem($event, weekDay, weekNumber)"
                            (dragenter)="onDragEnterWeekDayItem($event, weekDay, weekNumber);$event.stopPropagation()"
                            (dragleave)="onDragLeaveWeekDayItem($event, weekDay, weekNumber)">
                                <span class="weekday">{{getWeekDay(weekDay)}}</span>
                                <span *ngIf="!this.trainingPlan.isTemplate">{{getWeekDayDate(weekDay)}}</span>
                            </div>
                            <div class="label display-inline" *ngIf="weekDay == null"
                            (drop)="onDropOnWeekDayItemInternal(weekDay, week.id);$event.stopPropagation()"
                            (dragover)="onDragOverWeekDayItem($event, weekDay, weekNumber)"
                            (dragenter)="onDragEnterWeekDayItem($event, weekDay, weekNumber);$event.stopPropagation()"
                            (dragleave)="onDragLeaveWeekDayItem($event, weekDay, weekNumber)" translate>
                                Frei absolvierbar
                            </div>
                            <div class="weekday-buttons-wrapper marginright-20 float-right">
                                <div *ngIf="!isDayInThePast(weekDay) && !isWeekInThePast(week)" class="row">
                                    <div class="col-6 justify-content-center">
                                        <div class="icon-button display-inlineblock"
                                            (click)="addSessionToDayInternal(weekDay, week.id); $event.stopPropagation()"
                                            [tooltip]="'Neue Einheit hinzufügen' | translate">
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus"
                                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="col-6 justify-content-center">
                                        <div class="icon-button display-inlineblock"
                                            (click)="addSessionFromTemplateInternal(weekDay, week); $event.stopPropagation()"
                                            [tooltip]="'Einheit aus Vorlage hinzufügen' | translate">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                                fill="currentColor" class="bi bi-clipboard-plus padding-4"
                                                viewBox="0 0 16 16">
                                                <path fill-rule="evenodd"
                                                    d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7" />
                                                <path
                                                    d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                                                <path
                                                    d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="draggingSessionInternal && !isDayInThePast(weekDay)"
                                class="week-dropzone {{focusedWeekDayForSessionDropInternal == weekDay && focusedWeekNumberForSessionDropInternal == weekNumber ? 'hovered' : ''}}"
                                (drop)="onDropOnWeekDayItemInternal(weekDay, week.id);$event.stopPropagation()"
                                (dragover)="onDragOverWeekDayItem($event, weekDay, weekNumber)"
                                (dragenter)="onDragEnterWeekDayItem($event, weekDay, weekNumber);$event.stopPropagation()"
                                (dragleave)="onDragLeaveWeekDayItem($event, weekDay, weekNumber)">
                            </div>
                            <ng-container *ngFor="let session of trainingPlan?.getSessionsByDate(weekDay, week.id)">
                                <div id="sessionList" class="d-flex session-tab-wrapper" *ngIf="!session?.deleted"
                                    (dragover)="onDragOverSession($event, session);$event.stopPropagation()">
                                    <button class="tab-button vertical-tab-button ignore-disabled" draggable="true"
                                        [ngClass]="{'active': selectedSessionInternal === session}" type="button"
                                        (click)="onSessionSelectionChanged(session)"
                                        (dragstart)="onDragStartSession($event, session, colorPicker)"
                                        (dragend)="onDragEndSession()"
                                        (drop)="onDropAfterSessionInternal(session);$event.stopPropagation()"
                                        (dragleave)="onDragLeaveDropzoneSession($event)">
                                        <div *ngIf="!session?.isTracked" class="drag-handle session-drag-handle">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22"
                                                fill="currentColor" class="bi bi-grip-vertical" viewBox="0 0 16 16">
                                                <path
                                                    d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                            </svg>
                                        </div>
                                        <div class="row" ondragstart="return false;">
                                            <div class="base-session-indicator"
                                                (click)="colorPicker.click(); $event.stopPropagation();"
                                                [style.background-color]="getBaseSessionReferenceColor(session)"
                                                [matTooltip]="'Gleiche oder aufeinander aufbauende Einheiten können mit der gleichen Farbe miteinander verbunden werden, um Fortschritte besser messen zu können.' | translate">
                                            </div>
                                            <input #colorPicker class="hidden-color-picker"
                                                (cpToggleChange)="colorPickerToggleChanged($event)"
                                                (click)="$event.stopPropagation();"
                                                (colorPickerChange)="setIndicatorColor(session, $event)"
                                                [colorPicker]="getBaseSessionReferenceColor(session)"
                                                [style.background-color]="getBaseSessionReferenceColor(session)"
                                                cpOutputFormat="hex" />
                                            <div class="col tab-button-text-col">
                                                <div class="tab-button-text">
                                                    {{session?.getSessionNameTranslationWithFallback(languageService.selectedLanguageCode)}}
                                                </div>
                                            </div>
                                            <div class="col-auto d-flex">
                                                <div *ngIf="!this.trainingPlan.isTemplate && session?.isTracked"
                                                    class="col-auto checkbox-column" [matTooltip]="'Bereits absolviert' | translate"
                                                    (click)="onOpenTrackedTrainingSessionInternal(session);$event.stopPropagation()"
                                                    tooltipPlacement="left">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                        fill="currentColor" viewBox="0 0 16 16" class="bi bi-check2-circle">
                                                        <path
                                                            d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z">
                                                        </path>
                                                        <path
                                                            d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z">
                                                        </path>
                                                    </svg>
                                                </div>
                                                <button mat-button [matMenuTriggerFor]="sessionMenu"
                                                    class="session-menu-button"
                                                    (click)="setAvailableBaseSessions(session); $event.stopPropagation()">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        fill="currentColor" class="bi bi-three-dots-vertical"
                                                        viewBox="0 0 16 16">
                                                        <path
                                                            d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </button>
                                    <mat-menu #sessionMenu="matMenu">
                                        <button mat-menu-item (click)="duplicateSessionInternal(session)" [innerHTML]="'Einheit duplizieren' | translate"></button>
                                        <button mat-menu-item (click)="saveSessionAsTemplateInternal(session)" [innerHTML]="'Einheit als Vorlage speichern' | translate"></button>
                                        <button *ngIf="!session?.isTracked" mat-menu-item (click)="removeSessionInternal(session)" [innerHTML]="'Einheit entfernen' | translate"></button>
                                        <button *ngIf="session.baseSessionId" mat-menu-item
                                            (click)="disconnectFromBaseSession(session)" translate><span [translate]="">Verbindung zu</span> <div
                                                class="base-session-indicator text-indicator ml-1"
                                                [style.background-color]="getBaseSessionReferenceColor(session)"></div>
                                            <span translate>lösen</span></button>
                                        <button *ngIf="!session.baseSessionId && canConnectAnyBaseSession(session)"
                                            mat-menu-item [matMenuTriggerFor]="baseSessionConnectorMenu" [innerHTML]="'Mit Basis-Einheit verbinden' | translate"></button>
                                        <button *ngIf="!session?.isTracked && !trainingPlan.isTemplate" mat-menu-item
                                                (click)="trackSessionInternal(session)" [innerHTML]="'Einheit tracken' | translate"></button>
                                    </mat-menu>
                                    <mat-menu #baseSessionConnectorMenu="matMenu">
                                        <ng-container *ngFor="let baseSession of availableBaseSessions">
                                            <button mat-menu-item
                                                (click)="connectToBaseSessionInternal(session, baseSession.session)">
                                                <div class="base-session-indicator text-indicator"
                                                    [style.background-color]="baseSession.color"></div>
                                                {{baseSession?.session?.getSessionNameTranslationWithFallback(languageService.selectedLanguageCode)}}
                                            </button>
                                        </ng-container>
                                    </mat-menu>
                                </div>
                                <div *ngIf="draggingSessionInternal && !isDayInThePast(weekDay)"
                                    (drop)="onDropAfterSessionInternal(session);$event.stopPropagation()"
                                    class="week-dropzone {{focusedAfterDropzoneSessionInternal == session ? 'hovered' : ''}}"
                                    (dragover)="onDragOverDropzoneSession($event, session)"
                                    (dragenter)="onDragEnterWeekDayItem($event, weekDay, weekNumber);$event.stopPropagation()"
                                    (dragleave)="onDragLeaveDropzoneSession($event)">
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-template #hiddenWeekTemplate>
                        <div (drop)="onDropWeekInternal(week, true);$event.stopPropagation()"
                            (dragover)="onDragOverWeek($event, week, true);$event.stopPropagation()"
                            (dragenter)="onDragOverWeek($event, week, true);$event.stopPropagation()"
                            (dragleave)="onDragLeaveWeek($event, week, true)" class="weekday-container link"
                            (click)="week.collapsed = false;$event.stopPropagation()">
                            <div class="row justify-content-between">
                                <div class="col-auto label">
                                    {{getWeekRangeString(week)}}
                                </div>
                                <div class="col-auto text-nowrap">
                                    <ng-container *ngFor="let session of trainingPlan?.getSessionsByWeekId(week.id)">
                                        <div *ngIf="!session.deleted" (click)="onSessionSelectionChanged(session)"
                                            class="base-session-indicator mr-1 d-inline-block"
                                            [style.background-color]="getBaseSessionReferenceColor(session) ?? '#c1c4cf'"
                                            matTooltip="{{session?.getSessionNameTranslationWithFallback(languageService.selectedLanguageCode) + (session.isTracked ? + ' ' + ('(absolviert)' | translate) : '')}}">
                                            <span *ngIf="!session.isTracked; else trackedSessionIndicator;">
                                                {{getWeekDay(session.plannedDate)}}
                                            </span>
                                            <ng-template #trackedSessionIndicator>
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        fill="currentColor" viewBox="0 0 16 16"
                                                        class="bi bi-check2-circle">
                                                        <path
                                                            d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z">
                                                        </path>
                                                        <path
                                                            d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z">
                                                        </path>
                                                    </svg>
                                                </span>
                                            </ng-template>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <div (drop)="onDropWeekInternal(week, true);$event.stopPropagation()"
                        (dragover)="onDragOverWeek($event, week, true);$event.stopPropagation()"
                        (dragenter)="onDragOverWeek($event, week, true);$event.stopPropagation()"
                        (dragleave)="onDragLeaveWeek($event, week, true)"
                        *ngIf="draggingWeekInternal && draggingWeekInternal != week && !isNextWeekInThePast(week)"
                        class="week-dropzone  {{focusedAfterDropzoneWeekInternal == week ? 'hovered' : ''}}"></div>
                </div>
                <div class="col-3 actions-column">
                    <div class="link" [draggable]="!isWeekInThePast(week) && !isAnyTrackedSessionInWeek(week)"
                        (dragstart)="onDragWeekStarted($event, week)" (dragenter)="$event.preventDefault();"
                        (dragend)="onDragWeekEnd()">
                        <strong>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-grip-vertical" viewBox="0 0 16 16">
                                <path
                                    d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                            W{{weekNumber + 1}}
                        </strong>
                        <div class="week-edit-drag-buttons">
                            <div class="week-menu-button d-inline-block" (click)="week.collapsed = !week.collapsed"
                                matTooltip="{{week.collapsed ? ('Woche einblenden' | translate) : ('Woche ausblenden' | translate)}}">
                                <ng-container *ngIf="!week.collapsed; else collapsed">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </ng-container>
                                <ng-template #collapsed>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-caret-left-fill" viewBox="0 0 16 16">
                                        <path
                                            d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
                                    </svg>
                                </ng-template>
                            </div>
                            <button mat-button [matMenuTriggerFor]="weekMenu" class="week-menu-button"
                                (click)="$event.stopPropagation()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                    <path
                                        d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                </svg>
                            </button>
                        </div>
                        <div *cdkDragPreview>
                            <strong>
                                W{{weekNumber + 1}}
                            </strong>
                        </div>
                    </div>

                    <div *ngIf="week.hide && !draggingWeekInternal" class="icon-button display-inlineblock"
                        (click)="onShowWeek(week); $event.stopPropagation()" [tooltip]="'Woche für Coachee einblenden' | translate">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-eye-slash" viewBox="0 0 16 16">
                            <path
                                d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                            <path
                                d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                            <path
                                d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                        </svg>
                    </div>
                    <mat-menu #weekMenu="matMenu">
                        <button mat-menu-item (click)="duplicateWeekInternal(week)">{{'Woche duplizieren' | translate}}</button>
                        <button mat-menu-item (click)="copyWeekInternal(week)" translate>{{'Woche kopieren' | translate}}</button>
                        <button *ngIf="copyWeekFrom && !readOnlyMode && !isFirstDayOfWeekInThePast(week)" mat-menu-item (click)="pasteWeekInternal(week)">{{'Woche einfügen' | translate}}</button>
                        <button mat-menu-item *ngIf="!isWeekInThePast(week) && !isAnyTrackedSessionInWeek(week)"
                            (click)="removeWeekInternal(week)">{{'Woche löschen' | translate}}</button>
                        <ng-container *ngIf="!isWeekInThePast(week) && !isAnyTrackedSessionInWeek(week)">
                            <button *ngIf="!week.hide; else showWeekButton" mat-menu-item
                                (click)="onHideWeek(week)">{{'Woche für Coachee verstecken' | translate}}</button>
                            <ng-template #showWeekButton>
                                <button mat-menu-item (click)="onShowWeek(week)">{{'Woche einblenden' | translate}}</button>
                            </ng-template>
                        </ng-container>
                        <button mat-menu-item (click)="saveWeekAsTemplateInternal(week)">{{'Woche als Vorlage speichern' | translate}}</button>
                    </mat-menu>
                    <div *ngIf="!draggingWeekInternal && !week.collapsed"
                        class="week-number-indicator {{isWeekInThePast(week) ? 'past-week' : ''}}"></div>
                </div>
                <div class="spacer-20"></div>
            </ng-container>
        </div>
    </div>
</ng-container>
<ng-template #notPeriodic>
    <div cdkDropList (cdkDropListDropped)="dropTrainingSession($event)">
        <ng-container *ngFor="let session of trainingPlan?.sessions">
            <div id="{{'sessionButton-' + session.id}}" class="row" cdkDrag [cdkDragDisabled]="readOnlyMode"
                cdkDragLockAxis="y" *ngIf="!session?.deleted">
                <button cdkDragHandle class="tab-button vertical-tab-button ignore-disabled"
                    [ngClass]="{'active': selectedSessionInternal === session}" type="button"
                    (click)="onSessionSelectionChanged(session)">
                    <div class="row">
                        <div class="base-session-indicator" (click)="colorPicker.click(); $event.stopPropagation();"
                            [style.background-color]="session.indicatorColor"></div>
                        <input #colorPicker class="hidden-color-picker"
                            (cpToggleChange)="colorPickerToggleChanged($event)" (click)="$event.stopPropagation();"
                            [(colorPicker)]="session.indicatorColor" cpOutputFormat="hex" />
                        <div class="drag-handle session-drag-handle">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                                class="bi bi-grip-vertical" viewBox="0 0 16 16">
                                <path
                                    d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                        </div>
                        <div class="col tab-button-text-col">
                            <div class="tab-button-text">
                                {{session?.getSessionNameTranslationWithFallback(languageService.selectedLanguageCode)}}
                            </div>
                        </div>
                    </div>
                </button>

                <div *ngIf="!readOnlyMode" class="icon-button float-right marginleft-2 remove-session-button"
                    (click)="duplicateSessionInternal(session);$event.stopPropagation()" [tooltip]="'Einheit duplizieren' | translate">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="padding-6 bi bi-files" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z" />
                        <path
                            d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z" />
                    </svg>
                </div>
                <div *ngIf="!session.isRestDay && !readOnlyMode"
                    class="icon-button float-right marginleft-2 remove-session-button"
                    (click)="saveSessionAsTemplateInternal(session);$event.stopPropagation()"
                    [tooltip]="'Einheit als Vorlage speichern' | translate">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                        class="padding-6 bi bi-clipboard" viewBox="0 0 16 16">
                        <path
                            d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                        <path
                            d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                    </svg>
                </div>
                <div *ngIf="!readOnlyMode" class="icon-button float-right marginleft-2 remove-session-button"
                    (click)="removeSessionInternal(session);$event.stopPropagation()" [tooltip]="'Einheit entfernen' | translate">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="padding-6 bi bi-trash" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                        <path fill-rule="evenodd"
                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                    </svg>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>
<div *ngIf="!readOnlyMode && !isBusy" class="row">
    <div *ngIf="!trainingPlan.isPeriodicPlan" class="icon-button display-inlineblock marginleft-10 add-session-button"
        (click)="addSessionInternal(false, false)" translate>
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg>
        Einheit hinzufügen
    </div>
    <div *ngIf="trainingPlan.isPeriodicPlan" class="icon-button display-inlineblock marginleft-10 add-session-button"
        (click)="addWeekInternal()" translate>
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg>
        Woche hinzufügen
    </div>
    <div *ngIf="trainingPlan.isPeriodicPlan" class="icon-button display-inlineblock marginleft-10 add-session-button"
        (click)="addWeekFromTemplateInternal()" translate>
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg>
        Wochen aus Vorlage hinzufügen
    </div>
    <div *ngIf="!trainingPlan.isPeriodicPlan" class="icon-button display-inlineblock marginleft-10 add-session-button"
        (click)="addSessionFromTemplateInternal()" translate>
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg>
        Einheit aus Vorlage hinzufügen
    </div>
    <div *ngIf="!trainingPlan.isPeriodicPlan" class="icon-button display-inlineblock marginleft-10 add-session-button"
        (click)="addSessionInternal(true, false)" translate>
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
        </svg>
        Ruhetag hinzufügen
    </div>
    <div class="spacer-50"></div>
</div>