<div class="row">
    <ng-container *ngIf="loading">
        <div class="col-12 textalign-center">
            <div class="loading-animation">
                <mat-spinner class="spinner margin-auto" diameter="30" strokeWidth="2"></mat-spinner>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!loading">
        <div class="col-5 col-xl-3" translate>
            Daily Check
        </div>
        <div class="col-7 col-xl-9">
            <div class="d-inline-block wrapper-selection">
                <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getSelectedDailyQuestionaire()?.internalName ?? getSelectedDailyQuestionaire()?.name ?? ('Auswählen' | translate)}}</button>
                <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
                    <button class="dropdown-item" type="button" (click)="onSelectDailyCheck(null)">-</button>
                    <div *ngFor="let questionaire of getDailyQuestionaires()">
                        <button class="dropdown-item" type="button" (click)="onSelectDailyCheck(questionaire)">{{questionaire.internalName}}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-5 col-xl-3" translate>
            Weekly Check
        </div>
        <div class="col-7 col-xl-9">
            <div class="d-inline-block wrapper-selection">
                <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getSelectedWeeklyQuestionaire()?.internalName ?? getSelectedWeeklyQuestionaire()?.name ?? ('Auswählen' | translate)}}</button>
                <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
                    <button class="dropdown-item" type="button" (click)="onSelectWeeklyCheck(null)">-</button>
                    <div *ngFor="let questionaire of getWeeklyQuestionaires()">
                        <button class="dropdown-item" type="button" (click)="onSelectWeeklyCheck(questionaire)">{{questionaire.internalName}}</button>
                    </div>
                </div>
            </div>
            <div class="d-inline-block wrapper-selection" *ngIf="getSelectedWeeklyQuestionaire()">
                <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getSelectedWeeklyCheckInDay()}}</button>
                <div class="dropdown-menu" aria-labelledby="dropdownWeeklyDate">
                    <button class="dropdown-item" type="button" (click)="onWeeklyCheckDayChanged('monday')" translate>Montag</button>
                    <button class="dropdown-item" type="button" (click)="onWeeklyCheckDayChanged('tuesday')" translate>Dienstag</button>
                    <button class="dropdown-item" type="button" (click)="onWeeklyCheckDayChanged('wednesday')" translate>Mittwoch</button>
                    <button class="dropdown-item" type="button" (click)="onWeeklyCheckDayChanged('thursday')" translate>Donnerstag</button>
                    <button class="dropdown-item" type="button" (click)="onWeeklyCheckDayChanged('friday')" translate>Freitag</button>
                    <button class="dropdown-item" type="button" (click)="onWeeklyCheckDayChanged('saturday')" translate>Samstag</button>
                    <button class="dropdown-item" type="button" (click)="onWeeklyCheckDayChanged('sunday')" translate>Sonntag</button>
                </div>
            </div>
        </div>
        <div class="col-5 col-xl-3" translate>
            Trainingsfeedback
        </div>
        <div class="col-7 col-xl-9">
            <div class="d-inline-block wrapper-selection">
                <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getSelectedTrainingFeedbackQuestionaire()?.internalName ?? ('Auswählen' | translate)}}</button>
                <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
                    <button class="dropdown-item" type="button" (click)="onSelectTrainingFeedbackQuestionaire(null)">-</button>
                    <div *ngFor="let questionaire of getTrainingFeedbackQuestionaires()">
                        <button class="dropdown-item" type="button" (click)="onSelectTrainingFeedbackQuestionaire(questionaire)">{{questionaire.internalName}}</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>