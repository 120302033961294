import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-selection-dropdown',
  templateUrl: './selection-dropdown.component.html',
  styleUrls: ['./selection-dropdown.component.css']
})
export class SelectionDropdownComponent {

  @ViewChild(MatMenuTrigger, { static: false }) matMenuTrigger: MatMenuTrigger

  @Input() hasSearchField: boolean = false
  @Input() isMultiSelect: boolean = false
  @Input() canUnselect: boolean = true
  @Input() selectableGroups: SelectableGroup[] = []
  @Input() placeholder: string
  @Input() invalid: boolean = false
  @Input() disabled: boolean = false
  @Input() showArrowOnly: boolean = false
  
  @Output() itemChanged = new EventEmitter<SelectableItem>()
  @Output() groupsAndItemsChanged = new EventEmitter<SelectableGroup[]>()

  searchInput: string = null
  filteredSelectableGroups: SelectableGroup[]

  getButtonText(): string {
    var selectedItems = this.selectableGroups.flatMap(group => group.items.filter(item => item.isSelected))
    if (selectedItems.length == 0) return this.placeholder ?? 'Auswählen'
    return selectedItems.map(item => item.name).join(', ')
  }

  onSearchInputChanged(text: string) {
    this.searchInput = text
    if (text == null || text.length == 0) {
      this.filteredSelectableGroups = this.selectableGroups
      return
    }

    this.filteredSelectableGroups = []
    this.selectableGroups.forEach(group => {
      let items = group.items.filter(item => item.name.toLowerCase().includes(this.searchInput.toLowerCase()))
      if (items.length > 0) {
        let newGroup = new SelectableGroup()
        newGroup.name = group.name
        newGroup.items = items
        this.filteredSelectableGroups.push(newGroup)
      }
    })
  }

  openDropdown() { }

  onItemClicked(item: SelectableItem) {
    if (this.isMultiSelect || !item.isSelected || !this.canUnselect) item.isSelected = !item.isSelected
    if (item.isSelected && !this.isMultiSelect) {
      this.selectableGroups.forEach(group => {
        group.items.forEach(i => {
          if (i != item) i.isSelected = false
        })
      })
      this.itemChanged.emit(item)
      this.groupsAndItemsChanged.emit(this.selectableGroups)
      this.matMenuTrigger.closeMenu()
      return
    }
    this.itemChanged.emit(item)
    this.groupsAndItemsChanged.emit(this.selectableGroups)
  }
}

export class SelectableGroup {
  items: SelectableItem[] = []
  name: string
}

export class SelectableItem {
  value: any
  name: string
  description: string
  isSelected: boolean = false

  constructor(value: any, name: string, description: string, isSelected: boolean) {
    this.value = value
    this.name = name
    this.description = description
    this.isSelected = isSelected
  }
}