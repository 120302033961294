import { BaseNutritionFact, NutritionalValue, NutritionalValueEditable, NutritionalValueHolder } from './basenutritionfact.model';
import { Ingredient } from './ingredient.model';
import { Tag } from './tag.model';
import { LanguageDictionary } from './languagedictionary.model';

export class Recipe implements NutritionalValueEditable {

    id: string;
    name: string
    nameTranslation: LanguageDictionary<string>//= new LanguageDictionary<string>();
    creatorUid: string;
    tmpId: string;
    thumbnailPath: string
    thumbnailUrl: string

    ingredients: Ingredient[]
    instructions: string
    instructionsTranslation: LanguageDictionary<string>//= new LanguageDictionary<string>()
    servings: number
    preparationTimeMinutes: number
    totalTimeMinutes: number
    shared: boolean
    assignedUsers: string[]
    assignedGroupNames: string[]
    tags: string
    deleted: boolean
    creationDate: Date

    get allowWeightAdjustment(): boolean {
        return this.getIngredients()?.filter(i => i.allowWeightAdjustment).length > 0
    }

    hasFixedServing: boolean
    excludeFromAutoFill: boolean
    baseMealTemplateId: string
    modelVersion: number

    calories: number
    carbohydrates: number
    protein: number
    fat: number
    sugar: number
    polyols: number
    fibre: number
    saturatedFat: number
    monounsaturatedFat: number
    polyunsaturatedFat: number
    transFat: number
    omega3Fat: number
    omega6Fat: number
    vitaminA: number
    vitaminC: number
    vitaminD: number
    vitaminB1: number
    vitaminB2: number
    vitaminB3: number
    vitaminB5: number
    vitaminB6: number
    vitaminB7: number
    vitaminB9: number
    vitaminB12: number
    vitaminE: number
    vitaminK: number
    iron: number
    magnesium: number
    zinc: number
    calcium: number
    sodium: number
    potassium: number
    phosphorus: number
    sulphur: number
    chlorine: number
    copper: number
    manganese: number
    fluorine: number
    iodine: number
    minerals: number
    organicAcids: number
    mannitol: number
    sorbitol: number
    xylitol: number
    glucose: number
    fructose: number
    galactose: number
    sucrose: number
    maltose: number
    lactose: number
    glycogen: number
    starch: number
    monosaccharides: number
    disaccharides: number
    oligosaccharides: number
    polysaccharides: number
    waterSolubleFibre: number
    waterInsolubleFibre: number
    essentialAminoAcids: number
    nonessentialAminoAcids: number
    leucine: number
    isoleucine: number
    valine: number
    uricAcid: number
    purine: number
    linoleicAcid: number
    alphaLinolenicAcid: number
    arachidonicAcid: number
    eicosapentaenoicAcid: number
    docosahexaenoicAcid: number
    cholesterol: number
    salt: number
    alcoholContent: number
    alcohol: number
    water: number

    // Tmp:
    baseRecipe: Recipe
    loaded = false
    nutritionFactsLoaded = false
    fromCommonFirebase: boolean
    thumbnailURL: string
    updatedImage: File
    computedTags: Tag[]
    alreadyInDatabase: boolean
    // Tmp for migration:
    newRecipeVersion: Recipe

    constructor();
    constructor(init: Recipe);
    constructor(init?: Recipe) {
        this.id = init && init.id || null
        this.name = init && init.name || null
        this.nameTranslation = init && init.nameTranslation != null ? new LanguageDictionary(init.nameTranslation.de, init.nameTranslation.en) : null // new LanguageDictionary<string>(this.name, '')
        this.creatorUid = init && init.creatorUid || null
        this.tmpId = init && init.tmpId || null
        this.thumbnailPath = init && init.thumbnailPath || null
        this.thumbnailUrl = init && init.thumbnailUrl || null
        this.thumbnailURL = init && init.thumbnailURL || null
        this.instructions = init && init.instructions || null
        this.instructionsTranslation = init && init.instructionsTranslation != null ? new LanguageDictionary(init.instructionsTranslation.de, init.instructionsTranslation.en) : null // new LanguageDictionary<string>(this.instructions, '')
        if (!this.instructionsTranslation && (init as any)?.instrucionsTranslation) this.instructionsTranslation = new LanguageDictionary<string>((init as any).instrucionsTranslation.de, (init as any).instrucionsTranslation.en)
        this.servings = init && init.servings || null
        this.shared = init && init.shared || false
        this.assignedUsers = init && init.assignedUsers || []
        this.assignedGroupNames = init && init.assignedGroupNames || []
        this.tags = init && init.tags || null
        this.computedTags = Tag.tagsFromString(this.getTags())
        this.preparationTimeMinutes = init && init.preparationTimeMinutes != null ? init.preparationTimeMinutes : null
        this.totalTimeMinutes = init && init.totalTimeMinutes != null ? init.totalTimeMinutes : null
        this.deleted = init && init.deleted || false
        this.hasFixedServing = init && init.hasFixedServing || false
        this.excludeFromAutoFill = init && init.excludeFromAutoFill || false
        this.creationDate = init && init.creationDate || null
        this.fromCommonFirebase = init && init.fromCommonFirebase || false
        this.baseMealTemplateId = init && init.baseMealTemplateId || null
        this.baseRecipe = init && init.baseRecipe || null
        this.modelVersion = init && init.modelVersion || 1
        if (this.modelVersion == 2) {
            this.ingredients = init && init.ingredients?.map(x => new Ingredient(x)) || null
        } else {
            this.ingredients = init && init.ingredients || []
        }
    }

    getName(languageCode: string = 'de') {
        if (this.nameTranslation && this.nameTranslation[languageCode]) return this.nameTranslation[languageCode]
        if (this.baseRecipe && this.baseRecipe.nameTranslation && this.baseRecipe.nameTranslation[languageCode]) return this.baseRecipe.nameTranslation[languageCode]
        return this.name ?? this.baseRecipe?.name ?? ''
    }
    setName(value: string) {
        if(this.nameTranslation == null){
            this.nameTranslation = new LanguageDictionary<string>();
        }
        this.nameTranslation.de = value
    }

    getInstructions(languageCode: string = 'de') {
        if (this.instructionsTranslation && this.instructionsTranslation[languageCode] != null) return this.instructionsTranslation[languageCode]
        if (this.baseRecipe && this.baseRecipe.instructionsTranslation && this.baseRecipe.instructionsTranslation[languageCode] != null) return this.baseRecipe.instructionsTranslation[languageCode]
        return this.instructions ?? this.baseRecipe?.instructions
    }
    setInstructions(value: string) {
        if(this.instructionsTranslation == null){
            this.instructionsTranslation = new LanguageDictionary<string>();
        }
        this.instructionsTranslation.de = value
    }

    getThumbnailPath() {
        if (this.thumbnailPath != null) return this.thumbnailPath
        if (this.modelVersion == 2) return this.baseRecipe?.thumbnailPath ?? null
        return 'meal_templates/' + this.id + '/thumbnail.jpg'
    }
    isThumbnailPathFromCommonFirebase() {
        if (this.thumbnailPath != null) return this.fromCommonFirebase
        if (this.baseRecipe?.thumbnailPath != null) return this.baseRecipe.fromCommonFirebase
        return this.fromCommonFirebase
    }

    getIngredients() {
        return this.ingredients ?? this.baseRecipe?.ingredients
    }

    getRawTags() {
        return this.getTags()
    }
    
    getTags() {
        return this.tags ?? this.baseRecipe?.tags
    }

    getComputedTags(): Tag[] {
        return this.computedTags ?? this.baseRecipe?.computedTags
    }

    getPreparationTime() {
        return this.preparationTimeMinutes ?? this.baseRecipe?.getPreparationTime()
    }
    
    getTotalTime() {
        return this.totalTimeMinutes ?? this.baseRecipe?.getTotalTime()
    }

    getNutritionalValue(nutritionalValue: string): number {
        if (this.calories != null) {
            switch (NutritionalValue[nutritionalValue].toString()) {
                case NutritionalValue.calories.toString(): return this.calories
                case NutritionalValue.carbohydrates.toString(): return this.carbohydrates
                case NutritionalValue.protein.toString(): return this.protein
                case NutritionalValue.fat.toString(): return this.fat
                case NutritionalValue.sugar.toString(): return this.sugar
                case NutritionalValue.polyols.toString(): return this.polyols
                case NutritionalValue.fibre.toString(): return this.fibre
                case NutritionalValue.saturatedFat.toString(): return this.saturatedFat
                case NutritionalValue.monounsaturatedFat.toString(): return this.monounsaturatedFat
                case NutritionalValue.polyunsaturatedFat.toString(): return this.polyunsaturatedFat
                case NutritionalValue.transFat.toString(): return this.transFat
                case NutritionalValue.omega3Fat.toString(): return this.omega3Fat
                case NutritionalValue.omega6Fat.toString(): return this.omega6Fat
                case NutritionalValue.vitaminA.toString(): return this.vitaminA
                case NutritionalValue.vitaminC.toString(): return this.vitaminC
                case NutritionalValue.vitaminD.toString(): return this.vitaminD
                case NutritionalValue.vitaminB1.toString(): return this.vitaminB1
                case NutritionalValue.vitaminB2.toString(): return this.vitaminB2
                case NutritionalValue.vitaminB3.toString(): return this.vitaminB3
                case NutritionalValue.vitaminB5.toString(): return this.vitaminB5
                case NutritionalValue.vitaminB6.toString(): return this.vitaminB6
                case NutritionalValue.vitaminB7.toString(): return this.vitaminB7
                case NutritionalValue.vitaminB9.toString(): return this.vitaminB9
                case NutritionalValue.vitaminB12.toString(): return this.vitaminB12
                case NutritionalValue.vitaminE.toString(): return this.vitaminE
                case NutritionalValue.vitaminK.toString(): return this.vitaminK
                case NutritionalValue.iron.toString(): return this.iron
                case NutritionalValue.magnesium.toString(): return this.magnesium
                case NutritionalValue.zinc.toString(): return this.zinc
                case NutritionalValue.calcium.toString(): return this.calcium
                case NutritionalValue.sodium.toString(): return this.sodium
                case NutritionalValue.potassium.toString(): return this.potassium
                case NutritionalValue.phosphorus.toString(): return this.phosphorus
                case NutritionalValue.sulphur.toString(): return this.sulphur
                case NutritionalValue.chlorine.toString(): return this.chlorine
                case NutritionalValue.copper.toString(): return this.copper
                case NutritionalValue.manganese.toString(): return this.manganese
                case NutritionalValue.fluorine.toString(): return this.fluorine
                case NutritionalValue.iodine.toString(): return this.iodine
                case NutritionalValue.minerals.toString(): return this.minerals
                case NutritionalValue.organicAcids.toString(): return this.organicAcids
                case NutritionalValue.mannitol.toString(): return this.mannitol
                case NutritionalValue.sorbitol.toString(): return this.sorbitol
                case NutritionalValue.xylitol.toString(): return this.xylitol
                case NutritionalValue.glucose.toString(): return this.glucose
                case NutritionalValue.fructose.toString(): return this.fructose
                case NutritionalValue.galactose.toString(): return this.galactose
                case NutritionalValue.sucrose.toString(): return this.sucrose
                case NutritionalValue.maltose.toString(): return this.maltose
                case NutritionalValue.lactose.toString(): return this.lactose
                case NutritionalValue.glycogen.toString(): return this.glycogen
                case NutritionalValue.starch.toString(): return this.starch
                case NutritionalValue.monosaccharides.toString(): return this.monosaccharides
                case NutritionalValue.disaccharides.toString(): return this.disaccharides
                case NutritionalValue.oligosaccharides.toString(): return this.oligosaccharides
                case NutritionalValue.polysaccharides.toString(): return this.polysaccharides
                case NutritionalValue.waterSolubleFibre.toString(): return this.waterSolubleFibre
                case NutritionalValue.waterInsolubleFibre.toString(): return this.waterInsolubleFibre
                case NutritionalValue.essentialAminoAcids.toString(): return this.essentialAminoAcids
                case NutritionalValue.nonessentialAminoAcids.toString(): return this.nonessentialAminoAcids
                case NutritionalValue.leucine.toString(): return this.leucine
                case NutritionalValue.isoleucine.toString(): return this.isoleucine
                case NutritionalValue.valine.toString(): return this.valine
                case NutritionalValue.uricAcid.toString(): return this.uricAcid
                case NutritionalValue.purine.toString(): return this.purine
                case NutritionalValue.linoleicAcid.toString(): return this.linoleicAcid
                case NutritionalValue.alphaLinolenicAcid.toString(): return this.alphaLinolenicAcid
                case NutritionalValue.arachidonicAcid.toString(): return this.arachidonicAcid
                case NutritionalValue.eicosapentaenoicAcid.toString(): return this.eicosapentaenoicAcid
                case NutritionalValue.docosahexaenoicAcid.toString(): return this.docosahexaenoicAcid
                case NutritionalValue.cholesterol.toString(): return this.cholesterol
                case NutritionalValue.salt.toString(): return this.salt
                case NutritionalValue.alcoholContent.toString(): return this.alcoholContent
                case NutritionalValue.alcohol.toString(): return this.alcohol
                case NutritionalValue.water.toString(): return this.water
            
                default: return null
            }
        } else if (this.ingredients) {
            var value = 0
            this.ingredients.forEach(ingredient => {
                value += ingredient.getNutritionalValue(nutritionalValue) || 0
            })
            return value / this.getServings()
        }
        if (!this.ingredients && this.baseRecipe) return this.baseRecipe.getNutritionalValue(nutritionalValue)
        return null
    }
    
    setNutritionalValue(nutritionalValue: string, value: number) {
        switch (NutritionalValue[nutritionalValue].toString()) {
            case NutritionalValue.calories.toString(): this.calories = value; break;
            case NutritionalValue.carbohydrates.toString(): this.carbohydrates = value; break;
            case NutritionalValue.protein.toString(): this.protein = value; break;
            case NutritionalValue.fat.toString(): this.fat = value; break;
            case NutritionalValue.sugar.toString(): this.sugar = value; break;
            case NutritionalValue.polyols.toString(): this.polyols = value; break;
            case NutritionalValue.fibre.toString(): this.fibre = value; break;
            case NutritionalValue.saturatedFat.toString(): this.saturatedFat = value; break;
            case NutritionalValue.monounsaturatedFat.toString(): this.monounsaturatedFat = value; break;
            case NutritionalValue.polyunsaturatedFat.toString(): this.polyunsaturatedFat = value; break;
            case NutritionalValue.transFat.toString(): this.transFat = value; break;
            case NutritionalValue.omega3Fat.toString(): this.omega3Fat = value; break;
            case NutritionalValue.omega6Fat.toString(): this.omega6Fat = value; break;
            case NutritionalValue.vitaminA.toString(): this.vitaminA = value; break;
            case NutritionalValue.vitaminC.toString(): this.vitaminC = value; break;
            case NutritionalValue.vitaminD.toString(): this.vitaminD = value; break;
            case NutritionalValue.vitaminB1.toString(): this.vitaminB1 = value; break;
            case NutritionalValue.vitaminB2.toString(): this.vitaminB2 = value; break;
            case NutritionalValue.vitaminB3.toString(): this.vitaminB3 = value; break;
            case NutritionalValue.vitaminB5.toString(): this.vitaminB5 = value; break;
            case NutritionalValue.vitaminB6.toString(): this.vitaminB6 = value; break;
            case NutritionalValue.vitaminB7.toString(): this.vitaminB7 = value; break;
            case NutritionalValue.vitaminB9.toString(): this.vitaminB9 = value; break;
            case NutritionalValue.vitaminB12.toString(): this.vitaminB12 = value; break;
            case NutritionalValue.vitaminE.toString(): this.vitaminE = value; break;
            case NutritionalValue.vitaminK.toString(): this.vitaminK = value; break;
            case NutritionalValue.iron.toString(): this.iron = value; break;
            case NutritionalValue.magnesium.toString(): this.magnesium = value; break;
            case NutritionalValue.zinc.toString(): this.zinc = value; break;
            case NutritionalValue.calcium.toString(): this.calcium = value; break;
            case NutritionalValue.sodium.toString(): this.sodium = value; break;
            case NutritionalValue.potassium.toString(): this.potassium = value; break;
            case NutritionalValue.phosphorus.toString(): this.phosphorus = value; break;
            case NutritionalValue.sulphur.toString(): this.sulphur = value; break;
            case NutritionalValue.chlorine.toString(): this.chlorine = value; break;
            case NutritionalValue.copper.toString(): this.copper = value; break;
            case NutritionalValue.manganese.toString(): this.manganese = value; break;
            case NutritionalValue.fluorine.toString(): this.fluorine = value; break;
            case NutritionalValue.iodine.toString(): this.iodine = value; break;
            case NutritionalValue.minerals.toString(): this.minerals = value; break;
            case NutritionalValue.organicAcids.toString(): this.organicAcids = value; break;
            case NutritionalValue.mannitol.toString(): this.mannitol = value; break;
            case NutritionalValue.sorbitol.toString(): this.sorbitol = value; break;
            case NutritionalValue.xylitol.toString(): this.xylitol = value; break;
            case NutritionalValue.glucose.toString(): this.glucose = value; break;
            case NutritionalValue.fructose.toString(): this.fructose = value; break;
            case NutritionalValue.galactose.toString(): this.galactose = value; break;
            case NutritionalValue.sucrose.toString(): this.sucrose = value; break;
            case NutritionalValue.maltose.toString(): this.maltose = value; break;
            case NutritionalValue.lactose.toString(): this.lactose = value; break;
            case NutritionalValue.glycogen.toString(): this.glycogen = value; break;
            case NutritionalValue.starch.toString(): this.starch = value; break;
            case NutritionalValue.monosaccharides.toString(): this.monosaccharides = value; break;
            case NutritionalValue.disaccharides.toString(): this.disaccharides = value; break;
            case NutritionalValue.oligosaccharides.toString(): this.oligosaccharides = value; break;
            case NutritionalValue.polysaccharides.toString(): this.polysaccharides = value; break;
            case NutritionalValue.waterSolubleFibre.toString(): this.waterSolubleFibre = value; break;
            case NutritionalValue.waterInsolubleFibre.toString(): this.waterInsolubleFibre = value; break;
            case NutritionalValue.essentialAminoAcids.toString(): this.essentialAminoAcids = value; break;
            case NutritionalValue.nonessentialAminoAcids.toString(): this.nonessentialAminoAcids = value; break;
            case NutritionalValue.leucine.toString(): this.leucine = value; break;
            case NutritionalValue.isoleucine.toString(): this.isoleucine = value; break;
            case NutritionalValue.valine.toString(): this.valine = value; break;
            case NutritionalValue.uricAcid.toString(): this.uricAcid = value; break;
            case NutritionalValue.purine.toString(): this.purine = value; break;
            case NutritionalValue.linoleicAcid.toString(): this.linoleicAcid = value; break;
            case NutritionalValue.alphaLinolenicAcid.toString(): this.alphaLinolenicAcid = value; break;
            case NutritionalValue.arachidonicAcid.toString(): this.arachidonicAcid = value; break;
            case NutritionalValue.eicosapentaenoicAcid.toString(): this.eicosapentaenoicAcid = value; break;
            case NutritionalValue.docosahexaenoicAcid.toString(): this.docosahexaenoicAcid = value; break;
            case NutritionalValue.cholesterol.toString(): this.cholesterol = value; break;
            case NutritionalValue.salt.toString(): this.salt = value; break;
            case NutritionalValue.alcoholContent.toString(): this.alcoholContent = value; break;
            case NutritionalValue.alcohol.toString(): this.alcohol = value; break;
            case NutritionalValue.water.toString(): this.water = value; break;
            
            default: break
        }
    }

    recalculateNutritionalValues() {
        if (!this.getIngredients()) return
        BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
            var value = 0
            this.getIngredients().forEach(ingredient => {
                value += ingredient.getNutritionalValue(nutritionalValue) || 0
            })
            this.setNutritionalValue(nutritionalValue, value / this.getServings())
        })
    }

    getCarbohydrates() {
        return this.getNutritionalValue('carbohydrates')
    }
    getProtein() {
        return this.getNutritionalValue('protein')
    }
    getFat() {
        return this.getNutritionalValue('fat')
    }
    getCalories() {
        return this.getNutritionalValue('calories')
    }

    getServings() {
        if (this.servings != null && this.servings > 0) return this.servings
        if (this.baseRecipe) return this.baseRecipe.getServings()
        return 1
    }
    matchesTag(tag: Tag) {
        var match = false
        if (tag.isExcluding) {
            match = true
            this.getComputedTags().forEach(t => {
                if (t.printableIdentifier?.toLowerCase() == tag.printableIdentifier?.toLowerCase()) match = false
            })
        } else {
            this.getComputedTags().forEach(t => {
                if (t.printableIdentifier?.toLowerCase() == tag.printableIdentifier?.toLowerCase()) match = true
            })
        }
        return match
    }

    removeTag(tag: Tag) {
        var tags = this.tags
        tags = tags.replace(tag.rawTag, '')
        tags = tags.replace(';;', ';')
        if (tags.startsWith(';')) tags = tags.substr(1, tags.length)
        if (tags.endsWith(';')) tags = tags.substr(0, tags.length)
        if (tags.length == 0) tags = null
        this.tags = tags
        this.computedTags = Tag.tagsFromString(this.getTags())
    }
    
    cloneFromBaseRecipe() {
        var recipe = new Recipe()
        recipe.baseMealTemplateId = this.id
        recipe.baseRecipe = this
        recipe.shared = this.shared
        recipe.assignedUsers = this.assignedUsers
        recipe.assignedGroupNames = this.assignedGroupNames
        BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
            recipe.setNutritionalValue(nutritionalValue, this.getNutritionalValue(nutritionalValue))
        })
        recipe.computedTags = Tag.tagsFromString(this.getTags())
        recipe.ingredients = null
        recipe.modelVersion = 2
        return recipe
    }

    getIngredientById(id: string): Ingredient {
        return this.ingredients.find(i => i.id == id)
    }

    isModifiedBaseRecipe() {
        if (this.ingredients) return true
        if (this.nameTranslation) return true
        if (this.instructionsTranslation) return true
        if (this.servings) return true
        if (this.thumbnailPath) return true
        return false
    }

    clone() {
        var recipe = new Recipe()
        recipe.id = this.id
        recipe.baseMealTemplateId = this.baseMealTemplateId
        recipe.baseRecipe = this.baseRecipe
        recipe.name = this.name
        recipe.nameTranslation = this.nameTranslation?.clone()
        recipe.thumbnailPath = this.thumbnailPath
        recipe.thumbnailUrl = this.thumbnailUrl
        recipe.creatorUid = this.creatorUid
        recipe.tmpId = this.tmpId
        recipe.thumbnailURL = this.thumbnailURL
        recipe.instructions = this.instructions
        recipe.instructionsTranslation = this.instructionsTranslation?.clone()
        recipe.servings = this.servings
        recipe.shared = this.shared
        recipe.assignedUsers = this.assignedUsers
        recipe.assignedGroupNames = this.assignedGroupNames
        recipe.tags = this.tags
        recipe.totalTimeMinutes = this.totalTimeMinutes
        recipe.preparationTimeMinutes = this.preparationTimeMinutes
        recipe.computedTags = Tag.tagsFromString(recipe.getTags())
        recipe.loaded = this.loaded
        recipe.nutritionFactsLoaded = this.nutritionFactsLoaded
        recipe.hasFixedServing = this.hasFixedServing
        recipe.excludeFromAutoFill = this.excludeFromAutoFill
        recipe.fromCommonFirebase = this.fromCommonFirebase
        recipe.modelVersion = this.modelVersion
        recipe.ingredients = this.ingredients?.map(x => x.clone()) ?? null
        recipe.preparationTimeMinutes = this.preparationTimeMinutes
        recipe.totalTimeMinutes = this.totalTimeMinutes
        BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
            recipe.setNutritionalValue(nutritionalValue, this.getNutritionalValue(nutritionalValue))
        })
        if(recipe.nameTranslation == null){
            recipe.nameTranslation = new LanguageDictionary<string>();
        }
        if(recipe.instructionsTranslation == null){
            recipe.instructionsTranslation = new LanguageDictionary<string>();
        }
        return recipe
    }

    merge(baseRecipe: Recipe) {
        this.baseRecipe = baseRecipe
        this.computedTags = Tag.tagsFromString(this.getTags())
    }

    toMap(creatorUid: string) {
        var data = {
            creatorUid: creatorUid, 
            shared: this.shared, 
            assignedUsers: this.assignedUsers, 
            assignedGroupNames: this.assignedGroupNames, 
            creationDate: this.creationDate, 
            hasFixedServing: this.hasFixedServing,
            excludeFromAutoFill: this.excludeFromAutoFill, 
            modelVersion: 2, 
            timestamp: new Date()
        }

        BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
            if (this.getNutritionalValue(nutritionalValue) != null && this.getNutritionalValue(nutritionalValue) != undefined) {
              data[nutritionalValue] = this.getNutritionalValue(nutritionalValue)
            }
        })
        
        if (this.baseMealTemplateId) {
            data['baseMealTemplateId'] = this.baseMealTemplateId
 
            if (this.name != null) data['name'] = this.name
            if (this.nameTranslation) data['nameTranslation'] = this.nameTranslation?.AsMap() || null
            if (this.thumbnailPath != null) data['thumbnailPath'] = this.thumbnailPath
            if (this.thumbnailUrl != null) data['thumbnailUrl'] = this.thumbnailUrl
            if (this.instructionsTranslation?.de != null) data['instructions'] = this.instructionsTranslation?.de
            if (this.instructionsTranslation) {
                data['instructionsTranslation'] = this.instructionsTranslation?.AsMap() || null
                data['instrucionsTranslation'] = this.instructionsTranslation?.AsMap() || null
            }
            if (this.ingredients) data['ingredients'] = this.ingredients?.map(ingredient => ingredient.toMap()) || null
            if (this.servings != null) data['servings'] = this.servings
            if (this.tags != null) data['tags'] = this.tags
            if (this.preparationTimeMinutes != null) data['preparationTimeMinutes'] = this.preparationTimeMinutes
            if (this.totalTimeMinutes != null) data['totalTimeMinutes'] = this.totalTimeMinutes

            return data
        }

        data['name'] = this.name
        data['nameTranslation'] = this.nameTranslation?.AsMap() || null
        data['thumbnailPath'] = this.thumbnailPath
        data['thumbnailUrl'] = this.thumbnailUrl
        data['instructions'] = this.instructionsTranslation?.de
        data['instructionsTranslation'] = this.instructionsTranslation?.AsMap() || null
        data['ingredients'] = this.ingredients?.map(ingredient => ingredient.toMap()) || null
        data['servings'] = this.servings
        data['tags'] = this.tags
        data['preparationTimeMinutes'] = this.preparationTimeMinutes
        data['totalTimeMinutes'] = this.totalTimeMinutes
        
        return data
    }
}