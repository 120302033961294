<div class="row">
    <div class="col-12">
        <div class="layout-box adjust-mobile">
            <h3 class="display-inlineblock" translate>Tägliche Metriken</h3>
            <div class="icon-button marginleft-25" [tooltip]="'Metriken sind Werte, die dein Coachee täglich dokumentieren kann.' | translate">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                </svg>
            </div>
            <div class="icon-button display-inlineblock marginleft-25" (click)="onAddMetric()" translate>
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
                Hinzufügen
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="layout-box sub-box metriclist-wrapper">
                        <!-- <div class="bold">Zugewiesene Metriken</div> -->
                        <div class="row" cdkDropList (cdkDropListDropped)="dropAssignedMetric($event)">
                            <div *ngIf="displayedUser?.assignedMetrics?.length == 0 && !hadAssignedMetrics" class="col-12">
                                <div class="label" translate>
                                    Du hast noch keine Metriken zugewiesen. Standardmäßig wird in der App nur die Körpergewichts-Metrik angezeigt.
                                </div>
                            </div>
                            <div *ngIf="displayedUser?.assignedMetrics?.length == 0 && hadAssignedMetrics" class="col-12">
                                <div class="label" translate>
                                    Weise mindestens eine Metrik zu, um speichern zu können.
                                </div>
                            </div>
                            <ng-container *ngFor="let metric of displayedUser?.assignedMetrics">
                                <div class="col-12" cdkDrag>
                                    <div class="metric-wrapper">
                                        <div class="drag-handle link" cdkDragHandle>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-grip-vertical" viewBox="0 0 16 16">
                                                <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                            </svg>
                                        </div>
                                        <div class="metric-content">
                                            <div class="row">
                                                <div class="col-10 col-sm-8 col-lg-10 col-xl-6">
                                                    <div class="name">{{metric.nameTranslation?.GetValue(translate.currentLang) ?? metric.nameDe}}</div>
                                                    <div class="description">
                                                        {{metric.descriptionDe}} [Typ: {{metric.getPrintableType(translate)}}]
                                                    </div>
                                                </div>
                                                <div class="col-2 col-sm-1 order-sm-3 order-lg-2 order-xl-3">
                                                    <div class="icon-button" (click)="onRemoveAssignedMetric(metric)">
                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-sm-5 order-sm-2 order-lg-3 col-lg-6 order-xl-2 col-xl-5">
                                                    <div *ngIf="metric.isMetricTypeNumber() && metric.targetValue" class="targetvalue-wrapper" tooltip="Stelle auf Tagesziele um.">
                                                        <div class="label" translate>Zielwert (alt)</div>
                                                        <input [ngModel]="metric.targetValue" (input)="onMetricTargetValueChanged($event.target.value, metric)" class="input small white {{metric.hasTargetValueError ? 'invalid' : ''}}" type="text" [placeholder]="'optional' | translate"/>
                                                    </div>
                                                    <div *ngIf="metric.isMetricTypeNumber() || metric.isMetricTypeDuration() || metric.isMetricTypeYesNo() || metric.isMetricTypeToDo()" class="targetvalue-wrapper">
                                                        <button *ngIf="displayedUser.getGoalPlanByMetricId(metric.metricId, GoalPlanType.PROGRESS)" class="icon-button" (click)="openMetricDialog(metric, GoalPlanType.PROGRESS)">
                                                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-bullseye" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                                            <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10m0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12"/>
                                                            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8"/>
                                                            <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                                                          </svg>
                                                          Fortschrittsziel bearbeiten
                                                        </button>
                                                        <button *ngIf="displayedUser.getGoalPlanByMetricId(metric.metricId, GoalPlanType.WEEKLY)" class="icon-button" (click)="openMetricDialog(metric, GoalPlanType.WEEKLY)">
                                                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-bullseye" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                                            <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10m0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12"/>
                                                            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8"/>
                                                            <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                                                          </svg>
                                                          Wochenziel bearbeiten
                                                        </button>
                                                        <button *ngIf="displayedUser.getGoalPlanByMetricId(metric.metricId, GoalPlanType.DAILY)" class="icon-button" (click)="openMetricDialog(metric, GoalPlanType.DAILY)">
                                                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-bullseye" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                                            <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10m0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12"/>
                                                            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8"/>
                                                            <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                                                          </svg>
                                                          Tagesziel bearbeiten
                                                        </button>
                                                        <button *ngIf="!displayedUser.getGoalPlanByMetricId(metric.metricId)" class="icon-button" (click)="openMetricDialog(metric)">
                                                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-bullseye" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                                            <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10m0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12"/>
                                                            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8"/>
                                                            <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                                                          </svg>
                                                          Ziel erstellen
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <button class="button-filled small margin-25" (click)="saveAssignedMetrics()" translate>Speichern</button>
        </div>
    </div>
</div>
