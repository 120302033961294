<div>
    <ng-container *ngFor="let image of images">
        <div class="thumbnail-image link" [ngStyle]="{'background-image':'url('+ image.src +')'}" (click)="onOpenImage(image)">
            <div *ngIf="canEdit" class="remove-box-button" (click)="onDeleteImage(image);$event.stopPropagation()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>
        </div>
    </ng-container>
    <div *ngIf="canEdit" class="thumbnail-image link image-dropzone" (click)="onUploadNewFile()" (dragover)="onDragOver($event)" (drop)="onDropThumbnail($event)">
        <i class="bi bi-plus-circle"></i>
        <input id="file-input" type="file" style="display: none;" accept=".jpg, .png, .jpeg" (change)="uploadThumbnail($event)">
    </div>
</div>