import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { User } from '../model/user.model';
import { FirestoreService } from '../services/firestore.service';
import { Licence } from '../model/lid.model';
import { BroadcastChat, Chat } from '../model/chat.model';
import { NavbarService } from '../services/navbar.service';
import { ChatService } from '../services/chat.service';
import { UtilityService } from '../services/utility.service';
import { InputFieldDialogComponent } from '../inputfield-dialog/inputfield-dialog.component';
import {MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { Coach } from '../model/coach.model';
import { firstValueFrom } from 'rxjs';
import { Metric } from '../model/metric.model';
import { environment } from 'src/environments/environment';
import { FilterObject } from '../filter-selection-dropdown/filter-selection-dropdown.component';
import { ILanguageDictionary, LanguageDictionary } from '../model/languagedictionary.model';
import { AssignedQuestionaire, Questionaire } from '../model/questionaires.model';
import { CompletedQuestionaireResultsDialogComponent } from '../questionaire/completed-questionaire-results-dialog/completed-questionaire-results-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from '../services/payment.service';
import { TrainingHistoryDialogComponent } from '../training-monitoring/training-history-dialog/training-history-dialog.component';
import { TrackedTrainingSession } from '../model/training-monitoring.model';
import { TrainingSession } from '../model/training-plan.model';
import { TrainingService } from '../services/training.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-coach-area',
  templateUrl: './coach-area.component.html',
  styleUrls: ['./coach-area.component.css']
})
export class CoachAreaComponent implements OnInit {

  public environment = environment

  public selectedCoach: Coach
  public coaches: Coach[] = []
  public clients: User[];
  public filteredClients: User[];
  public clientSearchInput: string
  public user: User;
  public chats = [];
  public uid: string;
  public selectedClientGroup: string = null

  public get inListView() {
    return this.user?.portalSettingsCoach?.showClientsInListView ?? false
  }

  constructor(public chatService: ChatService, private authService: AuthService, private router: Router, public userService: FirestoreService, public navService: NavbarService, public utilityService: UtilityService, public dialog: MatDialog, private toastr: ToastrService, private paymentService: PaymentService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.user = this.userService.getLoggedInUser();
    this.selectedCoach = this.userService.getLoggedInUser().coach
    this.uid = this.authService.user.uid;
    if (!this.authService.isLoggedIn) {
      this.router.navigate['login'];
    }
  }

  onToggleListView() {
    if (this.user) this.user.portalSettingsCoach.showClientsInListView = !this.user.portalSettingsCoach.showClientsInListView
    this.userService.updatePortalSettingsForCoach(this.user)
  }
  
  logout() {
    this.authService.logout();
    this.userService.logout();
  }

  getCoach() {
    return this.userService.getLoggedInUser()
  }
}
