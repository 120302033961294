<div class="dialog-topbar">
    <div class="row">
        <div class="col">
            <div class="icon-button" (click)="onSavePayment()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                </svg>
                <span *ngIf="!utilityService.onSmallDisplay()" translate>Erstellen</span>
            </div>
        </div>
        <div class="col">
            <div class="icon-button float-right delete-recipe marginleft-25 marginright-25" (click)="onCancelDialog()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                <span *ngIf="!utilityService.onSmallDisplay()" translate>Abbrechen</span>
            </div>
        </div>
    </div>
</div>
<div class="dialog-content-wrapper">

    <h2 class="" translate>Rechnung erstellen</h2>
    <div class="row">

        <div class="col-12">

            <div class="label" translate>Rechnungsempfänger</div>
            <div class="spacer-10"></div>
            <div class="d-inline-block wrapper-selection">
                <button class="button-filled light small dropdown-toggle margin-inbetween {{assignedCustomer ? '' : 'invalid'}}" type="button" id="dropdownTrigger" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{assignedCustomer?.getName() || ('Empfänger auswählen' | translate)}}</button>
                <div class="dropdown-menu checkin-dropdown" aria-labelledby="dropdownBodyDataGraphTimeRange">
                    <div *ngFor="let user of getAvailableCustomers()" class="dropdown-item link" (click)="onSelectCustomer(user)">
                        <input class="input checkbox" type="checkbox" [checked]="assignedCustomer?.uid == user.uid"><div class="display-inline">{{user.getName()}}</div>
                    </div>
                </div>
            </div>

            <div class="spacer-25"></div>
            <div class="row">
                <div class="col">
                    <label for="input-name" class="label" translate>Name</label>
                    <input id="input-name" required class="input form-control wide" [(ngModel)]="productName" [placeholder]="'Produkt-/Leistungsbezeichnung' | translate" type="text">
                </div>
                <div class="col">
                    <label for="input-price" class="label display-block" translate>Brutto-Gesamtbetrag</label>
                    <input id="input-price" required class="input form-control wide display-inlineblock {{priceInvalid ? 'invalid' : ''}}" [ngModel]="payment.getPrintablePrice()" (input)="onAmountChanged($event.target.value)" placeholder="0"> <span class="display-inlineblock marginleft-10"> {{payment.getPrintableCurrency()}}</span>
                </div>
            </div>
        </div>
    </div>
        
</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "#4AE6D7" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>