import { Component, Input } from '@angular/core';
import { start } from 'repl';
import { TrackedTrainingExercise, TrackedTrainingSession, TrackedTrainingSet, TrackedVideoRecording } from 'src/app/model/training-monitoring.model';
import { Label2SetParameterMapping, PlannedTrainingExercise, SetParameter, SetParameter2LabelMapping, SetParameter2LabelUnitMapping, SetParameter2SubHeadingMapping, SetParameter2UnitMapping, TrainingSet } from 'src/app/model/training-plan.model';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { LanguageService } from 'src/app/services/language.service';
import { VideoRecordingDialogComponent } from '../video-recording-dialog/video-recording-dialog.component';
import { TrainingService } from 'src/app/services/training.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { RpeTableDialogComponent } from 'src/app/training/rpe-table-dialog/rpe-table-dialog.component';
import { UtilityService } from 'src/app/services/utility.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tracked-exercise-table',
  templateUrl: './tracked-exercise-table.component.html',
  styleUrls: ['./tracked-exercise-table.component.css']
})
export class TrackedExerciseTableComponent {

  public environment = environment

  public setParameter = SetParameter;
  public setParameter2LabelMapping = SetParameter2LabelMapping;
  public label2SetParameterMapping = Label2SetParameterMapping;
  public setParameter2UnitMapping = SetParameter2UnitMapping;
  public setParameter2LabelUnitMapping = SetParameter2LabelUnitMapping;
  public setParameter2SubHeadingMapping = SetParameter2SubHeadingMapping;

  public selectedSession: TrackedTrainingSession;

  private _externalSelectedSession: TrackedTrainingSession;
  @Input() set externalSelectedSession(value: TrackedTrainingSession) {
    this.checkOrSetSelectedSession(value);
  }
  public get externalSelectedSession(): TrackedTrainingSession {
    return this._externalSelectedSession;
  }

  private _plannedExercise: PlannedTrainingExercise;
  @Input() set plannedExercise(value: PlannedTrainingExercise) {
    this._plannedExercise = value;
  }
  public get plannedExercise(): PlannedTrainingExercise {
    return this._plannedExercise;
  }

  @Input() user: User;

  @Input() navigationDisabled: boolean = false;

  public trackedTrainingExercise: TrackedTrainingExercise;
  public loading: boolean = false;

  constructor(private userService: FirestoreService, public languageService: LanguageService, public trainingService: TrainingService, private dialog: MatDialog, public translate: TranslateService, public utilityService: UtilityService) {

  }

  checkOrSetSelectedSession(value: TrackedTrainingSession) {
    if (this.selectedSession == null || this._externalSelectedSession == null || this.selectedSession?.id == this._externalSelectedSession?.id) {
      this.selectedSession = value;
      this.updateTrackedExercise();
    }
    this._externalSelectedSession = value;
  }

  updateTrackedExercise() {
    if (this._plannedExercise) {
      this.trackedTrainingExercise = this.selectedSession?.trackedTrainingExercises?.find(tte => tte.plannedExerciseId == this._plannedExercise.id);
    }
    if (this.trackedTrainingExercise == null) {
      this.trackedTrainingExercise = this.selectedSession?.trackedTrainingExercises?.find(tte => tte.exerciseId == this._plannedExercise?.exerciseId || tte.replacedExerciseId == this._plannedExercise?.exerciseId);
    }
  }

  isPartOfDropset(set: TrainingSet, sets: TrainingSet[], setIndex: number) {
    if (set?.isDropset) {
      return true;
    }
    else if (sets?.length > setIndex + 1) {
      return sets[setIndex + 1].isDropset;
    }
  }


  getAvailableSetParameters(trackedExercise: TrackedTrainingExercise, trainingSets: TrackedTrainingSet[]): SetParameter[] {
    return Object.values(SetParameter).filter(parameter => trackedExercise.setParameters.includes(parameter) || this.hasValues(trackedExercise.trackedSets, parameter));
  }
  hasValues(sets: TrackedTrainingSet[], setParameter: SetParameter): boolean {
    return sets.filter(x => x[setParameter.toString()] != null).length > 0
  }

  trackedTrainingSetHasAnyPauseDuration(trainingSets: TrackedTrainingSet[]): boolean {
    return trainingSets.filter(x => x.pauseDuration > 0).length > 0
  }

  getSecondsAsTime(inputSeconds: number): string {
    if (inputSeconds == undefined) return "";
    if (inputSeconds < 0) inputSeconds = 0
    return inputSeconds.asDurationString();
  }

  async onShowTrackedVideoRecording(recording: TrackedVideoRecording) {
    let mergedExercise = this.trainingService.getExerciseById(this.plannedExercise.exerciseId);
    const dialogRef = await this.dialog.open(VideoRecordingDialogComponent, { data: { userUid: this.user.uid, trackedExercise: this.trackedTrainingExercise, recording: recording, exercise: mergedExercise }, width: '1000px', autoFocus: false }).afterClosed().subscribe(result => {
    })
  }

  async jumpToPreviousTrackedExercise() {
    try {
      this.loading = true;
      let session = this.selectedSession;
      let plannedExercise = this.plannedExercise;
      let startDate = this.trackedTrainingExercise?.startDate;
      if (!startDate && this.selectedSession) startDate = this.selectedSession.endDate;
      if (!startDate) startDate = new Date();
      startDate = new Date(startDate.getTime() - 1000);
      let previousExercise = await this.userService.getPreviousTrackedTrainingExercise(this.user, this.plannedExercise.exerciseId, startDate);
      if (previousExercise != null) {
        this.trackedTrainingExercise = previousExercise;
        let trackedSession = await this.userService.getTrackedTrainingSessionByIdWithoutExercises(this.user, previousExercise.trackedSessionId);
        if (trackedSession == null) return;
        this.setPlannendSession(trackedSession);
        this.selectedSession = trackedSession;
      }
    }
    catch (ex) {
      console.error(ex);
    }
    finally {
      this.loading = false;
    }

  }

  setPlannendSession(trackedSession: TrackedTrainingSession) {
    let trainingPlan = this.user.trainingPlans.find(x => x.id == trackedSession.trainingPlanId);
    let plannedSession = trainingPlan.sessions.find(x => x.id == trackedSession.plannedSessionId);

    if (!trackedSession.sessionName && plannedSession) {
      trackedSession.sessionName = plannedSession?.nameTranslation?.GetValue(this.translate.currentLang) ?? plannedSession?.name ?? this.translate.instant("Unbekannte Einheit");
    }

    if (plannedSession?.weekId) {
      let weekIndex = trainingPlan.weeks.findIndex(x => x.id == plannedSession.weekId);
      if (weekIndex >= 0) {
        let plannedWeek = trainingPlan.weeks[weekIndex];
        trackedSession.plannedWeek = plannedWeek;
        trackedSession.plannedWeekIndex = weekIndex;
      }
    }
  }

  async jumpToNextTrackedExercise() {
    try {
      this.loading = true;
      let startDate = this.trackedTrainingExercise?.startDate;
      if (!startDate && this.selectedSession) startDate = this.selectedSession.endDate;
      if (!startDate) {
        startDate = new Date();
        startDate.addDays(-100);
      }
      startDate = new Date(startDate.getTime() + 1000);
      let nextExercise = await this.userService.getNextTrackedTrainingExercise(this.user, this.plannedExercise.exerciseId, startDate);
      if (nextExercise != null) {
        this.trackedTrainingExercise = nextExercise;
        let trackedSession = await this.userService.getTrackedTrainingSessionByIdWithoutExercises(this.user, nextExercise.trackedSessionId);
        this.setPlannendSession(trackedSession);
        this.selectedSession = trackedSession;
      }
    }
    catch (ex) {
      console.error(ex);
    }
    finally {
      this.loading = false;
    }
  }

  onOpenRpeDialog(set: TrainingSet) {
    var rpe = set.rpe
    if (rpe == null && set.rir != null) {
      rpe = 10 - set.rir
    }
    var dialog = this.dialog.open(RpeTableDialogComponent, { data: { user: this.user, weight: set.weight, reps: set.reps, rpe: rpe } });
    dialog.afterClosed().subscribe(result => {
      if (result && result.weight) {
        this.trainingService.rpeTableCopyResult = result
      }
    });
  }
}
