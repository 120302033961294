<div class="dialog-topbar">
    <h3 translate>Email-Adresse ändern</h3>
</div>

<div class="dialog-content-wrapper">
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="label" translate>Alte Email-Adresse</div>
            <div class="spacer-10"></div>
            <input id="client-name" [(ngModel)]="oldEmail" class="input wide {{oldEmail?.length > 0 ? '' : ' invalid'}}" type="text">
            <div class="spacer-25"></div>
        </div>
        <div class="col-12 col-md-6">
            <div class="label" translate>Neue Email-Adresse</div>
            <div class="spacer-10"></div>
            <input id="client-name" [(ngModel)]="newEmail" class="input wide {{newEmail?.length > 0 ? '' : ' invalid'}}" type="text">
            <div class="spacer-25"></div>
        </div>
    </div>

    <div *ngIf="message">{{message}}</div>
    <div *ngIf="loading" class="loading-animation display-inlineblock marginleft-25">
        <mat-spinner class="spinner" diameter="20" strokeWidth="2"></mat-spinner>
    </div>
    <button *ngIf="!loading" class="button-filled small" (click)="onUpdateEmail()" translate>Änderung durchführen</button>

</div>

<mat-dialog-actions>
    <div class="actions">
        <button class="button-filled small light" (click)="onClose()" translate>Schließen</button>
    </div>
</mat-dialog-actions>