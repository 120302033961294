import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Coach } from '../model/coach.model';
import { LicenceHolder } from '../model/licenceholder.model';
import { FirestoreService } from '../services/firestore.service';
import { FirestoreNutritionPlanService } from '../services/firestore-nutritionplan.service';

@Component({
  selector: 'app-create-coach-dialog',
  templateUrl: './create-coach-dialog.component.html',
  styleUrls: ['./create-coach-dialog.component.css']
})
export class CreateCoachDialogComponent implements OnInit {

  public licenceHolders: LicenceHolder[]
  public selectedLicenceHolder: LicenceHolder
  public email: string = ''
  public password: string = ''
  public name: string = ''
  public uid: string

  constructor(public dialogRef: MatDialogRef<CreateCoachDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {licenceHolders: LicenceHolder[], selectedLicenceHolder: LicenceHolder}, private userService: FirestoreService, private spinner: NgxSpinnerService) {
    this.licenceHolders = data.licenceHolders
    this.selectedLicenceHolder = data.selectedLicenceHolder
  }

  ngOnInit(): void {
  }

  onSelectLicenceHolder(licenceholder: LicenceHolder) {
    this.selectedLicenceHolder = licenceholder
  }

  createCoach() {
    this.spinner.show()
    this.email = this.email?.replaceAll(' ', '')
    this.userService.createFirebaseUser(this.email, this.password).subscribe(async result => {
      var mainCoach: Coach = null
      if (result.uid) {
        this.uid = result.uid
        if (this.uid != this.selectedLicenceHolder?.uid && this.selectedLicenceHolder?.uid) {
          mainCoach = await this.getMainCoach(this.selectedLicenceHolder.uid)
        }
        var coach = new Coach()
        coach.uid = this.uid
        coach.licenceHolderUid = this.selectedLicenceHolder.uid
        coach.name = this.name
        coach.hasRecipeDatabaseAccess = true
        coach.trainingEnabled = mainCoach ? mainCoach.trainingEnabled : true
        coach.recipeSharingEnabled = mainCoach ? mainCoach.recipeSharingEnabled : false
        await this.userService.updateCoach(coach)
        this.spinner.hide()
        this.onCloseDialog()
      } else {
        this.userService.getFirebaseUserUid(this.email).subscribe(async result => {
          if (result.uid) {
            this.uid = result.uid
            if (this.uid != this.selectedLicenceHolder?.uid && this.selectedLicenceHolder?.uid) {
              mainCoach = await this.getMainCoach(this.selectedLicenceHolder.uid)
            }
            var coach = new Coach()
            coach.uid = this.uid
            coach.licenceHolderUid = this.selectedLicenceHolder.uid
            coach.name = this.name
            coach.hasRecipeDatabaseAccess = true
            coach.trainingEnabled = mainCoach ? mainCoach.trainingEnabled : true
            coach.recipeSharingEnabled = mainCoach ? mainCoach.recipeSharingEnabled : false
            coach.trainerCode = FirestoreNutritionPlanService.generateUniqueString().toUpperCase()
            await this.userService.updateCoach(coach)
            this.spinner.hide()
            alert('Account existiert bereits mit einem eigenen Passwort. Upgrade zum Coach durchgeführt.')
          } else {
            this.spinner.hide()
            alert('Coach konnte nicht erstellt werden.')
          }
          this.onCloseDialog()
        })
      }
    })
  }

  getMainCoach(licenceHolderUid: string): Promise<Coach> {
    return new Promise((resolve, reject) => {
      var subscription = 
      this.userService.getAllCoachesByLicenceHolderUid(this.selectedLicenceHolder.uid).subscribe(coaches => {
        subscription.unsubscribe()
        var coach = null
        coaches.forEach(c => {
          if (c.uid == licenceHolderUid) coach = c
        })
        if (!coach && coaches.length > 0) {
          coach = coaches[0]
        }
        if (coach) {
          resolve(coach)
        } else {
          reject()
        }
      }, error => {
        subscription.unsubscribe()
        reject()
      })
    })
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close()
  }
}

