import { Component } from '@angular/core';

@Component({
  selector: 'app-kpi-collection',
  templateUrl: './kpi-collection.component.html',
  styleUrls: ['./kpi-collection.component.css']
})
export class KpiCollectionComponent {

}
