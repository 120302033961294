import { TranslateService } from '@ngx-translate/core';
import { ClientComponent } from '../client/client-v1/client-v1.component';
import { Day } from './day.model';
import { NutritionalGoal } from './nutritionalgoal.model';
import { PlannedMealV2 } from './plannedmealv2.model';
import { Recipe } from './recipe.model';
import { Tag } from './tag.model';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

export class NutritionPlanV2 {

    id: string
    name: string
    order: number
    deleted: boolean = false

    mealConfigs: NutritionPlanMealConfig[]
    isRepeating: boolean
    repetitionDuration: number

    connectedSituationId: string

    tagRestrictions: Tag[] = []
    searchInputFocused: boolean = false
    tagSearchResults: Tag[] = []

    // Tmp:
    repeatingMeals: PlannedMealV2[] = null

    constructor();
    constructor(init: NutritionPlanV2);
    constructor(init?: NutritionPlanV2) {
        this.id = init && init.id || null
        this.name = init && init.name || null
        this.order = init && init.order || 0
        this.isRepeating = init && init.isRepeating || false
        this.repetitionDuration = init && init.repetitionDuration || 0
        this.deleted = init && init.deleted || false
        this.mealConfigs = []
        this.connectedSituationId = init && init.connectedSituationId || null
    }

    getName(translate: TranslateService) {
        return this.name || translate.instant(marker('Plan'))
    }

    hasRestriction(tag: Tag, translate: TranslateService) {
        var has = false
        this.tagRestrictions.forEach(t => {
            if (tag.getPrintableName(translate) == t.getPrintableName(translate)) has = true
        })
        return has
    }

    clone(): NutritionPlanV2 {
        var plan = new NutritionPlanV2(this)
        plan.mealConfigs = []
        this.mealConfigs.forEach(e => {
            plan.mealConfigs.push(e.clone())
        })
        if (this.repeatingMeals) plan.repeatingMeals = []
        this.repeatingMeals?.forEach(e => {
            plan.repeatingMeals.push(e.clone())
        })
        plan.tagRestrictions = []
        this.tagRestrictions.forEach(tag => {
            plan.tagRestrictions.push(tag)
        })
        return plan
    }

    static defaultNutritionPlan(order: number) {
        var plan = new NutritionPlanV2()
        plan.order = order
        plan.mealConfigs = []
        var mealConfig = new NutritionPlanMealConfig()
        mealConfig.position = 0
        mealConfig.mealType = MealType.breakfast
        mealConfig.tagRestrictions = MealTypeDefaultTags[MealType.breakfast]
        plan.mealConfigs.push(mealConfig)
        var mealConfig = new NutritionPlanMealConfig()
        mealConfig.position = 1
        mealConfig.mealType = MealType.lunch
        plan.mealConfigs.push(mealConfig)
        var mealConfig = new NutritionPlanMealConfig()
        mealConfig.position = 2
        mealConfig.mealType = MealType.dinner
        plan.mealConfigs.push(mealConfig)
        return plan
    }
}


export class NutritionPlanMealConfig {
    id: string
    mealType: MealType
    customMealType: string
    position: number

    autoFillEnabled: boolean = true
    autoFillAlternatives: number = 0
    tagRestrictions: Tag[] = []
    mealSizeProportion: number = null

    // Tmp:
    matchingRecipes: Recipe[] = []
    searchInputFocused: boolean = false
    tagSearchResults: Tag[] = []

    constructor();
    constructor(init: NutritionPlanMealConfig);
    constructor(init?: NutritionPlanMealConfig) {
        this.id = init && init.id || null
        this.mealType = init && init.mealType || null
        this.customMealType = init && init.customMealType || null
        this.position = init && init.position || 0
        this.mealSizeProportion = (init && init.mealSizeProportion != null) ? init.mealSizeProportion : null

        this.autoFillEnabled = init && init.autoFillEnabled || true
        this.autoFillAlternatives = init && init.autoFillAlternatives || 0
    }

    getType(translate: TranslateService): string {
        if (this.mealType && this.mealType != MealType.custom) {
            return translate.instant(MealTypeTranslation[this.mealType])
        }
        return this.customMealType
    }

    hasRestriction(tag: Tag, translate: TranslateService) {
        var has = false
        this.tagRestrictions.forEach(t => {
            if (tag.getPrintableName(translate) == t.getPrintableName(translate)) has = true
        })
        return has
    }
    
    clone(): NutritionPlanMealConfig {
        var config = new NutritionPlanMealConfig(this)
        config.tagRestrictions = []
        this.tagRestrictions.forEach(tag => {
            config.tagRestrictions.push(tag)
        })
        return config
    }
}

export enum MealType {
    none = '',
    breakfast = 'BREAKFAST',
    lunch = 'LUNCH',
    dinner = 'DINNER',
    snack = 'SNACK',
    preworkout = 'PREWORKOUT',
    postworkout = 'POSTWORKOUT',
    supplements = 'SUPPLEMENTS',
    custom = 'CUSTOM',
}
export const MealTypeTranslation: Record<MealType, string> = {
    [MealType.none]: marker("Typ auswählen"),
    [MealType.breakfast]: marker("Frühstück"),
    [MealType.lunch]: marker("Mittagessen"),
    [MealType.dinner]: marker("Abendessen"),
    [MealType.snack]: marker("Snack"),
    [MealType.preworkout]: marker("Pre-Workout"),
    [MealType.postworkout]: marker("Post-Workout"),
    [MealType.supplements]: marker("Supplements"),
    [MealType.custom]: marker("Eigener"),
}
export const MealTypeDefaultTags: Record<MealType, Tag[]> = {
    [MealType.none]: [],
    [MealType.breakfast]: [Tag.tagFromString('BREAKFAST')],
    [MealType.lunch]: [],
    [MealType.dinner]: [],
    [MealType.snack]: [],
    [MealType.preworkout]: [],
    [MealType.postworkout]: [],
    [MealType.supplements]: [],
    [MealType.custom]: [],
}
export const StringToMealType: Record<string, MealType> = {
    "": MealType.none,
    "BREAKFAST": MealType.breakfast,
    "LUNCH": MealType.lunch,
    "DINNER": MealType.dinner,
    "SNACK": MealType.snack,
    "PREWORKOUT": MealType.preworkout,
    "POSTWORKOUT": MealType.postworkout,
    "SUPPLEMENTS": MealType.supplements,
    "CUSTOM": MealType.custom,
}