import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[autofocus]'
})
export class AutofocusDirective {

  constructor(private host: ElementRef) {}

  private focus = true;

  ngAfterViewInit() {
    if(this.focus) {
      this.host.nativeElement.focus();
    }
  }

  @Input() set autofocus(condition: boolean)
  {
    this.focus = condition !== false;
  }
}
