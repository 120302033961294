<div *ngIf="user">
    <div *ngIf="loadingStatisticsForDateRange || user?.currentlyLoadingNutritionStatistics || user?.currentlyLoadingBodyDataStatistics" class="float-right">
        <mat-spinner class="loading-statistics-spinner" diameter="30" strokeWidth="2"></mat-spinner>
    </div>
    <h3 translate>Ernährungsstatistik</h3>
    <div class="d-inline-block wrapper-statisticstype-selection">
        <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownStatisticsGraphType" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{selectedStatisticsGraphType.name}}</button>
        <div class="dropdown-menu" aria-labelledby="dropdownStatisticsGraphType">
            <div *ngFor="let type of statisticsGraphTypes">
                <button class="dropdown-item" type="button" (click)="onStatisticsGraphTypeChanged(type)">{{type.name}}</button>
            </div>
        </div>
    </div>
    <div class="d-inline-block nutrition-info-tooltip" *ngIf="selectedStatisticsGraphType?.id == 2"><svg tooltip='Bitte beachte, dass die Nährwertangaben möglicherweise unvollständig sind. Es wird empfohlen, vorzugsweise die mit dem Hinweis "DETAIL" markierten Lebensmittel zu verwenden, um sicherzustellen, dass alle Nährwerte korrekt erfasst werden.' _ngcontent-tdr-c10="" class="bi bi-info-circle" fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-tdr-c10="" d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path _ngcontent-tdr-c10="" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path></svg></div>
    <div class="d-inline-block wrapper-timerange-selection">
        <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownStatisticsGraphTimeRange" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{selectedStatisticsGraphTimeRange.name}}</button>
        <div class="dropdown-menu" aria-labelledby="dropdownStatisticsGraphTimeRange">
            <div *ngFor="let range of statisticsGraphTimeRanges">
                <button class="dropdown-item" type="button" (click)="onStatisticsGraphTimeRangeChanged(range)">{{range.name}}</button>
            </div>
        </div>
    </div>
    <div class="d-inline-block" *ngIf="selectedStatisticsGraphTimeRange.id == 3">
        <div class="display-inlineblock">
            <span translate>Von:</span> 
            <span class="marginleft-5">{{selectedStatisticsStartDate?.asFormatedString()}}</span>
            <input [disabled]="loadingStatisticsForDateRange" [matDatepicker]="startDatePicker" class="invisible-datetime-input" [value]="selectedStatisticsStartDate" [matDatepickerFilter]="startDateFilterStatisticsData" class="visibility-hidden width-0 padding-0" (dateInput)="onStatisticsStartDateChanged($event.value?.toDate())">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker ></mat-datepicker><br>
        </div>
        <div class="display-inlineblock">
            <span translate>Bis:</span> 
            <span class="marginleft-5">{{selectedStatisticsEndDate?.asFormatedString()}}</span>
            <input [disabled]="loadingStatisticsForDateRange" [matDatepicker]="endDatePicker" class="invisible-datetime-input" [value]="selectedStatisticsEndDate" [matDatepickerFilter]="endDateFilterStatisticsData" class="visibility-hidden width-0 padding-0" (dateInput)="onStatisticsEndDateChanged($event.value?.toDate())">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker ></mat-datepicker>
        </div>
    </div>
    <dx-chart *ngIf="nutritionStatisticsGraphData"
        title=""
        [dataSource]="nutritionStatisticsGraphData">
        
        <dxi-series *ngIf="selectedStatisticsGraphType?.id == 0" valueField="calories" name="Kalorien" color="var(--accentColor)"></dxi-series>
        <dxi-series *ngIf="selectedStatisticsGraphType?.id == 0" valueField="calorieDeficit" name="Differenz" color="var(--accentColorLight)"></dxi-series>
        <dxi-series *ngIf="selectedStatisticsGraphType?.id == 0" valueField="calorieGoal" name="Kalorienziel" color="var(--accentColor)"></dxi-series>
        <dxi-series *ngIf="selectedStatisticsGraphType?.id == 0" valueField="calorieSurplus" name="Überschuss" color="var(--accentColorDark)"></dxi-series>
        <ng-container *ngFor="let graphItem of nutritionStatisticsGraphParams; let i = index;">
            <dxi-series *ngIf="graphItem.isSelected == true && selectedStatisticsGraphType?.id == 1 && graphItem.graphTypeId == 1 && graphItem.style != 'goal'" color="{{graphItem.color}}" valueField="{{graphItem.valueField}}" name="{{graphItem.name}}" type="spline" axis="{{graphItem.unit == 'kcal' ? 'kcalAxis' : 'gAxis'}}">
            </dxi-series>
            <dxi-series *ngIf="graphItem.isSelected == true && selectedStatisticsGraphType?.id == 1 && graphItem.graphTypeId == 1 && graphItem.style == 'goal'" color="{{graphItem.color}}" valueField="{{graphItem.valueField}}" name="{{graphItem.name}}" type="spline" dashStyle="dash" axis="{{graphItem.unit == 'kcal' ? 'kcalAxis' : 'gAxis'}}">
                <dxo-point [visible]="false"></dxo-point>
            </dxi-series>
        </ng-container>
        <ng-container *ngFor="let graphItem of nutritionStatisticsGraphParams; let i = index;">
            <dxi-series *ngIf="graphItem.isSelected == true && selectedStatisticsGraphType?.id == 2 && graphItem.graphTypeId == 2 && graphItem.style != 'goal'" color="{{graphItem.color}}" valueField="{{graphItem.valueField}}" name="{{graphItem.name}}" type="spline" axis="{{graphItem.unit == 'mg' ? 'mgAxis' : 'gAxis'}}">
            </dxi-series>
            <dxi-series *ngIf="graphItem.isSelected == true && selectedStatisticsGraphType?.id == 2 && graphItem.graphTypeId == 2 && graphItem.style == 'goal'" color="{{graphItem.color}}" valueField="{{graphItem.valueField}}" name="{{graphItem.name}}" type="spline" dashStyle="dash" axis="{{graphItem.unit == 'mg' ? 'mgAxis' : 'gAxis'}}">
                <dxo-point [visible]="false"></dxo-point>
            </dxi-series>
        </ng-container>
        <!-- <dxi-value-axis name="kcalAxis" *ngIf="selectedStatisticsGraphType?.id == 0">
            <dxo-title text="kcal"></dxo-title>
        </dxi-value-axis> -->
        <!-- <dxi-value-axis position="left" name="gAxis">
            <dxo-title text="g"></dxo-title>
        </dxi-value-axis>
        <dxi-value-axis position="right" name="mgAxis">
            <dxo-title text="mg"></dxo-title>
        </dxi-value-axis> -->
        <ng-container *ngFor="let axis of statisticsGraphAxis; let i = index">
            <dxi-value-axis name="{{axis.name}}" [visualRange]="axis.visualRange" [tickInterval]="axis.tickInterval || undefined" [visible]="i <= 1" [position]="i == 0 ? 'left' : 'right'">
                <dxo-title text="{{axis.title}}"></dxo-title>
            </dxi-value-axis>
        </ng-container>
        <dxo-common-series-settings
            argumentField="date"
            type="stackedBar">
        </dxo-common-series-settings>
        <dxo-argument-axis [allowDecimals]="false">
            <dxo-tick-interval [days]="1"></dxo-tick-interval>
            <dxo-label>
                <dxo-format type="dd. MMM"></dxo-format>
            </dxo-label>
        </dxo-argument-axis>
        <dxo-legend [visible]="false"
            verticalAlignment="{{utilityService.onMobile() ? 'bottom' : 'center'}}"
            horizontalAlignment="{{utilityService.onMobile() ? 'center' : 'right'}}"
            itemTextPosition="top">
        </dxo-legend>
        <dxo-export [enabled]="false"></dxo-export>
        <dxo-tooltip [enabled]="true" [shared]="true" position="top" [customizeTooltip]="customizeStatisticsGraphTooltip"></dxo-tooltip>
    </dx-chart>
    <div *ngIf="nutritionStatisticsGraphData && selectedStatisticsGraphType?.id == 0" id="weeklyreport-information">
        <b translate>Kalorienaufnahme: </b>
        <div>{{weeklyReportCalorieIntake.roundToPlaces(0)}} / {{weeklyReportCalorieGoal.roundToPlaces(0)}} {{'kcal' | translate}}, {{((weeklyReportCalorieIntake - weeklyReportCalorieGoal) * -1).roundToPlaces(0)}} {{'kcal' | translate}} <span translate>übrig</span></div>
        <b translate>∅ tägliche Abweichung:</b>
        <div>{{weeklyReportAbsoluteDeviation.toFixed(0)}} {{'kcal' | translate}} ({{weeklyReportDeviationPercentage.toFixed(0)}}%)</div>
    </div>
    <div class="row nutrition-values-selection" *ngIf="selectedStatisticsGraphType?.id == 1">
        <div class="spacer-10"></div>
        <div class="row col-12">
            <div class="col">
                <div *ngFor="let graphItem of nutritionStatisticsGraphParams" class="d-inline-block">
                    <button *ngIf="graphItem.style != 'goal' && graphItem.valueGroup == 0" [style.background-color]="graphItem.isSelected ? graphItem.color : 'var(--darkerBackgroundColor)'" class="tab-button" (click)="onNutritionStatisticsGraphParamsClicked(graphItem)">
                        {{graphItem.name}} ({{graphItem.unit}})
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row nutrition-values-selection" *ngIf="selectedStatisticsGraphType?.id == 2">
        <div class="spacer-10"></div>
        <div class="row col-12">
            <div class="col-sm-12 col-md-3 col-lg-2">
                <span class="bold" [innerHTML]="'Nährstoff<wbr>details:' | translate"></span>
            </div>
            <div class="col">
                <div *ngFor="let graphItem of nutritionStatisticsGraphParams" class="d-inline-block">
                    <button *ngIf="graphItem.style != 'goal' && graphItem.valueGroup == 1" [style.background-color]="graphItem.isSelected ? graphItem.color : 'var(--darkerBackgroundColor)'" class="tab-button" (click)="onNutritionStatisticsGraphParamsClicked(graphItem)">
                        {{graphItem.name}} ({{graphItem.unit}})
                    </button>
                </div>
            </div>
        </div>
        <div class="spacer-10"></div>
        <div class="row col-12">
            <div class="col-sm-12 col-md-3 col-lg-2">
                <span class="bold" [innerHTML]="'Kohlenhydrat<wbr>zusammen<wbr>setzung:' | translate"></span>
            </div>
            <div class="col">
                <div *ngFor="let graphItem of nutritionStatisticsGraphParams" class="d-inline-block">
                    <button *ngIf="graphItem.style != 'goal' && graphItem.valueGroup == 2" [style.background-color]="graphItem.isSelected ? graphItem.color : 'var(--darkerBackgroundColor)'" class="tab-button" (click)="onNutritionStatisticsGraphParamsClicked(graphItem)">
                        {{graphItem.name}} ({{graphItem.unit}})
                    </button>
                </div>
            </div>
        </div>
        <div class="spacer-10"></div>
        <div class="row col-12">
            <div class="col-sm-12 col-md-3 col-lg-2">
                <span class="bold" translate>Vitamine:</span>
            </div>
            <div class="col">
                <div *ngFor="let graphItem of nutritionStatisticsGraphParams" class="d-inline-block">
                    <button *ngIf="graphItem.style != 'goal' && graphItem.valueGroup == 3" [style.background-color]="graphItem.isSelected ? graphItem.color : 'var(--darkerBackgroundColor)'" class="tab-button" (click)="onNutritionStatisticsGraphParamsClicked(graphItem)">
                        {{graphItem.name}} ({{graphItem.unit}})
                    </button>
                </div>
            </div>
        </div>
        <div class="spacer-10"></div>
        <div class="row col-12">
            <div class="col-sm-12 col-md-3 col-lg-2">
                <span class="bold" [innerHTML]="'Mineralstoffe und Spuren<wbr>elemente:' | translate"></span>
            </div>
            <div class="col">
                <div *ngFor="let graphItem of nutritionStatisticsGraphParams" class="d-inline-block">
                    <button *ngIf="graphItem.style != 'goal' && graphItem.valueGroup == 4" [style.background-color]="graphItem.isSelected ? graphItem.color : 'var(--darkerBackgroundColor)'" class="tab-button" (click)="onNutritionStatisticsGraphParamsClicked(graphItem)">
                        {{graphItem.name}} ({{graphItem.unit}})
                    </button>
                </div>
            </div>
        </div>
        <div class="spacer-10"></div>
        <div class="row col-12">
            <div class="col-sm-12 col-md-3 col-lg-2">
                <span class="bold" translate>Aminosäuren:</span>
            </div>
            <div class="col">
                <div *ngFor="let graphItem of nutritionStatisticsGraphParams" class="d-inline-block">
                    <button *ngIf="graphItem.style != 'goal' && graphItem.valueGroup == 5" [style.background-color]="graphItem.isSelected ? graphItem.color : 'var(--darkerBackgroundColor)'" class="tab-button" (click)="onNutritionStatisticsGraphParamsClicked(graphItem)">
                        {{graphItem.name}} ({{graphItem.unit}})
                    </button>
                </div>
            </div>
        </div>
        <div class="spacer-10"></div>
        <div class="row col-12">
            <div class="col-sm-12 col-md-3 col-lg-2">
                <span class="bold" [innerHTML]="'Fett<wbr>zusammen<wbr>setzung:' | translate"></span>
            </div>
            <div class="col">
                <div *ngFor="let graphItem of nutritionStatisticsGraphParams" class="d-inline-block">
                    <button *ngIf="graphItem.style != 'goal' && graphItem.valueGroup == 6" [style.background-color]="graphItem.isSelected ? graphItem.color : 'var(--darkerBackgroundColor)'" class="tab-button" (click)="onNutritionStatisticsGraphParamsClicked(graphItem)">
                        {{graphItem.name}} ({{graphItem.unit}})
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!utilityService.onNativeMobileApp() && (selectedStatisticsGraphType?.id == 2 || selectedStatisticsGraphType.id == 1)" class="toggle-wrapper display-inlineblock marginright-25">
        <div class="toggle {{showMicrosDataTable ? 'on' : ''}}" (click)="onShowMicrosDataTableChanged(!showMicrosDataTable)">
            <div class="handle"></div>
        </div>
        <div class="text" translate>
            Daten tabellarisch anzeigen
        </div>
    </div>
    <div *ngIf="!utilityService.onNativeMobileApp() && showMicrosDataTable && (selectedStatisticsGraphType?.id == 2 || selectedStatisticsGraphType.id == 1)" class="toggle-wrapper display-inlineblock marginright-25">
        <div class="toggle {{showMicrosDataTableGoals ? 'on' : ''}}" (click)="onShowMicrosDataTableGoalsChanged(!showMicrosDataTableGoals)">
            <div class="handle"></div>
        </div>
        <div class="text">
            Zielspalten anzeigen
        </div>
    </div>
    <dx-data-grid *ngIf="showMicrosDataTable && canShowMicrosDataTable && selectedStatisticsGraphType?.id > 0" id="progressStatisticsTable"
        [dataSource]="nutritionStatisticsGraphData"
        [allowColumnReordering]="true"
        [allowColumnResizing]="false"
        [showBorders]="true"
        [showRowLines]="true"
        [columnAutoWidth]="true"
        [columnMinWidth]="100"
        [selectedRowKeys]="[]"
        [height]="750"
        (onSelectionChanged)="onSelectionChanged($event)" (onExporting)="chartExportService.onExporting($event)">

        <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
        <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
        <dxo-selection mode="multiple" [allowSelectAll]="false"></dxo-selection>

        <dxi-column dataField="dateRendered" [caption]="'Datum' | translate" [width]="75" [fixed]="true" [showInColumnChooser]="true" [visible]="isNutritionTableDateColumnVisible" (visibleChange)="nutritionTableColumnVisibilityChanged($event, 'date')"></dxi-column>
        <dxi-column dataField="weekNumber" [caption]="'KW' | translate" [groupIndex]="0"></dxi-column>

        <ng-container *ngFor="let graphItem of nutritionStatisticsGraphParams">
            <ng-container *ngIf="graphItem.style == 'goal'; else trackedValueColumn;">
                <ng-container *ngIf="showMicrosDataTableGoals">
                    <dxi-column [showInColumnChooser]="true" *ngIf="graphItem.isSelected && selectedStatisticsGraphType?.id == graphItem.graphTypeId" dataField="{{graphItem.valueField}}" caption="{{graphItem.name}} ({{graphItem.unit}}) {{graphItem.isCoachGoal}}" [showEditorAlways]="false" [visible]="graphItem.visible" (visibleChange)="nutritionTableColumnVisibilityChanged($event, graphItem?.valueField)"></dxi-column>
                </ng-container>
            </ng-container>
            <ng-template #trackedValueColumn>
                <dxi-column [showInColumnChooser]="true" *ngIf="graphItem.isSelected && selectedStatisticsGraphType?.id == graphItem.graphTypeId" dataField="{{graphItem.valueField + 'Rendered'}}" caption="{{graphItem.name}} ({{graphItem.unit}})" [showEditorAlways]="false" [visible]="graphItem.visible" (visibleChange)="nutritionTableColumnVisibilityChanged($event, graphItem?.valueField)"></dxi-column>
            </ng-template>
        </ng-container>

        <dxo-summary [calculateCustomSummary]="calculateCustomSummaryRow">
            <ng-container *ngFor="let graphItem of nutritionStatisticsGraphParams">
                <ng-container *ngIf="graphItem.style == 'goal'; else trackedValueSummary;">
                    <ng-container *ngIf="showMicrosDataTableGoals">
                        <dxi-group-item *ngIf="graphItem.isSelected && selectedStatisticsGraphType?.id == graphItem.graphTypeId" showInColumn="{{graphItem.valueField}}" summaryType="custom" name="{{graphItem.valueField}}" [customizeText]="customizeGroupSummaryText" [alignByColumn]="true"></dxi-group-item>
                    </ng-container>
                </ng-container>
                <ng-template #trackedValueSummary>
                    <dxi-group-item *ngIf="graphItem.isSelected && selectedStatisticsGraphType?.id == graphItem.graphTypeId" showInColumn="{{graphItem.valueField + 'Rendered'}}" summaryType="custom" name="{{graphItem.valueField + 'Rendered'}}" [customizeText]="customizeGroupSummaryText" [alignByColumn]="true"></dxi-group-item>
                </ng-template>
            </ng-container>
        </dxo-summary>
        <dxo-export [enabled]="true"></dxo-export>

    </dx-data-grid>
    <div *ngIf="showMicrosDataTable && !canShowMicrosDataTable && (selectedStatisticsGraphType?.id == 2 || selectedStatisticsGraphType?.id == 1)" class="margin-10" translate>
        Tabellarische Ansicht für diesen Zeitraum nicht verfügbar.
    </div>
</div>