<div class="row">
    <div class="col-12 col-lg-5">
        <div *ngIf="utilityService.onNativeMobileApp()" class="layout-box" translate>
            <h3 class="display-inlineblock" translate>Abonnement</h3><br>
            Rufe die Coaching Zone im Browser auf und melde dich an, um auf einen Account zuzugreifen.
        </div>
        <div *ngIf="!utilityService.onNativeMobileApp()" class="layout-box" translate>
            <h3 class="display-inlineblock" translate>Abonnement</h3>
            <a *ngIf="stripeCustomerPortalLink && subscription" href="{{stripeCustomerPortalLink}}" target="_blank" class="display-inlineblock marginleft-25">
                <button class="button-filled small" translate>
                    Rechnungsportal aufrufen
                </button>
            </a>
            <br>
            <div *ngIf="user.licenceHolder?.onTrial" class="bold" translate>Du bist gerade in der nutrilize for Coaches Probephase.</div>
            <br>
            <div *ngIf="!stripeCustomerPortalLink" class="loading-animation">
                <mat-spinner class="spinner" diameter="30" strokeWidth="2"></mat-spinner>
                <div class="textalign-center label" translate><br>Dies kann einen Moment dauern...</div>
            </div>
            <a *ngIf="stripeCheckoutSessionLink && !subscription" href="{{stripeCheckoutSessionLink}}" target="_blank" class="display-inlineblock">
                <div class="layout-box sub-box turquoise create-subscription">
                    <img draggable="false" class="logo" src="assets/images/nutrilize_for_Coaches_Logotype.png">
                    <div class="spacer-25"></div>
                    <div class="label color-black" translate>
                        Das Abonnement nutrilize for Coaches umfasst den Zugang zur Coaching-Software sowie die Möglichkeit, App-Lizenzen zur Nutzung der nutrilize-App zu verteilen. Die Abrechnung erfolgt monatlich und nutzungsbasiert entsprechend der unten verlinkten Preisübersicht.<br>
                        <span *ngIf="user.licenceHolder?.preStarterPackageClient" translate>Das Starter-Paket entfällt für dich als Bestandskund:in.<br></span>
                        Es gelten unsere <a href="https://nutrilize.app/agb/" target="_blank" translate>Allgemeinen Geschäftsbedingungen.</a>
                    </div>
                    <div class="spacer-25"></div>
                    <button class="button-filled light small" translate>
                        Abonnement erstellen
                    </button>
                </div>
            </a>
            <div *ngIf="subscription" class="layout-box sub-box turquoise create-subscription">
                <img draggable="false" class="logo" src="assets/images/nutrilize_for_Coaches_Logotype.png">
                <div class="spacer-25"></div>
                <div class="label color-black" translate>
                    Das Abonnement nutrilize for Coaches umfasst den Zugang zur Coaching-Software sowie die Möglichkeit, App-Lizenzen zur Nutzung der nutrilize-App zu verteilen. Die Abrechnung erfolgt monatlich und nutzungsbasiert entsprechend der unten verlinkten Preisübersicht.<br>
                    Es gelten unsere <a href="https://nutrilize.app/agb/" target="_blank" translate>Allgemeinen Geschäftsbedingungen.</a>
                </div>
                <div class="spacer-25"></div>
                <h3 translate>Du hast nutrilize for Coaches abonniert.</h3>
            </div>
            <div *ngIf="canAccessTraining()" class="layout-box sub-box turquoise create-subscription">
                <div class="heading" translate>Trainingsmodul</div>
                <div class="spacer-25"></div>
                <div class="label color-black" translate>
                    Aktiviere das Trainingsmodul für deinen Account und deine Coachees.
                </div>
                <div class="spacer-25"></div>
                <div class="training-toggle toggle-wrapper">
                    <div class="toggle {{user?.isTrainingEnabled() ? 'on' : ''}}" (click)="onTrainingEnabledChanged(!user?.isTrainingEnabled())">
                        <div class="handle"></div>
                    </div>
                </div>
            </div>
            
            <div class="spacer-25"></div>
            
            <br>
            <a [href]="'https://drive.google.com/file/d/1xK3O7UwfIxYOchL94qst5kKhtFqWU8R-/view?usp=drive_link' | translate" target="_blank" class="pricing-button">
                <div class="icon-button" translate>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="padding-4 bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                        <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"/>
                    </svg>
                    Preisübersicht ansehen
                </div>
            </a>
            <div class="spacer-10"></div>
            <div class="pricing-button marginleft-25">
                <div class="icon-button" (click)="openMarketingKit()" translate>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="padding-4 bi bi-briefcase" viewBox="0 0 16 16">
                        <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z"/>
                    </svg>
                    Marketing-Kit öffnen
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-7" *ngIf="hideInvoices && isAdmin()">
        <div class="spacer-25"></div>
        <div class="icon-button" (click)="onShowInvoices()" translate>
            Admin-Modus
        </div>
    </div>
    <div class="col-12 col-lg-7" *ngIf="coachInvoices?.length > 0 && !hideInvoices">
        <div class="layout-box" translate>
            <h3 class="display-inlineblock" translate>Zahlungshistorie</h3>

            <div class="paging-buttons display-inlineblock float-right">
                <button class="button change-page p-0" *ngIf="listViewPage > 0" (click)="goToPreviousPage()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                    </svg>
                </button>
                <div class="current-page">Seite {{listViewPage + 1}} von {{maxPage + 1}}</div>
                <button class="button change-page p-0" *ngIf="listViewPage < maxPage" (click)="goToNextPage()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </button>
            </div>

            <ng-container *ngIf="!isAdmin() && hasFailedPayments()">
                <div class="layout-box sub-box alert-box" translate>
                    <div class="bold" translate>Zahlung fehlgeschlagen</div>
                    Du hast fehlgeschlagene Zahlungen. Bitte klicke bei den entsprechenden Zahlungen in der Tabelle auf "Überweisen" und folge den Anweisungen. Nach erfolgreicher Überweisung wird die Zahlung als "Bezahlt" markiert.
                </div>
                <div class="spacer-10"></div>
            </ng-container>

            <div class="table payments-table">
                <div class="row table-item table-header">
                    <div class="col" translate>
                        Datum
                    </div>
                    <div class="col" translate>
                        Betrag
                    </div>
                    <div class="col" translate>
                        Status
                        <app-filter-selection-dropdown class="marginleft-10" [availableFilterObjects]="availableStatuses" [selectedLanguage]="translate.currentLang"></app-filter-selection-dropdown>
                    </div>
                    <div *ngIf="isAdmin()" class="col" translate>
                        Kunde
                    </div>
                    <div class="col" translate>
                        Rechnung
                    </div>
                    <div *ngIf="isAdmin()" class="col" translate>
                        Aktionen
                    </div>
                </div>
    
                <div class="row table-item link" *ngFor="let payment of getCoachInvoices()">
                    <div class="col">
                        {{payment.date.asFormatedString()}}
                    </div>
                    <div class="col">
                        {{payment.getPrintablePriceWithCurrency()}}
                    </div>
                    <div class="col">
                        <div *ngIf="payment.getPrintableStatus(translate)" class="status-indicator-wrapper">
                            <div class="status-indicator {{payment.status}}"></div>
                            {{payment.getPrintableStatus(translate)}}
                        </div>
                        <button *ngIf="payment.status == 'failed'" class="button-filled light small margin-inbetween" (click)="onShowBankTransferDialog(payment)" translate>
                            Überweisen
                        </button>
                    </div>
                    <div *ngIf="isAdmin()" class="col">
                        {{payment.getClientName()}}
                    </div>
                    <div class="col">
                        {{payment.invoiceNumber}}
                    </div>
                    <div *ngIf="isAdmin()" class="col">
                        <div *ngIf="payment.status == 'failed'" class="icon-button small margin-inbetween marginright-10" (click)="onDeleteInvoice(payment)" translate>
                            Löschen
                        </div>
                        <div class="icon-button small margin-inbetween" (click)="onMarkInvoiceAsPaid(payment)" translate>
                            Bezahlt
                        </div>
                    </div>
                </div>

            </div>
            <div class="spacer-10"></div>
            Ältere Zahlungen und die Rechnungs-PDFs findest du im Rechnungsportal.
        </div>
    </div>
</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "#4AE6D7" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>