import { Component, Input, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { PaymentSettings } from 'src/app/model/payment-settings.model';
import { ProductPurchase } from 'src/app/model/product-purchase.model';
import { Product } from 'src/app/model/product.model';
import { User } from 'src/app/model/user.model';
import { ChatService } from 'src/app/services/chat.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { environment } from 'src/environments/environment';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';
import { PaymentService } from 'src/app/services/payment.service';
import { PaymentComponent } from '../payment/payment.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-checkout-view',
  templateUrl: './checkout-view.component.html',
  styleUrls: ['./checkout-view.component.css']
})
export class CheckoutViewComponent {

  @Input() set setProduct(value: Product) {
    this.product = value
  }
  @Input() set setProductPurchase(value: ProductPurchase) {
    this.purchase = value
  }
  @Input() set setPaymentSettings(value: PaymentSettings) {
    this.paymentSettings = value
  }
  @Input() set setIsIframe(value: boolean) {
    this.isIframe = value
  }
  @Input() set setIsPreview(value: boolean) {
    this.isPreview = value
  }

  get user(): User {
    return this.userService.getLoggedInUser()
  }

  public environment = environment

  public product: Product
  public purchase: ProductPurchase
  public paymentSettings: PaymentSettings
  public isIframe: boolean = false
  public isPreview: boolean = false

  public userSelected = false
  public termsAccepted = false
  public selectedPaymentMethod: string

  public showDescription = false
  public enableCheckout = false
  public collectBillingAddress = false
  public address: any = {}

  stripeCheckoutSessionLink: string
  loadingCheckoutLink = false
  loading = false

  constructor(private router: Router, public paymentService: PaymentService, public userService: FirestoreService, private authService: AuthService, private chatService: ChatService, private route: ActivatedRoute, private ngZone: NgZone, public dialog: MatDialog, private spinner: NgxSpinnerService, private titleService: Title, public translate: TranslateService) {
    authService.getAuthState().subscribe(user => {
      if (user) {

      } else {
        this.userSelected = false
      }
    });
  }

  getPaymentMethods() {
    var availablePaymentMethods = this.purchase?.availablePaymentMethods ?? this.paymentSettings?.availablePaymentMethods
    return this.paymentService.allPaymentMethods.filter(method => availablePaymentMethods?.includes(method.value))
  }

  onShowProduct() {
    window.open(this.product.getProductPageLink(), "_blank");
  }

  onStartPurchase() {
    if (this.isIframe) {
      // Open new tab:
      this.onShowProduct()
    } else {
      var redirectUrl
      if (this.purchase) redirectUrl = 'checkout/' + this.purchase.id
      if (this.product && !this.purchase) redirectUrl = 'product/' + this.product.id
      const dialogRef = this.dialog.open(LoginDialogComponent, { data: { presetUid: this.purchase?.customerUid ?? null, redirectPath: redirectUrl }, width: '500px'})
      dialogRef.afterClosed().subscribe(async result => {
        if (result) {
          if (result.success) {
            this.userSelected = true
            if (this.product.price == 0 && (this.product.initialSetupFee == null || this.product.initialSetupFee == 0)) {
              this.enableCheckout = true
            }
          }
        }
      });
    }
  }

  onSelectPaymentMethod(paymentMethod: string) {
    this.selectedPaymentMethod = paymentMethod
  }
  async onConfirmPaymentMethod() {
    if (this.selectedPaymentMethod == 'bank_transfer') {
      this.enableCheckout = true
      this.loading = true
      console.log('Loading stripe connect customer data...')
      var res = await firstValueFrom(this.userService.getStripeConnectCustomerData(this.user.uid, this.product.stripeAccountId))
      console.log(res)
      if (res.success == true) {
        this.address = res.data.address
        this.address.name = res.data.name
      }
      this.collectBillingAddress = true
      this.loading = false
    } else {
      this.loadCheckoutLink()
    }
  }

  onToggleDescription() {
    this.showDescription = !this.showDescription
  }

  isAddressValid() {
    return this.address.name && this.address.street && this.address.postalCode && this.address.city && this.address.country
  }

  async onPurchaseProduct() {
    if (!this.termsAccepted) return
    if (this.collectBillingAddress && !this.isAddressValid()) return
    if (this.collectBillingAddress) {
      this.spinner.show()
      var res = await firstValueFrom(this.userService.updateStripeConnectCustomer(this.address, this.user.uid, this.product.stripeAccountId))
      console.log(res)
      this.spinner.hide()
    }
    if (this.isFree() || this.selectedPaymentMethod == 'bank_transfer') {
      this.spinner.show()
      firstValueFrom(this.userService.completeProductPurchase(this.purchase?.id, this.product?.id, this.user.uid, this.selectedPaymentMethod)).then(result => {
        this.ngZone.run( () => {
          this.spinner.hide()
          if (result.redirectUrl) {
            document.location.href = result.redirectUrl
          } else if (result.error) {
            if (result.error == 'Checkout temporarily unavailable') {
              this.error = this.translate.instant('Der Checkout ist vorübergehend nicht verfügbar. Bitte versuche es später erneut.')
            } else {
              this.error = result.error
            }
          }
        });
      }, err => {
        this.spinner.hide()
        console.log(err)
      })
    } else if (this.stripeCheckoutSessionLink) {
      document.location.href = this.stripeCheckoutSessionLink
    }
  }

  onAcceptTerms(value: boolean) {
    this.termsAccepted = value
  }

  error: string = null

  isFree() {
    return this.getPrice() == 0 && !this.hasInitialSetupFee()
  }
  getPrice() {
    if (this.purchase) return this.purchase.price
    if (this.product) return this.product.price
    return null
  }
  hasInitialSetupFee() {
    if (this.purchase) return this.purchase.initialSetupFee != null && this.purchase.initialSetupFee != 0
    if (this.product) return this.product.initialSetupFee != null && this.product.initialSetupFee != 0
    return false
  }
  getPrintableInitialSetupFeeWithCurrency() {
    if (this.purchase) {
      var printableCurrency = PaymentComponent.getPrintableCurrency(this.purchase.currency ?? this.paymentSettings?.currency ?? 'eur')
      if (this.purchase.initialSetupFee != null && this.purchase.initialSetupFee > 0) return this.purchase.getPrintableInitialSetupFee() + ' ' + printableCurrency + ' ' + this.translate.instant('Einmalgebühr bei Kauf')
      if (this.purchase.initialSetupFee != null && this.purchase.initialSetupFee < 0) return this.purchase.getPrintableInitialSetupFee() + ' ' + printableCurrency + ' ' + this.translate.instant('Gutschrift im ersten Monat')
    }
    if (this.product) {
      var printableCurrency = PaymentComponent.getPrintableCurrency(this.product.currency ?? this.paymentSettings?.currency ?? 'eur')
      if (this.product.initialSetupFee != null && this.product.initialSetupFee > 0) return this.product.getPrintableInitialSetupFee() + ' ' + printableCurrency + ' ' + this.translate.instant('Einmalgebühr bei Kauf')
      if (this.product.initialSetupFee != null && this.product.initialSetupFee < 0) return this.product.getPrintableInitialSetupFee() + ' ' + printableCurrency + ' ' + this.translate.instant('Gutschrift im ersten Monat')
    }
    return null
  }
  getProductName() {
    if (this.purchase) return this.purchase.productName
    if (this.product) return this.product.name
    return null
  }
  getProductDescription() {
    if (this.product) return this.product.description
    return null
  }
  getProductSubtitle() {
    if (this.product) return this.product.subtitle
    return null
  }
  getProductThumbnailUrl() {
    if (this.product) return this.product.thumbnailUrl
    return null
  }
  getPrintablePrice() {
    if (this.purchase) return this.purchase.getPrintablePrice()
    if (this.product) return this.product.getPrintablePrice()
    return null
  }
  getPrintablePriceWithCurrency() {
    var currency = this.purchase?.currency ?? this.product?.currency ?? this.paymentSettings?.currency ?? 'eur'
    return this.getPrintablePrice() + ' ' + PaymentComponent.getPrintableCurrency(currency)
  }
  getPrintablePaymentFrequency() {
    if (this.purchase) return this.purchase.getPrintablePaymentFrequency(this.translate)
    if (this.product) return this.product.getPrintablePaymentFrequency(this.translate)
    return null
  }
  getPrintableDuration() {
    if (this.purchase) {
      if (this.purchase.licenceType == null) return ''
      return this.purchase.getPrintableDuration(this.translate)
    }
    if (this.product) {
      if (this.product.licenceType == null) return ''
      return this.product.getPrintableDuration(this.translate)
    }
    return null
  }
  getPrintableRuntime() {
    if (this.purchase) return this.purchase.getPrintableRuntime(this.translate)
    if (this.product) return this.product.getPrintableRuntime(this.translate)
    return null
  }


  loadCheckoutLink() {
    this.error = null
    if (!this.userSelected) return
    if (this.isFree()) return
    if (this.user) {
      this.loadingCheckoutLink = true
      console.log('Loading checkout link...')
      firstValueFrom(this.userService.createStripeConnectCheckoutSession(this.purchase?.id, this.product?.id, this.user.uid, this.selectedPaymentMethod)).then(result => {
        this.ngZone.run( () => {
          console.log(result)
          if (result.redirectUrl) {
            this.stripeCheckoutSessionLink = result.redirectUrl
            this.enableCheckout = true
          } else if (result.error) {
            if (result.error == 'Checkout temporarily unavailable') {
              this.error = this.translate.instant('Der Checkout ist vorübergehend nicht verfügbar. Bitte versuche es später erneut.')
            } else {
              this.error = result.error
            }
          }
          this.loadingCheckoutLink = false
        });
      }, err => {
        console.log(err)
        this.loadingCheckoutLink = false
      })
    }
  }

  getTermsAndConditionsUrl() {
    if(this.paymentSettings?.termsAndConditionsUrl) {
      return this.translate.instant("Ich stimme den <a href='environmentTermsAndConditionsUrlParameter' target=\"_blank\" class=\"underline\"> Nutzungsbedingungen von brandNameParameter</a> und den <a href='paymentSettingsTermsAndConditionsUrlParameter' target=\"_blank\" class=\"underline\"> Nutzungsbedingungen des Anbieters</a> zu.", { brandNameParameter: environment.brandName, paymentSettingsTermsAndConditionsUrlParameter: this.paymentSettings.termsAndConditionsUrl, environmentTermsAndConditionsUrlParameter: environment.termsAndConditionsUrl})
    }
    return this.translate.instant("Ich stimme den <a href='environmentTermsAndConditionsUrlParameter' target=\"_blank\" class=\"underline\"> Nutzungsbedingungen von brandNameParameter</a> zu.", {environmentTermsAndConditionsUrlParameter: environment.termsAndConditionsUrl, brandNameParameter: environment.brandName})
  }
}
