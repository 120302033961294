<div class="dialog-topbar">
    <h3>{{title}}</h3>
</div>
<div class="dialog-content-wrapper">
    <p *ngIf="message">
        {{message}}
    </p>
    <br>
    <p>
        <input id="inputfield" class="input wide" [(ngModel)]="input" placeholder="" type="text">
    </p>
    <br>
    <div class="actions row">
        <button class="button-filled small light float-right col m-2" (click)="onCancelDialog()" translate>Abbrechen</button>
        <button class="button-filled small light float-right col m-2" (click)="onConfirmDialog()" translate>Bestätigen</button>
    </div>
</div>