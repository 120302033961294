import { map } from 'rxjs/operators';
import { FirestoreService } from 'src/app/services/firestore.service';
import { _ViewRepeaterOperation } from '@angular/cdk/collections';
import { Component, NO_ERRORS_SCHEMA, OnInit } from '@angular/core';
// import { firestore } from 'firebase';
import { Observable, Subscription, scheduled } from 'rxjs';
import { allowedNodeEnvironmentFlags } from 'process';
// import { NgAisInfiniteHitsModule } from 'angular-instantsearch';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AutomaticPushNotification, DropdownItem, Label2RepetitionMapping, Repetition, Repetition2LabelMapping } from 'src/app/model/automatic-push-notification.model';
import {MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { Coach } from 'src/app/model/coach.model';
import { User } from 'src/app/model/user.model';
import { TranslateService } from '@ngx-translate/core';
import {NotificationDialogComponent, NotificationDialogConfig} from "./notification-dialog/notification-dialog.component";

@Component({
  selector: 'app-push-notification-settings',
  templateUrl: './push-notification-settings.component.html',
  styleUrls: ['./push-notification-settings.component.css']
})
export class PushNotificationSettingsComponent implements OnInit {

  constructor(public userService: FirestoreService, private toastr: ToastrService, public dialog: MatDialog, private translate: TranslateService){}

  private subscriptionCoaches: Subscription

  public selectedPushNotification: AutomaticPushNotification;
  public selectableCoaches: Coach[] = []

  public observablePushNotifications: Observable<AutomaticPushNotification[]>;

  ngOnInit(): void {
    this.selectedPushNotification = null
    this.observablePushNotifications = this.userService.getScheduledPushNotifications(this.userService.getLoggedInUser()?.coach.licenceHolderUid);
    this.loadCoaches()
  }

  loadCoaches() {
    if (this.subscriptionCoaches) this.subscriptionCoaches.unsubscribe()
    this.subscriptionCoaches = this.userService.getAllCoachesByLicenceHolderUid(this.userService.getLoggedInUser().coach.licenceHolderUid).subscribe(coaches => {
      this.selectableCoaches = coaches
    })
  }

  openPushNotificationEditor(){

    const config: NotificationDialogConfig = {
      isNew: true,
      selectedPushNotification: this.selectedPushNotification,
      goalNotificatonMode: false
    }
    const dialog = this.dialog.open(NotificationDialogComponent, {data: config, width: '850px'});

    dialog.afterClosed().subscribe(result => {
    })
  }

  onCreatePushNotification(){
    this.selectedPushNotification = new AutomaticPushNotification();
    this.selectedPushNotification.active = true;
    this.selectedPushNotification.repetition = Repetition.once;
    this.openPushNotificationEditor();
  }

  onDeletePushNotification(notification:AutomaticPushNotification){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: this.translate.instant('Möchtest du diese Erinnerung wirklich löschen?'), title: this.translate.instant('Erinnerung löschen') },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.deleteScheduledPushNotification(notification);
      }
    });
  }

  onEditPushNotification(notification: AutomaticPushNotification){
    this.selectedPushNotification = new AutomaticPushNotification(notification)
    this.openPushNotificationEditor();
  }

  private toDateString(date: Date): string {
    return (date.getFullYear().toString() + '-'
       + ("0" + (date.getMonth() + 1)).slice(-2) + '-'
       + ("0" + (date.getDate())).slice(-2))
       + 'T' + date.toTimeString().slice(0,5);
  }
}

