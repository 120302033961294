import { FirestoreService } from 'src/app/services/firestore.service';
import { UtilityService } from './../../services/utility.service';
import { MuscleInformation, ExerciseTypeTranslation, ITrainingExercise, ITrainingExerciseOverwrite, TrainingExerciseOverwrite, MergedTrainingExercise, TrainingExercise } from './../../model/training-exercise';
import { ILanguageDictionary } from 'src/app/model/languagedictionary.model';
import { LanguageDictionary } from 'src/app/model/languagedictionary.model';
import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { TrainingService } from 'src/app/services/training.service';
import { FilterObject } from 'src/app/filter-selection-dropdown/filter-selection-dropdown.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-exercises-table',
  templateUrl: './exercises-table.component.html',
  styleUrls: ['./exercises-table.component.css']
})
export class ExercisesTableComponent implements OnInit {

  public trainingExercises: MergedTrainingExercise[] = [];
  public allTrainingExercises: MergedTrainingExercise[] = [];

  public availableMuscleGroups: FilterObject[] = [];
  public availableMainMuscles: FilterObject[] = [];
  public availableExerciseTypes: FilterObject[] = [];
  public availableMovementTypes: FilterObject[] = [];
  public availableCustomCategories: FilterObject[] = [];
  public availableEquipments: FilterObject[] = [];

  private searchText: string = "";

  public get SearchText(): string {
    return this.searchText;
  }

  public set SearchText(value: string) {
    this.searchText = value;
    this.setFilteredTrainingExercises();
  }

  public selectedLanguage: string = "de";

  @Input() set TrainingExercises(value: MergedTrainingExercise[]) {
    this.allTrainingExercises = value.filter(x => x.deleted === false);
    this.setAvailableFilters()
    this.setFilteredTrainingExercises()
  }

  public multiselectEnabled: boolean = false;
  @Input() set multiselect(value: boolean) {
    this.multiselectEnabled = value;
  }

  @Output() trainingExerciseSelected = new EventEmitter<MergedTrainingExercise>()

  // public selectedTrainingExercises: MergedTrainingExercise[] = [];

  @Input() selectedTrainingExercises: MergedTrainingExercise[] = [];
  @Output() selectedTrainingExercisesChange = new EventEmitter<MergedTrainingExercise[]>();

  @Output() multipleTrainingExercisesSelected = new EventEmitter<MergedTrainingExercise[]>()

  constructor(public utilityService: UtilityService, private userService: FirestoreService, public trainingService: TrainingService, private spinner: NgxSpinnerService, private ngZone: NgZone) { }

  ngOnInit(): void {
  }

  setFilteredTrainingExercises() {
    var searchText = this.searchText?.toLowerCase()
    var words = searchText?.split(' ')
    this.trainingExercises = []

    this.allTrainingExercises.forEach(exercise => {
      if (exercise.matchesSearchInput(words, this.selectedLanguage)) {
        if (this.fallsInFilter(this.availableMainMuscles, exercise.mainMuscles)) {
          if (this.fallsInFilter(this.availableMuscleGroups, exercise.muscleGroups)) {
            if (this.fallsInFilter(this.availableExerciseTypes, exercise.exerciseType)) {
              if (this.fallsInFilter(this.availableMovementTypes, exercise.movement)) {
                if (this.fallsInFilter(this.availableCustomCategories, exercise.customCategories, false)) {
                  if (exercise.equipment.length == 0 && this.availableEquipments?.find(x => x.originObject?.originObject == "empty")?.isFiltered) {
                    this.trainingExercises.push(exercise)
                  }
                  else if (this.fallsInFilter(this.availableEquipments, exercise.equipment, false)) {
                    this.trainingExercises.push(exercise)
                  }
                }
              }
            }
          }
        }
      }
    });
    //sort by matchesName
    this.trainingExercises = this.trainingExercises.sort((a, b) => (b.matchesName(words, this.selectedLanguage) == true ? 1 : 0) - (a.matchesName(words, this.selectedLanguage) ? 1 : 0))
    this.sortTrainingExercises();
  }

  setAvailableFilters() {
    if (this.availableEquipments.find(x => x.originObject.originObject == "empty") == null) {
      this.availableEquipments.push(new FilterObject(new LanguageDictionary("Ohne", "Empty", "empty"), false));
    }
    this.allTrainingExercises.forEach(exercise => {
      exercise.muscleGroups.forEach(muscleGroup => {
        if (this.availableMuscleGroups.filter(x => x.originObject.comparableValue == muscleGroup.comparableValue).length <= 0) {
          this.availableMuscleGroups.push(new FilterObject(muscleGroup, false))
        }
      });
      exercise.mainMuscles.forEach(muscle => {
        if (this.availableMainMuscles.filter(x => x.originObject.comparableValue == muscle.comparableValue).length <= 0) {
          this.availableMainMuscles.push(new FilterObject(muscle, false))
        }
      });
      exercise.exerciseType.forEach(exerciseType => {
        if (this.availableExerciseTypes.filter(x => x.originObject.comparableValue == exerciseType.comparableValue).length <= 0) {
          this.availableExerciseTypes.push(new FilterObject(exerciseType, false))
        }
      });
      exercise.movement.forEach(movement => {
        if (this.availableMovementTypes.filter(x => x.originObject.comparableValue == movement.comparableValue).length <= 0) {
          this.availableMovementTypes.push(new FilterObject(movement, false))
        }
      });
      exercise.equipment.forEach(equipment => {
        if (this.availableEquipments.filter(x => x.originObject.comparableValue == equipment.comparableValue).length <= 0) {
          this.availableEquipments.push(new FilterObject(equipment, false))
        }
      });
      exercise.customCategories.forEach(category => {
        if (this.availableCustomCategories.filter(x => x.originObject.originObject.toString() == category.originObject.toString()).length <= 0) {
          this.availableCustomCategories.push(new FilterObject(category, false))
        }
      });
    });
    this.availableMuscleGroups = this.availableMuscleGroups.sort((a, b) => this.getCompareText(a.originObject).localeCompare(this.getCompareText(b.originObject)))
    this.availableMainMuscles = this.availableMainMuscles.sort((a, b) => this.getCompareText(a.originObject).localeCompare(this.getCompareText(b.originObject)))
    this.availableExerciseTypes = this.availableExerciseTypes.sort((a, b) => this.getCompareText(a.originObject).localeCompare(this.getCompareText(b.originObject)))
    this.availableMovementTypes = this.availableMovementTypes.sort((a, b) => this.getCompareText(a.originObject).localeCompare(this.getCompareText(b.originObject)))
    this.availableEquipments = this.availableEquipments.sort((a, b) => this.getCompareText(a.originObject).localeCompare(this.getCompareText(b.originObject))).sort((a, b) => a.originObject.originObject == "empty" ? -1 : 1)
    this.availableCustomCategories = this.availableCustomCategories.sort((a, b) => this.getCompareText(a.originObject, false).localeCompare(this.getCompareText(b.originObject, false)))

  }
  
  async onTransferExercise(exercise: MergedTrainingExercise) {
    console.log('Transfer exercise', exercise.id)
    console.log(exercise)
    var thumbnailUrl = await this.trainingService.getExerciseThumbnailUrl(exercise)
    console.log(thumbnailUrl)
    var newExercise = exercise.clone()

    newExercise.trainingExercise.id = null
    newExercise.trainingExerciseOverwrite = new TrainingExerciseOverwrite()

    let newInstructionVideo: File = null
    if (newExercise.instructionVideoPath) {
      newInstructionVideo = await this.trainingService.getFileFromFirebaseStorage(newExercise.instructionVideoPath)
      newExercise.instructionVideoPath = null
    }
    let newThumbnailBlob: Blob = null
    if (newExercise.thumbnailPath) {
      newThumbnailBlob = await this.trainingService.getBlobFromFirebaseStorage(newExercise.thumbnailPath)
      newExercise.thumbnailPath = null
    }
    let newExercisePreview: File = null
    if (newExercise.exercisePreviewPath) {
      newExercisePreview = await this.trainingService.getFileFromFirebaseStorage(newExercise.exercisePreviewPath)
      newExercise.exercisePreviewPath = null
    }
    newExercise.thumbnailUrl = null

    newExercise.trainingExercise.creatorUid = 'nutrilize'

    this.trainingService.insertTrainingExercise(newExercise, newThumbnailBlob, newInstructionVideo, newExercisePreview);
    console.log('Done')
    

    exercise.deleted = true
    await this.trainingService.saveOrUpdateMergedTrainingExercise(exercise, null, null, null, null, null)

  }

  onTrainingExerciseSelected(exercise: MergedTrainingExercise) {
    if (this.multiselectEnabled) {
      if (this.selectedTrainingExercises.find(x => x.trainingExercise.id == exercise.trainingExercise.id) != null) {
        this.selectedTrainingExercises = this.selectedTrainingExercises.filter(x => x.trainingExercise.id != exercise.trainingExercise.id)
      }
      else {
        this.selectedTrainingExercises.push(exercise)
      }
      this.multipleTrainingExercisesSelected.emit(this.selectedTrainingExercises)
      this.selectedTrainingExercisesChange.emit(this.selectedTrainingExercises)
    }
    else {
      this.trainingExerciseSelected.emit(exercise);
    }
  }

  async onDuplicateTrainingExercise(exercise: MergedTrainingExercise) {
    this.spinner.show();
    try {
      let newExercise = await this.trainingService.duplicateMergedTrainingExercise(exercise);
    } catch (ex) {
      console.log(ex)
    } finally {
      this.spinner.hide();
    }
  }

  getStringForLanguageDictionaryList(items: LanguageDictionary<any>[], maxItems: number): string {
    var returnString: string = "";
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      if (index > maxItems - 1) return returnString + ", ..."
      if (index > 0) returnString = returnString.concat(", ");
      returnString = returnString.concat(element?.GetValue(this.selectedLanguage));
    }
    return returnString;
  }

  getStringForCustomCategoryList(items: LanguageDictionary<any>[], maxItems: number): string {
    var returnString: string = "";
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      if (index > maxItems - 1) return returnString + ", ..."
      if (index > 0) returnString = returnString.concat(", ");
      returnString = returnString.concat(element?.originObject?.toString());
    }
    return returnString;
  }

  deleteExercise(exercise: MergedTrainingExercise) {
    this.trainingService.deleteTrainingExercise(exercise.trainingExercise);
  }

  onDeleteSearchInput() {
    this.SearchText = "";
  }


  hasFilter(filterObjects: FilterObject[]): boolean {
    return filterObjects?.filter(x => x.isFiltered)?.length > 0
  }

  fallsInFilter(filter: FilterObject[], list: ILanguageDictionary<any>[], isTranslatable: boolean = true): boolean {
    return (!this.hasFilter(filter) || list.filter(x => filter.filter(i => i.isFiltered && this.getCompareText(i.originObject, isTranslatable) === this.getCompareText(x, isTranslatable)).length > 0).length > 0)
  }

  getCompareText(filterObject: ILanguageDictionary<any>, isTranslatable: boolean = true): string {
    if (isTranslatable) return filterObject.comparableValue
    else return filterObject.originObject.toString()
  }

  removeAllFiltersOfFilterObjects(filterObjects: FilterObject[]) {
    filterObjects.forEach(filterObject => {
      filterObject.isFiltered = false
    });
  }

  isAnyFilterActive(): boolean {
    return this.availableMuscleGroups.find(x => x.isFiltered) != null ||
      this.availableMainMuscles.find(x => x.isFiltered) != null ||
      this.availableExerciseTypes.find(x => x.isFiltered) != null ||
      this.availableMovementTypes.find(x => x.isFiltered) != null ||
      this.availableEquipments.find(x => x.isFiltered) != null ||
      this.availableCustomCategories.find(x => x.isFiltered) != null;
  }


  onRemoveAllFilters() {
    this.removeAllFiltersOfFilterObjects(this.availableMuscleGroups)
    this.removeAllFiltersOfFilterObjects(this.availableMainMuscles)
    this.removeAllFiltersOfFilterObjects(this.availableExerciseTypes)
    this.removeAllFiltersOfFilterObjects(this.availableMovementTypes)
    this.removeAllFiltersOfFilterObjects(this.availableEquipments)
    this.removeAllFiltersOfFilterObjects(this.availableCustomCategories)
    this.setFilteredTrainingExercises()
  }

  sortTrainingExercises() {
    if (this.sortAlphabetically) {
      this.trainingExercises = this.trainingExercises.sort((a, b) => a.name?.GetValue(this.selectedLanguage)?.localeCompare(b.name?.GetValue(this.selectedLanguage)));
    }
    else if (this.sortTimestamp) {
      this.trainingExercises = this.trainingExercises.sort((a, b) => b.timestamp?.getTime() - a.timestamp?.getTime())?.sort((a, b) => a.creatorUid == b.creatorUid ? 0 : a.creatorUid == "nutrilize" ? 1 : -1);
    }
    else if (this.sortOwnFirst) {
      this.trainingExercises = this.trainingExercises.sort((a, b) => a.name?.GetValue(this.selectedLanguage)?.localeCompare(b.name.GetValue(this.selectedLanguage)))?.sort((a, b) => a.creatorUid == b.creatorUid ? 0 : a.creatorUid == "nutrilize" ? 1 : -1);
    }
  }

  sortAlphabetically: boolean = true;
  sortTimestamp: boolean = false;
  sortOwnFirst: boolean = false;

  sortByAlphabeticalOrder() {
    this.sortAlphabetically = true;
    this.sortTimestamp = false;
    this.sortOwnFirst = false;
    this.sortTrainingExercises()
  }

  sortByTimestamp() {
    this.sortAlphabetically = false;
    this.sortTimestamp = true;
    this.sortOwnFirst = false;
    this.sortTrainingExercises()
  }

  sortByOwnFirst() {
    this.sortAlphabetically = false;
    this.sortTimestamp = false;
    this.sortOwnFirst = true;
    this.sortTrainingExercises()
  }

}
