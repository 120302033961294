<ng-container *ngIf="hasAccess()">
    <div *ngIf="loading" class="loading-animation display-inlineblock marginleft-25">
        <mat-spinner class="spinner" diameter="20" strokeWidth="2"></mat-spinner>
    </div>
    <div class="row">
        <div *ngIf="spikeCardioData && hasHeartRate" class="col-12 heartrate-graph-wrapper">
            <h3 translate>Herzfrequenz (bpm)</h3>

            <div class="kpi-display">
                <div class="kpi">
                    <div *ngIf="spikeCardioData.averageHR" class="start-value">
                        {{spikeCardioData.averageHR}}
                        <span class="label" translate>Durchschnitt</span>
                    </div>
                </div>
                <div class="kpi marginleft-25">
                    <div *ngIf="spikeCardioData.maxHR" class="start-value">
                        {{spikeCardioData.maxHR}}
                        <span class="label" translate>Maximum</span>
                    </div>
                </div>
            </div>
            <ejs-chart #heartRateChart id="chart-container" width="100%" [primaryXAxis]='primaryXAxisHR'[primaryYAxis]='primaryYAxisHR' [crosshair]="crosshairHR" [tooltip]='tooltipHR' containerClass="hidden" [legendSettings]='legendSettingsHR'>
                <e-series-collection>
                    <e-series [dataSource]='spikeCardioData?.dataSamples' type='SplineArea' xName='moment' yName='heartRate' [name]="'Herzfrequenz' | translate" fill='var(--accentColorHover)'  [border]='borderHR' [marker]='markerHR' opacity="0.9"></e-series>
                </e-series-collection>
            </ejs-chart>
            <div class="row justify-content-center">
                <div class="training-toggle toggle-wrapper">
                    <div class="toggle {{showCardioZonesHR ? 'on' : ''}}" (click)="toggleShowCardioZonesHR()">
                        <div class="handle"></div>
                    </div>
                    <div class="text" translate>
                        Belastungszonen anzeigen
                    </div>
                </div>
                <div *ngIf="trainingSessionContainer" class="training-toggle toggle-wrapper">
                    <div class="toggle {{showExercisesHR ? 'on' : ''}}" (click)="toggleShowExercisesHR()">
                        <div class="handle"></div>
                    </div>
                    <div class="text" translate>
                        Übungen anzeigen
                    </div>
                </div>
            </div>
            <div class="spacer-15"></div>
            <div *ngIf="showCardioZonesHR && defaultCardioZoneGroup?.zones?.length > 0" class="cardio-zone-legend row">
                <ng-container *ngFor="let zone of defaultCardioZoneGroup.zones">
                    <div *ngIf="!zone.isMaxCardioZone" class="cardio-zone-legend-item {{isHRCardioZoneDisabled(zone) ? 'zone-disabled' : ''}} link col" (click)="toggleHRCardioZone(zone)">
                        <div class="zone-legend-color-indicator" [style.backgroundColor]="isHRCardioZoneDisabled(zone) ? '' : CardioZoneGroup.getColorByCardioZone(globalCardioZones, zone)"></div>
                        <div class="cardio-zone-legend-text">{{zone.name}}</div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="col-12" *ngIf="hasPace">
            <div *ngIf="hasHeartRate" class="spacer-20"></div>
            <h3>Pace (min/km)</h3>

            <div class="kpi-display">
                <div class="kpi">
                    <div *ngIf="spikeCardioData.averagePaceInMinutes" class="start-value">
                        {{spikeCardioData.averagePaceInMinutes}}
                        <span class="label" translate>Durchschnitt</span>
                    </div>
                </div>
                <div class="kpi marginleft-25">
                    <div *ngIf="spikeCardioData.minPaceInMinutes" class="start-value">
                        {{spikeCardioData.minPaceInMinutes}}
                        <span class="label" translate>Beste</span>
                    </div>
                </div>
            </div>
            <ejs-chart #paceChart id="pace-chart-container" [primaryXAxis]='primaryXAxisPace'[primaryYAxis]='primaryYAxisPace' [crosshair]="crosshairPace" [tooltip]='tooltipPace' containerClass="hidden" [legendSettings]='legendSettingsPace'>
                <e-series-collection>
                    <e-series [dataSource]='spikeCardioData?.dataSamples' type='RangeArea' xName='moment' high='pace' low='low' name="Pace" fill='rgba(126, 225, 249, 0.7)'  [border]='borderPace' [marker]='markerPace' opacity="0.9"></e-series>
                </e-series-collection>
            </ejs-chart>
            <div class="row justify-content-center">
                <div class="training-toggle toggle-wrapper">
                    <div class="toggle {{showCardioZonesPace ? 'on' : ''}}" (click)="toggleShowCardioZonesPace()">
                        <div class="handle"></div>
                    </div>
                    <div class="text" translate>
                        Belastungszonen anzeigen
                    </div>
                </div>
                <div *ngIf="trainingSessionContainer" class="training-toggle toggle-wrapper">
                    <div class="toggle {{showExercisesPace ? 'on' : ''}}" (click)="toggleShowExercisesPace()">
                        <div class="handle"></div>
                    </div>
                    <div class="text" translate>
                        Übungen anzeigen
                    </div>
                </div>
            </div>
            <div class="spacer-15"></div>
            <div *ngIf="showCardioZonesPace && defaultCardioZoneGroup?.zones?.length > 0" class="cardio-zone-legend row">
                <ng-container *ngFor="let zone of defaultCardioZoneGroup.zones">
                    <div *ngIf="!zone.isMaxCardioZone" class="cardio-zone-legend-item {{isPaceCardioZoneDisabled(zone) ? 'zone-disabled' : ''}} link col" (click)="togglePaceCardioZone(zone)">
                        <div class="zone-legend-color-indicator" [style.backgroundColor]="isPaceCardioZoneDisabled(zone) ? '' : CardioZoneGroup.getColorByCardioZone(globalCardioZones, zone)"></div>
                        <div class="cardio-zone-legend-text">{{zone.name}}</div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>