import { CreateTemplateDialogComponent } from "src/app/create-template-dialog/create-template-dialog.component";
import { SetParameter2LabelMapping, BaseTrainingSet, TrainingPlan, TrainingSet } from "src/app/model/training-plan.model";
import { FirestoreNutritionPlanService } from "src/app/services/firestore-nutritionplan.service";
import { TrainingService } from "src/app/services/training.service";
import { TrainingPlanEditorComponent, FormulaCalculationResultAsMessage, FormulaCalculationResult } from "../training-plan-editor.component";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { MergedTrainingExercise } from "src/app/model/training-exercise";
import { TrainingVariable } from "src/app/model/training-variables.model";
import { User } from "src/app/model/user.model";
import { FirestoreService } from "src/app/services/firestore.service";
import { Injector } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { PastePlanTemplateDialogComponent } from "src/app/paste-plantemplate-dialog/paste-plantemplate-dialog.component";
import { TranslateService } from "@ngx-translate/core";

export class TrainingPlanEditorHelper {

    constructor(private trainingService: TrainingService, private dialog: MatDialog, private spinner: NgxSpinnerService, private userService: FirestoreService, private toastr: ToastrService, private translate: TranslateService) { }


    public async saveTrainingPlanAsTemplate(trainingPlan: TrainingPlan, availableTrainingVariables: TrainingVariable[], coach: User) {
        var numberOfTriningExercises = 0
        trainingPlan.sessions.forEach(session => {
            numberOfTriningExercises += session.exercises?.length
        });
        var description: string = trainingPlan.sessions?.length?.toString()
        if (trainingPlan.sessions?.length === 1) {
            description += " " + this.translate.instant("Einheit:") + "\n"
        } else {
            description += " " + this.translate.instant("Einheiten:") + "\n"
        }
        trainingPlan.sessions.forEach(session => {
            if (!session.deleted) {
                if (session.isRestDay) {
                    description += '- ' + (session.nameTranslation?.GetValue(this.translate.currentLang) ?? session.name ?? this.translate.instant('Ruhetag')) + '\n'
                } else {
                    description += '- ' + (session.nameTranslation?.GetValue(this.translate.currentLang) ?? session.name ?? this.translate.instant('Einheit')) + ": " + session.getExerciseListAsString(this.trainingService, this.translate) + "\n"
                }
            }
        })
        if (description.endsWith(', ')) description = description.substring(0, description.length - 2)

        trainingPlan.sessions.forEach(session => {
            session.exercises.forEach(exercise => {
                if (exercise.pauseDuration == null || exercise.pauseDuration == undefined) exercise.pauseDuration = this.getExerciseById(exercise.exerciseId)?.pauseDuration || this.getExerciseById(exercise.exerciseId)?.getDefaultPauseDuration()
            })
        });
        let availableTemplateFolders = await this.trainingService.getTrainingPlanTemplateFolders();
        const dialogRef = this.dialog.open(CreateTemplateDialogComponent, {
            data: { name: trainingPlan.nameTranslation?.GetValue(this.translate.currentLang) ?? trainingPlan.name, description: description, availableTemplateFolders: availableTemplateFolders}, width: '800px',
        });
        var result = await dialogRef.afterClosed().toPromise()
        if (result != null) {
            var template = trainingPlan.clone()
            template.endDate = null
            template.startDate = null
            template.name = result.name || this.translate.instant('Vorlage')
            template.description = result.description
            template.coachUid = coach.uid
            template.licenceHolderUid = coach.licenceHolderUid
            template.isTemplate = true
            if(result.selectedTemplateFolders?.length > 0){
                template.folderIds = result.selectedTemplateFolders.map(x => x.id)
            }
            if (this.trainingService.TrainingTemplateAdministratorEditModeActivated) {
                template.userEditable = true;
            }

            let availableSessions = template.sessions.filter(x => !x.deleted)

            availableSessions.forEach(session => {
                let newSessionId = FirestoreNutritionPlanService.generateUniqueString(8)
                let referencedSessions = availableSessions.filter(x => x.baseSessionId == session.id);
                referencedSessions.forEach(refSession => {
                    refSession.baseSessionId = newSessionId;
                });

                session.id = newSessionId;
                session.exercises.forEach(exercise => {
                    exercise.id = null
                    exercise.sessionId = session.id
                    exercise.alternativePlannedExercises?.forEach(alternativeExercise => {
                      alternativeExercise.id = null;
                      alternativeExercise.sessionId = session.id;
                    });
                });
            });

            template.sessions = availableSessions
            template.sessions.map(x => x.exercises = x.exercises.filter(x => !x.deleted))

            if (!trainingPlan.isTemplate) {
                let coach = this.userService.getLoggedInUser();
                let notAvailableVariables = []
                let variablesOfTrainingPlan = TrainingPlanEditorComponent.getAllVariablesOfTrainingPlan(template, availableTrainingVariables);
                for (let variable of variablesOfTrainingPlan?.availableVariables) {
                    if (!coach.trainingSettingsLicenceHolder.trainingVariables.some(x => x.id == variable.id) && !notAvailableVariables.includes(variable)) {
                        notAvailableVariables.push(variable);
                    }
                }

                if (trainingPlan.isPeriodicPlan) {
                    this.updateSessionPlannedDates(template, trainingPlan.startDate, new Date(0))
                }

                if (notAvailableVariables?.length > 0) {
                    coach.trainingSettingsLicenceHolder.trainingVariables = coach.trainingSettingsLicenceHolder.trainingVariables.concat(notAvailableVariables);
                    await this.userService.updateTrainingSettingsForLicencHolder(coach);
                }
            }


            this.spinner.show("trainingPlanSpinner")
            await this.trainingService.saveTrainingPlanTemplate(template)
            this.spinner.hide("trainingPlanSpinner")
        }

    }


    getExerciseById(exerciseId: string): MergedTrainingExercise {
        return this.trainingService.MergedTrainingExercises.filter(x => x.sourceExerciseId == exerciseId)[0] || null
    }

    updateSessionPlannedDates(trainingPlan: TrainingPlan, previousDate: Date, newDate: Date) {
        //previousDate.setHours(0, 0, 0, 0);
        //newDate.setHours(0, 0, 0, 0);
        for (let session of trainingPlan.sessions) {
            if (session.plannedDate) {
                if (newDate > previousDate) {
                    let diff = newDate.getTime() - previousDate.getTime();
                    session.plannedDate.setHours(0, 0, 0, 0);
                    session.plannedDate = new Date(session.plannedDate.getTime() + diff);
                }
                else if (newDate < previousDate) {
                    let diff = previousDate.getTime() - newDate.getTime();
                    session.plannedDate.setHours(0, 0, 0, 0);
                    session.plannedDate = new Date(session.plannedDate.getTime() - diff);
                }
                // session.plannedDate = new Date(session.plannedDate.getTime() + diff);
            }
        }
    }

    
  // public tutPattern: string = "([0-9]|X|x)-([0-9]|X|x)-([0-9]|X|x)-([0-9]|X|x)$";
  public static tutPattern: string = "([0-9]|X|x)-([0-9]|X|x)-([0-9]|X|x)((-([0-9]|X|x))?)$";
  static checkTUTINput(set:BaseTrainingSet, toastr: ToastrService, translate: TranslateService):boolean {
    if(!set.timeUnderTension) return true
    if(!set?.timeUnderTension?.match(this.tutPattern)){
      set.timeUnderTension = undefined
      toastr.error(translate.instant("Bitte gib die Time under Tension im Format X-X-X-X an."), "",  {
        positionClass: 'toast-bottom-center'
      });
      return false
    }
    return true
  }

  public tutPattern = TrainingPlanEditorHelper.tutPattern;

  checkTUTINput(set:TrainingSet) {
    return TrainingPlanEditorHelper.checkTUTINput(set, this.toastr, this.translate)
  }


  async clonePlan(trainingPlan: TrainingPlan, user: User, coach: User, presetStartDate: Date = null, selectedLanguageCode: string = 'de'): Promise<TrainingPlan>{
    var newTrainingPlan = trainingPlan.clone()
    var startDate: any = newTrainingPlan.startDate?.clone()
    startDate?.setHours(0,0,0,0);
    var endDate: any = newTrainingPlan.endDate?.clone()
    endDate?.setHours(0,0,0,0);
    
    newTrainingPlan.startDate = new Date()
    newTrainingPlan.startDate.setHours(0)
    newTrainingPlan.startDate.setMinutes(0)
    newTrainingPlan.startDate.setSeconds(0)
    newTrainingPlan.startDate.setMilliseconds(0)

    if(newTrainingPlan.isDeprecated === true) {
      newTrainingPlan.endDate = null
    }
    if (presetStartDate) {
        newTrainingPlan.startDate = presetStartDate
    } else {
        const dialogRef = this.dialog.open(PastePlanTemplateDialogComponent, {
            data: { startDate: newTrainingPlan.startDate },
        });
        var result = await dialogRef.afterClosed().toPromise()
        if (!result || !result.startDate) {
            return
        }
        newTrainingPlan.startDate = result.startDate
    }

    if (startDate != null && newTrainingPlan.durationInDays != null){
      newTrainingPlan.endDate = newTrainingPlan.startDate.clone().addDays(newTrainingPlan.durationInDays)
    } else if (startDate != null && endDate != null){
      const diffTime = Math.abs(endDate - startDate)
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      newTrainingPlan.endDate = newTrainingPlan.startDate.addDays(diffDays)
    }
    
    if (coach == null) {
      coach = this.userService.getLoggedInUser();
    }
    newTrainingPlan.id = null
    newTrainingPlan.licenceHolderUid = coach.licenceHolderUid;
    newTrainingPlan.coachUid = coach.uid;
    newTrainingPlan.trainingVariables = trainingPlan.trainingVariables.map(x => x.clone());
    newTrainingPlan.weeks.forEach(week => {
      let newWeekId = FirestoreNutritionPlanService.generateUniqueString(8)
      newTrainingPlan.sessions.forEach(session => {
        if(session.weekId === week.id){
          session.weekId = newWeekId
        }
      });
      week.id = newWeekId
    });
    newTrainingPlan.sessions.forEach(session => {
      let newSessionId = FirestoreNutritionPlanService.generateUniqueString(8)
      newTrainingPlan.sessions.forEach(baseSession => {
        if(baseSession.baseSessionId === session.id){
          baseSession.baseSessionId = newSessionId
        }
      });
      session.exercises.forEach(exercise => {
        exercise.id = null
        exercise.sessionId = newSessionId;
        exercise.alternativePlannedExercises?.forEach(alternativeExercise => {
          alternativeExercise.id = null;
          alternativeExercise.sessionId = newSessionId;
        });
      });
      session.id = newSessionId;

      if(newTrainingPlan.isPeriodicPlan && startDate != null && session.plannedDate != null){
        let diff = session.plannedDate.getTime() - startDate.getTime()
        session.plannedDate = new Date(newTrainingPlan.startDate.getTime() + diff);
      }
    });
    while(user.trainingPlans.filter(x => !x.deleted && x.name === newTrainingPlan.name)?.length > 0){
      newTrainingPlan.name = newTrainingPlan.name + " - Kopie"
    }

    
    let planVariables = trainingPlan.trainingVariables;
    let userVariables = user.trainingVariables;
    let newTrainingVariables = [];
    let userIsMissingVariables = false;
    for(let variable of planVariables){
      let userVariable = userVariables.find(x => x.id === variable.id);
      if(userVariable != null){
        newTrainingVariables.push(userVariable.clone());
      }
      else {
        userIsMissingVariables = true;
        newTrainingVariables.push(variable.clone());
      }
    }
    newTrainingPlan.trainingVariables = newTrainingVariables;
    
    let formulaCalculationErrors = TrainingPlanEditorComponent.calculateAllVariableValues(newTrainingPlan, newTrainingPlan.trainingVariables, user.cardioZoneGroups, selectedLanguageCode);
    if(formulaCalculationErrors?.length > 0) {
      let firstError = formulaCalculationErrors[0];
      let session = firstError.session;
      let sourceExerciseId = firstError.exercise?.exerciseId;
      let exerciseName = this.getExerciseById(sourceExerciseId)?.name?.GetValue(selectedLanguageCode);
      let setNumber = firstError.exercise?.sets?.indexOf(firstError.set) + 1;
      let errorText = '</br>' + this.translate.instant('Einheit:') + ' ' + session.nameTranslation.GetValue(selectedLanguageCode) + '</br>' + this.translate.instant('Übung:') + ' ' + exerciseName + '</br>' + this.translate.instant('Satz:') + ' ' + setNumber + '</br>' + this.translate.instant('Parameter:') + ' ' + this.translate.instant(SetParameter2LabelMapping[firstError.setProperty]);
      if(firstError.formulaCalculationResult === FormulaCalculationResult.cardioZoneNotFound){ 
        errorText = this.translate.instant("Es konnten nicht alle Werte berechnet werden. Überprüfe, ob der Coachee über alle Cardiozonen verfügt, die im Plan verwendet werden.") + " " + errorText;
      }
      else {
        errorText = this.translate.instant("Es konnten nicht alle Werte berechnet werden. Bitte überprüfe deine Formeleingaben im alten Plan.") + " " + errorText;
      }
      this.toastr.error(errorText, this.translate.instant(FormulaCalculationResultAsMessage[firstError.formulaCalculationResult]),  {
        positionClass: 'toast-bottom-center',
        enableHtml: true
      })
      return;
    }

    if(userIsMissingVariables){
      this.toastr.info(this.translate.instant("Der Trainingsplan enthält Variablen, die bei diesem Coachee noch nicht festgelegt wurden. Bitte öffne den kopierten Plan um die Variablenwerte zu überprüfen."), this.translate.instant("Fehlende Variablen automatisch übernommen"),  {
        positionClass: 'toast-bottom-center'
      });
    }

    return newTrainingPlan
  }

  
  unfocus() {
    try {
      document.activeElement['blur']();
    }
    catch (ex) {
      console.error(ex);
    }
  }
}
