<div class="statistic-box">
  <h3 class="d-inline-block wrapper-timerange-selection marginright-10" translate>Umsatz</h3>
  <div class="d-inline-block wrapper-timerange-selection">
    <button class="button-filled white small dropdown-toggle margin-inbetween" type="button"
            id="dropdownGraphTimeRange" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">{{ selectedGraphTimeRange?.name | translate }}
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownGraphTimeRange">
      <div *ngFor="let range of graphTimeRanges">
        <button class="dropdown-item" type="button" (click)="onGraphTimeRangeChanged(range)">{{ range.name | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="d-inline-block float-right">
    <app-analytics-config-menu *ngIf="statisticConfig"
                               [statisticConfig]="statisticConfig"
                               (onChangeConfigSizeEvent)="onChangeConfigSize($event)"
                               (onRemoveConfigEvent)="onRemoveConfig()"
                               (onMoveConfigDownEvent)="onMoveConfigDown()"
                               (onMoveConfigUpEvent)="onMoveConfigUp()"
                               class="d-inline-block"></app-analytics-config-menu>
  </div>

  <dx-chart palette="nutrilizePalette" [dataSource]="revenueStatistics">

    <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
    <dxi-series valueField="revenuePaid" [name]="'Realisierter Umsatz' | translate" color="#1db2f5"></dxi-series>
    <dxi-series valueField="revenueOpen" [name]="'Offener Umsatz' | translate"
                color="var(--accentColor)"></dxi-series>
    <dxi-series valueField="revenueProjection" [name]="'Zukünftiger Umsatz' | translate"
                color="lightgray"></dxi-series>
    <dxo-common-series-settings argumentField="timeArgument" type="stackedBar"></dxo-common-series-settings>
    <dxo-argument-axis *ngIf="selectedGraphTimeRange.id === GraphTimeRangeOptions.THIS_MONTH || selectedGraphTimeRange.id === GraphTimeRangeOptions.LAST_MONTH" [title]="month.format('MMMM YYYY')"></dxo-argument-axis>

    <dxo-legend
      verticalAlignment="bottom"
      horizontalAlignment="center"
      itemTextPosition="top"
      visible="true">
    </dxo-legend>
  </dx-chart>
</div>
