// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  isWhitelabel: true,
  hasInAppPurchase: false,
  brandName: 'BalanceVit',
  appName: 'Coaching Zone',
  redeemLinkPrefix: 'https://fit-mit-jasmin.coach-app.io',
  androidBundleId: 'com.fitmitjasmin.app',
  iOSBundleId: 'com.fitmitjasmin.app',
  iOSAppStoreNumber: '6648780721',
  firebaseProjectId: 'fit-mit-jasmin',
  assetFolderPath: 'assets/fitmitjasmin',
  loginImagePath: 'assets/fitmitjasmin/app-icon.png',
  baseUrl: 'https://fit-mit-jasmin.web.app',
  inAppLink: 'https://fit-mit-jasmin.coach-app.io',
  termsAndConditionsUrl: 'https://fitmitjasmin.com/impressum/',
  MEAL_DUMMY_IMAGE_UNDEFINED_URL: "https://firebasestorage.googleapis.com/v0/b/nutrilize-b4788.appspot.com/o/common%2Fmeal_dummy_images%2Fmeal_image_notype.png?alt=media&token=053e6fcb-919e-464c-a029-cd1c77ca9d8d",
  MEAL_DUMMY_IMAGE_BREAKFAST_URL: "https://firebasestorage.googleapis.com/v0/b/nutrilize-b4788.appspot.com/o/common%2Fmeal_dummy_images%2Fmeal_image_breakfast.png?alt=media&token=f578bb41-fdf0-4533-95fd-aadf2af09507",
  MEAL_DUMMY_IMAGE_LUNCH_URL: "https://firebasestorage.googleapis.com/v0/b/nutrilize-b4788.appspot.com/o/common%2Fmeal_dummy_images%2Fmeal_image_lunch.png?alt=media&token=9e16458a-370d-42e3-81d3-08a73d522e95",
  MEAL_DUMMY_IMAGE_DINNER_URL: "https://firebasestorage.googleapis.com/v0/b/nutrilize-b4788.appspot.com/o/common%2Fmeal_dummy_images%2Fmeal_image_dinner.png?alt=media&token=280523d6-0ee0-4693-9be1-f85aab03d38f",
  MEAL_DUMMY_IMAGE_SNACK_URL: "https://firebasestorage.googleapis.com/v0/b/nutrilize-b4788.appspot.com/o/common%2Fmeal_dummy_images%2Fmeal_image_snack.png?alt=media&token=f20465f6-c62a-4975-9be8-660cb4e9391e",
  colorCalorieGraph: '#FF5169',
  colorCarbohydratesGraph: '#9E31FF',
  colorProteinGraph: '#5CEFFF',
  colorFatGraph: '#B7FD78',
  colorOtherNutrientsGraph: '#B7FD78',
  
  firebaseProjectCommon: {
    apiKey: "AIzaSyCQvv6TtQ84CLuzgvgwiYtKXAu0-lleGwI",
    authDomain: "nutrilize-b4788.firebaseapp.com",
    projectId: "nutrilize-b4788",
    storageBucket: "nutrilize-b4788.appspot.com",
    messagingSenderId: "829290663374",
    appId: "1:829290663374:web:bb3ebd492d53a8d675ef9f",
    measurementId: "G-M6XC70DSPL"
  },
  firebaseProjectIndividual: {
    apiKey: "AIzaSyCDSu3Tf1lB-aRSBPOFiYmrvGDfoVqfCnY",
    authDomain: "fit-mit-jasmin.firebaseapp.com",
    projectId: "fit-mit-jasmin",
    storageBucket: "fit-mit-jasmin.appspot.com",
    messagingSenderId: "934379570266",
    appId: "1:934379570266:web:0983515d717178c0dac463",
  },
  overwrite(environment: any) {
    this.production = environment.production
    this.isWhitelabel = environment.isWhitelabel
    this.brandName = environment.brandName
    this.appName = environment.appName
    this.redeemLinkPrefix = environment.redeemLinkPrefix
    this.androidBundleId = environment.androidBundleId
    this.iOSBundleId = environment.iOSBundleId
    this.iOSAppStoreNumber = environment.iOSAppStoreNumber
    this.firebaseProjectId = environment.firebaseProjectId
    this.assetFolderPath = environment.assetFolderPath
    this.loginImagePath = environment.loginImagePath
    this.baseUrl = environment.baseUrl
    this.inAppLink = environment.inAppLink
    this.termsAndConditionsUrl = environment.termsAndConditionsUrl
    this.MEAL_DUMMY_IMAGE_UNDEFINED_URL = environment.MEAL_DUMMY_IMAGE_UNDEFINED_URL
    this.MEAL_DUMMY_IMAGE_BREAKFAST_URL = environment.MEAL_DUMMY_IMAGE_BREAKFAST_URL
    this.MEAL_DUMMY_IMAGE_LUNCH_URL = environment.MEAL_DUMMY_IMAGE_LUNCH_URL
    this.MEAL_DUMMY_IMAGE_DINNER_URL = environment.MEAL_DUMMY_IMAGE_DINNER_URL
    this.MEAL_DUMMY_IMAGE_SNACK_URL = environment.MEAL_DUMMY_IMAGE_SNACK_URL
    this.firebaseProjectCommon = environment.firebaseProjectCommon
    this.firebaseProjectIndividual = environment.firebaseProjectIndividual
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
