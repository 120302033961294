import { SharedFile } from 'src/app/model/sharedfile.model';
import { AudioPlayerComponent } from './../audio-player/audio-player.component';
export class Chatmessage {
    message: string;
    uid: string;
    time: Date;
    read: boolean;
    attachedFileName: string;
    attachmentUrl: string;
    attachment: File
    messageFromCoach: boolean = true;
    isDeleted: boolean = false;

    messageId: string;
    chatId: string;

    audioPlayerRef: AudioPlayerComponent = null;
    audioDuration: string = "";

    isFromPartner: boolean

    sharedFileId: string
    sharedFile: SharedFile

    // Tmp:
    senderDetails: any = null

    constructor();
    constructor(init: Chatmessage);
    constructor(init?: Chatmessage) {
        this.uid = init && init.uid || null
        this.message = init && init.message || null
        this.time = init && init.time || null
        this.chatId = init && init.chatId || null
        this.messageId = init && init.messageId || null
        this.read = init && init.read || false
        this.attachedFileName = init && init.attachedFileName || null
        this.attachmentUrl = init && init.attachmentUrl || null
        this.messageFromCoach = init && init.messageFromCoach || false
        this.isDeleted = init && init.isDeleted || false
        this.isFromPartner = init && init.isFromPartner || false
        this.sharedFileId = init && init.sharedFileId || null
        this.sharedFile = init && init.sharedFile || null
    }

    hasSharedFileReference() {
        return this.sharedFileId
    }

    hasAudioAttachment() {
        if (this.attachedFileName != null && (this.attachedFileName.toLowerCase().endsWith(".mp3") || this.attachedFileName.toLowerCase().endsWith(".m4a"))){
            return true;
        }
        else {
            return false;
        }
    }

    hasImageAttachment() {
        return this.attachedFileName != null && (this.attachedFileName.toLowerCase().endsWith(".png") || this.attachedFileName.toLowerCase().endsWith(".jpg") || this.attachedFileName.toLowerCase().endsWith(".jpeg"))
    }
    hasAttachment() {
        return this.attachedFileName != null
    }
    
    getPrintableDate() {
        return (this.time.getDate() < 10 ? '0' : '') + this.time.getDate() + '.' + (this.time.getMonth() + 1 < 10 ? '0' : '') + (this.time.getMonth() + 1) + '.' + ', ' + (this.time.getHours() < 10 ? '0' : '') + this.time.getHours() + ':' + (this.time.getMinutes() < 10 ? '0' : '') + this.time.getMinutes()
    }

    private loading: boolean = false;
    getAudioDuration():string{
        if(!this.audioDuration && !this.loading && this.attachmentUrl) {
            this.loading = true
            var audio = new Audio();
            audio.src = this.attachmentUrl
            audio.load()
            audio.addEventListener('loadedmetadata', () => {
                this.audioDuration = new Date(audio.duration * 1000).toISOString().substring(14, 19)
                this.loading = false;
                audio = null
            });
        }
        return this.audioDuration
    }
}