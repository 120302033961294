import { Metric } from "./metric.model";
import { MetricData } from "./metricdata.model";
import { QuestionaireResult } from "./questionaires.model";

export class DailyCondition {
    id: string
    date: Date
    dateString: string
    waterIntake: number
    sleepDuration: number
    menstruating: boolean
    note: string
    metricData: MetricData[]
    questionaireResults: QuestionaireResult[]

    targetValues: any
    selectedSituationId: string
    selectedNutritionPlanId: string

    timezoneOffset: number;

    constructor();
    constructor(init: DailyCondition);
    constructor(init?: DailyCondition) {
        this.id = init && init.id || null
        this.date = init && init.date || null
        this.dateString = init && init.dateString || null
        this.waterIntake = init && init.waterIntake || 0
        this.sleepDuration = init && init.sleepDuration || null
        this.menstruating = init && init.menstruating || false
        this.note = init && init.note || null
        this.metricData = init && init.metricData || []
        this.targetValues = init && init.targetValues || {}
        this.selectedSituationId = init && init.selectedSituationId || null
        this.selectedNutritionPlanId = init && init.selectedNutritionPlanId || null
        this.questionaireResults = init && init.questionaireResults?.map(x => new QuestionaireResult(x)) || []
        this.timezoneOffset = init && init.timezoneOffset || null

        if(typeof init?.timezoneOffset === 'string'){
            this.timezoneOffset = parseInt(init.timezoneOffset);
        }
    }

    getPrintableSleepDuration() {
        return Math.floor(this.sleepDuration / 60) + ':' + ((this.sleepDuration % 60) < 10 ? '0' : '') + (this.sleepDuration % 60)
    }
    getMetricDataByMetricId(metricId: string = null): MetricData {
        var data = null
      if(metricId === "waterIntake") {
        const fakeMetricData = new MetricData();
        fakeMetricData.metricId = metricId;
        fakeMetricData.value = this.waterIntake;
        fakeMetricData.date = this.date;
        return fakeMetricData;
      }
        this.metricData?.forEach(metricData => {
            if (data == null && (metricId == null || metricData.metricId == metricId)) data = metricData
        })
        return data
    }
    getMetricDatasByMetricIdWithQuestionaireResults(metricId: string = null): MetricData[] {
        var fromQuestionaires = this.getMetricDatasOfQuestionairesByMetricId(metricId)
        return fromQuestionaires.concat(this.getMetricDataByMetricId(metricId) ?? [])
    }

    getAllAvailableMetrics(): Metric[]{
        let result: Metric[] = [];
        this.metricData.forEach(metricD => {
            if(result.find(x => x.id == metricD.metricId) == null){
                if(metricD.metric != null){
                    result.push(metricD.metric);
                }
            }
        });

        this.questionaireResults.forEach(questionaireResult => {
            questionaireResult.metricData.forEach(metricData => {
                if(result.find(x => x.id == metricData.metricId) == null){
                    result.push(metricData.metric);
                }
            });
        });
        return result;

    }

    getAverageMetricDataWithQuestionairesByMetricId(metricId: string, includeQuestionaires: boolean): MetricData {
        let result: MetricData = null;
        let metricDataList = this.getMetricDataListWithQuestionairesByMetricId(metricId, includeQuestionaires);
        if(metricDataList?.length > 0){
            result = new MetricData(metricDataList[0]);
            if(!result.metric.isMetricTypeImage() && !result.metric.isMetricTypeVideo() && !result.metric.isMetricTypeText() && !result.metric.isMetricTypeMultiselect() && result.value != null) {
                let sum = metricDataList.map(x => x.value).filter(x => x != null).reduce((a, b) => a + b, 0);
                result.value = (sum / metricDataList.length) || 0;
                if(result.metric.isMetricTypeSelection()){
                    result.value = Math.round(result.value);
                }
            }
        }
        return result;
    }

    getMetricDataListWithQuestionairesByMetricId(metricId: string = null, includeQuestionaires: boolean): MetricData[] {
        let result: MetricData[] = []
        var metricData = this.getMetricDataByMetricId(metricId);
        if(metricData != null){
            result.push(metricData);
        }

        if(includeQuestionaires){
            var questionaireData = this.getMetricDatasOfQuestionairesByMetricId(metricId);
            if(questionaireData?.length > 0){
                questionaireData.forEach(data => {
                    result.push(data);
                });
            }
        }
        return result;
    }
    getMetricDataListWithQuestionaires(): MetricData[] {
        let result: MetricData[] = []
        var questionaireData: MetricData[] = []
        this.questionaireResults.forEach(questionaireResult => {
            questionaireResult.metricData.forEach(metricData => {
                questionaireData.push(metricData);
            });
        });

        this.metricData.forEach(metricData => {
            result.push(metricData);
        });

        if(questionaireData?.length > 0){
            questionaireData.forEach(data => {
                result.push(data);
            });
        }
        return result;
    }

    getMetricDatasOfQuestionairesByMetricId(metricId: string = null): MetricData[] {
        var data = []
        this.questionaireResults.forEach(questionaireResult => {
            questionaireResult.metricData.forEach(metricD => {
                if(metricD.value != null) {
                    if(metricD.metricId == metricId){
                        data.push(metricD);
                    }
                }
            });
        });
        return data;
    }


    getTargetCalories() {
        return this.targetValues['calories'] ?? null
    }
    getTargetCarbohydrates() {
        return this.targetValues['carbohydrates'] ?? null
    }
    getTargetProtein() {
        return this.targetValues['protein'] ?? null
    }
    getTargetFat() {
        return this.targetValues['fat'] ?? null
    }

    hasTargetValuesSet() {
        return this.targetValues != null && this.targetValues['calories']
    }
}
