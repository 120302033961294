import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from 'src/app/model/user.model';
import { RpeTableDialogComponent } from '../rpe-table-dialog/rpe-table-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TrainingVariable } from 'src/app/model/training-variables.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { TrainingService } from 'src/app/services/training.service';
import { LanguageService } from 'src/app/services/language.service';
import { TrainingPlan } from 'src/app/model/training-plan.model';

@Component({
  selector: 'app-one-rm-editor',
  templateUrl: './one-rm-editor.component.html',
  styleUrls: ['./one-rm-editor.component.css']
})
export class OneRmEditorComponent {

  constructor(public dialog: MatDialog, public userService: FirestoreService, public trainingService: TrainingService, public languageService: LanguageService) {}

  public user: User
  public selectedCoach: User
  public userTrainingVariables: TrainingVariable[]
  public trainingVariables: TrainingVariable[]
  public allAvailableForeignVariables: TrainingVariable[] = []
  public hasChanges = false
  public trainingPlan: TrainingPlan

  public get oneRmTrainingVariables(): TrainingVariable[] {
    return this.trainingVariables.filter(x => x.id.startsWith('ONERM_'))
  }
  public get goalOneRmTrainingVariables(): TrainingVariable[] {
    return this.trainingVariables.filter(x => x.id.startsWith('GOAL1RM_'))
  }

  @Input() set User(value: User) {
    this.user = value;
    this.userTrainingVariables = this.user.trainingVariables ?? []
    this.setupVariables()
  }

  @Input() set SelectedCoach(value: User) {
    this.selectedCoach = value;
  }

  @Input() set TrainingPlan(value: TrainingPlan) {
    this.trainingPlan = value
    if (this.trainingPlan) {
      this.setupVariables()
    }
  }

  setupVariables() {
    this.trainingVariables = this.trainingPlan?.trainingVariables ?? []
    this.userTrainingVariables.forEach(variable => {
      if (this.trainingVariables.find(x => x.id == variable.id) == null) {
        this.trainingVariables.push(variable)
      }
    })
    
    this.goalOneRmTrainingVariables.forEach(variable => {
      var id = variable.id
      var oneRmId = id.replace('GOAL1RM_', 'ONERM_')
      if (this.trainingVariables.find(x => x.id == oneRmId) == null) {
        let newVariable = new TrainingVariable();
        newVariable.id = oneRmId
        newVariable.weight = null
        newVariable.name = variable.name
        newVariable.isNew = false
        this.trainingVariables.push(newVariable)
      }
    })
    this.oneRmTrainingVariables.forEach(variable => {
      var id = variable.id
      var goal1RmId = id.replace('ONERM_', 'GOAL1RM_')
      if (this.trainingVariables.find(x => x.id == goal1RmId) == null) {
        let newVariable = new TrainingVariable();
        newVariable.id = goal1RmId
        newVariable.weight = null
        newVariable.name = variable.name
        newVariable.isNew = false
        this.trainingVariables.push(newVariable)
      }
    })

    this.allAvailableForeignVariables = this.userService.getLoggedInUser()?.trainingSettingsLicenceHolder?.trainingVariables?.filter(x => !this.trainingVariables?.find(y => y?.id == x?.id));
  }

  onOpenRpeDialog(variable: TrainingVariable) {
    var dialog = this.dialog.open(RpeTableDialogComponent, { data: { user: this.user, fixedOneRM: variable.weight } });
    dialog.afterClosed().subscribe(result => {
      if (result && result.weight) {
        this.trainingService.rpeTableCopyResult = result
      }
    });
  }

  setWeight(trainingVariable: TrainingVariable, value: string) {
    if (value == null || value.length == 0) {
      trainingVariable.weight = null;
    }
    let weight = parseFloat(value);
    if (weight) {
      trainingVariable.weight = weight;
    }
    this.hasChanges = true
  }
  setName(trainingVariable: TrainingVariable, value: string) {
    trainingVariable.name = value;
    var goalVariable = this.getGoalVariableForOneRmVariable(trainingVariable)
    if (goalVariable) goalVariable.name = value
  }
  isVariableNameValid(name: string) {
    const regex = /^[a-zA-Z0-9\s]+$/;
    return regex.test(name);
  }

  isVariableSelectable(variable: TrainingVariable) {
    return this.trainingVariables.find(x => x.name == variable.name) == null;
  }
  onCreateNewVariable() {
    let newVariable = new TrainingVariable();
    var id = newVariable.id
    newVariable.id = 'ONERM_' + id
    newVariable.weight = null
    this.trainingVariables.push(newVariable)

    newVariable = new TrainingVariable();
    newVariable.id = 'GOAL1RM_' + id
    newVariable.weight = null
    this.trainingVariables.push(newVariable)
  }

  deletedVariabeIds: string[] = []

  onDeleteVariable(variable: TrainingVariable) {
    var goalId = variable.id.replace('ONERM_', 'GOAL1RM_')
    var oneRmId = variable.id.replace('GOAL1RM_', 'ONERM_')
    this.deletedVariabeIds.push(goalId)
    this.deletedVariabeIds.push(oneRmId)
    this.trainingVariables = this.trainingVariables.filter(x => x.id != goalId && x.id != oneRmId)
    this.hasChanges = true
  }
  getGoalVariableForOneRmVariable(oneRmVariable: TrainingVariable) {
    return this.trainingVariables.find(x => x.id == 'GOAL1RM_' + oneRmVariable.id.split('_')[1]);
  }

  onAddAvailableVariable(variable: TrainingVariable) {
    let newVariable = variable.clone()
    this.trainingVariables.push(newVariable)

    newVariable = newVariable.clone()
    newVariable.id = variable.id.replace('ONERM_', 'GOAL1RM_')
    newVariable.weight = null
    this.trainingVariables.push(newVariable)

    this.hasChanges = true
  }

  async onSave() {
    var variables = this.oneRmTrainingVariables.concat(this.goalOneRmTrainingVariables)
    if (this.trainingPlan) await this.userService.updateTrainingPlanVariables(this.user, this.trainingPlan, variables);
    if (this.deletedVariabeIds.length > 0) {
      this.userTrainingVariables = this.userTrainingVariables.filter(x => !this.deletedVariabeIds.includes(x.id))
      this.userService.updateTrainingVariables(this.user, this.userTrainingVariables)
    }
    this.hasChanges = false
    this.deletedVariabeIds = []
  }
}
