<div class="content-wrapper">
    <div class="textalign-center">
        <div class="searchsource-metric margin-10">
            <button *ngIf="user" class="button tab-button searchsource" (click)="onSelectFileSource('PERSONAL_FILES')">
                1:1 mit Coachee
                <div *ngIf="selectedFileSource == 'PERSONAL_FILES'" class="underline"></div>
            </button>
            <button class="button tab-button searchsource" (click)="onSelectFileSource('PUBLIC_FILES')">
                Allgemein
                <div *ngIf="selectedFileSource == 'PUBLIC_FILES'" class="underline"></div>
            </button>
        </div>
    </div>
    <div class="template-folder-tags">
        <div class="folder-scroll-container">
            <div style="width: 105px; min-width: 105px;" class="template-folder-box link {{canCreateFolder() ? ' active' : ''}}" (click)="onFocusRootPath()" (dragover)="onDragOverFolderDropzone($event)" (dragend)="onDragEndFolderTemplate($event, null)" (drop)="onDropOnFolderDropzone($event, null)">
                <div class="create-template-folder-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16">
                        <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z"/>
                      </svg>
                </div>
            </div>
            <div class="create-template-folder-box link" (click)="onCreateFolder()">
                <div class="create-template-folder-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-folder-plus" viewBox="0 0 16 16">
                        <path d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z"/>
                        <path d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"/>
                    </svg>
                </div>
            </div>
            <ng-container *ngFor="let folder of getFolders()">
                <div class="template-folder-box link {{currentFolder?.id == folder.id ? ' active' : ''}}" (click)="onFocusFolder(folder)" (dragover)="onDragOverFolderDropzone($event)" (dragend)="onDragEndFolderTemplate($event, null)" (drop)="onDropOnFolderDropzone($event, folder)">
                    <div class="folder-info-container">
                        <strong class="folder-name">
                            {{folder.name?.GetValue(selectedLanguageCode)}}
                        </strong>
                        <div *ngIf="folder.isHiddenFolder()" class="hidden-folder-icon" tooltip="Ordner ist nicht zugewiesen">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z"/>
                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829"/>
                                <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z"/>
                            </svg>
                        </div>
                        <div class="small-round-button delete-folder-button link" (click)="onDeleteFolder(folder);$event.stopPropagation()" tooltip="Ordner löschen">
                            <svg class="bi bi-trash" fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path><path _ngcontent-btw-c42="" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" fill-rule="evenodd"></path></svg>
                        </div>
                        <div class="small-round-button edit-folder-button link" (click)="editFolder(folder);$event.stopPropagation()" tooltip="Ordner bearbeiten">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                            </svg>
                        </div>
                    </div>
                    <img draggable="false" *ngIf="folder.thumbnailLink" class="folder-thumbnail-image" [src]="folder.thumbnailLink">
                    <div *ngIf="!folder.thumbnailLink" class="folder-thumbnail-image">
                        <div class="create-template-folder-button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-folder" viewBox="0 0 16 16">
                                <path d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a2 2 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139q.323-.119.684-.12h5.396z"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="spacer-10"></div>
    <div class="row">
        <div class="col-12">
            <div>
                <div class="icon-button marginleft-25" (click)="onUploadFile()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-arrow-up" viewBox="0 0 16 16">
                        <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707V11.5z"/>
                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                    </svg>
                    Datei/Video hochladen
                </div>
                <input id="input-fileupload" type="file" class="display-none" (change)="onUploadFileSelected($event)">
                <div class="icon-button marginleft-25" (click)="onCreateWebLink()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
                        <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                        <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
                    </svg>
                    Link erstellen
                </div>
                <div class="icon-button marginleft-25" (click)="onCreateWebLink(true)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
                        <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                        <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
                    </svg>
                    Video-Link erstellen
                </div>
            </div>
        </div>
    </div>
    <div class="spacer-10"></div>
    <div class="row file-list">
        <div class="col-12 col-sm-8">
            <div *ngFor="let file of getFilesAtCurrentPath()" [attr.disabled]="file.isHidden ? true : null" class="row template-file-box link {{file == focusedFile ? 'active' : ''}}" (click)="onFocusFile(file)"  draggable="true" (dragstart)="onDragStartTemplate($event, file)" (dragend)="onDragEndFile($event, file)" (dragover)="onDragOverFileDropzone($event)" (drop)="onDropOnFileDropzone($event, file)">
                <img draggable="false" *ngIf="file.thumbnailLink" class="col-auto file-thumbnail-image p-0" [src]="file.thumbnailLink">
                <div *ngIf="!file.thumbnailLink" class="col-auto file-thumbnail-image">
                    <div class="create-template-folder-button" *ngIf="file.isImage()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-image" viewBox="0 0 16 16">
                            <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                            <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/>
                        </svg>
                    </div>
                    <div class="create-template-folder-button" *ngIf="file.isWebLink() && !file.isVideo()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
                            <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                            <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
                        </svg>
                    </div>
                    <div class="create-template-folder-button" *ngIf="file.isVideo()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera-video" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z"/>
                        </svg>
                    </div>
                    <div class="create-template-folder-button" *ngIf="!file.isFolder() && !file.isImage() && !file.isWebLink() && !file.isVideo()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                            <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
                        </svg>
                    </div>
                </div>
                <div class="col file-info-container">
                    <strong class="folder-name">
                        {{file.name?.GetValue(selectedLanguageCode)}}
                    </strong>
                </div>
                <div *ngIf="isFileUploadRunning(file); else filecomplete" class="col-auto file-buttons">
                    <mat-progress-spinner
                    tooltip="Upload zu {{getFileUploadProgress(file)}}% abgeschlossen"
                    class="loading-statistics-spinner"
                    color="primary"
                    mode="determinate"
                    diameter="30" 
                    strokeWidth="4"
                    [value]="getFileUploadProgress(file)">
                    </mat-progress-spinner>
                    <!-- <mat-spinner class="loading-statistics-spinner" diameter="30" strokeWidth="2"></mat-spinner> -->
                    <!-- <ngx-spinner bdColor = "rgba(0,0,0,0)" size="default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "false"></ngx-spinner> -->
                </div>
                <ng-template #filecomplete>
                    <div class="col-auto file-buttons">
                        <i class="bi bi-pen link" (click)="onEditFile(file);$event.stopPropagation()" style="font-size: 20px;"></i>
                        <i class="bi bi-trash link marginleft-25" (click)="onDeleteSharedFile(file);$event.stopPropagation()" style="font-size: 20px;"></i>
                        <ng-container *ngIf="utilityService.onMobile()">
                            <i *ngIf="focusedFile == file && file.url" class="bi-arrow-up-right link marginleft-25"  (click)="goToLink(file.url);$event.stopPropagation()" style="font-size: 20px;"></i>
                            <i *ngIf="focusedFile == file && focusedFile.isWebLink()" class="bi-arrow-up-right link marginleft-25"  (click)="goToLink(file.webLink);$event.stopPropagation()" style="font-size: 20px;"></i>
                        </ng-container>
                    </div>
                </ng-template>
                <ng-container *ngIf="!utilityService.onMobile()">
                    <div *ngIf="focusedFile == file && focusedFile.isWebLink()" class="open-link-button icon-button" (click)="goToLink(file.webLink)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                        </svg>
                        Öffnen
                    </div>
                    <div *ngIf="focusedFile == file && focusedFile.url" class="open-link-button icon-button" (click)="goToLink(file.url)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                        </svg>
                        Öffnen
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="col-12 col-sm-4 file-preview" *ngIf="!utilityService.onSmallDisplay()">
            <div class="layout-box preview-box" *ngIf="focusedFile?.isImage() && focusedFile?.url">
                <div class="spacer-10"></div>
                <div class="preview-image" [ngStyle]="{'background-image':'url('+ focusedFile.url +')'}"></div>
            </div>
            <div class="layout-box preview-box" *ngIf="focusedFile?.isVideo() && focusedFile.url">
                <video *ngIf="focusedFile?.isVideo() && focusedFile?.url" class="preview-image" controls [src]="focusedFile.url">
                    Your browser does not support the video tag.
                </video>
                
                <div *ngIf="focusedFile?.isPotentiallyBrowserIncompatibleVideo()" class="label">
                    Für .mov-Dateien ist möglicherweise keine Vorschau verfügbar.
                    <div class="spacer-10"></div>
                </div>
            </div>
            <!-- <div class="layout-box preview-box" *ngIf="focusedFile && focusedFile.isVideoLink()">
                <div class="spacer-10"></div>
                <iframe [src]="focusedFile.webLink | safe" width="100%" height="100%" title="External video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div> -->
        </div>
    </div>
</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true">
    <div *ngIf="spinnerText" class="spinner-overlaytext" [innerHTML]="spinnerText"></div>
</ngx-spinner>
<ng-container *ngIf="user">
    <app-notification-composer [user]="user"></app-notification-composer>
</ng-container>