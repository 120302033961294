import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {MatDialog } from '@angular/material/dialog';
import { MacroCalculatorComponent } from '../macro-calculator/macro-calculator.component';
import { NutritionalGoalV2 } from '../model/nutritionalgoalv2.model';
import { Situation } from '../model/nutritionconfig.model';
import { User } from '../model/user.model';

@Component({
  selector: 'app-nutritional-goal-setup',
  templateUrl: './nutritional-goal-setup.component.html',
  styleUrls: ['./nutritional-goal-setup.component.css']
})
export class NutritionalGoalSetupComponent implements OnInit {
  
  @Input() mode: string
  @Input() user: User
  @Input() nutritionalGoals: NutritionalGoalV2[] = []
  @Input() alternativeNutritionalGoals: NutritionalGoalV2[] = []
  @Input() public selectedSituation: Situation

  @Output() nutritionalGoalsChanged = new EventEmitter<NutritionalGoalV2[]>();

  public static MODE_NUTRITION_PLAN = 'nutritionplan'
  public static MODE_COMMON = 'common'

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {

  }


  onNutritionalGoalValueChanged(position: number, type: string, text: string) {
    while (this.nutritionalGoals.length <= position) {
      var nutritionalGoal = new NutritionalGoalV2()
      nutritionalGoal.targetValues = new Map()
      this.nutritionalGoals.push(nutritionalGoal)
    }
    var regex = new RegExp("^[0-9]+$");
    if (regex.test(text) && parseInt(text) > 0)  {
      var number = parseInt(text);
      if (type == "C") {
        this.nutritionalGoals[position].targetValues['carbohydrates'] = number
      } else if (type == "P") {
        this.nutritionalGoals[position].targetValues['protein'] = number
      } else if (type == "F") {
        this.nutritionalGoals[position].targetValues['fat'] = number
      }
    } else {
      if (type == "C") {
        this.nutritionalGoals[position].targetValues['carbohydrates'] = undefined
      } else if (type == "P") {
        this.nutritionalGoals[position].targetValues['protein'] = undefined
      } else if (type == "F") {
        this.nutritionalGoals[position].targetValues['fat'] = undefined
      }
    }
    if (this.nutritionalGoals[position].hasValues()) {
      this.nutritionalGoals[position].targetValues['calories'] = Math.round((this.nutritionalGoals[position].getCarbohydrates() + this.nutritionalGoals[position].getProtein()) * 4.1 + this.nutritionalGoals[position].getFat() * 9.3)
    } else {
      this.nutritionalGoals[position].targetValues['calories'] = 0
    }
  }
  onAlternativeNutritionalGoalValueChanged(position: number, type: string, text: string) {
    while (this.alternativeNutritionalGoals.length <= position) {
      var nutritionalGoal = new NutritionalGoalV2()
      nutritionalGoal.targetValues = new Map()
      this.alternativeNutritionalGoals.push(nutritionalGoal)
    }
    var regex = new RegExp("^[0-9]+$");
    if (regex.test(text) && parseInt(text) > 0)  {
      var number = parseInt(text);
      if (type == "C") {
        this.alternativeNutritionalGoals[position].targetValues['carbohydrates'] = number
      } else if (type == "P") {
        this.alternativeNutritionalGoals[position].targetValues['protein'] = number
      } else if (type == "F") {
        this.alternativeNutritionalGoals[position].targetValues['fat'] = number
      }
    } else {
      if (type == "C") {
        this.alternativeNutritionalGoals[position].targetValues['carbohydrates'] = undefined
      } else if (type == "P") {
        this.alternativeNutritionalGoals[position].targetValues['protein'] = undefined
      } else if (type == "F") {
        this.alternativeNutritionalGoals[position].targetValues['fat'] = undefined
      }
    }
    if (this.alternativeNutritionalGoals[position].hasValues()) {
      this.alternativeNutritionalGoals[position].targetValues['calories'] = Math.round((this.alternativeNutritionalGoals[position].getCarbohydrates() + this.alternativeNutritionalGoals[position].getProtein()) * 4.1 + this.alternativeNutritionalGoals[position].getFat() * 9.3)
    } else {
      this.alternativeNutritionalGoals[position].targetValues['calories'] = 0
    }
  }
  onNutritionalGoalWeekdayChanged(position: number, weekday: string) {
    if (this.nutritionalGoals.length <= position) return
    if (this.nutritionalGoals[position].applicableWeekdays.includes(weekday)) {
      //this.nutritionalGoals[position].removeWeekday(weekday)
    } else {
      this.nutritionalGoals[position].addWeekday(weekday)
      for (var i = 0; i < this.nutritionalGoals.length; i++) {
        if (i != position) this.nutritionalGoals[i].removeWeekday(weekday)
      }
    }
  }
  onNutritionalGoalFromCoachIncludeActivitiesChanged(index: number, value: boolean) {
    this.nutritionalGoals[index].adjustWithActivityCalories = value
  }
  onAddNewNutritionalGoal() {
    if (this.nutritionalGoals.length == 0) {
      this.nutritionalGoals.push(new NutritionalGoalV2())
      this.nutritionalGoals[0].applicableWeekdays = ['all']
      this.nutritionalGoals[0].situationId = this.selectedSituation.id
      return
    }
    if (this.nutritionalGoals[0].applicableWeekdays.includes('all')) {
      this.nutritionalGoals[0].applicableWeekdays = [].concat(NutritionalGoalV2.WEEKDAYS)
    }
    if (this.nutritionalGoals.length < 7) {
      var nutritionalGoal = new NutritionalGoalV2()
      nutritionalGoal.applicableWeekdays = []
      nutritionalGoal.situationId = this.selectedSituation.id
      this.nutritionalGoals.push(nutritionalGoal)
    }
  }
  onDeleteNutritionalGoal(position: number) {
    this.nutritionalGoals[position].applicableWeekdays.forEach(weekday => {
      this.nutritionalGoals[0].addWeekday(weekday)
    })
    this.nutritionalGoals.splice(position, 1)
    if (this.nutritionalGoals.length == 1) this.nutritionalGoals[0].applicableWeekdays = ['all']
  }
  onAddNewAlternativeNutritionalGoal() {
    if (this.alternativeNutritionalGoals.length < 3) {
      var nutritionalGoal = new NutritionalGoalV2()
      nutritionalGoal.applicableWeekdays = ['alt']
      this.alternativeNutritionalGoals.push(nutritionalGoal)
    }
  }
  onDeleteAlternativeNutritionalGoal(position: number) {
    this.alternativeNutritionalGoals.splice(position, 1)
  }
  areNutritionalGoalsValid(): boolean {
    var valid = true
    this.nutritionalGoals.forEach(nutritionalGoal => {
      if (!nutritionalGoal.isValid()) valid = false
    })
    this.alternativeNutritionalGoals.forEach(nutritionalGoal => {
      if (!nutritionalGoal.isValid()) valid = false
    })
    return valid;
  }
  assignNutritionalGoals() {
    if (this.areNutritionalGoalsValid()) {
      var nutritionalGoals: NutritionalGoalV2[] = []
      var adjustWithActivityCalories = this.nutritionalGoals[0].adjustWithActivityCalories
      var date = new Date()
      this.nutritionalGoals.forEach(nutritionalGoal => {
        nutritionalGoal.date = date
        nutritionalGoal.adjustWithActivityCalories = adjustWithActivityCalories
        if (nutritionalGoal.applicableWeekdays.length > 0) nutritionalGoals.push(nutritionalGoal)
      })
      this.alternativeNutritionalGoals.forEach(nutritionalGoal => {
        nutritionalGoal.date = date
        nutritionalGoal.adjustWithActivityCalories = adjustWithActivityCalories
        nutritionalGoals.push(nutritionalGoal)
      })
    }
  }

  onAdditionalCaloriesDistributionChanged(nutritionalGoal: NutritionalGoalV2, distribution: number[]) {
    nutritionalGoal.activityCaloriesDistribution = distribution
  }
  onNutritionalGoalActivityCaloriesMultiplierChanged(nutritionalGoal: NutritionalGoalV2, text: string) {
    if (text) text = text.replace(',', '.')
    var regex = new RegExp("^[0-9,.-]+$");
    if (regex.test(text) && parseFloat(text) > 0)  {
      var number = parseFloat(text);
      nutritionalGoal.activityCaloriesMultiplier = number
    } else {
      nutritionalGoal.activityCaloriesMultiplier = null
    }
  }

  public macroCalculatorNutritionalGoal: NutritionalGoalV2

  onOpenMacroCalculator(nutritionalGoal: NutritionalGoalV2) {
    this.macroCalculatorNutritionalGoal = nutritionalGoal
    const dialogRef = this.dialog.open(MacroCalculatorComponent, {
      data: { nutritionalGoalV2: this.macroCalculatorNutritionalGoal, user: this.user }, width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.nutritionalGoal) {
        this.macroCalculatorNutritionalGoal = result.nutritionalGoal
      }
    });
  }

  createRange(number){
    var items: number[] = [];
    for(var i = 0; i < number; i++){
       items.push(i);
    }
    return items;
  }
}
