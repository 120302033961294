import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DropdownItem } from 'src/app/model/automatic-push-notification.model';
import { TrainingPlanTemplateFolder } from 'src/app/model/training-plan-template-folder.model';
import { TrainingPlan, TrainingPlanAccess } from 'src/app/model/training-plan.model';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { SingleExerciseComponent } from 'src/app/training/single-exercise/single-exercise.component';
import { ImageEditorComponent } from 'src/app/utilities/image-editor/image-editor.component';

@Component({
  selector: 'app-training-plan-settings',
  templateUrl: './training-plan-settings.component.html',
  styleUrls: ['./training-plan-settings.component.css']
})
export class TrainingPlanSettingsComponent {
  public selectableGroupNames: DropdownItem[] = [];
  public selectableClients: DropdownItem[] = [];
  public durationDropdown: string[] = [marker('Tage'), marker('Wochen')];
  public durationDropdownSelection: string = this.durationDropdown[0];
  public templateSharingEnabled: boolean = false;
  public selectableTrainingPlanAccess: string[] = Object.values(TrainingPlanAccess).filter(value => typeof value === 'string');

  _trainingPlan: TrainingPlan;
  @Input() set trainingPlan(value: TrainingPlan) {
    this._trainingPlan = value;
    this.refreshSelectableAssignments();
  }

  get trainingPlan(): TrainingPlan {
    return this._trainingPlan;
  }

  planHasTrackedSessions() {
    return this.trainingPlan.isPeriodicPlan && this.trainingPlan.sessions?.find(x => x.isTracked);
  }

  @Input() trainingPlanSettingsExpanded: boolean = false;
  @Output() trainingPlanSettingsExpandedChange = new EventEmitter<boolean>();

  @Input() removeThumbnail: boolean;
  @Output() removeThumbnailChange = new EventEmitter<boolean>();

  @Input() user: User
  
  get removeThumbnailInternal(): boolean {
    return this.removeThumbnail;
  }
  set removeThumbnailInternal(value: boolean) {
    this.removeThumbnailChange.emit(value);
  }

  @Input() thumbnailImageSrc: any;
  @Output() thumbnailImageSrcChange = new EventEmitter<any>();

  get thumbnailImageSrcInternal(): any {
    return this.thumbnailImageSrc;
  }
  set thumbnailImageSrcInternal(value: any) {
    this.thumbnailImageSrcChange.emit(value);
  }

  @Input() newThumbnail: Blob;
  @Output() newThumbnailChange = new EventEmitter<Blob>();

  get newThumbnailInternal(): Blob {
    return this.newThumbnail;
  }
  set newThumbnailInternal(value: Blob) {
    this.newThumbnailChange.emit(value);
  }

  @Input() hasChanges: boolean;
  @Output() hasChangesChange = new EventEmitter<boolean>();

  get hasChangesInternal(): boolean {
    return this.hasChanges;
  }
  set hasChangesInternal(value: boolean) {
    this.hasChangesChange.emit(value);
  }

  @Input() readOnlyMode: boolean = false;

  @Input() selectedLanguageCode: string = "de";

  @Output() updateSessionPlannedDates = new EventEmitter<{trainingPlan: TrainingPlan, previousDate: Date, newDate: Date}>();
  @Output() setWeekDays = new EventEmitter<void>();
  @Output() setCalendarItems = new EventEmitter<void>();

  @Input() assignedTrainingTemplateFolders: TrainingPlanTemplateFolder[] = [];
  @Input() trainingTemplateAdministratorEditModeActivated: boolean = false;

  constructor(private toastr: ToastrService, private dialog: MatDialog, private userService: FirestoreService, private translate: TranslateService) {
    this.templateSharingEnabled = this.userService?.getLoggedInUser()?.coach?.trainingPlanTemplateSharingEnabled || false;
  }
  
  onRemoveThumbnail(){
    this.removeThumbnailInternal = true;
    this.thumbnailImageSrcInternal = null;
    this.newThumbnailInternal = null;
    this.hasChangesInternal = true;
  }
  
  openThumbnailDialog() {
    (document.querySelector('#thumbnail-input') as HTMLElement).click()
  }
  
  onDropThumbnail(event) {
    event.preventDefault();
    if (event.dataTransfer.files?.length > 0) {
      var file = event.dataTransfer.files[0]
      console.log(file)
      if (!SingleExerciseComponent.checkUploadFile(file, 50000000, ["jpg", "jpeg", "png", "gif"], this.toastr, this.translate)) return
      this.convertThumbnail(file);
    }
  }

  uploadThumbnail(e) {
    if (e.target.files && e.target.files[0]) {
      if (!SingleExerciseComponent.checkUploadFile(e.target.files[0], 50000000, ["jpg", "jpeg", "png"], this.toastr, this.translate)) return;
      this.convertThumbnail(e.target.files[0])
    }
  }
  

  convertThumbnail(thumbnail: File) {
    const dialogRef = this.dialog.open(ImageEditorComponent, {
      data: { imageFile: thumbnail, aspectRatio: 3/1, maintainAspectRatio: true, containWithinAspectRatio: true, maxDimension: 720 },
      width: '1000px',
    });
    dialogRef.afterClosed().subscribe(async result => {
      if(result?.croppedImage) {
        this.thumbnailImageSrcInternal = await this.blobToBase64(result.croppedImage);
        this.newThumbnail = result.croppedImage;
        this.removeThumbnail = false;
        this.hasChangesInternal= true;
        this.newThumbnailInternal = this.newThumbnail;
      }
    });
  }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  
  public today = new Date()
  startDateFilterTrainingPlanConfig = (d: Moment | null): boolean => {
    let lastMonday = new Date()
    lastMonday.setDate(this.today.getDate() - (this.today.getDay() + 6) % 7);
    
    if (this.trainingPlan.id && this.trainingPlan.startDate > d?.toDate() && !this.trainingPlan.startDate.isSameDate(d?.toDate()) && d?.toDate() < lastMonday && !d?.toDate().isSameDate(lastMonday)) return false
    if (!this.trainingPlan.isPeriodicPlan && this.trainingPlan.endDate && d?.toDate() > this.trainingPlan.endDate) return false
    return true
  }

  endDateFilterTrainingPlanConfig = (d: Moment | null): boolean => {
    if (d?.toDate() && d?.toDate() < this.today && !d?.toDate().isSameDate(this.today)) return false
    if (d?.toDate() && this.trainingPlan.startDate && d?.toDate() < this.trainingPlan.startDate) return false
    if (this.trainingPlan.id && d?.toDate()) {
      if (d?.toDate() < this.trainingPlan.startDate || d?.toDate()?.isSameDate(this.trainingPlan.startDate)) return false
    }
    return true
  }

  
  onStartDateChanged(date: Date) {
    date.setHours(0,0,0,0);
    var newDate = date
    let previousDate = this.trainingPlan.startDate;
    //previousDate.setHours(0,0,0,0);
    this.updateSessionPlannedDates.emit({trainingPlan: this.trainingPlan, previousDate: previousDate, newDate: newDate});
    this.trainingPlan.startDate = newDate
    this.setWeekDays.emit();
    this.hasChangesInternal= true
  }
  onEndDateChanged(date: Date) {
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    this.trainingPlan.endDate = date
    this.trainingPlan.initWeeks();
    this.setWeekDays.emit();
    this.hasChangesInternal= true
  }
  onRemoveEndDate() {
    this.trainingPlan.endDate = null
    this.setWeekDays.emit();
    this.hasChangesInternal= true
  }
  canRemoveEndDate() {
    return true;
  }

  
  onDurationDropdownSelectionChanged(durationDropdownItem: string){
    this.durationDropdownSelection = durationDropdownItem;
    this.setDurationNumber(this.getDurationNumber(this.trainingPlan.durationInDays))
    this.hasChanges = true
  }


  getDurationNumber(durationInDays: number){
    if(durationInDays == null) return null
    if(this.durationDropdownSelection == 'Tage') return durationInDays
    else return Math.round(durationInDays / 7);
  }

  setDurationNumber(value: number){
    if(this.durationDropdownSelection == 'Tage') this.trainingPlan.durationInDays = value
    else this.trainingPlan.durationInDays = value * 7
    this.hasChanges = true
    this.setCalendarItems.emit();
  }


  refreshSelectableAssignments(){
    if(this.trainingPlan.durationInDays == null) this.durationDropdownSelection = 'Tage'
    if(this.trainingPlan.durationInDays % 7 == 0) this.durationDropdownSelection = 'Wochen'
    else this.durationDropdownSelection = 'Tage'

    this.selectableGroupNames = [new DropdownItem('', 'Alle', this.trainingPlan.assignedGroupNames.includes('Alle'))]
    
    this.userService.getAccessibleClients().forEach(client => {
      this.selectableClients.push(new DropdownItem(client.uid, client.getName(), this.trainingPlan.assignedUids.includes(client.uid)))
    });
    this.userService.getClientGroups().forEach(group => {
      this.selectableGroupNames.push(new DropdownItem('', group, this.trainingPlan.assignedGroupNames.includes(group)))
    });
  }

  
  canAccessUser(user: User) {
    return this.userService.getLoggedInUser().coach.canAccessUser(user)
  }
  canAccessGroup(group: string) {
    return this.userService.getLoggedInUser().coach.canAccessClientGroup(group)
  }

  getPrintableAssignments(template: TrainingPlan):string{
    var checkedElements = '';
    if(template.assignedGroupNames != null){
      if (this.areAllUsersTargeted()) return this.translate.instant('Alle')
      this.selectableGroupNames.forEach(element => {
        if(template.assignedGroupNames?.includes(element.name) || this.isGroupAssignedByFolders(element.name)){
          if(checkedElements.length > 0){
            checkedElements = checkedElements.concat(', ')
          }
          checkedElements = checkedElements.concat(element.name);
        }
      });
    }
    if (this.selectableClients != null){
      this.selectableClients.forEach(element => {
        if(template.assignedUids?.includes(element.uid) || this.isUserAssignedByFolders(element.uid)){
          if(checkedElements.length > 0){
            checkedElements = checkedElements.concat(', ')
          }
          checkedElements = checkedElements.concat(element.name);
        }
      });
    }
    return checkedElements;
  }

  

  areAllUsersTargeted() {
    return this.trainingPlan.assignedGroupNames?.includes('Alle') || this.areAllUsersAssignedByFolders();
  }

  areAllUsersAssignedByFolders() {
    return this.assignedTrainingTemplateFolders.find(x => x.assignedGroupNames.includes('Alle')) != null
  }

  isUserAssignedByFolders(userUid: string): boolean {
    return this.assignedTrainingTemplateFolders.find(x => x.assignedGroupNames.includes('Alle') || x.assignedUids.includes(userUid)) != null
  }

  isGroupAssignedByFolders(groupName: string): boolean {
    return this.assignedTrainingTemplateFolders.find(x => x.assignedGroupNames.includes('Alle') || x.assignedGroupNames.includes(groupName)) != null
  }
  canSetUser(user: User){
    return this.canAccessUser(user) && !this.isUserAssignedByFolders(user.uid)
  }

  canSetGroup(groupName: string){
    return this.canAccessGroup(groupName) && !this.isGroupAssignedByFolders(groupName)
  }

  
  onUserEditableChanged(value: boolean){
    this.trainingPlan.userEditable = value
    this.hasChanges = true
  }

  onHideNextExecutionDateChanged(value: boolean){
    this.trainingPlan.hideNextExecutionDate = value
    this.hasChanges = true
  }
  
  onTargetSelectionChangedUser(user: User){
    if (!this.canSetUser(user)) return
    if (this.trainingPlan.assignedUids.includes(user.uid)) {
      this.trainingPlan.assignedUids.forEach( (item, index) => {
        if (item == user.uid) this.trainingPlan.assignedUids.splice(index, 1)
      })
    } else {
      this.trainingPlan.assignedUids.push(user.uid)
    }
    this.hasChanges = true;
  }

  onTargetSelectionChangedGroup(group: string){
    if (!this.canSetGroup(group)) return
    if (this.trainingPlan.assignedGroupNames.includes(group)) {
      this.trainingPlan.assignedGroupNames.forEach( (item, index) => {
        if (item == group) this.trainingPlan.assignedGroupNames.splice(index, 1)
      })
    } else {
      this.trainingPlan.assignedGroupNames.push(group)
    }
    this.hasChanges = true;
  }

  
  getSelectableClients() {
    return this.userService.getAccessibleClients()
  }

  getSelectableClientGroups() {
    return this.userService.getClientGroups()
  }
}
