import { Inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';

import { AuthService } from '../auth/auth.service';
import { Ingredient } from '../model/ingredient.model';
import { Recipe } from '../model/recipe.model';
import { FirestoreService } from './firestore.service';
import { map } from 'rxjs/operators';
import { Observable, combineLatest, of, merge, Observer, firstValueFrom } from 'rxjs';
import { User } from '../model/user.model';
import { NutritionPlan } from '../model/nutritionplan.model';
import { Day } from '../model/day.model';
import { PlannedMeal } from '../model/plannedmeal.model';
import { Meal } from '../model/meal.model';
import { NutritionalSummary } from '../model/nutritionalsummary.model';
import { Tag } from '../model/tag.model';
import { NutritionalGoal } from '../model/nutritionalgoal.model';
import { PlannedFood } from '../model/plannedfood.model';
import { BaseNutritionFact } from '../model/basenutritionfact.model';
import { Food } from '../model/food.model';
import { PlannedMealV2 } from '../model/plannedmealv2.model';
import { CycleConfig, Situation, SituationType } from '../model/nutritionconfig.model';
import { NutritionalGoalV2 } from '../model/nutritionalgoalv2.model';
import { NutritionPlanConfig } from '../model/nutritionplanconfig.model';
import { NutritionPlanMealConfig, NutritionPlanV2 } from '../model/nutritionplanv2.model';
import { PlanningTemplate } from '../model/planning-template.model';
import { CalendarDay } from '../nutrition-plan/nutrition-plan.component';
import { CommonFirebase, IndividualFirebase } from '../app.module';
import { NutritionService } from './nutrition.service';

@Injectable({
  providedIn: 'root'
})
export class FirestoreNutritionPlanService {

  get firestore(): AngularFirestore {
    return this.mainFirebase.firestore
  }
  get fireStorage(): AngularFireStorage {
    return this.mainFirebase.storage
  }

  public recipes: Recipe[]
  public clientRecipes: Recipe[]
  public databaseRecipes: Recipe[]
  public customTags: Tag[] = []
  public nutritionPlanTemplates: NutritionPlan[] = null
  public planningTemplates: PlanningTemplate[] = null

  constructor(private mainFirebase: IndividualFirebase, private authService: AuthService, private userService: FirestoreService, private nutritionService: NutritionService) {
    this.recipes = []
    this.clientRecipes = []
    this.databaseRecipes = []
  }

  async loadCycleConfigs(user: User) {
    /*if(user.configs?.length > 0){
      return user.configs;
    }*/
    user.configs = (await firstValueFrom(this.geCycleConfigs(user))).sort((a, b) => b.startDate.getTime() - a.startDate.getTime())
  }
  async loadNutritionPlanConfigs(user: User) {
    /*if(user.planConfigs?.length > 0){
      return user.planConfigs;
    }*/
    user.planConfigs = (await firstValueFrom(this.getNutritionPlanConfigs(user, null))).sort((a, b) => b.startDate.getTime() - a.startDate.getTime())
  }

  // NUTRITION PLANS

  async getNutritionPlanMealsForDateRange(user: User, startDate: Date, endDate: Date): Promise<PlannedMealV2[]> {
    endDate.setHours(23)
    endDate.setMinutes(59)
    endDate.setSeconds(59)
    var meals = []
    var snapshot = await this.firestore.collection<any>('Users/' + user.uid + '/NutritionPlanMeals/').ref.where('date', '>=', startDate).where('date', '<=', endDate).get()
    for (let document of snapshot.docs) {
      if (document.data().deleted == null || !document.data().deleted) {
        var meal = new PlannedMealV2(document.data() as PlannedMealV2)
        meal.id = document.id
        meal.date = new Date(document.data().date.seconds * 1000)
        BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
          if (document.data()[nutritionalValue] != null && document.data()[nutritionalValue] != undefined && document.data()[nutritionalValue].toString().length > 0) {
            meal.setNutritionalValue(nutritionalValue, parseFloat(document.data()[nutritionalValue]))
          } else {
            meal.setNutritionalValue(nutritionalValue, null)
          }
        })
        meals.push(meal)
      }
    }
    return meals
  }

  async getNutritionPlanMealsByPlanId(user: User, nutritionPlanId: string): Promise<PlannedMealV2[]> {
    var snapshot = await this.firestore.collection<any>('Users/' + user.uid + '/NutritionPlanMeals/').ref.where('nutritionPlanId', '==', nutritionPlanId).get()
    var meals = []
    for (let document of snapshot.docs) {
      if (document.data().deleted == null || !document.data().deleted) {
        var meal = new PlannedMealV2(document.data() as PlannedMealV2)
        meal.id = document.id
        meal.date = new Date(document.data().date.seconds * 1000)
        BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
          if (document.data()[nutritionalValue] != null && document.data()[nutritionalValue] != undefined && document.data()[nutritionalValue].toString().length > 0) {
            meal.setNutritionalValue(nutritionalValue, parseFloat(document.data()[nutritionalValue]))
          } else {
            meal.setNutritionalValue(nutritionalValue, null)
          }
        })
        meals.push(meal)
      }
    }
    return meals
  }

  getPlanningTemplateMeals(template: PlanningTemplate): Observable<PlannedMealV2[]> {
    return new Observable((observer) => {
      this.firestore.collection<any>('PlanningTemplates/' + template.id + '/NutritionPlanMeals/').ref.get().then(async documents => {
        var meals = []
        for (let document of documents.docs) {
          if (document.data().deleted == null || !document.data().deleted) {
            var meal = new PlannedMealV2(document.data() as PlannedMealV2)
            BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
              if (document.data()[nutritionalValue] != null && document.data()[nutritionalValue] != undefined && document.data()[nutritionalValue].toString().length > 0) {
                meal.setNutritionalValue(nutritionalValue, parseFloat(document.data()[nutritionalValue]))
              } else {
                meal.setNutritionalValue(nutritionalValue, null)
              }
            })
            meal.id = document.id
            await this.getNutritionPlanMeal(meal, null, template, false, false, false)
            meals.push(meal)
          }
        }
        observer.next(meals)
        observer.complete()
      })
    })
  }

  async getNutritionPlanMeal(meal: PlannedMealV2, user: User, template: PlanningTemplate, loadImage: boolean = true, loadFacts: boolean = true, loadBaseRecipe: boolean = true): Promise<boolean> {
    if (!meal.baseMealTemplateId) {
      var path = template ? 'PlanningTemplates/' + template.id + '/NutritionPlanMeals/' + meal.id + '/Foods/' : 'Users/' + user.uid + '/NutritionPlanMeals/' + meal.id + '/Foods/'
      var snapshot = await this.firestore.collection<any>(path).ref.get()
      var foods = []
      for (let document of snapshot.docs) {
        var food = new PlannedFood(document.data() as PlannedFood)
        food.nutritionFactId = document.data().baseNutritionFactId
        food.id = document.id
        food.weight = document.data().weight
        if (document.data().isDummy != null && document.data().isDummy == true) {
          food.isDummy = true;
        }
        if (food.nutritionFactId && loadFacts) {
          food.nutritionFact = await this.userService.getBaseNutritionFactById(food.nutritionFactId).toPromise()
        }
        foods.push(food)
        BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
          if (document.data()[nutritionalValue] != null && document.data()[nutritionalValue] != undefined && document.data()[nutritionalValue].toString().length > 0) {
            food.setNutritionalValue(nutritionalValue, parseFloat(document.data()[nutritionalValue]))
          } else {
            food.setNutritionalValue(nutritionalValue, null)
          }
        })
      }
      meal.foods = foods.sort((a, b) => a.position - b.position)
      meal.recalculateNutritionalValues()
    } else if (loadBaseRecipe) {
      var recipe = meal.baseRecipe
      if (recipe) {
        await this.nutritionService.loadFullRecipe(recipe)
        if (!recipe.calories) recipe.recalculateNutritionalValues()
        recipe.loaded = true
      }
      if (!meal.foods) meal.recalculateBaseRecipeFoods()
    }
    if (meal.foods && loadFacts) {
      for (let food of meal.foods) {
        if (food.nutritionFactId && !food.nutritionFact) {
          food.nutritionFact = await this.userService.getBaseNutritionFactById(food.nutritionFactId).toPromise()
          food.recalculateNutritionalValues()
        }
      }
      meal.nutritionFactsLoaded = true
      meal.recalculateNutritionalValues()
    }
    if (loadFacts) meal.nutritionFactsLoaded = true
    //if (loadImage && meal.mealTemplateId) await this.getNutritionPlanMealImage(meal)
    return Promise.resolve(true)
  }
  async getNutritionPlanMealImage(meal: PlannedMealV2): Promise<boolean> {
    try {
      var url = await this.fireStorage.ref("meal_templates/" + meal.mealTemplateId + "/thumbnail.jpg").getDownloadURL().toPromise()
      if (url) {
        meal.imageURL = url
        return Promise.resolve(true)
      }
    } catch (error) {}
    return Promise.resolve(false)
  }

  async insertNutritionPlanMeal(meal: PlannedMealV2, user: User, template: PlanningTemplate) {
    var data = meal.toMap()
    var path = template ? 'PlanningTemplates/' + template.id + '/NutritionPlanMeals/' : 'Users/' + user.uid + '/NutritionPlanMeals/'
    var res = await this.firestore.collection(path).add(data)
    meal.id = res.id
    await this.insertNutritionPlanMealContent(meal, user, template)
  }
  async updateNutritionPlanMeal(meal: PlannedMealV2, user: User): Promise<boolean> {
    var data = meal.toMap()
    var res = await this.firestore.collection('Users/' + user.uid + '/NutritionPlanMeals/').doc(meal.id).set(data, {merge: true})
    if (meal.foods) {
      var documents = await this.firestore.collection('Users/' + user.uid + '/NutritionPlanMeals/' + meal.id + '/Foods/').ref.get()
      for (var document of documents.docs) {
        var contains = false
        meal.foods.forEach(food => {
          if (food.id == document.id) contains = true
        })
        if (!contains) await document.ref.delete()
      }
      await this.insertNutritionPlanMealContent(meal, user, null)
    }
    return true
  }
  
  updateNutritionPlanMealPosition(meal: PlannedMealV2, user: User): Promise<void> {
    if (!meal.id) {
      return Promise.resolve()
    }
    var data = {
      date: meal.date, number: meal.number, nutritionPlanId: meal.nutritionPlanId, isRepeating: meal.isRepeating, repetitionDayNumber: meal.repetitionDayNumber, isAlternative: meal.isAlternative, name: meal.name, type: meal.type, customType: meal.customType, mealConfigId: meal.mealConfigId, timestamp: new Date()
    }
    return this.firestore.collection('Users/' + user.uid + '/NutritionPlanMeals/').doc(meal.id).set( data, {merge: true})
  }
  updateNutritionPlanMealThumbnailPath(meal: PlannedMealV2, user: User) {
    return this.firestore.collection('Users/' + user.uid + '/NutritionPlanMeals/').doc(meal.id).set( { thumbnailPath: meal.thumbnailPath, timestamp: new Date() }, {merge: true})
  }
  async updateMealPositions(day: CalendarDay, user: User) {
    var number = 0
    for (var i = 0; i < day.items.length; i++) {
      if (day.items[i].meal) {
        var meal = day.items[i].meal
        if (meal.number != number) {
          meal.number = number
          await this.updateNutritionPlanMealPosition(meal, user)
        }
        for (var alternativeMeal of day.items[i].alternativeMeals) {
          if (alternativeMeal.number != number) {
            alternativeMeal.number = number
            await this.updateNutritionPlanMealPosition(alternativeMeal, user)
          }
        }
        number++
      } else if (day.items[i].mealConfig) {
        number++
      } else {
      }
    }
  }

  
  async insertNutritionPlanMealContent(meal: PlannedMealV2, user: User, template: PlanningTemplate): Promise<boolean> {
    var i = 0
    var promises: Promise<any>[] = []
    if (meal.foods) {
      for (let food of meal.foods) {
        food.position = i
        food.recalculateNutritionalValues()
        var data = {
          name: food.getName() ?? null,
          weight: food.weight,
          servingSize: food.servingSize,
          unit: food.unit,
          isDummy: food.isDummy,
          position: food.position,
          groupHeading: food.groupHeading
        }
        BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
          if (food.getNutritionalValue(nutritionalValue) != null && food.getNutritionalValue(nutritionalValue) != undefined) {
            data[nutritionalValue] = food.getNutritionalValue(nutritionalValue)
          }
        })
        var path = template ? 'PlanningTemplates/' + template.id + '/NutritionPlanMeals/' + meal.id + '/Foods/' : 'Users/' + user.uid + '/NutritionPlanMeals/' + meal.id + '/Foods/'
        if (food.id) {
          if (!food.isDummy) {
            data['baseNutritionFactId'] = food.nutritionFactId
          } else {
            data['baseNutritionFactId'] = null
            data['name'] = food.name
          }
          var promise = this.firestore.collection(path).doc(food.id).set(data)
          promises.push(promise)
        } else {
          if (!food.isDummy) {
            data['baseNutritionFactId'] = food.nutritionFactId
          } else {
            data['baseNutritionFactId'] = null
            data['name'] = food.name
          }
          var promise = this.firestore.collection(path).add(data).then(res => {
            food.id = res.id
          })
          promises.push(promise) 
        }
        i++
      }
    } else {
      // Only for compatibility with old data
      /*for (let ingredient of meal.baseRecipe?.getIngredients()) {
        ingredient.position = i
        var data1 = {
          name: ingredient.getName(),
          weight: ingredient.weight,
          servingSize: ingredient.servingSize,
          unit: ingredient.getUnit(),
          isDummy: ingredient.isDummy,
          position: ingredient.position,
          groupHeading: ingredient.groupHeading
        }
        BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
          if (ingredient.getNutritionalValue(nutritionalValue) != null && ingredient.getNutritionalValue(nutritionalValue) != undefined) {
            data1[nutritionalValue] = ingredient.getNutritionalValue(nutritionalValue)
          }
        })
        var path = template ? 'PlanningTemplates/' + template.id + '/NutritionPlanMeals/' + meal.id + '/Foods/' : 'Users/' + user.uid + '/NutritionPlanMeals/' + meal.id + '/Foods/'
        if (ingredient.id) {
          if (!ingredient.isDummy) {
            data1['baseNutritionFactId'] = ingredient.nutritionFactId
          } else {
            data1['baseNutritionFactId'] = null
            data1['name'] = ingredient.name
          }
          var promise = this.firestore.collection(path).doc(ingredient.id).set(data1)
          promises.push(promise)
        } else {
          if (!ingredient.isDummy) {
            data1['baseNutritionFactId'] = ingredient.nutritionFactId
          } else {
            data1['baseNutritionFactId'] = null
            data1['name'] = ingredient.name
          }
          var promise = this.firestore.collection(path).add(data1).then(res => {
            ingredient.id = res.id
          })
          promises.push(promise) 
        }
        i++
      }*/
    }
    await Promise.all(promises)
    return Promise.resolve(true)
  }

  deleteNutritionPlanMeal(meal: PlannedMealV2, user: User): Promise<void> {
    return this.firestore.collection('Users/' + user.uid + '/NutritionPlanMeals/').doc(meal.id).update({deleted: true, timestamp: new Date()})
  }
  async adjustCollidingNutritionPlanConfigs(editedConfig: NutritionPlanConfig, planConfigs: NutritionPlanConfig[], user: User) {
    for (var config of planConfigs) {
      if (config.id == editedConfig.id) continue
      if (editedConfig.intersectsWith(config)) {

        if (editedConfig.startDate.isSameDate(config.startDate)) {
          await this.deleteNutritionPlanConfig(config, user)

        } else if (editedConfig.startsInside(config)) {

          config.endDate = editedConfig.startDate.clone().addDays(-1)
          await this.updateNutritionPlanConfig(config, user).toPromise()

          for (var plan of config.nutritionPlans) {
            var meals = await this.getNutritionPlanMealsByPlanId(user, plan.id)
            var matchingPlan: NutritionPlanV2
            editedConfig.nutritionPlans.forEach(p => {
              if (p.id == plan.id || p.name == plan.name) matchingPlan = p
            })
            if (matchingPlan) {
              for (var m of meals) {
                if (m.date.isSameOrAfterDate(editedConfig.startDate)) {
                  m.nutritionPlanId = matchingPlan.id
                  await this.updateNutritionPlanMealPosition(m, user)
                }
              }
            } else {
              for (var m of meals) {
                if (m.date.isSameOrAfterDate(editedConfig.startDate)) {
                  await this.deleteNutritionPlanMeal(m, user)
                }
              }
            }
          }

        } else if (config.startsInside(editedConfig)) {

          if (editedConfig.endDate) {
            config.startDate = editedConfig.endDate.clone().addDays(1)
            await this.updateNutritionPlanConfig(config, user).toPromise()

            for (var plan of config.nutritionPlans) {
              var meals = await this.getNutritionPlanMealsByPlanId(user, plan.id)
              var matchingPlan: NutritionPlanV2
              editedConfig.nutritionPlans.forEach(p => {
                if (p.id == plan.id || p.name == plan.name) matchingPlan = p
              })
              if (matchingPlan) {
                for (var m of meals) {
                  if (m.date.isSameOrBeforeDate(editedConfig.endDate)) {
                    m.nutritionPlanId = matchingPlan.id
                    await this.updateNutritionPlanMealPosition(m, user)
                  }
                }
              } else {
                for (var m of meals) {
                  if (m.date.isSameOrBeforeDate(editedConfig.endDate)) {
                    await this.deleteNutritionPlanMeal(m, user)
                  }
                }
              }
            }

          } else {

            for (var plan of config.nutritionPlans) {
              var meals = await this.getNutritionPlanMealsByPlanId(user, plan.id)
              var matchingPlan: NutritionPlanV2
              editedConfig.nutritionPlans.forEach(p => {
                if (p.id == plan.id || p.name == plan.name) matchingPlan = p
              })
              if (matchingPlan) {
                for (var m of meals) {
                  if (m.date.isSameOrBeforeDate(editedConfig.endDate)) {
                    m.nutritionPlanId = matchingPlan.id
                    await this.updateNutritionPlanMealPosition(m, user)
                  }
                }
              } else {
                for (var m of meals) {
                  if (m.date.isSameOrBeforeDate(editedConfig.endDate)) {
                    await this.deleteNutritionPlanMeal(m, user)
                  }
                }
              }
            }

            await this.deleteNutritionPlanConfig(config, user)

          }

        }
      }
    }
  }

  // NutritionConfig

  geCycleConfigs(user: User): Observable<CycleConfig[]> {
    return new Observable((observer) => {
      this.firestore.collection<any>('Users/' + user.uid + '/CycleConfigs/').ref.get().then(async documents => {
        var configs = []
        for (let document of documents.docs) {
          if (document.data().deleted == null || !document.data().deleted) {
            var config = new CycleConfig(document.data() as CycleConfig)
            config.id = document.id
            config.startDate = new Date(document.data().startDate.seconds * 1000)
            config.commonTargetValues = document.data().commonTargetValues || {};
            if (document.data().endDate) config.endDate = new Date(document.data().endDate.seconds * 1000)
  
            var situations = []
            if (document.data().situations) {
              (document.data().situations as Situation[]).forEach(e => {
                situations.push(new Situation(e as any))
              })
            }
            config.situations = situations
  
            if (config.situations == null || config.situations.length == 0) {
              config.situations = []
              config.situations.push(Situation.defaultSituation())
            }
            config.selectedSituation = situations[0]
  
            var nutritionalGoals = []
            if (document.data().nutritionalGoals) {
              (document.data().nutritionalGoals as NutritionalGoalV2[]).forEach(e => {
                var goal = new NutritionalGoalV2(e as any)
                nutritionalGoals.push(goal)
                if (goal.situationId) {
                  config.situations.forEach(situation => {
                    if (situation.id == goal.situationId) {
                      situation.nutritionalGoals.push(goal)
                      goal.situation = situation
                    }
                  })
                } else {
                  config.situations[0].nutritionalGoals.push(goal)
                }
              })
            }
            configs.push(config)
          }
        }
        observer.next(configs)
        observer.complete()
      })
    })
  }

  insertNutritionConfig(config: CycleConfig, user: User, template: PlanningTemplate): Observable<boolean> {
    if (config.startDate) {
      config.startDate.setHours(0)
      config.startDate.setMinutes(0)
      config.startDate.setSeconds(0)
      config.startDate.setMilliseconds(0)
    }
    if (config.endDate) {
      config.endDate.setHours(0)
      config.endDate.setMinutes(0)
      config.endDate.setSeconds(0)
      config.endDate.setMilliseconds(0)
    }

    var allNutritionalGoals = []
    config.situations.forEach(situation => {
      if (!situation.id) situation.id = FirestoreNutritionPlanService.generateUniqueString()
      situation.nutritionalGoals.forEach(goal => {
        goal.situationId = situation.id
        if (goal.activityCaloriesMultiplier == null) goal.activityCaloriesMultiplier = 1
        allNutritionalGoals.push(goal)
      })
    })
    var nutritionalGoals = allNutritionalGoals?.map((obj)=> {return Object.assign({}, obj)}) || null
    nutritionalGoals?.forEach(goal => {
      goal['situation'] = null
    })

    var situations = config.situations?.map((obj)=> {return Object.assign({}, obj)}) || null
    situations?.forEach(e => {
      e['nutritionalGoals'] = null
    })
    var path = template ?  'PlanningTemplates/' + template.id + '/CycleConfigs/' : 'Users/' + user.uid + '/CycleConfigs/'
    return new Observable((observer) => {
      this.firestore.collection(path).add({commonTargetValues: config.commonTargetValues, name: config.name, startDate: config.startDate, endDate: config.endDate, startDayNumber: config.startDayNumber, endDayNumber: config.endDayNumber, nutritionalGoals: nutritionalGoals, situations: situations, timestamp: new Date()}).then(async res => {
        config.id = res.id
        observer.next(true)
        observer.complete()
      })
    })
  }
  static generateUniqueString(length: number = 6) {
    let d = new Date().getTime(), d2 = (performance && performance.now && (performance.now() * 1000)) || 0;
    let placeholder = '';
    for (let i = 0; i < length; i++) {
      placeholder += 'x';
    }
    return placeholder.replace(/[xy]/g, c => {
      let r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
  };

  updateNutritionConfig(config: CycleConfig, user: User): Observable<boolean> {  
    if (config.startDate) {
      config.startDate.setHours(0)
      config.startDate.setMinutes(0)
      config.startDate.setSeconds(0)
      config.startDate.setMilliseconds(0)
    }
    if (config.endDate) {
      config.endDate.setHours(0)
      config.endDate.setMinutes(0)
      config.endDate.setSeconds(0)
      config.endDate.setMilliseconds(0)
    }

    var allNutritionalGoals = []
    config.situations.forEach(situation => {
      if (!situation.id) situation.id = FirestoreNutritionPlanService.generateUniqueString()
      situation.nutritionalGoals.forEach(goal => {
        goal.situationId = situation.id
        if (goal.activityCaloriesMultiplier == null) goal.activityCaloriesMultiplier = 1
        allNutritionalGoals.push(goal)
      })
    })
    var nutritionalGoals = allNutritionalGoals?.map((obj)=> {return Object.assign({}, obj)}) || null
    nutritionalGoals?.forEach(goal => {
      goal['situation'] = null
    })

    var situations = config.situations?.map((obj)=> {return Object.assign({}, obj)}) || null
    situations?.forEach(e => {
      e['nutritionalGoals'] = null
    })
    return new Observable((observer) => {
      this.firestore.collection('Users/' + user.uid + '/CycleConfigs/').doc(config.id).update({commonTargetValues: config.commonTargetValues, name: config.name, startDate: config.startDate, endDate: config.endDate, nutritionalGoals: nutritionalGoals, situations: situations, timestamp: new Date()}).then(async res => {
        observer.next(true)
        observer.complete()
      })
    })
  }
  deleteNutritionConfig(config: CycleConfig, user: User): Promise<void> {
    return this.firestore.collection('Users/' + user.uid + '/CycleConfigs/').doc(config.id).update({deleted: true, timestamp: new Date()})
  }

  // NutritionPlanConfig

  getNutritionPlanConfigs(user: User, template: PlanningTemplate): Observable<NutritionPlanConfig[]> {
    var path = template ? 'PlanningTemplates/' + template.id + '/NutritionPlanConfigs' : 'Users/' + user.uid + '/NutritionPlanConfigs/'
    return new Observable((observer) => {
      this.firestore.collection<any>(path).ref.get().then(async documents => {
        var configs = []
        for (let document of documents.docs) {
          if (document.data().deleted == null || !document.data().deleted) {
            var config = new NutritionPlanConfig(document.data() as NutritionPlanConfig)
            config.id = document.id
            if (!template) {
              config.startDate = new Date(document.data().startDate.seconds * 1000)
              if (document.data().endDate) config.endDate = new Date(document.data().endDate.seconds * 1000)
            }

            config.nutritionPlans = []
            if (document.data().nutritionPlans) {
              (document.data().nutritionPlans as NutritionPlanV2[]).forEach(e => {
                var plan = new NutritionPlanV2(e as any)
                if (e.tagRestrictions) {
                  var tags = [];
                  (e.tagRestrictions as any[] as string[]).forEach(t => {
                    tags.push(Tag.tagFromString(t))
                  })
                  plan.tagRestrictions = tags
                }
                plan.mealConfigs = []
                if (e.mealConfigs) {
                  (e.mealConfigs as NutritionPlanMealConfig[]).forEach(f => {
                    var c = new NutritionPlanMealConfig(f as any)
                    if (f.tagRestrictions) {
                      var tags = [];
                      (f.tagRestrictions as any[] as string[]).forEach(t => {
                        tags.push(Tag.tagFromString(t))
                      })
                      c.tagRestrictions = tags
                    }
                    plan.mealConfigs.push(c)
                  })
                }
                config.nutritionPlans.push(plan)
              })
            }
            if (config.nutritionPlans?.length > 0) config.selectedNutritionPlan = config.nutritionPlans[0]
            configs.push(config)
          }
        }
        observer.next(configs)
        observer.complete()
      })
    })
  }

  insertNutritionPlanConfig(config: NutritionPlanConfig, user: User, template: PlanningTemplate): Observable<boolean> {
    if (config.startDate) {
      config.startDate.setHours(0)
      config.startDate.setMinutes(0)
      config.startDate.setSeconds(0)
      config.startDate.setMilliseconds(0)
    }
    if (config.endDate) {
      config.endDate.setHours(0)
      config.endDate.setMinutes(0)
      config.endDate.setSeconds(0)
      config.endDate.setMilliseconds(0)
    }
    
    config.nutritionPlans?.forEach(plan => {
      if (!plan.id) plan.id = FirestoreNutritionPlanService.generateUniqueString()
      plan.mealConfigs?.forEach(mealConfig => {
        if (!mealConfig.id) mealConfig.id = FirestoreNutritionPlanService.generateUniqueString()
      })
    })

    var nutritionPlans = config.nutritionPlans?.map((obj)=> {return Object.assign({}, obj)}) || null
    nutritionPlans?.forEach(e => {
      var tags = []
      e.tagRestrictions.forEach(tag => {
        tags.push(tag.rawTag)
      })
      e.tagRestrictions = tags
      e.searchInputFocused = null
      e.tagSearchResults = null

      e['repeatingMeals'] = null
      e['mealConfigs'] = e.mealConfigs?.map((obj)=> {return Object.assign({}, obj)}) || null
      e['initialCycleConfig'] = null
      
      e['mealConfigs'].forEach(mealConfig => {
        var tags = []
        mealConfig.tagRestrictions.forEach(tag => {
          tags.push(tag.rawTag)
        })
        mealConfig.tagRestrictions = tags

        mealConfig.matchingRecipes = null
        mealConfig.searchInputFocused = null
        mealConfig.tagSearchResults = null
      })
    })

    return new Observable((observer) => {
      var path = template ?  'PlanningTemplates/' + template.id + '/NutritionPlanConfigs/' : 'Users/' + user.uid + '/NutritionPlanConfigs/';
      this.firestore.collection(path).add({startDate: config.startDate, endDate: config.endDate, startDayNumber: config.startDayNumber, endDayNumber: config.endDayNumber, nutritionPlans: nutritionPlans, isActive: config.isActive, coachUid: config.coachUid ?? null, licenceHolderUid: config.licenceHolderUid ?? null, timestamp: new Date()}).then(async res => {
        config.id = res.id
        observer.next(true)
        observer.complete()
      })
    })
  }

  updateNutritionPlanConfig(config: NutritionPlanConfig, user: User): Observable<boolean> {
    if (config.startDate) {
      config.startDate.setHours(0)
      config.startDate.setMinutes(0)
      config.startDate.setSeconds(0)
      config.startDate.setMilliseconds(0)
    }
    if (config.endDate) {
      config.endDate.setHours(0)
      config.endDate.setMinutes(0)
      config.endDate.setSeconds(0)
      config.endDate.setMilliseconds(0)
    }
    config.nutritionPlans?.forEach(plan => {
      if (!plan.id) plan.id = FirestoreNutritionPlanService.generateUniqueString()
      plan.mealConfigs?.forEach(mealConfig => {
        if (!mealConfig.id) mealConfig.id = FirestoreNutritionPlanService.generateUniqueString()
      })
    })

    var nutritionPlans = config.nutritionPlans?.map((obj)=> {return Object.assign({}, obj)}) || null
    nutritionPlans?.forEach(e => {
      var tags = []
      e.tagRestrictions.forEach(tag => {
        tags.push(tag.rawTag)
      })
      e.tagRestrictions = tags
      e.searchInputFocused = null
      e.tagSearchResults = null

      e['repeatingMeals'] = null
      e['mealConfigs'] = e.mealConfigs?.map((obj)=> {return Object.assign({}, obj)}) || null
      e['initialCycleConfig'] = null
      
      e['mealConfigs'].forEach(mealConfig => {
        var tags = []
        mealConfig.tagRestrictions.forEach(tag => {
          tags.push(tag.rawTag)
        })
        mealConfig.tagRestrictions = tags

        mealConfig.matchingRecipes = null
        mealConfig.searchInputFocused = null
        mealConfig.tagSearchResults = null
      })
    })
    
    return new Observable((observer) => {
      this.firestore.collection('Users/' + user.uid + '/NutritionPlanConfigs/').doc(config.id).update({startDate: config.startDate, endDate: config.endDate, nutritionPlans: nutritionPlans, isActive: config.isActive, timestamp: new Date()}).then(async res => {
        observer.next(true)
        observer.complete()
      })
    })
  }

  updateNutritionPlanConfigExpiringHint(config: NutritionPlanConfig, user: User): Promise<void> {
    return this.firestore.collection('Users/' + user.uid + '/NutritionPlanConfigs/').doc(config.id).update({expiringNutritionPlanHintDismissed: config.expiringNutritionPlanHintDismissed})
  }

  deleteNutritionPlanConfig(config: NutritionPlanConfig, user: User): Promise<void> {
    return this.firestore.collection('Users/' + user.uid + '/NutritionPlanConfigs/').doc(config.id).update({deleted: true, timestamp: new Date()})
  }

  // PLANNING TEMPLATES

  loadPlanningTemplates() {
    this.firestore.collection<any>('PlanningTemplates/').ref.where('licenceHolderUid', '==', this.userService.getLoggedInUser().licenceHolderUid || this.userService.getLoggedInUser().uid).get().then(async documents => {
      var templates = []
      for (let document of documents.docs) {
        if (document.data().deleted == null || !document.data().deleted) {
          var template = new PlanningTemplate(document.data() as PlanningTemplate)
          template.id = document.id
          templates.push(template)
        }
      }
      this.planningTemplates = templates
    })
  }

  insertPlanningTemplate(template: PlanningTemplate, coach: User): Observable<boolean> {
    return new Observable((observer) => {
      this.firestore.collection('PlanningTemplates/').add( {name: template.name, description: template.description, coachUid: template.coachUid, licenceHolderUid: template.licenceHolderUid} ).then(async res => {
        template.id = res.id
        observer.next(true)
        observer.complete()
      })
    })
  }

  deletePlanningTemplate(template: PlanningTemplate): Promise<void> {
    return this.firestore.collection('PlanningTemplates/').doc(template.id).update({deleted: true, timestamp: new Date()})
  }


  // ---------------------------------------------------------------------------

  // NUTRITION PLAN TEMPLATES (Old)

  loadNutritionPlanTemplates() {
    this.nutritionPlanTemplates = []
    this.firestore.collection<any>('NutritionPlanTemplates/').ref.where('creatorUid', '==', this.userService.getLoggedInUser().licenceHolderUid || this.userService.getLoggedInUser().uid).get().then(documents => {
      documents.forEach(document => {
        var deleted = document.data().deleted || false;
        if (!deleted) {
          var nutritionPlan = new NutritionPlan(document.data() as NutritionPlan)
          nutritionPlan.isTemplate = true
          if (document.data().carbohydrates && document.data().protein && document.data().fat && document.data().calories) {
            nutritionPlan.referenceNutritionalGoal = new NutritionalGoal()
            nutritionPlan.referenceNutritionalGoal.carbohydrates = document.data().carbohydrates
            nutritionPlan.referenceNutritionalGoal.protein = document.data().protein
            nutritionPlan.referenceNutritionalGoal.fat = document.data().fat
            nutritionPlan.referenceNutritionalGoal.calories = document.data().calories
          } else {
            nutritionPlan.referenceNutritionalGoal = null
          }
          this.nutritionPlanTemplates.push(nutritionPlan)
        }
        this.nutritionPlanTemplates.sort((a,b) => a.name.localeCompare(b.name));
      });
    });
  }
  loadNutritionPlanTemplateContent(nutritionPlan: NutritionPlan): Observable<boolean> {
    return new Observable((observer) => {
      nutritionPlan.days = []
      for (var dayNumber = 0; dayNumber < nutritionPlan.duration; dayNumber++) {
        var day = new Day()
        day.setDateAndNumber(nutritionPlan.startDate, dayNumber)
        day.initMeals(nutritionPlan.mealsPerDay)
        nutritionPlan.days.push(day)
      }
      this.firestore.collection<any>('NutritionPlanTemplates/' + nutritionPlan.id + '/PlannedMeals/').ref.get().then(async documents => {
        for (let document of documents.docs) {
          if (document.data().deleted == null || !document.data().deleted) {
            var meal = new PlannedMeal(document.data() as PlannedMeal)
            meal.id = document.id
            meal.dayNumber = document.data().dayNumber
            meal.number = document.data().number
            if (meal.mealTemplateId) this.loadRecipeImageForMeal(meal)
            nutritionPlan.days[meal.dayNumber].plannedMeals.splice(meal.number, 1, meal)
            meal.foods = await this.loadNutritionPlanTemplateMeal(meal, nutritionPlan).toPromise()
          }
        }
        observer.next(true)
        observer.complete()
      })
    })
  }
  loadNutritionPlanTemplateMeal(meal: PlannedMeal, nutritionPlan: NutritionPlan): Observable<PlannedFood[]> {
    return new Observable((observer) => {
      this.firestore.collection<any>('NutritionPlanTemplates/' + nutritionPlan.id + '/PlannedMeals/' + meal.id + '/Foods/').ref.get().then(async documents => {
        var foods = []
        for (let document of documents.docs) {
          var food = new PlannedFood(document.data() as PlannedFood)
          food.nutritionFactId = document.data().baseNutritionFactId
          food.id = document.id
          food.weight = document.data().weight
          if (document.data().isDummy != null && document.data().isDummy == true) {
            food.isDummy = true;
          }
          foods.push(food)
          BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
            if (document.data()[nutritionalValue] != null && document.data()[nutritionalValue] != undefined && document.data()[nutritionalValue].toString().length > 0) {
              food.setNutritionalValue(nutritionalValue, parseFloat(document.data()[nutritionalValue]))
            } else {
              food.setNutritionalValue(nutritionalValue, null)
            }
          })
          if (food.nutritionFactId && (food.calories == null || food.calories == 0) && !food.isDummy) {
            food.nutritionFact = await this.userService.getBaseNutritionFactById(food.nutritionFactId).toPromise()
            food.recalculateNutritionalValues()
            var data = {name: food.nutritionFact?.nameDe}
            BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
              if (food.getNutritionalValue(nutritionalValue) != null && food.getNutritionalValue(nutritionalValue) != undefined) {
                data[nutritionalValue] = food.getNutritionalValue(nutritionalValue)
              }
            })
            this.firestore.collection('NutritionPlanTemplates/' + nutritionPlan.id + '/PlannedMeals/' + meal.id + '/Foods/').doc(food.id).set(data, {merge: true})
          }
        }
        observer.next(foods)
        observer.complete()
      })
    })
  }

  // Deprecated NUTRITION PLANS

  retrieveNutritionPlanContent(nutritionPlan: NutritionPlan, user: User): Observable<boolean> {
    return new Observable((observer) => {
      nutritionPlan.days = []
      for (var dayNumber = 0; dayNumber < nutritionPlan.duration; dayNumber++) {
        var day = new Day()
        day.setDateAndNumber(nutritionPlan.startDate, dayNumber)
        day.initMeals(nutritionPlan.mealsPerDay)
        nutritionPlan.days.push(day)
      }
      this.firestore.collection<any>('Users/' + user.uid + '/Shared/' + user.getLid() + '/NutritionPlans/' + nutritionPlan.id + '/PlannedMeals/').ref.get().then(async documents => {
        for (let document of documents.docs) {
          if (document.data().deleted == null || !document.data().deleted) {
            var meal = new PlannedMeal(document.data() as PlannedMeal)
            meal.id = document.id
            meal.dayNumber = document.data().dayNumber
            meal.number = document.data().number
            if (meal.mealTemplateId) this.loadRecipeImageForMeal(meal)
            nutritionPlan.days[meal.dayNumber].plannedMeals.splice(meal.number, 1, meal)
            meal.foods = await this.retrieveNutritionPlanMeal(meal, day, nutritionPlan, user).toPromise()
          }
        }
        observer.next(true)
        observer.complete()
      })
    })
  }
  
  retrieveNutritionPlanMeal(meal: PlannedMeal, day: Day, nutritionPlan: NutritionPlan, user: User): Observable<PlannedFood[]> {
    return new Observable((observer) => {
      this.firestore.collection<any>('Users/' + user.uid + '/Shared/' + user.getLid() + '/NutritionPlans/' + nutritionPlan.id + '/PlannedMeals/' + meal.id + '/Foods/').ref.get().then(async documents => {
        var foods = []
        for (let document of documents.docs) {
          var food = new PlannedFood(document.data() as PlannedFood)
          food.nutritionFactId = document.data().baseNutritionFactId
          food.id = document.id
          food.weight = document.data().weight
          if (document.data().isDummy != null && document.data().isDummy == true) {
            food.isDummy = true;
          }
          foods.push(food)
          BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
            if (document.data()[nutritionalValue] != null && document.data()[nutritionalValue] != undefined && document.data()[nutritionalValue].toString().length > 0) {
              food.setNutritionalValue(nutritionalValue, parseFloat(document.data()[nutritionalValue]))
            } else {
              food.setNutritionalValue(nutritionalValue, null)
            }
          })
          if (food.nutritionFactId && (food.calories == null || food.calories == 0) && !food.isDummy) {
            food.nutritionFact = await this.userService.getBaseNutritionFactById(food.nutritionFactId).toPromise()
            food.recalculateNutritionalValues()
            var data = {name: food.nutritionFact?.nameDe}
            BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
              if (food.getNutritionalValue(nutritionalValue) != null && food.getNutritionalValue(nutritionalValue) != undefined) {
                data[nutritionalValue] = food.getNutritionalValue(nutritionalValue)
              }
            })
            this.firestore.collection('Users/' + user.uid + '/Shared/' + user.getLid() + '/NutritionPlans/' + nutritionPlan.id + '/PlannedMeals/' + meal.id + '/Foods/').doc(food.id).set(data, {merge: true})
          }
        }
        observer.next(foods)
        observer.complete()
      })
    })
  }

  async insertNutritionPlanTemplateMealContent(meal: PlannedMeal, nutritionPlan: NutritionPlan): Promise<boolean> {
    var promises: Promise<any>[] = []
    for (let food of meal.foods) {
      food.recalculateNutritionalValues()
      var data = {
        name: food.getName(),
        weight: food.weight,
        servingSize: food.servingSize,
        unit: food.unit,
        isDummy: food.isDummy,
        groupHeading: food.groupHeading
      }
      BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
        if (food.getNutritionalValue(nutritionalValue) != null && food.getNutritionalValue(nutritionalValue) != undefined) {
          data[nutritionalValue] = food.getNutritionalValue(nutritionalValue)
        }
      })
      if (food.id) {
        if (!food.isDummy) {
          data['baseNutritionFactId'] = food.nutritionFactId
          var promise = this.firestore.collection('NutritionPlanTemplates/' + nutritionPlan.id + '/PlannedMeals/' + meal.id + '/Foods/').doc(food.id).set(data)
          promises.push(promise)
        } else {
          data['baseNutritionFactId'] = null
          data['name'] = food.name
          var promise = this.firestore.collection('NutritionPlanTemplates/' + nutritionPlan.id + '/PlannedMeals/' + meal.id + '/Foods/').doc(food.id).set(data)
          promises.push(promise)
        }
      } else {
        if (!food.isDummy) {
          data['baseNutritionFactId'] = food.nutritionFactId
          var promise = this.firestore.collection('NutritionPlanTemplates/' + nutritionPlan.id + '/PlannedMeals/' + meal.id + '/Foods/').add(data).then(res => {
            food.id = res.id
          })
          promises.push(promise)
        } else {
          data['baseNutritionFactId'] = null
          data['name'] = food.name
          var promise = this.firestore.collection('NutritionPlanTemplates/' + nutritionPlan.id + '/PlannedMeals/' + meal.id + '/Foods/').add(data).then(res => {
            food.id = res.id
          })
          promises.push(promise)
        }
      }
    }
    await Promise.all(promises)
    return Promise.resolve(true)
  }

  deleteNutritionPlanTemplate(nutritionPlan: NutritionPlan) {
    this.firestore.collection('NutritionPlanTemplates/').doc(nutritionPlan.id).update({deleted: true}).then(res => {
      this.nutritionPlanTemplates.forEach( (item, index) => {
        if (item.id === nutritionPlan.id) this.nutritionPlanTemplates.splice(index, 1);
      });
    })
  }

  loadRecipeImageForMeal(meal: PlannedMeal) {
    this.fireStorage.ref("meal_templates/" + meal.mealTemplateId + "/thumbnail.jpg").getDownloadURL().subscribe(res => {
      meal.imageURL = res
    }, error => {})
  }

  async fetchMealImageUrl(meal: PlannedMealV2): Promise<string> {
    try {
      var url = await this.fireStorage.ref(meal.getThumbnailPath()).getDownloadURL().toPromise()
      return Promise.resolve(url)
    } catch (error) {
      return Promise.reject()
    }
  }
}
