import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ComponentFactory, ComponentFactoryResolver, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { NutritionalValueHolder } from 'src/app/model/basenutritionfact.model';
import { Food } from 'src/app/model/food.model';
import { Ingredient } from 'src/app/model/ingredient.model';
import { Meal } from 'src/app/model/meal.model';
import { MealType, MealTypeTranslation } from 'src/app/model/nutritionplanv2.model';
import { PlannedFood } from 'src/app/model/plannedfood.model';
import { PlannedMeal } from 'src/app/model/plannedmeal.model';
import { PlannedMealV2 } from 'src/app/model/plannedmealv2.model';
import { NutritionalValuePopoverComponent } from 'src/app/nutritional-value-popover/nutritional-value-popover.component';
import { EditUserDataService } from 'src/app/services/edit-user-data.service';
import { FirestoreNutritionPlanService } from 'src/app/services/firestore-nutritionplan.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { LanguageService } from 'src/app/services/language.service';
import { NutritionService } from 'src/app/services/nutrition.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-tracked-meal-editor-dialog',
  templateUrl: './tracked-meal-editor-dialog.component.html',
  styleUrls: ['./tracked-meal-editor-dialog.component.css']
})
export class TrackedMealEditorDialogComponent {

  public meal: Meal
  public readOnlyMode: boolean = false;

  editorValueInstructions: string;
  forceEditorValueNoteUpdate: boolean = false;
  forceEditorValueInstructionsUpdate: boolean = false;
  editorValueNote: string;
  placeholderInstructions = 'Zubereitung...'
  placeholderNote = 'Hinweis/Notiz o. Ä. für Kund:in'
  public componentFactory: ComponentFactory<any>

  public mealTypeTranslations = MealTypeTranslation
  public mealTypes = Object.keys(MealType)

  constructor(public dialogRef: MatDialogRef<TrackedMealEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {meal: Meal, readOnlyMode: boolean}, private nutritionPlanService: FirestoreNutritionPlanService, private nutritionService: NutritionService, private userService: FirestoreService, private componentFactoryResolver: ComponentFactoryResolver, public dialog: MatDialog, private languageService: LanguageService, private toastr: ToastrService, public utilityService: UtilityService) {
    this.meal = data.meal || new Meal()
    this.readOnlyMode = data.readOnlyMode || false
    
    this.loadNutritionFacts()
  }

  ngOnInit(): void {
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(NutritionalValuePopoverComponent)
    this.editorValueInstructions = this.meal.instructions;
    this.editorValueNote = this.meal.comment

    this.forceEditorValueInstructionsUpdate = true
    this.forceEditorValueNoteUpdate = true
  }

  async loadNutritionFacts() {
    for (var food of this.meal.foods) {
      if (food.nutritionFactId) food.nutritionFact = await this.userService.getBaseNutritionFactById(food.nutritionFactId).toPromise()
      if (food.nutritionFact) food.recalculateNutritionalValues()
      console.log(food.vitaminC)
    }
    this.meal.recalculateNutritionalValues()
    console.log(this.meal.getNutritionalValue('vitaminC'))
  }

  onSavePlannedMeal() {
    if(this.meal.mealType == null || this.meal.mealType == "" || this.meal.mealType == "none") {
      this.toastr.error("Bitte wähle einen Mahlzeitentyp aus.", "",  {
        positionClass: 'toast-bottom-center'
      });
      return
    }
    this.meal.instructions = this.editorValueInstructions
    this.meal.comment = this.editorValueNote
    this.onCloseDialog(true, false)
  }
  onDeletePlannedMealImage() {
    this.meal.imagePath = null
    this.meal.imageLink = null
  }
  onDeletePlannedMeal() {
    
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: 'Möchtest du diese Mahlzeit wirklich löschen?', title: 'Mahlzeit löschen' },
    });
    confirmationDialogRef.afterClosed().subscribe(async confirmationResult => {
      if (confirmationResult) {
        this.onCloseDialog(false, true)
      }
    });
  }
  onAddPlannedFood(food: PlannedFood) {
    if(!food.getName()){
      this.toastr.error("Bitte gib einen Namen ein.", "Fehler", {
        positionClass: 'toast-bottom-center'
      });
      return;
    }
    console.log('Add food')
    this.meal.foods.push(EditUserDataService.plannedFoodToFood(food, this.languageService.selectedLanguageCode))
    console.log(food.nutritionFact)
    this.meal.recalculateNutritionalValues()
    this.meal.getNutritionalValue('vitaminC')
  }

  getMealTypeTranslation(mealType: string) {
    return MealTypeTranslation[MealType[mealType]] || "Typ auswählen"
  }

  onMealTypeSelected(mealType: MealType) {
    this.meal.mealType = mealType
  }
  async onAddPlannedMeal(plannedMeal: PlannedMealV2) {
    await this.nutritionService.loadBaseRecipeForPlannedMeal(plannedMeal)
    this.meal.name = plannedMeal.getName()
    // if (!this.meal.mealTemplateId) this.meal.mealTemplateId = plannedMeal.mealTemplateId
    if (!this.meal.imagePath) 
    {
      this.meal.imageLink = plannedMeal.imageURL
      this.meal.imagePath = plannedMeal.getThumbnailPath()
    }
    // this.meal.thumbnailPath = plannedMeal.thumbnailPath ?? null
    this.editorValueInstructions = this.editorValueInstructions ? this.editorValueInstructions + '\n\n\n' + '**' + plannedMeal.getName() + '**' + '\n\n' + (plannedMeal.getInstructions() || '') : plannedMeal.getInstructions()
    this.meal.instructions = this.editorValueInstructions
    if (plannedMeal.note) {
      if (this.editorValueNote) this.editorValueNote = this.editorValueNote + '\n' + (plannedMeal.note || "")
      else{
        this.editorValueNote = plannedMeal.note
      }
    }
    plannedMeal.getFoods().forEach(food => {
      this.meal.foods.push(EditUserDataService.plannedFoodToFood(food, this.languageService.selectedLanguageCode))
    })
    this.meal.recalculateNutritionalValues()
  }
  onDeleteFood(food: Food) {
    this.meal.foods.forEach( (item, index) => {
      if (item == food) this.meal.foods.splice(index, 1);
    })
    this.meal.recalculateNutritionalValues()
  }
  onPlannedFoodWeightChanged(text: string, food: Food) {
    var regex = new RegExp("^[0-9]+$");

    if (food.weight != null && food.weight > 0 && food.tmpPer100Calories == null) {
      food.tmpPer100Calories = food.calories / food.weight * 100
      food.tmpPer100Carbohydrates = food.carbohydrates / food.weight * 100
      food.tmpPer100Protein = food.protein / food.weight * 100
      food.tmpPer100Fat = food.fat / food.weight * 100
    }
    let weight = 0;
    if (regex.test(text) && parseInt(text) > 0)  {
      var number = parseInt(text);
      weight = number
    }
    // food.recalculateNutritionalValues()
    food.setWeightAndRecalculateNutritionalValues(weight);
    this.meal.recalculateNutritionalValues()
  }
  onEditorValueInstructionsChanged(value: string) {
    if (value == this.placeholderInstructions) {
      this.editorValueInstructions = ''
    } else {
      this.editorValueInstructions = value
    }
  }
  onEditorValueNoteChanged(value: string) {
    if (value == this.placeholderNote) {
      this.editorValueNote = ''
    } else {
      this.editorValueNote = value
    }
  }
  onClosePlannedMeal() {
    this.onCloseDialog(false, false)
  }

  dropFood(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.meal.foods, event.previousIndex, event.currentIndex);
  }

  public nutritionalValueHolder: NutritionalValueHolder
  onNutritionalValuesFocused(nutritionalValueHolder: NutritionalValueHolder) {
    this.nutritionalValueHolder = nutritionalValueHolder
  }
  
  onAddGroupHeading(ingredient: Ingredient) {
    ingredient.groupHeading = 'Gruppe'
  }
  onDeleteGroupHeading(ingredient: Ingredient) {
    ingredient.groupHeading = null
  }

  getTimeOfDate(){
    if(this.meal.date == null) return ""
    return (this.meal.date.getHours() < 10 ? '0' : '') + this.meal.date.getHours() + ":" + (this.meal.date.getMinutes() < 10 ? '0' : '') + this.meal.date.getMinutes()
  }

  setTimeOfDate(value: string){
    let split = value?.split(":")
    if(split?.length != 2) return
    this.meal.date.setHours(parseInt(split[0]))
    this.meal.date.setMinutes(parseInt(split[1]))
  }

  onMealNameChanged(value: string) {
    this.meal.name = value
  }

  onCloseDialog(shouldSave: boolean, shouldDelete: boolean) {
    this.dialogRef.close({shouldSave: shouldSave, shouldDelete: shouldDelete, meal: this.meal});
  }
}
