<div class="row margin-0">
  <div *ngIf="!environment.isWhitelabel" class="col-12 col-md-5 col-lg-4 ad-column">
    <div class="ad-wrapper">
      <img draggable="false" class="logo" src="assets/images/nutrilize_for_Coaches_Logo.png">
      <div class="spacer-50"></div>
      <h2 translate>Möchtest du nutrilize für dein Coaching testen?</h2>
      <ng-container *ngIf="!utilityService.onNativeMobileApp()">
        <h3 translate>Schau auf unserer Website vorbei:</h3>
        <div class="spacer-25"></div>
        <button class="button button-filled white" (click)="goToWebsite()" translate>Zu unserer Website</button>
      </ng-container>
      <ng-container *ngIf="utilityService.onNativeMobileApp()">
        <h3 translate>Mehr dazu findest du unter www.nutrilize.app</h3>
      </ng-container>
      <div class="spacer-50"></div>
    </div>
    <img draggable="false" class="mockup" src="assets/images/Gruppe 397.png">
    <div *ngIf="!environment.isWhitelabel && utilityService.onMobile()" class="wrapper-switch-coachingzone-mobile">
      <div class="label link" (click)="onChangeCoachingZone()" translate>Coaching Zone wechseln</div>
    </div>
  </div>
  <div class="col-12 {{environment.isWhitelabel ? '' : 'col-md-7 col-lg-8'}}">
    <div class="container pt-3">
      <div class="row justify-content-sm-center">
          <div class="col-12">
            <div class="branding">
              <img draggable="false" class="logo" src="{{environment.loginImagePath}}">
            </div>
          </div>
          <div class="col-12">
            <div class="layout-box login">
              <h2 translate>Login</h2>
              <div class="">
                <app-login></app-login>
              </div>
            </div>
            <div class="spacer-50"></div>
          </div>
      </div>
      <div *ngIf="false && !environment.isWhitelabel && !utilityService.onMobile()" class="wrapper-switch-coachingzone-desktop">
        <div class="label link" (click)="onChangeCoachingZone()" translate>Coaching Zone wechseln</div>
      </div>
    </div>
  </div>
</div>

<div class="language-selector-wrapper">
  <app-global-language-selector customDropdownMenuClass="'dropdown-menu-right'"></app-global-language-selector>
</div>