<div class="row">
  <div class="col-12">
    <div *ngIf="utilityService.onNativeMobileApp()" class="layout-box">
      <h3 class="display-inlineblock" translate>Rechnungen</h3><br>
      <span translate>Rufe die Coaching Zone im Browser auf und melde dich an, um auf die Zahlungen zuzugreifen.</span>
    </div>

    <div *ngIf="!utilityService.onNativeMobileApp()" class="layout-box table-wrapper-box">

      <div class="row">
        <div class="col-3"></div>
        <div class="col-6 textalign-center tab-button-group adjust-mobile">
          <button class="tab-button {{currentTab == tabPayments ? 'active' : ''}}" (click)="onChangeTab(tabPayments)">
            <span translate>Rechnungen</span>
            <div class="underline"></div>
          </button>
          <button class="tab-button {{currentTab == tabSubscriptions ? 'active' : ''}}"
                  (click)="onChangeTab(tabSubscriptions)">
            <span translate>Käufe</span>
            <div class="underline"></div>
          </button>
          <button class="tab-button {{currentTab == tabSubscriptionAnalytics ? 'active' : ''}}"
                  (click)="onChangeTab(tabSubscriptionAnalytics)">
            <span translate>Statistik</span>
            <div class="underline"></div>
          </button>
        </div>
        <div class="col-3">
          <div *ngIf="currentTab == tabPayments">
            <div class="display-inlineblock marginleft-10 float-right">
              <div class="icon-button" mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                     class="bi bi-gear padding-4" viewBox="0 0 16 16">
                  <path
                    d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0"/>
                  <path
                    d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z"/>
                </svg>
                <span class="only-desktop" translate>Weitere Aktionen</span>
              </div>
              <mat-menu #menu="matMenu" class="padding-15">
                <div class="icon-button margin-4 mobile-marginbottom-10" (click)="onExportInvoices()" translate>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-download" viewBox="0 0 16 16">
                    <path
                      d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                    <path
                      d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                  </svg>
                  Rechnungen herunterladen
                </div>
                <div class="spacer-10"></div>
                <div class="icon-button margin-4 mobile-marginbottom-10" (click)="onExportPaymentTable()" translate>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-download" viewBox="0 0 16 16">
                    <path
                      d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                    <path
                      d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                  </svg>
                  Tabelle herunterladen
                </div>
                <div class="spacer-10"></div>
                <div class="icon-button margin-4 mobile-marginbottom-10" (click)="onCreatePayoutReport()" translate>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bank"
                       viewBox="0 0 16 16">
                    <path
                      d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.5.5 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89zM3.777 3h8.447L8 1zM2 6v7h1V6zm2 0v7h2.5V6zm3.5 0v7h1V6zm2 0v7H12V6zM13 6v7h1V6zm2-1V4H1v1zm-.39 9H1.39l-.25 1h13.72z"/>
                  </svg>
                  Auszahlungsabgleich
                </div>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
      <div class="spacer-25"></div>

      <div *ngIf="currentTab == tabPayments" class="table payments-table">

        <button *ngIf="paymentService.canUsePayment" class="button-filled small" (click)="onCreatePayment()" translate>
          Erstellen
        </button>
        <div class="spacer-25"></div>

        <div class="row table-item table-header">
          <div class="col" translate>
            Betrag
          </div>
          <div class="col">
            <span translate>Status</span>
            <app-filter-selection-dropdown class="marginleft-10" [availableFilterObjects]="availablePaymentStatuses"
                                           [selectedLanguage]="translate.currentLang"></app-filter-selection-dropdown>
          </div>
          <div class="col">
            <span translate>Kunde</span>
            <app-filter-dual-selection-dropdown class="marginleft-10" [hasSearchField]="true"
                                                [availableFilterObjects1]="availableGroups"
                                                [availableFilterObjects2]="availableClients" [labelFilter1]="'Gruppen'"
                                                [labelFilter2]="'Kunden'" [selectedLanguage]="'de'"
                                                (onFilterOpened)="onOpenClientsFilter()"></app-filter-dual-selection-dropdown>
          </div>
          <div class="col" translate>
            Datum
          </div>
          <div class="col" translate>
            Rechnung
          </div>
          <div class="col"
               [matTooltip]="'Das Auszahlungsdatum wird erst gesetzt, wenn du einen Auszahlungsabgleich durchgeführt hast. Diesen findest du oben rechts unter Weitere Aktionen.' | translate">
            <span translate>Auszahlungsdatum</span>
            <app-filter-selection-dropdown class="marginleft-10" [hasSearchField]="true"
                                           [availableFilterObjects]="availablePayoutDates" [selectedLanguage]="'de'"
                                           (onFilterOpened)="onOpenPayoutDatesFilter()"></app-filter-selection-dropdown>
          </div>
          <div class="col">
            <span translate>Einzugsdatum</span>
            <app-filter-selection-dropdown class="marginleft-10" [hasSearchField]="true"
                                           [availableFilterObjects]="availableReversePayoutDates"
                                           [selectedLanguage]="'de'"
                                           (onFilterOpened)="onOpenReversePayoutDatesFilter()"></app-filter-selection-dropdown>
          </div>
        </div>

        <div class="row table-item link" *ngFor="let payment of payments" (click)="onSelectPayment(payment)">
          <div class="col">
            {{ payment.getPrintablePriceWithCurrency() }}
            <div *ngIf="payment.status == 'voided'">-{{ payment.getPrintablePriceWithCurrency() }} {{'(Storno)' | translate}}</div>
          </div>
          <div class="col">
            <div *ngIf="payment.getPrintableStatus(translate)" class="status-indicator-wrapper" [tooltip]="payment.getPrintableStatusInfo(translate)">
              <div class="status-indicator {{payment.status}}"></div>
              {{ payment.getPrintableStatus(translate) }}
            </div>
          </div>
          <div class="col">
            {{ payment.customer?.getName() ?? ('Unbekannter Kunde'  | translate)}}
          </div>
          <div class="col">
            {{ payment.getDate().asFormatedString() }}
          </div>
          <div class="col">
            <div class="link" (click)="onOpenInvoice(payment);$event.stopPropagation()">{{ payment.invoiceNumber }}
            </div>
            <div *ngIf="payment.cancellationInvoiceNumber" class="link"
                 (click)="onOpenCancellationInvoice(payment);$event.stopPropagation()">{{ payment.cancellationInvoiceNumber }}
              {{'(Storno)
            ' | translate}}</div>
          </div>
          <div class="col">
            {{ payment.payoutDate?.asFormatedString() }}
          </div>
          <div class="col">
            {{ payment.reversePayoutDate?.asFormatedString() }}
          </div>
        </div>

        <div class="row table-item">
          <div class="col">
            {{ getTotalAmount() }} €
          </div>
          <div class="col">
            {{ payments.length }} {{ payments.length == 1 ? ('Rechnung' | translate) : ('Rechnungen'  | translate)}}
          </div>
          <div class="col">
          </div>
          <div class="col">
          </div>
          <div class="col">
          </div>
          <div class="col">
          </div>
        </div>
      </div>

      <div *ngIf="currentTab == tabSubscriptions" class="table purchase-table">

        <button *ngIf="paymentService.canUsePayment" class="button-filled small" (click)="onCreatePurchase()" translate>
          Erstellen
        </button>
        <div class="spacer-25"></div>

        <div class="row table-item table-header">
          <div class="col" translate>
            ID
          </div>
          <div
            class="col sort-attribute {{purchasesSortAttribute == 'customer' ? 'active' : ''}} {{purchasesSortDirection}}"
            (click)="onPurchaseSortChange('customer')">
            <span translate>Kunde</span>
            <app-filter-dual-selection-dropdown class="marginleft-10" [hasSearchField]="true"
                                                [availableFilterObjects1]="availableGroups"
                                                [availableFilterObjects2]="availableClients" [labelFilter1]="'Gruppen'"
                                                [labelFilter2]="'Kunden'" [selectedLanguage]="'de'"
                                                (onFilterOpened)="onOpenClientsFilter()"></app-filter-dual-selection-dropdown>
          </div>
          <div
            class="col sort-attribute {{purchasesSortAttribute == 'product' ? 'active' : ''}} {{purchasesSortDirection}}"
            (click)="onPurchaseSortChange('product')">
            <span translate>Produkt</span>
            <app-filter-selection-dropdown class="marginleft-10" [hasSearchField]="true"
                                           [availableFilterObjects]="availableProducts" [selectedLanguage]="'de'"
                                           (onFilterOpened)="onOpenProductsFilter()"></app-filter-selection-dropdown>
          </div>
          <div class="col">
            <span translate>Status</span>
            <app-filter-selection-dropdown class="marginleft-10" [availableFilterObjects]="availablePurchaseStatuses"
                                           [selectedLanguage]="translate.currentLang"></app-filter-selection-dropdown>
          </div>
          <div
            class="col sort-attribute {{purchasesSortAttribute == 'nextPaymentDate' ? 'active' : ''}} {{purchasesSortDirection}}"
            (click)="onPurchaseSortChange('nextPaymentDate')" translate>
            Nächste Zahlung
          </div>
          <div
            class="col sort-attribute {{purchasesSortAttribute == 'startDate' ? 'active' : ''}} {{purchasesSortDirection}}"
            (click)="onPurchaseSortChange('startDate')" translate>
            Start
          </div>
          <div
            class="col sort-attribute {{purchasesSortAttribute == 'endDate' ? 'active' : ''}} {{purchasesSortDirection}}"
            (click)="onPurchaseSortChange('endDate')" translate>
            Ende
          </div>
          <div class="col" translate>
            Aktion
          </div>
        </div>

        <div class="row table-item link" *ngFor="let subscription of productPurchases"
             (click)="onSelectProductPurchase(subscription)">
          <div class="col label">
            {{ subscription.id }}
          </div>
          <div class="col">
            {{ subscription.customer?.getName() ?? subscription.licence?.presetName ?? subscription.licence?.email ?? ('Unbekannter Kunde'  | translate)}}
          </div>
          <div class="col">
            {{ subscription.getProductName() ?? ('Unbekanntes Produkt'  | translate)}}
          </div>
          <div class="col">
            <div *ngIf="subscription.getPrintableStatus(translate)" class="status-indicator-wrapper">
              <div class="status-indicator {{subscription.status}}"></div>
              {{ subscription.getPrintableStatus(translate) }}
            </div>
          </div>
          <div class="col">
            <div *ngIf="subscription.status != 'paused'">{{ subscription.nextPaymentDate?.asFormatedString() }}</div>
          </div>
          <div class="col">
            {{ subscription.getPrintableStartDate(translate) }}
          </div>
          <div class="col">
            {{ subscription.getPrintableEndDate(translate) }}
          </div>
          <div class="col">
            <div *ngIf="subscription.status == 'uncompleted'" class="icon-button small margin-horizontal-10"
                 (click)="onEditSubscription(subscription);$event.stopPropagation()" translate>
              <i class="bi bi-arrow-up-right fontsize-18"></i>
              Erneut einladen
            </div>
            <div *ngIf="(subscription.status == 'purchased' || subscription.status == 'active') && !subscription.endDate"
              class="icon-button small margin-horizontal-10"
              (click)="onCancelSubscription(subscription);$event.stopPropagation()" translate>
              <i class="bi bi-x"></i>
              Verlängerung kündigen
            </div>
            <div *ngIf="subscription.status == 'purchased' && subscription.licenceId && !subscription.licence?.active"
                 class="icon-button small margin-horizontal-10"
                 (click)="onActivateLicence(subscription);$event.stopPropagation()" translate>
              <i class="bi bi-send fontsize-18"></i>
              Sofort aktivieren
            </div>
            <div *ngIf="(subscription.status == 'purchased' || subscription.status == 'active')"
                 class="icon-button small margin-horizontal-10"
                 (click)="onDeleteSubscription(subscription);$event.stopPropagation()" translate>
              <i class="bi bi-x"></i>
              Sofort löschen
            </div>
            <div *ngIf="subscription.status == 'uncompleted' || subscription.status == 'draft'"
                 class="icon-button small margin-horizontal-10"
                 (click)="onDeleteSubscription(subscription);$event.stopPropagation()" translate>
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-x"
                   viewBox="0 0 16 16">
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
              Löschen
            </div>
            <div *ngIf="subscription.status == 'active'"
                 class="icon-button small margin-horizontal-10"
                 (click)="onPauseProductPurchase(subscription);$event.stopPropagation()" translate>
              <i class="bi bi-pause"></i>
              Pausieren
            </div>
            <div *ngIf="subscription.status == 'paused'"
                 class="icon-button small margin-horizontal-10"
                 (click)="onResumeProductPurchase(subscription);$event.stopPropagation()" translate>
              <i class="bi bi-play"></i>
              Fortsetzen
            </div>
          </div>
        </div>

        <div class="row table-item">
          <div class="col">
            {{ productPurchases.length }} {{ productPurchases.length == 1 ? ('Käufe' | translate) : ('Käufe'  | translate)}}
          </div>
          <div class="col">
          </div>
          <div class="col">
          </div>
          <div class="col">
          </div>
          <div class="col">
          </div>
          <div class="col">
          </div>
          <div class="col">
          </div>
          <div class="col">
          </div>
          <div class="col">
          </div>
        </div>
      </div>

      <div *ngIf="currentTab == tabSubscriptionAnalytics" class="table">
        <app-payment-analytics [User]="user"></app-payment-analytics>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(0,0,0,0.33)" size="default" color="var(--accentColor)" type="ball-clip-rotate"
             [fullScreen]="true">
  <div *ngIf="spinnerText" class="spinner-overlaytext" [innerHTML]="spinnerText"></div>
</ngx-spinner>
