import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProfileSettingsDialogComponent } from 'src/app/dialogs/profile-settings-dialog/profile-settings-dialog.component';
import { QuestionaireResult } from 'src/app/model/questionaires.model';
import { TrainingPlan } from 'src/app/model/training-plan.model';
import { User } from 'src/app/model/user.model';
import { CompletedQuestionaireResultsDialogComponent } from 'src/app/questionaire/completed-questionaire-results-dialog/completed-questionaire-results-dialog.component';
import { FirestoreService } from 'src/app/services/firestore.service';
import { TrainingService } from 'src/app/services/training.service';
import { UtilityService } from 'src/app/services/utility.service';
import { RpeTableDialogComponent } from 'src/app/training/rpe-table-dialog/rpe-table-dialog.component';
import { environment } from 'src/environments/environment';
import { ClientBaseComponent } from '../client-base/client-base.component';
import { items } from 'fusioncharts';

@Component({
  selector: 'app-client-v2',
  templateUrl: './client-v2.component.html',
  styleUrls: ['./client-v2.component.css']
})
export class ClientV2Component {

  public environment = environment

  get user() {
    return this.userService.getLoggedInUser()
  }

  public displayedUser: User
  public loaded = false
  public selectedTrainingPlan: TrainingPlan

  @Input() set setDisplayedUser(value: User) {
    this.displayedUser = value
    if (this.displayedUser) {
      this.loadData()
      this.init()
    }
  }

  init() {
    setTimeout(() => {
      var list = document.querySelector(".list");
      if (list) {
        const observer = new IntersectionObserver(this.onIntersectionObserved, {
          root: list,
          threshold: 0.6
        });
        
        Array.from(document.querySelectorAll(".viewport .list .item"))?.forEach(item => {
          observer.observe(item);
        });
      }
    }, 4000);
  }

  onIntersectionObserved(entries) {
    entries.forEach(entry => {
      // On page load, firefox claims item with index 1 isIntersecting,
      // while intersectionRatio is 0
      if (entry.isIntersecting && entry.intersectionRatio >= 0.6) {
        const intersectingIndex = Array.from(document.querySelectorAll(".viewport .list .item"))?.indexOf(entry.target);
        Array.from(document.querySelectorAll(".indicator"))?.forEach((indicator, i) => {
          indicator.classList.toggle("active", i === intersectingIndex);
        });
      }
    });
  }

  async loadData() {
    await this.userService.loadLicenceSettingsForUser(this.displayedUser)
    await this.userService.loadTrainingPlans(this.displayedUser, this.user)
    await this.userService.loadAssignedMetricsForUser(this.displayedUser)
    if (!this.displayedUser.dailyConditions || this.displayedUser.dailyConditions.length == 0) {
      await this.loadDailyConditionsForUser(this.displayedUser)
    }

    var assignedQuestionaireId = this.route.snapshot.queryParamMap.get("assignedQuestionaire");
    if (assignedQuestionaireId) {
      if (this.displayedUser.questionaireResults == null || this.displayedUser.questionaireResults.length == 0) {
        let dailyConditionSubscription = this.displayedUser.dailyConditionChanged.subscribe(() => {
          dailyConditionSubscription?.unsubscribe();
          this.openQuestionaireDialog(assignedQuestionaireId, this.displayedUser);
        });
      }
      else {
        this.openQuestionaireDialog(assignedQuestionaireId, this.displayedUser);
      }
    }

    this.loaded = true
  }
  openQuestionaireDialog(assignedQuestionaireId: string, user: User) {
    var questionaireResult = this.displayedUser.questionaireResults.find(x => x.assignedQuestionaire?.id == assignedQuestionaireId)
    if (questionaireResult) {
      const dialogRef = this.dialog.open(CompletedQuestionaireResultsDialogComponent, { data: { selectedQuestionaireResults: [questionaireResult], allAvailableQuestionaireResults: user.questionaireResults, user: user }, autoFocus: false });
    }
  }

  constructor(public userService: FirestoreService, public utilityService: UtilityService, private router: Router, public dialog: MatDialog, private route: ActivatedRoute, public trainingService: TrainingService) {
    console.log(router.url)
  }

  private subscriptionDailyCondition: Subscription

  async loadDailyConditionsForUser(user: User) {
    if (this.subscriptionDailyCondition) {
      this.subscriptionDailyCondition.unsubscribe()
      this.subscriptionDailyCondition = null
    }
    this.subscriptionDailyCondition = this.userService.getDailyConditionsForUser(user).subscribe(async dailyConditions => {
      user.dailyConditions = dailyConditions.sort((a, b) => b.date.getTime() - a.date.getTime());
    })
  }

  isClientsAnalyticsMenuItemActive() {
    return this.router.url.includes('analytics')
  }
  isClientsTrainingMenuItemActive() {
    return this.router.url.includes('training') || !this.router.url.includes('?page=')
  }
  isClientsNutritionMenuItemActive() {
    return this.router.url.includes('nutrition')
  }
  openTraining() {
    this.navigateToClientPage('training')
  }
  openAnalytics() {
    this.navigateToClientPage('analytics')
  }
  openNutrition() {
    this.navigateToClientPage('nutrition')
  }

  async navigateToClientPage(page: string) {
    if(await this.canDeactivateClientPage()) {
      var userUid = this.router.url.split('client/')[1]
      if (userUid?.includes('?')) userUid = userUid.split('?')[0]
      if (!userUid || userUid == undefined) return null
      this.router.navigateByUrl('client/' + userUid + '?page=' + page)
    }
  }

  async canDeactivateClientPage() {
    if (environment.firebaseProjectId == 'traindoo-app' && this.trainingService.currentEditedTrainingPlanHasChanges) {
      return await ClientBaseComponent.showDeactivateTrainingDialog(this.dialog);
    }

    return true;
  }

  public nutritionPlanOpened: boolean = false
  onNutritionPlanOpened(value: boolean) {
    this.nutritionPlanOpened = value
  }

  isNutritionEnabled() {
    if (this.displayedUser.nutritionEnabled != null) return this.displayedUser.nutritionEnabled
    return this.userService.getLoggedInUser().isNutritionEnabled()
  }

  openUserProfile() {
    var dialog = this.dialog.open(ProfileSettingsDialogComponent, { data: { user: this.displayedUser }, width: '600px' });
  }

  onSelectedTrainingPlanChanged(trainingPlan: TrainingPlan) {
    this.selectedTrainingPlan = trainingPlan
  }

}
