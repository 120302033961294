import { map } from 'rxjs/operators';
import { FirestoreService } from 'src/app/services/firestore.service';
import { _ViewRepeaterOperation } from '@angular/cdk/collections';
import { Component, NO_ERRORS_SCHEMA, OnInit } from '@angular/core';
// import { firestore } from 'firebase';
import { Observable, Subscription, scheduled } from 'rxjs';
import { allowedNodeEnvironmentFlags } from 'process';
// import { NgAisInfiniteHitsModule } from 'angular-instantsearch';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AutomaticPushNotification, DropdownItem, Label2RepetitionMapping, Repetition, Repetition2LabelMapping } from 'src/app/model/automatic-push-notification.model';
import {MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { Coach } from 'src/app/model/coach.model';
import { User } from 'src/app/model/user.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-push-notification-settings',
  templateUrl: './push-notification-settings.component.html',
  styleUrls: ['./push-notification-settings.component.css']
})
export class PushNotificationSettingsComponent implements OnInit {

  constructor(public userService: FirestoreService, private toastr: ToastrService, public dialog: MatDialog, private translate: TranslateService){}
  
  private subscriptionCoaches: Subscription

  public selectedPushNotification: AutomaticPushNotification;
  public selectableGroupNames: DropdownItem[] = [];
  public selectableClients: DropdownItem[] = [];
  public selectableCoaches: Coach[] = []

  public selectedExecutionDate: string;


  public repetitions = Object.values(Repetition).filter(value => typeof value === 'string');
  public repetitionLabels = Object.values(Repetition2LabelMapping).filter(value => typeof value === 'string');
  public repetition2LabelMapping = Repetition2LabelMapping;
  public label2RepetitionMapping = Label2RepetitionMapping;
  public scheduledPushNotifications: AutomaticPushNotification[] = [];
  public observablePushNotifications: Observable<AutomaticPushNotification[]>;

  ngOnInit(): void {
    this.selectedPushNotification = null
    this.observablePushNotifications = this.userService.getScheduledPushNotifications(this.userService.getLoggedInUser()?.coach.licenceHolderUid);
    this.loadCoaches()
  }

  loadCoaches() {
    if (this.subscriptionCoaches) this.subscriptionCoaches.unsubscribe()
    this.subscriptionCoaches = this.userService.getAllCoachesByLicenceHolderUid(this.userService.getLoggedInUser().coach.licenceHolderUid).subscribe(coaches => {
      this.selectableCoaches = coaches
    })
  }

  canAccessGroup(group: string) {
    return this.userService.getLoggedInUser().coach.canAccessClientGroup(group)
  }
  canAccessCoachGroup(coach: Coach) {
    return this.userService.getLoggedInUser().coach.canAccessCoachClients(coach)
  }
  canAccessUser(user: User) {
    return this.userService.getLoggedInUser().coach.canAccessUser(user)
  }

  getScheduledPushNotifications():AutomaticPushNotification[]{
    return this.scheduledPushNotifications;
  }

  getSelectableClients() {
    return this.userService.getAccessibleClients()
  }

  getSelectableClientGroups() {
    return this.userService.getClientGroups()
  }

  openPushNotificationEditor(){
    this.selectableClients = []
    this.selectableGroupNames = [new DropdownItem('', 'Alle', this.selectedPushNotification.groupNames.includes('Alle'))]
    this.userService.getAccessibleClients().forEach(client => {
      this.selectableClients.push(new DropdownItem(client.uid, client.getName(), this.selectedPushNotification.userUids.includes(client.uid)))
    });
    this.userService.getClientGroups().forEach(group => {
      this.selectableGroupNames.push(new DropdownItem('', group, this.selectedPushNotification.groupNames.includes(group)))
    });
    this.selectedExecutionDate = this.toDateString(this.selectedPushNotification.nextExecutionDate);
    document.getElementById('createPushNotification-dialog').classList.toggle('show');
  }

  onCreatePushNotification(){
    this.selectedPushNotification = new AutomaticPushNotification();
    this.selectedPushNotification.active = true;
    this.selectedPushNotification.repetition = Repetition.once;
    this.openPushNotificationEditor();
  }

  onDeletePushNotification(notification:AutomaticPushNotification){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: this.translate.instant('Möchtest du diese Erinnerung wirklich löschen?'), title: this.translate.instant('Erinnerung löschen') },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.deleteScheduledPushNotification(notification);
      }
    });
  }

  onEditPushNotification(notification: AutomaticPushNotification){
    this.selectedPushNotification = new AutomaticPushNotification(notification)
    this.openPushNotificationEditor();
  }

  onNewExecutionDateChanged(date: string){
    this.selectedPushNotification.nextExecutionDate = this.parseDateString(date)

  }
  onRepetitionMultiplierChanged(multiplier: string){
    this.selectedPushNotification.repetitionMultiplier = parseInt(multiplier, 10);
  }

  cancelEditPushNotification(){
    document.getElementById('createPushNotification-dialog').classList.remove('show');
    this.selectedPushNotification = null;
  }
  toggleCreatePushNotification(){
    document.getElementById('createPushNotification-dialog').classList.toggle('show');
  }

  onRepetitionSelectionChanged(rep: string){
    this.selectedPushNotification.repetition = this.label2RepetitionMapping[rep].toString();
  }

  onTitleChanged(title: string){
    this.selectedPushNotification.title = title;
  }
  onDescriptionChanged(desc: string){
    this.selectedPushNotification.description = desc;
  }
  onActiveChanged(active: boolean){
    this.selectedPushNotification.active = active;
  }
  isNextExecutionDateValid() {
    var now = new Date()
    now.setMilliseconds(0)
    now.setSeconds(0)
    return this.selectedPushNotification?.nextExecutionDate >= now
  }
  isRepetitionMultiplierValid(){
    return this.selectedPushNotification?.repetition !==  Repetition.everyXDays || (this.selectedPushNotification?.repetitionMultiplier >= 1 && this.selectedPushNotification?.repetitionMultiplier <= 3000);
  }

  onSavePushNotification(){
    if(this.selectedPushNotification.nextExecutionDate == null || !this.isNextExecutionDateValid() || this.selectedPushNotification.repetition == null || this.selectedPushNotification.title == null || !this.isRepetitionMultiplierValid()){
      this.toastr.error(this.translate.instant("Überprüfe deine Eingaben!"), "" ,  {
        positionClass: 'toast-bottom-center'
      });
      return;
    }
    if(this.selectedPushNotification.id != null){
      this.userService.updateScheduledPushNotification(this.selectedPushNotification);
    }
    else {
      this.selectedPushNotification.coachUid = this.userService.getLoggedInUser().uid;
      this.userService.insertAutomaticPushNotification(this.selectedPushNotification);
    }
    document.getElementById('createPushNotification-dialog').classList.remove('show');
    this.selectedPushNotification = null;
  }

  getPrintableNotificationTarget(notification: AutomaticPushNotification):string{
    var checkedElements = '';
    if(notification == null){
      return ''
    }
    if(notification.coachUids != null){
      notification.coachUids.forEach(coachUid => {
        var coach = this.selectableCoaches.filter(c => c.uid == coachUid).shift()
        if (coach) {
          if(checkedElements.length > 0){
            checkedElements = checkedElements.concat(', ')
          }
          checkedElements = checkedElements.concat(this.translate.instant("{{coachNameParameter}}`s Kunden", {coachNameParameter: coach.name}));
        }
      });
    }
    if(notification.groupNames != null){
      if (notification.groupNames?.includes('Alle')) return this.translate.instant('Alle')
      notification.groupNames.forEach(name => {
        if(this.getSelectableClientGroups()?.includes(name)){
          if(checkedElements.length > 0){
            checkedElements = checkedElements.concat(', ')
          }
          checkedElements = checkedElements.concat(name);
        }
      });
    }
    if (this.selectableClients != null){
      this.selectableClients.forEach(element => {
        if(notification.userUids?.includes(element.uid)){
          if(checkedElements.length > 0){
            checkedElements = checkedElements.concat(', ')
          }
          checkedElements = checkedElements.concat(element.name);
        }
      });
    }
    return checkedElements;
  }

  onTargetSelectionChangedUser(user: User){
    if (!this.canAccessUser(user)) return
    if (this.selectedPushNotification.userUids.includes(user.uid)) {
      this.selectedPushNotification.userUids.forEach( (item, index) => {
        if (item == user.uid) this.selectedPushNotification.userUids.splice(index, 1)
      })
    } else {
      this.selectedPushNotification.userUids.push(user.uid)
    }
  }

  onTargetSelectionChangedGroup(group: string){
    if (!this.canAccessGroup(group)) return
    if (this.selectedPushNotification.groupNames.includes(group)) {
      this.selectedPushNotification.groupNames.forEach( (item, index) => {
        if (item == group) this.selectedPushNotification.groupNames.splice(index, 1)
      })
    } else {
      this.selectedPushNotification.groupNames.push(group)
    }
  }

  onTargetSelectionChangedCoach(coach: Coach){
    if (!this.canAccessCoachGroup(coach)) return
    if (this.selectedPushNotification.coachUids.includes(coach.uid)) {
      this.selectedPushNotification.coachUids.forEach( (item, index) => {
        if (item == coach.uid) this.selectedPushNotification.coachUids.splice(index, 1)
      })
    } else {
      this.selectedPushNotification.coachUids.push(coach.uid)
    }
  }

  areAllUsersTargeted() {
    return this.selectedPushNotification?.groupNames?.includes('Alle')
  }

  private toDateString(date: Date): string {
    return (date.getFullYear().toString() + '-' 
       + ("0" + (date.getMonth() + 1)).slice(-2) + '-' 
       + ("0" + (date.getDate())).slice(-2))
       + 'T' + date.toTimeString().slice(0,5);
  }

  private parseDateString(date:string): Date {
    date = date.replace('T','-');
    var parts = date.split('-');
    var timeParts = parts[3].split(':');
   return new Date(+parts[0], +parts[1]-1, +parts[2], +timeParts[0], +timeParts[1]);

 }

}

