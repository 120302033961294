<button mat-button [matMenuTriggerFor]="menu" class="filter-button {{hasAnyFilter ? 'filtered' : ''}}" (click)="openFilter();$event.stopPropagation()">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filter" viewBox="0 0 16 16">
        <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
    </svg>
</button>
<mat-menu #menu="matMenu">
    <div class="filter-container" (click)="$event.stopPropagation();">
        <div *ngIf="hasSearchField">
            <input id="search-input" class="input wide" (input)="onSearchInputChanged($event.target.value)" [placeholder]="'Suchen' | translate" type="text">
            <div *ngIf="searchInput?.length > 0" class="icon-button deletesearch-icon delete-search" (click)="onDeleteSearchInput()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>
        </div>
        <div class="filter-button-wrapper" *ngFor="let filterObject of getSearchResultFilterObjects()">
            <button class="dropdown-item filter-selection-button {{filterObject.isFiltered ? 'filtered': ''}}" type="button" (click)="onSetFilteredItem(filterObject)">{{getText(filterObject)}}</button>
        </div>
    </div>
</mat-menu>