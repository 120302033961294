import { Component, ComponentFactoryResolver, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { IComponentCanDeactivate } from 'src/app/app-routing.module';
import { AuthService } from 'src/app/auth/auth.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { User } from 'src/app/model/user.model';
import { ChartExportService } from 'src/app/services/chart-export.service';
import { ChatService } from 'src/app/services/chat.service';
import { EditUserDataService } from 'src/app/services/edit-user-data.service';
import { FirestoreNutritionPlanService } from 'src/app/services/firestore-nutritionplan.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { LanguageService } from 'src/app/services/language.service';
import { NutritionService } from 'src/app/services/nutrition.service';
import { NutritionplanExportService } from 'src/app/services/nutritionplan-export.service';
import { PaymentService } from 'src/app/services/payment.service';
import { SpikeService } from 'src/app/services/spike-service.service';
import { TrainingService } from 'src/app/services/training.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-base',
  templateUrl: './client-base.component.html',
  styleUrls: ['./client-base.component.css']
})
export class ClientBaseComponent implements IComponentCanDeactivate {

  public environment = environment

  public selectedUserUid: string
  public displayedUser: User

  // Logged-in User
  public user: User;

  constructor(public nutritionService: NutritionService, public trainingService: TrainingService, public chatService: ChatService, public authService: AuthService, public router: Router, public userService: FirestoreService, private paymentService: PaymentService, private nutritionPlanService: FirestoreNutritionPlanService, public route: ActivatedRoute, public utilityService: UtilityService, private nutritionPlanExportService: NutritionplanExportService, public toastr: ToastrService, public spinner: NgxSpinnerService,  public dialog: MatDialog, private componentFactoryResolver: ComponentFactoryResolver, private renderer: Renderer2, public chartExportService: ChartExportService, private spikeService: SpikeService, public editUserDataService: EditUserDataService, public languageService: LanguageService, public translate: TranslateService) {

  }

  canDeactivate: () => Promise<boolean> = async () => {
    if (environment.firebaseProjectId == 'traindoo-app' && this.trainingService.currentEditedTrainingPlanHasChanges) {
      return await ClientBaseComponent.showDeactivateTrainingDialog(this.dialog, this.translate);
    }

    return true;
  };

  static async showDeactivateTrainingDialog(dialog: MatDialog, translate: TranslateService): Promise<boolean> {
    const dialogRef = dialog.open(ConfirmationDialogComponent, {
      data: { message: translate.instant(marker('Bist du sicher, dass du den Trainingsplan schließen möchtest?<br> Dadurch gehen ungespeicherte Änderungen verloren.')), title: translate.instant(marker('Trainingsplan schließen')), positiveButton: translate.instant(marker('Schließen ohne Speichern')), negativeButton: translate.instant(marker('Abbrechen')) },
    });
    return await dialogRef.afterClosed().toPromise();
  }

  ngOnInit(): void {

    this.user = this.userService.getLoggedInUser()
    
    this.route.paramMap.subscribe((param) => {
      this.selectedUserUid = param.get('uid');

      this.displayedUser = this.userService.getAccessibleClients().find(c => c.uid == this.selectedUserUid);
      
      this.user = this.userService.getLoggedInUser()
      if (!this.user) {
        this.waitforUser()
      }
      if (!this.displayedUser?.uid) {
        this.displayedUser = this.user
      }
    })
    
  }

  waitforUser() {
    var userSubscription = this.userService.observableUser.subscribe((user) => {
      if (!user) {
        this.router.navigate(['../login'])
        return
      }
      var clientsSubscription = this.userService.observableClients.subscribe((clients) => {
        this.displayedUser = this.userService.getAccessibleClients().find(c => c.uid == this.selectedUserUid);
        userSubscription.unsubscribe()
        clientsSubscription.unsubscribe()
        if (!this.displayedUser) {
          this.router.navigate(['../dashboard'])
        }
      })
    })
  }

  async resendInvitationEmail() {
    this.spinner.show()
    var imageUrl = null
    var snapshot = await this.userService.getBrandingSettings(this.user.licenceHolderUid || this.user.uid).toPromise()
    if (snapshot.exists) {
      var logoFileName = snapshot?.data()?.logoFileName
      if (logoFileName) {
        imageUrl = await this.userService.getBrandingImage(this.user.licenceHolderUid || this.user.uid, logoFileName).toPromise()
      }
    }
    let firebaseUserEmail = await this.userService.getEmailForUser(this.displayedUser.uid)
    var paymentSettings = this.paymentService.paymentSettings
    let dynamicLink = environment.inAppLink + '?link=' + environment.inAppLink + 'app?email%3D' + firebaseUserEmail
    let subject = paymentSettings?.customerKickoffEmailTemplate?.subject?.replace('{{Name}}', this.displayedUser.getName() ?? '')
    let heading = paymentSettings?.customerKickoffEmailTemplate?.heading?.replace('{{Name}}', this.displayedUser.getName() ?? '')
    let body = paymentSettings?.customerKickoffEmailTemplate?.body?.replace('{{Name}}', this.displayedUser.getName() ?? '')
    firstValueFrom(this.userService.sendInvitationEmail(firebaseUserEmail, this.displayedUser.getName(), heading, body, dynamicLink, 'App öffnen', subject, imageUrl, paymentSettings?.email ?? this.authService.user?.email ?? 'no-reply@nutrilize.app', this.user.licenceHolderUid)).then(res => {
      this.spinner.hide()
      if (res?.result == 'success') {
        this.toastr.success(this.translate.instant("Email wurde versendet."), "",  {
          positionClass: 'toast-bottom-center'
        });
      } else {
        this.toastr.error(this.translate.instant("Beim Versenden der Email ist ein Fehler aufgetreten."), "",  {
          positionClass: 'toast-bottom-center'
        });
      }
    }, error => {
      console.log(error)
    })
  }

  static componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }
  
  static rgbToHex(rgb) {
    const [r, g, b] = rgb.map((value) => Math.floor(value * 255));
    const redHex = this.componentToHex(r);
    const greenHex = this.componentToHex(g);
    const blueHex = this.componentToHex(b);
    return `#${redHex}${greenHex}${blueHex}`;
  }
  
  static rgbaToHex(rgba) {
    const [r, g, b, a] = rgba.map((value) => Math.floor(value * 255));
    const redHex = this.componentToHex(r);
    const greenHex = this.componentToHex(g);
    const blueHex = this.componentToHex(b);
    const alphaHex = this.componentToHex(a);
    return `#${redHex}${greenHex}${blueHex}${alphaHex}`;
  }

  static rgbaStringToHex(rgbaString: string) {
    const rgba = rgbaString.split(',').map((value) => parseFloat(value.replace(/^\D+/g, '')));
    return this.rgbaToHex(rgba);
  }

  static generateColors(numColors):string[] {
    const colors = [];
    const hueIncrement = 1 / numColors;
    let hue = Math.random(); // Startfarbton zufällig wählen
  
    for (let i = 0; i < numColors; i++) {
      const saturation = Math.random(); // Zufällige Sättigung
      const value = 0.7 + Math.random() * 0.3; // Zufälliger Helligkeitswert
      const rgb = ClientBaseComponent.hsvToRgb(hue, saturation, value); // Umwandlung in den RGB-Farbraum
      const hex = ClientBaseComponent.rgbToHex(rgb); // Konvertierung in Hexadezimalwert
      colors.push(hex);
      
      hue = (hue + hueIncrement + Math.random() * 0.2) % 1; // Zufällige Schrittweite für Farbton hinzufügen
    }
    return colors;
  }
  
  static hsvToRgb(h, s, v) {
    let r, g, b;
    h = (h + 1) % 1; // Normalisiere h auf den Bereich von 0 bis 1
    s = Math.max(0.2, Math.min(0.7, s)); // Stelle sicher, dass s zwischen 0 und 0.7 liegt
    v = Math.max(0.1, Math.min(1, v)); // Stelle sicher, dass v zwischen 0 und 1 liegt
  
    const i = Math.floor(h * 6);
    const f = h * 6 - i;
    const p = v * (1 - s);
    const q = v * (1 - f * s);
    const t = v * (1 - (1 - f) * s);
  
    switch (i % 6) {
      case 0:
        r = v;
        g = t;
        b = p;
        break;
      case 1:
        r = q;
        g = v;
        b = p;
        break;
      case 2:
        r = p;
        g = v;
        b = t;
        break;
      case 3:
        r = p;
        g = q;
        b = v;
        break;
      case 4:
        r = t;
        g = p;
        b = v;
        break;
      case 5:
        r = v;
        g = p;
        b = q;
        break;
    }
  
    return [r, g, b];
  }
}
