
<h3 class="display-inlineblock" id="push-notification-headline" translate>Push-Notifications</h3>
<button class="button-filled small create-PushNotification" (click)="onCreatePushNotification()" translate>Erstellen</button>
<br>
<div class="row notification-wrapper" *ngFor="let notification of observablePushNotifications | async" [class.deactivated-notification]='!notification?.active'>
    <div class="col col-12 col-sm-5 col-md-3 col-xl-4">
        <b translate>Titel:</b> {{notification.title}}
    </div>
    <div  class="col col-12 col-sm-5 col-md-3 col-xl-4">
        <b translate>Nächste Ausführung: </b>{{notification?.nextExecutionDate?.asDateTimeFormatedString()}}
    </div>
    <div class="col col-5 col-sm-3 col-md-3 col-lg-3 col-xl-2">
        <div class="icon-button small" (click)="onEditPushNotification(notification)" translate>
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
            </svg>
            Bearbeiten
        </div>
    </div>
    <div class="col col-5 col-sm-3 col-md-3 col-lg-3 col-xl-2">
        <div class="icon-button" (click)="onDeletePushNotification(notification)" translate>
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
            Löschen
        </div>
    </div>
</div>

<div id="createPushNotification-dialog" class="dialog-wrapper">
    <div class="dialog layout-box">
        <div class="dialog-content-wrapper margin-0">
            <h3 class="display-inlineblock" translate>Push-Notification erstellen</h3>
            <div class="icon-button float-right" (click)="cancelEditPushNotification()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </div>
            <div class="content">
                    <div class="form-group">
                          <label for="title" translate>Titel</label>
                          <input id="title" required class="text-input" type="text" [ngModel]="selectedPushNotification?.title" (input)="onTitleChanged($event.target.value)" aria-describedby="titleHelp" [placeholder]="'Titel eingeben...' | translate">
                          <!-- <small id="titleHelp" class="form-text text-muted">Titel der Push-Benachrichtigung</small> -->
                    </div>
                    <div class="form-group">
                        <label for="description" translate>Inhalt</label>
                        <!--input id="description" class="text-input" type="text" [ngModel]="selectedPushNotification?.description" (input)="onDescriptionChanged($event.target.value)" aria-describedby="descriptionHelp" placeholder="Inhalt eingeben..."-->
                        <textarea [ngModel]="selectedPushNotification?.description" (input)="onDescriptionChanged($event.target.value)" class="input welcome-message" type="text" rows="5" [placeholder]="'Beschreibung eingeben' | translate"></textarea>    
                    </div>
                    <div class="form-group">
                        <label class="form-datetime-label" for="datetime" translate>Nächste Ausführung</label>
                        <input type="datetime-local" required class="form-datetime-input" id="datetime" [ngModel]="selectedExecutionDate" (input)="onNewExecutionDateChanged($event.target.value)">
                        <div class="icon-button marginleft-25" tooltip="Die Benachrichtigungen können wenige Minuten später ankommen.">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                            </svg>
                        </div>
                        <div *ngIf="!isNextExecutionDateValid()" class="label color-red" translate>Die nächste Ausführung muss in der Zukunft liegen.</div>
                    </div>
                    <div class="form-group">
                        <label for="dropdownRepetion" translate>Wiederholung</label>
                        <div class="d-inline-block wrapper-repetition-selection">
                            <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{repetition2LabelMapping[selectedPushNotification?.repetition] | translate}}</button>
                            <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
                                <div *ngFor="let rep of repetitionLabels">
                                    <button class="dropdown-item" type="button" (click)="onRepetitionSelectionChanged(rep)" translate>{{rep}}</button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="selectedPushNotification?.repetition === 'everyXDays'">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                            </svg>
                            <span translate>Wiederholung alle</span> <input type="number" min="1" max="4000" step="1" required mdInput [ngModel]="selectedPushNotification?.repetitionMultiplier" (input)="onRepetitionMultiplierChanged($event.target.value)"> <span translate>Tage</span>
                        </div>
                        <div *ngIf="!isRepetitionMultiplierValid()" class="label color-red">Die Auswahl muss zwischen 1 und 3000 liegen.</div>
                    </div>
                    <div class="form-group">
                        <label for="dropdownRepetion" translate>Empfänger</label>
                        <div class="d-inline-block wrapper-repetition-selection">
                            <button class="button-filled light small dropdown-toggle margin-inbetween" [class.button-invalid]='getPrintableNotificationTarget(selectedPushNotification)?.length === 0' type="button" id="dropdownRepetion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getPrintableNotificationTarget(selectedPushNotification)}}</button>
                            <div class="dropdown-menu dropdown-notificationTarget" aria-labelledby="dropdownNotificationTarget">
                                <div class="dropdown-item link" (click)="onTargetSelectionChangedGroup('Alle');$event.stopPropagation()">
                                    <input [disabled]="!canAccessGroup('Alle')" class="input checkbox" type="checkbox" [checked]="selectedPushNotification?.groupNames.includes('Alle')"><div class="display-inlineblock" translate>Alle</div>
                                </div>
                                <ng-container *ngIf="getSelectableClientGroups()?.length > 0">
                                    <div class="dropdown-divider"></div>
                                    <div class="label marginleft-10" translate>Gruppen</div>
                                    <div class="dropdown-item link" *ngFor="let group of getSelectableClientGroups()" (click)="onTargetSelectionChangedGroup(group);$event.stopPropagation()">
                                        <input [disabled]="group != 'Alle' && areAllUsersTargeted() || !canAccessGroup(group)" class="input checkbox" type="checkbox" [checked]="selectedPushNotification?.groupNames.includes(group)"><div class="display-inlineblock">{{group}}</div>
                                    </div>
                                </ng-container>
                                <div class="dropdown-divider"></div>
                                <div class="label marginleft-10" translate>Kunden</div>
                                <ng-container *ngFor="let coach of selectableCoaches">
                                    <div class="dropdown-item link" (click)="onTargetSelectionChangedCoach(coach);$event.stopPropagation()">
                                        <input [disabled]="areAllUsersTargeted() || !canAccessCoachGroup(coach)" class="input checkbox" type="checkbox" [checked]="selectedPushNotification?.coachUids.includes(coach.uid)"><div class="display-inlineblock">{{('{{coachNameParameter}}`s Kunden' | translate:{coachNameParameter: coach.name})}}</div>
                                    </div>
                                </ng-container>
                                <div class="dropdown-divider"></div>
                                <div class="dropdown-item link" *ngFor="let user of getSelectableClients()" (click)="onTargetSelectionChangedUser(user);$event.stopPropagation()">
                                    <input [disabled]="areAllUsersTargeted()" class="input checkbox" type="checkbox" [checked]="selectedPushNotification?.userUids.includes(user.uid)"><div class="display-inlineblock">{{user.getName()}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-check">
                          <input type="checkbox" class="form-check-input" id="active" [ngModel]="selectedPushNotification?.active" (change)="onActiveChanged($event.target.checked)">
                          <label class="form-check-label" for="active" translate>Aktiv</label>
                    </div>
            </div>
            <div class="actions">
                <div class="icon-button" (click)="onSavePushNotification()" translate>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                    </svg>
                    Speichern
                </div>
            </div>
        </div>
    </div>
</div>