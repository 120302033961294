import { ClientRecipesComponent } from './recipes_client/recipes_client.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { MarkdownModule } from 'ngx-markdown';

import 'firebase/storage';
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { AngularFireAuth, AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFireFunctions, AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFireDatabaseModule, USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/compat/database';
import { AngularFireMessagingModule, SERVICE_WORKER, VAPID_KEY } from '@angular/fire/compat/messaging';
import { AngularFireRemoteConfigModule, SETTINGS as REMOTE_CONFIG_SETTINGS, DEFAULTS as REMOTE_CONFIG_DEFAULTS } from '@angular/fire/compat/remote-config';
import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';
import { LoginComponent } from './user/login/login.component';
import { RegisterComponent } from './user/register/register.component';
import { ForgotPasswordComponent } from './user/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './user/verify-email/verify-email.component';
import { DashboardComponent } from './dashboard/dashboard-v1/dashboard.component';

import { AuthService } from "./auth/auth.service";
import { ProfileScreenComponent } from './profile-screen/profile-screen.component';
import { CoachAreaComponent } from './coach-area/coach-area.component';
import { ClientComponent } from './client/client-v1/client-v1.component';
import { ChatComponent } from './chat/chat.component';
import { SingleChatComponent } from './single-chat/single-chat.component';
import { LicenceManagementComponent } from './licence-management/licence-management.component';
import { RecipesComponent } from './recipes/recipes.component';
import { CustomerButtonComponent } from './customer-button/customer-button.component';

import { DxChartModule, DxDataGridModule, DevExtremeModule } from 'devextreme-angular';
import { NouisliderModule } from 'ng2-nouislider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'

import { ToastrModule } from 'ngx-toastr';
import { ClipboardModule } from 'ngx-clipboard';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule, PopoverConfig } from 'ngx-bootstrap/popover';
import { MdEditorComponent } from './md-editor/md-editor.component';
import { IngredientSelectorComponent } from './ingredient-selector/ingredient-selector.component';
import { DatabaseAdminComponent } from './database-admin/database-admin.component';
import { LicenceMonitoringComponent } from './licence-monitoring/licence-monitoring.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule, NativeDateAdapter } from '@angular/material/core';
import { NutritionalValuePopoverComponent } from './nutritional-value-popover/nutritional-value-popover.component';
import { SettingsBaseComponent } from './settings/settings-base/settings.component';
import { SafePipe } from './safe.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatInputModule } from '@angular/material/input';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';

import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { BodyDataComponent } from './body-data/body-data.component';
import { AccountComponent } from './account/account.component';
import { SubscriptionSuccessComponent } from './subscription-success/subscription-success.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { InputFieldDialogComponent } from './inputfield-dialog/inputfield-dialog.component';
import { PushNotificationSettingsComponent } from './settings/push-notification-settings/push-notification-settings.component';
import { MacroCalculatorComponent } from './macro-calculator/macro-calculator.component';
import { EventLogComponent } from './event-log/event-log.component';
import {SanitizedHtmlPipe} from './model/event-log.model';
import { MobileAppChatComponent } from './mobile-app-chat/mobile-app-chat.component';
import { CachedImageComponent } from './cached-image/cached-image.component';
import { NutritionPlanComponent } from './nutrition-plan/nutrition-plan.component';
import { MealEditorDialogComponent } from './meal-editor-dialog/meal-editor-dialog.component';
import { NutritionPlanConfigEditorDialogComponent } from './config-editor-dialog/config-editor-dialog.component';
import { NutritionalGoalSetupComponent } from './nutritional-goal-setup/nutritional-goal-setup.component';
import { NutritionConfigEditorComponent } from './nutrition-config-editor/nutrition-config-editor.component';
import { MetricsAssignmentComponent } from './metrics-assignment/metrics-assignment.component';
import { NotificationComposerComponent } from './notification-composer/notification-composer.component';
import { PastePlanTemplateDialogComponent } from './paste-plantemplate-dialog/paste-plantemplate-dialog.component';
import { CreateTemplateDialogComponent } from './create-template-dialog/create-template-dialog.component';
import { TrainingDatabaseComponent } from './training/training-database/training-database.component';
import { SingleExerciseComponent, PluckPipe } from './training/single-exercise/single-exercise.component';
import { SearchInputSelectComponent } from './search-input-select/search-input-select.component';
import { ExercisesTableComponent } from './training/exercises-table/exercises-table.component';
import { TrainingPlanEditorComponent } from './training/training-plan-editor/training-plan-editor.component';
import { UserTrainingPlanOverviewComponent } from './training/user-training-plan-overview/user-training-plan-overview.component';
import { ExercisesTableDialogComponent } from './training/exercises-table-dialog/exercises-table-dialog.component';
import { NewsBoardComponent } from './news-board/news-board.component';
import { TrainingHistoryComponent } from './training-monitoring/training-history/training-history.component';
import { TrainingHistoryDialogComponent } from './training-monitoring/training-history-dialog/training-history-dialog.component';
import { TrainingGraphComponent } from './training-monitoring/training-graph/training-graph.component';
import { TeamManagementComponent } from './team-management/team-management.component';
import { CreateLicenceHolderDialogComponent } from './create-licenceholder-dialog/create-licenceholder-dialog.component';
import { CreateCoachDialogComponent } from './create-coach-dialog/create-coach-dialog.component';
import { TrainingTemplatesComponent } from './training/training-templates/training-templates.component';
import { TrainingTabPageComponent } from './training/training-tab-page/training-tab-page.component';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { VideoRecordingDialogComponent } from './training-monitoring/video-recording-dialog/video-recording-dialog.component';
import { TrainingGraphExtensionComponent } from './training-monitoring/training-graph-extension/training-graph-extension.component';
import { FilterSelectionDropdownComponent } from './filter-selection-dropdown/filter-selection-dropdown.component';
import { TimeInputControlComponent } from './time-input-control/time-input-control.component';
import { UrlifyPipe } from './urlify.pipe';
import { ExerciseHistoryDialogComponent } from './training-monitoring/exercise-history-dialog/exercise-history-dialog.component';
import { MarketingKitComponent } from './dialogs/marketing-kit/marketing-kit.component';
import { QuestionaireOverviewComponent } from './questionaire/questionaire-overview/questionaire-overview.component';
import { QuestionaireEditorComponent } from './questionaire/questionaire-editor/questionaire-editor.component';
import { MetricsSelectionDialogComponent } from './metrics-selection-dialog/metrics-selection-dialog.component';
import { CustomMetricEditorDialogComponent } from './custom-metric-editor-dialog/custom-metric-editor-dialog.component';
import { MetricGroupEditorDialogComponent } from './metric-group-editor-dialog/metric-group-editor-dialog.component';
import { UserQuestionairesOverviewComponent } from './questionaire/user-questionaires-overview/user-questionaires-overview.component';
import { QuestionaireOverviewPageComponent } from './questionaire/questionaire-overview-page/questionaire-overview-page.component';
import { CompletedQuestionairesOverviewComponent } from './questionaire/completed-questionaires-overview/completed-questionaires-overview.component';
import { CompletedQuestionaireResultsDialogComponent } from './questionaire/completed-questionaire-results-dialog/completed-questionaire-results-dialog.component';
import { MetricDataImageDialogComponent } from './metric-data-image-dialog/metric-data-image-dialog.component';
import { QuestionaireSelectionDialogComponent } from './questionaire/questionaire-selection-dialog/questionaire-selection-dialog.component';
import { RecipeEditorComponent } from './recipe-editor/recipe-editor.component';
import { CoachingToolsComponent } from './coaching-tools/coaching-tools.component';
import { SelectionDialogComponent } from './selection-dialog/selection-dialog.component';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AngularFirestore, AngularFirestoreModule, SETTINGS as USE_FIRESTORE_SETTINGS } from '@angular/fire/compat/firestore';
import { LicenceDialogComponent } from './dialogs/licence-dialog/licence-dialog.component';


import { PLATFORM_ID, NgZone, LOCALE_ID } from '@angular/core';
import { AngularFireAuthCommon, AngularFirestoreCommon, AngularFirestoreIndividual } from './firebase.factory';
// import { FirebaseOptions, _firebaseAppFactory } from 'angularfire2';
// import { AngularFireStorage } from '@angular/fire/storage';
import { FileSharingComponent } from './file-sharing/file-sharing.component';
import { LinkCreationComponent } from './dialogs/link-creation/link-creation.component';
import { FileSharingDialogComponent } from './dialogs/file-sharing-dialog/file-sharing-dialog.component';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FirebaseApp } from '@angular/fire/app/firebase';
import { ɵAngularFireSchedulers } from '@angular/fire';
import { PaymentComponent } from './payment/payment/payment.component';
import { OrdersComponent } from './payment/orders/orders.component';
import { CheckoutComponent } from './payment/checkout/checkout.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ProductEditorComponent } from './payment/product-editor/product-editor.component';
import { SignUpComponent } from './user/sign-up/sign-up.component';
import { PurchaseDialogComponent } from './payment/purchase-dialog/purchase-dialog.component';
import { PaymentDetailsDialogComponent } from './payment/paymentdetails-dialog/paymentdetails-dialog.component';
import { ProductPageComponent } from './payment/product-page/product-page.component';
import { LoginDialogComponent } from './payment/login-dialog/login-dialog.component';
import { ProfileViewComponent } from './payment/profile-view/profile-view.component';
import { CalendlyWidgetComponent } from './payment/profile-view/calendly-embed.component';
import { NgbTimepicker, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { QuestionaireInfoslideDialogComponent } from './questionaire/questionaire-infoslide-dialog/questionaire-infoslide-dialog.component';
import { CustomSettingsDialogComponent } from './dialogs/custom-settings-dialog/custom-settings-dialog.component';
import { EmailTemplateDialogComponent } from './dialogs/email-template-dialog/email-template-dialog.component';
import { InvoiceViewComponent } from './payment/invoice-view/invoice-view.component';
import { TrainingPlanTemplateFolderEditorComponent } from './training/training-plan-template-folder-editor/training-plan-template-folder-editor.component';
import { MealsuggestionsSettingsDialogComponent } from './dialogs/mealsuggestions-settings-dialog/mealsuggestions-settings-dialog.component';
import { SessionTemplatesDialogComponent } from './training/session-templates-dialog/session-templates-dialog.component';
import { TrainingPlanTemplatesSelectionDialogComponent } from './training/training-plan-templates-selection-dialog/training-plan-templates-selection-dialog.component';
import { ImageEditorComponent } from './utilities/image-editor/image-editor.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TrackedMealEditorDialogComponent } from './dialogs/tracked-meal-editor-dialog/tracked-meal-editor-dialog.component';
import { MetricdataViewComponent } from './metricdata-view/metricdata-view.component';
import { CheckoutViewComponent } from './payment/checkout-view/checkout-view.component';
import { TimerangeSelectionDialogComponent } from './dialogs/timerange-selection-dialog/timerange-selection-dialog.component';
import { ProductViewComponent } from './payment/product-view/product-view.component';
import { InvoiceHistoryDialogComponent } from './payment/invoice-history-dialog/invoice-history-dialog.component';
import { MatDividerModule } from '@angular/material/divider';
import { ActivityEditorComponent } from './activity-editor/activity-editor.component';
import { TaskManagementComponent } from './task-management/task-management/task-management.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { RadioButtonsConfirmationDialogComponent } from './dialogs/radio-buttons-confirmation-dialog/radio-buttons-confirmation-dialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { TaskElementComponent } from './task-management/task-element/task-element.component';
import { TaskListComponent } from './task-management/task-list/task-list.component';
import { RenewalDialogComponent } from './payment/renewal-dialog/renewal-dialog.component';
import { RecipeSharingDialogComponent } from './dialogs/recipe-sharing-dialog/recipe-sharing-dialog.component';
import { CompletedQuestionaireViewComponent } from './questionaire/completed-questionaire-view/completed-questionaire-view.component';
import { FileEditorDialogComponent } from './dialogs/file-editor-dialog/file-editor-dialog.component';
import { DropdownSelectionDialogComponent } from './dialogs/dropdown-selection-dialog/dropdown-selection-dialog.component';
import { TrainingVariableEditorDialogComponent } from './training/training-variable-editor-dialog/training-variable-editor-dialog.component';
import { InvoiceEditorComponent } from './payment/invoice-editor/invoice-editor.component';
import { VisualSelectionDialogComponent } from './dialogs/visual-selection-dialog/visual-selection-dialog.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ExerciseGroupTemplatesDialogComponent } from './training/exercise-group-templates-dialog/exercise-group-templates-dialog.component';
import { CardioEvaluationComponent } from './graph/cardio-evaluation/cardio-evaluation.component';
import { ActivityHistoryDialogComponent } from './training-monitoring/activity-history-dialog/activity-history-dialog.component';
import { TrackedTrainingSessionComponent } from './training-monitoring/tracked-training-session/tracked-training-session.component';
import { GlobalLanguageSelectorComponent, LanguageSelectorComponent } from './language-selector/language-selector.component';
import { MonthlyCalendarComponent } from './monthly-calendar/monthly-calendar.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ImageGenerationDialogComponent } from './dialogs/image-generation-dialog/image-generation-dialog.component';
import { PayoutReportDialogComponent } from './payment/payout-report-dialog/payout-report-dialog.component';
import { TimezonePipe } from './timezone.pipe';
import { PartnersComponent } from './partners/partners.component';
import { AffiliateDialogComponent } from './dialogs/affiliate-dialog/affiliate-dialog.component';
import { MacroSliderComponent } from './nutrition/macro-slider/macro-slider.component';
import { NavigationBarComponent } from './navigation/navigation-bar/navigation-bar.component';
import { MobileNavigationBarComponent } from './navigation/mobile-navigation-bar/mobile-navigation-bar.component';
import { NavigationBarTraindooComponent } from './navigation/navigation-bar-traindoo/navigation-bar-traindoo.component';
import { DiaryComponent } from './diary/diary.component';
import { NotesComponent } from './notes/notes/notes.component';
import { NotesDialogComponent } from './notes/notes-dialog/notes-dialog.component';
import { ClientBaseComponent } from './client/client-base/client-base.component';
import { ClientV2Component } from './client/client-v2/client-v2.component';
import { SettingsV1Component } from './settings/settings-v1/settings-v1.component';
import { SettingsV2Component } from './settings/settings-v2/settings-v2.component';
import { QuickNoteViewComponent } from './notes/quick-note-view/quick-note-view.component';
import { ProfileSettingsDialogComponent } from './dialogs/profile-settings-dialog/profile-settings-dialog.component';
import { BanktransferDialogComponent } from './dialogs/banktransfer-dialog/banktransfer-dialog.component';
import { CardioZoneGroupsEditorComponent } from './training/cardio-zone-groups-editor/cardio-zone-groups-editor.component';
import { ChartModule } from '@syncfusion/ej2-angular-charts';
import { GlobalCardioZoneEditorComponent } from './training/global-cardio-zone-editor/global-cardio-zone-editor.component';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import { TrackedExerciseTableComponent } from './training-monitoring/tracked-exercise-table/tracked-exercise-table.component';
import { TrainingPlanSettingsComponent } from './training/training-plan-editor/components/training-plan-settings/training-plan-settings.component';
import { SessionsColumnComponent } from './training/training-plan-editor/components/sessions-column/sessions-column.component';
import { TrainingSessionEditorComponent } from './training/training-plan-editor/components/training-session-editor/training-session-editor.component';
import { TrainingPlanEditorInlineComponent } from './training/training-plan-editor-inline/training-plan-editor-inline.component';
import { CreatePeriodicPlanDialogComponent } from './dialogs/create-periodic-plan-dialog/create-periodic-plan-dialog.component';
import { RpeTableDialogComponent } from './training/rpe-table-dialog/rpe-table-dialog.component';
import { CheckinTableViewComponent } from './checkin-table-view/checkin-table-view.component';
import { OneRmEditorComponent } from './training/one-rm-editor/one-rm-editor.component';
import { MrrGraphComponent } from './graph/mrr-graph/mrr-graph.component';
import { UpdateEmailDialogComponent } from './dialogs/update-email-dialog/update-email-dialog.component';
import { WeightConversionPipe } from './weight.pipe';
import { UnitConversionPipe } from './unit.pipe';
import { FilterDualSelectionDropdownComponent } from './filter-dual-selection-dropdown/filter-dual-selection-dropdown.component';
import { NgxEditorModule } from 'ngx-editor';
import { MdEditorLegacyComponent } from './md-editor-legacy/md-editor-legacy.component';
import { CoachNotificationsComponent } from './settings/coach-notifications/coach-notifications.component';
import { ImageUploadViewComponent } from './common/input/image-upload-view/image-upload-view.component';
import { NutritionGraphComponent } from './graph/nutrition-graph/nutrition-graph.component';
import { DashboardBaseComponent } from './dashboard/dashboard-base/dashboard-base.component';
import { DashboardTraindooComponent } from './dashboard/dashboard-traindoo/dashboard-traindoo.component';
import { ClientsOverviewComponent } from './clients/clients-overview/clients-overview.component';
import { ClientSelectionDialogComponent } from './dialogs/client-selection-dialog/client-selection-dialog.component';
import { CheckinAssignmentViewComponent } from './checkin-assignment-view/checkin-assignment-view.component';
import { AnalyticsComponent } from './client/analytics/analytics.component';
import { IapDashboardComponent } from './iap-dashboard/iap-dashboard.component';
import { GraphRevenueOverTimeComponent } from './payment/analytics/graph-revenue-over-time/graph-revenue-over-time.component';
import { GraphActiveSalesComponent } from './payment/analytics/graph-active-sales/graph-active-sales.component';
import { KpiCustomerRetentionComponent } from './payment/analytics/kpi-collection/kpi-customer-retention/kpi-customer-retention.component';
import { KpiClvComponent } from './payment/analytics/kpi-collection/kpi-clv/kpi-clv.component';
import { GraphTopTenRevenueComponent } from './payment/analytics/graph-top-ten-revenue/graph-top-ten-revenue.component';
import {PaymentAnalyticsComponent} from "./payment/analytics/payment-analytics.component";
import { AnalyticsConfigMenuComponent } from './payment/analytics/analytics-config-menu/analytics-config-menu.component';
import { KpiCollectionComponent } from './payment/analytics/kpi-collection/kpi-collection.component';
import { KpiMonthlyRevComponent } from './payment/analytics/kpi-collection/kpi-monthly-rev/kpi-monthly-rev.component';
import { KpiYearlyRevComponent } from './payment/analytics/kpi-collection/kpi-yearly-rev/kpi-yearly-rev.component';
import { KpiActiveSalesComponent } from './payment/analytics/kpi-collection/kpi-active-sales/kpi-active-sales.component';
import { CommunityComponent } from './community/community.component';
import { ManagementComponent } from './community/management/management.component';
import { UserActivationDialogComponent } from './traindoo/user-activation-dialog/user-activation-dialog.component';
import { UnsavedChangesDirective } from './directives/unsavedchanges.directive';
import { LibraryMobileNavigationComponent } from './traindoo/library-mobile-navigation/library-mobile-navigation.component';

import {MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MarkerModule } from '@colsen1991/ngx-translate-extract-marker/extras';
import { ExerciseSetTableComponent } from './training/training-plan-editor/components/exercise-set-table/exercise-set-table.component';
import { TrackingSessionComponent } from './training/tracking/tracking-session/tracking-session.component';
import { TrackingSessionDialogComponent } from './training/tracking/tracking-session/tracking-session-dialog/tracking-session-dialog.component';
import { TimerComponent } from './utilities/timer/timer.component';import { LanguageService } from './services/language.service';
import { KnowledgeScreenComponent } from './traindoo/knowledge-screen/knowledge-screen.component';
import { InsideViewportDirective } from './directives/inside-viewport.directive';
import { EnterTheViewportNotifierDirective } from './directives/enterviewport-notifier.directive';
import { VideoViewDialogComponent } from './dialogs/video-view-dialog/video-view-dialog.component';

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
      //console.error('Missing translation for', params.key);
      return params.key;
  }
}
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export interface FirebaseProject {
  auth: AngularFireAuth
  firestore: AngularFirestore
  storage: AngularFireStorage
  functions: AngularFireFunctions
  name: string
}

@Injectable({
  providedIn: 'root'
})
export class CommonFirebase implements FirebaseProject {
  auth: AngularFireAuth
  firestore: AngularFirestore
  storage: AngularFireStorage
  functions: AngularFireFunctions
  name: string

  constructor() {}

  initialize(app: FirebaseApp, projectName: string, platformId: Object, zone: NgZone) {
    const scheduler = new ɵAngularFireSchedulers(zone);
    this.auth = new AngularFireAuth(app.options, app.name, platformId, zone, scheduler, null, null, null, null, true, null, null);
    this.firestore = new AngularFirestore(app.options, app.name, false, null, platformId, zone, scheduler, null, null, null, null, null, null, null, null, null, null);
    this.storage = new AngularFireStorage(app.options, app.name, null, platformId, zone, scheduler, null, null, null, null)
    this.functions = new AngularFireFunctions(app.options, app.name, zone, scheduler, null, null, null, null)
    this.name = projectName
  }

}

@Injectable({
  providedIn: 'root'
})
export class IndividualFirebase implements FirebaseProject {
  auth: AngularFireAuth
  firestore: AngularFirestore
  storage: AngularFireStorage
  functions: AngularFireFunctions
  name: string

  constructor() {}

  initialize(app: FirebaseApp, projectName: string, platformId: Object, zone: NgZone) {
    const scheduler = new ɵAngularFireSchedulers(zone);
    this.auth = new AngularFireAuth(app.options, app.name, platformId, zone, scheduler, null, null, null, null, null, null, null);
    this.firestore = new AngularFirestore(app.options, app.name, false, {experimentalForceLongPolling: true}, platformId, zone, scheduler, null, null, null, null, null, null, null, null, null, null);
    this.storage = new AngularFireStorage(app.options, app.name, null, platformId, zone, scheduler, null, null, null, null)
    this.functions = new AngularFireFunctions(app.options, app.name, zone, scheduler, null, null, null, null)
    this.name = projectName
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    DashboardComponent,
    ProfileScreenComponent,
    CoachAreaComponent,
    ClientComponent,
    ClientRecipesComponent,
    ChatComponent,
    SingleChatComponent,
    LicenceManagementComponent,
    CustomerButtonComponent,
    RecipesComponent,
    MdEditorComponent,
    IngredientSelectorComponent,
    DatabaseAdminComponent,
    LicenceMonitoringComponent,
    NutritionalValuePopoverComponent,
    SettingsBaseComponent,
    SafePipe,
    UrlifyPipe,
    WeightConversionPipe,
    UnitConversionPipe,
    BodyDataComponent,
    AccountComponent,
    SubscriptionSuccessComponent,
    ConfirmationDialogComponent,
    InputFieldDialogComponent,
    PushNotificationSettingsComponent,
    MacroCalculatorComponent,
    EventLogComponent,
    SanitizedHtmlPipe,
    MobileAppChatComponent,
    CachedImageComponent,
    NutritionPlanComponent,
    MealEditorDialogComponent,
    NutritionPlanConfigEditorDialogComponent,
    NutritionalGoalSetupComponent,
    NutritionConfigEditorComponent,
    MetricsAssignmentComponent,
    NotificationComposerComponent,
    PastePlanTemplateDialogComponent,
    CreateTemplateDialogComponent,
    TrainingDatabaseComponent,
    SingleExerciseComponent,
    SearchInputSelectComponent,
    PluckPipe,
    ExercisesTableComponent,
    TrainingPlanEditorComponent,
    UserTrainingPlanOverviewComponent,
    ExercisesTableDialogComponent,
    NewsBoardComponent,
    TrainingHistoryComponent,
    TrainingHistoryDialogComponent,
    TrainingGraphComponent,
    TeamManagementComponent,
    CreateLicenceHolderDialogComponent,
    CreateCoachDialogComponent,
    TrainingTemplatesComponent,
    TrainingTabPageComponent,
    AudioPlayerComponent,
    VideoRecordingDialogComponent,
    FileSharingComponent,
    FilterSelectionDropdownComponent,
    TrainingGraphExtensionComponent,
    FilterSelectionDropdownComponent,
    TimeInputControlComponent,
    ExerciseHistoryDialogComponent,
    MarketingKitComponent,
    LinkCreationComponent,
    QuestionaireOverviewComponent,
    QuestionaireEditorComponent,
    MetricsSelectionDialogComponent,
    CustomMetricEditorDialogComponent,
    MetricGroupEditorDialogComponent,
    UserQuestionairesOverviewComponent,
    QuestionaireOverviewPageComponent,
    CompletedQuestionairesOverviewComponent,
    CompletedQuestionaireResultsDialogComponent,
    MetricDataImageDialogComponent,
    QuestionaireSelectionDialogComponent,
    RecipeEditorComponent,
    CoachingToolsComponent,
    SelectionDialogComponent,
    LicenceDialogComponent,
    FileSharingDialogComponent,
    PaymentComponent,
    OrdersComponent,
    CheckoutComponent,
    LandingPageComponent,
    ProductEditorComponent,
    SignUpComponent,
    PurchaseDialogComponent,
    PaymentDetailsDialogComponent,
    ProductPageComponent,
    LoginDialogComponent,
    ProfileViewComponent,
    CalendlyWidgetComponent,
    QuestionaireInfoslideDialogComponent,
    CustomSettingsDialogComponent,
    EmailTemplateDialogComponent,
    InvoiceViewComponent,
    TrainingPlanTemplateFolderEditorComponent,
    MealsuggestionsSettingsDialogComponent,
    SessionTemplatesDialogComponent,
    TrainingPlanTemplatesSelectionDialogComponent,
    ImageEditorComponent,
    TrackedMealEditorDialogComponent,
    MetricdataViewComponent,
    CheckoutViewComponent,
    TimerangeSelectionDialogComponent,
    ProductViewComponent,
    InvoiceHistoryDialogComponent,
    ActivityEditorComponent,
    TaskManagementComponent,
    AutofocusDirective,
    RadioButtonsConfirmationDialogComponent,
    TaskElementComponent,
    TaskListComponent,
    RenewalDialogComponent,
    RecipeSharingDialogComponent,
    CompletedQuestionaireViewComponent,
    FileEditorDialogComponent,
    DropdownSelectionDialogComponent,
    TrainingVariableEditorDialogComponent,
    InvoiceEditorComponent,
    VisualSelectionDialogComponent,
    ExerciseGroupTemplatesDialogComponent,
    CardioEvaluationComponent,
    ActivityHistoryDialogComponent,
    TrackedTrainingSessionComponent,
    LanguageSelectorComponent,
    MonthlyCalendarComponent,
    ImageGenerationDialogComponent,
    PayoutReportDialogComponent,
    TimezonePipe,
    PartnersComponent,
    AffiliateDialogComponent,
    MacroSliderComponent,
    NavigationBarComponent,
    MobileNavigationBarComponent,
    NavigationBarTraindooComponent,
    DiaryComponent,
    NotesComponent,
    NotesDialogComponent,
    ClientBaseComponent,
    ClientV2Component,
    SettingsV1Component,
    SettingsV2Component,
    QuickNoteViewComponent,
    ProfileSettingsDialogComponent,
    BanktransferDialogComponent,
    CardioZoneGroupsEditorComponent,
    GlobalCardioZoneEditorComponent,
    TrackedExerciseTableComponent,
    TrainingPlanSettingsComponent,
    SessionsColumnComponent,
    TrainingSessionEditorComponent,
    TrainingPlanEditorInlineComponent,
    CreatePeriodicPlanDialogComponent,
    RpeTableDialogComponent,
    CheckinTableViewComponent,
    OneRmEditorComponent,
    MrrGraphComponent,
    UpdateEmailDialogComponent,
    FilterDualSelectionDropdownComponent,
    MdEditorLegacyComponent,
    CoachNotificationsComponent,
    ImageUploadViewComponent,
    NutritionGraphComponent,
    DashboardBaseComponent,
    DashboardTraindooComponent,
    ClientsOverviewComponent,
    GlobalLanguageSelectorComponent,
    ClientSelectionDialogComponent,
    CheckinAssignmentViewComponent,
    AnalyticsComponent,
    IapDashboardComponent,
    PaymentAnalyticsComponent,
    GraphRevenueOverTimeComponent,
    GraphActiveSalesComponent,
    KpiCustomerRetentionComponent,
    KpiClvComponent,
    GraphTopTenRevenueComponent,
    AnalyticsConfigMenuComponent,
    KpiCollectionComponent,
    KpiMonthlyRevComponent,
    KpiYearlyRevComponent,
    KpiActiveSalesComponent,
    CommunityComponent,
    ManagementComponent,
    UserActivationDialogComponent,
    ExerciseSetTableComponent,
    TrackingSessionComponent,
    TrackingSessionDialogComponent,
    TimerComponent,
    UnsavedChangesDirective,
    LibraryMobileNavigationComponent,
    KnowledgeScreenComponent,
    InsideViewportDirective,
    EnterTheViewportNotifierDirective,
    VideoViewDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgbTimepickerModule,
    NgxMaskPipe,
    NgxMaskDirective,
    AngularFireModule.initializeApp({}),
    provideFirebaseApp(() => initializeApp({})),
    //AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    AngularFireRemoteConfigModule,
    AngularFireMessagingModule,
    AngularFireAnalyticsModule,
    AngularFireFunctionsModule,
    ToastrModule.forRoot(),
    ClipboardModule,
    DxChartModule,
    DxDataGridModule,
    DevExtremeModule,
    TooltipModule.forRoot(),
    PopoverModule,
    NgxSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HttpClientModule,
    NouisliderModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    PickerModule,
    MatDialogModule,
    MatRadioModule,
    DragDropModule,
    CommonModule,
    MarkdownModule.forRoot(),
    ImageCropperModule,
    MatDividerModule,
    MatTooltipModule,
    ColorPickerModule,
    ChartModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: createTranslateLoader,
            deps: [HttpClient]
        },
        missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler},
    }),
    MarkerModule,
    NgxEditorModule,
  ],
  providers: [
    CommonFirebase,
    {
      provide: APP_INITIALIZER,
      deps: [CommonFirebase, PLATFORM_ID, NgZone],
      useFactory: initCommonProject,
      multi: true
    },
    IndividualFirebase,
    {
      provide: APP_INITIALIZER,
      deps: [IndividualFirebase, PLATFORM_ID, NgZone],
      useFactory: initIndividualProject,
      multi: true
    },
    AuthService,
    MatFormField,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    provideNgxMask(),
    PopoverConfig,
    WeightConversionPipe,
    UnitConversionPipe,
    { provide: FIREBASE_OPTIONS, useValue: environment.firebaseProjectCommon },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    // { provide: MAT_DATE_LOCALE, useFactory: (languageService: LanguageService) => languageService.getGlobalSelectedFullLanguageCode(), deps: [LanguageService] },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {
      provide: USE_FIRESTORE_SETTINGS,
      useValue: { experimentalForceLongPolling: true },
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent]

})
export class AppModule {}

export function initCommonProject(project: CommonFirebase, platformId: Object, zone: NgZone) {
  return () => {
    const app = initializeApp(environment.firebaseProjectCommon);
    project.initialize(app, environment.firebaseProjectCommon.projectId, platformId, zone)
    return project
  };
}

export function initIndividualProject(project: IndividualFirebase, platformId: Object, zone: NgZone) {
  return () => {
    //const app = initializeApp(environment.firebaseProjectIndividual, 'individualFirebase');
    //project.initialize(app, environment.firebaseProjectIndividual.projectId, platformId, zone)
    return project
  };
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './i18n/', '.json');
}
