import { marker } from "@colsen1991/ngx-translate-extract-marker";
import { TranslateService } from "@ngx-translate/core";

export { }

declare global {
    interface Date {
        addDays(days: number): Date;
        addMonths(months: number): Date;
        isToday(): boolean;
        getDayNumber(): number;
        getWeekNumber(): number;
        getWeekOfYearNumber(): number;
        getYearOfWeekOfYearNumber(): number;
        clone(): Date;
        getStartOfWeek(): Date;
        getWithoutTime(): Date;
        isSameDate(date: Date): boolean;
        isPreviousDate(date: Date): boolean;
        isSameDateTimeZoneSensitive(date: Date): boolean;
        isSameDateTimeZoneReference(date: Date, timezoneOffset: number): boolean;
        isSameOrBeforeDate(date: Date): boolean;
        isSameOrAfterDate(date: Date): boolean;
        isSameWeek(date: Date): boolean;
        isPreviousWeek(date: Date): boolean;
        isSameOrBeforeWeek(date: Date): boolean;
        isSameOrAfterWeek(date: Date): boolean;
        isSameMonth(date: Date): boolean;
        isPreviousMonth(date: Date): boolean;
        isSameOrBeforeMonth(date: Date): boolean;
        isSameOrAfterMonth(date: Date): boolean;
        asFormatedString(): string;
        asShortFormatedString(): string;
        asYearSensitiveShortFormatedString(): string;
        asTimeFormatedString(): string;
        asDateTimeFormatedString(): string;
        getPrintableMonth(): string;
        getPrintableWeekday(translateService: TranslateService): string;
        getPrintableWeekdayShort(translateService: TranslateService): string;
        asEncodedString(): string;
        getMonthSinceYearZero(): number;
        getTimestampStringWithoutTime(): string;
        getTimestampString(): string;
        parseTimestampString(timestamp: string): Date;
    }
    interface Number {
        roundToInt(): number;
        roundToPlaces(places: number): number;
        roundToBestPlaces(): number;
        asDurationString(): string;
        asShortDurationString(): string;
        asDurationStringWithOptionalHours(): string;
        asAutomaticShortDurationString(): string;
    }
    interface File {
        isImage(): boolean;
        getFileName(): string
        getExtension(): string
    }
    // interface String {
    //     replaceAll(find: string, replaceString: string): string
    // }
}

Date.prototype.getWeekNumber = function () {
    return Math.floor((this.getTime() + 1000 * 60 * 60 * 24 * 4) / (1000 * 60 * 60 * 24 * 7));
};
Date.prototype.getDayNumber = function () {
    if (this.getDay() == 0) return 6
    return this.getDay() - 1
};
Date.prototype.getPrintableWeekday = function (translateService: TranslateService) {
    return translateService.instant([marker('Sonntag'), marker('Montag'), marker('Dienstag'), marker('Mittwoch'), marker('Donnerstag'), marker('Freitag'), marker('Samstag')][this.getDay()])
};
Date.prototype.getPrintableWeekdayShort = function (translateService: TranslateService) {
    return this.getPrintableWeekday(translateService).substring(0, 2)
};
Date.prototype.getWeekOfYearNumber = function () {
    var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
    var dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1) / 7)
};
Date.prototype.getYearOfWeekOfYearNumber = function () {
    var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
    var dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return yearStart.getFullYear()
};
Date.prototype.isSameDate = function (date: Date): boolean {
    return !!date && this.getFullYear() === date.getFullYear() && this.getMonth() === date.getMonth() && this.getDate() === date.getDate();
};

Date.prototype.isPreviousDate = function (date: Date): boolean {
    const clone = this.clone();
    clone.addDays(-1);
    return date.getFullYear() === clone.getFullYear() && date.getMonth() === clone.getMonth() && date.getDate() === clone.getDate();
};

Date.prototype.isSameDateTimeZoneSensitive = function (date: Date): boolean {
    return this.getTime() >= date.getWithoutTime().getTime() && this.getTime() < date.getWithoutTime().getTime() + 24*60*60*1000
};
Date.prototype.isSameDateTimeZoneReference = function (date: Date, timezoneOffset: number): boolean {
    return this.getTime() >= date.getTime() + timezoneOffset && this.getTime() < date.getTime() + timezoneOffset + 24*60*60*1000
};
Date.prototype.isSameOrBeforeDate = function (date: Date): boolean {
    return !!date && this.isSameDate(date) || this < date;
};
Date.prototype.isSameOrAfterDate = function (date: Date): boolean {
    return !!date && this.isSameDate(date) || this > date;
};

Date.prototype.isSameWeek = function (date: Date): boolean {
  return this.getFullYear() === date.getFullYear() && this.getMonth() === date.getMonth() && this.getWeekOfYearNumber() === date.getWeekOfYearNumber();
}

Date.prototype.isPreviousWeek = function (date: Date): boolean {
  const clone = this.clone();
  clone.addDays(-7);
  return date.getFullYear() === clone.getFullYear() && date.getMonth() === clone.getMonth() && date.getWeekOfYearNumber() === clone.getWeekOfYearNumber();
}

Date.prototype.isSameOrBeforeWeek = function (date: Date): boolean {
  const yearDiff = this.getYearOfWeekOfYearNumber() - date.getYearOfWeekOfYearNumber();
  if(yearDiff > 0) {
    return false;
  }
  const monthDiff = this.getMonth() - (date.getMonth()  + Math.abs(yearDiff) * 12);
  if(monthDiff > 0) {
    return false;
  }

  const weekDiff = this.getWeekOfYearNumber() - (date.getWeekOfYearNumber() + (Math.abs(monthDiff) + Math.abs(yearDiff) * 12) * 4);
  return weekDiff <= 0;
}

Date.prototype.isSameOrAfterWeek = function (date: Date): boolean {
  const yearDiff = this.getYearOfWeekOfYearNumber() - date.getYearOfWeekOfYearNumber();
  if(yearDiff < 0) {
    return false;
  }
  const monthDiff = this.getMonth() - (date.getMonth()  + Math.abs(yearDiff) * 12);
  if(monthDiff < 0) {
    return false;
  }

  const weekDiff = this.getWeekOfYearNumber() - (date.getWeekOfYearNumber() + (Math.abs(monthDiff) + Math.abs(yearDiff) * 12) * 4);
  return weekDiff >= 0;
}

Date.prototype.isSameMonth = function (date: Date): boolean {
  return this.getFullYear() === date.getFullYear() && this.getMonth() === date.getMonth();
}

Date.prototype.isPreviousMonth = function (date: Date): boolean {
  const clone = this.clone();
  clone.addMonths(-1);
  return date.getFullYear() === clone.getFullYear() && date.getMonth() === clone.getMonth();
}

Date.prototype.isSameOrBeforeMonth = function (date: Date): boolean {
  const yearDiff = this.getFullYear() - date.getFullYear();
  if(yearDiff > 0) {
    return false;
  }
  const monthDiff = this.getMonth() - (date.getMonth()  + Math.abs(yearDiff) * 12);
  return monthDiff <= 0;
}

Date.prototype.isSameOrAfterMonth = function (date: Date): boolean {
  const yearDiff = this.getFullYear() - date.getFullYear();
  if(yearDiff < 0) {
    return false;
  }
  const monthDiff = this.getMonth() - (date.getMonth()  + Math.abs(yearDiff) * 12);
  return monthDiff >= 0;
}

Date.prototype.isToday = function (): boolean {
    const date = new Date()
    return date && this.getFullYear() === date.getFullYear() && this.getMonth() === date.getMonth() && this.getDate() === date.getDate();
};
Date.prototype.clone = function (): Date {
    return new Date(+this);
};
Date.prototype.getWithoutTime = function (): Date {
    return new Date(
        this.getFullYear(),
        this.getMonth(),
        this.getDate()
    );
};
Date.prototype.getStartOfWeek = function (): Date {
    var endDate = this.getWithoutTime()
    endDate.addDays(-endDate.getDayNumber())
    return endDate
};
Date.prototype.addDays = function (days: number): Date {
    if (!days) return this;
    let date = this;
    date.setDate(date.getDate() + days);
    return date;
};

Date.prototype.addMonths = function (months: number): Date {
    if (!months) return this;
    let date = this;
    date.setMonth(date.getMonth() + months);
    return date;
};

Date.prototype.asFormatedString = function (): string {
    return this.getDate() + '.' + (this.getMonth() + 1) + '.' + this.getFullYear();
};
Date.prototype.asShortFormatedString = function (): string {
    return (this.getDate() < 10 ? '0' : '') + this.getDate() + '.' + (this.getMonth() < 9 ? '0' : '') + (this.getMonth() + 1) + '.';
};

Date.prototype.asYearSensitiveShortFormatedString = function (): string {
  const now = new Date();
  if(now.getFullYear() === this.getFullYear()) {
    return (this.getDate() < 10 ? '0' : '') + this.getDate() + '.' + (this.getMonth() < 9 ? '0' : '') + (this.getMonth() + 1) + '.';
  }
  return (this.getDate() < 10 ? '0' : '') + this.getDate() + '.' + (this.getMonth() < 9 ? '0' : '') + (this.getMonth() + 1) + '.' + this.getFullYear();
};

Date.prototype.asTimeFormatedString = function (): string {
    return this.toTimeString().slice(0, 5);
}
Date.prototype.asDateTimeFormatedString = function (): string {
    return this.getDate() + '.' + (this.getMonth() + 1) + '.' + this.getFullYear() + ' ' + this.toTimeString().slice(0, 5);
}
Date.prototype.asEncodedString = function (): string {
    return this.getFullYear() + '-' + (this.getMonth() < 9 ? '0' : '') + (this.getMonth() + 1) + '-' + (this.getDate() < 10 ? '0' : '') + this.getDate() + ' ' + this.getHours() + '-' + this.getMinutes() + '-' + this.getSeconds();
};

Date.prototype.getPrintableMonth = function (): string {
    return [
        marker('Januar'),
        marker('Februar'),
        marker('März'),
        marker('April'),
        marker('Mai'),
        marker('Juni'),
        marker('Juli'),
        marker('August'),
        marker('September'),
        marker('Oktober'),
        marker('November'),
        marker('Dezember')
    ][this.getMonth()];
};

Date.prototype.getMonthSinceYearZero = function (): number {
    return this.getFullYear() * 12 + this.getMonth();
}

Date.prototype.getTimestampStringWithoutTime = function (): string {
    return this.getFullYear() + '-' + (this.getMonth() < 9 ? '0' : '') + (this.getMonth() + 1) + '-' + (this.getDate() < 10 ? '0' : '') + this.getDate();
}

Date.prototype.getTimestampString = function (): string {
    return this.toISOString();
}

Date.prototype.parseTimestampString = function (timestamp: string): Date {
    if(!timestamp) return null;
    let splitted = timestamp?.split('T');

    if(splitted?.length > 0) {
        let date = splitted[0].split('-');
        if(date.length != 3) return null;
        if(splitted.length > 1) {
            let time = splitted[1].split(':');
            if(time.length != 3) return null;
            return new Date(+date[0], +date[1] - 1, +date[2], +time[0], +time[1], +time[2].split('.')[0]);
        }
        return new Date(+date[0], +date[1] - 1, +date[2]);
    }
    return null;
}

Number.prototype.roundToInt = function (): number {
    return Math.round(this)
};
Number.prototype.roundToPlaces = function (places: number): number {
    return Math.round(this * Math.pow(10, places)) / Math.pow(10, places)
};

Number.prototype.roundToBestPlaces = function (): number {
    if (this == 0 || this == null) return 0;
    if (this >= 1) {
        return this.roundToPlaces(2)
    }
    else if (this >= 0.1) {
        return this.roundToPlaces(3);
    }
    else if (this >= 0.01) {
        return this.roundToPlaces(4);
    }
    else {
        return this.roundToPlaces(5);
    }
};

Number.prototype.asDurationStringWithOptionalHours = function (): string {
    var hours = Math.floor(this / 3600);
    var minutes = Math.floor((this - (hours * 3600)) / 60);
    var seconds = this - (hours * 3600) - (minutes * 60);

    var outputHours = hours.toString();
    var outputMinutes = minutes.toString();
    var outputSeconds = seconds.toString();

    if (minutes < 10) { outputMinutes = "0" + minutes; }
    if (seconds < 10) { outputSeconds = "0" + seconds; }
    if (hours == 0) {
        return outputMinutes + ':' + outputSeconds;
    }
    if (hours < 10) { outputHours = "0" + hours; }
    return outputHours + ':' + outputMinutes + ':' + outputSeconds;
}

Number.prototype.asDurationString = function (): string {
    var hours = Math.floor(this / 3600);
    var minutes = Math.floor((this - (hours * 3600)) / 60);
    var seconds = this - (hours * 3600) - (minutes * 60);

    var outputHours = hours.toString();
    var outputMinutes = minutes.toString();
    var outputSeconds = seconds.toString();


    if (hours < 10) { outputHours = "0" + hours; }
    if (minutes < 10) { outputMinutes = "0" + minutes; }
    if (seconds < 10) { outputSeconds = "0" + seconds; }
    return outputHours + ':' + outputMinutes + ':' + outputSeconds;
};

Number.prototype.asShortDurationString = function (): string {
    var minutes = Math.floor(this / 60);
    var seconds = this - (minutes * 60);

    var outputMinutes = minutes.toString();
    var outputSeconds = seconds.toString();

    if (minutes < 10) { outputMinutes = "0" + minutes; }
    if (seconds < 10) { outputSeconds = "0" + seconds; }
    return outputMinutes + ':' + outputSeconds;
}


Number.prototype.asAutomaticShortDurationString = function (): string {
    var hours = Math.floor(this / 3600);
    var minutes = Math.floor((this - (hours * 3600)) / 60);
    var seconds = this - (hours * 3600) - (minutes * 60);

    var outputHours = hours.toString();
    var outputMinutes = minutes.toString();
    var outputSeconds = seconds.toString();


    if (hours < 10) { outputHours = "0" + hours; }
    if (minutes < 10) { outputMinutes = "0" + minutes; }
    if (seconds < 10) { outputSeconds = "0" + seconds; }
    if (hours == 0) {
        return outputMinutes + ':' + outputSeconds + ' min';
    }
    return outputHours + ':' + outputMinutes + ':' + outputSeconds + ' h';
};

File.prototype.isImage = function (): boolean {
    return this.name.toLowerCase().endsWith('.png') || this.name.toLowerCase().endsWith('.jpg') || this.name.toLowerCase().endsWith('.jpeg')
};
File.prototype.getFileName = function (): string {
    return this.name.substring(0, this.name.lastIndexOf('.'))
};
File.prototype.getExtension = function (): string {
    return this.name.substring(this.name.lastIndexOf('.'), this.name.length)
};

// String.prototype.replaceAll = function (find: string, replaceString: string): string {
//     return this?.replace(new RegExp(find, 'g'), replaceString);
// }
