import { TrainingSessionContainer } from 'src/app/training-monitoring/training-history-dialog/training-history-dialog.component';
import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { Activity } from 'src/app/model/activity.model';
import { User } from 'src/app/model/user.model';
import { SpikeService } from 'src/app/services/spike-service.service';
import { ChartAnnotationService, ChartComponent, ChartModule, CrosshairService, ILegendRenderEventArgs, IMouseEventArgs, LegendService } from '@syncfusion/ej2-angular-charts'
import {
  CategoryService, LineSeriesService, StepLineSeriesService, SplineSeriesService, StackingLineSeriesService, DateTimeService,
  SplineAreaSeriesService, MultiColoredLineSeriesService, ParetoSeriesService, ColumnSeriesService, TooltipService, StripLineService, RangeAreaSeriesService
} from '@syncfusion/ej2-angular-charts'
import { TrainingService } from 'src/app/services/training.service';
import { LanguageService } from 'src/app/services/language.service';
import { CardioZone, CardioZoneGroup } from 'src/app/model/cardio-zone-group.model';
import { TrainingPlanEditorComponent } from 'src/app/training/training-plan-editor/training-plan-editor.component';
import { SetParameter } from 'src/app/model/training-plan.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cardio-evaluation',
  templateUrl: './cardio-evaluation.component.html',
  styleUrls: ['./cardio-evaluation.component.css'],
  providers: [CategoryService, LineSeriesService, StepLineSeriesService, SplineSeriesService, StackingLineSeriesService, DateTimeService,
    SplineAreaSeriesService, MultiColoredLineSeriesService, ParetoSeriesService, ColumnSeriesService, TooltipService, StripLineService, CrosshairService, ChartAnnotationService, LegendService, RangeAreaSeriesService],
})
export class CardioEvaluationComponent {

  private user: User
  public trainingSessionContainer: TrainingSessionContainer;
  public activity: Activity
  public spikeCardioData: SpikeCardioData
  public allDataSamples: DataSample[] = []
  public startDate: Date
  public endDate: Date
  public loading: boolean = true

  public hasPace = false
  public hasHeartRate = false
  public visualHeartRateRange = [null, null]
  public visualPaceRange = [null, null]

  public minAxisHR: number;
  public maxAxisHR: number;
  public primaryXAxisHR: Object;
  public primaryYAxisHR: Object;
  public chartAreaHR: Object;
  public crosshairHR: Object;
  public borderHR: Object;
  public markerHR: Object;
  public tooltipHR: Object;
  private xAxisStripLinesHR: any[];
  public legendSettingsHR: Object;
  public disabledGlobalCardioZonesHR: CardioZone[] = [];

  public minAxisPace: number;
  public maxAxisPace: number;
  public primaryXAxisPace: Object;
  public primaryYAxisPace: Object;
  public chartAreaPace: Object;
  public crosshairPace: Object;
  public borderPace: Object;
  public markerPace: Object;
  public tooltipPace: Object;
  private xAxisStripLinesPace: any[];
  public legendSettingsPace: Object;
  public disabledGlobalCardioZonesPace: CardioZone[] = [];

  public defaultCardioZoneGroup: CardioZoneGroup;

  public showCardioZonesHR: boolean = true;
  public showExercisesHR: boolean = true;

  public showCardioZonesPace: boolean = true;
  public showExercisesPace: boolean = true;

  public globalCardioZones: CardioZone[] = [];
  public CardioZoneGroup = CardioZoneGroup;

  @Input() public set User(value: User) {
    this.user = value
    this.defaultCardioZoneGroup = this.user.cardioZoneGroups?.find(group => group.isDefaultZoneGroup);
    this.disabledGlobalCardioZonesHR = [];
    this.loadData()
  }
  @Input() public set Activity(value: Activity) {
    this.activity = value
    this.loadData()
  }
  @Input() public set StartDate(value: Date) {
    if(!value) return;
    this.startDate = new Date(value.getTime() - 1000 * 60 * 5)
    this.loadData()
  }
  @Input() public set EndDate(value: Date) {
    if(!value) return;
    this.endDate = new Date(value.getTime() + 1000 * 60 * 5)
    this.loadData()
  }

  @Input() public set TrainingSessionContainer(value: TrainingSessionContainer) {
    this.trainingSessionContainer = value;
    this.loadData()
  }

  constructor(private spikeService: SpikeService, private trainingService: TrainingService, private languageService: LanguageService, private userService: FirestoreService, private translate: TranslateService) {
    this.globalCardioZones = this.userService.getLoggedInUser()?.trainingSettingsLicenceHolder?.cardioZones?.map(x => x.clone());

    this.primaryXAxisHR = {
      valueType: 'DateTime',
      crosshairTooltip: { enable: false },
      labelFormat: 'HH:mm'
    };
    this.chartAreaHR = {
      border: {
        width: 0
      }
    };
    this.crosshairHR = {
      enable: false,
    };
    this.borderHR = {
      color: 'var(--accentColor)',
      width: 3
    };
    this.markerHR = {
      visible: false
    };
    this.legendSettingsHR = {
      visible: true,
    }
    this.tooltipHR = { enable: true, header: this.translate.instant('Herzfrequenz'), format: '<b>${point.x} : ${point.y}</b>', shared: true };

    this.primaryXAxisPace = {
      valueType: 'DateTime',
      crosshairTooltip: { enable: false }
    };
    this.chartAreaPace = {
      border: {
        width: 0
      }
    };
    this.crosshairPace = {
      enable: false,
    };
    this.borderPace = {
      color: 'rgb(126, 225, 249)',
      width: 3
    };
    this.markerPace = {
      visible: false
    };
    this.legendSettingsPace = {
      visible: true,
    }
    this.tooltipPace = { enable: true, header: this.translate.instant('Pace'), format: '<b>${point.x} : ${point.high}</b>', shared: true };
  }

  hasAccess() {
    return this.user?.spikeUserId != null || this.activity?.heartRate
  }

  isHRCardioZoneDisabled(zone: CardioZone) {
    return this.disabledGlobalCardioZonesHR.find(x => x.id == zone.id);
  }

  toggleHRCardioZone(zone: CardioZone) {
    if (this.disabledGlobalCardioZonesHR.includes(zone)) {
      this.disabledGlobalCardioZonesHR = this.disabledGlobalCardioZonesHR.filter(z => z != zone);
    }
    else {
      this.disabledGlobalCardioZonesHR.push(zone);
    }
    this.setVisibleCardioZoneAxis();
  }

  isPaceCardioZoneDisabled(zone: CardioZone) {
    return this.disabledGlobalCardioZonesPace.find(x => x.id == zone.id);
  }

  togglePaceCardioZone(zone: CardioZone) {
    if (this.disabledGlobalCardioZonesPace.includes(zone)) {
      this.disabledGlobalCardioZonesPace = this.disabledGlobalCardioZonesPace.filter(z => z != zone);
    }
    else {
      this.disabledGlobalCardioZonesPace.push(zone);
    }
    this.setVisibleCardioZoneAxis();
  }

  toggleShowCardioZonesHR() {
    this.showCardioZonesHR = !this.showCardioZonesHR;
    this.setVisibleCardioZoneAxis();
  }

  toggleShowExercisesHR() {
    this.showExercisesHR = !this.showExercisesHR;
    this.setVisibleCardioZoneAxis();
  }

  toggleShowCardioZonesPace() {
    this.showCardioZonesPace = !this.showCardioZonesPace;
    this.setVisibleCardioZoneAxis();
  }
  toggleShowExercisesPace() {
    this.showExercisesPace = !this.showExercisesPace;
    this.setVisibleCardioZoneAxis();
  }

  private latestLoadingDate: Date

  async loadData() {

    if (!(this.user && (this.activity || (this.startDate && this.endDate)))) return

    var loadingDate = new Date()
    this.latestLoadingDate = loadingDate.clone()

    this.hasPace = false
    this.hasHeartRate = false

    if (this.activity) {
      this.startDate = this.activity.date
      this.endDate = this.activity.endDate
      let samples = this.activity.getDataSamples()

      if (samples.length > 0) {
        var heartRateSamples = samples.filter(sample => sample.heartRate)
        var minHR = Math.min.apply(Math, heartRateSamples.map(function (o) { return o.heartRate }))
        var maxHR = Math.max.apply(Math, heartRateSamples.map(function (o) { return o.heartRate }))
        this.minAxisHR = (minHR - 10 > 0) ? minHR - 10 : 0;
        this.maxAxisHR = maxHR + 10

        var paceSamples = samples.filter(sample => sample.pace)
        var minPace = paceSamples ? Math.min.apply(Math, paceSamples.map(function (o) { return o.pace })) : null
        var maxPace = paceSamples ? Math.max.apply(Math, paceSamples.map(function (o) { return o.pace })) : null


        this.minAxisPace = (minPace - 10 > 0) ? minPace - 10 : 0;
        this.maxAxisPace = maxPace + 1

        paceSamples?.forEach(sample => {
          sample.low = this.maxAxisPace + 10;
        });

        var data = new SpikeCardioData({
          timeStart: this.startDate,
          timeEnd: this.endDate,
          dataSamples: samples,
          minHR: minHR?.toFixed() ?? null,
          averageHR: heartRateSamples?.length > 0 ? (heartRateSamples.reduce((a, b) => a + b.heartRate, 0) / heartRateSamples.length)?.toFixed() ?? null : null,
          maxHR: maxHR?.toFixed() ?? null,
        })
        this.hasHeartRate = heartRateSamples.length > 0
        if (this.hasHeartRate) {
          this.visualHeartRateRange = [minHR - 10, maxHR + 10]
        }

        this.hasPace = paceSamples?.length > 0
        if (this.hasPace) {
          this.visualPaceRange = [minPace, maxPace]
          data.minPace = minPace
          data.maxPace = maxPace
          data.averagePace = paceSamples?.length > 0 ? (paceSamples.reduce((a, b) => a + b.pace, 0) / paceSamples.length) : null
          if (data.averagePace) {
            var seconds = Math.round((data.averagePace - Math.floor(data.averagePace)) * 60)
            data.averagePaceInMinutes = Math.floor(data.averagePace) + ':' + (seconds < 10 ? '0' : '') + seconds
          }
          if (data.minPace) {
            var seconds = Math.round((data.minPace - Math.floor(data.minPace)) * 60)
            data.minPaceInMinutes = Math.floor(data.minPace) + ':' + (seconds < 10 ? '0' : '') + seconds
          }
        }

        this.spikeCardioData = data
        this.loading = false
        this.setVisibleCardioZoneAxis();
        return
      }
    }

    var res = await this.spikeService.getActivities(this.user, this.startDate, this.endDate)
    var responseData: any = res

    if (this.latestLoadingDate.getTime() != loadingDate.getTime()) return

    if (responseData && responseData.data && responseData.data[0]) {

      var dataIndex = 0
      var startDateDeviaton = null
      if (responseData.data.length > 1) {
        for (var i = 0; i < responseData.data.length; i++) {
          var start = new Date(responseData.data[i].time_start)
          if (startDateDeviaton == null || Math.abs(start.getTime() - this.startDate.getTime()) < startDateDeviaton) {
            startDateDeviaton = Math.abs(start.getTime() - this.startDate.getTime())
            dataIndex = i
          }
        }
      }

      var data = new SpikeCardioData({
        timeStart: new Date(responseData.data[dataIndex].time_start),
        timeEnd: new Date(responseData.data[dataIndex].time_end),
        minHR: responseData.data[dataIndex].min_hr,
        averageHR: responseData.data[dataIndex].avg_hr,
        maxHR: responseData.data[dataIndex].max_hr,
        dataSamples: [],
        source: responseData.source[0].name
      })
      this.hasHeartRate = true

      this.allDataSamples = []
      if (responseData.data[dataIndex].samples) {
        var samples = responseData.data[dataIndex].samples

        let startDate = data.timeStart;
        samples.forEach((sample: any) => {
          var pace = sample.speed == 0 || sample.speed == null ? null : 1000 / (sample.speed! * 60)
          if (pace > 0) this.hasPace = true
          if (sample.heartrate > 0) this.hasHeartRate = true
          let startOfDay = startDate.clone()
          startOfDay.setHours(0, 0, 0, 0);
          let momentTime = new Date(sample.time_start)?.getTime() - startDate?.getTime();
          let moment = new Date(startOfDay?.getTime() + momentTime);
          this.allDataSamples.push(new DataSample({
            time: sample.time_start,
            date: new Date(sample.time_start),
            heartRate: sample.heartrate,
            speed: sample.speed,
            pace: pace,
            moment: moment
          }))
        })

        var minPace = Math.min.apply(Math, paceSamples?.map(function (o) { return o.pace }))
        var maxPace = Math.max.apply(Math, paceSamples?.map(function (o) { return o.pace }))

        this.minAxisPace = (minPace - 10 > 0) ? minPace - 10 : 0;
        this.maxAxisPace = maxPace + 1

        data.dataSamples = this.allDataSamples.filter(sample => sample.date >= this.startDate && sample.date <= this.endDate).map(sample => sample.clone())
        //data.maxPace = responseData.data[dataIndex].min_speed ? (1000 / (responseData.data[dataIndex].min_speed! * 60)) : null
        if (!data.maxPace) {
          data.maxPace = Math.max.apply(Math, data.dataSamples.map(function (o) { return o.pace; }))
        }
        data.dataSamples.forEach(sample => {
          if (sample.pace == null) sample.pace = data.maxPace
          sample.low = this.maxAxisPace + 10;
        })
        data.minPace = responseData.data[dataIndex].max_speed ? (1000 / (responseData.data[dataIndex].max_speed! * 60)) : null
        if (!data.minPace) {
          data.minPace = Math.min.apply(Math, data.dataSamples.map(function (o) { return o.pace; }))
        }
        data.averagePace = responseData.data[dataIndex].avg_speed ? (1000 / (responseData.data[dataIndex].avg_speed! * 60)) : null
        if (data.averagePace) {
          var seconds = Math.round((data.averagePace - Math.floor(data.averagePace)) * 60)
          data.averagePaceInMinutes = Math.floor(data.averagePace) + ':' + (seconds < 10 ? '0' : '') + seconds
        }
        if (data.minPace) {
          var seconds = Math.round((data.minPace - Math.floor(data.minPace)) * 60)
          data.minPaceInMinutes = Math.floor(data.minPace) + ':' + (seconds < 10 ? '0' : '') + seconds
        }
        this.visualPaceRange = [0, data.maxPace]
        var minHR = Math.min.apply(Math, data.dataSamples.map(function (o) { return o.heartRate; }))
        var maxHR = Math.max.apply(Math, data.dataSamples.map(function (o) { return o.heartRate; }))
        this.visualHeartRateRange = [minHR - 10, maxHR + 10]
        this.minAxisHR = (minHR - 10 > 0) ? minHR - 10 : 0;
        this.maxAxisHR = maxHR + 10

        var minPace = Math.min.apply(Math, data.dataSamples.map(function (o) { return o.pace; }))
        var maxPace = Math.max.apply(Math, data.dataSamples.map(function (o) { return o.pace; }))
        this.minAxisPace = (minPace - 10 > 0) ? minPace - 10 : 0;
        this.maxAxisPace = maxPace + 1


        data.dataSamples.forEach(sample => {
          sample.low = this.maxAxisPace + 10;
        });
      }

      this.spikeCardioData = data
      this.loading = false

    } else {

      var res = await this.spikeService.getHeartFrequency(this.user, this.startDate, this.endDate)
      dataIndex = 0

      var responseData: any = res
      if (!responseData || !responseData.data || !responseData.data[dataIndex]) {
        this.loading = false
        return
      }


      let startDate = this.startDate?.clone();
      let startOfDay = startDate?.clone();
      startOfDay.setHours(0, 0, 0, 0);
      const heartRateSamples = responseData.data[dataIndex].heart_rate_samples;
      if (!heartRateSamples) return
      heartRateSamples.forEach((sample) => {
        let momentTime = new Date(sample.time)?.getTime() - startDate?.getTime();
        let moment = new Date(startOfDay?.getTime() + momentTime);
        this.allDataSamples.push(new DataSample({
          time: sample.time,
          date: new Date(sample.time),
          heartRate: sample.value,
          moment: new Date(moment)
        }))
      })

      let samples = this.allDataSamples.filter(sample => sample.date >= this.startDate && sample.date <= this.endDate).map(sample => sample.clone())
      this.hasPace = false

      var minHR = Math.min.apply(Math, samples.map(function (o) { return o.heartRate; }))
      var maxHR = Math.max.apply(Math, samples.map(function (o) { return o.heartRate; }))
      var averageHR = samples?.length > 0 ? (samples.reduce((a, b) => a + b.heartRate, 0) / samples.length) : null
      this.minAxisHR = (minHR - 10 > 0) ? minHR - 10 : 0;
      this.maxAxisHR = maxHR + 10

      let data = new SpikeCardioData({
        timeStart: this.startDate,
        timeEnd: this.endDate,
        dataSamples: samples,
        source: responseData.source[dataIndex].name,
        minHR: minHR?.toFixed() ?? null,
        averageHR: averageHR?.toFixed() ?? null,
        maxHR: maxHR?.toFixed() ?? null,
      })

      this.visualHeartRateRange = [minHR - 10, maxHR + 10]
      this.hasHeartRate = true

      this.spikeCardioData = data
      this.loading = false
    }

    this.setVisibleCardioZoneAxis();
  }

  setVisibleCardioZoneAxis() {
    let stripLinesHR = [];
    let xAxisStripLinesHR = [];
    let stripLinesPace = [];
    let xAxisStripLinesPace = [];
    let cardioZoneGroups = this.user.cardioZoneGroups;
    let container = this.trainingSessionContainer;
    if (container) {
      for (let i = 0; i < container.trackedTrainingSession.trackedTrainingExercises.length; i++) {
        let exercise = container.trackedTrainingSession.trackedTrainingExercises[i];

        let startTime = exercise.startDate?.getTime() - this.startDate?.getTime();
        let startOfDay = exercise.startDate?.clone();
        startOfDay.setHours(0, 0, 0, 0);
        let startDate = new Date(startOfDay?.getTime() + startTime);
        let endDate;
        if (i < container.trackedTrainingSession.trackedTrainingExercises.length - 1) {
          let nextExercise = container.trackedTrainingSession.trackedTrainingExercises[i + 1];
          endDate = nextExercise.startDate?.clone();
        }
        else {
          endDate = container.trackedTrainingSession.endDate?.clone();
        }
        let endTime = endDate?.getTime() - this.startDate?.getTime();
        endDate = new Date(startOfDay?.getTime() + endTime);
        if (startDate && endDate) {
          let paceExerciseZoneGroup = TrainingPlanEditorComponent.getUserCardioZoneGroupByExerciseId(exercise.exerciseId, SetParameter.pace, cardioZoneGroups);
          if (this.showCardioZonesPace && paceExerciseZoneGroup) {
            paceExerciseZoneGroup.zones.forEach(zone => {
              if (!zone.isMaxCardioZone) {
                if (!this.isPaceCardioZoneDisabled(zone)) {
                  let color = CardioZoneGroup.getColorByCardioZone(this.globalCardioZones, zone);
                  let min = zone.minPace / 60;
                  let max = zone.maxPace / 60;
                  let stripLinePace = { start: min, end: max, name: zone.name, isSegmented: true, segmentStart: startDate, segmentEnd: endDate, color: color, opacity: 0.8 }
                  stripLinesPace.push(stripLinePace);
                }
              }
            });
          }
          let heartRateExerciseZoneGroup = TrainingPlanEditorComponent.getUserCardioZoneGroupByExerciseId(exercise.exerciseId, SetParameter.heartRate, cardioZoneGroups);
          if (this.showCardioZonesHR && heartRateExerciseZoneGroup) {
            heartRateExerciseZoneGroup.zones.forEach(zone => {
              if (!zone.isMaxCardioZone) {
                if (!this.isHRCardioZoneDisabled(zone)) {
                  let color = CardioZoneGroup.getColorByCardioZone(this.globalCardioZones, zone);
                  let min = zone.minHeartRate;
                  let max = zone.maxHeartRate;
                  let stripLineHR = { start: min, end: max, name: zone.name, isSegmented: true, segmentStart: startDate, segmentEnd: endDate, color: color, opacity: 0.8 }

                  stripLinesHR.push(stripLineHR);
                }
              }
            });
          }
          let endDateEnd: Date = endDate.clone();
          endDateEnd.setSeconds(endDateEnd.getSeconds() + 1);
          let exerciseName = this.trainingService.getExerciseById(exercise.exerciseId)?.name?.GetValue(this.translate.currentLang) ?? ("Exercise" + (i + 1));
          if (this.showExercisesHR) {
            let xAxisStripLineBox = { id: exercise.id, start: startDate, end: endDateEnd, text: exerciseName, color: 'transparent', textStyle: { size: 10, color: 'var(--textColor)', fontWeight: '500', textOverflow: 'Wrap' }, rotation: '270', visible: true, opacity: 0.8, zIndex: 'Over' };
            xAxisStripLinesHR.push(xAxisStripLineBox);
            let xAxisStripLine = { id: exercise.id, start: endDate, end: endDateEnd, text: '', color: 'black', zIndex: 'Over', textStyle: { size: 8, color: 'black' }, border: { width: 2, color: 'black' }, visible: true, opacity: 0.8 };
            xAxisStripLinesHR.push(xAxisStripLine);
          }
          if (this.showExercisesPace) {
            let xAxisStripLineBox = { id: exercise.id, start: startDate, end: endDateEnd, text: exerciseName, color: 'transparent', textStyle: { size: 10, color: 'var(--textColor)', fontWeight: '500', textOverflow: 'Wrap' }, rotation: '270', visible: true, opacity: 0.8, zIndex: 'Over' };
            xAxisStripLinesPace.push(xAxisStripLineBox);
            let xAxisStripLine = { id: exercise.id, start: endDate, end: endDateEnd, text: '', color: 'black', zIndex: 'Over', textStyle: { size: 8, color: 'black' }, border: { width: 2, color: 'black' }, visible: true, opacity: 0.8 };
            xAxisStripLinesPace.push(xAxisStripLine);
          }
        }
      }


      this.primaryYAxisHR = {
        valueType: 'Double',
        stripLines: stripLinesHR,
        minimum: this.minAxisHR,
        maximum: this.maxAxisHR
      };
      this.primaryXAxisHR = {
        valueType: 'DateTime',
        stripLines: xAxisStripLinesHR,
        crosshairTooltip: { enable: false },
        labelFormat: 'HH:mm'
      };

      this.primaryYAxisPace = {
        valueType: 'Double',
        stripLines: stripLinesPace,
        minimum: this.minAxisPace,
        maximum: this.maxAxisPace,
        isInversed: true
      };
      this.primaryXAxisPace = {
        valueType: 'DateTime',
        stripLines: xAxisStripLinesPace,
        labelFormat: 'HH:mm'
      };
    }
    else {
      let cardioZoneGroups = this.user.cardioZoneGroups;
      let defaultZoneGroup = cardioZoneGroups.find(group => group.isDefaultZoneGroup);
      let startDate = this.startDate?.clone();
      let startOfDay = startDate?.clone();
      startOfDay.setHours(0, 0, 0, 0);

      let timeSpan = (this.endDate?.getTime() ?? 0) - (startDate?.getTime() ?? 0);
      let endDate = new Date(startOfDay?.getTime() + timeSpan);

      if (defaultZoneGroup) {
        defaultZoneGroup.zones.forEach(zone => {
          if (!zone.isMaxCardioZone) {
            if (this.showCardioZonesHR && !this.isHRCardioZoneDisabled(zone) && this.defaultCardioZoneGroup.heartRateAvailable) {
              let color = CardioZoneGroup.getColorByCardioZone(this.globalCardioZones, zone);
              let min = zone.minHeartRate;
              let max = zone.maxHeartRate;
              let stripLineHR = { start: min, end: max, name: zone.name, isSegmented: true, segmentStart: startOfDay, segmentEnd: endDate, color: color, opacity: 0.8 }
              stripLinesHR.push(stripLineHR);
            }
            if (this.showCardioZonesPace && !this.isPaceCardioZoneDisabled(zone) && this.defaultCardioZoneGroup.paceAvailable) {
              let color = CardioZoneGroup.getColorByCardioZone(this.globalCardioZones, zone);
              let min = zone.minPace / 60;
              let max = zone.maxPace / 60;
              let stripLinePace = { start: min, end: max, name: zone.name, isSegmented: true, segmentStart: startOfDay, segmentEnd: endDate, color: color, opacity: 0.8 }
              stripLinesPace.push(stripLinePace);
            }
          }
        });

        if (this.maxAxisHR == undefined) {
          this.maxAxisHR = null;
        }
        if (this.minAxisHR == undefined) {
          this.minAxisHR = null;
        }
        if (this.maxAxisPace == undefined) {
          this.maxAxisPace = null;
        }
        if (this.minAxisPace == undefined) {
          this.minAxisPace = null;
        }
        this.primaryYAxisHR = {
          valueType: 'Double',
          stripLines: stripLinesHR,
          minimum: this.minAxisHR,
          maximum: this.maxAxisHR
        };
        this.primaryYAxisPace = {
          valueType: 'Double',
          stripLines: stripLinesPace,
          minimum: this.minAxisPace,
          maximum: this.maxAxisPace,
          isInversed: true
        };
      }
    }
  }

  public isMouseDown: boolean = false;
  public stripEle: any;
  public stripEleIndex: number;
  @ViewChild('heartRateChart')
  public heartRateChart: ChartComponent;
  public mouseDown(args: IMouseEventArgs): void {
    // if(args.target == "charts_stripline_Behind_rect_primaryYAxis_0"){
    //     this.stripEle = document.getElementById("charts_stripline_Behind_rect_primaryYAxis_0");
    //     this.isMouseDown = true;
    // }
    if (args.target.startsWith("chart-container_stripline_Behind_rect_primaryXAxis") || args.target.startsWith("chart-container_stripline_Behind_border_primaryXAxis") || args.target.startsWith("chart-container_stripline_Over_rect_primaryXAxis") || args.target.startsWith("chart-container_stripline_Over_border_primaryXAxis")) {
      this.stripEle = document.getElementById(args.target);
      this.isMouseDown = true;
      this.stripEleIndex = parseInt(args.target.split("_").pop());
    }
  };
  public mouseMove(args: IMouseEventArgs): void {
    if (this.isMouseDown && this.stripEle) {
      let mouseX = args.x;
      let mouseY = args.y;
      let xAxis = this.heartRateChart.series[0]["xAxis"];
      let yAxis = this.heartRateChart.series[0]["yAxis"];
      let rect = this.heartRateChart.series[0]["clipRect"];
      let xVal = mouseX - rect.x;
      let yVal = mouseY - rect.y;
      let xSize = rect.width;
      let ySize = rect.height;
      let actualXValue = !xAxis.isInversed ? xVal / xSize : (1 - (xVal / xSize));
      actualXValue = actualXValue * (xAxis.visibleRange.delta) + xAxis.visibleRange.min;
      let stripLine = this.heartRateChart.primaryXAxis.stripLines[this.stripEleIndex]

      this.heartRateChart.primaryXAxis.stripLines[this.stripEleIndex].start = Math.round(actualXValue);
      this.heartRateChart.primaryXAxis.stripLines[this.stripEleIndex].end = Math.round(actualXValue) + 1;


      let endDate = new Date(Math.round(actualXValue));
      let endEndDate = new Date(Math.round(actualXValue));
      endEndDate.setSeconds(endEndDate.getSeconds() + 1);

      let nextStripLine = this.xAxisStripLinesHR[this.stripEleIndex + 1];
      if (nextStripLine?.end < endDate) {
        return;
      }
      let previousStripLine = this.xAxisStripLinesHR[this.stripEleIndex - 1];
      if (previousStripLine?.end > endDate) {
        return;
      }
      stripLine.start = endDate;
      stripLine.end = endEndDate;
      if (this.stripEleIndex + 1 < this.xAxisStripLinesHR.length) {
        let nextStripLine = this.xAxisStripLinesHR[this.stripEleIndex + 1];
        nextStripLine.start = endEndDate;
        nextStripLine.end = endEndDate;
        let refNextStripeLine = this.xAxisStripLinesHR.find(x => x.id == nextStripLine.id);
        if (refNextStripeLine) {
          refNextStripeLine.start = endEndDate;
        }
      }
      this.primaryXAxisHR = {
        valueType: 'DateTime',
        stripLines: this.xAxisStripLinesHR,
        labelFormat: 'HH:mm',
        crosshairTooltip: { enable: false },
      };
      this.heartRateChart.dataBind();
    }
  };
  public mouseUp(args: IMouseEventArgs): void {
    this.isMouseDown = false;
  };
}

export class DataSample {
  time: string
  date: Date
  heartRate: number
  speed: number
  pace: number
  low: number = 0
  moment: Date

  constructor(data: any) {
    this.time = data.time
    this.date = data.date
    this.heartRate = data.heartRate
    this.speed = data.speed
    this.pace = data.pace
    this.low = data.low
    this.moment = data.moment
  }

  clone() {
    return new DataSample({
      time: this.time,
      date: this.date,
      heartRate: this.heartRate,
      speed: this.speed,
      pace: this.pace,
      low: this.low,
      moment: this.moment
    })
  }
}


class SpikeCardioData {
  timeStart: Date
  timeEnd: Date

  dataSamples: DataSample[]

  minHR: number
  averageHR: number
  maxHR: number

  minPace: number
  maxPace: number
  averagePace: number
  averagePaceInMinutes: string
  minPaceInMinutes: string

  source: string

  constructor(data: any) {
    this.timeStart = data.timeStart
    this.timeEnd = data.timeEnd
    this.minHR = data.minHR
    this.averageHR = data.averageHR
    this.maxHR = data.maxHR
    this.dataSamples = data.dataSamples
    this.source = data.source
  }
}
