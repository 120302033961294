import { MatDialog } from '@angular/material/dialog';
import { LanguageService } from './../../services/language.service';
import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { DailyCondition } from 'src/app/model/dailycondition.model';
import { MetricData } from 'src/app/model/metricdata.model';
import { AssignedQuestionaire, QuestionaireResult } from 'src/app/model/questionaires.model';
import { User } from 'src/app/model/user.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { QuestionairesService } from 'src/app/services/questionaires.service';
import { TranslateService } from '@ngx-translate/core';
import { MdEditorLegacyComponent } from 'src/app/md-editor-legacy/md-editor-legacy.component';

@Component({
  selector: 'app-completed-questionaire-view',
  templateUrl: './completed-questionaire-view.component.html',
  styleUrls: ['./completed-questionaire-view.component.css']
})
export class CompletedQuestionaireViewComponent implements OnDestroy {

  public _user: User;
  @Input() set user(value: User) {
    this._user = value;

    if(this.editabledQuestionaireResult){
      this.setDailyCondition();
    }
  }
  @Input() set questionaireResult(value: QuestionaireResult) {
    this.originalQuestionaireResult = value;
    this.editabledQuestionaireResult = value?.clone();

    if(this._user){
      this.setDailyCondition();
    }
  }
  @Input() isClosable: boolean = false;
  @Input() openMetricDataImage: (metricData: MetricData) => void;

  @Output() onCloseQuestionaireResult: EventEmitter<QuestionaireResult> = new EventEmitter<QuestionaireResult>();
  @Output() onQuestionaireResultChangesSaved: EventEmitter<QuestionaireResult> = new EventEmitter<QuestionaireResult>();

  public originalQuestionaireResult: QuestionaireResult;
  public editabledQuestionaireResult: QuestionaireResult;
  private initialFeedbackNote: string = null;

  // public editMode: boolean = false;

  @Input() editMode: boolean = true;

  public hasFeedbackChanges: boolean = false;

  constructor(private toastr: ToastrService, private questionaireService: QuestionairesService, public userService: FirestoreService, private languageService: LanguageService, private spinner: NgxSpinnerService, private dialog: MatDialog, private translate: TranslateService) { }

  
  ngOnDestroy(): void {
    if(this.originalQuestionaireResult.assignedQuestionaire) {
      this.originalQuestionaireResult.assignedQuestionaire.feedback = this.initialFeedbackNote;
    }
  }


  hasChanges() {
    return !this.compareMetricDataOfQuestionaireResults(this.originalQuestionaireResult, this.editabledQuestionaireResult);
  }

  onRemoveMetricData(metricData: MetricData, questionaireResult: QuestionaireResult) {
    questionaireResult.metricData = questionaireResult.metricData.filter(x => x != metricData);
  }

  onFeedbackChanged(result: QuestionaireResult, value: string) {
    result.assignedQuestionaire.feedback = value
    this.hasFeedbackChanges = true
  }

  async sendFeedback(assignedQuestionaire: AssignedQuestionaire) {
    if(await this.questionaireService.sendFeedback(assignedQuestionaire, this._user)){
      this.hasFeedbackChanges = false
      this.initialFeedbackNote = assignedQuestionaire.feedback;
    }
  }

  async switchEditMode() {
    if (this.editMode) {
      if (!await this.askSaveChanges()) return;
    }
    this.editMode = !this.editMode;
    if (!this.editMode) {
      this.editabledQuestionaireResult = this.originalQuestionaireResult?.clone();
    }
  }


  public dailyCondition: DailyCondition;
  async setDailyCondition() {
    if(this.dailyCondition != null) return;
    if(this._user?.dailyCondition?.questionaireResults?.find(y => y.assignedQuestionaire.id == this.editabledQuestionaireResult.assignedQuestionaire.id)){
      this.dailyCondition = this._user.dailyCondition;
      return;
    }
    this.dailyCondition = this._user.dailyConditions.find(x => x.questionaireResults.find(y => y.assignedQuestionaire.id == this.editabledQuestionaireResult.assignedQuestionaire.id) != null);
    if (this.dailyCondition == null) {
      this.dailyCondition = await firstValueFrom(this.userService.getDailyConditionByDate(this._user, this.editabledQuestionaireResult.assignedQuestionaire.assignmentDate));
    }
  }

  async onSaveChanges(): Promise<boolean> {
    let invalidMetricData = this.editabledQuestionaireResult.metricData?.filter(x => x.isValueValid(true) == false);
    if (invalidMetricData?.length > 0) {
      this.toastr.error(invalidMetricData.map(x => x.metric?.nameTranslation?.GetValue(this.languageService.selectedLanguageCode)).join(', '), "Bitte überprüfe deine Eingaben", {
        positionClass: 'toast-bottom-center'
      });
      return false;
    }
    this.spinner.show();
    try {
      if(this.dailyCondition == null) await this.setDailyCondition();
      let dailyCondition = this.dailyCondition;
      if (dailyCondition == null) {
        dailyCondition = new DailyCondition();
        dailyCondition.date = this.editabledQuestionaireResult.assignedQuestionaire.assignmentDate;
        dailyCondition.date.setHours(0, 0, 0, 0);
      }
      if (dailyCondition.questionaireResults.find(x => x.assignedQuestionaire.id == this.editabledQuestionaireResult.assignedQuestionaire.id) == null) {
        dailyCondition.questionaireResults.push(this.editabledQuestionaireResult);
      }
      else {
        dailyCondition.questionaireResults = dailyCondition.questionaireResults.map(x => x.assignedQuestionaire.id == this.editabledQuestionaireResult.assignedQuestionaire.id ? this.editabledQuestionaireResult : x);
      }
      await this.userService.updateQuestionaireResults(dailyCondition, this._user);
      this.onQuestionaireResultChangesSaved.emit(this.editabledQuestionaireResult);
      this.toastr.success(this.translate.instant("Änderungen gespeichert"), "", {
        positionClass: 'toast-bottom-center'
      });
      return true;
    }
    catch (ex) {
      console.error(ex);
      this.toastr.error(this.translate.instant("Fehler beim Speichern"), "", {
        positionClass: 'toast-bottom-center'
      });
      return false;
    }
    finally {
      this.spinner.hide()
    }
  }

  compareMetricDataOfQuestionaireResults(a: QuestionaireResult, b: QuestionaireResult) {
    if (a.metricData.length != b.metricData.length) return false;
    for (let i = 0; i < a.metricData.length; i++) {
      if (a.metricData[i].metricId != b.metricData[i].metricId) return false;
      if (a.metricData[i].value != b.metricData[i].value) return false;
    }
    return true;
  }

  async askSaveChanges(): Promise<boolean> {
    if (!this.hasChanges()) return true;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: this.translate.instant('Möchtest du die Änderungen speichern?'), title: this.translate.instant('Check-In speichern'), positiveButton: this.translate.instant('Ja'), negativeButton: this.translate.instant('Nein') },
    });
    let result = await firstValueFrom(dialogRef.afterClosed());
    if (result == true) {
      return await this.onSaveChanges();
    }
    return true;
  }

  async onClose() {
    if (await this.askSaveChanges()) {
      this.onCloseQuestionaireResult.emit(this.originalQuestionaireResult);
    }
  }

  
  handleEmojiSelection(event) {
    if(this.editabledQuestionaireResult.assignedQuestionaire){
      if(!this.editabledQuestionaireResult.assignedQuestionaire.feedback) {
        this.editabledQuestionaireResult.assignedQuestionaire.feedback = "";
      }
      if(this.cursorPosition != null){
        this.editabledQuestionaireResult.assignedQuestionaire.feedback = this.editabledQuestionaireResult.assignedQuestionaire.feedback.slice(0, this.cursorPosition) + event.emoji.native + this.editabledQuestionaireResult.assignedQuestionaire.feedback.slice(this.cursorPosition);
        this.cursorPosition += event?.emoji?.native?.length ?? 0;
      } else {
        this.editabledQuestionaireResult.assignedQuestionaire.feedback += event.emoji.native;
      }

      this.hasFeedbackChanges = true
    }
  }

  @ViewChild("mdEditor") mdEditor: MdEditorLegacyComponent;
  private cursorPosition: number = null;

  onEmojiPickerButtonClick(event) {
    if(this.mdEditor.textArea?.nativeElement){
      this.cursorPosition = this.mdEditor.textArea.nativeElement.selectionStart ?? null;
    }
  }
}
