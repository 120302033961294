import { Component } from '@angular/core';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-knowledge-screen',
  templateUrl: './knowledge-screen.component.html',
  styleUrls: ['./knowledge-screen.component.css']
})
export class KnowledgeScreenComponent {

  public environment = environment

  constructor(public utilityService: UtilityService) { }
}
