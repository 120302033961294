<div class="layout-box timer-box">
    <div class="row justify-content-center">
        <div class="col-auto input-container">
            <div class="row" *ngIf="title">
                <div class="col-12 title-col">
                    <strong class="timer-title">{{ title }}</strong>
                </div>
            </div>
            <div class="row">
                <div>
                    <input [disabled]="mode == TimerMode.CountUp && isTimerRunning()" [value]="hours" (input)="hours = $event.target.value" type="number" min="0" max="23" class="input timer-input" placeholder="hh">
                </div>
                <div class="timer-splitter">:</div>
                <div>
                    <input [disabled]="mode == TimerMode.CountUp && isTimerRunning()" [value]="minutes" (input)="minutes = $event.target.value" type="number" min="0" max="59" class="input timer-input" placeholder="mm">
                </div>
                <div class="timer-splitter">:</div>
                <div>
                    <input [disabled]="mode == TimerMode.CountUp && isTimerRunning()" [value]="seconds" (input)="seconds = $event.target.value" type="number" min="0" max="59" class="input timer-input" placeholder="ss">
                </div>
            </div>
        </div>
        <div class="col-auto button-container">
            <div class="row">
                <div class="col">
                    <button class="button-filled timer-button" (click)="toggleTimer()">
                        <svg *ngIf="isTimerRunning()" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="currentColor" class="bi bi-pause-fill" viewBox="0 0 16 16">
                            <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z"/>
                        </svg>
                        <svg *ngIf="!isTimerRunning()" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                            <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                        </svg>
                    </button>
                </div>
                <div class="col">
                    <button class="button-filled timer-button" (click)="resetTimer()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                            <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
                        </svg>
                    </button>
                </div>
                <div class="col">
                    <button class="button-filled timer-button" (click)="finishTimer()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
                          </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>