import {AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PaymentAnalyticsService} from "../../../services/payment-analytics.service";
import {LegendClickEvent, PointClickEvent} from "devextreme/viz/pie_chart";
import {
  AnalyticsConfigMenuItem
} from "../analytics-config-menu/analytics-config-menu-item";
import {DevExtremeModule, DxPieChartComponent} from "devextreme-angular";
import {ChartExportService} from "../../../services/chart-export.service";

export enum TotalRevenueType {
  BY_CUSTOMER,
  BY_PRODUCT
}

type TotalRevenueByCustomerStatistic = {
  customer: string,
  customerRevenue: number,
  customerUid: string
}

type TotalRevenueByProductStatistic = {
  product: string,
  productRevenue: number,
  productId: string
}

@Component({
  selector: 'app-graph-top-ten-revenue',
  templateUrl: './graph-top-ten-revenue.component.html',
  styleUrls: ['./graph-top-ten-revenue.component.css']
})
export class GraphTopTenRevenueComponent extends AnalyticsConfigMenuItem implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('piechartcontainer') piechartcontainer: ElementRef;
  @ViewChild('customerPieChart') customerPieChart: DxPieChartComponent;
  @ViewChild('productPieChart') productPieChart: DxPieChartComponent;

  protected readonly TotalRevenueType = TotalRevenueType;
  public selectedType: TotalRevenueType = null;
  public totalRevenueByCustomerStatistics: TotalRevenueByCustomerStatistic[] = [];
  public totalRevenueByProductsStatistics: TotalRevenueByProductStatistic[] = [];
  public top10RevenueByCustomerStatistics: TotalRevenueByCustomerStatistic[] = [];
  public top10RevenueByProductsStatistics: TotalRevenueByProductStatistic[] = [];
  public showDataTable: boolean = false;

  private formerWindowY: number = 0;
  private pleaseScroll: boolean = false;

  customizeCustomerLabel = (arg) => {
    return `${this.paymentAnalyticsService.transformNumberToMoney(parseInt(arg.valueText))} (${arg.percentText})`;
  };

  customizeProductLabel = (arg) => {
    return `${this.paymentAnalyticsService.transformNumberToMoney(parseInt(arg.valueText))} (${arg.percentText})`;
  };

  constructor(protected paymentAnalyticsService: PaymentAnalyticsService, protected chartExportService: ChartExportService) {
    super();
  }

  ngOnInit(): void {
    this.init();
  }

  ngAfterViewInit() {
    this.piechartcontainer.nativeElement.minHeight = this.piechartcontainer.nativeElement.clientHeight;
  }

  ngAfterViewChecked() {
    if (this.pleaseScroll) {
      window.scrollTo(0, this.formerWindowY);
      this.pleaseScroll = false;
    }
    this.initFilters();
  }

  private init() {
    this.selectedType = this.statisticConfig.top10ToggleState ?? TotalRevenueType.BY_CUSTOMER
    const paymentsByCustomer = this.paymentAnalyticsService.getPaymentsByCustomer();

    const byCustomer: TotalRevenueByCustomerStatistic[] = []
    paymentsByCustomer.forEach((value, key) => {
      const statistic: TotalRevenueByCustomerStatistic = {
        customerUid: key,
        customer: value.customer.getName(),
        customerRevenue: value.payments.reduce((previousValue, currentValue) => previousValue + currentValue.amount, 0) / 100
      };

      byCustomer.push(statistic);
    });
    byCustomer.sort((a, b) => b.customerRevenue - a.customerRevenue);
    this.totalRevenueByCustomerStatistics = byCustomer;
    this.top10RevenueByCustomerStatistics = byCustomer.slice(0,10);

    const paymentsByProduct = this.paymentAnalyticsService.getPaymentsByProducts();
    const byProduct: TotalRevenueByProductStatistic[] = []
    paymentsByProduct.forEach((value, key) => {
      const statistic: TotalRevenueByProductStatistic = {
        productId: key,
        product: value.product.name,
        productRevenue: value.payments.reduce((previousValue, currentValue) => previousValue + currentValue.amount, 0) / 100
      };

      byProduct.push(statistic);
    });
    byProduct.sort((a, b) => b.productRevenue - a.productRevenue);
    this.totalRevenueByProductsStatistics = byProduct;
    this.top10RevenueByProductsStatistics = byProduct.slice(0,10);
  }

  initFilters() {
    if (this.selectedType === TotalRevenueType.BY_CUSTOMER) {
      if (!this.statisticConfig.hiddenCustomers || this.statisticConfig.hiddenCustomers && !this.statisticConfig.hiddenCustomers.length) return;
      if (!this.customerPieChart) return;
      const customerPoints = this.customerPieChart.instance.getAllSeries()[0].getAllPoints();
      this.statisticConfig.hiddenCustomers.forEach(customerUid => {
        const point = customerPoints.find(point => point.data.customerUid === customerUid);
        if(point && (point as any).isVisible()) {
          (point as any).hide();
        }
      });
    }

    if (this.selectedType === TotalRevenueType.BY_PRODUCT) {
      if (!this.statisticConfig.hiddenProducts || this.statisticConfig.hiddenProducts && !this.statisticConfig.hiddenCustomers.length) return;
      if (!this.productPieChart) return;
      const productPoints = this.productPieChart.instance.getAllSeries()[0].getAllPoints();
      this.statisticConfig.hiddenProducts.forEach(productId => {
        const point = productPoints.find(point => point.data.productId === productId);
        if (point && (point as any).isVisible()) {
          (point as any).hide();
        }
      });
    }
  }

  onShowTable() {
    this.showDataTable = !this.showDataTable;
  }

  handleToggle(type: TotalRevenueType) {
    this.selectedType = this.statisticConfig.top10ToggleState = type;
    this.onConfigSettingChanged();
    this.formerWindowY = window.scrollY;
    this.pleaseScroll = true;
  }

  pointClickHandler(e: PointClickEvent) {
    this.toggleVisibility(e.target);
  }

  legendClickHandler(e: LegendClickEvent) {
    const arg = e.target;
    const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];

    this.toggleVisibility(item);
  }

  toggleVisibility(item) {
    if (item.isVisible()) {
      item.hide();
      this.addToFilter(item);
    } else {
      item.show();
      this.removeFromFilter(item);
    }
  }

  addToFilter(item) {
    if (this.selectedType === TotalRevenueType.BY_CUSTOMER) {
      const statistic = item.data as TotalRevenueByCustomerStatistic;
      if (!this.statisticConfig.hiddenCustomers) this.statisticConfig.hiddenCustomers = [];
      if (this.statisticConfig.hiddenCustomers.indexOf(statistic.customerUid) === -1) {
        this.statisticConfig.hiddenCustomers.push(statistic.customerUid);
        this.onConfigSettingChanged();
      } else {
        console.error(`The User: ${statistic.customer} is already hidden!`);
      }
    }
    if (this.selectedType === TotalRevenueType.BY_PRODUCT) {

      const statistic = item.data as TotalRevenueByProductStatistic;
      if (!this.statisticConfig.hiddenProducts) this.statisticConfig.hiddenProducts = [];
      if (this.statisticConfig.hiddenProducts.indexOf(statistic.productId) === -1) {
        this.statisticConfig.hiddenProducts.push(statistic.productId);
        this.onConfigSettingChanged();
      } else {
        console.error(`The Product: ${statistic.product} is already hidden!`);
      }
    }
  }

  removeFromFilter(item) {
    if (this.selectedType === TotalRevenueType.BY_CUSTOMER) {
      const statistic = item.data as TotalRevenueByCustomerStatistic;
      if (this.statisticConfig.hiddenCustomers.indexOf(statistic.customerUid) !== -1) {
        this.statisticConfig.hiddenCustomers.splice(this.statisticConfig.hiddenCustomers.indexOf(statistic.customerUid), 1);
        this.onConfigSettingChanged();
      } else {
        console.error(`The User: ${statistic.customer} is already visible!`);
      }
    }

    if (this.selectedType === TotalRevenueType.BY_PRODUCT) {
      const statistic = item.data as TotalRevenueByProductStatistic;
      if (this.statisticConfig.hiddenProducts.indexOf(statistic.productId) !== -1) {
        this.statisticConfig.hiddenProducts.splice(this.statisticConfig.hiddenCustomers.indexOf(statistic.productId), 1);
        this.onConfigSettingChanged();
      } else {
        console.error(`The Product: ${statistic.product} is already visible!`);
      }
    }
  }
}
