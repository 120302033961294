import { Component, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { PaymentSettings } from 'src/app/model/payment-settings.model';
import { ProductPurchase } from 'src/app/model/product-purchase.model';
import { Product } from 'src/app/model/product.model';
import { User } from 'src/app/model/user.model';
import { ChatService } from 'src/app/services/chat.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { environment } from 'src/environments/environment';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from 'src/app/services/payment.service';
import { InvoiceHistoryDialogComponent } from '../invoice-history-dialog/invoice-history-dialog.component';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent {

  get user(): User {
    return this.userService.getLoggedInUser()
  }

  public environment = environment

  public userSelected = false
  private userEmail: string = null

  productPurchaseId: string 
  purchase: ProductPurchase
  stripeCheckoutSessionLink: string
  product: Product
  brandingImageUrl: string
  paymentSettings: PaymentSettings

  observableSubscription: any
  loadingCheckoutLink = false
  showDescription = false

  constructor(private router: Router, public paymentService: PaymentService, public userService: FirestoreService, private authService: AuthService, private chatService: ChatService, private route: ActivatedRoute, private ngZone: NgZone, public dialog: MatDialog, private spinner: NgxSpinnerService, private toastr: ToastrService) {
    authService.getAuthState().subscribe(user => {
      if (user) {
        this.stripeCheckoutSessionLink = null
        if (this.purchase?.status == 'uncompleted') this.loadCheckoutLink()
        this.userEmail = authService.user.email
      } else {
        this.userSelected = false
      }
    });
  }

  ngOnInit(): void {
    /*this.route.paramMap.subscribe(async (param) => {
      var id = param.get('id')
      if (id?.includes('?')) {
        this.productPurchaseId = id.split('?')[0]
      }
      console.log(this.productPurchaseId)
      this.loadProductPurchase()
    })
    console.log(this.route.queryParams)
    this.route.queryParams.subscribe(async (param) => {
      if (param?.token) {
        var token = param.token
        if (token) {
          console.log('Token')
          this.authService.loginWithToken(token)
        }
        console.log(token)
      }
    })*/
    combineLatest(this.route.params, this.route.queryParams, (params, queryParams) => ({ params, queryParams })).subscribe( ap => {
      var param = ap.params
      var id = param?.id
      if (id?.includes('?')) {
        this.productPurchaseId = id.split('?')[0]
      } else {
        this.productPurchaseId = id
      }
      this.loadProductPurchase()
      var queryParams = ap.queryParams
      if (queryParams?.token) {
        var token = queryParams.token
        if (token) {
          this.authService.loginWithToken(token)
        }
      }
    });
  }

  getInAppLink() {
    return environment.inAppLink + '?action=login&email=' + this.userEmail + '&purchaseId=' + this.purchase.id
  }

  loadProductPurchase() {
    if (this.productPurchaseId) {
      if (this.observableSubscription != null) this.observableSubscription.unsubscribe()
      this.observableSubscription = this.userService.getProductPurchase(this.productPurchaseId).subscribe(async productSubscription => {
        this.purchase = productSubscription
        if (this.purchase.customerUid && this.user && this.purchase.customerUid == this.user.uid) {
          this.userSelected = true
        }
        this.product = await this.userService.getProduct(this.purchase.productId)
        if (this.product.thumbnailPath) {
          this.userService.getDownloadUrl(this.product.getFullThumbnailPath(this.purchase.licenceHolderUid)).then(url => this.product.thumbnailUrl = url)
        }
        if (!this.stripeCheckoutSessionLink && this.purchase?.status == 'uncompleted') this.loadCheckoutLink()
        this.loadBrandingSettings()
        this.loadPaymentSettings()
      })
    }
  }

  isProductPurchaseAccessible() {
    if (!this.purchase) return false
    if (this.purchase.customerUid != null && this.user && this.purchase.customerUid != this.user.uid) {
      return false
    }
    if (this.purchase.customerUid != null && !this.user) return false
    return true
  }
  
  error: string = null

  loadCheckoutLink() {
    this.error = null
    if (!this.userSelected) return
    if (!this.purchase) return
    if (this.purchase.price == 0) return
    if (this.user) {
      if (!this.isProductPurchaseAccessible()) return

      this.loadingCheckoutLink = true
      firstValueFrom(this.userService.createStripeConnectCheckoutSession(this.productPurchaseId, null, this.user.uid, null)).then(result => {
        this.ngZone.run( () => {
          if (result.redirectUrl) {
            this.stripeCheckoutSessionLink = result.redirectUrl
          } else if (result.error) {
            if (result.error == 'Checkout temporarily unavailable') {
              this.error = 'Der Checkout ist vorübergehend nicht verfügbar. Bitte versuche es später erneut.'
            } else {
              this.error = result.error
            }
          }
          this.loadingCheckoutLink = false
        });
      }, err => {
        console.log(err)
        this.loadingCheckoutLink = false
      })
    }
  }

  async loadBrandingSettings() {
    var snapshot = await firstValueFrom(this.userService.getBrandingSettings(this.purchase.licenceHolderUid))
    if (snapshot.exists) {
      var logoFileName = snapshot?.data()?.logoFileName
      if (logoFileName) {
        this.brandingImageUrl = await firstValueFrom(this.userService.getBrandingImage(this.purchase.licenceHolderUid, logoFileName))
      }
    }
  }
  async loadPaymentSettings() {
    this.paymentSettings = await this.userService.getPaymentSettings(this.purchase.licenceHolderUid)
    if (this.paymentSettings?.termsAndConditionsPath) {
      this.paymentSettings.termsAndConditionsUrl = await this.userService.getDownloadUrl("licence_holders/" + this.purchase.licenceHolderUid + "/settings/payment/" + this.paymentSettings.termsAndConditionsPath)
    }
  }

  onAcceptTerms(value: boolean) {
    this.purchase.termsAccepted = value
  }

  onPurchaseProduct() {
    if (!this.purchase.termsAccepted) return
    if (this.purchase.price > 0) {
      document.location.href = this.stripeCheckoutSessionLink
    } else if (this.purchase.price == 0) {
      this.spinner.show()
      firstValueFrom(this.userService.completeProductPurchase(this.productPurchaseId, null, this.user.uid, null)).then(result => {
        this.ngZone.run( () => {
          this.spinner.hide()
          if (result.redirectUrl) {
            document.location.href = result.redirectUrl
          } else if (result.error) {
            if (result.error == 'Checkout temporarily unavailable') {
              this.error = 'Der Checkout ist vorübergehend nicht verfügbar. Bitte versuche es später erneut.'
            } else {
              this.error = result.error
            }
          }
        });
      }, err => {
        this.spinner.hide()
        console.log(err)
      })
    }
  }

  onStartPurchase() {
    const dialogRef = this.dialog.open(LoginDialogComponent, { data: { presetUid: null, redirectPath: 'checkout/' + this.productPurchaseId }, width: '500px'})
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.success) {
          this.userSelected = true
          this.loadCheckoutLink()
        }
      }
    });
  }
  onLogin() {
    const dialogRef = this.dialog.open(LoginDialogComponent, { data: { presetUid: this.purchase?.customerUid, redirectPath: 'checkout/' + this.productPurchaseId }, width: '500px'})
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.success) {
          this.userSelected = true
          this.loadCheckoutLink()
        }
      }
    });
  }

  async onCancelPurchase() {
    if (!this.purchase.canBeCanceled()) return
    if (this.purchase.autoRenew) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { message: 'Möchtest du das Abonnement zum ' + this.purchase.getNextCancelationDate()?.asFormatedString() + ' wirklich kündigen?', title: 'Abonnement kündigen' },
      });
      dialogRef.afterClosed().subscribe(async result => {
        if (result) {
          this.spinner.show()
          this.userService.cancelProductPurchase(this.purchase, false).then(res => {
            this.spinner.hide()
            if (res.success) {
              this.toastr.success("Kündigung erfolgreich.", "",  {
                positionClass: 'toast-bottom-center'
              });
            }
          })
        }
      });
    }
  }

  getPaymentMethods() {
    var availablePaymentMethods = this.purchase?.availablePaymentMethods ?? this.paymentSettings?.availablePaymentMethods
    return this.paymentService.allPaymentMethods.filter(method => availablePaymentMethods?.includes(method.value))
  }

  public enablePaymentMethodSelection: boolean = false
  selectedPaymentMethod: string

  onSelectPaymentMethod(paymentMethod: string) {
    this.selectedPaymentMethod = paymentMethod
  }
  onUpdatePaymentMethod() {
    this.enablePaymentMethodSelection = true
  }

  loading = false
  async onConfirmPaymentMethod() {
    if (this.selectedPaymentMethod == 'bank_transfer') {
      await this.userService.updateProductPurchasePaymentMethod(this.purchase, this.selectedPaymentMethod)
      this.toastr.success("Zahlungsmethode erfolgreich aktualisiert.", "",  {
        positionClass: 'toast-bottom-center'
      });
    } else {
      this.loading = true
      firstValueFrom(this.userService.createStripeConnectBillingPortalLink(this.productPurchaseId, this.user.uid, this.selectedPaymentMethod)).then(result => {
        this.ngZone.run( () => {
          this.loading = false
          if (result.redirectUrl) {
            document.location.href = result.redirectUrl
          } else if (result.error) {
            if (result.error == 'Billing portal temporarily unavailable') {
              this.error = 'Das Rechnungsportal ist vorübergehend nicht verfügbar. Bitte versuche es später erneut.'
            } else {
              this.error = result.error
            }
          }
          this.loadingCheckoutLink = false
        });
      }, err => {
        console.log(err)
        this.loadingCheckoutLink = false
      })
    }
    this.enablePaymentMethodSelection = false
  }

  onViewInvoices() {
    this.dialog.open(InvoiceHistoryDialogComponent, { data: {  }, width: '1000px'})
  }

  onToggleDescription() {
    this.showDescription = !this.showDescription
  }

  onLogout() {
    this.stripeCheckoutSessionLink = null
    this.userService.logout();
    this.authService.logoutToPage('checkout/' + this.purchase.id);
    this.chatService.logout();
    this.userEmail = null
  }
}
