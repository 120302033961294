import { environment } from "src/environments/environment"
import { Licence } from "./lid.model"
import { PortalSettingsCoach } from "./portalsettings-coach.model"
import { CoachSubscription } from "./subscription.model"
import { User } from "./user.model"

export class Coach {
    uid: string
    licenceHolderUid: string
    name: string
    communicationEmailAddress: string
    disabled: boolean
    recipeSharingEnabled: boolean
    trainingEnabled: boolean
    nutritionEnabled: boolean
    coacheeVoiceMessageEnabled: boolean
    recipeSuggestionsEnabled: boolean // Default value for all users without override
    trainingPlanTemplateSharingEnabled: boolean
    coacheeTrainingPlanCreationEnabled: boolean // Default value for all users without override
    hideChatMessageSender: boolean
    recipeQRCodeSharingEnabled: boolean
    trainerCode: string
    unitSystem: string

    emailNotificationSettings: any
    pushNotificationSettings: any

    hasLicenceHolderRights: boolean
    canAccessAllUsers: boolean
    accessibleGroupNames: string[]
    accessibleUserUids: string[]
    accessibleCoachUids: string[]
    _canAssignCoachesAndLicences: boolean
    canAccessPayment: boolean
    canAccessProducts: boolean

    portalSettingsCoach: PortalSettingsCoach
    hasRecipeDatabaseAccess: boolean

    fcmTokens: string[];

    subscription: CoachSubscription

    isLicenceHolder() {
        return this.uid == this.licenceHolderUid
    }

    canAccessUser(user: User) {
        if (this.canAccessAllUsers) return true
        if (this.accessibleUserUids.includes(user.uid)) return true
        if (this.uid == user.licence?.coachUid) return true
        if (this.accessibleCoachUids.includes(user.coachUid)) return true
        var canAccess = false
        user.metadataUser?.assignedClientGroups.forEach(group => {
            if (this.accessibleGroupNames.includes(group)) canAccess = true
        })
        return canAccess
    }

    canAccessClientGroup(group: string) {
        return this.accessibleGroupNames.includes(group) || this.hasLicenceHolderRights || false
    }

    canAccessCoachClients(coach: Coach) {
        return this.uid == coach.uid || this.canAccessAllUsers || this.accessibleCoachUids.includes(coach.uid)
    }

    canAccessLicence(licence: Licence) {
        return this.uid == licence.coachUid || this.canAccessAllUsers || this.accessibleCoachUids.includes(licence.coachUid)
    }

    canAssignCoachesAndLicences() {
        return this._canAssignCoachesAndLicences || this.isLicenceHolder() || this.hasLicenceHolderRights
    }

    isEmailValid() {
        return this.communicationEmailAddress && this.communicationEmailAddress?.length > 0 && this.communicationEmailAddress.includes('@') && this.communicationEmailAddress.includes('.')
    }

    getAppLinkWithTrainerCode() {
        if (!this.trainerCode) return null
        return environment.inAppLink + '?action=redeem&trainerCode=' + this.trainerCode
    }

    constructor();
    constructor(init: Coach);
    constructor(init?: Coach) {
        this.uid = init && init.uid || null
        this.licenceHolderUid = init && init.licenceHolderUid || null
        this.name = init && init.name || null
        this.communicationEmailAddress = init && init.communicationEmailAddress || null
        this.disabled = init && init.disabled || false
        this.recipeSharingEnabled = init && init.recipeSharingEnabled || false
        this.recipeSuggestionsEnabled = init && init.recipeSuggestionsEnabled || false
        this.trainingEnabled = init && init.trainingEnabled || false
        this.nutritionEnabled = init && init.nutritionEnabled || (environment.firebaseProjectId == 'traindoo-app' ? false : true)
        this.coacheeVoiceMessageEnabled = init && init.coacheeVoiceMessageEnabled != null ? init.coacheeVoiceMessageEnabled : true
        this.hideChatMessageSender = init && init.hideChatMessageSender || false
        this.hasLicenceHolderRights = init && init.hasLicenceHolderRights || (this.uid == this.licenceHolderUid)
        this.canAccessAllUsers = init && init.canAccessAllUsers || (this.hasLicenceHolderRights || false)
        this.accessibleGroupNames = init && init.accessibleGroupNames || []
        this.accessibleUserUids = init && init.accessibleUserUids || []
        this.accessibleCoachUids = init && init.accessibleCoachUids || []
        this._canAssignCoachesAndLicences = init && (init as any).canAssignCoachesAndLicences || false
        this.canAccessPayment = init && init.canAccessPayment != null ? init.canAccessPayment : true
        this.canAccessProducts = init && init.canAccessProducts != null ? init.canAccessProducts : true
        this.trainerCode = init && init.trainerCode || null
        this.unitSystem = init && init.unitSystem || null
        this.emailNotificationSettings = init && init.emailNotificationSettings || null
        this.pushNotificationSettings = init && init.pushNotificationSettings || null

        this.subscription = init && init.subscription || null
        this.hasRecipeDatabaseAccess = init && init.hasRecipeDatabaseAccess || false

        this.fcmTokens = init && init.fcmTokens || [];

        this.trainingPlanTemplateSharingEnabled = init && init.trainingPlanTemplateSharingEnabled || false
        this.recipeQRCodeSharingEnabled = init && init.recipeQRCodeSharingEnabled || false
    }
}