<div class="page-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12" *ngIf="environment.firebaseProjectId == 'traindoo-app' && utilityService.onMobile()">
                <app-library-mobile-navigation></app-library-mobile-navigation>
            </div>
            <div class="col-9 col-lg-9 col-xl-8 col-xxl-4 order-1 order-xxl-1 profile">
                <h2 class="" translate>Training</h2>
            </div>
            <div class="col-12 col-xl-12 col-xxl-4 order-3 order-xxl-2">
                <div class="div textalign-center">
                    <div class="spacer-10"></div>
                    <button class="tab-button {{showTrainingExercises ? 'active' : ''}}" (click)="swapTab('showTrainingExercises')">
                        <span translate>Übungen</span>
                        <div class="underline"></div>
                    </button>
                    <button class="tab-button {{showTrainingPlanTemplates ? 'active' : ''}}" (click)="swapTab('showTrainingPlanTemplates')">
                        <span translate>Trainingsplan-Vorlagen</span>
                        <div class="underline"></div>
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="showTrainingExercises">
            <app-training-database></app-training-database>
        </div>
        <div *ngIf="showTrainingPlanTemplates">
            <app-training-templates></app-training-templates>
        </div>
    </div>
</div>