import { SafeResourceUrl } from "@angular/platform-browser";
import { FirestoreNutritionPlanService } from "../services/firestore-nutritionplan.service";
import { BaseTrainingSet, PlannedTrainingExercise, SetParameter, TrainingSession, TrainingSet, Week } from "./training-plan.model";

export class TrackedTrainingSet extends BaseTrainingSet {
  public setNumber: number;
  public exertionValue: number;

  public time: number;
  public coacheeNote: string;

  // temp
  public isTracked: boolean = false;

  constructor(init?: Partial<TrackedTrainingSet>) {
    super();
    if (init) {
      Object.assign(this, init);
      this.time = init.time ?? null;
    }
  }

  getWeight() {
    return this.weight ?? 0
  }

  getTotalWeight() {
    return this.weight * this.reps
  }
  getRepCount() {
    return this.reps ?? 0
  }
  getE1RM() {
    var reps = this.getRepCount()
    if (this.rir != null) {
      reps = reps + this.rir
    } else if (this.rpe != null) {
      reps = reps + (10 - this.rpe)
    }
    if (reps == null || reps == 0) return null
    if (reps == 1) return this.getWeight()?.roundToPlaces(2)
    if (this.getWeight() == 0) return null
    return ((this.getWeight() * reps * 0.0333) + this.getWeight()).roundToPlaces(2)
  }

  getEXRM(x: number) {
    if (x == 1) return this.getE1RM();
    var reps = this.getRepCount()
    if (this.rir != null) {
      reps = reps + this.rir
    } else if (this.rpe != null) {
      reps = reps + (10 - this.rpe)
    }
    return (this.getWeight() * (1 + (reps / 30)) / (1 + (x / 30))).roundToPlaces(2);
  }

  static getAvailableSetParameters(trackedTrainingExercise: TrackedTrainingExercise): SetParameter[] {
    return Object.values(SetParameter).filter(parameter => trackedTrainingExercise?.setParameters.includes(parameter) || this.hasValues(trackedTrainingExercise.trackedSets, parameter))
  }

  static hasValues(sets: TrackedTrainingSet[], setParameter: SetParameter): boolean {
    return sets.filter(x => x[setParameter.toString()] != null).length > 0
  }



  asMap(): Record<string, any> {
    return {
      "setNumber": this.setNumber ?? 0,
      "weight": this.weight ?? null,
      "reps": this.reps ?? null,
      "exertionValue": this.exertionValue ?? null,
      "time": this.time ?? null,
      "pace": this.pace ?? null,
      "distance": this.distance ?? null,
      "timeUnderTension": this.timeUnderTension ?? null,
      "rpe": this.rpe ?? null,
      "rir": this.rir ?? null,
      "calories": this.calories ?? null,
      "caloriesPerHour": this.caloriesPerHour ?? null,
      "rpm": this.rpm ?? null,
      "watts": this.watts ?? null,
      "pauseDuration": this.pauseDuration ?? null,
      "coacheeNote": this.coacheeNote ?? null,
      "heartRate": this.heartRate ?? null,
      "isWarmupSet": this.isWarmupSet ?? false,
      "isDropset": this.isDropset ?? false,
      "pace500": this.pace500 ?? null,
      "speed": this.speed ?? null,
    }
  }

}

export class TrackedVideoRecording {
  public setNumber: number;
  public recordingTime: Date;
  public videoPath: string;
  public thumbnailPath: string;
  public note: string;
  public feedbackNote: string
  public feedbackRead: boolean
  public viewedByCoach: boolean

  // Tmp:
  public tmpId = Math.floor(Math.random() * 1000000000)?.toString()
  public urlsLoaded = false
  public videoUrl: string;
  public thumbnailUrl: string
  public newVideoFile: File;
  public newVideoBlob: Blob;

  constructor(setNumber: number, recordingTime: Date, videoPath: string, thumbnailPath: string, note: string, feedbackNote: string, feedbackRead: boolean, videoUrl: string, thumbnailUrl: string, viewedByCoach: boolean | null) {
    this.setNumber = setNumber;
    this.recordingTime = recordingTime || null;
    this.videoPath = videoPath || null;
    this.thumbnailPath = thumbnailPath || null;
    this.note = note || null;
    this.feedbackNote = feedbackNote || null;
    this.feedbackRead = feedbackRead || null;
    this.videoUrl = videoUrl || null;
    this.thumbnailUrl = thumbnailUrl || null;
    this.viewedByCoach = viewedByCoach != null ? viewedByCoach : null
  }

  asMap(): Record<string, any> {
    return {
      "setNumber": this.setNumber,
      "recordingTime": this.recordingTime,
      "videoPath": this.videoPath,
      "thumbnailPath": this.thumbnailPath,
      "note": this.note,
      "feedbackNote": this.feedbackNote,
      "feedbackRead": this.feedbackRead,
      "viewedByCoach": this.viewedByCoach
    }
  }

  clone(): TrackedVideoRecording {
    let clonedRecording = new TrackedVideoRecording(this.setNumber, this.recordingTime, this.videoPath, this.thumbnailPath, this.note, this.feedbackNote, this.feedbackRead, this.videoUrl, this.thumbnailUrl, this.viewedByCoach)
    clonedRecording.newVideoFile = this.newVideoFile;
    clonedRecording.urlsLoaded = this.urlsLoaded;
    clonedRecording.newVideoBlob = this.newVideoBlob;
    return clonedRecording;
  }
}

export class TrackedTrainingExercise {
  public id: string
  public completed: boolean;
  public note: string;
  public startDate: Date;
  public exerciseId: string;
  public plannedExerciseId: string;
  public trackedSessionId: string
  public groupHeading?: string;
  public trackedSets: TrackedTrainingSet[];
  public recordings: TrackedVideoRecording[];
  public nextSupersetExerciseId: string;
  public deleted: boolean;
  public replacedExerciseId: string;
  public sets: TrainingSet[];
  public position: number;
  public noteImagePaths: string[];
  public setParameters: SetParameter[] = []

  //temp
  public plannedExercise: PlannedTrainingExercise;
  public tempNoteImages: { blob: Blob, src: string, path: string }[];
  public autoRun: boolean;

  constructor();
  constructor(init: TrackedTrainingExercise);
  constructor(init?: TrackedTrainingExercise) {
    this.id = init?.id || null
    this.completed = init?.completed || false;
    this.note = init?.note || null;
    this.startDate = init?.startDate || null;
    this.exerciseId = init?.exerciseId || null;
    this.plannedExerciseId = init?.plannedExerciseId || null;
    this.trackedSessionId = init?.trackedSessionId || null;
    this.groupHeading = init && init?.groupHeading || null
    this.trackedSets = init?.trackedSets.map(x => new TrackedTrainingSet(x)) || [];
    this.recordings = init?.recordings?.map(x => new TrackedVideoRecording(x.setNumber, x.recordingTime, x.videoPath, x.thumbnailPath, x.note, x.feedbackNote, x.feedbackRead, x.videoUrl, x.thumbnailUrl, x.viewedByCoach)) || [];
    this.nextSupersetExerciseId = init?.nextSupersetExerciseId || null;
    this.deleted = init?.deleted || false;
    this.replacedExerciseId = init?.replacedExerciseId || null;
    this.sets = init?.sets?.map(x => new TrainingSet(x)) || [];
    this.position = init?.position || null;
    this.noteImagePaths = init?.noteImagePaths || null
    this.setParameters = init?.setParameters || []

    this.plannedExercise = init?.plannedExercise || null;
    this.autoRun = init?.autoRun || false;
  }

  getTrackedVideoRecordingBySetNumber(setNumber: number): TrackedVideoRecording {
    var result = this.recordings.filter(x => x.setNumber == setNumber)
    if (result.length > 0) return result[0]
  }

  getTotalWeight(exerciseId: string = null) {
    if (exerciseId != null && exerciseId != this.exerciseId) return null
    var value = 0
    for (var set of this.trackedSets) {
      value += set.getTotalWeight()
    }
    return value
  }

  getMaxWeight(exerciseId: string = null) {
    if (exerciseId != null && exerciseId != this.exerciseId) return null
    var value = null
    if (this.trackedSets) {
      for (var set of this.trackedSets) {
        if (set.weight != null) {
          if (value == null || set.weight > value) value = set.weight
        }
      }
    }
    return value
  }

  getE1RM(exerciseId: string = null) {
    if (exerciseId != null && exerciseId != this.exerciseId) return null
    var value = null
    if (this.trackedSets) {
      for (var set of this.trackedSets) {
        var v = set.getE1RM()
        if (v != null && (value == null || v > value)) value = set.getE1RM()
      }
    }
    return value
  }

  getEXRM(x: number) {
    if (x == 1) return this.getE1RM();
    var value = null
    if (this.trackedSets) {
      for (var set of this.trackedSets) {
        var v = set.getE1RM()
        if (v != null && (value == null || v > value)) value = set.getEXRM(x)
      }
    }
    return value
  }

  getAverageExertion(exerciseId: string = null) {
    if (exerciseId != null && exerciseId != this.exerciseId) return null
    var value = 0
    var count = null
    if (this.trackedSets) {
      for (var set of this.trackedSets) {
        if (set.exertionValue != null) {
          value += set.exertionValue
          if (count == null) {
            count = 1
          } else {
            count++
          }
        }
      }
    }
    if (count != null) return value / count
    return null
  }

  getAverageRPE(exerciseId: string = null) {
    if (exerciseId != null && exerciseId != this.exerciseId) return null
    var value = 0
    var count = null
    if (this.trackedSets) {
      for (var set of this.trackedSets) {
        if (set.rpe != null) {
          value += set.rpe
          if (count == null) {
            count = 1
          } else {
            count++
          }
        }
      }
    }
    if (count != null) return value / count
    return null
  }

  getAverageRIR(exerciseId: string = null) {
    if (exerciseId != null && exerciseId != this.exerciseId) return null
    var value = 0
    var count = null
    if (this.trackedSets) {
      for (var set of this.trackedSets) {
        if (set.rir != null) {
          value += set.rir
          if (count == null) {
            count = 1
          } else {
            count++
          }
        }
      }
    }
    if (count != null) return value / count
    return null
  }

  getSetCount(exerciseId: string = null) {
    if (exerciseId != null && exerciseId != this.exerciseId) return null
    return this.trackedSets?.length ?? 0
  }

  getRepCount(exerciseId: string = null) {
    if (exerciseId != null && exerciseId != this.exerciseId) return null
    var value = 0
    if (this.trackedSets) {
      for (var set of this.trackedSets) {
        value += set.getRepCount()
      }
    }
    return value
  }

  hasVideoRecordings() {
    return this.recordings?.length > 0
  }

  setHasVideoRecording(setNumber: number) {
    return this.recordings?.find(x => x.setNumber == setNumber) != null;
  }

  getSetVideoRecording(setNumber: number): TrackedVideoRecording {
    return this.recordings?.find(x => x.setNumber == setNumber);
  }

  hasCoacheeNotes() {
    return this.trackedSets?.filter(x => x.coacheeNote?.length > 0)?.length > 0
  }

  getSetIndexWithoutWarmupSets(set: TrackedTrainingSet): number {
    return this.trackedSets?.filter(x => !x.isWarmupSet)?.indexOf(set);
  }

  getRealSetIndex(set: TrackedTrainingSet): number {
    return this.trackedSets?.filter(x => !x.isWarmupSet && !x.isDropset)?.indexOf(set);
  }

  hasNote() {
    return this.note?.length > 0
  }
  hasNoteImages() {
    return this.noteImagePaths?.length > 0
  }

  getTotalTime() {
    let time = this.trackedSets?.map(x => x.time)?.reduce((a, b) => a + b, 0) ?? 0;
    let pause = this.trackedSets?.map(x => x.pauseDuration)?.reduce((a, b) => a + b, 0) ?? 0;

    return time + pause;
  }

  clone(): TrackedTrainingExercise {
    return new TrackedTrainingExercise(this)
  }

  asMap(): Record<string, any> {
    return {
      "completed": this.completed,
      "note": this.note,
      "startDate": this.startDate,
      "exerciseId": this.exerciseId,
      "plannedExerciseId": this.plannedExerciseId,
      "trackedSessionId": this.trackedSessionId,
      "groupHeading": this.groupHeading,
      "nextSupersetExerciseId": this.nextSupersetExerciseId,
      "deleted": this.deleted,
      "replacedExerciseId": this.replacedExerciseId,
      "position": this.position,
      "noteImagePaths": this.noteImagePaths,
      "sets": this.sets.map(x => x.asMap()),
      "trackedSets": this.trackedSets.map(x => x.asMap()),
      "recordings": this.recordings.map(x => x.asMap()),
      "timestamp": new Date(),
      "setParameters": this.setParameters.map(x => x.toString())
    }
  }

  getSetPlaceholder(setIndex: number, setParameter: SetParameter, unitSystem: string): string {
    if (this.plannedExercise?.sets?.length > setIndex && this.plannedExercise?.sets[setIndex]?.getValueBySetParameter(setParameter)) {
      return this.plannedExercise.sets[setIndex].getValueBySetParameter(setParameter, unitSystem);
    }
    else {
      switch (setParameter) {
        case SetParameter.weight:
          return '';
        case SetParameter.reps:
          return '';
        case SetParameter.time:
          return '';
        case SetParameter.rir:
          return '';
        case SetParameter.rpe:
          return '';
        case SetParameter.speed:
          return '';
        case SetParameter.pace:
          return '';
        case SetParameter.timeUnderTension:
          return '';
        case SetParameter.distance:
          return '';
        case SetParameter.calories:
          return '';
        case SetParameter.caloriesPerHour:
          return '';
        case SetParameter.rpm:
          return '';
        case SetParameter.watts:
          return '';
        case SetParameter.heartRate:
          return '';
        case SetParameter.note:
          return '';
        case SetParameter.pace500:
          return '';
        case SetParameter.pauseDuration:
          return '';
        default:
          return '';
      }
    }
    // return this.setParameters[setIndex]?.[setParameter]?.toString() ?? null
  }
}

export class TrackedTrainingSession {
  public id: string;
  public startDate: Date;
  public endDate: Date;
  public trainingPlanId: string;
  public plannedSessionId: string;
  public sessionName: string;
  public deleted: boolean;
  public viewedByCoach: boolean

  public custom: boolean;

  public trackedTrainingExercises: TrackedTrainingExercise[]
  public continued: boolean;

  //temp
  public plannedWeekIndex: number;
  public plannedWeek: Week;
  public tempUserUid: string;

  constructor();
  constructor(init: TrackedTrainingSession);
  constructor(init?: TrackedTrainingSession) {
    this.id = init?.id || null
    this.startDate = init?.startDate || null;
    this.endDate = init?.endDate || null;
    this.trainingPlanId = init?.trainingPlanId || null;
    this.plannedSessionId = init?.plannedSessionId || null;
    this.sessionName = init?.sessionName || null;
    this.trackedTrainingExercises = init?.trackedTrainingExercises?.map(x => x.clone()) || [];
    this.deleted = init?.deleted || false;
    this.custom = init?.custom || false;
    this.viewedByCoach = init && init.viewedByCoach != null ? init.viewedByCoach : null;
    this.continued = init?.continued || false;
  }

  static createFromPlannedTrainingSession(trainingSession: TrainingSession, trainingPlanId: string): TrackedTrainingSession {
    var trackedTrainingSession = new TrackedTrainingSession()
    trackedTrainingSession.startDate = new Date()
    trackedTrainingSession.trainingPlanId = trainingPlanId
    trackedTrainingSession.plannedSessionId = trainingSession.id
    trackedTrainingSession.sessionName = trainingSession.name
    trackedTrainingSession.trackedTrainingExercises = []
    let superSets = trainingSession.getExercisesBySuperSets();

    superSets.forEach(superSet => {
      let superSetExercises = superSet.exercises?.filter(x => x.exerciseId && !x.deleted);
      for (let i = 0; i < superSetExercises.length; i++) {
        let exercise = superSetExercises[i];
        let trackedExercise = new TrackedTrainingExercise()
        trackedExercise.exerciseId = exercise.exerciseId
        trackedExercise.plannedExerciseId = exercise.id
        trackedExercise.sets = [];
        trackedExercise.trackedSets = []
        trackedExercise.setParameters = exercise.setParameters;
        trackedExercise.autoRun = exercise.autoRun && exercise.setParameters?.includes(SetParameter.time);

        trackedExercise.id = FirestoreNutritionPlanService.generateUniqueString(24);
        if (superSetExercises.length > 1 && i < superSetExercises.length - 1) {
          trackedExercise.nextSupersetExerciseId = superSetExercises[i + 1]?.id;
        }
        trackedTrainingSession.trackedTrainingExercises.push(trackedExercise)

        if (!trackedExercise.setParameters.includes(SetParameter.pauseDuration) && exercise.pauseDuration > 0) {
          trackedExercise.setParameters.push(SetParameter.pauseDuration);
        }
        for (let index = 0; index < exercise.sets.length; index++) {
          const set = exercise.sets[index];
          let trackedSet = new TrackedTrainingSet(set);
          trackedSet.setNumber = index;
          if (set.pauseDuration == null && exercise.pauseDuration > 0) {
            trackedSet.pauseDuration = exercise.pauseDuration;
          }
          trackedExercise.trackedSets.push(trackedSet);
          trackedExercise.sets.push(set.clone());
        }
      }
    });

    // trackedTrainingSession.trackedTrainingExercises = trainingSession.exercises.map(x => new TrackedTrainingExercise({ exerciseId: x.exerciseId, plannedExerciseId: x.id, trackedSets: x.sets.map(y => new TrackedTrainingSet(y)) }))
    return trackedTrainingSession
  }

  containsExercise(exerciseId: string) {
    return this.trackedTrainingExercises.filter(exercise => exercise.exerciseId == exerciseId).length > 0
  }

  getTrackedExerciseByExerciseId(exerciseId: string): TrackedTrainingExercise {
    var result = this.trackedTrainingExercises.filter(exercise => exercise.exerciseId == exerciseId)
    if (result.length > 0) return result[0]
    return null
  }

  getSetCount(exerciseId: string = null) {
    var value = null
    if (this.trackedTrainingExercises) {
      for (var exercise of this.trackedTrainingExercises) {
        var v = exercise.getSetCount(exerciseId)
        if (v != null) {
          if (value == null) {
            value = v
          } else {
            value += v
          }
        }
      }
    }
    return value
  }
  getRepCount(exerciseId: string = null) {
    var value = null
    if (this.trackedTrainingExercises) {
      for (var exercise of this.trackedTrainingExercises) {
        var v = exercise.getRepCount(exerciseId)
        if (v != null) {
          if (value == null) {
            value = v
          } else {
            value += v
          }
        }
      }
    }
    return value
  }
  getTrainingDuration() {
    if (this.startDate && this.endDate) return Math.floor((this.endDate.getTime() - this.startDate.getTime()) / (1000 * 60))
    return null
  }
  getPrintableTrainingDuration() {
    var duration = this.getTrainingDuration()
    if (duration == null) return null
    return Math.floor(duration / 60) + ':' + ((duration % 60) < 10 ? '0' : '') + (duration % 60)
  }

  getTotalWeight(exerciseId: string = null) {
    var value = null
    if (this.trackedTrainingExercises) {
      for (var exercise of this.trackedTrainingExercises) {
        var v = exercise.getTotalWeight(exerciseId)
        if (v != null) {
          if (value == null) {
            value = v
          } else {
            value += v
          }
        }
      }
    }
    return value
  }

  getMaxWeight(exerciseId: string = null) {
    var value = null
    if (this.trackedTrainingExercises) {
      for (var exercise of this.trackedTrainingExercises) {
        var v = exercise.getMaxWeight(exerciseId)
        if (v != null && (value == null || v > value)) value = exercise.getMaxWeight(exerciseId)
      }
    }
    return value
  }

  getE1RM(exerciseId: string = null) {
    var value = null
    if (this.trackedTrainingExercises) {
      for (var exercise of this.trackedTrainingExercises) {
        var v = exercise.getE1RM(exerciseId)
        if (v != null && (value == null || v > value)) value = exercise.getE1RM(exerciseId)
      }
    }
    return value
  }

  getEXRM(x: number) {
    if (x == 1) return this.getE1RM();
    var value = null
    if (this.trackedTrainingExercises) {
      for (var exercise of this.trackedTrainingExercises) {
        var v = exercise.getE1RM()
        if (v != null && (value == null || v > value)) value = exercise.getEXRM(x);
      }
    }
    return value
  }

  getAverageExertion(exerciseId: string = null) {
    var value = 0
    var count = null
    if (this.trackedTrainingExercises) {
      for (var exercise of this.trackedTrainingExercises) {
        var exertion = exercise.getAverageExertion(exerciseId)
        if (exertion != null) {
          value += exertion
          if (count == null) {
            count = 1
          } else {
            count++
          }
        }
      }
    }
    if (count != null) return value / count
    return null
  }

  getAverageRPE(exerciseId: string = null) {
    var value = 0
    var count = null
    if (this.trackedTrainingExercises) {
      for (var exercise of this.trackedTrainingExercises) {
        var exertion = exercise.getAverageRPE(exerciseId)
        if (exertion != null) {
          value += exertion
          if (count == null) {
            count = 1
          } else {
            count++
          }
        }
      }
    }
    if (count != null) return value / count
    return null
  }

  getAverageRIR(exerciseId: string = null) {
    var value = 0
    var count = null
    if (this.trackedTrainingExercises) {
      for (var exercise of this.trackedTrainingExercises) {
        var exertion = exercise.getAverageRIR(exerciseId)
        if (exertion != null) {
          value += exertion
          if (count == null) {
            count = 1
          } else {
            count++
          }
        }
      }
    }
    if (count != null) return value / count
    return null
  }

  clone(): TrackedTrainingSession {
    return new TrackedTrainingSession(this)
  }

  asMap(): Record<string, any> {
    return {
      "startDate": this.startDate ?? new Date(),
      "endDate": this.endDate ?? new Date(),
      "trainingPlanId": this.trainingPlanId,
      "plannedSessionId": this.plannedSessionId,
      "sessionName": this.sessionName,
      "deleted": this.deleted,
      "viewedByCoach": this.viewedByCoach,
      "continued": this.continued,
      "timestamp": new Date(),
    }
  }



  getJson(): string {
    return JSON.stringify(this, jsonReplacer);
  }

  static fromJson(json: string): TrackedTrainingSession {
    try {
      let obj = JSON.parse(json) as TrackedTrainingSession;
      obj.startDate = new Date(obj.startDate);
      if (obj.endDate) obj.endDate = new Date(obj.endDate);
      let trackedTrainingExercises = [];
      obj.trackedTrainingExercises?.forEach(exercise => {
        if(exercise.startDate) {
          exercise.startDate = new Date(exercise.startDate);
        }
        trackedTrainingExercises.push(new TrackedTrainingExercise(exercise));
      });
      obj.trackedTrainingExercises = trackedTrainingExercises;
      let trackedTrainingSession = new TrackedTrainingSession(obj);
      trackedTrainingSession.tempUserUid = obj.tempUserUid;
      return trackedTrainingSession;
    }
    catch (ex) {
      console.error('Error parsing TrackedTrainingSession', ex);
    }
    return null;
  }
}

export class TrackedSuperSets {
  public TrackedTrainingSets: TrackedTrainingSet[] = []

}

export class TrackedExerciseSet {
  constructor(trackedTrainingSet: TrackedTrainingSet, trackedTrainingExercise: ExtendedTrackedTrainingExercise, exerciseThumbnailNumber: number, plannedTrainingExercise?: PlannedTrainingExercise) {
    this.trackedTrainingSet = trackedTrainingSet;
    this.trackedTrainingExercise = trackedTrainingExercise;
    this.exerciseThumbnailNumber = exerciseThumbnailNumber;
    this.plannedTrainingExercise = plannedTrainingExercise;
  }

  public trackedTrainingSet: TrackedTrainingSet;
  public trackedTrainingExercise: ExtendedTrackedTrainingExercise;

  public exerciseThumbnailNumber: number;
  public plannedTrainingExercise: PlannedTrainingExercise;
}

export class TrackedSuperSetRounds {
  public trackedExerciseSets: TrackedExerciseSet[] = []


  public getTimeInSeconds(): number {
    let time: number = 0;
    this.trackedExerciseSets.forEach(exerciseSet => {
      if (exerciseSet.trackedTrainingSet.time) {
        time += exerciseSet.trackedTrainingSet.time
      }
      if (exerciseSet.trackedTrainingSet.pauseDuration) {
        time += exerciseSet.trackedTrainingSet.pauseDuration
      }
    });
    return time;
  }

  public getTime(): Date {
    let time = this.getTimeInSeconds();
    return new Date(0, 0, 0, 0, 0, time, 0);
  }
}

export class TrackedSuperSet {
  constructor(trackedSuperExercises: ExtendedTrackedTrainingExercise[]) {
    this.trackedSuperSetExercises = trackedSuperExercises;
  }

  public trackedSuperSetRounds: TrackedSuperSetRounds[] = []
  public trackedSuperSetExercises: ExtendedTrackedTrainingExercise[] = []

  public plannedSuperSetRounds: Map<SetParameter, string>[][] = [];

  hasVideoRecordings() {
    return this.trackedSuperSetExercises.filter(x => x.hasVideoRecordings()).length > 0
  }
  hasCoacheeNotes() {
    return this.trackedSuperSetExercises.filter(x => x.hasCoacheeNotes()).length > 0
  }
}

export class ExtendedTrackedTrainingExercise extends TrackedTrainingExercise {
  public isExerciseExchanged: boolean = false;

  /**
   *
   */
  constructor();
  constructor(init: TrackedTrainingExercise);
  constructor(init?: TrackedTrainingExercise) {
    super(init);
  }

  static createFromTrackedTrainingExercise(trackedTrainingExercise: TrackedTrainingExercise): ExtendedTrackedTrainingExercise {
    return trackedTrainingExercise as ExtendedTrackedTrainingExercise
  }
}


function jsonReplacer(key, value) {
  if (key == "newVideoFile") return undefined;
  if (key == "videoUrl") return undefined;
  if (key == "blob") return undefined;
  if (key == "plannedExercise") return undefined;
  else return value;
}