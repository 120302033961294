import { TrainingService } from './../../services/training.service';
import { MuscleInformation, ExerciseTypeTranslation, ITrainingExercise, ITrainingExerciseOverwrite, TrainingExerciseOverwrite, MergedTrainingExercise, TrainingExercise, MuscleTranslation, MuscleGroupTranslation, EquipmentTranslation } from './../../model/training-exercise';
import { ILanguageDictionary } from 'src/app/model/languagedictionary.model';
import { LanguageDictionary } from 'src/app/model/languagedictionary.model';
import { Component, Input, OnInit,NgZone, EventEmitter, Output, Inject, Renderer2, Pipe, PipeTransform } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { Label2SetParameterMapping, SetParameter, SetParameter2LabelMapping, SetParameter2LanguageDictionary } from 'src/app/model/training-plan.model';
import { LanguageService } from 'src/app/services/language.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ImageEditorComponent } from 'src/app/utilities/image-editor/image-editor.component';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-single-exercise',
  templateUrl: './single-exercise.component.html',
  styleUrls: ['./single-exercise.component.css']
})
export class SingleExerciseComponent implements OnInit {

  public environment = environment

  public selectedTrainingExercise: MergedTrainingExercise;
  public isvisible: boolean = false;

  private newThumbnail: Blob;
  public thumbnailImageSrc: any;

  public newVideo: File;
  public videoSrc: any;
  private deletedVideo: boolean = false;

  private newExercisePreview: File;
  public exercisePreviewSrc: any;

  public editExerciseDisabled: boolean = true;
  public overwriteExerciseDisabled: boolean = true;
  public previewOnly: boolean = true;
  public isLanguageEnglish: boolean = false;
  // public selectedLanguageCode:string = "de"

  public get selectedLanguageCode(): string {
    return this.languageService.selectedLanguageCode;
  }
  public set selectedLanguageCode(value: string) {
    this.languageService.selectedLanguageCode = value;
  }

  public setParameter = SetParameter;
  public SetParameter2LanguageDictionary = SetParameter2LanguageDictionary;
  public label2SetParameterMapping = Label2SetParameterMapping;

  public forceEditorUpdate: boolean = false;


  placeholder = marker('Instruktionen...');


  constructor(public dialogRef: MatDialogRef<SingleExerciseComponent>, @Inject(MAT_DIALOG_DATA) private data: {selectedExercise: MergedTrainingExercise, editExerciseDisabled: boolean, overwriteExerciseDisabled: boolean, previewOnly: boolean}, private toastr: ToastrService, private renderer: Renderer2, public trainingService:TrainingService, private ngZone: NgZone, public dialog: MatDialog, public languageService: LanguageService, private domSanitizer: DomSanitizer, private translate: TranslateService, public authService: AuthService) {
    this.selectedTrainingExercise = data.selectedExercise
    this.editExerciseDisabled = data.editExerciseDisabled
    this.previewOnly = data.previewOnly || this.selectedTrainingExercise.editDisabled || false;
    this.overwriteExerciseDisabled = data.overwriteExerciseDisabled
    if(this.previewOnly){
      this.editExerciseDisabled = true;
      this.overwriteExerciseDisabled = true;
    }
    this.thumbnailImageSrc = this.selectedTrainingExercise.thumbnailDownloadURL;
    this.trainingService.GetFileDownloadURL(this.selectedTrainingExercise.instructionVideoPath, this.selectedTrainingExercise.isFromCommonFirebaseProject && this.selectedTrainingExercise.trainingExerciseOverwrite?.id == null)?.toPromise()?.then(
      link => this.videoSrc = link || ""
    );
    this.trainingService.GetFileDownloadURL(this.selectedTrainingExercise.exercisePreviewPath, this.selectedTrainingExercise.isFromCommonFirebaseProject)?.toPromise()?.then(
      link => this.exercisePreviewSrc = link || ""
    );

    this.placeholder = this.translate.instant(this.placeholder)
  }
  
  ngOnInit(): void {
  }

  getSelectableSetParameters(): LanguageDictionary<SetParameter>[]{
    return Object.values(SetParameter).map(x => SetParameter2LanguageDictionary[x])
    // return Object.values(SetParameter).filter(parameter => !this.selectedTrainingExercise.defaultSetParameters.map(x => x.originObject).includes(parameter)).map(x => SetParameter2LanguageDictionary[x])
  }

  onIsInvertedWeightChanged(isInvertedWeight: boolean){
    if(!this.previewOnly){
      this.selectedTrainingExercise.isInvertedWeight = isInvertedWeight;
    }
  }

  onHiddenExerciseChanged(hiddenExercise: boolean){
    if(!this.previewOnly){
      this.selectedTrainingExercise.hiddenExercise = hiddenExercise;
    }
  }

  onIsBasicExerciseChanged(isBasicExercise: boolean) {
    if(!this.previewOnly){
      this.selectedTrainingExercise.isBasicExercise = isBasicExercise;
    }
  }

  
  onDeleteVideo(){
    this.videoSrc = ""
    this.newVideo = null
    this.deletedVideo = true
  }

  openVideoDialog(){
    if(this.previewOnly) return;
    (document.querySelector('#video-upload') as HTMLElement).click()
  }

  openThumbnailDialog() {
    if(this.previewOnly) return;
    (document.querySelector('#thumbnail-input') as HTMLElement).click()
  }
  uploadThumbnail(e) {
    if (e.target.files && e.target.files[0]) {
      this.editThumbnail(e.target.files[0]);
    }
  }

  editThumbnail(file: File) {
    if (!SingleExerciseComponent.checkUploadFile(file, 100000000, ["jpg", "jpeg", "png", "gif"], this.toastr, this.translate)) return;
    const dialogRef = this.dialog.open(ImageEditorComponent, {
      data: { imageFile: file, aspectRatio: 1, maintainAspectRatio: true, containWithinAspectRatio: true, maxDimension: 720, transparentBackground: true },
      width: '1000px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result?.croppedImage) {
        this.convertThumbnail(result.croppedImage)
      }
    });
  }

  openExercisePreviewDialog() {
    if(this.previewOnly) return;
    (document.querySelector('#preview-input') as HTMLElement).click()
  }

  onDragOver(event) {
    event.preventDefault();
  }
  
  onDropThumbnail(event) {
    if(this.previewOnly) return;
    event.preventDefault();
    if (event.dataTransfer.files?.length > 0) {
      var file = event.dataTransfer.files[0]
      this.editThumbnail(file);
      // if (!SingleExerciseComponent.checkUploadFile(file, 1000000, ["jpg", "jpeg", "png", "gif"], this.toastr)) return
      // this.convertThumbnail(file);
    }
  }
  
  onDropPreview(event) {
    if(this.previewOnly) return;
    event.preventDefault();
    if (event.dataTransfer.files?.length > 0) {
      var file = event.dataTransfer.files[0]
      if (!SingleExerciseComponent.checkUploadFile(file, 1000000, ["gif"], this.toastr, this.translate)) return
      this.processPreview(file);
    }
  }
  
  static checkUploadFile(file: File, maxSize: number = 50000000, acceptedTypes: string[], toastr: ToastrService, translate: TranslateService): boolean{
    if(!file) return false
    if(file.size > maxSize) {
      toastr?.show(translate.instant("Die gewählte Datei ist zu groß. Die maximal zulässige Dateigröße liegt bei maxSizeParameter MB.", {maxSizeParameter: maxSize / 1000000}))
      return false;
    }
    let validType:boolean = acceptedTypes.filter(x => file.name?.toLowerCase().endsWith('.' + x)).length > 0
    if(validType !== true) {
      toastr?.show(translate.instant("Die ausgewählte Datei entspricht nicht dem richtigen Format."))
      return false;
    }

    return true
  }

  uploadVideo(e){
    if(this.previewOnly) return;
    if (e.target.files && e.target.files[0]) {
      this.newVideo = e.target.files[0];
      this.deletedVideo = false
      if(!SingleExerciseComponent.checkUploadFile(e.target.files[0], 50000000, ["mp4", "x-m4v", "mov", "quicktime"], this.toastr, this.translate)) return;
      var reader = new FileReader();
      reader.readAsDataURL(this.newVideo);
      reader.onload = (event) => {
        let newVideoSrc = (<FileReader>event.target)?.result?.toString();
        this.ngZone.run(() => {
          if(newVideoSrc != null){
            this.videoSrc = this.domSanitizer.bypassSecurityTrustUrl(newVideoSrc.replace('video/quicktime', 'video/mp4'));
          }
        });
      }
    }
  }

  uploadExercisePreview(e){
    if (e.target.files && e.target.files[0]) {
      if(!SingleExerciseComponent.checkUploadFile(e.target.files[0], 50000000, ["gif"], this.toastr, this.translate)) return;
      this.processPreview(e.target.files[0])
    }
  }

  processPreview(file: File) {
    this.newExercisePreview = file;
    var reader = new FileReader();
    reader.readAsDataURL(this.newExercisePreview);
    reader.onload = (event) => {
      this.ngZone.run(() => {
        this.exercisePreviewSrc = (<FileReader>event.target).result;
      });
    }
  }

  convertThumbnail(thumbnail: File) {
    var thumbnailImage = new Image();
    thumbnailImage.src = URL.createObjectURL(thumbnail)
    thumbnailImage.onload = (event) => {
      var newWidth = 360;
      var newHeight = 360;

      var width = thumbnailImage.width;
      var height = thumbnailImage.height;

      var p = new Image();
      p.width = newWidth;
      var i = width / newHeight;
      p.height = height / i;
      p.src = thumbnailImage.src;
      
      var x = 0;
      var y = 0;
      var min = height;
      if (width > height) {
        x = (width - height) / 2;
      } else {
        y = (height - width) / 2;
        min = width;
      }
      const elem = document.createElement('canvas');
      elem.width = newWidth;
      elem.height = newHeight;
      const ctx = elem.getContext('2d');
      ctx.drawImage(p, x, y, min, min, 0, 0, newWidth, newHeight);
      ctx.canvas.toBlob(async (blob) => {
        this.ngZone.run( async () => {
          this.thumbnailImageSrc = await this.blobToBase64(blob);
        });
          this.newThumbnail = blob
          return blob
      }, 'image/png', 1);
    }
  }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  onEditorValueChanged(value: string) {
    let instructions = this.selectedTrainingExercise.instructions;
    if (value == this.placeholder) {
      instructions.SetValue(this.selectedLanguageCode, '')
    } else {
      instructions.SetValue(this.selectedLanguageCode, value)
    }
    this.selectedTrainingExercise.instructions = instructions
  }

  onNotesEditorValueChanged(value: string) {
    if (value == this.placeholder) {
      this.selectedTrainingExercise.customNotes = ""
    } else {
      this.selectedTrainingExercise.customNotes = value
    }
  }

  setLanguageDictionaryValue(mergedExercise: MergedTrainingExercise, propertyName: string, value: string){
    let languageDictionary = mergedExercise[propertyName] as ILanguageDictionary<any>;
    languageDictionary.SetValue(this.languageService.selectedLanguageCode, value);
    mergedExercise[propertyName] = languageDictionary;
  }

  setSelectedItems(mergedExercise: MergedTrainingExercise, propertyName: string, selectedItems: LanguageDictionary<any>[]){
    mergedExercise[propertyName] = selectedItems;
  }


  refreshEditor(){
    this.forceEditorUpdate = true;
  }

  cancelEditExercise(){
    if(!this.previewOnly && (this.selectedTrainingExercise.isDirty || this.newVideo || this.newExercisePreview || this.newThumbnail || this.deletedVideo)){
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { message: this.translate.instant('Möchtest du die Übung vor dem Schließen speichern?'), title: this.translate.instant('Übung speichern'), positiveButton: this.translate.instant('Ja'), negativeButton: this.translate.instant('Nein') },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == true) {
          this.onSaveExercise();
        } else {
          this.onCloseDialog(false, false);
        }
      })
    }
    else {
      this.onCloseDialog(false, false);
    }
  }

  public videoPattern = "^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.be|youtube\.de|youtube-nocookie\.com|vimeo.com|player.vimeo.com|drive.google.com)\/.+$";

  onSaveExercise(){
    if((this.selectedTrainingExercise.customVideoUrl?.length <= 0 || this.selectedTrainingExercise.customVideoUrl.match(this.videoPattern)) && this.selectedTrainingExercise.name?.GetValue(this.selectedLanguageCode)?.length > 0)
    {
      this.onCloseDialog(true, false)
    }
    else{
      this.toastr.error(this.translate.instant("Bitte überprüfe deine Eingaben."), "",  {
        positionClass: 'toast-bottom-center'
      });
    }
  }

  onDeleteExercise(){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: this.translate.instant('Möchtest du diese Übung wirklich löschen?'), title: this.translate.instant('Übung löschen') },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onCloseDialog(true, true)
      }
    })
  }

  onCloseDialog(shouldSave: boolean, shouldDelete: boolean){
    this.dialogRef.close({shouldSave: shouldSave, shouldDelete: shouldDelete, exercise: this.selectedTrainingExercise, newThumbnail: this.newThumbnail, newVideo: this.newVideo, newExercisePreview: this.newExercisePreview, deletedVideo: this.deletedVideo, customImages: this.customImages ?? null })
  }

  customImages: any[] = null
  onCustomImagesChanged(images: any[]) {
    console.log(images)
    this.customImages = images
  }

  async translateExerciseToAllLanguages(){
    await this.languageService.translateExerciseInstructionsToAllLanguages(this.selectedTrainingExercise, this.languageService.selectedLanguageCode);
    await this.languageService.translateExerciseNameToAllLanguages(this.selectedTrainingExercise, this.languageService.selectedLanguageCode);
    await this.languageService.translateExerciseSubNameToAllLanguages(this.selectedTrainingExercise, this.languageService.selectedLanguageCode);
    this.selectedTrainingExercise.isDirty = true;
  }

}

@Pipe({name: 'pluck'})
export class PluckPipe implements PipeTransform {
  transform (input: any[], key: string): any {
      return input.map(value => value[key]);
  }
}