import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FirebaseProject, IndividualFirebase } from '../app.module';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { InputFieldDialogComponent } from '../inputfield-dialog/inputfield-dialog.component';
import { SharedFile, SharedFileType } from '../model/sharedfile.model';
import { User } from '../model/user.model';
import { FirestoreService } from '../services/firestore.service';
import { UtilityService } from '../services/utility.service';
import { LinkCreationComponent } from '../dialogs/link-creation/link-creation.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { DocumentReference } from 'firebase/firestore';
import { FileEditorDialogComponent } from '../dialogs/file-editor-dialog/file-editor-dialog.component';
import { LanguageService } from '../services/language.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { NotificationService } from '../services/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-file-sharing',
  templateUrl: './file-sharing.component.html',
  styleUrls: ['./file-sharing.component.css']
})
export class FileSharingComponent implements OnInit {
  constructor(private userService: FirestoreService, public utilityService: UtilityService, private firebaseProject: IndividualFirebase, private toastr: ToastrService, public dialog: MatDialog, private spinner: NgxSpinnerService, public languageService: LanguageService, private notificationService: NotificationService, public translate: TranslateService) {}

  public user: User
  @Input() set User(user: User) {
    this.user = user
    this.init()
  }

  private referencedSharedFileId: string = null;

  @Input() set ReferencedSharedFileId(referencedSharedFileId: string) {
    this.referencedSharedFileId = referencedSharedFileId;
    this.setSelectedSharedFileById(referencedSharedFileId);
  }

  public PERSONAL_FILES = 'PERSONAL_FILES'
  public PUBLIC_FILES = 'PUBLIC_FILES'
  public selectedFileSource: string = this.PERSONAL_FILES

  public personalFiles: SharedFile[] = []
  public publicFiles: SharedFile[] = []
  public focusedFile: SharedFile = null

  public currentFolder: SharedFile = null;

  public selectedFileForUpload: File
  public allowedFileExtensions: string[]
  public imageCropperEvent: any = null
  public croppedImage: any = null
  public fileSearchInput: string =  ''

  public spinnerText: string = '';

  ngOnInit(): void {
  }

  init() {
    if (!this.user) {
      this.selectedFileSource = this.PUBLIC_FILES
    }
    this.resetPath()
    if (this.user) {
      this.userService.getSharedFiles(this.user).subscribe(files => {
        this.personalFiles = files
        if(this.referencedSharedFileId != null){
          this.setSelectedSharedFileById(this.referencedSharedFileId);
        }
      })
    }
    this.userService.getPublicSharedFiles().subscribe(files => {
      this.publicFiles = files 
      if(this.user){
        this.publicFiles = files.filter(file => !file.isFolder() || this.isFolderAvailableForUser(file))
      }
      if(this.referencedSharedFileId != null){
        this.setSelectedSharedFileById(this.referencedSharedFileId);
      }
    })

   
  }

  isFolderAvailableForUser(sharedFile: SharedFile){
    if(sharedFile.assignedUids?.includes(this.user.uid)) return true;
    if(sharedFile.assignedGroupNames?.includes('Alle')) return true;
    if(sharedFile.assignedGroupNames?.some(x => this.userService.getClientGroups()?.includes(x))) return true;
    return false;
  }

  setSelectedSharedFileById(id: string){
    if(this.focusedFile != null){
      return;
    }
    if(this.user){
      this.onFocusFile(this.personalFiles.find(x => x.id == id));
      if(this.focusedFile != null){
        this.selectedFileSource = this.PERSONAL_FILES;
        this.referencedSharedFileId = null;
      }
    }
    if(this.focusedFile == null){
      this.onFocusFile(this.publicFiles.find(x => x.id == id));
      if(this.focusedFile != null){
        this.selectedFileSource = this.PUBLIC_FILES;
        this.referencedSharedFileId = null;
      }
    }

    if(this.focusedFile != null){
      let folder = this.getFolders().find(x => this.focusedFile.isInFolder(x));
      this.currentFolder = folder;
    }
  }

  resetPath() {
    this.currentFolder = null
    this.focusedFile = null
  }

  getRootPath(personal: boolean): string {
    return this.userService.getRootPath(personal, this.user)
  }

  getFolders(){
    return (this.selectedFileSource == this.PERSONAL_FILES ? this.personalFiles : this.publicFiles).filter(file => file.isFolder()).sort((a, b) => a.fileName?.localeCompare(b.fileName));
  }

  getFilesAtCurrentPath() {
    return this.getFilesOfFolder(this.currentFolder);
  }


  getFilesOfFolder(folder: SharedFile){
    return (this.selectedFileSource == this.PERSONAL_FILES ? this.personalFiles : this.publicFiles).filter(file => 
      {
        if(file.isFolder()) return false;
        if(folder == null) return file.isAtPath('');
        else if(file.version > 1){
          return file.isAtPath(folder.id);
        }
        else {
          return file.isAtPath(folder.fileName);
        }
      }).sort((a, b) => {
        if(a.name?.de != null && b.name?.de != null){
          return a.name.de.localeCompare(b.name.de);
        }
        return a.fileName?.localeCompare(b.fileName)
      }).sort((a, b) => a.position - b.position);
  }


  loadImageFailed() {
      console.log("Loading image failed.")
  }

  onSelectFileSource(source: string) {
    this.selectedFileSource = source
    this.resetPath()
  }

  static dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {type:mimeString});
  }

  onFocusFile(file: SharedFile) {
    if(file == null || this.isFileUploadRunning(file)) return;
    if (file?.isFolder()) {
      this.currentFolder = file;
      this.focusedFile = null
    } else {
      this.focusedFile = file
      let check = this.focusedFile.description?.GetValue(this.translate.currentLang);
      if (!this.focusedFile.isWebLink()) {
        this.firebaseProject.storage.ref(this.getRootPath(file.personal) + this.focusedFile.getFullPath()).getDownloadURL().toPromise().then((url) => {
          console.log(url)
          this.focusedFile.url = url
        }).catch((error) => {
          console.log(error)
        });
      }
    }
  }

  onFocusRootPath(){
    this.resetPath();
  }

  onFocusFolder(folder: SharedFile) {
    this.currentFolder = folder;
    this.focusedFile = null
  }

  goToLink(url: string){
    window.open(url, "_blank");
  }

  onDeleteFolder(sharedFolder: SharedFile) {
    if(sharedFolder?.id == null) return;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: this.translate.instant('Möchtest du diesen Ordner und seinen Inhalt wirklich löschen?'), title: this.translate.instant('Ordner löschen') },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show()
        await this.deleteFile(sharedFolder);
        if (this.selectedFileSource == this.PERSONAL_FILES) {
          for (var file of this.personalFiles) {
            if (!file.isFolder() && file.isInFolder(sharedFolder)) {
              await this.deleteFile(file)
            }
          }
        } else {
          for (var file of this.publicFiles) {
            if (!file.isFolder() && file.isInFolder(sharedFolder)) {
              await this.deleteFile(file)
            }
          }
        }
        this.currentFolder = null
        this.focusedFile = null
        this.spinner.hide()
      }
    })
  }

  onDeleteSharedFile(sharedFile: SharedFile) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { message: this.translate.instant('Möchtest du diese Datei wirklich löschen?'), title: this.translate.instant('Datei löschen') },
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show()
        let folder = this.getFolders()?.find(x => sharedFile.isInFolder(x));
        await this.deleteFile(sharedFile)
        if(folder != null){
          await this.updateFileTimestamp(folder);
        }
        this.focusedFile = null
        this.spinner.hide()
      }
    })
  }

  private async deleteFile(file: SharedFile) {
    file.deleted = true
    file.timestamp = new Date()
    if (file.personal) {
      await this.userService.saveSharedFile(this.user, file)
    } else {
      await this.userService.savePublicSharedFile(this.userService.getLoggedInUser().coach, file)
    }
    await this.firebaseProject.storage.ref(this.getRootPath(file.personal) + file.getFullPath()).delete()
  }

  onNavigateBack() {
    // this.currentSuffix = this.currentSuffix.substring(0, this.currentSuffix.lastIndexOf('/'))
    this.currentFolder = null
    this.focusedFile = null
  }

  onEditFile(sharedFile: SharedFile){
    let editedFile = sharedFile.clone();
    const dialogRef = this.dialog.open(FileEditorDialogComponent, { data: { selectedFile: editedFile, sharedFileType: sharedFile.getSharedFileType(), existingPersonalFiles: this.personalFiles}, width: '1000px', autoFocus: false})
    dialogRef.afterClosed().subscribe(async result => {
      if(result){
        if(result.save){
          if(result.delete){
            this.onDeleteSharedFile(sharedFile);
          }
          else {
            try{
              this.spinner.show();
              if(sharedFile.version > 1){
                await this.saveFile(editedFile, result.removeThumbnail, result.newThumbnail);
              }
              else {
                let folder = this.getFolders().find(x => sharedFile.isInFolder(x));
                await this.updateFileAndIncreaseVersion(editedFile, folder, result.newThumbnail, result.removeThumbnail);
              }
            }
            catch(ex){
              console.error(ex);
              this.toastr.error(this.translate.instant("Es ist ein unbekannter Fehler aufgetreten."), this.translate.instant("Fehler"), {
                positionClass: 'toast-bottom-center'
              });
            }
            finally{
              this.spinner.hide();
            }
          }
        }
      }
    });
  }

  onUploadFile() {
    document.getElementById('input-fileupload').click()
  }
  async onUploadFileSelected(e) {
    this.selectedFileForUpload = e.target.files[0]
    var fileName = this.selectedFileForUpload.name as string
    const format = /[!@#$%^&*()+=\[\]{};':"\\|,<>\/?]+/
    //const format2 = /[^\x00-\x7F(?:\u00c4,\u00e4,\u00d6,\u00f6,\u00dc,\u00fc,\u00df)]/;
    if (format.test(fileName)) {
      this.toastr.error(this.translate.instant('Fehler: Der Dateiname darf keine Sonderzeichen enthalten.'), '',  {
        positionClass: 'toast-bottom-center'
      })
      return
    }
    if (this.selectedFileForUpload.size > 100000000) {
      this.toastr.error(this.translate.instant('Die gewählte Datei ist zu groß. Die maximal zulässige Dateigröße liegt bei 100MB'), '',  {
        positionClass: 'toast-bottom-center'
      })
      return
    }
    
    var sharedFile = new SharedFile()
    sharedFile.path = this.currentFolder?.id ?? '';
    sharedFile.fileName = this.selectedFileForUpload.name
    sharedFile.name.de = this.selectedFileForUpload.name
    sharedFile.timestamp = new Date()
    sharedFile.personal = this.selectedFileSource == this.PERSONAL_FILES
    sharedFile.version = 2;

    const dialogRef = this.dialog.open(FileEditorDialogComponent, { data: { selectedFile: sharedFile, sharedFileType: SharedFileType.File, existingPersonalFiles: this.personalFiles}, width: '1000px', autoFocus: false})
    dialogRef.afterClosed().subscribe(async result => {
      if(result){
        if(result.save && !result.delete){
          sharedFile.fileName = sharedFile.fileName || this.selectedFileForUpload.name
          await this.saveFile(sharedFile, result.removeThumbnail, result.newThumbnail, this.selectedFileForUpload);
        }
      }
    });
  }

  canAccessGroup(group: string) {
    return this.userService.getLoggedInUser().coach.canAccessClientGroup(group)
  }


  private async updateFileTimestamp(file: SharedFile){
    if(file.personal){
      await this.userService.updateSharedFileTimestamp(this.user, file);
    }
    else {
      await this.userService.updatePublicSharedFileTimestamp(this.userService.getLoggedInUser().coach, file);
    }
  }

  private async saveFile(file: SharedFile, removeThumbnail = false, newThumbnail: Blob = null, newFile: File = null) {
    try{
      this.spinner.show();
      let folder = this.getFolders().find(x => file.isInFolder(x));
      let filesAtPath = this.getFilesOfFolder(folder);
      if(file.id == null){
        file.position = filesAtPath.length;
      }

      if (file.personal) {
        await this.userService.saveSharedFile(this.user, file, removeThumbnail, newThumbnail, newFile);

        if(folder != null){
          await this.userService.updateSharedFileTimestamp(this.user, folder);
        }

        this.notificationService.composeFileExchangeUpdateNotification();

      } else {
        await this.userService.savePublicSharedFile(this.userService.getLoggedInUser().coach, file, removeThumbnail, newThumbnail, newFile);
        
        if(folder != null){
          await this.userService.updatePublicSharedFileTimestamp(this.userService.getLoggedInUser().coach, folder);
        }
      }
    }
    catch(ex){
      console.error(ex);
      this.toastr.error(this.translate.instant("Es ist ein unbekannter Fehler aufgetreten."), this.translate.instant("Fehler"), {
        positionClass: 'toast-bottom-center'
      });
    }
    finally{
      this.spinner.hide();
    }
  }

  getFileUploadProgress(file: SharedFile){
    if(this.userService.fileUploadProgress?.has(file.id)){
      return this.userService.fileUploadProgress.get(file.id);
    }
    return 0;
  }

  isFileUploadRunning(file: SharedFile){
    return this.userService.fileUploadProgress?.has(file.id);
  }

  onCreateTextFile() {
    var sharedFile = new SharedFile()
    sharedFile.path = this.currentFolder?.id ?? '';
    sharedFile.timestamp = new Date()
    sharedFile.personal = this.selectedFileSource == this.PERSONAL_FILES
    sharedFile.version = 2;
    
    this.showCreateFileEditorDialog(sharedFile, SharedFileType.Text);
  }

  onCreateWebLink(videoLink: boolean = false) {
    var sharedFile = new SharedFile()
    sharedFile.path = this.currentFolder?.id ?? '';
    sharedFile.timestamp = new Date()
    sharedFile.personal = this.selectedFileSource == this.PERSONAL_FILES
    sharedFile.version = 2;

    let fileType = videoLink ? SharedFileType.VideoLink : SharedFileType.WebLink;
    this.showCreateFileEditorDialog(sharedFile, fileType);
  }
  
  showCreateFileEditorDialog(sharedFile: SharedFile, sharedFileType: SharedFileType){
    const dialogRef = this.dialog.open(FileEditorDialogComponent, { data: { selectedFile: sharedFile, sharedFileType: sharedFileType, existingPersonalFiles: this.personalFiles}, width: '1000px', autoFocus: false})
    dialogRef.afterClosed().subscribe(async result => {
      if(result){
        if(result.save && !result.delete){
          try{
            this.spinner.show();
            sharedFile.name.de = sharedFile.name.de || sharedFile.webLink
            sharedFile.name.en = sharedFile.name.en || sharedFile.webLink
            sharedFile.name.fr = sharedFile.name.fr || sharedFile.webLink
            sharedFile.name.es = sharedFile.name.es || sharedFile.webLink
            await this.saveFile(sharedFile, result.removeThumbnail, result.newThumbnail);
          }
          catch(ex){
            console.error(ex);
            this.toastr.error(this.translate.instant("Es ist ein unbekannter Fehler aufgetreten."), this.translate.instant("Fehler"), {
              positionClass: 'toast-bottom-center'
            });
          }
          finally{
            this.spinner.hide();
          }
        }
      }
    });
  }

  canCreateFolder() {
    return this.currentFolder == null;
  }

  onCreateFolder() {
    var file = new SharedFile()
    file.fileName = ''
    file.path = null;
    file.timestamp = new Date()
    file.personal = this.selectedFileSource == this.PERSONAL_FILES
    file.version = 2;

    
    if(!this.canAccessGroup('Alle')){
      // file.assignedUids = this.userService.getAccessibleClients()?.map(x => x.uid) ?? [];
      file.assignedGroupNames = [];
    }

    this.editFolder(file);
  }

  
  editFolder(originalFolder: SharedFile){
    let editedFolder = originalFolder.clone();
    const dialogRef = this.dialog.open(FileEditorDialogComponent, { data: { selectedFile: editedFolder, sharedFileType: SharedFileType.Folder, existingPersonalFiles: this.personalFiles}, width: '1000px', autoFocus: false})
    dialogRef.afterClosed().subscribe(async result => {
      if(result){
        if(result.save){
          if(result.delete){
            this.onDeleteFolder(originalFolder);
          }
          else {
            this.updateFolder(originalFolder, editedFolder, result.newThumbnail, result.removeThumbnail);
          }
        }
      }
    });
  }

  async updateFolder(originalFolder: SharedFile, editedFolder: SharedFile, newThumbnail: Blob = null, removeThumbnail: boolean = false){
    try{
      let filesOfFolder = this.getFilesOfFolder(originalFolder);
      editedFolder.version = 2;
      await this.saveFile(editedFolder, removeThumbnail, newThumbnail);
      for(let file of filesOfFolder){
        if(file.version > 1){
          await this.updateFileTimestamp(file);
        }
        else {
          await this.updateFileAndIncreaseVersion(file, editedFolder);
        }
      }
    }
    catch(ex){
      console.error(ex);
      this.toastr.error(this.translate.instant("Es ist ein unbekannter Fehler aufgetreten."), this.translate.instant("Fehler"), {
        positionClass: 'toast-bottom-center'
      });
    }
    finally{
      this.spinner.hide();
    }
  }

  async updateFileAndIncreaseVersion(originalFile: SharedFile, folder: SharedFile, newThumbnail: Blob = null, removeThumbnail: boolean = false){
      this.spinner.show();
      this.spinnerText = this.translate.instant('Datei wird aktualisiert...');
      let clonedFile = originalFile.clone();
      clonedFile.path = folder?.id ?? '';
      clonedFile.version = 2;
      let newFile = null;
      if(!removeThumbnail && newThumbnail == null && originalFile.thumbnailPath != null){
        if(!originalFile.thumbnailLink){
          const ref = this.firebaseProject.storage.ref(this.getRootPath(originalFile.personal) + '/' + originalFile.thumbnailPath);
          originalFile.thumbnailLink = await ref.getDownloadURL().toPromise();
        }
        newThumbnail = await this.getFileFromURL(originalFile.thumbnailLink);
      }
      if(!originalFile.isWebLink() && !originalFile.isFolder()){
        if(!originalFile.url){
          const ref = this.firebaseProject.storage.ref(this.getRootPath(originalFile.personal) + '/' + originalFile.getFullPath());
          originalFile.url = await ref.getDownloadURL().toPromise();
        }
        newFile = await this.getFileFromURL(originalFile.url);
        this.spinnerText = null;
        this.spinner.hide();
        await this.saveFile(clonedFile, removeThumbnail, newThumbnail, newFile)
      }
  }

  //#region FileDragging

  public draggedFile: SharedFile = null;
  public draggingFile: boolean = false;
  onDragEndTemplate(event: any, file: any) {
    this.draggingFile = false;
    this.draggedFile = null;
  }
  onDragStartTemplate(event: any, file: SharedFile) {
    this.draggingFile = true;
    this.draggedFile = file;
  }
  async onDropOnFolderDropzone(event: any, folder: SharedFile) {
    event.preventDefault()
    if(this.draggedFile != null){
      try{
        let originalFile = this.draggedFile.clone();
        if(originalFile.isInFolder(folder)){
          return;
        }
        let clonedDraggedFile = this.draggedFile.clone();
        
        if(originalFile.version > 1 || ((originalFile.isWebLink() && originalFile.thumbnailPath == null))){
          clonedDraggedFile.path = folder?.id ?? '';
          await this.saveFile(clonedDraggedFile);
        }
        else {
          await this.updateFileAndIncreaseVersion(originalFile, folder);
        }
      }
      catch(ex){
        console.error(ex)
        this.toastr.error(this.translate.instant("Es ist ein unbekannter Fehler aufgetreten."), this.translate.instant("Fehler"), {
          positionClass: 'toast-bottom-center'
        });
      }
      this.focusedFile = null;
    }
  }
  onDragEndFolderTemplate($event: any,arg1: null) {
    this.draggingFile = false;
    this.draggedFile = null;
  }
  onDragOverFolderDropzone(event: any) {
    event.preventDefault()
  }


  private async getFileFromURL(url: string): Promise<File> {
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], url.split('/').pop(), {
      type: blob.type,
      lastModified: Date.now()
    });
    return file;
  }
  
  async onDropOnFileDropzone(event: any, file: SharedFile) {
    event.preventDefault()

    if(this.draggedFile != null){
      if(this.draggedFile == file) {
        return;
      }
      let folder = this.getFolders().find(x => this.draggedFile.isInFolder(x));
      let filesAtPath = this.getFilesOfFolder(folder);
      this.draggedFile.position = filesAtPath.length;
      let oldIndex = filesAtPath.findIndex(f => f.id == this.draggedFile.id);
      let newIndex = filesAtPath.findIndex(f => f.id == file.id);
      if(oldIndex == -1 || newIndex == -1){
        return;
      }
      moveItemInArray(filesAtPath, oldIndex, newIndex);
      this.setAllPositions(filesAtPath);
      for(let file of filesAtPath){
        await this.saveFile(file);
      }
    }
  }
  onDragOverFileDropzone(event: any) {
    event.preventDefault()
  }
  onDragEndFile(event: any, file: any) {
    this.draggingFile = false;
    this.draggedFile = null;
  }
  

  //#endregion

  setAllPositions(files: SharedFile[]){
    files.forEach((file, index) => {
      file.position = index;
    });
  }
}

export class StorageItem {
  name: string
  url: string
  path: string
  reference: DocumentReference<any>
  isFolder: boolean

  isImage() {
      return (this.path.toLowerCase().endsWith('.png') || this.path.toLowerCase().endsWith('.jpg') || this.path.toLowerCase().endsWith('.jpeg'))
  }
}