import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { BaseNutritionFact, NutritionalValue } from '../model/basenutritionfact.model';
import { ServingInquiry } from '../model/servinginquiry.model';
import { FirestoreService } from '../services/firestore.service';
import { UtilityService } from '../services/utility.service';
const instantsearch = require('instantsearch.js').default;
import { searchBox, hits, pagination } from 'instantsearch.js/es/widgets';
import connectHits, { HitsConnector } from 'instantsearch.js/es/connectors/hits/connectHits';
import TypesenseInstantsearchAdapter from 'typesense-instantsearch-adapter';
import { HttpClient } from '@angular/common/http';

const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter({
  server: {
    apiKey: "Io7U5tHSaJfUWuCG0P1SrhOAPnLPgjQ3", // Be sure to use an API key that only allows search operations
    nodes: [
      {
        host: "oh8j5ed04lrti217p.a1.typesense.net",
        port: 443,
        protocol: "https",
      },
    ],
    cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
  },
  additionalSearchParameters: {
    query_by: "nameDe,brands,barcode",
    sort_by: "detailed:desc",
    per_page: 20
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

@Component({
  selector: 'app-database-admin',
  templateUrl: './database-admin.component.html',
  styleUrls: ['./database-admin.component.css']
})
export class DatabaseAdminComponent implements OnInit, AfterViewInit {

  public selectedTab: string = 'NUTRITIONFACTS'
  public baseNutritionFact: BaseNutritionFact
  public baseNutritionFactInquiry: BaseNutritionFact
  public servingInquiry: ServingInquiry
  public databaseAdminInUse: boolean
  public numberOfInquiries: number
  
  searchConfig = instantsearch({
    indexName: 'BaseNutritionFacts',
    searchClient,
    queryType: 'prefixAll',
    removeWordsIfNoResults: 'lastWords',
    minWordSizefor2Typos: '7'
  });

  public nutritionFactSearchFocused: boolean = false
  public baseNutritionFactSaved: boolean = false

  public tmpSynonymsDe: string

  constructor(public authService: AuthService, private router: Router, public userService: FirestoreService, public utilityService: UtilityService, private http: HttpClient) { }

  ngOnInit(): void {
    this.baseNutritionFact = new BaseNutritionFact()
    this.userService.getAdminSettings().subscribe((document) => {
      this.databaseAdminInUse = document.databaseAdminInUse || false
    })
    /*this.userService.getNumberOfBaseNutritionFactInquiries().subscribe(number => {
      this.numberOfInquiries = number
    })*/
  }

  ngAfterViewInit(){
    this.setSearchConfig();
  }

  
  state: { hits: {}[] } = { hits: [] };
  setSearchConfig(){
    let searchBoxContainer = document.getElementById('searchbox')
    let paginationContainer = document.getElementById('pagination')
    

    if(!searchBoxContainer || !paginationContainer) return;
    let customHits = connectHits(this.updateState);
    this.searchConfig.addWidgets([
      searchBox({
          container: searchBoxContainer,
          placeholder: 'Lebensmittel suchen',
      }),
      customHits({}),
      pagination({
          container: paginationContainer,
      }),
    ]);

    this.searchConfig.start();

  }

  
  updateState = (state, isFirstRendering) => {
    if (isFirstRendering) {
      return Promise.resolve().then(() => {
        this.state = state;
      });
    }

    this.state = state;
  }


  onSelectTab(tab: string) {
    this.selectedTab = tab
  }

  onDatabaseAdminInUseChanged(value: boolean) {
    this.databaseAdminInUse = value
    this.userService.updateDatabaseAdminInUse(this.databaseAdminInUse)
  }

  loadNewBaseNutritionFactInquiry() {
    this.tmpSynonymsDe = null
    var subscription0 = this.userService.loadNewBaseNutritionFactInquiry().subscribe((result1) => {
      if (result1) {
        this.baseNutritionFactInquiry = result1
        this.baseNutritionFactSaved = false
        if (this.baseNutritionFactInquiry.barcode) {
          while (this.baseNutritionFactInquiry.barcode.startsWith('0')) {
            this.baseNutritionFactInquiry.barcode = this.baseNutritionFactInquiry.barcode.substr(1)
          }
          var subscription1 = this.userService.getBaseNutritionFactByBarcode(this.baseNutritionFactInquiry.barcode).subscribe((result2) => {
            if (result2) {
              this.baseNutritionFact = result2
              this.tmpSynonymsDe = this.baseNutritionFact.synonymsDe?.join(';')
              subscription1.unsubscribe()
            } else {
              this.baseNutritionFact = new BaseNutritionFact()
            }
          })
        } else {
          if (this.baseNutritionFactInquiry.baseNutritionFactId != null) {
            var subscription2 = this.userService.getBaseNutritionFactById(this.baseNutritionFactInquiry.baseNutritionFactId, false).subscribe((result3) => {
              if (result3) {
                this.baseNutritionFact = result3
                this.tmpSynonymsDe = this.baseNutritionFact.synonymsDe?.join(';')
                subscription2.unsubscribe()
              } else {
                this.baseNutritionFact = new BaseNutritionFact()
              }
            })
          } else {
            this.baseNutritionFact = new BaseNutritionFact()
          }
        }
        subscription0.unsubscribe()
      }
    })
  }
  loadNewBaseNutritionFactInquiryOFF() {
    var subscription0 = this.userService.loadNewBaseNutritionFactInquiryOFF().subscribe((result1) => {
      if (result1) {
        this.baseNutritionFactInquiry = result1
        this.baseNutritionFactSaved = false
        if (this.baseNutritionFactInquiry.barcode) {
          while (this.baseNutritionFactInquiry.barcode.startsWith('0')) {
            this.baseNutritionFactInquiry.barcode = this.baseNutritionFactInquiry.barcode.substr(1)
          }
          var subscription1 = this.userService.getBaseNutritionFactByBarcode(this.baseNutritionFactInquiry.barcode).subscribe((result2) => {
            if (result2) {
              this.baseNutritionFact = result2
              subscription1.unsubscribe()
            } else {
              this.baseNutritionFact = new BaseNutritionFact()
            }
          })
        } else {
          this.baseNutritionFact = new BaseNutritionFact()
        }
        subscription0.unsubscribe()
      }
    })
  }
  loadBaseNutritionFactWithMistake() {
    this.baseNutritionFactSaved = false
    var subscription0 = this.userService.loadNewBaseNutritionFactWithMistake().subscribe((result1) => {
      if (result1) {
        this.baseNutritionFact = result1
        subscription0.unsubscribe()
      }
    })
  }

  saveBaseNutritionFact() {
    if (!this.baseNutritionFact.id || this.baseNutritionFact.calories == null || this.baseNutritionFact.carbohydrates == null || this.baseNutritionFact.protein == null || this.baseNutritionFact.fat == null || (this.baseNutritionFact.barcode && ('brc' + this.baseNutritionFact.barcode != this.baseNutritionFact.id))) return
    this.baseNutritionFact.synonymsDe = this.tmpSynonymsDe?.split(';') || null
    this.userService.saveBaseNutritionFact(this.baseNutritionFact).then(() => {
      this.baseNutritionFactSaved = true
      if (this.baseNutritionFact.id.startsWith('bnd') || this.baseNutritionFact.id.startsWith('ntf')) {
        var subscriptionAdminSettings = this.userService.getAdminSettings().subscribe((document) => {
          var maxBaseNutritionFactBndId = document.maxBaseNutritionFactBndId
          var maxBaseNutritionFactNtfId = document.maxBaseNutritionFactNtfId
          if (this.baseNutritionFact.id.startsWith('bnd')) {
            var maxNumber = parseInt(maxBaseNutritionFactBndId.replace('bnd', ''))
            var thisNumber = parseInt(this.baseNutritionFact.id.replace('bnd', ''))
            if (thisNumber > maxNumber) {
              this.userService.updateMaxBaseNutritionFactBndId(this.baseNutritionFact.id)
            }
          } else if (this.baseNutritionFact.id.startsWith('ntf')) {
            var maxNumber = parseInt(maxBaseNutritionFactNtfId.replace('ntf', ''))
            var thisNumber = parseInt(this.baseNutritionFact.id.replace('ntf', ''))
            if (thisNumber > maxNumber) {
              this.userService.updateMaxBaseNutritionFactNtfId(this.baseNutritionFact.id)
            }
          }
          subscriptionAdminSettings.unsubscribe()
        })
      }
    })
  }
  deleteBaseNutritionFactInquiry() {
    if (!this.baseNutritionFactInquiry.fromOFF) {
      this.userService.deleteBaseNutritionFactInquiry(this.baseNutritionFactInquiry).then(() => {
        this.baseNutritionFact = null
        this.baseNutritionFactInquiry = null
        this.loadNewBaseNutritionFactInquiry()
      })
    } else {
      this.userService.deleteBaseNutritionFactInquiryOFF(this.baseNutritionFactInquiry).then(() => {
        this.baseNutritionFact = null
        this.baseNutritionFactInquiry = null
        this.loadNewBaseNutritionFactInquiryOFF()
      })
    }
  }
  createNutritionFactFromInquiry() {
    this.baseNutritionFact = new BaseNutritionFact(this.baseNutritionFactInquiry)
    BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
      if (this.baseNutritionFactInquiry.getNutritionalValue(nutritionalValue) != null) {
        this.baseNutritionFact.setNutritionalValue(nutritionalValue, this.baseNutritionFactInquiry.getNutritionalValue(nutritionalValue))
      } else {
        this.baseNutritionFact.setNutritionalValue(nutritionalValue, null)
      }
    })
    if (this.baseNutritionFact.barcode) {
      this.baseNutritionFact.id = 'brc' + this.baseNutritionFact.barcode
    } else {
      this.baseNutritionFact.id = null
    }
    this.baseNutritionFact.valid = true
    this.baseNutritionFactSaved = false
  }
  clearBaseNutritionFact() {
    this.baseNutritionFact = new BaseNutritionFact()
    this.baseNutritionFact.valid = true
    this.baseNutritionFactSaved = false
  }
  generateBrandedBnfId() {
    var subscriptionAdminSettings = this.userService.getAdminSettings().subscribe((document) => {
      var maxBaseNutritionFactBndId = document.maxBaseNutritionFactBndId
      var number = parseInt(maxBaseNutritionFactBndId.replace('bnd', ''))
      this.baseNutritionFact.id = 'bnd' + (number + 1)
      subscriptionAdminSettings.unsubscribe()
    })
  }
  generateCommonBnfId() {
    var subscriptionAdminSettings = this.userService.getAdminSettings().subscribe((document) => {
      var maxBaseNutritionFactNtfId = document.maxBaseNutritionFactNtfId
      var number = parseInt(maxBaseNutritionFactNtfId.replace('ntf', ''))
      this.baseNutritionFact.id = 'ntf' + (number + 1)
      subscriptionAdminSettings.unsubscribe()
    })
  }
  onDeleteBaseNutritionFact() {
    document.getElementById('delete-basenutritionfact-dialog').classList.toggle('show');
  }
  onConfirmBaseNutritionFactDeletion() {
    document.getElementById('delete-basenutritionfact-dialog').classList.toggle('show');
    this.userService.deleteBaseNutritionFact(this.baseNutritionFact)
    this.clearBaseNutritionFact()
  }
  onCancelBaseNutritionFactDeletion() {
    document.getElementById('delete-basenutritionfact-dialog').classList.toggle('show');
  }

  loadNewServingInquiry() {
    var subscription0 = this.userService.getNewServingInquiry().subscribe((result1) => {
      if (result1) {
        this.servingInquiry = result1
        if (this.servingInquiry.baseNutritionFactId.startsWith('fdb') || this.servingInquiry.baseNutritionFactId.startsWith('off') || this.servingInquiry.baseNutritionFactId.startsWith('brd')) {
          this.deleteServingInquiry()
        } else {
          var subscription1 = this.userService.getBaseNutritionFactById(this.servingInquiry.baseNutritionFactId, false).subscribe((result2) => {
            if (result2) {
              this.baseNutritionFact = result2
              subscription1.unsubscribe()
            }
          })
        }
        subscription0.unsubscribe()
      }
    })
    this.baseNutritionFactSaved = false
  }
  onServingInquiryChanged(text: string) {
    this.servingInquiry.servingSize = text;
  }
  onBaseNutritionFactServingSizesChanged(text: string) {
    this.baseNutritionFact.servingSizes = text
  }
  updateBaseNutritionFactAndDeleteServingInquiry() {
    this.userService.updateBaseNutritionFactServingSize(this.baseNutritionFact)
    this.userService.deleteServingInquiry(this.servingInquiry)
    this.baseNutritionFact = null
    this.servingInquiry = null
  }
  deleteServingInquiry() {
    this.userService.deleteServingInquiry(this.servingInquiry)
    this.baseNutritionFact = null
    this.servingInquiry = null
    this.loadNewServingInquiry()
  }
  getNutritionalValues() {
    return BaseNutritionFact.getNutritionalValues()
  }

  onImportOFFFact() {
    // Fetching HTML documents via https://allorigins.win/.
    var url = 'https://de.openfoodfacts.org/api/v0/product/' + this.baseNutritionFact.barcode + '.json'
    this.http.get(url).subscribe((response) => {
      console.log(response)
      if (response['status_verbose'] == 'product found') {
        var brands = response['product']['brands']
        var name = response['product']['product_name']
        var values = response['product']['nutriments']

        var calories = parseFloat(values['energy-kcal']?.toString())
        var carbohydrates = parseFloat(values['carbohydrates']?.toString())
        var protein = parseFloat(values['proteins']?.toString())
        var fat = parseFloat(values['fat']?.toString())
        var saturatedFat = parseFloat(values['saturated-fat']?.toString())
        var sugar = parseFloat(values['sugars']?.toString())

        if (calories >= 0) this.baseNutritionFact.setNutritionalValue('calories', calories)
        if (carbohydrates >= 0) this.baseNutritionFact.setNutritionalValue('carbohydrates', carbohydrates)
        if (protein >= 0) this.baseNutritionFact.setNutritionalValue('protein', protein)
        if (fat >= 0) this.baseNutritionFact.setNutritionalValue('fat', fat)
        if (saturatedFat >= 0) this.baseNutritionFact.setNutritionalValue('saturatedFat', saturatedFat)
        if (sugar >= 0) this.baseNutritionFact.setNutritionalValue('sugar', sugar)

        this.baseNutritionFact.brands = brands
        this.baseNutritionFact.nameDe = name
      }
    });

    return
    fetch(`https://api.allorigins.win/get?url=${encodeURIComponent('https://de.openfoodfacts.org/cgi/search.pl?search_terms=' + this.baseNutritionFact.barcode + '&search_simple=1&action=process' )}`)
    .then(response => {
      if (response.ok) return response.json()
    })
    .then(async data => {
      const parser = new DOMParser()
      const document = parser.parseFromString(data.contents, "text/html")
      var valueTypes = [
        ['Fett', 'fat'],
        ['GesättigteFettsäuren', 'saturatedFat'],
        ['Kohlenhydrate', 'carbohydrates'],
        ['Zucker', 'sugar'],
        ['Ballaststoffe', 'fibre'],
        ['Eiweiß', 'protein'],
        ['Salz', 'salt'],
      ]
      var panelFacts = document.getElementById('panel_nutrition_facts_table_content')
      var tableBody = panelFacts.children[0].children[0].getElementsByTagName('tbody')[0]
      for (var i = 0; i < tableBody.children.length; i++) {
        var row = tableBody.children[i]
        var identifier = row.children[0].children[0].textContent?.split(' ').join('').replace(/\t/g, '').replace(/(?:\r\n|\r|\n)/g, '')
        var rawValue = row.children[1].children[0].textContent?.split(' ').join('').replace(/\t/g, '').replace(/(?:\r\n|\r|\n)/g, '')

        if (identifier == 'Energie') {
          rawValue = rawValue.split('(')[1].replace(')', '')
          rawValue = rawValue.replace('mg', '').replace('kcal', '').split(' ').join('').replace(/\t/g, '').replace(/(?:\r\n|\r|\n)/g, '').replace(',', '.')
          var regex = new RegExp("^[0-9,.]+$");
          if (rawValue?.length > 0 && regex.test(rawValue) && parseFloat(rawValue) >= 0) {
            var number = parseFloat(rawValue)
            this.baseNutritionFact.setNutritionalValue('calories', number)
          }
        } else {
          valueTypes.forEach(pair => {
            if (identifier == pair[0]) {
              if (rawValue) {
                rawValue = rawValue.replace('mg', '').replace('g', '').replace('ml', '').replace('l', '').split(' ').join('').replace(/\t/g, '').replace(/(?:\r\n|\r|\n)/g, '').replace(',', '.')
                var regex = new RegExp("^[0-9,.]+$");
                if (rawValue?.length > 0 && regex.test(rawValue) && parseFloat(rawValue) >= 0) {
                  var number = parseFloat(rawValue)
                  this.baseNutritionFact.setNutritionalValue(pair[1], number)
                }
              }
            }
          })
        }
      }
      
      var rawBrands = document.getElementById('field_brands_value')
      if (rawBrands && rawBrands.children?.length > 0) {
        var brand = rawBrands.children[0].textContent
        this.baseNutritionFact.brands = brand
      }
    });
  }
  
  valueOfBaseNutritionFactChanged(nutritionalValue: string, text: string) {
    if (text) text = text.replace(',', '.')
    var regex = new RegExp("^[0-9,.]+$");
    if (text?.length > 0 && regex.test(text) && parseFloat(text) >= 0) {
      var number = parseFloat(text)
      this.baseNutritionFact.setNutritionalValue(nutritionalValue, number)
    } else {
      this.baseNutritionFact.setNutritionalValue(nutritionalValue, null)
    }
  }
  valueOfBaseNutritionFactInquiryChanged(nutritionalValue: string, text: string) {
    if (text) text = text.replace(',', '.')
    var regex = new RegExp("^[0-9,.]+$");
    if (text?.length > 0 && regex.test(text) && parseFloat(text) >= 0) {
      var number = parseFloat(text)
      this.baseNutritionFactInquiry.setNutritionalValue(nutritionalValue, number)
    } else {
      this.baseNutritionFactInquiry.setNutritionalValue(nutritionalValue, null)
    }
  }

  onDeleteNutritionFactSearch() {
    (<HTMLInputElement> document.getElementById('nutritionfact-search-box').getElementsByClassName('ais-SearchBox-input')[0]).value = ''
    this.nutritionFactSearchFocused = false
  }
  onNutritionFactSearchClicked() {
    this.nutritionFactSearchFocused = true;
  }
  onNutritionFactSelected(hit: any) {
    this.nutritionFactSearchFocused = false;
    this.baseNutritionFact = new BaseNutritionFact((hit as any) as BaseNutritionFact);
    this.tmpSynonymsDe = this.baseNutritionFact.synonymsDe?.join(';')
    console.log(hit)
    BaseNutritionFact.getNutritionalValues().forEach(nutritionalValue => {
      if (hit[nutritionalValue] != null && hit[nutritionalValue] != undefined) {
        this.baseNutritionFact.setNutritionalValue(nutritionalValue, parseFloat(hit[nutritionalValue]))
      }
    });
    console.log(this.baseNutritionFact);
    (<HTMLInputElement> document.getElementById('nutritionfact-search-box').getElementsByClassName('ais-SearchBox-input')[0]).value = this.baseNutritionFact.nameDe
  }
  onAddServingToBaseNutritionFact(text: string) {
    if (this.baseNutritionFact?.servingSizes) {
      this.baseNutritionFact.servingSizes = this.baseNutritionFact.servingSizes + ';' + text
    } else {
      this.baseNutritionFact.servingSizes = text
    }
  }

  idOfBaseNutritionFactChanged(text: string) {
    if (text?.length > 0) {
      this.baseNutritionFact.id = text
    } else {
      this.baseNutritionFact.id = null
    }
  }
  barcodeOfBaseNutritionFactChanged(text: string) {
    if (text?.length > 0) {
      this.baseNutritionFact.barcode = text
    } else {
      this.baseNutritionFact.barcode = null
    }
  }
  nameDeOfBaseNutritionFactChanged(text: string) {
    if (text?.length > 0) {
      this.baseNutritionFact.nameDe = text
    } else {
      this.baseNutritionFact.nameDe = null
    }
  }
  nameEnOfBaseNutritionFactChanged(text: string) {
    if (text?.length > 0) {
      this.baseNutritionFact.nameEn = text
    } else {
      this.baseNutritionFact.nameEn = null
    }
  }
  brandsOfBaseNutritionFactChanged(text: string) {
    if (text?.length > 0) {
      this.baseNutritionFact.brands = text
    } else {
      this.baseNutritionFact.brands = null
    }
  }
  synonymsDeOfBaseNutritionFactChanged(text: string) {
    if (text?.length > 0) {
      this.tmpSynonymsDe = text
    } else {
      this.tmpSynonymsDe = null
    }
  }
  servingSizesOfBaseNutritionFactChanged(text: string) {
    if (text?.length > 0) {
      this.baseNutritionFact.servingSizes = text
    } else {
      this.baseNutritionFact.servingSizes = null
    }
  }
  unitOfBaseNutritionFactChanged(text: string) {
    if (text?.length > 0 && text == 'g' || text == 'ml') {
      this.baseNutritionFact.unit = text
    } else {
      this.baseNutritionFact.unit = null
    }
  }
  regionOfBaseNutritionFactChanged(text: string) {
    if (text?.length > 0) {
      this.baseNutritionFact.region = text
    } else {
      this.baseNutritionFact.region = null
    }
  }
  validOfBaseNutritionFactChanged(checked: boolean) {
    this.baseNutritionFact.valid = checked
  }
  nutriScoreOfBaseNutritionFactChanged(text: string) {
    var regex = new RegExp("^[0-9]+$");
    if (text?.length > 0 && regex.test(text) && parseInt(text)) {
      var number = parseInt(text)
      this.baseNutritionFact.nutriScore = number
    } else {
      this.baseNutritionFact.nutriScore = null
    }
  }
  nutriScoreGradeOfBaseNutritionFactChanged(text: string) {
    if (text?.length > 0) {
      this.baseNutritionFact.nutriScoreGrade = text
    } else {
      this.baseNutritionFact.nutriScoreGrade = null
    }
  }
  offCategory1OfBaseNutritionFactChanged(text: string) {
    if (text?.length > 0) {
      this.baseNutritionFact.offCategory1 = text
    } else {
      this.baseNutritionFact.offCategory1 = null
    }
  }
  offCategory2OfBaseNutritionFactChanged(text: string) {
    if (text?.length > 0) {
      this.baseNutritionFact.offCategory2 = text
    } else {
      this.baseNutritionFact.offCategory2 = null
    }
  }
  imageUrlOfBaseNutritionFactChanged(text: string) {
    if (text?.length > 0) {
      this.baseNutritionFact.imageUrl = text
    } else {
      this.baseNutritionFact.imageUrl = null
    }
  }

  
  idOfBaseNutritionFactInquiryChanged(text: string) {
    if (text?.length > 0) {
      this.baseNutritionFactInquiry.id = text
    } else {
      this.baseNutritionFactInquiry.id = null
    }
  }
  barcodeOfBaseNutritionFactInquiryChanged(text: string) {
    if (text?.length > 0) {
      this.baseNutritionFactInquiry.barcode = text
    } else {
      this.baseNutritionFactInquiry.barcode = null
    }
  }
  nameDeOfBaseNutritionFactInquiryChanged(text: string) {
    if (text?.length > 0) {
      this.baseNutritionFactInquiry.nameDe = text
    } else {
      this.baseNutritionFactInquiry.nameDe = null
    }
  }
  nameEnOfBaseNutritionFactInquiryChanged(text: string) {
    if (text?.length > 0) {
      this.baseNutritionFactInquiry.nameEn = text
    } else {
      this.baseNutritionFactInquiry.nameEn = null
    }
  }
  brandsOfBaseNutritionFactInquiryChanged(text: string) {
    if (text?.length > 0) {
      this.baseNutritionFactInquiry.brands = text
    } else {
      this.baseNutritionFactInquiry.brands = null
    }
  }
  servingSizesOfBaseNutritionFactInquiryChanged(text: string) {
    if (text?.length > 0) {
      this.baseNutritionFactInquiry.servingSizes = text
    } else {
      this.baseNutritionFactInquiry.servingSizes = null
    }
  }
  unitOfBaseNutritionFactInquiryChanged(text: string) {
    if (text?.length > 0 && text == 'g' || text == 'ml') {
      this.baseNutritionFactInquiry.unit = text
    } else {
      this.baseNutritionFactInquiry.unit = null
    }
  }
  regionOfBaseNutritionFactInquiryChanged(text: string) {
    if (text?.length > 0) {
      this.baseNutritionFactInquiry.region = text
    } else {
      this.baseNutritionFactInquiry.region = null
    }
  }
  validOfBaseNutritionFactInquiryChanged(checked: boolean) {
    this.baseNutritionFactInquiry.valid = checked
  }
  nutriScoreOfBaseNutritionFactInquiryChanged(text: string) {
    var regex = new RegExp("^[0-9]+$");
    if (text?.length > 0 && regex.test(text) && parseInt(text)) {
      var number = parseInt(text)
      this.baseNutritionFactInquiry.nutriScore = number
    } else {
      this.baseNutritionFactInquiry.nutriScore = null
    }
  }
  nutriScoreGradeOfBaseNutritionFactInquiryChanged(text: string) {
    if (text?.length > 0) {
      this.baseNutritionFactInquiry.nutriScoreGrade = text
    } else {
      this.baseNutritionFactInquiry.nutriScoreGrade = null
    }
  }
  offCategory1OfBaseNutritionFactInquiryChanged(text: string) {
    if (text?.length > 0) {
      this.baseNutritionFactInquiry.offCategory1 = text
    } else {
      this.baseNutritionFactInquiry.offCategory1 = null
    }
  }
  offCategory2OfBaseNutritionFactInquiryChanged(text: string) {
    if (text?.length > 0) {
      this.baseNutritionFactInquiry.offCategory2 = text
    } else {
      this.baseNutritionFactInquiry.offCategory2 = null
    }
  }
  imageUrlOfBaseNutritionFactInquiryChanged(text: string) {
    if (text?.length > 0) {
      this.baseNutritionFactInquiry.imageUrl = text
    } else {
      this.baseNutritionFactInquiry.imageUrl = null
    }
  }

}


/*

{
  "fields": [
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": ".*",
      "optional": true,
      "sort": false,
      "type": "auto"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "alanine",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "alcohol",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "alphaLinolenicAcid",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "arachidonicAcid",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "arginine",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "asparagine",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "brands",
      "optional": true,
      "sort": false,
      "type": "string"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "calcium",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "calories",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "carbohydrates",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "chlorine",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "cholesterol",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "copper",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "cysteine",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "detailed",
      "optional": true,
      "sort": true,
      "type": "bool"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "disaccharides",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "docosahexaenoicAcid",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "eicosapentaenoicAcid",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "essentialAminoAcids",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "fat",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "fibre",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "fluorine",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "fromBLS",
      "optional": true,
      "sort": true,
      "type": "bool"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "fructose",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "galactose",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "glucose",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "glutamicAcid",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "glycine",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "glycogen",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "histidine",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "iodine",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "iron",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "isoleucine",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "lactose",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "lastChangeDate",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "leucine",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "linoleicAcid",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "lysine",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "magnesium",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "maltose",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "manganese",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "mannitol",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "methionine",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "minerals",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "monosaccharides",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "monounsaturatedFat",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "nameDe",
      "optional": true,
      "sort": false,
      "type": "string"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "nameEn",
      "optional": true,
      "sort": false,
      "type": "string"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "nonessentialAminoAcids",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "oligosaccharides",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "omega3Fat",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "omega6Fat",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "organicAcids",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "phenylalanine",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "phosphorus",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "polyols",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "polysaccharides",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "polyunsaturatedFat",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "potassium",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "proline",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "protein",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "purine",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "region",
      "optional": true,
      "sort": false,
      "type": "string"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "salt",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "saturatedFat",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "serine",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "servingSizes",
      "optional": true,
      "sort": false,
      "type": "string"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "sodium",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "sorbitol",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "starch",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "sucrose",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "sugar",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "sulphur",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "synonymsDe",
      "optional": true,
      "sort": false,
      "type": "string[]"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "threonine",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "tryptophan",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "tyrosine",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "uricAcid",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "valid",
      "optional": true,
      "sort": true,
      "type": "bool"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "valine",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "vitaminA",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "vitaminABetaCarotene",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "vitaminARetinol",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "vitaminB1",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "vitaminB12",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "vitaminB2",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "vitaminB3",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "vitaminB3Niacin",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "vitaminB5",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "vitaminB6",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "vitaminB7",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "vitaminB9",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "vitaminC",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "vitaminD",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "vitaminE",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "vitaminEAlphaTocopherol",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "vitaminK",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "water",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "waterInsolubleFibre",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "waterSolubleFibre",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "xylitol",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "zinc",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "unit",
      "optional": true,
      "sort": false,
      "type": "string"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "transFat",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "barcode",
      "optional": true,
      "sort": false,
      "type": "string"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "imageUrl",
      "optional": true,
      "sort": false,
      "type": "string"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "nutriScore",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "nutriScoreGrade",
      "optional": true,
      "sort": false,
      "type": "string"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "offCategory1",
      "optional": true,
      "sort": false,
      "type": "string"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "offCategory2",
      "optional": true,
      "sort": false,
      "type": "string"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "alcoholContent",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "density",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "foodCategoryId",
      "optional": true,
      "sort": true,
      "type": "int64"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "name",
      "optional": true,
      "sort": false,
      "type": "string"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "shape",
      "optional": true,
      "sort": false,
      "type": "string"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "selenium",
      "optional": true,
      "sort": true,
      "type": "float"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "nameTranslation.de",
      "optional": true,
      "sort": false,
      "type": "string"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "nameTranslation.en",
      "optional": true,
      "sort": false,
      "type": "string"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "brand",
      "optional": true,
      "sort": false,
      "type": "string"
    },
    {
      "facet": false,
      "index": true,
      "infix": false,
      "locale": "",
      "name": "saturated_fat",
      "optional": true,
      "sort": true,
      "type": "float"
    }
  ]
}
*/