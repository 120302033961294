<div class="dialog-topbar">
    <div class="icon-button float-right" (click)="onCloseDialog()" translate>
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
        Schließen
    </div>
</div>
<div class="dialog-content-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="spacer-10"></div>
            <input id="templatesearch-input" class="input wide"
                (input)="onSessionTemplateSearchInputChanged($event.target.value)" [placeholder]="'Vorlagen durchsuchen' | translate"
                type="text">
            <div class="icon-button deletesearch-icon delete-recipesearch"
                (click)="onDeleteSessionTemplateSearchInput()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>
            <div class="spacer-25"></div>
        </div>
    </div>
    <div class="row">
        <div *ngIf="availableTemplateFolders?.length > 0" class="col-12">
            <span translate>Vorlagen-Ordner</span><br>
            <div class="d-inline-block wrapper-selection">
                <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownRepetion"
                    data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">{{selectedTemplateFolder?.name?.GetValue(translate.currentLang) ?? ("Alle" | translate)}}</button>
                <div class="dropdown-menu" aria-labelledby="dropdownBodyDataGraphTimeRange">
                    <button class="dropdown-item" type="button"
                            (click)="onSelectedTemplateFolderChanged(null)" translate>Alle</button>
                    <div class="dropdown-divider"></div>
                    <div *ngFor="let folder of availableTemplateFolders">
                        <button class="dropdown-item" type="button"
                            (click)="onSelectedTemplateFolderChanged(folder)">{{folder?.name?.GetValue(translate.currentLang)}}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="spacer-25"></div>
    </div>
    <div *ngIf="trainingService.trainingPlanTemplates?.length > 0" class="row">
        <div *ngFor="let template of filteredTrainingPlanTemplates" class="col-12">
            <ng-container
                *ngIf="template.getAvailableSessionsWithoutRestDays()?.length > 0 && ((selectedSessionTemplateTab === 'OWN' && template.licenceHolderUid !== 'nutrilize') || (selectedSessionTemplateTab === 'NUTRILIZE' && template.licenceHolderUid === 'nutrilize'))">
                <b>{{template.nameTranslation.GetValue(translate.currentLang) ?? template.getName()}}</b>
                <div class="label" *ngIf="template.subNameTranslation.GetValue(translate.currentLang) || template.subNameTranslation.GetValue('de')">
                    {{template.nameTranslation.GetValue(translate.currentLang) ?? template.subNameTranslation.GetValue('de')}}</div>
                <div class="layout-box mt-2" *ngIf="!template.isPeriodicPlan; else periodicTemplate">
                    <ng-container *ngFor="let session of template.sessions">
                        <div class="row layout-box sub-box link" *ngIf="!session.isRestDay && !session.deleted"
                            (click)="selectSessionTemplate(session, template)">
                            <div *ngIf="!weekSelectionMode" class="checkbox-wrapper">
                                <input class="input checkbox" type="checkbox"
                                    [checked]="selectedSessions.includes(session)">
                            </div>
                            <div class="col">
                                {{session.getInternalOrRealPublicName(translate)}}
                            </div>
                        </div>
                    </ng-container>
                </div>
                <ng-template #periodicTemplate>
                    <ng-container *ngFor="let week of template.weeks; let i = index;">
                        <div class="layout-box mt-2" *ngIf="template.getSessionsByWeekId(week.id)?.length > 0">
                            <div *ngIf="weekSelectionMode" class="checkbox-wrapper mb-3">
                                <div class="row link" (click)="selectWeek(week, template)">
                                    <div class="checkbox-wrapper">
                                        <input class="input checkbox" type="checkbox"
                                            [checked]="isWeekSelected(week, template)">
                                    </div>
                                    <div class="col">
                                        {{week.name.GetValue(translate.currentLang) ?? ("Woche" | translate) + " " + (i+1)}}
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!weekSelectionMode" class="label link" (click)="toggleMenu(week)">
                                <ng-container *ngIf="week == expandedWeek; else settingsCollapsed">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                                        class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </ng-container>
                                <ng-template #settingsCollapsed>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                                        class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                        <path
                                            d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                    </svg>
                                </ng-template>
                                <span translate>Woche</span> {{i+1}}
                            </div>
                            <ng-container *ngIf="weekSelectionMode || week == expandedWeek">
                                <ng-container *ngFor="let session of template.getSessionsByWeekId(week.id)">
                                    <div class="row layout-box sub-box link"
                                        *ngIf="!session.isRestDay && !session.deleted"
                                        (click)="selectSessionTemplate(session, template)">
                                        <div *ngIf="!weekSelectionMode" class="checkbox-wrapper">
                                            <input class="input checkbox" type="checkbox"
                                                [checked]="selectedSessions.includes(session)">
                                        </div>
                                        <div class="col">
                                            {{session.getInternalOrRealPublicName(translate)}}
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-template>
            </ng-container>
        </div>
    </div>
    <div class="spacer-50"></div>
</div>
<div class="dialog-bottombar" mat-dialog-actions>
    <div class="label col-12">
        <span *ngFor="let session of selectedSessions; let isLast=last">
            {{session.getInternalOrRealPublicName(translate)}}{{isLast ? '' : ', '}}
        </span>
        <span *ngFor="let selectedWeek of selectedWeeks; let isLast=last">
            {{selectedWeek.trainingPlan?.nameTranslation?.GetValue(translate.currentLang)}}-{{selectedWeek.week.name?.GetValue(translate.currentLang)
            ?? ("Woche" | translate)}}{{isLast ? '' : ', '}}
        </span>
    </div>
    <button *ngIf="selectedSessions?.length > 0 || selectedWeeks?.length > 0"
        class="button-filled small create-Exercise" (click)="onTakeSelection()" translate>Auswahl übernehmen</button>
</div>
<ngx-spinner name="sessionTemplateSpinner" bdColor="rgba(0,0,0,0.33)" size="default" color="var(--accentColor)"
    type="ball-clip-rotate" [fullScreen]="true"></ngx-spinner>