
<div class="dialog-topbar">
    <div *ngIf="!isAutoFillMode && canEdit" class="icon-button" (click)="onSaveConfig()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
        {{isDuplicationMode ? 'Duplizieren' : 'Speichern'}}
    </div>
    <div *ngIf="isAutoFillMode" class="icon-button" (click)="onConfirmAutoFill()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
        Fertigstellen
    </div>
    <div class="icon-button float-right" (click)="onCloseConfig()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        Schließen
    </div>
    <div *ngIf="!isAutoFillMode && canEdit && canDelete" class="icon-button float-right delete-plannedmeal" (click)="onDeleteConfig()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
        Löschen
    </div>
    <div *ngIf="!isAutoFillMode && canEdit && !canDelete" class="icon-button float-right delete-plannedmeal" (click)="onDeleteConfig()" tooltip="Aktive Einstellungen können beendet, aber nicht rückwirkend gelöscht werden." tooltipPlacement="bottom">
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-slash-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M11.354 4.646a.5.5 0 0 0-.708 0l-6 6a.5.5 0 0 0 .708.708l6-6a.5.5 0 0 0 0-.708z"/>
        </svg>
        Beenden
    </div>
</div>
<div class="dialog-content-wrapper">
    
    <div *ngIf="config">
        <app-nutrition-config-editor [user]="user" [(config)]="config" [expertMode]="true"></app-nutrition-config-editor>
    </div>

    <div class="spacer-25"></div>

    <div *ngIf="!hasRecipesInDatabase() && planConfig">
        <div class="layout-box turquoise">
            <h3>Achtung</h3>
            Deine Rezeptdatenbank enthält noch keine oder wenige Rezepte. Im Menü unter Rezepte kannst du über den Button Rezeptdatenbank auf unsere Rezepte zugreifen und diese in deine eigene Rezeptsammlung übernehmen.
        </div>
    </div>
    <div *ngIf="isAutoFillMode && planConfig.endDate == null && !planConfig.selectedNutritionPlan.isRepeating">
        <div class="layout-box turquoise">
            <h3>Achtung</h3>
            Du hast kein Enddatum für den Plan festgelegt. Es werden aber nur die nächsten 31 Tage automatisch durch den AutoFill-Algorithmus befüllt.
        </div>
    </div>

    <div *ngIf="planConfig" class="col-12">
        <ng-container *ngFor="let plan of planConfig.getNutritionPlans(); let i = index">
            <button class="tab-button {{planConfig.selectedNutritionPlan == plan ? ' active' : ''}}" (click)="onSelectPlan(plan)">
                {{plan.getName()}}
                <div *ngIf="i != 0" class="icon-button display-inlineblock marginleft-5" tooltip="Alternativen Plan löschen" (click)="onDeletePlan(plan);$event.stopPropagation()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                    </svg>
                </div>
            </button>
        </ng-container>
        <button *ngIf="!isAutoFillMode" class="tab-button button-addsituation" (click)="onAddAdditionalPlan()" tooltip="Alternativen Plan erstellen">
            +
        </button>
        <div class="spacer-25"></div>
    </div>
    <div *ngIf="planConfig && !isAutoFillMode" class="col-6">
        <input id="input-mealname" class="input plannedmeal-name" [(ngModel)]="planConfig.selectedNutritionPlan.name" placeholder="Name" type="text">
    </div>
    <div *ngIf="planConfig" class="col-12">
        <div class="spacer-10"></div>
        
        <div *ngIf="!isAutoFillMode">
            Start: 
            <span class="marginleft-10">{{planConfig.startDate?.asFormatedString()}}</span>
            <input [matDatepicker]="startDatePicker" [value]="planConfig?.startDate" [matDatepickerFilter]="startDateFilterNutritionPlanConfig" class="visibility-hidden width-0 padding-0" (dateInput)="onStartDateChanged($event.value?.toDate())">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker ></mat-datepicker><br>
            
            Ende: 
            <span class="marginleft-10">{{planConfig.endDate?.asFormatedString()}}</span>
            <input [matDatepicker]="endDatePicker" [value]="planConfig?.endDate" [matDatepickerFilter]="endDateFilterNutritionPlanConfig" class="visibility-hidden width-0 padding-0" (dateInput)="onEndDateChanged($event.value?.toDate())">
            <span *ngIf="!planConfig.endDate">unbegrenzt</span>
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker ></mat-datepicker>
            <div *ngIf="planConfig.endDate && canRemoveEndDate()" class="icon-button display-inlineblock marginleft-25" (click)="onRemoveEndDate()">
                Enddatum entfernen
            </div>

            <div *ngIf="!isDuplicationMode">
                <div class="spacer-10"></div>
                <input class="input checkbox" type="checkbox" [checked]="planConfig.selectedNutritionPlan.isRepeating" (change)="onIsRepeatingChanged($event.target.checked)"> Sich wiederholender Plan<br>
                <div *ngIf="planConfig.selectedNutritionPlan.isRepeating"><input type="number" class="small" [(ngModel)]="planConfig.selectedNutritionPlan.repetitionDuration" min="1"> Tage planen</div>
            </div>
            <div class="spacer-50"></div>
        </div>
        <div *ngIf="isAutoFillMode && allowFillOnlySelectedDays">
            <input class="input checkbox" type="checkbox" [(ngModel)]="autoFillOnlySelectedDays"> Nur markierte Tage befüllen
            <div class="spacer-50"></div>
        </div>

        <div *ngIf="possibleSituations.length > 1 && planConfig.selectedNutritionPlan != planConfig.nutritionPlans[0]">
            Plan mit Situation verknüpfen:<br>
            <div class="spacer-10"></div>
            <div class="d-inline-block wrapper-mealtype-selection">
                <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownMealType" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getSituationById(planConfig.selectedNutritionPlan?.connectedSituationId)?.getName() || 'Situation auswählen'}}</button>
                <div class="dropdown-menu" aria-labelledby="dropdownMealType">
                    <div *ngFor="let situation of possibleSituations">
                        <button class="dropdown-item" type="button" (click)="onConnectSituation(situation)">{{situation.getName()}}</button>
                    </div>
                </div>
            </div>
            <div class="spacer-25"></div>
        </div>

        <div class="bold">Rezepte vorauswählen</div>
        <div class="spacer-10"></div>
        <div class="input margin-0" id="tagsearch-input-wrapper" (click)="onFocusRestrictionSearchInput(-1);tagInput.focus();$event.stopPropagation()">
            <ng-container *ngFor="let tag of planConfig.selectedNutritionPlan.tagRestrictions">
                <div class="chip white tag {{tag?.isExcluding ? ' excluding' : ' including'}}">
                    {{tag.getPrintableName()}}
                    <div class="delete-tag" (click)="onRemoveTagFromMealConfig(tag, null);$event.stopPropagation()">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>
            </ng-container>
            <input #tagInput id="tagsearch-input" class="input tag plain restrictionsearch-input" (input)="onRecipeTagSearchInputChanged($event.target.value, -1);$event.stopPropagation()" placeholder="Rezepte einschränken" type="text">
        </div>
        <div id="tagsearch-results" class="layout-box sub-box margin-0" *ngIf="planConfig.selectedNutritionPlan.tagSearchResults?.length > 0 && planConfig.selectedNutritionPlan.searchInputFocused">
            <div *ngFor="let restriction of planConfig.selectedNutritionPlan.tagSearchResults" class="search-result-item">
                <div class="row">
                    <div class="col-4 col-lg-3">
                        {{restriction.getPrintableName()}}
                    </div>
                    <div class="col-8 col-lg-7">
                        <div class="">
                            <div class="label link display-inlineblock marginleft-25" (click)="onRecipeTagSearchResultSelected(restriction, -1);tagInput.value = '';tagInput.focus()">Einschließen</div>
                            <div class="label link display-inlineblock marginleft-25" (click)="onRecipeTagSearchResultSelected(restriction, -1, true);tagInput.value = '';tagInput.focus()">Ausschließen</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="spacer-25"></div>
        <div class="bold">Mahlzeitentypen anlegen</div>
        <div class="spacer-25"></div>
        <div cdkDropList class="example-list" (cdkDropListDropped)="dropMealConfig($event)">
            <ng-container *ngFor="let mealConfig of planConfig.selectedNutritionPlan.mealConfigs; index as i">
                <div class="mealconfig-wrapper" cdkDrag>
    
                    <div class="drag-handle link" cdkDragHandle>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-grip-vertical" viewBox="0 0 16 16">
                            <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                        </svg>
                    </div>
                    <div class="d-inline-block wrapper-mealtype-selection">
                        <button class="button-filled light small dropdown-toggle margin-inbetween {{mealConfig.mealType == null ? ' button-invalid' : ''}}" type="button" id="dropdownMealType" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{mealTypeTranslations[mealConfig.mealType]}}</button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMealType">
                            <div *ngFor="let mealType of mealTypes">
                                <button class="dropdown-item" type="button" (click)="onMealTypeSelected(mealConfig, mealType)">{{mealTypeTranslations[mealType]}}</button>
                            </div>
                        </div>
                    </div>
    
                    <input *ngIf="mealConfig.mealType == 'CUSTOM'" class="input mealconfig small wide" [(ngModel)]="mealConfig.customMealType" type="text" placeholder="Mahlzeitentyp"/>

                    <div *ngIf="isAutoFillMode" class="display-inlineblock marginleft-25">
                        <input class="input checkbox" type="checkbox" [(ngModel)]="mealConfig.autoFillEnabled"> AutoFill anwenden
                        <div *ngIf="mealConfig.autoFillEnabled" class="display-inlineblock marginleft-25"><input type="number" class="small" min="0" [(ngModel)]="mealConfig.autoFillAlternatives"> Alternativen einplanen</div>
                    </div>
    
                    <div class="icon-button marginleft-25 float-right" (click)="onRemoveMealConfig(mealConfig)">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                        Entfernen
                    </div>
                    <br>

                    <div class="input" id="tagsearch-input-wrapper" (click)="onFocusRestrictionSearchInput(i);tagInput.focus();$event.stopPropagation()">
                        <ng-container *ngFor="let tag of mealConfig.tagRestrictions">
                            <div class="chip white tag color-black {{tag.isExcluding ? ' excluding' : ' including'}}">
                                {{tag.getPrintableName()}}
                                <div class="delete-tag" (click)="onRemoveTagFromMealConfig(tag, mealConfig);$event.stopPropagation()">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                </div>
                            </div>
                        </ng-container>
                        <input #tagInput id="tagsearch-input" class="input tag plain restrictionsearch-input" (input)="onRecipeTagSearchInputChanged($event.target.value, i);$event.stopPropagation()" placeholder="Rezepte einschränken" type="text">
                    </div>
                    <div id="tagsearch-results" class="layout-box sub-box" *ngIf="mealConfig.tagSearchResults?.length > 0 && mealConfig.searchInputFocused">
                        <div *ngFor="let restriction of mealConfig.tagSearchResults" class="search-result-item">
                            <div class="row">
                                <div class="col-4 col-lg-3">
                                    {{restriction.getPrintableName()}}
                                </div>
                                <div class="col-8 col-lg-7">
                                    <div class="">
                                        <div class="label link display-inlineblock marginleft-25" (click)="onRecipeTagSearchResultSelected(restriction, i);tagInput.value = '';tagInput.focus()">Einschließen</div>
                                        <div class="label link display-inlineblock marginleft-25" (click)="onRecipeTagSearchResultSelected(restriction, i, true);tagInput.value = '';tagInput.focus()">Ausschließen</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isAutoFillMode" class="label marginleft-25">{{mealConfig.matchingRecipes?.length}} passende Rezepte gefunden</div>
                    <div class="spacer-25"></div>
                </div>
            </ng-container>
        </div>
        <div class="spacer-10"></div>
        <div class="icon-button marginleft-25" (click)="onAddNewMealConfig()">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
            </svg>
            Weitere Mahlzeit hinzufügen
        </div>
        <div class="spacer-50"></div>

        <div class="bold">Mahlzeitengröße festlegen</div>
        <div class="spacer-25"></div>
        <nouislider *ngIf="nutritionPlanCalorieDistributionSliderConfig" [config]="nutritionPlanCalorieDistributionSliderConfig" [(ngModel)]="nutritionPlanCalorieDistributionSliderValues" (update)="onAutoFillSliderChanged()"></nouislider>
        <br>

        <div class="spacer-25"></div>
        <div class="bold">Genauigkeit</div>
        <div class="spacer-10"></div>
        <div class="mealsuggestion-settings">
            <div class="durationunit-selection-wrapper">
                <div class="d-inline-block wrapper-selection">
                    <button class="button-filled light small dropdown-toggle margin-inbetween" type="button" id="dropdownTrigger" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{mealSuggestionAccuracy == 'high' ? 'Hoch' : 'Standard'}}</button>
                    <div class="dropdown-menu" aria-labelledby="dropdownProduct">
                        <button class="dropdown-item" type="button" (click)="onSetMealSuggestionAccuracy('medium')">Standard</button>
                        <button class="dropdown-item" type="button" (click)="onSetMealSuggestionAccuracy('high')">Hoch</button>
                    </div>
                </div>
            </div>
            <div class="spacer-10"></div>
            <div *ngIf="mealSuggestionAccuracy == 'high'" class="label">
                Achtung: Bei höherer Genauigkeit werden weniger passende Rezepte gefunden.
            </div>
            <div class="spacer-10"></div>
            <input class="input checkbox" type="checkbox" [(ngModel)]="allowWeightAdjustment"><div class="display-inline"> Gewichtsanpassung der einzelnen Lebensmittel zulassen</div>
            <div class="icon-button marginleft-25" tooltip="Um die Nährstoffziele noch besser zu erreichen, können einzelne Lebensmittel des Rezepte unabhängig voneinander angepasst werden.">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                </svg>
            </div>
        </div>
    </div>
    <div class="spacer-50"></div>
</div>