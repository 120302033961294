<div class="layout-box sub-box container {{selectedTask?.id == task?.id ? 'active' : ''}}">
    <div class="row flex-nowrap">
        <div *ngIf="!task.done" class="col-auto checklist-column link" (click)="onCompleteTask.emit(task)" tooltip="Erledigen">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                </svg>
            </div>
            <div *ngIf="task.priority == 'high'">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-exclamation-lg mt-2" viewBox="0 0 16 16">
                    <path d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0L7.005 3.1ZM7 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0"/>
                </svg>
            </div>
        </div>
        <div *ngIf="task.done" class="col-auto checklist-column link" (click)="onUnCompleteTask.emit(task)" tooltip="Zurücksetzen">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#4AE6D7" viewBox="0 0 16 16" class="bi bi-check2-circle"><path _ngcontent-ng-c3458721007="" d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"></path><path _ngcontent-ng-c3458721007="" d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"></path></svg>
        </div>
        <div class="col task-details-column">
            <div class="row">
                <div class="col">
                    {{task.title}}
                </div>
            </div>
            <div class="row label">
                <div class="col">
                    {{task.description}}
                </div>
            </div>
            <div class="spacer-5"></div>
            <div class="row">
                <div class="col-auto label" *ngIf="task.dueDate">
                    <i class="bi bi-calendar-event"></i>
                    {{task.dueDate?.asShortFormatedString()}}
                </div>
                <div class="col-auto label link" *ngIf="task.connectedClientUid" routerLink="/client/{{task.connectedClientUid}}">
                    <i class="bi bi-person"></i>
                    {{getPrintableConnectedClientName(task)}}
                </div>
                <div class="col-auto label" *ngIf="task.connectedClientGroup">
                    <i class="bi bi-people"></i>
                    {{task.connectedClientGroup}}
                </div>
                <div class="col-auto label" *ngIf="task.tags?.length > 0">
                    <i class="bi bi-tags"></i>
                    {{getPrintableTags(task.tags, 3)}}
                </div>
            </div>
            <div class="row">
                <div class="sub-task-list">
                    <ng-container *ngFor="let subTask of task.subTasks">
                        <div *ngIf="!subTask.deleted" class="col sub-task-container {{subTask.done ? 'done' : ''}}">
                            <div class="spacer-5"></div>
                            <div class="row flex-nowrap">
                                <div *ngIf="!subTask.done" class="col-auto checklist-column link" (click)="onCompleteSubTask.emit(subTask)" tooltip="Erledigen">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    </svg>
                                </div>
                                <div *ngIf="subTask.done" class="col-auto checklist-column link" (click)="onUnCompleteSubTask.emit(subTask)" tooltip="Zurücksetzen">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#4AE6D7" viewBox="0 0 16 16" class="bi bi-check2-circle"><path _ngcontent-ng-c3458721007="" d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"></path><path _ngcontent-ng-c3458721007="" d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"></path></svg>
                                </div>
                                <div class="col text-column">
                                    <div class="row">
                                        <input type="text" (keydown.Tab)="onSaveAndAddSubTasks.emit(subTask); $event.stopPropagation()" (keyup.enter)="onSaveSubTasks.emit(task); $event.stopPropagation()"  (input)="enteredChanges()" class="white wide-input small task-title-input" placeholder="Titel eingeben" [(ngModel)]="subTask.title">
                                    </div>
                                </div>
                                <div class="d-flex" *ngIf="subTask.hasChanges">
                                    <div class="link margin-vertical-auto" (click)="onSaveSubTasks.emit(subTask); $event.stopPropagation()" tooltip="Speichern">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                        </svg>
                                    </div>
                                </div>
                                <div class="d-flex" *ngIf="!subTask.hasChanges">
                                    <div class="link margin-vertical-auto" (click)="onRemoveSubTask.emit(subTask); $event.stopPropagation()" tooltip="Löschen">
                                        <svg width="20" height="20" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="label link col-12 mt-1" (click)="onAddSubTask.emit(task); $event.stopPropagation()">
                        + Unteraufgabe hinzufügen
                    </div>
                </div>
            </div>
        </div>
        <button *ngIf="!disableEdit" class="task-menu" mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
          </svg>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onEditTask.emit(task)">
                <div class="icon">
                    <i class="bi bi-pencil"></i>
                </div>Bearbeiten
            </button>
            <button mat-menu-item (click)="onDeleteTask.emit(task)">
                <div class="icon">
                    <i class="bi bi-trash"></i>
                </div>Löschen
            </button>
            <button mat-menu-item (click)="onDuplicateTask.emit(task)">
                <div class="icon">
                    <i class="bi bi-files"></i>
                </div>Duplizieren
            </button>
        </mat-menu>
    </div>
</div>