<div class="container-left-align">
    <h3 class="display-inlineblock">Check-Ins</h3>
    <div class="icon-button marginleft-25 padding-4" (click)="onOpenQuestionaireResults()" [tooltip]="'Markiere die Check-Ins, die du vergleichen möchtest, mit einem Haken.' | translate" translate>
        Ausgewählte Check-Ins vergleichen
    </div>
    <div class="questionaires-container">
        <ng-container *ngFor="let dailyCondition of dailyConditions">
            <ng-container *ngFor="let questionaireResult of dailyCondition.questionaireResults">
                <ng-container *ngIf="questionaireResult.assignedQuestionaire?.completed; else pending_questionaire;">
                    <div class="row layout-box sub-box link"  (click)="onOpenQuestionaire(questionaireResult);$event.stopPropagation()">
                        <div class="checkbox-wrapper d-inline-block" *ngIf="questionaireResult?.canAccess(getCoach())" (click)="onQuestionaireResultSelected(questionaireResult);$event.stopPropagation()">
                            <input class="input checkbox margin-vertical-auto h-100" type="checkbox" [checked]="selectedQuestionaireResults.includes(questionaireResult)" [tooltip]="'Zum Vergleich hinzufügen' | translate">
                        </div>
                        <div class="col-8 col-xl-9">
                            <div class="row">
                                <div class="col-12">
                                    <b>{{questionaireResult.assignedQuestionaire.name}}</b>
                                    <div class="indicator small marginleft-10 display-inlineblock" *ngIf="questionaireResult.assignedQuestionaire.viewedByCoach == false" [tooltip]="'Du hast diesen Check-In noch nicht angesehen.' | translate" placement="right"></div>
                                </div>
                                <div class="col-12">
                                    {{(questionaireResult.assignedQuestionaire.completionDate | timezone:dailyCondition)?.asDateTimeFormatedString() || (questionaireResult.assignedQuestionaire.assignmentDate | timezone:dailyCondition)?.asDateTimeFormatedString()}}
                                </div>
                            </div>
                        </div>
                        <div class="col-3 col-xl-2">
                            <div class="row full-height" *ngIf="questionaireResult?.canAccess(getCoach())">
                                <div *ngIf="!utilityService.onSmallDisplay()" class="icon-button marginright-10 margin-vertical-auto" translate>
                                    Öffnen
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#4AE6D7" class="bi bi-check" viewBox="0 0 16 16">
                                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    
                </ng-container>
                <ng-template #pending_questionaire>
                    <div *ngIf="showAllQuestionaires" class="row layout-box sub-box">
                        <div class="checkbox-wrapper d-inline-block">
                        </div>
                        <div class="col-8 col-xl-9">
                            <div class="row">
                                <div class="col-12">
                                    <b>{{questionaireResult.assignedQuestionaire?.name}}</b>
                                </div>
                                <div class="col-12">
                                    {{(questionaireResult.assignedQuestionaire?.assignmentDate | timezone:dailyCondition)?.asDateTimeFormatedString()}} <span translate>(Ausstehend)</span>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row float-right full-height">
                                <div class="icon-button marginright-10 margin-vertical-auto" (click)="onFilloutQuestionaire(questionaireResult);$event.stopPropagation()" translate>
                                    Ausfüllen
                                </div>
                                <div class="icon-button margin-vertical-auto" (click)="onDeleteQuestionaire(questionaireResult);$event.stopPropagation()" translate>
                                    Löschen
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </ng-container>
    </div>
    <div class="icon-button marginleft-25" (click)="onShowAllQuestionaires()">
        <span *ngIf="!showAllQuestionaires; else show_all_questionaires;" translate>Ausstehende einblenden</span>
        <ng-template #show_all_questionaires><span translate>Ausstehende ausblenden</span></ng-template>
    </div>
</div>