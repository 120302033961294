export class PortalSettingsCoach {
    dashboardUids: string[];
    dashboardClientGroups: string[];
    clientGroups: string[]
    maxVersionNumberNewsDisplayed: number;
    pendingRedemptionLids: string[];
    lastRecipeDatabaseOpenDate: Date
    selectedEventLogTypes: string[]
    selectedBodyDataGraphTypNames: string[]
    migratedAppUserUids: string[];
    migratedPlanEndDateUids: string[]
    hideTutorialsHint: boolean
    hideCoachAppHint: boolean
    hiddenBodyDataTableColumns: string[]
    hiddenNutritionTableColumns: string[]
    showClientsInListView: boolean
    selectedChatFilterGroupNames: string[]
    selectedChatFilterCoachUids: string[]
    clientAnalyticsPageConfigs: any[]

    constructor();
    constructor(init: PortalSettingsCoach);
    constructor(init?: PortalSettingsCoach) {
        this.dashboardUids = init && init.dashboardUids || []
        this.dashboardClientGroups = init && init.dashboardClientGroups || []
        this.clientGroups = init && init.clientGroups || []
        this.maxVersionNumberNewsDisplayed = init && init.maxVersionNumberNewsDisplayed || -1;
        this.pendingRedemptionLids = init && init.pendingRedemptionLids || []
        this.lastRecipeDatabaseOpenDate = init && init.lastRecipeDatabaseOpenDate || null
        this.selectedEventLogTypes = init && init.selectedEventLogTypes || []
        this.selectedBodyDataGraphTypNames = init && init.selectedBodyDataGraphTypNames || []
        this.migratedAppUserUids = init && init.migratedAppUserUids || []
        this.hideTutorialsHint = init && init.hideTutorialsHint || false
        this.hideCoachAppHint = init && init.hideCoachAppHint || false
        this.migratedPlanEndDateUids = init && init.migratedPlanEndDateUids || []
        this.hiddenNutritionTableColumns = init && init.hiddenNutritionTableColumns || []
        this.showClientsInListView = init && init.showClientsInListView || false
        this.hiddenBodyDataTableColumns = init && init.hiddenBodyDataTableColumns || []
        this.selectedChatFilterGroupNames = init && init.selectedChatFilterGroupNames || []
        this.selectedChatFilterCoachUids = init && init.selectedChatFilterCoachUids || []
        this.clientAnalyticsPageConfigs = init && init.clientAnalyticsPageConfigs || []
    }
}