import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { NativeAudio } from '@capacitor-community/native-audio'
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnDestroy {


  // private audio: HTMLAudioElement = new Audio();
  constructor(utilityService: UtilityService) {
    // let soundFile = "assets/sounds/training_timer_sound.mp3"
    // this.audio.src = soundFile;
    // this.audio.load();

    if (utilityService.onNativeMobileApp()) {
      NativeAudio.configure({
        focus: false
      });
    }

    NativeAudio.preload({
      assetId: "beep",
      assetPath: "training_timer_sound.mp3",
      audioChannelNum: 1,
      isUrl: false
    });

    document.onvisibilitychange = () => {
      if (document.visibilityState === 'visible') {
        if(this.isTimerRunning()) {
          this.timer?.refresh();
        }
      }
    }
  }
  ngOnDestroy(): void {
    this.stopTimer();
    NativeAudio.unload({
      assetId: 'beep',
    });
  }


  public TimerMode = TimerMode;
  public mode: TimerMode = TimerMode.CountDown;
  private autoRun: boolean = false;

  private _inputTime: number = 0;
  @Input() set inputTime(value: number) {
    this.setTimerTime(value);
  }

  setTimerTime(time: number) {
    this._inputTime = time ?? 0;
    if (time === null) {
      this.mode = TimerMode.CountUp;
    }
    else {
      this.mode = TimerMode.CountDown;
    }
    this.resetTimer();
  }

  @Input() set forceReset(value: boolean) {
    if (value) {
      this.resetTimer();
      this.forceResetChange.emit(false);
    }
  }
  @Output() forceResetChange = new EventEmitter<boolean>();

  private time: number = 0;
  @Output() timeChange = new EventEmitter<number>();

  private _elapsedTime = 0;
  @Output() elapsedTimeChange = new EventEmitter();


  public get elapsedTime(): number {
    return this._elapsedTime;
  }

  public set elapsedTime(value: number) {
    this._elapsedTime = value;
    if (value >= 0) {
      this.elapsedTimeChange.emit(value);
    }
  }

  @Input() title: string = '';

  @Output() timerFinished = new EventEmitter();

  @Output() timerRunning = new EventEmitter<boolean>();

  public get hours(): string {
    return this.splitTime()?.hours?.toString()?.padStart(2, '0');
  }

  public set hours(value: string) {
    if (value?.length <= 0) return;
    this.time = (parseInt(value) * 3600) + (parseInt(this.minutes) * 60) + parseInt(this.seconds);
    this.timeChange.emit(this.time);
  }

  public get minutes(): string {
    return this.splitTime()?.minutes?.toString()?.padStart(2, '0');
  }

  public set minutes(value: string) {
    if (value?.length <= 0) return;
    this.time = (parseInt(this.hours) * 3600) + (parseInt(value) * 60) + parseInt(this.seconds);
    this.timeChange.emit(this.time);
  }

  public get seconds(): string {
    return this.splitTime()?.seconds?.toString()?.padStart(2, '0');
  }

  public set seconds(value: string) {
    if (value?.length <= 0) return;
    this.time = (parseInt(this.hours) * 3600) + (parseInt(this.minutes) * 60) + parseInt(value);
    this.timeChange.emit(this.time);
  }


  private splitTime(): { hours: number, minutes: number, seconds: number } {
    let hours = Math.floor(this.time / 3600);
    let minutes = Math.floor((this.time % 3600) / 60);
    let seconds = Math.floor(this.time % 60);

    return { hours, minutes, seconds };
  }

  public setProperties(time: number, title: string, autoStart: boolean, autoRun: boolean) {
    this.title = title;
    this.setTimerTime(time);
    this.autoRun = autoRun;
    if (autoStart) {
      this.startTimer();
    }
  }

  private timer: NodeJS.Timeout;
  private lastTickTime: number;

  public startTimer() {
    this.timerRunning.emit(true);
    this.lastTickTime = Date.now();
    this.timer = setInterval(() => {
      const now = Date.now();
      const deltaSeconds = Math.floor((now - this.lastTickTime) / 1000);
      if (deltaSeconds < 1) {
        return;
      }
      if (this.mode == TimerMode.CountUp) {
        this.time += deltaSeconds;
      }
      else {
        if (this.time <= 0) {
          this.reachedTimerEnd();
          return;
        }
        const previousTime = this.time;
        this.time = Math.max(0, this.time - deltaSeconds);
        // Play sound when crossing the 4-second threshold.
        if (previousTime >= 5 && this.time < 5 && this.time >= 4) {
          this.playSound();
        }
      }
      this.elapsedTime += deltaSeconds;
      this.timeChange.emit(this.time);
      this.lastTickTime = now;
    }, 250);
  }

  playSound() {
    try {
      // this.audio?.play();
      NativeAudio.play({
        assetId: 'beep',
      });
    }
    catch (ex) {
      console.log(ex);
    }
  }

  stopSound() {
    try {
      NativeAudio.stop({
        assetId: 'beep',
      });
      // if(this.audio) {
      //   this.audio.pause();
      //   this.audio.currentTime = 0;
      // }
    }
    catch (ex) {
      console.log(ex);
    }
  }


  public stopTimer() {
    this.timerRunning.emit(false);
    clearInterval(this.timer);
    this.timer = null;
  }

  public toggleTimer() {
    if (this.timer) {
      this.stopTimer();
    } else {
      this.startTimer();
    }
  }

  public isTimerRunning() {
    return this.timer ? true : false;
  }

  public resetTimer() {
    this.stopSound();
    this.stopTimer();
    this.time = this._inputTime;
    this.elapsedTime = 0;
    if(this.time > 0) {
      this.mode = TimerMode.CountDown;
    }
    else {
      this.mode = TimerMode.CountUp;
    }
    this.timeChange.emit(this.time);
  }


  private reachedTimerEnd() {
    if (this.autoRun) {
      this.finishTimer();
    }
    else {
      if (this.mode == TimerMode.CountDown) {
        this.mode = TimerMode.CountUp;
        this.time = 0;
      }
    }
  }

  public finishTimer() {
    this.stopTimer();
    this.timerFinished.emit();
  }

}

enum TimerMode {
  CountDown = 0,
  CountUp = 1
}
