import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { FirestoreService } from 'src/app/services/firestore.service';
import { UtilityService } from 'src/app/services/utility.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { InputFieldDialogComponent } from '../inputfield-dialog/inputfield-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent {
  public environment = environment

  public mail = '';
  public password = '';

  constructor(public authService: AuthService, private userService: FirestoreService, private router: Router, private route: ActivatedRoute, public utilityService: UtilityService, private navService: NavbarService, public dialog: MatDialog, private translate: TranslateService) { }

  ngOnInit(): void {
    //this.router.routerState
    this.route.paramMap.subscribe((param) => {
      this.authService.redirectUrl = param.get('redirectURL');
    });
    if (this.authService?.isLoggedIn()) {
      if (this.authService?.redirectUrl === '/body-data') {
        this.router.navigate['body-data'];
      }
      else if (this.authService?.redirectUrl === '/chat' && this.utilityService?.onNativeMobileApp()){
        this.router.navigate['chat'];
      }
      else {
        this.router.navigate['dashboard'];
      }
    }
  }

  goToWebsite() {
    window.open('https://nutrilize.app', "_blank");
  }

  onChangeCoachingZone() {
    const dialogRef = this.dialog.open(InputFieldDialogComponent, {
      data: { message: '', title: this.translate.instant('Coaching Zone wechseln') },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.input) {
        var id = result.input?.replaceAll(' ', '');
        var link = window.location.href
        if (link.includes('?appId=')) link = link.split('?appId=')[0]
        window.location.href = link + '?appId=' + id;
      }
    });
  }
}
