<div class="dialog-topbar">
    <h3 translate>Rezept teilen</h3>
</div>
<div class="dialog-content-wrapper mealsuggestion-settings">
    <div class="recipesharing-toggle toggle-wrapper">
        <div class="toggle {{shared ? 'on' : ''}}" (click)="onShareSelectedRecipeChanged(!shared)">
            <div class="handle"></div>
        </div>
        <div class="text" translate>
            Rezept teilen
        </div>
    </div>
    <br>
    <div class="spacer-25"></div>
    <button *ngIf="shared" class="button-filled light small dropdown-toggle marginleft-25" type="button" id="dropdownAssignedUsers" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{areAllUsersTargeted() ? ('Alle Kunden' | translate) : ('Ausgewählte Kunden' | translate)}}</button>
    <div *ngIf="shared" class="dropdown-menu dropdown-assignedusers" aria-labelledby="dropdownAssignedUsers">
        <div class="dropdown-item link" (click)="onAllAssignedUserDropdownValueChanged(!areAllUsersTargeted());$event.stopPropagation()">
            <input class="input checkbox" type="checkbox" [checked]="areAllUsersTargeted()"><div class="display-inlineblock">Alle</div>
        </div>
        <ng-container *ngIf="getSelectableClientGroups()?.length > 0">
            <div class="dropdown-divider"></div>
            <div class="label marginleft-10" translate>Gruppen</div>
            <div class="dropdown-item link" *ngFor="let group of getSelectableClientGroups()" (click)="onAssignedGroupDropdownValueChanged(group);$event.stopPropagation()">
                <input [disabled]="areAllUsersTargeted()" class="input checkbox" type="checkbox" [checked]="assignedGroupNames.includes(group)"><div class="display-inlineblock">
                    <span *ngIf="group == 'Alle'; else anyGroup;" translate>
                        Alle
                    </span>
                    <ng-template #anyGroup>
                        {{group}}
                    </ng-template>
                </div>
            </div>
        </ng-container>
        <div class="dropdown-divider"></div>
        <div class="label marginleft-10" translate>Kunden</div>
        <div class="dropdown-item link" *ngFor="let user of getSelectableClients()" (click)="onAssignedUserDropdownValueChanged(user);$event.stopPropagation()">
            <input [disabled]="areAllUsersTargeted()" class="input checkbox" type="checkbox" [checked]="assignedUsers.includes(user.uid)"><div class="display-inlineblock">{{user.getName()}}</div>
        </div>
    </div>
</div>
<mat-dialog-actions>
    <div class="actions">
        <button class="button-filled small light " (click)="onCancel()" translate>Abbrechen</button>
        <button class="button-filled small light float-right marginleft-25" (click)="onSubmit()" translate>Übernehmen</button>
    </div>
</mat-dialog-actions>