<!--suppress XmlDuplicatedId, JSUnusedGlobalSymbols -->
<ng-template #progressDialog>
  <label for="progressPlanNameInput" class="label" translate>Ziel benennen</label> <br>
  <input #validationInput id="progressPlanNameInput" [(ngModel)]="planName" (ngModelChange)="validate()"
         class="input small wide"
         type="text"
         placeholder=""/>
  <div class="spacer-25"></div>

  <span translate>Metrik</span>:<br>
  <div class="spacer-10"></div>
  <app-selection-dropdown [isMultiSelect]="false" [hasSearchField]="true" [selectableGroups]="metricSelectionGroups"
                          [invalid]="getSelectedMetric() == 'Bitte Metrik auswählen...'" [disabled]="!isNew"
                          (itemChanged)="onSelectedItemChanged($event)"></app-selection-dropdown>
  <div class="spacer-25"></div>

  <ng-container>
    <div *ngIf="showInApp" class="display-block">
      <input id="progressShowPlanOnDashboardInput" class="input checkbox" type="checkbox"
             [checked]="showOnCoacheeDashboard"
             (change)="showOnCoacheeDashboard = !showOnCoacheeDashboard;">
      <label for="progressShowPlanOnDashboardInput" translate>Auf App-Dashboard fixieren</label>
    </div>
    <div *ngIf="showInApp" class="spacer-25"></div>
  </ng-container>

  <ng-container *ngIf="selectedMetric">
    <div class="row">
      <div class="col-6 col-lg-3">
        <div class="label" for="progressPlanStartDateInput" translate>Startdatum</div>
        <input class="input small medium-width" disabled [value]="intermediateGoals[0].date?.asFormatedString()"/>
        <input #validationInput required id="progressPlanStartDateInput" [matDatepicker]="startDatePicker"
               [matDatepickerFilter]="startDateFilter"
               class="invisible-datetime-input visibility-hidden width-0 padding-0"
               [value]="intermediateGoals[0].date"
               (dateInput)="onGoalDateChanged($event.value?.toDate(), intermediateGoals[0]);validate();">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </div>

      <div class="col-6 col-lg-3">
        <label class="label" for="progressPlanStartValueInput" translate>Startwert</label><br>
        <ng-container *ngIf="!selectedMetric.isMetricTypeDuration(); else durationInput">
          <input #validationInput required id="progressPlanStartValueInput"
                 [value]="intermediateGoals[0].getValue()"
                 (change)="onTargetValueChanged($event, intermediateGoals[0]);"
                 class="input small medium-width" type="text"
                 placeholder="" pattern="^\d+([.,]\d+)?(-\d+([.,]\d+)?)?$" (input)="validate();">
        </ng-container>

        <ng-template #durationInput>
          <input #validationInput required type="text" class="input small medium-width"
                 pattern="^(([0-9][0-9]:)?([0-5][0-9]):[0-5][0-9])(-([0-9][0-9]:)?([0-5][0-9]))?$"
                 placeholder="hh:mm(-hh:mm)" id="progressPlanStartValueInput"
                 [value]="getDuration(intermediateGoals[0].getValue())"
                 (change)="onTargetValueChanged($event, intermediateGoals[0]);" (input)="validate();">
          <span> h</span>
        </ng-template>
        <span *ngIf="selectedMetric.unit"> {{ selectedMetric.unit }}</span>
      </div>

      <div class="col">
        <div class="display-block marginleft-10 cheat-input">
          <input id="progressShowStartStepInAppInput" class="input checkbox" type="checkbox"
                [disabled]="!isFirstOnly()"
                [checked]="intermediateGoals[0].showInApp" (change)="onHandleShowInApp(intermediateGoals[0]);">
          <label class="label" for="progressShowStartStepInAppInput" translate>In Kunden-App anzeigen</label>
        </div>
      </div>
    </div>
    <div class="spacer-25"></div>
  </ng-container>

  <ng-container *ngIf="selectedMetric">
    <div class="table-wrapper">
      <table class="col-12">
        <colgroup>
          <col style="width:20%">
          <col style="width:20%">
          <col style="width:20%">
          <col style="width:30%">
        </colgroup>
        <thead>
        <tr>
          <th translate>Benennung</th>
          <th translate>Zieldatum</th>
          <th>Ziel</th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let intermediateGoal of intermediateGoals; let first = first; let index = index;">
          <tr class="values-tr" *ngIf="!first">
            <td>
              <input #validationInput [(ngModel)]="intermediateGoal.name" (ngModelChange)="validate();"
                     class="input small form-control full-width" [placeholder]="first ? 'Start' : 'Zwischenziel'"
                     required>
            </td>
            <td>
              <span class="marginleft-5">{{ intermediateGoal.date?.asFormatedString() }}</span>
              <input [matDatepicker]="goalDatePicker" [matDatepickerFilter]="first ? startDateFilter : stepDateFilter"
                     class="invisible-datetime-input visibility-hidden width-0 padding-0" [value]="intermediateGoal.date"
                     (dateInput)="onGoalDateChanged($event.value?.toDate(), intermediateGoal);">
              <mat-datepicker-toggle matSuffix [for]="goalDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #goalDatePicker></mat-datepicker>
            </td>
            <td>
              <ng-container *ngIf="!selectedMetric.isMetricTypeDuration(); else durationInput">
                <input #validationInput [value]="intermediateGoal.getValue()"
                       (change)="onTargetValueChanged($event, intermediateGoal);" type="text"
                       placeholder="100 oder 90-100" class="input small form-control full-width"
                       pattern="^\d+([.,]\d+)?(-\d+([.,]\d+)?)?$" (input)="validate();" required>
              </ng-container>
  
              <ng-template #durationInput>
                <input #validationInput type="text" class="input small medium-width"
                       pattern="^(([0-9][0-9]:)?([0-5][0-9]):[0-5][0-9])(-([0-9][0-9]:)?([0-5][0-9]))?$"
                       placeholder="hh:mm(-hh:mm)"
                       [value]="getDuration(intermediateGoal.getValue())"
                       (change)="onTargetValueChanged($event, intermediateGoal);" (input)="validate();"
                       required>
              </ng-template>
            </td>
            <td>
              <div class="display-block marginleft-10">
                <input [id]="'progressShowStepInAppInput'+index" class="input checkbox" type="checkbox"
                       [checked]="intermediateGoal.showInApp" (change)="onHandleShowInApp(intermediateGoal);">
                <label class="label" [for]="'progressShowStepInAppInput'+index" translate>In Kunden-App anzeigen</label>
              </div>
            </td>
            <td>
              <button (click)="onDeleteIntermediateGoal(intermediateGoal);"
                      class="icon-button float-right">
                <svg class="padding-4 bi bi-trash" fill="currentColor" viewBox="0 0 16 16" height="25" width="25"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path>
                  <path
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    fill-rule="evenodd"></path>
                </svg>
              </button>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
    <div class="spacer-25"></div>

    <button (click)="onCreateIntermediateGoal();" class="icon-button marginbottom-15">
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
           xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
              d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
      </svg>
      <span translate>Neues Zwischenziel erstellen</span>
    </button>
    <div class="spacer-25"></div>
  </ng-container>
</ng-template>

<ng-template #weeklyDialog>
  <label for="weeklyPlanNameInput" class="label" translate>Ziel benennen</label> <br>
  <input #validationInput required id="weeklyPlanNameInput" [(ngModel)]="planName" (ngModelChange)="validate()"
         class="input small wide"
         type="text"
         placeholder=""/>
  <button (click)="toggleEditMode()" class="icon-button float-right" [disabled]="disableEasyMode">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sliders"
         viewBox="0 0 16 16">
      <path fill-rule="evenodd"
            d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z"/>
    </svg>
    {{ mode == EditMode.DETAILED ? "Einfache Einstellungen" : "Erweiterte Einstellungen" }}
  </button>
  <div class="spacer-25"></div>

  <span translate>Metrik</span>:<br>
  <div class="spacer-10"></div>
  <app-selection-dropdown [isMultiSelect]="false" [hasSearchField]="true" [selectableGroups]="metricSelectionGroups"
                          [invalid]="getSelectedMetric() == 'Bitte Metrik auswählen...'" [disabled]="!isNew"
                          (itemChanged)="onSelectedItemChanged($event)"></app-selection-dropdown>

  <div class="d-inline-block wrapper-metrictype-selection">
    <button class="button-filled light small dropdown-toggle margin-inbetween"
            [class.button-invalid]="aggregation == null" type="button"
            id="dropdownAggregation"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            [disabled]="!isNew || disableAggregation">{{ getAggregation() }}
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMetric">
      <button class="dropdown-item" type="button"
              (click)="aggregation = WeeklyAggregation.AVG;" translate><span>Durchschnitt</span>
      </button>
      <button class="dropdown-item" type="button"
              (click)="aggregation = WeeklyAggregation.SUM;" translate><span>Summe</span>
      </button>
    </div>
  </div>
  <div class="spacer-25"></div>

  <ng-container *ngIf="selectedMetric && mode == EditMode.EASY">
    <ng-container *ngIf="isNew; else newWeeklyGoal">
      <label class="label" for="weeklyPlanStartDateInput" translate>Neues Ziel startet am</label><br>
      <span class="marginleft-5">{{ intermediateGoals[0].date?.asFormatedString() }}</span>
      <input id="weeklyPlanStartDateInput" [matDatepicker]="startDatePicker" [matDatepickerFilter]="startDateFilter"
             class="invisible-datetime-input visibility-hidden width-0 padding-0"
             [value]="intermediateGoals[0].date"
             (dateInput)="onGoalDateChanged($event.value?.toDate(), intermediateGoals[0]);validate();" required>
      <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
      <div class="spacer-25"></div>
      <label class="label"
             for="weeklyPlanStartValueInput" translate>Zielwert</label> <br>
      <div class="spacer-10"></div>
      <ng-container *ngIf="!selectedMetric.isMetricTypeDuration(); else durationInput">
        <!--suppress XmlDuplicatedId -->
        <input #validationInput id="weeklyPlanStartValueInput" [value]="intermediateGoals[0].getValue()"
               (change)="onTargetValueChanged($event, intermediateGoals[0]);"
               class="input small medium-width" type="text"
               placeholder="" pattern="^\d+([.,]\d+)?(-\d+([.,]\d+)?)?$" (input)="validate();" required>
      </ng-container>

      <ng-template #durationInput>
        <input #validationInput type="text" class="input small medium-width"
               pattern="^(([0-9][0-9]:)?([0-5][0-9]):[0-5][0-9])(-([0-9][0-9]:)?([0-5][0-9]))?$"
               placeholder="hh:mm(-hh:mm)" id="weeklyPlanStartValueInput"
               [value]="getDuration(intermediateGoals[0].getValue())"
               (change)="onTargetValueChanged($event, intermediateGoals[0]);" (input)="validate();"
               required>
        <span> h</span>
      </ng-template>
      <span *ngIf="selectedMetric.unit"> {{ selectedMetric.unit }}</span>
      <span *ngIf="selectedMetric.isMetricTypeYesNo() || selectedMetric.isMetricTypeToDo()" translate> mal</span>
    </ng-container>

    <ng-template #newWeeklyGoal>
      <label class="label"
             for="weeklyPlanStartValueInput" translate>Neuer Zielwert/Zielbereich</label> <br>
      <div class="spacer-10"></div>
      <ng-container *ngIf="!selectedMetric.isMetricTypeDuration(); else durationInput">
        <input #validationInput required id="weeklyPlanValueInput" [value]="nextGoal.getValue()"
               (change)="onTargetValueChanged($event, nextGoal);"
               class="input small medium-width" type="text"
               placeholder=""
               pattern="^\d+([.,]\d+)?(-\d+([.,]\d+)?)?$" (input)="validate();">
      </ng-container>

      <ng-template #durationInput>
        <input #validationInput required type="text" class="input small medium-width"
               pattern="^(([0-9][0-9]:)?([0-5][0-9]):[0-5][0-9])(-([0-9][0-9]:)?([0-5][0-9]))?$"
               placeholder="hh:mm(-hh:mm)" id="weeklyPlanValueInput"
               [value]="getDuration(nextGoal.getValue())"
               (change)="onTargetValueChanged($event, nextGoal);" (input)="validate();">
        <span> h</span>
      </ng-template>
      <span *ngIf="selectedMetric.unit"> {{ selectedMetric.unit }}</span>
      <span *ngIf="selectedMetric.isMetricTypeYesNo() || selectedMetric.isMetricTypeToDo()" translate> mal</span>

      <label class="label marginleft-10" for="weeklyPlanStartValueInput">
        <span translate>Letztes Ziel</span>: {{ getPrintableLastGoal() }}
        <span *ngIf="selectedMetric.unit"> {{ selectedMetric.unit }}</span>
        <span *ngIf="selectedMetric.isMetricTypeDuration()"> h</span>
        <span *ngIf="selectedMetric.isMetricTypeYesNo() || selectedMetric.isMetricTypeToDo()" translate> mal</span>
      </label>
    </ng-template>
    <div class="spacer-25"></div>
  </ng-container>

  <ng-container>
    <div *ngIf="mode == EditMode.EASY" class="display-block">
      <input id="weeklyShowPlanInAppInput" class="input checkbox" type="checkbox" [checked]="showInApp"
             (change)="onHandleShowInApp(null);">
      <label for="weeklyShowPlanInAppInput" translate>In Kunden-App anzeigen</label>
    </div>

    <div *ngIf="showInApp" class="display-block">
      <input id="weeklyShowPlanOnDashboardInput" class="input checkbox" type="checkbox"
             [checked]="showOnCoacheeDashboard"
             (change)="showOnCoacheeDashboard = !showOnCoacheeDashboard;">
      <label for="weeklyShowPlanOnDashboardInput" translate>Auf App-Dashboard fixieren</label>
    </div>
    <div *ngIf="showInApp" class="spacer-25"></div>
  </ng-container>

  <ng-container *ngIf="mode === EditMode.DETAILED">
    <div class="table-wrapper">
      <table class="col-12">
        <colgroup>
          <col style="width:20%">
          <col style="width:20%">
          <col style="width:30%">
        </colgroup>
        <thead>
        <tr>
          <th translate>Gültig ab</th>
          <th translate>Ziel</th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr class="values-tr" *ngFor="let intermediateGoal of intermediateGoals; let first = first">
          <td>
            <span class="marginleft-5">{{ intermediateGoal.date?.asFormatedString() }}</span>
            <input #validationInput required [matDatepicker]="goalDatePicker"
                   [matDatepickerFilter]="first ? startDateFilter : stepDateFilter"
                   class="invisible-datetime-input visibility-hidden width-0 padding-0" [value]="intermediateGoal.date"
                   (dateInput)="onGoalDateChanged($event.value?.toDate(), intermediateGoal);">
            <mat-datepicker-toggle matSuffix [for]="goalDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #goalDatePicker></mat-datepicker>
          </td>
          <td>
            <ng-container *ngIf="!selectedMetric.isMetricTypeDuration(); else durationInput">
              <input #validationInput required [value]="intermediateGoal.getValue()"
                     (change)="onTargetValueChanged($event, intermediateGoal);" type="text"
                     placeholder="100 oder 90-100" class="input small form-control full-width"
                     pattern="^\d+([.,]\d+)?(-\d+([.,]\d+)?)?$"
                     (input)="validate();">
            </ng-container>
  
            <ng-template #durationInput>
              <input #validationInput required type="text" class="input small medium-width"
                     pattern="^(([0-9][0-9]:)?([0-5][0-9]):[0-5][0-9])(-([0-9][0-9]:)?([0-5][0-9]))?$"
                     placeholder="hh:mm(-hh:mm)"
                     [value]="getDuration(intermediateGoal.getValue())"
                     (change)="onTargetValueChanged($event, intermediateGoal);" (input)="validate();">
            </ng-template>
          </td>
          <td>
            <div class="display-block marginleft-10">
              <input id="weeklyShowStepInAppInput" class="input checkbox" type="checkbox"
                     [checked]="intermediateGoal.showInApp" (change)="onHandleShowInApp(intermediateGoal);">
  
              <label class="label" for="weeklyShowStepInAppInput" translate>In Kunden-App anzeigen</label>
            </div>
          </td>
          <td>
            <button (click)="onDeleteIntermediateGoal(intermediateGoal);"
                    class="icon-button float-right">
              <svg class="padding-4 bi bi-trash" fill="currentColor" viewBox="0 0 16 16" height="25" width="25"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path>
                <path
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  fill-rule="evenodd"></path>
              </svg>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="spacer-25"></div>

    <button (click)="onCreateIntermediateGoal();" class="icon-button marginbottom-15">
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
           xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
              d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
      </svg>
      <span translate>Neues Zwischenziel erstellen</span>
    </button>
    <div class="spacer-25"></div>
  </ng-container>

  <ng-container *ngIf="!endDate; else showEndDate">
    <ng-container *ngIf="mode == EditMode.DETAILED; else terminatePlan">
      <label class="label" for="weeklyPlanEndDateInput" translate>Ziel beenden am (optional)</label><br>
      <span class="marginleft-5">{{ endDate?.asFormatedString() }}</span>
      <input id="weeklyPlanEndDateInput" [matDatepicker]="goalDatePicker" [value]="endDate"
             class="invisible-datetime-input visibility-hidden width-0 padding-0"
             (dateInput)="onEndDateChanged($event.value?.toDate());">
      <mat-datepicker-toggle matSuffix [for]="goalDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #goalDatePicker></mat-datepicker>
      <div class="spacer-25"></div>
    </ng-container>

    <ng-template #terminatePlan>
      <!--    Rückmeldung zu welchem Datum beendet wurde. Vielleicht bei onClick das obere Template anzeigen-->
      <button class="button-filled small light" (click)="onEndDateChanged()" translate>Ziel beenden</button>
      <div class="spacer-25"></div>
    </ng-template>
  </ng-container>

  <ng-template #showEndDate>
    <label class="label" for="weeklyPlanEndDateInput" translate>Ziel endet am</label><br>
    <span class="marginleft-5">{{ endDate?.asFormatedString() }}</span>
    <input id="weeklyPlanEndDateInput" [matDatepicker]="goalDatePicker" [value]="endDate"
           class="invisible-datetime-input visibility-hidden width-0 padding-0"
           (dateInput)="onEndDateChanged($event.value?.toDate());" [matDatepickerFilter]="sundayDateFilter">
    <mat-datepicker-toggle matSuffix [for]="goalDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #goalDatePicker></mat-datepicker>
    <button (click)="endDate = null" title="Enddatum entfernen"
            class="icon-button cheat-button">
      <svg class="padding-4 bi bi-trash" fill="currentColor" viewBox="0 0 16 16" height="25" width="25"
           xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path>
        <path
          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
          fill-rule="evenodd"></path>
      </svg>
    </button>
    <div class="spacer-25"></div>
  </ng-template>
</ng-template>

<ng-template #dailyDialog>
  <label for="dailyPlanNameInput" class="label" translate>Ziel benennen</label> <br>
  <input #validationInput required id="dailyPlanNameInput" [(ngModel)]="planName" (ngModelChange)="validate()"
         class="input small wide"
         type="text"
         placeholder=""/>
  <button (click)="toggleEditMode()" class="icon-button float-right" [disabled]="disableEasyMode">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sliders"
         viewBox="0 0 16 16">
      <path fill-rule="evenodd"
            d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z"/>
    </svg>
    {{ mode == EditMode.DETAILED ? "Einfache Einstellungen" : "Erweiterte Einstellungen" }}
  </button>
  <div class="spacer-25"></div>

  <span translate>Metrik</span>:<br>
  <div class="spacer-10"></div>
  <app-selection-dropdown [isMultiSelect]="false" [hasSearchField]="true" [selectableGroups]="metricSelectionGroups"
                          [invalid]="getSelectedMetric() == 'Bitte Metrik auswählen...'" [disabled]="!isNew"
                          (itemChanged)="onSelectedItemChanged($event)"></app-selection-dropdown>
  <div class="spacer-25"></div>

  <ng-container *ngIf="selectedMetric && mode == EditMode.EASY">
    <ng-container *ngIf="isNew; else newDailyGoal">
      <div class="label" for="dailyPlanStartDateInput">{{ isNew ? "Neues Ziel startet am" : "Startdatum" }}</div>
      <span class="marginleft-5">{{ intermediateGoals[0].date?.asFormatedString() }}</span>
      <input #validationInput required id="dailyPlanStartDateInput" [matDatepicker]="startDatePicker"
             [matDatepickerFilter]="startDateFilter"
             class="invisible-datetime-input visibility-hidden width-0 padding-0"
             [value]="intermediateGoals[0].date"
             (dateInput)="onGoalDateChanged($event.value?.toDate(), intermediateGoals[0]);validate();">
      <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
      <div class="spacer-25"></div>
      <label class="label"
             for="dailyPlanStartValueInput">{{ selectedMetric.isMetricTypeYesNo() || selectedMetric.isMetricTypeToDo() ? 'Ziel ist es, die Metrik mit Ja zu beantworten' : 'Zielwert' }}</label>
      <br>
      <div class="spacer-10"></div>
      <ng-container *ngIf="!selectedMetric.isMetricTypeDuration(); else durationInput">
        <input #validationInput required id="dailyPlanStartValueInput"
               *ngIf="!(selectedMetric.isMetricTypeYesNo() || selectedMetric.isMetricTypeToDo())"
               [value]="intermediateGoals[0].getValue()"
               (change)="onTargetValueChanged($event, intermediateGoals[0]);"
               class="input small medium-width" type="text"
               placeholder="" pattern="^\d+([.,]\d+)?(-\d+([.,]\d+)?)?$" (input)="validate();">
      </ng-container>

      <ng-template #durationInput>
        <input #validationInput required type="text" class="input small medium-width"
               pattern="^(([0-9][0-9]:)?([0-5][0-9]):[0-5][0-9])(-([0-9][0-9]:)?([0-5][0-9]))?$"
               placeholder="hh:mm(-hh:mm)" id="dailyPlanStartValueInput"
               [value]="getDuration(intermediateGoals[0].getValue())"
               (change)="onTargetValueChanged($event, intermediateGoals[0]);" (input)="validate();">
        <span> h</span>
      </ng-template>
      <span *ngIf="selectedMetric.unit"> {{ selectedMetric.unit }}</span>
    </ng-container>

    <ng-template #newDailyGoal>
      <ng-container *ngIf="!(selectedMetric.isMetricTypeToDo() || selectedMetric.isMetricTypeYesNo())">
        <label class="label" for="dailyPlanGoalValueInput" translate>Neuer Zielwert/Zielbereich</label> <br>
        <div class="spacer-10"></div>
        <ng-container *ngIf="!selectedMetric.isMetricTypeDuration(); else durationInput">
          <input #validationInput required id="dailyPlanGoalValueInput" [value]="nextGoal.getValue()"
                 (change)="onTargetValueChanged($event, nextGoal);"
                 class="input small medium-width" type="text"
                 placeholder="" pattern="^\d+([.,]\d+)?(-\d+([.,]\d+)?)?$" (input)="validate();">
        </ng-container>

        <ng-template #durationInput>
          <input #validationInput required type="text" class="input small medium-width"
                 pattern="^(([0-9][0-9]:)?([0-5][0-9]):[0-5][0-9])(-([0-9][0-9]:)?([0-5][0-9]))?$"
                 placeholder="hh:mm(-hh:mm)" id="dailyPlanGoalValueInput"
                 [value]="getDuration(nextGoal.getValue())"
                 (change)="onTargetValueChanged($event, nextGoal);" (input)="validate();">
          <span> h</span>
        </ng-template>
        <span *ngIf="selectedMetric.unit"> {{ selectedMetric.unit }}</span>
        <span *ngIf="selectedMetric.isMetricTypeYesNo() || selectedMetric.isMetricTypeToDo()" translate> mal</span>
        <label class="label marginleft-10" for="weeklyPlanStartValueInput">
          Letztes Ziel: {{ getPrintableLastGoal() }}
          <span *ngIf="selectedMetric.unit"> {{ selectedMetric.unit }}</span>
          <span *ngIf="selectedMetric.isMetricTypeDuration()"> h</span>
          <span *ngIf="selectedMetric.isMetricTypeYesNo() || selectedMetric.isMetricTypeToDo()" translate> mal</span>
        </label>
      </ng-container>
    </ng-template>
    <div class="spacer-25"></div>
  </ng-container>

  <ng-container>
    <div *ngIf="mode == EditMode.EASY" class="display-block">
      <input id="dailyShowPlanInAppInput" class="input checkbox" type="checkbox" [checked]="showInApp"
             (change)="onHandleShowInApp(null);">
      <label for="dailyShowPlanInAppInput" translate>In Kunden-App anzeigen</label>
    </div>

    <div *ngIf="showInApp" class="display-block">
      <input id="dailyShowPlanOnDashboardInput" class="input checkbox" type="checkbox"
             [checked]="showOnCoacheeDashboard"
             (change)="showOnCoacheeDashboard = !showOnCoacheeDashboard;">
      <label for="dailyShowPlanOnDashboardInput" translate>Auf App-Dashboard fixieren</label>
    </div>
    <div *ngIf="showInApp" class="spacer-25"></div>
  </ng-container>

  <ng-container *ngIf="mode === EditMode.DETAILED">
    <div class="table-wrapper">
      <table class="col-12">
        <colgroup>
          <col style="width:20%">
          <col style="width:20%">
          <col style="width:30%">
        </colgroup>
        <thead>
        <tr>
          <th translate>Gültig ab</th>
          <th translate>Ziel</th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr class="values-tr" *ngFor="let intermediateGoal of intermediateGoals; let first = first">
          <td>
            <span class="marginleft-5">{{ intermediateGoal.date?.asFormatedString() }}</span>
            <input #validationInput required [matDatepicker]="goalDatePicker"
                   [matDatepickerFilter]="first ? startDateFilter : stepDateFilter"
                   [value]="intermediateGoal.date"
                   class="invisible-datetime-input visibility-hidden width-0 padding-0"
                   (dateInput)="onGoalDateChanged($event.value?.toDate(), intermediateGoal);">
            <mat-datepicker-toggle matSuffix [for]="goalDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #goalDatePicker></mat-datepicker>
          </td>
          <td>
            <ng-container *ngIf="!selectedMetric.isMetricTypeDuration(); else durationInput">
              <input #validationInput required [value]="intermediateGoal.getValue()"
                     (change)="onTargetValueChanged($event, intermediateGoal);" type="text"
                     placeholder="100 oder 90-100" class="input small form-control full-width"
                     pattern="^\d+([.,]\d+)?(-\d+([.,]\d+)?)?$"
                     (input)="validate();">
            </ng-container>
  
            <ng-template #durationInput>
              <input #validationInput required type="text" class="input small medium-width"
                     pattern="^(([0-9][0-9]:)?([0-5][0-9]):[0-5][0-9])(-([0-9][0-9]:)?([0-5][0-9]))?$"
                     placeholder="hh:mm(-hh:mm)"
                     [value]="getDuration(intermediateGoal.getValue())"
                     (change)="onTargetValueChanged($event, intermediateGoal);"
                     (input)="validate();">
            </ng-template>
          </td>
          <td>
            <div class="display-block marginleft-10">
              <input id="dailyShowStepInAppInput" class="input checkbox" type="checkbox"
                     [checked]="intermediateGoal.showInApp" (change)="onHandleShowInApp(intermediateGoal);">
  
              <label class="label" for="dailyShowStepInAppInput" translate>In Kunden-App anzeigen</label>
            </div>
          </td>
          <td>
            <button (click)="onDeleteIntermediateGoal(intermediateGoal);"
                    class="icon-button float-right">
              <svg class="padding-4 bi bi-trash" fill="currentColor" viewBox="0 0 16 16" height="25" width="25"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path>
                <path
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  fill-rule="evenodd"></path>
              </svg>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    
    <div class="spacer-25"></div>

    <button (click)="onCreateIntermediateGoal();" class="icon-button marginbottom-15">
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
           xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
              d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
      </svg>
      <span translate>Neues Zwischenziel erstellen</span>
    </button>
    <div class="spacer-25"></div>
  </ng-container>

  <ng-container *ngIf="!endDate; else showEndDate">
    <ng-container *ngIf="mode == EditMode.DETAILED; else terminatePlan">
      <label class="label" for="dailyPlanEndDateInput" translate>Ziel beenden am (optional)</label><br>
      <span class="marginleft-5">{{ endDate?.asFormatedString() }}</span>
      <input id="dailyPlanEndDateInput" [matDatepicker]="goalDatePicker" [value]="endDate"
             class="invisible-datetime-input visibility-hidden width-0 padding-0"
             (dateInput)="onEndDateChanged($event.value?.toDate());">
      <mat-datepicker-toggle matSuffix [for]="goalDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #goalDatePicker></mat-datepicker>
      <div class="spacer-25"></div>
    </ng-container>

    <ng-template #terminatePlan>
      <!--    Rückmeldung zu welchem Datum beendet wurde. Vielleicht bei onClick das obere Template anzeigen-->
      <button class="button-filled small light" (click)="onEndDateChanged()" translate>Ziel beenden</button>
      <div class="spacer-25"></div>
    </ng-template>
  </ng-container>

  <ng-template #showEndDate>
    <label class="label" for="dailyPlanEndDateInput" translate>Ziel endet am</label><br>
    <span class="marginleft-5">{{ endDate?.asFormatedString() }}</span>
    <input id="dailyPlanEndDateInput" [matDatepicker]="goalDatePicker" [value]="endDate"
           class="invisible-datetime-input visibility-hidden width-0 padding-0"
           (dateInput)="onEndDateChanged($event.value?.toDate());">
    <mat-datepicker-toggle matSuffix [for]="goalDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #goalDatePicker></mat-datepicker>
    <button (click)="endDate = null" title="Enddatum entfernen"
            class="icon-button cheat-button">
      <svg class="padding-4 bi bi-trash" fill="currentColor" viewBox="0 0 16 16" height="25" width="25"
           xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path>
        <path
          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
          fill-rule="evenodd"></path>
      </svg>
    </button>
    <div class="spacer-25"></div>
  </ng-template>

  <ng-container>
    <div class="form-group">
      <label for="dropdownRepetion" class="label" translate>Tägliche Erinnerung senden, wenn Ziel noch nicht erreicht:</label><br>
      <app-selection-dropdown [isMultiSelect]="true" [hasSearchField]="true"
                              [selectableGroups]="notificationSelectionGroups"
                              (itemChanged)="onSelectedNotificationChanged($event)"></app-selection-dropdown>

      <button (click)="onCreateNewPushNotification()" class="icon-button marginleft-10">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
             xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
        </svg>
      </button>
    </div>
  </ng-container>
</ng-template>

<div class="dialog-topbar">
  <h3 class="display-inlineblock margin-0">{{ title }} {{ createOrUpdateStr }}</h3>

  <div class="icon-button float-right" (click)="onCloseCustomMetricDialog()">
    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor"
         xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd"
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
    </svg>
  </div>
</div>

<div class="dialog-content-wrapper">
  <div class="dual-switch justifycontent-center">
    <button class="option {{type === GoalPlanType.PROGRESS ? 'active' : ''}}"
            (click)="onSetGoalPlanType(GoalPlanType.PROGRESS);"
            [disabled]="!isNew">{{ GoalPlanText.PROGRESS }}
    </button>
    <button class="option {{type === GoalPlanType.WEEKLY ? 'active' : ''}}"
            (click)="onSetGoalPlanType(GoalPlanType.WEEKLY);"
            [disabled]="!isNew || disableWeeklyGoal">{{ GoalPlanText.WEEKLY }}
    </button>
    <button class="option {{type === GoalPlanType.DAILY ? 'active' : ''}}"
            (click)="onSetGoalPlanType(GoalPlanType.DAILY);" [disabled]="!isNew">{{ GoalPlanText.DAILY }}
    </button>
  </div>
  <div class="spacer-10"></div>
  <div class="label">
    <div *ngIf="type === GoalPlanType.PROGRESS">{{ GoalPlanTextNote.PROGRESS }}</div>
    <div *ngIf="type === GoalPlanType.WEEKLY">{{ GoalPlanTextNote.WEEKLY }}</div>
    <div *ngIf="type === GoalPlanType.DAILY">{{ GoalPlanTextNote.DAILY }}</div>
  </div>
  <div class="spacer-15"></div>

  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="GoalPlanType.PROGRESS">
      <ng-container *ngTemplateOutlet="progressDialog"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="GoalPlanType.WEEKLY">
      <ng-container *ngTemplateOutlet="weeklyDialog"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="GoalPlanType.DAILY">
      <ng-container *ngTemplateOutlet="dailyDialog"></ng-container>
    </ng-container>
  </ng-container>

  <button class="button-filled small" [disabled]="notValid" (click)="onSaveGoalPlan()">
    {{ submitText }}
  </button>
  <div class="icon-button marginleft-20" (click)="onCancelGoalPlan()">
    {{ cancelText }}
  </div>
</div>
