<div class="dialog-topbar">
    <div class="row">
        <div class="col">
            <div class="icon-button float-right" (click)="onCloseDialog()">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                <span *ngIf="!utilityService.onSmallDisplay()" translate>Schließen</span>
            </div>
        </div>
    </div>
</div>
<div class="dialog-content-wrapper">
    <div class="margin-auto textalign-center tab-button-group adjust-mobile">
        <button class="tab-button {{currentTab == tabShorten ? 'active' : ''}}" (click)="onChangeTab(tabShorten)">
            <span translate>Verkürzen</span>
            <div class="underline"></div>
        </button>
        <button class="tab-button {{currentTab == tabExtend ? 'active' : ''}}" (click)="onChangeTab(tabExtend)">
            <span translate>Verlängern</span>
            <div class="underline"></div>
        </button>
    </div>
    <div class="spacer-25"></div>

    <div *ngIf="currentTab == tabShorten">
        <div *ngIf="productPurchase.recurring" class="display-inlineblock">
            <span>Verkürzen um:</span> <br>
            <div class="spacer-10"></div>
            <div class="display-inlineblock">
                <input id="input-duration" required class="input form-control display-inlineblock {{isInputValid() ? '' : 'invalid'}}" min="1" step="1" [(ngModel)]="durationMultiplier" type="number"> <span translate>Mal</span> {{productPurchase.duration}} {{productPurchase.getPrintableDurationUnit(translate)}}<br>
            </div>
            <div class="spacer-10"></div>
            <span>Neues Enddatum:</span> {{getProjectedEndDate()?.asFormatedString()}}<br>
            <div class="spacer-25"></div>
            <button class="button-filled small" (click)="onConfirm()" translate>Speichern</button>
        </div>
    </div>

    <div *ngIf="currentTab == tabExtend">
        <div *ngIf="productPurchase.recurring" class="display-inlineblock">
            <span translate>Verlängern um:</span> <br>
            <div class="spacer-10"></div>
            <div class="display-inlineblock">
                <input id="input-duration" required class="input form-control display-inlineblock {{isInputValid() ? '' : 'invalid'}}" min="1" step="1" [(ngModel)]="durationMultiplier" type="number"> Mal {{productPurchase.duration}} {{productPurchase.getPrintableDurationUnit(translate)}}<br>
            </div>
            <div class="spacer-10"></div>
            <span translate>Neues Enddatum:</span> {{getProjectedEndDate()?.asFormatedString()}}<br>
            <div class="spacer-25"></div>
            <button class="button-filled small" (click)="onConfirm()" translate>Speichern</button>
        </div>
    </div>
</div>
<ngx-spinner bdColor = "rgba(0,0,0,0.33)" size = "default" color = "var(--accentColor)" type = "ball-clip-rotate" [fullScreen] = "true"></ngx-spinner>