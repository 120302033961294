import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { PlannedTrainingExercise, SetParameter, SetParameter2LabelMapping, SetParameter2LabelUnitMapping, SetParameter2SubHeadingMapping, SetParameter2UnitMapping, BaseTrainingSet, TrainingSet } from 'src/app/model/training-plan.model';
import { LanguageService } from 'src/app/services/language.service';
import { TrainingSessionEditorComponent } from '../training-session-editor/training-session-editor.component';
import { TrainingPlanEditorComponent } from '../../training-plan-editor.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { environment } from 'src/environments/environment';
import { TrainingService } from 'src/app/services/training.service';
import { RpeTableDialogComponent } from 'src/app/training/rpe-table-dialog/rpe-table-dialog.component';
import { User } from 'src/app/model/user.model';
import { MatDialog } from '@angular/material/dialog';
import { TrackedTrainingExercise, TrackedTrainingSet } from 'src/app/model/training-monitoring.model';

@Component({
  selector: 'app-exercise-set-table',
  templateUrl: './exercise-set-table.component.html',
  styleUrls: ['./exercise-set-table.component.css', '../training-session-editor/training-session-editor.component.css']
})
export class ExerciseSetTableComponent {
  @ViewChildren('tableRows') tableRows: QueryList<ElementRef>;
  @ViewChildren('setElements') setElements: QueryList<ElementRef>;

  @Input() trackingMode: boolean;

  @Input() user: User;
  @Input() readOnlyMode: boolean;
  @Input() selectedSetParameters: SetParameter[];
  @Output() selectedSetParametersChange = new EventEmitter<SetParameter[]>();
  @Input() noteUnit: string;

  @Input() sets: BaseTrainingSet[];
  @Output() trainingSetsChange = new EventEmitter<BaseTrainingSet[]>();

  @Input() hasChanges: boolean;
  @Output() hasChangesChange = new EventEmitter<boolean>();

  @Input() isPartOfSuperSet: boolean;
  @Input() tutPattern: string;

  @Input() exercise: PlannedTrainingExercise;
  @Input() isPeriodicPlan: boolean;

  @Input() checkTUTINput: (set: BaseTrainingSet) => boolean;
  @Input() tutChange: (set: BaseTrainingSet) => void;

  @Input() setSetRir: (set: BaseTrainingSet, rir: string) => void;
  @Input() getSetRir: (set: BaseTrainingSet) => string;

  @Input() getRegexForSetParameter: (setParameter: SetParameter) => RegExp;

  @Input() setSetRpe: (set: BaseTrainingSet, rpe: string) => void;
  @Input() getSetRpe: (set: BaseTrainingSet) => string;

  @Input() noteChanged: (set: BaseTrainingSet, value: string) => void;

  @Input() setTime: (set: BaseTrainingSet, setParameter: SetParameter, time: string) => void;
  @Input() getTime: (set: BaseTrainingSet, setParameter: SetParameter) => string;

  @Input() autoRun: boolean;
  @Output() autoRunChange = new EventEmitter<boolean>();

  @Input() getSetPlaceholder: (setIndex: number, setParameter: SetParameter, unitSystem: string) => string;

  @Input() hasVideoRecording: (setIndex: number) => boolean;

  @Input() formulaTemplate: TemplateRef<any>;

  @Input() showVideoIcon: boolean;

  @Output() onVideoIconClicked = new EventEmitter<BaseTrainingSet>();
  @Output() isTrackedChanged = new EventEmitter<BaseTrainingSet>();

  private _focusedSet: BaseTrainingSet;
  get focusedSet() {
    return this._focusedSet;
  }
  @Input() set focusedSet(value: BaseTrainingSet) {
    if (value != this._focusedSet) {
      this._focusedSet = value;
    }
  }

  private focusSet(set: BaseTrainingSet) {
    try {
      if((set as TrackedTrainingSet)?.isTracked) {
        return;
      }
      let index = this.sets.indexOf(set);
      if (index > -1) {
        let tableRowArray = this.tableRows?.toArray();
        if (tableRowArray?.length > index) {
          let tableRow = tableRowArray[index]?.nativeElement;
          let setInput = tableRow?.querySelector('#set-input-' + index);
          if (setInput != null) {
            let subInputs = setInput.getElementsByTagName('input');
            if (subInputs.length > 0) {
              let focusedElement = document.activeElement;
              if(focusedElement &&  this.setElements?.find(el => el?.nativeElement?.contains(focusedElement))) {
                return;
              }
              subInputs[0].focus();
            }
          }
        }
      }
    }
    catch (ex) {
      console.error(ex);
    }
  }

  @Output() focusedTable = new EventEmitter();

  public setParameter = SetParameter;
  public setParameter2SubHeadingMapping = SetParameter2SubHeadingMapping;
  public setParameter2LabelUnitMapping = SetParameter2LabelUnitMapping;
  public setParameter2LabelMapping = SetParameter2LabelMapping;
  public setParameter2UnitMapping = SetParameter2UnitMapping;
  public setParameter2ValueStepMapping = TrainingSessionEditorComponent.SetParameter2ValueStepMapping;

  public getRealSetIndex = BaseTrainingSet.getRealSetIndex;

  public environment = environment;

  constructor(public languageService: LanguageService, private translate: TranslateService, private toastr: ToastrService, public trainingService: TrainingService, private dialog: MatDialog) { }

  public onRemoveParameterFromExerciseTableClick(setParameter: SetParameter) {
    this.selectedSetParameters = this.selectedSetParameters.filter(sp => sp !== setParameter);
    this.sets.forEach(set => {
      set[setParameter] = undefined;
    });
    this.selectedSetParametersChange.emit(this.selectedSetParameters);
  }

  public getSelectableSetParameters(): SetParameter[] {
    return Object.values(SetParameter).filter(sp => !this.selectedSetParameters.includes(sp));
  }

  public onParameterSetSelected(setParameter: SetParameter) {
    this.selectedSetParameters.push(setParameter);
    this.selectedSetParametersChange.emit(this.selectedSetParameters);
  }

  setDropped(event: CdkDragDrop<BaseTrainingSet[]>) {
    moveItemInArray(this.sets, event.previousIndex, event.currentIndex);

    for (let i = 0; i < this.sets.length; i++) {
      let set = this.sets[i];
      if (set.isWarmupSet) {
        set.isDropset = false;
        if (i > 0) {
          if (!this.sets[i - 1].isWarmupSet) {
            set.isWarmupSet = false;
          }
        }
      }
      if (i == 0) {
        set.isDropset = false;
      }
    }

    this.hasChangesChange.emit(true);
  }

  isPartOfDropset(set: BaseTrainingSet, sets: BaseTrainingSet[], setIndex: number) {
    if (set?.isDropset) {
      return true;
    }
    return this.isFirstPartOfDropset(set, sets, setIndex);
  }


  isFirstPartOfDropset(set: BaseTrainingSet, sets: BaseTrainingSet[], setIndex: number) {
    if (sets?.length > setIndex + 1) {
      return sets[setIndex + 1].isDropset;
    }
    return false;
  }


  setSetMode(set: BaseTrainingSet, mode: string, setIndex: number) {
    if (mode === 'warmup') {
      set.isWarmupSet = true;
      set.isDropset = false;
      if (this.sets.filter(x => x.isWarmupSet).length === 1) {
        this.toastr.info(this.translate.instant("`W` kennzeichnet einen Warmup-Satz. Dieser Satz fließt nicht in deine Statistik mit ein."), this.translate.instant("Info"), {
          positionClass: 'toast-bottom-center'
        });
      }
    }
    else if (mode === 'dropset') {
      set.isWarmupSet = false;
      set.isDropset = true;
      if (this.sets.filter(x => x.isDropset).length === 1) {
        this.toastr.info(this.translate.instant("`D` kennzeichnet einen Dropset."), this.translate.instant("Info"), {
          positionClass: 'toast-bottom-center'
        });
      }
      if (setIndex > 0) {
        let previousSet = this.sets[setIndex - 1];
        if (previousSet.pauseDuration) {
          previousSet.pauseDuration = undefined;
        }
      }
    }
    else {
      set.isWarmupSet = false;
      set.isDropset = false;
    }

    this.hasChangesChange.emit(true);
  }


  canSelectWarmupSet(set: BaseTrainingSet) {
    if (BaseTrainingSet.getSetIndexWithoutWarmupSets(this.sets, set) === 0) return true;
    return false;
  }


  unfocus() {
    try {
      document.activeElement['blur']();
    }
    catch (ex) {
      console.error(ex);
    }
  }

  addSet(set: BaseTrainingSet = null, focusElement: HTMLElement = null) {
    this.unfocus();
    let type = this.trackingMode ? TrackedTrainingSet : TrainingSet;
    if (this.sets.length > 0) {
      if (set == null) {
        let newTrainingSet = new type(this.sets[this.sets.length - 1]);
        newTrainingSet.isDropset = false;
        newTrainingSet.isWarmupSet = false;
        if (newTrainingSet instanceof TrackedTrainingSet) {
          newTrainingSet.isTracked = false;
          newTrainingSet.setNumber = this.sets?.length ?? 0;
        }
        this.sets.push(newTrainingSet)
        this.trainingSetsChange.emit(this.sets);
      }
      else {
        if (focusElement != null && this.trackingMode) {
          if (set instanceof TrackedTrainingSet) {
            if (!set.isTracked) {
              this.setTracked(set);
            }
          }
        }
        else {
          let index = this.sets.indexOf(set, 0);
          if (index > -1) {
            let newTrainingSet = new type(set);
            if (this.sets.length > index + 1) {
              newTrainingSet.isWarmupSet = this.sets[index + 1].isWarmupSet;
              if (!newTrainingSet.isWarmupSet) {
                newTrainingSet.isDropset = this.sets[index + 1].isDropset && set.isDropset;
              }
            }
            else {
              newTrainingSet.isWarmupSet = false;
              newTrainingSet.isDropset = false;
            }
            this.sets.splice(index + 1, 0, newTrainingSet);

            if (newTrainingSet instanceof TrackedTrainingSet) {
              for (let i = index + 1; i < this.sets.length; i++) {
                (this.sets[i] as TrackedTrainingSet).isTracked = false;
              }
            }
          }
        }
      }
    }
    else {
      if (type == TrackedTrainingSet) {
        let newSet = new TrackedTrainingSet();
        newSet.setNumber = 0;
        this.sets.push(newSet);
      }
      else {
        this.sets.push(new type());
      }

    }
    this.hasChangesChange.emit(true);

    if (focusElement && type == TrainingSet) {
      focusElement.focus();
    }
  }

  removeSet(set: BaseTrainingSet) {
    this.unfocus();
    let index = this.sets.indexOf(set, 0);
    if (index > -1) {
      this.sets.splice(index, 1);
    }
    if (set instanceof TrackedTrainingSet) {
      for (let i = index; i < this.sets.length; i++) {
        (this.sets[i] as TrackedTrainingSet).setNumber = i;
      }
    }
    this.hasChangesChange.emit(true);
    this.trainingSetsChange.emit(this.sets);
  }



  hasValueRange(value: string) {
    if (value?.includes('-')) return true;
    return false;
  }

  onOpenRpeDialog(exercise: PlannedTrainingExercise, set: BaseTrainingSet) {
    var rpe = set.rpe
    if (rpe == null && set.rir != null) {
      rpe = 10 - set.rir
    }
    var dialog = this.dialog.open(RpeTableDialogComponent, { data: { user: this.user, weight: set.weight, reps: set.reps, rpe: rpe } });
    dialog.afterClosed().subscribe(result => {
      if (result && result.weight) {
        this.trainingService.rpeTableCopyResult = result
      }
    });
  }
  onPasteRpeTableCopyResult(exercise: PlannedTrainingExercise, set: BaseTrainingSet) {
    if (this.trainingService.rpeTableCopyResult && set instanceof TrainingSet) {
      set.weight = this.trainingService.rpeTableCopyResult.weight
      set.weightFormula = null
      set.reps = this.trainingService.rpeTableCopyResult.reps
      if (exercise.setParameters?.includes(SetParameter.rpe)) {
        set.rpe = this.trainingService.rpeTableCopyResult.rpe
      } else if (exercise.setParameters?.includes(SetParameter.rir)) {
        set.rir = 10 - this.trainingService.rpeTableCopyResult.rpe
      }
      this.trainingService.rpeTableCopyResult = null
      this.hasChangesChange.emit(true);
    }
  }

  toggleExerciseRecordingRequestedForSet(set: BaseTrainingSet) {
    if (set instanceof TrainingSet) {
      set.videoRecordingRequested = !set.videoRecordingRequested;
    }
    this.hasChangesChange.emit(true);
  }

  hasTimeParameter() {
    return this.selectedSetParameters.includes(SetParameter.time);
  }

  canTrackSet(set: BaseTrainingSet) {
    if (set instanceof TrackedTrainingSet) {
      if (set.isTracked) {
        return false;
      }
      else if (set == this.focusedSet) {
        return true;
      }
      else {
        let firstUntrackedSet = this.sets.find(x => x instanceof TrackedTrainingSet && !(x as TrackedTrainingSet)?.isTracked);
        if (firstUntrackedSet == set) {
          return true;
        }
      }
    }
    return false;
  }

  setTracked(set: BaseTrainingSet) {
    if (this.trackingMode && set instanceof TrackedTrainingSet) {
      if (!set.isTracked) {
        let untrackedSets = this.sets.filter(x => x instanceof TrackedTrainingSet && !(x as TrackedTrainingSet)?.isTracked);
        if (untrackedSets.length > 0 && untrackedSets[0] == set) {
          set.isTracked = true;
          this.isTrackedChanged.emit(set);
        }
      }
      else {
        let trackedSets = this.sets.filter(x => x instanceof TrackedTrainingSet && (x as TrackedTrainingSet)?.isTracked);
        if (trackedSets.length > 0) {
          if (trackedSets[trackedSets.length - 1] == set) {
            set.isTracked = false;
            this.isTrackedChanged.emit(set);
          }
        }
      }
      this.hasChangesChange.emit(true);
    }
  }

  isTransparentSet(set: BaseTrainingSet) {
    if (set instanceof TrackedTrainingSet) {
      if (set.isTracked) return false;
      if (set == this.focusedSet) return false;
      return true;
    }
    else {
      return false;
    }
  }

  isCurrentSet(set: BaseTrainingSet) {
    return set == this.focusedSet;
  }

  isFutureSet(set: BaseTrainingSet) {
    if (set instanceof TrackedTrainingSet && set.isTracked) return false;
    return set != this.focusedSet;
  }

  public hasSetParameterFormulaInput(setParameter: SetParameter) {
    if (this.trackingMode) {
      return false;
    }
    return TrainingPlanEditorComponent.hasSetParameterFormulaInput(setParameter);
  }

  isDefaultInputType(setParameter: SetParameter, set: BaseTrainingSet, sets: BaseTrainingSet[], i: number) {
    return (setParameter != SetParameter.pauseDuration || !this.isFirstPartOfDropset(set, sets, i)) && !this.isTimeInputType(setParameter) && setParameter != SetParameter.timeUnderTension && setParameter != SetParameter.rir && setParameter != SetParameter.rpe && setParameter != SetParameter.note && !this.hasSetParameterFormulaInput(setParameter)
  }

  isTimeInputType(setParameter: SetParameter) {
    return setParameter == SetParameter.time || (this.trackingMode && (setParameter == SetParameter.pace || setParameter == SetParameter.pace500))
  }

  getPlaceholder(setIndex: number, setParameter: SetParameter) {
    if (this.getSetPlaceholder) {
      return this.getSetPlaceholder(setIndex, setParameter, this.languageService.selectedUnitSystem);
    }
    else {
      if (this.isTimeInputType(setParameter)) {
        return this.trackingMode ? 'hh:mm:ss' : 'hh:mm:ss(-hh:mm:ss)';
      }
      else if (setParameter == SetParameter.note) {
        return "";
      }
      else if (setParameter == SetParameter.rpe || setParameter == SetParameter.rir) {
        return "";
      }
      else if (setParameter == SetParameter.timeUnderTension) {
        return "X-X-X-X";
      }
    }
    return "";
  }

  onTableClick() {
    this.focusedTable.emit();
  }

  setChanged() {
    this.hasChangesChange.emit(true);
  }

  onAutoRunChanged() {
    this.autoRunChange.emit(!this.autoRun);
    this.hasChangesChange.emit(true);
  }

}
